
import React, { useState } from 'react';

const ReviewDrifts = ({reviewDrifts , setIsReviewDriftModelOpen , setreviewDriftsData}) => {
	console.log(reviewDrifts , "Review Driftsss")
		return (
			<div className="container-fluid">
				<div className="p-4">
					<div className="row mt-4">
						<div className="col-md-12 mb-4">
							<p className="font-20 font-bold black-v2 mb-0">Review Drifts</p>
						</div>
						{reviewDrifts.length!= 0?
						<div className="col-md-12 mt-3"> 
							<div className="accordion" id="accordionExample">
								{reviewDrifts.map((service, serviceIndex) => (
									<div key={serviceIndex} className="accordion-item border-0 mb-2 background-white">
										<div className="template-container py-3 px-3">
											<div className="accordion-header d-flex align-items-center" id={`heading${serviceIndex}`}>
												<button
													className="cust-acc-btn d-flex align-items-center gap-2 font-14 btn border-0 me-3"
													type="button" 
													data-bs-toggle="collapse" 
													data-bs-target={`#collapse${serviceIndex}`}
													aria-expanded="false" 
													aria-controls={`collapse${serviceIndex}`}
												>
												</button>
												<div>
													<p className="mb-0 font-16 font-medium black-v2">
														<img src={service.icon} alt={`${service.service}-icon`} className="me-2"/>
														{service.service}
													</p>
												</div>
											</div>
											<div 
												id={`collapse${serviceIndex}`} 
												className="accordion-collapse collapse" 
												aria-labelledby={`heading${serviceIndex}`} 
												data-bs-parent="#accordionExample"
											>
												<div className="accordion-body px-5">
													<div className="accordion" id={`accordionExample${serviceIndex}`}>
														{service.functions.map((func, funcIndex) => (
															<div key={funcIndex} className="accordion-item border-0 background-white">
																<div className="template-container mt-2 py-3 ps-3">
																	<div className="accordion-header d-flex align-items-center" id={`sub-heading${serviceIndex}-${funcIndex}`}>
																		<button
																			className="cust-acc-btn d-flex align-items-center gap-2 font-14 btn border-0 me-3"
																			type="button" 
																			data-bs-toggle="collapse"
																			data-bs-target={`#collapse-${serviceIndex}-${funcIndex}`}
																			aria-expanded="false"
																			aria-controls={`collapse-${serviceIndex}-${funcIndex}`}
																		>
																		</button>
																		<div>
																			<p className="mb-0 font-14 font-medium black-v2">{func.name}</p>
																		</div>
																	</div>
																	<div 
																		id={`collapse-${serviceIndex}-${funcIndex}`}
																		className="accordion-collapse collapse"
																		aria-labelledby={`sub-heading${serviceIndex}-${funcIndex}`}
																		data-bs-parent={`#accordionExample${serviceIndex}`}
																	>
																		<div className="accordion-body pb-2 ps-5 ms-3">
																			<div className="table-responsive table-border">
																				<table className="table custom-table">
																					<thead>
																						<tr>
																							<th className="text-start border-0">Configuration</th>
																							<th className="text-start border-0">State File Configuration</th>
																							<th className="text-start border-0">Actual Configuration</th>
																						</tr>
																					</thead>
																					<tbody>
																						{func.configurations.map((config, configIndex) => (
																							<tr key={configIndex}>
																								<td className="text-start text-grey">{config.name}</td>
																								<td className="text-start text-grey">{config.stateFile}</td>
																								<td className="text-start text-grey">{config.actual}</td>
																							</tr>
																						))}
																					</tbody>
																				</table>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														))}
													</div>
												</div>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
						:
						<div className="row mt-4 pt-2 manage-workloads-parent">
						<div className="col-md-12">
						<div className="record-container d-flex align-items-center justify-content-center flex-column">
							<div className="no-rec-img">
							<img src="images/no-rec-found-img.png" alt="no-rec-found" className="w-100 h-100" />
							</div>
							<h6 className="color-black font-bold font-16 mt-5 mb-0">No Drifts Detected!!</h6>
							<p className="font-medium font-12 mt-4 mb-0 secondary-text-color-v5"></p>
						</div>
						</div>
					</div>
}
	</div>
	<div className="d-flex justify-content-end align-items-center my-4 pt-3">
		<button type="button" className="button primary-button text-nowrap px-32 py-3 lh-1"
		onClick={()=>{
			setIsReviewDriftModelOpen(false)
			setreviewDriftsData(reviewDrifts)
		}}
		>Update</button>
	</div>
	</div>
	</div>
	);
};

export default ReviewDrifts;
