// OrgContext.js
import React, { createContext, useState, useContext, useEffect } from "react";
import { getAllRulesApi } from "../Service/api";
import { FormContext } from "../Components/Organization/Workload_Grid";
// Create the context
export const OrgContext = createContext();

// Create a provider component
export function OrgProvider({ children }) {
  const [orgData, setOrgData] = useState([]);

  // const[selectedAccount,setSelectedAccount]=useState('')
  // const [approvalStatus, setApprovalStatus] = useState("")
  const {
    formData = JSON.parse(localStorage.getItem("formData")) || formData,
    setFormData,
  } = useContext(FormContext);

  console.log(formData.language, "formdata");

  const [providerId, setproviderId] = useState(
    localStorage.getItem("providerId")
  );
  const [providerName, setProviderName] = useState(
    localStorage.getItem("providerName")
  );
  const [services, setservices] = useState([{}]);
  // const [providerId, setproviderId] =  useState("03a8aaaa-7447-42aa-9aaa-538452cdcd8b");
  // const [providerName, setProviderName] =  useState("Azure")
  // const [services , setservices] = useState( [
  //     {
  //       "service": [
  //         {
  //           "serviceId": "50b2640a-4aad-47c4-ae66-1bcb08e9cbe1",
  //           "serviceName": "Iaas"
  //         },
  //         {
  //           "serviceId": "845fcdac-a215-4d19-84f0-47c8e026caec",
  //           "serviceName": "Resource Group"
  //         }
  //       ]
  //     }
  //   ])

  const [architectures, setarchitectures] = useState("");
  const [userRoles, setuserRoles] = useState("");
  const [canCreateWorkload, setCanCreateWorkload] = useState(false);
  const [canDeleteWorkload, setCanDeleteWorkload] = useState(false);
  const [canViewWorkload, setCanViewWorkload] = useState(false);
  const [providerId2, setProviderId2] = useState([]);
  const [UTI, setUTI] = useState({});
  const [colorMode, setColorMode] = useState("light");
  const [isProjectAdmin, setIsProjectAdmin] = useState(false);

  const [scm, setScm] = useState("");
  const [workloadRole, setworkloadRole] = useState("");

  // const { formData } = useContext(FormContext);

  //  const defaultLanguage = formData.language

  //  console.log(defaultLanguage,"defaultLanguage")

  const [selectedLangforDownload, setSelectedLangforDownload] = useState(() => {
    const storedFormData = localStorage.getItem("formdata");
    const formData = JSON.parse(storedFormData);
    const language = formData?.language;
    return language ? language : "";
  });

  useEffect(() => {
    console.log(userRoles, "UserRoles2");
    loadRules();
    console.log(userRoles, "UserRoles");

    // Calculate permissions based on user roles
    setCanCreateWorkload(
      userRoles.includes("admin") ||
        userRoles.includes("project admin") ||
        userRoles.includes("architect")
    );
    setCanDeleteWorkload(
      userRoles.includes("admin") || userRoles.includes("project admin")
    );
    setCanViewWorkload(
      userRoles.includes("admin") ||
        userRoles.includes("project admin") ||
        userRoles.includes("architect") ||
        userRoles.includes("viewer")
    );

    if (userRoles.includes("Viewer")) {
      setViewMode(true);
    }
  }, [userRoles]);

  const [orgId, setOrgId] = useState(localStorage.getItem("orgId"));
  const [userId, setUserId] = useState("");
  const [userName, setuserName] = useState("");
  const [envId, setEnvId] = useState(localStorage.getItem("envId"));
  const [workloadId, setworkloadId] = useState(
    localStorage.getItem("workloadId")
  );
  const [approvalId, setapprovalId] = useState(
    localStorage.getItem("approvalId") ?   localStorage.getItem("approvalId") : ""
  );
  useEffect(() => {
    // Calculate permissions based on user roles
    if (userId) {
      loadRules();
    }
  }, [userId, orgId]);

  const [aiArchitecture, setaiArchitecture] = useState(false);
  const [envName, setEnvName] = useState("");
  const [language, setLanguage] = useState("");
  const [business, setBusiness] = useState("");
  const [archType, setArchType] = useState("");
  const [rulebook, setRulebook] = useState([{}]);
  const [archDesc, setArchDesc] = useState("");

  // Repo
  const [lastBuildID, setlastBuildID] = useState("");
  const [lastBuildDate, setlastBuildDate] = useState("");
  const [LastBuildMessage, setLastBuildMessage] = useState("");
  // Repo Workbench
  const [repoId, setRepository_id] = useState(
    localStorage.getItem("repoId") ? localStorage.getItem("repoId") : ""
  );
  const [repoName, setrepository_name] = useState("");
  const [viewMode, setViewMode] = useState(false);

  // Pulse Purpose
  const [PulseOrganization_id, setPulseOrganization_id] = useState("");
  const [PulseVertical_id, setPulseVertical_id] = useState("");
  const [PulseVertical_name, setPulseVertical_name] = useState("");
  const [PulseOrganization_name, setPulseOrganization_name] = useState("");
  const [PusleIdentificaiton, setPulseIdentification] = useState(false);

  //dashboard pipeline commit context
  const [filteredPipelineData, setFilteredPipelineData] = useState([]);
  const [filteredCommitData, setFilteredCommitData] = useState([]);
  const [pipelineData, setPipelineData] = useState([]);
  const [commitData, setCommitData] = useState([]);
  const [pendingCount, setPendingCount] = useState(0);
  const [selectedAccount, setSelectedAccount] = useState("");
  const [approvalStatus, setApprovalStatus] = useState("");
  const [reqApprovalId, setreqApprovalId] = useState("");
  // const {  orgId,userId,envId,workloadId,providerId} = useOrgContext();
  // {
  //     orgId: "6f47c8a1-9c2d-4e8b-b3a5-8d7f6e5d4c3b",
  //     userId: "c7d86e9d-4819-4f5e-95f8-782d4c6774a3"
  //     providerId: "0a5816fa-5f85-4fd9-b716-62e4395c7b4a"
  //     workloadId: "93f6aa1a-b004-46bf-bf5f-4f11ba31dea1"
  //     envId: "986b6f95-50cf-4f42-95a1-b213f7b1da00"
  //     }
  const loadRules = async () => {
    const payload = {
      userId: userId,
      orgId: orgId,
    };

    try {
      const response = await getAllRulesApi(payload); // Call the API function
      if (response.success) {
        console.log("Response fetched successfully");
        const data = response.data;

        // Prepare rulebook based on the structure of the response data
        const rulebook = Object.keys(data).map((category) => ({
          categoryId: category, // Use category name as ID (or modify if you have a specific ID)
          categoryName: category,
          ruleName: data[category].map((rule) => ({
            rulebook_id: rule.ruleId, // Assuming ruleId is what you want to keep
            check: rule.check,
            ruleDesc: rule.ruleDesc,
          })),
        }));

        setFormData((prevFormData) => ({
          ...prevFormData, // Preserve existing form data
          rulebook: rulebook, // Set the constructed rulebook
        }));
      } else {
        console.error("Error fetching rules");
      }
    } catch (error) {
      console.error("An error occurred while fetching rules:", error);
    }
  };
  console.log(envName, "envName");

  return (
    <OrgContext.Provider
      value={{
        // Workbench + AI to Architecture
        orgId,
        userId,
        envId,
        setUserId,
        setEnvId,
        envName,
        setEnvName,
        setworkloadId,
        scm,
        setScm,
        workloadRole,
        setworkloadRole,
        UTI,
        setUTI,
        setproviderId,
        setProviderName,
        setRepository_id,
        setrepository_name,
        providerId2,
        setProviderId2,
        isProjectAdmin,
        setIsProjectAdmin,
        setPulseIdentification,
        workloadId,
        providerId,
        providerName,
        aiArchitecture,
        viewMode,
        envName,
        language,
        archType,
        business,
        rulebook,
        archDesc,
        services,
        // Repo Workbench
        lastBuildID,
        lastBuildDate,
        LastBuildMessage,
        repoId,
        repoName,

        // Pulse Scenario
        PulseOrganization_id,
        PulseVertical_id,
        PulseVertical_name,
        PulseOrganization_name,
        PusleIdentificaiton,
        colorMode,
        setColorMode,

        setOrgId,
        orgData,
        setOrgData,
        setarchitectures,
        architectures,
        userRoles,
        setuserRoles,
        canCreateWorkload,
        canDeleteWorkload,
        canViewWorkload,

        //dashboard pipeline commit
        pipelineData,
        setPipelineData,
        commitData,
        setCommitData,
        filteredPipelineData,
        setFilteredPipelineData,
        filteredCommitData,
        setFilteredCommitData,
        //Approval grid pending
        pendingCount,
        setPendingCount,
        selectedAccount,
        setSelectedAccount,
        approvalStatus,
        setApprovalStatus,
        reqApprovalId,
        setreqApprovalId,
        setapprovalId,
        approvalId,

        selectedLangforDownload,
        setSelectedLangforDownload,
      }}
    >
      {children}
    </OrgContext.Provider>
  );
}

// Create a custom hook for using this context
export function useOrgContext() {
  return useContext(OrgContext);
}
