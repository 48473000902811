// /PS_03 - Import statements
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { editServiceDetails, getSelfServiceWithAI, saveServiceDetails, updateSelfService } from "../../../Service/api"
import Pagination from "../../CostManagement/Pagination"
import Loader from "../../loader/loader"
import { useModuleContext } from "../../../ContextProvider/ModulesContxt"
import { v4 as uuidv4 } from 'uuid';
import AddResourcePopup from "./AddResourcePopup"

//PS_04
function EditServiceForm() {
  //PS_05
  const navigate = useNavigate()
  const location = useLocation()

  //PS_06- Declaration of state variables
  const [serviceData, setServiceData] = useState([])
  const [containsServices, setContainsServices] = useState(false)
  const [parentServices, setParentServices] = useState(false)
  const [formServiceName, setFormServiceName] = useState('');
  const [serviceIcon, setServiceIcon] = useState(null);
  const [serviceIconName, setServiceIconName] = useState("")
  const [description, setDescription] = useState('');

  const [searchTerm, setSearchTerm] = useState('');
  const [sortColumnName, setSortColumnName] = useState('');
  const [sortOrder, setSortOrder] = useState('ASC'); // ASC or DESC
  const [filteredConfigs, setFilteredConfigs] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loadCount, setLoadCount] = useState(10)
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10;
  const [showLoader, setShowLoader] = useState(false)

  const { resources, setResources } = useModuleContext();
  const [resourcePopup, setResourcePopup] = useState(false);


  //PS_08
  const serviceId = location?.state?.serviceId || ""
  const serviceName = location?.state?.serviceName || ""
  const userId = localStorage.getItem('userId')

  console.log(serviceIcon, "serviceIcon")

  //PS_09 - Initial set when edit serviceData 
  useEffect(() => {
    if (serviceData) {
      setContainsServices(serviceData.containsService || false);
      setFormServiceName(formServiceName||serviceData.serviceName || '');
     
      setDescription(serviceData.description || description);
      setContainsServices(serviceData.containService || containsServices)
      setParentServices(serviceData.is_parent || parentServices)
      console.log("serviceIconNameserviceIconName",serviceIconName);
      
    }
  }, [serviceData]);

  useEffect(()=>{
    console.log("serviceIconNameserviceIconName2",serviceIconName);
    console.log("serviceIconNameserviceIconName2",serviceData?.imageUrl);
if(serviceData?.imageUrl){
    setServiceIcon(serviceIcon||serviceData.imageUrl || null);
    setServiceIconName(serviceIconName||serviceData?.imageUrl ? serviceData?.imageUrl?.split('/').pop() : '');
 }
 },[serviceData?.imageUrl])
  console.log("serviceIconNameserviceIconName2",serviceIconName);
  console.log("serviceIconNameserviceIconName2",serviceData?.imageUrl);


  //PS_12- Intial fetch of service data
  useEffect(() => {

    if (serviceId) {

      getServiceConfigurationById()
    }

  }, [serviceId])


  //PS_38 - Fetch when search,sort,pagination
  useEffect(() => {
    const configs = Array.isArray(serviceData.configuration) ? serviceData.configuration : [];


    const filtered = searchTerm
      ? configs.filter(config =>
        config.input.toLowerCase().includes(searchTerm.toLowerCase())
      )
      : configs;

    const sorted = [...filtered].sort((a, b) => {
      const valA = a[sortColumnName] ?? '';
      const valB = b[sortColumnName] ?? '';

      if (typeof valA === 'string' && typeof valB === 'string') {
        return sortOrder === 'ASC'
          ? valA.localeCompare(valB)
          : valB.localeCompare(valA);
      }

      if (typeof valA === 'number' && typeof valB === 'number') {
        return sortOrder === 'ASC'
          ? valA - valB
          : valB - valA;
      }

      return 0;
    });

    setFilteredConfigs(sorted);
  }, [searchTerm, sortColumnName, sortOrder, serviceData]);


  //PS_39- Handles the search functionality
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  //PS_40- Handles the sort functionality
  const handleSort = (columnName) => {
    setSortOrder(prevSortOrder => prevSortOrder === 'ASC' ? 'DESC' : 'ASC');
    setSortColumnName(columnName);
  };


  //PS_13-PS_19- To fetch the service data and configuration
  const getServiceConfigurationById = async () => {
    try {
      console.log("entereddd")
      const payload =
      {
        userId: userId,
        serviceId: serviceId
      };
      const response = await editServiceDetails(payload);

      console.log(response, "responsefor edit")

      if (response.status === 200) {

        const serviceDataForID = response.data.data.configuration.map((config, index) => ({
          ...config,
          id: index // or use UUID generation library for a more unique identifier
        }));

        console.log(serviceDataForID, "serviceDataForID")
        setServiceData({
          ...response.data.data,
          configuration: serviceDataForID
        });

        

        const dataFile = response.data.data.dataFile.map(resource => ({ ...resource, id: uuidv4() }));
        setResources(dataFile);


      }

    } catch (error) {
      console.error('Error fetching service configuration:', error);
    }
  };

  console.log(serviceData, "editservicedata")

  //PS_29- PS_36 - Handles the update functionality
  const handleUpdate = async () => {

    const isImageBase64 = serviceIcon && serviceIcon.startsWith('data:image/');
    const updatedData =
    {
      userId: userId,
      serviceId: serviceData.serviceid,
      serviceName: formServiceName,
      imageUrl: serviceIcon,
      imageUpdate: isImageBase64, // Modify this appropriately if the image can be updated
      containService: containsServices,
      is_parent: parentServices,
      description: description,
      configuration: serviceData.configuration,
      dataFile: resources
    };

    console.log("updatedData", updatedData)
    try {
      setShowLoader(true)
      const response = await updateSelfService(updatedData);
      setShowLoader(false)
      console.log(response, "response")
      if (response.status === 200) {
        setServiceData(response);

        navigate('/ServiceConfigurationMain', {
          state:
            { activeTab: "Services" }
        })
        setServiceData(null);

      }
      else {
        console.log("error in updating")
      }
    } catch (error) {
      console.error('Error updating service configuration:', error);
      setShowLoader(false)
    }
  };


  
  //PS_41- Bind the configuration grid
  const bindConfiguration = () => {

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    console.log(filteredConfigs, "Fileter Configgsss")
    const sortedConfigs = [...filteredConfigs].sort((a, b) => {
      if (a.status === b.status) return 0;
      return a.status == true ? -1 : 1;
    });
    const currentConfigurations = sortedConfigs?.slice(startIndex, endIndex);
    console.log(currentConfigurations , "currentConfigurations")

    return currentConfigurations.map((config, index) => {

      return (
        <tr key={config.id} className={config.status == true ? "row-selected-one" : ""}>
          <td>{config.id + 1}</td>
          <td>{config.input}</td>
          <td>{config.type}</td>

          <td>
    {typeof config?.default === 'boolean' ? String(config.default) :
        Array.isArray(config?.default) ? (
            <span title={JSON.stringify(config.default)}>
                {JSON.stringify(config.default).length > 50 ?
                    `${JSON.stringify(config.default)?.slice(0, 50)}...` :
                    JSON.stringify(config.default)}
            </span>
        ) :
        typeof config?.default === 'object' && config?.default !== null ? (
            <span title={JSON.stringify(config.default)}>
                {JSON.stringify(config.default).length > 50 ?
                    `${JSON.stringify(config.default)?.slice(0, 50)}...` :
                    JSON.stringify(config.default)}
            </span>
        ) :
        (
            config?.default ? ( // Check if config.default exists and is not empty
                <span title={config.default}>
                    {String(config.default).length > 50 ? `${String(config.default)?.slice(0, 50)}...` : String(config.default)}
                </span>
            ) : (
                '" "' // Display this when config.default is empty, null or undefined
            )
        )}
</td>

          <td title={config.description}>
            {config.description.length > 50
              ? `${config.description?.slice(0, 50)}...`
              : config.description}
          </td>

          <td className="text-nowrap text-center">
            <div className="form-check form-switch d-flex justify-content-center">
              <input
                className="form-check-input custom-switcher"
                type="checkbox"
                role="switch"
                id={`suggestion_${config.id}`}
                checked={config.configSuggestion == true}
                onChange={(e) => handleCheckboxChange(config.id, e.target.checked, 'configSuggestion')}
              />
            </div>
          </td>

          <td className="text-nowrap text-center">
            <div className="form-check form-switch d-flex justify-content-center">
              <input
                className="form-check-input custom-switcher"
                type="checkbox"
                role="switch"
                id={`status_${config.id}`}
                checked={config.status == true}
                onChange={(e) => handleCheckboxChange(config.id, e.target.checked, 'status')}
              />
            </div>
          </td>

        </tr>
      );
    });
  };

  const handleCheckboxChange = (id, checked, field) => {

    const updatedConfigurations = serviceData.configuration.map(config =>
      config.id === id ?
        { ...config, [field]: checked } : config
    );

    const updatedService = {
      ...serviceData,
      configuration: updatedConfigurations
    };
console.log("updatedService",updatedService);

    setServiceData(updatedService);
  };

  //  //PS_43- Handles the toggle functionality
//   const handleCheckboxChange = (id, checked, field) => {
 
//     const updatedConfigurations = serviceData.configuration.map(config =>
//       config.id === id ?
//         { ...config, [field]: checked } : config
//     );
//     const isImageBase64 = serviceIcon && serviceIcon.startsWith('data:image/');
 
//     const updatedService = {
//       serviceName: formServiceName,
//       imageUrl: serviceIcon,
//       imageUpdate: isImageBase64, // Modify this appropriately if the image can be updated
//       containService: containsServices,
//       is_parent: parentServices,
//       description: description,
//       configuration: updatedConfigurations
//     };
//  console.log("updatedService",updatedService);
 
//     setServiceData(updatedService);
//   };

  //PS_44- Handles the remove icon functionality
  const removeServiceIcon = () => {
    const fileInput = document.getElementById('orgLogo');
    fileInput.value = '';
    setServiceIcon(null);
    setServiceIconName("");
    setServiceData((prevData) => ({
      ...prevData,
      imageUrl: null,
    }));

  };


  console.log("servicedata in edit form", serviceData)
  console.log("servicedata serviceIcon form", serviceIcon)
  console.log("filtered configs", filteredConfigs)



  return (
    <>
      {showLoader ?
        <Loader /> :
        <div className="container-fluid custom-container">
          {/*  title start here*/}
          <div className="d-flex align-items-center py-3 pt-4 mt-2">
            <a onClick={() => {
              navigate('/ServiceConfigurationMain', {
                state: {
                  activeTab: "Services" // Replace this with your actual state variable
                }
              });
            }}>
              <img
                src="images/back-arrow.svg"
                alt="back-arrow"
                title="Back"
                className="me-3 icon-filter"
              />
            </a>
            <h2 className="font-20 font-bold black-v2 mb-0"> Edit {serviceName}</h2>

            <div class="d-flex justify-content-end align-items-center my-4 gap-3" style={{ marginLeft: '71%' }}>
              <button type="button" class="button primary-button text-nowrap px-3" onClick={(() => { setResourcePopup(true); })}>Configure resource</button>
              {resourcePopup &&
                <AddResourcePopup
                  resourcePopup={resourcePopup}
                  setResourcePopup={setResourcePopup} />}
            </div>

          </div>
          {/*  title end here*/}
          {/* Page Content Start here */}

          {serviceData && (
            <div className="row">
              <div className="col-md-12 mt-4 ps-5">
                <div className="row">
                  <div className="col-md-6 col-lg-3 mb-4 px-3">
                    <label
                      className="form-label font-14 font-medium black-v2"
                      htmlFor="Name"
                    >
                      {" "}
                      Name<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      id="Name"
                      className="form-control custom-form font-14 font-medium"
                      placeholder="Enter Name"
                      value={formServiceName}
                      onChange={(e) => {
                        // Replace spaces with underscores
                        const newValue = e.target.value.replace(/\s+/g, '_');
                        // Update the state
                        setFormServiceName(newValue);
                      }}
                    />
                  </div>
                  <div className="col-md-6 col-lg-4 mb-4 px-3">
                    <label />
                    <div className="d-flex align-items-center gap-3 mt-3">
                      <label className="font-14 font-medium ms-2 black-v2 service-checkbox">
                        <input
                          type="checkbox"
                          className="form-check-input custom-checkbox shadow-none me-2"
                          checked={parentServices}
                          onChange={(e) => setParentServices(e.target.checked)}
                        />
                        Parent Service
                      </label>
                      <label className="font-14 font-medium ms-2 black-v2 service-checkbox">
                        <input
                          type="checkbox"
                          className="form-check-input custom-checkbox shadow-none me-2"
                          checked={containsServices}
                          onChange={(e) => setContainsServices(e.target.checked)}
                        />
                        Contains Service?
                      </label>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-3 mb-4 px-3">
                    <div className="position-relative">
                      <label className="file-upload-outline position-absolute rounded-3 d-flex align-items-center gap-2 font-14 font-medium color-black">
                        Upload Icon <span className="required">*</span>
                      </label>
                      <button
                        type="button"
                        className="btn p-0 ms-1"
                        data-bs-toggle="tooltip"
                        data-bs-placement="right"
                        data-bs-title="Kindly add updated icons with proper format - sample text needs to be added by dev team"
                      >
                      </button>
                      <input
                        type="file"
                        className="form-control custom-file"
                        id="orgLogo"
                        aria-label="upload"
                        accept=".jpg, .jpeg, .png"
                        disabled={serviceIcon !== null} // Disable input if an image is already set
                        onChange={(e) => {
                          const file = e.target.files[0];
                          const reader = new FileReader();
                          reader.onloadend = () => {
                            setServiceIcon(reader.result);
                            setServiceIconName(file.name);
                          };
                          reader.readAsDataURL(file);
                        }}
                      />

                      {(serviceIcon || serviceData.imageUrl) && (
                        <p className="font-10 font-medium primary-color">
                          <img
                            src={serviceIcon || serviceData.imageUrl}
                            alt="service-icon"
                            className="uploaded-partner-logo me-3"
                            width="32"
                            height=""
                          />
                          {serviceIconName|| serviceData.imageUrl}
                          <img
                            src="images/cancel-upload-icon.svg"
                            alt="close icon"
                            className="ms-2 cursor-pointer"
                            onClick={removeServiceIcon}
                          />
                        </p>
                      )}
                    </div>
                  </div>


                  <div className="col-md-8 mb-5">
                    <label
                      className="form-label font-14 font-medium black-v2"
                      htmlFor="description"

                    >
                      Description <span className="required">*</span>
                    </label>
                    <textarea
                      className="h-100 form-control custom-form custom-textarea font-14 font-medium"
                      placeholder="Enter Description"
                      id="description"
                      value={description}
                      onChange={((e) => { setDescription(e.target.value) })}
                    />
                  </div>
                </div>
              </div>
              <div className="d-xl-flex justify-content-between align-items-center service-configurations-title-bar py-3 pt-4 mt-2">
                <div className="d-flex align-items-center mb-3 mb-md-0 ">
                  <h2 className="font-20 font-bold black-v2 mb-0">
                    Service Configurations
                  </h2>
                </div>
                <div className="d-flex justify-content-center flex-nowrap gap-3">
                  {/* search */}
                  <div className="input-group input-search custom-border-box custom-search-box">
                    <input
                      type="search"
                      className="px-3 form-control common-bg border-0 custom-search gray-v1"
                      placeholder="Search"
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                    <button className="btn border-start-0 custom-search " type="button">
                      <img src="images/search-icon.svg" alt="search" />
                    </button>
                  </div>
                  {/* search */}
                </div>
              </div>

              {filteredConfigs.length != 0 ? (
                <div className="col-md-12 mb-4 pb-2">
                  {/* table start */}

                  <div className="table-responsive table-border">
                    <table className="table custom-table">
                      <thead>
                        <tr>
                          <th className="text-start border-0">S.No</th>
                          <th className="text-start border-0" onClick={() => handleSort('input')}>
                            Inputs
                            <span className="cursor-pointer ms-2">
                              <img src="images/table-sort.svg" alt="sort-icon"
                                style={{
                                  transform: sortColumnName === "input" && sortOrder === "ASC" ? "scaleY(-1)" : "scaleY(1)",
                                  transition: "transform 0.2s ease-in-out"
                                }}
                              />
                            </span>
                          </th>
                          <th className="text-start border-0" onClick={() => handleSort('type')}>
                            Type
                            <span className="cursor-pointer ms-2">
                              <img src="images/table-sort.svg" alt="sort-icon"
                                style={{
                                  transform: sortColumnName === "type" && sortOrder === "ASC" ? "scaleY(-1)" : "scaleY(1)",
                                  transition: "transform 0.2s ease-in-out"
                                }}
                              />
                            </span>
                          </th>
                          <th className="text-start border-0">Default</th>
                          <th className="text-start border-0">Description</th>
                          <th className="text-center border-0">Suggestions</th>
                          <th className="text-center border-0">Enable</th>
                        </tr>
                      </thead>
                      <tbody>
                        {bindConfiguration()}
                      </tbody>

                    </table>
                  </div>

                  {/* table end */}

                  <Pagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalItems={filteredConfigs.length}
                    itemsPerPage={itemsPerPage}
                    displayedCount={filteredConfigs?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).length}
                  />
                </div>
              ) : (
                <tr>
                  <td colSpan="8" className="no-record-cell">
                    <div className="no-record-found" style={{ paddingTop: '28%' }}>
                      <img src="images/no-records-found.png" alt="No records found" className="no-record-icon" />

                    </div>
                  </td>
                </tr>
              )}


              <div className="col-md-12 mb-4">
                <div className="d-flex justify-content-between" style={{ paddingTop: filteredConfigs.length === 0 ? '25%' : '0' }} >
                  <button type="button" className="button cancel-button px-4 py-3 lh-1"
                    onClick={(() => {
                      navigate('/ServiceConfigurationMain', {
                        state:
                          { activeTab: "Services" }
                      })
                      setServiceData(null)
                    })}>
                    Cancel
                  </button>
                  <button type="button" className="btn button primary-button py-3 lh-1"
                    onClick={handleUpdate}
                    disabled={!formServiceName || !serviceIcon || !description}
                  >

                    Update
                  </button>
                </div>
              </div>
            </div>
          )}
          {/* Page Content End here */}
        </div>
      }
    </>

  )
}
export default EditServiceForm

