
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { OrgContext } from '../../ContextProvider/OrgContext';

export  function WorkbenchNav() {

    const{selectedAccount,setSelectedAccount,setSelectedLangforDownload, setapprovalId}=useContext(OrgContext)

    const navigate = useNavigate();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState(getInitialActiveTab());
    const { environmentId, approvalStatus, approvalId, isThreshholdSet } = location.state || {};
    useEffect(()=>{
        console.log(location , "Location Here")
    },[location])
    console.log( environmentId, approvalStatus, approvalId, isThreshholdSet, "++++++++++++++++++")  


    // console.log(location,"This is the location")
    // console.log(location,"dsfgdh")
    console.log("workbenchNav",selectedAccount)

    function getInitialActiveTab() {
        if (location.pathname === '/Workbench') return 'Design';
        if (location.pathname === '/Estimation') return 'Estimation';
        if (location.pathname === '/DeployModuleCodeEditor') return 'Deploy';
        if (location.pathname === '/ServiceReview') return 'Deploy';
        return 'Design'; // Default to Design if no match
    }


    // console.log(location,"This is the location for the workbench...")


    const handleTabClick = (tab, path) => {
        console.log(location,"This is location")
        setActiveTab(tab);
        setSelectedAccount("");
        console.log(path , "Pathhhh")
        setSelectedAccount('')
        // Pass the state to the Estimation route
        if (path === '/Estimation') {
            console.log("Route INvokation to Estimation", { 
                    environmentId, 
                    approvalStatus, 
                    approvalId, 
                    isThreshholdSet 
                } )
            navigate(path, { 
                state: { 
                    environmentId, 
                    approvalStatus, 
                    approvalId, 
                    isThreshholdSet 
                } 
            });
            
        } else {
            navigate(path , {
                state: {
                    environmentId, 
                    approvalStatus, 
                    approvalId, 
                    isThreshholdSet 
                } 
            } );
        }
    };


    return (
        <nav className="navbar navbar-expand-lg background-white custom-nav">
            <div className="container-fluid custom-container px-3">
                <a className="navbar-brand" href="#">
                    <span className="d-flex">
                        <img src="images/logo.svg" alt="logo" className="header-logo light-theme-img" />
                        <img src="images/logo-dark.svg" alt="logo" className="header-logo dark-theme-img" />
                    </span>
                </a>
                <div className="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
                    <ul className="navbar-nav">
                        <li className={`nav-item custom-nav-item ${activeTab === 'Design' ? 'active' : ''}`}
                            onClick={() => handleTabClick('Design', '/Workbench')}>
                            <a className="nav-link" aria-current="page" href="#">Design</a>
                        </li>
                        <li className={`nav-item custom-nav-item ${activeTab === 'Estimation' ? 'active' : ''}`}
                            onClick={() => handleTabClick('Estimation', '/Estimation')}>
                            <a className="nav-link" aria-current="page" href="#">Estimation</a>
                        </li>
                        <li className={`nav-item custom-nav-item ${activeTab === 'Deploy' ? 'active' : ''}`}
                            onClick={() => handleTabClick('Deploy', '/ServiceReview')}>
                            <a className="nav-link" aria-current="page" href="#">Deploy</a>
                        </li>
                    </ul>
                </div>

                <div className="btn-group">
                    <button type="button" className="button danger-button" onClick={() => 
                    {navigate('/workloadsGrid')
                    setSelectedLangforDownload("")
                    localStorage.removeItem('approvalId')
                    setapprovalId('')
                
                    } 
                }>
                        <img src="images/button-exit.svg" alt="exit" className="me-1" /> Exit Workbench
                    </button>
                </div>
            </div>
        </nav>
    );
}