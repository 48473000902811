
import React, { useState, useEffect , useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
	fetchConnectorsApi,
	fetchLocationApi,
	fetchTagsAzureApi,
	get_all_ProviderApi,
	get_all_regionApi,
	get_all_tags_b2a_Api,
	getAllOrgApi,
	saveb2Api,
} from '../../../Service/api';
import SideBar from '../../sidebar/SideBar';
import { useOrgContext } from '../../../ContextProvider/OrgContext';
import Loader from '../../loader/loader';

function B2AformComponent() {
	// State Varaible Declaration 
	const { orgId }= useOrgContext()
	const [userId, setuserId] = useState(localStorage.getItem('userId'));
	const [cloudProviders, setCloudProviders] = useState([]);
	const [connectors, setConnectors] = useState([]);
	const [regions, setRegions] = useState([]);
	const [selectedProvider, setSelectedProvider] = useState(null);
	const [selectedConnector, setSelectedConnector] = useState(null);
	const [selectedRegion, setSelectedRegion] = useState(null);
	const [selectedTags, setSelectedTags] = useState([]);
	const [tags, setTags] = useState([]);
	const [b2aConnectorId, setB2AConnectorId] = useState('');
	const [isDropdownOpen, setIsDropdownOpen] = useState({
		provider: false,
		connector: false,
		region: false,
		tag: false,
	});
	const [isEditMode, setIsEditMode] = useState(false);
	const [isLoading , setIsLoading] = useState(false)
	

	const navigate = useNavigate();
	const location = useLocation();
	console.log(location , "Location")
		useEffect(()=>{
			console.log(selectedProvider , "Selected Rpvoder")
		},[selectedProvider])
		console.log("cloud providerrrrs",cloudProviders);
	// Page Onload UseEffect to Retreive the Provider selected in the GRID component
	useEffect(() => {
		try {
		if (location.state) {
			const {
				cloudproviderId,
				connector_id,
				connector_name,
				region,
				tags,
				b2a_connector_id,
					providerName,
					editMode
			} = location.state;
				console.log(editMode , "HEr")
			if(editMode == true){
				setIsEditMode(editMode);
				setSelectedProvider({ providerId: cloudproviderId , providerName : providerName });
				if (connector_id) {
					setSelectedConnector({
						connectorId: connector_id,
						connectorName: connector_name,
					});
					setSelectedRegion({ name: region , displayName :region });
					setSelectedTags(tags);
					setB2AConnectorId(b2a_connector_id);
					fetchConnectors(cloudproviderId);

				} else {
					fetchConnectors(cloudproviderId);
				}
			}
			else{ 
				setSelectedProvider({ providerId: cloudproviderId , providerName : providerName });
				fetchCloudProviders()
				fetchConnectors(cloudproviderId);
			}
		}
		fetchCloudProviders();
		} catch (error) {
				console.log(error  ,"Error in the Form Component Location")
		}
	}, [location]);

		useEffect(()=>{
			console.log(selectedTags , "SelectedTags")
		},[selectedTags])
	// Cloud Provider is Fetched 
	const fetchCloudProviders = async () => {
		try {
			const payload = { userId, type: 'org', orgId };
			setIsLoading(true)
			const response = await get_all_ProviderApi(payload);
			console.log(response , "Respon for the Fetch CloudProviders")
			// setIsLoading(false)
			if (response.success) {
				setCloudProviders(response.data);
			}
		} catch (error) {
			console.error('Error fetching cloud providers:', error);
		}
	};
	// fetchConnectors Function is Triggered to Retreived the Connector based on the Provder Chosen
	const fetchConnectors = async (providerId) => {
		try {
			const payload = { userId, orgId, providerId, search: '' };
			setIsLoading(true)
			const response = await fetchConnectorsApi(payload);
				console.log("Ferch Connectorss")
			if (response.success) {
				setConnectors(response.data);
				console.log("B2AA",response.data);
				setIsLoading(false)

			}
		} catch (error) {
			console.error('Error fetching connectors:', error);
		}
	};
	// fetchRegions function is Triggered During the Connector Configurations
	const fetchRegions = async (connectorId) => {
		try {
			if(selectedProvider.providerName == "AWS"){
			const payload = {
				userId,
				organizationId: orgId,
				connectorId,
				cloudproviderId: selectedProvider.providerId,
			};
			setIsLoading(true)
			const response = await get_all_regionApi(payload);
			setIsLoading(false)
			if (response.success) {
				setRegions(response.data);
			}
				}else{
					const payload = {
				userId,
				organizationId: orgId,
				connectorId,
				cloudproviderId: selectedProvider.providerId,
			};
			console.log(payload , "Payoad in for Location ")
			setIsLoading(true)
			const response = await fetchLocationApi(payload);
			setIsLoading(false)
			if (response.success) {
				setRegions(response.data);
			}
				}


		} catch (error) {
			console.error('Error fetching regions:', error);
			setIsLoading(false)
		}
	};

	// Fetch Tags Function retreives the Tags for the Specifed Region
	const fetchTags = async (region) => {
			try {

				if( selectedProvider.providerName.toLowerCase() == "azure" ||selectedProvider.providerName =="AZURE" ){
					const payload = {
						userId,
						organizationId: orgId,
						connectorId: selectedConnector.connectorId,
						location: region,
						providerType: selectedProvider.providerId,
						tags: selectedTags,
						providerName: selectedProvider.providerName
					};
					setIsLoading(true)
					console.log(payload, "Tags Payload");
					const response = await fetchTagsAzureApi(payload);
					setIsLoading(false)
					console.log(response, "Tags Response");
					if (response.success) {
						const filteredTags = response.data.filter(item => item.Key != null);
    
						// Set the filtered tags to state
						setTags(filteredTags);
					}					
				}else{
					const payload = {
						userId,
						organizationId: orgId,
						connectorId: selectedConnector.connectorId,
						region: region,
						providerType: selectedProvider.providerId,
						tags: selectedTags,
						providerName: selectedProvider.providerName
					};
					console.log(payload, "Tags Payload");
					setIsLoading(true)
					const response = await get_all_tags_b2a_Api(payload);
					setIsLoading(false)
					console.log(response, "Tags Response");
					if (response.success) {
						const filteredTags = response.data.filter(item => item.Key != null);
    
						// Set the filtered tags to state
						setTags(filteredTags);					}
				}
			} catch (error) {
				console.error("Error fetching tags:", error);
				setIsLoading(false)
			}
			};
	useEffect(()=>{
		if(selectedProvider != null){
			fetchConnectors(selectedProvider.providerId);
		}
		},[selectedProvider])
		useEffect(()=>{
			if(selectedConnector!= null){
				fetchRegions(selectedConnector.connectorId);
					// setCloudProviders([])
					// setSelectedRegion([])
					// setTags([])


			}
		},[selectedConnector])

		useEffect(()=>{
			if(selectedRegion!=null && selectedRegion != ""){
				fetchTags(selectedRegion.name);
			}
		},[selectedRegion])

	// When User Changes the Providers handleProviderChange Funtion is Triggered 
	const handleProviderChange = (provider) => {
		console.log(provider , "Provider Cheoses")
		setSelectedProvider(provider);
		setSelectedConnector(null); // Reset connector selection
    setSelectedRegion(null); // Reset region selection


		toggleDropdown('provider');
	};
	// When User Changes the Connector handleConnectorChange Function Is Triggered 
	const handleConnectorChange = (connector) => {
		setSelectedConnector(connector);
		setSelectedRegion(null);
		// resetSelections(['region', 'tags']);

		toggleDropdown('connector');
	};
	// When user Chagnes the Regions  handleRegionChange Function is Triggered 
	const handleRegionChange = (region) => {
		console.log(region , "Regionsss")
		setSelectedRegion(region);
		setSelectedTags([]);
		fetchTags(region.name);
		toggleDropdown('region');
	};
	// When User Changes the Tags handleTagChange Function Is Triggered 
	const handleTagChange = (selected) => {
		setSelectedTags([selected]);
		toggleDropdown('tag'); // This will close the dropdown
	};


	const toggleDropdown = (dropdown) => {
        setIsDropdownOpen((prev) => {
            // Close all dropdowns
            const allClosed = Object.keys(prev).reduce((acc, key) => {
                acc[key] = false;
                return acc;
            }, {});

            // Open only the clicked dropdown
            return {
                ...allClosed,
                [dropdown]: !prev[dropdown]
            };
        });
    };



	// When user Clicks Submit / Create B2A Button to Submit the Form handleSubmit Function is Triggered 
	const handleSubmit = async () => {
		  if (!selectedProvider || !selectedConnector || !selectedRegion) {
			alert('Please fill all required fields');
			return;
		  }
		  // Payload for for the Form Submit
		  const payload = {
			userId,
			connectorId: selectedConnector.connectorId || '',
			organizationId: orgId,
			cloudproviderId: selectedProvider.providerId,
			region: selectedRegion.name,
			tags: selectedTags.length > 0 ? selectedTags : [], // Allow empty array for tags
			b2a_connector_id: b2aConnectorId,
			editMode: isEditMode,
		  };
		
		  try {
			console.log(payload, "Payload")
			const response = await saveb2Api(payload);
			if (response.success) {
			  navigate('/B2AGrid', { 
				cloudproviderId: selectedProvider.providerId, 
				providerName: selectedProvider.providerName,
			  });
			} else {
			  console.log("Invalid")
			}
		  } catch (error) {
			console.error('Error submitting form:', error);
			alert('Error submitting form');
		  }
	};
	const isSubmitEnabled =
	selectedProvider &&
	selectedConnector &&
	selectedRegion &&
	selectedTags.length >= 0; 


	return (
		<>
	<div class="container-fluid">
            <div class="dashboard-container ps-0">
        
        <div class="row">
        <SideBar/>
	<div className="col-md-9 px-4 pt-5 right-container-scroll">
		
			<div className="row px-3">
				<div className="col-md-12">
					<div className="d-flex align-items-center gap-3 mb-4 pb-2">
						<button
							type="button"
							className="button secondary-outline-button py-1"
							onClick={() => {
								console.log(selectedProvider , "selectedProviderselectedProviderin back")
								navigate('/B2AGrid', {
								state: {
									providerName: selectedProvider.providerName,
									cloudproviderId: selectedProvider.providerId,

								}
								})}}
						>
							Back to B2A
						</button>
						<p className="font-20 font-bold black-v2 mb-0">{isEditMode ? 'Edit' : 'Add'} B2A</p>
					</div>
				</div>
				{isLoading
						 ? <Loader/> :
						 <>
			<div className="col-md-12">
			<div className="col-md-12">
    <div className="row">
        <FormDropdown
            label="Cloud Provider"
            isOpen={isDropdownOpen.provider}
            options={cloudProviders}
            selected={selectedProvider}
            onSelect={handleProviderChange}
            toggleDropdown={() => toggleDropdown('provider')}
            keyProp="providerId"
            labelKey="providerName"
            icon={`images/${selectedProvider?.providerName?.toLowerCase()}-logo-coloured.svg`}
            dropdownHeight="301px" // Set height for Cloud Provider dropdown
        />

        <FormDropdown
            label="Connector"
            isOpen={isDropdownOpen.connector}
            options={connectors}
            selected={selectedConnector}
            onSelect={(connector) => {
                handleConnectorChange(connector); // Call to handle selection and close dropdown
                setSelectedRegion(null); // Reset region selection when changing connector
            }}
            toggleDropdown={() => toggleDropdown('connector')}
            disabled={!selectedProvider}
            keyProp="connectorId"
            labelKey="connectorName"
        />

        <FormDropdown
            label={selectedProvider?.label === 'Azure' ? 'Location' : 'Region'}
            isOpen={isDropdownOpen.region}
            options={regions}
            selected={selectedRegion} // Ensure this state is set correctly
            onSelect={handleRegionChange}
            toggleDropdown={() => toggleDropdown('region')}
            disabled={!selectedConnector} // Disable if no connector is selected
            keyProp="name"
            labelKey="displayName"
            dropdownHeight="310px" // Set height for Region dropdown
        />

        <FormDropdown
            label="Tags"
            isOpen={isDropdownOpen.tag}
            options={tags}
            selected={selectedTags[0]}
            onSelect={(tag) => {
                handleTagChange(tag); // Call to handle selection and close dropdown
                toggleDropdown(); // Close dropdown after selection
            }}
            toggleDropdown={() => toggleDropdown('tag')}
            disabled={!selectedRegion} // Disable if no region is selected
            keyProp="Key"
            labelKey="Value"
            isRequired={false} // Set this to false to make Tags optional
        />
    </div>
</div>
</div>

							<div className="col-md-12 text-end mb-5">
								<button
									type="button"
									className="btn button primary-button py-3 px-5 lh-1"
									onClick={handleSubmit}
									disabled={!isSubmitEnabled} 

								>
									{isEditMode ? 'Update' : 'Create'} B2A
								</button>
							</div>
						</>
				}

			</div>
		
	</div>
	</div>
	</div>
	</div>
		</>
	);
}



//Dynamic Render of Fields Performed Here
const FormDropdown = ({
	label,
	isOpen,
	options,
	selected,
	onSelect,
	toggleDropdown,
	disabled,
	keyProp,
	labelKey,
	icon,
	isRequired = true, // Default value for required
	dropdownHeight = 'auto', // New prop for dropdown height
  }) => (
	<div className="col-md-4 mb-4 px-3 pb-3">
	  <label className="form-label font-14 font-medium black-v2">
		{label}
		{isRequired && <span className="required">*</span>}
	  </label>
	  <div className="dropdown font-14 font-medium black-v2" style={{ position: 'relative' }}>
	  <button
  type="button"
  className="btn cust-dropdown 
  font-14 font-medium black-v2
  d-flex justify-content-between align-items-center gap-3 cust-select-btn 
  w-100"
  onClick={toggleDropdown}
  aria-expanded={isOpen}
  disabled={disabled}
>
  {console.log('Selected:', selected)} {/* Debugging log */}
  {console.log('Icon:', icon)} {	/* Debugging log */}
  
  {selected && icon ? (
    <>
	<span>
      <img src={`images/${selected.providerName.toLowerCase()}-logo-coloured.svg`} alt={`${label}-icon`} className="me-2 page-icon-width" />
      {selected[labelKey]} {/* Display selected label */}
	  </span>

    </>
  ) : selected ? (
    label === "Tags" ? `${selected.Key}: ${selected.Value}` : selected[labelKey] // Handle tags specifically
  ) : (
    `Select ${label}` // Placeholder text when nothing is selected
  )}
  <img src="images/down-arrow-grey.svg" alt="down-arrow-grey" />
	  </button>
		{isOpen && (
		  <ul 
			className={`dropdown-menu body-bg cust-drop-menu px-2 py-1 w-100 show  
				font-14 font-medium black-v2 ${label === "Cloud Provider" ?"custom-nav-scrl2":"custom-nav-scrl"}`}
				style={{ 
					maxHeight: dropdownHeight, // Set maximum height from prop
					overflowY: 'auto', // Enable vertical scrolling
					// backgroundColor: 'inherit' // Inherit parent element's background color
				  }}
		  >

			{options.map((option, index) => (
			  <li key={option[keyProp] || index} className="font-14 font-medium black-v2">
				<button
				  type="button"
				  className="d-flex align-items-center custom-drop-item-2 text-decoration-none font-14 font-medium black-v2 mb-1 gap-3 w-100 bg-transparent border-0"
				  onClick={() => onSelect(option)}
				>
				  {label === "Cloud Provider" && (
					<img 
					  src={`images/${option.providerName.toLowerCase()}-logo-coloured.svg`} 
					  alt={`${option.providerName}-icon`} 
					  className="me-1 page-icon-width" 
					/>
				  )}
				  {label === "Tags" ? `${option.Key}: ${option.Value}` : option[labelKey]}
				</button>
			  </li>
			))}
		  </ul>
		)}
	  </div>
	</div>
  );
  FormDropdown.propTypes = {
	label: PropTypes.string.isRequired,
	isOpen: PropTypes.bool.isRequired,
	options: PropTypes.array.isRequired,
	selected: PropTypes.object,
	onSelect: PropTypes.func.isRequired,
	toggleDropdown: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	keyProp: PropTypes.string.isRequired,
	labelKey: PropTypes.string.isRequired,
	dropdownHeight: PropTypes.string
  };
  
// Define defaultProps for non-required props
FormDropdown.defaultProps = {
	selected: null,
	disabled: false,
	icon: false,
	isRequired: true,
	dropdownHeight: 'auto', // fallback value for dropdownHeight
  };
  
export default B2AformComponent;
