import { useNavigate } from "react-router-dom";

export default function AddServiceTitle(){
  
    const navigate = useNavigate()

    return (
        <div className="d-block mt-4 pt-2">
          <div className="d-flex align-items-center mb-3 mb-md-0 ">
            <a onClick={()=>{
               navigate('/SelfService');
            }}>
              <img
                src="images/back-arrow.svg"
                alt="back-arrow"
                title="Back"
                className="icon-filter"
              />
            </a>
            <h2 className="font-20 font-bold black-v2 mb-0 ms-3 ">Add New Service</h2>
          </div>
        </div>
    )
}