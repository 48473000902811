
//PS_02 - Import statement
import { useContext, useEffect } from "react";
import { ModulesContext, useModuleContext } from "../../../ContextProvider/ModulesContxt";
import ModulesCards from "./ModuleCards";
import ImportModulesPopup from "./ImportModulesPopup";
import { deleteModuleById, fetchAllModule, fetchCategoryData } from "../../../Service/api";

//PS_03-PS_05 : Define the component with props
function Modularization(
  categoryData, setCategoryData,
  selectedCategory, setSelectedCategory,
  selectedProviderName,
  getAllModules,
) {

  const {
    userId,
    modules,
    setSelectedModule,
    selectedProviderId,
    setReload
  } = useModuleContext()


  console.log(selectedProviderId,"selectedProviderId consoliinggg")
  console.log(selectedCategory,"selectedCategory in module")


  //PS_52-PS_62 - Handles the delete functionality
  const handleDelete = async (moduleId) => {
    try {
        const payload = {
            userId: userId,
            moduleId: moduleId
        }

        const response = await deleteModuleById(payload);
        console.log(response,"resp")
        if (response.status == 200) {
           // setPopUpMsg({ ...popupMsg, ["Msg"]: "Service deleted successfully", ["show"]: true, ["success"]: true });
            // setShowSuccessToast(true);
            // setTimeout(() => setShowSuccessToast(false), 2000);
           
            setReload(true)
        } else {
            throw new Error('Error deleting service');
        }
    } catch (err) {
        // setError(err);
        // setPopUpMsg({ ...popupMsg, ["Msg"]: "Service deletion failed", ["show"]: true, ["success"]: false });
    } finally {
        // setDeletePopup(false);
        // setServiceIdToDelete(null);
    }
    
};


    useEffect(() => {
      console.log(' getAllModules function: in modularization', getAllModules);
  }, [getAllModules]);


  return (
    <>
      <div className="row">
        <div className="notification-tab-parent">

          <div className="notification-tab-content mt-4">
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="module-tab-pane"
                role="tabpanel"
                aria-labelledby="all-tab"
                tabIndex={0}
              >
                {/* {modules && renderCategories(modules)} */}
                <ModulesCards
                  modules={modules}
                  setSelectedModule={setSelectedModule}
                  selectedProviderId={selectedProviderId}
                  selectedProviderName={selectedProviderName}
                  categoryData={categoryData}
                  setCategoryData={setCategoryData}
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                  handleDelete ={handleDelete}


                />

                {/* {modulesData.map((module) => (
                  <ModulesCards key={module.moduleId} module={module} onEditClick={handleEditClick} />
                ))}

                {selectedModule && (
                  <ImportModulesPopup module={selectedModule} />
                )} */}

                

              </div>

              <div
                className="tab-pane fade"
                id="services-tab-pane"
                role="tabpanel"
                aria-labelledby="azure-tab"
                tabIndex={0}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Modularization