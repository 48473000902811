
import React, { useRef, useState, useEffect } from 'react';
import { insertCommitAPI } from '../../../../../Service/api';
import { useOrgContext } from '../../../../../ContextProvider/OrgContext';
  
     
export default function CommitPopup(

	{
    isCommitModalOpen,
	setIsCommitModalOpen,
	nodes,
  	edges,
    setIsToastPopUpOpen,
    popupMsg,
    setPopUpMsg


}) {
  try {
    
  } catch (error) {
    
  }
	const { orgId,userId,envId,workloadId,providerId} = useOrgContext();

	const [commitMessage, setCommitMessage] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState('');
  	const modalRef = useRef(null);
  // useEffect(() => {
  //   let modalInstance = null;
  //   if (modalRef.current && window.bootstrap) {
  //     modalInstance = new window.bootstrap.Modal(modalRef.current, {
  //       backdrop: 'static',
  //       keyboard: false
  //     });
  //   }

  //   if (isCommitModalOpen) {
  //     modalInstance?.show();
  //   } else {
  //     modalInstance?.hide();
  //   }

  //   return () => {
  //     modalInstance?.dispose();
  //   };
  // }, [isCommitModalOpen]);

	// PS_103-  PS_105             
	/**
	 * Triggered During the Save Button Click 
	 * 
	 */           
	const handleSave = async () => {
		if (!commitMessage.trim()) {
			setError('Please enter a commit message');
			return;
		}

		setIsLoading(true);
		setError('');

		try {
			// Replace 'YOUR_API_ENDPOINT' with your actual API endpoint
      let nodeData = {
        nodes: nodes,
        edges: edges,
      };
		let payload = {
			"envId":envId,
			"Commits":commitMessage,
			"Datas":JSON.stringify(nodeData),
			"ModifyBy":userId != "" ? userId : localStorage.getItem('userId')
 		}
			// PS_46 ,  PS_104   Insert commit APi
			let res = await insertCommitAPI(payload)
			console.log(res , "From InsertCommit API")
			if (res.success) {
			// setIsToastPopUpOpen(true)
			// setToastMessage({...commitMessage ,["success"]:""})
			setPopUpMsg({...popupMsg , ["Msg"]:"Commit Successfull" , ["show"]:true ,["success"]:true })

			setIsCommitModalOpen(false);
			} else {
			setPopUpMsg({...popupMsg , ["Msg"]:"Error on Commit" , ["show"]:true ,["success"]:false })
				console.log('Failed to commit changes');
			}
		} catch (err) {
			console.log(err , "Error on Commit ")
			setPopUpMsg({...popupMsg , ["Msg"]:"Error on Commit" , ["show"]:true ,["success"]:false })
		} finally {
			setIsLoading(false);
		}
		setIsCommitModalOpen(false)
		};

		return (
			<div className="modal fade show"  ref={modalRef} id="staticBackdrop-7" style={{display: isCommitModalOpen ?'block' :"none", backgroundColor: 'rgba(0,0,0,0.5)'}} 
				data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
				aria-labelledby="staticBackdropLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered justify-content-center">
					<div className="modal-content border-0">
						<div className="modal-header border-0 py-4 px-32">
							<h1 className="modal-title font-20 font-bold black-v2" id="staticBackdropLabel">Commit message</h1>
							<button type="button" className="btn shadow-none" onClick={() => setIsCommitModalOpen(false)}>
								<img src="images/close-icon.svg" alt="close-icon" className="icon-hover shadow-none modal-close-filter" />
							</button>
						</div>
						<div className="modal-body px-32 pt-0">
							<label className="form-label font-14 font-medium black-v2" htmlFor="Status-10">Enter commit message</label>
							<input 
								type="text" 
								className="form-control custom-dropdown black-v2" 
								id="Status-10"
								placeholder="Enter commit message"
								value={commitMessage}
								onChange={(e) => setCommitMessage(e.target.value)}
							/>
							{error && <div className="text-danger mt-2">{error}</div>}
							<div className="d-flex justify-content-end align-items-center mt-4 mb-2 gap-3">
								<button type="button" className="button outline-button text-nowrap py-12" onClick={() => setIsCommitModalOpen(false)}>Cancel</button>
								<button 
									type="button" 
									className="button primary-button text-nowrap py-12" 
									onClick={handleSave}
									disabled={isLoading || nodes.length==0}
								>
									{isLoading ? 'Saving...' : 'Save'}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
}
