//PS_02
import React, { useState, useEffect ,useContext} from 'react';
//PS_04
import Pagination from '../../../CostManagement/Pagination';
import AddLocalPopup from './Add_localPopup';
import DeleteConfirmationModal from '../../Delete_Popup';
import EditOutputPopup from './Edit_Popup';
//PS_03
import { getLocalsAndOutputsApi,addUpdateDeleteLocalsApi,updateAndDeleteOutputsApi} from '../../../../Service/api';
import { OrgContext } from '../../../../ContextProvider/OrgContext';
const LocalsOutputsComponent = ({activeTabs , setIACModelOpen, viewMode}) => {
  // PS_05
const [localsData, setLocalsData] = useState([]);
    const [outputsData, setOutputsData] = useState([]);
  const [activeTab, setActiveTab] = useState( activeTabs != "" ?activeTabs : 'locals');

  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(5); // Adjust as needed
  const [searchTerm, setSearchTerm] = useState('');

  // State variables for managing modal
  const [isAddPopupOpen, setIsAddPopupOpen] = useState(false);
  const [selectedLocal, setSelectedLocal] = useState(null);


  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [localToDelete, setLocalToDelete] = useState(null);

  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [selectedOutput, setSelectedOutput] = useState(null);
  
      const {
          userRoles,
          orgId,
          providerId,
          architectures,
          canCreateWorkload,
          canDeleteWorkload,
          canViewWorkload,
          setuserRoles,
          setRepository_id,setrepository_name,setPulseIdentification,
  
          setworkloadId,setEnvName,setUTI,
  
          // Add organization and provider fields here
          providerName,       
          orgData, isProjectAdmin, setIsProjectAdmin,          
          setOrgData,        
          setArchitectures,
      
          envId,             // Environment ID
          workloadId,        // Workload ID
          aiArchitecture,    
          envName,           
          language,          
          archType,          
          business,          
          rulebook,          
          archDesc,          
          services,
      
          lastBuildID,       
          lastBuildDate,     
          lastBuildMessage,
      
          repoId,            
          repoName,
      
          PulseOrganization_id,
          PulseVertical_id,
          PulseVertical_name,
          PulseOrganization_name,
          PulseIdentification,            setproviderId,setProviderName
  ,
      
          setEnvId           // Function to update environment ID
      } = useContext(OrgContext);
      const [userId, setuserId] = useState(localStorage.getItem('userId'));

//PS_06
  useEffect(() => {
    fetchLocalsData();
  }, []);
//PS_07
  const fetchLocalsData = async () => {
    setLoading(true);
    const payload = {
      organizationId: orgId, // Replace with dynamic value if needed
      envId:envId, // Replace with dynamic value if needed
      userId: userId // Replace with dynamic value if needed
  };
  
  try {
      //PS_09 Call your API to get locals and outputs
      const response = await getLocalsAndOutputsApi(payload);
      console.log("successs",response);
//PS_17
      if (response.body.success) {
        console.log("successs");
        setLocalsData(response.body.data.locals);
        setOutputsData(response.body.data.outputs);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };
//PS_39
  const handleTabSwitch = (tab) => {
    setActiveTab(tab);
    setCurrentPage(1); // Reset to first page when switching tabs
  };
//PS_29
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset to first page on search
  };

  //PS_17
  const handleSaveLocal = async (localData) => {
    // This function will handle saving a new local or updating an existing one.
    
    console.log("Saving local data:", localData);

    const payload = {
      organizationId: orgId, // Replace with dynamic value if needed
      envId:envId, // Replace with dynamic value if needed
      userId: userId ,// Replace with dynamic value if needed
        localId: {
            id: selectedLocal ? selectedLocal.id :"", // Use existing ID or generate a new one
            name: localData.name,
            description: localData.description,
            enableStatus: localData.enableStatus,
            snippet: localData.snippet
        },
        isdelete: false // Set to true if you want to delete this local
    };

    try {
        if (selectedLocal) {
            // Update existing local logic here
            console.log("Updating local:", selectedLocal.id);
            const response = await addUpdateDeleteLocalsApi(payload);
            if (response.body.success) {
              fetchLocalsData();

                console.log("Local updated successfully");
            } else {
                console.error('Error updating local:', response.message);
            }
        } else {
            // Add new local logic here
            console.log("Adding new local");
            const response = await addUpdateDeleteLocalsApi(payload);
            if (response.body.success) {
              fetchLocalsData();
                console.log("New local added successfully");
            } else {
                console.error('Error adding new local:', response.message);
            }
        }
    } catch (error) {
        console.error('An error occurred while saving the local:', error);
    } finally {
        // Close the popup after saving
        setIsAddPopupOpen(false);
        setSelectedLocal(null); // Reset selected local after saving
    }
};
//PS_34
const handleEditClick = (local) => {
   setSelectedLocal(local); // Set the selected local for editing
   setIsAddPopupOpen(true); // Open the popup
};
//PS_08
const handleEditClick2 = (output) => {
    setSelectedOutput(output); // Set the selected output for editing
    setIsEditPopupOpen(true); // Open the popup
 };
 //PS_21
 const handleUpdateOutput = async (outputData) => {
  console.log("Updating output:", outputData.id);

  const payload = {
    organizationId: orgId, // Replace with dynamic value if needed
    envId:envId, // Replace with dynamic value if needed
    userId: userId ,// Replace with dynamic value if needed, // Replace with dynamic value if needed
      outputId: {
          id: outputData.id,              // Unique identifier for the output
          name: outputData.name,          // Name of the output
          description: outputData.description, // Description of the output
          sensitive: outputData.sensitive, // Boolean indicating if the output is sensitive
          snippet: outputData.snippet      // Snippet related to the output
      },
      isdelete: false                     // Set to true if you want to delete this output
  };

  try {
      // Call your API to update the output
      const response = await updateAndDeleteOutputsApi(payload);
      if (response.body.success) {
          // Update state with new output data.
        fetchLocalsData()
          console.log("Updated successfully");
      } else {
          console.error('Error updating output:', response.message);
          // Handle error response (e.g., show a notification)
      }
  } catch (error) {
      console.error('An error occurred while updating the output:', error);
      // Handle error response (e.g., show a notification)
  } finally {
      setIsEditPopupOpen(false); // Close modal after update attempt
      setSelectedOutput(null);    // Reset selected output for editing
  }
};

 //PS_50 
 //PS_32
const handleDeleteClick = (local) => {
    setLocalToDelete(local); // Set the local to be deleted
    setIsDeletePopupOpen(true); // Open delete confirmation modal
 };
 //PS_51
 //PS_36
 const handleDeleteConfirm = async () => {
  if (!localToDelete ) return; // Ensure at least one item is selected for deletion

  let payload;

  if (activeTab=="locals") {
      console.log("Deleting local:", localToDelete.id);
      payload = {
        organizationId: orgId, // Replace with dynamic value if needed
        envId:envId, // Replace with dynamic value if needed
        userId: userId ,// Replace with dynamic value if needed
          localId: {
              id: localToDelete.id,              // Unique identifier for the local
              name: localToDelete.name,          // Name of the local (optional for deletion)
              description: localToDelete.description, // Description of the local (optional for deletion)
              enableStatus: localToDelete.enableStatus, // Status indicating if the local is enabled (optional for deletion)
              snippet: localToDelete.snippet      // Snippet related to the local (optional for deletion)
          },
          isdelete: true                        // Set to true to indicate deletion
      };
  } else {
      console.log("Deleting output:", localToDelete.id);
      payload = {
        organizationId: orgId, // Replace with dynamic value if needed
        envId:envId, // Replace with dynamic value if needed
        userId: userId, // Replace with dynamic value if needed
          outputId: {
              id: localToDelete.id,              // Unique identifier for the output
              name: localToDelete.name,          // Name of the output (optional for deletion)
              description: localToDelete.description, // Description of the output (optional for deletion)
              sensitive: localToDelete.sensitive, // Boolean indicating if the output is sensitive (optional for deletion)
              snippet: localToDelete.snippet      // Snippet related to the output (optional for deletion)
          },
          isdelete: true                        // Set to true to indicate deletion
      };
  }
  
  try {
      let response;
      if (activeTab=="locals") {
          response = await addUpdateDeleteLocalsApi(payload); // Call API for deleting a local
      } else {
          response = await updateAndDeleteOutputsApi(payload); // Call API for deleting an output
      }

      if (response.body.success) {
          console.log(localToDelete ? "Local deleted successfully" : "Output deleted successfully");
          
        fetchLocalsData()
      } else {
          console.error('Error deleting item:', response.message);
          // Handle error response (e.g., show a notification)
      }
  } catch (error) {
      console.error('An error occurred while deleting:', error);
      // Handle error response (e.g., show a notification)
  } finally {
      setIsDeletePopupOpen(false);  // Close modal after deletion attempt
      setLocalToDelete(null);        // Reset selected local for deletion
  }
};



  // Filter data based on search term
  const filteredData = activeTab === 'locals'
    ? localsData.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()))
    : outputsData.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()));

  // Calculate pagination
  const totalItems = filteredData.length;
  
   return (
    <>
   <div className="container-fluid">
     <AddLocalPopup 
       isOpen={isAddPopupOpen} 
       onClose={() => {setIsAddPopupOpen(false); setSelectedLocal(null);}} 
       onSave={handleSaveLocal} 
       selectedLocal={selectedLocal} 
     />
      { isDeletePopupOpen&& <DeleteConfirmationModal 
       item={localToDelete} 
       itemType="local"
       onClose={() => setIsDeletePopupOpen(false)} 
       onDeleteConfirm={handleDeleteConfirm} 
       isOpen={isDeletePopupOpen} 
     />}
      <EditOutputPopup 
       isOpen={isEditPopupOpen} 
       onClose={() => {setIsEditPopupOpen(false);}} 
       onSave={handleUpdateOutput} 
       selectedOutput={selectedOutput} 
     />
     <div className="row">
       {/* Sidebar Menu Start */}
       <div className="col-md-3 custom-border-right custom-white ps-5 pe-1 pt-2">
         <div className="about-page-title pt-4">
           <div className="d-flex align-items-center mb-3 mb-md-0">
             <a href="#">
               <img src="images/back-arrow.svg" alt="back-arrow" title="Back" 
               className="me-3 icon-filter" 
               onClick={(()=>{
                setIACModelOpen(false)
               })}
               />
             </a>
             <h2 className="font-20 font-bold black-v2 mb-0">Back to Workbench</h2>
           </div>
         </div>
         <ul className="nav nav-pills flex-column mb-auto flex-nowrap nav-overflow mt-3 pt-2">
           <li className={`side-nav-item d-flex align-items-center ${activeTab === 'locals' ? 'active' : ''}`} onClick={() => handleTabSwitch('locals')}>
             <img src="images/locals-icon.svg" alt="system-icon" className="side-icon" />
             <span>Locals</span>
           </li>
           <li className={`side-nav-item d-flex align-items-center ${activeTab === 'outputs' ? 'active' : ''}`} onClick={() => handleTabSwitch('outputs')}>
             <img src="images/outputs-icon.svg" alt="outputs-icon" className="side-icon" />
             <span>Outputs</span>
           </li>
         </ul>
       </div>
       {/* Sidebar Menu End */}

       {/* Main Content Start */}
       <div className="col-md-9 ps-4">
         <div className="d-flex justify-content-between align-items-center mt-4 mb-3">
           <h2 className="mb-0 font-32 font-bold black-v2">{activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}</h2>
           <div className="d-flex gap-3">
             {/* Search */}
             <div className="input-group input-search custom-border-box custom-search-box">
               <input 
                 type="search"
                 className="px-3 form-control common-bg border-0 custom-form custom-search $gray-v1"
                 placeholder="Search"
                 value={searchTerm}
                 onChange={handleSearch}
               />
               <button className="btn border-start-0 custom-form custom-search" type="button">
                 <img src="images/search-icon.svg" alt="search" />
               </button>
             </div>
             {/* Add Button */}
             {activeTab === 'locals' && (
         <button type="button" className="button primary-button text-nowrap" 
         onClick={() => {setIsAddPopupOpen(true);}}
         disabled ={viewMode ? true : false}
         >
           <img src="images/add-icon.svg" alt="add-icon" className="me-2"/> Add Local
         </button>
       )}
           </div>
         </div>

         {/* Page Inner Content Start */}
         <div className="d-flex flex-column gap-3">
           {loading ? (
             <p>Loading...</p>
           ) : (
             filteredData.slice((currentPage - 1) * recordsPerPage, currentPage * recordsPerPage).map(item => (
               <div key={item.id} className="d-flex align-items-center border rounded-3 p-3 w-100">
                 <input type="checkbox" className="form-check-input custom-checkbox shadow-none mt-0"/>
                 <div className="ms-3">
                   <p className="font-16 font-medium black-v2 mb-2">{item.name}</p>
                   <p className="mb-0 font-14 font-regular pt-1 secondary-text-color-v4">{item.description}</p>
                 </div>
                 <div className="d-flex flex-nowrap gap-3 justify-content-center align-items-center ms-auto">
                 {activeTab === 'locals' ? (
   <button className="btn p-0 shadow-none"><img src="images/edit-icon-blue.svg" alt="edit-icon-blue" 
                             disabled ={viewMode ? true : false}
   
   onClick={()=>handleEditClick(item)}/></button>):(
    <button className="btn p-0 shadow-none"><img src="images/edit-icon-blue.svg" alt="edit-icon-blue" 
    disabled ={viewMode ? true : false}
    
    onClick={()=>handleEditClick2(item)}/></button>)}
                   <button className="btn p-0 shadow-none"
                             disabled ={viewMode ? true : false}

                   onClick={() => handleDeleteClick(item)}>
                     <img src="images/bin-icon-red.svg" alt="bin-icon-red"/>
                   </button>                 </div>
               </div>
             ))
           )}
           
           {/* Pagination Component */}
           <Pagination 
             currentPage={currentPage} 
             setCurrentPage={setCurrentPage} 
             totalItems={totalItems} 
             itemsPerPage={recordsPerPage} 
             displayedCount={filteredData.length} 
           />
         </div>
         {/* Page Inner Content End */}
       </div>

     </div>  
    </div>
    </>
   );
};

export default LocalsOutputsComponent;
