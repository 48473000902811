import React, { useContext, useEffect, useState ,useRef} from 'react';
import { useMsal } from "@azure/msal-react";
import { isAuth, loginRequest } from '../../authConfig';
import { useNavigate } from 'react-router-dom';
import { postLoginDetails } from '../../Service/api';
import VideoPlayer from "react-background-video-player";
import { useSwipeable } from 'react-swipeable';
import { useOrgContext } from '../../ContextProvider/OrgContext';


 

function Login({ isDarkMode, setIsDarkMode }) {

	const swipeAreaStyle = {
		touchAction: 'pan-y',
		userSelect: 'none',
		WebkitUserDrag: 'none',
		WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)'
	};

	// PS_02 - state variable declaration
	const { instance } = useMsal();
	const navigate = useNavigate()
	const [isLogin, setIsLogin] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false);
	const [photoBlob, setPhotoBlob] = useState(null);
	const[userID,setUserID]=useState("")
	const {setUserId} = useOrgContext()
    	const [swipeProgress, setSwipeProgress] = useState(0);
		const [buttonClass, setButtonClass] = useState('loginnew-btn btn-rect d-none');





	//PS_10- PS_11: Function to decode JWT without using a library
	function parseJwt(token) {
		const base64Url = token.split('.')[1];
		const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
		const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
			return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
		}).join(''));

		return JSON.parse(jsonPayload);
	}

	//PS_19-PS_22: Function to fetch profile photo
	const getProfilePhoto = async (userId, account) => {
		try {
			const request = {
				...loginRequest,
				account: account
			};

			const tokenResponse = await instance.acquireTokenSilent(request);
			const response = await fetch(`https://graph.microsoft.com/v1.0/users/${userId}/photo/$value`, {
				headers: {
					Authorization: `Bearer ${tokenResponse.accessToken}`
				}
			});

			if (response.status === 404) {
				console.log('User does not have a profile photo');
				return null;
			}

			if (!response.ok) {
				throw new Error(`Network response was not ok: ${response.status}`);
			}

			const photoBlob = await response.blob();
			return photoBlob;
		} catch (err) {
			console.error('Error fetching profile photo:', err);
			return null;
		}
	};


	async function fetchProfileImageAsBase64(accessToken) {
		const endpoint = "https://graph.microsoft.com/v1.0/me/photo/$value";

		const response = await fetch(endpoint, {
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		});

		if (response.ok) {
			const blob = await response.blob();
			return await convertBlobToBase64(blob);
		} else {
			console.error("Failed to fetch profile image:", response.status, response.statusText);
			return null;
		}
	}

	function convertBlobToBase64(blob) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onloadend = () => resolve(reader.result.split(",")[1]); // Remove prefix to get pure Base64
			reader.onerror = reject;
			reader.readAsDataURL(blob); // Convert blob to data URL
		});
	}


	console.log(instance,"instanceee");
	

	//PS_05 -PS_09: Function for Login by hitting microsoft api and post login details
	const handleLogin = async () => {
		console.log("triggered")
		try {
			if (instance?.getActiveAccount()) {
				console.log("User is already logged in");

				const tokenResponse = await instance?.acquireTokenSilent(loginRequest);

				localStorage.setItem('userImage',await fetchProfileImageAsBase64(tokenResponse?.accessToken))


				navigate('/manage-organizations');
				return;
			}
			console.log("loginRequestloginRequest",loginRequest);
			
			const loginResponse = await instance.loginPopup(loginRequest);
			console.log(loginResponse, "login response");



			setIsLogin(true);
			instance.setActiveAccount(loginResponse.account);

			const tokenResponse = await instance.acquireTokenSilent(loginRequest);

			localStorage.setItem('userImage',await fetchProfileImageAsBase64(tokenResponse?.accessToken))



			// const isAdmin = await checkAdminStatus(loginResponse.account);

			console.log(loginResponse.account.name, "name")
			console.log(loginResponse.account.username, "UserName")

			const jwtResponse = await postLoginDetails({
				username: loginResponse.account.name,
				email: loginResponse.account.username,
			});

			console.log(jwtResponse,"jwtResponse")
			if(jwtResponse.data.body.statusCode !== '200' ){
				navigate('/Login')
			}
			// Access the JWT token from the correct location in the response
			const jwtToken = jwtResponse.data.body.data[0].jwtToken;
			const connectionId = jwtResponse.data.body.data[0].connectionId
			const isadmin = jwtResponse.data.body.data[0].isAdmin;
			const isDarkTheme = jwtResponse.data.body.data[0].isDarkTheme;

			if (!jwtToken) {
				throw new Error('JWT token is empty');
			}

			// Set the token in localStorage
			localStorage.setItem('jwt', jwtToken);
			localStorage.setItem('connectionId', connectionId)
			localStorage.setItem('isAdmin', isadmin);
			localStorage.setItem('isDarkTheme', isDarkTheme)
			localStorage.setItem('darkTheme', isDarkTheme)

			localStorage.setItem('userName', loginResponse.account.name)

			setIsDarkMode(isDarkTheme)
			// Decode the JWT token
			const decodedToken = parseJwt(jwtToken);


			// Extract the user ID from the decoded token
			const userId = decodedToken.user_id;


			setUserID(userId)
			// Org Context Set state Happened Here 
			setUserId(userId)


			// Store the user ID in localStorage
			localStorage.setItem('userId', userId);
			localStorage.setItem('emailId',loginResponse.account.username)


			// Fetch profile photo after successful login
			const photo = await getProfilePhoto(userId, loginResponse.account);
			if (photo) {
				setPhotoBlob(photo);
			} else {
				console.log('No profile photo available, using default');
				// You might want to set a default photo here
				// setPhotoBlob(defaultPhotoBlob);
			}

			if (isAdmin) {
				setTimeout(() => {
					navigate('/admin-dashboard');
				}, 2000);

			} else {
				setTimeout(() => {
					navigate('/manage-organizations');
				}, 2000);

			}

		} catch (error) {
			// setTimeout(() => {
			// 	console.error("Login failed", error);

			// 	const closeButton = document.getElementById('checkbox2');
			// 	if (closeButton) {
			// 	  closeButton.click();
			// 	}
			//   }, );
			console.error("Login failed", error);
			localStorage.clear()
			sessionStorage.clear()
			navigate('/') 
		}
	};


	const [touchStart, setTouchStart] = useState(null);
	const [touchEnd, setTouchEnd] = useState(null);

	const minSwipeDistance = 50;

	const onTouchStart = (e) => {
		setTouchEnd(null);
		setTouchStart(e.targetTouches[0].clientX);
	}

	const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

	const onTouchEnd = () => {
		if (!touchStart || !touchEnd) return;
		const distance = touchStart - touchEnd;
		const isRightSwipe = distance < -minSwipeDistance;
		if (isRightSwipe) {
			handleLogin();
		}
	}

	useEffect(() => {
		const timer = setTimeout(() => {
		  setButtonClass('loginnew-btn btn-rect block');
		}, 3000); // 3000 milliseconds = 3 seconds
	
		return () => clearTimeout(timer);
	  }, []);


	//   const videoRef = useRef(null);
	//   useEffect(() => {
    //     const videoElement = videoRef.current;

    //     const handleVisibilityChange = () => {
    //         if (document.hidden) {
    //             videoElement.pause();
    //         } else {
    //             if (videoElement && videoElement.readyState >= 3) { // check if the video is ready
    //                 const playPromise = videoElement.play();
    //                 if (playPromise !== undefined) {
    //                     playPromise.catch(error => {
    //                         console.log('Unable to play the video:', error);
    //                     });
    //                 }
    //             }
    //         }
    //     };

    //     document.addEventListener('visibilitychange', handleVisibilityChange);

    //     // Cleanup function to ensure play() is not called if the component is unmounted
    //     return () => {
    //         document.removeEventListener('visibilitychange', handleVisibilityChange);
    //         if (videoElement) {
    //             videoElement.pause();
    //         }
    //     };
    // }, []);

    // const triggerVideoPlay = () => {
    //     const videoElement = videoRef.current;
    //     if (videoElement) {
    //         videoElement.muted = false;
    //         const playPromise = videoElement.play();
    //         if (playPromise !== undefined) {
    //             playPromise.catch(error => {
    //                 console.log('Video play interrupted:', error);
    //             });
    //         }
    //     }
    // };

	//PS_04 - Button for Login
	return (
		<>

			<div className="login-container">

				{/* <video autoPlay={true} muted="" id="background-video" className="video-container">
					<source src="video/cloudgen-login-video.mp4" type="video/mp4" />
				</video> */}
					<VideoPlayer
					id="background-video" className="video-container"
					src={
						"video/cloudgen-login-video.mp4"
					}
					autoPlay={true}
					muted={true}
					loop={false}
				/>


			</div>

			
			{/* <div className="login-content">
				<div {...swipeHandlers} className="swipe-button-container">
					<button type="button" className="btn login-btn font-16 font-medium"
                    style={{lineHeight:3}}
                    >
						<span className="arrow-cont" 
                          style={{
                            transform: `translateX(${swipeProgress}%)`,
                            padding: '6px 20px'
                        }
                     
                    }>
							<img
								src="images/right-arrow-pink.svg"
								alt="right-arrow"
								className="insights-icon-width"
							/>
						</span>
						Explore CloudGen
					</button>
					<div className="swipe-progress" style={{width: `${swipeProgress}%`}}></div>
					</div>
			</div> */}

			

<div className="toggle-button-container">
  <div className="toggle-button gd">
    <div className ={buttonClass} id="button-10" >
      <span className="font-16 font-medium login-text">Explore CloudGen</span>
      <input 
        type="checkbox" 
		id="checkbox2"
        className="checkbox" 
        onClick={() => {
          setTimeout(() => {
            // Trigger the desired function after 2 seconds
			handleLogin(); // Call handleLogin immediately
			// triggerVideoPlay();
		}, 600); // 2000 milliseconds = 2 seconds
        }}
      />
      <div className="knob">
        {/* <span>YES</span> */}
      </div>
      <div className="btn-bg"></div>
    </div>
  </div>
</div>
  

		</>


	)
}



export default Login;
