//Importing the required paxkages
import React, { useContext, useState, useEffect } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import { getIACCoverage, getAccountCount } from '../../Service/api';
import { OrgContext } from '../../ContextProvider/OrgContext';
import { DashboardContext } from './Context/DashboardContext';
//PS_01 Defining the col0rs
const COLORS = ['#f48fb1', '#64b5f6'];
const COVERAGE_COLORS = ['#e6ee9c', '#80deea', '#e1bee7'];
//PS_02 Declare a function 
const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip" style={{ backgroundColor: 'white', padding: '5px', border: '1px solid #cccccc' }}>
        <p style={{ margin: 0 }}>{`${payload[0].name}: ${payload[0].value}`}</p>
      </div>
    );
  }
  return null;
};
//PS_03 to PS_05 Declare a component 
const AssetIACoverage = () => {
//Getting organization id from context
  const { orgId } = useContext(OrgContext);
//Getting userId from local storage
  const userId = localStorage.getItem('userId');
//Declaring state variables  
  const [iacData, setIacData] = useState(null);
  const [assetData, setAssetData] = useState(null);
  const [error, setError] = useState(null);
//PS_06 Get data from context
  const { iac, setIac, assets, setAssets } = useContext(DashboardContext);
//PS_07 to PS_19 Use effect for triggering data
  useEffect(() => {
    //Declare a function named fetch data
    const fetchData = async () => {
      //Setting the payload
      const payload = { userId, orgId: orgId };
      try {
        //Calling api
        const iacResponse = await getIACCoverage(payload);
        if (iacResponse.data && iacResponse.data.body && iacResponse.data.body.success) {
          //Updating the variable
          setIacData(iacResponse.data.body.data[0]);
          let responseData = iacResponse.data.body.data[0];
          let iacDataForExport = {
            "Codified": responseData?.codified,
            "Regeneration": responseData?.regenerate,
            "Drifts": responseData?.drifts
          };
          setIac(iacDataForExport);
        } else {
          //Catch the error
          console.log("Unexpected IaC coverage response structure:", iacResponse);
        }
        //Calling api for services data
        const assetResponse = await getAccountCount(payload);
        if (assetResponse.data && assetResponse.data.body && assetResponse.data.body.success) {
          //Updating the variable
          setAssetData(assetResponse.data.body.data);
          let responseData = assetResponse.data.body.data;
          let assetDatas = {
            Total_Service_Count: responseData?.total_services,
            AWS: responseData?.aws_services,
            Azure: responseData?.azure_services 
          };
          setAssets(assetDatas);
        } else {
          console.log("Unexpected asset count response structure:", assetResponse);
        }
        //Catch error if any
      } catch (err) {
        setError(err.message);
        console.log('Error fetching data:', err);
      }
    };
    //Calling fetchData 
    fetchData();
  }, [userId, orgId]);
// PS_20Prepare the iac data
  const iacCoverageData = iacData ? [
    { name: 'Codified', value: parseFloat(iacData.codified) },
    { name: 'Regeneration', value: parseFloat(iacData.regenerate) },
    { name: 'Drifts', value: parseFloat(iacData.drifts) },
  ] : [];
//PS_21 Prepare asset data
  const assetPieData = assetData ? [
    { name: 'AWS', value: assetData.aws_services },
    { name: 'Azure', value: assetData.azure_services },
  ] : [];
//PS_22 Calculate the total value
  const totalServices = assetData ? assetData.total_services : 0;
//PS_23 Defining styles for no data found
  const noDataStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    margin: 0,
    fontSize: '14px',
    color: '#333',
  };

  return (
    <div className="col-md-12">
      <div className="row">
        <div className="col-md-12 col-lg-6 mb-4 px-2">
          <div className="chart-container-3 px-3 py-4">
            <p className="font-14 font-medium black-v3 mb-4">#Assets</p>
            <div className="w-100 chart-container mb-4 d-flex justify-content-center">
              <ResponsiveContainer width="100%" height={230}>
                {assetPieData.length > 0 ? (
                  <PieChart>
                    <Pie
                      data={assetPieData}
                      dataKey="value"
                      nameKey="name"
                      cx="50%"
                      cy="50%"
                      innerRadius={60}
                      outerRadius={80}
                      fill="#8884d8"
                      paddingAngle={5}
                    >
                      {assetPieData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip content={<CustomTooltip />} />
                    <text x="50%" y="50%" textAnchor="middle" dominantBaseline="middle">
                      <tspan x="50%" dy="-0.5em" fontSize="14" fill="#333">Total Services</tspan>
                      <tspan x="50%" dy="1.5em" fontSize="18" fontWeight="bold" fill="#333">{totalServices}</tspan>
                    </text>
                  </PieChart>
                ) : (
                  <p style={noDataStyle}>No data found</p>
                )}
              </ResponsiveContainer>
            </div>
            <div className="mt-4 d-flex justify-content-center align-items-center gap-4">
              <p className="font-12 font-medium secondary-text-color mb-1 text-center">
                <span className="legend legend-pink me-1"></span>AWS
              </p>
              <p className="font-12 font-medium secondary-text-color mb-1 text-center">
                <span className="legend legend-violet me-1"></span>Azure
              </p>
            </div>
          </div>
        </div>

        <div className="col-md-12 col-lg-6 mb-4 px-2">
          <div className="chart-container-3 px-3 py-4">
            <p className="font-14 font-medium black-v3 mb-4">IaC Coverage</p>
            <div className="w-100 chart-container mb-4 d-flex justify-content-center">
              <ResponsiveContainer width="100%" height={230}>
                {iacCoverageData.length > 0 ? (
                  <PieChart>
                    <Pie
                      data={iacCoverageData}
                      dataKey="value"
                      nameKey="name"
                      cx="50%"
                      cy="50%"
                      innerRadius={60}
                      outerRadius={80}
                      fill="#8884d8"
                      paddingAngle={5}
                    >
                      {iacCoverageData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COVERAGE_COLORS[index % COVERAGE_COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip content={<CustomTooltip />} />
                  </PieChart>
                ) : (
                  <p style={noDataStyle}>No data found</p>
                )}
              </ResponsiveContainer>
            </div>
            <div className="mt-4 d-flex justify-content-center align-items-center gap-4">
              <p className="font-12 font-medium secondary-text-color mb-1 text-center">
                <span className="legend legend-success-light me-1"></span>Codified
              </p>
              <p className="font-12 font-medium secondary-text-color mb-1 text-center">
                <span className="legend legend-blue-light me-1"></span>Regeneration
              </p>
              <p className="font-12 font-medium secondary-text-color mb-1 text-center">
                <span className="legend legend-purple me-1"></span>Drifts
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetIACoverage;