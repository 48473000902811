import React, { useState, useEffect, useContext } from "react";
import { getPipelineData, getCommitData } from "../../Service/api";
import { OrgContext, useOrgContext } from "../../ContextProvider/OrgContext";
import { DashboardContext } from "./Context/DashboardContext";

const PipelineCommit = ({ isExport }) => {
  const {
    orgId,
    pipelineData,
    setPipelineData,
    commitData,
    setCommitData,
    filteredPipelineData,
    setFilteredPipelineData,
    filteredCommitData,
    setFilteredCommitData,
  } = useOrgContext();
  const userId = localStorage.getItem("userId");

  console.log(filteredCommitData,"commitData",filteredPipelineData,"filteredPipelineData")

  const [activeTab, setActiveTab] = useState("pipeline");
  const { pipelineExecutions, setPipelineExecutions, commits, setCommits } =
    useContext(DashboardContext);

  const [filterOptions, setFilterOptions] = useState({
    workload: "",
    repository: "",
    branch: "",
    status: "",
  });

  const [allFilterOptions, setAllFilterOptions] = useState({
    workload: [],
    repository: [],
    branch: [],
    status: [],
  });
  const sortAndLimitData = (data, dateField, limit = 5) => {
    return data
      .sort((a, b) => new Date(b[dateField]) - new Date(a[dateField]))
      .slice(0, limit);
  };

  useEffect(() => {
    console.log("Effect running with orgId:", orgId, "and userId:", userId);
    if (orgId && userId) {
      fetchInitialData();
    }
  }, [orgId, userId]);

  const [isLoading, setIsLoading] = useState(true);

  const fetchInitialData = async () => {
    try {
      const pipelineResponse = await getPipelineData({ orgId, userId });
      handlePipelineData(pipelineResponse);

      const commitResponse = await getCommitData({ orgId, userId });
      handleCommitData(commitResponse);

      // Update all filter options
      updateAllFilterOptions(pipelineResponse.data.flat(), commitResponse.data);
    } catch (error) {
      console.log("Error during data fetch:", error);
      setPipelineData([]);
      setFilteredPipelineData([]);
      setCommitData([]);
      setFilteredCommitData([]);
      setAllFilterOptions({
        workload: [],
        repository: [],
        branch: [],
        status: [],
      });
    }
  };

  const handlePipelineData = (response) => {
    if (response.success && response.data) {
      const flattenedPipelineData = response.data
        .flat()
        .filter((item) => typeof item === "object");

      const sortedData = sortAndLimitData(flattenedPipelineData, "date_time");
      setPipelineData(sortedData);
      setFilteredPipelineData(sortedData);

      // Set pipelineExecutions
      const formattedPipelineExecutions = sortedData.map((item) => ({
        Repository: item.repo_name,
        Branch: item.branch_name,
        Description: item.description || "",
        Status: item.status,
        Date_Time: item.date_time,
      }));
      setPipelineExecutions(formattedPipelineExecutions);
    } else {
      console.log(
        "Pipeline data fetch failed: No data or unsuccessful response"
      );
    }
  };

  const handleCommitData = (commitResponse) => {
    if (commitResponse.success && Array.isArray(commitResponse.data)) {
      console.log("Commit Data:", JSON.stringify(commitResponse.data, null, 2));
      const sortedData = sortAndLimitData(commitResponse.data, "dateTime");
      setCommitData(sortedData);
      setFilteredCommitData(sortedData);

      // Set commits
      const formattedCommits = sortedData.map((item) => ({
        Repository: item.repo,
        Branch: item.branch,
        Commit_Tag: item.commitTag,
        Date_Time: item.dateTime,
      }));
      setCommits(formattedCommits);
    } else {
      setCommitData([]);
      setFilteredCommitData([]);
      setCommits([]);
    }
  };

  const applyFilters = (data) => {
    return data.filter((item) => {
      if (activeTab === "pipeline") {
        return (
          (filterOptions.workload === "" ||
            item.workload_name === filterOptions.workload) &&
          (filterOptions.repository === "" ||
            item.repo_name === filterOptions.repository) &&
          (filterOptions.branch === "" ||
            item.branch_name === filterOptions.branch) &&
          (filterOptions.status === "" || item.status === filterOptions.status)
        );
      } else {
        // For commits
        return (
          (filterOptions.repository === "" ||
            item.repo === filterOptions.repository) &&
          (filterOptions.branch === "" || item.branch === filterOptions.branch)
        );
      }
    });
  };

  const handleFilterApply = () => {
    if (activeTab === "pipeline") {
      const filteredData = applyFilters(pipelineData);
      setFilteredPipelineData(sortAndLimitData(filteredData, "date_time"));
    } else {
      const filteredData = applyFilters(commitData);
      setFilteredCommitData(sortAndLimitData(filteredData, "dateTime"));
    }
  };

  const handleFilterClear = () => {
    if (activeTab === "pipeline") {
      setFilterOptions({
        workload: "",
        repository: "",
        branch: "",
        status: "",
      });
      setFilteredPipelineData(sortAndLimitData(pipelineData, "date_time"));
    } else {
      setFilterOptions({
        repository: "",
        branch: "",
      });
      setFilteredCommitData(sortAndLimitData(commitData, "dateTime"));
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    if (tab === "pipeline") {
      setFilteredPipelineData(sortAndLimitData(pipelineData, "date_time"));
    } else {
      setFilteredCommitData(sortAndLimitData(commitData, "dateTime"));
    }
  };

  const getFilterOptions = () => {
    if (activeTab === "pipeline") {
      return {
        workload: allFilterOptions.workload,
        repository: allFilterOptions.repository,
        branch: allFilterOptions.branch,
        status: allFilterOptions.status,
      };
    } else {
      return {
        repository: allFilterOptions.repository,
        branch: allFilterOptions.branch,
      };
    }
  };

  const updateAllFilterOptions = (pipelineData, commitData) => {
    const newAllFilterOptions = {
      workload: [...new Set(pipelineData.map((item) => item.workload_name))],
      repository: [
        ...new Set([
          ...pipelineData.map((item) => item.repo_name),
          ...commitData.map((item) => item.repo),
        ]),
      ],
      branch: [
        ...new Set([
          ...pipelineData.map((item) => item.branch_name),
          ...commitData.map((item) => item.branch),
        ]),
      ],
      status: [...new Set(pipelineData.map((item) => item.status))],
    };

    console.log("New All Filter Options:", newAllFilterOptions);
    setAllFilterOptions(newAllFilterOptions);
  };
 

  return (
    <div className="col-md-12 mb-3">
      {isExport == false && (
        <div className="d-flex justify-content-between mb-2">
          <div className="custom-tab-scroll">
            <ul
              className="nav nav-pills d-flex gap-3"
              id="pills-tab-1"
              role="tablist"
            >
              <li
                className="nav-item custom-bg rounded-start"
                role="presentation"
              >
                <button
                  className={`nav-link theme-tab ${
                    activeTab === "pipeline" ? "active" : ""
                  } position-relative cus-tab-border bg-transparent font-12 secondary-text-color font-medium px-3 py-1`}
                  onClick={() => handleTabChange("pipeline")}
                  type="button"
                  role="tab"
                  aria-selected={activeTab === "pipeline"}
                >
                  Pipeline Execution
                </button>
              </li>
              <li className="nav-item custom-bg" role="presentation">
                <button
                  className={`nav-link theme-tab ${
                    activeTab === "commits" ? "active" : ""
                  } position-relative cus-tab-border bg-transparent font-12 secondary-text-color font-medium px-3 py-1`}
                  onClick={() => handleTabChange("commits")}
                  type="button"
                  role="tab"
                  aria-selected={activeTab === "commits"}
                >
                  Commits
                </button>
              </li>
            </ul>
          </div>
          {/* filter start */}
          <button
            type="button"
            className="filter-button p-2 position-relative lh-0"
            data-bs-toggle="dropdown"
            id="dropdownMenuLink-1"
            data-bs-auto-close="outside"
          >
            <img src="images/filter-blue.svg" alt="filter-icon" />
          </button>
          {/*filter*/}
          <div
            className="dropdown-menu dropdown-menu-end border-0 custom-shadow background-white dd-position p-4 custom-filter-width"
            aria-labelledby="dropdownMenuLink-1"
          >
            <h5 className="font-medium font-16 mb-0 color-black mb-4">
              Filter
            </h5>

            <div className="row">
              {activeTab === "pipeline"
                ? ["Workload", "Repository", "Branch", "Status"].map(
                    (label) => (
                      <div className="col-md-6 mb-4" key={label}>
                        <label
                          className="form-label font-14 font-medium black-v2"
                          htmlFor={`${label}Status`}
                        >
                          {label}
                        </label>
                        <select
                          className="form-select custom-dropdown custom-select shadow-none custom-border-box py-1 font-14 font-regular black-v2"
                          id={`${label}Status`}
                          value={filterOptions[label.toLowerCase()]}
                          onChange={(e) =>
                            setFilterOptions({
                              ...filterOptions,
                              [label.toLowerCase()]: e.target.value,
                            })
                          }
                        >
                          <option value="">Select</option>
                          {getFilterOptions()[label.toLowerCase()]?.map(
                            (option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                    )
                  )
                : ["Repository", "Branch"].map((label) => (
                    <div className="col-md-6 mb-4" key={label}>
                      <label
                        className="form-label font-14 font-medium black-v2"
                        htmlFor={`${label}Status`}
                      >
                        {label}
                      </label>
                      <select
                        className="form-select custom-dropdown custom-select shadow-none custom-border-box py-1 font-14 font-regular black-v2"
                        id={`${label}Status`}
                        value={filterOptions[label.toLowerCase()]}
                        onChange={(e) =>
                          setFilterOptions({
                            ...filterOptions,
                            [label.toLowerCase()]: e.target.value,
                          })
                        }
                      >
                        <option value="">Select</option>
                        {getFilterOptions()[label.toLowerCase()]?.map(
                          (option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  ))}
              <div className="col-md-12">
                <div className="d-flex justify-content-end align-items-center pt-3 pb-0 gap-3">
                  <button
                    className="button outline-button px-4 py-2"
                    onClick={handleFilterClear}
                  >
                    Clear All
                  </button>
                  <button
                    className="btn button primary-button px-3 py-2"
                    onClick={handleFilterApply}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/*filter*/}
          {/* filter end */}
        </div>
      )}

      {isExport == false && (
        <div className="tab-content" id="pills-tabContent-1">
          <div
            className={`tab-pane fade ${
              activeTab === "pipeline" ? "show active" : ""
            }`}
            id="dash-PipelineExecution"
            role="tabpanel"
            aria-labelledby="dash-PipelineExecution"
          >
            <div className="table-responsive table-border">
              <table className="table custom-table">
                <thead>
                  <tr>
                    <th className="border-0">Repository</th>
                    <th className="border-0">Branch</th>
                    <th className="border-0">Description</th>
                    <th className="border-0">Status</th>
                    <th className="border-0">Date &amp; Time</th>
                  </tr>
                </thead>
                <tbody>
                  {console.log("Rendering tbody")}
                  {console.log("filteredPipelineData:", filteredPipelineData)}
                  {filteredPipelineData.length > 0 ? (
                    filteredPipelineData.map((pipelineItem, index) => (
                      <tr key={index}>
                        <td>{pipelineItem.repo_name}</td>
                        <td>{pipelineItem.branch_name}</td>
                        <td>{pipelineItem.description}</td>
                        <td
                          className={
                            pipelineItem.status === "success"
                              ? "text-success"
                              : "text-danger"
                          }
                        >
                          {pipelineItem.status}
                        </td>
                        <td>{pipelineItem.date_time}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" className="text-center">
                        {/* No pipeline data available */}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div
            className={`tab-pane fade ${
              activeTab === "commits" ? "show active" : ""
            }`}
            id="dash-Commits"
            role="tabpanel"
            aria-labelledby="dash-Commits"
          >
            <div className="table-responsive mt-4 table-border">
              <table className="table custom-table">
                <thead>
                  <tr>
                    <th className="border-0">Repository</th>
                    <th className="border-0">Branch</th>
                    <th className="border-0">Commit Tag</th>
                    <th className="border-0">Date &amp; Time</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredCommitData.length > 0 ? (
                    filteredCommitData.map((item, index) => (
                      <tr key={index}>
                        <td>{item.repo}</td>
                        <td>{item.branch}</td>
                        <td>{item.commitTag}</td>
                        <td>{item.dateTime}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" className="text-center">
                        {/* No commit data available */}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      {isExport && (
        <div className="tab-content" id="pills-tabContent-1">
          <p>Pipeline executions</p>
          <div className={"tab-pane fade show active"}>
            <div className="table-responsive table-border">
              <table className="table custom-table">
                <thead>
                  <tr>
                    <th className="border-0">Repository</th>
                    <th className="border-0">Branch</th>
                    <th className="border-0">Description</th>
                    <th className="border-0">Status</th>
                    <th className="border-0">Date &amp; Time</th>
                  </tr>
                </thead>
                <tbody>
                  {console.log("Rendering tbody")}
                  {console.log("filteredPipelineData:", filteredPipelineData)}
                  {filteredPipelineData.length > 0 ? (
                    filteredPipelineData.map((pipelineItem, index) => (
                      <tr key={index}>
                        <td>{pipelineItem.repo_name}</td>
                        <td>{pipelineItem.branch_name}</td>
                        <td>{pipelineItem.description}</td>
                        <td
                          className={
                            pipelineItem.status === "success"
                              ? "text-success"
                              : "text-danger"
                          }
                        >
                          {pipelineItem.status}
                        </td>
                        <td>{pipelineItem.date_time}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" className="text-center">
                        {/* No pipeline data available */}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <p>Commits</p>
          <div
            className={"tab-pane fade show active"}
            id="dash-Commits"
            role="tabpanel"
            aria-labelledby="dash-Commits"
          >
            <div className="table-responsive mt-4 table-border">
              <table className="table custom-table">
                <thead>
                  <tr>
                    <th className="border-0">Repository</th>
                    <th className="border-0">Branch</th>
                    <th className="border-0">Commit Tag</th>
                    <th className="border-0">Date &amp; Time</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredCommitData.length > 0 ? (
                    filteredCommitData.map((item, index) => (
                      <tr key={index}>
                        <td>{item.repo}</td>
                        <td>{item.branch}</td>
                        <td>{item.commitTag}</td>
                        <td>{item.dateTime}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" className="text-center">
                        {/* No commit data available */}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PipelineCommit;
