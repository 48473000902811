import { useContext, useEffect, useState } from "react"
import { AddServiceContext } from "./context/AddServiceContext"
import { v4 as uuidv4 } from 'uuid';


export default function InputConfiguration(){
   const {selfServiceData,setSelfServiceData,isAddField,setIsAddField,fieldType,setFieldType,services } = useContext(AddServiceContext);
   const [configArray,setConfigArray] = useState({
    "fieldName": "",
    "fieldType": "",
    "value": "",
    "isDependsOn": false,
    "required": false,
    "isDependsOn" : false,
    "dependsOn": "",
    "depends_on_configuration_id": "",
    "fieldId":""
});

// console.log(configArray,"This is the config arrays")

const [fields,setFields] = useState([])

  useEffect(()=>{
    if(configArray.dependsOn){
      setFieldsFunction()
    }
  },[configArray?.dependsOn])


  function deleteConfigurationItem(targetUuid) {
    setSelfServiceData((prevData) => ({
      ...prevData,
      InputConfiguration: prevData.InputConfiguration.filter(
        (item) => item.uuid !== targetUuid
      ),
    }));
  }

  function saveConfigurationArray() {
    // debugger;
    try {
      if (configArray?.fieldName !== "" && fieldType) {
        let jsonData = "";
        try {
          jsonData = JSON.stringify(configArray.value.split(","));
        } catch (error) {
          console.log(error);
        }
  
        // If uuid exists, update the existing item
        if (configArray.uuid) {
          setSelfServiceData((prevData) => {
            // Map through InputConfiguration to create a new array with updated values
            const updatedInputConfiguration = prevData.InputConfiguration.map((item) => {
              if (item.uuid === configArray.uuid) {
                // Update the item with new values if uuid matches
                return {
                  ...item,
                  uuid: configArray.uuid,
                  fieldName: configArray.fieldName,
                  fieldType: fieldType,
                  value: jsonData,
                  isDependsOn: configArray.isDependsOn,
                  required: configArray.required,
                  dependsOn: {
                    serviceId: configArray.dependsOn,
                    fieldName: configArray.depends_on_configuration_id,
                  },
                  depends_on_configuration_id: configArray.depends_on_configuration_id,
                  fieldId : configArray.fieldId
                };
              }
              return item; // Return unchanged items
            });
  
            return {
              ...prevData,
              InputConfiguration: updatedInputConfiguration,
            };
          });
        } else {
          // If uuid doesn't exist, add new configuration
          setSelfServiceData((prevData) => ({
            ...prevData,
            InputConfiguration: [
              ...prevData.InputConfiguration,
              {
                uuid: uuidv4(),
                fieldName: configArray.fieldName,
                fieldType: fieldType,
                value: jsonData,
                isDependsOn: configArray.isDependsOn,
                required: configArray.required,
                dependsOn: {
                  serviceId: configArray.dependsOn,
                  fieldName: configArray.depends_on_configuration_id,
                },
                depends_on_configuration_id: configArray.depends_on_configuration_id,
                fieldId : configArray.fieldId
              },
            ],
          }));
        }
  
        // Reset configArray after saving
        setConfigArray({
          fieldName: "",
          fieldType: "",
          value: "",
          isDependsOn: false,
          required: false,
          dependsOn: "",
          depends_on_configuration_id: "",
          fieldId : ""
        });
  
      }
      setIsAddField(false);
    } catch (error) {
      console.log(error?.message);
    }
  }
  
   function editConfig(editId){
     try {


      let configsData = {
        "fieldName": "",
        "fieldType": "",
        "value": "",
        "isDependsOn": false,
        "required": false,
        "isDependsOn" : false,
        "dependsOn": "",
        "depends_on_configuration_id": "",
        "fieldId":""
    }

      setConfigArray(configArray)

      for(let configs of selfServiceData?.InputConfiguration){


        let valOfjson = ""
        try {

          valOfjson = JSON.parse( configs.value).join(',')
          
        } catch (error) {
          
        }
        if (editId == configs?.uuid){
         configsData.dependsOn = configs?.dependsOn
         configsData.depends_on_configuration_id = configs?.depends_on_configuration_id
         configsData.fieldName = configs?.fieldName
         configsData.fieldType = configs.fieldType
         configsData.isDependsOn = configs.isDependsOn
         configsData.required = configs.required
         configsData.value =valOfjson
         configsData.uuid = configs.uuid
         configsData.fieldId = configs.fieldId
         setConfigArray(configsData)
         setIsAddField(true)
         setFieldType(configs.fieldType)
        }
      }
     } catch (error) {
      console.log(error.message)
     }
   }

   function setFieldsFunction(){
    try{

      let fieldsCopy = [];

      for (let values of services){
        if (configArray?.dependsOn == values?.serviceId){
      
          for (let valueF of values?.fields){
            fieldsCopy.push(valueF)
          }
        }
      }
      setFields(fieldsCopy)
    }catch(error){
      console.log(error.message)
    }
   }


   /**Draggable */
   const handleDragStart = (e, index) => {
    e.dataTransfer.setData("draggedItemIndex", index);
  };

  const handleDragOver = (e) => {
    e.preventDefault(); // Necessary to allow a drop.
  };

  const handleDrop = (e, dropIndex) => {
    e.preventDefault();
    const draggedIndex = e.dataTransfer.getData("draggedItemIndex");
    const updatedConfiguration = [...selfServiceData.InputConfiguration];

    // Swap the positions
    const draggedItem = updatedConfiguration.splice(draggedIndex, 1)[0];
    updatedConfiguration.splice(dropIndex, 0, draggedItem);

    // Update the state
    setSelfServiceData((prevState) => ({
      ...prevState,
      InputConfiguration: updatedConfiguration,
    }));
  };

    return (
      <>
      <div className="row position-relative">
        <h2 className="font-18 font-bold black-v2 mt-2 mb-3">
          Inputs Configuration
        </h2>

        {
          selfServiceData?.InputConfiguration?.map((value,index) => {
            return (
              <div 
              className="col-md-8 mb-3 custom-function-field"
              draggable
              onDragStart={(e) => handleDragStart(e, index)}
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, index)}
              style={{"cursor":"pointer"}}
              >
              <input
                type="text"
                id="fuction"
                className="form-control custom-form"
                placeholder=""
                value={value?.fieldName}
                disabled
              />
              <span className="function-field-img"></span>
              <span className="function-field-options">
                
                <a className="cursor-pointer font-medium font-14 primary-color text-decoration-none me-2" onClick={()=>{
                  editConfig(value?.uuid)
                }}>
                  Edit
                </a>
                <a className="cursor-pointer font-medium font-14 danger-red text-decoration-none ms-2" onClick={()=>{
                  deleteConfigurationItem(value?.uuid)
                }}>
                  Delete
                </a>
              </span>
            </div>
            )
          })
        }


<div className="add-new-field-dropdown mb-4 pb-2 position-relative">
          <button
            type="button"
            className="add-new-field-button position-relative px-2 font-medium"
            data-bs-toggle="dropdown"
            aria-expanded="false"
           onClick={()=>{
            setConfigArray({
              "fieldName": "",
              "fieldType": "",
              "value": "",
              "isDependsOn": false,
              "required": false,
              "isDependsOn" : false,
              "dependsOn": "",
              "depends_on_configuration_id": "",
              "fieldId":""
          })
           }}
            
          >
            <img
              src="images/plus-icon-blue.svg"
              alt="plus-icon"
              className="me-2"
            />
            Add New Field{" "}
            <img
              src="images/dropdown-blue.svg"
              alt="dropdown-icon"
              className="ms-3"
            />
          </button>
          <ul
            className="dropdown-menu dropdown-menu-end border-0 dd-position px-1 px-1 add-new-field-width"
            aria-labelledby="dropdownMenuLink"
            data-popper-placement="bottom-end"
          >
            <li className="py-2 px-2" onClick={()=>{
              setFieldType("text")
                setIsAddField(true)
           
            }}>
              <a
                
                className="font-14 font-medium text-decoration-none color-black"
              >
                Text{" "}
              </a>
            </li>
            <li className="py-2 px-2" onClick={()=>{
               setFieldType("dropdown")
               setIsAddField(true)
            }}>
              <a
                
                className="font-14 font-medium text-decoration-none color-black"
              >
                Dropdown
              </a>
            </li>
            <li className="py-2 px-2" onClick={()=>{
               setFieldType("check box")
               setIsAddField(true)
            }}>
              <a
              
                className="font-14 font-medium text-decoration-none color-black"
              >
                Checkbox
              </a>
            </li>
            
            <li className="py-2 px-2" onClick={()=>{
               setFieldType("file upload")
               setIsAddField(true)
            }}>
              <a
             
                className="font-14 font-medium text-decoration-none color-black"
              >
                File Upload
              </a>
            </li>
          </ul>
        </div>
       

       {
             isAddField &&  <div className="col-md-8 mb-4 pb-3 mt-4" >
             <label className="form-label font-14 font-medium" htmlFor="label-1">
               Label
             </label>
             <input
               type="text"
               id="label-1"
               className="form-control custom-form"
               placeholder="Enter Label"
               value={configArray?.fieldName}
               onChange={(e) => setConfigArray(prevConfig => ({
                ...prevConfig,
                fieldName: e.target.value, // Directly update fieldName
              }))}
             />
           </div>
       }

{
             isAddField && (fieldType == "check box" || fieldType == "dropdown")  &&  <div className="col-md-8 mb-4 pb-3 mt-4" >
             <label className="form-label font-14 font-medium" htmlFor="label-1">
               Values (Seperate values with comma)
             </label>
             <input
               type="text"
               id="label-1"
               className="form-control custom-form"
               placeholder="Enter Label"
               value={configArray.value}
               onChange={(e) => setConfigArray(prevConfig => ({
                ...prevConfig,
                value: e.target.value // Directly update fieldName
              }))}
             />
           </div>
       }
       {
         isAddField && <div className="col-md-8 d-flex mb-4 pb-3" >
         <div className="d-flex gap-5">
           <div className="form-check form-switch">
             <input
               className="form-check-input custom-switcher"
               type="checkbox"
               role="switch"
               id="flexSwitchCheckChecked-2"
               defaultChecked=""
               checked={configArray.required} 
  onChange={(e) => setConfigArray(prevConfig => ({
    ...prevConfig,
    required: e.target.checked, // Update `required` based on checkbox state
  }))}
             />
             <label
               className="form-check-label font-14 font-medium  black-v2 mb-0"
               htmlFor="flexSwitchCheckChecked-2"
             >
               Required Field
             </label>
           </div>
           <div className="d-flex align-items-center gap-3">
             <div className="form-check form-switch">
               <input
                 className="form-check-input custom-switcher"
                 type="checkbox"
                 role="switch"
                 id="flex-2"
                 defaultChecked=""
                 checked={configArray.isDependsOn} 
                 onChange={(e) => {
                  
                  setConfigArray(prevConfig => ({
                   ...prevConfig,
                   isDependsOn: e.target.checked, // Update `isDependsOn` based on checkbox state
                 }))

                 if (e.target.checked){
                  document.getElementById("dependsOnPop").click()
                 }

                }}
               />
               <label
                 className="form-check-label font-14 font-medium  black-v2 mb-0"
                 htmlFor="flex-2"
               >
                 Depends on
               </label>
             </div>
             {
            <div className={`ms-3 gap-3 ${( configArray?.isDependsOn) ? "d-flex" : "d-none"}`} >
              <a>
                <img
                  src="images/edit-icon-blue.svg"
                  alt="logo"
                  id="dependsOnPop"
                  className="edit-icon cursor-pointer"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop-26"
                  onClick={()=>{
                  }}
                />
              </a>
              <a>
                <img
                  src="images/bin-icon-red.svg"
                  alt="logo"
                  className="edit-icon cursor-pointer"
                  onClick={()=>{
                    setConfigArray(prevConfig => ({
                      ...prevConfig,
                      isDependsOn: false, 
                      "dependsOn" : "" ,
                      "depends_on_configuration_id" : ""
                    }) ) }}
                />
              </a>
            </div>
             }
             
           </div>
         </div>
       </div>
       }    

{ isAddField && <div className="d-flex justify-content-end align-items-center mb-2 mt-32 gap-3" >
          <button
            type="button"
            className="button outline-button text-nowrap py-12"
            data-bs-dismiss="modal"
            id="dependsClose"
            onClick={()=>{
              setIsAddField(false)
              setConfigArray({
                "fieldName": "",
                "fieldType": "",
                "value": "",
                "isDependsOn": true,
                "required": false,
                "isDependsOn" : false,
                "dependsOn": "",
                "depends_on_configuration_id": "",
                "fieldId":""
            })

            }}
          >
            Cancel
          </button>
          <button
            type="button"
            className="button primary-button text-nowrap px-32 py-12"
            data-bs-dismiss="modal"
            onClick={()=>{saveConfigurationArray()}}
          >
            Save
          </button>
          </div>}
</div>
<div
  className="modal fade"
  id="staticBackdrop-26"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabIndex={-1}
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content  border-0">
      <div className="modal-header border-0 py-4 px-32">
        <h1
          className="modal-title font-20 font-bold black-v2"
          id="staticBackdropLabel"
        >
          Depends on
        </h1>
        <button
          type="button"
          className="btn shadow-none p-0"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={()=>{
            setConfigArray(prevConfig => ({
              ...prevConfig,
              isDependsOn: false,
              depends_on_configuration_id : "",
              dependsOn : "" // Update `isDependsOn` based on checkbox state
            }))
          }}
        >
          <img
            src="images/close-icon.svg"
            alt="close-icon"
            className="icon-hover shadow-none modal-close-filter"
          />
        </button>
      </div>
      <div className="modal-body p-32">
        <div className="row">
          <div className="col-md-12">
            <div className="row mb-4">
              <div className="col-md-6 mb-3 px-2">
                <label
                  className="form-label font-14 font-medium black-v2"
                  htmlFor="Service"
                >
                  Service
                </label>
                <select
                  id="Service"
                  className="form-select form-control custom-form font-14 font-medium read-only"
                  onChange={(e) => setConfigArray(prevConfig => ({
                    ...prevConfig,
                    dependsOn: e.target.value, // Directly update fieldName
                  }))}
                  value={configArray.dependsOn}
                >
                  <option value={""}>Select Key</option>
                  {
                    services?.map((values,index) => {
                      return(
                        <option value={values?.serviceId}>{values?.serviceName}</option>
                      )
                    })
                  }
                </select>
              </div>
              <div className="col-md-6 mb-3 px-2">
                <label
                  className="form-label font-14 font-medium black-v2"
                  htmlFor="Feature"
                >
                  Feature
                </label>
                <select
                  id="Feature"
                  className="form-select form-control custom-form font-14 font-medium read-only"
                  onChange={(e) => setConfigArray(prevConfig => ({
                    ...prevConfig,
                    depends_on_configuration_id: e.target.value, // Directly update fieldName
                  }))}
                  value={configArray.depends_on_configuration_id}
                >
                  <option value={""}>Select Key</option>
                  {
                    fields?.map((values,index) => {
                      return(
                        <option value={values?.fieldId} >{values?.fieldName}</option>
                      )
                    })
                  }
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end align-items-center mb-2 mt-32 gap-3">
          <button
            type="button"
            className="button outline-button text-nowrap py-12"
            data-bs-dismiss="modal"
            id="dependsClose"
            onClick={()=>{
              setConfigArray(prevConfig => ({
                ...prevConfig,
                isDependsOn: false,
              depends_on_configuration_id : "",
              dependsOn : ""  // Update `isDependsOn` based on checkbox state
              }))
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            className="button primary-button text-nowrap px-32 py-12"
            disabled = {
              (configArray?.dependsOn == "" || configArray?.depends_on_configuration_id == "")
            }
            data-bs-dismiss="modal"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</div> 



      </>
        
    )
}