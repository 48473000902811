
import React, { useState, useEffect } from 'react';
import { getAllEnvByWorkloadIdApi, getAllOrgApi, getAllWorkloadByOrgIdApi, handleCloneSaveApi } from '../../../../Service/api';
import { useOrgContext } from '../../../../ContextProvider/OrgContext';
import { useB2AContext } from '../../../../ContextProvider/B2AContext';

const CloneModal = ({ showCloneModal, setShowCloneModal, nodes , edges , setPopUpMsg, popupMsg  }) => {
    // Context values

    // State variables
    const [organizations, setOrganizations] = useState([]);
    const [workloads, setWorkloads] = useState([]);
    const [environments, setEnvironments] = useState([]);
    const [environments2, setEnvironments2] = useState([]);
    
    const [selectedOrganization, setSelectedOrganization] = useState('');
    const [selectedWorkload, setSelectedWorkload] = useState('');
    const [selectedEnvironment, setSelectedEnvironment] = useState('');
    const [selectedCloneEnvironment2, setSelectedCloneEnvironment2] = useState('');
    const {userId , orgId} = useOrgContext()

    // Error and loading states
    const [error, setError] = useState(null);
    

    // Load organizations when the component mounts
    useEffect(() => {
        loadOrganizations();
    }, []);

    // Load workloads when an organization is selected
    useEffect(() => {
        if (selectedOrganization !== "") {
            loadWorkloads();
        }
    }, [selectedOrganization]);

    /**
     * Fetches all organizations from the API
     */
    const loadOrganizations = async () => {
     
        setError(null);
        const payload = {
            userId: userId,
            orgId: orgId,
            // Uncomment below and comment above when using actual context values
            // userId: userId,
            // orgId: orgId,
        };

        try {
            const response = await getAllOrgApi(payload);
            if (response.success) {
                setOrganizations(response.data);
            } else {
                setError('Error fetching organizations');
            }
        } catch (error) {
            setError('An error occurred while fetching organizations');
            console.error('Error details:', error);
        } finally {
           
        }
    };

    /**
     * Fetches all workloads for the selected organization
     */
    const loadWorkloads = async () => {
     
        setError(null);
        const payload = {
            orgId: selectedOrganization,
            userId: userId, // Replace with userId from context when ready
            type: "clone",
            connectorId: "",
            orgIdGeneral: ""
        };

        try {
            const response = await getAllWorkloadByOrgIdApi(payload);
            console.log(response , "response from Workload ")
            // {
            //     "orgId": "28da4a71-14f5-47ce-a349-35840ebf2dcb",
            //     "workload": [
            //         {
            //             "workloadId": "93f6aa1a-b004-46bf-bf5f-4f11ba31dea1",
            //             "workloadName": "Test-project"
            //         }
            //     ]
            // }
            if (response.success) {
                setWorkloads(response.data.workload);
            } else {
                setError('Error fetching workloads');
            }
        } catch (error) {
            setError('An error occurred while fetching workloads');
            console.error('Error details:', error);
        } finally {
           
        }
    };

    /**
     * Fetches environments based on the selected workload
     * @param {boolean} isClone - Determines if it's for cloning or not
     * @param {string} workloadId - The ID of the selected workload
     */
    const loadEnvironments = async (isClone, workloadId) => {
       
        setError(null);
        const payload = {
            userId: userId, // Replace with userId from context when ready
            orgId: selectedOrganization,
            workloadId: workloadId,
            type: isClone ? "clone" : "budget",
        };

        try {
            const response = await getAllEnvByWorkloadIdApi(payload);
            console.log(response , "getAllEnvByWorkloadIdApi")
            if (response.success) {
                if (isClone) {
                    setEnvironments2(response.data);
                } else {
                    setEnvironments(response.data);
                }
            } else {
                setError('Error fetching environments');
            }
        } catch (error) {
            setError('An error occurred while fetching environments');
            console.error('Error details:', error);
        } finally {
           
        }
    };

    /**
     * Handles the change event for workload selection
     * @param {Event} e - The change event
     */
    const handleWorkloadChange = (e) => {
        const workloadId = e.target.value;
        setSelectedWorkload(workloadId);
        if (workloadId) {
            loadEnvironments(true, workloadId);
        } else {
            setEnvironments2([]);
        }
    };

    /**
     * Initiates the cloning process
     */
    const handleClone = async () => {
      
        setError(null);
        let nodeData = {
            nodes: nodes,
            edges: edges
          }
    
        const payload = {
            userId: userId, // Replace with userId from context when ready
            organizationId: selectedOrganization,
            workloadId:selectedWorkload,
            envId: selectedCloneEnvironment2,
            acrhData:JSON.stringify(nodeData)

        };

        try {
            console.log(payload , 'Paylaod for Clone')
            const response = await handleCloneSaveApi(payload);
            if (response.success) {
                console.log('Architecture cloned successfully:', response);
                // TODO: Show success message to user
				setPopUpMsg({...popupMsg , ["Msg"]:"Architecture cloned successfully" , ["show"]:true ,["success"]:true })

            } else {
				setPopUpMsg({...popupMsg , ["Msg"]:"Error Cloning Architecture" , ["show"]:true ,["success"]:false })

                setError('Error cloning architecture');
            }
        } catch (error) {
            setError('Failed to clone architecture');
            console.error('Error details:', error);
        } finally {
           
            resetAndCloseModal();
        }
    };

    /**
     * Resets all form fields and closes the modal
     */
    const resetAndCloseModal = () => {
        setShowCloneModal(false);
        setSelectedOrganization('');
        setSelectedWorkload('');
        setSelectedEnvironment('');
        setSelectedCloneEnvironment2('');
        setError(null);
    };

    return (
        <div className={`modal fade ${showCloneModal ? 'show d-block' : ''}`}
             style={{ display: showCloneModal ? 'block' : 'none', backgroundColor: 'rgba(0, 0, 0, 0.5)'}}>
            <div className="modal-dialog modal-dialog-centered justify-content-center">
                <div className="modal-content border-0">
                    <div className="modal-header border-0 p-4">
                        <h1 className="modal-title font-20 font-bold black-v2" id="staticBackdropLabel">Clone Environment</h1>
                        <button type="button" className="btn shadow-none" onClick={resetAndCloseModal} aria-label="Close">
                            <img src="images/close-icon.svg" alt="close-icon" className="icon-hover shadow-none modal-close-filter" />
                        </button>
                    </div>
                    <div className="modal-body px-4 pt-0">
                        <div className="my-3">
                            <label className="form-label font-14 font-medium black-v2" htmlFor="organizationSelect">Organization <span className="danger-red">*</span></label>
                            <select
                                className="form-select custom-dropdown shadow-none custom-border-box font-14 font-regular black-v2 custom-select"
                                id="organizationSelect"
                                value={selectedOrganization}
                                onChange={(e) => setSelectedOrganization(e.target.value)}
                            >
                                <option value="">Select</option>
                                {organizations.map(org => (
                                    <option key={org.orgId} value={org.orgId}>{org.orgName}</option>
                                ))}
                            </select>
                        </div>
                        <div className="my-3">
                            <label className="form-label font-14 font-medium black-v2" htmlFor="workloadSelect">Workload <span className="danger-red">*</span></label>
                            <select
                                className="form-select custom-dropdown shadow-none custom-border-box font-14 font-regular black-v2 custom-select"
                                id="workloadSelect"
                                value={selectedWorkload}
                                onChange={handleWorkloadChange}
                            >
                                <option value="">Select</option>
                                {workloads.map(workload => (
                                    <option key={workload.workloadId} value={workload.workloadId}>{workload.workloadName}</option>
                                ))}
                            </select>
                        </div>
                        <div className="my-3">
                            <label className="form-label font-14 font-medium black-v2" htmlFor="cloneEnvironmentSelect">Clone Environment<span className="danger-red">*</span></label>
                            <select
                                className="form-select custom-dropdown shadow-none custom-border-box font-14 font-regular black-v2 custom-select"
                                id="cloneEnvironmentSelect"
                                value={selectedCloneEnvironment2}
                                onChange={(e) => setSelectedCloneEnvironment2(e.target.value)}
                            >
                                <option value="">Select</option>
                                {environments2.map(env => (
                                    <option key={env.envId} value={env.envId}>{env.envName}</option>
                                ))}
                            </select>
                        </div>

                        <div className='d-flex justify-content-end align-items-center mt-4 mb-2 gap-3'>
                            <button type='button' className='button outline-button text-nowrap py-12' onClick={resetAndCloseModal}>Cancel</button>
                            <button type='button' className='button primary-button text-nowrap px-4 py-12' onClick={handleClone} disabled={
                               selectedOrganization !== "" &&
                               selectedWorkload !== "" &&
                               selectedCloneEnvironment2 !== "" ? false : true
                            }>Clone</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CloneModal;