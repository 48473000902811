// Endpoints.jsx
// This should be set based on your deployment environment
const CURRENT_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || 'dev';

const BASE_URLS = {
	dev: 'https://devapi.cloudgen.ai',
	qa: 'https://qa-api.example.com',
	uat : 'https://uatapi.cloudgen.ai',
	prod: 'https://api.example.com'
};

// Define common endpoints by module
const ENDPOINTS = {
	Organization: {
		getAllOrganization: "/organization/get_all_organization",
		delete_organization: "/organization/delete_organization",
		get_organization_details_by_id:"/organization/get_organization_details_by_id",
		create_organization:"/organization/create_organization",
		get_all_workloads:"/organization/get_all_workloads",
		get_all_provider:"/organization/get_all_provider",
		delete_workload:"/organization/delete_workload",
		clone_architecture:"/organization/clone_architecture",
		edit_organization:"/organization/edit_organization",
		get_all_methods:"/organization/get_all_methods",
		get_all_rules:"/organization/get_all_rules",
		get_arch_type_by_ai:"/organization/get_arch_type_by_ai",
		get_all_repo:"/organization/get_all_repo",
		delete_repository:"/organization/delete_repository",
		get_all_roles:"/organization/get_all_roles",
		get_all_rule_category:"/organization/get_all_rule_catagory",
		get_all_connector_details:"/organization/get_all_connector_details",
		get_all_azure_services:"/organization/get_all_azure_services",
		get_all_workload_by_org_id:"/organization/get_all_workload_by_org_id",
		get_all_org:"/organization/get_all_org",
		validate_scm_creds:"/organization/validate_scm_creds",
		add_repository:"/organization/add_repository",
		update_dark_theme:"/organization/update_dark_theme",
		edit_repository:"/organization/edit_repository",
		create_workload:"/organization/create_workload",
		update_workload:"/organization/update_workload",

		// keerthana code 
		GetAllProviders: "/organization/get_all_provider",
		getAllProvider:"/organization/get_all_provider",
		GetAllRoles:"/organization/get_all_roles",
		GetAllWorkloadsByOrg:"/organization/get_all_workload_by_org_id",
		GetUniqueTagsByOrg:"/organization/get_uniqueTags_by_org",
		GetAllConnectors:"/organization/get_all_connector",
		syncNowAws : "/jobs/get_aws_cost_job",
		syncNowAzure : "/jobs/get_azure_cost_job",
		syncNowAzureInventory : "/jobs/get_azure_inventory_job",
        syncNowAwsInventory : "/jobs/get_aws_inventory_job_schedular",
		generate_arch_with_ai :"/organization/generate_arch_with_ai",
		get_all_env_by_workload_id : "/organization/get_all_env_by_workload_id",
	},
	cost_management:{
		get_all_invoices:"/cost_management/get_invoice",
		create_aws_connector:"/cost_management/create_aws_connector",
		test_aws_connector:"/cost_management/test_aws_connector",
		update_aws_connector:"/cost_management/update_aws_connector",
		delete_connector:"/cost_management/delete_connector",
		create_azure_connector:"/cost_management/create_azure_connector",
		test_azure_connector:"/cost_management/test_azure_connector",
		update_azure_connector:"/cost_management/update_azure_connector",
		get_all_env_threshold:"/cost_management/get_all_env_threshold",
		get_all_project_threshold:"/cost_management/get_all_project_threshold",
		delete_budget:"/cost_management/delete_budget",
		get_all_env_by_workload_id:"/cost_management/get_all_env_by_workload_id",



	},
	Estimation: {
		GetTodayInrPrice: "/estimation/get_inr_cost",
		GetAzureCost : "/estimation/get_azure_cost",
		GetAWSCost :"/estimation/get_aws_cost",
		SetThreshold:"/estimation/set_threshold",
		RequestApproval:"/estimation/request_approval",
		ListApprovers:"/estimation/list_approvers",
		RejectApprove:"/deploy/approveDeploy"

	},
	workbench:{
		searchUsers:"/workbench/search_users",

		fetchServicesApi :'/workbench/fetchServicesApi',
		getAllRepoAndEnv:"/workbench/getAllRepoAndEnv",
		getAllTags:"/workbench/getAllTags",
		getRecommendation:"/workbench/getRecommendation",
		insertCommit :"/workbench/insertCommit",
		getVersionHistory :"/workbench/getVersionHistory",
		saveTemplate :"/workbench/saveTemplate",
		save_pulse_template :"/workbench/save_pulse_template",
		saveArchietcture :"/workbench/saveArchietcture",
		getarchitecture_byid :"/workbench/getarchitecture_byid",
		updateTags :"/workbench/updateTags",
		driftTerraform :"/workbench/driftTerraform",
		getEstimateDetails :"/workbench/getEstimateDetails",

	},
	b2a_services:{
		get_all_rules:"/b2a_services/get_all_rules",
		create_rules:"/b2a_services/create_rules",
		delete_rules:"/b2a_services/delete_rules",
		update_rules:"/b2a_services/update_rules",
		get_all_services:"/b2a_services/get_all_services",
		get_workload_details_by_id:"/b2a_services/get_workload_details_by_id",
		get_all_region :"/b2a_services/get_all_region",
		get_all_fields :"/b2a_services/get_all_fields",
		get_all_b2a :"/b2a_services/get_all_b2a",
		save_B2a :"/b2a_services/save_B2a", 
		get_all_tags :"/b2a_services/get_all_tags", 
		get_all_vpc :"/b2a_services/get_all_vpc", 
		get_all_resources_aws :"/b2a_services/get_all_resources_aws", 
		get_arm_iac :"/b2a_services/get_arm_iac", 
		get_cf_iac :"/b2a_services/get_cf_iac", 
		clone_b2a :"/b2a_services/clone_b2a", 
		get_all_location :"/b2a_services/get_all_location", 
		get_all_tags_azure :"/b2a_services/get_all_tags_azure", 
		get_all_resource_grp :"/b2a_services/get_all_resource_grp", 
		get_all_resources_azure :"/b2a_services/get_all_resources_azure", 
		get_all_provider :"/b2a_services/get_all_provider", 
		generate_azureTerraformCode :"/b2a_services/generate_azureTerraformCode", 
		generate_awsTerraformCode :"/b2a_services/generate_awsTerraformCode", 


	},
	Dashboard:{
		getResourceGroup:"/dashboard/getResourceGroup",
		getWorkspaceAzure:"/dashboard/getWorkspaceAzure",
		getWorkspaceAws:"/dashboard/getWorkspaceAws",
		getComputeAws:"/dashboard/getComputeAws",
		getComputeAzure:"/dashboard/getComputeAzure",
		get_all_inventory_services:"/dashboard/get_all_inventory_services",
		// GetAccountCount:"/dashboard/get_account_and_asset_count",



		// keerthana code 

		GetCostByWorkloads:"/dashboard/get_cost_by_workload",
		GetCostByProviders:"/dashboard/get_cost_by_provider",
		GetCostByTags:"/dashboard/get_cost_by_tags",
		GetCostByPeriod:"/dashboard/get_month_cost",
		GetCostByRegion:"/dashboard/get_region_cost",
		GetCostByService:"/dashboard/get_service_cost",
		GetPipelineData:"/dashboard/get_pipeline_data",
		GetCommitData:"/dashboard/get_commit_data",
		GetIACCoverage:"/dashboard/get_iac_coverage_data",
		GetAccountCount:"/dashboard/get_account_and_asset_count",
		GetOverAllCost:"/dashboard/get_overall_cost"
	},
	estimation:{
		set_threshold:"/estimation/set_threshold",
		listApprovers:"/estimation/list_approvers",
		get_threshold_details :"/estimation/get_threshold_details"
	},
	Login: {
		login: "/login/login",
		logout:"/login/logout"
	},

	CICD: {
		CicdDeploy: "/deploy/generateCicdCode",
		CicdCommit: "/deploy/commitCicdCode",
		Branch: "/deploy/getAllBranches"
	},
	Article:{
		CreateArticle: "/documentation/create_article",
		EditArticle:"/documentation/update_article",
		DeleteArticle: "/documentation/delete_article",
		GetArticleDetail: "/documentation/get_article_by_id",
		SearchArticle:"/documentation/search_article",
		GetAllArticle:"/documentation/get_all_articles"
	},
	TemplateKB:{
		CreateTemplate: "/documentation/create_templatekb",
		UpdateTemplate:"/documentation/update_templateKb",
		DeleteTemplate:"/documentation/delete_templatekb",
		GetAllTemplate:"/documentation/get_all_template_kb",
		GetArchiTypeByAi:"/organization/get_arch_type_by_ai"
	},
	Users:{
		ActivityLogs:"/login/get_user_activity_logs",
		CreateUser:"/login/add_user_role",
		EditUser:"/login/edit_user_role",
		DeleteUser:"/login/delete_user_role",
		ActivateUser:"/login/activate_users",
		GetAllUser:"/login/get_all_users"
	},


	deploy :{
		getAllBranches :"/deploy/getAllBranches"
	},

	Databricks: {
		getOverViewCardData: "/dashboard/getCardDataForDataBricks",
		getDbuUtilization: "/dashboard/getDBUUtilizationCard",
		getTrendsForDataBricks: "/dashboard/getTrendsForDataBricks",
		getGridDatabricks: "/dashboard/getGridDatabricks",
		getWorkspaceData:"/dashboard/getWorkspaceBasedOnOrganization"
	},

	Deploy: {
		getAllServicesReview: "/deploy/getAllServicesReview",

		getCloudFormationCode: "/deploy/getCloudFormationCode",
		getTerraformCode:"/deploy/getTerraformCode",
		getArmCode:"/deploy/getArmCode",
		requestForDeploy:"/deploy/requestForDeploy",
		approveDeploy:"/deploy/approveDeploy",

		deploytfcode:"/deploy/deploytfcode",
		deploycfcode:"/deploy/deploycfcode",
		deployarmcode:"/deploy/deployarmcode"
	},

	Insights: {
		getAllInsights: "/documentation/get_all_new_features",
		getAllInsightsById: "/documentation/get_new_features_by_id"
	},

	BranchingStrategy: {
		getBranchingDataStrategy: "/deploy/getBranchingData"
	},

	Documentation: {
		getAllApproval: "/documentation/getAllApproval"
	},

	SelfService: {
		getAllService: "/b2a_services/get_all_services",
		createService: "/b2a_services/create_service",
		deleteService: "/b2a_services/delete_services",
		updateServiceData: "/b2a_services/update_service",
		getAllCategory:"/b2a_services/get_all_category"
	},

	Notifications: {
		getAllNotifications: "/login/getallnotifications",
		updateNotification:"/login/update_notification"
	},

	Templates: {
		getAllTemplates: "/documentation/getAllTemplates",
		getAllZebPulseTemplates: "/documentation/get_all_zeb_pulse_template",
		approveTemplate: "/documentation/approve_template",
		deleteTemplate: "/documentation/delete_template",
		cloneTemplate: "/documentation/clone_template",
		approveZebPulseTemplate: "/documentation/approve_templates",
		deleteZebpulseTemplate: "/documentation/approve_template",
		cloneZebPulseTemplate: "/documentation/clone_zebPulse_template",
		workload:"/organization/get_all_workload_by_org_id",
		environment:"/cost_management/get_all_env_by_workload_id"
	},



};

// Function to get the base URL based on the environment
export const getBaseUrl = () => BASE_URLS[CURRENT_ENVIRONMENT];

// Function to get the full endpoint URL
export const getEndpoint = (moduleName, endpointName) => {
	const baseUrl = getBaseUrl();
	const moduleEndpoints = ENDPOINTS[moduleName];

	if (!moduleEndpoints) {
		throw new Error(`Module "${moduleName}" not found`);
	}

	const endpoint = moduleEndpoints[endpointName];

	if (!endpoint) {
		throw new Error(`Endpoint "${endpointName}" not found in module "${moduleName}"`);
	}

	return `${baseUrl}${endpoint}`;
};

// Default export for convenience
export default getEndpoint;




