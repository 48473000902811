import {
    ReactFlowProvider,
} from '@xyflow/react';
import React, { useMemo, useState, useRef, useCallback, useEffect } from "react";
import RepoWorkbench from './RepoWorkbench';
import { OrgProvider } from '../../../../ContextProvider/OrgContext';


function RepoWorkbenchRender(props) {

    return (
        <div className="container-fluid">
        {/* <div className="px-3 pt-2"> */}
                <div className="row">
                <div className="col-md-12">
                    <div className="row">
              <ReactFlowProvider>
                <RepoWorkbench/>
            </ReactFlowProvider>
                    </div>
        </div>
        </div>
        </div>

    );
}

export default RepoWorkbenchRender;