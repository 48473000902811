import { useContext } from "react"
import { AddServiceContext } from "./context/AddServiceContext"
import { postServiceDetails, updateServiceData } from "../../Service/api";
import { useNavigate } from "react-router-dom";

export default function AddServiceButtons(){
  const navigate = useNavigate()
  const {selfServiceData,setSelfServiceData} = useContext(AddServiceContext)

  function saveSelfServiceData(){
    try {    


    if (selfServiceData?.serviceId){

      console.info(selfServiceData,"This is the self service data..........")

      updateServiceData(selfServiceData)
      .then((response) => {
          setSelfServiceData({
            "userId": localStorage.getItem('userId'),
            "cloudproviderId": "",
            "categoryId": "",
            "serviceName": "",
            "uploadIconBase64": "",
            "containsService": false,
            "description": "",
            "InputConfiguration": [],
            "TerraformCode": "",
            "armcode": "arm",
            "CloudFormationCode": "",
            "filetype": "",
            "filename": "",
            "serviceId": ""
        })


        setTimeout(()=>{ navigate('/SelfService');},3000)
       
      })
      .catch((error) => console.log(error));

    }else{
      postServiceDetails(selfServiceData)
      .then((response) => {
          setSelfServiceData({
            "userId": localStorage.getItem('userId'),
            "cloudproviderId": "",
            "categoryId": "",
            "serviceName": "",
            "uploadIconBase64": "",
            "containsService": false,
            "description": "",
            "InputConfiguration": [],
            "TerraformCode": "",
            "armcode": "arm",
            "CloudFormationCode": "",
            "filetype": "",
            "filename": ""
        })


        setTimeout(()=>{ navigate('/SelfService');},3000)
       
      })
      .catch((error) => console.log(error));
      
    }



      
    } catch (error) {
      
    }
  }
    return (
        <div className="d-flex align-items-center justify-content-between mt-4">
                    <button
                      type="button"
                      className="button cancel-button text-nowrap"
                      onClick={()=>{
                        setSelfServiceData({
                          "userId": localStorage.getItem('userId'),
                          "cloudproviderId": "",
                          "categoryId": "",
                          "serviceName": "",
                          "uploadIconBase64": "",
                          "containsService": false,
                          "description": "",
                          "InputConfiguration": [],
                          "TerraformCode": "",
                          "armcode": "",
                          "CloudFormationCode": "",
                          "filetype": "",
                          "filename": ""
                      })
                        navigate('/SelfService');
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="button primary-button text-nowrap"
                      onClick={() => {
                        saveSelfServiceData()
                      
                      }}

                      disabled = {
                        (
                          selfServiceData?.cloudproviderId == "" ||
                          selfServiceData?.categoryId == "" ||
                          selfServiceData?.serviceName == "" ||
                          selfServiceData?.uploadIconBase64 == "" ||
                          selfServiceData?.description == "" ||
                          selfServiceData?.InputConfiguration?.length == 0 ||
                          (selfServiceData?.armcode == "" && selfServiceData?.TerraformCode == "" && selfServiceData?.CloudFormationCode == "")
                        )
                      }



                    >
                      Save Details
                    </button>
                  </div>
    )
}