
import React, { useState, useEffect } from 'react';
import { fetchRecommendationsApi, getAccountDetailsApi } from '../../../../Service/api';
import { useOrgContext } from '../../../../ContextProvider/OrgContext';

export default function AccountConfigureComponent({ 
	configureDetails, 
	labelSubmit ,
	accounts,
	setAccounts
	}) {
	console.log(configureDetails.data.ConfigureDetail.accountId , configureDetails , "Accoutttt")

	const [selectedAccount, setSelectedAccount] = useState(configureDetails.data.ConfigureDetail.accountId);
	console.log(selectedAccount , "SelectedAcccount")
	const [activeTab, setActiveTab] = useState('configure');
	const [recommendations, setRecommendations] = useState([]);
	const [isLoadingRecommendations, setIsLoadingRecommendations] = useState(false);
	const [recommendationError, setRecommendationError] = useState(null);

	const { 
		orgId, 
		envId,
		providerId,
		workloadRole,

		} = useOrgContext()

		const [userId, setuserId] = useState(localStorage.getItem('userId'));
		const [viewMode,setViewMode] = useState(workloadRole.toLowerCase() == 'viewer' ? true : false)

		useEffect(() => {
				fetchAccountDetails();
			}, []);
		// fetchAccountDetails Function is Triggered During the node Click of the Account 
		const fetchAccountDetails = async () => {
			try{
			// Sample Paylad Test 
			// let payload = {
			// "userId": "c7d86e9d-4819-4f5e-95f8-782d4c6774a3",
			// "orgId": "28da4a71-14f5-47ce-a349-35840ebf2dcb",
			// "providerId": "0a5816fa-5f85-4fd9-b716-62e4395c7b4a",
			// "search": ""
			// }
			let payload = {
				"userId": userId,
				"orgId": orgId,
				"providerId":providerId ,
				"search": ""
			}
			let res = await getAccountDetailsApi(payload)
			console.log(res , "getAccountDetailsApi")
			if (res.success) {
				setAccounts(res.data)
				if (res.data.length > 0 && selectedAccount!=""&& selectedAccount != undefined &&selectedAccount != null ) {
					setSelectedAccount(res.data[0].connectorId)
				}
			}
			}
			catch(err){
				console.log(err , "Eroor")
			}
			};

			const handleAccountChange = (e) => {
				const connectorId = e.target.value;
				setSelectedAccount(connectorId);
				updateConfigureDetails(connectorId);
			};

			const updateConfigureDetails = (connectorId) => {
				const selectedAccountObj = accounts.find(account => account.connectorId === connectorId);
				if (selectedAccountObj) {
					let temp = { ...configureDetails };
					temp.data.ConfigureDetail = {
						accountId: selectedAccountObj.connectorId,
						accountName: selectedAccountObj.connectorName,
						cloudproviderId: providerId,
						permanentAccountId:temp.data.ConfigureDetail.permanentAccountId
					};
					temp.data.imageServiceName = selectedAccountObj.connectorName ? selectedAccountObj.connectorName :temp.data.imageServiceName
					console.log(temp , "Temppp")
					labelSubmit(temp);
				}
			};
			// UseEffect for fetching recommendations
	useEffect(() => {
		if (activeTab === 'recommendation') {
			fetchRecommendations();
		}
	}, [activeTab]);
		// Function to fetch recommendations
	const fetchRecommendations = async () => {
		setIsLoadingRecommendations(true);
		setRecommendationError(null);

		try {
			const payload = {organizationId : orgId, envId, userId };
			console.log("Fetch Recommendation payload:", payload);

			const response = await fetchRecommendationsApi(payload);
			console.log("Fetch Recommendation response:", response);

			if (response.statusCode === 200 && response.body && response.body.data) {
			let parsedData;
			if (typeof response.body.data === "string") {
				parsedData = JSON.parse(response.body.data);
			} else {
				parsedData = response.body.data;
			}

			setRecommendations(parsedData);
				} else {
			throw new Error("Invalid response structure");
				}
		} catch (error) {
			console.error("Error fetching recommendations:", error);
			setRecommendationError("Failed to load recommendations. Please try again.");
		} finally {
			setIsLoadingRecommendations(false);
		}
			};

				return (
					<div className="col-md-3 mt-3 ps-1 pe-3 content-right-container">
						<div className="recommended-container pe-3">
							{/* Tab navigation */}
							<div className="custom-tab-scroll">
								<ul className="nav nav-pills d-flex gap-1 flex-nowrap mb-1" id="pills-tab" role="tablist">
									<li className="nav-item" role="presentation">
										<button
											className={`nav-link theme-tab cust-tab font-14 secondary-text-color font-medium px-3 py-2 ${activeTab === 'configure' ? 'active' : ''}`}
											onClick={() => setActiveTab('configure')}
											type="button"
											role="tab"
										>
											Configure
										</button>
									</li>
									<li className="nav-item" role="presentation">
										<button
											className={`nav-link theme-tab cust-tab font-14 secondary-text-color font-medium px-3 py-2 ${activeTab === 'recommendation' ? 'active' : ''}`}
											onClick={() => setActiveTab('recommendation')}
											type="button"
											role="tab"
										>
											Recommendation
										</button>
									</li>
								</ul>
							</div>

							{/* Tab content */}
							<div className="tab-content" id="pills-tabContent">
								{/* Configure tab */}
								<div className={`tab-pane fade ${activeTab === 'configure' ? 'show active' : ''} pe-1 custom-area-scrl`} id="dash-Configure" role="tabpanel">
									<form>
										<div className="config-container">
											<p className="mb-3 font-18 font-bold black-v2">Configurations</p>
											<div className="row">
												<div className="col-md-12 mb-4">
													<label className="form-label font-14 font-medium black-v2" htmlFor="Account">Account</label>
													<select
														id="Account"
														className="form-select form-control custom-form font-14 font-medium"
														value={selectedAccount}
														onChange={handleAccountChange}
														disabled = {viewMode}
													> 
													<option key={""} value={""}>
														{"Select Account"}
														</option>
														{accounts.map(account => (
															<option key={account.connectorId} value={account.connectorId}>
																{account.connectorName}
															</option>
														))}
													</select>
												</div>
											</div>
										</div>
									</form>
								</div>
								<div className={`tab-pane fade ${activeTab === 'recommendation' ? 'show active' : ''} pt-4 tab-scrl`} id="dash-Recommendation" role="tabpanel">
				{isLoadingRecommendations ? (
					<p>Loading recommendations...</p>
				) : recommendationError ? (
					<p className="error-message">{recommendationError}</p>
				) : recommendations.length === 0 ? (
					<p>No recommendations available.</p>
				) : (
					recommendations.map((recommendationGroup, groupIndex) => (
					<div key={groupIndex} className="recommendation-group mb-4">
						<h2 className="font-20 color-black font-bold mb-3">{recommendationGroup.head}</h2>
						<p className="font-16 font-regular secondary-text-color-v5 mb-4">{recommendationGroup.Description}</p>

						{recommendationGroup.recommendations.map((recommendation, index) => (
						<div key={index} className="recommendation-section mb-4">
							<div className="d-flex align-items-center gap-3 mb-3">
							<p className="font-16 color-black font-medium mb-0">{recommendation.service}</p>
							{recommendation.speedup !== "N/A" && (
								<button className="domain-btn font-14 font-medium bg-primary-dark-v1">
								<img src="images/faster-analysis.svg" alt="faster-analysis" className="dark-theme-icons" />
								{recommendation.speedup}
								</button>
							)}
							</div>
							<p className="font-16 font-regular secondary-text-color-v5">{recommendation.recommendation}</p>

							<ul className="nav pb-3 custom-border-bottom" id={`myTab-${groupIndex}-${index}`} role="tablist">
							<li className="nav-item" role="presentation">
								<button 
								className="nav-link active work-tab pros-tab me-3" 
								id={`home-tab-${groupIndex}-${index}`}
								data-bs-toggle="tab" 
								data-bs-target={`#home-${groupIndex}-${index}`} 
								type="button" 
								role="tab"
								aria-controls={`home-${groupIndex}-${index}`} 
								aria-selected="true"
								>
								Pros <span>({recommendation.pros.length})</span>
								</button>
							</li>
							<li className="nav-item" role="presentation">
								<button 
								className="nav-link work-tab cons-tab" 
								id={`profile-tab-${groupIndex}-${index}`}
								data-bs-toggle="tab" 
								data-bs-target={`#profile-${groupIndex}-${index}`} 
								type="button" 
								role="tab"
								aria-controls={`profile-${groupIndex}-${index}`} 
								aria-selected="false"
								>
								Cons <span>({recommendation.cons.length})</span>
								</button>
							</li>
							</ul>

							<div className="tab-content" id={`myTabContent-${groupIndex}-${index}`}>
							<div 
								className="tab-pane fade show active" 
								id={`home-${groupIndex}-${index}`} 
								role="tabpanel"
								aria-labelledby={`home-tab-${groupIndex}-${index}`}
							>
								<h6 className="font-18 font-bold success-v4 my-3">Pros</h6>
								<ul className="work-custom-list ps-0 pb-3 custom-border-bottom">
								{recommendation.pros.map((pro, proIndex) => (
									<li key={proIndex} className="font-16 font-regular secondary-text-color-v5 mb-3">
									{pro.prosDescription}
									</li>
								))}
								</ul>
							</div>
							<div 
								className="tab-pane fade" 
								id={`profile-${groupIndex}-${index}`} 
								role="tabpanel"
								aria-labelledby={`profile-tab-${groupIndex}-${index}`}
							>
								<h6 className="font-18 font-bold danger-red my-3">Cons</h6>
								<ul className="work-custom-list ps-0 pb-3 custom-border-bottom">
								{recommendation.cons.map((con, conIndex) => (
									<li key={conIndex} className="font-16 font-regular secondary-text-color-v5 mb-3">
									{con.consDescription}
									</li>
								))}
								</ul>
							</div>
							</div>
						</div>
						))}
					</div>
					))
				)}
					</div>
						</div>
						</div>
						</div>
				);
}
