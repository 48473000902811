

import React, { useState, useContext, useEffect } from 'react';
import { FormContext } from './Workload_Grid';
import { getAllServicesApi } from '../../Service/api';
import { Tooltip } from 'react-tooltip';
import { OrgContext } from '../../ContextProvider/OrgContext';

import 'react-tooltip/dist/react-tooltip.css';

// PS_49 
const ChooseServicesModal = ({ onClose, onSave }) => {
    console.log("Rendering ChooseServicesModal"); // This should print when the modal is rendered
    // PS_50 
    const [activeTab, setActiveTab] = useState(null);
    const [selectedServices, setSelectedServices] = useState([]);
    const [Services, setServices] = useState([]);
    const { formData, setFormData } = useContext(FormContext);
    const [userId, setuserId] = useState(localStorage.getItem('userId'));
    console.log("length", selectedServices.length);
    const {
        userRoles,
        orgId,
        providerId,
        architectures,
        canCreateWorkload,
        canDeleteWorkload,
        canViewWorkload,
        setuserRoles,
        setRepository_id, setrepository_name,


        // Add organization and provider fields here
        providerName,
        orgData,
        setOrgData,
        setArchitectures,

        envId,             // Environment ID
        workloadId,        // Workload ID
        aiArchitecture,
        viewMode,
        envName,
        language,
        archType,
        business,
        rulebook,
        archDesc,
        services,

        lastBuildID,
        lastBuildDate,
        lastBuildMessage,

        repoId,
        repoName,

        PulseOrganization_id,
        PulseVertical_id,
        PulseVertical_name,
        PulseOrganization_name,
        PulseIdentification,

        setEnvId           // Function to update environment ID
    } = useContext(OrgContext);
    useEffect(() => {
        loadServices(); // Fetch rules when the component mounts
        if (Services && Services.length > 0 && activeTab === null) {
            setActiveTab(Services[0].serviceCategory); // Set the first category as active
        }
    }, [Services, activeTab]);

    useEffect(() => {
        // Check if formData and formData.services are defined and properly structured
        if (formData?.services && Array.isArray(formData.services) && formData.services.length > 0) {
            const initializedServices = formData.services.reduce((acc, category) => {
                if (!category.service || !Array.isArray(category.service)) {
                    return acc; // Skip if service is not an array
                }
                acc[category.categoryId] = category.service.map(service => ({
                    service_id: service.serviceId,
                    serviceName: service.serviceName,
                    check: true // Assuming all services are selected initially
                }));
                return acc;
            }, {});

            // Ensure initializedServices is defined and correctly structured
            if (initializedServices) {
                // Convert initializedServices to an array of categories
                const servicesArray = Object.values(initializedServices).flat();
        
                // Check if the 0th index has a service_id
                if (servicesArray.length > 0 && servicesArray[0]?.service_id) {
                    console.log("idddd",servicesArray[0]?.service_id);
                    const selectedServiceIds = servicesArray.map(service => service.service_id);
                    setSelectedServices(selectedServiceIds); // Set selected service IDs
                } else {
                    console.log("No valid service_id found at index 0, leaving selectedServices unchanged");
                    // Optionally do nothing or handle the case where no valid IDs are found
                }
            }
        } else {
            console.error("formData.services is undefined or not in the expected structure");
            setSelectedServices([]); // Set to empty array if formData.services does not exist or is empty
        }
    }, [formData.workloadId, formData.services]);
    // PS_52 
    const handleServiceToggle = (service) => {
        setSelectedServices((prev) => {
            const isSelected = prev.includes(service.service_id); // Check if the service ID is already selected

            if (isSelected) {
                return prev.filter(selectedServiceId => selectedServiceId !== service.service_id); // Remove from selection
            } else {
                return [...prev, service.service_id]; // Add only the service ID
            }
        });
    };
    //ps_53


    const handleSave = () => {
        console.log("Saving services...");

        // Check if Services is an array before mapping
        if (!Array.isArray(Services)) {
            console.error("Expected Services to be an array but got:", Services);
            return; // Exit early if Services is not an array
        }

        // Constructing the services structure for formData
        const newServices = Services.map(category => {
            const servicesInCategory = category.services.filter(service =>
                selectedServices.includes(service.service_id) // Use includes to check if the service ID is selected
            );

            return {
                categoryId: category?.services[0]?.categoryId, // Set categoryId from the category object
                categoryName: category?.serviceCategory, // Set categoryName from the serviceCategory property
                service: servicesInCategory?.map(service => ({
                    serviceId: service?.service_id,
                    serviceName: service?.serviceName
                }))
            };
        }).filter(category => category.service.length > 0); // Filter out categories without selected services

        // Call onSave with new services
        onSave(newServices);

        console.log('Updated Form Data:', { services: newServices });
    };

    const loadServices = async () => {
        const payload = {
            cloudproviderID: formData.cloudProviderId,
            userId: userId,
            orgId: orgId,
            searchData: ""
        };

        try {
            const response = await getAllServicesApi(payload); // Call the API function
            console.log('Services fetched successfully:1');

            if (response.body.success) {
                console.log('Services fetched successfully:');
                console.log(response.body.data);
                setServices(response.body.data); // Update state with fetched services

            } else {
                console.error('Error fetching services');
            }
        } catch (error) {
            console.error('An error occurred while fetching services:', error);
        }
    };
    console.log("servicess");
    console.log(Services);
    const [isAtBottom, setIsAtBottom] = useState(false);

    const handleScroll = (event) => {
        const { scrollTop, scrollHeight, clientHeight } = event.target;
        const atBottom = scrollTop + clientHeight >= scrollHeight - 1; // Allow a small margin
        console.log("Scroll Position:", scrollTop, "Client Height:", clientHeight, "Scroll Height:", scrollHeight);
        console.log("Is at bottom:", atBottom);
        setIsAtBottom(atBottom);
    };

    return (
        <div className="modal fade show" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }} tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl modal-dialog-centered justify-content-center">
            <div className="modal-content border-0">
                <div className="modal-header border-0 p-32">
                    <h1 className="modal-title font-20 font-bold black-v2" id="staticBackdropLabel">Choose Services</h1>
                    <button type="button" className="btn shadow-none" onClick={onClose} aria-label="Close">
                        <img src="images/close-icon.svg" alt="close-icon" className="icon-hover shadow-none modal-close-filter" />
                    </button>
                </div>
                <div className="modal-body px-4 pt-0 pb-4">
                    <div className="row">
                        {/* Sidebar Menu Start */}
                        <div className="col-md-3 custom-border-right ps-4 pe-1 cursor-pointer">
    <ul className="nav nav-pills flex-column mb-auto flex-nowrap nav-overflow" id="pills-tab" role="tablist">
        {Services?.map((category, index) => (
            <li className="nav-item" key={index}>
                <button
                    className={`nav-link side-nav-item modal-menu d-flex align-items-center ${activeTab === category.serviceCategory ? 'active' : ''}`}
                    onClick={() => setActiveTab(category.serviceCategory)}
                    style={{
                        width: '250px', // Increased width for better visibility
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                    }}
                    title={category?.serviceCategory} // Tooltip with full name
                >
                    {category?.serviceCategory}
                </button>
            </li>
        ))}
    </ul>
</div>
                        {/* Sidebar Menu End */}
    
                        {/* Tab Content Start */}
                        <div className="col-md-9 px-4 pt-3">
    <div className="tab-content" id="pills-tabContent">
      {Services?.map((category) => (
        <div
          key={category?.serviceCategory}
          className={`tab-pane fade ${activeTab === category?.serviceCategory ? 'show active' : ''}`}
          id={`pills-${category.serviceCategory}`}
          role="tabpanel"
          aria-labelledby={`pills-${category?.serviceCategory}-tab`}>
          <h5 className="font-16 font-medium black-v2 mb-3">{`${category?.serviceCategory} Services`}</h5>
          <div
            className="d-flex flex-wrap gap-3 py-3 service-scrl"
            style={{ maxHeight: '50vh', overflowY: 'auto' }}>
            {category?.services?.map((service) => {
              const displayName = service.serviceName.replace(/\s+/g, '');
              return (
                <div
                  key={service.service_id}
                  className={`service-container cursor-pointer ${selectedServices.includes(service.service_id) ? 'active' : ''}`}
                  onClick={() => handleServiceToggle(service)}>
                  <img
                    src={service.serviceImage}
                    alt={service.serviceName}
                    className="service-image"
                    style={{
                      width: '50px',
                      height: '50px',
                      objectFit: 'cover'
                    }}
                  />
                  <p
            key={service.service_id}
            className="font-16 font-medium black-v2 mt-3 mb-0"
            title={displayName.length > 10 ? displayName : ''} // Add title conditionally
          >
            {displayName.length > 10
              ? `${displayName.slice(0, 10)}...`
              : displayName}
          </p>
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  </div>
                        {/* Tab Content End */}
                    </div>
    
                    {/* Footer Buttons */}
                    {/* {selectedServices.length > 0 && ( */}
                        <div className="d-flex justify-content-between align-items-center ptt-5 mt-2 mb-1 px-3"> {/* Adjusted margin-top */}
                            <p className="font-14 font-medium secondary-color mb-0"><span className="primary-color">{selectedServices.length}</span> services selected</p>
                            <div className="d-flex gap-3">
                                <button type="button" className="button outline-button text-nowrap px-4" onClick={() => onClose()}>Cancel</button>
                                <button type="button"
                                    className={`button primary-button text-nowrap px-3 ${selectedServices.length === 0 && "disabled"}`}
                                    onClick={() => { handleSave(); onClose(); }}>
                                    Proceed
                                </button>
                            </div>
                        </div>
                    {/* )} */}
                </div>
            </div>
        </div>
    </div>


    );
};

export default ChooseServicesModal; // Ensure this line exports the component correctly