export default function AwsSDKEC2({ values, onChangeOfConfiguration, isViewer }) {


    try {
        return (
            <>
                    <div
                        class="accordion-body p-0 estimation-container">
                        <div className="row py-4 mx-4 px-5">
                            <div className="col-md-6 px-3">
                                <label htmlFor="region" className="form-label font-14 font-medium black-v2 mb-3">
                                    Region
                                </label>
                                <select
                                    className="form-select custom-form font-semibold font-14 form-select-sm"
                                    id="region"
                                    style={{paddingTop :"5px", paddingBottom : "5px"}}
                                    value={values.region}
                                    name={"region"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                >
                                    <option value="us-east-1">us-east-1</option>
                                    <option value="ap-south-2">ap-south-2</option><option value="ap-southeast-3">ap-southeast-3</option><option value="ap-southeast-1">ap-southeast-1</option><option value="us-gov-east-1">us-gov-east-1</option><option value="eu-west-2">eu-west-2</option><option value="af-south-1">af-south-1</option><option value="ap-northeast-1">ap-northeast-1</option><option value="ap-southeast-2">ap-southeast-2</option><option value="us-east-2">us-east-2</option><option value="eu-central-1">eu-central-1</option><option value="ca-central-1">ca-central-1</option><option value="ap-south-1">ap-south-1</option><option value="ap-northeast-3">ap-northeast-3</option><option value="sa-east-1">sa-east-1</option><option value="ap-east-1">ap-east-1</option><option value="eu-west-1">eu-west-1</option><option value="eu-south-1">eu-south-1</option><option value="ap-southeast-4">ap-southeast-4</option><option value="eu-north-1">eu-north-1</option><option value="eu-south-2">eu-south-2</option><option value="us-west-1">us-west-1</option><option value="us-west-2">us-west-2</option><option value="me-south-1">me-south-1</option><option value="eu-central-2">eu-central-2</option><option value="us-gov-west-1">us-gov-west-1</option><option value="ap-northeast-2">ap-northeast-2</option><option value="me-central-1">me-central-1</option>
                                    <option value="eu-west-3">eu-west-3</option>
                                </select>
                            </div>
                            <div className="col-md-6 px-3">
                                <label htmlFor="tenancy" className="form-label font-14 font-medium black-v2 mb-3">
                                    Tenancy
                                </label>
                                <select
                                    className="form-select custom-form font-semibold font-14 form-select-sm mb-3"
                                    id="tenancy"
                                    style={{paddingTop :"5px", paddingBottom : "5px"}}
                                    name={"tenancy"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                >
                                    <option value="sharedInstances">Shared Instances</option>
                                    <option value="dedicatedInstances">Dedicated Instances</option>
                                    <option value="dedicatedHost">Dedicated Host</option>
                                </select>
                            </div>
                            {values.tenancy != "dedicatedHost" ? (<div className="col-md-6 px-3">
                                <label htmlFor="region1" className="form-label font-14 font-medium black-v2 mb-3">
                                    Operating System
                                </label>
                                <select
                                    className="form-select custom-form font-semibold font-14 form-select-sm"
                                    id="region1"
                                    style={{paddingTop :"5px", paddingBottom : "5px"}}
                                    value={values.operatingSystem}
                                    name={"operatingSystem"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                >
                                    <option value={"Linux"}>Linux</option>
                                    <option value={"Windows"}>Windows</option>
                                </select>
                            </div>) : null}
                            <div className="col-md-6 px-3">
                                <label htmlFor="region1" className="form-label font-14 font-medium black-v2 mb-3">
                                    Workloads
                                </label>
                                <select
                                    className="form-select custom-form font-semibold font-14 form-select-sm mb-3"
                                    id="region1"
                                    style={{paddingTop :"5px", paddingBottom : "5px"}}
                                    value={"Constant Usage"}
                                    name={"WorkLoads"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                >
                                    <option value={"Constant Usage"}>Constant Usage</option>
                                </select>
                            </div>
                            <div >
                                <label htmlFor="region1" className="form-label font-14 font-medium black-v2 mb-3">
                                    No Of Instance
                                </label>
                                <input
                                    type="text"                                    
                                    className="form-control custom-form text-field font-14 font-medium mb-3"
                                    id="noOfRequest1"
                                    value={values.noOfInstance}
                                    name={"noOfInstance"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}

                                />
                            </div>
                            <label style={{ paddingBottom: '10px', fontWeight: 'bold'}}>EC2 Instance</label>
                            <div >
                                <label htmlFor="region1" className="form-label font-14 font-medium black-v2">
                                    Instance Family
                                </label>
                                <select
                                    className="form-select custom-form font-semibold font-14 form-select-sm"
                                    style={{paddingTop :"5px", paddingBottom : "5px"}}
                                    id="region1"
                                    value={values.instanceType}
                                    name={"instanceType"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                >
                                    <option value="a1">a1</option>
                                    <option value="a1.2xlarge">a1.2xlarge</option>
                                    <option value="a1.4xlarge">a1.4xlarge</option>
                                    <option value="a1.large">a1.large</option>
                                    <option value="a1.medium">a1.medium</option>
                                    <option value="a1.metal">a1.metal</option>
                                    <option value="a1.xlarge">a1.xlarge</option>
                                    <option value="c1.medium">c1.medium</option>
                                    <option value="c1.xlarge">c1.xlarge</option>
                                    <option value="c3">c3</option>
                                    <option value="c3.2xlarge">c3.2xlarge</option>
                                    <option value="c3.4xlarge">c3.4xlarge</option>
                                    <option value="c3.8xlarge">c3.8xlarge</option>
                                    <option value="c3.large">c3.large</option>
                                    <option value="c3.xlarge">c3.xlarge</option>
                                    <option value="c4">c4</option>
                                    <option value="c4.2xlarge">c4.2xlarge</option>
                                    <option value="c4.4xlarge">c4.4xlarge</option>
                                    <option value="c4.8xlarge">c4.8xlarge</option>
                                    <option value="c4.large">c4.large</option>
                                    <option value="c4.xlarge">c4.xlarge</option>
                                    <option value="c5">c5</option>
                                    <option value="c5.12xlarge">c5.12xlarge</option>
                                    <option value="c5.18xlarge">c5.18xlarge</option>
                                    <option value="c5.24xlarge">c5.24xlarge</option>
                                    <option value="c5.2xlarge">c5.2xlarge</option>
                                    <option value="c5.4xlarge">c5.4xlarge</option>
                                    <option value="c5.9xlarge">c5.9xlarge</option>
                                    <option value="c5.large">c5.large</option>
                                    <option value="c5.metal">c5.metal</option>
                                    <option value="c5.xlarge">c5.xlarge</option>
                                    <option value="c5a.12xlarge">c5a.12xlarge</option>
                                    <option value="c5a.16xlarge">c5a.16xlarge</option>
                                    <option value="c5a.24xlarge">c5a.24xlarge</option>
                                    <option value="c5a.2xlarge">c5a.2xlarge</option>
                                    <option value="c5a.4xlarge">c5a.4xlarge</option>
                                    <option value="c5a.8xlarge">c5a.8xlarge</option>
                                    <option value="c5a.large">c5a.large</option>
                                    <option value="c5a.xlarge">c5a.xlarge</option>
                                    <option value="c5ad.12xlarge">c5ad.12xlarge</option>
                                    <option value="c5ad.16xlarge">c5ad.16xlarge</option>
                                    <option value="c5ad.24xlarge">c5ad.24xlarge</option>
                                    <option value="c5ad.2xlarge">c5ad.2xlarge</option>
                                    <option value="c5ad.4xlarge">c5ad.4xlarge</option>
                                    <option value="c5ad.8xlarge">c5ad.8xlarge</option>
                                    <option value="c5ad.large">c5ad.large</option>
                                    <option value="c5ad.xlarge">c5ad.xlarge</option>
                                    <option value="c5d">c5d</option>
                                    <option value="c5d.12xlarge">c5d.12xlarge</option>
                                    <option value="c5d.18xlarge">c5d.18xlarge</option>
                                    <option value="c5d.24xlarge">c5d.24xlarge</option>
                                    <option value="c5d.2xlarge">c5d.2xlarge</option>
                                    <option value="c5d.4xlarge">c5d.4xlarge</option>
                                    <option value="c5d.9xlarge">c5d.9xlarge</option>
                                    <option value="c5d.large">c5d.large</option>
                                    <option value="c5d.metal">c5d.metal</option>
                                    <option value="c5d.xlarge">c5d.xlarge</option>
                                    <option value="c5n">c5n</option>
                                    <option value="c5n.18xlarge">c5n.18xlarge</option>
                                    <option value="c5n.2xlarge">c5n.2xlarge</option>
                                    <option value="c5n.4xlarge">c5n.4xlarge</option>
                                    <option value="c5n.9xlarge">c5n.9xlarge</option>
                                    <option value="c5n.large">c5n.large</option>
                                    <option value="c5n.metal">c5n.metal</option>
                                    <option value="c5n.xlarge">c5n.xlarge</option>
                                    <option value="c6a">c6a</option>
                                    <option value="c6a.12xlarge">c6a.12xlarge</option>
                                    <option value="c6a.16xlarge">c6a.16xlarge</option>
                                    <option value="c6a.24xlarge">c6a.24xlarge</option>
                                    <option value="c6a.2xlarge">c6a.2xlarge</option>
                                    <option value="c6a.32xlarge">c6a.32xlarge</option>
                                    <option value="c6a.48xlarge">c6a.48xlarge</option>
                                    <option value="c6a.4xlarge">c6a.4xlarge</option>
                                    <option value="c6a.8xlarge">c6a.8xlarge</option>
                                    <option value="c6a.large">c6a.large</option>
                                    <option value="c6a.metal">c6a.metal</option>
                                    <option value="c6a.xlarge">c6a.xlarge</option>
                                    <option value="c6g">c6g</option>
                                    <option value="c6g.12xlarge">c6g.12xlarge</option>
                                    <option value="c6g.16xlarge">c6g.16xlarge</option>
                                    <option value="c6g.2xlarge">c6g.2xlarge</option>
                                    <option value="c6g.4xlarge">c6g.4xlarge</option>
                                    <option value="c6g.8xlarge">c6g.8xlarge</option>
                                    <option value="c6g.large">c6g.large</option>
                                    <option value="c6g.medium">c6g.medium</option>
                                    <option value="c6g.metal">c6g.metal</option>
                                    <option value="c6g.xlarge">c6g.xlarge</option>
                                    <option value="c6gd">c6gd</option>
                                    <option value="c6gd.12xlarge">c6gd.12xlarge</option>
                                    <option value="c6gd.16xlarge">c6gd.16xlarge</option>
                                    <option value="c6gd.2xlarge">c6gd.2xlarge</option>
                                    <option value="c6gd.4xlarge">c6gd.4xlarge</option>
                                    <option value="c6gd.8xlarge">c6gd.8xlarge</option>
                                    <option value="c6gd.large">c6gd.large</option>
                                    <option value="c6gd.medium">c6gd.medium</option>
                                    <option value="c6gd.metal">c6gd.metal</option>
                                    <option value="c6gd.xlarge">c6gd.xlarge</option>
                                    <option value="c6gn">c6gn</option>
                                    <option value="c6gn.12xlarge">c6gn.12xlarge</option>
                                    <option value="c6gn.16xlarge">c6gn.16xlarge</option>
                                    <option value="c6gn.2xlarge">c6gn.2xlarge</option>
                                    <option value="c6gn.4xlarge">c6gn.4xlarge</option>
                                    <option value="c6gn.8xlarge">c6gn.8xlarge</option>
                                    <option value="c6gn.large">c6gn.large</option>
                                    <option value="c6gn.medium">c6gn.medium</option>
                                    <option value="c6gn.metal">c6gn.metal</option>
                                    <option value="c6gn.xlarge">c6gn.xlarge</option>
                                    <option value="c6i">c6i</option>
                                    <option value="c6i.12xlarge">c6i.12xlarge</option>
                                    <option value="c6i.16xlarge">c6i.16xlarge</option>
                                    <option value="c6i.24xlarge">c6i.24xlarge</option>
                                    <option value="c6i.2xlarge">c6i.2xlarge</option>
                                    <option value="c6i.32xlarge">c6i.32xlarge</option>
                                    <option value="c6i.4xlarge">c6i.4xlarge</option>
                                    <option value="c6i.8xlarge">c6i.8xlarge</option>
                                    <option value="c6i.large">c6i.large</option>
                                    <option value="c6i.metal">c6i.metal</option>
                                    <option value="c6i.xlarge">c6i.xlarge</option>
                                    <option value="c6id">c6id</option>
                                    <option value="c6id.12xlarge">c6id.12xlarge</option>
                                    <option value="c6id.16xlarge">c6id.16xlarge</option>
                                    <option value="c6id.24xlarge">c6id.24xlarge</option>
                                    <option value="c6id.2xlarge">c6id.2xlarge</option>
                                    <option value="c6id.32xlarge">c6id.32xlarge</option>
                                    <option value="c6id.4xlarge">c6id.4xlarge</option>
                                    <option value="c6id.8xlarge">c6id.8xlarge</option>
                                    <option value="c6id.large">c6id.large</option>
                                    <option value="c6id.metal">c6id.metal</option>
                                    <option value="c6id.xlarge">c6id.xlarge</option>
                                    <option value="c7g">c7g</option>
                                    <option value="c7g.12xlarge">c7g.12xlarge</option>
                                    <option value="c7g.16xlarge">c7g.16xlarge</option>
                                    <option value="c7g.2xlarge">c7g.2xlarge</option>
                                    <option value="c7g.4xlarge">c7g.4xlarge</option>
                                    <option value="c7g.8xlarge">c7g.8xlarge</option>
                                    <option value="c7g.large">c7g.large</option>
                                    <option value="c7g.medium">c7g.medium</option>
                                    <option value="c7g.xlarge">c7g.xlarge</option>
                                    <option value="cc2.8xlarge">cc2.8xlarge</option>
                                    <option value="cr1.8xlarge">cr1.8xlarge</option>
                                    <option value="d2">d2</option>
                                    <option value="d2.2xlarge">d2.2xlarge</option>
                                    <option value="d2.4xlarge">d2.4xlarge</option>
                                    <option value="d2.8xlarge">d2.8xlarge</option>
                                    <option value="d2.xlarge">d2.xlarge</option>
                                    <option value="d3.2xlarge">d3.2xlarge</option>
                                    <option value="d3.4xlarge">d3.4xlarge</option>
                                    <option value="d3.8xlarge">d3.8xlarge</option>
                                    <option value="d3.xlarge">d3.xlarge</option>
                                    <option value="d3en.12xlarge">d3en.12xlarge</option>
                                    <option value="d3en.2xlarge">d3en.2xlarge</option>
                                    <option value="d3en.4xlarge">d3en.4xlarge</option>
                                    <option value="d3en.6xlarge">d3en.6xlarge</option>
                                    <option value="d3en.8xlarge">d3en.8xlarge</option>
                                    <option value="d3en.xlarge">d3en.xlarge</option>
                                    <option value="dl1">dl1</option>
                                    <option value="dl1.24xlarge">dl1.24xlarge</option>
                                    <option value="f1">f1</option>
                                    <option value="f1.16xlarge">f1.16xlarge</option>
                                    <option value="f1.2xlarge">f1.2xlarge</option>
                                    <option value="f1.4xlarge">f1.4xlarge</option>
                                    <option value="g2.2xlarge">g2.2xlarge</option>
                                    <option value="g3">g3</option>
                                    <option value="g3.16xlarge">g3.16xlarge</option>
                                    <option value="g3.4xlarge">g3.4xlarge</option>
                                    <option value="g3.8xlarge">g3.8xlarge</option>
                                    <option value="g3s.xlarge">g3s.xlarge</option>
                                    <option value="g4ad">g4ad</option>
                                    <option value="g4ad.16xlarge">g4ad.16xlarge</option>
                                    <option value="g4ad.2xlarge">g4ad.2xlarge</option>
                                    <option value="g4ad.4xlarge">g4ad.4xlarge</option>
                                    <option value="g4ad.8xlarge">g4ad.8xlarge</option>
                                    <option value="g4ad.xlarge">g4ad.xlarge</option>
                                    <option value="g4dn">g4dn</option>
                                    <option value="g4dn.12xlarge">g4dn.12xlarge</option>
                                    <option value="g4dn.16xlarge">g4dn.16xlarge</option>
                                    <option value="g4dn.2xlarge">g4dn.2xlarge</option>
                                    <option value="g4dn.4xlarge">g4dn.4xlarge</option>
                                    <option value="g4dn.8xlarge">g4dn.8xlarge</option>
                                    <option value="g4dn.metal">g4dn.metal</option>
                                    <option value="g4dn.xlarge">g4dn.xlarge</option>
                                    <option value="g5">g5</option>
                                    <option value="g5.12xlarge">g5.12xlarge</option>
                                    <option value="g5.16xlarge">g5.16xlarge</option>
                                    <option value="g5.24xlarge">g5.24xlarge</option>
                                    <option value="g5.2xlarge">g5.2xlarge</option>
                                    <option value="g5.48xlarge">g5.48xlarge</option>
                                    <option value="g5.4xlarge">g5.4xlarge</option>
                                    <option value="g5.8xlarge">g5.8xlarge</option>
                                    <option value="g5.xlarge">g5.xlarge</option>
                                    <option value="g5g">g5g</option>
                                    <option value="g5g.16xlarge">g5g.16xlarge</option>
                                    <option value="g5g.2xlarge">g5g.2xlarge</option>
                                    <option value="g5g.4xlarge">g5g.4xlarge</option>
                                    <option value="g5g.8xlarge">g5g.8xlarge</option>
                                    <option value="g5g.metal">g5g.metal</option>
                                    <option value="g5g.xlarge">g5g.xlarge</option>
                                    <option value="h1">h1</option>
                                    <option value="h1.16xlarge">h1.16xlarge</option>
                                    <option value="h1.2xlarge">h1.2xlarge</option>
                                    <option value="h1.4xlarge">h1.4xlarge</option>
                                    <option value="h1.8xlarge">h1.8xlarge</option>
                                    <option value="hs1.8xlarge">hs1.8xlarge</option>
                                    <option value="i2">i2</option>
                                    <option value="i2.2xlarge">i2.2xlarge</option>
                                    <option value="i2.4xlarge">i2.4xlarge</option>
                                    <option value="i2.8xlarge">i2.8xlarge</option>
                                    <option value="i2.large">i2.large</option>
                                    <option value="i2.xlarge">i2.xlarge</option>
                                    <option value="i3">i3</option>
                                    <option value="i3.16xlarge">i3.16xlarge</option>
                                    <option value="i3.2xlarge">i3.2xlarge</option>
                                    <option value="i3.4xlarge">i3.4xlarge</option>
                                    <option value="i3.8xlarge">i3.8xlarge</option>
                                    <option value="i3.large">i3.large</option>
                                    <option value="i3.metal">i3.metal</option>
                                    <option value="i3.xlarge">i3.xlarge</option>
                                    <option value="i3en">i3en</option>
                                    <option value="i3en.12xlarge">i3en.12xlarge</option>
                                    <option value="i3en.24xlarge">i3en.24xlarge</option>
                                    <option value="i3en.2xlarge">i3en.2xlarge</option>
                                    <option value="i3en.3xlarge">i3en.3xlarge</option>
                                    <option value="i3en.6xlarge">i3en.6xlarge</option>
                                    <option value="i3en.large">i3en.large</option>
                                    <option value="i3en.metal">i3en.metal</option>
                                    <option value="i3en.xlarge">i3en.xlarge</option>
                                    <option value="i4i">i4i</option>
                                    <option value="i4i.16xlarge">i4i.16xlarge</option>
                                    <option value="i4i.2xlarge">i4i.2xlarge</option>
                                    <option value="i4i.32xlarge">i4i.32xlarge</option>
                                    <option value="i4i.4xlarge">i4i.4xlarge</option>
                                    <option value="i4i.8xlarge">i4i.8xlarge</option>
                                    <option value="i4i.large">i4i.large</option>
                                    <option value="i4i.metal">i4i.metal</option>
                                    <option value="i4i.xlarge">i4i.xlarge</option>
                                    <option value="im4gn">im4gn</option>
                                    <option value="im4gn.16xlarge">im4gn.16xlarge</option>
                                    <option value="im4gn.2xlarge">im4gn.2xlarge</option>
                                    <option value="im4gn.4xlarge">im4gn.4xlarge</option>
                                    <option value="im4gn.8xlarge">im4gn.8xlarge</option>
                                    <option value="im4gn.large">im4gn.large</option>
                                    <option value="im4gn.xlarge">im4gn.xlarge</option>
                                    <option value="inf1">inf1</option>
                                    <option value="inf1.24xlarge">inf1.24xlarge</option>
                                    <option value="inf1.2xlarge">inf1.2xlarge</option>
                                    <option value="inf1.6xlarge">inf1.6xlarge</option>
                                    <option value="inf1.xlarge">inf1.xlarge</option>
                                    <option value="is4gen.2xlarge">is4gen.2xlarge</option>
                                    <option value="is4gen.4xlarge">is4gen.4xlarge</option>
                                    <option value="is4gen.8xlarge">is4gen.8xlarge</option>
                                    <option value="is4gen.large">is4gen.large</option>
                                    <option value="is4gen.medium">is4gen.medium</option>
                                    <option value="is4gen.xlarge">is4gen.xlarge</option>
                                    <option value="m1.large">m1.large</option>
                                    <option value="m1.medium">m1.medium</option>
                                    <option value="m1.small">m1.small</option>
                                    <option value="m1.xlarge">m1.xlarge</option>
                                    <option value="m2.2xlarge">m2.2xlarge</option>
                                    <option value="m2.4xlarge">m2.4xlarge</option>
                                    <option value="m2.xlarge">m2.xlarge</option>
                                    <option value="m3">m3</option>
                                    <option value="m3.2xlarge">m3.2xlarge</option>
                                    <option value="m3.large">m3.large</option>
                                    <option value="m3.medium">m3.medium</option>
                                    <option value="m3.xlarge">m3.xlarge</option>
                                    <option value="m4">m4</option>
                                    <option value="m4.10xlarge">m4.10xlarge</option>
                                    <option value="m4.16xlarge">m4.16xlarge</option>
                                    <option value="m4.2xlarge">m4.2xlarge</option>
                                    <option value="m4.4xlarge">m4.4xlarge</option>
                                    <option value="m4.large">m4.large</option>
                                    <option value="m4.xlarge">m4.xlarge</option>
                                    <option value="m5">m5</option>
                                    <option value="m5.12xlarge">m5.12xlarge</option>
                                    <option value="m5.16xlarge">m5.16xlarge</option>
                                    <option value="m5.24xlarge">m5.24xlarge</option>
                                    <option value="m5.2xlarge">m5.2xlarge</option>
                                    <option value="m5.4xlarge">m5.4xlarge</option>
                                    <option value="m5.8xlarge">m5.8xlarge</option>
                                    <option value="m5.large">m5.large</option>
                                    <option value="m5.metal">m5.metal</option>
                                    <option value="m5.xlarge">m5.xlarge</option>
                                    <option value="m5a.12xlarge">m5a.12xlarge</option>
                                    <option value="m5a.16xlarge">m5a.16xlarge</option>
                                    <option value="m5a.24xlarge">m5a.24xlarge</option>
                                    <option value="m5a.2xlarge">m5a.2xlarge</option>
                                    <option value="m5a.4xlarge">m5a.4xlarge</option>
                                    <option value="m5a.8xlarge">m5a.8xlarge</option>
                                    <option value="m5a.large">m5a.large</option>
                                    <option value="m5a.xlarge">m5a.xlarge</option>
                                    <option value="m5ad.12xlarge">m5ad.12xlarge</option>
                                    <option value="m5ad.16xlarge">m5ad.16xlarge</option>
                                    <option value="m5ad.24xlarge">m5ad.24xlarge</option>
                                    <option value="m5ad.2xlarge">m5ad.2xlarge</option>
                                    <option value="m5ad.4xlarge">m5ad.4xlarge</option>
                                    <option value="m5ad.8xlarge">m5ad.8xlarge</option>
                                    <option value="m5ad.large">m5ad.large</option>
                                    <option value="m5ad.xlarge">m5ad.xlarge</option>
                                    <option value="m5d">m5d</option>
                                    <option value="m5d.12xlarge">m5d.12xlarge</option>
                                    <option value="m5d.16xlarge">m5d.16xlarge</option>
                                    <option value="m5d.24xlarge">m5d.24xlarge</option>
                                    <option value="m5d.2xlarge">m5d.2xlarge</option>
                                    <option value="m5d.4xlarge">m5d.4xlarge</option>
                                    <option value="m5d.8xlarge">m5d.8xlarge</option>
                                    <option value="m5d.large">m5d.large</option>
                                    <option value="m5d.metal">m5d.metal</option>
                                    <option value="m5d.xlarge">m5d.xlarge</option>
                                    <option value="m5dn">m5dn</option>
                                    <option value="m5dn.12xlarge">m5dn.12xlarge</option>
                                    <option value="m5dn.16xlarge">m5dn.16xlarge</option>
                                    <option value="m5dn.24xlarge">m5dn.24xlarge</option>
                                    <option value="m5dn.2xlarge">m5dn.2xlarge</option>
                                    <option value="m5dn.4xlarge">m5dn.4xlarge</option>
                                    <option value="m5dn.8xlarge">m5dn.8xlarge</option>
                                    <option value="m5dn.large">m5dn.large</option>
                                    <option value="m5dn.metal">m5dn.metal</option>
                                    <option value="m5dn.xlarge">m5dn.xlarge</option>
                                    <option value="m5n">m5n</option>
                                    <option value="m5n.12xlarge">m5n.12xlarge</option>
                                    <option value="m5n.16xlarge">m5n.16xlarge</option>
                                    <option value="m5n.24xlarge">m5n.24xlarge</option>
                                    <option value="m5n.2xlarge">m5n.2xlarge</option>
                                    <option value="m5n.4xlarge">m5n.4xlarge</option>
                                    <option value="m5n.8xlarge">m5n.8xlarge</option>
                                    <option value="m5n.large">m5n.large</option>
                                    <option value="m5n.metal">m5n.metal</option>
                                    <option value="m5n.xlarge">m5n.xlarge</option>
                                    <option value="m5zn">m5zn</option>
                                    <option value="m5zn.12xlarge">m5zn.12xlarge</option>
                                    <option value="m5zn.2xlarge">m5zn.2xlarge</option>
                                    <option value="m5zn.3xlarge">m5zn.3xlarge</option>
                                    <option value="m5zn.6xlarge">m5zn.6xlarge</option>
                                    <option value="m5zn.large">m5zn.large</option>
                                    <option value="m5zn.metal">m5zn.metal</option>
                                    <option value="m5zn.xlarge">m5zn.xlarge</option>
                                    <option value="m6a">m6a</option>
                                    <option value="m6a.12xlarge">m6a.12xlarge</option>
                                    <option value="m6a.16xlarge">m6a.16xlarge</option>
                                    <option value="m6a.24xlarge">m6a.24xlarge</option>
                                    <option value="m6a.2xlarge">m6a.2xlarge</option>
                                    <option value="m6a.32xlarge">m6a.32xlarge</option>
                                    <option value="m6a.48xlarge">m6a.48xlarge</option>
                                    <option value="m6a.4xlarge">m6a.4xlarge</option>
                                    <option value="m6a.8xlarge">m6a.8xlarge</option>
                                    <option value="m6a.large">m6a.large</option>
                                    <option value="m6a.metal">m6a.metal</option>
                                    <option value="m6a.xlarge">m6a.xlarge</option>
                                    <option value="m6g">m6g</option>
                                    <option value="m6g.12xlarge">m6g.12xlarge</option>
                                    <option value="m6g.16xlarge">m6g.16xlarge</option>
                                    <option value="m6g.2xlarge">m6g.2xlarge</option>
                                    <option value="m6g.4xlarge">m6g.4xlarge</option>
                                    <option value="m6g.8xlarge">m6g.8xlarge</option>
                                    <option value="m6g.large">m6g.large</option>
                                    <option value="m6g.medium">m6g.medium</option>
                                    <option value="m6g.metal">m6g.metal</option>
                                    <option value="m6g.xlarge">m6g.xlarge</option>
                                    <option value="m6gd">m6gd</option>
                                    <option value="m6gd.12xlarge">m6gd.12xlarge</option>
                                    <option value="m6gd.16xlarge">m6gd.16xlarge</option>
                                    <option value="m6gd.2xlarge">m6gd.2xlarge</option>
                                    <option value="m6gd.4xlarge">m6gd.4xlarge</option>
                                    <option value="m6gd.8xlarge">m6gd.8xlarge</option>
                                    <option value="m6gd.large">m6gd.large</option>
                                    <option value="m6gd.medium">m6gd.medium</option>
                                    <option value="m6gd.metal">m6gd.metal</option>
                                    <option value="m6gd.xlarge">m6gd.xlarge</option>
                                    <option value="m6i">m6i</option>
                                    <option value="m6i.12xlarge">m6i.12xlarge</option>
                                    <option value="m6i.16xlarge">m6i.16xlarge</option>
                                    <option value="m6i.24xlarge">m6i.24xlarge</option>
                                    <option value="m6i.2xlarge">m6i.2xlarge</option>
                                    <option value="m6i.32xlarge">m6i.32xlarge</option>
                                    <option value="m6i.4xlarge">m6i.4xlarge</option>
                                    <option value="m6i.8xlarge">m6i.8xlarge</option>
                                    <option value="m6i.large">m6i.large</option>
                                    <option value="m6i.metal">m6i.metal</option>
                                    <option value="m6i.xlarge">m6i.xlarge</option>
                                    <option value="m6id">m6id</option>
                                    <option value="m6id.12xlarge">m6id.12xlarge</option>
                                    <option value="m6id.16xlarge">m6id.16xlarge</option>
                                    <option value="m6id.24xlarge">m6id.24xlarge</option>
                                    <option value="m6id.2xlarge">m6id.2xlarge</option>
                                    <option value="m6id.32xlarge">m6id.32xlarge</option>
                                    <option value="m6id.4xlarge">m6id.4xlarge</option>
                                    <option value="m6id.8xlarge">m6id.8xlarge</option>
                                    <option value="m6id.large">m6id.large</option>
                                    <option value="m6id.metal">m6id.metal</option>
                                    <option value="m6id.xlarge">m6id.xlarge</option>
                                    <option value="mac1">mac1</option>
                                    <option value="mac1.metal">mac1.metal</option>
                                    <option value="mac2">mac2</option>
                                    <option value="mac2.metal">mac2.metal</option>
                                    <option value="p2">p2</option>
                                    <option value="p2.16xlarge">p2.16xlarge</option>
                                    <option value="p2.8xlarge">p2.8xlarge</option>
                                    <option value="p2.xlarge">p2.xlarge</option>
                                    <option value="p3">p3</option>
                                    <option value="p3.16xlarge">p3.16xlarge</option>
                                    <option value="p3.2xlarge">p3.2xlarge</option>
                                    <option value="p3.8xlarge">p3.8xlarge</option>
                                    <option value="p3dn">p3dn</option>
                                    <option value="p3dn.24xlarge">p3dn.24xlarge</option>
                                    <option value="p4d">p4d</option>
                                    <option value="p4d.24xlarge">p4d.24xlarge</option>
                                    <option value="p4de">p4de</option>
                                    <option value="p4de.24xlarge">p4de.24xlarge</option>
                                    <option value="r3">r3</option>
                                    <option value="r3.2xlarge">r3.2xlarge</option>
                                    <option value="r3.4xlarge">r3.4xlarge</option>
                                    <option value="r3.8xlarge">r3.8xlarge</option>
                                    <option value="r3.large">r3.large</option>
                                    <option value="r3.xlarge">r3.xlarge</option>
                                    <option value="r4">r4</option>
                                    <option value="r4.16xlarge">r4.16xlarge</option>
                                    <option value="r4.2xlarge">r4.2xlarge</option>
                                    <option value="r4.4xlarge">r4.4xlarge</option>
                                    <option value="r4.8xlarge">r4.8xlarge</option>
                                    <option value="r4.large">r4.large</option>
                                    <option value="r4.xlarge">r4.xlarge</option>
                                    <option value="r5">r5</option>
                                    <option value="r5.12xlarge">r5.12xlarge</option>
                                    <option value="r5.16xlarge">r5.16xlarge</option>
                                    <option value="r5.24xlarge">r5.24xlarge</option>
                                    <option value="r5.2xlarge">r5.2xlarge</option>
                                    <option value="r5.4xlarge">r5.4xlarge</option>
                                    <option value="r5.8xlarge">r5.8xlarge</option>
                                    <option value="r5.large">r5.large</option>
                                    <option value="r5.metal">r5.metal</option>
                                    <option value="r5.xlarge">r5.xlarge</option>
                                    <option value="r5a.12xlarge">r5a.12xlarge</option>
                                    <option value="r5a.16xlarge">r5a.16xlarge</option>
                                    <option value="r5a.24xlarge">r5a.24xlarge</option>
                                    <option value="r5a.2xlarge">r5a.2xlarge</option>
                                    <option value="r5a.4xlarge">r5a.4xlarge</option>
                                    <option value="r5a.8xlarge">r5a.8xlarge</option>
                                    <option value="r5a.large">r5a.large</option>
                                    <option value="r5a.xlarge">r5a.xlarge</option>
                                    <option value="r5ad.12xlarge">r5ad.12xlarge</option>
                                    <option value="r5ad.16xlarge">r5ad.16xlarge</option>
                                    <option value="r5ad.24xlarge">r5ad.24xlarge</option>
                                    <option value="r5ad.2xlarge">r5ad.2xlarge</option>
                                    <option value="r5ad.4xlarge">r5ad.4xlarge</option>
                                    <option value="r5ad.8xlarge">r5ad.8xlarge</option>
                                    <option value="r5ad.large">r5ad.large</option>
                                    <option value="r5ad.xlarge">r5ad.xlarge</option>
                                    <option value="r5b">r5b</option>
                                    <option value="r5b.12xlarge">r5b.12xlarge</option>
                                    <option value="r5b.16xlarge">r5b.16xlarge</option>
                                    <option value="r5b.24xlarge">r5b.24xlarge</option>
                                    <option value="r5b.2xlarge">r5b.2xlarge</option>
                                    <option value="r5b.4xlarge">r5b.4xlarge</option>
                                    <option value="r5b.8xlarge">r5b.8xlarge</option>
                                    <option value="r5b.large">r5b.large</option>
                                    <option value="r5b.metal">r5b.metal</option>
                                    <option value="r5b.xlarge">r5b.xlarge</option>
                                    <option value="r5d">r5d</option>
                                    <option value="r5d.12xlarge">r5d.12xlarge</option>
                                    <option value="r5d.16xlarge">r5d.16xlarge</option>
                                    <option value="r5d.24xlarge">r5d.24xlarge</option>
                                    <option value="r5d.2xlarge">r5d.2xlarge</option>
                                    <option value="r5d.4xlarge">r5d.4xlarge</option>
                                    <option value="r5d.8xlarge">r5d.8xlarge</option>
                                    <option value="r5d.large">r5d.large</option>
                                    <option value="r5d.metal">r5d.metal</option>
                                    <option value="r5d.xlarge">r5d.xlarge</option>
                                    <option value="r5dn">r5dn</option>
                                    <option value="r5dn.12xlarge">r5dn.12xlarge</option>
                                    <option value="r5dn.16xlarge">r5dn.16xlarge</option>
                                    <option value="r5dn.24xlarge">r5dn.24xlarge</option>
                                    <option value="r5dn.2xlarge">r5dn.2xlarge</option>
                                    <option value="r5dn.4xlarge">r5dn.4xlarge</option>
                                    <option value="r5dn.8xlarge">r5dn.8xlarge</option>
                                    <option value="r5dn.large">r5dn.large</option>
                                    <option value="r5dn.metal">r5dn.metal</option>
                                    <option value="r5dn.xlarge">r5dn.xlarge</option>
                                    <option value="r5n">r5n</option>
                                    <option value="r5n.12xlarge">r5n.12xlarge</option>
                                    <option value="r5n.16xlarge">r5n.16xlarge</option>
                                    <option value="r5n.24xlarge">r5n.24xlarge</option>
                                    <option value="r5n.2xlarge">r5n.2xlarge</option>
                                    <option value="r5n.4xlarge">r5n.4xlarge</option>
                                    <option value="r5n.8xlarge">r5n.8xlarge</option>
                                    <option value="r5n.large">r5n.large</option>
                                    <option value="r5n.metal">r5n.metal</option>
                                    <option value="r5n.xlarge">r5n.xlarge</option>
                                    <option value="r6a">r6a</option>
                                    <option value="r6a.12xlarge">r6a.12xlarge</option>
                                    <option value="r6a.16xlarge">r6a.16xlarge</option>
                                    <option value="r6a.24xlarge">r6a.24xlarge</option>
                                    <option value="r6a.2xlarge">r6a.2xlarge</option>
                                    <option value="r6a.32xlarge">r6a.32xlarge</option>
                                    <option value="r6a.48xlarge">r6a.48xlarge</option>
                                    <option value="r6a.4xlarge">r6a.4xlarge</option>
                                    <option value="r6a.8xlarge">r6a.8xlarge</option>
                                    <option value="r6a.large">r6a.large</option>
                                    <option value="r6a.metal">r6a.metal</option>
                                    <option value="r6a.xlarge">r6a.xlarge</option>
                                    <option value="r6g">r6g</option>
                                    <option value="r6g.12xlarge">r6g.12xlarge</option>
                                    <option value="r6g.16xlarge">r6g.16xlarge</option>
                                    <option value="r6g.2xlarge">r6g.2xlarge</option>
                                    <option value="r6g.4xlarge">r6g.4xlarge</option>
                                    <option value="r6g.8xlarge">r6g.8xlarge</option>
                                    <option value="r6g.large">r6g.large</option>
                                    <option value="r6g.medium">r6g.medium</option>
                                    <option value="r6g.metal">r6g.metal</option>
                                    <option value="r6g.xlarge">r6g.xlarge</option>
                                    <option value="r6gd">r6gd</option>
                                    <option value="r6gd.12xlarge">r6gd.12xlarge</option>
                                    <option value="r6gd.16xlarge">r6gd.16xlarge</option>
                                    <option value="r6gd.2xlarge">r6gd.2xlarge</option>
                                    <option value="r6gd.4xlarge">r6gd.4xlarge</option>
                                    <option value="r6gd.8xlarge">r6gd.8xlarge</option>
                                    <option value="r6gd.large">r6gd.large</option>
                                    <option value="r6gd.medium">r6gd.medium</option>
                                    <option value="r6gd.metal">r6gd.metal</option>
                                    <option value="r6gd.xlarge">r6gd.xlarge</option>
                                    <option value="r6i">r6i</option>
                                    <option value="r6i.12xlarge">r6i.12xlarge</option>
                                    <option value="r6i.16xlarge">r6i.16xlarge</option>
                                    <option value="r6i.24xlarge">r6i.24xlarge</option>
                                    <option value="r6i.2xlarge">r6i.2xlarge</option>
                                    <option value="r6i.32xlarge">r6i.32xlarge</option>
                                    <option value="r6i.4xlarge">r6i.4xlarge</option>
                                    <option value="r6i.8xlarge">r6i.8xlarge</option>
                                    <option value="r6i.large">r6i.large</option>
                                    <option value="r6i.metal">r6i.metal</option>
                                    <option value="r6i.xlarge">r6i.xlarge</option>
                                    <option value="r6id">r6id</option>
                                    <option value="r6id.12xlarge">r6id.12xlarge</option>
                                    <option value="r6id.16xlarge">r6id.16xlarge</option>
                                    <option value="r6id.24xlarge">r6id.24xlarge</option>
                                    <option value="r6id.2xlarge">r6id.2xlarge</option>
                                    <option value="r6id.32xlarge">r6id.32xlarge</option>
                                    <option value="r6id.4xlarge">r6id.4xlarge</option>
                                    <option value="r6id.8xlarge">r6id.8xlarge</option>
                                    <option value="r6id.large">r6id.large</option>
                                    <option value="r6id.metal">r6id.metal</option>
                                    <option value="r6id.xlarge">r6id.xlarge</option>
                                    <option value="t1.micro">t1.micro</option>
                                    <option value="t2.2xlarge">t2.2xlarge</option>
                                    <option value="t2.large">t2.large</option>
                                    <option value="t2.medium">t2.medium</option>
                                    <option value="t2.micro">t2.micro</option>
                                    <option value="t2.nano">t2.nano</option>
                                    <option value="t2.small">t2.small</option>
                                    <option value="t2.xlarge">t2.xlarge</option>
                                    <option value="t3">t3</option>
                                    <option value="t3.2xlarge">t3.2xlarge</option>
                                    <option value="t3.large">t3.large</option>
                                    <option value="t3.medium">t3.medium</option>
                                    <option value="t3.micro">t3.micro</option>
                                    <option value="t3.nano">t3.nano</option>
                                    <option value="t3.small">t3.small</option>
                                    <option value="t3.xlarge">t3.xlarge</option>
                                    <option value="t3a.2xlarge">t3a.2xlarge</option>
                                    <option value="t3a.large">t3a.large</option>
                                    <option value="t3a.medium">t3a.medium</option>
                                    <option value="t3a.micro">t3a.micro</option>
                                    <option value="t3a.nano">t3a.nano</option>
                                    <option value="t3a.small">t3a.small</option>
                                    <option value="t3a.xlarge">t3a.xlarge</option>
                                    <option value="t4g.2xlarge">t4g.2xlarge</option>
                                    <option value="t4g.large">t4g.large</option>
                                    <option value="t4g.medium">t4g.medium</option>
                                    <option value="t4g.micro">t4g.micro</option>
                                    <option value="t4g.nano">t4g.nano</option>
                                    <option value="t4g.small">t4g.small</option>
                                    <option value="t4g.xlarge">t4g.xlarge</option>
                                    <option value="u-12tb1">u-12tb1</option>
                                    <option value="u-12tb1.112xlarge">u-12tb1.112xlarge</option>
                                    <option value="u-12tb1.metal">u-12tb1.metal</option>
                                    <option value="u-18tb1">u-18tb1</option>
                                    <option value="u-18tb1.metal">u-18tb1.metal</option>
                                    <option value="u-24tb1">u-24tb1</option>
                                    <option value="u-24tb1.metal">u-24tb1.metal</option>
                                    <option value="u-3tb1.56xlarge">u-3tb1.56xlarge</option>
                                    <option value="u-6tb1">u-6tb1</option>
                                    <option value="u-6tb1.112xlarge">u-6tb1.112xlarge</option>
                                    <option value="u-6tb1.56xlarge">u-6tb1.56xlarge</option>
                                    <option value="u-6tb1.metal">u-6tb1.metal</option>
                                    <option value="u-9tb1">u-9tb1</option>
                                    <option value="u-9tb1.112xlarge">u-9tb1.112xlarge</option>
                                    <option value="u-9tb1.metal">u-9tb1.metal</option>
                                    <option value="vt1">vt1</option>
                                    <option value="vt1.24xlarge">vt1.24xlarge</option>
                                    <option value="vt1.3xlarge">vt1.3xlarge</option>
                                    <option value="vt1.6xlarge">vt1.6xlarge</option>
                                    <option value="x1">x1</option>
                                    <option value="x1.16xlarge">x1.16xlarge</option>
                                    <option value="x1.32xlarge">x1.32xlarge</option>
                                    <option value="x1e">x1e</option>
                                    <option value="x1e.16xlarge">x1e.16xlarge</option>
                                    <option value="x1e.2xlarge">x1e.2xlarge</option>
                                    <option value="x1e.32xlarge">x1e.32xlarge</option>
                                    <option value="x1e.4xlarge">x1e.4xlarge</option>
                                    <option value="x1e.8xlarge">x1e.8xlarge</option>
                                    <option value="x1e.xlarge">x1e.xlarge</option>
                                    <option value="x2gd">x2gd</option>
                                    <option value="x2gd.12xlarge">x2gd.12xlarge</option>
                                    <option value="x2gd.16xlarge">x2gd.16xlarge</option>
                                    <option value="x2gd.2xlarge">x2gd.2xlarge</option>
                                    <option value="x2gd.4xlarge">x2gd.4xlarge</option>
                                    <option value="x2gd.8xlarge">x2gd.8xlarge</option>
                                    <option value="x2gd.large">x2gd.large</option>
                                    <option value="x2gd.medium">x2gd.medium</option>
                                    <option value="x2gd.metal">x2gd.metal</option>
                                    <option value="x2gd.xlarge">x2gd.xlarge</option>
                                    <option value="x2idn">x2idn</option>
                                    <option value="x2idn.16xlarge">x2idn.16xlarge</option>
                                    <option value="x2idn.24xlarge">x2idn.24xlarge</option>
                                    <option value="x2idn.32xlarge">x2idn.32xlarge</option>
                                    <option value="x2idn.metal">x2idn.metal</option>
                                    <option value="x2iedn">x2iedn</option>
                                    <option value="x2iedn.16xlarge">x2iedn.16xlarge</option>
                                    <option value="x2iedn.24xlarge">x2iedn.24xlarge</option>
                                    <option value="x2iedn.2xlarge">x2iedn.2xlarge</option>
                                    <option value="x2iedn.32xlarge">x2iedn.32xlarge</option>
                                    <option value="x2iedn.4xlarge">x2iedn.4xlarge</option>
                                    <option value="x2iedn.8xlarge">x2iedn.8xlarge</option>
                                    <option value="x2iedn.metal">x2iedn.metal</option>
                                    <option value="x2iedn.xlarge">x2iedn.xlarge</option>
                                    <option value="x2iezn">x2iezn</option>
                                    <option value="x2iezn.12xlarge">x2iezn.12xlarge</option>
                                    <option value="x2iezn.2xlarge">x2iezn.2xlarge</option>
                                    <option value="x2iezn.4xlarge">x2iezn.4xlarge</option>
                                    <option value="x2iezn.6xlarge">x2iezn.6xlarge</option>
                                    <option value="x2iezn.8xlarge">x2iezn.8xlarge</option>
                                    <option value="x2iezn.metal">x2iezn.metal</option>
                                    <option value="z1d">z1d</option>
                                    <option value="z1d.12xlarge">z1d.12xlarge</option>
                                    <option value="z1d.2xlarge">z1d.2xlarge</option>
                                    <option value="z1d.3xlarge">z1d.3xlarge</option>
                                    <option value="z1d.6xlarge">z1d.6xlarge</option>
                                    <option value="z1d.large">z1d.large</option>
                                    <option value="z1d.metal">z1d.metal</option>
                                    <option value="z1d.xlarge">z1d.xlarge</option>
                                </select>
                            </div>
                        </div>
                        <div className="row py-4 mx-4 px-5">       
                        <h6 style={{fontWeight: 'bold' , paddingBottom: '10px', paddingTop :"-12px", display:"block", marginTop :"-4%"}}>Payment Options</h6>                     
                            <div className="col-md-6 px-3">                            
                                <label htmlFor="region1" className="form-label font-14 font-medium black-v2 mb-3">
                                    Payment Method
                                </label>
                                <select
                                    className="form-select custom-form font-semibold font-14 form-select-sm mb-3"
                                    id="region1"
                                    value={values.operatingSystem}
                                    name={"operatingSystem"}
                                    style={{paddingTop :"5px", paddingBottom : "5px"}}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                >
                                    <option value={"OnDemand"}>On-Demand</option>
                                </select>
                            </div>

                            {values.tenancy != "dedicatedHost" ? (<div className="col-md-6 px-3">
                                <label htmlFor="Usage" className="form-label font-14 font-medium black-v2 mb-3">
                                    Usage
                                </label>
                                <input
                                    type="text"                                    
                                    className="form-control custom-form text-field font-14 font-medium"
                                    id="Usage"
                                    value={values.Usage}
                                    name={"Usage"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}

                                />
                            </div>) : null}
                            {values.tenancy != "dedicatedHost" ? <div >
                                <label htmlFor="UsageType" className="form-label font-14 font-medium black-v2 mb-3">
                                    Usage Type
                                </label>
                                <select
                                    className="form-select custom-form font-semibold font-14 form-select-sm"
                                    id="UsageType"
                                    style={{paddingTop :"5px", paddingBottom : "5px"}}
                                    name={"UsageType"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                >
                                    <option value="Hours/Month">Hour/Month</option>
                                    <option value="Hours/Day">Hour/Day</option>
                                    <option value="Hours/Week">Hour/Week</option>
                                    <option value="Utilization per month">Utilization per month</option>
                                </select>
                            </div> : null}
                        </div>
                    </div>
            </>
        );
    } catch (error) {
        //console.log(error.message);
    }
}