export default function scrapedCost({ values, scrapedCost, isViewer }) {
    try {

        return (
            <>
                <div
                    class="accordion-body p-0 estimation-container">
                    <div className="row py-4 mx-4 px-5">
                        <div className="col-md-6 px-3">
                            <label htmlFor="noOfNAT" className="form-label font-14 font-medium black-v2 mb-3" disabled ={isViewer}>
                                Cost that was scraped from the link you provided : {scrapedCost}
                            </label>
                        </div>
                    </div>
                </div>
            </>
        );
    } catch (error) {
        console.log(error);
    }
}

