import React, { createContext, useState, useContext } from 'react';

export const EstimationContext = createContext();

export const EstimationProvider = ({ children }) => {
  const [setEstimateAsBudget, setSetEstimateAsBudget] = useState(false);
  const [estimationNodes , setEstimationNodes] = useState([])

  return (
    <EstimationContext.Provider value={{ 
      setEstimateAsBudget, 
      setSetEstimateAsBudget,
      estimationNodes,
      setEstimationNodes
       }}>
      {children}
    </EstimationContext.Provider>
  );
};

