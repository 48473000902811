import { createContext, useContext, useEffect, useState } from "react";

import CloudGenTemplates from "./CloudGenTemplates";
import ZebPulseTemplates from "./ZebPulseTemplates";
import { fetchAllEnvironment, fetchAllWorkload, fetchTemplates, fetchZebPulseTemplates, getAllOrganization, getAllOrgApi } from "../../Service/api";
import { fetchAllOrganization } from "../../Service/api";
import { OrgContext } from "../../ContextProvider/OrgContext";
import WorkbechPopup from "../Organization/Workbench/WorkBench/PopupMaintanence/WorkBenchPopUp";
import Loader from "../loader/loader";



export const CloudGenContext = createContext(undefined);
export const ZebPulseContext = createContext(undefined);

function ManageTemplates() {
  const [activeTab, setActiveTab] = useState("cloudgen");

  useEffect(() => {
    console.log(activeTab, "Active Tab")
  }, [activeTab])


  //PS_05
  const [cloudGenTemplates, setCloudGenTemplates] = useState([]);
  const [awsCloudGenTemplate, setAwsCloudGenTemplate] = useState([]);
  const [azureCloudGenTemplate, setAzureCloudGenTemplate] = useState([]);

  const [providerTab, setProviderTab] = useState('');

  const [searchTemplate, setSearchTemplate] = useState('');
  const [searchCloudGenTemplate, setSearchCloudGenTemplate] = useState('');
  const [searchZebPulseTemplate, setSearchZebPulseTemplate] = useState('');
  const [zebPulseTemplates, setZebPulseTemplates] = useState([]);
  const [awsZebPulseTemplate, setAwsZebPulseTemplate] = useState([]);
  const [azureZebPulseTemplate, setAzureZebPulseTemplate] = useState([]);

  const [organizations, setOrganizations] = useState([])
  const [awsTemplatesCount, setAwsTemplatesCount] = useState(0);
  const [azureTemplatesCount, setAzureTemplatesCount] = useState(0);
  const [awsZebPulseTemplatesCount, setAwsZebPulseTemplatesCount] = useState(0);
  const [azureZebPulseTemplatesCount, setAzureZebPulseTemplatesCount] = useState(0);

  const [organizationData, setOrganizationData] = useState([])
  const [workloadData, setWorkloadData] = useState([])
  const [templateOrganizationID, setTemplateOrganizationId] = useState("")
  const [environmentData, setEnvironmentData] = useState([])
  const [chosenWorkloadId, setChosenWorkloadId] = useState("")
  const userId = localStorage.getItem('userId');
  const [showLoader, setShowLoader] = useState(false)
  console.log("userid", userId)
  const [loading, setLoading] = useState(false);

  const { orgId } = useContext(OrgContext)

  console.log(orgId, "organization")
  const [popupMsg, setPopUpMsg] = useState({ "Msg": "", show: false, success: false })



  //PS_51 
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  useEffect(() => {
    if (templateOrganizationID) {
      console.log(templateOrganizationID)
      getOrganizationData(templateOrganizationID)
      getWorkLoadData(templateOrganizationID)
      if (chosenWorkloadId) {
        getEnvironmentData()
      }
    }
  }, [templateOrganizationID, chosenWorkloadId])
  //PS_10 - PS_22 , PS_24 - PS_36 
  //Functin for fetching templates for cloudGen , Zeb pulse
  useEffect(() => {

    //setShowLoader(true)
    fetchAllTemplates();
    fetchZebPulseTemplateData();
    //setShowLoader(false)


  }, []);

  async function fetchAllTemplates() {
    const payload = {
      "userId": userId,
      "organizationId": orgId,
      "searchValue": ""
    };

    try {
      setShowLoader(true)
      const response = await fetchTemplates(payload);
       setShowLoader(false)

      console.log(response, "response for cloudgenTemplates");

      if (response.status === 200 && response.data.body.success) {
        const { data } = response.data.body;
        console.log(data, "data");

        //setPopUpMsg({...popupMsg , ["Msg"]:"Templates approved successfully" , ["show"]:true ,["success"]:false })

        setCloudGenTemplates(data);
        partitionTemplates(data);


      } else {
        console.error("Error fetching templates:", response.data.body.message);
      }
    } catch (error) {
      console.error("Error fetching templates:", error);
      setShowLoader(false)
    }
  }

  useEffect(() => {
    console.log(cloudGenTemplates, "cloudgenTemplates")
    console.log(showLoader, "showloader status")

  }, [showLoader, cloudGenTemplates])


  async function fetchZebPulseTemplateData() {
    const payload = {
      "userId": userId,
      "organizationId": orgId,
      "searchValue": ""
    };

    try {
      setShowLoader(true)
      const response = await fetchZebPulseTemplates(payload);
      setShowLoader(false)
      console.log(response, "response");

      if (response.status === 200 && response.data.body.success) {
        const { data } = response.data.body;
        console.log(data, "data");

        setZebPulseTemplates(data);

        partitionZebPulseTemplates(data);
      } else {
        console.error("Error fetching templates:", response.data.body.message);
      }
    } catch (error) {
      console.error("Error fetching templates:", error);
      setShowLoader(false)
    }
  }



  //PS_32 - PS_36
  const partitionZebPulseTemplates = (data) => {
    const awsTemplates = data.filter(template => template.cloudProvider === "AWS");
    const azureTemplates = data.filter(template => template.cloudProvider === "Azure");

    setAwsZebPulseTemplate(awsTemplates);
    setAzureZebPulseTemplate(azureTemplates);

    setAwsZebPulseTemplatesCount(awsTemplates.length);
    setAzureZebPulseTemplatesCount(azureTemplates.length);
  };


  //PS_18 - PS_22
  // Function to partition templates into AWS and Azure
  const partitionTemplates = (data) => {
    const awsTemplates = data.filter(template => template.providerName === "AWS");
    const azureTemplates = data.filter(template => template.providerName === "Azure");
    setAwsCloudGenTemplate(awsTemplates);
    setAzureCloudGenTemplate(azureTemplates);
    setAwsTemplatesCount(awsTemplates.length);
    setAzureTemplatesCount(azureTemplates.length);

  };

  //PS_53 -PS_56
  const getOrganizationData = async (templateOrganizationID) => {
    try {
     
      const payload = {
        "userId": userId,
        "orgId": templateOrganizationID,
        "search": ""
      }

      const response = await getAllOrganization(payload); // Replace with your actual API call function
      console.log(response, "response");

      if (response.status === 200) {
        setOrganizationData(response.data.data); // Store the response in a state variable
      } else {
        console.error("Error fetching cloud provider data:", response.data.body.message);
        setPopUpMsg({ ...popupMsg, ["Msg"]: response.data.body.message, ["show"]: true, ["success"]: false })

      }
    } catch (error) {
      console.error("API Error:", {
        errorName: error.name,
        errorServiceName: "Front-end",
        errorFunctionName: "getCloudProviderData",
        errorSource: "frontend",
        errorDescription: "fetch failed: " + error.message
      });
    }
  };


  //PS_56-PS_57
  const handleSearchChange = (e) => {
    const searchValue = e.target.value;
    if (activeTab === 'cloudgen') {
      setSearchCloudGenTemplate(searchValue);
    } else {
      setSearchZebPulseTemplate(searchValue);
    }
  };

  //PS_58-PS_59
  const getWorkLoadData = async (templateOrganizationID) => {
    try {
      const payload = {
        "userId": userId,
        "orgId": templateOrganizationID,
        "type": "clone",
        "connectorId": "",
        "orgIdGeneral": []
      }

      const response = await fetchAllWorkload(payload); // Replace with your actual API call function
      console.log(response, "response workload");

      if (response.status === 200) {
        setWorkloadData(response.data.data.workload); // Store the response in a state variable
      } else {
        console.error("Error fetching cloud provider data:", response.data.body.message);
      }
    } catch (error) {
      console.error("API Error:", {
        errorName: error.name,
        errorServiceName: "Front-end",
        errorFunctionName: "getCloudProviderData",
        errorSource: "frontend",
        errorDescription: "fetch failed: " + error.message
      });
    }
  };

  //PS_60-PS_62
  const getEnvironmentData = async () => {
    try {
      
      const payload = {
        "userId": userId,
        "orgId": templateOrganizationID,
        "workloadId": chosenWorkloadId,
        "type": "budget"
      }

      const response = await fetchAllEnvironment(payload); // Replace with your actual API call function
      console.log(response, "response workload");

      if (response.status === 200) {
        setEnvironmentData(response.data.data); // Store the response in a state variable
      } else {
        console.error("Error fetching cloud provider data:", response.data.body.message);
      }
    } catch (error) {
      console.error("API Error:", {
        errorName: error.name,
        errorServiceName: "Front-end",
        errorFunctionName: "getCloudProviderData",
        errorSource: "frontend",
        errorDescription: "fetch failed: " + error.message
      });
    }
  };


  if (loading) {
    return <Loader />; // Show loader while loading
  }




  // PS_37 - PS_50
  //Bind the cloudgen and zebPulse templates .Search functionality performed.
  return (
    <>
      {
        popupMsg.show == true && popupMsg.Msg != "" ?
          <WorkbechPopup
            popupMsg={popupMsg}
            setPopUpMsg={setPopUpMsg}
          />
          : <></>
      }

      {/* header starts */}

      {/* header ends */}
      {showLoader ?
        <Loader /> :
        <div className="container-fluid custom-container right-container-scroll" style={{ height: "100vh" }}>
          <div className="d-flex justify-content-between align-items-center notifications-title-bar my-32">
            <h2 className="font-22 font-bold black-v2 mb-0">Manage Templates</h2>
            <div className="input-group input-search custom-border-box custom-search-box w-25">
              <input
                type="search"
                className="px-3 form-control common-bg border-0 custom-form custom-search $gray-v1"
                placeholder="Search"
                value={activeTab === 'cloudgen' ? searchCloudGenTemplate : searchZebPulseTemplate}
                onChange={handleSearchChange}
              />
              <button className="btn border-start-0 custom-form custom-search" type="button">
                <img src="images/search-blue.svg" alt="search" />
              </button>
            </div>
          </div>

          <div className="notification-tab-parent">
            <div className="notification-tab-title mb-2">
              <ul className="nav nav-tabs custom-border-bottom" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${activeTab === "cloudgen" ? "active" : ""}`}
                    onClick={() => handleTabClick("cloudgen")}
                  >
                    CloudGen
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${activeTab === "zebpulse" ? "active" : ""}`}
                    onClick={() => handleTabClick("zebpulse")}
                  >
                    Zeb Pulse
                  </button>
                </li>
              </ul>
            </div>
            <div className="notification-tab-content mt-4">
              <div className="tab-content">
                {activeTab === "cloudgen" && (
                  <CloudGenContext.Provider value={{
                    cloudGenTemplates,
                    awsCloudGenTemplate,
                    azureCloudGenTemplate,
                    providerTab,
                    setProviderTab,
                    awsTemplatesCount,
                    azureTemplatesCount,
                    organizationData,
                    searchTerm: searchCloudGenTemplate,
                    workloadData,
                    environmentData,
                    setTemplateOrganizationId,
                    templateOrganizationID,
                    chosenWorkloadId,
                    setChosenWorkloadId,
                    popupMsg,
                    setPopUpMsg,
                    setAwsTemplatesCount,
                    setAzureTemplatesCount
                  }}>
                    <CloudGenTemplates
                      fetchAllTemplates={fetchAllTemplates}
                    />
                  </CloudGenContext.Provider>
                )}
                {activeTab === "zebpulse" && (
                  <ZebPulseContext.Provider value={{
                    zebPulseTemplates,
                    awsZebPulseTemplatesCount,
                    azureZebPulseTemplatesCount,
                    providerTab,
                    setProviderTab,
                    awsZebPulseTemplate,
                    azureZebPulseTemplate,
                    organizationData,
                    searchTerm: searchZebPulseTemplate,
                    workloadData,
                    environmentData,
                    setTemplateOrganizationId,
                    templateOrganizationID,
                    chosenWorkloadId,
                    setChosenWorkloadId,
                    popupMsg,
                    setPopUpMsg,
                    setAwsZebPulseTemplatesCount,
                    setAzureZebPulseTemplatesCount
                  }}>
                    <ZebPulseTemplates
                      fetchZebPulseTemplateData={fetchZebPulseTemplateData}
                    />
                  </ZebPulseContext.Provider>
                )}
              </div>
            </div>
          </div>
        </div>
      }

    </>
  )
}
export default ManageTemplates;
