
import React, { useContext, useState } from 'react';
import { savePulseTemplateApi, saveTemplateApi } from "../../../../../Service/api";
import * as htmlToImage from "html-to-image";
import { useOrgContext } from '../../../../../ContextProvider/OrgContext';
import { FormContext } from '../../../Workload_Grid';

export default function SaveTemplatePopup({ 
	setIsSaveTemplateModalOpen, nodes, edges, templateProviderType,
	popupMsg,
	setPopUpMsg
}) {

	const [templateName, setTemplateName] = useState('');
	const [isSaving, setIsSaving] = useState(false);
	const { 
		orgId,
		userId,
		envId,
		workloadId,
		providerId,
		providerName ,
		PulseOrganization_id,
		PulseVertical_id,
		PulseVertical_name,
		PulseOrganization_name,
		
		UTI,

		


	} = useOrgContext();
	const {formData} = useContext(FormContext)

    console.log(nodes , "Nodes in the Save TenmpaetePOpoup")


	/**
		* PS_89 - PS_93 
		* This save Template Function is used to Save the Template for the Providers
		* 
		*/

	async function saveTemplate() {
		// setIsSaving(true);
		try {
			let nodeData = {
				nodes: nodes,
				edges: edges
			};
			const dataUrl = await htmlToImage.toPng(document.getElementById('myArchitecture'));
			let payload = {
				"ProviderId": providerId,
				"templateName": templateName,
				"orgId": orgId,
				"userId": userId,
				"envId": envId,
				"providerName":providerName,
				"workloadId":workloadId,
				"UTI" : UTI,
				"workloadName":formData.workloadName,
				// "userId": "5deade04-5a89-480a-a50b-1e285cb7a810",
				// "orgId": "7e35129a-5a9f-4daf-ac9b-86dfef341f71",
				// "envId": "e3f032ab-a20e-4059-a3c3-07ed8403117b",
				"templateXml": JSON.stringify(nodeData),
				"prefix": templateProviderType,
				"templateImage":dataUrl
			};
			console.log(payload, "Payload for AWS Template")

			let res = await saveTemplateApi(payload);
			console.log(res, "Template saved successfully");
			setIsSaveTemplateModalOpen(false);
			setTemplateName("")
			if(res.success){
				setPopUpMsg({...popupMsg , ["Msg"]:"Architecture Saved Successfully" , ["show"]:true ,["success"]:true })
			}
			else{
				setPopUpMsg({...popupMsg , ["Msg"]:"Architecture Saved UnSuccessfull" , ["show"]:true ,["success"]:false })

			}
			// if(res.suce)
			
			// Show success message to user

		} catch (error) {
			console.log("Error saving template:", error);
			// Show error message to user

		} finally {
			// setIsSaving(false);
		}
	}
	/**
		* This savePulseTemplate is used to save the Pulse Template 
		*/
	async function savePulseTemplate() {
		try {

		let nodeData = {
			nodes: nodes,
			edges: edges
		};
		const dataUrl = await htmlToImage.toPng(document.getElementById('myArchitecture'));
			// let payload = {
			// "orgId": "",
			// "userId": "",
			// "templateXml": JSON.stringify(nodeData),
			// "templateName": templateName,
			// "templateImage": dataUrl,
			// "ProviderId": "",
			// "envId": "",
			// "prefix": ""
			// };
			let payload ={
				"userId":userId, 
				"templateXml":JSON.stringify(nodeData), 
				"organizationId":orgId, 
				"templateImage":dataUrl, 
				"cloudProviderId":providerId, 
				"environment_id":envId,
				"PulseOrganization_id":PulseOrganization_id,
				"PulseVertical_id":PulseVertical_id,
				"PulseVertical_name":PulseVertical_id,
				"PulseOrganization_name":PulseOrganization_name,

			}
			console.log(payload , "Payload for the save Pulse Template API")
			let res = await savePulseTemplateApi(payload);
			console.log(res , "from the save Pulse Templatee")
			if(res.success){
				setPopUpMsg({...popupMsg , ["Msg"]:"Architecture Saved successfully" , ["show"]:true ,["success"]:true })
			}
			else{
				setPopUpMsg({...popupMsg , ["Msg"]:"Architecture Saved UnSuccessfull" , ["show"]:true ,["success"]:false })

			}

			setIsSaveTemplateModalOpen(false);
		} catch (error) {
			console.error("Error saving pulse template:", error);
		} finally {
			setIsSaving(false);
		}
	}
	/**
		* PS_99 - PS_101 
		* Triggered during the Save Button Click 
		*/
	const handleSave = () => {
		console.log(templateProviderType , "Template Gonna Saveddd")
		if (templateProviderType === "ZebPulse" ||templateProviderType.toLowerCase() === "zebpulse" ) {
			savePulseTemplate();
		} else if (templateProviderType.toLowerCase() == "aws" || templateProviderType.toLowerCase() === "azure" || 
		templateProviderType === "Azure") {
			saveTemplate();
		}
	};

	return (
		<div className="modal fade show"
			id="SaveTemplatePopup"
			data-bs-backdrop="static"
			data-bs-keyboard="false"
			tabIndex={-1}
			aria-labelledby="staticBackdropLabel"
			aria-hidden="true"
			style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}
		>
			<div className="modal-dialog modal-dialog-centered justify-content-center">
				<div className="modal-content border-0">
					<div className="modal-header border-0 py-4 px-32">
						<h1 className="modal-title font-20 font-bold black-v2" id="staticBackdropLabel">
							Save as Template
						</h1>
						<button
							type="button"
							className="btn shadow-none"
							aria-label="Close"
							onClick={() => setIsSaveTemplateModalOpen(false)}
							disabled={isSaving}
						>
							<img
								src="images/close-icon.svg"
								alt="close-icon"
								className="icon-hover shadow-none modal-close-filter"
							/>
						</button>
					</div>
					<div className="modal-body px-32 pt-0">
						<label className="form-label font-14 font-medium black-v2" htmlFor="templateName">
							Template Name
						</label>
						<input
							type="text"
							className="form-control custom-dropdown shadow-none font-14 font-medium black-v2"
							id="templateName"
							placeholder="Enter Template Name"
							value={templateName}
							onChange={(e) => setTemplateName(e.target.value)}
							disabled={isSaving}
						/>
						<div className="d-flex justify-content-end align-items-center mt-4 mb-2 gap-3">
							<button
								type="button"
								className="button outline-button text-nowrap py-12"
								onClick={() => setIsSaveTemplateModalOpen(false)}
								disabled={isSaving}
							>
								Cancel
							</button>
							<button
								type="button"
								className="button primary-button text-nowrap py-12"
								onClick={handleSave}
								disabled={isSaving || templateName==""}
							>
								{isSaving ? 'Saving...' : 'Save'}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
