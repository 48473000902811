
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { get_all_b2aApi, get_all_ProviderApi } from '../../../Service/api';
import SideBar from '../../sidebar/SideBar';
import { useOrgContext } from '../../../ContextProvider/OrgContext';
import Loader from '../../loader/loader';
import { loader } from '@monaco-editor/react';

function B2AGridComponent() {
	// State variables for the B2A Grid 
	const { orgId } = useOrgContext()
	const [userId, setuserId] = useState(localStorage.getItem('userId'));
	const [connectors, setConnectors] = useState([]);
	const [displayedConnectors, setDisplayedConnectors] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [cloudProviders, setCloudProviders] = useState([]);
	const [selectedProvider, setSelectedProvider] = useState({ id: "", name: "" });
	const navigate = useNavigate();
	const location = useLocation()
	const itemsPerPage = 10;
	const [isLoading , setIsLoading] = useState(false)

	// Page Load Handlers
	useEffect(()=>{
		if(orgId == null || undefined || ""){
			navigate('/manage-organizations')
		}
	},[])

	useEffect(()=>{
		console.log(location , "Locaiotn")
		if(location?.state){
			const {
				providerName,
				cloudproviderId
			} = location.state;
			console.log({
				id: cloudproviderId,
				name: providerName
			} , "here in the uselocation")
			if(selectedProvider){
			setSelectedProvider({
				id: cloudproviderId,
				name: providerName
			});
		}

		}
		fetchCloudProviders()
	},[location])
	// Function to fetch cloud providers
	const fetchCloudProviders = async () => {
		try {
			let payload = {
				userId,
				type: "org",
				orgId
			}
			console.log(payload , "Payloaddd")
			let response = await get_all_ProviderApi(payload)
			if(response.success){
			setCloudProviders(response.data);
			if (response.data.length > 0) {
				console.log(selectedProvider , "get_all+proivderesd")
				if(selectedProvider.id==""|| null||undefined){
				setSelectedProvider({
					id: response.data[0].providerId,
					name: response.data[0].providerName
				});
			}
			}
		}
		} catch (error) {
			console.error("Error fetching cloud providers:", error);
		}
	};

	// Function to fetch connectors from the API
	const fetchConnectors = async () => {
		if (!selectedProvider.id) return;

		setIsLoading(true);
		let payload = {
			"userId": userId,
			"organizationId": orgId,
			"cloudproviderId": selectedProvider.id,
		};
		try {
			setIsLoading(true)
			const response = await get_all_b2aApi(payload);
			
			if (response.data && response.success) {
				setConnectors(response.data);
				setDisplayedConnectors(response.data.slice(0, itemsPerPage));
				setCurrentPage(1);
			}
			setIsLoading(false)
		} catch (error) {
			console.error('Error fetching connectors:', error);
		}
		setIsLoading(false);
	};

	// Fetch connectors when selected provider changes
	useEffect(() => {
		console.log(selectedProvider , "selectedProviderselectedProvider")
		if (selectedProvider.id) {
			fetchConnectors();

		}
	}, [selectedProvider]);

	// Handle load more functionality
	const handleLoadMore = () => {
		const nextPage = currentPage + 1;
		const nextDisplayedConnectors = connectors.slice(0, nextPage * itemsPerPage);
		setDisplayedConnectors(nextDisplayedConnectors);
		setCurrentPage(nextPage);
	};

	// Navigate to the add B2A form
	const handleAddB2A = () => {
		navigate('/B2AForm', {
			state: {
				providerName: selectedProvider.name,
				cloudproviderId: selectedProvider.id,
				editMode: false
			}
		});
	};

	// Handle provider change
	const handleProviderChange = (providerId) => {
		const provider = cloudProviders.find(p => p.providerId === providerId);
		setSelectedProvider({
			id: provider.providerId,
			name: provider.providerName
		});
		};

	return (
		<>
		
		<div class="container-fluid">
		<div class="dashboard-container ps-0">
	
		<div class="row">
		<SideBar/>
		
		<div className="col-md-9 right-container-scroll ">
		<div className="px-4 mt-4">
			{/* Header section */}
			<div className="d-flex justify-content-between align-items-center py-4">
				<h2 className="font-32 font-bold black-v2 mb-0">B2A</h2>

				<div className="d-flex gap-3">
					<div className="dropdown">
						<button 
							type="button" 
							className="btn custom-dropdown d-flex justify-content-between align-items-center gap-3 font-12 font-regular color-black shadow-none" 
							id="dropdownMenuButton1" 
							data-bs-toggle="dropdown" 
							aria-expanded="false"
						>
							{/* <span className="d-flex">
								<img src={`images/${selectedProvider.name ? selectedProvider.name.toLowerCase() :'aws'}-logo-coloured.svg`} 
								alt={`${selectedProvider.name}-img`} className="me-1 icon-width light-theme-img" />
								{selectedProvider.name || ""}
							</span> */}
							<span className="d-flex align-items-center">
							<img 
								src={`images/${selectedProvider.name.toLowerCase()}-logo.svg`} 
								alt={`${selectedProvider.name}-img`} 
								className="me-1 icon-width" 
							/> 
							{selectedProvider.name}
						</span>
							<img src="images/drop-down-arrow.svg" alt="dropdown-arrow" />
						</button>
						<ul className="dropdown-menu body-bg access-dropdown" aria-labelledby="dropdownMenuButton1">
							{cloudProviders.map(provider => (
								<li 
									key={provider.providerId} 
									className={`font-regular color-black font-12 p-2 custom-list ${selectedProvider.id === provider.providerId ? 'active' : ''}`}
									onClick={() => handleProviderChange(provider.providerId)}
								>
									<span className="d-flex align-items-center">
										<img 
											src={`images/${provider.providerName.toLowerCase()}-logo.svg`} 
											alt={`${provider.providerName}-img`} 
											className="me-1 icon-width" 
										/> 
										{provider.providerName}
									</span>
								</li>
							))}
						</ul>
					</div>
					<button type="button" className="button primary-button text-nowrap"
					onClick={(()=>{
						handleAddB2A()
					})}
					>
					<img src="images/add-icon.svg" alt="add-icon" className="me-2" /> Add B2A
					</button>

				</div>
				</div>
				{/* Conditionally render table or no data message */}
				
				{isLoading ? (
				<Loader />
				) : (
				<>
					{displayedConnectors.length > 0 ? (
					<div className="table-responsive table-border custom-nav-scrl" style={{ height: "410px" }}>
						<table className="table custom-table">
						<thead>
							<tr>
							<th className="position-sort-icon text-start border-0">Connector Name</th>
							<th className="text-start border-0">Tags</th>
							<th className="text-center border-0">View Architecture</th>
							</tr>
						</thead>
						<tbody className="manage-work">
							{displayedConnectors.map((connector) => (
							<tr key={connector.connector_id}>
								<td className="text-start">{connector.connector_name}</td>
								<td className="text-start">
								{connector.tags.length ? (
									connector.tags.map((tag, index) => (
									<span key={index}>
										{tag.Key}: {tag["Value"]}
										{index < connector.tags.length - 1 ? ', ' : ''}
									</span>
									))
								) : 'No Tags'}
								</td>
								<td className="text-center">
								<button 
									type="button" 
									className="button secondary-outline-button py-1"
									onClick={() => {
									if(selectedProvider.name == "AWS"){
										navigate('/AWSB2A', {
										state: {
											connector_id: connector.connector_id,
											connector_name: connector.connector_name,
											tags: connector.tags[0]? connector.tags[0] :{Key:"", Value:""},
											region: connector.region,
											cloudproviderId: selectedProvider.id,
											providerName: selectedProvider.name,
											b2a_connector_id: connector.b2a_connector_id,
											editMode: true
										}
										});
									} else {
										navigate('/Azureb2a', {
										state: {
											connector_id: connector.connector_id,
											connector_name: connector.connector_name,
											tags: connector.tags[0],
											region: connector.region,
											cloudproviderId: selectedProvider.id,
											providerName: selectedProvider.name,
											b2a_connector_id: connector.b2a_connector_id,
											editMode: true
										}
										});
									}
									}}
								>
									View
								</button>
								</td>
							</tr>
							))}
						</tbody>
						</table>
					</div>
					) : (
					<div className="row mt-4 pt-2 manage-workloads-parent">
						<div className="col-md-12">
						<div className="record-container d-flex align-items-center justify-content-center flex-column">
							<div className="no-rec-img">
							<img src="images/no-rec-found-img.png" alt="no-rec-found" className="w-100 h-100" />
							</div>
							<h6 className="color-black font-bold font-16 mt-5 mb-0">No Connectors Found!!</h6>
							<p className="font-medium font-12 mt-4 mb-0 secondary-text-color-v5"></p>
						</div>
						</div>
					</div>
					)}

					<div className="d-flex justify-content-between align-items-center my-4">
					<p className="font-14 font-medium secondary-text-color mb-0">
						# of Records <span className="font16 font-medium color-black">
						{displayedConnectors.length} out of {connectors.length}
						</span>
					</p>
					</div>

					{connectors.length > itemsPerPage && displayedConnectors.length < connectors.length && (
					<div className="d-flex justify-content-center my-2">
						<button 
						type="button" 
						className="button secondary-outline-button black-v2"
						onClick={handleLoadMore}
						disabled={isLoading}
						>
						{isLoading ? 'isLoading...' : 'Load More'}
						</button>
					</div>
					)}
				</>
				)}

		</div>
	</div>
		
		
		</div>
		</div>
		</div>
		
		
		
	</>
	);
}

export default B2AGridComponent;
