import { useContext } from "react"
import { AddServiceContext } from "./context/AddServiceContext"

export default function LanguageTab(){

   const {langKb,setLangKb, providerName } = useContext(AddServiceContext)


    return (
        <div className="custom-tab-scroll mb-4">
        <ul
          className="nav nav-pills d-flex gap-1 flex-nowrap mb-1"
          id="pills-tab"
          role="tablist"
        >
          <li className="nav-item" role="presentation" hidden={
            (providerName != 'Azure' && providerName != 'AWS')
          }>
            <button
              className="nav-link theme-tab active font-14 secondary-text-color font-medium px-3 py-1"
              data-bs-toggle="pill"
              data-bs-target="#terraform"
              type="button"
              role="tab"
              aria-selected="true"
              onClick={()=>{
                setLangKb("terraform")
              }}
            >

              Terraform
            </button>
          </li>
          <li className="nav-item" role="presentation" hidden = { (providerName != 'AWS')}>
            <button
              className="nav-link theme-tab font-14 secondary-text-color font-medium px-3 py-1"
              data-bs-toggle="pill"
              data-bs-target="#cloudformation"
              type="button"
              role="tab"
              aria-selected="false"
              onClick={()=>{
                setLangKb("cloudFormation")
              }}
            >
              Cloud Formation
            </button>
          </li>
          <li className="nav-item" role="presentation" hidden = {(providerName != 'Azure')}>
            <button
              className="nav-link theme-tab font-14 secondary-text-color font-medium px-3 py-1"
              data-bs-toggle="pill"
              data-bs-target="#arm"
              type="button"
              role="tab"
              aria-selected="false"
              onClick={()=>{
                setLangKb("arm")
              }}
            >
              ARM
            </button>
          </li>
        </ul>
      </div>
    )
}