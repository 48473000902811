
import { useContext, useEffect, useState } from 'react';
import { deleteResourceData, saveResourceData, updateCodeSnippetApi } from "../../../../Service/api";
import MonacoEditor from 'react-monaco-editor';
import { OrgContext } from '../../../../ContextProvider/OrgContext';

// SQ_1.2: Props passed to popup
function DataResourcePopup({ showDataResourcePopup, selectedResource, onClose, selectedAccount,setShowLoader }) {

   const { orgId, envId } = useContext(OrgContext)
  // SQ_1.3- 1.7 : State declaration
  const [name, setName] = useState('');
  const [fieldNames, setFieldNames] = useState([]);
  const [code, setCode] = useState('');
  const [enableBtn, setEnableBtn] = useState(false);

  //const [showLoader, setShowLoader] = useState(false);
  const [error, setError] = useState(null);
  const darkTheme = localStorage.getItem('darkTheme');
  const userId = localStorage.getItem('userId');
  


  console.log(selectedResource, "selectedresourceinpopup")


  // SQ_1.8- 1.11 :  Intial prepopulation
  useEffect(() => {
    if (selectedResource) {
      setName(selectedResource.Name); 
      
      // Transform fieldNames to ensure it follows a consistent format
      if (Array.isArray(selectedResource.fieldName)) {
        const transformedFieldNames = selectedResource.fieldName.map(field => 
          typeof field === 'string' ? { field, value: '' } : field);
        setFieldNames(transformedFieldNames);
      } else {
        setFieldNames([]);
      }

      setCode(selectedResource.code || '');  // Ensure code has a default value if undefined
    }
  }, [selectedResource]);

   // SQ_1.12- 1.17 : On field change
  const handleFieldChange = (index, value) => {

    setEnableBtn(true)
    const newFieldNames = [...fieldNames];
    newFieldNames[index].value = value;
    setFieldNames(newFieldNames);

  };

  // SQ_1.18- 1.39 :
  const updateCode = async () => {
    try {
       //setShowLoader(true);
      const payload = {
        userId: userId,
        resourceId: selectedResource.resourceId,
        fieldName: [...fieldNames, { field: "Resource Name", value: name }],
        code,
        name
      };

      //setShowLoader(true)
      const response = await updateCodeSnippetApi(payload);
      setCode(response.data.code);
      setShowLoader(false)
      setEnableBtn(false)
    } catch (error) {
      setError(error.message);
      setShowLoader(false)
    } finally {
      setShowLoader(false);
    }
  };

    // SQ_1.40- 1.53 :
  const saveResources = async () => {
    try {
      setShowLoader(true);
      const payload = {
        userId: userId,
        accountId: selectedAccount,
        environmentId : envId,
        resourceName: selectedResource.resourceName,
        environmentId :envId,
        name,
        field: [...fieldNames],
        code,
        createResource: !selectedResource.dataId,
        dataId: selectedResource.dataId || ''
      };
      //SQ_1.43- 1.50
      const response = await saveResourceData(payload);
      onClose();
    } catch (error) {
      setError(error.message);
    } finally {
      setShowLoader(false);
    }
  };

    // SQ_1.54- 1.67 :
  const deleteResource = async () => {
    try {
      setShowLoader(true);
      const payload = {
        userId: userId,
        dataId: selectedResource.dataId
      };

      //SQ_1.57- 1.64
      const response = await deleteResourceData(payload);
      onClose();
    } catch (error) {
      setError(error.message);
    } finally {
      setShowLoader(false);
    }
  };

    // SQ_1.68- 1.70 :
  const handleCancel = () => {
    setName('');
    setFieldNames([]);
    setCode('');
    onClose();
  };

  console.log(enableBtn,"enableBtn")

  return (
    showDataResourcePopup && (
      <div className="modal fade show" style={{ display: 'block', backgroundColor: "rgba(0,0,0,0.7)" }}>
        <div className="modal-dialog modal-xl modal-dialog-centered justify-content-center">
          <div className="modal-content border-0">
            <div className="modal-header border-0 p-4 ">
              <h1 className="modal-title font-20 font-medium black-v2 ps-2" id="staticBackdropLabel">
                {!selectedResource.dataId ? "Add Resource" : selectedResource.resourceName}
                
                {/* additional info */}
              </h1>
              <button
                type="button"
                className="btn shadow-none"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onClose}
              >
                <img src="images/close-icon.svg" alt="close-icon" className="icon-hover shadow-none modal-close-filter" />
              </button>
            </div>
            <div className="modal-body px-4 pt-0">
              <div className="row ps-4">
                {/* sidebar-menu start */}
                <div className="col-md-6 p-0">
                  <div className="row">
                    <div className="col-md-6 mb-4 px-2">
                      <label className="form-label font-14 font-medium black-v2" htmlFor="name">
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control custom-dropdown shadow-none"
                        id="name"
                        placeholder="Enter the Name"
                        value={name}
                        onChange={(e) => {
                          let value = e.target.value;
                      
                          // Replace spaces with underscores
                          value = value.replace(/\s/g, '_');
                      
                          // Remove any character that is NOT a letter, number, or underscore
                          value = value.replace(/[^a-zA-Z0-9_]/g, '');
                      
                          setName(value);
                          setEnableBtn(true);
                        }}
                      />
                    </div>
                  </div>
                  <div className="row data-resource-scrl">
                    <h3 className="font-16 font-bold mb-4 black-v2">Attributes</h3>
                    {fieldNames.map((field, index) => (
                      <div className="col-md-6 mb-4 px-2" key={index}>
                        <label className="form-label font-14 font-medium black-v2" htmlFor={`field-${index}`}>
                          {field.field}
                        </label>
                        <input
                          type="text"
                          className="form-control custom-dropdown shadow-none"
                          id={`field-${index}`}
                          placeholder={`Enter ${field.field}`}
                          value={field.value}
                          onChange={(e) => handleFieldChange(index, e.target.value)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-md-6 pe-4 ps-5">
                  <div className="col-md-12">
                    <div className="d-flex justify-content-between mb-2">
                      <h3 className="form-label font-14 font-medium black-v2">Snippet</h3>
                      <button
                        className="bg-transparent font-14 font-medium primary-color d-flex gap-2 border-0 shadow-none align-items-center"
                        onClick={updateCode}
                      >
                        <img src="images/refresh-icon.svg" alt="refresh icon" />
                        <span>Update Code</span>
                      </button>
                    </div>
                    <div className="theme-border-top code-editor border rounded-3 offer-snippet">
                      <div className="monaco-editor-wrapper" style={{ position: 'relative', height: '400px' }}>
                        <MonacoEditor
                          width="100%"
                          height="70%"
                          language={'json'}
                          theme={darkTheme === "true" ? 'vs-dark' : 'vs-light'}
                          value={code}
                          onChange={(value) => setCode(value)}
                          options={{ readOnly: true }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center my-0">
                <button type="button" className="button danger-outline-button px-4" onClick={deleteResource}>
                  Delete
                </button>
                <div className="d-flex gap-2">
                  <button
                    type="button"
                    className="button outline-button text-nowrap px-4"
                    data-bs-dismiss="modal"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="button primary-button text-nowrap px-3"
                    onClick={saveResources}
                    disabled={enableBtn}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default DataResourcePopup;
