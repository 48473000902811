import React, { useState, useEffect, useRef } from "react";
// import { getAllProviders } from "../../Service/ApiMaintanence/Dashboards/DashboardApi";
import { OrgContext } from "../../ContextProvider/OrgContext";
import { useContext } from "react";
import { OrgProvider } from "../../ContextProvider/OrgContext";
import CostByWorkloads from "./CostByWorkloads";
import OverallCost from "./OverallCost";
import AssetIACoverage from "./AssetIacCoverage";
import CostByTags from "./CostByTags";
import CostByRegion from "./CostByRegion";
import CostByService from "./CostByService";
import CostByProvider from "./CostByProvider";
import PipelineCommit from "./PipelineCommit";
import CostByPeriod from "./CostByPeriod";
import html2canvas from "html2canvas";
import { formatDistanceToNow } from "date-fns";

import {
  getAllProviders,
  getUniqueTagsByOrg,
  getAllWorkloadsByOrgId,
  getAllConnectors,
  getAllWorkloadsApi,
  syncNowAws,
  syncNowAzure,
} from "../../Service/api";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SideBar from "../sidebar/SideBar";
import ExportComponent from "./ExportComponent";
// import PdfComponent from "./PDF.jsx";
import { DashboardContext } from "./Context/DashboardContext.jsx";
// import { Context } from "html2canvas/dist/types/core/context";

const CostDashboard = () => {
  const [providers, setProviders] = useState([]);
  const { orgId, setuserRoles, setIsProjectAdmin } = useContext(OrgContext);
  const [activeTab, setActiveTab] = useState("Workloads");
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [connectors, setConnectors] = useState([]);
  const [selectedConnectors, setSelectedConnectors] = useState([]);
  const [tags, setTags] = useState([]);
  const [workloads, setWorkloads] = useState([]);
  const [groupByOptions, setGroupByOptions] = useState([]);
  const [periodError, setPeriodError] = useState("");
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedProviderId, setSelectedProviderId] = useState("");
  const [period, setPeriod] = useState("");
  const [isMainFilterOpen, setIsMainFilterOpen] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [workloadsProvider, setWorkloadsProvider] = useState(null);
  const [providersProvider, setProvidersProvider] = useState(null);
  const [tagsProvider, setTagsProvider] = useState(null);
  const [periodProvider, setPeriodProvider] = useState(null);
  const [regionsProvider, setRegionsProvider] = useState(null);
  const [servicesProvider, setServicesProvider] = useState(null);
  const [providersConnectors, setProvidersConnectors] = useState([]);
  const [serviceConnectors, setServiceConnectors] = useState([]);
  const [workloadsFilters, setWorkloadsFilters] = useState({
    providerId: null,
    period: "",
    fromDate: null,
    toDate: null,
  });

  const [timeAgo, setTimeAgo] = useState(null);
  const [lastSyncTime, setLastSyncTime] = useState(null);

  console.log(
    lastSyncTime,
    timeAgo,
    "This is the date to be shown to the user"
  );

  const [appliedWorkloadsFilters, setAppliedWorkloadsFilters] = useState({
    providerId: null,
    period: "",
    fromDate: null,
    toDate: null,
  });

  const [providersFilters, setProvidersFilters] = useState({
    providerId: null,
    connectors: [],
    groupBy: [],
    period: "",
    fromDate: null,
    toDate: null,
  });

  const [appliedProvidersFilters, setAppliedProvidersFilters] = useState({
    providerId: null,
    connectors: [],
    groupBy: [],
    period: "",
    fromDate: null,
    toDate: null,
  });

  const [tagsFilters, setTagsFilters] = useState({
    providerId: null,
    selectedTags: [],
    period: "",
    fromDate: null,
    toDate: null,
  });

  const [appliedTagsFilters, setAppliedTagsFilters] = useState({
    providerId: null,
    selectedTags: [],
    period: "",
    fromDate: null,
    toDate: null,
  });

  const [periodFilters, setPeriodFilters] = useState({
    selectedProviders: [],
    period: "",
    fromDate: null,
    toDate: null,
  });

  const [appliedPeriodFilters, setAppliedPeriodFilters] = useState({
    selectedProviders: [],
    period: "",
    fromDate: null,
    toDate: null,
  });

  const [regionsFilters, setRegionsFilters] = useState({
    providerId: null,
    connectors: [],
    period: "",
    fromDate: null,
    toDate: null,
  });

  const [appliedRegionsFilters, setAppliedRegionsFilters] = useState({
    providerId: null,
    connectors: [],
    period: "",
    fromDate: null,
    toDate: null,
  });

  const [servicesFilters, setServicesFilters] = useState({
    providerId: null,
    workloads: [],
    connectors: [],
    period: "",
    fromDate: null,
    toDate: null,
  });

  const [appliedServicesFilters, setAppliedServicesFilters] = useState({
    providerId: null,
    workloads: [],
    connectors: [],
    period: "",
    fromDate: null,
    toDate: null,
  });

  const { isExport, setIsExport } = useContext(DashboardContext);

  const getActiveProvider = () => {
    switch (activeTab) {
      case "Workloads":
        return workloadsProvider;
      case "Providers":
        return providersProvider;
      case "Tags":
        return tagsProvider;
      case "Period":
        return periodProvider;
      case "Regions":
        return regionsProvider;
      case "Services":
        return servicesProvider;
      default:
        return null;
    }
  };


  useEffect(() => {
    fetchProviders();
  }, []);

  useEffect(() => {
    if (selectedProvider) {
      fetchWorkloads();
    }
  }, [selectedProvider]);

  useEffect(() => {
    if (getActiveProvider()) {
      fetchConnectors();
    }
  }, [activeTab, getActiveProvider()]);

  useEffect(() => {
    function updateTimeAgo() {
      setTimeAgo(formatDistanceToNow(lastSyncTime, { addSuffix: true }));
    }

    updateTimeAgo();
    const timer = setInterval(updateTimeAgo, 30000); // Update every minute

    return () => clearInterval(timer);
  }, [lastSyncTime]);

  const handleConnectorChange = (allSelected) => {
    const updatedConnectors = allSelected ? connectors : [];
    switch (activeTab) {
      case "Providers":
        setProvidersFilters((prev) => ({
          ...prev,
          connectors: updatedConnectors,
        }));
        break;
      case "Regions":
        setRegionsFilters((prev) => ({
          ...prev,
          connectors: updatedConnectors,
        }));
        break;
      case "Services":
        setServicesFilters((prev) => ({
          ...prev,
          connectors: updatedConnectors,
        }));
        break;
    }
  };
  useEffect(() => {
    if (selectedConnectors.length > 0) {
      fetchGroupByOptions();
    }
  }, [selectedConnectors]);


  const fetchProviders = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const payload = {
        userId: userId,
        orgId: orgId,
        type: "general",
      };

      const response = await getAllProviders(payload);

      if (response.data && response.data.success) {
        setProviders(response.data.data);
        if (response.data.data.length >= 1) {
          const firstProvider = response.data.data[0];
          setSelectedProvider(firstProvider);
          setSelectedProviderId(firstProvider.providerId);
        }
      } else {
        console.log("Unexpected providers response structure:", response);
      }
    } catch (error) {
      console.log("Error fetching providers:", error);
    }
  };

  const fetchConnectors = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const activeProvider = getActiveProvider();
      if (!activeProvider) {
        return;
      }

      const payload = {
        userId: userId,
        orgId: orgId,
        providerId: activeProvider.providerId,
        type: "general",
        workloadId: [],
      };

      const response = await getAllConnectors(payload);

      if (response.data && response.data.success) {
        setConnectors(response.data.data);
      } else {
        console.log("Unexpected connectors response structure:", response);
        setConnectors([]);
      }
    } catch (error) {
      console.log("Error fetching connectors:", error);
      setConnectors([]);
    }
  };
  const fetchTags = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const payload = {
        userId: userId,
        orgId: orgId,
        providerId: selectedProvider.providerId,
      };

      const response = await getUniqueTagsByOrg(payload);

      if (response.data && response.data.success) {
        setTags(response.data.data);
      } else {
        console.log("Unexpected tags response structure:", response);
      }
    } catch (error) {
      console.log("Error fetching tags:", error);
    }
  };

  const syncNowClick = async () => {
    try {
      const payload = {
        orgId: orgId,
      };

      try {
        setLastSyncTime(new Date());
        const response = await syncNowAws(payload);

        if (response.data && response.data.success) {
        } else {
          console.log("Unexpected tags response structure:", response);
        }
      } catch (error) {
        console.log("Error while syncing  Aws");
      }

      try {
        const response = await syncNowAzure(payload);

        if (response.data && response.data.success) {
        } else {
          console.log("Unexpected tags response structure:", response);
        }
      } catch (error) {
        console.log("Error while syncing  Azure ");
      }
    } catch (error) {
      console.log("Error fetching tags:", error);
    }
  };

  const fetchWorkloads = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const payload = {
        userId: userId,
        orgId: orgId,
        orgIdGeneral: [orgId],
        connectorId: [],
        type: "general",
      };
      const response = await getAllWorkloadsByOrgId(payload);

      if (response.data && response.data.success) {
        setWorkloads(response.data.data.workload);
        setGroupByOptions(response.data.data.workload);
      } else {
        console.log("Unexpected workloads response structure:", response);
        setWorkloads([]);
        setGroupByOptions([]);
      }
    } catch (error) {
      console.log("Error fetching workloads:", error);
      setWorkloads([]);
      setGroupByOptions([]);
    }
  };

  const fetchGroupByOptions = async (selectedConnectors) => {
    if (selectedConnectors.length === 0) {
      setGroupByOptions([]);
      return;
    }

    try {
      const userId = localStorage.getItem("userId");
      const payload = {
        userId: userId,
        orgId: orgId,
        orgIdGeneral: [orgId],
        connectorId: selectedConnectors.map((c) => c.connectorId),
        type: "DB",
      };
      const response = await getAllWorkloadsByOrgId(payload);

      if (response.data && response.data.success) {
        setGroupByOptions(response.data.data.workload);
      } else {
        console.log(
          "Unexpected group by options response structure:",
          response
        );
        setGroupByOptions([]);
      }
    } catch (error) {
      console.log("Error fetching group by options:", error);
      setGroupByOptions([]);
    }
  };

  useEffect(() => {
    if (activeTab === "Providers" && providersConnectors.length > 0) {
      fetchGroupByOptions();
    }
  }, [activeTab, providersConnectors]);

  // Call this function when workloads are selected
  useEffect(() => {
    if (activeTab === "Services" && servicesFilters.workloads.length > 0) {
      fetchServiceConnectors(servicesFilters.workloads);
    }
  }, [activeTab, servicesFilters.workloads]);

  // Fetch service connectors when workloads are selected
  useEffect(() => {
    if (activeTab === "Services" && servicesFilters.workloads.length > 0) {
      fetchServiceConnectors(servicesFilters.workloads);
    }
  }, [activeTab, servicesFilters.workloads]);

  const handleProviderChange = (providerId) => {
    if (activeTab === "Period") {
      setPeriodFilters((prev) => {
        let newSelectedProviders;
        if (providerId === "all") {
          newSelectedProviders =
            prev.selectedProviders.length === providers.length
              ? []
              : providers.map((p) => p.providerId);
        } else {
          newSelectedProviders = prev.selectedProviders.includes(providerId)
            ? prev.selectedProviders.filter((id) => id !== providerId)
            : [...prev.selectedProviders, providerId];
        }
        return { ...prev, selectedProviders: newSelectedProviders };
      });
    } else {
      switch (activeTab) {
        case "Workloads":
          setWorkloadsFilters((prev) => ({ ...prev, providerId }));
          break;
        case "Providers":
          setProvidersFilters((prev) => ({ ...prev, providerId }));
          break;
        case "Tags":
          setTagsFilters((prev) => ({ ...prev, providerId }));
          break;
        case "Regions":
          setRegionsFilters((prev) => ({ ...prev, providerId }));
          break;
        case "Services":
          setServicesFilters((prev) => ({ ...prev, providerId }));
          break;
        default:
          console.log("Unknown tab:", activeTab);
      }
    }
  };

  const toggleConnector = (connector) => {
    setProvidersFilters((prev) => {
      const updatedConnectors = prev.connectors.some(
        (c) => c.connectorId === connector.connectorId
      )
        ? prev.connectors.filter((c) => c.connectorId !== connector.connectorId)
        : [...prev.connectors, connector];

      // Trigger group by API call with updated connectors
      fetchGroupByOptions(updatedConnectors);

      return { ...prev, connectors: updatedConnectors };
    });
  };

  const MAX_GROUP_BY_SELECTIONS = 8;

  const toggleGroupBy = (option) => {
    setProvidersFilters((prev) => {
      const isOptionSelected = prev.groupBy.some(
        (g) => g.workloadId === option.workloadId
      );

      if (isOptionSelected) {
        // If the option is already selected, remove it
        return {
          ...prev,
          groupBy: prev.groupBy.filter(
            (g) => g.workloadId !== option.workloadId
          ),
        };
      } else if (prev.groupBy.length < MAX_GROUP_BY_SELECTIONS) {
        // If the option is not selected and we haven't reached the limit, add it
        return { ...prev, groupBy: [...prev.groupBy, option] };
      } else {
        // If we've reached the limit, don't add the new option
        alert(
          `You can select a maximum of ${MAX_GROUP_BY_SELECTIONS} options.`
        );
        return prev;
      }
    });
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isMainFilterOpen &&
        !event.target.closest(".dropdown-menu") &&
        !event.target.closest(".filter-button")
      ) {
        setIsMainFilterOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isMainFilterOpen]);

  const handleMainFilterToggle = () => {
    setIsMainFilterOpen(!isMainFilterOpen);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    // Set the active provider based on the selected tab
    switch (tab) {
      case "Workloads":
        setWorkloadsProvider(selectedProvider);
        break;
      case "Providers":
        setProvidersProvider(selectedProvider);
        break;
      case "Tags":
        setTagsProvider(selectedProvider);
        break;
      case "Period":
        setPeriodProvider(selectedProvider);
        break;
      case "Regions":
        setRegionsProvider(selectedProvider);
        break;
      case "Services":
        setServicesProvider(selectedProvider);
        break;
    }
  };

  const handlePeriodClearAll = () => {
    const resetFilters = {
      period: "",
      fromDate: null,
      toDate: null,
    };

    // Reset the state variables
    setPeriod("");
    setFromDate(null);
    setToDate(null);
    setPeriodError("");

    // Update all filter states with reset values
    setWorkloadsFilters((prev) => ({ ...prev, ...resetFilters }));
    setProvidersFilters((prev) => ({ ...prev, ...resetFilters }));
    setTagsFilters((prev) => ({ ...prev, ...resetFilters }));
    setPeriodFilters((prev) => ({ ...prev, ...resetFilters }));
    setRegionsFilters((prev) => ({ ...prev, ...resetFilters }));
    setServicesFilters((prev) => ({ ...prev, ...resetFilters }));

    // Apply the reset filters to trigger re-render of graphs
    setAppliedWorkloadsFilters((prev) => ({ ...prev, ...resetFilters }));
    setAppliedProvidersFilters((prev) => ({ ...prev, ...resetFilters }));
    setAppliedTagsFilters((prev) => ({ ...prev, ...resetFilters }));
    setAppliedPeriodFilters((prev) => ({ ...prev, ...resetFilters }));
    setAppliedRegionsFilters((prev) => ({ ...prev, ...resetFilters }));
    setAppliedServicesFilters((prev) => ({ ...prev, ...resetFilters }));

    // Close the filter popup
    setIsMainFilterOpen(false);
  };

  const handleApplyFilter = () => {
    switch (activeTab) {
      case "Workloads":
        setAppliedWorkloadsFilters({ ...workloadsFilters });
        break;
      case "Providers":
        setAppliedProvidersFilters({ ...providersFilters });
        break;
      case "Tags":
        setAppliedTagsFilters({ ...tagsFilters });
        break;
      case "Period":
        setAppliedPeriodFilters({ ...periodFilters });
        break;
      case "Regions":
        setAppliedRegionsFilters({ ...regionsFilters });
        break;
      case "Services":
        const servicesPayload = {
          userId: localStorage.getItem("userId"),
          orgId: orgId,
          filterCloudProvider: servicesFilters.providerId,
          filterWorkload: servicesFilters.workloads.map((w) => w.workloadId),
          filterConnector: servicesFilters.connectors.map((c) => c.connectorId),
          period: servicesFilters.period,
          fromDate: servicesFilters.fromDate,
          toDate: servicesFilters.toDate,
        };
        setAppliedServicesFilters(servicesPayload);

        break;
      default:
        console.log("Unknown tab:", activeTab);
    }
    setIsFilterOpen(false);
  };
  // Clear all function

  const handleClearAll = () => {
    const defaultProvider = providers[0]?.providerId;
    const defaultFilters = {
      providerId: defaultProvider,
      period: "",
      fromDate: null,
      toDate: null,
    };

    switch (activeTab) {
      case "Workloads":
        setWorkloadsFilters(defaultFilters);
        setAppliedWorkloadsFilters(defaultFilters);
        break;
      case "Providers":
        setProvidersFilters({ ...defaultFilters, connectors: [], groupBy: [] });
        setAppliedProvidersFilters({
          ...defaultFilters,
          connectors: [],
          groupBy: [],
        });
        break;
      case "Tags":
        setTagsFilters({ ...defaultFilters, selectedTags: [] });
        setAppliedTagsFilters({ ...defaultFilters, selectedTags: [] });
        break;
      case "Period":
        setPeriodFilters({
          ...defaultFilters,
          selectedProviders: [defaultProvider],
        });
        setAppliedPeriodFilters({
          ...defaultFilters,
          selectedProviders: [defaultProvider],
        });
        break;
      case "Regions":
        setRegionsFilters({ ...defaultFilters, connectors: [] });
        setAppliedRegionsFilters({ ...defaultFilters, connectors: [] });
        break;
      case "Services":
        setServicesFilters({
          ...defaultFilters,
          workloads: [],
          connectors: [],
        });
        setAppliedServicesFilters({
          ...defaultFilters,
          workloads: [],
          connectors: [],
        });
        break;
    }
    setIsFilterOpen(false);
  };

  useEffect(() => {
    fetchProviders();
  }, []);

  useEffect(() => {
    if (providers.length >= 1) {
      const firstProvider = providers[0];
      const initialFilters = {
        providerId: firstProvider.providerId,
        period: "",
        fromDate: null,
        toDate: null,
      };

      // Set initial filters for all tabs
      setWorkloadsFilters(initialFilters);
      setProvidersFilters({ ...initialFilters, connectors: [], groupBy: [] });
      setTagsFilters({ ...initialFilters, selectedTags: [] });
      setPeriodFilters({
        ...initialFilters,
        selectedProviders: [firstProvider.providerId],
      });
      setRegionsFilters({ ...initialFilters, connectors: [] });
      setServicesFilters({ ...initialFilters, workloads: [], connectors: [] });

      // Also set the applied filters
      setAppliedWorkloadsFilters(initialFilters);
      setAppliedProvidersFilters({
        ...initialFilters,
        connectors: [],
        groupBy: [],
      });
      setAppliedTagsFilters({ ...initialFilters, selectedTags: [] });
      setAppliedPeriodFilters({
        ...initialFilters,
        selectedProviders: [firstProvider.providerId],
      });
      setAppliedRegionsFilters({ ...initialFilters, connectors: [] });
      setAppliedServicesFilters({
        ...initialFilters,
        workloads: [],
        connectors: [],
      });

      setPeriodFilters({
        selectedProviders: [firstProvider.providerId],
        period: "",
        fromDate: null,
        toDate: null,
      });
      setAppliedPeriodFilters({
        selectedProviders: [firstProvider.providerId],
        period: "",
        fromDate: null,
        toDate: null,
      });
    }
  }, [providers]);


  useEffect(() => {
    const userId = localStorage.getItem("userId");
    fetchProviders(userId, orgId);
  }, [orgId]);

  useEffect(() => {
    fetchProviders();
  }, []);

  useEffect(() => {
    if (selectedProvider) {
      fetchTags();
    }
  }, [selectedProvider]);

  useEffect(() => {
    if (selectedProvider) {
      fetchWorkloads();
    }
  }, [selectedProvider]);

  const getActiveConnectors = () => {
    switch (activeTab) {
      case "Providers":
        return providersFilters.connectors;
      case "Regions":
        return regionsFilters.connectors;
      case "Services":
        return servicesFilters.connectors;
      default:
        return [];
    }
  };

  useEffect(() => {
    if (selectedConnectors.length > 0) {
      fetchGroupByOptions();
    }
  }, [selectedConnectors]);

  useEffect(() => {
    if (activeTab === "Providers" && providersFilters.connectors.length > 0) {
      fetchGroupByOptions(providersFilters.connectors);
    }
  }, [activeTab, providersFilters.connectors]);

  useEffect(() => {
    if (
      activeTab === "Providers" ||
      activeTab === "Regions" ||
      activeTab === "Services"
    ) {
      fetchConnectors();
    }
  }, [activeTab]);


  const handleTagChange = (selectedTag) => {
    setTagsFilters((prev) => {
      let updatedTags;
      if (selectedTag === "all") {
        // If 'All' is selected, toggle between all tags and no tags
        updatedTags =
          prev.selectedTags.length === tags.length
            ? []
            : tags.map((tag) => tag);
      } else {
        // For individual tags, toggle their selection
        updatedTags = prev.selectedTags.includes(selectedTag)
          ? prev.selectedTags.filter((tag) => tag !== selectedTag)
          : [...prev.selectedTags, selectedTag];
      }
      return { ...prev, selectedTags: updatedTags };
    });
  };

  // Handler for workload change

  const handleWorkloadChange = (selectedWorkloads) => {
    setServicesFilters((prev) => ({
      ...prev,
      workloads: selectedWorkloads,
      connectors: [], // Reset connectors when workloads change
    }));
    if (selectedWorkloads.length > 0) {
      fetchServiceConnectors(selectedWorkloads);
    } else {
      setServiceConnectors([]);
    }
  };

  const toggleWorkload = (workload) => {
    setServicesFilters((prev) => {
      const updatedWorkloads = prev.workloads.some(
        (w) => w.workloadId === workload.workloadId
      )
        ? prev.workloads.filter((w) => w.workloadId !== workload.workloadId)
        : [...prev.workloads, workload];

      // Call fetchServiceConnectors with the updated workloads
      fetchServiceConnectors(updatedWorkloads);

      return {
        ...prev,
        workloads: updatedWorkloads,
        connectors: [], // Reset connectors when workloads change
      };
    });
  };

  const handleServiceConnectorChange = (selectedConnectors) => {
    setServicesFilters((prev) => ({
      ...prev,
      connectors: selectedConnectors,
    }));
  };

  const toggleServiceConnector = (connector) => {
    setServicesFilters((prev) => {
      const updatedConnectors = prev.connectors.some(
        (c) => c.connectorId === connector.connectorId
      )
        ? prev.connectors.filter((c) => c.connectorId !== connector.connectorId)
        : [...prev.connectors, connector];
      return {
        ...prev,
        connectors: updatedConnectors,
      };
    });
  };

  const fetchServiceConnectors = async (selectedWorkloads) => {
    try {
      const userId = localStorage.getItem("userId");
      const payload = {
        userId: userId,
        orgId: orgId,
        providerId: servicesFilters.providerId,
        workloadId: selectedWorkloads.map((w) => w.workloadId),
        type: "general",
      };
      const response = await getAllConnectors(payload);

      if (response.data && response.data.success) {
        setServiceConnectors(response.data.data);
      } else {
        console.log(
          "Unexpected service connectors response structure:",
          response
        );
        setServiceConnectors([]);
      }
    } catch (error) {
      console.log("Error fetching service connectors:", error);
      setServiceConnectors([]);
    }
  };

  const [isFromDateFocused, setIsFromDateFocused] = useState(false);
  const [isToDateFocused, setIsToDateFocused] = useState(false);

  const formatDate = (date) => {
    if (!date) return "";
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("-");
  };
  const handleFromDateChange = (value) => {
    if (value) {
      const selectedDate = new Date(value);
      if (!isNaN(selectedDate.getTime())) {
        setFromDate(selectedDate);
        setPeriodError("");
        // Update the filters based on the active tab
        updateFilters("fromDate", selectedDate);
      } else {
        setFromDate(null);
      }
    } else {
      setFromDate(null);
      // You might want to update filters here as well, depending on your requirements
      // updateFilters("fromDate", null);
    }
  };

  const handleToDateChange = (value) => {
    if (value) {
      const selectedDate = new Date(value);
      if (!isNaN(selectedDate.getTime())) {
        setToDate(selectedDate);
        setPeriodError("");
        // Update the filters based on the active tab
        updateFilters("toDate", selectedDate);
      } else {
        setToDate(null);
      }
    } else {
      setToDate(null);
      // You might want to update filters here as well, depending on your requirements
      // updateFilters("toDate", null);
    }
  };
  const handlePeriodChange = (newPeriod) => {
    setPeriod(newPeriod);
    setFromDate(null);
    setToDate(null);
    setPeriodError("");

    // Update the filters based on the active tab
  };

  const updateFilters = (field, value) => {
    const updateFunction = (prev) => ({
      ...prev,
      [field]: value,
    });

    switch (activeTab) {
      case "Workloads":
        setWorkloadsFilters(updateFunction);
        break;
      case "Providers":
        setProvidersFilters(updateFunction);
        break;
      case "Tags":
        setTagsFilters(updateFunction);
        break;
      case "Period":
        setPeriodFilters(updateFunction);
        break;
      case "Regions":
        setRegionsFilters(updateFunction);
        break;
      case "Services":
        setServicesFilters(updateFunction);
        break;
    }
  };

  const handlePeriodApplyFilter = () => {
    if (!period || !fromDate || !toDate) {
      setPeriodError("Please fill all fields");
      return;
    }

    if (fromDate > toDate) {
      setPeriodError("From date cannot be after To date");
      return;
    }

    const diffInDays = moment(toDate).diff(moment(fromDate), "days");
    if (period === "monthly" && diffInDays > 180) {
      setPeriodError("Date range cannot exceed 6 months for monthly period");
      return;
    }
    if (period === "daily" && diffInDays > 30) {
      setPeriodError("Date range cannot exceed 30 days for daily period");
      return;
    }

    // Format dates to "dd-mm-yyyy"
    const formattedFromDate = moment(fromDate).format("DD-MM-YYYY");
    const formattedToDate = moment(toDate).format("DD-MM-YYYY");

    // Create new filters
    const newFilters = {
      period,
      fromDate: formattedFromDate,
      toDate: formattedToDate,
    };

    // Update all filter states
    setWorkloadsFilters((prev) => ({ ...prev, ...newFilters }));
    setProvidersFilters((prev) => ({ ...prev, ...newFilters }));
    setTagsFilters((prev) => ({ ...prev, ...newFilters }));
    setPeriodFilters((prev) => ({ ...prev, ...newFilters }));
    setRegionsFilters((prev) => ({ ...prev, ...newFilters }));
    setServicesFilters((prev) => ({ ...prev, ...newFilters }));

    // Apply the filters
    setAppliedWorkloadsFilters((prev) => ({ ...prev, ...newFilters }));
    setAppliedProvidersFilters((prev) => ({ ...prev, ...newFilters }));
    setAppliedTagsFilters((prev) => ({ ...prev, ...newFilters }));
    setAppliedPeriodFilters((prev) => ({ ...prev, ...newFilters }));
    setAppliedRegionsFilters((prev) => ({ ...prev, ...newFilters }));
    setAppliedServicesFilters((prev) => ({ ...prev, ...newFilters }));

    // Close the filter popup
    setIsMainFilterOpen(false);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="px-4 dashboard-container ps-0">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                {/* sidebar-menu start */}
                <SideBar />
                {/*sidebar-menu end  */}
                {/* dashboard starts here */}
                <div className="col-md-9 px-4 mt-4" id="dashboardCon">
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab"
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="d-flex justify-content-between align-items-center">
                                <p className="mb-0 font-32 font-bold black-v2">
                                  Dashboard
                                </p>
                                <div className="d-flex align-items-center gap-2">
                                  {/* filter start */}
                                  <button
                                    type="button"
                                    className="filter-button p-2 position-relative lh-0"
                                    data-bs-toggle="dropdown"
                                    id="dropdownMenuLink"
                                    data-bs-auto-close="outside"
                                    onClick={handleMainFilterToggle}
                                  >
                                    <img
                                      src="images/filter-blue.svg"
                                      alt="filter-icon"
                                    />
                                  </button>
                                  {/*filter*/}

                                  <div
                                    className="dropdown-menu dropdown-menu-end border-0 custom-shadow dd-position p-4 background-white custom-filter-width"
                                    aria-labelledby="dropdownMenuLink"
                                  >
                                    <h5 className="font-medium font-16 mb-0 color-black mb-4">
                                      Filter
                                    </h5>
                                    <div className="row">
                                      <div className="col-md-6 mb-4">
                                        <label
                                          className="form-label font-14 font-medium black-v2"
                                          htmlFor="period"
                                        >
                                          Period
                                        </label>
                                        <select
                                          className="form-select custom-dropdown custom-select shadow-none custom-border-box py-1 font-14 font-regular black-v2"
                                          aria-label="Default select example"
                                          id="period"
                                          value={period}
                                          onChange={(e) =>
                                            handlePeriodChange(
                                              e.target.value,
                                              fromDate,
                                              toDate
                                            )
                                          }
                                        >
                                          <option value="">Select</option>
                                          <option value="monthly">
                                            Monthly
                                          </option>
                                          <option value="daily">Daily</option>
                                        </select>
                                      </div>
                                      <div className="col-md-12 mb-2">
                                        <label className="font-14 font-medium black-v2">
                                          Date Range
                                        </label>
                                      </div>

                                      <div className="col-md-6 mb-4">
                                        <label
                                          className="form-label font-14 font-medium label-color black-v2"
                                          htmlFor="from"
                                        >
                                          From
                                        </label>
                                        <div className="position-relative">
                                          {/* From date input */}
                                          <input
                                            className="form-control custom-dropdown shadow-none py-1"
                                            type={
                                              isFromDateFocused
                                                ? "date"
                                                : "text"
                                            }
                                            id="from"
                                            value={
                                              isFromDateFocused
                                                ? fromDate
                                                  ? fromDate
                                                    .toISOString()
                                                    .split("T")[0]
                                                  : ""
                                                : fromDate
                                                  ? formatDate(fromDate)
                                                  : ""
                                            }
                                            onChange={(e) =>
                                              handleFromDateChange(
                                                e.target.value
                                              )
                                            }
                                            onFocus={() =>
                                              setIsFromDateFocused(true)
                                            }
                                            onBlur={() =>
                                              setIsFromDateFocused(false)
                                            }
                                            placeholder="dd-mm-yyyy"
                                            min={
                                              period === "monthly"
                                                ? new Date(
                                                  new Date().setMonth(
                                                    new Date().getMonth() - 6
                                                  )
                                                )
                                                  .toISOString()
                                                  .split("T")[0]
                                                : new Date(
                                                  new Date().setMonth(
                                                    new Date().getMonth() - 1
                                                  )
                                                )
                                                  .toISOString()
                                                  .split("T")[0]
                                            }
                                            max={
                                              new Date()
                                                .toISOString()
                                                .split("T")[0]
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6 mb-4">
                                        <label
                                          className="form-label font-14 font-medium label-color black-v2"
                                          htmlFor="to"
                                        >
                                          To
                                        </label>
                                        <div className="position-relative">
                                          <input
                                            className="form-control custom-dropdown shadow-none py-1"
                                            type={
                                              isToDateFocused ? "date" : "text"
                                            }
                                            id="to"
                                            value={
                                              isToDateFocused
                                                ? toDate
                                                  ? toDate
                                                    .toISOString()
                                                    .split("T")[0]
                                                  : ""
                                                : toDate
                                                  ? formatDate(toDate)
                                                  : ""
                                            }
                                            onChange={(e) =>
                                              handleToDateChange(e.target.value)
                                            }
                                            onFocus={() =>
                                              setIsToDateFocused(true)
                                            }
                                            onBlur={() =>
                                              setIsToDateFocused(false)
                                            }
                                            placeholder="dd-mm-yyyy"
                                            min={
                                              fromDate
                                                ? fromDate
                                                  .toISOString()
                                                  .split("T")[0]
                                                : ""
                                            }
                                            max={
                                              new Date()
                                                .toISOString()
                                                .split("T")[0]
                                            }
                                          />
                                        </div>
                                      </div>
                                      {periodError && (
                                        <div className="col-md-12 mb-2">
                                          <span className="error-message text-danger">
                                            {periodError}
                                          </span>
                                        </div>
                                      )}
                                      <div className="col-md-12">
                                        <div className="d-flex justify-content-end align-items-center pt-3 pb-0 gap-3">
                                          <button
                                            className="button outline-button px-4 py-2"
                                            onClick={handlePeriodClearAll}
                                          >
                                            Clear All
                                          </button>
                                          <button
                                            className="button primary-button py-2 px-3"
                                            onClick={handlePeriodApplyFilter}
                                            disabled={
                                              !period ||
                                              !fromDate ||
                                              !toDate ||
                                              !!periodError
                                            }
                                          >
                                            Apply
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/*filter*/}
                                  {/* filter end */}
                                  <ExportComponent />
                                  <button
                                    type="button"
                                    className="button primary-button"
                                    onClick={() => {
                                      syncNowClick();
                                    }}
                                  >
                                    Sync Now
                                  </button>
                                </div>
                              </div>
                              <div className="text-end mb-3">
                                <p className="my-3 mb-0 font-10 font-medium secondary-text-color-v4">
                                  {lastSyncTime ? `Last Synced ${timeAgo}` : ""}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <OrgProvider>
                          <OverallCost />
                        </OrgProvider>

                        <div className="col-md-12 mt-2 mb-3">
                          <div className="count-container">
                            <div className="d-flex justify-content-between align-items-center mb-2">
                              <p className="font-14 font-medium black-v3 m-0">
                                Cost by
                              </p>
                              {/* filter start */}
                              <button
                                type="button"
                                className="filter-button p-2 position-relative lh-0"
                                data-bs-toggle="dropdown"
                                id="dropdownMenuLink"
                                data-bs-auto-close="outside"
                                onClick={() => setIsFilterOpen(!isFilterOpen)}
                              >
                                <img
                                  src="images/filter-blue.svg"
                                  alt="filter-icon"
                                />
                              </button>
                              <div
                                className="dropdown-menu dropdown-menu-end border-0 custom-shadow dd-position p-4 background-white custom-filter-width"
                                aria-labelledby="dropdownMenuLink"
                              >
                                <h5 className="font-medium font-16 mb-0 color-black mb-4">
                                  Filter
                                </h5>
                                <div className="row">
                                  <div className="col-md-6 mb-4">
                                    <label
                                      className="form-label font-14 font-medium black-v2"
                                      htmlFor="CloudProvider1"
                                    >
                                      Cloud Provider
                                    </label>
                                    {activeTab === "Period" ? (
                                      <div className="dropdown active-check">
                                        <button
                                          type="button"
                                          className="form-select custom-dropdown custom-select shadow-none form-field-border py-1 font-14 font-regular black-v2 d-flex"
                                          id="cloudProviderDropdown"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <span>
                                            {periodFilters.selectedProviders
                                              .length === providers.length
                                              ? "All"
                                              : periodFilters.selectedProviders
                                                .length > 1
                                                ? `${periodFilters.selectedProviders.length} selected`
                                                : providers.find(
                                                  (p) =>
                                                    p.providerId ===
                                                    periodFilters
                                                      .selectedProviders[0]
                                                )?.providerName || "Select"}
                                          </span>
                                          {/* <img
                                            src="images/drop-down-arrow.svg"
                                            alt="dropdown-arrow"
                                          /> */}
                                        </button>
                                        <ul
                                          className="dropdown-menu body-bg access-dropdown"
                                          aria-labelledby="cloudProviderDropdown"
                                        >
                                          <li className="font-regular color-black font-12 p-2 custom-list">
                                            <div className="form-check">
                                              <input
                                                type="checkbox"
                                                className="form-check-input custom-checkbox"
                                                id="provider-all"
                                                checked={
                                                  periodFilters
                                                    .selectedProviders
                                                    .length === providers.length
                                                }
                                                onChange={() =>
                                                  handleProviderChange("all")
                                                }
                                              />
                                              <label
                                                className="form-check-label font-11 font-regular black-v2 cursor-pointer"
                                                htmlFor="provider-all"
                                              >
                                                All
                                              </label>
                                            </div>
                                          </li>
                                          {providers.map((provider) => (
                                            <li
                                              key={provider.providerId}
                                              className="font-regular color-black font-12 p-2 custom-list"
                                            >
                                              <div className="form-check">
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input custom-checkbox"
                                                  id={`provider-${provider.providerId}`}
                                                  checked={periodFilters.selectedProviders.includes(
                                                    provider.providerId
                                                  )}
                                                  onChange={() =>
                                                    handleProviderChange(
                                                      provider.providerId
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="form-check-label font-11 font-regular black-v2 cursor-pointer"
                                                  htmlFor={`provider-${provider.providerId}`}
                                                >
                                                  {provider.providerName}
                                                </label>
                                              </div>
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    ) : (
                                      <select
                                        className="form-select custom-dropdown custom-select shadow-none custom-border-box py-1 font-14 font-regular black-v2"
                                        aria-label="Default select example"
                                        id="CloudProvider1"
                                        value={
                                          activeTab === "Workloads"
                                            ? workloadsFilters.providerId
                                            : activeTab === "Providers"
                                              ? providersFilters.providerId
                                              : activeTab === "Tags"
                                                ? tagsFilters.providerId
                                                : activeTab === "Regions"
                                                  ? regionsFilters.providerId
                                                  : activeTab === "Services"
                                                    ? servicesFilters.providerId
                                                    : ""
                                        }
                                        onChange={(e) =>
                                          handleProviderChange(e.target.value)
                                        }
                                        disabled={providers.length === 1}
                                      >
                                        {providers.map((provider) => (
                                          <option
                                            key={provider.providerId}
                                            value={provider.providerId}
                                          >
                                            {provider.providerName}
                                          </option>
                                        ))}
                                      </select>
                                    )}
                                  </div>

                                  {(activeTab === "Providers" ||
                                    activeTab === "Regions") && (
                                      <div className="col-md-6 mb-4">
                                        <label
                                          className="form-label font-14 font-medium black-v2"
                                          htmlFor="Connectorstatus"
                                        >
                                          Connector
                                        </label>
                                        <div className="dropdown active-check">
                                          <button
                                            type="button"
                                            className="form-select custom-dropdown custom-select shadow-none form-field-border py-1 font-14 font-regular black-v2 d-flex"
                                            id="connectorDropdown"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                          >
                                            <span>
                                              {getActiveConnectors().length > 0
                                                ? `${getActiveConnectors().length
                                                } selected`
                                                : "Select"}
                                            </span>
                                            {/* <img
                                            src="images/drop-down-arrow.svg"
                                            alt="dropdown-arrow"
                                          /> */}
                                          </button>
                                          <ul
                                            className="dropdown-menu body-bg access-dropdown"
                                            aria-labelledby="connectorDropdown"
                                          >
                                            <li className="font-regular color-black font-12 p-2 custom-list">
                                              <div className="form-check">
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input custom-checkbox"
                                                  id="connector-all"
                                                  checked={
                                                    getActiveConnectors()
                                                      .length ===
                                                    connectors.length &&
                                                    connectors.length > 0
                                                  }
                                                  onChange={(e) =>
                                                    handleConnectorChange(
                                                      e.target.checked
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="form-check-label font-11 font-regular black-v2 cursor-pointer"
                                                  htmlFor="connector-all"
                                                >
                                                  All
                                                </label>
                                              </div>
                                            </li>
                                            {connectors.map((connector) => (
                                              <li
                                                key={connector.connectorId}
                                                className="font-regular color-black font-12 p-2 custom-list"
                                              >
                                                <div className="form-check">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input custom-checkbox"
                                                    id={`connector-${connector.connectorId}`}
                                                    checked={getActiveConnectors().some(
                                                      (c) =>
                                                        c.connectorId ===
                                                        connector.connectorId
                                                    )}
                                                    onChange={() =>
                                                      toggleConnector(connector)
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label font-11 font-regular black-v2 cursor-pointer"
                                                    htmlFor={`connector-${connector.connectorId}`}
                                                  >
                                                    {connector.connectorName}
                                                  </label>
                                                </div>
                                              </li>
                                            ))}
                                          </ul>
                                        </div>
                                      </div>
                                    )}

                                  {activeTab === "Providers" && (
                                    <div className="col-md-6 mb-4">
                                      <label
                                        className="form-label font-14 font-medium black-v2"
                                        htmlFor="Group-By"
                                      >
                                        Group By
                                      </label>
                                      <div className="dropdown active-check">
                                        <button
                                          type="button"
                                          className="form-select custom-dropdown custom-select shadow-none form-field-border py-1 font-14 font-regular black-v2 d-flex"
                                          id="groupByDropdown"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                          disabled={
                                            providersFilters.connectors
                                              .length === 0
                                          }
                                        >
                                          <span>
                                            {providersFilters.groupBy.length > 0
                                              ? `${providersFilters.groupBy.length} selected`
                                              : "Select"}
                                          </span>
                                          {/* <img
                                            src="images/drop-down-arrow.svg"
                                            alt="dropdown-arrow" */}
                                          {/* /> */}
                                        </button>
                                        <ul
                                          className="dropdown-menu body-bg access-dropdown"
                                          aria-labelledby="groupByDropdown"
                                        >
                                          {groupByOptions.map((option) => (
                                            <li
                                              key={option.workloadId}
                                              className="font-regular color-black font-12 p-2 custom-list"
                                            >
                                              <div className="form-check">
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input custom-checkbox"
                                                  id={`groupby-${option.workloadId}`}
                                                  checked={providersFilters.groupBy.some(
                                                    (g) =>
                                                      g.workloadId ===
                                                      option.workloadId
                                                  )}
                                                  onChange={() =>
                                                    toggleGroupBy(option)
                                                  }
                                                  disabled={
                                                    providersFilters.groupBy
                                                      .length >=
                                                    MAX_GROUP_BY_SELECTIONS &&
                                                    !providersFilters.groupBy.some(
                                                      (g) =>
                                                        g.workloadId ===
                                                        option.workloadId
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="form-check-label font-11 font-regular black-v2 cursor-pointer"
                                                  htmlFor={`groupby-${option.workloadId}`}
                                                >
                                                  {option.workloadName}
                                                </label>
                                              </div>
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    </div>
                                  )}

                                  {activeTab === "Tags" && (
                                    <div className="col-md-6 mb-4">
                                      <label
                                        className="form-label font-14 font-medium black-v2"
                                        htmlFor="Tags"
                                      >
                                        Tags
                                      </label>
                                      <div className="dropdown active-check">
                                        <button
                                          type="button"
                                          className="form-select custom-dropdown custom-select shadow-none form-field-border py-1 font-14 font-regular black-v2 d-flex"
                                          id="tagsDropdown"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <span>
                                            {tagsFilters.selectedTags.length ===
                                              tags.length
                                              ? "All"
                                              : tagsFilters.selectedTags
                                                .length > 0
                                                ? `${tagsFilters.selectedTags.length} selected`
                                                : "Select"}
                                          </span>
                                          {/* <img
                                            src="images/drop-down-arrow.svg"
                                            alt="dropdown-arrow"
                                          /> */}
                                        </button>
                                        <ul
                                          className="dropdown-menu body-bg access-dropdown"
                                          aria-labelledby="tagsDropdown"
                                        >
                                          <li className="font-regular color-black font-12 p-2 custom-list">
                                            <div className="form-check">
                                              <input
                                                type="checkbox"
                                                className="form-check-input custom-checkbox"
                                                id="tags-all"
                                                checked={
                                                  tagsFilters.selectedTags
                                                    .length === tags.length
                                                }
                                                onChange={() =>
                                                  handleTagChange("all")
                                                }
                                              />
                                              <label
                                                className="form-check-label font-11 font-regular black-v2 cursor-pointer"
                                                htmlFor="tags-all"
                                              >
                                                All
                                              </label>
                                            </div>
                                          </li>
                                          {tags.map((tag) => (
                                            <li
                                              key={tag}
                                              className="font-regular color-black font-12 p-2 custom-list"
                                            >
                                              <div className="form-check">
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input custom-checkbox"
                                                  id={`tag-${tag}`}
                                                  checked={tagsFilters.selectedTags.includes(
                                                    tag
                                                  )}
                                                  onChange={() =>
                                                    handleTagChange(tag)
                                                  }
                                                />
                                                <label
                                                  className="form-check-label font-11 font-regular black-v2 cursor-pointer"
                                                  htmlFor={`tag-${tag}`}
                                                >
                                                  {tag}
                                                </label>
                                              </div>
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    </div>
                                  )}

                                  {activeTab === "Services" && (
                                    <>
                                      <div className="col-md-6 mb-4">
                                        <label
                                          className="form-label font-14 font-medium black-v2"
                                          htmlFor="Workload"
                                        >
                                          Workload
                                        </label>
                                        <div className="dropdown active-check">
                                          <button
                                            type="button"
                                            className="form-select custom-dropdown custom-select shadow-none form-field-border py-1 font-14 font-regular black-v2 d-flex"
                                            id="workloadDropdown"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                          >
                                            <span>
                                              {servicesFilters.workloads
                                                .length > 0
                                                ? `${servicesFilters.workloads.length} selected`
                                                : "Select"}
                                            </span>
                                            {/* <img
                                              src="images/drop-down-arrow.svg"
                                              alt="dropdown-arrow"
                                            /> */}
                                          </button>
                                          <ul
                                            className="dropdown-menu body-bg access-dropdown"
                                            aria-labelledby="workloadDropdown"
                                          >
                                            <li className="font-regular color-black font-12 p-2 custom-list">
                                              <div className="form-check">
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input custom-checkbox"
                                                  id="workload-all"
                                                  checked={
                                                    servicesFilters.workloads
                                                      .length ===
                                                    workloads.length &&
                                                    workloads.length > 0
                                                  }
                                                  onChange={(e) =>
                                                    handleWorkloadChange(
                                                      e.target.checked
                                                        ? workloads
                                                        : []
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="form-check-label font-11 font-regular black-v2 cursor-pointer"
                                                  htmlFor="workload-all"
                                                >
                                                  All
                                                </label>
                                              </div>
                                            </li>
                                            {workloads.map((workload) => (
                                              <li
                                                key={workload.workloadId}
                                                className="font-regular color-black font-12 p-2 custom-list"
                                              >
                                                <div className="form-check">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input custom-checkbox"
                                                    id={`workload-${workload.workloadId}`}
                                                    checked={servicesFilters.workloads.some(
                                                      (w) =>
                                                        w.workloadId ===
                                                        workload.workloadId
                                                    )}
                                                    onChange={() =>
                                                      toggleWorkload(workload)
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label font-11 font-regular black-v2 cursor-pointer"
                                                    htmlFor={`workload-${workload.workloadId}`}
                                                  >
                                                    {workload.workloadName}
                                                  </label>
                                                </div>
                                              </li>
                                            ))}
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="col-md-6 mb-4">
                                        <label
                                          className="form-label font-14 font-medium black-v2"
                                          htmlFor="Connectorstatus"
                                        >
                                          Connector
                                        </label>
                                        <div className="dropdown active-check">
                                          <button
                                            type="button"
                                            className="form-select custom-dropdown custom-select shadow-none form-field-border py-1 font-14 font-regular black-v2 d-flex d-flex"
                                            id="serviceConnectorDropdown"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                            disabled={
                                              servicesFilters.workloads
                                                .length === 0
                                            }
                                          >
                                            <span>
                                              {servicesFilters.connectors
                                                .length > 0
                                                ? `${servicesFilters.connectors.length} selected`
                                                : "Select"}
                                            </span>
                                            {/* <img
                                              src="images/drop-down-arrow.svg"
                                              alt="dropdown-arrow"
                                            /> */}
                                          </button>
                                          <ul
                                            className="dropdown-menu body-bg access-dropdown"
                                            aria-labelledby="serviceConnectorDropdown"
                                          >
                                            <li className="font-regular color-black font-12 p-2 custom-list">
                                              <div className="form-check">
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input custom-checkbox"
                                                  id="service-connector-all"
                                                  html
                                                  checked={
                                                    servicesFilters.connectors
                                                      .length ===
                                                    serviceConnectors.length &&
                                                    serviceConnectors.length > 0
                                                  }
                                                  onChange={(e) =>
                                                    handleServiceConnectorChange(
                                                      e.target.checked
                                                        ? serviceConnectors
                                                        : []
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="form-check-label font-11 font-regular black-v2 cursor-pointer"
                                                  htmlFor="service-connector-all"
                                                >
                                                  All
                                                </label>
                                              </div>
                                            </li>
                                            {serviceConnectors.map(
                                              (connector) => (
                                                <li
                                                  key={connector.connectorId}
                                                  className="font-regular color-black font-12 p-2 custom-list"
                                                >
                                                  <div className="form-check">
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input custom-checkbox"
                                                      id={`service-connector-${connector.connectorId}`}
                                                      checked={servicesFilters.connectors.some(
                                                        (c) =>
                                                          c.connectorId ===
                                                          connector.connectorId
                                                      )}
                                                      onChange={() =>
                                                        toggleServiceConnector(
                                                          connector
                                                        )
                                                      }
                                                    />
                                                    <label
                                                      className="form-check-label font-11 font-regular black-v2 cursor-pointer"
                                                      htmlFor={`service-connector-${connector.connectorId}`}
                                                    >
                                                      {connector.connectorName}
                                                    </label>
                                                  </div>
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        </div>
                                      </div>
                                    </>
                                  )}

                                  <div className="col-md-12">
                                    <div className="d-flex justify-content-end align-items-center pt-3 pb-0 gap-3">
                                      <button
                                        className="button outline-button px-4 py-2"
                                        onClick={handleClearAll}
                                      >
                                        Clear All
                                      </button>
                                      <button
                                        className="button primary-button py-2 px-3"
                                        onClick={handleApplyFilter}
                                      >
                                        Apply
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/*filter*/}
                              {/* filter end */}
                            </div>
                            {/* tab content starts here */}
                            <div>
                              <div className="custom-tab-scroll mb-4">
                                <ul
                                  className="nav nav-pills d-flex gap-1 flex-nowrap mb-1"
                                  id="pills-tab"
                                  role="tablist"
                                >
                                  <li className="nav-item" role="presentation">
                                    <button
                                      className={`nav-link theme-tab ${activeTab === "Workloads"
                                        ? "active"
                                        : ""
                                        } position-relative cus-tab-border bg-transparent font-12 secondary-text-color font-medium px-3 py-1`}
                                      data-bs-toggle="pill"
                                      data-bs-target="#dash-Workloads"
                                      type="button"
                                      role="tab"
                                      aria-selected={activeTab === "Workloads"}
                                      onClick={() =>
                                        handleTabChange("Workloads")
                                      }
                                    >
                                      Workloads
                                    </button>
                                  </li>
                                  <li className="nav-item" role="presentation">
                                    <button
                                      className={`nav-link  theme-tab ${activeTab === "Providers"
                                        ? "active"
                                        : ""
                                        } position-relative cus-tab-border bg-transparent font-12 secondary-text-color font-medium px-3 py-1`}
                                      data-bs-toggle="pill"
                                      data-bs-target="#dash-Providers"
                                      type="button"
                                      role="tab"
                                      aria-selected={activeTab === "Providers"}
                                      onClick={() =>
                                        handleTabChange("Providers")
                                      }
                                    >
                                      Providers
                                    </button>
                                  </li>
                                  <li className="nav-item" role="presentation">
                                    <button
                                      className={`nav-link  theme-tab ${activeTab === "Tags" ? "active" : ""
                                        } position-relative cus-tab-border bg-transparent font-12 secondary-text-color font-medium px-3 py-1`}
                                      data-bs-toggle="pill"
                                      data-bs-target="#dash-Tags"
                                      type="button"
                                      role="tab"
                                      aria-selected={activeTab === "Tags"}
                                      onClick={() => handleTabChange("Tags")}
                                    >
                                      Tags
                                    </button>
                                  </li>
                                  <li className="nav-item" role="presentation">
                                    <button
                                      className={`nav-link  theme-tab ${activeTab === "Period" ? "active" : ""
                                        } position-relative cus-tab-border bg-transparent font-12 secondary-text-color font-medium px-3 py-1`}
                                      data-bs-toggle="pill"
                                      data-bs-target="#dash-Period"
                                      type="button"
                                      role="tab"
                                      aria-selected={activeTab === "Period"}
                                      onClick={() => handleTabChange("Period")}
                                    >
                                      Period
                                    </button>
                                  </li>
                                  <li className="nav-item" role="presentation">
                                    <button
                                      className={`nav-link theme-tab ${activeTab === "Regions" ? "active" : ""
                                        } position-relative cus-tab-border bg-transparent font-12 secondary-text-color font-medium px-3 py-1`}
                                      data-bs-toggle="pill"
                                      data-bs-target="#dash-Regions"
                                      type="button"
                                      role="tab"
                                      aria-selected={activeTab === "Regions"}
                                      onClick={() => handleTabChange("Regions")}
                                    >
                                      Regions
                                    </button>
                                  </li>
                                  <li className="nav-item" role="presentation">
                                    <button
                                      className={`nav-link theme-tab ${activeTab === "Services" ? "active" : ""
                                        } position-relative cus-tab-border bg-transparent font-12 secondary-text-color font-medium px-3 py-1`}
                                      data-bs-toggle="pill"
                                      data-bs-target="#dash-Services"
                                      type="button"
                                      role="tab"
                                      aria-selected={activeTab === "Services"}
                                      onClick={() =>
                                        handleTabChange("Services")
                                      }
                                    >
                                      Services
                                    </button>
                                  </li>
                                </ul>
                              </div>
                              <div
                                className="tab-content"
                                id="pills-tabContent"
                              >
                                <div
                                  className="tab-pane fade show active pt-4"
                                  id="dash-Workloads"
                                  role="tabpanel"
                                  aria-labelledby="dash-Workloads"
                                >
                                  <div className="w-100 chart-container mb-4">
                                    <OrgProvider>
                                      <CostByWorkloads
                                        period={appliedWorkloadsFilters.period}
                                        fromDate={
                                          appliedWorkloadsFilters.fromDate
                                        }
                                        toDate={appliedWorkloadsFilters.toDate}
                                        selectedProviderId={
                                          appliedWorkloadsFilters.providerId
                                        }
                                      />
                                    </OrgProvider>
                                  </div>
                                  <div className="mt-2 d-flex justify-content-center align-items-center gap-4">
                                    <p className="font-12 font-medium secondary-text-color-v4 mb-1">
                                      <span className="legend legend-success me-1" />
                                      Actual Cost
                                    </p>
                                    <p className="font-12 font-medium secondary-text-color-v4 mb-1">
                                      <span className="legend legend-red me-1" />
                                      Budget
                                    </p>
                                  </div>
                                </div>
                                <div
                                  className={`tab-pane fade ${activeTab === "Providers"
                                    ? "show active"
                                    : ""
                                    } pt-4`}
                                  id="dash-Providers"
                                  role="tabpanel"
                                  aria-labelledby="dash-Providers"
                                >
                                  <div className="w-100 chart-container mb-4">
                                    <OrgProvider>
                                      <CostByProvider
                                        selectedProviderId={
                                          appliedProvidersFilters.providerId
                                        }
                                        selectedConnectors={
                                          appliedProvidersFilters.connectors
                                        }
                                        fromDate={
                                          appliedProvidersFilters.fromDate
                                        }
                                        toDate={appliedProvidersFilters.toDate}
                                        selectedGroupBy={
                                          appliedProvidersFilters.groupBy
                                        }
                                      />
                                    </OrgProvider>
                                  </div>
                                  <div className="mt-2 d-flex justify-content-center align-items-center gap-4">
                                    <p className="font-12 font-medium secondary-color mb-1">
                                      <span className="legend legend-success me-1" />
                                      Actual Cost
                                    </p>
                                    <p className="font-12 font-medium secondary-color mb-1">
                                      <span className="legend legend-red me-1" />
                                      Budget
                                    </p>
                                  </div>
                                </div>
                                <div
                                  className={`tab-pane fade ${activeTab === "Tags" ? "show active" : ""
                                    } pt-4`}
                                  id="dash-Tags"
                                  role="tabpanel"
                                  aria-labelledby="dash-Tags"
                                >
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="text-center">
                                        <div className="d-flex align-items-center justify-content-center">
                                          <div className="chart-container-2 mb-4 me-5">
                                            <OrgProvider>
                                              <CostByTags
                                                selectedProviderId={
                                                  appliedTagsFilters.providerId
                                                }
                                                fromDate={
                                                  appliedTagsFilters.fromDate
                                                }
                                                toDate={
                                                  appliedTagsFilters.toDate
                                                }
                                                selectedTagIds={
                                                  appliedTagsFilters.selectedTags
                                                }
                                              />
                                            </OrgProvider>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`tab-pane fade ${activeTab === "Period" ? "show active" : ""
                                    } pt-4`}
                                  id="dash-Period"
                                  role="tabpanel"
                                  aria-labelledby="dash-Period"
                                >
                                  <div className="w-100 chart-container mb-4">
                                    <OrgProvider>
                                      <CostByPeriod
                                        selectedPeriodProviders={
                                          appliedPeriodFilters.selectedProviders
                                            .length === 1
                                            ? appliedPeriodFilters
                                              .selectedProviders[0]
                                            : ""
                                        }
                                        period={appliedPeriodFilters.period}
                                        fromDate={appliedPeriodFilters.fromDate}
                                        toDate={appliedPeriodFilters.toDate}
                                      />
                                    </OrgProvider>
                                  </div>
                                  <div className="mt-2 d-flex justify-content-center align-items-center gap-4">
                                    <p className="font-12 font-medium secondary-color mb-1">
                                      <span className="legend legend-blue-light me-1" />
                                      AWS
                                    </p>
                                    <p className="font-12 font-medium secondary-color mb-1">
                                      <span className="legend legend-yellow me-1" />
                                      Azure
                                    </p>
                                  </div>
                                </div>
                                <div
                                  className={`tab-pane fade ${activeTab === "Regions" ? "show active" : ""
                                    } pt-4`}
                                  id="dash-Regions"
                                  role="tabpanel"
                                  aria-labelledby="dash-Regions"
                                >
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="text-center">
                                        <div className="d-flex align-items-center justify-content-center">
                                          <div className="chart-container-2 mb-4 text-end me-5">
                                            <OrgProvider>
                                              <CostByRegion
                                                selectedProviderId={
                                                  appliedRegionsFilters.providerId
                                                }
                                                selectedConnectors={
                                                  appliedRegionsFilters.connectors
                                                }
                                                fromDate={
                                                  appliedRegionsFilters.fromDate
                                                }
                                                toDate={
                                                  appliedRegionsFilters.toDate
                                                }
                                              />
                                            </OrgProvider>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`tab-pane fade ${activeTab === "Services"
                                    ? "show active"
                                    : ""
                                    } pt-4`}
                                  id="dash-Services"
                                  role="tabpanel"
                                  aria-labelledby="dash-Services"
                                >
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="text-center">
                                        <div className="d-flex align-items-center justify-content-center">
                                          <div className="chart-container-2 mb-4 text-end me-5">
                                            <OrgProvider>
                                              <CostByService
                                                selectedProviderId={
                                                  appliedServicesFilters.filterCloudProvider
                                                }
                                                selectedWorkload={
                                                  appliedServicesFilters.filterWorkload
                                                }
                                                selectedConnector={
                                                  appliedServicesFilters.filterConnector
                                                }
                                                fromDate={
                                                  appliedServicesFilters.fromDate
                                                }
                                                toDate={
                                                  appliedServicesFilters.toDate
                                                }
                                              />
                                            </OrgProvider>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* tab content ends here */}
                          </div>
                        </div>
                        {/* chart starts here */}
                        <OrgProvider>
                          <AssetIACoverage />
                        </OrgProvider>

                        {/* chart ends here */}
                        {/* chart starts here */}

                        {/* tabbed table starts here */}
                        <OrgProvider>
                          <PipelineCommit isExport={false} />
                        </OrgProvider>
                        {/* tabbed table ends here */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* dashboard starts here */}
              </div>
            </div>
            {/**PDF starts here */}
            {isExport && (
              <div id="pdfdiv" className="col-md-12">
                <div className="col-md-12 md-5">
                  <OrgProvider>
                    <OverallCost />
                  </OrgProvider>
                </div>
                <div className="col-md-12 mb-5">
                  <p className="font-14 font-medium black-v3 mb-4">Cost by workloads</p>
                  <CostByWorkloads
                    period={appliedWorkloadsFilters.period}
                    fromDate={appliedWorkloadsFilters.fromDate}
                    toDate={appliedWorkloadsFilters.toDate}
                    selectedProviderId={appliedWorkloadsFilters.providerId}
                  />
                </div>
                <div className="col-md-12  mb-5">
                  <p className="font-14 font-medium black-v3 mb-4">Cost by Providers</p>
                  <OrgProvider>
                    <CostByProvider
                      selectedProviderId={appliedProvidersFilters.providerId}
                      selectedConnectors={appliedProvidersFilters.connectors}
                      fromDate={appliedProvidersFilters.fromDate}
                      toDate={appliedProvidersFilters.toDate}
                      selectedGroupBy={appliedProvidersFilters.groupBy}
                    />
                  </OrgProvider>
                </div>

                <div className="col-md-12  mb-5">
                  <p className="font-14 font-medium black-v3 mb-4">Cost by period</p>
                  <OrgProvider>
                    <CostByPeriod
                      selectedPeriodProviders={
                        appliedPeriodFilters.selectedProviders.length === 1
                          ? appliedPeriodFilters.selectedProviders[0]
                          : ""
                      }
                      period={appliedPeriodFilters.period}
                      fromDate={appliedPeriodFilters.fromDate}
                      toDate={appliedPeriodFilters.toDate}
                    />
                  </OrgProvider>
                </div>
                {/* <div className="row"> */}

                
                <div className="chart-container-3 px-3 py-4">
                  
              
                  
                  <div className="d-flex sort-alignment">
                    
                  <div className="aligning-center">
                    {/* <div className="col-md-50"> */}
                      <p className="font-14 font-medium black-v3 mb-4">Cost by tags</p>
                      <OrgProvider>
                        <CostByTags
                          selectedProviderId={appliedTagsFilters.providerId}
                          fromDate={appliedTagsFilters.fromDate}
                          toDate={appliedTagsFilters.toDate}
                          selectedTagIds={appliedTagsFilters.selectedTags}
                        />
                      </OrgProvider>
                     </div>
                    
                    {/* <div className="col-md-12 col-lg-6 mb-4 px-2">
                    <div className="col-md-50 d-flex flex-column border-start border-secondary"> */}
                    <div className="aligning-center" style={{marginLeft : "40px"}}>
                      <p className="font-14 font-medium black-v3 mb-4">Cost by region</p>
                      <OrgProvider>
                        <CostByRegion
                          selectedProviderId={appliedRegionsFilters.providerId}
                          selectedConnectors={appliedRegionsFilters.connectors}
                          fromDate={appliedRegionsFilters.fromDate}
                          toDate={appliedRegionsFilters.toDate}
                        />
                      </OrgProvider>
                      </div>
                    
                    </div>
                    </div>
                  
                {/* </div> */}
                {/* </div> */}

                <div className="col-md-12  mb-5">
                  <AssetIACoverage />
                </div>

                <div className="chart-container-3 px-3 py-4">
                <div className="d-flex   mb-5">
                  <p className="font-14 font-medium black-v3 mb-4">Cost by services</p>
                  <div className="d-flex justify-content-center" style={{marginLeft:"490px"}} >
                  <OrgProvider>
                    <CostByService
                      selectedProviderId={
                        appliedServicesFilters.filterCloudProvider
                      }
                      selectedWorkload={appliedServicesFilters.filterWorkload}
                      selectedConnector={appliedServicesFilters.filterConnector}
                      fromDate={appliedServicesFilters.fromDate}
                      toDate={appliedServicesFilters.toDate}
                    />
                  </OrgProvider>
                  </div>
                </div>
                </div>

                <div className="col-md-12  mb-5">
                  <OrgProvider>
                    <PipelineCommit isExport={true} />
                  </OrgProvider>
                </div>
              </div>
            )}

            {/**PDF ends here */}
          </div>
        </div>
      </div>
    </>
  );
};

export default CostDashboard;
