import React, { useContext, useEffect, useState } from "react";
import ChartComponent from "./ChartComponent";
import DbuUtilizationGrid from "./DbuUtilizationGrid";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import * as XLSX from 'xlsx';
import Header from "../Header/Header";
import { fetchOverViewCardData } from "../../Service/api";
import { OrgContext } from "../../ContextProvider/OrgContext";
import WorkbechPopup from "../Organization/Workbench/WorkBench/PopupMaintanence/WorkBenchPopUp";
import { Chart } from "react-chartjs-2";
import FileSaver from "file-saver";
import { DatabricksContext } from "./Databricks";
// import { postErrorLog } from "../../Service/ApiMaintanence/Databricks/Api";

function DbuDashBoard() {

    const OverviewCarddata = {
        clients: 88,
        overallDBUUtilization: 342343,
        overallCommitments: 53456346,
    };

    // State Declaration
    const [cardDatas, setCardDatas] = useState(null);
    const [error, setError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('all');
    const [selectedWidgets, setSelectedWidgets] = useState([]);
    const [downloadFormat, setDownloadFormat] = useState('pdf');

    const [isExporting, setIsExporting] = useState(false);
    const [availableDivs, setAvailableDivs] = useState([]);
    const [selectedDivs, setSelectedDivs] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [exportFormat, setExportFormat] = useState('pdf');
    const [exportOption, setExportOption] = useState('all');
    const userId = localStorage.getItem('userId');
    const [popupMsg, setPopUpMsg] = useState({ "Msg": "", show: false, success: false })


    const { utilisationChart, SetutilisationChart, trendsChart, SetTrendsChart, gridData, setGridData,
        cardData,
        setCardData
    } = useContext(DatabricksContext)


    console.log(utilisationChart, SetutilisationChart, trendsChart, SetTrendsChart, gridData, setGridData,
        cardData,
        setCardData, "This is the card data...")

    //chartData

    const [selectedCards, setSelectedCards] = useState([
        "charts", "Utilization", "forecastedGraph", "forecastGrid", "trends"
    ]);


    console.log("userid", userId)

    const { orgId } = useContext(OrgContext)
    const allowedIds = ["stats-cards", "chart-component", "dbu-utilization-grid"];

    //PS_23 -PS_24

    useEffect(() => {
        // Scan document for elements with 'id' attribute
        const elements = document.querySelectorAll('[id]');

        const divs = Array.from(elements)
            .filter(el => allowedIds.includes(el.id))
            .map(el => ({
                id: el.id,
                name: el.getAttribute('data-export-name') || el.id
            }));

        setAvailableDivs(divs);
    }, []);

    console.log(availableDivs, "availableDivs")
    //PS_66
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);


    //PS_67 - PS_68
    const handleExportOptionChange = (option) => {
        setExportOption(option);
        if (option === 'all') {
            setSelectedDivs(availableDivs.map(div => div.id));
        } else {
            setSelectedDivs([]);
        }
    };

    //PS_27 -PS_29
    const handleDivSelect = (selectedId) => {
        setSelectedDivs(prev => {
            if (prev.includes(selectedId)) {
                return prev.filter(id => id !== selectedId);
            } else {
                return [...prev, selectedId];
            }
        });
        setIsAllSelected(selectedDivs.length + 1 === availableDivs.length);
    };

    //PS_65
    const handleRemoveDiv = (divId) => {
        console.log(`Attempting to remove div with id: ${divId}`);
        console.log(`Current availableDivs before removal:`, availableDivs);

        setAvailableDivs(prev => {
            const newAvailable = prev.filter(div => div.id !== divId);
            console.log(`New availableDivs after removal:`, newAvailable);
            return newAvailable;
        });
    };

    console.log(selectedDivs, "This is the selected divs!!!...")



    //PS_30 -PS_31
    const handleExportFormatChange = (newFormat) => {
        setExportFormat(newFormat);
    };






    //PS_36 - PS_44
    const exportToPDF = async () => {
        // Close the modal   
        setIsModalOpen(false);

        // Wait for 5 seconds   
        setTimeout(async () => {
            // Start the capturing process   
            const pdf = new jsPDF('p', 'mm', 'a4');
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const margins = 10;

            if (exportOption === 'all') {
                // Capture the whole body  
                const body = document.body;
                const html = body.innerHTML;

                const canvas = await html2canvas(body, {
                    scale: 2,
                    logging: false,
                    useCORS: true,
                    ignoreElements: (element) => element.classList.contains('exclude-from-pdf')
                });

                const imgData = canvas.toDataURL('image/jpeg'); // Use JPEG instead of PNG  
                const imgWidth = pdfWidth - 2 * margins;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;

                pdf.addImage(imgData, 'JPEG', margins, margins, imgWidth, imgHeight);
            } else if (exportOption === 'specific') {

                // Capture only the selected widgets  
                const selectedWidgets = availableDivs.filter(div => div.id);

                if (selectedWidgets.length === 0) {
                    console.error("No widgets selected. Please check if selectedDivs is populated correctly.");
                    setPopUpMsg({ ...popupMsg, ["Msg"]: "No widgets selected for PDF export", ["show"]: true, ["success"]: false });
                    return;
                }


                const selectedWidgetsElements = selectedWidgets
                    .map(div => document.getElementById(div.id))
                    .filter(element => element !== null);

                console.log("Selected Widget Elements:", selectedWidgetsElements);

                // Attach the elements to the document  
                const tempContainer = document.createElement('div');
                tempContainer.style.position = 'absolute';
                tempContainer.style.top = '-1000px';
                tempContainer.style.left = '-1000px';
                document.body.appendChild(tempContainer);

                selectedWidgetsElements.forEach(element => {
                    const clone = element.cloneNode(true);
                    tempContainer.appendChild(clone);
                });


                const canvas = await html2canvas(tempContainer, {
                    scale: 2,
                    logging: false,
                    useCORS: true,
                    ignoreElements: (element) => element.classList.contains('exclude-from-pdf')
                });

                const imgData = canvas.toDataURL('image/jpeg');
                const imgWidth = pdfWidth - 2 * margins;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;

                pdf.addImage(imgData, 'JPEG', margins, margins, imgWidth, imgHeight);

                // Remove the temporary container  
                tempContainer.remove();
            }
            setPopUpMsg({ ...popupMsg, ["Msg"]: "Pdf downloaded successfully", ["show"]: true, ["success"]: true });

            pdf.save('databricks-overview.pdf');
        }, 5000); // Increase the timeout to 5 seconds 
    };




    //PS_45 - PS_53
    const exportToExcel = (widgets) => {
        const workbook = XLSX.utils.book_new();


        // Get the entire HTML content
        const html = document.body.innerHTML;

        // Parse the HTML content to extract data
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const tables = doc.querySelectorAll('table');

        // Add data to the worksheet
        tables.forEach((table, index) => {
            const data = XLSX.utils.table_to_sheet(table);
            XLSX.utils.book_append_sheet(workbook, data, `Sheet ${index + 1}`);
        });

        // Generate a blob from the workbook
        const workbookBlob = new Blob([XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' })], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });

        // Create a downloadable link
        const url = URL.createObjectURL(workbookBlob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'databricks-overview.xlsx';
        a.click();
        URL.revokeObjectURL(url);
        setPopUpMsg({ ...popupMsg, ["Msg"]: "Excel downloaded successfully", ["show"]: true, ["success"]: true })


    };

    //PS_54 - PS_60

    
    const exportToPNG = async () => {
        // Close the modal   
        setIsModalOpen(false);

        setTimeout(async () => {
            try {
                let elementToCapture;

                if (exportOption === 'all') {
                    elementToCapture = document.body;

                }  else if (exportOption === 'specific') {
                    // Capture only the selected widgets  
                    const selectedWidgets = availableDivs.filter(div => div.id);
    
                    if (selectedWidgets.length === 0) {
                        console.error("No widgets selected. Please check if selectedDivs is populated correctly.");
                        setPopUpMsg({ ...popupMsg, ["Msg"]: "No widgets selected for PNG export", ["show"]: true, ["success"]: false });
                        return;
                    }
    
                    const selectedWidgetsElements = selectedWidgets
                        .map(div => document.getElementById(div.id))
                        .filter(element => element !== null);
    
                    console.log("Selected Widget Elements:", selectedWidgetsElements);
    
                    // Attach the elements to the document  
                    const tempContainer = document.createElement('div');
                    tempContainer.style.position = 'absolute';
                    tempContainer.style.top = '-1000px';
                    tempContainer.style.left = '-1000px';
                    document.body.appendChild(tempContainer);
    
                    selectedWidgetsElements.forEach(element => {
                        const clone = element.cloneNode(true);
                        tempContainer.appendChild(clone);
                    });
    
                    elementToCapture = tempContainer;
                } else {
                    throw new Error("Invalid export option");
                }
    
                const canvas = await html2canvas(elementToCapture, {
                    scale: 2,
                    logging: false,
                    useCORS: true,
                    ignoreElements: (element) => element.classList.contains('exclude-from-pdf')
                });
    
                const link = document.createElement('a');
                link.download = 'databricks-overview.png';
                link.href = canvas.toDataURL('image/png');
                link.click();
    
                setPopUpMsg({ ...popupMsg, ["Msg"]: "PNG downloaded successfully", ["show"]: true, ["success"]: true });
    
                // Remove the temporary container if it was created
                if (exportOption === 'specific') {
                    elementToCapture.remove();
                }
            } catch (error) {
                console.error("Error generating PNG:", error);
                setPopUpMsg({ ...popupMsg, ["Msg"]: error.message || "Error generating PNG", ["show"]: true, ["success"]: false });
            }
        }, 100); // Short delay to ensure modal is closed
    };

    //PS_35 - PS_63
    const handleExport = async () => {
        setIsExporting(true);
        try {
            const selectedWidgets = availableDivs;
            switch (exportFormat) {
                case 'pdf':
                    await exportToPDF(selectedWidgets);
                    break;
                case 'xlsx':
                    exportToExcel();
                    break;
                case 'png':
                    await exportToPNG();
                    break;
                default:
                    console.error('Unsupported export format');
            }
        } catch (error) {
            console.error('Export failed:', error);
        } finally {
            setIsExporting(false);
            closeModal();
        }
    };




    //PS_9
    useEffect(() => {
        getCardData();
    }, []);

    const userDetails = {
        "userId": userId,
        "orgId": orgId
    }


    //PS_10- PS_22
    const getCardData = async () => {
        try {
            const response = await fetchOverViewCardData(userDetails);

            if (response.status === 200) {
                const { clients, overallCommitments, overallDBUUtilization } = response.data.body.data;
                setCardDatas({
                    clients,
                    overallCommitments,
                    overallDBUUtilization
                });
            } else {
                setError(`Error: ${response.status}`);
            }
        } catch (error) {
            setError('An error occurred while fetching data');

            const errorData = {
                errorName: error.name,
                errorServiceName: "Front-end",
                errorfunctionName: "getCardData",
                errorSource: "frontend",
                errorDiscription: "Couldn't fetch Card Data: " + error.message
            };

            // await postErrorLog(errorData);  
        }
    };


    const jsonToExcel = (data) => {
        const workbook = XLSX.utils.book_new();

        Object.entries(data).forEach(([sheetName, sheetData]) => {
            let worksheet;

            if (Array.isArray(sheetData)) {
                worksheet = XLSX.utils.json_to_sheet(sheetData);
            } else if (typeof sheetData === 'object' && sheetData !== null) {
                const flattenedData = Object.entries(sheetData).map(([key, value]) => ({ Key: key, Value: value }));
                worksheet = XLSX.utils.json_to_sheet(flattenedData);
            } else {
                worksheet = XLSX.utils.aoa_to_sheet([[sheetName, sheetData]]);
            }

            XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
        });

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const excelData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
        FileSaver.saveAs(excelData, 'data.xlsx');
    };


    async function exportSpecific() {
        try {
            switch (exportFormat) {
                case 'pdf':
                    await exportToPDF(selectedWidgets);
                    break;
                case 'xlsx':
                    let exportData = {

                    }
                    if (selectedCards.includes("charts")) {
                        exportData["charts"] = cardDatas
                    }
                    if (selectedCards.includes("Utilization")) {
                        exportData["utilization"] = utilisationChart
                    }
                    if (selectedCards.includes("trends")) {
                        exportData["trends"] = trendsChart
                    }
                    if (selectedCards.includes("forecastedGraph")) {
                        exportData["Utilization_Graph"] = cardData
                    }
                    if (selectedCards.includes("forecastGrid")) {
                        exportData["Uritilization_Grid"] = gridData
                    }
                    jsonToExcel(exportData)
                    break;
                case 'png':
                    await exportToPNG();

                default:
                    console.error('Unsupported export format');
            }
        } catch (error) {
            console.log(error.message);
        }
    }




    return (
        <>

            {
                popupMsg.show == true && popupMsg.Msg != "" ?
                    <WorkbechPopup
                        popupMsg={popupMsg}
                        setPopUpMsg={setPopUpMsg}
                    />
                    : <></>
            }
            {/* header starts */}

            {/* header ends */}
            <div data-export-id="overview-header">
                <div className="container-fluid">
                    <div className="px-4 pt-4 dashboard-container">
                        <div className="d-flex justify-content-between align-items-center exclude-from-pdf">
                            <p className="font-20 font-bold black-v2 m-0">
                                <img src="images/databricks.svg" alt="databricks" className="me-3" />
                                Databricks Overview
                            </p>
                            <div className="d-flex align-items-center gap-2">

                                <button id="export-button"
                                    type="button"
                                    className="btn cust-dropdown d-flex justify-content-between align-items-center gap-3 button secondary-outline-button px-4"
                                    onClick={openModal}
                                >
                                    Export
                                </button>
                                {isModalOpen && (
                                    <div className="modal fade show" id="staticBackdrop-23" style={{ display: 'block', backgroundColor: "rgba(0,0,0,0.7)" }} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                        <div className="modal-dialog modal-dialog-centered justify-content-center">
                                            <div className="modal-content content-540 border-0">
                                                <div className="modal-header border-0 pt-4 pb-0 px-32">
                                                    <h1 className="modal-title font-20 font-bold black-v2" id="staticBackdropLabel">Export</h1>
                                                    <button type="button" className="btn shadow-none pe-0" onClick={closeModal}>
                                                        <img src="images/close-icon.svg" alt="close-icon" className="icon-hover shadow-none modal-close-filter" />
                                                    </button>
                                                </div>
                                                <div className="modal-body pt-0 p-32">
                                                    <h6 className="font-14 font-medium black-v2 mt-4 pt-2">How would you like to download the File?</h6>
                                                    <div className="d-flex gap-5">
                                                        <div className="form-check mt-3">
                                                            <input
                                                                className="form-check-input shadow-none m2e-2"
                                                                type="radio"
                                                                name="flexRadioDefault"
                                                                id="flexRadioDefault1"
                                                                checked={exportOption === 'all'}
                                                                onChange={() => handleExportOptionChange('all')}
                                                            />
                                                            <label className="form-check-label font-14 font-regular secondary-text-color-v4" htmlFor="flexRadioDefault1">
                                                                All Widgets
                                                            </label>
                                                        </div>
                                                        <div className="form-check mt-3">
                                                            <input
                                                                className="form-check-input shadow-none me-2"
                                                                type="radio"
                                                                name="flexRadioDefault"
                                                                id="flexRadioDefault11"
                                                                checked={exportOption === 'specific'}
                                                                onChange={() => handleExportOptionChange('specific')}
                                                            />
                                                            <label className="form-check-label font-14 font-regular secondary-text-color-v4" htmlFor="flexRadioDefault11">
                                                                Specific Widgets
                                                            </label>
                                                        </div>
                                                    </div>


                                                    {exportOption === 'specific' && (
                                                        <>
                                                            <h6 className="font-14 font-medium black-v2 mt-4 pt-2">Select Widgets</h6>
                                                            <div className="col-md-12 mb-4 pb-2 add-organization-form">
                                                                <div className="region-input d-flex gap-2 cust-scroll mt-3">
                                                                    {availableDivs.map(div => (
                                                                        <span key={div.id} className="selected-item d-flex gap-3 align-items-center font-12 font-medium">
                                                                            {div.name}
                                                                            <button
                                                                                onClick={() => handleRemoveDiv(div.id)}
                                                                                style={{ background: 'none', border: 'none', cursor: 'pointer', padding: 0 }}
                                                                            >
                                                                                <img src="images/close-icon.svg" alt="close" />
                                                                            </button>
                                                                        </span>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}


                                                    <h6 className="font-14 font-medium black-v2 mt-4 pt-2">How would you like to download the File?</h6>
                                                    <div className="d-flex justify-content-center gap-3 mt-3">
                                                        <button
                                                            type="button"
                                                            className={`button download-button secondary-text-color-v4 ${exportFormat === 'pdf' ? 'active' : ''}`}
                                                            onClick={() => handleExportFormatChange('pdf')}
                                                        >
                                                            <img src="images/pdf-icon.svg" alt="icon" className="me-2" />PDF
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className={`button download-button secondary-text-color-v4 ${exportFormat === 'xlsx' ? 'active' : ''}`}
                                                            onClick={() => handleExportFormatChange('xlsx')}
                                                        >
                                                            <img src="images/excel-icon.svg" alt="icon" className="me-2" />XLSX
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className={`button download-button secondary-text-color-v4 ${exportFormat === 'png' ? 'active' : ''}`}
                                                            onClick={() => handleExportFormatChange('png')}
                                                        >
                                                            <img src="images/png-icon.svg" alt="icon" className="me-2" />PNG
                                                        </button>
                                                    </div>

                                                    <div className="d-flex justify-content-end gap-3 align-items-center mt-4 pt-3">
                                                        <button type="button" className="button outline-button text-nowrap px-4 py-12" onClick={closeModal}>
                                                            Cancel
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="button primary-button popup-btn px-4 py-12"
                                                            onClick={exportOption == 'specific' ? exportSpecific : handleExport}
                                                        >
                                                            {isExporting ? 'Exporting...' : 'Export'}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}





                            </div>
                        </div>

                    </div>

                    <div data-export-id="stats-cards" >
                        <div className="row">
                            <div className="col-md-12">
                                {/* dashboard starts here */}
                                <div className="row" id="stats-cards">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-6 col-lg-4 mb-4 px-2">
                                                <div className="count-container d-flex justify-content-between align-items-center gap-2">
                                                    <div>
                                                        <p className="font-14 font-regular color-black mb-2">
                                                            #Clients
                                                        </p>
                                                        <p className="font-19 font-bold color-black m-0">
                                                            {cardDatas?.clients}
                                                        </p>
                                                    </div>
                                                    <div className="cost-cont aws-account">
                                                        <img src="images/clients.svg" alt="clients" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-lg-4 mb-4 px-2">
                                                <div className="count-container d-flex justify-content-between align-items-center gap-2">
                                                    <div>
                                                        <p className="font-14 font-regular color-black mb-2 text-nowrap">
                                                            Overall DBU Utilization
                                                        </p>
                                                        <p className="font-19 font-bold color-black m-0">
                                                            {cardDatas?.overallDBUUtilization.toLocaleString()}
                                                        </p>
                                                    </div>
                                                    <div className="cost-cont workload">
                                                        <img src="images/cube-img.svg" alt="cube-img" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-lg-4 mb-4 px-2">
                                                <div className="count-container d-flex justify-content-between align-items-center gap-2">
                                                    <div>
                                                        <p className="font-14 font-regular color-black mb-2">
                                                            Overall Commitments
                                                        </p>
                                                        <p className="font-19 font-bold color-black m-0">
                                                            ${cardDatas?.overallCommitments.toFixed(2)}
                                                        </p>
                                                    </div>
                                                    <div className="cost-cont overall-cost">
                                                        <img src="images/dollar-img.svg" alt="dollar-img" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div data-export-id="chart-component" id="chart-component">
                                    <ChartComponent />
                                </div>

                                <div data-export-id="dbu-utilization-grid" id="dbu-utilization-grid">
                                    <DbuUtilizationGrid isExporting={isExporting} />
                                </div>
                                {/* Page Content End here */}
                                {/* table-container-end */}
                            </div>
                            {/* dashboard starts here */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default DbuDashBoard;
