import { useContext, useEffect, useState } from "react"
import { DashboardContext } from "./Context/DashboardContext"
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export default function ExportComponent() {

  const { exportJson, setExportJson, isExport, setIsExport } = useContext(DashboardContext)
  const [whichExport, setWhichExport] = useState("")

  const jsonToExcel = (data) => {
    const workbook = XLSX.utils.book_new();

    Object.entries(data).forEach(([sheetName, sheetData]) => {
      let worksheet;

      if (Array.isArray(sheetData)) {
        worksheet = XLSX.utils.json_to_sheet(sheetData);
      } else if (typeof sheetData === 'object' && sheetData !== null) {
        const flattenedData = Object.entries(sheetData).map(([key, value]) => ({ Key: key, Value: value }));
        worksheet = XLSX.utils.json_to_sheet(flattenedData);
      } else {
        worksheet = XLSX.utils.aoa_to_sheet([[sheetName, sheetData]]);
      }

      XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    });

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const excelData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    FileSaver.saveAs(excelData, 'data.xlsx');
  };


  const exportToPNG = async () => {
    const element = document.getElementById('pdfdiv');

    if (!element) {
        console.error('Element with id "pdfdiv" not found');
        return;
    }

    try {
        const pageHeight = 1000; // Adjust this value based on your needs
        const elementHeight = element.offsetHeight;
        const pageCount = Math.ceil(elementHeight / pageHeight);

        // Create a canvas that's tall enough to fit all content
        const fullCanvas = document.createElement('canvas');
        const ctx = fullCanvas.getContext('2d');
        fullCanvas.width = element.offsetWidth * 2; // Multiply by 2 for higher resolution
        fullCanvas.height = pageHeight * pageCount * 2; // Multiply by 2 for higher resolution

        for (let i = 0; i < pageCount; i++) {
            const pageCanvas = await html2canvas(element, {
                height: pageHeight,
                y: i * pageHeight,
                scale: 2,
                logging: false,
                useCORS: true
            });

            // Draw each page canvas onto the full canvas
            ctx.drawImage(pageCanvas, 0, i * pageHeight * 2);
        }

        // Create download link for the full canvas
        const link = document.createElement('a');
        link.download = 'dashboard-overview.png';
        link.href = fullCanvas.toDataURL('image/png');
        link.click();

        setIsExport(false);
    } catch (error) {
        console.error('Error generating PNG:', error);
    } finally {
        setIsExport(false);
    }
};

// Call exportToPNG after a delay of 3 minutes (180000 milliseconds)
setTimeout(() => {
    exportToPNG();
}, 2 * 60 * 1000);




const exportToPDF = async () => {
  
  setTimeout(async()=>{
    const element = document.getElementById('pdfdiv');

    if (!element) {
        console.error('Element with id "pdfdiv" not found');
        return;
    }

    try {
        const pageHeight = 1000; // Adjust this value based on your needs
        const elementHeight = element.offsetHeight;
        const pageCount = Math.ceil(elementHeight / pageHeight);

        // Create a canvas that's tall enough to fit all content
        const fullCanvas = document.createElement('canvas');
        const ctx = fullCanvas.getContext('2d');
        fullCanvas.width = element.offsetWidth * 2; // Multiply by 2 for higher resolution
        fullCanvas.height = pageHeight * pageCount * 2; // Multiply by 2 for higher resolution

        for (let i = 0; i < pageCount; i++) {
            const pageCanvas = await html2canvas(element, {
                height: pageHeight,
                y: i * pageHeight,
                scale: 2,
                logging: false,
                useCORS: true
            });

            // Draw each page canvas onto the full canvas
            ctx.drawImage(pageCanvas, 0, i * pageHeight * 2);
        }

        const imgData = fullCanvas.toDataURL('image/png');
        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'px',
            format: [fullCanvas.width, fullCanvas.height]
        });

        pdf.addImage(imgData, 'PNG', 0, 0, fullCanvas.width, fullCanvas.height);
        pdf.save("dashboard-overview.pdf");

        setIsExport(false);
    } catch (error) {
        console.error('Error generating PDF:', error);
    } finally {
        setIsExport(false);
    }
  },1 * 60 * 1000)
};


  useEffect(() => {
    if (isExport == true && whichExport == "PDF") {


      setTimeout(() => {
        exportToPDF()
      }, 5000)

    } else if (isExport == true && whichExport == "PNG") {
      setTimeout(() => { exportToPNG() }, 5000)
    }

  }, [isExport])



  return (
    <div className="dropdown">
      <button
        type="button"
        className="btn cust-dropdown d-flex justify-content-between align-items-center gap-3 button secondary-outline-button px-4"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        Export
      </button>
      <ul
        className="dropdown-menu body-bg cust-drop-menu p-3 background-white dropdown-menu-end"
        aria-labelledby="dropdownMenuButton1"
      >
        <li className="font-16 font-medium color-black mb-3">
          Export as
        </li>
        <li className="font-regular color-black font-12 custom-item my-1">
          <a
            href="#"
            className="d-flex align-items-center custom-drop-item text-decoration-none secondary-text-color-v4 font-14 font-medium mb-1"
            onClick={() => {
              setIsExport(true)
              setWhichExport("PDF")
            }}
          >
            <img
              src="images/pdf-icon.svg"
              alt="pdf-icon"
              className="me-3"
            />{" "}
            PDF
          </a>
        </li>
        <li className="font-regular color-black font-12 custom-item my-1" onClick={() => {
          jsonToExcel(exportJson)
        }}>
          <a
            href="#"
            className="d-flex align-items-center custom-drop-item text-decoration-none secondary-text-color-v4 font-14 font-medium mb-1"
          >
            <img
              src="images/excel-icon.svg"
              alt="excel-icon"
              className="me-3"
            />{" "}
            XLSX
          </a>
        </li>
        <li className="font-regular color-black font-12 custom-item border-0 mt-1">
          <a
            href="#"
            className="d-flex align-items-center custom-drop-item text-decoration-none secondary-text-color-v4 font-14 font-medium"
            onClick={() => {
              if (!isExport) {
                setIsExport(true)
                setWhichExport("PNG")
              }
            }}
          >
            <img
              src="images/png-icon.svg"
              alt="png-icon"
              className="me-3"
            />{" "}
            {isExport ? "Export is in progress" : "PNG"}
          </a>
        </li>
      </ul>
    </div>
  )
}