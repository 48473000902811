
import React from 'react';

const DeployLoader = ({ isLoading }) => {
    return (
        <div className="modal fade show" style={{display: 'block', backgroundColor: 'rgba(0,0,0,0.5)',
             position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 10000}}
            data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
            aria-labelledby="loaderLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered justify-content-center">
            {/* <div className="modal-content border-0 bg-transparent"> */}
                <div className="bg-transparent">
                    <div className="modal-body d-flex align-items-center justify-content-center d-none">
                        <img src="images/Dots Loader 1.gif" alt="Loading..." className="loader-width" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeployLoader;