
import React, { useState, useEffect } from 'react';
import { getAllTagsApi, updateTagsApi } from '../../../../../Service/api';
import { useOrgContext } from '../../../../../ContextProvider/OrgContext';
import { v4 as uuidv4 } from 'uuid';

export default function AssignTags({ handleTagsUpdate, onClose, envId }) {
  const [definedTags, setDefinedTags] = useState([]);
  const [userTags, setUserTags] = useState([]);
  const [newTag, setNewTag] = useState({ key: '', value: '' });
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState('');
  const { orgId, userId } = useOrgContext();

  useEffect(() => {
    getAllTags();
  }, []);
  // Used to Retreive the Tags For the Organization 
  const getAllTags = async () => {
    // let payload = {
    //   "userId": "c7d86e9d-4819-4f5e-95f8-782d4c6774a3",
    //   "organizationId": "28da4a71-14f5-47ce-a349-35840ebf2dcb",
    //   "envId": "5caac303-8ebc-4eb2-96a9-3347d301a4dd"
    // }
    let payload = {
      "userId": userId,
      "organizationId": orgId,
      "envId": envId
    }

    try {
      let res = await getAllTagsApi(payload);
      console.log(res , "GetAll Tags")
      if (res.success) {
        setDefinedTags(res.data.defineTags.map(tag => ({
          tagId: tag.tag_id,
          key: tag.tag_value,
          value: '',
          isAdded: false
        })));
        setUserTags(res.data.tags.map(tag => ({
          tagId: tag.tag_map_id,
          key: tag.tag_name,
          value: tag.tag_value,
          isAdded: true,
          isEditing: false
        })));
      } else {
        console.log("Failed to retrieve tags");
      }
    } catch (error) {
      console.log("Error in getAllTags:", error);
    }
  };

  const handleTagChange = (index, field, value) => {
    const newUserTags = [...userTags];
    if (newUserTags[index].key !== "UTI") {
      newUserTags[index][field] = value;
      setUserTags(newUserTags);
    }
  };

  const addNewTag = () => {
    if (newTag.key && newTag.value && newTag.key !== "UTI") {
      setUserTags([...userTags, { ...newTag, isAdded: true, isEditing: false, tagId: uuidv4() }]);
      setNewTag({ key: '', value: '' });
    }
  };

  const removeTag = (index) => {
    if (userTags[index].key !== "UTI") {
      setUserTags(userTags.filter((_, i) => i !== index));
    }
  };

  
  const toggleEdit = (index) => {
    if (userTags[index].key !== "UTI") {
      const newUserTags = [...userTags];
      newUserTags[index].isEditing = !newUserTags[index].isEditing;
      setUserTags(newUserTags);
    }
  };
  useEffect(()=>{
    console.log(userTags , "UserTags  ")
  },[userTags])

  const saveTags = async () => {
    setIsSaving(true);
    setError('');

    const tagsToSave = userTags.filter(tag => tag.key && tag.value);
    
    if (tagsToSave.length === 0) {
      setIsSaving(false);
      return;
    }

    // const payload = {
      
    //   "userId": "c7d86e9d-4819-4f5e-95f8-782d4c6774a3",
    //   "organizationId": "28da4a71-14f5-47ce-a349-35840ebf2dcb",
    //   "envId": "5caac303-8ebc-4eb2-96a9-3347d301a4dd",      
    //   tags: tagsToSave.map(tag => ({
    //     tagId: tag.tagId,
    //     key: tag.key,
    //     value: tag.value
    //   }))
    // };
    const payload = {
      
      "userId": userId,
      "organizationId": orgId,
      "envId": envId,      
      tags: tagsToSave.map(tag => ({
        tagId: tag.tagId,
        key: tag.key,
        value: tag.value
      }))
    };

    try {
      const response = await updateTagsApi(payload);
      if (response.success) {
        onClose();
        handleTagsUpdate(tagsToSave);
      } else {
        setError('Failed to update tags. Please try again.');
      }
    } catch (error) {
      console.error("Error in saveTags:", error);
      setError('An error occurred while saving tags.');
    } finally {
      setIsSaving(false);
    }
  };
  

  return (
    <div className="modal fade show" id="staticBackdrop-15" style={{ 
      display: 'block', backgroundColor: 'rgba(0,0,0,0.5)',
      zIndex: "100001",
    }} 
    data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" 
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content border-0">
          <div className="modal-header border-0 py-4 px-32">
            <h1 className="modal-title font-20 font-bold black-v2" id="staticBackdropLabel">Assign Tags</h1>
            <button type="button" className="btn shadow-none p-0" onClick={onClose}>
              <img src="images/close-icon.svg" alt="close-icon" className="icon-hover shadow-none modal-close-filter" />
            </button>
          </div>
          <div className="modal-body p-32 custom-nav-scrl">
            {error && <div className="alert alert-danger">{error}</div>}
            <div className="row">
              <div className="col-md-12">
                {userTags.map((tag, index) => (
                  <div key={index} className="row mb-4">
                    <div className="col-md-5 mb-3 px-2">
                      <label className="form-label font-14 font-medium black-v2" htmlFor={`Key-${index}`}>Key</label>
                      <input 
                        type="text"
                        id={`Key-${index}`}
                        className="form-control custom-form font-14 font-medium"
                        value={tag.key}
                        disabled={true}
                      />
                    </div>
                    <div className="col-md-5 mb-3 px-2">
                      <label className="form-label font-14 font-medium black-v2" htmlFor={`Value-${index}`}>Value</label>
                      <input 
                        type="text" 
                        id={`Value-${index}`}
                        className="form-control custom-form font-14 font-medium"
                        placeholder="Enter Value"
                        value={tag.value}
                        onChange={(e) => handleTagChange(index, 'value', e.target.value)}
                        disabled={!tag.isEditing || tag.key === "UTI"}
                      />
                    </div>
                    <div className="col-md-2 d-flex justify-content-center align-items-center gap-3 mb-3 px-2">
                      <button 
                        type="button" 
                        className="btn p-0 mt-4"
                        onClick={() => toggleEdit(index)}
                        disabled={tag.key === "UTI"}
                      >
                        <img src={tag.isEditing ? "/images/tick-large-icon.svg" : "images/edit-icon-blue.svg"} alt="edit-icon" />
                      </button>
                      <button 
                        type="button" 
                        className="btn p-0 mt-4" 
                        onClick={() => removeTag(index)}
                        disabled={tag.key === "UTI"}
                      >
                        <img src="images/bin-icon-red.svg" alt="bin-icon-red" />
                      </button>
                    </div>
                  </div>
                ))}
                <div className="row mb-4">
                  <div className="col-md-5 mb-3 px-2">
                    <label className="form-label font-14 font-medium black-v2" htmlFor="newTagKey">Key</label>
                    <select 
                      id="newTagKey"
                      className="form-select custom-form font-14 font-medium"
                      value={newTag.key}
                      onChange={(e) => setNewTag({...newTag, key: e.target.value})}
                    >
                      <option value="">Select a key</option>
                      {definedTags.filter(tag => tag.key !== "UTI").map(tag => (
                        <option key={tag.tagId} value={tag.key}>{tag.key}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-5 mb-3 px-2">
                    <label className="form-label font-14 font-medium black-v2" htmlFor="newTagValue">Value</label>
                    <input 
                      type="text"
                      id="newTagValue"
                      className="form-control custom-form font-14 font-medium"
                      placeholder="Enter Value"
                      value={newTag.value}
                      onChange={(e) => setNewTag({...newTag, value: e.target.value})}
                    />
                  </div>
                  <div className="col-md-2 d-flex justify-content-center align-items-end mb-3 px-2">
                    <button type="button" className="btn p-0" onClick={addNewTag}>
                      <img src="images/add-plus-icon.svg" alt="add-icon" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end align-items-center gap-3 mt-4">
              <button type="button" className="btn button outline-button px-3" onClick={onClose}>
                Cancel
              </button>
              <button type="button" className="btn button primary-button px-3" onClick={saveTags} disabled={isSaving}>
                {isSaving ? 'Saving...' : 'Save'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}