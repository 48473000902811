export default function AWS_SDK_EFS({ values, onChangeOfConfiguration, isViewer }) {
    try {

        return (
            <div
                class="accordion-body p-0 estimation-container">
                <div className="row py-4 mx-4 px-5">
                    <div className="col-md-6 px-3">
                        <label htmlFor="region" className="form-label font-14 font-medium black-v2 mb-3">
                            Region
                        </label>
                        <select
                            className="form-select custom-form font-semibold font-14 form-select-sm"
                            id="region"
                            style={{ paddingTop: "10px" }}
                            value={values.region}
                            name={"region"}
                            onChange={(e) => {
                                onChangeOfConfiguration(e, values.instanceName);
                            }} disabled={isViewer}
                        >
                            <option value="ap-south-2">ap-south-2</option><option value="ap-southeast-3">ap-southeast-3</option><option value="ap-southeast-1">ap-southeast-1</option><option value="us-gov-east-1">us-gov-east-1</option><option value="eu-west-2">eu-west-2</option><option value="af-south-1">af-south-1</option><option value="ap-northeast-1">ap-northeast-1</option><option value="ap-southeast-2">ap-southeast-2</option><option value="us-east-2">us-east-2</option><option value="eu-central-1">eu-central-1</option><option value="ca-central-1">ca-central-1</option><option value="ap-south-1">ap-south-1</option><option value="ap-northeast-3">ap-northeast-3</option><option value="sa-east-1">sa-east-1</option><option value="ap-east-1">ap-east-1</option><option value="eu-west-1">eu-west-1</option><option value="eu-south-1">eu-south-1</option><option value="ap-southeast-4">ap-southeast-4</option><option value="eu-north-1">eu-north-1</option><option value="eu-south-2">eu-south-2</option><option value="us-west-1">us-west-1</option><option value="us-west-2">us-west-2</option><option value="me-south-1">me-south-1</option><option value="eu-central-2">eu-central-2</option><option value="us-gov-west-1">us-gov-west-1</option><option value="ap-northeast-2">ap-northeast-2</option><option value="me-central-1">me-central-1</option><option value="us-east-1">us-east-1</option><option value="eu-west-3">eu-west-3</option>
                        </select>
                    </div>
                    <div className="col-md-6 px-3">
                        <label htmlFor="EFSstorageType" className="form-label font-14 font-medium black-v2 mb-3">
                            Storage Type
                        </label>
                        <select
                            style={{ paddingTop: "10px" }}
                            className="form-select custom-form font-semibold font-14 form-select-sm mb-3"
                            id="EFSstorageType"
                            value={values.EFSstorageType}
                            name={"EFSstorageType"}
                            onChange={(e) => {
                                onChangeOfConfiguration(e, values.instanceName);
                            }} disabled={isViewer}
                        >
                            <option value="1">Standard Storage</option>
                            <option value="2">One Zone Storage</option>
                        </select>
                    </div>
                    <div className="col-md-6 px-3">
                        <label
                            htmlFor="capacity"
                            className="form-label font-14 font-medium black-v2 mb-3"
                        >
                            Desired Storage Capacity
                        </label>
                        <input
                            type="number"
                            style={{ paddingTop: "10px" }}
                            className="form-control custom-form text-field font-14 font-medium mb-3"
                            id="capacity"
                            value={values.capacity}
                            name={"capacity"}
                            onChange={(e) => {
                                onChangeOfConfiguration(e, values.instanceName);
                            }} disabled={isViewer}
                        />
                    </div>
                    <div className="col-md-6 px-3">
                        <label htmlFor="capacityType" className="form-label font-14 font-medium black-v2 mb-3">
                            Unit
                        </label>
                        <select
                            style={{ paddingTop: "10px" }}
                            className="form-select custom-form font-semibold font-14 form-select-sm mb-3"
                            id="capacityType"
                            value={values.capacityType}
                            name={"capacityType"}
                            onChange={(e) => {
                                onChangeOfConfiguration(e, values.instanceName);
                            }} disabled={isViewer}
                        >
                            <option value="1">GB per Month</option>
                            <option value="2">TB per Month</option>
                        </select>
                    </div>
                    <div className="col-md-6 px-3">
                        <label
                            htmlFor="frequentpercentage"
                            className="form-label font-14 font-medium black-v2 mb-3"
                        >
                            Percentage of data that is frequently accessed
                        </label>
                        <input
                            type="number"
                            className="form-control custom-form text-field font-14 font-medium"
                            id="frequentpercentage"
                            value={values.frequentpercentage}
                            style={{ marginTop: '1px', paddingTop: '8px', paddingBottom: '8px', marginBottom: '10px' }}
                            name={"frequentpercentage"}
                            onChange={(e) => {
                                onChangeOfConfiguration(e, values.instanceName);
                            }} disabled={isViewer}
                        />
                    </div>

                    <div className="col-md-6 px-3">
                        <label
                            htmlFor="InfrequentAccessRequest"
                            className="form-label font-14 font-medium black-v2 mb-3"
                        >
                            Infrequent Access requests
                        </label>
                        <input
                            type="number"
                            style={{ paddingTop: "10px", marginTop: "18px" }}
                            className="form-control custom-form text-field font-14 font-medium"
                            id="InfrequentAccessRequest"
                            value={values.InfrequentAccessRequest}
                            name={"InfrequentAccessRequest"}
                            onChange={(e) => {
                                onChangeOfConfiguration(e, values.instanceName);
                            }} disabled={isViewer}
                        />
                    </div>
                    <div className="col-md-6 px-3">
                        <label htmlFor="InfrequentAccessRequestType" className="form-label font-14 font-medium black-v2 mb-3">
                            Unit
                        </label>
                        <select
                            style={{ paddingTop: "10px", marginTop: "19px" }}
                            className="form-select custom-form font-semibold font-14 form-select-sm"
                            id="InfrequentAccessRequestType"
                            value={values.InfrequentAccessRequestType}
                            name={"InfrequentAccessRequestType"}
                            onChange={(e) => {
                                onChangeOfConfiguration(e, values.instanceName);
                            }} disabled={isViewer}
                        >
                            <option value="1">GB per Month</option>
                            <option value="2">TB per Month</option>
                        </select>
                    </div>

                    <div className="col-md-6 px-3">
                        <label htmlFor="ThroughputType" className="form-label font-14 font-medium black-v2 mb-3">
                            Choose the throughput mode of your file system
                        </label>

                        <select
                            className="form-select custom-form font-semibold font-14 form-select-sm"
                            id="ThroughputType"
                            value={values.ThroughputType}
                            name={"ThroughputType"}
                            style={{ marginTop: '1px', paddingTop: '8px', paddingBottom: '8px', marginBottom: '10px' }}
                            onChange={(e) => {
                                onChangeOfConfiguration(e, values.instanceName);
                            }} disabled={isViewer}
                        >
                            <option value="1">Provisioned Throughput</option>
                        </select>
                    </div>
                    <div className="col-md-6 px-3">
                        <label
                            htmlFor="provisionedThroughput"
                            className="form-label font-14 font-medium black-v2 mb-3">
                            Provisioned Throughput
                        </label>
                        <input
                            type="number"
                            style={{ paddingTop: "10px" }}
                            className="form-control custom-form text-field font-14 font-medium"
                            id="provisionedThroughput"
                            value={values.provisionedThroughput}
                            name={"provisionedThroughput"}
                            onChange={(e) => {
                                onChangeOfConfiguration(e, values.instanceName);
                            }} disabled={isViewer}
                        />
                    </div>
                    <div className="col-md-6 px-3">
                        <label htmlFor="provisionedThroughputUnit" className="form-label font-14 font-medium black-v2 mb-3">
                            Unit
                        </label>
                        <select
                            style={{ paddingTop: "10px" }}
                            className="form-select custom-form font-semibold font-14 form-select-sm"
                            id="provisionedThroughputUnit"
                            value={values.provisionedThroughputUnit}
                            name={"provisionedThroughputUnit"}
                            onChange={(e) => {
                                onChangeOfConfiguration(e, values.instanceName);
                            }} disabled={isViewer}
                        >
                            <option value="1">KB/s per month</option>
                            <option value="2">MB/s per month</option>
                            <option value="3">GB/s per month</option>
                        </select>
                    </div>
                </div>
            </div>
        );
    } catch (error) {
        //console.log(error);
    }
}