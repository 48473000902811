import React, { createContext, useState,useEffect } from 'react';

// Create the context
export const OrgContext = createContext();

// Create a provider component
export const OrgProvider = ({ children }) => {
    const [orgId, setOrgId] = useState(null);
    const [orgData, setOrgData] = useState([]);

    const [providerId, setproviderId] = useState(null);
    const [architectures, setarchitectures] = useState("");
    const [userRoles, setuserRoles] = useState("");
    const [canCreateWorkload, setCanCreateWorkload] = useState(false);
    const [canDeleteWorkload, setCanDeleteWorkload] = useState(false);
    const [canViewWorkload, setCanViewWorkload] = useState(false);
    useEffect(() => {
        // Calculate permissions based on user roles
        setCanCreateWorkload(userRoles.includes('Admin') || userRoles.includes('project admin') || userRoles.includes('Architect'));
        setCanDeleteWorkload(userRoles.includes('Admin') || userRoles.includes('project admin'));
        setCanViewWorkload(userRoles.includes('Admin') || userRoles.includes('project admin') || userRoles.includes('Architect') || userRoles.includes('Viewer'));
    }, [userRoles]);
    console.log("yhjkhbyghyguh",canCreateWorkload);
    return (
        <OrgContext.Provider value={{ orgId, setOrgId ,providerId, setproviderId,orgData, setOrgData,setarchitectures,architectures,userRoles,setuserRoles,canCreateWorkload,canDeleteWorkload,canViewWorkload}}>
            {children}
        </OrgContext.Provider>
    );
};


// keerthana code 


// import React, { createContext, useState } from 'react';

export const OrganizationContext = createContext();

const ContextProvider = ({ children }) => {
  const [organizationId, setOrganizationId] = useState('');
  const [providers, setProviders] = useState([]);

  return (
    <OrganizationContext.Provider value={{ organizationId, providers }}>
      {children}
    </OrganizationContext.Provider>
  );
};

export default ContextProvider;


                      
