import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { getAllArticles, deleteArticle } from "../../Service/api";
import Loader from "../loader/loader";
import WorkbenchPopup from "../Organization/Workbench/WorkBench/PopupMaintanence/WorkBenchPopUp";
import { OrgContext } from "../../ContextProvider/OrgContext";
import { useLocation } from "react-router-dom";

//PS_01 to PS_06 Declaring state variables
const ArticleGrid = () => {
  const [activeTab, setActiveTab] = useState("recent");
  const [articles, setArticles] = useState({ recent: [], all: [] });
  const [searchText, setSearchText] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);
  //PS_08 Declare useNavigate
  const navigate = useNavigate();
  const [popupMsg, setPopUpMsg] = useState({
    Msg: "",
    show: false,
    success: false,
  });

  const[showLoader, setShowLoader] = useState(false)
  const isAdmin = JSON.parse(localStorage.getItem('isAdmin'));
  const location = useLocation();

  //PS_09 use effect for trigggering
  useEffect(() => {
    fetchArticles();
  }, []);

  useEffect(() => {
    const { state } = location;
    if (state && state.activeTab) {
      setActiveTab(state.activeTab);
    }
  }, [location]);
  //PS_09 to PS_13 Function to fetch article data

  const fetchArticles = async () => {
    try {
      const userId = localStorage.getItem("userId");
      setShowLoader(true)
      const response = await getAllArticles({ userId });
      setShowLoader(false)
      if (response.data && response.data.body && response.data.body.success) {
        const { Recent, data } = response.data.body;
        if (Recent && data) {
          setArticles({
            recent: Recent,
            all: data,
          });
        } else {
          console.error("Unexpected API response structure:", response);
          setShowLoader(false)
          setArticles({ recent: [], all: [] });
        }
      } else {
        console.error("Unexpected API response structure:", response);
        setShowLoader(false)
        setArticles({ recent: [], all: [] });
      }
    } catch (error) {
      console.error("Error fetching articles:", error);
      setShowLoader(false)
      setArticles({ recent: [], all: [] });
    }
  };

  //PS_14 to PS_17 Function to handle search
  const handleSearch = () => {
    if (searchText.length >= 3) {
      const userId = localStorage.getItem("userId");
      navigate("/ViewArticle", { state: { userId, searchText } });
    }
  };

  //PS_18 to PS_20 Function to hadle card click
  const handleCardClick = (articleId) => {
    const userId = localStorage.getItem("userId");
    navigate("/ViewArticle", { state: { userId, articleId } });
  };
  //PS_21 to PS_24 Function to handle edit
  const handleEdit = (e, articleId) => {
    e.stopPropagation();
    const userId = localStorage.getItem("userId");
    navigate("/ArticleForm", { state: { userId, articleId, activeTab } });
  };
  
  

  //PS_25 to PS_28 Function to handle delete
  const handleDelete = async (e, article) => {
    e.stopPropagation();
    setSelectedArticle(article);
    setShowDeleteModal(true);
  };

  //PS_29 to PS_38 Function handle delete

  const confirmDelete = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const response = await deleteArticle({
        userId,
        articleId: selectedArticle.article_id,
      });
      console.log("Full response:", response);

      // Close the delete modal immediately after API call
      setShowDeleteModal(false);

      if (response.data && response.data.body && response.data.body.success) {
        setPopUpMsg({
          ...popupMsg,
          ["Msg"]: "Article deleted successfully",
          ["show"]: true,
          ["success"]: true,
        });
        fetchArticles();

        
      } else {
        console.error("Error deleting article:", response.data?.body?.message);
        setPopUpMsg({
          ...popupMsg,
          ["Msg"]: "Something went wrong",
          ["show"]: true,
          ["success"]: false,
        });
      }
    } catch (error) {
      console.error("Error deleting article:", error); 
      // Close the modal even if there's an error
      setShowDeleteModal(false);
      setPopUpMsg({
        ...popupMsg,
        ["Msg"]: "Something went wrong",
        ["show"]: true,
        ["success"]: false,
      });
    }
  };

  return (
    <>
      {/* Your existing header code here */}
      {showLoader ? (
        <Loader />
      ) : (
      <div className="container-fluid custom-container" style={{height:"100vh"}}>
        <div className="d-flex justify-content-between align-items-center pt-4 mt-4">
          <div>
            <h2 className="font-20 font-bold black-v2 mb-0">Documentation</h2>
            <p className="secondary-text-color-v4 font-14 font-regular mt-3 mb-0">
              Find troubleshooting and how-to articles for CloudGen
            </p>
          </div>
          {isAdmin && (
     <button
     type="button"
     className="button primary-button text-nowrap"
     onClick={() => navigate("/ArticleForm", { state: { activeTab } })}
   >
     <img src="images/add-icon.svg" alt="add-icon" className="me-2" />
     Add Article
   </button>
   
   
   )}
   
        </div>

        <div className="row">
          <div className="custom-documentation-search-bar">
            <div className="input-group input-search custom-border-box custom-search-box">
              <input
                type="search"
                className="px-3 form-control common-bg border-0 custom-form custom-search $gray-v1"
                placeholder="Search"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyPress={(e) => e.key === "Enter" && handleSearch()}
              />
              <button
                className="btn border-start-0 custom-form custom-search"
                type="button"
                onClick={handleSearch}
                disabled={searchText.length < 3}
              >
                <img src="images/search-icon.svg" alt="search" />
              </button>
            </div>
          </div>
        </div>

        <div className="kb-template documentation-template mb-5">
          <div className="tab-scroll mb-4">
            <ul
              className="nav nav-pills mb-4 d-flex gap-3 shadow-none"
              id="pills-tab"
              role="tablist"
            >
              <li
                className="nav-item custom-bg rounded-start"
                role="presentation"
              >
                <button
                  className={`nav-link theme-tab-2 custom-theme-tab position-relative cus-tab-border-1 cus-tab-border bg-transparent font-12 font-medium secondary-color secondary-text-color ${
                    activeTab === "all" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("all")}
                >
                  All Articles
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link theme-tab-2 custom-theme-tab position-relative cus-tab-border-1 cus-tab-border font-12 font-medium bg-transparent secondary-color secondary-text-color ${
                    activeTab === "recent" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("recent")}
                >
                  Recently Added
                </button>
              </li>
            </ul>
          </div>
          <div className="tab-content" id="pills-tabContent">
              {articles[activeTab].length === 0 ? (
                <div className="no-record-found" style={{
                 top:350
                }}>
                  <img
                    src="images/no-records-found.png"
                    alt="No records found"
                    className="no-record-icon"
                  />
                </div>
              ) : (
            <div className="row mt-4 pt-2 articles-documentation-parent">
              {articles[activeTab].map((article) => (
                <div key={article.article_id} className="col-md-3 mb-4">
                  <div
                    className="articles-documentation-section p-4 pb-3"
                    onClick={() => handleCardClick(article.article_id)}
                    style={{
                      height: "130px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="d-flex justify-content-between align-items-start mb-2">
                      <a
                        href="#"
                        className="font-14 font-medium primary-color text-decoration-none"
                        title={article.article_name}
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "calc(100% - 24px)", // Adjust based on the width of your dots icon
                        }}
                      >
                        {article.article_name}
                      </a>
                      {isAdmin && (
                      <div className="position-relative">
                        <button
                          type="button"
                          className="btn border-0 shadow-none p-0"
                          onClick={(e) => e.stopPropagation()}
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img
                            src="images/articles-options-dots.svg"
                            alt="dots"
                            className="articles-option-dots"
                          />
                        </button>
                        <ul className="dropdown-menu custom-dropdown-select dropdown-menu-end border-0 p-2 py-2">
                          <li className="py-1 px-1">
                            <a
                              href="#"
                              className="color-black font-13 font-medium text-decoration-none d-block"
                              onClick={(e) => handleEdit(e, article.article_id)}
                            >
                              <img
                                src="images/articles-edit-icon-blue.svg"
                                alt="articles-edit-icon"
                                className="articles-option-icons"
                              />
                              Edit
                            </a>
                          </li>
                          <li className="py-1 px-1">
                            <a
                              href="#"
                              className="color-black font-13 font-medium text-decoration-none d-block"
                              onClick={(e) => handleDelete(e, article)}
                            >
                              <img
                                src="images/articles-delete-icon.svg"
                                alt="articles-delete-icon"
                                className="articles-option-icons"
                              />
                              Delete
                            </a>
                          </li>
                        </ul>
                      </div>
                      )}
                    </div>
                    <p
                      className="font-12 font-medium secondary-text-color mb-0"
                      style={{
                        display: "-webkit-box",
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        margin: "0",
                      }}
                    >
                      {article.description}
                    </p>
                  </div>
                </div>
               ))}
               </div>
             )}
           </div>
         </div>

        {/* Delete Modal */}
        {showDeleteModal && (
          <div
            className="modal fade show"
            style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
            id="staticBackdrop-25"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered justify-content-center">
              <div className="modal-content border-0">
                <div className="modal-header d-flex justify-content-end border-0 pb-0 ">
                  <button
                    type="button"
                    className="btn shadow-none"
                    onClick={() => setShowDeleteModal(false)}
                  >
                    <img
                      src="images/close-icon.svg"
                      alt="close-icon"
                      className="icon-hover shadow-none modal-close-filter"
                    />
                  </button>
                </div>
                <div className="modal-body text-center pt-0 p-32">
                  <img
                    src="images/delete-organization-icon.svg"
                    alt="delete the Organization"
                  />
                  <h6 className="font-20 font-bold black-v2 mt-4 pt-2">
                    You're about to delete an article
                  </h6>
                  <p className="font-16 font-regular secondary-text-color-v5 mt-3 line-height-32 mb-0">
                    {selectedArticle?.article_name}
                  </p>
                  <p className="font-16 font-regular secondary-text-color-v5 mt-2 line-height-32">
                    Do you want to proceed?
                  </p>
                  <div className="d-flex justify-content-center gap-3 align-items-center mt-4 pt-3">
                    <button
                      type="button"
                      className="button outline-button text-nowrap px-32"
                      onClick={confirmDelete}
                    >
                      Yes
                    </button>

                    <button
                      type="button"
                      className="button primary-button popup-btn px-32"
                      onClick={() => setShowDeleteModal(false)}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

          {/* WorkbenchPopup */}
          {popupMsg.show && popupMsg.Msg !== "" && (
            <div
              style={{
                position: "fixed",
                top: "15%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 1000,
                maxWidth: "80%",
                width: "300px",
              }}
            >
              <WorkbenchPopup popupMsg={popupMsg} setPopUpMsg={setPopUpMsg} />
            </div>
          )}
        </div>
      )}
    </>
  );
};
export default ArticleGrid;
