export default function AwsSDKSNS({ values, onChangeOfConfiguration, isViewer }) {
    try {
        return (
            <>
                    <div
                        class="accordion-body p-0 estimation-container">
                        <div className="row py-4 mx-4 px-5" hidden={(values.isPriceAvailable == "No") ? false : true}>
                            <div className="col-md-6 px-3"><span className="font-semibold font-14 text-danger">{values.isPriceAvailable == "No" ? "The Price is not available for the region / the configuration that you have selected might be not available in the region" : null}</span></div>
                        </div>
                        <div className="row py-4 mx-4 px-5">
                            <div className="col-md-6 px-3">
                                <label htmlFor="region" className="form-label font-14 font-medium black-v2 mb-3">
                                    Region
                                </label>
                                <select
                                    className="form-select custom-form font-semibold font-14 form-select-sm"
                                    id="region"
                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                    value={values.region}
                                    name={"region"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                >
                                    <option value="ap-south-2">ap-south-2</option><option value="ap-southeast-3">ap-southeast-3</option><option value="ap-southeast-1">ap-southeast-1</option><option value="us-gov-east-1">us-gov-east-1</option><option value="eu-west-2">eu-west-2</option><option value="af-south-1">af-south-1</option><option value="ap-northeast-1">ap-northeast-1</option><option value="ap-southeast-2">ap-southeast-2</option><option value="us-east-2">us-east-2</option><option value="eu-central-1">eu-central-1</option><option value="ca-central-1">ca-central-1</option><option value="ap-south-1">ap-south-1</option><option value="ap-northeast-3">ap-northeast-3</option><option value="sa-east-1">sa-east-1</option><option value="ap-east-1">ap-east-1</option><option value="eu-west-1">eu-west-1</option><option value="eu-south-1">eu-south-1</option><option value="ap-southeast-4">ap-southeast-4</option><option value="eu-north-1">eu-north-1</option><option value="eu-south-2">eu-south-2</option><option value="us-west-1">us-west-1</option><option value="us-west-2">us-west-2</option><option value="me-south-1">me-south-1</option><option value="eu-central-2">eu-central-2</option><option value="us-gov-west-1">us-gov-west-1</option><option value="ap-northeast-2">ap-northeast-2</option><option value="me-central-1">me-central-1</option><option value="us-east-1">us-east-1</option><option value="eu-west-3">eu-west-3</option>
                                </select>
                            </div>

                            <div className="col-md-6 px-3">
                                <label htmlFor="SNStype" className="form-label font-14 font-medium black-v2 mb-3">
                                    Select SNS Type
                                </label>
                                <select
                                    className="form-select custom-form font-semibold font-14 form-select-sm mb-3"
                                    id="SNStype"
                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                    value={values.SNStype}
                                    name={"SNStype"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                >
                                    <option value="SNS-Standard">SNS- Standard</option>
                                    <option value="SNS-FIFO">SNS-FIFO</option>
                                </select>
                            </div>


                            {values.SNStype === "SNS-Standard" &&
                                <><div className="col-md-6 px-3">
                                    <label htmlFor="standardReq" className="form-label font-14 font-medium black-v2 mb-3">
                                        Requests
                                    </label>
                                    <input
                                        type="text"
                                        style={{paddingTop :"10px"}}
                                        placeholder="Enter the amount"
                                        className="form-control custom-form text-field font-14 font-medium mb-3"
                                        id="standardReq"
                                        value={values.standardReq}
                                        name={"standardReq"}
                                        onChange={(e) => {
                                            onChangeOfConfiguration(e, values.instanceName);
                                        }} disabled={isViewer}
                                    />
                                </div>
                                    <div className="col-md-6 px-3">
                                        <label htmlFor="standardReqType" className="form-label font-14 font-medium black-v2 mb-3">
                                            Unit
                                        </label>
                                        <select
                                            className="form-select custom-form font-semibold font-14 form-select-sm"
                                            id="standardReqType"
                                            style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                            value={values.standardReqType}
                                            name={"standardReqType"}
                                            onChange={(e) => {
                                                onChangeOfConfiguration(e, values.instanceName);
                                            }} disabled={isViewer}>
                                            <option value="per month">per month</option>
                                            <option value="million per month">million per month</option>
                                        </select>
                                    </div>

                                    <div className="col-md-6 px-3">
                                        <label htmlFor="httpNotification" className="form-label font-14 font-medium black-v2 mb-3">
                                            HTTP/HTTPS Notifications
                                        </label>
                                        <input
                                            type="text"
                                            style={{paddingTop :"10px"}}
                                            placeholder="Enter the amount"
                                            className="form-control custom-form text-field font-14 font-medium mb-3"
                                            id="httpNotification"
                                            value={values.httpNotification}
                                            name={"httpNotification"}
                                            onChange={(e) => {
                                                onChangeOfConfiguration(e, values.instanceName);
                                            }} disabled={isViewer}
                                        />
                                    </div>
                                    <div className="col-md-6 px-3">
                                        <label htmlFor="httpNotificationType" className="form-label font-14 font-medium black-v2 mb-3">
                                            Unit
                                        </label>
                                        <select
                                            className="form-select custom-form font-semibold font-14 form-select-sm"
                                            id="httpNotificationType"
                                            style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                            value={values.httpNotificationType}
                                            name={"httpNotificationType"}
                                            onChange={(e) => {
                                                onChangeOfConfiguration(e, values.instanceName);
                                            }} disabled={isViewer}>
                                            <option value="per month">per month</option>
                                            <option value="million per month">million per month</option>
                                        </select>
                                    </div>

                                    <div className="col-md-6 px-3">
                                        <label htmlFor="emailNotification" className="form-label font-14 font-medium black-v2 mb-3">
                                            EMAIL/EMAIL-JSON Notifications
                                        </label>
                                        <input
                                            type="text"
                                            style={{paddingTop :"10px"}}
                                            placeholder="Enter the amount"
                                            className="form-control custom-form text-field font-14 font-medium mb-3"
                                            id="emailNotification"
                                            value={values.emailNotification}
                                            name={"emailNotification"}
                                            onChange={(e) => {
                                                onChangeOfConfiguration(e, values.instanceName);
                                            }} disabled={isViewer}
                                        />
                                    </div>
                                    <div className="col-md-6 px-3">
                                        <label htmlFor="emailNotificationType" className="form-label font-14 font-medium black-v2 mb-3">
                                            Unit
                                        </label>
                                        <select
                                            className="form-select custom-form font-semibold font-14 form-select-sm"
                                            id="emailNotificationType"
                                            style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                            value={values.emailNotificationType}
                                            name={"emailNotificationType"}
                                            onChange={(e) => {
                                                onChangeOfConfiguration(e, values.instanceName);
                                            }} disabled={isViewer}>
                                            <option value="per month">per month</option>
                                            <option value="million per month">million per month</option>
                                        </select>
                                    </div>

                                    <div className="col-md-6 px-3">
                                        <label htmlFor="sqsNotification" className="form-label font-14 font-medium black-v2 mb-3">
                                            SQS Notifications
                                        </label>
                                        <input
                                            type="text"
                                            style={{paddingTop :"10px"}}
                                            placeholder="Enter the amount"
                                            className="form-control custom-form text-field font-14 font-medium mb-3"
                                            id="sqsNotification"
                                            value={values.sqsNotification}
                                            name={"sqsNotification"}
                                            onChange={(e) => {
                                                onChangeOfConfiguration(e, values.instanceName);
                                            }} disabled={isViewer}
                                        />
                                    </div>
                                    <div className="col-md-6 px-3">
                                        <label htmlFor="sqsNotificationType" className="form-label font-14 font-medium black-v2 mb-3">
                                            Unit
                                        </label>
                                        <select
                                            className="form-select custom-form font-semibold font-14 form-select-sm"
                                            id="sqsNotificationType"
                                            style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                            value={values.sqsNotificationType}
                                            name={"sqsNotificationType"}
                                            onChange={(e) => {
                                                onChangeOfConfiguration(e, values.instanceName);
                                            }} disabled={isViewer}>
                                            <option value="per month">per month</option>
                                            <option value="million per month">million per month</option>
                                        </select>
                                    </div>

                                    {values.region == "ap-east-1" ? (null
                                    ) :
                                        <>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="kinesisData" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Amazon Kinesis Data Firehose
                                                </label>
                                                <input
                                                    type="text"
                                                    style={{paddingTop :"10px"}}
                                                    placeholder="Enter the amount"
                                                    className="form-control custom-form text-field font-14 font-medium"
                                                    id="kinesisData"
                                                    value={values.kinesisData}
                                                    name={"kinesisData"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }}
                                                    disabled={isViewer}
                                                />
                                            </div>

                                            <div className="col-md-6 px-3">
                                                <label htmlFor="kinesisDataType" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Unit
                                                </label>
                                                <select
                                                    className="form-select custom-form font-semibold font-14 form-select-sm"
                                                    id="kinesisDataType"
                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                    value={values.kinesisDataType}
                                                    name={"kinesisDataType"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }}
                                                    disabled={isViewer}>
                                                    <option value="per month">per month</option>
                                                    <option value="million per month">million per month</option>
                                                </select>
                                            </div>
                                        </>}</>}


                            {values.SNStype === "SNS-FIFO" && <>
                                <div className="col-md-6 px-3">
                                    <label htmlFor="Requests" className="form-label font-14 font-medium black-v2 mb-3">
                                        Requests
                                    </label>
                                    <input
                                        type="text"
                                        style={{paddingTop :"10px"}}
                                        placeholder="Enter the number of requests"
                                        className="form-control custom-form text-field font-14 font-medium mb-3"
                                        id="Requests"
                                        value={values.Requests}
                                        name={"Requests"}
                                        onChange={(e) => {
                                            onChangeOfConfiguration(e, values.instanceName);
                                        }} disabled={isViewer}
                                    />
                                </div>
                                <div className="col-md-6 px-3">
                                    <label htmlFor="RequestsUnit" className="form-label font-14 font-medium black-v2 mb-3">
                                        Unit
                                    </label>
                                    <select
                                        className="form-select custom-form font-semibold font-14 form-select-sm"
                                        id="RequestsUnit"
                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                        value={values.RequestsUnit}
                                        name={"RequestsUnit"}
                                        onChange={(e) => {
                                            onChangeOfConfiguration(e, values.instanceName);
                                        }} disabled={isViewer}>
                                        <option value="per month">per month</option>
                                        <option value="million per month">million per month</option>
                                    </select>
                                </div>


                                <div className="col-md-6 px-3">
                                    <label htmlFor="AverageMessageSizeUnit" className="form-label font-14 font-medium black-v2 mb-3">
                                        Average message size
                                    </label>
                                    <input
                                        type="text"
                                        style={{paddingTop :"10px"}}
                                        placeholder="Enter average message size"
                                        className="form-control custom-form text-field font-14 font-medium mb-3"
                                        id="AverageMessageSizeUnit"
                                        value={values.AverageMessageSizeUnit}
                                        name={"AverageMessageSizeUnit"}
                                        onChange={(e) => {
                                            onChangeOfConfiguration(e, values.instanceName);
                                        }} disabled={isViewer}
                                    />
                                </div>
                                <div className="col-md-6 px-3">
                                    <label htmlFor="AverageMessageSize" className="form-label font-14 font-medium black-v2 mb-3">
                                        Unit
                                    </label>
                                    <select
                                        className="form-select custom-form font-semibold font-14 form-select-sm"
                                        id="AverageMessageSize"
                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                        value={values.AverageMessageSize}
                                        name={"AverageMessageSize"}
                                        onChange={(e) => {
                                            onChangeOfConfiguration(e, values.instanceName);
                                        }} disabled={isViewer}>
                                        <option value="Byte">Byte</option>
                                        <option value="KB">KB</option>
                                    </select>
                                </div>



                                <div>
                                    <label htmlFor="subscriptions" className="form-label font-14 font-medium black-v2 mb-3">
                                        Number of subscriptions
                                    </label>
                                    <input
                                        type="text"
                                        style={{paddingTop :"10px"}}
                                        placeholder="Enter number of subscriptions per month"
                                        className="form-control custom-form text-field font-14 font-medium"
                                        id="subscriptions"
                                        value={values.subscriptions}
                                        name={"subscriptions"}
                                        onChange={(e) => {
                                            onChangeOfConfiguration(e, values.instanceName);
                                        }} disabled={isViewer}
                                    />
                                </div>


                                <div className="col-md-6 px-3" style={{ marginTop: "20px" }}>
                                    <label htmlFor="RetentionPeriod" className="form-label font-14 font-medium black-v2 mb-3">
                                        Retention Period
                                    </label>
                                    <input
                                        type="text"
                                        style={{paddingTop :"10px"}}
                                        placeholder="Enter retention Period"
                                        className="form-control custom-form text-field font-14 font-medium"
                                        id="RetentionPeriod"
                                        value={values.RetentionPeriod}
                                        name={"RetentionPeriod"}
                                        onChange={(e) => {
                                            onChangeOfConfiguration(e, values.instanceName);
                                        }} disabled={isViewer}
                                    />
                                </div>
                                <div className="col-md-6 px-3" style={{ marginTop: "20px" }}>
                                    <label htmlFor="RetentionPeriodUnit" className="form-label font-14 font-medium black-v2 mb-3">
                                        Retention Period Unit
                                    </label>
                                    <select
                                        className="form-select custom-form font-semibold font-14 form-select-sm"
                                        id="RetentionPeriodUnit"
                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                        value={values.RetentionPeriodUnit}
                                        name={"RetentionPeriodUnit"}
                                        onChange={(e) => {
                                            onChangeOfConfiguration(e, values.instanceName);
                                        }} disabled={isViewer}>
                                        <option value="days">days</option>
                                        <option value="month">month</option>
                                    </select>
                                </div></>}
                        </div>
                    </div>
            </>
        );
    } catch (error) {
        //console.log(error);
    }
}