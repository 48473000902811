// values["region"] = "us-east"
// values["storageAccountName"] = "block-blob"
// values["performanceTier"] = "standard"            
// values["count"] = "1"
// values["storageUnits"] = "1"
// values["blobWriteOperations"] = "1"
// values["blobCreateContainerOperations"] = "1"
// values["blobReadOperations"] = "1"
// values["blobOtherOperations"] = "1"
// values["storageAccountType"] = "general-purpose"
// values["storageAccountType"] = "general-purpose-v2"
// values["fileStructure"] = "flat"
// values["accessTier"] = "hot"
// values["redundancy"] = "lrs"
// values["blobWriteOperationsV1"] = "1"
// values["blobCreateContainerOperationsV1"] = "1"
// values["blobReadOperationsV1"] = "1"
// values["blobOtherOperationsV1"] = "1"      
// values["blobDataRetrievalUnits"] = "1"
// values["blobDataRetrieval"] = "1"
// values["blobDataWriteUnits"] = "1"
// values["blobDataWrite"] = "1"
// values["blobArchiveRead"] = "1"
// values["blobArchiveRetrievalUnits"] = "1"
// values["blobArchiveRetrieval"] = "1"


// ["us-central", "us-east", "us-east-2", "us-north-central", "us-south-central", "us-west-central", "us-west", "us-west-2", "us-west-3", "united-kingdom-south", "united-kingdom-west", "uae-central", "uae-north", "switzerland-north", "switzerland-west", "sweden-central", "sweden-south", "spain-central", "qatar-central", "poland-central", "norway-east", "norway-west", "mexico-central", "korea-central", "korea-south", "japan-east", "japan-west", "italy-north", "israel-central", "central-india", "south-india", "west-india", "germany-north", "germany-west-central", "france-central", "france-south", "europe-north", "europe-west", "canada-central", "canada-east", "brazil-south", "brazil-southeast", "australia-central", "australia-central-2", "australia-east", "australia-southeast", "asia-pacific-east", "asia-pacific-southeast", "south-africa-north", "south-africa-west"]



export default function Azure_StorageAccount({ values, onChangeOfConfiguration, isViewer }) {
    try {
        return (
            <div id={`${values.instanceName.split(" ").join("_")}`} className="accordion-collapse collapse">
                <div className="accordioan-body p-0 ">
                    <table className="table table-borderless cg-grid font-14 font-semibold mb-0">
                        <tbody>
                            <tr className="ms-acc-data-align">
                                <td colSpan={3}>
                                    <div className="row py-4 mx-4 px-5" hidden={(values.isPriceAvailable == "No") ? false : true}>
                                        <div className="col-md-6 px-3"><span className="font-semibold font-14 text-danger">{values.isPriceAvailable == "No" ? "The Price is not available for the region / the configuration that you have selected might be not available in the region" : null}</span></div>
                                    </div>
                                    <div className="row py-4 mx-4 px-5">
                                        <div className="col-md-6 px-3">
                                            <label htmlFor="region" className="form-label font-14 font-medium black-v2 mb-3">
                                                Region
                                            </label>
                                            <select
                                                className=" form-select custom-form  font-semibold font-14 form-select-sm mb3"
                                                id="region"
                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px", marginBottom : "10px"}}
                                                value={values.region}
                                                name={"region"}
                                                onChange={(e) => {
                                                    onChangeOfConfiguration(e, values.instanceName);
                                                }} disabled={isViewer}
                                            >

                                                <optgroup label="United States">
                                                    <option value="us-central">Central US</option>
                                                    <option value="us-east">East US</option>
                                                    <option value="us-east-2">East US 2</option>
                                                    <option value="us-north-central">North Central US</option>
                                                    <option value="us-south-central">South Central US</option>
                                                    <option value="us-west-central">West Central US</option>
                                                    <option value="us-west">West US</option>
                                                    <option value="us-west-2">West US 2</option>
                                                    <option value="us-west-3">West US 3</option>
                                                </optgroup>
                                                <optgroup label="United Kingdom">
                                                    <option value="united-kingdom-south">UK South</option>
                                                    <option value="united-kingdom-west">UK West</option>
                                                </optgroup>
                                                <optgroup label="United Arab Emirates">
                                                    <option value="uae-central">UAE Central</option>
                                                    <option value="uae-north">UAE North</option>
                                                </optgroup>
                                                <optgroup label="Switzerland">
                                                    <option value="switzerland-north">Switzerland North</option>
                                                    <option value="switzerland-west">Switzerland West</option>
                                                </optgroup>
                                                <optgroup label="Sweden">
                                                    <option value="sweden-central">Sweden Central</option>
                                                    <option value="sweden-south">Sweden South</option>
                                                </optgroup>
                                                <optgroup label="Spain">
                                                    <option value="spain-central">Spain Central</option>
                                                </optgroup>
                                                <optgroup label="Qatar">
                                                    <option value="qatar-central">Qatar Central</option>
                                                </optgroup>
                                                <optgroup label="Poland">
                                                    <option value="poland-central">Poland Central</option>
                                                </optgroup>
                                                <optgroup label="Norway">
                                                    <option value="norway-east">Norway East</option>
                                                    <option value="norway-west">Norway West</option>
                                                </optgroup>
                                                <optgroup label="Mexico">
                                                    <option value="mexico-central">Mexico Central</option>
                                                </optgroup>
                                                <optgroup label="Korea">
                                                    <option value="korea-central">Korea Central</option>
                                                    <option value="korea-south">Korea South</option>
                                                </optgroup>
                                                <optgroup label="Japan">
                                                    <option value="japan-east">Japan East</option>
                                                    <option value="japan-west">Japan West</option>
                                                </optgroup>
                                                <optgroup label="Italy">
                                                    <option value="italy-north">Italy North</option>
                                                </optgroup>
                                                <optgroup label="Israel">
                                                    <option value="israel-central">Israel Central</option>
                                                </optgroup>
                                                <optgroup label="India">
                                                    <option value="central-india">Central India</option>
                                                    <option value="south-india">South India</option>
                                                    <option value="west-india">West India</option>
                                                </optgroup>
                                                <optgroup label="Germany">
                                                    <option value="germany-north">Germany North</option>
                                                    <option value="germany-west-central">Germany West Central</option>
                                                </optgroup>
                                                <optgroup label="France">
                                                    <option value="france-central">France Central</option>
                                                    <option value="france-south">France South</option>
                                                </optgroup>
                                                <optgroup label="Europe">
                                                    <option value="europe-north">North Europe</option>
                                                    <option value="europe-west">West Europe</option>
                                                </optgroup>
                                                <optgroup label="Canada">
                                                    <option value="canada-central">Canada Central</option>
                                                    <option value="canada-east">Canada East</option>
                                                </optgroup>
                                                <optgroup label="Brazil">
                                                    <option value="brazil-south">Brazil South</option>
                                                    <option value="brazil-southeast">Brazil Southeast</option>
                                                </optgroup>
                                                <optgroup label="Australia">
                                                    <option value="australia-central">Australia Central</option>
                                                    <option value="australia-central-2">Australia Central 2</option>
                                                    <option value="australia-east">Australia East</option>
                                                    <option value="australia-southeast">Australia Southeast</option>
                                                </optgroup>
                                                <optgroup label="Asia Pacific">
                                                    <option value="asia-pacific-east">East Asia</option>
                                                    <option value="asia-pacific-southeast">Southeast Asia</option>
                                                </optgroup>
                                                <optgroup label="Africa">
                                                    <option value="south-africa-north">South Africa North</option>
                                                    <option value="south-africa-west">South Africa West</option>
                                                </optgroup>
                                            </select>
                                        </div>

                                        <div className="col-md-6 px-3">
                                            <label htmlFor="storageAccountName" className="form-label font-14 font-medium black-v2 mb-3">
                                                Type
                                            </label>
                                            <select
                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                id="storageAccountName"
                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                value={values.storageAccountName}
                                                name={"storageAccountName"}
                                                onChange={(e) => {
                                                    onChangeOfConfiguration(e, values.instanceName);
                                                }} disabled={isViewer}>
                                                <option value="block-blob">Block Blob Storage</option>
                                            </select>
                                        </div>

                                        {values.storageAccountType === "general-purpose" ? (<>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="storageAccountType" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Storage Account Type
                                                </label>
                                                <select
                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                    id="storageAccountType"
                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                    value={values.storageAccountType}
                                                    name={"storageAccountType"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}>
                                                    <option value="general-purpose-v2">General Purpose V2</option>
                                                    <option value="blob-storage">Blob Storage</option>
                                                    <option value="general-purpose">General Purpose V1</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="count" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Capacity Count
                                                </label>
                                                <input
                                                    type="text"
                                                    style={{paddingTop:"9px"}}
                                                    className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                    id="count"
                                                    value={values.count}
                                                    name={"count"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}
                                                />
                                            </div>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="storageUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Capacity storage Units
                                                </label>
                                                <select
                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                    id="storageUnits"
                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                    value={values.storageUnits}
                                                    name={"storageUnits"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}>
                                                    <option value="1">GB</option>
                                                    <option value="1024">TB</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="blobWriteOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Write Operations
                                                </label>
                                                <input
                                                    type="text"
                                                    style={{paddingTop:"9px"}}
                                                    className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                    id="blobWriteOperations"
                                                    value={values.blobWriteOperations}
                                                    name={"blobWriteOperations"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}
                                                />
                                            </div>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="blobCreateContainerOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                    List and Create Container Operations
                                                </label>
                                                <input
                                                    type="text"
                                                    style={{paddingTop:"9px"}}
                                                    className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                    id="blobCreateContainerOperations"
                                                    value={values.blobCreateContainerOperations}
                                                    name={"blobCreateContainerOperations"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}
                                                />
                                            </div>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="blobReadOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Read operations
                                                </label>
                                                <input
                                                    type="text"
                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                    id="blobReadOperations"
                                                    value={values.blobReadOperations}
                                                    name={"blobReadOperations"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}
                                                />
                                            </div>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="blobOtherOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                    All other operations
                                                </label>
                                                <input
                                                    type="text"
                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                    id="blobOtherOperations"
                                                    value={values.blobOtherOperations}
                                                    name={"blobOtherOperations"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}
                                                />
                                            </div>
                                        </>) : (
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="performanceTier" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Performance Tier
                                                </label>
                                                <select
                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                    id="performanceTier"
                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px", marginBottom : "10px"}}
                                                    value={values.performanceTier}
                                                    name={"performanceTier"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}>
                                                    <option value="standard">Standard</option>
                                                    <option value="premium">Premium</option>
                                                </select>
                                            </div>)}

                                        {(values.performanceTier === "premium" && values.storageAccountType !== "general-purpose") && <>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="performanceTier" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Performance Tier
                                                </label>
                                                <select
                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                    id="performanceTier"
                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                    value={values.performanceTier}
                                                    name={"performanceTier"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}>
                                                    <option value="flat">Flat Namespace</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="redundancy" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Redundancy
                                                </label>
                                                <select
                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                    id="redundancy"
                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px", marginBottom : "10px"}}
                                                    value={values.redundancy}
                                                    name={"redundancy"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}>
                                                    <option value="lrs">LRS</option>
                                                    <option value="zrs">ZRS</option>
                                                </select>
                                            </div>

                                            <div className="col-md-6 px-3">
                                                <label htmlFor="count" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Capacity Count
                                                </label>
                                                <input
                                                style={{paddingTop:"9px"}}
                                                    type="text"
                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                    id="count"
                                                    value={values.count}
                                                    name={"count"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}
                                                />
                                            </div>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="storageUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Capacity storage Units
                                                </label>
                                                <select
                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                    id="storageUnits"
                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px", marginBottom : "10px"}}
                                                    value={values.storageUnits}
                                                    name={"storageUnits"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}>
                                                    <option value="1">GB</option>
                                                    <option value="1024">TB</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="blobWriteOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Write Operations
                                                </label>
                                                <input
                                                    type="text"                                                    
                                                    style={{paddingTop:"9px"}}
                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                    id="blobWriteOperations"
                                                    value={values.blobWriteOperations}
                                                    name={"blobWriteOperations"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}
                                                />
                                            </div>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="blobCreateContainerOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                    List and Create Container Operations
                                                </label>
                                                <input
                                                    type="text"
                                                    style={{paddingTop:"9px",marginBottom :"10px"}}
                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                    id="blobCreateContainerOperations"
                                                    value={values.blobCreateContainerOperations}
                                                    name={"blobCreateContainerOperations"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}
                                                />
                                            </div>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="blobReadOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Read operations
                                                </label>
                                                <input
                                                    type="text"
                                                    style={{paddingTop:"9px"}}
                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                    id="blobReadOperations"
                                                    value={values.blobReadOperations}
                                                    name={"blobReadOperations"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}
                                                />
                                            </div>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="blobOtherOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                    All other operations
                                                </label>
                                                <input
                                                    type="text"
                                                    style={{paddingTop:"9px"}}
                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                    id="blobOtherOperations"
                                                    value={values.blobOtherOperations}
                                                    name={"blobOtherOperations"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}
                                                />
                                            </div>
                                        </>}

                                        {(values.performanceTier === "standard" && values.storageAccountType !== "general-purpose") && <>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="storageAccountType" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Storage Account Type
                                                </label>
                                                <select
                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                    id="storageAccountType"
                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                    value={values.storageAccountType}
                                                    name={"storageAccountType"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}>
                                                    <option value="general-purpose-v2">General Purpose V2</option>
                                                    <option value="blob-storage">Blob Storage</option>
                                                    <option value="general-purpose">General Purpose V1</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="fileStructure" className="form-label font-14 font-medium black-v2 mb-3">
                                                    File Structure
                                                </label>
                                                <select
                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                    id="fileStructure"
                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px",  marginBottom : "10px"}}
                                                    value={values.fileStructure}
                                                    name={"fileStructure"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}>
                                                    <option value="flat">Flat Namespace</option>
                                                </select>
                                            </div>
                                            {values.storageAccountType === "general-purpose-v2" && <>
                                                <div className="col-md-6 px-3">
                                                    <label htmlFor="accessTier" className="form-label font-14 font-medium black-v2 mb-3">
                                                        Access Tier
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="accessTier"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.accessTier}
                                                        name={"accessTier"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="hot">Hot</option>
                                                        <option value="cool">Cool</option>
                                                        <option value="cold">Cold</option>
                                                        <option value="archive">Archive</option>
                                                    </select>
                                                </div>

                                                {values.accessTier === "hot" && <>
                                                    <div className="col-md-6 px-3">
                                                        <label htmlFor="redundancy" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Redundancy
                                                        </label>
                                                        <select
                                                            className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                            id="redundancy"
                                                            style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                            value={values.redundancy}
                                                            name={"redundancy"}
                                                            onChange={(e) => {
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}>
                                                            <option value="lrs">LRS</option>
                                                            <option value="zrs">ZRS</option>
                                                            <option value="grs">GRS</option>
                                                        </select>
                                                    </div>
                                                </>}
                                                {(values.accessTier === "hot" && values.redundancy === "lrs") && <>
                                                    <div className="col-md-6 px-3">
                                                        <label htmlFor="count" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Capacity Count
                                                        </label>
                                                        <input
                                                            type="text"
                                                            style={{paddingTop:"9px"}}
                                                            className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                            id="count"
                                                            value={values.count}
                                                            name={"count"}
                                                            onChange={(e) => {
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 px-3">
                                                        <label htmlFor="storageUnits" 
                                                        
                                                        className="form-label font-14 font-medium black-v2 mb-3">
                                                            Capacity storage Units
                                                        </label>
                                                        <select
                                                            className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                            id="storageUnits"
                                                            style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px"}}
                                                            value={values.storageUnits}
                                                            name={"storageUnits"}
                                                            onChange={(e) => {
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}>
                                                            <option value="1">GB</option>
                                                            <option value="1024">TB</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-6 px-3">
                                                        <label htmlFor="blobWriteOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Write Operations
                                                        </label>
                                                        <input
                                                            type="text"
                                                            style={{paddingTop:"9px"}}
                                                            className=" form-control  custom-form text-field font-14 font-medium"
                                                            id="blobWriteOperations"
                                                            value={values.blobWriteOperations}
                                                            name={"blobWriteOperations"}
                                                            onChange={(e) => {
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 px-3">
                                                        <label htmlFor="blobCreateContainerOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                            List and Create Container Operations
                                                        </label>
                                                        <input
                                                            type="text"
                                                            style={{paddingTop:"9px"}}
                                                            className=" form-control  custom-form text-field font-14 font-medium"
                                                            id="blobCreateContainerOperations"
                                                            value={values.blobCreateContainerOperations}
                                                            name={"blobCreateContainerOperations"}
                                                            onChange={(e) => {
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 px-3">
                                                        <label htmlFor="blobReadOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Read operations
                                                        </label>
                                                        <input
                                                            type="text"
                                                            style={{marginBottom : "10px", paddingTop :"9px"}}
                                                            className=" form-control  custom-form text-field font-14 font-medium"
                                                            id="blobReadOperations"
                                                            value={values.blobReadOperations}
                                                            name={"blobReadOperations"}
                                                            onChange={(e) => {
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 px-3">
                                                        <label htmlFor="blobOtherOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                            All other operations
                                                        </label>
                                                        <input
                                                            type="text"
                                                            style={{marginBottom : "10px", paddingTop :"9px"}}
                                                            className=" form-control  custom-form text-field font-14 font-medium"
                                                            id="blobOtherOperations"
                                                            value={values.blobOtherOperations}
                                                            name={"blobOtherOperations"}
                                                            onChange={(e) => {
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}
                                                        />
                                                    </div>
                                                    {!["us-west-3", "sweden-central", "sweden-south", "spain-central", "qatar-central", "poland-central", "norway-east", "norway-west", "mexico-central", "italy-north", "israel-central", "brazil-southeast"].includes(values.region) && <>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobDataRetrieval" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Blob Data Retrieval
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{ paddingTop :"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                id="blobDataRetrieval"
                                                                value={values.blobDataRetrieval}
                                                                name={"blobDataRetrieval"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobDataRetrievalUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Blob Data Retrieval Units
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                id="blobDataRetrievalUnits"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.blobDataRetrievalUnits}
                                                                name={"blobDataRetrievalUnits"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}>
                                                                <option value="1">GB</option>
                                                                <option value="1024">TB</option>
                                                            </select>
                                                        </div>
                                                    </>}
                                                </>}
                                                {(values.accessTier === "hot" && values.redundancy === "zrs") && <>
                                                    {!["us-north-central", "us-west-central", "us-west", "united-kingdom-west", "uae-central", "uae-north", "switzerland-north", "switzerland-west", "norway-west", "korea-south", "japan-west", "israel-central", "south-india", "west-india", "germany-north", "france-south", "canada-east", "australia-central", "australia-central-2", "australia-east", "australia-southeast", "south-africa-west"].includes(values.region) && <>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="count" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Capacity Count
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{ paddingTop :"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="count"
                                                                value={values.count}
                                                                name={"count"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="storageUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Capacity storage Units
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                id="storageUnits"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.storageUnits}
                                                                name={"storageUnits"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}>
                                                                <option value="1">GB</option>
                                                                <option value="1024">TB</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobWriteOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Write Operations
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{ paddingTop :"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobWriteOperations"
                                                                value={values.blobWriteOperations}
                                                                name={"blobWriteOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobCreateContainerOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                List and Create Container Operations
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{ paddingTop :"9px"}}                                    
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobCreateContainerOperations"
                                                                value={values.blobCreateContainerOperations}
                                                                name={"blobCreateContainerOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobReadOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Read operations
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{ paddingTop :"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mn-3"
                                                                id="blobReadOperations"
                                                                value={values.blobReadOperations}
                                                                name={"blobReadOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobOtherOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                All other operations
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{ paddingTop :"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobOtherOperations"
                                                                value={values.blobOtherOperations}
                                                                name={"blobOtherOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        {!["us-east", "us-south-central", "us-west-3", "united-kingdom-south", "sweden-central", "sweden-south", "spain-central", "qatar-central", "poland-central", "norway-east", "mexico-central", "korea-central", "japan-east", "italy-north", "central-india", "germany-west-central", "france-central", "canada-central", "brazil-south", "brazil-southeast", "asia-pacific-east", "asia-pacific-southeast", "south-africa-north"].includes(values.region) && <>
                                                            <div className="col-md-6 px-3">
                                                                <label htmlFor="blobDataRetrieval" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Blob Data Retrieval
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    style={{ paddingTop :"9px"}}                                        
                                                                    className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                    id="blobDataRetrieval"
                                                                    value={values.blobDataRetrieval}
                                                                    name={"blobDataRetrieval"}
                                                                    onChange={(e) => {
                                                                        onChangeOfConfiguration(e, values.instanceName);
                                                                    }} disabled={isViewer}
                                                                />
                                                            </div>
                                                            <div className="col-md-6 px-3">
                                                                <label htmlFor="blobDataRetrievalUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Blob Data Retrieval Units
                                                                </label>
                                                                <select
                                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                    id="blobDataRetrievalUnits"
                                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                    value={values.blobDataRetrievalUnits}
                                                                    name={"blobDataRetrievalUnits"}
                                                                    onChange={(e) => {
                                                                        onChangeOfConfiguration(e, values.instanceName);
                                                                    }} disabled={isViewer}>
                                                                    <option value="1">GB</option>
                                                                    <option value="1024">TB</option>
                                                                </select>
                                                            </div>
                                                        </>}
                                                    </>}
                                                </>}
                                                {(values.accessTier === "hot" && values.redundancy === "grs") && <>
                                                    {!["spain-central", "qatar-central", "poland-central", "mexico-central", "italy-north", "israel-central"].includes(values.region) && <>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="count" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Capacity Count
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                id="count"
                                                                value={values.count}
                                                                name={"count"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="storageUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Capacity storage Units
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                id="storageUnits"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.storageUnits}
                                                                name={"storageUnits"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}>
                                                                <option value="1">GB</option>
                                                                <option value="1024">TB</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobWriteOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Write Operations
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                id="blobWriteOperations"
                                                                value={values.blobWriteOperations}
                                                                name={"blobWriteOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobCreateContainerOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                List and Create Container Operations
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                id="blobCreateContainerOperations"
                                                                value={values.blobCreateContainerOperations}
                                                                name={"blobCreateContainerOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobReadOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Read operations
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                id="blobReadOperations"
                                                                value={values.blobReadOperations}
                                                                name={"blobReadOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobOtherOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                All other operations
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                id="blobOtherOperations"
                                                                value={values.blobOtherOperations}
                                                                name={"blobOtherOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobDataRetrieval" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Blob Data Retrieval
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                id="blobDataRetrieval"
                                                                value={values.blobDataRetrieval}
                                                                name={"blobDataRetrieval"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobDataRetrievalUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Blob Data Retrieval Units
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                id="blobDataRetrievalUnits"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.blobDataRetrievalUnits}
                                                                name={"blobDataRetrievalUnits"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}>
                                                                <option value="1">GB</option>
                                                                <option value="1024">TB</option>
                                                            </select>
                                                        </div>
                                                        {!["us-west-3", "uae-central", "uae-north", "sweden-central", "sweden-south", "norway-west", "norway-east", "brazil-southeast", "south-africa-north", "south-africa-west"].includes(values.region) && <>
                                                            <div className="col-md-6 px-3">
                                                                <label htmlFor="blobDataWrite" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Blob Data Write
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                                    id="blobDataWrite"
                                                                    value={values.blobDataWrite}
                                                                    name={"blobDataWrite"}
                                                                    onChange={(e) => {
                                                                        onChangeOfConfiguration(e, values.instanceName);
                                                                    }} disabled={isViewer}
                                                                />
                                                            </div>
                                                            <div className="col-md-6 px-3">
                                                                <label htmlFor="blobDataWriteUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Blob Data Write Units
                                                                </label>
                                                                <select
                                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                    id="blobDataWriteUnits"
                                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                    value={values.blobDataWriteUnits}
                                                                    name={"blobDataWriteUnits"}
                                                                    onChange={(e) => {
                                                                        onChangeOfConfiguration(e, values.instanceName);
                                                                    }} disabled={isViewer}>
                                                                    <option value="1">GB</option>
                                                                    <option value="1024">TB</option>
                                                                </select>
                                                            </div>
                                                        </>}
                                                    </>}
                                                </>}

                                                {values.accessTier === "cool" && <>
                                                    <div className="col-md-6 px-3">
                                                        <label htmlFor="redundancy" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Redundancy
                                                        </label>
                                                        <select
                                                            className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                            id="redundancy"
                                                            style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                            value={values.redundancy}
                                                            name={"redundancy"}
                                                            onChange={(e) => {
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}>
                                                            <option value="lrs">LRS</option>
                                                            <option value="zrs">ZRS</option>
                                                            <option value="grs">GRS</option>
                                                        </select>
                                                    </div>
                                                </>}
                                                {(values.accessTier === "cool" && values.redundancy === "lrs") && <>
                                                    <div className="col-md-6 px-3">
                                                        <label htmlFor="count" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Capacity Count
                                                        </label>
                                                        <input
                                                            type="text"
                                                            style={{paddingTop:"9px"}}
                                                            className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                            id="count"
                                                            value={values.count}
                                                            name={"count"}
                                                            onChange={(e) => {
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 px-3">
                                                        <label htmlFor="storageUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Capacity storage Units
                                                        </label>
                                                        <select
                                                            className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                            id="storageUnits"
                                                            style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px", marginBottom : "10px"}}
                                                            value={values.storageUnits}
                                                            name={"storageUnits"}
                                                            onChange={(e) => {
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}>
                                                            <option value="1">GB</option>
                                                            <option value="1024">TB</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-6 px-3">
                                                        <label htmlFor="blobWriteOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Write Operations
                                                        </label>
                                                        <input
                                                            type="text"
                                                            style={{paddingTop:"9px"}}
                                                            className=" form-control  custom-form text-field font-14 font-medium"
                                                            id="blobWriteOperations"
                                                            value={values.blobWriteOperations}
                                                            name={"blobWriteOperations"}
                                                            onChange={(e) => {
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 px-3">
                                                        <label htmlFor="blobCreateContainerOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                            List and Create Container Operations
                                                        </label>
                                                        <input
                                                            type="text"
                                                            style={{paddingTop:"9px"}}                                  
                                                            className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                            id="blobCreateContainerOperations"
                                                            value={values.blobCreateContainerOperations}
                                                            name={"blobCreateContainerOperations"}
                                                            onChange={(e) => {
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 px-3">
                                                        <label htmlFor="blobReadOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Read operations
                                                        </label>
                                                        <input
                                                            type="text"
                                                            style={{paddingTop:"9px"}}
                                                            className=" form-control  custom-form text-field font-14 font-medium"
                                                            id="blobReadOperations"
                                                            value={values.blobReadOperations}
                                                            name={"blobReadOperations"}
                                                            onChange={(e) => {
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 px-3">
                                                        <label htmlFor="blobOtherOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                            All other operations
                                                        </label>
                                                        <input
                                                            type="text"
                                                            style={{paddingTop:"9px"}}
                                                            className=" form-control  custom-form text-field font-14 font-medium"
                                                            id="blobOtherOperations"
                                                            value={values.blobOtherOperations}
                                                            name={"blobOtherOperations"}
                                                            onChange={(e) => {
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 px-3">
                                                        <label htmlFor="blobDataRetrieval" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Blob Data Retrieval
                                                        </label>
                                                        <input
                                                            type="text"
                                                            style={{paddingTop:"9px"}}
                                                            className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                            id="blobDataRetrieval"
                                                            value={values.blobDataRetrieval}
                                                            name={"blobDataRetrieval"}
                                                            onChange={(e) => {
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 px-3">
                                                        <label htmlFor="blobDataRetrievalUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Blob Data Retrieval Units
                                                        </label>
                                                        <select
                                                            className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                            id="blobDataRetrievalUnits"
                                                            style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px", marginBottom : "10px"}}
                                                            value={values.blobDataRetrievalUnits}
                                                            name={"blobDataRetrievalUnits"}
                                                            onChange={(e) => {
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}>
                                                            <option value="1">GB</option>
                                                            <option value="1024">TB</option>
                                                        </select>
                                                    </div>
                                                </>}
                                                {(values.accessTier === "cool" && values.redundancy === "zrs") && <>
                                                    {!["us-north-central", "us-west-central", "us-west", "united-kingdom-west", "uae-central", "uae-north", "switzerland-north", "switzerland-west", "norway-west", "korea-south", "japan-west", "israel-central", "south-india", "west-india", "germany-north", "france-south", "canada-east", "australia-central", "australia-central-2", "australia-east", "australia-southeast", "south-africa-west"].includes(values.region) && <>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="count" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Capacity Count
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="count"
                                                                value={values.count}
                                                                name={"count"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="storageUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Capacity storage Units
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                id="storageUnits"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.storageUnits}
                                                                name={"storageUnits"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}>
                                                                <option value="1">GB</option>
                                                                <option value="1024">TB</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobWriteOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Write Operations
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}                                      
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobWriteOperations"
                                                                value={values.blobWriteOperations}
                                                                name={"blobWriteOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobCreateContainerOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                List and Create Container Operations
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobCreateContainerOperations"
                                                                value={values.blobCreateContainerOperations}
                                                                name={"blobCreateContainerOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobReadOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Read operations
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                id="blobReadOperations"
                                                                value={values.blobReadOperations}
                                                                name={"blobReadOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobOtherOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                All other operations
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobOtherOperations"
                                                                value={values.blobOtherOperations}
                                                                name={"blobOtherOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobDataRetrieval" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Blob Data Retrieval
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                id="blobDataRetrieval"
                                                                value={values.blobDataRetrieval}
                                                                name={"blobDataRetrieval"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobDataRetrievalUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Blob Data Retrieval Units
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                id="blobDataRetrievalUnits"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.blobDataRetrievalUnits}
                                                                name={"blobDataRetrievalUnits"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}>
                                                                <option value="1">GB</option>
                                                                <option value="1024">TB</option>
                                                            </select>
                                                        </div>
                                                    </>}
                                                </>}
                                                {(values.accessTier === "cool" && values.redundancy === "grs") && <>
                                                    {!["spain-central", "qatar-central", "poland-central", "mexico-central", "italy-north", "israel-central"].includes(values.region) && <>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="count" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Capacity Count
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="count"
                                                                value={values.count}
                                                                name={"count"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="storageUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Capacity storage Units
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                id="storageUnits"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.storageUnits}
                                                                name={"storageUnits"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}>
                                                                <option value="1">GB</option>
                                                                <option value="1024">TB</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobWriteOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Write Operations
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobWriteOperations"
                                                                value={values.blobWriteOperations}
                                                                name={"blobWriteOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobCreateContainerOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                List and Create Container Operations
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobCreateContainerOperations"
                                                                value={values.blobCreateContainerOperations}
                                                                name={"blobCreateContainerOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobReadOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Read operations
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                id="blobReadOperations"
                                                                value={values.blobReadOperations}
                                                                name={"blobReadOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobOtherOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                All other operations
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobOtherOperations"
                                                                value={values.blobOtherOperations}
                                                                name={"blobOtherOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobDataRetrieval" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Blob Data Retrieval
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                id="blobDataRetrieval"
                                                                value={values.blobDataRetrieval}
                                                                name={"blobDataRetrieval"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobDataRetrievalUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Blob Data Retrieval Units
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                id="blobDataRetrievalUnits"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.blobDataRetrievalUnits}
                                                                name={"blobDataRetrievalUnits"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}>
                                                                <option value="1">GB</option>
                                                                <option value="1024">TB</option>
                                                            </select>
                                                        </div>

                                                    </>}
                                                </>}

                                                {values.accessTier === "cold" && <>
                                                    <div className="col-md-6 px-3">
                                                        <label htmlFor="redundancy" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Redundancy
                                                        </label>
                                                        <select
                                                            className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                            id="redundancy"
                                                            style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                            value={values.redundancy}
                                                            name={"redundancy"}
                                                            onChange={(e) => {
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}>
                                                            <option value="lrs">LRS</option>
                                                            <option value="zrs">ZRS</option>
                                                            <option value="grs">GRS</option>
                                                        </select>
                                                    </div>
                                                </>}
                                                {(values.accessTier === "cold" && values.redundancy === "lrs") && <>
                                                    <div className="col-md-6 px-3">
                                                        <label htmlFor="count" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Capacity Count
                                                        </label>
                                                        <input
                                                            type="text"
                                                            style={{paddingTop:"9px"}}
                                                            className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                            id="count"
                                                            value={values.count}
                                                            name={"count"}
                                                            onChange={(e) => {
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 px-3">
                                                        <label htmlFor="storageUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Capacity storage Units
                                                        </label>
                                                        <select
                                                            className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                            id="storageUnits"
                                                            style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                            value={values.storageUnits}
                                                            name={"storageUnits"}
                                                            onChange={(e) => {
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}>
                                                            <option value="1">GB</option>
                                                            <option value="1024">TB</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-6 px-3">
                                                        <label htmlFor="blobWriteOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Write Operations
                                                        </label>
                                                        <input
                                                            type="text"
                                                            style={{paddingTop:"9px"}}
                                                            className=" form-control  custom-form text-field font-14 font-medium"
                                                            id="blobWriteOperations"
                                                            value={values.blobWriteOperations}
                                                            name={"blobWriteOperations"}
                                                            onChange={(e) => {
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 px-3">
                                                        <label htmlFor="blobCreateContainerOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                            List and Create Container Operations
                                                        </label>
                                                        <input
                                                            type="text"

                                                            className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                            id="blobCreateContainerOperations"
                                                            value={values.blobCreateContainerOperations}
                                                            name={"blobCreateContainerOperations"}
                                                            onChange={(e) => {
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 px-3">
                                                        <label htmlFor="blobReadOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Read operations
                                                        </label>
                                                        <input
                                                            type="text"
                                                            style={{paddingTop:"9px"}}                                  
                                                            className=" form-control  custom-form text-field font-14 font-medium"
                                                            id="blobReadOperations"
                                                            value={values.blobReadOperations}
                                                            name={"blobReadOperations"}
                                                            onChange={(e) => {
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 px-3">
                                                        <label htmlFor="blobOtherOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                            All other operations
                                                        </label>
                                                        <input
                                                            type="text"
                                                            style={{paddingTop:"9px"}}
                                                            className=" form-control  custom-form text-field font-14 font-medium"
                                                            id="blobOtherOperations"
                                                            value={values.blobOtherOperations}
                                                            name={"blobOtherOperations"}
                                                            onChange={(e) => {
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 px-3">
                                                        <label htmlFor="blobDataRetrieval" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Blob Data Retrieval
                                                        </label>
                                                        <input
                                                            type="text"
                                                            style={{paddingTop:"9px"}}
                                                            className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                            id="blobDataRetrieval"
                                                            value={values.blobDataRetrieval}
                                                            name={"blobDataRetrieval"}
                                                            onChange={(e) => {
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 px-3">
                                                        <label htmlFor="blobDataRetrievalUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Blob Data Retrieval Units
                                                        </label>
                                                        <select
                                                            className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                            id="blobDataRetrievalUnits"
                                                            style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                            value={values.blobDataRetrievalUnits}
                                                            name={"blobDataRetrievalUnits"}
                                                            onChange={(e) => {
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}>
                                                            <option value="1">GB</option>
                                                            <option value="1024">TB</option>
                                                        </select>
                                                    </div>
                                                </>}
                                                {(values.accessTier === "cold" && values.redundancy === "zrs") && <>
                                                    {!["us-north-central", "us-west-central", "us-west", "united-kingdom-west", "uae-central", "switzerland-west", "sweden-south", "norway-west", "korea-south", "japan-west", "israel-central", "south-india", "west-india", "germany-north", "france-south", "canada-east", "australia-central", "australia-central-2", "australia-east", "australia-southeast", "south-africa-west"].includes(values.region) && <>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="count" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Capacity Count
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="count"
                                                                value={values.count}
                                                                name={"count"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="storageUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Capacity storage Units
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                id="storageUnits"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.storageUnits}
                                                                name={"storageUnits"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}>
                                                                <option value="1">GB</option>
                                                                <option value="1024">TB</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobWriteOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Write Operations
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobWriteOperations"
                                                                value={values.blobWriteOperations}
                                                                name={"blobWriteOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobCreateContainerOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                List and Create Container Operations
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobCreateContainerOperations"
                                                                value={values.blobCreateContainerOperations}
                                                                name={"blobCreateContainerOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobReadOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Read operations
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobReadOperations"
                                                                value={values.blobReadOperations}
                                                                name={"blobReadOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobOtherOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                All other operations
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                id="blobOtherOperations"
                                                                value={values.blobOtherOperations}
                                                                name={"blobOtherOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobDataRetrieval" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Blob Data Retrieval
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobDataRetrieval"
                                                                value={values.blobDataRetrieval}
                                                                name={"blobDataRetrieval"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobDataRetrievalUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Blob Data Retrieval Units
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                id="blobDataRetrievalUnits"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.blobDataRetrievalUnits}
                                                                name={"blobDataRetrievalUnits"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}>
                                                                <option value="1">GB</option>
                                                                <option value="1024">TB</option>
                                                            </select>
                                                        </div>
                                                    </>}
                                                </>}
                                                {(values.accessTier === "cold" && values.redundancy === "grs") && <>
                                                    {!["spain-central", "qatar-central", "poland-central", "mexico-central", "italy-north", "israel-central"].includes(values.region) && <>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="count" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Capacity Count
                                                            </label>
                                                            <input
                                                                type="text"

                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                id="count"
                                                                value={values.count}
                                                                name={"count"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="storageUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Capacity storage Units
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                id="storageUnits"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.storageUnits}
                                                                name={"storageUnits"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}>
                                                                <option value="1">GB</option>
                                                                <option value="1024">TB</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobWriteOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Write Operations
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobWriteOperations"
                                                                value={values.blobWriteOperations}
                                                                name={"blobWriteOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobCreateContainerOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                List and Create Container Operations
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobCreateContainerOperations"
                                                                value={values.blobCreateContainerOperations}
                                                                name={"blobCreateContainerOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobReadOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Read operations
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobReadOperations"
                                                                value={values.blobReadOperations}
                                                                name={"blobReadOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobOtherOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                All other operations
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobOtherOperations"
                                                                value={values.blobOtherOperations}
                                                                name={"blobOtherOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobDataRetrieval" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Blob Data Retrieval
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobDataRetrieval"
                                                                value={values.blobDataRetrieval}
                                                                name={"blobDataRetrieval"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobDataRetrievalUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Blob Data Retrieval Units
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                id="blobDataRetrievalUnits"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.blobDataRetrievalUnits}
                                                                name={"blobDataRetrievalUnits"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}>
                                                                <option value="1">GB</option>
                                                                <option value="1024">TB</option>
                                                            </select>
                                                        </div>
                                                    </>}
                                                </>}

                                                {values.accessTier === "archive" && <>
                                                    <div className="col-md-6 px-3">
                                                        <label htmlFor="redundancy" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Redundancy
                                                        </label>
                                                        <select
                                                            className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                            id="redundancy"
                                                            style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                            value={values.redundancy}
                                                            name={"redundancy"}
                                                            onChange={(e) => {
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}>
                                                            <option value="lrs">LRS</option>

                                                            <option value="grs">GRS</option>
                                                        </select>
                                                    </div>
                                                </>}
                                                {(values.accessTier === "archive" && values.redundancy === "lrs") && <>
                                                    {!["switzerland-west", "spain-central", "mexico-central", "west-india"].includes(values.region) && <>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="count" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Capacity Count
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                id="count"
                                                                value={values.count}
                                                                name={"count"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="storageUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Capacity storage Units
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                id="storageUnits"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.storageUnits}
                                                                name={"storageUnits"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}>
                                                                <option value="1">GB</option>
                                                                <option value="1024">TB</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobWriteOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Write Operations
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobWriteOperations"
                                                                value={values.blobWriteOperations}
                                                                name={"blobWriteOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobCreateContainerOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                List and Create Container Operations
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobCreateContainerOperations"
                                                                value={values.blobCreateContainerOperations}
                                                                name={"blobCreateContainerOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobReadOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Read operations
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobReadOperations"
                                                                value={values.blobReadOperations}
                                                                name={"blobReadOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobArchiveRead" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Archive high priority read
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobArchiveRead"
                                                                value={values.blobArchiveRead}
                                                                name={"blobArchiveRead"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobOtherOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                All other operations
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}                                      
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobOtherOperations"
                                                                value={values.blobOtherOperations}
                                                                name={"blobOtherOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobDataRetrieval" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Blob Data Retrieval
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobDataRetrieval"
                                                                value={values.blobDataRetrieval}
                                                                name={"blobDataRetrieval"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobDataRetrievalUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Blob Data Retrieval Units
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                id="blobDataRetrievalUnits"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.blobDataRetrievalUnits}
                                                                name={"blobDataRetrievalUnits"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}>
                                                                <option value="1">GB</option>
                                                                <option value="1024">TB</option>
                                                            </select>
                                                        </div>

                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobArchiveRetrieval" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Archive high priority retrieval - Blob Archive Retrieval
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobArchiveRetrieval"
                                                                value={values.blobArchiveRetrieval}
                                                                name={"blobArchiveRetrieval"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobArchiveRetrievalUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Archive high priority retrieval - Blob Archive Retrieval Units
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                id="blobArchiveRetrievalUnits"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.blobArchiveRetrievalUnits}
                                                                name={"blobArchiveRetrievalUnits"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}>
                                                                <option value="1">GB</option>
                                                                <option value="1024">TB</option>
                                                            </select>
                                                        </div>
                                                    </>}
                                                </>}
                                                {(values.accessTier === "archive" && values.redundancy === "grs") && <>
                                                    {!["switzerland-west", "mexico-central", "west-india"].includes(values.region) && <>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="count" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Capacity Count
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}                                      
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="count"
                                                                value={values.count}
                                                                name={"count"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="storageUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Capacity storage Units
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                id="storageUnits"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.storageUnits}
                                                                name={"storageUnits"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}>
                                                                <option value="1">GB</option>
                                                                <option value="1024">TB</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobWriteOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Write Operations
                                                            </label>
                                                            <input
                                                                type="text"

                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobWriteOperations"
                                                                value={values.blobWriteOperations}
                                                                name={"blobWriteOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobCreateContainerOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                List and Create Container Operations
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobCreateContainerOperations"
                                                                value={values.blobCreateContainerOperations}
                                                                name={"blobCreateContainerOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobReadOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Read operations
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobReadOperations"
                                                                value={values.blobReadOperations}
                                                                name={"blobReadOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobArchiveRead" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Archive high priority read
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobArchiveRead"
                                                                value={values.blobArchiveRead}
                                                                name={"blobArchiveRead"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobOtherOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                All other operations
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobOtherOperations"
                                                                value={values.blobOtherOperations}
                                                                name={"blobOtherOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobDataRetrieval" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Blob Data Retrieval
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobDataRetrieval"
                                                                value={values.blobDataRetrieval}
                                                                name={"blobDataRetrieval"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobDataRetrievalUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Blob Data Retrieval Units
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                id="blobDataRetrievalUnits"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.blobDataRetrievalUnits}
                                                                name={"blobDataRetrievalUnits"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}>
                                                                <option value="1">GB</option>
                                                                <option value="1024">TB</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobArchiveRetrieval" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Archive high priority retrieval - Blob Archive Retrieval
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobArchiveRetrieval"
                                                                value={values.blobArchiveRetrieval}
                                                                name={"blobArchiveRetrieval"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobArchiveRetrievalUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Archive high priority retrieval - Blob Archive Retrieval Units
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                id="blobArchiveRetrievalUnits"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.blobArchiveRetrievalUnits}
                                                                name={"blobArchiveRetrievalUnits"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}>
                                                                <option value="1">GB</option>
                                                                <option value="1024">TB</option>
                                                            </select>
                                                        </div>
                                                    </>}
                                                </>}
                                            </>}
                                            {values.storageAccountType === "blob-storage" && <>
                                                <div className="col-md-6 px-3">
                                                    <label htmlFor="accessTier" className="form-label font-14 font-medium black-v2 mb-3">
                                                        Access Tier
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="accessTier"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.accessTier}
                                                        name={"accessTier"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="hot">Hot</option>
                                                        <option value="cool">Cool</option>
                                                        <option value="archive">Archive</option>
                                                    </select>
                                                </div>
                                                {values.accessTier === "hot" && <>
                                                    <div className="col-md-6 px-3">
                                                        <label htmlFor="redundancy" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Redundancy
                                                        </label>
                                                        <select
                                                            className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                            id="redundancy"
                                                            style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                            value={values.redundancy}
                                                            name={"redundancy"}
                                                            onChange={(e) => {
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}>
                                                            <option value="lrs">LRS</option>
                                                            <option value="grs">GRS</option>
                                                        </select>
                                                    </div>
                                                </>}
                                                {(values.accessTier === "hot" && values.redundancy === "lrs") && <>
                                                    <div className="col-md-6 px-3">
                                                        <label htmlFor="count" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Capacity Count
                                                        </label>
                                                        <input
                                                            type="text"
                                                            style={{paddingTop:"9px"}}
                                                            className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                            id="count"
                                                            value={values.count}
                                                            name={"count"}
                                                            onChange={(e) => {
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 px-3">
                                                        <label htmlFor="storageUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Capacity storage Units
                                                        </label>
                                                        <select
                                                            className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                            id="storageUnits"
                                                            style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                            value={values.storageUnits}
                                                            name={"storageUnits"}
                                                            onChange={(e) => {
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}>
                                                            <option value="1">GB</option>
                                                            <option value="1024">TB</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-6 px-3">
                                                        <label htmlFor="blobWriteOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Write Operations
                                                        </label>
                                                        <input
                                                            type="text"
                                                            style={{paddingTop:"9px"}}
                                                            className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                            id="blobWriteOperations"
                                                            value={values.blobWriteOperations}
                                                            name={"blobWriteOperations"}
                                                            onChange={(e) => {
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 px-3">
                                                        <label htmlFor="blobCreateContainerOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                            List and Create Container Operations
                                                        </label>
                                                        <input
                                                            type="text"
                                                            style={{paddingTop:"9px"}}
                                                            className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                            id="blobCreateContainerOperations"
                                                            value={values.blobCreateContainerOperations}
                                                            name={"blobCreateContainerOperations"}
                                                            onChange={(e) => {
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 px-3">
                                                        <label htmlFor="blobReadOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Read operations
                                                        </label>
                                                        <input
                                                            type="text"
                                                            style={{paddingTop:"9px"}}
                                                            className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                            id="blobReadOperations"
                                                            value={values.blobReadOperations}
                                                            name={"blobReadOperations"}
                                                            onChange={(e) => {
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 px-3">
                                                        <label htmlFor="blobOtherOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                            All other operations
                                                        </label>
                                                        <input
                                                            type="text"
                                                            style={{paddingTop:"9px"}}
                                                            className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                            id="blobOtherOperations"
                                                            value={values.blobOtherOperations}
                                                            name={"blobOtherOperations"}
                                                            onChange={(e) => {
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}
                                                        />
                                                    </div>
                                                    {!["us-west-3", "sweden-central", "sweden-south", "spain-central", "qatar-central", "poland-central", "norway-east", "norway-west", "mexico-central", "italy-north", "israel-central", "brazil-southeast"].includes(values.region) && <>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobDataRetrieval" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Blob Data Retrieval
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobDataRetrieval"
                                                                value={values.blobDataRetrieval}
                                                                name={"blobDataRetrieval"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobDataRetrievalUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Blob Data Retrieval Units
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                id="blobDataRetrievalUnits"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.blobDataRetrievalUnits}
                                                                name={"blobDataRetrievalUnits"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}>
                                                                <option value="1">GB</option>
                                                                <option value="1024">TB</option>
                                                            </select>
                                                        </div>
                                                    </>}
                                                </>}
                                                {(values.accessTier === "hot" && values.redundancy === "grs") && <>
                                                    {!["spain-central", "poland-central", "qatar-central", "mexico-central", "italy-north", "israel-central"].includes(values.region) && <>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="count" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Capacity Count
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="count"
                                                                value={values.count}
                                                                name={"count"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="storageUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Capacity storage Units
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                id="storageUnits"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.storageUnits}
                                                                name={"storageUnits"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}>
                                                                <option value="1">GB</option>
                                                                <option value="1024">TB</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobWriteOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Write Operations
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobWriteOperations"
                                                                value={values.blobWriteOperations}
                                                                name={"blobWriteOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobCreateContainerOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                List and Create Container Operations
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobCreateContainerOperations"
                                                                value={values.blobCreateContainerOperations}
                                                                name={"blobCreateContainerOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobReadOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Read operations
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobReadOperations"
                                                                value={values.blobReadOperations}
                                                                name={"blobReadOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobOtherOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                All other operations
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobOtherOperations"
                                                                value={values.blobOtherOperations}
                                                                name={"blobOtherOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        {!["us-west-3", "uae-central", "sweden-central", "sweden-south", "norway-east", "norway-west", "brazil-southeast"].includes(values.region) && <>
                                                            <div className="col-md-6 px-3">
                                                                <label htmlFor="blobDataRetrieval" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Blob Data Retrieval
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    style={{paddingTop:"9px"}}
                                                                    className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                    id="blobDataRetrieval"
                                                                    value={values.blobDataRetrieval}
                                                                    name={"blobDataRetrieval"}
                                                                    onChange={(e) => {
                                                                        onChangeOfConfiguration(e, values.instanceName);
                                                                    }} disabled={isViewer}
                                                                />
                                                            </div>
                                                            <div className="col-md-6 px-3">
                                                                <label htmlFor="blobDataRetrievalUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Blob Data Retrieval Units
                                                                </label>
                                                                <select
                                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                    id="blobDataRetrievalUnits"
                                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                    value={values.blobDataRetrievalUnits}
                                                                    name={"blobDataRetrievalUnits"}
                                                                    onChange={(e) => {
                                                                        onChangeOfConfiguration(e, values.instanceName);
                                                                    }} disabled={isViewer}>
                                                                    <option value="1">GB</option>
                                                                    <option value="1024">TB</option>
                                                                </select>
                                                            </div>
                                                            <div className="col-md-6 px-3">
                                                                <label htmlFor="blobDataWrite" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Blob Data Write
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    style={{paddingTop:"9px"}}
                                                                    className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                    id="blobDataWrite"
                                                                    value={values.blobDataWrite}
                                                                    name={"blobDataWrite"}
                                                                    onChange={(e) => {
                                                                        onChangeOfConfiguration(e, values.instanceName);
                                                                    }} disabled={isViewer}
                                                                />
                                                            </div>
                                                            <div className="col-md-6 px-3">
                                                                <label htmlFor="blobDataRetrievalUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Blob Data Retrieval Units
                                                                </label>
                                                                <select
                                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                    id="blobDataRetrievalUnits"
                                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                    value={values.blobDataRetrievalUnits}
                                                                    name={"blobDataRetrievalUnits"}
                                                                    onChange={(e) => {
                                                                        onChangeOfConfiguration(e, values.instanceName);
                                                                    }} disabled={isViewer}>
                                                                    <option value="1">GB</option>
                                                                    <option value="1024">TB</option>
                                                                </select>
                                                            </div>
                                                        </>}
                                                    </>}
                                                </>}

                                                {values.accessTier === "cool" && <>
                                                    <div className="col-md-6 px-3">
                                                        <label htmlFor="redundancy" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Redundancy
                                                        </label>
                                                        <select
                                                            className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                            id="redundancy"
                                                            style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                            value={values.redundancy}
                                                            name={"redundancy"}
                                                            onChange={(e) => {
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}>
                                                            <option value="lrs">LRS</option>
                                                            <option value="grs">GRS</option>
                                                        </select>
                                                    </div>
                                                </>}
                                                {(values.accessTier === "cool" && values.redundancy === "lrs") && <>
                                                    <div className="col-md-6 px-3">
                                                        <label htmlFor="count" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Capacity Count
                                                        </label>
                                                        <input
                                                            type="text"
                                                            style={{paddingTop:"9px"}}
                                                            className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                            id="count"
                                                            value={values.count}
                                                            name={"count"}
                                                            onChange={(e) => {
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 px-3">
                                                        <label htmlFor="storageUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Capacity storage Units
                                                        </label>
                                                        <select
                                                            className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                            id="storageUnits"
                                                            style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                            value={values.storageUnits}
                                                            name={"storageUnits"}
                                                            onChange={(e) => {
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}>
                                                            <option value="1">GB</option>
                                                            <option value="1024">TB</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-6 px-3">
                                                        <label htmlFor="blobWriteOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Write Operations
                                                        </label>
                                                        <input
                                                            type="text"
                                                            style={{paddingTop:"9px"}}
                                                            className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                            id="blobWriteOperations"
                                                            value={values.blobWriteOperations}
                                                            name={"blobWriteOperations"}
                                                            onChange={(e) => {
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 px-3">
                                                        <label htmlFor="blobCreateContainerOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                            List and Create Container Operations
                                                        </label>
                                                        <input
                                                            type="text"
                                                            style={{paddingTop:"9px"}}
                                                            className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                            id="blobCreateContainerOperations"
                                                            value={values.blobCreateContainerOperations}
                                                            name={"blobCreateContainerOperations"}
                                                            onChange={(e) => {
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 px-3">
                                                        <label htmlFor="blobReadOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Read operations
                                                        </label>
                                                        <input
                                                            type="text"
                                                            style={{paddingTop:"9px"}}
                                                            className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                            id="blobReadOperations"
                                                            value={values.blobReadOperations}
                                                            name={"blobReadOperations"}
                                                            onChange={(e) => {
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 px-3">
                                                        <label htmlFor="blobOtherOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                            All other operations
                                                        </label>
                                                        <input
                                                            type="text"
                                                            style={{paddingTop:"9px"}}
                                                            className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                            id="blobOtherOperations"
                                                            value={values.blobOtherOperations}
                                                            name={"blobOtherOperations"}
                                                            onChange={(e) => {
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 px-3">
                                                        <label htmlFor="blobDataRetrieval" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Blob Data Retrieval
                                                        </label>
                                                        <input
                                                            type="text"
                                                            style={{paddingTop:"9px"}}
                                                            className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                            id="blobDataRetrieval"
                                                            value={values.blobDataRetrieval}
                                                            name={"blobDataRetrieval"}
                                                            onChange={(e) => {
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 px-3">
                                                        <label htmlFor="blobDataRetrievalUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Blob Data Retrieval Units
                                                        </label>
                                                        <select
                                                            className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                            id="blobDataRetrievalUnits"
                                                            style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                            value={values.blobDataRetrievalUnits}
                                                            name={"blobDataRetrievalUnits"}
                                                            onChange={(e) => {
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}>
                                                            <option value="1">GB</option>
                                                            <option value="1024">TB</option>
                                                        </select>
                                                    </div>
                                                </>}
                                                {(values.accessTier === "cool" && values.redundancy === "grs") && <>
                                                    {!["spain-central", "poland-central", "qatar-central", "mexico-central", "italy-north", "israel-central"].includes(values.region) && <>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="count" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Capacity Count
                                                            </label>
                                                            <input
                                                                type="text" 
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="count"
                                                                value={values.count}
                                                                name={"count"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="storageUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Capacity storage Units
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                id="storageUnits"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.storageUnits}
                                                                name={"storageUnits"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}>
                                                                <option value="1">GB</option>
                                                                <option value="1024">TB</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobWriteOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Write Operations
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobWriteOperations"
                                                                value={values.blobWriteOperations}
                                                                name={"blobWriteOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobCreateContainerOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                List and Create Container Operations
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}                                      
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobCreateContainerOperations"
                                                                value={values.blobCreateContainerOperations}
                                                                name={"blobCreateContainerOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobReadOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Read operations
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobReadOperations"
                                                                value={values.blobReadOperations}
                                                                name={"blobReadOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobOtherOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                All other operations
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobOtherOperations"
                                                                value={values.blobOtherOperations}
                                                                name={"blobOtherOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobDataRetrieval" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Blob Data Retrieval
                                                            </label>
                                                            <input
                                                                type="text"           
                                                                style={{paddingTop:"9px"}}                           
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobDataRetrieval"
                                                                value={values.blobDataRetrieval}
                                                                name={"blobDataRetrieval"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobDataRetrievalUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Blob Data Retrieval Units
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                id="blobDataRetrievalUnits"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.blobDataRetrievalUnits}
                                                                name={"blobDataRetrievalUnits"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}>
                                                                <option value="1">GB</option>
                                                                <option value="1024">TB</option>
                                                            </select>
                                                        </div>
                                                    </>}
                                                </>}

                                                {values.accessTier === "archive" && <>
                                                    <div className="col-md-6 px-3">
                                                        <label htmlFor="redundancy" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Redundancy
                                                        </label>
                                                        <select
                                                            className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                            id="redundancy"
                                                            style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                            value={values.redundancy}
                                                            name={"redundancy"}
                                                            onChange={(e) => {
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}>
                                                            <option value="lrs">LRS</option>
                                                            <option value="grs">GRS</option>
                                                        </select>
                                                    </div>
                                                </>}
                                                {(values.accessTier === "archive" && values.redundancy === "lrs") && <>
                                                    {!["mexico-central", "west-india"].includes(values.region) && <>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="count" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Capacity Count
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="count"
                                                                value={values.count}
                                                                name={"count"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="storageUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Capacity storage Units
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                id="storageUnits"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.storageUnits}
                                                                name={"storageUnits"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}>
                                                                <option value="1">GB</option>
                                                                <option value="1024">TB</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobWriteOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Write Operations
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobWriteOperations"
                                                                value={values.blobWriteOperations}
                                                                name={"blobWriteOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobCreateContainerOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                List and Create Container Operations
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobCreateContainerOperations"
                                                                value={values.blobCreateContainerOperations}
                                                                name={"blobCreateContainerOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobReadOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Read operations
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}                                      
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobReadOperations"
                                                                value={values.blobReadOperations}
                                                                name={"blobReadOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobOtherOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                All other operations
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobOtherOperations"
                                                                value={values.blobOtherOperations}
                                                                name={"blobOtherOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobDataRetrieval" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Blob Data Retrieval
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}                                                                
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobDataRetrieval"
                                                                value={values.blobDataRetrieval}
                                                                name={"blobDataRetrieval"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobDataRetrievalUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Blob Data Retrieval Units
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                id="blobDataRetrievalUnits"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.blobDataRetrievalUnits}
                                                                name={"blobDataRetrievalUnits"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}>
                                                                <option value="1">GB</option>
                                                                <option value="1024">TB</option>
                                                            </select>
                                                        </div>
                                                    </>}
                                                </>}
                                                {(values.accessTier === "archive" && values.redundancy === "grs") && <>
                                                    {!["spain-central", "mexico-central", "italy-north", "israel-central"].includes(values.region) && <>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="count" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Capacity Count
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="count"
                                                                value={values.count}
                                                                name={"count"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="storageUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Capacity storage Units
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                id="storageUnits"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.storageUnits}
                                                                name={"storageUnits"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}>
                                                                <option value="1">GB</option>
                                                                <option value="1024">TB</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobWriteOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Write Operations
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobWriteOperations"
                                                                value={values.blobWriteOperations}
                                                                name={"blobWriteOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobCreateContainerOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                List and Create Container Operations
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobCreateContainerOperations"
                                                                value={values.blobCreateContainerOperations}
                                                                name={"blobCreateContainerOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobReadOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Read operations
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobReadOperations"
                                                                value={values.blobReadOperations}
                                                                name={"blobReadOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobOtherOperations" className="form-label font-14 font-medium black-v2 mb-3">
                                                                All other operations
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobOtherOperations"
                                                                value={values.blobOtherOperations}
                                                                name={"blobOtherOperations"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobDataRetrieval" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Blob Data Retrieval
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop:"9px"}}                                      
                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                id="blobDataRetrieval"
                                                                value={values.blobDataRetrieval}
                                                                name={"blobDataRetrieval"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="blobDataRetrievalUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Blob Data Retrieval Units
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                id="blobDataRetrievalUnits"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.blobDataRetrievalUnits}
                                                                name={"blobDataRetrievalUnits"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}>
                                                                <option value="1">GB</option>
                                                                <option value="1024">TB</option>
                                                            </select>
                                                        </div>
                                                    </>}
                                                </>}
                                            </>}
                                        </>}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    } catch (error) {
        //console.log(error);
    }
}