export default function AWS_SDK_RDS_SQL({ values, onChangeOfConfiguration, isViewer }) {
    try {
        return (
            <div
                class="accordion-body p-0 estimation-container">
                <div className="row py-4 mx-4 px-5">
                    <div className="col-md-6 px-3">
                        <label htmlFor="region" className="form-label font-14 font-medium black-v2 mb-3">
                            Region
                        </label>
                        <select
                            className="form-select custom-form font-semibold font-14 form-select-sm"
                            id="region"
                            style={{ paddingTop: "10px" }}
                            value={values.region}
                            name={"region"}
                            onChange={(e) => {
                                onChangeOfConfiguration(e, values.instanceName);
                            }} disabled={isViewer}
                        >
                            <option value="ap-south-2">ap-south-2</option><option value="ap-southeast-3">ap-southeast-3</option><option value="ap-southeast-1">ap-southeast-1</option><option value="us-gov-east-1">us-gov-east-1</option><option value="eu-west-2">eu-west-2</option><option value="af-south-1">af-south-1</option><option value="ap-northeast-1">ap-northeast-1</option><option value="ap-southeast-2">ap-southeast-2</option><option value="us-east-2">us-east-2</option><option value="eu-central-1">eu-central-1</option><option value="ca-central-1">ca-central-1</option><option value="ap-south-1">ap-south-1</option><option value="ap-northeast-3">ap-northeast-3</option><option value="sa-east-1">sa-east-1</option><option value="ap-east-1">ap-east-1</option><option value="eu-west-1">eu-west-1</option><option value="eu-south-1">eu-south-1</option><option value="ap-southeast-4">ap-southeast-4</option><option value="eu-north-1">eu-north-1</option><option value="eu-south-2">eu-south-2</option><option value="us-west-1">us-west-1</option><option value="us-west-2">us-west-2</option><option value="me-south-1">me-south-1</option><option value="eu-central-2">eu-central-2</option><option value="us-gov-west-1">us-gov-west-1</option><option value="ap-northeast-2">ap-northeast-2</option><option value="me-central-1">me-central-1</option><option value="us-east-1">us-east-1</option><option value="eu-west-3">eu-west-3</option>
                        </select>
                    </div>
                    <div className="col-md-6 px-3">
                        <label
                            htmlFor="noOfNodes"
                            className="form-label font-14 font-medium black-v2 mb-3"
                        >
                            Nodes
                        </label>
                        <input
                            type="text"
                            style={{ paddingTop: "10px" }}
                            className="form-control custom-form text-field font-14 font-medium"
                            id="noOfNodes"
                            value={values.noOfNodes}
                            name={"noOfNodes"}
                            onChange={(e) => {
                                onChangeOfConfiguration(e, values.instanceName);
                            }} disabled={isViewer}
                        />
                    </div>
                    <div className="col-md-6 px-3">
                        <label htmlFor="instanceType" className="form-label font-14 font-medium black-v2 mb-3">
                            Instance Type
                        </label>
                        <select
                            style={{ paddingTop: "10px" }}
                            className="form-select custom-form font-semibold font-14 form-select-sm"
                            id="instanceType"
                            value={values.instanceType}
                            name={"instanceType"}
                            onChange={(e) => {
                                onChangeOfConfiguration(e, values.instanceName);
                            }} disabled={isViewer}
                        >
                            <option value="db.m4.10xlarge">db.m4.10xlarge</option>
                            <option value="db.m4.16xlarge">db.m4.16xlarge</option>
                            <option value="db.m4.2xlarge">db.m4.2xlarge</option>
                            <option value="db.m4.4xlarge">db.m4.4xlarge</option>
                            <option value="db.m4.large">db.m4.large</option>
                            <option value="db.m4.xlarge">db.m4.xlarge</option>
                            <option value="db.m5.12xlarge">db.m5.12xlarge</option>
                            <option value="db.m5.16xlarge">db.m5.16xlarge</option>
                            <option value="db.m5.24xlarge">db.m5.24xlarge</option>
                            <option value="db.m5.2xlarge">db.m5.2xlarge</option>
                            <option value="db.m5.4xlarge">db.m5.4xlarge</option>
                            <option value="db.m5.8xlarge">db.m5.8xlarge</option>
                            <option value="db.m5.large">db.m5.large</option>
                            <option value="db.m5.xlarge">db.m5.xlarge</option>
                            <option value="db.m5d.12xlarge">db.m5d.12xlarge</option>
                            <option value="db.m5d.16xlarge">db.m5d.16xlarge</option>
                            <option value="db.m5d.24xlarge">db.m5d.24xlarge</option>
                            <option value="db.m5d.2xlarge">db.m5d.2xlarge</option>
                            <option value="db.m5d.4xlarge">db.m5d.4xlarge</option>
                            <option value="db.m5d.8xlarge">db.m5d.8xlarge</option>
                            <option value="db.m5d.large">db.m5d.large</option>
                            <option value="db.m5d.xlarge">db.m5d.xlarge</option>
                            <option value="db.m6i.12xlarge">db.m6i.12xlarge</option>
                            <option value="db.m6i.16xlarge">db.m6i.16xlarge</option>
                            <option value="db.m6i.24xlarge">db.m6i.24xlarge</option>
                            <option value="db.m6i.2xlarge">db.m6i.2xlarge</option>
                            <option value="db.m6i.32xlarge">db.m6i.32xlarge</option>
                            <option value="db.m6i.4xlarge">db.m6i.4xlarge</option>
                            <option value="db.m6i.8xlarge">db.m6i.8xlarge</option>
                            <option value="db.m6i.large">db.m6i.large</option>
                            <option value="db.m6i.xlarge">db.m6i.xlarge</option>
                            <option value="db.r3.large">db.r3.large</option>
                            <option value="db.r4.16xlarge">db.r4.16xlarge</option>
                            <option value="db.r4.2xlarge">db.r4.2xlarge</option>
                            <option value="db.r4.4xlarge">db.r4.4xlarge</option>
                            <option value="db.r4.8xlarge">db.r4.8xlarge</option>
                            <option value="db.r4.large">db.r4.large</option>
                            <option value="db.r4.xlarge">db.r4.xlarge</option>
                            <option value="db.r5.12xlarge">db.r5.12xlarge</option>
                            <option value="db.r5.16xlarge">db.r5.16xlarge</option>
                            <option value="db.r5.24xlarge">db.r5.24xlarge</option>
                            <option value="db.r5.2xlarge">db.r5.2xlarge</option>
                            <option value="db.r5.4xlarge">db.r5.4xlarge</option>
                            <option value="db.r5.8xlarge">db.r5.8xlarge</option>
                            <option value="db.r5.large">db.r5.large</option>
                            <option value="db.r5.xlarge">db.r5.xlarge</option>
                            <option value="db.r5b.12xlarge">db.r5b.12xlarge</option>
                            <option value="db.r5b.16xlarge">db.r5b.16xlarge</option>
                            <option value="db.r5b.24xlarge">db.r5b.24xlarge</option>
                            <option value="db.m5d.2xlarge">db.m5d.2xlarge</option>
                            <option value="db.m5d.4xlarge">db.m5d.4xlarge</option>
                            <option value="db.m5d.8xlarge">db.m5d.8xlarge</option>
                            <option value="db.m5d.large">db.m5d.large</option>
                            <option value="db.m5d.xlarge">db.m5d.xlarge</option>
                            <option value="db.m6i.12xlarge">db.m6i.12xlarge</option>
                            <option value="db.m6i.16xlarge">db.m6i.16xlarge</option>
                            <option value="db.m6i.24xlarge">db.m6i.24xlarge</option>
                            <option value="db.m6i.2xlarge">db.m6i.2xlarge</option>
                            <option value="db.m6i.32xlarge">db.m6i.32xlarge</option>
                            <option value="db.m6i.4xlarge">db.m6i.4xlarge</option>
                            <option value="db.m6i.8xlarge">db.m6i.8xlarge</option>
                            <option value="db.m6i.large">db.m6i.large</option>
                            <option value="db.m6i.xlarge">db.m6i.xlarge</option>
                            <option value="db.r3.large">db.r3.large</option>
                            <option value="db.r4.16xlarge">db.r4.16xlarge</option>
                            <option value="db.r4.2xlarge">db.r4.2xlarge</option>
                            <option value="db.r4.4xlarge">db.r4.4xlarge</option>
                            <option value="db.r4.8xlarge">db.r4.8xlarge</option>
                            <option value="db.r4.large">db.r4.large</option>
                            <option value="db.r4.xlarge">db.r4.xlarge</option>
                            <option value="db.r5.12xlarge">db.r5.12xlarge</option>
                            <option value="db.r5.16xlarge">db.r5.16xlarge</option>
                            <option value="db.r5.24xlarge">db.r5.24xlarge</option>
                            <option value="db.r5.2xlarge">db.r5.2xlarge</option>
                            <option value="db.r5.4xlarge">db.r5.4xlarge</option>
                            <option value="db.r5.8xlarge">db.r5.8xlarge</option>
                            <option value="db.r5.large">db.r5.large</option>
                            <option value="db.r5.xlarge">db.r5.xlarge</option>
                            <option value="db.r5b.12xlarge">db.r5b.12xlarge</option>
                            <option value="db.r5b.16xlarge">db.r5b.16xlarge</option>
                            <option value="db.r5b.24xlarge">db.r5b.24xlarge</option>
                        </select>
                    </div>


                    <label style={{ paddingBottom: '15px', marginTop: "20px" }}>Utilization (On-Demand only)</label>

                    <div className="col-md-6 px-3">
                        <label htmlFor="deploymentOption" className="form-label font-14 font-medium black-v2 mb-3">
                            Deployment Option
                        </label>
                        <select
                            style={{ paddingTop: "10px" }}
                            className="form-select custom-form font-semibold font-14 form-select-sm"
                            id="deploymentOption"
                            value={values.deploymentOption}
                            name={"deploymentOption"}
                            onChange={(e) => {
                                onChangeOfConfiguration(e, values.instanceName);
                            }} disabled={isViewer}
                        >
                            <option value="Multi-AZ">Multi-AZ</option>
                            <option value="Single-AZ">Single-AZ</option>
                        </select>
                    </div>
                    <div className="col-md-6 px-3">
                        <label
                            htmlFor="utilization"
                            className="form-label font-14 font-medium black-v2 mb-3"
                        >
                            Utilization
                        </label>
                        <input
                            type="text"
                            style={{ paddingTop: "10px" }}
                            className="form-control custom-form text-field font-14 font-medium mb-3"
                            id="utilization"
                            value={values.utilization}
                            name={"utilization"}
                            onChange={(e) => {
                                onChangeOfConfiguration(e, values.instanceName);
                            }} disabled={isViewer}
                        />
                    </div>
                    <div className="col-md-6 px-3">
                        <label htmlFor="utilizationUnit" className="form-label font-14 font-medium black-v2 mb-3">
                            Unit
                        </label>
                        <select
                            style={{ paddingTop: "10px" }}
                            className="form-select custom-form font-semibold font-14 form-select-sm"
                            id="utilizationUnit"
                            value={values.utilizationUnit}
                            name={"utilizationUnit"}
                            onChange={(e) => {
                                onChangeOfConfiguration(e, values.instanceName);
                            }} disabled={isViewer}
                        >
                            <option value="perDay">Hours/Day</option>
                            <option value="perWeek">Hours/Week</option>
                            <option value="perMonth">Hours/Month</option>
                            <option value="utilization">%Utilized/Month</option>
                        </select>
                    </div>
                    <div className="col-md-6 px-3">
                        <label htmlFor="databaseEdition" className="form-label font-14 font-medium black-v2 mb-3">
                            Database Edition
                        </label>
                        <select
                            style={{ paddingTop: "10px" }}
                            className="form-select custom-form font-semibold font-14 form-select-sm"
                            id="databaseEdition"
                            value={values.databaseEdition}
                            name={"databaseEdition"}
                            onChange={(e) => {
                                onChangeOfConfiguration(e, values.instanceName);
                            }} disabled={isViewer}
                        >
                            <option value="Standard">Standard</option>
                            <option value="Enterprise">Enterprise</option>
                        </select>
                    </div>


                    <label style={{ marginTop: '15px', marginBottom: "10px" }}>RDS Proxy</label>
                    <div >
                        <label htmlFor="RDSproxy" className="form-label font-14 font-medium black-v2 mb-3">
                            Would you be creating an RDS Proxy with the database?
                        </label>
                        <select
                            className="form-select custom-form font-semibold font-14 form-select-sm"
                            id="RDSproxy"
                            value={values.RDSproxy}
                            style={{ paddingTop: "10px" }}
                            name={"RDSproxy"}
                            onChange={(e) => {
                                onChangeOfConfiguration(e, values.instanceName);
                            }} disabled={isViewer}
                        >
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </div>
                    <label style={{ paddingBottom: '10px', marginTop: "20px" }}>Storage</label>
                    <div className="col-md-6 px-3">
                        <label
                            htmlFor="Storage"
                            className="form-label font-14 font-medium black-v2 mb-3"
                        >
                            Storage amount
                        </label>
                        <input
                            type="text"
                            style={{ paddingTop: "10px" }}
                            className="form-control custom-form text-field font-14 font-medium"
                            id="Storage"
                            value={values.Storage}
                            name={"Storage"}
                            onChange={(e) => {
                                onChangeOfConfiguration(e, values.instanceName);
                            }} disabled={isViewer}
                        />
                    </div>
                    <div className="col-md-6 px-3">
                        <label htmlFor="provisionedThroughputUnit" className="form-label font-14 font-medium black-v2 mb-3">
                            Unit
                        </label>
                        <select
                            className="form-select custom-form font-semibold font-14 form-select-sm mb-3"
                            id="StorageUnit"
                            value={values.StorageUnit}
                            name={"StorageUnit"}
                            style={{ paddingTop: "10px" }}
                            onChange={(e) => {
                                onChangeOfConfiguration(e, values.instanceName);
                            }} disabled={isViewer}
                        >
                            <option value="MB">MB</option>
                            <option value="GB">GB</option>
                            <option value="TB">TB</option>
                        </select>
                    </div>
                    <div className="col-md-6 px-3">
                        <label htmlFor="storageType" className="form-label font-14 font-medium black-v2 mb-3">
                            Storage for each RDS instance
                        </label>
                        <select
                            className="form-select custom-form font-semibold font-14 form-select-sm"
                            id="storageType"
                            value={values.storageType}
                            style={{ paddingTop: "10px" }}
                            name={"storageType"}
                            onChange={(e) => {
                                onChangeOfConfiguration(e, values.instanceName);
                            }} disabled={isViewer}
                        >
                            <option value="gp2 General Purpose (SSD)">gp2</option>
                            <option value="gp3 General Purpose-GP3">gp3</option>
                            <option value="io1 Provisioned IOPS (SSD)">io1</option>
                            <option value="io2 Provisioned IOPS (SSD)">io2</option>
                            <option value="Magnetic">Magnetic</option>
                        </select>
                    </div>


                </div>
            </div>
        );
    } catch (error) {
        //console.log(error);
    }
}