import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getArticleDetail, searchArticle } from '../../Service/api'; // Adjust the import path as needed
import Loader from '../loader/loader';

//PS_01 To PS_05 Declare state variables
const ViewArticle = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [article, setArticle] = useState(null);
  const [relatedArticles, setRelatedArticles] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [showLoader, setShowLoader]=useState(false)
//PS_06 Use effect for triggereing
  useEffect(() => {
    //PS_07 to PS_10 Function to fetch data
    
const fetchData = async () => {
  try {
    const { state } = location;
    if (state) {
      const { userId, articleId, searchText: initialSearchText } = state;
      if (articleId) {
        setShowLoader(true)
        await fetchArticleById(userId, articleId);
        setShowLoader(false)
      } else if (initialSearchText) {
        setSearchText(initialSearchText);
        setShowLoader(true)
        await performSearch(userId, initialSearchText);
        setShowLoader(false)
      }
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    setShowLoader(false)
    // Optionally, you can set an error state or show an error message here
    // setError("Failed to fetch data. Please try again.");
  }
};
    fetchData();
  }, [location]);



  // function removeImgPTags(htmlString) {

  //   console.log(htmlString,"This is the html string...")
  //   // Regular expression to match <p><img src=""></p>
  //   const imgPTagPattern = /<p><img\s+(?:src=["']?([^"'>\s]+)["']?\s*\/?)?><\/p>/gi;
    
  //   // Replace the matched pattern with just the <img> tag
  //   const cleanedHtmlString = htmlString.replace(imgPTagPattern, (match, src) => {
  //     return src ? `<img src="${src}">` : '<img>';
  //   });


  //   return cleanedHtmlString;
  // }

  function removeImgPTags(htmlString) {
    console.log(htmlString, "This is the html string...");
  
    // Regular expression to match <p><img src=""></p>
    const imgPTagPattern = /<p><img\s+(?:src=["']?([^"'>\s]+)["']?\s*\/?)?><\/p>/gi;
  
    // Regular expression to match <img src=""> (without <p> tags)
    const imgPattern = /<img\s+(?:src=["']?([^"'>\s]+)["']?\s*\/?)?>/gi;
  
    // Replace the matched patterns
    const cleanedHtmlString = htmlString.replace(imgPTagPattern, (match, src) => {
      return src ? `<img src="${src}" width="100%">` : '<img width="100%">';
    }).replace(imgPattern, (match, src) => {
      return src ? `<img src="${src}" width="100%">` : '<img width="100%">';
    });
  
    return cleanedHtmlString;
  }


  //PS_11 to PS_15 Function to fetch article detail
const fetchArticleById = async (userId, articleId) => {
  try {
    setShowLoader(true)
    const response = await getArticleDetail({ userId, articleId });
    setShowLoader(false)
    console.log('Full response:', response);

    if (response.data && response.data.success) {
      let { article, relatedArticles } = response.data;

      // console.info(article,"This is the article to be aligned!!!")

      // article = removeImgPTags(article?.articleData)

      // console.log(article,"This is the article")

      console.log(article,"This is the article....")

      let refinedArticleString = removeImgPTags(article?.articleData)

      console.log(refinedArticleString,"This is the refined Article string...")


      article.articleData = refinedArticleString

      if (article) {
        setArticle(article);
        setRelatedArticles(relatedArticles || []);
      } else {
        console.error("Article data not found in the response");
        setShowLoader(false)
      }
    } else {
      console.error("Unexpected API response structure:", response);
      setShowLoader(false)
    }
  } catch (error) {
    console.error('Error fetching article details:', error);
    setShowLoader(false)
    // Optionally, you can set an error state or show an error message here
    // setError("Failed to fetch article details. Please try again.");
  }
};
  //PS_16 to PS_21 Function for seraching article
  
  
  const performSearch = async (userId, searchData) => {
    if (searchData.length < 3) return;
  
    try {
      setShowLoader(true)
      const response = await searchArticle({ userId, searchData });
      setShowLoader(false)
  
      if (response.data && response.data.success) {
        const { article, relatedArticles } = response.data;
        if (article) {
          setArticle(article);
          setRelatedArticles(relatedArticles || []);
        } else {
          console.log("No article found for the search query");
          setShowLoader(false)
        }
      } else {
        console.error("Unexpected API response structure:", response);
        setShowLoader(false)
      }
    } catch (error) {
      console.error('Error searching for article:', error);
      setShowLoader(false) 
    }
  };
  //PS_22 to PS_24 Function to handle search
  const handleSearch = () => {
    const userId = localStorage.getItem('userId');
    performSearch(userId, searchText);
  };

  //PS_25 to PS_26 Function for enter key click
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  //PS_27 to PS_28 Function for back arrow
  const handleBackClick = () => {
    navigate('/ArticleGrid');
  };

  return (
    <>
  {showLoader ? (
        <Loader />
      ) : (
    <div className="container-fluid custom-container right-container-scroll
 " style={{height:"100vh"}}>
      <div className="d-block mt-4">
        <div className="d-flex align-items-center mb-3 mb-md-0 ">
          <a href="#" onClick={handleBackClick}>
            <img
              src="images/back-arrow.svg"
              alt="back-arrow"
              title="Back"
              className="me-3 icon-filter"
            />
          </a>
          <div className="custom-article-search-bar">
            <div className="input-group input-search custom-border-box custom-search-box">
              <input
                type="search"
                className="px-3 form-control common-bg border-0 custom-form custom-search $gray-v1"
                placeholder="Search"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyPress={handleKeyPress}
              />
              <button
                className="btn border-start-0 custom-form custom-search"
                type="button"
                onClick={handleSearch}
                disabled={searchText.length < 3}
              >
                <img src="images/search-icon-violet.svg" alt="search" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row ps-5 pe-2 mt-4 black-v2">
        <div className="col-md-9 p-0 pe-4">
          {article && (
            <>
              <h1 className="font-20 font-bold black-v2">{article.articleName}</h1>
              <div dangerouslySetInnerHTML={{ __html: article.articleData }} />
            </>
          )}
        </div>
        <div className="col-md-3 p-0 ps-4 pe-3 custom-related-contents">
          <h4 className="font-20 font-bold mb-4 pb-2 black-v2">Related Contents</h4>
          {relatedArticles.map((relatedArticle) => (
            <a
              key={relatedArticle.articleId}
              href="#"
              className="font-14 font-medium secondary-text-color-v4 line-height-24 mt-4 text-decoration-none d-block"
              onClick={(e) => {
                e.preventDefault();
                fetchArticleById(localStorage.getItem('userId'), relatedArticle.articleId);
              }}
            >
              {relatedArticle.articleName}
            </a>
          ))}
          </div>
        </div>
      </div>
    )}
  </>
);
};

export default ViewArticle;