//PS_01
import React, { useState, useEffect,useContext } from 'react';
import { useLocation } from 'react-router-dom';
//PS_02
import SideBar from '../sidebar/SideBar';
//PS_03
import { getAllAwsServicesApi,getAccountCount,getAllProvidersApi, syncNowAwsInventory, syncNowAzureInventory} from '../../Service/api';
import { formatDistanceToNow } from 'date-fns';
import { OrgContext } from '../../ContextProvider/OrgContext';
import Loader from '../loader/loader';

//PS_04
const ServicesGridPage = ({ isDarkMode, setIsDarkMode }) => {
    const [services, setServices] = useState([]);
    const [services2, setServices2] = useState([]);
    const [services3, setServices3] = useState([]);
    const [userId, setuserId] = useState(localStorage.getItem('userId'));
   
    const [categories, setCategories] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0); // For carousel index
    const [currentIndex2, setCurrentIndex2] = useState(0); // For carousel index
    const [filterCriteria, setFilterCriteria] = useState({ resourceName: "", resourceType: "", region: "" });
    const [showFilterPopup, setShowFilterPopup] = useState(false);
    const [filteredServices, setFilteredServices] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchQuery2, setSearchQuery2] = useState("");

    const [timeAgo,setTimeAgo] = useState(null)
    const [lastSyncTime,setLastSyncTime] = useState(null)

    const [cloudProvider, setCloudProvider] = useState("AWS");
    const [providers,setProviders] = useState([]);

    const [awsservices, setawsServices] = useState({ data: { aws_services: { service_details: [], services: {} } } });
    const [azureservices, setAzureServices] = useState({ data: { azure_services: { service_details: [], services: {} }, azure_overview: {} } });
    const [AWSURL, setAWSURL] = useState("");
    const [AzureURL, setAzureURL] = useState("");
    const [AWScount, setAWScount] = useState("");
    const [AzureCount, setAzureCount] = useState("");

    const [loading, setLoading] = useState(true); 
    const [offset, setOffset] = useState(0); // Initial offset
    const [offset2, setOffset2] = useState(0); // Initial offset

    // const limit = 20; // Set limit for paginatio





    const {
        userRoles,
        orgId,
        providerId,
        architectures,
        canCreateWorkload,
        canDeleteWorkload,
        canViewWorkload,
        setuserRoles,
    
        // Add organization and provider fields here
        providerName,       
        orgData,           
        setOrgData, 
        setOrgId,       
        setArchitectures,
    
        workloadId,        // Workload ID
        aiArchitecture,    
        viewMode,          
        envName,           
        language,          
        archType,          
        business,          
        rulebook,          
        archDesc,          
    
        lastBuildID,       
        lastBuildDate,     
        lastBuildMessage,
    
        repoId,            
        repoName,
    
        PulseOrganization_id,
        PulseVertical_id,
        PulseVertical_name,
        PulseOrganization_name,
        PulseIdentification,
    
    } = useContext(OrgContext);
    const location = useLocation();
    const state = location.state;
    //PS_05          loadProviders()

    useEffect(() => {
        loadProviders()
        fetchAccountAndAssetCount()}
        // Call to fetch AWS services
        , []);

    useEffect(() => {
        if(cloudProvider){
        loadAwsServices(); // Call to fetch AWS services
        }}, [isDarkMode, offset,offset2]);

        useEffect(() => {
            if(state){
                console.log("kk",state);
                setCloudProvider(state.cardProviderName)
                loadAwsServices(state.cardProviderName)
            }
            }, []);



    useEffect(()=>{
        setLastSyncTime(null)
    },[cloudProvider])

    //PS_06
    const loadAwsServices = async (provider) => {
        // const payload = {
        //     userId: "c7d86e9d-4819-4f5e-95f8-782d4c6774a3",
        //     orgId: "28da4a71-14f5-47ce-a349-35840ebf2dcb",
        //     cloudProvider: cloudProvider,
        //     filterResourceName: "",
        //     filterResourceType: "",
        //     filterRegion: "",
        // };
        console.log("offset",offset2);
        const payload = {
            userId: userId, // Replace with actual user ID
            orgId:orgId, // Replace with actual organization ID
            limit: cloudProvider=="AWS"? offset.toString():offset2.toString(), // Convert limit to string
            offset:cloudProvider=="AWS"? offset.toString():offset2.toString(), // Convert offset to string
            cloudProvider: provider?provider:cloudProvider,
            filterResourceName: filters.resourceName, // Optional filters can be added here
            filterResourceType: filters.resourceType,
            filterRegion: filters.region,
            filterTenant: filters2.tenant,
            filterSubscription: filters2.subscription,
            filterResourceGroup: filters2.resourceGroup,
            filterTags: filters2.tags ||""
        };
    
        try {
            const response = await getAllAwsServicesApi(payload);
            if (response) {
                if(provider?provider=="AWS":cloudProvider=="AWS"){
                    console.log("inaws");
                    setawsServices(response.body);
                    setAWSURL(response?.body?.data?.aws_services?.url)
                    console.log("count",response.body.data.count);
                    setAWScount(response.body.data.count)
                    setFilters({
                        resourceName: '',
                        resourceType: '',
                        region: ''
                    });
                }else{
                    console.log("inazure");
                    setAzureServices(response.body)
                    setAzureURL(response.body.data.azure_services.url)
                    console.log("count2",response.body.data.count);
                    setAzureCount(response.body.data.count)
                    setFilters2({
                        tenant: '',
                        subscription: '',
                        resourceGroup: '',
                        tags: ''
                    });
                }              
                setLoading(false); // Set loading to false when data is fetched
            } else {
                setawsServices([])
                setAzureServices([])

            }
        } catch (error) {
            console.error('An error occurred while fetching AWS services:', error);
        }
    };
    //PS_08
    useEffect(() => {
        if (loading) return; // Skip if still loading
    
        console.log("AWS Services updated:", awsservices);
    
        // Fetching services from awsservices
        const fetchedServices = awsservices?.data?.aws_services?.service_details.map(item => {
            const service = awsservices?.data?.aws_services?.services[item.resource_type];
    
            return {
                resourceType: item.resource_type,
                resourceName: item.resource_name,
                account: item.account,
                region: item.region,
                totalCount: service ? service.total_count : 0,
                regions: service ? service.regions : {},
                service_icon:item.service_icon?item.service_icon: isDarkMode? 'images/aws-logo-dark.svg':'images/aws-logo.svg',
                count:item.count
            };
        });
    
        console.log("ssssss",fetchedServices);
        setServices(fetchedServices); // Load initial services
    
        // Fetching services from mockData2
        const fetchedServices2 = azureservices?.data?.azure_services?.service_details.map(item => {
            return {
                resourceType: item.resource_type,
                resourceName: item.resource_name || `resource-${item.resource_group}`,
                account: item.tenant_id || "N/A",
                region: item.region,
                totalCount: azureservices?.data?.azure_services?.services[item.resource_type] || 0,
                regions: { [item.region]: 1 },
                supscription:item.subscription_id,
                resourceGroup:item.resource_group,
                service_icon: item.service_icon?item.service_icon: 'images/azure-logo.svg', 
                 count:item.count

                
            };
        });
        if (azureservices.data && azureservices.data.azure_overview) {
            const overview = azureservices.data.azure_overview;
        
            // Create an array with the four specific counts
            const fetchedServices3 = [
                {
                    resource_group_count: overview.resource_group_count || "N/A",
                    resource_type_total: overview.resource_type_total || "N/A",
                    subscription_count: overview.subscription_count || "N/A",
                    tenant_count: overview.tenant_count || "N/A",
                }
            ];
        
            // Set the state or perform further actions with fetchedServices3
            setServices3(fetchedServices3);
        } else {
            console.error("Azure overview data is not available.");
        }
        console.log(fetchedServices2);
        setServices2(fetchedServices2); // Load initial services2
    
        // Set up automatic sliding for fetchedServices every 2 seconds
        const intervalId = setInterval(() => {
            if (fetchedServices.length > 9) {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % Math.ceil(fetchedServices.length / 9));
            }
        }, 5000);
    
        // Set up automatic sliding for fetchedServices2 every 20 seconds
        const intervalId2 = setInterval(() => {
            if (fetchedServices2.length > 9) {
                setCurrentIndex2((prevIndex) => (prevIndex + 1) % Math.ceil(fetchedServices2.length / 9));
            }
        }, 5000);
    
        // Cleanup function to clear both intervals on unmount
        return () => {
            clearInterval(intervalId);
            clearInterval(intervalId2);
        };
    }, [awsservices,azureservices, loading]); // Add loading as a dependency
    

    const [filters, setFilters] = useState({
        resourceName: '',
        resourceType: '',
        region: ''
    });

     // Extract unique values for filters
     const uniqueResourceNames = [...new Set(services?.map(service => service.resourceName))];
     const uniqueResourceTypes = [...new Set(services?.map(service => service.resourceType))];
     const uniqueRegions = [...new Set(services?.map(service => service.region))];
 
     const handleChange = (e) => {
         const { name, value } = e.target;
         setFilters(prevFilters => ({
             ...prevFilters,
             [name]: value
         }));
     };
 
     const clearFilters = () => {
        setTimeout(() => {
            const closeButton = document.getElementById('dropdownMenuClickableInside');
            if (closeButton) {
              closeButton.click();
            }
          }, 100);
         setFilters({
             resourceName: '',
             resourceType: '',
             region: ''
         });
         loadAwsServices()

     };
 
     const applyFilters = () => {
        setOffset(0)
        loadAwsServices()
        setTimeout(() => {
            const closeButton = document.getElementById('dropdownMenuClickableInside');
            if (closeButton) {
              closeButton.click();
            }
          }, 100);

    };

    const [filters2, setFilters2] = useState({
        tenant: '',
        subscription: '',
        resourceGroup: '',
        tags: ''
    });

     // Extract unique values for filters
     const uniqueTenants = [...new Set(services2.map(service => service.account))];
     const uniqueSubscriptions = [...new Set(services2.map(service => service.supscription || 'N/A'))]; // Adjust if subscription is part of your data
     const uniqueResourceGroups = [...new Set(services2.map(service => service.resourceGroup || 'N/A'))]; // Adjust if resourceGroup is part of your data
     const uniqueTags = [...new Set(services2.map(service => service.tags || 'N/A'))]; // Adjust if tags are part of your data
 
     const handleChange2 = (e) => {
         const { name, value } = e.target;
         setFilters2(prevFilters => ({
             ...prevFilters,
             [name]: value
         }));
     };
 
     const clearFilters2 = () => {
        
         setTimeout(() => {
            const closeButton = document.getElementById('customDropdownMenu');
            if (closeButton) {
              closeButton.click();
            }
          }, 100);
          setFilters2({
            tenant: '',
            subscription: '',
            resourceGroup: '',
            tags: ''
        });
        loadAwsServices()

     };
 
     const applyFilters2 = () => {
        setOffset2(0) 
         loadAwsServices()
         setTimeout(() => {
            const closeButton = document.getElementById('customDropdownMenu');
            if (closeButton) {
              closeButton.click();
            }
          }, 100);
     };
     const [accountCount, setAccountCount] = useState(null);
     const [assetCount, setAssetCount] = useState(null);

     const fetchAccountAndAssetCount = async () => {
      


        const payload = {
            userId:userId, // Replace with actual user ID
            orgId:orgId, // Replace with actual organization ID
        };

        try {
            const response = await getAccountCount(payload);
            if(response)
            {
                console.log("ing",response.data.body.data);
            setAccountCount(response.data.body.data.aws_accounts); // Assuming the response has accountCount
            setAssetCount(response.data.body.data.azure_accounts); // Assuming the response has assetCount
        }} catch (err) {
        } finally {
        }
    };


    console.log(AWScount,"This is the AWS Count!!!")
    console.log(AzureCount,"This is the azure count!!!")

    const loadProviders = async () => {
        console.log("checked providers");
        const payload = {
            userId :userId,
            orgId :orgId,
            type: "general"
        };
// PS_20
        try {
            const response = await getAllProvidersApi(payload); // Call the API function
            console.log("checked providers 2");

            if (response.success) {
                console.log('Providers fetched successfully:', response.data);
                setProviders(response.data); // Update state with fetched providers
                if(!state){
                // Find the provider ID for AWS and load workloads
                const awsProvider = response.data.find(provider => provider.providerName === "AWS");
                if (awsProvider) {
                    // setselectedProviderId(awsProvider.providerId); // Set selected provider ID
                    setCloudProvider(awsProvider.providerName); // Set cloud provider name
                    loadAwsServices(awsProvider.providerName)
                    // fetchWorkloads(awsProvider.providerId); // Load workloads for AWS
                }
            }else {
                console.error('Error fetching providers');
            }}
        } catch (error) {
            console.error('An error occurred while fetching providers:', error);
        }
    };
    //PS_09
    const handleLoadMore = () => {
        console.log("inn");
        setOffset(prevOffset => prevOffset + 10); // Increment offset for next fetch
    };
    const handleLoadMore2 = () => {
        console.log("inn2",offset);
        setOffset2(prevOffset => prevOffset + 10); // Increment offset for next fetch
    };
    const [searchQueryAWS, setSearchQueryAWS] = useState('');
    const [searchQueryAzure, setSearchQueryAzure] = useState('');

    //PS_10 Function to filter AWS services based on search query
    const filterAWSServices = () => {
        return awsservices?.data?.aws_services?.service_details.filter(item =>
            item.resource_name.toLowerCase().includes(searchQueryAWS.toLowerCase())
        );
    };

    //PS_11 Function to filter Azure services based on search query
    const filterAzureServices = () => {
        return azureservices.data.azure_services.service_details.filter(item =>
            item.resource_type.toLowerCase().includes(searchQueryAzure.toLowerCase())
        );
    };
//PS_12
    const handleExport = () => {
        const link = document.createElement('a');
        link.href = cloudProvider === 'AWS' ? AWSURL : AzureURL; // Use the appropriate URL based on the provider
        link.download = ''; // Optional filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const [sortField, setSortField] = useState(''); // Field to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'
    const [sortField2, setSortField2] = useState(''); // Field to sort by
    const [sortOrder2, setSortOrder2] = useState('asc'); // 'asc' or 'desc'
//PS_13
    const handleSort = (field) => {
        const newSortOrder = (sortField === field && sortOrder === 'asc') ? 'desc' : 'asc';
        setSortField(field);
        setSortOrder(newSortOrder);
    };
//PS_14
    const sortedData = () => {
        const filteredData = filterAzureServices();
        if (!sortField) return filteredData; // Return filtered data if no sort field is selected

        return [...filteredData].sort((a, b) => {
            const aValue = a[sortField];
            const bValue = b[sortField];

            if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
            if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
            return 0;
        });
    };
    //PS_15
    const handleSort2 = (field) => {
        const newSortOrder = (sortField2 === field && sortOrder2 === 'asc') ? 'desc' : 'asc';
        setSortField2(field);
        setSortOrder2(newSortOrder);
    };

  
//PS_16
    const sortedData2 = () => {
        const filteredData = filterAWSServices();
        if (!sortField2) return filteredData; // Return filtered data if no sort field is selected

        return [...filteredData].sort((a, b) => {
            const aValue = a[sortField2];
            const bValue = b[sortField2];

            if (aValue < bValue) return sortOrder2 === 'asc' ? -1 : 1;
            if (aValue > bValue) return sortOrder2 === 'asc' ? 1 : -1;
            return 0;
        });
    }


    useEffect(() => {
        function updateTimeAgo() {
          setTimeAgo(formatDistanceToNow(lastSyncTime, { addSuffix: true }));
        }
    
        updateTimeAgo();
        const timer = setInterval(updateTimeAgo, 30000); // Update every minute
    
        return () => clearInterval(timer);
      }, [lastSyncTime]);
    


    const syncNowClick = async () => {
        try {
          const payload = {
            orgId: orgId
          };

          if (cloudProvider=="AWS"){
            try {
                setLastSyncTime(new Date())
                const response = await syncNowAwsInventory(payload);
        
                if (response.data && response.data.success) {
                    loadAwsServices()

                } else {
                    loadAwsServices()

                  console.log("Unexpected tags response structure:", response);
                }
              } catch (error) {
                console.log("Error while syncing  Aws ")
              }
          }else{

            try {
                const response = await syncNowAzureInventory(payload);
                if (response.data && response.data.success) {
                    loadAwsServices()

                } else {
                    loadAwsServices()

                  console.log("Unexpected tags response structure:", response);
                }
              } catch (error) {
                console.log("Error while syncing  Azure ")
              }
          } 
        } catch (error) {
          console.log("Error fetching tags:", error);
        }
      };

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
          return text.substring(0, maxLength) + '...';
        }
        return text;
      };

    if (loading) {
        return <Loader />; // Show loader while loading
    }   
    return (
        <div class="container-fluid">
            <div class="dashboard-container ps-0">
        
        <div class="row">
        <SideBar/>
        <div class="col-md-9 px-5 right-container-scroll">
           
           
            {/* Inventory Section */}
            <div className="tab-pane fade show active" id="pills-Inventory" role="tabpanel" aria-labelledby="pills-Inventory-tab">
           
                <div className="mt-4">
                    <div className="my-4">
                        <div className="d-flex align-items-center justify-content-between pt-4 gap-3">
                            <h3 className="font-32 font-bold black-v2">Inventory</h3>
                            <div className="d-flex align-items-center gap-3">
                                <div className="account-container d-flex justify-content-between align-items-center">
                                    <p className="font-14 font-medium black-v5 mb-0">
                                        <img src="images/profile-icon.svg" alt="profile-icon" className="icon-width-24 me-2" /> #Accounts
                                    </p>
                                    <p className="mb-0 font-20 font-medium color-black">{cloudProvider=="AWS"?accountCount:assetCount}</p>
                                </div>
                                {/* Provider Dropdown */}
                              

                                <div className="dropdown">
                        
	<button 

		type="button" 
		className="btn custom-dropdown d-flex justify-content-between align-items-center gap-5 font-12 font-regular color-black shadow-none"
		id="dropdownMenuButton1" 
		data-bs-toggle="dropdown" 
		aria-expanded="false"
	>
		<span className="d-flex">
        <img 
    src={cloudProvider.toLowerCase() === 'aws' ? 
        (isDarkMode ? 'images/aws-logo-dark.svg' : 'images/aws-logo.svg') : 
        `images/${cloudProvider.toLowerCase()}-logo.svg`} 
    alt={`${cloudProvider}-img`} 
    className="me-1 icon-width" 
/>
			{cloudProvider}
		</span>
		<img src="images/drop-down-arrow.svg" alt="dropdown-arrow" />
	</button>
	<ul className="dropdown-menu body-bg access-dropdown w-100" aria-labelledby="dropdownMenuButton1">
	{providers.map((provider) => (
	<li 
		key={provider.providerId} // Use providerId as the key
		className="font-regular color-black font-12 p-2 custom-list"
		onClick={(event) => {
			event.preventDefault(); // Prevent default action
			setCloudProvider(provider.providerName); // Set cloudProvider to providerName
            loadAwsServices(provider.providerName);
            setOffset(0)
            setOffset2(0)
		

		}} 
	>
		<span className="d-flex align-items-center">
        <img 
    src={provider.providerName.toLowerCase() === 'aws' ? 
        (isDarkMode ? 'images/aws-logo-dark.svg' : 'images/aws-logo.svg') : 
        `images/${provider.providerName.toLowerCase()}-logo.svg`} 
    alt={`${provider.providerName}-img`} 
    className="me-1 icon-width" 
/>

			{provider.providerName}
		</span>
	</li>
))}
	</ul>
</div>
                                {/* Buttons */}
                                <button type="button" className="button secondary-outline-button text-nowrap"     onClick={handleExport}
                                >Export</button>
                                <button type="button" className="button primary-button text-nowrap" onClick={()=>{
                                    syncNowClick()
                                }}>Sync Now</button>
                            </div>
                        </div>

                        {/* Last Synced Info */}
                        <div className="d-flex justify-content-end mb-4 mt-3">
                            <p className="mb-0 font-10 font-medium secondary-text-color-v4">{lastSyncTime ?`Last Synced ${ timeAgo }` : ""}</p>
                        </div>
                       
                       <div className='table-responsive mt-4 '>
                       {cloudProvider === "AWS" ? 
                       (
                       <div>

                        {/* Services Section */}
                        <h5 className="font-20 font-bold color-black">Services</h5>

                        {/* Carousel Start */}
                        <div id="carouselExampleControls" className="carousel slide mt-4" data-bs-ride="carousel">
                            <div className="carousel-inner">
                                {/* Carousel Items */}
                                {services?.length > 0 ? (
    [...Array(Math.ceil(services.length / 9))].map((_, carouselIndex) => (
        <div key={carouselIndex} className={`carousel-item ${carouselIndex === currentIndex ? 'active' : ''}`}>
            <div className={`row px-4`}>
                {services.slice(carouselIndex * 9, carouselIndex * 9 + 9).map((service, index) => (
                    <div key={index} className={`col-md-4 py-36 px-5 custom-border-bottom border-right-position`}>
                        <div className={`dropdown`}>
                            <div 
                                className={`cursor-pointer w-100 shadow-none p-0 d-flex justify-content-between align-items-center`}
                                id={`dropdownMenu${service.resourceType}`} 
                                data-bs-toggle={`dropdown`} 
                                aria-expanded={`false`}
                            >
                                <p className={`mb-0 font-14 font-regular black-v5`}>
                                    <img
                                        src={service.service_icon} // Use the service_icon URL
                                        alt={service.resourceName} // Use resource name for accessibility
                                        className="me-3 custom-icon-width"
                                    />
                                    {service.resourceType}
                                </p>
                                <p className={`mb-0 font-20 font-bold color-black`}>
                                    {service.totalCount} {/* Display total count */}
                                </p>
                            </div>
                            {/* Dropdown Menu */}
                            <ul 
                                className={`dropdown-menu body-bg carousel-dropdown py-0`}
                                aria-labelledby={`dropdownMenu${service.resourceType}`}
                            >
                                {/* Example Sub-items - Replace with actual data as needed */}
                                {Object.entries(service.regions).map(([region, count], subIndex) => (
                                    <li key={subIndex} className={`w-100 custom-list px-4 py-2`}>
                                        <div className={`d-flex justify-content-between`}>
                                            <p className={`mb-0 font-12 font-regular black-v2`}>{region}</p>
                                            <p className={`mb-0 font-12 font-medium black-v2`}>{count}</p>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    ))
) : (
    // No data found message
    <div className="d-flex flex-column justify-content-center align-items-center custom-height">
        <img src="images/nodata-found.svg" alt="No Data Found" />
        <h6 className="color-black font-bold font-16 mt-5 mb-0">No Data Found!</h6>
        <p className="font-medium font-12 mt-4 mb-0 secondary-text-color-v5">
            Add new services to see the data here.
        </p>
    </div>
)}
                            </div>

                            {/* Carousel Controls */}
                            {services?.length > 9 && (
                                <>
                                    <button 
                                        className={`carousel-control-prev custom-arrow d-flex justify-content-start`}
                                        type={`button`} 
                                        data-bs-target={`#carouselExampleControls`}
                                        data-bs-slide={`prev`}
                                    >
                                        <span 
                                            className={`carousel-control-prev-icon custom-prev icon-filter`} 
                                            aria-hidden={`true`}
                                        ></span>
                                        <span className={`visually-hidden`}>Previous</span>
                                    </button>

                                    <button 
                                        className={`carousel-control-next custom-arrow d-flex justify-content-end`}
                                        type={`button`} 
                                        data-bs-target={`#carouselExampleControls`}
                                        data-bs-slide={`next`}
                                    >
                                        <span 
                                            className={`carousel-control-next-icon custom-next icon-filter`} 
                                            aria-hidden={`true`}
                                        ></span>
                                        <span className={`visually-hidden`}>Next</span>
                                    </button>
                                </>
                            )}
                        </div> {/* Carousel End */}

                       {/* Records Count Display */}
                       <div className={`d-flex justify-content-between align-items-center my-4`}>
                            <p 
                                className={`font16 font-medium color-black`}>
                                # of Record:
                                {services?.length} out of {services?.length}
                            </p>
                        </div>

                        <div className='d-flex justify-content-between align-items-center my-3'>
                            <h2 className='font-bold font-size-lg mb-lg black-v2'>Service Details</h2>
                            <div class="d-flex gap-3">
  
                            {/* Search Input */}
                            <div className="input-group input-search custom-border-box custom-search-box">                  <input
                    type="search"
                    className="px-3 form-control common-bg border-0 custom-search $gray-v1"
                    placeholder="Search AWS Services"
                    value={searchQueryAWS}
                    onChange={(e) => setSearchQueryAWS(e.target.value)}
                />
                <button className="btn border-start-0 custom-search" type="button">
                    <img src="images/search-icon.svg" alt="search" />
                </button>
            </div>


                            {/* Filter Button */}
                            {/* Add filter button code here if needed */}
                      
            {/* Filter Button */}
            <button
                type="button"
                className="filter-button px-2 position-relative"
                id="dropdownMenuClickableInside"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                aria-expanded="false"
            >
                <img src="images/filter-blue.svg" alt="filter-icon" />
            </button>

            {/* Dropdown Menu */}
            <div className="dropdown-menu dropdown-menu-end border-0 custom-shadow dd-position background-white p-4 custom-filter-width" aria-labelledby="dropdownMenuClickableInside">
            <h5 className="font-bold font-18 mb-0 color-black mb-4">Filter</h5>

            <div className="row">
                {/* Resource Name Dropdown */}
                <div className="col-md-6 mb-4">
                    <label className="form-label font-14 font-medium black-v2 mb-0" htmlFor="resourceNameSelect">Resource Name</label>
                    <select
                        id="resourceNameSelect"
                        name="resourceName"
                        className="form-select custom-dropdown shadow-none custom-border-box py-1 font-14 font-regular black-v2 custom-select"
                        value={filters.resourceName}
                        onChange={handleChange}
                    >
                        <option value="" disabled>Select</option>
                        {uniqueResourceNames?.map((name, index) => (
                            <option key={index} value={name}>{name}</option>
                        ))}
                    </select>
                </div>

                {/* Resource Type Dropdown */}
                <div className="col-md-6 mb-4">
                    <label className="form-label font-14 font-medium black-v2 mb-0" htmlFor="resourceTypeSelect">Resource Type</label>
                    <select
                        id="resourceTypeSelect"
                        name="resourceType"
                        className="form-select custom-dropdown shadow-none custom-border-box py-1 font-14 font-regular black-v2 custom-select"
                        value={filters.resourceType}
                        onChange={handleChange}
                    >
                        <option value="" disabled>Select</option>
                        {uniqueResourceTypes?.map((type, index) => (
                            <option key={index} value={type}>{type}</option>
                        ))}
                    </select>
                </div>

                {/* Region Dropdown */}
                <div className="col-md-6 mb-4">
                    <label className="form-label font-14 font-medium black-v2 mb-0" htmlFor="regionSelect">Region</label>
                    <select
                        id="regionSelect"
                        name="region"
                        className="form-select custom-dropdown shadow-none custom-border-box py-1 font-14 font-regular black-v2 custom-select"
                        value={filters.region}
                        onChange={handleChange}
                    >
                        <option value="" disabled>Select</option>
                        {uniqueRegions?.map((region, index) => (
                            <option key={index} value={region}>{region}</option>
                        ))}
                    </select>
                </div>

                {/* Buttons */}
                <div className="col-md-12">
                    <div className="d-flex justify-content-end align-items-center gap-3">
                        <button type="button" className="button outline-button px-4" onClick={clearFilters}>Clear All</button>
                        <button type="button" className={`btn button primary-button  ${!filters.resourceName && !filters.resourceType && !filters.region ? 'disabled' : ''}`} onClick={applyFilters}>Apply</button>
                    </div>
                </div>
            </div>

            {/* Close Icon */}
            {/* Add any close icon or functionality as needed */}
        </div>

        </div>

                        </div>  
                        {/* table-container heading end */}

                        {/* Table Start */}
                        <div className="table-responsive table-border">
            <table className="table custom-table">
            <thead>
            <tr>
                    <th className="position-relative text-start border-0" onClick={() => handleSort2('resource_type')}>
                        Resource Type
                        <span className="cursor-pointer margin-left-2">
                            <img 
                                src="./images/table-sort.svg" 
                                alt="sort" 
                                style={{
                                    transform: 
                                        sortField2 === "resource_type" && sortOrder2 === "asc" 
                                            ? "scaleY(-1)" // Flip icon for ascending order
                                            : "scaleY(1)",  // Normal position for descending order
                                    transition: "transform 0.2s ease-in-out", // Smooth transition
                                    cursor: 'pointer' // Pointer cursor for better UX
                                }} 
                            />
                        </span>
                    </th>
                    <th className="position-relative text-start border-0" onClick={() => handleSort2('resource_name')}>
                        Resource Name
                        <span className="cursor-pointer margin-left-2">
                            <img 
                                src="./images/table-sort.svg" 
                                alt="sort" 
                                style={{
                                    transform: 
                                        sortField2 === "resource_name" && sortOrder2 === "asc" 
                                            ? "scaleY(-1)" 
                                            : "scaleY(1)",  
                                    transition: "transform 0.2s ease-in-out",
                                    cursor: 'pointer'
                                }} 
                            />
                        </span>
                    </th>
                    <th className="position-relative text-start border-0" onClick={() => handleSort2('account')}>
                        Account
                        <span className="cursor-pointer margin-left-2">
                            <img 
                                src="./images/table-sort.svg" 
                                alt="sort" 
                                style={{
                                    transform: 
                                        sortField2 === "account" && sortOrder2 === "asc" 
                                            ? "scaleY(-1)" 
                                            : "scaleY(1)",  
                                    transition: "transform 0.2s ease-in-out",
                                    cursor: 'pointer'
                                }} 
                            />
                        </span>
                    </th>
                    <th className="position-relative text-start border-0" onClick={() => handleSort2('region')}>
                        Region
                        <span className="cursor-pointer margin-left-2">
                            <img 
                                src="./images/table-sort.svg" 
                                alt="sort" 
                                style={{
                                    transform: 
                                        sortField2 === "region" && sortOrder2 === "asc" 
                                            ? "scaleY(-1)" 
                                            : "scaleY(1)",  
                                    transition: "transform 0.2s ease-in-out",
                                    cursor: 'pointer'
                                }} 
                            />
                        </span>
                    </th>
                </tr>
                    </thead>
                <tbody className="manage-work">
                {sortedData2()?.length > 0 ? (
        sortedData2().map((item, index) => (
            <tr key={index}>
                <td className="text-start">{item.resource_type}</td>
                <td className="text-start">{item.resource_name}</td>
                <td className="text-start">{item.account}</td>
                <td className="text-start">{item.region}</td>
            </tr>
        ))
    ) : (
        // No records found message
        <tr>
            <td colSpan={4} className="text-center"> {/* Adjust colSpan based on the number of columns */}
                <div className="d-flex flex-column justify-content-center align-items-center custom-height">
                    <img src="images/nodata-found.svg" alt="No Data Found" />
                    <h6 className="color-black font-bold font-16 mt-5 mb-0">No Data Found!</h6>
                    <p className="font-medium font-12 mt-4 mb-0 secondary-text-color-v5">
                        Add new data to see the data here.
                    </p>
                </div>
            </td>
        </tr>
    )}
                </tbody>
            </table>

                        </div>  
                        {/* Table End */}

                        {/* Records Count Display for Service Details*/}
                      <div className={`d-flex justify-content-between align-items-center my-4`}>
    {services?.length > 0 && (
        <>
            {services?.length > 9 && (
                <p className={`font16 font-medium color-black`}>
                    # of Record: {services.length} out of {AWScount}
                </p>
            )}
        </>
    )}
</div>

                      {/* Load More Button for Service Details Only*/}
                      {(AWScount > (Number(offset) + 10) ) &&  (
                <div className="d-flex justify-content-center my-2">
                    <button 
                        type="button" 
                        className="button secondary-outline-button black-v2" 
                        onClick={()=>{handleLoadMore()}}
                    >
                        Load More
                    </button>
                </div>
            )}
                    </div>
                    )
                    :
                    (
                    <>
     <div className="row">
    <div className="col-md-3 py-36 px-5 border-right-position-1">
        <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex flex-column">
                <img src="images/Resource-Groups.svg" alt="Resource Group" className="mb-2 icon-width-30" />
                <p className="mb-0 font-14 font-regular black-v5">Resource Group</p>
            </div>
            <p className="mb-0 font-20 font-bold color-black">{services3[0]?.resource_group_count || "N/A"}</p>
        </div>
    </div>
    <div className="col-md-3 py-36 px-5 border-right-position-1">
        <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex flex-column">
                <img src="images/subscriptions.svg" alt="Subscription" className="mb-2 icon-width-30" />
                <p className="mb-0 font-14 font-regular black-v5">Subscription</p>
            </div>
            <p className="mb-0 font-20 font-bold color-black">{services3[0]?.subscription_count || "N/A"}</p>
        </div>
    </div>
    <div className="col-md-3 py-36 px-5 border-right-position-1">
        <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex flex-column">
                <img src="images/tenent.svg" alt="Tenant" className="mb-2 icon-width-30" />
                <p className="mb-0 font-14 font-regular black-v5">Tenant</p>
            </div>
            <p className="mb-0 font-20 font-bold color-black">{services3[0]?.tenant_count || "N/A"}</p>
        </div>
    </div>
    <div className="col-md-3 py-36 px-5 border-right-position-1">
        <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex flex-column">
                <img src="images/all-resources.svg" alt="Resource Type" className="mb-2 icon-width-30" />
                <p className="mb-0 font-14 font-regular black-v5">Resource Type</p>
            </div>
            <p className="mb-0 font-20 font-bold color-black">{services3[0]?.resource_type_total || "N/A"}</p>
        </div>
    </div>
</div>


{/* Services Section */}
<h5 className="font-20 font-bold color-black">Services</h5>

{/* Carousel Start */}
<div id="carouselExampleControls" className="carousel slide mt-4" data-bs-ride="carousel">
    <div className="carousel-inner">
        {/* Carousel Items */}
        {services2?.length > 0 ? (
    [...Array(Math.ceil(services2.length / 9))].map((_, carouselIndex) => (
        <div key={carouselIndex} className={`carousel-item ${carouselIndex === currentIndex2 ? 'active' : ''}`}>
            <div className={`row px-4`}>
                {services2.slice(carouselIndex * 9, carouselIndex * 9 + 9).map((service, index) => (
                    <div key={index} className={`col-md-4 py-36 px-5 custom-border-bottom border-right-position`}>
                        <div className={`dropdown`}>
                            <div 
                                className={`cursor-pointer w-100 shadow-none p-0 d-flex justify-content-between align-items-center`}
                                id={`dropdownMenu${service.resourceType}`} 
                                data-bs-toggle={`dropdown`} 
                                aria-expanded={`false`}
                            >
                                     <p
          key={service.resourceName}
          className="mb-0 font-14 font-regular black-v5"
          title={service.resourceType.length > 10 ? service.resourceType : ''} // Add title conditionally
        >
          <img
            src={service.service_icon} // Use the service_icon URL
            alt={service.resourceName} // Use resource name for accessibility
            className="me-3 custom-icon-width"
          />
          {truncateText(service.resourceType, 10)}
        </p>
                                <p className={`mb-0 font-20 font-bold color-black`}>
                                    {service.totalCount} {/* Display total count */}
                                </p>
                            </div>
                            {/* Dropdown Menu */}
                            <ul 
                                className={`dropdown-menu body-bg carousel-dropdown py-0`}
                                aria-labelledby={`dropdownMenu${service.resourceType}`}
                            >
                                {/* Example Sub-items - Replace with actual data as needed */}
                                {Object.entries(service?.regions)?.map(([region, count], subIndex) => (
                                    <li key={subIndex} className={`w-100 custom-list px-4 py-2`}>
                                        <div className={`d-flex justify-content-between`}>
                                            <p className={`mb-0 font-12 font-regular black-v2`}>{region}</p>
                                            <p className={`mb-0 font-12 font-medium black-v2`}>{count}</p>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    ))
) : (
    // No data found message
    <div className="d-flex flex-column justify-content-center align-items-center custom-height">
        <img src="images/nodata-found.svg" alt="No Data Found" />
        <h6 className="color-black font-bold font-16 mt-5 mb-0">No Data Found!</h6>
        <p className="font-medium font-12 mt-4 mb-0 secondary-text-color-v5">
            Add new services to see the data here.
        </p>
    </div>
)}
    </div>

    {/* Carousel Controls */}
       {services2?.length > 9 && (
                                <>
                                    <button 
                                        className={`carousel-control-prev custom-arrow d-flex justify-content-start`}
                                        type={`button`} 
                                        data-bs-target={`#carouselExampleControls`}
                                        data-bs-slide={`prev`}
                                    >
                                        <span 
                                            className={`carousel-control-prev-icon custom-prev icon-filter`} 
                                            aria-hidden={`true`}
                                        ></span>
                                        <span className={`visually-hidden`}>Previous</span>
                                    </button>

                                    <button 
                                        className={`carousel-control-next custom-arrow d-flex justify-content-end`}
                                        type={`button`} 
                                        data-bs-target={`#carouselExampleControls`}
                                        data-bs-slide={`next`}
                                    >
                                        <span 
                                            className={`carousel-control-next-icon custom-next icon-filter`} 
                                            aria-hidden={`true`}
                                        ></span>
                                        <span className={`visually-hidden`}>Next</span>
                                    </button>
                                </>
                            )}
</div> {/* Carousel End */}

{/* Records Count Display */}
<div className={`d-flex justify-content-between align-items-center my-4`}>
    <p 
        className={`font16 font-medium color-black`}>
        # of Record:
        {services2?.length} out of {services2?.length}
    </p>
</div>

<div className='d-flex justify-content-between align-items-center my-3'>
    <h2 className='font-bold font-size-lg mb-lg black-v2'>Service Details</h2>
    <div class="d-flex gap-3">

    {/* Search Input */}
    <div className="input-group input-search custom-border-box custom-search-box">  <input
                    type="search"
                    className="px-3 form-control common-bg border-0 custom-search $gray-v1"
                    placeholder="Search Azure Services"
                    value={searchQueryAzure}
                    onChange={(e) => setSearchQueryAzure(e.target.value)}
                />
                <button className="btn border-start-0 custom-search" type="button">
                    <img src="images/search-icon.svg" alt="search" />
                </button>

</div>


    {/* Filter Button */}
    {/* Add filter button code here if needed */}

{/* Filter Button */}

{/* Dropdown Menu */}
<button 
    type="button" 
    className="filter-button px-2 position-relative" 
    id="customDropdownMenu"  
    data-bs-toggle="dropdown" 
    data-bs-auto-close="outside" 
    aria-expanded="false"
>
    <img src="images/filter-blue.svg" alt="filter-icon" />
</button>

<div className="dropdown-menu dropdown-menu-end border-0 custom-shadow background-white dd-position p-4 custom-filter-width" aria-labelledby="customDropdownMenu">
            <h5 className="font-bold font-18 mb-0 color-black mb-4">Filter</h5>

            <div className="row">
                {/* Tenant Dropdown */}
                <div className="col-md-6 mb-4">
                    <label className="form-label font-14 font-medium black-v2 mb-0" htmlFor="tenantSelect">Tenant</label>
                    <select
                        className="form-select custom-dropdown shadow-none custom-border-box py-1 font-14 font-regular black-v2 custom-select"
                        aria-label="Default select example" 
                        id="tenantSelect"
                        name="tenant"  
                        value={filters2.tenant}  
                        onChange={handleChange2}  
                    > 
                        <option value="" disabled>Select</option>
                        {uniqueTenants?.map((tenant, index) => (
                            <option key={index} value={tenant}>{tenant}</option>
                        ))}
                    </select>
                </div>

                {/* Subscription Dropdown */}
                <div className="col-md-6 mb-4">
                    <label className="form-label font-14 font-medium black-v2 mb-0" htmlFor="subscriptionSelect">Subscription</label>
                    <select
                        className="form-select custom-dropdown shadow-none form-field-border py-1 font-14 font-regular black-v2 custom-select"
                        aria-label="Default select example" 
                        id="subscriptionSelect"
                        name="subscription"  
                        value={filters2.subscription}  
                        onChange={handleChange2}  
                    >
                        <option value="" disabled>Select</option>
                        {uniqueSubscriptions?.map((subscription, index) => (
                            <option key={index} value={subscription}>{subscription}</option>
                        ))}
                    </select>
                </div>

                {/* Resource Group Dropdown */}
                <div className="col-md-6 mb-4">
                    <label className="form-label font-14 font-medium black-v2 mb-0" htmlFor="resourceGroupSelect">Resource Group</label>
                    <select
                        className="form-select custom-dropdown shadow-none form-field-border py-1 font-14 font-regular black-v2 custom-select"
                        aria-label="Default select example" 
                        id="resourceGroupSelect"
                        name="resourceGroup"  
                        value={filters2.resourceGroup}  
                        onChange={handleChange2}  
                    > 
                        <option value="" disabled>Select</option>
                        {uniqueResourceGroups?.map((group, index) => (
                            <option key={index} value={group}>{group}</option>
                        ))}
                    </select>
                </div>

                {/* Tags Dropdown */}
                <div className="col-md-6 mb-4">
                    <label className="form-label font-14 font-medium black-v2 mb-0" htmlFor="tagsSelect">Tags</label>
                    <select
                        className="form-select custom-dropdown shadow-none form-field-border py-1 font-14 font-regular black-v2 custom-select"
                        aria-label="Default select example" 
                        id="tagsSelect"
                        name="tags"  
                        value={filters2.tags}  
                        onChange={handleChange2}  
                    > 
                        <option value="" disabled>Select</option>
                        {uniqueTags?.map((tag, index) => (
                            <option key={index} value={tag}>{tag}</option>
                        ))}
                    </select>
                </div>

                {/* Buttons */}
                <div className="col-md-12">
                    <div className="d-flex justify-content-end align-items-center gap-3">
                        <button type="button" className="button outline-button px-4" onClick={clearFilters2}>Clear All</button>
                        <button type="button"  className={`btn button primary-button
                             ${!filters2.tenant && !filters2.subscription && !filters2.resourceGroup? 'disabled' : ''}`
                             } onClick={()=>{applyFilters2()}}>Apply</button>
                    </div>
                </div>
            </div>
        </div>


        </div>

</div>  
{/* table-container heading end */}

{/* Table Start */}


            {/* Table */}
            <div className="table-responsive table-border">
                <table className="table custom-table">
                    <thead>
                    <tr>
                    <th className="position-relative text-start border-0" onClick={() => handleSort('tenant_id')}>
                        Tenant
                        <span className="cursor-pointer margin-left-2">
                            <img 
                                src="./images/table-sort.svg" 
                                alt="sort" 
                                style={{
                                    transform: 
                                        sortField === "tenant_id" && sortOrder === "asc" 
                                            ? "scaleY(-1)" // Flip icon for ascending order
                                            : "scaleY(1)",  // Normal position for descending order
                                    transition: "transform 0.2s ease-in-out", // Smooth transition
                                    cursor: 'pointer' // Pointer cursor for better UX
                                }} 
                            />
                        </span>
                    </th>
                    <th className="position-relative text-start border-0" onClick={() => handleSort('subscription_id')}>
                        Subscription
                        <span className="cursor-pointer margin-left-2">
                            <img 
                                src="./images/table-sort.svg" 
                                alt="sort" 
                                style={{
                                    transform: 
                                        sortField === "subscription_id" && sortOrder === "asc" 
                                            ? "scaleY(-1)" 
                                            : "scaleY(1)",  
                                    transition: "transform 0.2s ease-in-out",
                                    cursor: 'pointer'
                                }} 
                            />
                        </span>
                    </th>
                    <th className="position-relative text-start border-0" onClick={() => handleSort('resource_group')}>
                        Resource Group
                        <span className="cursor-pointer margin-left-2">
                            <img 
                                src="./images/table-sort.svg" 
                                alt="sort" 
                                style={{
                                    transform: 
                                        sortField === "resource_group" && sortOrder === "asc" 
                                            ? "scaleY(-1)" 
                                            : "scaleY(1)",  
                                    transition: "transform 0.2s ease-in-out",
                                    cursor: 'pointer'
                                }} 
                            />
                        </span>
                    </th>
                    <th className="position-relative text-start border-0" onClick={() => handleSort('resource_type')}>
                        Resource Type
                        <span className="cursor-pointer margin-left-2">
                            <img 
                                src="./images/table-sort.svg" 
                                alt="sort" 
                                style={{
                                    transform: 
                                        sortField === "resource_type" && sortOrder === "asc" 
                                            ? "scaleY(-1)" 
                                            : "scaleY(1)",  
                                    transition: "transform 0.2s ease-in-out",
                                    cursor: 'pointer'
                                }} 
                            />
                        </span>
                    </th>
                    <th className="position-relative text-start border-0" onClick={() => handleSort('region')}>
                        Region
                        <span className="cursor-pointer margin-left-2">
                            <img 
                                src="./images/table-sort.svg" 
                                alt="sort" 
                                style={{
                                    transform: 
                                        sortField === "region" && sortOrder === "asc" 
                                            ? "scaleY(-1)" 
                                            : "scaleY(1)",  
                                    transition: "transform 0.2s ease-in-out",
                                    cursor: 'pointer'
                                }} 
                            />
                        </span>
                    </th>
                </tr>
                    </thead>
                    <tbody className="manage-work">
                    {sortedData()?.length > 0 ? (
        sortedData().map((item, index) => (
            <tr key={index}>
                <td className="text-start">{item.tenant_id || "N/A"}</td>
                <td className="text-start">{item.subscription_id || "N/A"}</td>
                <td className="text-start">{item.resource_group || "N/A"}</td>
                <td className="text-start">{item.resource_type || "N/A"}</td>
                <td className="text-start">{item.region || "N/A"}</td>
            </tr>
        ))
    ) : (
        // No records found message
        <tr>
            <td colSpan={5} className="text-center"> {/* Adjust colSpan based on the number of columns */}
                <div className="d-flex flex-column justify-content-center align-items-center custom-height">
                    <img src="images/nodata-found.svg" alt="No Data Found" />
                    <h6 className="color-black font-bold font-16 mt-5 mb-0">No Data Found!</h6>
                    <p className="font-medium font-12 mt-4 mb-0 secondary-text-color-v5">
                        Add new data to see the data here.
                    </p>
                </div>
            </td>
        </tr>
    )}
                    </tbody>
                </table>
            </div> 
{/* Table End */}

{/* Records Count Display for Service Details*/}
<div className={`d-flex justify-content-between align-items-center my-4`}>
    {services2.length > 0 && (
        <>
            {services2.length > 9 && (
                <p className={`font16 font-medium color-black`}>
                    # of Record: {services2.length} out of {AzureCount}
                </p>
            )}
        </>
    )}
</div>

{/*  More Button for Service Details Only*/}
{(AzureCount > (Number(offset2) + 10) ) &&  (
                <div className="d-flex justify-content-center my-2">
                    <button 
                        type="button" 
                        className="button secondary-outline-button black-v2" 
                        onClick={()=>{handleLoadMore2()}}
                    >
                        Load More
                    </button>
                </div>
            )}
</>)}</div>  
                    </div>
                    </div> {/* End of my4 */}
                </div>
                </div>
         </div>
         </div>
      </div>
    );
};

export default ServicesGridPage;