

export default function AWS_SDK_RDS_MYSQL({ values, onChangeOfConfiguration, isViewer }) {
    console.log("000000000000000000000000000000000000000000000000000")
    try {
        console.log("sagin+++++++RDS", values)
        return (

            <div
                class="accordion-body p-0 estimation-container">
                <div className="row py-4 mx-4 px-5">
                    <div className="col-md-6 px-3">
                        <label htmlFor="region1" className="form-label font-14 font-medium black-v2 mb-3">
                            Region
                        </label>
                        <select
                            className="form-select custom-form font-semibold font-14 form-select-sm"
                            id="region"
                            value={values.region}
                            style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                            name={"region"}
                            onChange={(e) => {
                                onChangeOfConfiguration(e, values.instanceName);
                            }} disabled={isViewer}
                        >
                            <option value="ap-south-2">ap-south-2</option><option value="ap-southeast-3">ap-southeast-3</option><option value="ap-southeast-1">ap-southeast-1</option><option value="us-gov-east-1">us-gov-east-1</option><option value="eu-west-2">eu-west-2</option><option value="af-south-1">af-south-1</option><option value="ap-northeast-1">ap-northeast-1</option><option value="ap-southeast-2">ap-southeast-2</option><option value="us-east-2">us-east-2</option><option value="eu-central-1">eu-central-1</option><option value="ca-central-1">ca-central-1</option><option value="ap-south-1">ap-south-1</option><option value="ap-northeast-3">ap-northeast-3</option><option value="sa-east-1">sa-east-1</option><option value="ap-east-1">ap-east-1</option><option value="eu-west-1">eu-west-1</option><option value="eu-south-1">eu-south-1</option><option value="ap-southeast-4">ap-southeast-4</option><option value="eu-north-1">eu-north-1</option><option value="eu-south-2">eu-south-2</option><option value="us-west-1">us-west-1</option><option value="us-west-2">us-west-2</option><option value="me-south-1">me-south-1</option><option value="eu-central-2">eu-central-2</option><option value="us-gov-west-1">us-gov-west-1</option><option value="ap-northeast-2">ap-northeast-2</option><option value="me-central-1">me-central-1</option><option value="us-east-1">us-east-1</option><option value="eu-west-3">eu-west-3</option>
                        </select>
                    </div>


                    {/* this is for the unit for storage amount */}
                    <div className="col-md-6 px-3">
                        <label htmlFor="sqltype" className="form-label font-14 font-medium black-v2 mb-3">
                            SQL Type
                        </label>
                        <select

                            className="form-select custom-form font-semibold font-14 form-select-sm mb-3"
                            id="sqltype"
                            value={values.sqltype}
                            name={"sqltype"}
                            style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                            onChange={(e) => {
                                console.log("sagin", e)
                                onChangeOfConfiguration(e, values.instanceName);
                            }} disabled={isViewer}
                        >
                            <option value="mysql">Mysql</option>
                            <option value="aurora_mysql">Aurora Mysql</option>
                        </select>
                    </div>

                    {values.sqltype == 'mysql' ? (
                        <>
                            {/* shiram start */}
                            <label style={{  paddingBottom: '10px' }}>MySQL instance specifications</label>

                            <div className="col-md-6 px-3">
                                <label
                                    htmlFor="noOfNodes1"
                                    className="form-label font-14 font-medium black-v2 mb-3"
                                >
                                    Nodes
                                </label>
                                <input
                                    type="text"
                                    style={{paddingTop : "10px"}}
                                    className="form-control custom-form text-field font-14 font-medium"
                                    id="noOfNodes1"
                                    value={values.noOfNodes1}
                                    name={"noOfNodes1"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                />
                            </div>

                            {values.EngineType === "MySQL" && <div className="col-md-6 px-3">
                                <label htmlFor="instanceType1" className="form-label font-14 font-medium black-v2 mb-3">
                                    Instance Type
                                </label>
                                <select
                                style={{paddingTop : "10px"}}
                                    className="form-select custom-form font-semibold font-14 form-select-sm"
                                    id="instanceType1"
                                    value={values.instanceType1}
                                    name={"instanceType1"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                >
                                    <option value="db.m1.large">db.m1.large</option>
                                    <option value="db.m1.medium">db.m1.medium</option>
                                    <option value="db.m1.small">db.m1.small</option>
                                    <option value="db.m1.xlarge">db.m1.xlarge</option>
                                    <option value="db.m2.2xlarge">db.m2.2xlarge</option>
                                    <option value="db.m2.4xlarge">db.m2.4xlarge</option>
                                    <option value="db.m2.xlarge">db.m2.xlarge</option>
                                    <option value="db.m3.2xlarge">db.m3.2xlarge</option>
                                    <option value="db.m3.large">db.m3.large</option>
                                    <option value="db.m3.medium">db.m3.medium</option>
                                    <option value="db.m3.xlarge">db.m3.xlarge</option>
                                    <option value="db.m4.10xlarge">db.m4.10xlarge</option>
                                    <option value="db.m4.16xlarge">db.m4.16xlarge</option>
                                    <option value="db.m4.2xlarge">db.m4.2xlarge</option>
                                    <option value="db.m4.4xlarge">db.m4.4xlarge</option>
                                    <option value="db.m4.large">db.m4.large</option>
                                    <option value="db.m4.xlarge">db.m4.xlarge</option>
                                    <option value="db.m5.12xlarge">db.m5.12xlarge</option>
                                    <option value="db.m5.16xlarge">db.m5.16xlarge</option>
                                    <option value="db.m5.24xlarge">db.m5.24xlarge</option>
                                    <option value="db.m5.2xlarge">db.m5.2xlarge</option>
                                    <option value="db.m5.4xlarge">db.m5.4xlarge</option>
                                    <option value="db.m5.8xlarge">db.m5.8xlarge</option>
                                    <option value="db.m5.large">db.m5.large</option>
                                    <option value="db.m5.xlarge">db.m5.xlarge</option>
                                    <option value="db.m5d.12xlarge">db.m5d.12xlarge</option>
                                    <option value="db.m5d.16xlarge">db.m5d.16xlarge</option>
                                    <option value="db.m5d.24xlarge">db.m5d.24xlarge</option>
                                    <option value="db.m5d.2xlarge">db.m5d.2xlarge</option>
                                    <option value="db.m5d.4xlarge">db.m5d.4xlarge</option>
                                    <option value="db.m5d.8xlarge">db.m5d.8xlarge</option>
                                    <option value="db.m5d.large">db.m5d.large</option>
                                    <option value="db.m5d.xlarge">db.m5d.xlarge</option>
                                    <option value="db.m6g.12xlarge">db.m6g.12xlarge</option>
                                    <option value="db.m6g.16xlarge">db.m6g.16xlarge</option>
                                    <option value="db.m6g.2xlarge">db.m6g.2xlarge</option>
                                    <option value="db.m6g.4xlarge">db.m6g.4xlarge</option>
                                    <option value="db.m6g.8xlarge">db.m6g.8xlarge</option>
                                    <option value="db.m6g.large">db.m6g.large</option>
                                    <option value="db.m6g.xlarge">db.m6g.xlarge</option>
                                    <option value="db.m6gd.12xlarge">db.m6gd.12xlarge</option>
                                    <option value="db.m6gd.16xlarge">db.m6gd.16xlarge</option>
                                    <option value="db.m6gd.2xlarge">db.m6gd.2xlarge</option>
                                    <option value="db.m6gd.4xlarge">db.m6gd.4xlarge</option>
                                    <option value="db.m6gd.8xlarge">db.m6gd.8xlarge</option>
                                    <option value="db.m6gd.large">db.m6gd.large</option>
                                    <option value="db.m6gd.xlarge">db.m6gd.xlarge</option>
                                    <option value="db.m6i.12xlarge">db.m6i.12xlarge</option>
                                    <option value="db.m6i.16xlarge">db.m6i.16xlarge</option>
                                    <option value="db.m6i.24xlarge">db.m6i.24xlarge</option>
                                    <option value="db.m6i.2xlarge">db.m6i.2xlarge</option>
                                    <option value="db.m6i.32xlarge">db.m6i.32xlarge</option>
                                    <option value="db.m6i.4xlarge">db.m6i.4xlarge</option>
                                    <option value="db.m6i.8xlarge">db.m6i.8xlarge</option>
                                    <option value="db.m6i.large">db.m6i.large</option>
                                    <option value="db.m6i.xlarge">db.m6i.xlarge</option>
                                    <option value="db.m7g.12xlarge">db.m7g.12xlarge</option>
                                    <option value="db.m7g.16xlarge">db.m7g.16xlarge</option>
                                    <option value="db.m7g.2xlarge">db.m7g.2xlarge</option>
                                    <option value="db.m7g.4xlarge">db.m7g.4xlarge</option>
                                    <option value="db.m7g.8xlarge">db.m7g.8xlarge</option>
                                    <option value="db.m7g.large">db.m7g.large</option>
                                    <option value="db.m7g.xlarge">db.m7g.xlarge</option>
                                    <option value="db.r3.2xlarge">db.r3.2xlarge</option>
                                    <option value="db.r3.4xlarge">db.r3.4xlarge</option>
                                    <option value="db.r3.8xlarge">db.r3.8xlarge</option>
                                    <option value="db.r3.large">db.r3.large</option>
                                    <option value="db.r3.xlarge">db.r3.xlarge</option>
                                    <option value="db.r4.16xlarge">db.r4.16xlarge</option>
                                    <option value="db.r4.2xlarge">db.r4.2xlarge</option>
                                    <option value="db.r4.4xlarge">db.r4.4xlarge</option>
                                    <option value="db.r4.8xlarge">db.r4.8xlarge</option>
                                    <option value="db.r4.large">db.r4.large</option>
                                    <option value="db.r4.xlarge">db.r4.xlarge</option>
                                    <option value="db.r5.12xlarge">db.r5.12xlarge</option>
                                    <option value="db.r5.12xlarge.tpc2.mem2x">db.r5.12xlarge.tpc2.mem2x</option>
                                    <option value="db.r5.16xlarge">db.r5.16xlarge</option>
                                    <option value="db.r5.24xlarge">db.r5.24xlarge</option>
                                    <option value="db.r5.2xlarge">db.r5.2xlarge</option>
                                    <option value="db.r5.2xlarge.tpc1.mem2x">db.r5.2xlarge.tpc1.mem2x</option>
                                    <option value="db.r5.2xlarge.tpc2.mem4x">db.r5.2xlarge.tpc2.mem4x</option>
                                    <option value="db.r5.2xlarge.tpc2.mem8x">db.r5.2xlarge.tpc2.mem8x</option>
                                    <option value="db.r5.4xlarge">db.r5.4xlarge</option>
                                    <option value="db.r5.4xlarge.tpc2.mem2x">db.r5.4xlarge.tpc2.mem2x</option>
                                    <option value="db.r5.4xlarge.tpc2.mem3x">db.r5.4xlarge.tpc2.mem3x</option>
                                    <option value="db.r5.4xlarge.tpc2.mem4x">db.r5.4xlarge.tpc2.mem4x</option>
                                    <option value="db.r5.6xlarge.tpc2.mem4x">db.r5.6xlarge.tpc2.mem4x</option>
                                    <option value="db.r5.8xlarge">db.r5.8xlarge</option>
                                    <option value="db.r5.8xlarge.tpc2.mem3x">db.r5.8xlarge.tpc2.mem3x</option>
                                    <option value="db.r5.large">db.r5.large</option>
                                    <option value="db.r5.large.tpc1.mem2x">db.r5.large.tpc1.mem2x</option>
                                    <option value="db.r5.xlarge">db.r5.xlarge</option>
                                    <option value="db.r5.xlarge.tpc2.mem2x">db.r5.xlarge.tpc2.mem2x</option>
                                    <option value="db.r5.xlarge.tpc2.mem4x">db.r5.xlarge.tpc2.mem4x</option>
                                    <option value="db.r5b.12xlarge">db.r5b.12xlarge</option>
                                    <option value="db.r5b.12xlarge.tpc2.mem2x">db.r5b.12xlarge.tpc2.mem2x</option>
                                    <option value="db.r5b.16xlarge">db.r5b.16xlarge</option>
                                    <option value="db.r5b.24xlarge">db.r5b.24xlarge</option>
                                    <option value="db.r5b.2xlarge">db.r5b.2xlarge</option>
                                    <option value="db.r5b.2xlarge.tpc1.mem2x">db.r5b.2xlarge.tpc1.mem2x</option>
                                    <option value="db.r5b.2xlarge.tpc2.mem4x">db.r5b.2xlarge.tpc2.mem4x</option>
                                    <option value="db.r5b.2xlarge.tpc2.mem8x">db.r5b.2xlarge.tpc2.mem8x</option>
                                    <option value="db.r5b.4xlarge">db.r5b.4xlarge</option>
                                    <option value="db.r5b.4xlarge.tpc2.mem2x">db.r5b.4xlarge.tpc2.mem2x</option>
                                    <option value="db.r5b.4xlarge.tpc2.mem3x">db.r5b.4xlarge.tpc2.mem3x</option>
                                    <option value="db.r5b.4xlarge.tpc2.mem4x">db.r5b.4xlarge.tpc2.mem4x</option>
                                    <option value="db.r5b.6xlarge.tpc2.mem4x">db.r5b.6xlarge.tpc2.mem4x</option>
                                    <option value="db.r5b.8xlarge">db.r5b.8xlarge</option>
                                    <option value="db.r5b.8xlarge.tpc2.mem3x">db.r5b.8xlarge.tpc2.mem3x</option>
                                    <option value="db.r5b.large">db.r5b.large</option>
                                    <option value="db.r5b.large.tpc1.mem2x">db.r5b.large.tpc1.mem2x</option>
                                    <option value="db.r5b.xlarge">db.r5b.xlarge</option>
                                    <option value="db.r5b.xlarge.tpc2.mem2x">db.r5b.xlarge.tpc2.mem2x</option>
                                    <option value="db.r5b.xlarge.tpc2.mem4x">db.r5b.xlarge.tpc2.mem4x</option>
                                    <option value="db.r5d.12xlarge">db.r5d.12xlarge</option>
                                    <option value="db.r5d.16xlarge">db.r5d.16xlarge</option>
                                    <option value="db.r5d.24xlarge">db.r5d.24xlarge</option>
                                    <option value="db.r5d.2xlarge">db.r5d.2xlarge</option>
                                    <option value="db.r5d.4xlarge">db.r5d.4xlarge</option>
                                    <option value="db.r5d.8xlarge">db.r5d.8xlarge</option>
                                    <option value="db.r5d.large">db.r5d.large</option>
                                    <option value="db.r5d.xlarge">db.r5d.xlarge</option>
                                    <option value="db.r6g.12xlarge">db.r6g.12xlarge</option>
                                    <option value="db.r6g.16xlarge">db.r6g.16xlarge</option>
                                    <option value="db.r6g.2xlarge">db.r6g.2xlarge</option>
                                    <option value="db.r6g.4xlarge">db.r6g.4xlarge</option>
                                    <option value="db.r6g.8xlarge">db.r6g.8xlarge</option>
                                    <option value="db.r6g.large">db.r6g.large</option>
                                    <option value="db.r6g.xlarge">db.r6g.xlarge</option>
                                    <option value="db.r6gd.12xlarge">db.r6gd.12xlarge</option>
                                    <option value="db.r6gd.16xlarge">db.r6gd.16xlarge</option>
                                    <option value="db.r6gd.2xlarge">db.r6gd.2xlarge</option>
                                    <option value="db.r6gd.4xlarge">db.r6gd.4xlarge</option>
                                    <option value="db.r6gd.8xlarge">db.r6gd.8xlarge</option>
                                    <option value="db.r6gd.large">db.r6gd.large</option>
                                    <option value="db.r6gd.xlarge">db.r6gd.xlarge</option>
                                    <option value="db.r6i.12xlarge">db.r6i.12xlarge</option>
                                    <option value="db.r6i.16xlarge">db.r6i.16xlarge</option>
                                    <option value="db.r6i.24xlarge">db.r6i.24xlarge</option>
                                    <option value="db.r6i.2xlarge">db.r6i.2xlarge</option>
                                    <option value="db.r6i.32xlarge">db.r6i.32xlarge</option>
                                    <option value="db.r6i.4xlarge">db.r6i.4xlarge</option>
                                    <option value="db.r6i.8xlarge">db.r6i.8xlarge</option>
                                    <option value="db.r6i.large">db.r6i.large</option>
                                    <option value="db.r6i.xlarge">db.r6i.xlarge</option>
                                    <option value="db.r7g.12xlarge">db.r7g.12xlarge</option>
                                    <option value="db.r7g.16xlarge">db.r7g.16xlarge</option>
                                    <option value="db.r7g.2xlarge">db.r7g.2xlarge</option>
                                    <option value="db.r7g.4xlarge">db.r7g.4xlarge</option>
                                    <option value="db.r7g.8xlarge">db.r7g.8xlarge</option>
                                    <option value="db.r7g.large">db.r7g.large</option>
                                    <option value="db.r7g.xlarge">db.r7g.xlarge</option>
                                    <option value="db.t1.micro">db.t1.micro</option>
                                    <option value="db.t2.2xlarge">db.t2.2xlarge</option>
                                    <option value="db.t2.large">db.t2.large</option>
                                    <option value="db.t2.medium">db.t2.medium</option>
                                    <option value="db.t2.micro">db.t2.micro</option>
                                    <option value="db.t2.small">db.t2.small</option>
                                    <option value="db.t2.xlarge">db.t2.xlarge</option>
                                    <option value="db.t3.2xlarge">db.t3.2xlarge</option>
                                    <option value="db.t3.large">db.t3.large</option>
                                    <option value="db.t3.medium">db.t3.medium</option>
                                    <option value="db.t3.micro">db.t3.micro</option>
                                    <option value="db.t3.small">db.t3.small</option>
                                    <option value="db.t3.xlarge">db.t3.xlarge</option>
                                    <option value="db.t4g.2xlarge">db.t4g.2xlarge</option>
                                    <option value="db.t4g.large">db.t4g.large</option>
                                    <option value="db.t4g.medium">db.t4g.medium</option>
                                    <option value="db.t4g.micro">db.t4g.micro</option>
                                    <option value="db.t4g.small">db.t4g.small</option>
                                    <option value="db.t4g.xlarge">db.t4g.xlarge</option>
                                    <option value="db.x1.16xlarge">db.x1.16xlarge</option>
                                    <option value="db.x1.32xlarge">db.x1.32xlarge</option>
                                    <option value="db.x1e.16xlarge">db.x1e.16xlarge</option>
                                    <option value="db.x1e.2xlarge">db.x1e.2xlarge</option>
                                    <option value="db.x1e.32xlarge">db.x1e.32xlarge</option>
                                    <option value="db.x1e.4xlarge">db.x1e.4xlarge</option>
                                    <option value="db.x1e.8xlarge">db.x1e.8xlarge</option>
                                    <option value="db.x1e.xlarge">db.x1e.xlarge</option>
                                    <option value="db.x2g.12xlarge">db.x2g.12xlarge</option>
                                    <option value="db.x2g.16xlarge">db.x2g.16xlarge</option>
                                    <option value="db.x2g.2xlarge">db.x2g.2xlarge</option>
                                    <option value="db.x2g.4xlarge">db.x2g.4xlarge</option>
                                    <option value="db.x2g.8xlarge">db.x2g.8xlarge</option>
                                    <option value="db.x2g.large">db.x2g.large</option>
                                    <option value="db.x2g.xlarge">db.x2g.xlarge</option>
                                    <option value="db.x2idn.16xlarge">db.x2idn.16xlarge</option>
                                    <option value="db.x2idn.24xlarge">db.x2idn.24xlarge</option>
                                    <option value="db.x2idn.32xlarge">db.x2idn.32xlarge</option>
                                    <option value="db.x2iedn.16xlarge">db.x2iedn.16xlarge</option>
                                    <option value="db.x2iedn.24xlarge">db.x2iedn.24xlarge</option>
                                    <option value="db.x2iedn.2xlarge">db.x2iedn.2xlarge</option>
                                    <option value="db.x2iedn.32xlarge">db.x2iedn.32xlarge</option>
                                    <option value="db.x2iedn.4xlarge">db.x2iedn.4xlarge</option>
                                    <option value="db.x2iedn.8xlarge">db.x2iedn.8xlarge</option>
                                    <option value="db.x2iedn.xlarge">db.x2iedn.xlarge</option>
                                    <option value="db.x2iezn.12xlarge">db.x2iezn.12xlarge</option>
                                    <option value="db.x2iezn.2xlarge">db.x2iezn.2xlarge</option>
                                    <option value="db.x2iezn.4xlarge">db.x2iezn.4xlarge</option>
                                    <option value="db.x2iezn.6xlarge">db.x2iezn.6xlarge</option>
                                    <option value="db.x2iezn.8xlarge">db.x2iezn.8xlarge</option>
                                    <option value="db.z1d.12xlarge">db.z1d.12xlarge</option>
                                    <option value="db.z1d.2xlarge">db.z1d.2xlarge</option>
                                    <option value="db.z1d.3xlarge">db.z1d.3xlarge</option>
                                    <option value="db.z1d.6xlarge">db.z1d.6xlarge</option>
                                    <option value="db.z1d.large">db.z1d.large</option>
                                    <option value="db.z1d.xlarge">db.z1d.xlarge</option>
                                </select>
                            </div>}

                            <label style={{ paddingBottom: '10px' , marginTop :"2%"}}>Utilization (On-Demand only)</label>
                            <div className="col-md-6 px-3">
                                <label htmlFor="deploymentOption1" className="form-label font-14 font-medium black-v2 mb-3">
                                    Deployment Option
                                </label>
                                <select
                                style={{paddingTop : "10px"}}
                                    className="form-select custom-form font-semibold font-14 form-select-sm mb-3"
                                    id="deploymentOption1"
                                    value={values.deploymentOption1}
                                    name={"deploymentOption1"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                >
                                    <option value="Multi-AZ">Multi-AZ</option>
                                    <option value="Single-AZ">Single-AZ</option>
                                </select>
                            </div>
                            <div className="col-md-6 px-3">
                                <label
                                    htmlFor="utilization1"
                                    className="form-label font-14 font-medium black-v2 mb-3"
                                >
                                    Utilization
                                </label>
                                <input
                                    type="text"
                                    style={{paddingTop : "10px"}}
                                    className="form-control custom-form text-field font-14 font-medium"
                                    id="utilization1"
                                    value={values.utilization1}
                                    name={"utilization1"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                />
                            </div>
                            <div >
                                <label htmlFor="utilizationUnit1" className="form-label font-14 font-medium black-v2 mb-3">
                                    Unit
                                </label>
                                <select
                                    className="form-select custom-form font-semibold font-14 form-select-sm"
                                    id="utilizationUnit1"
                                    value={values.utilizationUnit1}
                                    name={"utilizationUnit1"}
                                    style={{paddingTop : "10px"}}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                >
                                    <option value="perDay">Hours/Day</option>
                                    <option value="perWeek">Hours/Week</option>
                                    <option value="perMonth">Hours/Month</option>
                                    <option value="utilization">%Utilized/Month</option>
                                </select>
                            </div>
                            <label style={{paddingBottom: '10px', marginTop : "2%"}}>RDS Proxy</label>
                            <div >
                                <label htmlFor="RDSproxy1" className="form-label font-14 font-medium black-v2 mb-3">
                                    Would you be creating an RDS Proxy with the database?
                                </label>
                                <select
                                    className="form-select custom-form font-semibold font-14 form-select-sm"
                                    id="RDSproxy1"
                                    style={{paddingTop : "10px"}}
                                    value={values.RDSproxy1}
                                    name={"RDSproxy1"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                >
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </div>
                            <label style={{ paddingBottom: '10px', marginTop:"2%"}}>Storage</label>
                            <div className="col-md-6 px-3">
                                <label
                                    htmlFor="Storage1"
                                    className="form-label font-14 font-medium black-v2 mb-3"
                                >
                                    Storage amount
                                </label>
                                <input
                                    type="text"
                                    style={{paddingTop : "10px"}}
                                    className="form-control custom-form text-field font-14 font-medium"
                                    id="Storage1"
                                    value={values.Storage1}
                                    name={"Storage1"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                />
                            </div>
                            <div className="col-md-6 px-3">
                                <label htmlFor="provisionedThroughputUnit" className="form-label font-14 font-medium black-v2 mb-3">
                                    Unit
                                </label>
                                <select
                                style={{paddingTop : "10px"}}
                                    className="form-select custom-form font-semibold font-14 form-select-sm"
                                    id="StorageUnit1"
                                    value={values.StorageUnit1}
                                    name={"StorageUnit1"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                >
                                    <option value="MB">MB</option>
                                    <option value="GB">GB</option>
                                    <option value="TB">TB</option>
                                </select>
                            </div>
                            <div className="col-md-6 px-3">
                                <label htmlFor="storageType1" className="form-label font-14 font-medium black-v2 mb-3">
                                    Storage for each RDS instance
                                </label>
                                <select
                                style={{paddingTop : "10px"}}
                                    className="form-select custom-form font-semibold font-14 form-select-sm"
                                    id="storageType1"
                                    value={values.storageType1}
                                    name={"storageType1"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                >
                                    <option value="gp2 General Purpose (SSD)">gp2</option>
                                    <option value="gp3 General Purpose-GP3">gp3</option>
                                    <option value="io1 Provisioned IOPS (SSD)">io1</option>
                                    <option value="io2 Provisioned IOPS (SSD)">io2</option>
                                    <option value="Magnetic">Magnetic</option>
                                </select>
                            </div>
                            {/* shiram end */}
                        </>) : (
                        <>

                            <div>
                                <label htmlFor="Mysqltype2" className="form-label font-14 font-medium black-v2 mb-3">
                                    Aurora Mysql Type
                                </label>
                                <select
                                    className="form-select custom-form font-semibold font-14 form-select-sm"
                                    id="Mysqltype2"
                                    value={values.Mysqltype2}
                                    name={"Mysqltype2"}
                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                    onChange={(e) => {
                                        console.log("sagin", e)
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                >
                                    <option value="Aurora MySQL-Compatible">Aurora MySQL-Compatible</option>
                                    <option value="AuroraServerless">Aurora Serverless MySQL-Compatible</option>
                                </select>
                            </div>
                            {values.Mysqltype2 === "Aurora MySQL-Compatible" && <><div style={{ marginTop: "20px" }}>
                                <label htmlFor="AuroraMySQLClusterConfiguration2" className="form-label font-14 font-medium black-v2 mb-3">
                                    Aurora MySQL Cluster Configuration Options
                                </label>
                                <select
                                    className="form-select custom-form font-semibold font-14 form-select-sm mb-3"
                                    id="AuroraMySQLClusterConfiguration2"
                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                    value={values.AuroraMySQLClusterConfiguration2}
                                    name={"AuroraMySQLClusterConfiguration2"}
                                    onChange={(e) => {
                                        console.log("sagin", e)
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                >
                                    <option value="Aurora Standard">Aurora Standard</option>
                                    <option value="Aurora I/O-Optimized">Aurora I/O-Optimized</option>
                                </select>
                            </div>
                                {values.AuroraMySQLClusterConfiguration2 == "Aurora Standard" ? (
                                    <h6 style={{ marginTop: "20px", marginBottom: "20px"}}>Select Aurora MySQL Compatible instances</h6>
                                ) : (
                                    <h6 style={{ marginTop: "20px", marginBottom: "20px"}}>Select Aurora MySQL I/O-Optimized Compatible instances</h6>
                                )}

                                <div className="col-md-6 px-3">
                                    <label htmlFor="Nodes2" className="form-label font-14 font-medium black-v2 mb-3">
                                        Nodes
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Enter the Nodes"
                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                        className="form-control custom-form text-field font-14 font-medium"
                                        id="Nodes2"
                                        value={values.Nodes2}
                                        name={"Nodes2"}
                                        onChange={(e) => {
                                            onChangeOfConfiguration(e, values.instanceName);
                                        }} disabled={isViewer}
                                    />
                                </div>
                                <div className="col-md-6 px-3">
                                    <label htmlFor="AuroraMySQLInstanceType2" className="form-label font-14 font-medium black-v2 mb-3">
                                        Instance
                                    </label>
                                    <select
                                        className="form-select custom-form font-semibold font-14 form-select-sm"
                                        id="AuroraMySQLInstanceType2"
                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                        value={values.AuroraMySQLInstanceType2}
                                        name={"AuroraMySQLInstanceType2"}
                                        onChange={(e) => {
                                            onChangeOfConfiguration(e, values.instanceName);
                                        }} disabled={isViewer}
                                    >
                                        <option value="db.t2.medium">db.t2.medium</option>
                                        <option value="db.c6gd.12xlarge">db.c6gd.12xlarge</option>
                                        <option value="db.c6gd.16xlarge">db.c6gd.16xlarge</option>
                                        <option value="db.c6gd.2xlarge">db.c6gd.2xlarge</option>
                                        <option value="db.c6gd.4xlarge">db.c6gd.4xlarge</option>
                                        <option value="db.c6gd.8xlarge">db.c6gd.8xlarge</option>
                                        <option value="db.c6gd.large">db.c6gd.large</option>
                                        <option value="db.c6gd.medium">db.c6gd.medium</option>
                                        <option value="db.c6gd.xlarge">db.c6gd.xlarge</option>
                                        <option value="db.m1.large">db.m1.large</option>
                                        <option value="db.m1.medium">db.m1.medium</option>
                                        <option value="db.m1.small">db.m1.small</option>
                                        <option value="db.m1.xlarge">db.m1.xlarge</option>
                                        <option value="db.m2.2xlarge">db.m2.2xlarge</option>
                                        <option value="db.m2.4xlarge">db.m2.4xlarge</option>
                                        <option value="db.m2.xlarge">db.m2.xlarge</option>
                                        <option value="db.m3.2xlarge">db.m3.2xlarge</option>
                                        <option value="db.m3.large">db.m3.large</option>
                                        <option value="db.m3.medium">db.m3.medium</option>
                                        <option value="db.m3.xlarge">db.m3.xlarge</option>
                                        <option value="db.m4.10xlarge">db.m4.10xlarge</option>
                                        <option value="db.m4.16xlarge">db.m4.16xlarge</option>
                                        <option value="db.m4.2xlarge">db.m4.2xlarge</option>
                                        <option value="db.m4.4xlarge">db.m4.4xlarge</option>
                                        <option value="db.m4.large">db.m4.large</option>
                                        <option value="db.m4.xlarge">db.m4.xlarge</option>
                                        <option value="db.m5.12xlarge">db.m5.12xlarge</option>
                                        <option value="db.m5.16xlarge">db.m5.16xlarge</option>
                                        <option value="db.m5.24xlarge">db.m5.24xlarge</option>
                                        <option value="db.m5.2xlarge">db.m5.2xlarge</option>
                                        <option value="db.m5.4xlarge">db.m5.4xlarge</option>
                                        <option value="db.m5.8xlarge">db.m5.8xlarge</option>
                                        <option value="db.m5.large">db.m5.large</option>
                                        <option value="db.m5.xlarge">db.m5.xlarge</option>
                                        <option value="db.m5d.12xlarge">db.m5d.12xlarge</option>
                                        <option value="db.m5d.16xlarge">db.m5d.16xlarge</option>
                                        <option value="db.m5d.24xlarge">db.m5d.24xlarge</option>
                                        <option value="db.m5d.2xlarge">db.m5d.2xlarge</option>
                                        <option value="db.m5d.4xlarge">db.m5d.4xlarge</option>
                                        <option value="db.m5d.8xlarge">db.m5d.8xlarge</option>
                                        <option value="db.m5d.large">db.m5d.large</option>
                                        <option value="db.m5d.xlarge">db.m5d.xlarge</option>
                                        <option value="db.m6g.12xlarge">db.m6g.12xlarge</option>
                                        <option value="db.m6g.16xlarge">db.m6g.16xlarge</option>
                                        <option value="db.m6g.2xlarge">db.m6g.2xlarge</option>
                                        <option value="db.m6g.4xlarge">db.m6g.4xlarge</option>
                                        <option value="db.m6g.8xlarge">db.m6g.8xlarge</option>
                                        <option value="db.m6g.large">db.m6g.large</option>
                                        <option value="db.m6g.xlarge">db.m6g.xlarge</option>
                                        <option value="db.m6gd.12xlarge">db.m6gd.12xlarge</option>
                                        <option value="db.m6gd.16xlarge">db.m6gd.16xlarge</option>
                                        <option value="db.m6gd.2xlarge">db.m6gd.2xlarge</option>
                                        <option value="db.m6gd.4xlarge">db.m6gd.4xlarge</option>
                                        <option value="db.m6gd.8xlarge">db.m6gd.8xlarge</option>
                                        <option value="db.m6gd.large">db.m6gd.large</option>
                                        <option value="db.m6gd.xlarge">db.m6gd.xlarge</option>
                                        <option value="db.m6i.12xlarge">db.m6i.12xlarge</option>
                                        <option value="db.m6i.16xlarge">db.m6i.16xlarge</option>
                                        <option value="db.m6i.24xlarge">db.m6i.24xlarge</option>
                                        <option value="db.m6i.2xlarge">db.m6i.2xlarge</option>
                                        <option value="db.m6i.32xlarge">db.m6i.32xlarge</option>
                                        <option value="db.m6i.4xlarge">db.m6i.4xlarge</option>
                                        <option value="db.m6i.8xlarge">db.m6i.8xlarge</option>
                                        <option value="db.m6i.large">db.m6i.large</option>
                                        <option value="db.m6i.xlarge">db.m6i.xlarge</option>
                                        <option value="db.m6id.12xlarge">db.m6id.12xlarge</option>
                                        <option value="db.m6id.16xlarge">db.m6id.16xlarge</option>
                                        <option value="db.m6id.24xlarge">db.m6id.24xlarge</option>
                                        <option value="db.m6id.2xlarge">db.m6id.2xlarge</option>
                                        <option value="db.m6id.32xlarge">db.m6id.32xlarge</option>
                                        <option value="db.m6id.4xlarge">db.m6id.4xlarge</option>
                                        <option value="db.m6id.8xlarge">db.m6id.8xlarge</option>
                                        <option value="db.m6id.large">db.m6id.large</option>
                                        <option value="db.m6id.xlarge">db.m6id.xlarge</option>
                                        <option value="db.m6idn.12xlarge">db.m6idn.12xlarge</option>
                                        <option value="db.m6idn.16xlarge">db.m6idn.16xlarge</option>
                                        <option value="db.m6idn.24xlarge">db.m6idn.24xlarge</option>
                                        <option value="db.m6idn.2xlarge">db.m6idn.2xlarge</option>
                                        <option value="db.m6idn.32xlarge">db.m6idn.32xlarge</option>
                                        <option value="db.m6idn.4xlarge">db.m6idn.4xlarge</option>
                                        <option value="db.m6idn.8xlarge">db.m6idn.8xlarge</option>
                                        <option value="db.m6idn.large">db.m6idn.large</option>
                                        <option value="db.m6idn.xlarge">db.m6idn.xlarge</option>
                                        <option value="db.m6in.12xlarge">db.m6in.12xlarge</option>
                                        <option value="db.m6in.16xlarge">db.m6in.16xlarge</option>
                                        <option value="db.m6in.24xlarge">db.m6in.24xlarge</option>
                                        <option value="db.m6in.2xlarge">db.m6in.2xlarge</option>
                                        <option value="db.m6in.32xlarge">db.m6in.32xlarge</option>
                                        <option value="db.m6in.4xlarge">db.m6in.4xlarge</option>
                                        <option value="db.m6in.8xlarge">db.m6in.8xlarge</option>
                                        <option value="db.m6in.large">db.m6in.large</option>
                                        <option value="db.m6in.xlarge">db.m6in.xlarge</option>
                                        <option value="db.m7g.12xlarge">db.m7g.12xlarge</option>
                                        <option value="db.m7g.16xlarge">db.m7g.16xlarge</option>
                                        <option value="db.m7g.2xlarge">db.m7g.2xlarge</option>
                                        <option value="db.m7g.4xlarge">db.m7g.4xlarge</option>
                                        <option value="db.m7g.8xlarge">db.m7g.8xlarge</option>
                                        <option value="db.m7g.large">db.m7g.large</option>
                                        <option value="db.m7g.xlarge">db.m7g.xlarge</option>
                                        <option value="db.r3.2xlarge">db.r3.2xlarge</option>
                                        <option value="db.r3.4xlarge">db.r3.4xlarge</option>
                                        <option value="db.r3.8xlarge">db.r3.8xlarge</option>
                                        <option value="db.r3.large">db.r3.large</option>
                                        <option value="db.r3.xlarge">db.r3.xlarge</option>
                                        <option value="db.r4.16xlarge">db.r4.16xlarge</option>
                                        <option value="db.r4.2xlarge">db.r4.2xlarge</option>
                                        <option value="db.r4.4xlarge">db.r4.4xlarge</option>
                                        <option value="db.r4.8xlarge">db.r4.8xlarge</option>
                                        <option value="db.r4.large">db.r4.large</option>
                                        <option value="db.r4.xlarge">db.r4.xlarge</option>
                                        <option value="db.r5.12xlarge">db.r5.12xlarge</option>
                                        <option value="db.r5.12xlarge.tpc2.mem2x">db.r5.12xlarge.tpc2.mem2x</option>
                                        <option value="db.r5.16xlarge">db.r5.16xlarge</option>
                                        <option value="db.r5.24xlarge">db.r5.24xlarge</option>
                                        <option value="db.r5.2xlarge">db.r5.2xlarge</option>
                                        <option value="db.r5.2xlarge.tpc1.mem2x">db.r5.2xlarge.tpc1.mem2x</option>
                                        <option value="db.r5.2xlarge.tpc2.mem4x">db.r5.2xlarge.tpc2.mem4x</option>
                                        <option value="db.r5.2xlarge.tpc2.mem8x">db.r5.2xlarge.tpc2.mem8x</option>
                                        <option value="db.r5.4xlarge">db.r5.4xlarge</option>
                                        <option value="db.r5.4xlarge.tpc2.mem2x">db.r5.4xlarge.tpc2.mem2x</option>
                                        <option value="db.r5.4xlarge.tpc2.mem3x">db.r5.4xlarge.tpc2.mem3x</option>
                                        <option value="db.r5.4xlarge.tpc2.mem4x">db.r5.4xlarge.tpc2.mem4x</option>
                                        <option value="db.r5.6xlarge.tpc2.mem4x">db.r5.6xlarge.tpc2.mem4x</option>
                                        <option value="db.r5.8xlarge">db.r5.8xlarge</option>
                                        <option value="db.r5.8xlarge.tpc2.mem3x">db.r5.8xlarge.tpc2.mem3x</option>
                                        <option value="db.r5.large">db.r5.large</option>
                                        <option value="db.r5.large.tpc1.mem2x">db.r5.large.tpc1.mem2x</option>
                                        <option value="db.r5.xlarge">db.r5.xlarge</option>
                                        <option value="db.r5.xlarge.tpc2.mem2x">db.r5.xlarge.tpc2.mem2x</option>
                                        <option value="db.r5.xlarge.tpc2.mem4x">db.r5.xlarge.tpc2.mem4x</option>
                                        <option value="db.r5b.12xlarge">db.r5b.12xlarge</option>
                                        <option value="db.r5b.16xlarge">db.r5b.16xlarge</option>
                                        <option value="db.r5b.24xlarge">db.r5b.24xlarge</option>
                                        <option value="db.r5b.2xlarge">db.r5b.2xlarge</option>
                                        <option value="db.r5b.2xlarge.tpc1.mem2x">db.r5b.2xlarge.tpc1.mem2x</option>
                                        <option value="db.r5b.2xlarge.tpc2.mem4x">db.r5b.2xlarge.tpc2.mem4x</option>
                                        <option value="db.r5b.2xlarge.tpc2.mem8x">db.r5b.2xlarge.tpc2.mem8x</option>
                                        <option value="db.r5b.4xlarge">db.r5b.4xlarge</option>
                                        <option value="db.r5b.4xlarge.tpc2.mem2x">db.r5b.4xlarge.tpc2.mem2x</option>
                                        <option value="db.r5b.4xlarge.tpc2.mem3x">db.r5b.4xlarge.tpc2.mem3x</option>
                                        <option value="db.r5b.4xlarge.tpc2.mem4x">db.r5b.4xlarge.tpc2.mem4x</option>
                                        <option value="db.r5b.6xlarge.tpc2.mem4x">db.r5b.6xlarge.tpc2.mem4x</option>
                                        <option value="db.r5b.8xlarge">db.r5b.8xlarge</option>
                                        <option value="db.r5b.8xlarge.tpc2.mem3x">db.r5b.8xlarge.tpc2.mem3x</option>
                                        <option value="db.r5b.large">db.r5b.large</option>
                                        <option value="db.r5b.large.tpc1.mem2x">db.r5b.large.tpc1.mem2x</option>
                                        <option value="db.r5b.xlarge">db.r5b.xlarge</option>
                                        <option value="db.r5b.xlarge.tpc2.mem2x">db.r5b.xlarge.tpc2.mem2x</option>
                                        <option value="db.r5b.xlarge.tpc2.mem4x">db.r5b.xlarge.tpc2.mem4x</option>
                                        <option value="db.r5d.12xlarge">db.r5d.12xlarge</option>
                                        <option value="db.r5d.16xlarge">db.r5d.16xlarge</option>
                                        <option value="db.r5d.24xlarge">db.r5d.24xlarge</option>
                                        <option value="db.r5d.2xlarge">db.r5d.2xlarge</option>
                                        <option value="db.r5d.4xlarge">db.r5d.4xlarge</option>
                                        <option value="db.r5d.8xlarge">db.r5d.8xlarge</option>
                                        <option value="db.r5d.large">db.r5d.large</option>
                                        <option value="db.r5d.xlarge">db.r5d.xlarge</option>
                                        <option value="db.r6g.12xlarge">db.r6g.12xlarge</option>
                                        <option value="db.r6g.16xlarge">db.r6g.16xlarge</option>
                                        <option value="db.r6g.2xlarge">db.r6g.2xlarge</option>
                                        <option value="db.r6g.4xlarge">db.r6g.4xlarge</option>
                                        <option value="db.r6g.8xlarge">db.r6g.8xlarge</option>
                                        <option value="db.r6g.large">db.r6g.large</option>
                                        <option value="db.r6g.xlarge">db.r6g.xlarge</option>
                                        <option value="db.r6gd.12xlarge">db.r6gd.12xlarge</option>
                                        <option value="db.r6gd.16xlarge">db.r6gd.16xlarge</option>
                                        <option value="db.r6gd.2xlarge">db.r6gd.2xlarge</option>
                                        <option value="db.r6gd.4xlarge">db.r6gd.4xlarge</option>
                                        <option value="db.r6gd.8xlarge">db.r6gd.8xlarge</option>
                                        <option value="db.r6gd.large">db.r6gd.large</option>
                                        <option value="db.r6gd.xlarge">db.r6gd.xlarge</option>
                                        <option value="db.r6i.12xlarge">db.r6i.12xlarge</option>
                                        <option value="db.r6i.16xlarge">db.r6i.16xlarge</option>
                                        <option value="db.r6i.24xlarge">db.r6i.24xlarge</option>
                                        <option value="db.r6i.2xlarge">db.r6i.2xlarge</option>
                                        <option value="db.r6i.2xlarge.tpc1.mem2x">db.r6i.2xlarge.tpc1.mem2x</option>
                                        <option value="db.r6i.2xlarge.tpc2.mem4x">db.r6i.2xlarge.tpc2.mem4x</option>
                                        <option value="db.r6i.2xlarge.tpc2.mem8x">db.r6i.2xlarge.tpc2.mem8x</option>
                                        <option value="db.r6i.32xlarge">db.r6i.32xlarge</option>
                                        <option value="db.r6i.4xlarge">db.r6i.4xlarge</option>
                                        <option value="db.r6i.4xlarge.tpc2.mem2x">db.r6i.4xlarge.tpc2.mem2x</option>
                                        <option value="db.r6i.4xlarge.tpc2.mem3x">db.r6i.4xlarge.tpc2.mem3x</option>
                                        <option value="db.r6i.4xlarge.tpc2.mem4x">db.r6i.4xlarge.tpc2.mem4x</option>
                                        <option value="db.r6i.6xlarge.tpc2.mem4x">db.r6i.6xlarge.tpc2.mem4x</option>
                                        <option value="db.r6i.8xlarge">db.r6i.8xlarge</option>
                                        <option value="db.r6i.8xlarge.tpc2.mem3x">db.r6i.8xlarge.tpc2.mem3x</option>
                                        <option value="db.r6i.8xlarge.tpc2.mem4x">db.r6i.8xlarge.tpc2.mem4x</option>
                                        <option value="db.r6i.large">db.r6i.large</option>
                                        <option value="db.r6i.large.tpc1.mem2x">db.r6i.large.tpc1.mem2x</option>
                                        <option value="db.r6i.xlarge">db.r6i.xlarge</option>
                                        <option value="db.r6i.xlarge.tpc2.mem2x">db.r6i.xlarge.tpc2.mem2x</option>
                                        <option value="db.r6i.xlarge.tpc2.mem4x">db.r6i.xlarge.tpc2.mem4x</option>
                                        <option value="db.r6id.12xlarge">db.r6id.12xlarge</option>
                                        <option value="db.r6id.16xlarge">db.r6id.16xlarge</option>
                                        <option value="db.r6id.24xlarge">db.r6id.24xlarge</option>
                                        <option value="db.r6id.2xlarge">db.r6id.2xlarge</option>
                                        <option value="db.r6id.32xlarge">db.r6id.32xlarge</option>
                                        <option value="db.r6id.4xlarge">db.r6id.4xlarge</option>
                                        <option value="db.r6id.8xlarge">db.r6id.8xlarge</option>
                                        <option value="db.r6id.large">db.r6id.large</option>
                                        <option value="db.r6id.xlarge">db.r6id.xlarge</option>
                                        <option value="db.r6idn.12xlarge">db.r6idn.12xlarge</option>
                                        <option value="db.r6idn.16xlarge">db.r6idn.16xlarge</option>
                                        <option value="db.r6idn.24xlarge">db.r6idn.24xlarge</option>
                                        <option value="db.r6idn.2xlarge">db.r6idn.2xlarge</option>
                                        <option value="db.r6idn.32xlarge">db.r6idn.32xlarge</option>
                                        <option value="db.r6idn.4xlarge">db.r6idn.4xlarge</option>
                                        <option value="db.r6idn.8xlarge">db.r6idn.8xlarge</option>
                                        <option value="db.r6idn.large">db.r6idn.large</option>
                                        <option value="db.r6idn.xlarge">db.r6idn.xlarge</option>
                                        <option value="db.r6in.12xlarge">db.r6in.12xlarge</option>
                                        <option value="db.r6in.16xlarge">db.r6in.16xlarge</option>
                                        <option value="db.r6in.24xlarge">db.r6in.24xlarge</option>
                                        <option value="db.r6in.2xlarge">db.r6in.2xlarge</option>
                                        <option value="db.r6in.32xlarge">db.r6in.32xlarge</option>
                                        <option value="db.r6in.4xlarge">db.r6in.4xlarge</option>
                                        <option value="db.r6in.8xlarge">db.r6in.8xlarge</option>
                                        <option value="db.r6in.large">db.r6in.large</option>
                                        <option value="db.r6in.xlarge">db.r6in.xlarge</option>
                                        <option value="db.r7g.12xlarge">db.r7g.12xlarge</option>
                                        <option value="db.r7g.16xlarge">db.r7g.16xlarge</option>
                                        <option value="db.r7g.2xlarge">db.r7g.2xlarge</option>
                                        <option value="db.r7g.4xlarge">db.r7g.4xlarge</option>
                                        <option value="db.r7g.8xlarge">db.r7g.8xlarge</option>
                                        <option value="db.r7g.large">db.r7g.large</option>
                                        <option value="db.r7g.xlarge">db.r7g.xlarge</option>
                                        <option value="db.t1.micro">db.t1.micro</option>
                                        <option value="db.t2.2xlarge">db.t2.2xlarge</option>
                                        <option value="db.t2.large">db.t2.large</option>
                                        <option value="db.t2.medium">db.t2.medium</option>
                                        <option value="db.t2.micro">db.t2.micro</option>
                                        <option value="db.t2.small">db.t2.small</option>
                                        <option value="db.t2.xlarge">db.t2.xlarge</option>
                                        <option value="db.t3.2xlarge">db.t3.2xlarge</option>
                                        <option value="db.t3.large">db.t3.large</option>
                                        <option value="db.t3.medium">db.t3.medium</option>
                                        <option value="db.t3.micro">db.t3.micro</option>
                                        <option value="db.t3.small">db.t3.small</option>
                                        <option value="db.t3.xlarge">db.t3.xlarge</option>
                                        <option value="db.t4g.2xlarge">db.t4g.2xlarge</option>
                                        <option value="db.t4g.large">db.t4g.large</option>
                                        <option value="db.t4g.medium">db.t4g.medium</option>
                                        <option value="db.t4g.micro">db.t4g.micro</option>
                                        <option value="db.t4g.small">db.t4g.small</option>
                                        <option value="db.t4g.xlarge">db.t4g.xlarge</option>
                                        <option value="db.x1.16xlarge">db.x1.16xlarge</option>
                                        <option value="db.x1.32xlarge">db.x1.32xlarge</option>
                                        <option value="db.x1e.16xlarge">db.x1e.16xlarge</option>
                                        <option value="db.x1e.2xlarge">db.x1e.2xlarge</option>
                                        <option value="db.x1e.32xlarge">db.x1e.32xlarge</option>
                                        <option value="db.x1e.4xlarge">db.x1e.4xlarge</option>
                                        <option value="db.x1e.8xlarge">db.x1e.8xlarge</option>
                                        <option value="db.x1e.xlarge">db.x1e.xlarge</option>
                                        <option value="db.x2g.12xlarge">db.x2g.12xlarge</option>
                                        <option value="db.x2g.16xlarge">db.x2g.16xlarge</option>
                                        <option value="db.x2g.2xlarge">db.x2g.2xlarge</option>
                                        <option value="db.x2g.4xlarge">db.x2g.4xlarge</option>
                                        <option value="db.x2g.8xlarge">db.x2g.8xlarge</option>
                                        <option value="db.x2g.large">db.x2g.large</option>
                                        <option value="db.x2g.xlarge">db.x2g.xlarge</option>
                                        <option value="db.x2idn.16xlarge">db.x2idn.16xlarge</option>
                                        <option value="db.x2idn.24xlarge">db.x2idn.24xlarge</option>
                                        <option value="db.x2idn.32xlarge">db.x2idn.32xlarge</option>
                                        <option value="db.x2iedn.16xlarge">db.x2iedn.16xlarge</option>
                                        <option value="db.x2iedn.24xlarge">db.x2iedn.24xlarge</option>
                                        <option value="db.x2iedn.2xlarge">db.x2iedn.2xlarge</option>
                                        <option value="db.x2iedn.32xlarge">db.x2iedn.32xlarge</option>
                                        <option value="db.x2iedn.4xlarge">db.x2iedn.4xlarge</option>
                                        <option value="db.x2iedn.8xlarge">db.x2iedn.8xlarge</option>
                                        <option value="db.x2iedn.xlarge">db.x2iedn.xlarge</option>
                                        <option value="db.x2iezn.12xlarge">db.x2iezn.12xlarge</option>
                                        <option value="db.x2iezn.2xlarge">db.x2iezn.2xlarge</option>
                                        <option value="db.x2iezn.4xlarge">db.x2iezn.4xlarge</option>
                                        <option value="db.x2iezn.6xlarge">db.x2iezn.6xlarge</option>
                                        <option value="db.x2iezn.8xlarge">db.x2iezn.8xlarge</option>
                                        <option value="db.z1d.12xlarge">db.z1d.12xlarge</option>
                                        <option value="db.z1d.2xlarge">db.z1d.2xlarge</option>
                                        <option value="db.z1d.3xlarge">db.z1d.3xlarge</option>
                                        <option value="db.z1d.6xlarge">db.z1d.6xlarge</option>
                                        <option value="db.z1d.large">db.z1d.large</option>
                                        <option value="db.z1d.xlarge">db.z1d.xlarge</option>
                                    </select>
                                </div>
                                <div className="col-md-6 px-3">
                                    <label htmlFor="AuroraMySQLCompatibleinstancesValue2" className="form-label font-14 font-medium black-v2 mb-3">
                                        Value
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Enter the Value"
                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                        className="form-control custom-form text-field font-14 font-medium"
                                        id="AuroraMySQLCompatibleinstancesValue2"
                                        value={values.AuroraMySQLCompatibleinstancesValue2}
                                        name={"AuroraMySQLCompatibleinstancesValue2"}
                                        onChange={(e) => {
                                            onChangeOfConfiguration(e, values.instanceName);
                                        }} disabled={isViewer}
                                    />
                                </div>
                                <div className="col-md-6 px-3">
                                    <label htmlFor="AuroraMySQLCompatibleinstancesUnit2" className="form-label font-14 font-medium black-v2 mb-3">
                                        Unit
                                    </label>
                                    <select
                                        className="form-select custom-form font-semibold font-14 form-select-sm"
                                        id="AuroraMySQLCompatibleinstancesUnit2"
                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                        value={values.AuroraMySQLCompatibleinstancesUnit2}
                                        name={"AuroraMySQLCompatibleinstancesUnit2"}
                                        onChange={(e) => {
                                            onChangeOfConfiguration(e, values.instanceName);
                                        }} disabled={isViewer}
                                    >
                                        <option value="%Utilized/Month">%Utilized/Month</option>
                                        <option value="Hours/Day">Hours/Day</option>
                                        <option value="Hours/Week">Hours/Week</option>
                                        <option value="Hours/Month">Hours/Month</option>
                                    </select>
                                </div>
                                {values.AuroraMySQLClusterConfiguration2 == "Aurora Standard" ? (
                                    <div className="col-md-6 px-3">
                                        <label htmlFor="AuroraMySQLClusterConfiguration2" className="form-label font-14 font-medium black-v2 mb-3">
                                            Instance Family
                                        </label>
                                        <select
                                            className="form-select custom-form font-semibold font-14 form-select-sm"
                                            id="AuroraMySQLCompatibleinstancesInstanceFamily2"
                                            style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                            value={values.AuroraMySQLCompatibleinstancesInstanceFamily2}
                                            name={"AuroraMySQLCompatibleinstancesInstanceFamily2"}
                                            onChange={(e) => {
                                                onChangeOfConfiguration(e, values.instanceName);
                                            }} disabled={isViewer}
                                        >
                                            <option value="Memory optimized">General purpose</option>
                                        </select>
                                    </div>
                                ) : (
                                    <div className="col-md-6 px-3">
                                        <label htmlFor="AuroraMySQLCompatibleinstancesInstanceFamily2" className="form-label font-14 font-medium black-v2 mb-3">
                                            Instance Family
                                        </label>
                                        <select
                                            className="form-select custom-form font-semibold font-14 form-select-sm"
                                            id="AuroraMySQLCompatibleinstancesInstanceFamily2"
                                            style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                            value={values.AuroraMySQLCompatibleinstancesInstanceFamily2}
                                            name={"AuroraMySQLCompatibleinstancesInstanceFamily2"}
                                            onChange={(e) => {
                                                onChangeOfConfiguration(e, values.instanceName);
                                            }} disabled={isViewer}
                                        >
                                            <option value="General purpose">General purpose</option>
                                        </select>
                                    </div>
                                )}


                                <div className="col-md-6 px-3">
                                    <label htmlFor="AuroraMySQLCompatibleinstancesPricingModel2" className="form-label font-14 font-medium black-v2 mb-3">
                                        Pricing Model
                                    </label>
                                    <select
                                        className="form-select custom-form font-semibold font-14 form-select-sm"
                                        id="AuroraMySQLCompatibleinstancesPricingModel2"
                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                        value={values.AuroraMySQLCompatibleinstancesInstanceFamily2}
                                        name={"AuroraMySQLCompatibleinstancesPricingModel2"}
                                        onChange={(e) => {
                                            onChangeOfConfiguration(e, values.instanceName);
                                        }} disabled={isViewer}
                                    >
                                        <option value="OnDemand">OnDemand</option>
                                    </select>
                                </div>
                                {/* RDS proxy */}
                                <div>
                                    <h6 style={{ marginTop: "20px", marginBottom: "20px"}}>RDS Proxy</h6>
                                    <label htmlFor="RDSProxy2" className="form-label font-14 font-medium black-v2 mb-3">
                                        RDS Proxy
                                    </label>
                                    <select
                                        className="form-select custom-form font-semibold font-14 form-select-sm"
                                        id="RDSProxy2"
                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                        value={values.RDSProxy2}
                                        name={"RDSProxy2"}
                                        onChange={(e) => {
                                            onChangeOfConfiguration(e, values.instanceName);
                                        }} disabled={isViewer}>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>
                                {values.AuroraMySQLClusterConfiguration2 == "Aurora Standard" ? (
                                    <h6 style={{ marginTop: "20px", marginBottom: "20px"}}>Database Storage</h6>
                                ) : (
                                    <h6 style={{ marginTop: "20px", marginBottom: "20px"}}>Database Storage - Aurora I/O-Optimized</h6>
                                )}
                                <div className="col-md-6 px-3">
                                    <label htmlFor="StorageamountValue2" className="form-label font-14 font-medium black-v2 mb-3">
                                        Storage amount
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Enter amount"
                                        className="form-control custom-form text-field font-14 font-medium"
                                        id="StorageamountValue2"
                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                        value={values.StorageamountValue2}
                                        name={"StorageamountValue2"}
                                        onChange={(e) => {
                                            onChangeOfConfiguration(e, values.instanceName);
                                        }} disabled={isViewer}

                                    />
                                </div>

                                <div className="col-md-6 px-3">
                                    <label htmlFor="StorageamountUnit2" className="form-label font-14 font-medium black-v2 mb-3">
                                        Storage amount unit
                                    </label>
                                    <select
                                        className="form-select custom-form font-semibold font-14 form-select-sm"
                                        id="StorageamountUnit2"
                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                        value={values.StorageamountUnit2}
                                        name={"StorageamountUnit2"}
                                        onChange={(e) => {
                                            console.log("sagin", e)
                                            onChangeOfConfiguration(e, values.instanceName);
                                        }} disabled={isViewer}
                                    >
                                        <option value="MB">MB</option>
                                        <option value="GB">GB</option>
                                        <option value="TB">TB</option>
                                    </select>
                                </div>
                                {values.AuroraMySQLClusterConfiguration2 === "Aurora Standard" ? (
                                    <>
                                        {/* Baseline IO rate */}
                                        <div className="col-md-6 px-3">
                                            <label htmlFor="BaselineIOrateValue2" className="form-label font-14 font-medium black-v2 mb-3">
                                                Baseline IO rate
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Enter the number of IOs"
                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                className="form-control custom-form text-field font-14 font-medium"
                                                id="BaselineIOrateValue2"
                                                value={values.BaselineIOrateValue2}
                                                name={"BaselineIOrateValue2"}
                                                onChange={(e) => {
                                                    onChangeOfConfiguration(e, values.instanceName);
                                                }} disabled={isViewer}

                                            />
                                        </div>

                                        <div className="col-md-6 px-3">
                                            <label htmlFor="BaselineIOrateUnit2" className="form-label font-14 font-medium black-v2 mb-3">
                                                Baseline IO rate unit
                                            </label>
                                            <select
                                                className="form-select custom-form font-semibold font-14 form-select-sm"
                                                id="BaselineIOrateUnit2"
                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                value={values.BaselineIOrateUnit2}
                                                name={"BaselineIOrateUnit2"}
                                                onChange={(e) => {
                                                    console.log("sagin", e)
                                                    onChangeOfConfiguration(e, values.instanceName);
                                                }} disabled={isViewer}
                                            >
                                                <option value="per second">per second</option>
                                                <option value="per minute">per minute</option>
                                                <option value="per hour">per hour</option>
                                                <option value="per day">per day</option>
                                                <option value="per month">per month</option>
                                            </select>
                                        </div>
                                        {/* Peak IO rate */}
                                        <div className="col-md-6 px-3">
                                            <label htmlFor="PeakIOrateValue2" className="form-label font-14 font-medium black-v2 mb-3">
                                                Peak IO rate
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Enter the number of IOs"
                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                className="form-control custom-form text-field font-14 font-medium"
                                                id="PeakIOrateValue2"
                                                value={values.PeakIOrateValue2}
                                                name={"PeakIOrateValue2"}
                                                onChange={(e) => {
                                                    onChangeOfConfiguration(e, values.instanceName);
                                                }} disabled={isViewer}

                                            />
                                        </div>

                                        <div className="col-md-6 px-3">
                                            <label htmlFor="PeakIOrateUnit2" className="form-label font-14 font-medium black-v2 mb-3">
                                                Peak IO rate unit
                                            </label>
                                            <select
                                                className="form-select custom-form font-semibold font-14 form-select-sm"
                                                id="PeakIOrateUnit2"
                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                value={values.PeakIOrateUnit2}
                                                name={"PeakIOrateUnit2"}
                                                onChange={(e) => {
                                                    console.log("sagin", e)
                                                    onChangeOfConfiguration(e, values.instanceName);
                                                }} disabled={isViewer}
                                            >
                                                <option value="per second">per second</option>
                                                <option value="per minute">per minute</option>
                                                <option value="per hour">per hour</option>
                                                <option value="per day">per day</option>
                                                <option value="per month">per month</option>
                                            </select>
                                        </div>
                                        {/* Duration of peak IO activity */}
                                        <div className="col-md-6 px-3">
                                            <label htmlFor="DurationofpeakIOactivityValue2" className="form-label font-14 font-medium black-v2 mb-3">
                                                Duration of peak IO activity
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Enter the number of hours"
                                                className="form-control custom-form text-field font-14 font-medium"
                                                id="DurationofpeakIOactivityValue2"
                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                value={values.DurationofpeakIOactivityValue2}
                                                name={"DurationofpeakIOactivityValue2"}
                                                onChange={(e) => {
                                                    onChangeOfConfiguration(e, values.instanceName);
                                                }} disabled={isViewer}

                                            />
                                        </div>

                                        <div className="col-md-6 px-3">
                                            <label htmlFor="DurationofpeakIOactivityUnit2" className="form-label font-14 font-medium black-v2 mb-3">
                                                Duration of peak IO activity unit
                                            </label>
                                            <select
                                                className="form-select custom-form font-semibold font-14 form-select-sm"
                                                id="DurationofpeakIOactivityUnit2"
                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                value={values.DurationofpeakIOactivityUnit2}
                                                name={"DurationofpeakIOactivityUnit2"}
                                                onChange={(e) => {
                                                    console.log("sagin", e)
                                                    onChangeOfConfiguration(e, values.instanceName);
                                                }} disabled={isViewer}>
                                                <option value="hours per month" selected>hours per month</option>
                                            </select>
                                        </div>
                                    </>
                                ) : (null)}
                            </>}
                            {values.Mysqltype2 === "AuroraServerless" && <>
                                <div className="col-md-6 px-3" >
                                    <label htmlFor="AuroraMySQLClusterConfiguration" className="form-label font-14 font-medium black-v2 mb-3" style={{ marginTop: "20px" }}>
                                        Aurora MySQL Cluster Configuration Option
                                    </label>
                                    <select
                                        className="form-select custom-form font-semibold font-14 form-select-sm"
                                        id="AuroraMySQLClusterConfiguration"
                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                        value={values.AuroraMySQLClusterConfiguration}
                                        name={"AuroraMySQLClusterConfiguration"}
                                        onChange={(e) => {
                                            console.log("sagin", e)
                                            onChangeOfConfiguration(e, values.instanceName);
                                            values.AuroraServerlessVersion = "Aurora Serverless v2"

                                        }} disabled={isViewer}
                                    >
                                        <option value="Aurora Standard">Aurora Standard</option>
                                        <option value="Aurora I/O-Optimized">Aurora I/O-Optimized</option>
                                    </select>
                                </div>
                                <div className="col-md-6 px-3">
                                    <label htmlFor="AuroraServerlessVersion" className="form-label font-14 font-medium black-v2 mb-3" style={{ marginTop: "20px" }}>
                                        Aurora Serverless Version
                                    </label>
                                    <select
                                        className="form-select custom-form font-semibold font-14 form-select-sm"
                                        id="AuroraServerlessVersion"
                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                        value={values.AuroraServerlessVersion}
                                        name={"AuroraServerlessVersion"}
                                        onChange={(e) => {
                                            console.log("sagin----()serverless version", e, "----", values.instanceName)
                                            onChangeOfConfiguration(e, values.instanceName);
                                        }} disabled={isViewer}
                                    >
                                        {/* <option value="Aurora Serverless v2" >Aurora Serverless v2</option> */}
                                        {values.AuroraMySQLClusterConfiguration != "Aurora I/O-Optimized" ? (<><option value="Aurora Serverless v1">Aurora Serverless v1</option>
                                            <option value="Aurora Serverless v2" >Aurora Serverless v2</option></>) : <option value="Aurora Serverless v2" >Aurora Serverless v2</option>}
                                    </select>
                                </div>
                                <div className="col-md-6 px-3">
                                    <label htmlFor="NoofACU" className="form-label font-14 font-medium black-v2 mb-3">
                                        Number of Aurora Capacity Units (ACUs)
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control custom-form text-field font-14 font-medium"
                                        id="NoofACU"
                                        value={values.NoofACU}
                                        name={"NoofACU"}
                                        onChange={(e) => {
                                            onChangeOfConfiguration(e, values.instanceName);
                                        }} disabled={isViewer}

                                    />
                                </div>

                                <div className="col-md-6 px-3">
                                    <label htmlFor="ACUunit" className="form-label font-14 font-medium black-v2 mb-3">
                                        Unit
                                    </label>
                                    <select
                                        className="form-select custom-form font-semibold font-14 form-select-sm"
                                        id="ACUunit"
                                        value={values.ACUunit}
                                        name={"ACUunit"}
                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                        onChange={(e) => {
                                            console.log("sagin", e)
                                            onChangeOfConfiguration(e, values.instanceName);
                                        }} disabled={isViewer}
                                    >
                                        <option value="per hour">per hour</option>
                                        <option value="per day">per day</option>
                                        <option value="per month">per month</option>
                                    </select>
                                </div>
                                {/* RDS proxy */}
                                {values.AuroraServerlessVersion === "Aurora Serverless v2" && <div>
                                    <label htmlFor="RDSProxy" className="form-label font-14 font-medium black-v2 mb-3">
                                        RDS Proxy
                                    </label>
                                    <select
                                        className="form-select custom-form font-semibold font-14 form-select-sm"
                                        id="RDSProxy"
                                        value={values.RDSProxy}
                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                        name={"RDSProxy"}
                                        onChange={(e) => {
                                            console.log("sagin", e)
                                            onChangeOfConfiguration(e, values.instanceName);
                                        }} disabled={isViewer}
                                    >
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>}
                                {/* Storage amount */}
                                <div className="col-md-6 px-3">
                                    <label htmlFor="StorageAmount" className="form-label font-14 font-medium black-v2 mb-3"
                                        style={{ marginTop: "15px" }}>
                                        Storage amount Value
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control custom-form text-field font-14 font-medium"
                                        id="StorageAmount"
                                        value={values.StorageAmount}
                                        name={"StorageAmount"}
                                        onChange={(e) => {
                                            onChangeOfConfiguration(e, values.instanceName);
                                        }} disabled={isViewer}

                                    />
                                </div>

                                <div className="col-md-6 px-3">
                                    <label htmlFor="StorageAmountUnit" className="form-label font-14 font-medium black-v2 mb-3"
                                        style={{ marginTop: "15px" }}>
                                        Unit
                                    </label>
                                    <select
                                        className="form-select custom-form font-semibold font-14 form-select-sm"
                                        id="StorageAmountUnit"
                                        value={values.StorageAmountUnit}
                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                        name={"StorageAmountUnit"}
                                        onChange={(e) => {
                                            console.log("sagin", e)
                                            onChangeOfConfiguration(e, values.instanceName);
                                        }} disabled={isViewer}
                                    >
                                        <option value="gb">GB</option>
                                        <option value="tb">TB</option>
                                        <option value="mb">MB</option>
                                    </select>
                                </div>
                                {/* Baseline IO rate */}

                                {/* start here */}
                                {values.AuroraMySQLClusterConfiguration == "Aurora Standard" &&
                                    <>
                                        <div className="col-md-6 px-3">
                                            <label htmlFor="BaselineIOrateValue" className="form-label font-14 font-medium black-v2 mb-3">
                                                Baseline IO rate Value
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control custom-form text-field font-14 font-medium"
                                                id="BaselineIOrateValue"
                                                value={values.BaselineIOrateValue}
                                                name={"BaselineIOrateValue"}
                                                onChange={(e) => {
                                                    onChangeOfConfiguration(e, values.instanceName);
                                                }} disabled={isViewer}

                                            />
                                        </div>
                                        <div className="col-md-6 px-3">
                                            <label htmlFor="BaselineIOrateunit" className="form-label font-14 font-medium black-v2 mb-3">
                                                Unit
                                            </label>
                                            <select
                                                className="form-select custom-form font-semibold font-14 form-select-sm"
                                                id="BaselineIOrateunit"
                                                value={values.BaselineIOrateunit}
                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                name={"BaselineIOrateunit"}
                                                onChange={(e) => {
                                                    console.log("sagin", e)
                                                    onChangeOfConfiguration(e, values.instanceName);
                                                }} disabled={isViewer}
                                            >
                                                <option value="per month">per month</option>
                                                <option value="per second">per second</option>
                                                <option value="per minute">per minute</option>
                                                <option value="per hour">per hour</option>
                                                <option value="per day">per day</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6 px-3">
                                            <label htmlFor="PeakIOrateValue" className="form-label font-14 font-medium black-v2 mb-3">
                                                Peak IO rate Value
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control custom-form text-field font-14 font-medium"
                                                id="PeakIOrateValue"
                                                value={values.PeakIOrateValue}
                                                name={"PeakIOrateValue"}
                                                onChange={(e) => {
                                                    onChangeOfConfiguration(e, values.instanceName);
                                                }} disabled={isViewer}

                                            />
                                        </div>
                                        <div className="col-md-6 px-3">
                                            <label htmlFor="PeakIorateunit" className="form-label font-14 font-medium black-v2 mb-3">
                                                Unit
                                            </label>
                                            <select
                                                className="form-select custom-form font-semibold font-14 form-select-sm"
                                                id="PeakIorateunit"
                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                value={values.PeakIorateunit}
                                                name={"PeakIorateunit"}
                                                onChange={(e) => {
                                                    console.log("sagin", e)
                                                    onChangeOfConfiguration(e, values.instanceName);
                                                }} disabled={isViewer}
                                            >
                                                <option value="per month">per month</option>
                                                <option value="per second">per second</option>
                                                <option value="per minute">per minute</option>
                                                <option value="per hour">per hour</option>
                                                <option value="per day">per day</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6 px-3">
                                            <label htmlFor="PeakDurationValue" className="form-label font-14 font-medium black-v2 mb-3">
                                                Duration of peak IO activity Value
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control custom-form text-field font-14 font-medium"
                                                id="PeakDurationValue"
                                                value={values.PeakDurationValue}
                                                name={"PeakDurationValue"}
                                                onChange={(e) => {
                                                    onChangeOfConfiguration(e, values.instanceName);
                                                }} disabled={isViewer}

                                            />
                                        </div>
                                        <div className="col-md-6 px-3">
                                            <label htmlFor="PeakDurationunit" className="form-label font-14 font-medium black-v2 mb-3">
                                                Unit
                                            </label>
                                            <select
                                                className="form-select custom-form font-semibold font-14 form-select-sm"
                                                id="PeakDurationunit"
                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                value={values.PeakDurationunit}
                                                name={"PeakDurationunit"}
                                                onChange={(e) => {
                                                    console.log("sagin", e)
                                                    onChangeOfConfiguration(e, values.instanceName);
                                                }} disabled={isViewer}
                                            >
                                                <option value="hours per month" selected>hours per month</option>

                                            </select>
                                        </div>

                                    </>}
                                {/* <div className="col-md-6 px-3">
                                                        <label htmlFor="BaselineIOrateValue" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Baseline IO rate Value
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control custom-form text-field font-14 font-medium"
                                                            id="BaselineIOrateValue"
                                                            value={values.BaselineIOrateValue}
                                                            name={"BaselineIOrateValue"}
                                                            onChange={(e) => {
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}

                                                        />
                                                    </div> */}

                                {/* <div className="col-md-6 px-3">
                                                        <label htmlFor="BaselineIOrateunit" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Unit
                                                        </label>
                                                        <select
                                                            className="form-select custom-form font-semibold font-14 form-select-sm"
                                                            id="BaselineIOrateunit"
                                                            value={values.BaselineIOrateunit}
                                                            style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                            name={"BaselineIOrateunit"}
                                                            onChange={(e) => {
                                                                console.log("sagin", e)
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}
                                                        >
                                                            <option value="per month">per month</option>
                                                            <option value="per second">per second</option>
                                                            <option value="per minute">per minute</option>
                                                            <option value="per hour">per hour</option>
                                                            <option value="per day">per day</option>
                                                        </select>
                                                    </div> */}
                                {/* Peak IO rate */}
                                {/* <div className="col-md-6 px-3">
                                                        <label htmlFor="PeakIOrateValue" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Peak IO rate Value
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control custom-form text-field font-14 font-medium"
                                                            id="PeakIOrateValue"
                                                            value={values.PeakIOrateValue}
                                                            name={"PeakIOrateValue"}
                                                            onChange={(e) => {
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}

                                                        />
                                                    </div> */}

                                {/* <div className="col-md-6 px-3">
                                                        <label htmlFor="PeakIorateunit" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Unit
                                                        </label>
                                                        <select
                                                            className="form-select custom-form font-semibold font-14 form-select-sm"
                                                            id="PeakIorateunit"
                                                            style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                            value={values.PeakIorateunit}
                                                            name={"PeakIorateunit"}
                                                            onChange={(e) => {
                                                                console.log("sagin", e)
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}
                                                        >
                                                            <option value="per month">per month</option>
                                                            <option value="per second">per second</option>
                                                            <option value="per minute">per minute</option>
                                                            <option value="per hour">per hour</option>
                                                            <option value="per day">per day</option>
                                                        </select>
                                                    </div> */}
                                {/* Duration of peak IO activity */}
                                {/* <div className="col-md-6 px-3">
                                                        <label htmlFor="PeakDurationValue" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Duration of peak IO activity Value
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control custom-form text-field font-14 font-medium"
                                                            id="PeakDurationValue"
                                                            value={values.PeakDurationValue}
                                                            name={"PeakDurationValue"}
                                                            onChange={(e) => {
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}

                                                        />
                                                    </div> */}

                                {/* <div className="col-md-6 px-3">
                                                        <label htmlFor="PeakDurationunit" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Unit
                                                        </label>
                                                        <select
                                                            className="form-select custom-form font-semibold font-14 form-select-sm"
                                                            id="PeakDurationunit"
                                                            style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                            value={values.PeakDurationunit}
                                                            name={"PeakDurationunit"}
                                                            onChange={(e) => {
                                                                console.log("sagin", e)
                                                                onChangeOfConfiguration(e, values.instanceName);
                                                            }} disabled={isViewer}
                                                        >
                                                            <option value="hours per month" selected>hours per month</option>

                                                        </select>
                                                    </div> */}


                            </>}
                        </>)}

                </div>
            </div>
        );
    } catch (error) {
        console.log(error, "error in the RDS");
    }
}