
//PS_02- Import statements
import { useEffect, useState } from "react"
import Modularization from "./Modularization"
import SelfService from "../SelfService"
import { useLocation, useNavigate } from "react-router-dom";
import { fetchAllCloudProvider, fetchAllModule, fetchCategoryData } from "../../../Service/api";
import { useModuleContext } from "../../../ContextProvider/ModulesContxt";
import Loader from "../../loader/loader";
import WorkbenchPopup from "../../Organization/Workbench/WorkBench/PopupMaintanence/WorkBenchPopUp";

function ServiceConfigurationMain() {

    //PS_04 - Utilize context variables
    const {
        modules, setModules,
        versions, setVersions,
        selectedVersion, setSelectedVersion,
        searchTerm, setSearchTerm,
        importModulePopup, setImportModulePopup,
        providerName, setProviderName,
        selectedProviderId, setSelectedProviderId,
        selectedProviderNamee, setSelectedProviderNamee,
        cloudProviderData,
        setCloudProviderData,
        categoryData, setCategoryData,
        reload, setReload


    } = useModuleContext()

    const location = useLocation()

    //PS_09 - State variable declaration

    const userId = localStorage.getItem('userId')
    const [activeTab, setActiveTab] = useState("Modules")
    const navigate = useNavigate()
    const [search, setSearch] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const selectedProviderName = cloudProviderData.find(provider => provider.providerId === selectedProviderId)?.providerName;
    const [error, setError] = useState("")
    const [showLoader, setShowLoader] = useState(false)
    const [importModuleId, setImportModuleId] = useState("")
    const [importServicePopup, setImportServicePopup] = useState(false)
    const [popupMsg, setPopUpMsg] = useState({ "Msg": "", show: false, success: false })
    const [moduleName, setModuleName] = useState('');
    const [dropdownModules, setDropdownModules] = useState([])
    const flattenedModules = modules?.reduce((acc, curr) => {
        return acc.concat(curr.module);
    }, []);



    //PS_105-PS_107
    // Effect to find module name based on selected moduleId
    useEffect(() => {
        if (importModuleId) {
            const selectedModule = flattenedModules.find(module => module.moduleId === importModuleId);
            if (selectedModule) {
                setModuleName(selectedModule.moduleName);
            } else {
                setModuleName(''); // Reset on no match
            }
        } else {
            setModuleName(''); // Reset when no ID is selected
        }
    }, [importModuleId, flattenedModules]);


    //PS_108- Triggers whne active tab changes
    useEffect(() => {
        if (location?.state?.activeTab) {
            let data = location?.state?.activeTab
            setActiveTab(data)
        }
        // Clear location.state after setting the active tab
        window.history.replaceState({}, document.title, window.location.pathname);


    }, [])

    //PS_109 - Handles to fetch all modules
    useEffect(() => {
        getAllModules();

    }, [selectedVersion, selectedProviderId, selectedCategory, reload,search]);

    //PS_110- Handles search functionality
    const searchRecord = (e) => {
        const newValue = e.target.value;
        setSearch(newValue);

        if (!newValue) {
            setShowLoader(true); // Show loader when input is cleared
            getAllModules("");   // Call the API without search term to reset the results
        } else {
            setShowLoader(false); // Do not show loader when typing a search term
            getAllModules(newValue);
        }
    };


    //PS_63- Fetch provider data based on category
    useEffect(() => {
        getCloudProviderData()

    }, [])

    //PS_84 - Fetch category data based on providerID selected
    useEffect(
        () => {
            fetchAllCategoryData()
        }, [selectedProviderId]
    )

    //PS_64-PS_73 - Fetches cloud data
    const getCloudProviderData = async () => {
        try {
            const payload = {
                "userId": userId,
                "orgId": "", // Assuming orgId is intentionally left empty
                "type": "general"
            };

            const response = await fetchAllCloudProvider(payload); // Replace with your actual API call function
            console.log(response, "response");

            if (response.status === 200) {
                setCloudProviderData(response.data.data);

                console.info(response.data.data, "This is the cloud provider data")

                for (let values of response.data.data) {

                    if (values?.providerName == 'AWS') {
                        console.log("Inside the data of the self service")
                        setSelectedProviderId(values?.providerId)
                    }

                }

                // Store the response in a state variable
            } else {
                console.error("Error fetching cloud provider data:", response.data.body.message);
            }
        } catch (error) {
            console.error("API Error:", {
                errorName: error.name,
                errorServiceName: "Front-end",
                errorFunctionName: "getCloudProviderData",
                errorSource: "frontend",
                errorDescription: "fetch failed: " + error.message
            });
        }
    };

    //PS_86-PS_94 -Fetches category data
    const fetchAllCategoryData = async () => {
        try {
            const payload = {
                "cloud_provider_id": selectedProviderId,
                "userId": userId
            }
            const response = await fetchCategoryData(payload);
            if (response.status === 200) {
                console.log(response, "resp")
                setCategoryData(response.data.body.data);
            } else {
                throw new Error('Error fetching category data');
            }
        } catch (err) {
            //postErrorLog(err.message || err.toString());
            setError(err);
        }
    };

    //PS_111 - Handle tab switch
    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
        setSelectedCategory("")
        setSearch("")
    };

    //PS_112 - Handles submit functionality
    const handleSubmit = (e) => {
        e.preventDefault();

        const closeButton = document.getElementById('close');
        if (closeButton) {
            closeButton.click();
        }


        navigate('/ImportServiceForm', {
            state:
                { importModuleId: importModuleId, importModuleName: moduleName, selectedProviderId: selectedProviderId, selectedProviderName: selectedProviderName }
        });
    };


    //PS_12-PS_31
    const getAllModules = async (searchTerm = search) => {
        const payload = {
            "userId": userId,
            "providerId": selectedProviderId,
            "categoryId": selectedCategory || "All",
            "version": selectedVersion || "All",
            "search": searchTerm
        }
        try {

            if (!searchTerm) {
                setShowLoader(true);
            }
            const response = await fetchAllModule(payload);
            setShowLoader(false)

            console.log(response, "response for module")
            if (response.status == 200) {

                const modulesData = response.data.data.chart;
                // Set modules state with the retrieved data
                setModules(modulesData);
                setReload(false)
                setDropdownModules(response.data.data.dropdown)
                
                const uniqueVersions = [...new Set(response.data.data.version)].filter(version => version !== '');
                console.log("uniqueVersionsuniqueVersions",uniqueVersions);
                
                setVersions(uniqueVersions)
                
                
            }

        } catch (error) {
            console.error('Error fetching modules', error);
            setShowLoader(false)

        }
    };



    console.log(dropdownModules, "dropdownmodules")
    console.log(activeTab, "activeTab")
    console.log(location, "location")
    console.log(location.state, "locationState");
    console.log(selectedProviderId,"selectedProviderId")
    console.log(selectedCategory,"selectedCategory");
    console.log(versions,"versions");
    console.log(search,"searchTerm");


    






    return (
        <>

            <>
                {
                    popupMsg.show == true && popupMsg.Msg != "" ?
                        <WorkbenchPopup
                            popupMsg={popupMsg}
                            setPopUpMsg={setPopUpMsg}
                        />
                        : <></>
                }

                {/* header starts */}
                {/* header ends */}
                {showLoader ?
                    <Loader /> :
                    <div className="container-fluid custom-container right-container-scroll">
                        {/* Slide title start here*/}
                        <div className="d-xl-flex justify-content-between align-items-center service-configurations-title-bar py-3 pt-4 mt-2">
                            <h2 className="font-20 font-bold black-v2 mb-0">
                                Service Configurations
                            </h2>
                            <div className="d-flex justify-content-center flex-nowrap gap-3">

                                {/* dropdown */}
                                <div className="dropdown">
                                    <button
                                        type="button"
                                        className="btn custom-dropdown d-flex justify-content-between align-items-center gap-3 font-12 font-regular color-black shadow-none"
                                        id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <span className="d-flex">
                                            {selectedProviderName === "AWS" ? ( // Check against provider name
                                                <>
                                                    <img
                                                        src="images/aws-black-icon.svg"
                                                        alt="aws-img"
                                                        className="me-1 icon-width light-theme-img"
                                                    />
                                                    <img
                                                        src="images/aws-logo-dark.svg"
                                                        alt="aws-img"
                                                        className="me-1 icon-width dark-theme-img"
                                                    />
                                                </>
                                            ) : (
                                                <img
                                                    src="images/azure-logo.svg"
                                                    alt="azure-img"
                                                    className="me-1 icon-width"
                                                />
                                            )}
                                            {selectedProviderName} {/* Display the selected provider name */}
                                        </span>
                                        <img src="images/drop-down-arrow.svg" alt="dropdown-arrow" />
                                    </button>
                                    <ul
                                        className="dropdown-menu body-bg access-dropdown w-100"
                                        aria-labelledby="dropdownMenuButton1"
                                    >
                                        {cloudProviderData.map((provider) => (
                                            <li
                                                key={provider.providerId}
                                                className="font-regular color-black font-12 p-2 custom-list"
                                                onClick={() => {
                                                    setSelectedProviderId(provider.providerId)
                                                    setSelectedCategory("")
                                                }
                                                } // Set ID based on selection

                                            >
                                                <span className="d-flex align-items-center">
                                                    {provider.providerName === "AWS" ? ( // Check against provider name
                                                        <>
                                                            <img
                                                                src="images/aws-black-icon.svg"
                                                                alt="aws-img"
                                                                className="me-1 icon-width light-theme-img"
                                                            />
                                                            <img
                                                                src="images/aws-logo-dark.svg"
                                                                alt="aws-img"
                                                                className="me-1 icon-width dark-theme-img"
                                                            />
                                                        </>
                                                    ) : (
                                                        <img
                                                            src="images/azure-logo.svg"
                                                            alt="azure-logo"
                                                            className="me-1 icon-width"
                                                        />
                                                    )}
                                                    {provider.providerName} {/* Display the provider name */}
                                                </span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>

                                {/* dropdown */}
                                <div className="input-group title-dropdown">
                                    <select
                                        name="region"
                                        id="select-category"
                                        className="form-select font-14 font-regular black-v2"
                                        value={selectedCategory}
                                        onChange={(e) => {

                                            setSelectedCategory(e.target.value)
                                            setSelectedVersion("")
                                        }}
                                    >
                                        <option value="" >Select Category</option>
                                        {categoryData.map((category) => (
                                            <option key={category.serviceCategoryId} value={category.serviceCategoryId}>
                                                {category.serviceCategoryName}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                {/* dropdown */}
                                {activeTab == "Modules" && (
                                    <div className="input-group title-dropdown d-flex align-items-center gap-3">
                                     
                                        <select
                                            name="versions"
                                            id="version"
                                            className="form-select font-14 font-regular black-v2"
                                            value={selectedVersion}
                                            onChange={(e) => { setSelectedVersion(e.target.value) }}

                                        >
                                            <option value=""
                                                className="custom-nav-scrl "
                                            >
                                                Select Version</option>
                                            {versions.map((version) => (
                                                <option key={version} value={version}>
                                                    {version}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                )}

                                {/* search */}
                                <div className="input-group input-search custom-border-box custom-search-box">
                                    <input
                                        type="search"
                                        className="px-3 form-control common-bg border-0 custom-search gray-v1"
                                        placeholder="Search"
                                        value={search}
                                        onChange={(e) => {
                                            searchRecord(e)
                                        }}
                                    />
                                    <button className="btn border-start-0 custom-search " type="button"
                                    // onClick={getAllService}
                                    >
                                        <img src="images/search-icon.svg" alt="search" />
                                    </button>
                                </div>
                                {/* search */}


                                {activeTab == "Modules" && (
                                    <button type="button" className="button primary-button text-nowrap"
                                        onClick={(() => {

                                            setImportModulePopup(true)
                                        })}

                                    >
                                        Import Module
                                    </button>

                                )}
                                {activeTab == "Services" && (
                                    <button type="button" className="button primary-button text-nowrap"
                                        onClick={(() => {
                                            setImportServicePopup(true)

                                        })}                                 

                                    >
                                        Import Service
                                    </button>
                                )}


                                {/* add work load btn */}
                            </div>
                        </div>

                        {/* Slide title end here*/}
                        {/* Page Content Start here */}
                        <div className="row">
                            <div className="notification-tab-parent">
                                <div className="notification-tab-title mb-2">
                                    <ul
                                        className="nav nav-tabs custom-border-bottom"
                                        id="myTab"
                                        role="tablist"
                                    >
                                        <li className="nav-item" role="presentation">
                                            <button
                                                className={`nav-link ${activeTab === "Modules" ? "active" : ""}`}
                                                onClick={() => handleTabClick("Modules")}
                                                id="module-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#module-tab-pane"
                                                type="button"
                                                role="tab"
                                                aria-controls="module-tab-pane"
                                                aria-selected="true"
                                            >
                                                Module
                                            </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button
                                                className={`nav-link ${activeTab === "Services" ? "active" : ""}`}
                                                onClick={() => handleTabClick("Services")}
                                                id="services-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#services-tab-pane"
                                                type="button"
                                                role="tab"
                                                aria-controls="services-tab-pane"
                                                aria-selected="false"
                                            >
                                                Services
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="notification-tab-content mt-4">
                                    <div className="tab-content" id="myTabContent">
                                        {activeTab == "Modules" &&
                                            (
                                                <Modularization
                                                    search={search}
                                                    setSearch={setSearch}
                                                    categoryData={categoryData}
                                                    setCategoryData={setCategoryData}
                                                    selectedCategory={selectedCategory}
                                                    setSelectedCategory={setSelectedCategory}
                                                    cloudProviderData={cloudProviderData}
                                                    setCloudProviderData={setCloudProviderData}
                                                    selectedProviderId={selectedProviderId}
                                                    setSelectedProviderId={setSelectedProviderId}
                                                    selectedProviderName={selectedProviderName}
                                                    getAllModules={getAllModules}


                                                />

                                            )
                                        }

                                        {activeTab === "Services" && (
                                            <SelfService
                                                search={search}
                                                setSearch={setSearch}
                                                categoryData={categoryData}
                                                setCategoryData={setCategoryData}
                                                selectedCategory={selectedCategory}
                                                setSelectedCategory={setSelectedCategory}
                                                cloudProviderData={cloudProviderData}
                                                setCloudProviderData={setCloudProviderData}
                                                selectedProviderId={selectedProviderId}
                                                setSelectedProviderId={setSelectedProviderId}
                                                selectedProviderName={selectedProviderName}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Import Service popup */}
                        {importServicePopup && (
                            <div
                                className="modal fade show"
                                tabIndex={-1}
                                role="dialog"
                                style={{ display: 'block', backgroundColor: "rgba(0,0,0,0.7)" }}
                            >
                                <div className="modal-dialog modal-dialog-centered justify-content-center">
                                    <div className="modal-content border-0">
                                        <div className="modal-header border-0 p-4" style={{ paddingTop: "5%" }}>
                                            <h1
                                                className="modal-title font-20 font-bold black-v2"
                                                id="staticBackdropLabel"
                                            >
                                                Import Service
                                            </h1>
                                            <button
                                                type="button"
                                                className="btn shadow-none"
                                                onClick={() => { setImportServicePopup(false); }}
                                                aria-label="Close"
                                                id='close'
                                            >
                                                <img
                                                    src="images/close-icon.svg"
                                                    alt="close-icon"
                                                    className="icon-hover shadow-none modal-close-filter"
                                                />
                                            </button>
                                        </div>
                                        <div className="modal-body px-4 pt-0">
                                            <form onSubmit={handleSubmit}>
                                                <div className="title-dropdown">
                                                    <label className="form-label font-14 font-medium black-v2" htmlFor="category">
                                                        Module <span className="danger-red">*</span>
                                                    </label>
                                                    <select
                                                        id="select-category"
                                                        className="form-select font-14 font-regular black-v2 color-black"
                                                        value={importModuleId}
                                                        onChange={(e) => { setImportModuleId(e.target.value); }}
                                                    >
                                                        <option className="color-black" value="">Select Module</option>
                                                        {dropdownModules.map((moduleData) => (
                                                            <option key={moduleData.moduleId} value={moduleData.moduleId}>
                                                                {moduleData.moduleName}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>

                                                <div className="d-flex justify-content-end align-items-center mt-4 mb-2 gap-3">
                                                    <button
                                                        type="button"
                                                        className="button outline-button text-nowrap py-12"
                                                        onClick={() => { setImportServicePopup(false); }}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        className="button primary-button text-nowrap px-4 py-12"
                                                    >
                                                        Proceed with AI
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {/* Import Service popup end */}

                    </div>

                }
            </>

        </>
    )
}

export default ServiceConfigurationMain
