import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getActivityLogs } from '../../Service/api';
import Loader from "../loader/loader";

function ViewUserActivityLogs() {
  const location = useLocation();
  const navigate = useNavigate();

  const { resourceId, userId, userName } = location.state || {};

  const [logs, setLogs] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [count, setCount] = useState(0);
  const [showLoader, setShowLoader] = useState(false);

  // Effect for initial load
  useEffect(() => {
    if (resourceId && userId) {
      fetchLogs(0); // Initial fetch with count 0
    } else {
      setError('Missing required parameters');
    }
  }, []); // Empty dependency array for initial load

  // Effect for subsequent loads
  useEffect(() => {
    if (count > 0) {
      fetchLogs(count);
    }
  }, [count]);

  const fetchLogs = async (currentCount) => {
    if (loading) return; // Prevent multiple simultaneous requests
  
    try {
      setLoading(true);
      setError(null);
      const payload = {
        resId: resourceId,
        userId: userId,
        count: currentCount
      };
  
      setShowLoader(true);
      const response = await getActivityLogs(payload);
      setShowLoader(false);
      if (response.data && response.data.body && response.data.body.success) {
        setLogs(prevLogs => {
          if (currentCount === 0) {
            // Initial load, replace existing logs
            return response.data.body.data;
          } else {
            // Subsequent loads, append new logs
            return [...prevLogs, ...response.data.body.data];
          }
        });
        setTotalCount(response.data.body.count);
      } else {
        console.error('Unexpected response structure:', response);
        setError(response.data?.body?.message || 'Failed to fetch logs');
      }
    } catch (error) {
      console.error('Error fetching logs:', error);
      setError('An unexpected error occurred');
    } finally {
      setLoading(false);
      setShowLoader(false);
    }
  };

  const handleLoadMore = () => {
    setCount(prevCount => prevCount + 10);
  };

  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page
  };

  if (showLoader) {
    return <Loader />;
  }

  return (
    <div className="container-fluid custom-container" style={{height:"100vh"}}>
      <div className="d-flex justify-content-between align-items-center mt-4 pt-2 pb-2">
        <h2 className="font-20 font-bold black-v2 mb-0">
     
                            <img
                                src="images/back-arrow.svg"
                                alt="back-arrow"
                                title="Back"
                                className="icon-filter me-4"
                                onClick={() => { navigate('/ManageUsers') }}
                            />
                     
          Activity Logs - {userName || 'User'}
        </h2>
      </div>
      
      {logs.length === 0 ? (
        <div className="no-record-found">
          <img
            src="images/no-records-found.png"
            alt="No records found"
            className="no-record-icon"
          />
        </div>
      ) : (
        <>
          <div className="table-responsive mt-4 table-border">
            <table className="table custom-table">
              <thead>
                <tr>
                  <th className="text-start border-0">Date &amp; Time</th>
                  <th className="text-start border-0">Workloads</th>
                  <th className="text-start border-0">Activity</th>
                </tr>
              </thead>
              <tbody>
                {logs.map((log, index) => (
                  <tr key={index}>
                    <td className="text-start align-middle">{log.dateTime}</td>
                    <td className="text-start">
                      <p className="mb-1 font-medium">{log.workloadName}</p>
                      <p className="mt-1 mb-0">{log.organizationName}</p>
                    </td>
                    <td className="text-start align-middle">{log.activitylogs}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-between align-items-center my-3">
            <p className="font-14 font-regular secondary-text-color mb-0">
              # of Records{" "}
              <span className="font16 font-medium color-black">
                <span>{logs.length}</span> out of <span>{totalCount}</span>
              </span>
            </p>
          </div>
          
<div className="d-block text-center my-3">
  {logs.length < totalCount && (
    <>
      {showLoader ? (
        <Loader />
      ) : (
        <button
          type="button"
          className="button secondary-outline-button text-nowrap"
          onClick={handleLoadMore}
        >
          Load More
        </button>
      )}
    </>
  )}
</div>
        </>
      )}
    </div>
  );
}

export default ViewUserActivityLogs;