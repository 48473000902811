// values["dailyLogsIngested"] = "1";
// values["basicLogsIngested"] = "1";
// values["searchJobsTotalNumberOfQueries"] = "1";
// values["searchJobsTotalScannedPerQueries"] = "1";

export default function AzureLogAnalytics({
  values,
  onChangeOfConfiguration,
  isViewer,
}) {
  try {
    return (
      <div
        id={`${values.instanceName.split(" ").join("_")}`}
        className="accordion-collapse collapse"
      >
        <div className="accordioan-body p-0 ">
          <table className="table table-borderless cg-grid font-14 font-semibold mb-0">
            <tbody>
              <tr className="ms-acc-data-align">
                <td colSpan={3}>
                  <div
                    className="row py-4 mx-4 px-5"
                    hidden={values.isPriceAvailable == "No" ? false : true}
                  >
                    <div className="col-md-6 px-3">
                      <span className="font-semibold font-14 text-danger">
                        {values.isPriceAvailable == "No"
                          ? "The Price is not available for the region / the configuration that you have selected might be not available in the region"
                          : null}
                      </span>
                    </div>
                  </div>
                  <div className="row py-4 mx-4 px-5">
                    <div>
                      <label
                        htmlFor="region"
                        className="form-label font-14 font-medium black-v2 mb-3"
                      >
                        Region
                      </label>
                      <select
                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                        id="region"
                        style={{
                          marginTop: "1px",
                          paddingTop: "8px",
                          paddingBottom: "8px",
                        }}
                        value={values.region}
                        name={"region"}
                        onChange={(e) => {
                          onChangeOfConfiguration(e, values.instanceName);
                        }}
                        disabled={isViewer}
                      >
                        <optgroup label="United States">
                          <option value="us-central">Central US</option>
                          <option value="us-east">East US</option>
                          <option value="us-east-2">East US 2</option>
                          <option value="us-north-central">
                            North Central US
                          </option>
                          <option value="us-south-central">
                            South Central US
                          </option>
                          <option value="us-west-central">
                            West Central US
                          </option>
                          <option value="us-west">West US</option>
                          <option value="us-west-2">West US 2</option>
                          <option value="us-west-3">West US 3</option>
                        </optgroup>
                        <optgroup label="United Kingdom">
                          <option value="united-kingdom-south">UK South</option>
                          <option value="united-kingdom-west">UK West</option>
                        </optgroup>
                        <optgroup label="United Arab Emirates">
                          <option value="uae-central">UAE Central</option>
                          <option value="uae-north">UAE North</option>
                        </optgroup>
                        <optgroup label="Switzerland">
                          <option value="switzerland-north">
                            Switzerland North
                          </option>
                          <option value="switzerland-west">
                            Switzerland West
                          </option>
                        </optgroup>
                        <optgroup label="Sweden">
                          <option value="sweden-central">Sweden Central</option>
                          <option value="sweden-south">Sweden South</option>
                        </optgroup>
                        <optgroup label="Spain">
                          <option value="spain-central">Spain Central</option>
                        </optgroup>
                        <optgroup label="Qatar">
                          <option value="qatar-central">Qatar Central</option>
                        </optgroup>
                        <optgroup label="Poland">
                          <option value="poland-central">Poland Central</option>
                        </optgroup>
                        <optgroup label="Norway">
                          <option value="norway-east">Norway East</option>
                          <option value="norway-west">Norway West</option>
                        </optgroup>
                        <optgroup label="Mexico">
                          <option value="mexico-central">Mexico Central</option>
                        </optgroup>
                        <optgroup label="Korea">
                          <option value="korea-central">Korea Central</option>
                          <option value="korea-south">Korea South</option>
                        </optgroup>
                        <optgroup label="Japan">
                          <option value="japan-east">Japan East</option>
                          <option value="japan-west">Japan West</option>
                        </optgroup>
                        <optgroup label="Italy">
                          <option value="italy-north">Italy North</option>
                        </optgroup>
                        <optgroup label="Israel">
                          <option value="israel-central">Israel Central</option>
                        </optgroup>
                        <optgroup label="India">
                          <option value="central-india">Central India</option>
                          <option value="south-india">South India</option>
                          <option value="west-india">West India</option>
                        </optgroup>
                        <optgroup label="Germany">
                          <option value="germany-north">Germany North</option>
                          <option value="germany-west-central">
                            Germany West Central
                          </option>
                        </optgroup>
                        <optgroup label="France">
                          <option value="france-central">France Central</option>
                          <option value="france-south">France South</option>
                        </optgroup>
                        <optgroup label="Europe">
                          <option value="europe-north">North Europe</option>
                          <option value="europe-west">West Europe</option>
                        </optgroup>
                        <optgroup label="Canada">
                          <option value="canada-central">Canada Central</option>
                          <option value="canada-east">Canada East</option>
                        </optgroup>
                        <optgroup label="Brazil">
                          <option value="brazil-south">Brazil South</option>
                          <option value="brazil-southeast">
                            Brazil Southeast
                          </option>
                        </optgroup>
                        <optgroup label="Australia">
                          <option value="australia-central">
                            Australia Central
                          </option>
                          <option value="australia-central-2">
                            Australia Central 2
                          </option>
                          <option value="australia-east">Australia East</option>
                          <option value="australia-southeast">
                            Australia Southeast
                          </option>
                        </optgroup>
                        <optgroup label="Asia Pacific">
                          <option value="asia-pacific-east">East Asia</option>
                          <option value="asia-pacific-southeast">
                            Southeast Asia
                          </option>
                        </optgroup>
                        <optgroup label="Africa">
                          <option value="south-africa-north">
                            South Africa North
                          </option>
                          <option value="south-africa-west">
                            South Africa West
                          </option>
                        </optgroup>
                      </select>
                    </div>
                    <div className="col-md-6 px-3">
                      <label
                        htmlFor="dailyLogsIngested"
                        className="form-label font-14 font-medium black-v2 mb-3"
                        style={{ marginTop: "20px" }}
                      >
                        Analytics Logs - Daily logs ingested (GB/day)
                      </label>
                      <input
                        type="text"
                        style={{paddingTop :"10px"}}
                        className=" form-control  custom-form text-field font-14 font-medium"
                        id="dailyLogsIngested"
                        value={values.dailyLogsIngested}
                        name={"dailyLogsIngested"}
                        onChange={(e) => {
                          onChangeOfConfiguration(e, values.instanceName);
                        }}
                        disabled={isViewer}
                      />
                    </div>

                    <div className="col-md-6 px-3">
                      <label
                        htmlFor="basicLogsIngested"
                        className="form-label font-14 font-medium black-v2 mb-3"
                        style={{ marginTop: "20px" }}
                      >
                        Basic Logs - Per day (GB)
                      </label>
                      <input
                        type="text"
                        style={{paddingTop :"10px"}}
                        className=" form-control  custom-form text-field font-14 font-medium"
                        id="basicLogsIngested"
                        value={values.basicLogsIngested}
                        name={"basicLogsIngested"}
                        onChange={(e) => {
                          onChangeOfConfiguration(e, values.instanceName);
                        }}
                        disabled={isViewer}
                      />
                    </div>

                    <div className="col-md-6 px-3">
                      <label
                        htmlFor="searchJobsTotalNumberOfQueries"
                        className="form-label font-14 font-medium black-v2 mb-3"
                        style={{ marginTop: "20px" }}
                      >
                        Basic Logs Search Queries - Total number Of queries(day)
                      </label>
                      <input
                        type="text"
                        style={{paddingTop :"10px"}}
                        className=" form-control  custom-form text-field font-14 font-medium"
                        id="searchJobsTotalNumberOfQueries"
                        value={values.searchJobsTotalNumberOfQueries}
                        name={"searchJobsTotalNumberOfQueries"}
                        onChange={(e) => {
                          onChangeOfConfiguration(e, values.instanceName);
                        }}
                        disabled={isViewer}
                      />
                    </div>
                    <div className="col-md-6 px-3">
                      <label
                        htmlFor="searchJobsTotalScannedPerQueries"
                        className="form-label font-14 font-medium black-v2 mb-3"
                        style={{ marginTop: "20px" }}
                      >
                        Basic Logs Search Queries - Data scanned per query (GB)
                      </label>
                      <input
                        type="text"
                        style={{paddingTop :"10px"}}
                        className=" form-control  custom-form text-field font-14 font-medium"
                        id="searchJobsTotalScannedPerQueries"
                        value={values.searchJobsTotalScannedPerQueries}
                        name={"searchJobsTotalScannedPerQueries"}
                        onChange={(e) => {
                          onChangeOfConfiguration(e, values.instanceName);
                        }}
                        disabled={isViewer}
                      />
                    </div>
                  </div>
                  { }
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  } catch (error) {
    //console.log(error);
  }
}
