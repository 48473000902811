import { createContext, useEffect, useState } from "react";




const DatabricksContext = createContext();


const DatabricksContextProvider = ({ children }) => {

    const [utilisationChart, SetutilisationChart] = useState([])
    const [trendsChart, SetTrendsChart] = useState([])
    const [cardData, setCardData] = useState({
        actualCost: 0,
        forecastedCost: 0,
        commitments: 0,
        overage: 0,
      })
    const [gridData, setGridData] = useState([])

    return (
       < DatabricksContext.Provider value={{utilisationChart, SetutilisationChart,trendsChart, SetTrendsChart,cardData, setCardData,gridData, setGridData}}>
       {
        children
       }
       </DatabricksContext.Provider>
    )
}

export { DatabricksContextProvider, DatabricksContext}