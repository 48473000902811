
import React from 'react';

const ResourceCards = (
    { resource,
        handleResourceClick,
        expandedResourceId,
        setShowDataResourcePopup,
        nameCount,
        handleResourceNameBtnClick,
        handleAddDataClick,
        handleDeleteResource
    }) => {
    const isExpanded = expandedResourceId === resource.resourceName;

    console.log(resource, "resource in resourceCards")

    return (
        <div className="accordion my-3" id="accordion2">
            <div className="accordion-item border-0 mb-2 background-white">
                <div className="template-container py-3 px-3 position-relative">
                    <div className="accordion-header d-flex align-items-center" id={`heading-${resource.resourceName}`}>
                        <button
                            className={`cust-acc-btn d-flex align-items-center gap-2 font-14 btn border-0 me-2 ${isExpanded ? '' : 'collapsed'}`}
                            type="button"
                            onClick={() => handleResourceClick(resource.resourceName)}
                            aria-expanded={isExpanded}
                            aria-controls={`collapse-${resource.resourceName}`}
                        >
                            {/* Empty span to occupy space for the ::before pseudo-element */}
                            <span></span>
                        </button>

                        <span className="font-14 font-medium link-text-v2">
                            {resource.resourceName} (<span>{nameCount}</span>)
                        </span>
                    </div>
                    <div
                        id={`collapse-${resource.resourceName}`}
                        className={`accordion-collapse collapse${isExpanded ? ' show' : ''}`}
                        aria-labelledby={`heading-${resource.resourceName}`}
                    >
                        <div className="accordion-body px-5">
                            <div className="d-flex flex-wrap gap-2 align-items-center">
                                {resource.resources.map((res, idx) => (
                                    <button
                                        key={idx}
                                        type="button"
                                        className="table-env-btn active custom-tags"
                                        onClick={() => {
                                            //   setShowDataResourcePopup(true);
                                            handleResourceNameBtnClick(resource.resourceName, res)
                                        }}
                                    >
                                        {res.Name}
                                    </button>
                                ))}
                                <button
                                    type="button"
                                    className="table-env-btn active add-tag-btn"
                                    onClick={() => handleAddDataClick(resource.resourceName)}
                                >
                                    <img className="me-2" src="images/plus-blue.svg" alt="plus-icon" />
                                    Add Data
                                </button>
                            </div>
                        </div>
                    </div>

                    <button className="border-0 delete-icon-circle d-flex align-items-center justify-content-center"
                        onClick={() => handleDeleteResource(resource)}
                    >
                        <img src="images/delete-icon.svg" alt="delete-icon" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ResourceCards;