import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import MonacoEditor from "react-monaco-editor";
import Editor from "@monaco-editor/react"; // Add this line
import {
  getAllProviders,
  getArchiTypeByAi,
  createTemplateKb,
  updateTemplateKb,
} from "../../Service/api";
import WorkbechPopup from "../Organization/Workbench/WorkBench/PopupMaintanence/WorkBenchPopUp";


//PS_01 to PS_10 Declaring state variables
const TemplateKbForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isEditMode, setIsEditMode] = useState(false);
  const [isFormEditable, setIsFormEditable] = useState(true);
  const [providers, setProviders] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [initialFormData, setInitialFormData] = useState({});
  const [showLoader, setShowLoader] = useState(false)
  const [architectureTypes, setArchitectureTypes] = useState([]);
  const [formData, setFormData] = useState({
    templateName: "",
    cloudProviderId: "",
    architectureTypeId: "",
    business: "",
    architecture: "",
    terraformCode: "",
    cloudformationCode: "",
    armCode: "",
    templateKbId: "",
  });
  const [activeTabs, setActiveTabs] = useState(["terraform", "cloudformation"]);
  const [popupMsg, setPopUpMsg] = useState({
    Msg: "",
    show: false,
    success: false,
  });

  //PS_11 to PS_14  Use effects for rendering
  useEffect(() => {
    fetchProviders();
  }, []);

  useEffect(() => {
    const templateDetails = location.state?.template;
    if (templateDetails) {
      setIsEditMode(true);
      setIsFormEditable(false);
      populateFormData(templateDetails);
    }
  }, [location.state]);

  useEffect(() => {
    if (formData.cloudProviderId) {
      fetchArchitectureTypes();
    }
  }, [formData.cloudProviderId]);

  useEffect(() => {
    validateForm();
  }, [formData]);

 
//PS_15 to PS_16 Function for prepopulating data
  
  const populateFormData = useCallback((templateDetails) => {
    if (!templateDetails) return;

    const populatedData = {
      templateName: templateDetails.template_kb_name || '',
      cloudProviderId: templateDetails.cloudProviderId || '',
      architectureTypeId: templateDetails.architectureTypeId || '',
      business: templateDetails.businessDescription || '',
      architecture: templateDetails.architectureDescrption || '',
      terraformCode: templateDetails.terraformCode || '',
      cloudformationCode: templateDetails.cloudformationCode || '',
      armCode: templateDetails.armCode || '',
      templateKbId: templateDetails.templateKbId || '',
    };

    console.log(populatedData, "popsssssssss")

    setFormData(populatedData);
    setInitialFormData(populatedData);

    updateActiveTabs(templateDetails.cloudProviderId);
  }, []);


//PS_17 For updating tabs in editor
  const updateActiveTabs = (providerId) => {
    const selectedProvider = providers.find((p) => p.providerId === providerId);
    if (selectedProvider) {
      const newActiveTabs =
        selectedProvider.providerName === "AWS"
          ? ["terraform", "cloudformation"]
          : ["terraform", "arm"];
      setActiveTabs(newActiveTabs);
    }
  };

  //PS_18 Function for provider change 
  const handleCloudProviderChange = (e) => {
    const providerId = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      cloudProviderId: providerId,
      architectureTypeId: "",
    }));
    updateActiveTabs(providerId);
  };

  //PS_19 Input change function for form
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  //PS_20 Code chnage function for editor
  const handleCodeChange = (value, language) => {
    setFormData((prevData) => ({ ...prevData, [`${language}Code`]: value }));
  };

  //PS_21 to PS_25 function for submit data
  
  
  const handleSubmit = async () => {
    if (!isFormValid) return;
  
    const userId = localStorage.getItem('userId');
    const payload = {
      userId,
      template_kb_name: formData.templateName,
      cloudProviderId: formData.cloudProviderId,
      architectureTypeId: formData.architectureTypeId,
      businessDescription: formData.business,
      architectureDescrption: formData.architecture,
      cloudformationCode: formData.cloudformationCode,
      terraformCode: formData.terraformCode,
      armCode: formData.armCode,
    };
  
    if (isEditMode) {
      payload.templateKbId = formData.templateKbId;
    }
  
    try {
      const response = isEditMode 
        ? await updateTemplateKb(payload)
        : await createTemplateKb(payload);
  
  
      // Check if response has the expected structure
      if (response.data && response.data.body && response.data.body.success) {
        setPopUpMsg({
          ...popupMsg,
          Msg: isEditMode ? 'Template updated successfully' : 'Template created successfully',
          show: true,
          success: true
        });
        
                // setToastMessage();
        // setShowToast(true);
        setTimeout(() => navigate('/TemplateKbGrid'), 2000);
      } else {
        setPopUpMsg({...popupMsg , ["Msg"]:"Something went wrong" , ["show"]:true ,["success"]:false})
        console.error('Unexpected response structure:', response);
      }
    } catch (error) {
      console.error('Error saving template:', error);
      setPopUpMsg({...popupMsg , ["Msg"]:"Something went wrong" , ["show"]:true ,["success"]:false})
    }
  };
//PS_1.26 function to fetch providers

const fetchProviders = async () => {
  const userId = localStorage.getItem("userId");
  try {
    const response = await getAllProviders({
      userId,
      orgId: "",
      type: "general",
    });
    
    // Check if response.data exists and has the expected structure
    if (response.data && response.data.success) {
      setProviders(response.data.data);
    } else {
      console.error("Unexpected response structure:", response);
    }
  } catch (error) {
    console.error("Error fetching providers:", error);
  }
};


//PS_27 Function to fetch architecture types

const fetchArchitectureTypes = async () => {
  const userId = localStorage.getItem("userId");
  try {
    const response = await getArchiTypeByAi({
      userId,
      orgId: "",
      providerId: formData.cloudProviderId,
      business: "Business description",
      archi: "archi description",
    });

    // Check if response.data exists and has the expected structure
    if (response.data && response.data.success) {
      setArchitectureTypes(response.data.data);
    } else {
      console.error("Unexpected response structure:", response);
    }
  } catch (error) {
    console.error("Error fetching architecture types:", error);
  }
};
//PS_28 Function to fetch validating form
  const validateForm = () => {
    const isCodeFilled = formData.terraformCode.trim() !== '' ||
                         formData.cloudformationCode.trim() !== '' ||
                         formData.armCode.trim() !== '';

    const isValid = formData.templateName.trim() !== '' &&
                    formData.cloudProviderId.trim() !== '' &&
                    formData.architectureTypeId.trim() !== '' &&
                    formData.business.trim() !== '' &&
                    formData.architecture.trim() !== '' &&
                    isCodeFilled;

    setIsFormValid(isValid);
  };

//PS_29 Function for iniatilizing the editor
  const handleEditorDidMount = (editor, monaco) => {
    // Set editor options
    editor.updateOptions({
      fontSize: 14,
      lineNumbers: "on",
      renderWhitespace: "all",
      tabSize: 2,
      minimap: { enabled: false },
      scrollBeyondLastLine: false,
      automaticLayout: true,
    });

    // Add custom keybindings
    editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyS, () => {
      // Custom save action
      console.log("Custom save action triggered");
      // You can call your save function here
    });

    // Set up custom theme (optional)
    monaco.editor.defineTheme("myCustomTheme", {
      base: "vs-dark",
      inherit: true,
      rules: [
        { token: "comment", foreground: "6A9955" },
        { token: "keyword", foreground: "569CD6" },
        // Add more token rules as needed
      ],
      colors: {
        "editor.background": "#1E1E1E",
        // Add more color customizations as needed
      },
    });
    monaco.editor.setTheme("myCustomTheme");

    // Add custom completion items
    monaco.languages.registerCompletionItemProvider("javascript", {
      provideCompletionItems: (model, position) => {
        const suggestions = [
          {
            label: "customFunction",
            kind: monaco.languages.CompletionItemKind.Function,
            insertText: "customFunction(${1:param1}, ${2:param2})",
            insertTextRules:
              monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
            documentation: "A custom function",
          },
          // Add more custom suggestions as needed
        ];
        return { suggestions: suggestions };
      },
    });

    // Set up error checking (for JavaScript as an example)
    const modelMarkers = [];
    editor.onDidChangeModelContent(() => {
      const model = editor.getModel();
      if (model) {
        monaco.editor.setModelMarkers(model, "owner", modelMarkers);
        const errorMarkers = monaco.editor.getModelMarkers({
          owner: "javascript",
        });
        modelMarkers.push(...errorMarkers);
      }
    });

    // Log when the editor is ready
    console.log("Editor is ready!");
  };


  //PS_31 Function to check the changes in form
  const isFormChanged = () => {
    return JSON.stringify(formData) !== JSON.stringify(initialFormData);
  };
  return (
    <>
     
      <div className="container-fluid custom-container">
        <div className="d-flex justify-content-between align-items-center pt-4 mt-2">
          <div className="d-flex align-items-center mb-3 mb-md-0">
            <a href="#" onClick={() => navigate('/TemplateKbGrid')}>
              <img src="images/back-arrow.svg" alt="back-arrow" title="Back" className="icon-filter" />
            </a>
            <h2 className="font-20 font-bold black-v2 mb-0 ms-3">
              {isEditMode ? formData.templateName : 'Add Template'}
            </h2>
          </div>
          {isEditMode && !isFormEditable && (
            <button
              type="button"
              className="button primary-button text-nowrap custom-btn-spacing"
              onClick={() => setIsFormEditable(true)}
            >
              <img src="images/btn-edit-icon.svg" alt="edit-icon" className="me-2" /> Edit
            </button>
          )}
        </div>
        {popupMsg.show && popupMsg.Msg !== "" && (
              <div
                style={{
                  position: "absolute",
                  top: "15%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 1000,
                  maxWidth: "80%",
                  width: "300px",
                }}
              >
                <WorkbechPopup popupMsg={popupMsg} setPopUpMsg={setPopUpMsg} />
              </div>
            )}


        <div className="col-md-10">
        <div className="row add-organization-form mt-4 pt-3">
          <div className="col-md-10">
            <div className="px-5">
              <div className="row kb-template-section">
                <div className="col-md-4 mb-4 pb-3">
                  <label className="form-label font-14 font-medium" htmlFor="templateName">
                    Template Name<span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    id="templateName"
                    name="templateName"
                    className="form-control custom-form"
                    placeholder="Enter Template Name"
                    value={formData.templateName}
                    onChange={handleInputChange}
                    disabled={!isFormEditable}
                  />
                </div>
                <div className="col-md-4 mb-4 pb-3">
                  <label className="form-label font-14 font-medium" htmlFor="cloud-provider">
                    Cloud Provider<span className="required">*</span>
                  </label>
                  <select
                    name="cloudProviderId"
                    id="cloud-provider"
                    className="form-select form-control custom-form"
                    value={formData.cloudProviderId}
                    onChange={handleCloudProviderChange}
                    disabled={!isFormEditable}
                  >
                    <option value="">Select Cloud Provider</option>
                    {providers.map(provider => (
                      <option key={provider.providerId} value={provider.providerId}>
                        {provider.providerName}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-4 mb-4 pb-3">
                  <label className="form-label font-14 font-medium" htmlFor="type-of-architecture">
                    Type of Architecture<span className="required">*</span>
                  </label>
                  <select
                    name="architectureTypeId"
                    id="type-of-architecture"
                    className="form-select form-control custom-form"
                    value={formData.architectureTypeId}
                    onChange={handleInputChange}
                    disabled={!isFormEditable}
                  >
                    <option value="">Select Architecture Type</option>
                    {architectureTypes.map(type => (
                      <option key={type.architectureTypeId} value={type.architectureTypeId}>
                        {type.architectureTypeName}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-10 mb-4 pb-3">
                  <label className="form-label font-14 font-medium" htmlFor="business">
                    Business<span className="required">*</span>
                  </label>
                  <textarea
                    className="form-control custom-form custom-textarea font-14 font-regular"
                    placeholder="Enter Business"
                    id="business"
                    name="business"
                    value={formData.business}
                    onChange={handleInputChange}
                    disabled={!isFormEditable}
                  />
                </div>
                <div className="col-md-10 mb-5 pb-1">
                  <label className="form-label font-14 font-medium" htmlFor="architecture">
                    Architecture<span className="required">*</span>
                  </label>
                  <textarea
                    className="form-control custom-form custom-textarea font-14 font-regular"
                    placeholder="Enter Architecture"
                    id="architecture"
                    name="architecture"
                    value={formData.architecture}
                    onChange={handleInputChange}
                    disabled={!isFormEditable}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>

        <div className="col-md-12">
        <div className="row">
          <div className="col-md-12">
            <div className="ps-5">
              <h3 className="font-14 font-medium mt-3 mb-2 black-v2">
                Service Configuration<span className="required">*</span>
              </h3>
              <p className="font-10 font-regular secondary-text-color">
                It is mandatory to add a valid code under any one of the tabs.
              </p>
              <div className="kb-template mb-5">
                <ul className="nav nav-pills d-flex gap-1 flex-nowrap mb-1" id="pills-tab" role="tablist">
                  {activeTabs.map((tab, index) => (
                    <li className="nav-item" role="presentation" key={tab}>
                      <button
                        className={`nav-link theme-tab font-14 secondary-text-color font-medium px-3 py-1 ${index === 0 ? 'active' : ''}`}
                        data-bs-toggle="pill"
                        data-bs-target={`#${tab}`}
                        type="button"
                        role="tab"
                        aria-selected={index === 0 ? "true" : "false"}
                      >
                        {tab === 'terraform' ? 'Terraform' : tab === 'cloudformation' ? 'Cloud Formation' : 'ARM'}
                      </button>
                    </li>
                  ))}
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div className="tab-pane fade show active" id="terraform" role="tabpanel">
                    <Editor
                      height="400px"
                      defaultLanguage="terraform"
                      value={formData.terraformCode}
                      onChange={(value) => handleCodeChange(value, 'terraform')}
                      options={{
                        readOnly: !isFormEditable,
                        minimap: { enabled: false },
                        scrollBeyondLastLine: false,
                      }}
                      onMount={handleEditorDidMount}
                    />
                  </div>
                  {activeTabs.includes('cloudformation') && (
                    <div className="tab-pane fade" id="cloudformation" role="tabpanel">
                      <Editor
                        height="400px"
                        defaultLanguage="json"
                        value={formData.cloudformationCode}
                        onChange={(value) => handleCodeChange(value, 'cloudformation')}
                        options={{
                          readOnly: !isFormEditable,
                          minimap: { enabled: false },
                          scrollBeyondLastLine: false,
                        }}
                        onMount={handleEditorDidMount}
                      />
                    </div>
                  )}
                  {activeTabs.includes('arm') && (
                    <div className="tab-pane fade" id="arm" role="tabpanel">
                      <Editor
                        height="400px"
                        defaultLanguage="json"
                        value={formData.armCode}
                        onChange={(value) => handleCodeChange(value, 'arm')}
                        options={{
                          readOnly: !isFormEditable,
                          minimap: { enabled: false },
                          scrollBeyondLastLine: false,
                        }}
                        onMount={handleEditorDidMount}
                      />
                    </div>
                  )}
                </div>
              </div>
              {isFormEditable && (
                <div className="d-flex align-items-center justify-content-between">
                  <button
                    type="button"
                    className="button cancel-button text-nowrap"
                    onClick={() => navigate('/TemplateKbGrid')}
                  >
                    Cancel
                  </button>
                  <button
            type="button"
            className="button primary-button text-nowrap"
            onClick={handleSubmit}
            disabled={!isFormValid || !isFormChanged()}
          >
            Save Details
          </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {showToast && (
        <div className="container-fluid" style={{
          position: 'fixed',
          top: '20px',
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 1060,
        }}>
          <div className="p-4">
            <div className="d-flex align-items-center justify-content-center">
              <div className="custom-toast-container d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center gap-3 mb-0">
                  <img
                    src="images/tick-green-small.svg"
                    alt="tick-green"
                    className="width-25"
                  />
              <p className="font-14 font-regular text-white mb-0">
                {toastMessage}
              </p>
            </div>
            <button type="button" className="btn p-0 shadow-none" onClick={() => setShowToast(false)}>
              <img
                src="images/cross-white.svg"
                alt="cross-icon"
                className="w-9"
              />
            </button>
            </div>
      </div>
    </div>
  </div>
)}
      </div>
    </>
  );
};


export default TemplateKbForm;
