import { useEffect } from 'react';

export default function AwsSDKECS({ values, onChangeOfConfiguration, isViewer }) {
  useEffect(() => {
    if (values.os === 'Windows') {
      if (values.cpuArchitecture !== 'x86') {
        onChangeOfConfiguration({ target: { name: 'cpuArchitecture', value: 'x86' } }, values.instanceName);
      }
    } else {
      if (values.cpuArchitecture !== 'ARM') {
        onChangeOfConfiguration({ target: { name: 'cpuArchitecture', value: 'ARM' } }, values.instanceName);
      }
      if (values.cpuArchitecture !== 'x86') {
        onChangeOfConfiguration({ target: { name: 'cpuArchitecture', value: 'x86' } }, values.instanceName);
      }
    }
    if (values.vCPUAllocated == 8) {
      onChangeOfConfiguration({ target: { name: 'memoryAllocated', value: "16" } }, values.instanceName);
    }
    else if (values.vCPUAllocated == 16) {
      onChangeOfConfiguration({ target: { name: 'memoryAllocated', value: "32" } }, values.instanceName);
    }
    else {
      onChangeOfConfiguration({ target: { name: 'memoryAllocated', value: values.memoryAllocated } }, values.instanceName)
    }

  }, [values.os, values.vCPUAllocated]);

  {
    try {
      return (

        <>
          <div
            class="accordion-body p-0 estimation-container">
            <div
              className="row   py-4 mx-4 px-5"
              hidden={values.isPriceAvailable == "no" ? false : true}
            >
              <div className="col-md-6 px-3">
                <span className="font-semibold font-14 text-danger">
                  {values.isPriceAvailable == "no"
                    ? "The Price is not available for the region / the configuration that you have selected might be not available in the region"
                    : null}
                </span>
              </div>
            </div>
            <div className="row   py-4 mx-4 px-5">
              <div className="col-md-6 px-3">
                <label
                  htmlFor="region"
                  className="form-label font-14 font-medium black-v2 mb-3"
                >
                  Region
                </label>
                {
                  console.log(values.region, "LOCATION")
                }
                <select
                  className="form-select custom-form  font-medium font-14 text-field"
                  id="region"
                  style={{ paddingTop: "10px" }}
                  value={values.region}
                  name={"region"}
                  onChange={(e) => {
                    onChangeOfConfiguration(e, values.instanceName);
                  }} disabled={isViewer}
                >
                  <option value="ap-south-2">ap-south-2</option>
                  <option value="ap-southeast-3">ap-southeast-3</option>
                  <option value="ap-southeast-1">ap-southeast-1</option>
                  <option value="us-gov-east-1">us-gov-east-1</option>
                  <option value="eu-west-2">eu-west-2</option>
                  <option value="af-south-1">af-south-1</option>
                  <option value="ap-northeast-1">ap-northeast-1</option>
                  <option value="ap-southeast-2">ap-southeast-2</option>
                  <option value="us-east-2">us-east-2</option>
                  <option value="eu-central-1">eu-central-1</option>
                  <option value="ca-central-1">ca-central-1</option>
                  <option value="ap-south-1">ap-south-1</option>
                  <option value="ap-northeast-3">ap-northeast-3</option>
                  <option value="sa-east-1">sa-east-1</option>
                  <option value="ap-east-1">ap-east-1</option>
                  <option value="eu-west-1">eu-west-1</option>
                  <option value="eu-south-1">eu-south-1</option>
                  <option value="ap-southeast-4">ap-southeast-4</option>
                  <option value="eu-north-1">eu-north-1</option>
                  <option value="eu-south-2">eu-south-2</option>
                  <option value="us-west-1">us-west-1</option>
                  <option value="us-west-2">us-west-2</option>
                  <option value="me-south-1">me-south-1</option>
                  <option value="eu-central-2">eu-central-2</option>
                  <option value="us-gov-west-1">us-gov-west-1</option>
                  <option value="ap-northeast-2">ap-northeast-2</option>
                  <option value="me-central-1">me-central-1</option>
                  <option value="us-east-1">us-east-1</option>
                  <option value="eu-west-3">eu-west-3</option>
                </select>
              </div>
              <div className="col-md-6 px-3">
                <label
                  htmlFor="region1"
                  className="form-label font-14 font-medium black-v2 mb-3"
                >
                  Operating system
                </label>
                <select
                  className="form-select custom-form  font-medium font-14 text-field mb-3"
                  id="region1"
                  style={{ paddingTop: "10px" }}
                  value={values.os}
                  name={"os"}
                  onChange={(e) => {
                    onChangeOfConfiguration(e, values.instanceName);
                  }} disabled={isViewer}
                >
                  <option value={"Linux"} selected>
                    Linux
                  </option>
                  <option value={"Windows"}>Windows</option>
                </select>
              </div>
              <div className="col-md-6 px-3">
                <label
                  htmlFor="cpuArchitecture"
                  className="form-label font-14 font-medium black-v2 mb-3"
                >
                  CPU Architecture
                </label>
                <select
                  style={{ paddingTop: "10px" }}
                  className="form-select custom-form font-medium font-14 text-field mb-3"
                  id="cpuArchitecture"
                  value={values.cpuArchitecture}
                  name="cpuArchitecture"
                  onChange={(e) => onChangeOfConfiguration(e, values.instanceName)}
                  disabled={isViewer}
                >
                  <option value="x86"> x86</option>
                  {values.os != "Windows" ? (<option value="ARM">ARM</option>) : null}

                </select>

              </div>
              <div className="col-md-6 px-3">
                <label
                  htmlFor="region1"
                  className="form-label font-14 font-medium black-v2 mb-3"
                >
                  Number of tasks or pods
                </label>
                <input
                  type="text"
                  style={{ paddingTop: "10px" }}
                  className="form-control custom-form text-field font-14 font-medium"
                  id="noTask"
                  value={values.noTask}
                  name={"noTask"}
                  onChange={(e) => {
                    onChangeOfConfiguration(e, values.instanceName);
                  }} disabled={isViewer}
                />
              </div>
              <div className="col-md-6 px-3">
                <label
                  htmlFor="taskType"
                  className="form-label font-14 font-medium black-v2 mb-3"
                >
                  Unit
                </label>
                <select
                  className="form-select custom-form  font-medium font-14 text-field mb-3"
                  id="taskType"
                  style={{ paddingTop: "10px" }}
                  value={values.taskType}
                  name={"taskType"}
                  onChange={(e) => {
                    onChangeOfConfiguration(e, values.instanceName);
                  }} disabled={isViewer}
                >
                  <option value={"month"} selected> per Month </option>
                  <option value={"second"} selected> per Second </option>
                  <option value={"minute"} selected> per Minute </option>
                  <option value={"hour"} selected> per Hour  </option>
                  <option value={"day"} selected> per Day </option>
                </select>
              </div>

              <div className="col-md-6 px-3">
                <label
                  htmlFor="averageDuration"
                  className="form-label font-14 font-medium black-v2 mb-3"
                >
                  Average Duration
                </label>
                <input
                  type="text"
                  style={{ paddingTop: "10px" }}
                  className="form-control custom-form text-field font-14 font-medium"
                  id="averageDuration"
                  value={values.averageDuration}
                  name={"averageDuration"}
                  onChange={(e) => {
                    onChangeOfConfiguration(e, values.instanceName);
                  }} disabled={isViewer}
                />
              </div>
              <div className="col-md-6 px-3">
                <label
                  htmlFor="averageDurationType"
                  className="form-label font-14 font-medium black-v2 mb-3"
                >
                  Average Duration Unit
                </label>
                <select
                  style={{ paddingTop: "10px" }}
                  className="form-select custom-form  font-medium font-14 text-field mb-3"
                  id="averageDurationType"
                  value={values.averageDurationType}
                  name={"averageDurationType"}
                  onChange={(e) => {
                    onChangeOfConfiguration(e, values.instanceName);
                  }} disabled={isViewer}
                >
                  <option value={"second"} selected> Second </option>
                  <option value={"minute"} selected> Minutes </option>
                  <option value={"hour"} selected> Hour  </option>
                  <option value={"day"} selected> Day </option>
                </select>
              </div>
              <div className="col-md-6 px-3">
                <label
                  htmlFor="region1"
                  className="form-label font-14 font-medium black-v2 mb-3"
                >
                  Amount of vCPU allocated
                </label>
                {values.os == "Linux" ? <>
                  <select
                    style={{ paddingTop: "10px" }}
                    className="form-select custom-form  font-medium font-14 text-field"
                    id="region1"
                    value={values.vCPUAllocated}
                    name={"vCPUAllocated"}
                    onChange={(e) => {
                      onChangeOfConfiguration(e, values.instanceName);
                    }} disabled={isViewer}
                  >
                    <option value={0.25} selected>0.25</option>
                    <option value={0.5}>0.5</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={4}>4</option>
                    <option value={8}>8</option>
                    <option value={16}>16</option>
                  </select></> : <><select
                    className="form-select custom-form  font-medium font-14 text-field"
                    id="region1"
                    value={values.vCPUAllocated}
                    name={"vCPUAllocated"}
                    onChange={(e) => {
                      onChangeOfConfiguration(e, values.instanceName);

                    }} disabled={isViewer}
                  >
                    <option value={1} selected>1
                    </option>

                    <option value={2}>2</option>
                    <option value={4}>4</option>
                    <option value={8}>8</option>
                    <option value={16}>16</option>
                  </select></>}
              </div>
              <div className="col-md-6 px-3">
                <label
                  htmlFor="region1"
                  className="form-label font-14 font-medium black-v2 mb-3"
                >
                  Memory allocated
                </label>

                <select
                  className="form-select custom-form font-medium font-14 text-field"
                  id="region1"
                  style={{ paddingTop: "10px" }}
                  value={values.memoryAllocated}
                  name="memoryAllocated"
                  onChange={(e) => {
                    onChangeOfConfiguration(e, values.instanceName);
                  }}
                  disabled={isViewer}
                >
                  {values.os === "Linux" && values.vCPUAllocated == 1 && (() => {
                    const options = [];
                    for (let i = 2; i <= 8; i++) {
                      options.push(
                        <option key={i} value={i} selected={values.memoryAllocated === i}>
                          {i}
                        </option>
                      );
                    }
                    return options;
                  })()}

                  {values.os === "Linux" && values.vCPUAllocated == 0.25 && (
                    <>
                      <option value={0.5} selected={values.memoryAllocated === 0.5}>
                        0.5
                      </option>
                      <option value={1} selected={values.memoryAllocated === 1}>
                        1
                      </option>
                      <option value={2} selected={values.memoryAllocated === 2}>
                        2
                      </option>
                    </>
                  )}

                  {values.os === "Linux" && values.vCPUAllocated == 0.5 && (
                    <>
                      <option value={1} selected={values.memoryAllocated === 1}>
                        1
                      </option>
                      <option value={2} selected={values.memoryAllocated === 2}>
                        2
                      </option>
                      <option value={3} selected={values.memoryAllocated === 3}>
                        3
                      </option>
                      <option value={4} selected={values.memoryAllocated === 4}>
                        4
                      </option>
                    </>
                  )}

                  {values.os === "Linux" && values.vCPUAllocated == 2 && (() => {
                    const options = [];
                    for (let i = 4; i <= 16; i++) {
                      options.push(
                        <option key={i} value={i} selected={values.memoryAllocated === i}>
                          {i}
                        </option>
                      );
                    }
                    return options;
                  })()}

                  {values.os === "Linux" && values.vCPUAllocated == 4 && (() => {
                    const options = [];
                    for (let i = 8; i <= 30; i++) {
                      options.push(
                        <option key={i} value={i} selected={values.memoryAllocated === i}>
                          {i}
                        </option>
                      );
                    }
                    return options;
                  })()}

                  {values.os === "Linux" && values.vCPUAllocated == 8 && (() => {
                    const options = [];
                    for (let i = 16; i <= 60; i++) {
                      options.push(
                        <option key={i} value={i} selected={values.memoryAllocated === i}>
                          {i}
                        </option>
                      );
                    }
                    return options;
                  })()}

                  {values.os === "Linux" && values.vCPUAllocated == 16 && (() => {
                    const options = [];
                    for (let i = 32; i <= 120; i += 8) {
                      options.push(
                        <option key={i} value={i} selected={values.memoryAllocated === i}>
                          {i}
                        </option>
                      );
                    }
                    return options;
                  })()}

                  {values.os === "Windows" && values.vCPUAllocated == 1 && (() => {
                    const options = [];
                    for (let i = 2; i <= 8; i++) {
                      options.push(
                        <option key={i} value={i} selected={values.memoryAllocated === i}>
                          {i}
                        </option>
                      );
                    }
                    return options;
                  })()}
                  {values.os === "Windows" && values.vCPUAllocated == 2 && (() => {
                    const options = [];
                    for (let i = 4; i <= 16; i++) {
                      options.push(
                        <option key={i} value={i} selected={values.memoryAllocated === i}>
                          {i}
                        </option>
                      );
                    }
                    return options;
                  })()}
                  {values.os === "Windows" && values.vCPUAllocated == 4 && (() => {
                    const options = [];
                    for (let i = 8; i <= 30; i++) {
                      options.push(
                        <option key={i} value={i} selected={values.memoryAllocated === i}>
                          {i}
                        </option>
                      );
                    }
                    return options;
                  })()}
                  {values.os === "Windows" && values.vCPUAllocated == 8 && (() => {
                    const options = [];
                    for (let i = 16; i <= 60; i += 4) {
                      options.push(
                        <option key={i} value={i} selected={values.memoryAllocated === i}>
                          {i}
                        </option>
                      );
                    }
                    return options;
                  })()}
                  {values.os === "Windows" && values.vCPUAllocated == 16 && (() => {
                    const options = [];
                    for (let i = 32; i <= 120; i += 8) {
                      options.push(
                        <option key={i} value={i} selected={values.memoryAllocated === i}>
                          {i}
                        </option>
                      );
                    }
                    return options;
                  })()}

                </select>
              </div>
              <div className="col-md-6 px-3">
                <label
                  htmlFor="region1"
                  className="form-label font-14 font-medium black-v2 mb-3"
                >
                  Memory Allocated Unit (GB)
                </label>
                <select
                  style={{ paddingTop: "10px" }}
                  className="form-select custom-form  font-medium font-14 text-field"
                  id="region1"
                  value={values.memoryAllocatedUnit}
                  name={"os"}
                  onChange={(e) => {
                    onChangeOfConfiguration(e, values.instanceName);
                  }} disabled={isViewer}
                >
                  <option value={"GB"} selected>
                    GB
                  </option>
                </select>
              </div>

            </div>

            {values.os == "Linux" ? <>
              <div className="row   py-4 mx-4 px-5">
                <div className="col-md-6 px-3">
                  <label
                    style={{ marginTop: "-20px" }}
                    htmlFor="region1"
                    className="form-label font-14 font-medium black-v2 mb-3"
                  >
                    Amount of ephemeral storage allocated for Amazon ECS Value
                  </label>
                  <input
                    type="text"
                    style={{ paddingTop: "10px" }}
                    className="form-control custom-form text-field font-14 font-medium"
                    id="noOfRequest1"
                    value={values.ephemeralStorage}
                    name={"ephemeralStorage"}
                    onChange={(e) => {
                      onChangeOfConfiguration(e, values.instanceName);
                    }} disabled={isViewer}
                  />
                </div>
                <div className="col-md-6 px-3">
                  <label
                  style={{marginTop :"-20px"}}
                    htmlFor="region1"
                    className="form-label font-14 font-medium black-v2 mb-3"
                  >
                    Ephemeral Storage Unit (GB)
                  </label>
                  <select
                    style={{ paddingTop: "10px" }}
                    className="form-select custom-form  font-medium font-14 text-field"
                    id="region1"
                    value={values.ephemeralStorageUnit}
                    name={"os"}
                    onChange={(e) => {
                      onChangeOfConfiguration(e, values.instanceName);
                    }} disabled={isViewer}
                  >
                    <option value={"GB"} selected>
                      GB
                    </option>
                  </select>
                </div>
              </div>
            </> : <></>
            }
          </div>
        </>
      );
    } catch (error) {
      //console.log(error);
    }
  }
}