
export default function AWS_SDK_Lambda({ values, onChangeOfConfiguration, isViewer }) {
    try {

        return (
                <div
                    class="accordion-body p-0 estimation-container">
                    <div className="row py-4 mx-4 px-5">
                        <div className="col-md-6 px-3">
                            <label htmlFor="region" className="form-label font-14 font-medium black-v2 mb-3">
                                Region
                            </label>
                            <select
                                className="form-select custom-form font-semibold font-14 form-select-sm"
                                id="region"
                                value={values.region}
                                name={"region"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            >
                                <option value="ap-south-2">ap-south-2</option><option value="ap-southeast-3">ap-southeast-3</option><option value="ap-southeast-1">ap-southeast-1</option><option value="us-gov-east-1">us-gov-east-1</option><option value="eu-west-2">eu-west-2</option><option value="af-south-1">af-south-1</option><option value="ap-northeast-1">ap-northeast-1</option><option value="ap-southeast-2">ap-southeast-2</option><option value="us-east-2">us-east-2</option><option value="eu-central-1">eu-central-1</option><option value="ca-central-1">ca-central-1</option><option value="ap-south-1">ap-south-1</option><option value="ap-northeast-3">ap-northeast-3</option><option value="sa-east-1">sa-east-1</option><option value="ap-east-1">ap-east-1</option><option value="eu-west-1">eu-west-1</option><option value="eu-south-1">eu-south-1</option><option value="ap-southeast-4">ap-southeast-4</option><option value="eu-north-1">eu-north-1</option><option value="eu-south-2">eu-south-2</option><option value="us-west-1">us-west-1</option><option value="us-west-2">us-west-2</option><option value="me-south-1">me-south-1</option><option value="eu-central-2">eu-central-2</option><option value="us-gov-west-1">us-gov-west-1</option><option value="ap-northeast-2">ap-northeast-2</option><option value="me-central-1">me-central-1</option><option value="us-east-1">us-east-1</option><option value="eu-west-3">eu-west-3</option>
                            </select>
                        </div>
                        <div className="col-md-6 px-3">
                            <label htmlFor="EFSstorageType" className="form-label font-14 font-medium black-v2 mb-3">
                                Lambda Function
                            </label>
                            <select
                                className="form-select custom-form font-semibold font-14 form-select-sm"
                                id="EFSstorageType"
                                value={values.EFSstorageType}
                                name={"EFSstorageType"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            >
                                <option value="1">Lambda Function - Include Free Tier</option>
                                <option value="2">Lambda Function - Without Free Tier</option>
                            </select>
                        </div>
                        <div className="col-md-6 px-3">
                            <label htmlFor="capacityType" className="form-label font-14 font-medium black-v2 mb-3">
                                ArchiTecture
                            </label>
                            <select
                                className="form-select custom-form font-semibold font-14 form-select-sm"
                                id="capacityType"
                                value={values.capacityType}
                                name={"capacityType"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            >
                                <option value="1">X86</option>
                                <option value="2">Arm</option>
                            </select>
                        </div>
                        <div className="col-md-6 px-3">
                            <label
                                htmlFor="requests"
                                className="form-label font-14 font-medium black-v2 mb-3"
                            >
                                Number Of Requests
                            </label>
                            <input
                                type="requets"
                                className="form-control custom-form text-field font-14 font-medium"
                                id="requets"
                                value={values.requets}
                                name={"requets"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            />
                        </div>
                        <div className="col-md-6 px-3">
                            <label htmlFor="InfrequentAccessRequestType" className="form-label font-14 font-medium black-v2 mb-3">
                                Unit
                            </label>
                            <select
                                className="form-select custom-form font-semibold font-14 form-select-sm"
                                id=""
                                value={values.InfrequentAccessRequestType}
                                name={""}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            >
                                <option value="per second">per month</option>
                                <option value="per minute">per month</option>
                                <option value="per hour">per month</option>
                                <option value="per day">per month</option>
                                <option value="per month">per month</option>
                                <option value="million per month">million per month</option>
                            </select>
                        </div>
                        <div className="col-md-6 px-3">
                            <label
                                htmlFor="frequentpercentage"
                                className="form-label font-14 font-medium black-v2 mb-3"
                            >
                                Duration of each request(in ms)
                            </label>
                            <input
                                type="number"
                                className="form-control custom-form text-field font-14 font-medium"
                                id="frequentpercentage"
                                value={values.frequentpercentage}
                                name={"frequentpercentage"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            />
                        </div>
                        <div className="col-md-6 px-3">
                            <label
                                htmlFor="InfrequentAccessRequest"
                                className="form-label font-14 font-medium black-v2 mb-3"
                            >
                                Amount of memory allocated
                            </label>
                            <input
                                type="number"
                                className="form-control custom-form text-field font-14 font-medium"
                                id="InfrequentAccessRequest"
                                value={values.InfrequentAccessRequest}
                                name={"InfrequentAccessRequest"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            />
                        </div>
                        <div className="col-md-6 px-3">
                            <label htmlFor="capacityType" className="form-label font-14 font-medium black-v2 mb-3">
                                Unit
                            </label>
                            <select
                                className="form-select custom-form font-semibold font-14 form-select-sm"
                                id="capacityType"
                                value={values.capacityType}
                                name={"capacityType"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            >
                                <option value="1">MB</option>
                                <option value="2">GB</option>
                            </select>
                        </div>
                        <div className="col-md-6 px-3">
                            <label
                                htmlFor="InfrequentAccessRequest"
                                className="form-label font-14 font-medium black-v2 mb-3"
                            >
                                Amount of ephemeral storage allocated
                            </label>
                            <input
                                type="number"
                                className="form-control custom-form text-field font-14 font-medium"
                                id="InfrequentAccessRequest"
                                value={values.InfrequentAccessRequest}
                                name={"InfrequentAccessRequest"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            />
                        </div>
                        <div className="col-md-6 px-3">
                            <label htmlFor="capacityType" className="form-label font-14 font-medium black-v2 mb-3">
                                Unit
                            </label>
                            <select
                                className="form-select custom-form font-semibold font-14 form-select-sm"
                                id="capacityType"
                                value={values.capacityType}
                                name={"capacityType"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            >
                                <option value="1">MB</option>
                                <option value="2">GB</option>
                            </select>
                        </div>






                    </div>
                </div>
        );
    } catch (error) {
        //console.log(error);
    }
}