import { useState } from "react";


export default function AwsSDKRDS({ values, onChangeOfConfiguration, isViewer }) {
    try {
        console.log("sagin RDS values", values)
        // const [isAuroraOptimized, SetIsAuroraOptimized] = useState(false)
        // if(values.AuroraMySQLClusterConfiguration === "Aurora I/O-Optimized"){
        //     SetIsAuroraOptimized(true)
        // }
        // else{
        //     SetIsAuroraOptimized(false)
        // }
        if (values.AuroraMySQLClusterConfiguration === "Aurora I/O-Optimized") {
            values.AuroraServerlessVersion = "Aurora Serverless v2"
        }
        return (
            <>
                    <div
                        class="accordion-body p-0 estimation-container">
                        <div className="row py-4 mx-4 px-5" hidden={(values.isPriceAvailable == "No") ? false : true}>
                            <div className="col-md-6 px-3"><span className="font-semibold font-14 text-danger">{values.isPriceAvailable == "No" ? "The Price is not available for the region / the configuration that you have selected might be not available in the region" : null}</span></div>
                        </div>
                        <div className="row py-4 mx-4 px-5">
                            <div className="col-md-6 px-3">
                                <label htmlFor="region1" className="form-label font-14 font-medium black-v2 mb-3">
                                    Region
                                </label>
                                <select
                                    className="form-select custom-form font-semibold font-14 form-select-sm"
                                    id="region"
                                    value={values.region}
                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                    name={"region"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                >
                                    <option value="ap-south-2">ap-south-2</option><option value="ap-southeast-3">ap-southeast-3</option><option value="ap-southeast-1">ap-southeast-1</option><option value="us-gov-east-1">us-gov-east-1</option><option value="eu-west-2">eu-west-2</option><option value="af-south-1">af-south-1</option><option value="ap-northeast-1">ap-northeast-1</option><option value="ap-southeast-2">ap-southeast-2</option><option value="us-east-2">us-east-2</option><option value="eu-central-1">eu-central-1</option><option value="ca-central-1">ca-central-1</option><option value="ap-south-1">ap-south-1</option><option value="ap-northeast-3">ap-northeast-3</option><option value="sa-east-1">sa-east-1</option><option value="ap-east-1">ap-east-1</option><option value="eu-west-1">eu-west-1</option><option value="eu-south-1">eu-south-1</option><option value="ap-southeast-4">ap-southeast-4</option><option value="eu-north-1">eu-north-1</option><option value="eu-south-2">eu-south-2</option><option value="us-west-1">us-west-1</option><option value="us-west-2">us-west-2</option><option value="me-south-1">me-south-1</option><option value="eu-central-2">eu-central-2</option><option value="us-gov-west-1">us-gov-west-1</option><option value="ap-northeast-2">ap-northeast-2</option><option value="me-central-1">me-central-1</option><option value="us-east-1">us-east-1</option><option value="eu-west-3">eu-west-3</option>
                                </select>
                            </div>
                            <div className="col-md-6 px-3">
                                <label htmlFor="Mysqltype" className="form-label font-14 font-medium black-v2 mb-3">
                                    Aurora Mysql
                                </label>
                                <select
                                    className="form-select custom-form font-semibold font-14 form-select-sm"
                                    id="Mysqltype"
                                    value={values.Mysqltype}
                                    name={"Mysqltype"}
                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                    onChange={(e) => {
                                        console.log("sagin", e)
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                >
                                    <option value="AuroraCompatible">Aurora MySQL-Compatible</option>
                                    <option value="AuroraServerless">Aurora Serverless MySQL-Compatible</option>
                                </select>
                            </div>
                            {values.Mysqltype === "AuroraServerless" && <><div className="col-md-6 px-3">
                                <label htmlFor="AuroraMySQLClusterConfiguration" className="form-label font-14 font-medium black-v2 mb-3">
                                    Aurora MySQL Cluster Configuration Option
                                </label>
                                <select
                                    className="form-select custom-form font-semibold font-14 form-select-sm"
                                    id="AuroraMySQLClusterConfiguration"
                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                    value={values.AuroraMySQLClusterConfiguration}
                                    name={"AuroraMySQLClusterConfiguration"}
                                    onChange={(e) => {
                                        console.log("sagin", e)
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                >
                                    <option value="Aurora Standard">Aurora Standard</option>
                                    <option value="Aurora I/O-Optimized">Aurora I/O-Optimized</option>
                                </select>
                            </div>

                                <div className="col-md-6 px-3">
                                    <label htmlFor="AuroraServerlessVersion" className="form-label font-14 font-medium black-v2 mb-3">
                                        Aurora Serverless Version
                                    </label>
                                    <select
                                        className="form-select custom-form font-semibold font-14 form-select-sm"
                                        id="AuroraServerlessVersion"
                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                        value={values.AuroraServerlessVersion}
                                        name={"AuroraServerlessVersion"}
                                        onChange={(e) => {
                                            console.log("sagin serverless version", e)
                                            onChangeOfConfiguration(e, values.instanceName);
                                        }} disabled={isViewer}
                                    >
                                        <option value="Aurora Serverless v2" >Aurora Serverless v2</option>
                                        {values.AuroraMySQLClusterConfiguration != "Aurora I/O-Optimized" ? (<option value="Aurora Serverless v1">Aurora Serverless v1</option>) : (null)}
                                    </select>
                                </div>
                                <div className="col-md-6 px-3">
                                    <label htmlFor="NoofACU" className="form-label font-14 font-medium black-v2 mb-3">
                                        Number of Aurora Capacity Units (ACUs)
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control custom-form text-field font-14 font-medium"
                                        id="NoofACU"
                                        value={values.NoofACU}
                                        name={"NoofACU"}
                                        onChange={(e) => {
                                            onChangeOfConfiguration(e, values.instanceName);
                                        }} disabled={isViewer}

                                    />
                                </div>

                                <div className="col-md-6 px-3">
                                    <label htmlFor="ACUunit" className="form-label font-14 font-medium black-v2 mb-3">
                                        Unit
                                    </label>
                                    <select
                                        className="form-select custom-form font-semibold font-14 form-select-sm"
                                        id="ACUunit"
                                        value={values.ACUunit}
                                        name={"ACUunit"}
                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                        onChange={(e) => {
                                            console.log("sagin", e)
                                            onChangeOfConfiguration(e, values.instanceName);
                                        }} disabled={isViewer}
                                    >
                                        <option value="per hour">per hour</option>
                                        <option value="per day">per day</option>
                                        <option value="per month">per month</option>
                                    </select>
                                </div>
                                {/* RDS proxy */}
                                {values.AuroraServerlessVersion === "Aurora Serverless v2" && <div>
                                    <label htmlFor="RDSProxy" className="form-label font-14 font-medium black-v2 mb-3">
                                        RDS Proxy
                                    </label>
                                    <select
                                        className="form-select custom-form font-semibold font-14 form-select-sm"
                                        id="RDSProxy"
                                        value={values.RDSProxy}
                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                        name={"RDSProxy"}
                                        onChange={(e) => {
                                            console.log("sagin", e)
                                            onChangeOfConfiguration(e, values.instanceName);
                                        }} disabled={isViewer}
                                    >
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>}
                                {/* Storage amount */}
                                <div className="col-md-6 px-3">
                                    <label htmlFor="StorageAmount" className="form-label font-14 font-medium black-v2 mb-3"
                                        style={{ marginTop: "15px" }}>
                                        Storage amount Value
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control custom-form text-field font-14 font-medium"
                                        id="StorageAmount"
                                        value={values.StorageAmount}
                                        name={"StorageAmount"}
                                        onChange={(e) => {
                                            onChangeOfConfiguration(e, values.instanceName);
                                        }} disabled={isViewer}

                                    />
                                </div>

                                <div className="col-md-6 px-3">
                                    <label htmlFor="StorageAmountUnit" className="form-label font-14 font-medium black-v2 mb-3"
                                        style={{ marginTop: "15px" }}>
                                        Unit
                                    </label>
                                    <select
                                        className="form-select custom-form font-semibold font-14 form-select-sm"
                                        id="StorageAmountUnit"
                                        value={values.StorageAmountUnit}
                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                        name={"StorageAmountUnit"}
                                        onChange={(e) => {
                                            console.log("sagin", e)
                                            onChangeOfConfiguration(e, values.instanceName);
                                        }} disabled={isViewer}
                                    >
                                        <option value="gb">GB</option>
                                        <option value="tb">TB</option>
                                        <option value="mb">MB</option>
                                    </select>
                                </div>
                                {/* Baseline IO rate */}
                                <div className="col-md-6 px-3">
                                    <label htmlFor="BaselineIOrateValue" className="form-label font-14 font-medium black-v2 mb-3">
                                        Baseline IO rate Value
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control custom-form text-field font-14 font-medium"
                                        id="BaselineIOrateValue"
                                        value={values.BaselineIOrateValue}
                                        name={"BaselineIOrateValue"}
                                        onChange={(e) => {
                                            onChangeOfConfiguration(e, values.instanceName);
                                        }} disabled={isViewer}

                                    />
                                </div>

                                <div className="col-md-6 px-3">
                                    <label htmlFor="BaselineIOrateunit" className="form-label font-14 font-medium black-v2 mb-3">
                                        Unit
                                    </label>
                                    <select
                                        className="form-select custom-form font-semibold font-14 form-select-sm"
                                        id="BaselineIOrateunit"
                                        value={values.BaselineIOrateunit}
                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                        name={"BaselineIOrateunit"}
                                        onChange={(e) => {
                                            console.log("sagin", e)
                                            onChangeOfConfiguration(e, values.instanceName);
                                        }} disabled={isViewer}
                                    >
                                        <option value="per month">per month</option>
                                        <option value="per second">per second</option>
                                        <option value="per minute">per minute</option>
                                        <option value="per hour">per hour</option>
                                        <option value="per day">per day</option>
                                    </select>
                                </div>
                                {/* Peak IO rate */}
                                <div className="col-md-6 px-3">
                                    <label htmlFor="PeakIOrateValue" className="form-label font-14 font-medium black-v2 mb-3">
                                        Peak IO rate Value
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control custom-form text-field font-14 font-medium"
                                        id="PeakIOrateValue"
                                        value={values.PeakIOrateValue}
                                        name={"PeakIOrateValue"}
                                        onChange={(e) => {
                                            onChangeOfConfiguration(e, values.instanceName);
                                        }} disabled={isViewer}

                                    />
                                </div>

                                <div className="col-md-6 px-3">
                                    <label htmlFor="PeakIorateunit" className="form-label font-14 font-medium black-v2 mb-3">
                                        Unit
                                    </label>
                                    <select
                                        className="form-select custom-form font-semibold font-14 form-select-sm"
                                        id="PeakIorateunit"
                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                        value={values.PeakIorateunit}
                                        name={"PeakIorateunit"}
                                        onChange={(e) => {
                                            console.log("sagin", e)
                                            onChangeOfConfiguration(e, values.instanceName);
                                        }} disabled={isViewer}
                                    >
                                        <option value="per month">per month</option>
                                        <option value="per second">per second</option>
                                        <option value="per minute">per minute</option>
                                        <option value="per hour">per hour</option>
                                        <option value="per day">per day</option>
                                    </select>
                                </div>
                                {/* Duration of peak IO activity */}
                                <div className="col-md-6 px-3">
                                    <label htmlFor="PeakDurationValue" className="form-label font-14 font-medium black-v2 mb-3">
                                        Duration of peak IO activity Value
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control custom-form text-field font-14 font-medium"
                                        id="PeakDurationValue"
                                        value={values.PeakDurationValue}
                                        name={"PeakDurationValue"}
                                        onChange={(e) => {
                                            onChangeOfConfiguration(e, values.instanceName);
                                        }} disabled={isViewer}

                                    />
                                </div>

                                <div className="col-md-6 px-3">
                                    <label htmlFor="PeakDurationunit" className="form-label font-14 font-medium black-v2 mb-3">
                                        Unit
                                    </label>
                                    <select
                                        className="form-select custom-form font-semibold font-14 form-select-sm"
                                        id="PeakDurationunit"
                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                        value={values.PeakDurationunit}
                                        name={"PeakDurationunit"}
                                        onChange={(e) => {
                                            console.log("sagin", e)
                                            onChangeOfConfiguration(e, values.instanceName);
                                        }} disabled={isViewer}
                                    >
                                        <option value="hours per month" selected>hours per month</option>

                                    </select>
                                </div></>}
                        </div>
                    </div>
                    </>
        );
    } catch (error) {
        //console.log(error);
    }
}