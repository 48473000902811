
// - Import statements
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Pagination from '../../CostManagement/Pagination';
import { updateModuleConfig } from '../../../Service/api';
import { v4 as uuidv4 } from 'uuid';

function ModulesConfigGrid() {

    //PS_02-PS_03 - Declare state variables
    const location = useLocation();
    const navigate = useNavigate();
    console.log(location, "location")
    const [activeTab, setActiveTab] = useState('Inputs');
    const [editIndex, setEditIndex] = useState(null);
    const [editData, setEditData] = useState({});




    //PS_04 -PS_06
    const inputConfigurations = location.state?.inputConfigurations ?? [];
    const outputConfiguration = location.state?.outputConfiguration ?? []
    const moduleId = location.state?.moduleId ?? []

    const addUniqueIds = (data) => {
        return data.map(item => ({ ...item, id: uuidv4() }));
    };

    const [gridData, setGridData] = useState(addUniqueIds(inputConfigurations));
    const [outputData, setOutputData] = useState(addUniqueIds(outputConfiguration));
    const [filteredConfigs, setFilteredConfigs] = useState([]);
    const [editId, setEditId] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortColumnName, setSortColumnName] = useState('');
    const [sortOrder, setSortOrder] = useState('ASC');
    const [inputPagination, setInputPagination] = useState({ currentPage: 1, itemsPerPage: 10 });
    const [outputPagination, setOutputPagination] = useState({ currentPage: 1, itemsPerPage: 10 });
    const userId = localStorage.getItem('userId')

    console.log(gridData, "gridData", outputData, "outputdata")
    //PS_07 - Initial tab set
    useEffect(() => {
        setActiveTab('Inputs');
    }, []);


    //PS_52-PS_56 - Handle Inline edit
    const handleEdit = (id, type = "input") => {
        setEditId(id);
        if (type === "input") {
            setEditData(gridData.find(item => item.id === id));
        } else {
            setEditData(outputData.find(item => item.id === id));
        }
    };

    //PS_57- Handle the input field change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    //PS_59-PS_63 - Handle edit save
    const handleEditSave = () => {
        if (activeTab === 'Inputs') {
            const updatedData = gridData.map(item => item.id === editData.id ? editData : item);
            setGridData(updatedData);
        } else {
            const updatedData = outputData.map(item => item.id === editData.id ? editData : item);
            setOutputData(updatedData);
        }
        setEditId(null);
    };

    //PS_65-PS_67- Handle the delete for each row
    const handleDelete = (id, type = "input") => {
        if (type === "input") {
            const updatedData = gridData.filter(item => item.id !== id);
            setGridData(updatedData);
        } else {
            const updatedData = outputData.filter(item => item.id !== id);
            setOutputData(updatedData);
        }
    };

    const handleEditCancel = () => {
        setEditId(null);
        setEditData(null);
    };

    //PS_105- Handles search functionality
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    //PS_76-PS_83 - To handle sort
    const handleSort = (columnName) => {
        const order = sortOrder === 'ASC' ? 'DESC' : 'ASC';
        setSortColumnName(columnName);
        setSortOrder(order);
    };

    //PS_08-PS_10 - Triggers when search,sort,pagination of grid data
    useEffect(() => {
        try{
            const applyFilters = (data) => {
                let filtered = data;
                console.log(filtered , "Filtereer")
                if (searchTerm) {
                    filtered = filtered.filter(config => {
                        console.log("Current config object:", config); // Log the current config object
                    
                        const matchesSearchTerm = Object.values(config).some(value => {
                            console.log(value , "Valueeee")
                            const stringValue = value?.toString()?.toLowerCase();
                            const searchTermLower = searchTerm?.toLowerCase();
                    
                            console.log(`Checking value: ${value}, Converted to string: ${stringValue}, Search term: ${searchTermLower}`); // Log each value being checked
                    
                            if (stringValue?.includes(searchTermLower)) {
                                console.log(`Match found in value: ${value}`); // Log when a match is found
                                return true; // Return true if match is found
                            }
                            return false; // Return false if no match is found
                        });
                    
                        console.log(`Config object ${matchesSearchTerm ? 'matches' : 'does not match'} the search term.`); // Log whether config object matches or not
                    
                        return matchesSearchTerm; // Return true if at least one value matches
                    
                    });
                    console.log(filtered , "FIltereeddd")
                }
                
                const sorted = filtered.sort((a, b) => {
                    const valA = a[sortColumnName] ?? '';
                    const valB = b[sortColumnName] ?? '';
    
                    if (typeof valA === 'string' && typeof valB === 'string') {
                        return sortOrder === 'ASC'
                            ? valA.localeCompare(valB)
                            : valB.localeCompare(valA);
                    }
    
                    if (typeof valA === 'number' && typeof valB === 'number') {
                        return sortOrder === 'ASC'
                            ? valA - valB
                            : valB - valA;
                    }
    
                    return 0;
                });
    
                return sorted;
            };
    
            if (activeTab === 'Inputs') {
                setFilteredConfigs(applyFilters(gridData));
            } else {
                setFilteredConfigs(applyFilters(outputData));
            }
        }catch(err){

            console.log(err , "Error here")
        }
      
    }, [searchTerm, activeTab, gridData, outputData, sortColumnName, sortOrder]);

    //PS_40-PS_47 -Handles the input grid
    const renderInputRows = () => {
        const { currentPage, itemsPerPage } = inputPagination;
        const startingIndex = (currentPage - 1) * itemsPerPage;
        const endingIndex = startingIndex + itemsPerPage;
        const dataToRender = filteredConfigs?.slice(startingIndex, endingIndex);

        return dataToRender?.map((data) => (
            <tr key={data.id}>
                <td className="text-start align-middle">{data.input}</td>
                <td className="text-start align-middle">{data.type}</td>
                <td className="text-start align-middle" title={data?.default}>
                    {editId === data.id && activeTab === 'Inputs' ? (
                        <input
                            type="text"
                            name="default"
                            className="form-control custom-form font-14 font-medium h-40 py-2"
                            value={editData.default}
                            onChange={handleInputChange}
                        />
                    ) : 
                    // (
                    //     data.default !== "" ?
                    //         (data.default?.length > 50 ? `${data?.default?.slice(0, 50)}...` : data?.default) :
                    //         '" "'
                    // )}
                    (
                        // Handle boolean, array, and object types
                        typeof data.default === 'boolean' ? String(data.default) :
                        Array.isArray(data.default) ? (
                            JSON.stringify(data.default).length > 50 ?
                            `${JSON.stringify(data.default)?.slice(0, 50)}...` :
                            JSON.stringify(data.default)
                        ) :
                        typeof data.default === 'object' && data.default !== null ? (
                            JSON.stringify(data.default).length > 50 ?
                            `${JSON.stringify(data.default)?.slice(0, 50)}...` :
                            JSON.stringify(data.default)
                        ) :
                        (
                            data.default ? ( // Check if data.default exists and is not empty
                                data.default.length > 50 ? `${data.default?.slice(0, 50)}...` : data.default
                            ) : (
                                '" "' // Display this when data.default is empty, null or undefined
                            )
                        )
                    )}  
                </td>
                <td className="text-start align-middle" title={data?.description}>
                    {editId === data.id && activeTab === 'Inputs' ? (
                        <input
                            type="text"
                            name="description"
                            className="form-control custom-form font-14 font-medium h-40 py-2"
                            value={editData.description}
                            onChange={handleInputChange}
                        />
                    ) : (
                        data?.description?.length > 50 ? `${data?.description?.slice(0, 50)}...` : data?.description
                    )}
                </td>
                <td className="text-center align-middle">
                    <div className="d-flex flex-nowrap gap-3 justify-content-center align-items-center">
                        {editId === data.id && activeTab === 'Inputs' ? (
                            <>
                                <button className="btn p-0 shadow-none" onClick={handleEditSave}>
                                    <img src="images/table-green-tick.svg" alt="tick-icon" />
                                </button>
                                <button className="btn p-0 shadow-none" onClick={handleEditCancel}>
                                    <img src="images/red-cancel-icon.svg" alt="cancel-icon" />
                                </button>
                            </>
                        ) : (
                            <>
                                <button className="btn p-0 shadow-none" onClick={() => handleEdit(data.id, 'input')}>
                                    <img src="images/edit-icon-blue.svg" alt="edit-icon-blue" />
                                </button>
                                <button className="btn p-0 shadow-none" onClick={() => handleDelete(data.id, 'input')}>
                                    <img src="images/bin-icon-red.svg" alt="bin-icon-red" />
                                </button>
                            </>
                        )}
                    </div>
                </td>
            </tr>
        ));
    };

    //PS_48 - Handles the output grid rendering
    const renderOutputRows = () => {
        const { currentPage, itemsPerPage } = outputPagination;
        const startingIndex = (currentPage - 1) * itemsPerPage;
        const endingIndex = startingIndex + itemsPerPage;
        const dataToRender = filteredConfigs?.slice(startingIndex, endingIndex);

        return dataToRender?.map((data) => (
            <tr key={data.id}>
                <td className="text-start align-middle">{data.outputs}</td>
                <td className="text-start align-middle">{data.value}</td>
                <td className="text-start align-middle" title={data.description}>
                    {editId === data.id && activeTab === 'Outputs' ? (
                        <input
                            type="text"
                            name="description"
                            className="form-control custom-form font-14 font-medium h-40 py-2"
                            value={editData.description}
                            onChange={handleInputChange}
                        />
                    ) : (
                        data?.description?.length > 80 ? `${data?.description?.slice(0, 80)}...` : data?.description
                    )}
                </td>
                <td className="text-center align-middle">
                    <div className="d-flex flex-nowrap gap-3 justify-content-center align-items-center">
                        {editId === data.id && activeTab === 'Outputs' ? (
                            <>
                                <button className="btn p-0 shadow-none" onClick={handleEditSave}>
                                    <img src="images/table-green-tick.svg" alt="tick-icon" />
                                </button>
                                <button className="btn p-0 shadow-none" onClick={handleEditCancel}>
                                    <img src="images/red-cancel-icon.svg" alt="cancel-icon" />
                                </button>
                            </>
                        ) : (
                            <>
                                <button className="btn p-0 shadow-none" onClick={() => handleEdit(data.id, 'output')}>
                                    <img src="images/edit-icon-blue.svg" alt="edit-icon-blue" />
                                </button>
                                <button className="btn p-0 shadow-none" onClick={() => handleDelete(data.id, 'output')}>
                                    <img src="images/bin-icon-red.svg" alt="bin-icon-red" />
                                </button>
                            </>
                        )}
                    </div>
                </td>
            </tr>
        ));
    };


    //PS_69-PS_74 - Updates the conifgurations
    const updateModuleConfigData = async () => {
        debugger
        console.log("sdfghtyuj")
        try {
            const payload =
            {
                "userId": userId,
                "moduleId": moduleId,
                "inputs": gridData,
                "outputs": outputData
            }


            const response = await updateModuleConfig(payload);

            console.log(response, "response")
            if (response.status === 200) {

                navigate('/ServiceConfigurationMain', {
                    state:
                        { activeTab: "Modules" }
                })

            }

            //setVersions([...new Set(response.data.map(module => module.version))]);
        } catch (error) {
            console.error('Error fetching modules', error);
        }
    };

    //PS_106    
    return (
        <>
            <div className="container-fluid custom-container">
                {/* Slide title start here*/}
                <div className="d-xl-flex justify-content-between align-items-center service-configurations-title-bar py-3 pt-4 mt-2">
                    <div className="d-flex align-items-center mb-3 mb-md-0 ">
                        <a href='#' onClick={(() => {
                            navigate('/ServiceConfigurationMain')
                        }
                        )}>
                            <img
                                src="images/back-arrow.svg"
                                alt="back-arrow"
                                title="Back"
                                className="me-3 icon-filter"
                            />
                        </a>
                        <h2 className="font-20 font-bold black-v2 mb-0">Configurations</h2>
                    </div>
                    <div className="d-flex justify-content-center flex-nowrap gap-3">
                        {/* search */}
                        <div className="input-group input-search custom-border-box custom-search-box">
                            <input
                                type="search"
                                className="px-3 form-control common-bg border-0 custom-search gray-v1"
                                placeholder="Search"
                                value={searchTerm}
                                onChange={handleSearchChange}
                            />
                            <button className="btn border-start-0 custom-search " type="button">
                                <img src="images/search-icon.svg" alt="search" />
                            </button>
                        </div>
                        {/* search */}
                    </div>
                </div>
                {/* Slide title end here*/}
                {/* Page Content Start here */}
                <div className="row">
                    <div className="notification-tab-parent ">
                        <div className="notification-tab-title mb-2">
                            <ul
                                className="nav nav-tabs custom-border-bottom"
                                id="myTab"
                                role="tablist"
                            >
                                <li className="nav-item" role="presentation">
                                    <button
                                        className="nav-link active"
                                        id="input-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#input-tab-pane"
                                        type="button"
                                        role="tab"
                                        aria-controls="input-tab-pane"
                                        aria-selected="true"
                                        onClick={() => setActiveTab('Inputs')}
                                    >
                                        Inputs
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className="nav-link"
                                        id="output-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#output-tab-pane"
                                        type="button"
                                        role="tab"
                                        aria-controls="output-tab-pane"
                                        aria-selected="false"
                                        onClick={() => setActiveTab('Outputs')}
                                    >
                                        Outputs
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div className="notification-tab-content mt-4">
                            <div className="tab-content" id="myTabContent">
                                <div
                                    className="tab-pane fade show active"
                                    id="input-tab-pane"
                                    role="tabpanel"
                                    aria-labelledby="input-tab"
                                    tabIndex={0}
                                >
                                    {filteredConfigs.length != 0 ?(
                                        <>
                                        <div className="col-md-12 mb-4 pb-2 right-container-scroll">
                                            {/* table start */}
                                            <div className="table-responsive table-border">
                                                <table className="table custom-table">
                                                    <thead>
                                                        <tr>
                                                            <th className="text-start border-0" onClick={() => handleSort('input')}>
                                                                Inputs
                                                                <span className="cursor-pointer ms-2">
                                                                    <img src="images/table-sort.svg" alt="sort-icon"
                                                                        style={{
                                                                            transform: sortColumnName === "input" && sortOrder === "ASC" ? "scaleY(-1)" : "scaleY(1)",
                                                                            transition: "transform 0.2s ease-in-out"
                                                                        }}
                                                                    />
                                                                </span>
                                                            </th>
                                                            <th className="text-start border-0" onClick={() => handleSort('type')}>
                                                                Type
                                                                <span className="cursor-pointer ms-2">
                                                                    <img src="images/table-sort.svg" alt="sort-icon"
                                                                        style={{
                                                                            transform: sortColumnName === "type" && sortOrder === "ASC" ? "scaleY(-1)" : "scaleY(1)",
                                                                            transition: "transform 0.2s ease-in-out"
                                                                        }}
                                                                    />
                                                                </span>
                                                            </th>
                                                            <th className="text-start border-0">Default</th>
                                                            <th className="text-start border-0">Description</th>
                                                            <th className="text-center border-0">Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {renderInputRows()}
                                                    </tbody>
                                                </table>
                                            </div>
                                            {/* table end */}


                                            {/* pagination */}
                                     



                                            {/* pagination */}

                                            <div>
                                               
                                            </div>
                                           
                                        </div>
                                         <Pagination
                                         currentPage={activeTab === 'Inputs' ? inputPagination.currentPage : outputPagination.currentPage}
                                         setCurrentPage={page => activeTab === 'Inputs' ? setInputPagination(prev => ({ ...prev, currentPage: page })) : setOutputPagination(prev => ({ ...prev, currentPage: page }))}
                                         totalItems={filteredConfigs.length}
                                         itemsPerPage={activeTab === 'Inputs' ? inputPagination.itemsPerPage : outputPagination.itemsPerPage}
                                         displayedCount={filteredConfigs?.slice(((activeTab === 'Inputs' ? inputPagination.currentPage : outputPagination.currentPage) - 1) * (activeTab === 'Inputs' ? inputPagination.itemsPerPage : outputPagination.itemsPerPage), (activeTab === 'Inputs' ? inputPagination.currentPage : outputPagination.currentPage) * (activeTab === 'Inputs' ? inputPagination.itemsPerPage : outputPagination.itemsPerPage)).length}
                                     />
                                      <div className="col-md-12 mb-4">
                                             <div className="d-flex justify-content-between">
                                                 <button type="button" className="button cancel-button px-4 py-3 lh-1"
                                                     onClick={(() => {
                                                         navigate('/ServiceConfigurationMain')
                                                     }
                                                     )}>
                                                     Cancel
                                                 </button>
                                                 <button type="button" className="btn button primary-button py-3 lh-1" style={{ marginRight: "50px" }}
                                                     onClick={updateModuleConfigData}>
                                                     Save Details
                                                 </button>
                                             </div>
                                         </div>
                                        </>
                                    ):
                                    (
                                        <tr>
                                          <td colSpan="8" className="no-record-cell">
                                            <div className="no-record-found" style={{ paddingTop: '5%' }}>
                                              <img src="images/no-records-found.png" alt="No records found" className="no-record-icon" />
                        
                                            </div>
                                          </td>
                                        </tr>
                                      )
                                    }

                                </div>
                                <div
                                    className="tab-pane fade"
                                    id="output-tab-pane"
                                    role="tabpanel"
                                    aria-labelledby="output-tab"
                                    tabIndex={0}
                                >
                                    {filteredConfigs.length != 0 ?(
                                        <>
                                    <div className="col-md-12 mb-4 pb-2 right-container-scroll">
                                        {/* table start */}
                                        <div className="table-responsive table-border">
                                            <table className="table custom-table">
                                                <thead>
                                                    <tr>
                                                        <th className="text-start border-0" onClick={() => handleSort('outputs')}>
                                                            Outputs
                                                            <span className="cursor-pointer ms-2">
                                                                <img src="images/table-sort.svg" alt="sort-icon"
                                                                    style={{
                                                                        transform: sortColumnName === "outputs" && sortOrder === "ASC" ? "scaleY(-1)" : "scaleY(1)",
                                                                        transition: "transform 0.2s ease-in-out"
                                                                    }}
                                                                />
                                                            </span>
                                                        </th>
                                                        <th className="text-start border-0">Value</th>
                                                        <th className="text-start border-0">Description</th>
                                                        <th className="text-center border-0">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {renderOutputRows()}
                                                </tbody>
                                            </table>
                                        </div>
                                        {/* table end */}




                                        {/* pagination */}
                                       


                                        {/* pagination */}

                                        <div>
                                           
                                        </div>
                             

                                    </div>
                                    <Pagination
                                            currentPage={activeTab === 'Inputs' ? inputPagination.currentPage : outputPagination.currentPage}
                                            setCurrentPage={page => activeTab === 'Inputs' ? setInputPagination(prev => ({ ...prev, currentPage: page })) : setOutputPagination(prev => ({ ...prev, currentPage: page }))}
                                            totalItems={filteredConfigs.length}
                                            itemsPerPage={activeTab === 'Inputs' ? inputPagination.itemsPerPage : outputPagination.itemsPerPage}
                                            displayedCount={filteredConfigs?.slice(((activeTab === 'Inputs' ? inputPagination.currentPage : outputPagination.currentPage) - 1) * (activeTab === 'Inputs' ? inputPagination.itemsPerPage : outputPagination.itemsPerPage), (activeTab === 'Inputs' ? inputPagination.currentPage : outputPagination.currentPage) * (activeTab === 'Inputs' ? inputPagination.itemsPerPage : outputPagination.itemsPerPage)).length}
                                        />
                                        <div className="col-md-12 mb-4">
                                                <div className="d-flex justify-content-between">
                                                    <button type="button" className="button cancel-button px-4 py-3 lh-1"
                                                        onClick={(() => {
                                                            navigate('/ServiceConfigurationMain')
                                                        }
                                                        )}>
                                                        Cancel
                                                    </button>
                                                    <button type="button" className="btn button primary-button py-3 lh-1" style={{ marginRight: "50px" }}
                                                        onClick={updateModuleConfigData}>
                                                        Save Details
                                                    </button>
                                                </div>
                                            </div>
                                    </>
                                    ):
                                    (
                                        <tr>
                                          <td colSpan="8" className="no-record-cell">
                                            <div className="no-record-found" style={{ paddingTop: '5%' }}>
                                              <img src="images/no-records-found.png" alt="No records found" className="no-record-icon" />
                        
                                            </div>
                                          </td>
                                        </tr>
                                      )
                                    }

                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Page Content End here */}
            </div>
        </>
    );
}

export default ModulesConfigGrid;