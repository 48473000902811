// PS_02 
import React, { useState, useEffect, useContext, useRef } from 'react';
// PS_04 
import Pagination from './Pagination';
import Chart from 'react-apexcharts';
import { getAllProjectThresholdApi, getAllWorkloadByOrgIdApi, getAllEnvByWorkloadIdApi, getAllProvidersApi } from '../../Service/api';
import { useNavigate, useLocation } from 'react-router-dom';
import { OrgContext } from '../../ContextProvider/OrgContext';

// PS_05 
import SideBar from '../sidebar/SideBar';

const ManageBudget = ({ isDarkMode, setIsDarkMode }) => {
    // PS_06 
    const [data, setData] = useState([])
    // PS_07 
    const [expandedRows, setExpandedRows] = useState({});
    // PS_08 
    const [currentPage, setCurrentPage] = useState(1);
    const [textColor, setTextColor] = useState('#000000');
    const [costStatus, setCostStatus] = useState('Select');
    const [workloadName, setWorkloadName] = useState('Select');
    const [environment, setEnvironment] = useState('Select');
    const [highestActualCost, sethighestActualCost] = useState(0);
    const [workloads, setWorkloads] = useState([]);
    const [environments, setEnvironments] = useState([]);
    const [budgetFormData, setBudgetFormData] = useState({
        workloadName: '',
        workloadId: '', // Track workload ID if necessary
        environment: '',
        environmentId: ''
    });
    const [architectureName, setarchitectureName] = useState([])


    const {
        userRoles,
        orgId,
        providerId,
        architectures,
        canCreateWorkload,
        canDeleteWorkload,
        canViewWorkload,
        setuserRoles,

        // Add organization and provider fields here
        providerName,
        orgData,
        setOrgData,
        setOrgId,
        setArchitectures,

        workloadId,        // Workload ID
        aiArchitecture,
        viewMode,
        envName,
        language,
        archType,
        business,
        rulebook,
        archDesc,
        services,

        lastBuildID,
        lastBuildDate,
        lastBuildMessage,

        repoId,
        repoName,

        PulseOrganization_id,
        PulseVertical_id,
        PulseVertical_name,
        PulseOrganization_name,
        PulseIdentification,

    } = useContext(OrgContext);


    const [envId, setEnvId] = useState([]);
    const [userId, setuserId] = useState(localStorage.getItem('userId'));


    const navigate = useNavigate();

    const [filterVisible, setFilterVisible] = useState(true);

    // Sample data for dropdowns (you can replace this with your actual data)
    const costStatusOptions = ['Select', 'Monthly', 'Two', 'Three'];
    const workloadOptions = ['Select', 'One', 'Two', 'Three'];
    const environmentOptions = ['Select', 'One', 'Two', 'Three'];
    const [providers, setProviders] = useState([]);
    const [cloudProvider, setCloudProvider] = useState("AWS");
    const [cloudProviderId, setCloudProviderId] = useState("AWS");


    const [sortColumn, setSortColumn] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');

    const itemsPerPage = 5;


    // const [isDarkMode, setIsDarkMode] = useState(() => {
    //     // Set initial state from local storage
    //     return localStorage.getItem('darkTheme') === 'true';
    // });

    useEffect(() => {
        // Apply dark mode class to body based on initial state
        document.body.classList.toggle('dark-mode', isDarkMode);
        console.log("darklog", document.body.classList.toggle('dark-mode', isDarkMode));
    }, []); // Run only once on mount

    useEffect(() => {
        // Update local storage and body class whenever isDarkMode changes
        localStorage.setItem('darkTheme', isDarkMode ? 'true' : 'false');
        document.body.classList.toggle('dark-mode', isDarkMode);
        setTextColor(isDarkMode ? "#ffffff" : "#000000")
    }, [isDarkMode]);

    //PS_08 Fetch environment threshold data on component mount
    useEffect(() => {
        // Use mock data instead of fetching from an API
        // loadProjectThresholds()
        loadProviders()
    }, [isDarkMode]);
    useEffect(() => {
        // Use mock data instead of fetching from an API
        // loadProjectThresholds()
        loadWorkloads()

    }, [cloudProvider]);


    const loadProjectThresholds = async (providerId) => {
        const payload = {
            userId: userId,
            orgId: orgId,
            cloudProviderId: providerId ? providerId : cloudProviderId,
            environment_name: budgetFormData.environment,
            workload_name: budgetFormData.workloadName,
            limit: 100000000,
            offset: 1
        };
        try {
            const response = await getAllProjectThresholdApi(payload); // Call the API function
            if (response.body.success) {
                setData(response.body.data); // Update state with fetched project thresholds
                sethighestActualCost(response.body.highestBudgetCost)
                console.log("guru");
                console.log(response.body.highestActualCost)
                setBudgetFormData({
                    workloadName: '',
                    workloadId: '', // Track workload ID if necessary
                    environment: '',
                    environmentId: ''
                })
            } else {
                setData([])
                console.error('Error fetching project thresholds');
            }
        } catch (error) {
            setData([])
            console.error('An error occurred while fetching project thresholds:', error);
        }
    };

    const handleSort = (column) => {
        const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortColumn(column);
        setSortOrder(newSortOrder);

        // Sort the data based on selected column and order
        const sortedData = [...data].sort((a, b) => {
            if (newSortOrder === 'asc') {
                return a[column] > b[column] ? 1 : -1;
            } else {
                return a[column] < b[column] ? 1 : -1;
            }
        });

        setData(sortedData);
    };

    const loadProviders = async () => {
        console.log("checked providers");
        const payload = {
            userId: userId,
            orgId: orgId,
            type: "general"
        };

        try {
            const response = await getAllProvidersApi(payload); // Call the API function
            console.log("checked providers 2");

            if (response.success) {
                setProviders(response.data); // Update state with fetched providers


                // Find the provider ID for AWS and load workloads
                const awsProvider = response.data.find(provider => provider.providerName === "AWS");
                if (awsProvider) {

                    setCloudProvider(awsProvider.providerName); // Set cloud provider name
                    loadProjectThresholds(awsProvider.providerId); // Load workloads for AWS
                    setCloudProviderId(awsProvider.providerId)
                }
            } else {
                console.error('Error fetching providers');
            }
        } catch (error) {
            console.error('An error occurred while fetching providers:', error);
        }
    };

    //PS_06 Pagination logic
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
    const displayedCount = currentItems.length;

    //PS_07
    const chartWrapperStyle = {
        width: '500px', // Set the desired width
        height: '300px', // Set the desired height
        position: 'relative',
        marginTop: '-60px',
    };

    console.log("highhh", highestActualCost);
    const yAxisStep = highestActualCost / 5;


    //PS_08 Prepare chart data
    // const chartData = {
    //     series: [
    //         {
    //             name: 'Budget',
    //             data: data.map(item => item.totalBudget),
    //         }
    //     ],
    //     options: {
    //         chart: {
    //             type: 'bar',
    //             background: 'transparent',
    //             foreColor: textColor,
    //             events: {
    //                 dataPointSelection: (event, chartContext, { dataPointIndex }) => {
    //                     // Get the selected workload
    //                     const selectedWorkload = data[dataPointIndex]; // Get the entire workload object
    //                     const selectedWorkloadName = selectedWorkload.workloadName; // Get the workload name
    //                     const selectedEnvironmentId = selectedWorkload.environmentDetails[0]?.environmentId; // Get the first environment ID
    //                     const selectedWorkloadId = selectedWorkload.workloadId; // Get the workload ID

    //                     // Navigate to another page on bar click
    //                     navigate('/environmentThresholdPage', { 
    //                         state: { 
    //                             workload: selectedWorkloadName,
    //                             environmentId: selectedEnvironmentId, // Pass the environment ID
    //                             workloadId: selectedWorkloadId // Pass the workload ID
    //                         } 
    //                     });
    //                 }
    //             },


    //             toolbar: {
    //                 show: true, // Show the toolbar
    //                 tools: {
    //                     download: false // Disable the download option
    //                 }
    //             },
    //         },   plotOptions: {
    //             bar: {
    //                 borderRadius: 3,
    //                 borderRadiusApplication: 'end',
    //                 columnWidth: '20%',
    //                 dataLabels: { position: 'down' ,enabled:false},
    //             }
    //         },

    //         xaxis: {
    //             categories: data.map(item => item.workloadName),

    //         },
    //         yaxis: {
    //             min: 0,
    //             max: highestActualCost,
    //             tickAmount: 4, // Set to 4 to display 5 ticks including 0
    //         labels: {
    //             formatter: (val) => `$${val}`, // Prepend $ symbol to each label
    //         },
    //         }, grid: {
    //             borderColor: 'rgba(0, 0, 0, 0.1)', // Set the grid line color
    //             strokeDashArray: [5, 5], // Dotted lines (5px dash, 5px gap)
    //         },
    //     }
    // };


    const scrollContainerRef = useRef(null);

    const chartData = {
        series: [
            {
                name: 'Budget',
                data: data.map(item => item.totalBudget),
            }
        ],
        options: {
            chart: {
                type: 'bar',
                background: 'transparent',
                foreColor: textColor,
                events: {
                    dataPointSelection: (event, chartContext, { dataPointIndex }) => {
                        const selectedWorkload = data[dataPointIndex]; // Get the entire workload object
                        const selectedWorkloadName = selectedWorkload.workloadName; // Get the workload name
                        const selectedEnvironmentId = selectedWorkload.environmentDetails[0]?.environmentId; // Get the first environment ID
                        const selectedWorkloadId = selectedWorkload.workloadId; // Get the workload ID

                        // Navigate to another page on bar click
                        navigate('/environmentThresholdPage', {
                            state: {
                                workload: selectedWorkloadName,
                                environmentId: selectedEnvironmentId,
                                workloadId: selectedWorkloadId,
                                architectureName: selectedWorkload.architectureTypeName
                            }
                        });
                    }
                },
                toolbar: {
                    show: true, // Show the toolbar
                    tools: {
                        download: false // Disable the download option
                    }
                },
            },
            plotOptions: {
                bar: {
                    borderRadius: 2,
                    columnWidth: data.length > 5 ? '18%' : '5%',
                                        dataLabels: { position: 'down', enabled: false },
                }
            },
            xaxis: {
                categories: data.map(item => item.workloadName),
                labels: {
                    rotate: 0, // Rotate labels for better visibility
                    style: {
                        fontSize: '14px',
                    }
                },
            },
            yaxis: {
                min: 0,
                max: highestActualCost,
                tickAmount: 4,
                labels: {
                    formatter: (val) => `$${val}`, // Prepend $ symbol to each label
                    
                },
            },
            grid: {
                borderColor: isDarkMode == false ? 'rgba(0, 0, 0, 0.1)' : '#ffffff', // Set the grid line color
                strokeDashArray: [5, 5], // Dotted lines (5px dash, 5px gap)
            },
        }
    };


    // PS_09 Toggle row expansion
    const toggleRow = (workloadName) => {
        setExpandedRows(prev => ({
            ...prev,
            [workloadName]: !prev[workloadName],
        }));
    };

    //PS_07
    const handleApplyFilter = () => {
        loadProjectThresholds(cloudProviderId)
        // Implement filter logic here
        setFilterVisible(false); // Close the filter dropdown after applying
        setTimeout(() => {
            const closeButton = document.getElementById('dropdownMenuLink');
            if (closeButton) {
                closeButton.click();
            }
        }, 100);
    };
    //PS_07
    const handleClearAll = () => {
        setTimeout(() => {
            const closeButton = document.getElementById('dropdownMenuLink');
            if (closeButton) {
                closeButton.click();
            }
        }, 100);
        setBudgetFormData({
            workloadName: '',
            workloadId: '', // Track workload ID if necessary
            environment: '',
            environmentId: ''
        })
        loadProjectThresholds(cloudProviderId)

    };

    const loadWorkloads = async () => {
        const payload = {
            userId: userId,
            orgId: orgId,
            orgIdGeneral: "",
            connectorId: "",
            type: "budget",
        };
        console.log("Payload:", payload);

        try {
            const response = await getAllWorkloadByOrgIdApi(payload); // Call the API function
            if (response.success) {
                let filteredWorkloads;

                // Check cloudProvider and filter workloads accordingly
                if (cloudProvider == "AWS") {
                    console.log("aws");
                    filteredWorkloads = response.data.workload.filter(workload => workload.providerName === "AWS");
                } else if (cloudProvider == "Azure") {
                    filteredWorkloads = response.data.workload.filter(workload => workload.providerName === "Azure");
                } else {
                    // If cloudProvider is neither AWS nor Azure, handle as needed
                    filteredWorkloads = [];
                }
                console.log("aws", filteredWorkloads);
                setWorkloads(filteredWorkloads); // Update state with filtered workloads
            } else {
                console.error('Error fetching workloads');
            }
        } catch (error) {
            console.error('An error occurred while fetching workloads:', error);
        }
    };
    const loadEnvironments = async (workloadId) => {
        const payload = {
            userId: userId,
            orgId: orgId,
            workloadId: workloadId,
            type: "budget",
        };

        try {
            const response = await getAllEnvByWorkloadIdApi(payload); // Call the API function
            if (response.success) {
                console.log('Environments fetched successfully:', response.data);

                setEnvironments(response.data); // Store in setEnvironments2 if event exists

            } else {
                console.error('Error fetching environments');
            }
        } catch (error) {
            console.error('An error occurred while fetching environments:', error);
        }
    };

    const handleInputChange = (fieldName, fieldValue) => {
        setBudgetFormData(prevData => ({
            ...prevData,
            [fieldName]: fieldValue
        }));
        console.log("LOGG", fieldName);
        console.log("LOGG", fieldValue);

    };
    console.log("LOGG", budgetFormData.workloadName);


    return (
        <div class="container-fluid">
            <div class="">

                <div class="row">
                    <div class="col-md-12">
                        <div class="row">
                            <SideBar />
                            <div className="col-md-9 px-4 pt-4 right-container-scroll">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <p className="font-32 font-bold black-v2 mb-0">Budget</p>
                                            <div className="d-flex align-items-center gap-3">

                                                <div className="dropdown">
                                                    <button
                                                        type="button"
                                                        className="btn custom-dropdown d-flex justify-content-between align-items-center gap-5 font-12 font-regular color-black shadow-none"
                                                        id="dropdownMenuButton1"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                    >
                                                        <span className="d-flex">
                                                            <img src={`images/${cloudProvider.toLowerCase()}-logo.svg`} alt={`${cloudProvider}-img`} className="me-1 icon-width" />
                                                            {cloudProvider}
                                                        </span>
                                                        <img src="images/drop-down-arrow.svg" alt="dropdown-arrow" />
                                                    </button>
                                                    <ul className="dropdown-menu body-bg access-dropdown w-100" aria-labelledby="dropdownMenuButton1">
                                                        {providers.map((provider) => (
                                                            <li
                                                                key={provider.providerId} // Use providerId as the key
                                                                className="font-regular color-black font-12 p-2 custom-list"
                                                                onClick={(event) => {
                                                                    event.preventDefault(); // Prevent default action
                                                                    setBudgetFormData({
                                                                        workloadName: '',
                                                                        workloadId: '', // Track workload ID if necessary
                                                                        environment: '',
                                                                        environmentId: ''
                                                                    });
                                                                    loadProjectThresholds(provider.providerId)
                                                                    setCloudProvider(provider.providerName);
                                                                    setCloudProviderId(provider.providerId)

                                                                    // Set cloudProvider to providerName
                                                                }}
                                                            >
                                                                <span className="d-flex">
                                                                    <img src={`images/${provider.providerName.toLowerCase()}-logo.svg`} alt={`${provider.providerName}-img`} className="me-1 icon-width" />
                                                                    {provider.providerName}
                                                                </span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                {/* Dropdown for Cloud Provider */}
                                                {/* <div className="dropdown">

                                {filterVisible && (
                                    <div className="dropdown-menu body-bg access-dropdown cust-dropdown-width">
                                        <h5 className="font-medium font-16 mb-0 color-black mb-4">Filter</h5>
                                        <div className="row">
                                            <div className="col-md-6 mb-4">
                                                <label htmlFor="Workload-Name" className="form-label font-14 font-medium black-v2">Workload Name</label>
                                                <select
                                                    id="Workload-Name"
                                                    className="form-select custom-dropdown custom-select shadow-none custom-border-box py-1 font-14 font-regular black-v2"
                                                    value={workloadName}
                                                    onChange={(e) => setWorkloadName(e.target.value)}
                                                >
                                                    {workloadOptions.map((option, index) => (
                                                        <option key={index} value={option}>{option}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <label htmlFor="Environmentnew" className="form-label font-14 font-medium black-v2">Environment</label>
                                                <select
                                                    id="Environmentnew"
                                                    className="form-select custom-dropdown custom-select shadow-none form-field-border py-1 font-14 font-regular black-v2"
                                                    value={environment}
                                                    onChange={(e) => setEnvironment(e.target.value)}
                                                >
                                                    {environmentOptions.map((option, index) => (
                                                        <option key={index} value={option}>{option}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="d-flex justify-content-end align-items-center pt-3 pb-2 gap-3">
                                                    <button 
                                                        type="button" 
                                                        className="button outline-button"
                                                        onClick={handleClearAll}
                                                    >
                                                        Clear All
                                                    </button>
                                                    <button 
                                                        type="button" 
                                                        className={`btn button primary-button ${!workloadName || !environment || costStatus === 'Select' ? 'disabled' : ''}`}
                                                        onClick={handleApplyFilter}
                                                        disabled={!workloadName || !environment || costStatus === 'Select'}
                                                    >
                                                        Apply
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div> */}
                                                <button type="button"
                                                    class="filter-button p-2 position-relative lh-0 h-48 p-3"
                                                    data-bs-toggle="dropdown" id="dropdownMenuLink"
                                                    data-bs-auto-close="outside"><img src="images/filter-blue.svg"
                                                        alt="filter-icon" /> </button>

                                                <div class="dropdown-menu dropdown-menu-end border-0 custom-shadow dd-position p-3 background-white custom-filter-width"
                                                    aria-labelledby="dropdownMenuLink">
                                                    <h5 class="font-medium font-16 mb-0 color-black mb-4">Filter
                                                    </h5>


                                                    <div className="row">
                                                        <div className="col-md-6 mb-4">
                                                            <label htmlFor="Workload-Name" className="form-label font-14 font-medium black-v2">Workload Name</label>
                                                            <select
                                                                id="Workload-Name"
                                                                className="form-select custom-dropdown custom-select shadow-none form-field-border py-1 font-14 font-regular black-v2"
                                                                value={budgetFormData.workloadId} // Bind to workloadId for correct selection display
                                                                onChange={(e) => {
                                                                    const selectedWorkloadId = e.target.value; // This should be workloadId
                                                                    const selectedWorkload = workloads?.find(workload => workload.workloadId === selectedWorkloadId);

                                                                    handleInputChange('workloadName', selectedWorkload ? selectedWorkload.workloadName : '');
                                                                    handleInputChange('workloadId', selectedWorkloadId); // Update ID as well

                                                                    loadEnvironments(selectedWorkloadId);
                                                                }}
                                                            >
                                                                <option value="">Select</option>
                                                                {workloads?.map(workload => (
                                                                    <option key={workload.workloadId} value={workload.workloadId}>
                                                                        {workload.workloadName}
                                                                    </option>
                                                                ))}
                                                            </select>

                                                            {/* Displaying current selection for debugging */}

                                                        </div>
                                                        <div className="col-md-6 mb-4">
                                                            <label htmlFor="Environmentnew" className="form-label font-14 font-medium black-v2">Environment</label>
                                                            <select
                                                                id="Environmentnew"
                                                                className="form-select custom-dropdown custom-select shadow-none form-field-border py-1 font-14 font-regular black-v2"
                                                                value={budgetFormData.environmentId} // Bind to environmentId for correct selection display
                                                                onChange={(e) => {
                                                                    const selectedEnvId = e.target.value; // Get selected environment ID
                                                                    const selectedEnv = environments.find(env => env.envId === selectedEnvId); // Find the selected environment

                                                                    // Update state with both name and ID
                                                                    handleInputChange('environment', selectedEnv ? selectedEnv.envName : ''); // Update environment name
                                                                    handleInputChange('environmentId', selectedEnvId); // Update environment ID if needed
                                                                }}
                                                            >
                                                                <option value="">Select</option>
                                                                {environments.map(env => (
                                                                    <option key={env.envId} value={env.envId}>{env.envName}</option> // Render options
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div class="col-md-12">

                                                            <div className="d-flex justify-content-end align-items-center pt-3 pb-2 gap-3">
                                                                <button
                                                                    type="button"
                                                                    className="button outline-button"
                                                                    onClick={handleClearAll}
                                                                >
                                                                    Clear All
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className={`btn button primary-button`}
                                                                    onClick={() => handleApplyFilter()}
                                                                 disabled={budgetFormData.workloadName == ""}
                                                                >
                                                                    Apply
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Add Budget Button */}
                                                <button type="button" className="button primary-button font-14 font-medium d-flex align-items-center justify-content-center h-48" onClick={() => { navigate('/CostManagementForm') }} >
                                                    <img src="images/plus-icon-white.svg" alt="plus-icon-white" className="me-2 plus-icon-width" /> Add Budget
                                                </button>
                                            </div>
                                        </div>

                                        {/* Budget Graph */}
                                        {/* <div className="col-md-12 mt-2 mb-3">
                    <div className="count-container-2" style={{ padding: '20px', position: 'relative' }}>
                    {chartData?.series && chartData.series.length > 0 && chartData.series[0].data.length > 0 ? (
    <Chart 
        options={chartData.options}
        series={chartData.series}
        type="bar"
        height={250} // Keep this consistent with your container height
    />
) : (
    // No data found message
    <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: '250px' }}>
        <p className="font-medium font-12 text-center">
            No data available for the graph.
        </p>
    </div>
)}
</div>
        </div> */}

                                        <div className='count-container-2' style={{ width: '100%', height: 250, minWidth: '300px', position: 'relative' }}>
                                            {chartData?.series && chartData.series.length > 0 && chartData.series[0].data.length > 0 ? (
                                                <div
                                                    ref={scrollContainerRef}
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        overflowX: 'auto',
                                                        overflowY: 'hidden'
                                                    }}
                                                >
                                                    <div style={{ width: `${data.length>5?data.length * 200:1040}px`, height: '100%' }} className='threshold-chart'> {/* Adjust width based on number of bars */}
                                                        <Chart
                                                            options={chartData.options}
                                                            series={chartData.series}
                                                            type="bar"
                                                            height="100%" // Keep this consistent with your container height
                                                        />
                                                    </div>
                                                </div>
                                            ) : (
                                                // No data found message
                                                <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: '250px' }}>
                                                    <p className="font-medium font-12 text-center">
                                                        No data available for the graph.
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                        {/* <div className="chart-container mb-4">
                <Bar data={chartData}
                 options={{
                    scales: {
                      y: {
                        min: 0,
                        max: highestActualCost,
                        stepSize: yAxisStep,
                      },
                    },
                  }} />
            </div> */}

                                        {/* Budget Grid */}
                                        <table className="table custom-table acc-table">
                                            <thead>
                                                <tr>
                                                    <th className="border-0 p-4"></th>
                                                  
                                                    <th className="text-nowrap text-start border-0" onClick={() => handleSort('workloads')}>
                                                        Workloads
                                                        <span className="cursor-pointer ms-2">
                                                            <img
                                                                src="./images/table-sort.svg"
                                                                alt="sort"
                                                                style={{
                                                                    transform:
                                                                        sortColumn === "workloads" && sortOrder === "asc"
                                                                            ? "scaleY(-1)"
                                                                            : "scaleY(1)",
                                                                    transition: "transform 0.2s ease-in-out",
                                                                    cursor: 'pointer'
                                                                }}
                                                            />
                                                        </span>
                                                    </th>
                                                    <th className="text-nowrap text-end border-0" onClick={() => handleSort('budget')}>
                                                        Budget
                                                        <span className="cursor-pointer ms-2">
                                                            <img
                                                                src="./images/table-sort.svg"
                                                                alt="sort"
                                                                style={{
                                                                    transform:
                                                                        sortColumn === "budget" && sortOrder === "asc"
                                                                            ? "scaleY(-1)"
                                                                            : "scaleY(1)",
                                                                    transition: "transform 0.2s ease-in-out",
                                                                    cursor: 'pointer'
                                                                }}
                                                            />
                                                        </span>
                                                    </th>
                                                    <th className="text-nowrap text-end border-0" onClick={() => handleSort('actualCost')}>
                                                        Actual Cost
                                                        <span className="cursor-pointer ms-2">
                                                            <img
                                                                src="./images/table-sort.svg"
                                                                alt="sort"
                                                                style={{
                                                                    transform:
                                                                        sortColumn === "actualCost" && sortOrder === "asc"
                                                                            ? "scaleY(-1)"
                                                                            : "scaleY(1)",
                                                                    transition: "transform 0.2s ease-in-out",
                                                                    cursor: 'pointer'
                                                                }}
                                                            />
                                                        </span>
                                                    </th>
                                                    <th className="text-nowrap text-end border-0" onClick={() => handleSort('forecastedCost')}>
                                                        Forecasted Cost
                                                        <span className="cursor-pointer ms-2">
                                                            <img
                                                                src="./images/table-sort.svg"
                                                                alt="sort"
                                                                style={{
                                                                    transform:
                                                                        sortColumn === "forecastedCost" && sortOrder === "asc"
                                                                            ? "scaleY(-1)"
                                                                            : "scaleY(1)",
                                                                    transition: "transform 0.2s ease-in-out",
                                                                    cursor: 'pointer'
                                                                }}
                                                            />
                                                        </span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentItems.length > 0 ? (
                                                    currentItems.map((item) => (
                                                        <React.Fragment key={item.workloadName}>
                                                            <tr className="accordion-middle" onClick={() => toggleRow(item.workloadName)} style={{ cursor: 'pointer' }}>
                                                                <td className="text-center p-4 w-60">
                                                                    <span className="notify-viewmore table-accordion-arrow" data-bs-toggle="collapse"
                                                                        data-bs-target="#cloud1">
                                                                        <img className="notifications-arrow cursor-pointer"
                                                                            src="images/notifify-right-arrow.svg"
                                                                            alt="notifications-arrow" />
                                                                    </span>
                                                                </td>
                                                                <td className="text-nowrap text-start">{item.workloadName}</td>
                                                                <td className="text-nowrap text-end">${item.totalBudget}</td>
                                                                <td className="text-nowrap text-end success-text" style={{ color: item.totalActualCost > item.budget ? 'red' : 'green' }}>${item.totalActualCost}</td>
                                                                <td className="text-nowrap text-end">${item.totalForecastedCost}</td>
                                                            </tr>
                                                            {expandedRows[item.workloadName] && (
                                                                <tr className="collapse show" id="cloud1">
                                                                    <td colSpan="5" className="inner-table px-5 pt-0 pb-0">
                                                                        <span className="table-responsive mt-4 table-border border-0">
                                                                            {/* Service Cost Grid */}
                                                                            <table className="table custom-table acc-table">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th className="text-nowrap text-start border-0">Service</th>
                                                                                        <th className="text-nowrap text-end border-0">Actual Cost</th>
                                                                                        <th className="text-nowrap text-end border-0">Forecasted Cost</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody className="acc-child">
                                                                                    {item.services.map((service) => (
                                                                                        <tr key={service.service}>
                                                                                            <td className="text-start">{service.serviceName}</td>
                                                                                            <td className="text-end">${service.actualCost}</td>
                                                                                            <td className="text-end">${service.forecastedCost}</td>
                                                                                        </tr>
                                                                                    ))}
                                                                                </tbody>
                                                                            </table>
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </React.Fragment>
                                                    ))
                                                ) : (
                                                    // No records found message
                                                    <tr>
                                                        <td colSpan={5} className="text-center"> {/* Adjust colSpan based on the number of columns */}
                                                            <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: '160px' }}>
                                                                <img src="images/nodata-found.svg" alt="No Data Found" />
                                                                <h6 className="color-black font-bold font-16 mt-5 mb-0">No Data Found!</h6>

                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>

                                        {/* Pagination Controls */}
                                        <Pagination
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            totalItems={data.length} // Assuming 'data' is your array of items
                                            itemsPerPage={itemsPerPage} // Define how many items you want per page
                                            displayedCount={displayedCount}
                                        />


                                    </div>
                                </div>
                            </div>
                        </div></div></div></div></div>

    );
};

export default ManageBudget;