import { useContext, useEffect, useState } from "react";
import Header from "../Header/Header";
import { useNavigate } from "react-router-dom";
import { deleteServiceRequest, fetchAllService, fetchCategoryData, fetchEditServiceData } from "../../Service/api";
import { fetchAllCloudProvider } from "../../Service/api";
import { OrgContext } from "../../ContextProvider/OrgContext";
import WorkbechPopup from "../Organization/Workbench/WorkBench/PopupMaintanence/WorkBenchPopUp";
import Loader from "../loader/loader";

function SelfService() {



    //PS_03
    const navigate = useNavigate();
    // const { cloudProviderId, userId } = useContext(UserContext);

    const [isLoading, setIsLoading] = useState(true);
    const [search, setSearch] = useState('');
    const [filterData, setFilterData] = useState('');
    // const [providerId, setProviderId] = useState(cloudProviderId);
    const [showSuccessToast, setShowSuccessToast] = useState(false);
    const [categoryData, setCategoryData] = useState([]);
    const [error, setError] = useState(null);
    const [imageBase64, setImageBase64] = useState('');
    const [servicesData, setServicesData] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [serviceData, setServiceData] = useState(null);
    const [noRecordsMsg, setNoRecordsMsg] = useState("No Records Found");

    const [cloudProviderData, setCloudProviderData] = useState([])
    const [deletePoup, setDeletePopup] = useState(false)
    const [serviceName, setServiceName] = useState("")

    const [selectedProviderId, setSelectedProviderId] = useState('');

    const selectedProviderName = cloudProviderData.find(provider => provider.providerId === selectedProviderId)?.providerName;

    //const [cloudProviderData, setCloudProviderData] = useState([])

    const [popupMsg, setPopUpMsg] = useState({ "Msg": "", show: false, success: false })
    // const [loading, setLoading] = useState(true);

    const userId = localStorage.getItem('userId');
    console.log("userid", userId)

    const { orgId } = useContext(OrgContext)

    console.log(orgId, "organization")
    const [showLoader, setShowLoader] = useState(false)
    const [serviceIdToDelete, setServiceIdToDelete] = useState(null);


    useEffect(() => {
        getCloudProviderData()
    }, [])


    //PS_05 - PS_06
    useEffect(() => {
        setShowLoader(true)
        fetchAllCategoryData()
        setShowLoader(false)
        getAllService(search);
    }, [search, selectedCategory, selectedProviderId]);

    useEffect(() => {
        console.log("selected cloud provider", selectedProviderId)
    }, [selectedProviderId])

    //PS_07 - PS_13
    // Get all services function
    const getAllService = async (searchData) => {
        const payload = {
            "cloudproviderID": selectedProviderId,
            "userId": userId,
            "orgId": "",
            "searchData": searchData,
            "categoryId": selectedCategory
        }
        try {
            setShowLoader(true)
            const response = await fetchAllService(payload);
            setShowLoader(false)
            if (response.status === 200) {
                const { body } = response.data;
                const { data } = body;


                console.log("This is the service ====>-", data)

                // Store the destructured data
                const filteredCategories = data.filter(category => category.services && category.services.length > 0);

                // Store the filtered categories in state
                setServicesData(filteredCategories);

            } else {
                throw new Error('Error fetching service data');
            }
            setShowLoader(false)
        } catch (err) {
            // postErrorLog(err.message || err.toString());
            setShowLoader(false)
            setError(err);
        } finally {
            setIsLoading(false);
        }
    };


    //PS_59
    const searchRecord = (e) => {
        const newValue = e.target.value;
        if (isValidInput(newValue)) {
            setSearch(newValue);
            getAllService(newValue);
            setNoRecordsMsg(servicesData.length === 0 ? "No Search Record Found" : "");
        }
    };

    //PS_62-PS_63
    const isValidInput = (input) => {
        const regex = /^[a-zA-Z0-9_-]*$/;
        return regex.test(input);
    };



    //PS_19 - PS_25
    // Category dropdown function
    const fetchAllCategoryData = async () => {
        try {
            const payload = {
                "cloud_provider_id": selectedProviderId,
                "userId": userId
            }
            const response = await fetchCategoryData(payload);
            if (response.status === 200) {
                console.log(response, "resp")
                setCategoryData(response.data.body.data);
            } else {
                throw new Error('Error fetching category data');
            }
        } catch (err) {
            //postErrorLog(err.message || err.toString());
            setError(err);
        }
    };



    // PS_33 - PS_41
    // Delete service function

    const handleDeleteService = async (serviceId) => {
        try {
            const payload = {
                userId: userId,
                serviceId: serviceId
            }

            const response = await deleteServiceRequest(payload);
            if (response.status === 200) {
                setPopUpMsg({ ...popupMsg, ["Msg"]: "Service deleted successfully", ["show"]: true, ["success"]: true });
                setShowSuccessToast(true);
                setTimeout(() => setShowSuccessToast(false), 2000);
                await getAllService(search);
            } else {
                throw new Error('Error deleting service');
            }
        } catch (err) {
            setError(err);
            setPopUpMsg({ ...popupMsg, ["Msg"]: "Service deletion failed", ["show"]: true, ["success"]: false });
        } finally {
            setDeletePopup(false);
            setServiceIdToDelete(null);
        }
    };
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'long' }; // Customize as needed
        return date.toLocaleDateString(undefined, options);
    };

    //PS_47 - PS_48
    const renderServices = () => {
        if (servicesData.length === 0) {
            return (
                <tr>
                    <td colSpan="7" style={{ textAlign: 'center', padding: '20px' }}>
                        No Services found matching your search.
                    </td>
                </tr>
            );
        }

        return servicesData.map(category => (
            <div key={category.serviceCategory} className="">
                <h2 className="font-18 font-bold color-black">{category.serviceCategory}</h2>
                <div className="row mt-3 service-configurations-section">
                    {category.services.map(service => (
                        <div
                            title={service.serviceName}
                            key={service.serviceId} className="col-md-2 mb-4">
                            <div className="service-configurations-grid p-4 position-relative" style={{
                                width: '200px',  // Set a fixed width
                                height: '150px', // Set a fixed height
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between'
                            }}>
                                <div>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center" style={{ width: '100%' }}>
                                            <img
                                                className="partner-logo-circle grid-blur"
                                                src={service.serviceImage}
                                                alt={`${service.serviceName}-logo`}
                                                width="32"
                                                height="32"
                                            />
                                            <p className="font-18 font-bold black-v2 ms-3 mb-0 partner-name grid-blur" style={{
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                maxWidth: 'calc(100% - 40px)' // Adjust based on your layout
                                            }}>
                                                {service.serviceName}
                                            </p>
                                        </div>
                                        <span className="Workload-right-arrow" />
                                    </div>
                                    <p className="mt-4 font-14 font-medium secondary-text-color-v4 mb-0 grid-blur">
                                        Created: <span className="font-14 font-medium">{formatDate(service.createdAt)}</span>
                                    </p>
                                </div>
                                <div className="grid-options">
                                    <a className="color-black font-13 font-medium text-decoration-none d-block cursor-pointer mb-2"
                                        onClick={() => {
                                            navigate('/AddNewService1', { state: { serviceEditData: service, serviceData: servicesData } });
                                        }}>
                                        <img
                                            src="images/articles-edit-icon-blue.svg"
                                            alt="edit-icon"
                                            className="articles-option-icons me-2"
                                        />
                                        Edit
                                    </a>
                                    <a className="color-black font-13 font-medium text-decoration-none d-block cursor-pointer mt-2"
                                        onClick={() => { handleDeletePopUp(service.service_id, service.serviceName) }}>
                                        <img
                                            src="images/articles-delete-icon.svg"
                                            alt="delete-icon"
                                            className="articles-option-icons me-2"
                                        />
                                        Delete
                                    </a>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        ));
    };

    function handleDeletePopUp(serviceId, serviceName) {
        setDeletePopup(true);
        setServiceName(serviceName);
        // Store the serviceId for later use
        setServiceIdToDelete(serviceId);
    }

    const getCloudProviderData = async () => {
        try {
            const payload = {
                "userId": userId,
                "orgId": "", // Assuming orgId is intentionally left empty
                "type": "general"
            };

            const response = await fetchAllCloudProvider(payload); // Replace with your actual API call function
            console.log(response, "response");

            if (response.status === 200) {
                setCloudProviderData(response.data.data);

                console.info(response.data.data, "This is the cloud provider data")

                for (let values of response.data.data) {

                    if (values?.providerName == 'AWS') {
                        console.log("Inside the data of the self service")
                        setSelectedProviderId(values?.providerId)
                    }

                }

                // Store the response in a state variable
            } else {
                console.error("Error fetching cloud provider data:", response.data.body.message);
            }
        } catch (error) {
            console.error("API Error:", {
                errorName: error.name,
                errorServiceName: "Front-end",
                errorFunctionName: "getCloudProviderData",
                errorSource: "frontend",
                errorDescription: "fetch failed: " + error.message
            });
        }
    };


    console.log(selectedProviderId, "This is the selected id")


    const handleAddService = () => {
        navigate('/AddNewService1', { state: { serviceData: servicesData, categoryData: categoryData, provider: cloudProviderData } })
    }

    const closeDeletePopup = () => {
        setDeletePopup(false)

    }



    //PS_31 -PS_32 - Navigates to add new service component
    return (
        <>
            {
                popupMsg.show == true && popupMsg.Msg != "" ?
                    <WorkbechPopup
                        popupMsg={popupMsg}
                        setPopUpMsg={setPopUpMsg}
                    />
                    : <></>
            }
            {/* header starts */}

            {/* header ends */}
            {showLoader ?
                <Loader /> :

                <div className="container-fluid custom-container right-container-scroll">
                    {/* Slide title start here*/}
                    <div className="d-xl-flex justify-content-between align-items-center service-configurations-title-bar py-3 pt-4 mt-2">
                        <h2 className="font-20 font-bold black-v2 mb-0">
                            Self Service Configurations
                        </h2>
                        <div className="d-flex justify-content-center flex-nowrap gap-3">
                            {/* dropdown */}
                            <div className="dropdown">
                                <button
                                    type="button"
                                    className="btn custom-dropdown d-flex justify-content-between align-items-center gap-3 font-12 font-regular color-black shadow-none"
                                    id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <span className="d-flex">
                                        {selectedProviderName === "AWS" ? ( // Check against provider name
                                            <>
                                                <img
                                                    src="images/aws-black-icon.svg"
                                                    alt="aws-img"
                                                    className="me-1 icon-width light-theme-img"
                                                />
                                                <img
                                                    src="images/aws-logo-dark.svg"
                                                    alt="aws-img"
                                                    className="me-1 icon-width dark-theme-img"
                                                />
                                            </>
                                        ) : (
                                            <img
                                                src="images/azure-logo.svg"
                                                alt="azure-img"
                                                className="me-1 icon-width"
                                            />
                                        )}
                                        {selectedProviderName} {/* Display the selected provider name */}
                                    </span>
                                    <img src="images/drop-down-arrow.svg" alt="dropdown-arrow" />
                                </button>
                                <ul
                                    className="dropdown-menu body-bg access-dropdown w-100"
                                    aria-labelledby="dropdownMenuButton1"
                                >
                                    {cloudProviderData.map((provider) => (
                                        <li
                                            key={provider.providerId}
                                            className="font-regular color-black font-12 p-2 custom-list"
                                            onClick={() => setSelectedProviderId(provider.providerId)} // Set ID based on selection
                                        >
                                            <span className="d-flex align-items-center">
                                                {provider.providerName === "AWS" ? ( // Check against provider name
                                                    <>
                                                        <img
                                                            src="images/aws-black-icon.svg"
                                                            alt="aws-img"
                                                            className="me-1 icon-width light-theme-img"
                                                        />
                                                        <img
                                                            src="images/aws-logo-dark.svg"
                                                            alt="aws-img"
                                                            className="me-1 icon-width dark-theme-img"
                                                        />
                                                    </>
                                                ) : (
                                                    <img
                                                        src="images/azure-logo.svg"
                                                        alt="azure-logo"
                                                        className="me-1 icon-width"
                                                    />
                                                )}
                                                {provider.providerName} {/* Display the provider name */}
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            {/* dropdown */}
                            <div className="input-group title-dropdown">
                                <select
                                    name="region"
                                    id="select-category"
                                    className="form-select font-14 font-regular black-v2"
                                    value={selectedCategory}
                                    onChange={(e) => { setSelectedCategory(e.target.value) }}
                                >
                                    <option value="" >Select Category</option>
                                    {categoryData.map((category) => (
                                        <option key={category.serviceCategoryId} value={category.serviceCategoryId}>
                                            {category.serviceCategoryName}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {/* search */}
                            <div className="input-group input-search custom-border-box custom-search-box">
                                <input
                                    type="search"
                                    className="px-3 form-control common-bg border-0 custom-search gray-v1"
                                    placeholder="Search"
                                    value={search}
                                    onChange={(e) => { searchRecord(e) }}
                                />
                                <button className="btn border-start-0 custom-search " type="button" onClick={getAllService}>
                                    <img src="images/search-icon.svg" alt="search" />
                                </button>
                            </div>
                            {/* search */}
                            {/* add work load btn */}
                            <button type="button" className="button primary-button text-nowrap" onClick={handleAddService}>
                                <img src="images/add-icon.svg" alt="add-icon" className="me-2" /> Add
                                New Service
                            </button>

                            {/* add work load btn */}
                        </div>
                    </div>
                    <p className="secondary-color font-14 font-regular mt-1 mb-3 custom-para-title-bar line-height-24">
                        You can create services and add the input configurations against those
                        services so that the same would get reflected on the workbench.
                    </p>



                    {/* Slide title end here*/}


                    {/* Page Content Start here */}
                    <div className="row">
                        {servicesData.length === 0 ? (
                            <tr>
                                <td colSpan="8" className="no-record-cell">
                                    <div className="no-record-found">
                                        <img src="images/no-records-found.png" alt="No records found" className="no-record-icon" />

                                    </div>
                                </td>
                            </tr>
                        ) : (
                            renderServices()
                        )}

                        {deletePoup && (
                            <div
                                className="modal fade show"
                                id="staticBackdrop-25"
                                data-bs-backdrop="static"
                                data-bs-keyboard="false"
                                tabIndex={-1}
                                aria-labelledby="staticBackdropLabel"
                                aria-hidden={!deletePoup}
                                style={{ display: 'block', backgroundColor: "rgba(0,0,0,0.6)" }}
                            >
                                <div className="modal-dialog modal-dialog-centered justify-content-center">
                                    <div className="modal-content border-0">
                                        <div className="modal-header d-flex justify-content-end border-0 pb-0 ">
                                            <button
                                                type="button"
                                                className="btn shadow-none"
                                                //data-bs-dismiss="modal"
                                                aria-label="Close"
                                                onClick={closeDeletePopup}
                                            >
                                                <img
                                                    src="images/close-icon.svg"
                                                    alt="close-icon"
                                                    className="icon-hover shadow-none modal-close-filter"
                                                />
                                            </button>
                                        </div>
                                        <div className="modal-body text-center pt-0 p-32">
                                            <img
                                                src="images/delete-organization-icon.svg"
                                                alt="delete the Organization"
                                            />
                                            <h6 className="font-20 font-bold black-v2 mt-4 pt-2">
                                                You’re about to delete the Service
                                            </h6>
                                            <p className=" font-16 font-regular secondary-text-color-v5 mt-3 line-height-32 mb-0">
                                                "{serviceName}"
                                            </p>
                                            <p className=" font-16 font-regular secondary-text-color-v5 mt-2 line-height-32">
                                                Do you want to proceed?
                                            </p>
                                            <div className="d-flex justify-content-center gap-3 align-items-center mt-4 pt-3">
                                                <button
                                                    type="button"
                                                    className="button outline-button text-nowrap px-32"
                                                    data-bs-dismiss="modal"
                                                    onClick={() => {
                                                        handleDeleteService(serviceIdToDelete);
                                                        closeDeletePopup();
                                                    }}
                                                >
                                                    Yes
                                                </button>
                                                <button
                                                    type="button"
                                                    className="button primary-button popup-btn px-32"
                                                    onClick={closeDeletePopup}
                                                >
                                                    No
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}




                    </div>
                    {/* Page Content End here */}
                </div>
            }

        </>
    )
}

export default SelfService;



