//SQ_NO_1.1 Workbech Import statements
import React, { useMemo, useState, useRef, useCallback, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";
import * as base64 from "base-64";
import * as htmlToImage from "html-to-image";
import { v4 as uuidv4 } from 'uuid';  // Importing uuid
// import "../../ComponentCSS/reactflow.css"
// import "../../ComponentCSS/workbench.css"
import "../WorkBench/ReactflowCustom.css"

import {
    ReactFlow,
    ReactFlowProvider,
    Background,
    useNodesState,
    useEdgesState,
    addEdge,
    Controls,
    Handle,
    MarkerType,
    NodeResizer,
    useReactFlow,
    applyNodeChanges,
    applyEdgeChanges

} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import moment from "moment";

import Draggable from "react-draggable";
import ConfigureComponent from "./ConfigureComponent";
import SideBarComponent from "./SideBarComponent";
import CommitPopup from "./PopupMaintanence/CommitPopup";
import VersionHistoryPopUp from "./PopupMaintanence/VersionHistory";
import AccountConfigureComponent from "./AccountConfigureComponent";
import SaveTemplatePopup from "./PopupMaintanence/SaveTemplate";
import { useOrgContext } from "../../../../ContextProvider/OrgContext";
import { fetchdriftApi, getAccountDetailsApi, getarchitectureByIDApi, saveArchietctureApi, getAllServiceReviewApi, fetchDataConfigs } from "../../../../Service/api";
import ReviewDrifts from "./ReviewDrifts";
import { RegenerateButton } from "./PopupMaintanence/RegenerateButton";
import ToastMessage from "./PopupMaintanence/ToastMessage";
import WorkbechPopup from "./PopupMaintanence/WorkBenchPopUp";
import AssignTags from "./PopupMaintanence/AssignTags";
import { FormContext } from "../../Workload_Grid";
import { EstimationContext, useEstimation } from "../../../../ContextProvider/EstimationContext";
import WorkbenchLoader from "../WorkBenchLoader";
import WorkbenchPopupComponent from "./PopupMaintanence/WorkBenchPopUpComponent";
import RightSidebar from "./RightSidebar";
import LocalsOutputsComponent from "./IAC_Component";
import DataConfiguration from "./DataConfiguration";
import ToastPopup from "../../../Toast/toastPopup";





function Workbench(isDarkMode) {
    //SQ_NO_1.2-1.39 initialize state variables and import useOrgContext
    const {
        orgId,
        envId,
        setEnvId,
        workloadId,
        providerId,
        providerName,
        envName,
        setEnvName,
        PusleIdentificaiton,
        workloadRole,
        UTI,
        colorMode,
        userRoles,
    } = useOrgContext();
console.log("isDarkModeisDarkMode3",isDarkMode);

    const { formData, setFormData } = useContext(FormContext);
    console.log(formData, "FormData")
    const [userId, setuserId] = useState(localStorage.getItem('userId'));
    console.log(providerId, "providerId")
    console.log(providerName, "providerName")
    console.log(orgId, "orgId")
    console.log(userId, "userId")
    console.log(envId, "envId")
    console.log(UTI, "UTI")
    console.log(colorMode, "colorMode")

    const [isLoading, setIsLoading] = useState(false);

    const { setEstimationNodes } = useContext(EstimationContext)

    console.log('Organization ID:', orgId);
    console.log("envId", envId)
    const initialNodes = [];
    const initialEdges = [];
    const reactFlowWrapper = useRef(null);
    const { screenToFlowPosition } = useReactFlow();
    //PS_09
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    //PS_10
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
    const reactFlowInstance = useReactFlow();

    /**
     * Draggable Stack Maintance for the Undo and Redo funcionalites
     */
    //PS_12
    const [undoStack, setUndoStack] = useState([]);
    //PS_13
    const [redoStack, setRedoStack] = useState([]);
    /**
     * Workbench State Variables 
     */
    //PS_14
    const [configureDetails, setConfigureDetails] = useState({ id: "" });
    const [toastMsg, setToastMsg] = useState({ show: false, message: '', success: true });

    //PS_15
    const [accountStatus, setAccountStatus] = useState({});
    //PS_16
    const [stateForCount, setStateForCount] = useState([]);
    const [stateForCount2, setStateForCount2] = useState([]);

    const [isCommitModalOpen, setIsCommitModalOpen] = useState(false);
    const [isVersionHistoryModalOpen, setIsVersionHistoryModalOpen] = useState(false);
    const [isSaveTemplateModalOpen, setIsSaveTemplateModalOpen] = useState(false)
    const [templateProviderType, setTemplateProviderType] = useState(providerName ? providerName : "Azure")
    const [connectedSourceNodes, setConnectedSourceNodes] = useState([])
    const [repositories, setRepositories] = useState([]);
    const [selectedEnv, setSelectedEnv] = useState({
        environment_id: envId,
        environment_name: envName
    })
    const navigate = useNavigate();
    const [isToastPopupOpen, setIsToastPopUpOpen] = useState(false)
    const [istoastMessage, setToastMessage] = useState({ success: "", failure: "" })
    const [viewMode, setViewMode] = useState(workloadRole.toLowerCase() == 'viewer' ? true : false)

    // Review Drifts Scenario
    const [reviewDriftsData, setreviewDriftsData] = useState([])
    const [isReviewDriftModelOpen, setIsReviewDriftModelOpen] = useState(false)
    const [reviewDrifts, setReviewDrifts] = useState([
    ])

    // Regenerate State Variables
    const [regenerationText, setRegenerationText] = useState('')


    const [preventSaving, setPreventSaving] = useState(false)
    const [imageUrl, setImageUrl] = useState('');
    const [popupMsg, setPopUpMsg] = useState({ "Msg": "", show: false, success: false })
    const [showLoader, setShowLoader] = useState(false)
    const [showAssignTags, setShowAssignTags] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [deleteNodeService, setDeleteService] = useState({})
    const { getNode } = useReactFlow();
    const [deletedNode, setDeletedNode] = useState([]);
    const [isIACModelOpen, setIACModelOpen] = useState(false)
    const [activeTab, setActiveTab] = useState(''); // Default to 'locals'
    const [dataConfigModal, setDataConfigModal] = useState(false)
    const [intialAccountState, setInitalAccountState] = useState(0)
    const [initialDataConfig, setinitialDataConfig] = useState([])
    const [nodeDetails, setNodeDetails] = useState([]);

    useEffect(() => {
        console.log("sagin purpose", edges)
    }, [edges])

    // React Flow Some Times Throws the ResizeObserver loop completed with undelivered notifications" ||
    //"ResizeObserver loop limit exceeded
    //TO Remove that  use this Below Code 
    //  PS_02 -  PS_10 
    // Drift scenario 
    useEffect(() => {
        if (!envId || !providerId) {
            navigate('/workloadsGrid')
        }
        fetchDrift()
    }, [])
    useEffect(() => {
        const errorHandler = (e) => {
            if (
                e.message.includes(
                    "ResizeObserver loop completed with undelivered notifications" ||
                    "ResizeObserver loop limit exceeded"
                )
            ) {
                const resizeObserverErr = document.getElementById(
                    "webpack-dev-server-client-overlay"
                );
                if (resizeObserverErr) {
                    resizeObserverErr.style.display = "none";
                }
            }
        };
        window.addEventListener("error", errorHandler);

        return () => {
            window.removeEventListener("error", errorHandler);
        };
    }, []);
    // Data Configurations Retrieval 
    useEffect(() => {

    }, [dataConfigModal])
    // Workbench Functionalities 

    //SQ_NO_1.40 UseEffect to call getArchitectureById
    useEffect(() => {

        getArchitectureById()
        fetchDrift()


    }, [envId])

    useEffect(() => {
        fetchAccountDetails()
    }, [providerId])
    // Change of Architecture when the user Switches from Once Architecture to Another
    useEffect(() => {
        if (selectedEnv.environment_id != envId
            &&
            selectedEnv.environment_id
        ) {
            setEnvId(selectedEnv.environment_id)
            setEnvName(selectedEnv.environment_name)
        }
    }, [selectedEnv])
    useEffect(() => {
        if (preventSaving == false) {
            //PS_35 Save Architecture is Triggered on the Node Onchange 
            saveArchitecture()
            // Nodes for the Estimation Purposes 
            if (Array.isArray(nodes)) {
                // Nodes for the Estimation Purposes 
                let estimationNodes = nodes?.filter((val) => val.data.serviceName !== "Account");
                setEstimationNodes(estimationNodes);
            } else {
                console.error("nodes is not an array:", nodes);
                // Handle the case where nodes is not an array (e.g., set to an empty array, throw an error, etc.)
                setEstimationNodes([]); // Or handle this scenario appropriately
            }
            // This Handling is made if the user removes all the Node , the Account Should not be sent to the Data 
            // Configuration Page 

            if (nodes.length == 0) {
                // Set State the account to Intial State
                setAccountIds([])
            }
        }

    }, [nodes, edges])

    /**
     * Save Architecture Function Which is Triggered on the every action Performed in the Workbench allowing 
     * the user to save all the architecture Drafted 
    */
    //SQ_NO_3.6
    async function saveArchitecture(params) {
        try {
            if (viewMode == false) {
                console.log("Save Architecture is Triggered")
                console.log(nodes, "Nodesss")
                let nodeData = {
                    nodes,
                    edges
                };
                console.log(nodeData, "NodeData")
                if (nodeData["nodes"].length != 0) {
                    let payload = {
                        userId: userId,
                        orgId: orgId,
                        envId: envId,
                        xml: JSON.stringify(nodeData)
                    }
                    console.log(payload, "Payload in Save Architecture")
                    let res = await saveArchietctureApi(payload)
                    console.log(res, "Response from Save architecture")
                    if (params == 0) {
                        console.log("Saveed Arch")
                    }
                }
            }

        }
        catch (error) {
            console.log(error)
        }
    }

    /**
     * Get Architecture By Id Function Triggered Once the user is Navigated to Workbench 
    */

    //SQ_NO_1.42
    async function getArchitectureById() {
        try {
            console.log("Triggering the GetArchitectureByID");
            if (envId !== "") {
                let payload = {
                    userId,
                    orgId,
                    envId,
                };
                try {
                    console.log(payload, "in Get Architecture");
                    // if (isReviewDriftModelOpen == false) {
                    //     setShowLoader(true);
                    // }
                    setShowLoader(true);
                    let jsonData = await getarchitectureByIDApi(payload);
                    setShowLoader(false);
                    console.log(jsonData, "JSON Data");
                    //SQ_NO_ 1.58
                    if (jsonData.success) {
                        //SQ_NO_1.63
                        let parsedJson = JSON.parse(jsonData.data);
                        console.log("parsedjson", parsedJson);


                        // Replace this part in your code
                        const processedNodes = parsedJson.nodes.map(node => {
                            console.log(node, "Node");
                            //SQ_NO_1.64 for each node in parsedJson.nodes
                            const nodeElement = createNodeElement(node);
                            console.log(nodeElement, "Node Element");

                            // Check if UTI tag already exists To avaoid Duplications this scenario 
                            // This scenario is maily Handled for if a architecture is been Drafted in th B2A and Cloned here 
                            // That architecture will not have the UTI associated with that
                            console.log(node.data.Tags, 'Tags before')
                            let updatedTags = (node.data.Tags || []).map(tag => {
                                if (tag.key === UTI.Key) {
                                    // If UTI key exists, update its value
                                    return { ...tag, value: UTI.Value };
                                }
                                return tag;
                            });

                            // If no UTI tag was found and updated, add a new one
                            if (!updatedTags.some(tag => tag.key === UTI.Key)) {
                                updatedTags.push({ key: UTI.Key, value: UTI.Value });
                            }
                            console.log(updatedTags, "Updatedd Tags ")

                            // While Cloning Sometimes the Service Family might not be retrieved 
                            let serviceFamily = node.data.serviceFamily ? node.data.serviceFamily : "Other"

                            return {
                                ...node,
                                type: nodeElement.type,
                                style: nodeElement.style,
                                data: {
                                    ...node.data,
                                    element: nodeElement.element,
                                    Tags: updatedTags,
                                    serviceFamily: serviceFamily
                                },
                            };
                        });
                        let intialAccountLength = processedNodes.filter((val, index) => {
                            return val.data.serviceName == "Account"
                        })
                        console.log(intialAccountLength, "intialAccountLength")
                        setInitalAccountState(intialAccountLength.length)
                        ////SQ_NO_1.66-67 Set State the Nodes

                        if (Array(processedNodes)) {
                            // Set stating For tracking for the service Name in the Workbench
                            const serviceName = processedNodes?.map(node => node.data.serviceName).filter(name => name !== undefined);
                            console.log("serviceName", serviceName);
                            setStateForCount2(serviceName);
                        }
                        setNodes(processedNodes);
                        setEdges(parsedJson.edges);

                    } else {
                        console.log(formData, "FormData Here to Initate AI")
                        console.error("Failed to fetch architecture data");
                        if (jsonData.message == "Environment not found, inactive, or has no architecture data") {
                            setNodes([])

                        }
                    }
                } catch (error) {
                    console.error("Error in getArchitectureById:", error);
                }
            } else {
                console.error("envId is empty");
            }
        } catch (err) {
            console.log(err, "Error in the Get Architecture");
        }
    }

    //SQ_NO_1.64 Helper function to create the node element
    function createNodeElement(node) {
        // Verify it Belongs to the Service Name 
        let isLocation = ["Account",
            "Region",
            "VPC",
            "Availability_Zone",
            "Availability Zone",
            "Subnet",
            "Security_group",
            "Resource_Group",
            "VNET"].includes(node.data.serviceName);

        if (node.type == "location") {
            isLocation = true
        }
        let nodeStyle = {
            position: "absolute",
            zIndex: node.data.serviceName === "VPC" ? "-100000" : "100010",
        };

        if (isLocation) {
            const zIndexMap = {
                "Account": "-100005",
                "Region": "-100004",
                "VPC": "-100003",
                "Availability_Zone": "-100002",
                "Availability Zone": "-100002",
                "Subnet": "-100001",
                "Security_group": "-100000"
            };

            const dimensionsMap = {
                "Account": 400,
                "Region": 350,
                "VPC": 300,
                "Availability_Zone": 250,
                "Availability Zone": 250,
                "Subnet": 200,
                "Security_group": 150
            };
            let styleData = node.style
            nodeStyle = {
                ...nodeStyle,
                ...styleData
            };
        }

        const imageClassName = isLocation ? "vpcImageStyle" : "img";

        return {
            element: (
                <div className="container-hover"
                    onMouseEnter={(e) => {
                        // Show tooltip
                        const tooltip = document.createElement('div');
                        tooltip.innerHTML = `<strong>${node.data.serviceName}</strong><br>${node.data.description}`;
                        tooltip.style.position = 'fixed';
                        tooltip.style.top = `${e.clientY + 10}px`; // Position below cursor
                        tooltip.style.left = `${e.clientX + 10}px`; // Position right of cursor
                        tooltip.style.backgroundColor = 'black';
                        tooltip.style.color = 'grey';
                        tooltip.style.padding = '8px';
                        tooltip.style.borderRadius = '4px';
                        tooltip.style.zIndex = '100';
                        tooltip.style.whiteSpace = 'pre-wrap';
                        tooltip.style.maxWidth = '200px'; // Adjust as needed
                        tooltip.className = 'custom-tooltip'; // Add class for additional styling
                        document.body.appendChild(tooltip);
                    }}
                    onMouseLeave={() => {
                        // Hide tooltip
                        const tooltips = document.getElementsByClassName('custom-tooltip');
                        while (tooltips.length > 0) {
                            tooltips[0].parentNode.removeChild(tooltips[0]);
                        }
                    }}
                >
                    <img
                        id={node.id}
                        width={25}
                        height={25}
                        src={node.data.imageName}
                        className={imageClassName}
                        alt="service-logo"
                        onError={(e) => {
                            e.target.onerror = null; // Prevents looping
                            e.target.src = providerName == "Azure"
                                ? "./images/Workbench/AIArchitecture/Azure/AzureLogo.svg"
                                : "./images/Workbench/AIArchitecture/AWS/aws-logo.svg";
                        }}

                    />
                </div>
            ),
            style: nodeStyle,
            type: isLocation ? "location" : "service"
        };
    }

    /**
     * ^^^^^^^^^^^^ Node Funtionalities Handler Start  ^^^^^^^^^^^^^^^^^^^
     */

    /**
     * SerivceBind function  is to render the node in service node custom Type
     */

    const serviceBind = ({ data, id }) => {
        return (
            <div>
                {/* Source handles */}
                <span id={id + "1Target"}>
                    <Handle id={`${id}leftTarget`} type="target" position="left" />
                    <Handle id={`${id}topTarget`} type="target" position="top" />
                </span>
                <span id={id + "1Source"}>
                    <Handle id={`${id}leftSource`} type="source" position="left" />
                    <Handle id={`${id}topSource`} type="source" position="top" />
                </span>


                {/* Rest of your component */}
                <div style={{ position: "relative", margin: "-1px 0px 0px 0px" }}>
                    {data.element}
                </div>
                <label
                    style={{ position: "absolute", color: colorMode === "dark" ? "#FFFFFF" : "black" }}
                    className="nodeLabel black-v2"
                    id={id + "@nodeLabel"}
                >
                    {data.unique_Name ? data.unique_Name : data.imageServiceName}
                </label>
                <span id={id + "2Target"}>
                    <Handle id={`${id}rightTarget`} type="target" position="right" />
                    <Handle id={`${id}bottomTarget`} type="target" position="bottom" />
                </span>
                <span id={id + "2Source"}>
                    <Handle id={`${id}rightSource`} type="source" position="right" />
                    <Handle id={`${id}bottomSource`} type="source" position="bottom" />
                </span>

            </div>
        );
    };

    /**
    * LocationBind function  is to render the node in service node custom Type
     */
    //SQ_NO_3.98-107
    const locationBind = ({ data, id }) => {
        console.log(data, id, "Here2")
        return (
            <>
                <NodeResizer style={{ "border": 'unset' }} />
                <div className={`locationMain ${id}`}>
                    <div
                        className="container-hover"
                        id={id + "@location"}
                        style={{
                            display: "flex",
                            padding: "11px 10px",
                        }}
                    >
                        {data.element}
                        <label
                            className="locationLabel black-v2"
                            id={id + "@nodeLabel"}
                            style={{ color: colorMode === "dark" ? "#FFFFFF" : "black" }}
                        >

                            {data.unique_Name ? data.unique_Name : data.imageServiceName}
                        </label>
                    </div>
                </div>
            </>
        );
    };



    /**
     * PC_WB_10
     */
    //SQ_NO_3.98
    const nodeTypes = useMemo(
        () => ({
            service: serviceBind,
            location: (props) => locationBind(props, nodes),
        }),
        []
    );

    /**
     * Edges Connection is Handled Below 
     * Handled the Step Connection of the edges 
     */
    // const onConnect = (params) => {
    //     // if (viewMode != true) {
    //         console.log(params, "Params in Edges");
    //         let edgeStyle = {
    //             id: params.source + params.sourceHandle + params.target + params.targetHandle + Math.random(),
    //             sourceHandle: params.sourceHandle,
    //             targetHandle: params.targetHandle,
    //             source: params.source,
    //             target: params.target,
    //             configure: {},
    //             type: 'step',
    //             labelBgBorderRadius: 4,
    //             labelBgStyle: { color: '#fff', fillOpacity: 0.7 },
    //             markerEnd: {
    //                 type: MarkerType.ArrowClosed,
    //             },
    //         }
    //         setEdges([...edges, edgeStyle])

    //         // setLatestChange((data) => data.concat("0"))
    //         //console.log(latestChange);
    //     // }
    //     saveArchitecture()
    // }; 


    // const onConnect = useCallback((params) => {
    //     if (viewMode != true) {
    //     console.log(params, "Params in Edges");

    //     // Ensure the source and target are in the correct order
    //     const { source, target, sourceHandle, targetHandle } = params;

    //     let edgeStyle = {
    //         id: `${source}${sourceHandle}${target}${targetHandle}${Math.random()}`,
    //         source,
    //         target,
    //         targetHandle,
    //         sourceHandle,
    //         configure: {},
    //         type: 'step',
    //         labelBgBorderRadius: 4,
    //         labelBgStyle: { color: '#fff', fillOpacity: 0.7 },
    //         markerEnd: {
    //             type: MarkerType.ArrowClosed,
    //         },
    //     };

    //     setEdges((prevEdges) => [...prevEdges, edgeStyle]);
    // }


    // }, [setEdges]);



    const onConnect = useCallback((params) => {
        if (viewMode !== true) {
            console.log(params, "Params in Edges");

            let { source, target, sourceHandle, targetHandle } = params;
            console.log(source, target, "Source & Target")
            console.log(sourceHandle, targetHandle, "hande;s")

            // No need to swap, as the handle types are now clearly defined
            const edgeStyle = {
                id: `${source}${sourceHandle}${target}${targetHandle}${Math.random()}`,
                source,
                target,
                sourceHandle,
                targetHandle,
                configure: {},
                type: 'step',
                labelBgBorderRadius: 4,
                labelBgStyle: { color: '#fff', fillOpacity: 0.7 },
                markerEnd: {
                    type: MarkerType.ArrowClosed,
                },
            };

            setEdges((prevEdges) => [...prevEdges, edgeStyle]);
        }
    }, [viewMode]);

    useEffect(() => {
        console.log(edges, "Edgesss")
    }, [edges])


    /**
     * PC_WB_09
     * onDragover function will be called when the user drags the nodes over the workbench area;
     */
    const onDragOver = useCallback((event) => {
        console.log("Drag Over Triggered ")
        event.preventDefault();
        event.dataTransfer.dropEffect = "move";


    }, []);
    /** Handling of Node Delete Scenario  */

    const onNodesDelete = useCallback((deletedNodes) => {
        handleCloseConfigure()
        console.log(deletedNodes, "Node");

        // Remove the Tool Tip 
        const tooltips = document.getElementsByClassName('custom-tooltip');
        while (tooltips.length > 0) {
            tooltips[0].parentNode.removeChild(tooltips[0]);
        }

        deletedNodes.forEach(node => {
            if (node.data.serviceName != "Account") {
                const nodeType = node.data.ConfigureDetail.subServieConfig.type;
                console.log(nodeType, "NodeType")
                if (nodeType === "create") {
                    console.log("Normal Node is Deleted");
                    setDeletedNode(prevDeletedNodes => Array.isArray(prevDeletedNodes) ? [...prevDeletedNodes, node] : [node]);
                    // pushToUndoStack({ type: 'deleteNode', node });
                } else if (nodeType === "update" || nodeType === "delete") {
                    setDeleteService(node);
                    setShowDeleteModal(true);
                }
            } else {
                setDeletedNode(prevDeletedNodes => Array.isArray(prevDeletedNodes) ? [...prevDeletedNodes, node] : [node]);
                // pushToUndoStack({ type: 'deleteNode', node });
            }
        });

        // Update nodes and edges
        setNodes((nds) => {
            const deletedNodeIds = deletedNodes.map(node => node.id);
            const newNodes = nds.filter(node => !deletedNodeIds.includes(node.id));
            pushToUndoStack(newNodes, edges);
            return newNodes;
        });

        setEdges((eds) => {
            const deletedNodeIds = deletedNodes.map(node => node.id);
            const newEdges = eds.filter(edge => !deletedNodeIds.includes(edge.source) && !deletedNodeIds.includes(edge.target));
            // pushToUndoStack(nodes, newEdges);
            return newEdges;
        });
    }, [nodes, edges]);
    /**
     * Function Which Converts the S3 Image to an base64 image 
     * 
     */
    // Function to convert image to base64
    function imageToBase64(img) {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        return canvas.toDataURL();
    }

    // Function to load image and convert to base64
    function loadImageAsBase64(url) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = "anonymous";  // This is important for CORS
            img.onload = () => resolve(imageToBase64(img));
            img.onerror = (err) => reject(err);
            img.src = url;
        });
    }
    /**
     * PC_WB_08
     * the onDrop function will be called when the user drops a node in the wokbench;
     */
    //SQ_NO_3.36
    const onDrop = useCallback(
        (event) => {
            //SQ_NO_3.40
            event.preventDefault();
            if (viewMode == false) {
                let serviceData;
                //SQ_NO_3.45-46 Log the data being transferred
                const jsonData = event.dataTransfer.getData('application/json');
                const childName = event.dataTransfer.getData('application/childName');
                console.log("Data from transfer:", { jsonData, childName });

                // Attempt to parse JSON data
                if (jsonData) {
                    try {
                        serviceData = JSON.parse(jsonData);
                        console.log("Parsed serviceData:", serviceData);
                    } catch (error) {
                        console.log("JSON parsing failed:", error);
                    }
                }

                ////SQ_NO_3.50 If no serviceData was parsed, check for Account node
                if (!serviceData && childName === 'Account') {
                    console.log("Account node detected");
                    serviceData = {
                        id: 'account-node-' + Math.random().toString(36),
                        childName: 'Account',
                        parentName: 'Account',
                        nodeType: 'location',
                        imageName: './images/Workbench/ServiceImages/AWS/Security/AWS-Organizations_Account_light-bg.png',
                        serviceName: 'Account',
                        containsService: true,
                    };
                    console.log("Created Account serviceData:", serviceData);
                } else if (!serviceData) {
                    console.log("No valid service data found in the drop event");
                    return; // Early exit if no valid data
                }

                //SQ_NO_3.52 Calculate drop position
                const position = screenToFlowPosition({
                    x: event.clientX,
                    y: event.clientY,
                });
                console.log("Calculated position:", position);
                //SQ_NO_3.55-63
                let nodeDataForCount = [...stateForCount];
                let nodeDataForCount2 = [...stateForCount2];

                let countForLabel = nodeDataForCount.filter(data => data === serviceData.parentName).length + 1;
                let countForLabel2 = nodeDataForCount2.filter(data => data === serviceData.childName).length + 1;

                let labelName = `${serviceData.parentName} ${countForLabel}`;
                let imageServiceName = `${serviceData.serviceName}_${countForLabel2}`

                setStateForCount(prev => [...prev, serviceData.parentName]);
                setStateForCount2(prev => [...prev, serviceData.childName]);

                console.log("countttttt2", stateForCount2);

                //SQ_NO_3.65-69 set specific stylr for account nodes
                const isAccount = serviceData.serviceName === "Account";

                // Determine node identity
                let nodeIdentity = serviceData.containsService ? "location" : "service";

                let htmlElement = "";
                let nodeStyle = {
                    position: "absolute",
                    zIndex: serviceData.serviceName === "vpc" ? "-100000" : "100010",
                };

                // Set node style based on service name location Binding Purposes
                if (["Account", "Region", "VPC",
                    "Availability_Zone",
                    "Availability Zone",
                    "Subnet", "Security_group","Security_Group",  "Security group" , "Resource_Group", "Virtual Network","Virtual_Network", "Resource Group","Resource_Group"].includes(serviceData.serviceName)) {
                    const zIndexMap = {
                        "Account": "-100005",
                        "Region": "-100004",
                        "VPC": "-100003",
                        "Availability_Zone": "-100002",
                        "Availability Zone": "-100002",
                        "Subnet": "-100001",
                        "Security_group": "-100000",
                        "Security group" : "-100000",
                        "Security_Group" : "-100000",
                        "Virtual Network": "-100002",
                        "Virtual_Network": "-100002",
                        "Resource_Group": "-100003",

                        "Resource Group": "-100003"
                    };

                    const dimensionsMap = {
                        "Account": 400,
                        "Region": 350,
                        "VPC": 300,
                        "Availability_Zone": 250,
                        "Availability Zone": 250,
                        "Subnet": 200,
                        "Security_group": 150,
                        "Virtual Network": 300,
                        "Resource Group": 350
                    };

                    nodeStyle = {
                        position: "absolute",
                        zIndex: zIndexMap[serviceData.serviceName] || "100010",
                        width: dimensionsMap[serviceData.serviceName],
                        height: dimensionsMap[serviceData.serviceName],
                        className: "resizeLoop",
                        color: getColorForService(serviceData.serviceName),
                        border: "1px solid",
                        borderStyle: "dashed",
                    };
                    nodeIdentity = "location"
                }

                // Set HTML element based on node identity
                switch (nodeIdentity) {

                    default:
                        htmlElement = (
                            <div>
                                <div className="container-hover"
                                    onMouseEnter={(e) => {
                                        // Show tooltip
                                        const tooltip = document.createElement('div');
                                        tooltip.innerHTML = `<strong>${serviceData.serviceName}</strong><br>${serviceData.serviceDR}`;
                                        tooltip.style.position = 'fixed';
                                        tooltip.style.top = `${e.clientY + 10}px`; // Position below cursor
                                        tooltip.style.left = `${e.clientX + 10}px`; // Position right of cursor
                                        tooltip.style.backgroundColor = 'black';
                                        tooltip.style.color = 'grey';
                                        tooltip.style.padding = '8px';
                                        tooltip.style.borderRadius = '4px';
                                        tooltip.style.zIndex = '100';
                                        tooltip.style.whiteSpace = 'pre-wrap';
                                        tooltip.style.maxWidth = '200px'; // Adjust as needed
                                        tooltip.className = 'custom-tooltip'; // Add class for additional styling
                                        document.body.appendChild(tooltip);
                                    }}
                                    onMouseLeave={() => {
                                        // Hide tooltip
                                        const tooltips = document.getElementsByClassName('custom-tooltip');
                                        while (tooltips.length > 0) {
                                            tooltips[0].parentNode.removeChild(tooltips[0]);
                                        }
                                    }}

                                >

                                    <img
                                        id={serviceData.id}
                                        width={25}
                                        height={25}
                                        src={`${serviceData.imageName}`}
                                        className={["VPC", "Account", "Availability_Zone", "Region", "Subnet", "Security_group"].includes(serviceData.serviceName) ? "vpcImageStyle" : "img"}
                                        alt="service-logo"
                                    />
                                </div>
                            </div>
                        );
                        break;
                }
                // Construct the new node
                // if(serviceData.imageName.test(s3UrlRegex))
                // {
                // serviceData.imageName = loadImageAsBase64(serviceData.imageName)
                // }
                //SQ_NO_3.70 construct newnode data
                const newNode = {
                    id: serviceData.id,
                    unique_Name: serviceData?.unique_Name,
                    type: nodeIdentity,
                    position: position,
                    style: nodeStyle,
                    data: {
                        element: htmlElement,
                        resourceName: isAccount ? isAccount : labelName,
                        serviceName: serviceData.serviceName,
                        imageName: serviceData.imageName,
                        imageServiceName: imageServiceName,
                        instanceName: imageServiceName,
                        newRosource: true,
                        fileuploadFileContent: "",


                        moduleName: serviceData.module_name ? serviceData.module_name : "",
                        source: serviceData.source ? serviceData.source : "",
                        version: serviceData.version ? serviceData.version : "",
                        terraform_module_urls: serviceData.terraform_module_urls ? serviceData.terraform_module_urls : "",
                        custom: serviceData.custom,
                        //   is_parent:serviceData.is_parent,


                        terraform_input: serviceData.terraform_input,
                        terraform_output: serviceData.terraform_output,
                        updated_input: serviceData.updated_input,
                        updated_output: serviceData.updated_output,

                        fields: serviceData.fields || [],
                        serviceFamily: serviceData.serviceFamily,
                        ConfigureDetail: {
                            subServieConfig: {
                                Action: {
                                    create: {},
                                    delete: {},
                                    update: {}
                                },
                                type: "create"
                            }
                        },
                        description: serviceData.description || '',
                        children: isAccount ? [] : undefined,
                        repositoryData: {
                            repository_name: "",
                            repository_id: "",
                            branchData: {
                                branch_id: "",
                                branch_name: ""
                            }
                        },
                        // Added the Environment Tags
                        Tags: [
                            {
                                "key": "cloudgen",
                                "value": true,
                                defaultTag: true
                            },
                            {
                                "key": "env",
                                "value": envName != "" ? envName : "dev",
                                defaultTag: true
                            },
                            ...(UTI["Key"] && UTI["Value"] !== "" ? [
                                {
                                    "key": UTI["Key"],
                                    "value": UTI["Value"],
                                    defaultTag: true
                                }
                            ] : [])
                        ]
                    },
                    instanceName: imageServiceName
                };
                if (serviceData.serviceName == "Account") {
                    // Account Detials Data Maintance 
                    newNode["data"]["ConfigureDetail"] = {
                        accountId: "",
                        accountName: "",
                        cloudproviderId: "",
                        permanentAccountId: ""
                    }
                }
                if (serviceData.serviceName == "Resource_Group" && providerName == "Azure") {
                    newNode["data"]["Resource_Group"] = {
                        "Name": ""
                    }
                }
                if (!isAccount) {

                    let serviceType = newNode?.data?.ConfigureDetail?.subServieConfig?.type;
                    if (serviceType === "create" || serviceType === "update") {
                        // Update only if the keys contain values in them
                        let filteredFormData = Object.keys(serviceData.fields).reduce((acc, key) => {
                            if (serviceData.fields[key]) { // Check if the key has a value 
                                acc[key] = serviceData.fields[key];
                            }
                            return acc;
                        }, {});

                        // Mapping out and creating a new object with 'input' as key and 'default' as value
                        const reducedFormData = Object.keys(filteredFormData).reduce((acc, key) => {
                            const { input, default: defaultValue } = filteredFormData[key];

                            // Check if defaultValue is explicitly true or false
                            const isBoolean = (val) => typeof val === 'boolean';
                            // Function to check if a value is an empty object, array, or string
                            const isEmptyObjectOrArrayOrString = (val) => {
                                return (
                                    (typeof val === 'string' && (val.trim() === '{}' || val.trim() === '[]' || val.trim() === '""' || val.trim() === '' || val.trim() === 'null'))
                                );
                            };
                            // Function to check if a value is a number or a string that represents a number
                            const isNumberOrQuotedNumber = (val) => {
                                const trimmedVal = val.trim();
                                return !isNaN(trimmedVal) || (trimmedVal.startsWith('"') && trimmedVal.endsWith('"') && !isNaN(trimmedVal.slice(1, -1)));
                            };

                            if (
                                input !== undefined &&
                                defaultValue !== undefined
                                // && (isBoolean(defaultValue) || (!isEmptyObjectOrArrayOrString(defaultValue) && !isNumberOrQuotedNumber(defaultValue)))
                            ) {

                                acc[input] = defaultValue
                                // acc[input] = defaultValue =="true"?true:false

                                // console.log(defaultValue,"sankarrr", defaultValue =="true"?true:false);

                            }
                            return acc;
                        }, {});

                        newNode.data.ConfigureDetail.subServieConfig["Action"][serviceType] = { ...reducedFormData };
                    }


                    newNode.data.fields = newNode?.data?.fields?.map(field => {
                        // Check if the field type is 'list(string)'
                        if (field?.type === 'list(string)') {
                            return {
                                ...field,
                                UserValue: { values: [] } // Set UserValue to an object with an empty array
                            };
                        } else {
                            return {
                                ...field,
                                UserValue: field?.default // Set UserValue to the default value of the field
                            };
                        }
                    });

                    newNode.data.fields = newNode?.data?.fields?.map(field => {
                        // Check if the field type is 'list(string)'
                        if (field) {
                            return {
                                ...field,
                                node_id: ""// Set UserValue to an object with an empty array
                            };
                        }
                    });


                    newNode.data.fields = newNode?.data?.fields?.map(field => {
                        // Check if the field type is 'list(string)'
                        if (field?.type === 'bool') {
                            const val = field.value
                            console.log("no2", val);

                            return {
                                ...field,
                                UserValue: val == "true" ? true : false
                            };
                        }

                        // Return the field unchanged if the condition is not met
                        return field;
                    });


                    // Function to check if a value is an empty object, array, or string

                }
                console.log("newNode", newNode);


                setPreventSaving(false)
                // Account Tracking

                // //SQ_NO_3.71 Update nodes state
                setNodes((nds) => {
                    const newNodes = nds.concat(newNode);
                    //SQ_NO_3.73
                    pushToUndoStack(newNodes, edges);
                    return newNodes;
                });

            }
        },
        [reactFlowInstance, stateForCount, accountStatus, edges]
    );


    const [uniqueNames, setUniqueNames] = useState([]);

    useEffect(() => {
        console.log(nodes, "Nodesss");
        const serviceName = nodes?.map(node => node.data.serviceName).filter(name => name !== undefined);
        console.log("serviceName", serviceName);

        setStateForCount2(serviceName);
        // Transform and filter the nodes
        const transformedData = nodes?.map((node) => {
            const terraformOutputs = node?.data?.terraform_output || [];

            // Reduce the outputs array to a key-value pair object
            const transformedOutputs = terraformOutputs?.reduce((acc, output) => {
                acc[output.outputs] = output.value;
                return acc;
            }, {});

            // Check the description and conditionally exclude nodes
            if (node?.data?.description === "Account" || node?.data?.description === "Region") {
                return null;
            }

            // Transform each node
            return {
                id: node.id,
                name: node.data.unique_Name || node.data.imageServiceName, // Generating a unique name for each node
                value: transformedOutputs
            };
        }).filter(node => node !== null); // Filter out null values

        setNodeDetails(transformedData);
        console.log("transformedData", transformedData);


            // Extract and set the unique names
    const uniqueNamesList = nodes?.map(node => node.data.unique_Name).filter(name => name !== undefined);
    setUniqueNames(uniqueNamesList);

    }, [nodes]);
    const [accountCount, setAccountCount] = useState(intialAccountState)
    //SQ_NO_2.0 useEffect to call fetchAccounts
    useEffect(() => {
        console.log(intialAccountState, "INitalAccount");
        console.log(accountCount, "INitalAccount");

        if (accountCount != intialAccountState) {
            fetchAccounts()
        } 
    }, [intialAccountState,accountCount])

    useEffect(()=>{
        console.log("uniqueNames2", uniqueNames);

    },[uniqueNames])
    const fetchAccounts = async () => {
        try {
            const payload = {
                organizationId: orgId, // Replace with dynamic value if needed
                envId: envId, // Replace with dynamic value if needed
                userId: userId, // Replace with dynamic value if needed
                "accountId": ""
            }
            ////SQ_NO_2.1
            console.log(payload, "Payloddas")
            const response = await getAllServiceReviewApi(payload);
            console.log(response, "sdfasse getAllServiceReviewApi")
            if (response.body.success) {
                let accountss = response.body.accounts
                console.log(accountss, "sdf")
                //SQ_NO_2.20  Update the Node based on the permanent Id retreived 
                if (accountss.length != 0) {
                    // This function Will update the Node if the Permanent Key is not been Present inside the Node.
                    updateAccountDetails(accountss)
                }
                let ids = accountss.map((detail => detail.accountId));
                console.log(ids, "IDS")
                // console.log(accountIds , "Account Ids")
                // setAccountIds(ids);

                const accountObjects = accountss.map(detail => ({
                    accountId: detail.accountId,
                    accountName: detail.accountName
                }));
                console.log(accountObjects, "Account Objects");

                setAccountIds(accountObjects); // Set state with array of account objects
                //SQ_NO_2.21
                if (ids.length > 0) {
                    getDataConfiguration(ids)

                }

            }
        } catch (error) {
            console.log("Error fetching accounts", error);
        }

    };
    // getDataConfiguration Function is used to Retrieve the Data Configuration for the Accounts for 
    ////SQ_NO_2.26 Data Addition Purposes
    const getDataConfiguration = async (ids) => {
        try {
            // Conditional Check 
            if (ids.length !== 0) {
                const payload = {
                    userId: userId,
                    //accountId: accountIds,
                    accountId: ids,
                    providerId: providerId,
                    environmentId: envId
                };
                console.log(payload, "Payload  here ")
                ////SQ_NO_2.26
                const response = await fetchDataConfigs(payload);
                console.log(response, "dataConfigResponse")
                //SQ_NO_2.44
                if (response.status === 200) {
                    setinitialDataConfig(response.data.data.configResource)
                }
            }
        } catch (err) {
            console.log(err, "error in the Data Configurations")
        }
    };
    // Helper function to get color for service
    function getColorForService(serviceName) {
        const colorMap = {
            "VPC": "#8C4FFF",
            "Account": "#CD2264",
            "Availability_Zone": "#6610f2",
            "Region": "#00A4A6",
            "Subnet": "#0d6efd",
            "Security_group": "#DD3522"
        };
        return colorMap[serviceName] || "#000000";
    }
    const [accountIds, setAccountIds] = useState([]);

    // Account Permanent ID update
    function updateAccountDetails(details) {
        console.log(details, "Details Coming");

        if (details.length !== 0) {
            // Create a new array from nodes with updated values
            const updatedNodes = nodes.map(node => {
                // Find the matching account detail by nodeId
                const matchingDetail = details.find(account => account.nodeId === node.id);

                if (matchingDetail) {
                    // Check if the node already has a permanentAccountId
                    if (node.data.ConfigureDetail.permanentAccountId === "") {
                        return {
                            ...node,
                            data: {
                                ...node.data,
                                ConfigureDetail: {
                                    ...node.data.ConfigureDetail,
                                    permanentAccountId: matchingDetail.accountId
                                }
                            }
                        };
                    }
                }
                return node;
            });

            // Check if there are any changes
            const hasChanges = !updatedNodes.every((node, index) =>
                JSON.stringify(node) === JSON.stringify(nodes[index])
            );

            // Only update state if there are changes
            if (hasChanges) {
                setNodes(updatedNodes);
            }
        }
    }


    const [isConfigureVisible, setIsConfigureVisible] = useState(true);
    const [isAccountConfigureVisible, setIsAccountConfigureVisible] = useState(true);
    const [overlappedNodes, setoverlappedNodes] = useState([]);


    const handleCloseConfigure = () => {
        setIsConfigureVisible(false);
    };
    const handleAccountCloseConfigure = () => {
        setIsAccountConfigureVisible(false);
    };


    /**
     * PC_WB_12
     * the following function will be called when the user double click on the
     * Node
     */
    //PS_65 
    const doubleClickLabel = (event, node) => {
        setIsConfigureVisible(true)
        setIsAccountConfigureVisible(true);

        console.log("Node double-clicked:", node);
        console.log("Node type:", node.type);
        console.log("Node resourceName:", node.data.resourceName);
        setConfigureDetails(node);
        console.log("Edges in Double Clik", edges);

        let overlappedNodes = nodes.filter((n) => {
            if (n.id === node.id) return false; // Skip the node itself

            let horizontalOverlap =
                n.position.x < node.position.x + node.measured.width &&
                n.position.x + n.measured.width > node.position.x;
            let verticalOverlap =
                n.position.y < node.position.y + node.measured.height &&
                n.position.y + n.measured.height > node.position.y;

            return horizontalOverlap && verticalOverlap;
        });

        console.log("overlappedNodes", overlappedNodes);

        const accountNodeIDs = overlappedNodes
            .filter((n) => n.data && n.data.serviceName === "Account")
            .map(n => n.data.ConfigureDetail.permanentAccountId);
        if (accountNodeIDs.length == 1) {
            console.log(accountNodeIDs,
                "Here are the IDs of the overlapped 'Account' nodes",
                accountNodeIDs[0]
            );
            setoverlappedNodes(accountNodeIDs[0])
        }

        // Find connected source nodes
        const connectedSourceNodes = edges.reduce((acc, edge) => {
            if (edge.target === node.id) {
                const sourceNode = nodes.find(n => n.id === edge.source);
                if (sourceNode) {
                    acc.push(sourceNode);
                }
            }
            return acc;
        }, []);

        console.log("Connected source nodes:", connectedSourceNodes);
        setConnectedSourceNodes(connectedSourceNodes)

        // You can do something with the connectedSourceNodes here,
        // such as setting them in state or passing them to another function
    }


    /**
     * ^^^^^^^^^^^^ Node Funtionalities Handler End  ^^^^^^^^^^^^^^^^^^^
     */
    /**
     * ^^^^^^^^^^^^ Draggable Funtionalities Handler Started  ^^^^^^^^^^^^^^^^^^^
     */
    const pushToUndoStack = (newNodes, newEdges) => {
        
        setUndoStack((prev) => [...prev, { nodes: newNodes, edges: newEdges }]);
        setRedoStack([]); // Clear redo stack on new action
    };




    // PS_03 -  PS_09 
    //PS_73
    const unDoFunction = () => {

        if (undoStack.length === 0) return;

        const lastState = undoStack[undoStack.length - 1];
        // if(lastState?.nodes?.type=='deleteNode')return;
        // PS_04  Redo Stack Set state
        setRedoStack((prev) => [...prev, { nodes, edges }]);
        setNodes(lastState.nodes);
        setEdges(lastState.edges);
        setUndoStack((prev) => prev.slice(0, -1));

    };
    // PS_10-  PS_17   Redo Function in the Draggable
    //PS_80
    const reDoFunction = () => {
        if (redoStack.length === 0) return;

        const lastState = redoStack[redoStack.length - 1];
        setUndoStack((prev) => [...prev, { nodes, edges }]);
        setNodes(lastState.nodes);
        setEdges(lastState.edges);
        setRedoStack((prev) => prev.slice(0, -1));
    };

    useEffect(()=>{
        console.log(undoStack,"undo");
        console.log(redoStack,"undo2");

        
    },[undoStack,redoStack])
    /**
     * PS_62 - PS_66  XML Donwload Functionality
     */
    function XMLDownload() {
        //  PS_64  - Node Data formation 
        let nodeData = {
            nodes: nodes,
            edges: edges,
        };

        let stringifyNode = JSON.stringify(nodeData);
        stringifyNode = stringifyNode.replace(/&/g, "#@#@#");
        let architecutureName = "Test"
        let provider = "AWS"
        let technology = "Terraform"
        download(
            `${architecutureName}.xml`,
            `<?xml version="1.0" encoding="UTF-8" ?><cloudgen><${provider + "-" + technology
            }>` +
            stringifyNode +
            `</${provider + "-" + technology}></cloudgen>`
        );
    }

    /** 
     * XML Download Function 
    */
    function download(filename, text) {
        //console.log("xmlText", text);

        var element = document.createElement("a");
        element.setAttribute(
            "href",
            "data:text/plain;charset=utf-8," + encodeURIComponent(text)
        );
        element.setAttribute("download", filename);

        element.style.display = "none";
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }
    // PS_72 
    // DownLoadImage Function Download the Architecture Image in the specifed format 
    //PS_94
    async function DownLoadImage(type) {
        try {
            document.getElementById("fittoscreen").click();

            const architectureElement = document.getElementById("myArchitecture");
            architectureElement.style.backgroundColor = "white";

            let dataUrl;
            if (type === "Jpeg") {
                dataUrl = await htmlToImage.toJpeg(architectureElement);
            } else if (type === "Png") {
                // Convert the Entire architecture ot dataURl
                dataUrl = await htmlToImage.toPng(architectureElement);

            } else {
                throw new Error("Unsupported image type");
            }

            const link = document.createElement("a");
            link.download = `${envName ? envName : providerName ? providerName : "image"}.${type.toLowerCase()}`;
            link.href = dataUrl;
            link.click();

            setTimeout(() => {
                architectureElement.style.backgroundColor = "transparent";
            }, 3000);
        } catch (error) {
            console.log(`Error in downloadImage: ${error}`);
        }
    }
    // const S3_BUCKET_NAME = process.env.REACT_APP_S3_BUCKET_NAME;
    // const S3_REGION = process.env.REACT_APP_S3_REGION;
    // Fetch ImageFromS3
    // useEffect(() => {
    //     fetchImageFromS3();
    // }, []);
    // const fetchImageFromS3 = async () => {
    //     const s3Client = new S3Client({ region: S3_REGION });
    //     const command = new GetObjectCommand({
    //         Bucket: S3_BUCKET_NAME,
    //         Key: 'organization/Avasoft/Avasoft.png'
    //     });

    //     try {
    //         const response = await s3Client.send(command);
    //         const blob = await response.Body.blob();
    //         const url = URL.createObjectURL(blob);
    //         setImageUrl(url);
    //     } catch (error) {
    //         console.error('Error fetching image from S3:', error);
    //     }
    // };
    /** 
    * PS_78 -  PS_82 
    *  viewCommitHistory is Triggered When the user Performs the Commit Action in the Workbench 
    */
    function viewCommitHistory(nodes) {
        let parsedJson = JSON.parse(nodes)
        // Process nodes
        const processedNodes = parsedJson.nodes.map(node => {
            const nodeElement = createNodeElement(node);
            return {
                ...node,
                type: nodeElement.type,
                style: nodeElement.style,
                data: {
                    ...node.data,
                    element: nodeElement.element,
                },
            };
        });
        console.log(processedNodes, "Processed Nodes")
        pushToUndoStack(processedNodes, parsedJson.edges);
        setPreventSaving(true)
        setNodes(processedNodes);
        setEdges(parsedJson.edges);


    }
    /** 
     *  PS_83  - PS_87  
     *  Restore the Commit 
    */
    function restoreCommitHistory(nodes) {
        let parsedJson = JSON.parse(nodes)
        // Process nodes
        const processedNodes = parsedJson.nodes.map(node => {
            const nodeElement = createNodeElement(node);
            return {
                ...node,
                type: nodeElement.type,
                style: nodeElement.style,
                data: {
                    ...node.data,
                    element: nodeElement.element,
                },
            };
        });
        console.log(processedNodes, "Processed Nodes")
        pushToUndoStack(processedNodes, parsedJson.edges);
        setNodes(processedNodes);
        setEdges(parsedJson.edges);
    }

    // Draggable Functionality ends here


    // Workbench Functionalites 
    /**
     * Label Submit updates the Node During the 
     * user Provided Configuration
     * Which Save the Architecture on the realtime Basis
    */
    //SQ_NO_3.93
    function labelSubmit(configureValues) {
        // Account Handling Done Here 
        console.log(configureValues, "configureValues")
        if (configureValues.data.serviceName == "Account") {
            if (configureValues.data.ConfigureDetail.accountName != "") {
                setAccountCount(accountCount + 1)
            }
        }
        ////SQ_NO_3.95 update node data and unique name
        const updatedNodes = nodes.map(node => {
            if (node.id === configureValues.id) {
                // Create a new object with updated data and unique_Name
                return {
                    ...node,
                    instanceName: configureValues.unique_Name,
                    unique_Name: configureValues.unique_Name,
                    data: {
                        ...configureValues.data,
                        unique_Name: configureValues.unique_Name,

                    }
                };
            }
            return node; // Return unchanged node
        });
        console.log(updatedNodes, "updated Nodes!")
        //SQ_NO_3.96 update the nodes
        setNodes(updatedNodes); // 
    }

    /**
     * Assign Tags Update which Iterates Through Node and Update the Node
     */

    function assignTagsUpdate(tagsValues) {
        let updatedNodes = nodes.map(node => {
            // Get existing tags or initialize an empty array
            const existingTags = node.data.Tags || [];

            // Create a new tags array
            const updatedTags = [...existingTags];

            // Update or add new tags
            tagsValues.forEach(newTag => {
                const existingTagIndex = updatedTags.findIndex(tag => tag.key === newTag.key);
                if (existingTagIndex !== -1) {
                    // Update existing tag
                    updatedTags[existingTagIndex] = {
                        ...updatedTags[existingTagIndex],
                        ...newTag,
                        defaultTag: newTag.defaultTag || false
                    };
                } else {
                    // Add new tag
                    updatedTags.push({
                        ...newTag,
                        defaultTag: newTag.defaultTag || false
                    });
                }
            });

            // Return updated node
            return {
                ...node,
                data: {
                    ...node.data,
                    Tags: updatedTags
                }
            };
        });

        setNodes(updatedNodes);
        setShowAssignTags(false);


    }


    // Drift 

    // Node Delete Scenario 
    const nodeDeleteState = () => {
        let tobedeltedNode = { ...deleteNodeService }
        console.log(tobedeltedNode, "SelectedDeleteNode")
        let node = [...nodes]
        console.log(node, "here")
        // Handling Last Case Scenario in Node !!s
        tobedeltedNode.data.ConfigureDetail.subServieConfig.Action.delete = tobedeltedNode.data.ConfigureDetail.subServieConfig.Action.update
        tobedeltedNode.data.ConfigureDetail.subServieConfig.type = "delete"
        // tobedeltedNode.data.ConfigureDetail.subServieConfig.Action.delete.DeployStatus = true
        // After Yes Clicked 
        node.push(tobedeltedNode)
        console.log(node, "nodeCheck for Deltion")
        setNodes(node)
        saveArchitecture()

    }
    async function fetchDrift() {
        try {


          
            // PS_04  - Payload for the Drift 
            let payload = {
                "organizationId": orgId,
                "userId": userId,
                "envId": envId,
                "language": formData.language ? formData.language : "Terraform"
            }
            // setIsReviewDriftModelOpen(true)
            console.log(payload, "payload in Driftss")
            let res = await fetchdriftApi(payload)
            console.log(res, "Responses from Drifts")
           
            if (res.success) {
                if (res.data.length != 0) {
                    setReviewDrifts(res.data)
                    setIsReviewDriftModelOpen(true)
                }
                else {
                    setReviewDrifts([])
                    setIsReviewDriftModelOpen(false)
                }
            }

        } catch (error) {
            console.log(error, "Error in Reveiw Drifts API")
        }
    }

    /** 
     *  PS_29- PS_32 
     * Review Drifts Node updation Scenario
     */
    useEffect(() => {
        if (reviewDriftsData.length != 0 &&
            isReviewDriftModelOpen == false
        ) {
            reviewNodeUpdation()
        }
    }, [reviewDriftsData])


    /**
     *  PS_32 - 41
     * This Function is used to update the Node when user selects the updation Option in the 
     * Review Drift Page 
     */

    function reviewNodeUpdation() {
        try {
            let reviewData = [...reviewDriftsData];
            let nodeData = [...nodes];
            console.log(reviewData, "Review Data");

            // Helper function to convert value to string representation
            const valueToString = (value) => {
                if (typeof value === 'object' && value !== null) {
                    return JSON.stringify(value);
                }
                return String(value);
            };

            // Iterate the Node and set the Values to the Node   
            reviewData.forEach(review => {
                review.functions.forEach(func => {
                    let matchingNode = nodeData.find(node => node.id === func.id);

                    if (matchingNode) {
                        // Ensure the necessary structure exists
                        if (!matchingNode.data.ConfigureDetail) {
                            matchingNode.data.ConfigureDetail = {};
                        }
                        if (!matchingNode.data.ConfigureDetail.subServieConfig) {
                            matchingNode.data.ConfigureDetail.subServieConfig = {};
                        }
                        if (!matchingNode.data.ConfigureDetail.subServieConfig.Action) {
                            matchingNode.data.ConfigureDetail.subServieConfig.Action = {};
                        }

                        let type = matchingNode.data.ConfigureDetail.subServieConfig.Action.type || 'create';

                        if (!matchingNode.data.ConfigureDetail.subServieConfig.Action[type]) {
                            matchingNode.data.ConfigureDetail.subServieConfig.Action[type] = {};
                        }

                        func.configurations.forEach(config => {
                            // Update subServieConfig
                            matchingNode.data.ConfigureDetail.subServieConfig.Action[type][config.name] = config.actual;

                            // Update fields
                            let matchingField = matchingNode.data.fields.find(field => field.fieldName === config.name);
                            if (matchingField) {
                                // Convert the value to a string representation
                                matchingField.UserValue = valueToString(config.actual);
                            }
                        });
                    }
                });
            });

            console.log(nodeData, "NodeData");
            // Update the state with the modified nodeData
            setNodes(nodeData);
        } catch (error) {
            console.log(error, "Error in Review Drifts");
        }
    }
    // Asssign Tags Opertions 
    // Toggle the Assign Tags modal
    const toggleAssignTags = () => {
        setShowAssignTags(!showAssignTags);
    };
    // fetchAccountDetails Function is Triggered During the node Click of the Account 
    const fetchAccountDetails = async () => {
        try {
            let payload = {
                "userId": userId,
                "orgId": orgId,
                "providerId": providerId,
                "search": ""
            }
            let res = await getAccountDetailsApi(payload)
            console.log(res, "getAccountDetailsApi")
            if (res.success) {
                setAccounts(res.data)
                if (res.data.length > 0) {

                }
            }
        }
        catch (err) {
            console.log(err, "Eroor")
        }
    };

    const [showRightSidebar, setShowRightSidebar] = useState(false);

    const handleToggleSidebar = () => {
        setShowRightSidebar(prevState => !prevState); // Toggle sidebar visibility
    };

    const processSelectedService = (selectedServiceName) => {
        console.log(selectedServiceName, "enteredProcess");

        const isLocationList = [
            "Account",
            "Region",
            "VPC",
            "Availability_Zone",
            "Availability Zone",
            "Subnet",
            "Security_group",
            "Resource_Group",
            "VNET"
        ];

        // Check if selected service is location
        const isSelectedServiceLocation = isLocationList.includes(selectedServiceName);

        const lastNode = nodes[nodes.length - 1];
        console.log("lastNodeData", lastNode);

        if (!lastNode || !lastNode.position) {
            console.error("Last node or its position is undefined");
            return;
        }

        // Determine new node type and initial positions
        let newX, newY;
        const defaultPosition = { x: 100, y: 100 }; // Default position if needed

        const isLastNodeLocation = isLocationList.includes(lastNode.data.serviceName);

        // Logic for positioning
        if (isSelectedServiceLocation) {
            if (isLastNodeLocation) {
                newX = lastNode.position.x + 20;
                newY = lastNode.position.y + 20;
            } else {
                newX = lastNode.position.x + 50; // Default offset
                newY = lastNode.position.y + 50; // Default offset
            }
        } else {
            if (isLastNodeLocation) {
                newX = lastNode.position.x + 20;
                newY = lastNode.position.y + 20; // Centering
            } else {
                newX = lastNode.position.x + 50;  // left right padding for service type node
                newY = lastNode.position.y + 10;  // top down padding for service type node
            }
        }

        console.log(`isLocation: ${isSelectedServiceLocation}, new coordinates: (${newX}, ${newY})`);

        const newNodeType = isSelectedServiceLocation ? 'location' : 'service';

        const zIndexMap = {
            "Account": "-100005",
            "Region": "-100004",
            "VPC": "-100003",
            "Availability_Zone": "-100002",
            "Availability Zone": "-100002",
            "Subnet": "-100001",
            "Security_group": "-100000",
            "Virtual Network": "-100002",
            "Resource Group": "-100003"
        };

        const dimensionsMap = {
            "Account": 400,
            "Region": 350,
            "VPC": 300,
            "Availability_Zone": 250,
            "Availability Zone": 250,
            "Subnet": 200,
            "Security_group": 150,
            "Virtual Network": 300,
            "Resource Group": 350
        };

        const nodeStyle = {
            position: "absolute",
            zIndex: zIndexMap[selectedServiceName] || "100010",
            width: dimensionsMap[selectedServiceName],
            height: dimensionsMap[selectedServiceName],
            className: "resizeLoop",
            color: getColorForService(selectedServiceName),
            border: "1px solid",
            borderStyle: "dashed",
        };

        // Create a new node object that is a shallow copy of parts of lastNode's data with updates
        const newNode = {
            id: `${selectedServiceName}_${Math.random()}`,
            type: newNodeType,
            position: {
                x: newX,
                y: newY
            },
            style: nodeStyle,
            data: {
                ...lastNode.data,
                serviceName: selectedServiceName,
                instanceName: `${selectedServiceName}_instance_${Math.random()}`
            }
            // Include other necessary properties if needed
        };

        console.log("newNode", newNode);

        // Append the new node to the node state
        setNodes(prevNodes => [...prevNodes, newNode]);
    };

    return (
        <>



            {!isReviewDriftModelOpen && !dataConfigModal ? <>
                <div className="container-fluid p-0">

                    <div class="content-center-container">

                        <div class="row">


                            <>

                                {/** Repo Side Bar Component */}
                                <SideBarComponent
                                    assignTagsUpdate={assignTagsUpdate}
                                    setRepositories={setRepositories}
                                    setSelectedEnv={setSelectedEnv}
                                    showAssignTags={showAssignTags}
                                    setShowAssignTags={setShowAssignTags}
                                    setActiveTab={setActiveTab}
                                    setIACModelOpen={setIACModelOpen}
                                    setDataConfigModal={setDataConfigModal}
                                    isDarkMode={isDarkMode.isDarkMode}
                                    isLoading={isLoading}
                                     setIsLoading={setIsLoading}
                                    
                                />


                                <>
                                    <div
                                        className="reactflow-wrapper"
                                        style={{ position: "absolute", height: "90%" }}

                                        ref={reactFlowWrapper}
                                    >
                                        {
                                            popupMsg.show == true && popupMsg.Msg != "" ?
                                                <WorkbenchPopupComponent
                                                    popupMsg={popupMsg}
                                                    setPopUpMsg={setPopUpMsg}
                                                />
                                                : <></>
                                        }
                                        {toastMsg.show && (
                                            <ToastPopup
                                                toastMsg={toastMsg}
                                                setToastMsg={setToastMsg}
                                            />
                                        )}
                                        <Draggable handle="#moveable"
                                            defaultPosition={{ x: 700, y: 530 }}
                                        >
                                            <div
                                                className="tools d-flex rounded shadow-sm"
                                                style={{
                                                    zIndex: "10",
                                                    position: "relative",
                                                    bottom: '0'

                                                }}
                                            >
                                                <button className="btn drag-btn" id="moveable">
                                                    <img
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="bottom"
                                                        title="Drag"
                                                        src="images/drag-img.svg"
                                                        className
                                                        draggable={false}
                                                    />
                                                </button>

                                                <button
                                                    className="btn tool-btn"
                                                    onClick={() => {
                                                        unDoFunction();
                                                    }}
                                                    disabled={undoStack.length === 0 || viewMode ? true : false}
                                                >
                                                    <img
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="bottom"
                                                        title="Undo"
                                                        src="images/undo-icon.svg"
                                                        className
                                                    />
                                                </button>

                                                <button
                                                    className="btn tool-btn"
                                                    onClick={() => {
                                                        reDoFunction();
                                                    }}
                                                    disabled={redoStack.length === 0 || viewMode ? true : false}

                                                >
                                                    <img
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="bottom"
                                                        title="Redo"
                                                        src="images/redo-icon.svg"
                                                        className
                                                    />
                                                </button>

                                                <button
                                                    id="fittoscreen"
                                                    className="btn tool-btn"
                                                    onClick={() => {
                                                        document.documentElement
                                                            .getElementsByClassName(
                                                                "react-flow__controls-button react-flow__controls-fitview"
                                                            )[0]
                                                            .click();
                                                    }}
                                                >
                                                    <img
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="bottom"
                                                        title="Fit to screen"
                                                        src="images/fit-to-screen.svg"
                                                        className
                                                    />
                                                </button>

                                                <button
                                                    className="btn tool-btn"
                                                    onClick={() => {
                                                        document.documentElement
                                                            .getElementsByClassName(
                                                                "react-flow__controls-button react-flow__controls-zoomin"
                                                            )[0]
                                                            .click();
                                                    }}
                                                >
                                                    <img
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="bottom"
                                                        title="Zoom in"
                                                        src="images/zoom-in.svg"
                                                        className
                                                    />
                                                </button>

                                                <button
                                                    className="btn tool-btn"
                                                    onClick={() => {
                                                        document.documentElement
                                                            .getElementsByClassName(
                                                                "react-flow__controls-button react-flow__controls-zoomout"
                                                            )[0]
                                                            .click();
                                                    }}
                                                >
                                                    <img
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="bottom"
                                                        title="Zoom out"
                                                        src="images/zoom-out.svg"
                                                        className
                                                    />
                                                </button>



                                                <div className="dropdown d-flex">
                                                    <button
                                                        className="btn tool-btn"
                                                        type="button"
                                                        id="dropdownSAVE"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                        disabled={viewMode ? true : false}
                                                        onClick={() => {
                                                            // Roles Should be Handled 
                                                        }}

                                                    >
                                                        <img
                                                            data-bs-toggle="tooltip"
                                                            data-bs-placement="bottom"
                                                            title="Save"
                                                            src="images/save.svg"
                                                            alt="Save"
                                                        />
                                                    </button>
                                                    <ul className="dropdown-menu profile-menu border-0 py-1 px-2 shadow-sm"
                                                        disabled={viewMode ? true : false}
                                                    >
                                                        <li
                                                            className="border-grey pb-1"
                                                            onClick={() => {
                                                                saveArchitecture(0);
                                                            }}
                                                        >
                                                            <a className="dropdown-item font-semibold font-14 profile-dropdown py-2 px-2">
                                                                Save
                                                            </a>
                                                        </li>
                                                        <li
                                                            className="pt-1"
                                                            onClick={() => {
                                                                setIsSaveTemplateModalOpen(true)
                                                                if (providerName.toLowerCase() == 'aws' && PusleIdentificaiton) {
                                                                    setTemplateProviderType("ZebPulse")
                                                                } else if (providerName.toLowerCase() == "aws") {
                                                                    setTemplateProviderType("AWS")
                                                                } else if (providerName.toLowerCase() === 'azure') {
                                                                    setTemplateProviderType("Azure")
                                                                }
                                                            }}
                                                            disabled={viewMode ? viewMode : false}
                                                        >
                                                            <a className="dropdown-item font-semibold font-14 py-2 px-2 profile-dropdown">
                                                                Save as Template
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>

                                                <div className="dropdown d-flex">
                                                    <button
                                                        className="btn tool-btn"
                                                        type="button"
                                                        id="dropdownDOWNLOAD"
                                                        data-bs-toggle="dropdown"
                                                        disabled={nodes.length == 0 ? true : false}
                                                        aria-expanded="false"
                                                    >
                                                        <img
                                                            data-bs-toggle="tooltip"
                                                            data-bs-placement="bottom"
                                                            title="Download"
                                                            src="images/download.svg"

                                                        />
                                                    </button>
                                                    <ul className="dropdown-menu profile-menu border-0 py-1 px-2 shadow-sm">
                                                        <li
                                                            className="border-grey pb-1"
                                                            onClick={() => {
                                                                XMLDownload()
                                                            }}
                                                        >
                                                            <a className="dropdown-item font-semibold font-14 profile-dropdown py-2 px-2">
                                                                XML
                                                            </a>
                                                        </li>
                                                        <li
                                                            className="pt-1 pb-1 border-grey"
                                                            onClick={() => {
                                                                DownLoadImage("Png");

                                                            }}
                                                        >
                                                            <a className="dropdown-item font-semibold font-14 py-2 px-2 profile-dropdown">
                                                                PNG
                                                            </a>
                                                        </li>
                                                        <li
                                                            className="pt-1"
                                                            onClick={() => {
                                                                DownLoadImage("Jpeg");
                                                            }}
                                                        >
                                                            <a className="dropdown-item font-semibold font-14 py-2 px-2 profile-dropdown">
                                                                JPG
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <button
                                                    className="btn tool-btn"

                                                    // data-bs-target="#commit"
                                                    disabled={
                                                        viewMode ? true : false
                                                    }
                                                    onClick={() => {
                                                        setIsCommitModalOpen(true)
                                                    }}

                                                >
                                                    <img
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="bottom"
                                                        title="Commit"
                                                        src="images/commit.svg"

                                                    />
                                                </button>
                                                <button
                                                    className="btn tool-btn"
                                                    disabled={viewMode == true ? true : false}
                                                    onClick={() => {
                                                        setIsVersionHistoryModalOpen(true)
                                                    }}
                                                >
                                                    <img
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="bottom"
                                                        style={{ width: "18px" }}
                                                        title="Commit history"
                                                        src="images/commit-history-icon.svg"

                                                    />
                                                </button>
                                            </div>
                                        </Draggable>
                                        <ReactFlow
                                            nodes={nodes}
                                            edges={edges}
                                            onNodesChange={(e) => {
                                                if (viewMode != true) {
                                                    onNodesChange(e);
                                                }
                                            }}
                                            onEdgesChange={(e) => {
                                                onEdgesChange(e);
                                            }}
                                            onConnect={onConnect}
                                            onDrop={
                                                onDrop}

                                            onDragOver={onDragOver}
                                            onNodeDoubleClick={(e, node) => {
                                                doubleClickLabel(e, node);
                                            }}
                                            onNodesDelete={

                                                onNodesDelete
                                            }
                                            connectionMode="strict"
                                            deleteKeyCode={["Delete", "Backspace"]}
                                            fitView
                                            className="validationflow"
                                            nodeTypes={nodeTypes}
                                            id="myArchitecture"
                                            onPaneClick={(e) => {
                                                if (configureDetails.id != "") {
                                                    setConfigureDetails({ id: "" })
                                                }
                                            }}
                                        // colorMode={colorMode}
                                        >
                                            <Controls />
                                            <Background />
                                        </ReactFlow>

                                        {/** Workbench PopUP Handler */}
                                        {isCommitModalOpen ?
                                            <>
                                                <CommitPopup
                                                    isCommitModalOpen={isCommitModalOpen}
                                                    setIsCommitModalOpen={setIsCommitModalOpen}
                                                    nodes={nodes}
                                                    edges={edges}
                                                    setIsToastPopUpOpen={setIsToastPopUpOpen}
                                                    setToastMessage={setToastMessage}
                                                    popupMsg={popupMsg}
                                                    setPopUpMsg={setPopUpMsg}
                                                />
                                            </> : <></>}
                                        {isVersionHistoryModalOpen ?
                                            <>
                                                <VersionHistoryPopUp
                                                    isVersionHistoryModalOpen={isVersionHistoryModalOpen}
                                                    setIsVersionHistoryModalOpen={setIsVersionHistoryModalOpen}
                                                    viewCommitHistory={viewCommitHistory}
                                                    restoreCommitHistory={restoreCommitHistory}
                                                />
                                            </> : <></>}
                                        {/* TemplatePopup */}
                                        {isSaveTemplateModalOpen ? <>
                                            <SaveTemplatePopup
                                                setIsSaveTemplateModalOpen={setIsSaveTemplateModalOpen}
                                                nodes={nodes}
                                                edges={edges}
                                                templateProviderType={templateProviderType}
                                                popupMsg={popupMsg}
                                                setPopUpMsg={setPopUpMsg}
                                            />
                                        </> : <></>}
                                        {showLoader ?
                                            <WorkbenchLoader /> : <></>

                                        }
                                        {
                                            isLoading?
                                            <WorkbenchLoader /> : <></>

                                        }





                                    </div>
                                </>




                                {isToastPopupOpen ? <>
                                    <ToastMessage
                                        setIsToastPopUpOpen={setIsToastPopUpOpen}
                                        istoastMessage={istoastMessage}

                                    />
                                </> : <></>}

                                {showDeleteModal ?

                                    <div className="modal fade show" id="deleteConfirmationModal"
                                        style={{ display: showDeleteModal ? 'block' : "none", backgroundColor: 'rgba(0,0,0,0.5)' }}
                                        data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
                                        aria-labelledby="deleteConfirmationModalLabel" aria-hidden="true">
                                        <div className="modal-dialog modal-dialog-centered justify-content-center">
                                            <div className="modal-content border-0">
                                                <div className="modal-header border-0 py-4 px-32">
                                                    <h1 className="modal-title font-20 font-bold black-v2" id="deleteConfirmationModalLabel">Delete Confirmation</h1>
                                                    <button type="button" className="btn shadow-none" onClick={() => setShowDeleteModal(false)}>
                                                        <img src="images/close-icon.svg" alt="close-icon" className="icon-hover shadow-none modal-close-filter" />
                                                    </button>
                                                </div>
                                                <div className="modal-body px-32 pt-0">
                                                    <p className="font-16 font-medium black-v2 mb-4">Are you sure you want to delete?</p>
                                                    <div className="d-flex justify-content-end align-items-center mt-4 mb-2 gap-3">
                                                        <button type="button" className="button outline-button text-nowrap py-12" onClick={() => {
                                                            console.log(nodes, "For No")
                                                            let node = [...nodes]
                                                            let tobedeltedNode = { ...deleteNodeService }

                                                            if (tobedeltedNode.data.ConfigureDetail.subServieConfig.type == "delete") {
                                                                tobedeltedNode.data.ConfigureDetail.subServieConfig.type = "update"
                                                                tobedeltedNode.data.ConfigureDetail.subServieConfig.Action.delete = tobedeltedNode.ConfigureDetail.subServieConfig.Action.update
                                                                //    tobedeltedNode.data.ConfigureDetail.subServieConfig.Action.delete.DeployStatus = true
                                                            }
                                                            node.push(tobedeltedNode)
                                                            setNodes(node)

                                                            setShowDeleteModal(false)
                                                        }}>Cancel</button>
                                                        <button
                                                            type="button"
                                                            className="button primary-button text-nowrap py-12"
                                                            onClick={((e) => {
                                                                nodeDeleteState()
                                                                setShowDeleteModal(false)
                                                            })}
                                                        >
                                                            Yes
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : <></>
                                }


                            </>


                            <div className="d-flex h-100 align-items-center position-absolute right-panel-closed w-auto" style={{ right: 0, top: "50%", transform: "translate(0, -50%)" }}>
                                <button className="border-0 bg-transparent" onClick={handleToggleSidebar}>
                                    <img src="images/arrow-left-sidebar.svg" alt="left-arrow" />
                                </button>
                            </div>

                            {/* Conditionally render RightSidebar based on showRightSidebar state */}
                            {showRightSidebar && (
                                <RightSidebar
                                    assignTagsUpdate={() => { }} // Pass any required props here
                                    setRepositories={() => { }}
                                    setSelectedEnv={() => { }}
                                    showAssignTags={false}
                                    setShowAssignTags={() => { }}
                                    onClose={handleToggleSidebar} // Pass close handler to RightSidebar
                                    showLoader={showLoader}
                                    setShowLoader={setShowLoader}
                                    updateAccountDetails={updateAccountDetails}
                                    toastMsg={toastMsg}
                                    setToastMsg={setToastMsg}
                                />
                            )}

                            {/** Configure Component */}

                            {configureDetails.id !== "" ? (
                                configureDetails.data.serviceName !== "Account" &&
                                    configureDetails.data.serviceName != "Region" && isConfigureVisible ? (
                                    <ConfigureComponent
                                        configureDetails={configureDetails}
                                        setconfigureDetails={setConfigureDetails}
                                        repositories={repositories}
                                        labelSubmit={labelSubmit}
                                        connectedSourceNodes={connectedSourceNodes}
                                        onClose={handleCloseConfigure}
                                        nodeDetails={nodeDetails}
                                        overlappedNodes={overlappedNodes}
                                        accountIds={accountIds}
                                        initialDataConfig={initialDataConfig}
                                        uniqueNames={uniqueNames}



                                    />
                                ) : configureDetails.id !== "" && (configureDetails.data.serviceName === "Account" && isAccountConfigureVisible) || (configureDetails.data.serviceName === "Region" && isAccountConfigureVisible) ? (
                                    <AccountConfigureComponent
                                        configureDetails={configureDetails}
                                        labelSubmit={labelSubmit}
                                        accounts={accounts}
                                        setAccounts={setAccounts}
                                        onClose={handleAccountCloseConfigure}
                                        processSelectedService={processSelectedService}



                                    />
                                ) : (
                                    <></>
                                )
                            ) : (
                                <></>
                            )}


                        </div>

                    </div>
                </div>
            </> : <> </>}

            {isReviewDriftModelOpen && viewMode == false ? <>
                <ReviewDrifts
                    reviewDrifts={reviewDrifts}
                    setIsReviewDriftModelOpen={setIsReviewDriftModelOpen}
                    setreviewDriftsData={setreviewDriftsData}
                    restoreCommitHistory={restoreCommitHistory}
                />
            </> : <></>}
            {
                dataConfigModal ?
                    <DataConfiguration
                        activeTabs={activeTab}
                        viewMode={viewMode}
                        setDataConfigModal={setDataConfigModal}
                        accountIds={accountIds}

                    /> :
                    <></>

            }
            {/* {
                    isIACModelOpen  ? 
                    <LocalsOutputsComponent 
                    activeTabs={activeTab} 
                    viewMode = {viewMode}
                    setIACModelOpen = {setIACModelOpen}
                    /> :
                     <></>

                } */}


            {showAssignTags && (
                <AssignTags
                    orgId={orgId}
                    userId={userId}
                    onClose={toggleAssignTags}
                    handleTagsUpdate={assignTagsUpdate}
                    envId={envId}
                />
            )}



        </>
    )
}




export default Workbench;