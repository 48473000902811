import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import {
  getAllOrganization,
  getAllWorkloadsByOrgId,
  getAllRoles,
  createUserRole,
  DeleteUserRole,
  EditUserRole,
  ActivateUserRole,
  GetAllUsers,
} from "../../Service/api"; // Assuming these functions are in an api.js file
import WorkbechPopup from "../Organization/Workbench/WorkBench/PopupMaintanence/WorkBenchPopUp";
import Loader from "../loader/loader";

//PS_01 to PS_18 Declaring state variables
const MangeUserGrid = () => {
  const [organizations, setOrganizations] = useState([]);
  const [workloads, setWorkloads] = useState([]);
  const [roles, setRoles] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedOrgs, setSelectedOrgs] = useState([]);
  const [selectedOrgForWorkload, setSelectedOrgForWorkload] = useState("");
  const [selectedWorkloadId, setSelectedWorkloadId] = useState("");
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [activityStatus, setActivityStatus] = useState([]);
  const [editingRowId, setEditingRowId] = useState(null);
  const [sortOrder, setSortOrder] = useState("ASC");
  const [sortColumn, setSortColumn] = useState("name");
  const [offsetCount, setOffsetCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [workloadsForUser, setWorkloadsForUser] = useState([]);
  const [newRole, setNewRole] = useState({
    organization: "",
    workload: "",
    role: "",
  });
  const [editingRole, setEditingRole] = useState(null);
  const [isFilterPopupOpen, setIsFilterPopupOpen] = useState(false);
  const [selectedWorkloads, setSelectedWorkloads] = useState([]);
  const [openAccordionId, setOpenAccordionId] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);
  const [defaultOrgId, setDefaultOrgId] = useState("");
  const [defaultWorkloadId, setDefaultWorkloadId] = useState("");
  const [defaultRoleId, setDefaultRoleId] = useState("");

  const navigate = useNavigate();

  const resourceId = localStorage.getItem("userId");
  const [popupMsg, setPopUpMsg] = useState({
    Msg: "",
    show: false,
    success: false,
  });
  const [showLoader, setShowLoader] = useState(false);

  // Initial data fetch on component mount
  useEffect(() => {
    console.log("Initial component mount");
    fetchOrganizations();
    fetchRoles();
    fetchUsers("", "name", "ASC", 0);
  }, []);

  // Fetch users when sort parameters change
  useEffect(() => {
    if (sortColumn !== "" && sortOrder !== "") {
      console.log(`Sort changed - column: ${sortColumn}, order: ${sortOrder}`);
      setOffsetCount(0); // Reset offset when sort changes
      fetchUsers("", sortColumn, sortOrder, 0);
    }
  }, [sortColumn, sortOrder]);

  // Fetch users when offset changes (for load more)
  useEffect(() => {
    if (offsetCount > 0) {
      console.log(`Offset changed - New offset: ${offsetCount}`);
      fetchUsers(searchText, sortColumn, sortOrder, offsetCount);
    }
  }, [offsetCount, searchText, sortColumn, sortOrder]);

  const handleViewActivityLogs = (userId, userName) => {
    const sessionUserId = localStorage.getItem("userId");
    navigate("/ViewUserLogs", {
      state: {
        resourceId: userId,
        userId: sessionUserId,
        userName,
      },
    });
  };

  const getActivityStatusForPayload = () => {
    if (activityStatus.length === 0) return [];
    if (activityStatus.length === 2) return [true, false];
    return activityStatus;
  };

  const handleOrganizationChangeForUser = (e, userId) => {
    const orgId = e.target.value;
    if (editingRowId) {
      setSelectedOrgForWorkload(orgId);
      setSelectedWorkloadId(""); // Reset selected workload
    } else {
      setDefaultOrgId(orgId);
      setDefaultWorkloadId(""); // Reset default workload
    }
    if (orgId) {
      fetchWorkloadsForUser(orgId, userId);
    } else {
      setWorkloadsForUser([]);
    }
  };

  const fetchOrganizations = async () => {
    try {
      const response = await getAllOrganization({
        userId: resourceId,
        search: "",
      });
      console.log("Full response:", response);

      if (response.data && response.data.success) {
        setOrganizations(response.data.data || []);
      } else {
        console.error("Unexpected response structure:", response);
        setOrganizations([]);
      }
    } catch (error) {
      console.error("Error fetching organizations:", error);
      setOrganizations([]);
    }
  };

  const fetchWorkloads = async (selectedOrgIds) => {
    try {
      const response = await getAllWorkloadsByOrgId({
        userId: resourceId,
        orgId: "",
        orgIdGeneral: selectedOrgIds,
        connectorId: "",
        type: "general",
      });
      console.log("Full response:", response);

      if (response.data && response.data.success) {
        setWorkloads(response.data.data?.workload || []);
        setSelectedWorkloads([]);
      } else {
        console.error("Unexpected response structure:", response);
        setWorkloads([]);
      }
    } catch (error) {
      console.error("Error fetching workloads:", error);
      setWorkloads([]);
    }
  };

  const fetchWorkloadsForUser = async (orgId, userId) => {
    const resourceId = localStorage.getItem("userId");
    try {
      const response = await getAllWorkloadsByOrgId({
        userId: resourceId,
        orgId: "",
        orgIdGeneral: [orgId],
        connectorId: "",
        type: "general",
      });
      console.log("Full response:", response);

      if (response.data && response.data.success) {
        setWorkloadsForUser(response.data.data?.workload || []);
      } else {
        console.error("Unexpected response structure:", response);
        setWorkloadsForUser([]);
      }
    } catch (error) {
      console.error("Error fetching workloads:", error);
      setWorkloadsForUser([]);
    }
  };

  const fetchRoles = async () => {
    try {
      const response = await getAllRoles({ userId: resourceId, orgId: "" });
      console.log("Full response:", response);

      if (response.data && response.data.body && response.data.body.success) {
        // Filter out roles with name 'admin' (case-insensitive)
        const filteredRoles = (response.data.body.data || []).filter(
          (role) => role.roleName.toLowerCase() !== "admin"
        );
        setRoles(filteredRoles);
      } else {
        console.error("Unexpected response structure:", response);
        setRoles([]);
      }
    } catch (error) {
      console.error("Error fetching roles:", error);
      setRoles([]);
    }
  };

  const handleOrganizationChange = (orgId) => {
    let newSelectedOrgs;
    if (orgId === "all") {
      newSelectedOrgs =
        selectedOrgs.length === organizations.length
          ? []
          : organizations.map((org) => org.orgId);
    } else {
      newSelectedOrgs = selectedOrgs.includes(orgId)
        ? selectedOrgs.filter((id) => id !== orgId)
        : [...selectedOrgs, orgId];
    }
    setSelectedOrgs(newSelectedOrgs);

    // Fetch workloads based on the new selection
    if (newSelectedOrgs.length > 0) {
      fetchWorkloads(newSelectedOrgs);
    } else {
      // If no organizations are selected, reset the workloads
      setWorkloads([]);
      setSelectedWorkloads([]);
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchText(value);
    setIsSearchEnabled(value.length >= 3);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && isSearchEnabled) {
      fetchUsers(searchText);
    }
  };

  const handleWorkloadChange = (workloadId) => {
    if (workloadId === "all") {
      setSelectedWorkloads(workloads.map((wl) => wl.workloadId));
    } else {
      setSelectedWorkloads((prev) =>
        prev.includes(workloadId)
          ? prev.filter((id) => id !== workloadId)
          : [...prev, workloadId]
      );
    }
  };

  const handleRoleChange = (roleId) => {
    if (roleId === "all") {
      setSelectedRoles(roles.map((role) => role.roleId));
    } else {
      setSelectedRoles((prev) =>
        prev.includes(roleId)
          ? prev.filter((id) => id !== roleId)
          : [...prev, roleId]
      );
    }
  };

  const handleAccordionToggle = (userId) => {
    if (openAccordionId === userId) {
      // If clicking on the already open accordion, close it
      setOpenAccordionId(null);
    } else {
      // If clicking on a different accordion, close the current one and open the new one
      setOpenAccordionId(userId);

      // Fetch data for the newly opened accordion
      fetchOrganizations();
      fetchWorkloads(userId);
      fetchRoles();
    }
  };
  const handleActivityStatusChange = (status) => {
    if (status === "all") {
      setActivityStatus(activityStatus.length === 2 ? [] : [true, false]);
    } else {
      setActivityStatus((prev) => {
        const statusBoolean = status === "active";
        if (prev.includes(statusBoolean)) {
          return prev.filter((s) => s !== statusBoolean);
        } else {
          return [...prev, statusBoolean];
        }
      });
    }
  };

  const handleApplyFilter = () => {
    setOffsetCount(0);
    setOpenAccordionId(null); // Close any open accordion
    fetchUsers();
    closeFilterPopup();
  };

  const handleSearch = () => {
    if (isSearchEnabled) {
      setOpenAccordionId(null); // Close any open accordion
      fetchUsers(searchText);
    }
  };

  const handleClearAll = () => {
    // Create an object with reset values
    const resetValues = {
      selectedOrgs: [],
      selectedWorkloads: [],
      selectedRoles: [],
      activityStatus: [],
      searchText: "",
      sortOrder: "ASC",
      sortColumn: "name",
      offsetCount: 0,
    };

    // Update all states at once
    setSelectedOrgs(resetValues.selectedOrgs);
    setSelectedWorkloads(resetValues.selectedWorkloads);
    setSelectedRoles(resetValues.selectedRoles);
    setActivityStatus(resetValues.activityStatus);
    setSearchText(resetValues.searchText);
    setSortOrder(resetValues.sortOrder);
    setSortColumn(resetValues.sortColumn);
    setOffsetCount(resetValues.offsetCount);

    // Close the filter popup
    closeFilterPopup();

    // Fetch users with reset values
    fetchUsers(
      resetValues.searchText,
      resetValues.sortColumn,
      resetValues.sortOrder,
      resetValues.offsetCount,
      resetValues.selectedOrgs,
      resetValues.selectedWorkloads,
      resetValues.selectedRoles,
      resetValues.activityStatus
    );
  };
  const closeFilterPopup = () => {
    setIsFilterPopupOpen(false);
  };

  const handleEditRole = (userId, workloadId, roleId) => {
    setEditingRowId(`${userId}-${workloadId}`);
    setSelectedRoleId(roleId);
  };

  const handleDeleteRole = (userId, workloadId, roleId) => {
    setUserToDelete({ userId, workloadId, roleId });
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    if (userToDelete) {
      try {
        const payload = {
          resourceId: userToDelete.userId,
          userId: resourceId,
          workloadId: userToDelete.workloadId,
        };

        const response = await DeleteUserRole(payload);
        // Close the delete confirmation modal
        setShowDeleteModal(false);
        setOpenAccordionId(null);

        if (response.data && response.data.body && response.data.body.success) {
          setPopUpMsg({
            ...popupMsg,
            ["Msg"]: "User role deleted successfully",
            ["show"]: true,
            ["success"]: true,
          });
        } else {
          console.error("Unexpected response structure:", response);

          setPopUpMsg({
            ...popupMsg,
            ["Msg"]: "Something went wrong",
            ["show"]: true,
            ["success"]: false,
          });
        }
      } catch (error) {
        console.error("Error deleting user role:", error);
        setShowDeleteModal(false);
        setToastMessage("Error deleting user role");
        setShowToast(true);
      }

      await fetchUsers();
    }
  };
  const [selectedRoleId, setSelectedRoleId] = useState("");

  const handleAddRole = async (user) => {
    if (!defaultWorkloadId || !defaultRoleId) {
      setToastMessage("Please select both workload and role");
      setShowToast(true);
      return;
    }

    const payload = {
      resourceId: user.userId,
      userId: resourceId,
      workloadId: defaultWorkloadId,
      roleId: defaultRoleId,
    };

    console.log("Payload for createUserRole:", payload);

    try {
      const response = await createUserRole(payload);
      console.log("Full response:", response);

      if (response.data && response.data.body && response.data.body.success) {
        setPopUpMsg({
          ...popupMsg,
          ["Msg"]: "User role added successfully",
          ["show"]: true,
          ["success"]: true,
        });

        // Reset selections
        setDefaultOrgId("");
        setDefaultWorkloadId("");
        setDefaultRoleId("");
        setOpenAccordionId(null);

        // Fetch workloads for the specific user and organization
        if (defaultOrgId) {
          await fetchWorkloadsForUser(defaultOrgId, user.userId);
        }

        // Refresh the user list
        fetchUsers();
      } else {
        console.error("Unexpected response structure:", response);
        setPopUpMsg({
          ...popupMsg,
          ["Msg"]: "Something went wrong",
          ["show"]: true,
          ["success"]: false,
        });
      }
    } catch (error) {
      console.error("Error adding new role:", error);
      setToastMessage("Error adding new role");
      setShowToast(true);
    }
  };
  const handleCancelEdit = () => {
    setEditingRowId(null);
    setSelectedOrgForWorkload("");
    setSelectedWorkloadId("");
    setSelectedRoleId("");
  };

  const handleSaveEdit = async (userId, workloadId) => {
    const payload = {
      resourceId: userId,
      userId: resourceId,
      workloadId: workloadId,
      roleId: selectedRoleId,
    };

    try {
      const response = await EditUserRole(payload);
      console.log("Full response:", response);

      if (response.data && response.data.body && response.data.body.success) {
        setPopUpMsg({
          ...popupMsg,
          ["Msg"]: "User role updated successfully",
          ["show"]: true,
          ["success"]: true,
        });

        fetchUsers();
        setEditingRowId(null);
        setSelectedRoleId("");
        setOpenAccordionId(null);
      } else {
        console.error("Unexpected response structure:", response);
        throw new Error("Failed to update user role");
      }
    } catch (error) {
      console.error("Error updating user role:", error);
      setPopUpMsg({
        ...popupMsg,
        ["Msg"]: "Something went wrong",
        ["show"]: true,
        ["success"]: false,
      });
    }
  };
  //PS_53 to PS_57 Function to activate user

  const handleActivateUser = async (resourceId, isActive) => {
    try {
      const payload = {
        resourceId: resourceId,
        userId: localStorage.getItem("userId"),
        isActive: (!isActive).toString(), // Convert boolean to string
      };

      const response = await ActivateUserRole(payload);
      console.log("Full response:", response);

      if (response.data && response.data.body && response.data.body.success) {
        setPopUpMsg({
          ...popupMsg,
          ["Msg"]: "User activity status updated successfully",
          ["show"]: true,
          ["success"]: true,
        });

        // Update the user's status in the local state
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.userId === resourceId ? { ...user, isActive: !isActive } : user
          )
        );
      } else {
        console.error("Unexpected API response structure:", response);
        setPopUpMsg({
          ...popupMsg,
          ["Msg"]: "Something went wrong",
          ["show"]: true,
          ["success"]: false,
        });
      }
    } catch (error) {
      console.error("Error activating/deactivating user:", error);
      setPopUpMsg({
        ...popupMsg,
        ["Msg"]: "Something went wrong",
        ["show"]: true,
        ["success"]: false,
      });
    }
  };
  //PS_58 to PS_60 Function to handle load more

  const handleLoadMore = () => {
    console.log("Load More clicked");
    setOffsetCount((prevOffset) => prevOffset + 10);
  };

  const fetchUsers = async (
    searchWord = "",
    sortColumn = "",
    sortOrder = "",
    offset = 0,
    orgIds = selectedOrgs,
    workloadIds = selectedWorkloads,
    roleIds = selectedRoles,
    activityStatusValues = activityStatus
  ) => {
    console.log(
      `Fetching users - Offset: ${offset}, Search: "${searchWord}", Sort: ${sortColumn} ${sortOrder}`
    );
    try {
      setShowLoader(true);
      const response = await GetAllUsers({
        userId: resourceId,
        searchWord,
        filter: {
          organizationId: orgIds,
          workloadId: workloadIds,
          role: roleIds,
          status:
            activityStatusValues.length === 0
              ? []
              : activityStatusValues.length === 2
              ? [true, false]
              : activityStatusValues,
        },
        sort: {
          sortOrder: sortOrder || "ASC",
          sortColumn: sortColumn || "name",
        },
        offsetCount: offset,
      });
      setShowLoader(false);

      if (response.data && response.data.body && response.data.body.success) {
        const { data, rowCount } = response.data.body;
        if (data.length === 0 && offset === 0) {
          setUsers([]);
          setTotalCount(0);
        } else {
          setUsers((prevUsers) => {
            const newUsers = offset === 0 ? data : [...prevUsers, ...data];
            return newUsers;
          });
          setTotalCount(rowCount || 0);
        }
      } else {
        console.error("Unexpected response structure:", response);
        setUsers([]);
        setTotalCount(0);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
      setUsers([]);
      setTotalCount(0);
      setShowLoader(false);
      console.log("Error occurred, reset users and total count");
    }
  };

  const handleSort = (column) => {
    const newSortOrder =
      column === sortColumn && sortOrder === "ASC" ? "DESC" : "ASC";
    console.log(`Sorting by ${column} in ${newSortOrder} order`);
    setSortOrder(newSortOrder);
    setSortColumn(column);
    // fetchUsers is called by the useEffect that depends on sortColumn and sortOrder
  };

  return (
    <>
      {showLoader ? (
        <Loader />
      ) : (
        <>
          <div className="container-fluid custom-container">
            {/* Slide title start here*/}
            <div className="d-flex justify-content-between align-items-center pt-4 mt-2 pb-2 notifications-title-bar">
              <h2 className="font-20 font-bold black-v2 mb-0">Manage Users</h2>
              <div className="d-flex gap-3 position-relative">
                {/* filter start */}
                <button
                  type="button"
                  className="filter-button position-relative"
                  onClick={() => setIsFilterPopupOpen(!isFilterPopupOpen)}
                >
                  <img src="images/filter-blue.svg" alt="filter-icon" />
                </button>
                {/*filter*/}
                {isFilterPopupOpen && (
                  <div
                    className="dropdown-menu dropdown-menu-end border-0 custom-shadow dd-position background-white manage-users-filter show"
                    style={{
                      position: "absolute",
                      right: "300px",
                    }}
                  >
                    <h5 className="font-bold font-20 color-black mb-3">
                      Filter
                    </h5>
                    <div className="row">
                      <div className="col-md-6 mb-4">
                        <label
                          className="form-label font-14 font-medium black-v2"
                          htmlFor="organization"
                        >
                          Organization
                        </label>
                        <div className="dropdown active-check">
                          <button
                            type="button"
                            className="btn custom-dropdown d-flex justify-content-between align-items-center gap-5 font-12 font-regular color-black shadow-none"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <span className="d-flex font-14 font-regular black-v2">
                              {selectedOrgs.length > 0
                                ? `${selectedOrgs.length} selected`
                                : "Select"}
                            </span>
                            <img
                              src="images/drop-down-arrow.svg"
                              alt="dropdown-arrow"
                            />
                          </button>
                          <ul
                            className="dropdown-menu body-bg access-dropdown"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li className="font-regular color-black font-12 p-2 custom-list">
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  className="form-check-input custom-checkbox"
                                  id="organization-all"
                                  checked={
                                    selectedOrgs.length === organizations.length
                                  }
                                  onChange={() =>
                                    handleOrganizationChange("all")
                                  }
                                />
                                <label
                                  className="form-check-label font-11 font-regular black-v2 cursor-pointer"
                                  htmlFor="organization-all"
                                >
                                  All
                                </label>
                              </div>
                            </li>
                            {organizations.map((org) => (
                              <li
                                key={org.orgId}
                                className="font-regular color-black font-12 p-2 custom-list"
                              >
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input custom-checkbox"
                                    id={`organization-${org.orgId}`}
                                    checked={selectedOrgs.includes(org.orgId)}
                                    onChange={() =>
                                      handleOrganizationChange(org.orgId)
                                    }
                                  />
                                  <label
                                    className="form-check-label font-11 font-regular black-v2 cursor-pointer"
                                    htmlFor={`organization-${org.orgId}`}
                                  >
                                    {org.orgName}
                                  </label>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>

                      <div className="col-md-6 mb-4">
                        <label
                          className="form-label font-14 font-medium black-v2"
                          htmlFor="workload"
                        >
                          Workload
                        </label>
                        <div className="dropdown active-check">
                          <button
                            type="button"
                            className="btn custom-dropdown d-flex justify-content-between align-items-center gap-5 font-12 font-regular color-black shadow-none"
                            id="dropdownMenuButton2"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <span className="d-flex font-14 font -regular black-v2">
                              {selectedWorkloads.length > 0
                                ? `${selectedWorkloads.length} selected`
                                : "Select"}
                            </span>
                            <img
                              src="images/drop-down-arrow.svg"
                              alt="dropdown-arrow"
                            />
                          </button>
                          <ul
                            className="dropdown-menu body-bg access-dropdown"
                            aria-labelledby="dropdownMenuButton2"
                          >
                            <li className="font-regular color-black font-12 p-2 custom-list">
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  className="form-check-input custom-checkbox"
                                  id="workload-all"
                                  checked={
                                    selectedWorkloads.length ===
                                    workloads.length
                                  }
                                  onChange={() => handleWorkloadChange("all")}
                                />
                                <label
                                  className="form-check-label font-11 font-regular black-v2 cursor-pointer"
                                  htmlFor="workload-all"
                                >
                                  All
                                </label>
                              </div>
                            </li>
                            {workloads.map((workload) => (
                              <li
                                key={workload.workloadId}
                                className="font-regular color-black font-12 p-2 custom-list"
                              >
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input custom-checkbox"
                                    id={`workload-${workload.workloadId}`}
                                    checked={selectedWorkloads.includes(
                                      workload.workloadId
                                    )}
                                    onChange={() =>
                                      handleWorkloadChange(workload.workloadId)
                                    }
                                  />
                                  <label
                                    className="form-check-label font-11 font-regular black-v2 cursor-pointer"
                                    htmlFor={`workload-${workload.workloadId}`}
                                  >
                                    {workload.workloadName}
                                  </label>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>

                      <div className="col-md-6 mb-4">
                        <label
                          className="form-label font-14 font-medium black-v2"
                          htmlFor="role"
                        >
                          Role
                        </label>
                        <div className="dropdown active-check">
                          <button
                            type="button"
                            className="btn custom-dropdown d-flex justify-content-between align-items-center gap-5 font-12 font-regular color-black shadow-none"
                            id="dropdownMenuButton3"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <span className="d-flex font-14 font-regular black-v2">
                              {selectedRoles.length > 0
                                ? `${selectedRoles.length} selected`
                                : "Select"}
                            </span>
                            <img
                              src="images/drop-down-arrow.svg"
                              alt="dropdown-arrow"
                            />
                          </button>
                          <ul
                            className="dropdown-menu body-bg access-dropdown"
                            aria-labelledby="dropdownMenuButton3"
                          >
                            <li className="font-regular color-black font-12 p-2 custom-list">
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  className="form-check-input custom-checkbox"
                                  id="role-all"
                                  checked={
                                    selectedRoles.length === roles.length
                                  }
                                  onChange={() => handleRoleChange("all")}
                                />
                                <label
                                  className="form-check-label font-11 font-regular black-v2 cursor-pointer"
                                  htmlFor="role-all"
                                >
                                  All
                                </label>
                              </div>
                            </li>
                            {roles.map((role) => (
                              <li
                                key={role.roleId}
                                className="font-regular color-black font-12 p-2 custom-list"
                              >
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input custom-checkbox"
                                    id={`role-${role.roleId}`}
                                    checked={selectedRoles.includes(
                                      role.roleId
                                    )}
                                    onChange={() =>
                                      handleRoleChange(role.roleId)
                                    }
                                  />
                                  <label
                                    className="form-check-label font-11 font-regular black-v2 cursor-pointer"
                                    htmlFor={`role-${role.roleId}`}
                                  >
                                    {role.roleName}
                                  </label>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>

                      <div className="col-md-6 mb-4">
                        <label
                          className="form-label font-14 font-medium black-v2"
                          htmlFor="activity-status"
                        >
                          Activity Status
                        </label>
                        <div className="dropdown active-check">
                          <button
                            type="button"
                            className="btn custom-dropdown d-flex justify-content-between align-items-center gap-5 font-12 font-regular color-black shadow-none"
                            id="dropdownMenuButton4"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <span className="d-flex font-14 font-regular black-v2">
                              {activityStatus.length > 0
                                ? `${activityStatus.length} selected`
                                : "Select"}
                            </span>
                            <img
                              src="images/drop-down-arrow.svg"
                              alt="dropdown-arrow"
                            />
                          </button>
                          <ul
                            className="dropdown-menu body-bg access-dropdown"
                            aria-labelledby="dropdownMenuButton4"
                          >
                            <li className="font-regular color-black font-12 p-2 custom-list">
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  className="form-check-input custom-checkbox"
                                  id="activity-status-all"
                                  checked={activityStatus.length === 2}
                                  onChange={() =>
                                    handleActivityStatusChange("all")
                                  }
                                />
                                <label
                                  className="form-check-label font-11 font-regular black-v2 cursor-pointer"
                                  htmlFor="activity-status-all"
                                >
                                  All
                                </label>
                              </div>
                            </li>
                            <li className="font-regular color-black font-12 p-2 custom-list">
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  className="form-check-input custom-checkbox"
                                  id="activity-status-active"
                                  checked={activityStatus.includes(true)}
                                  onChange={() =>
                                    handleActivityStatusChange("active")
                                  }
                                />
                                <label
                                  className="form-check-label font-11 font-regular black-v2 cursor-pointer"
                                  htmlFor="activity-status-active"
                                >
                                  Active
                                </label>
                              </div>
                            </li>
                            <li className="font-regular color-black font-12 p-2 custom-list">
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  className="form-check-input custom-checkbox"
                                  id="activity-status-inactive"
                                  checked={activityStatus.includes(false)}
                                  onChange={() =>
                                    handleActivityStatusChange("inactive")
                                  }
                                />
                                <label
                                  className="form-check-label font-11 font-regular black-v2 cursor-pointer"
                                  htmlFor="activity-status-inactive"
                                >
                                  Inactive
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end align-items-center gap-3">
                      <button
                        type="button"
                        className="button outline-button text-nowrap px-4"
                        onClick={handleClearAll}
                      >
                        Clear All
                      </button>
                      <button
                        type="button"
                        className="btn button primary-button text-nowrap px-3"
                        onClick={handleApplyFilter}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                )}
                {/*filter*/}
                <div className="input-group input-search custom-border-box custom-search-box">
                  <input
                    type="search"
                    className="px-3 form-control common-bg border-0 custom-search $gray-v1"
                    placeholder="Search"
                    value={searchText}
                    onChange={handleSearchChange}
                    onKeyPress={handleKeyPress}
                  />
                  <button
                    className="btn border-start-0 custom-search"
                    type="button"
                    onClick={handleSearch}
                    disabled={!isSearchEnabled}
                  >
                    <img src="images/search-icon.svg" alt="search" />
                  </button>
                </div>
              </div>
            </div>
            {/* Slide title end here*/}
            {/* Page Content Start here */}
            {popupMsg.show && popupMsg.Msg !== "" && (
              <div
                style={{
                  position: "absolute",
                  top: "15%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 1000,
                  maxWidth: "80%",
                  width: "300px",
                }}
              >
                <WorkbechPopup popupMsg={popupMsg} setPopUpMsg={setPopUpMsg} />
              </div>
            )}

            {users.length === 0 ? (
              <div className="no-record-found">
                <img
                  src="images/no-records-found.png"
                  alt="No records found"
                  className="no-record-icon"
                />
              </div>
            ) : (
              <>
                <div className="table-responsive mt-4 table-border manage-user-table">
                  <table className="table custom-table">
                    <thead className="parent-table-title">
                      <tr>
                        <th className="border-0" style={{ width: 0 }} />
                        <th className="position-relative text-start border-0">
                          Name
                          <span
                            className="cursor-pointer margin-left-2"
                            onClick={() => handleSort("name")}
                          >
                            <img
                              src="./images/table-sort.svg"
                              alt="sort"
                              style={{
                                transform:
                                  sortColumn === "name" && sortOrder === "ASC"
                                    ? "scaleY(-1)"
                                    : "scaleY(1)",
                                transition: "transform 0.2s ease-in-out",
                              }}
                            />
                          </span>
                        </th>
                        <th className="position-sort-icon text-start border-0">
                          Email
                        </th>

                        <th className="position-relative text-end border-0" style={{
                paddingRight:"18px"
              }}>
                          #Workloads Associated
                          <span
                            className="cursor-pointer margin-left-2"
                            onClick={() => handleSort("workload")}
                          >
                            <img
                              src="./images/table-sort.svg"
                              alt="sort"
                              style={{
                                transform:
                                  sortColumn === "workload" &&
                                  sortOrder === "ASC"
                                    ? "scaleY(-1)"
                                    : "scaleY(1)",
                                transition: "transform 0.2s ease-in-out",
                              }}
                            />
                          </span>
                        </th>
                        <th className="position-relative text-end border-0" style={{
                paddingRight:"18px"
              }}>
                          #Organizations Associated
                          <span
                            className="cursor-pointer margin-left-2"
                            onClick={() => handleSort("organization")}
                          >
                            <img
                              src="./images/table-sort.svg"
                              alt="sort"
                              style={{
                                transform:
                                  sortColumn === "organization" &&
                                  sortOrder === "ASC"
                                    ? "scaleY(-1)"
                                    : "scaleY(1)",
                                transition: "transform 0.2s ease-in-out",
                              }}
                            />
                          </span>
                        </th>
                        <th className="position-sort-icon text-center border-0">
                          Active Status
                        </th>
                        <th className="text-center border-0">Activity Logs</th>
                      </tr>
                    </thead>
                    <tbody className="parent-table-data">
                      {users.map((user) => (
                        <React.Fragment key={user.userId}>
                          <tr className="accordion-middle">
                            <td className="text-center">
                              <span className="notify-viewmore">
                                <img
                                  className={`notifications-arrow cursor-pointer table-accordion-arrow ${
                                    openAccordionId === user.userId
                                      ? ""
                                      : "collapsed"
                                  }`}
                                  src="images/notifify-right-arrow.svg"
                                  alt="notifications-arrow"
                                  onClick={() =>
                                    handleAccordionToggle(user.userId)
                                  }
                                />
                              </span>
                            </td>
                            <td className="text-nowrap text-start">
                              {user.userName}
                            </td>
                            <td className="text-nowrap text-start">
                              <a>{user.emailId}</a>
                            </td>
                            <td className="text-nowrap text-end number">
                              {user.totalWorkloadCount}
                            </td>
                            <td className="text-nowrap text-end number">
                              {user.totalOrganizationCount}
                            </td>

                            <td className="text-nowrap text-center">
                              <div className="form-check form-switch d-flex justify-content-center">
                                <input
                                  className="form-check-input custom-switcher"
                                  type="checkbox"
                                  role="switch"
                                  checked={user.isActive}
                                  onChange={() =>
                                    handleActivateUser(
                                      user.userId,
                                      user.isActive
                                    )
                                  }
                                />
                              </div>
                            </td>
                            <td className="text-nowrap text-center">
                              <button
                                type="button"
                                className="button secondary-outline-button tag-color table-btn text-nowrap"
                                onClick={() =>
                                  handleViewActivityLogs(
                                    user.userId,
                                    user.userName
                                  )
                                }
                              >
                                View
                              </button>
                            </td>
                          </tr>
                          <tr
                            className={`collapse ${
                              openAccordionId === user.userId ? "show" : ""
                            }`}
                          >
                            <td colSpan={7} className="inner-table pe-0 pt-0">
                              <span className="table-responsive mt-4 table-border border-0">
                                <table className="table custom-table">
                                  <thead>
                                    <tr>
                                      <th className="text-nowrap text-start border-0">
                                        Organization
                                      </th>
                                      <th className="text-nowrap text-start border-0">
                                        Workload Name
                                      </th>
                                      <th className="text-nowrap text-start border-0">
                                        Role
                                      </th>
                                      <th className="text-nowrap text-start border-0">
                                        Last active
                                      </th>
                                      <th className="text-nowrap text-center border-0">
                                        Action
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {/* Default Row */}

                                    {/* Default Row */}
                                    <tr>
                                      <td className="text-start">
                                        <select
                                          name="organization"
                                          id="organization"
                                          className="form-select form-control tabel-select"
                                          value={defaultOrgId}
                                          onChange={(e) => {
                                            setDefaultOrgId(e.target.value);
                                            handleOrganizationChangeForUser(
                                              e,
                                              user.userId
                                            );
                                          }}
                                        >
                                          <option value="">Select</option>
                                          {organizations.map((org) => (
                                            <option
                                              key={org.orgId}
                                              value={org.orgId}
                                            >
                                              {org.orgName}
                                            </option>
                                          ))}
                                        </select>
                                      </td>
                                      <td className="text-start">
                                        <select
                                          name="workload"
                                          id="workload-name"
                                          className="form-select form-control tabel-select"
                                          value={defaultWorkloadId}
                                          onChange={(e) =>
                                            setDefaultWorkloadId(e.target.value)
                                          }
                                          // disabled={!defaultOrgId}
                                        >
                                          <option value="">Select</option>
                                          {workloadsForUser.map((workload) => (
                                            <option
                                              key={workload.workloadId}
                                              value={workload.workloadId}
                                            >
                                              {workload.workloadName}
                                            </option>
                                          ))}
                                        </select>
                                      </td>
                                      <td className="text-start">
                                        <select
                                          name="role"
                                          id="role"
                                          className="form-select form-control tabel-select"
                                          value={defaultRoleId}
                                          onChange={(e) =>
                                            setDefaultRoleId(e.target.value)
                                          }
                                        >
                                          <option value="">Select</option>
                                          {roles.map((role) => (
                                            <option
                                              key={role.roleId}
                                              value={role.roleId}
                                            >
                                              {role.roleName}
                                            </option>
                                          ))}
                                        </select>
                                      </td>
                                      <td className="text-start" />
                                      <td className="text-nowrap text-center">
                                        <div className="d-flex align-items-center justify-content-center gap-3 me-1">
                                          <button
                                            type="button"
                                            className="btn p-0"
                                            onClick={() => handleAddRole(user)}
                                          >
                                            <img
                                              src="images/table-plus-icon.svg"
                                              alt="plus-icon"
                                              className="plus-icon-width"
                                            />
                                          </button>
                                        </div>
                                      </td>
                                    </tr>

                                    {/* Associated Workloads */}
                                    {user.associatedWorkloads.map(
                                      (workload) => (
                                        <tr key={workload.workloadId}>
                                          {editingRowId ===
                                          `${user.userId}-${workload.workloadId}` ? (
                                            <>
                                              <td className="text-start">
                                                {workload.organizationName}
                                              </td>
                                              <td className="text-start">
                                                {workload.workloadName}
                                              </td>
                                              <td className="text-start">
                                                <select
                                                  value={selectedRoleId}
                                                  onChange={(e) =>
                                                    setSelectedRoleId(
                                                      e.target.value
                                                    )
                                                  }
                                                  className="form-select form-control tabel-select"
                                                >
                                                  {roles.map((role) => (
                                                    <option
                                                      key={role.roleId}
                                                      value={role.roleId}
                                                    >
                                                      {role.roleName}
                                                    </option>
                                                  ))}
                                                </select>
                                              </td>
                                              <td className="text-start">
                                                {workload.lastActive}
                                              </td>
                                              <td className="text-nowrap text-center">
                                                <div className="d-flex align-items-center justify-content-center gap-3 me-1">
                                                  <button
                                                    type="button"
                                                    className="btn p-0"
                                                    onClick={() =>
                                                      handleSaveEdit(
                                                        user.userId,
                                                        workload.workloadId
                                                      )
                                                    }
                                                  >
                                                    <img
                                                      src="images/table-success-tick.svg"
                                                      alt="tick-icon"
                                                      className="plus-icon-width"
                                                    />
                                                  </button>
                                                  <button
                                                    type="button"
                                                    className="btn p-0"
                                                    onClick={handleCancelEdit}
                                                  >
                                                    <img
                                                      src="images/red-cancel-icon.svg"
                                                      alt="cancel-icon"
                                                      className="plus-icon-width"
                                                    />
                                                  </button>
                                                </div>
                                              </td>
                                            </>
                                          ) : (
                                            <>
                                              <td className="text-start">
                                                {workload.organizationName}
                                              </td>
                                              <td className="text-start">
                                                {workload.workloadName}
                                              </td>
                                              <td className="text-start">
                                                {workload.roleName}
                                              </td>
                                              <td className="text-start">
                                                {workload.lastActive}
                                              </td>
                                              <td className="text-nowrap text-center">
                                                <div className="d-flex align-items-center justify-content-center gap-3 me-1">
                                                  <button
                                                    type="button"
                                                    className="btn p-0"
                                                    onClick={() =>
                                                      handleEditRole(
                                                        user.userId,
                                                        workload.workloadId,
                                                        workload.roleId
                                                      )
                                                    }
                                                  >
                                                    <img
                                                      src="images/edit-icon-blue.svg"
                                                      alt="edit-icon-blue"
                                                      className="plus-icon-width"
                                                    />
                                                  </button>
                                                  <button
                                                    type="button"
                                                    className="btn p-0"
                                                    onClick={() =>
                                                      handleDeleteRole(
                                                        user.userId,
                                                        workload.workloadId,
                                                        workload.roleId
                                                      )
                                                    }
                                                  >
                                                    <img
                                                      src="images/bin-icon-red.svg"
                                                      alt="bin-icon-red"
                                                      className="plus-icon-width"
                                                    />
                                                  </button>
                                                </div>
                                              </td>
                                            </>
                                          )}
                                        </tr>
                                      )
                                    )}
                                    {/* Associated Workloads */}
                                  </tbody>
                                </table>
                              </span>
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="d-flex justify-content-between align-items-center my-3">
                  <p className="font-14 font-regular secondary-text-color mb-0">
                    # of Records:{" "}
                    <span className="font16 font-medium color-black">
                      {" "}
                      <span>{users.length}</span> out of{" "}
                      <span>{totalCount}</span>
                    </span>
                  </p>
                </div>

                <div className="d-block text-center my-3 mb-5">
                  {users.length < totalCount && (
                    <button
                      type="button"
                      className="button secondary-outline-button text-nowrap tag-color"
                      onClick={handleLoadMore}
                    >
                      Load More
                    </button>
                  )}
                </div>
              </>
            )}
            {/* Page Content End here */}
          </div>
          {showDeleteModal && (
            <div
              className="modal fade show"
              style={{
                display: "block",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
              id="staticBackdrop-25"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabIndex={-1}
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered justify-content-center">
                <div className="modal-content border-0">
                  <div className="modal-header d-flex justify-content-end border-0 pb-0 ">
                    <button
                      type="button"
                      className="btn shadow-none"
                      onClick={() => setShowDeleteModal(false)}
                    >
                      <img
                        src="images/close-icon.svg"
                        alt="close-icon"
                        className="icon-hover shadow-none modal-close-filter"
                      />
                    </button>
                  </div>
                  <div className="modal-body text-center pt-0 p-32">
                    <img
                      src="images/delete-organization-icon.svg"
                      alt="delete the Organization"
                    />
                    <h6 className="font-20 font-bold black-v2 mt-4 pt-2">
                      You're about to delete an user
                    </h6>
                    <p className="font-16 font-regular secondary-text-color-v5 mt-3 line-height-32 mb-0">
                      {users.find(
                        (user) => user.userId === userToDelete?.userId
                      )?.userName || "User"}
                    </p>
                    <p className="font-16 font-regular secondary-text-color-v5 mt-2 line-height-32">
                      Do you want to proceed?
                    </p>
                    <div className="d-flex justify-content-center gap-3 align-items-center mt-4 pt-3">
                      <button
                        type="button"
                        className="button outline-button text-nowrap px-32"
                        onClick={confirmDelete}
                      >
                        Yes
                      </button>

                      <button
                        type="button"
                        className="button primary-button popup-btn px-32"
                        onClick={() => setShowDeleteModal(false)}
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default MangeUserGrid;
