import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import ManageOrganizations from './Components/Organization/organizationGrid.jsx';
import AboutPage from './Components/Organization/About.jsx'; 
import AddOrganization from './Components/Organization/Add_Organizations.jsx';
import Header from './Components/Header/Header.jsx';
import Inventory from './Components/Inventories/Inventories.jsx';
import Workloads from './Components/Organization/Workload_Grid.jsx';
import WorkloadDetailsPage from './Components/Organization/workloadDetails.jsx';
import { FormProvider } from './Components/Organization/Workload_Grid.jsx'; // Check if this is valid
import EnvironmentDetails from './Components/Organization/EnvironmentDetails.jsx';
import MemberDetails from './Components/Organization/memberDetails.jsx';
import RepositoryDetails from './Components/Organization/RepoDetails.jsx';
import SideBar from './Components/sidebar/SideBar.jsx';
import InvoicesPage from './Components/CostManagement/Invoices.jsx';
import RulebookGrid from './Components/Rulebook/Rulebook.jsx';
import AddRule from './Components/Rulebook/AddRule.jsx';
import RepositoryGrid from './Components/Organization/RepoGrid.jsx';
import ProviderConfiguration from './Components/ProviderConfiguration/ProviderConfiguration.jsx';
import ThresholdGridPage from './Components/CostManagement/workload_Threshold.jsx';
import EnvironmentThresholdPage from './Components/CostManagement/Environment_Threshold.jsx';
import CostManagementForm from './Components/CostManagement/AddBudget.jsx';
import EditPrimaryDetails from './Components/Organization/Edit_primaryDetils.jsx';
import { OrgProvider } from './ContextProvider/OrgContext.js';
import LoadingComponent from './Components/loader/loader.jsx';
import { postLoginDetails } from './Service/api.jsx';
// keerthana code 

import ContextProvider from "./ContextProvider/contextProvider";

import ViewUserActivityLogs from "./Components/Admin/ViewUserActivityLogs";
import CostDashboard from "./Components/Dashboard/CostDashboard";
import TemplateKBGrid from "./Components/Admin/TemplatesKBGrid";
import TemplateKbForm from "./Components/Admin/TemplateKBForm";
import ManageUserGrid from "./Components/Admin/ManageUserGrid";
import CICDDeployment from "./Components/CICD/CICDDeployment";
import ArticleGrid from "./Components/KnowledgeBase/ArticleGrid";
import ViewArticle from "./Components/KnowledgeBase/ViewArticle";
import ArticleForm from "./Components/KnowledgeBase/ArticleForm";

// harideesha code 

import AddNewService1 from './Components/Admin/AddNewService1';
import SelfService from './Components/Admin/SelfService';
import DbuDashBoard from './Components/Databricks/DbuDashboard';
import Insights from './Components/KnowledgeBase/Insights';
import InsightsView from './Components/KnowledgeBase/InsightsView';
import Usermanual1 from './Components/KnowledgeBase/Usermanual1';
import Login from './Components/Login/Login';
import Notifications from './Components/Organization/Notification';
import ApprovalGrid from './Components/Organization/ApprovalGrid';
import ManageTemplates from './Components/Templates/ManageTemplates';
import ServiceReviewAccordian from './Components/Workbench/ServiceReviewAccordian';
import DeployModuleCodeEditor from './Components/Workbench/DeployModuleCodeEditor';
import BranchingStrategy from './Components/Workbench/BranchingStrategy';
import DeployPopUps from './Components/Workbench/DeployPopUps';

import RightSidebar from './Components/Organization/Workbench/WorkBench/RightSidebar.jsx';
import RepoWorkbenchRender from './Components/Organization/Workbench/RepoWorkBench/RepoWorkBenchRender';
import WorkbenchRender from './Components/Organization/Workbench/WorkBench/WorkbenchRender';
import B2AGridcomponent from './Components/Organization/B2A/B2AGridComponent';
import B2AformComponent from './Components/Organization/B2A/B2AFormComponent';
import AWSB2AComponent from './Components/Organization/B2A/AWSB2A';
import AzureB2A from './Components/Organization/B2A/AzureB2A';
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from './authConfig.js';
import { MsalProvider } from '@azure/msal-react';
import Estimation from './Components/Organization/Estimation/Estimation';
import { EstimationProvider } from './ContextProvider/EstimationContext.js';
import AddNewService from './Components/SelfService/AddNewService.jsx';
import AddService from './Components/SelfService/AddNewServiceMain.jsx';
import InvoicesComponent from './Components/CostManagement/Invoices/Invoices.jsx';
import { DashboardServiceProvider } from './Components/Dashboard/Context/DashboardContext.jsx';
import { DatabricksContext, DatabricksContextProvider } from './Components/Databricks/Databricks.jsx';
import LocalsOutputsComponent from './Components/Organization/Workbench/WorkBench/IAC_Component.jsx';
import NotFoundPage from './Components/Not found/Page_Not_Found.jsx';
import ServiceConfigurationMain from './Components/Admin/ServiceConfigurations/ServiceCOnfigurationMain.jsx';
import ModulesConfigGrid from './Components/Admin/ServiceConfigurations/ModulesConfigGrid.jsx';
import ImportServiceForm from './Components/Admin/ServiceConfigurations/ImportServiceForm.jsx';
import EditServiceForm from './Components/Admin/ServiceConfigurations/EditServiceForm.jsx';
import { ModulesProvider } from './ContextProvider/ModulesContxt.jsx';
import DataConfiguration from './Components/Organization/Workbench/WorkBench/DataConfiguration.jsx';
import ChatBot from './Components/Organization/Workbench/WorkBench/ChatBot.jsx';
const msalInstance = new PublicClientApplication(msalConfig);










function AppContent() {

	console.log("Inside the App.js.........")
	const location = useLocation();
	const navigate = useNavigate()
	const hideHeaderRoutes = ['/', '/forgetPassword', '/oops', '/signUp', '/signUpStepTwo'];
	const shouldShowHeader = !hideHeaderRoutes.includes(location.pathname);

	const [isDarkMode, setIsDarkMode] = useState( localStorage.getItem('darkTheme') === 'true');
	const isDarkMode2 = localStorage.getItem('darkTheme') === 'true';
console.log("mode",isDarkMode);
console.log("mode",isDarkMode2);

    // Effect to update local storage whenever isDarkMode changes
    useEffect(() => {
        localStorage.setItem('darkTheme', isDarkMode);
    }, [isDarkMode]);


	// const [isDarkMode, setIsDarkMode] = useState(false);
	// console.log("darkkkkkkkkkk",localStorage.getItem('darkTheme')== 'true');
	// document.body.classList.toggle('dark-mode', isDarkMode);
	// console.log("darkkkkkkkkkk2",isDarkMode);

	// useEffect(() => {
	// 	// Apply dark mode class to body based on initial state
	// 	document.body.classList.toggle('dark-mode', isDarkMode);
	// 	console.log("darklog",document.body.classList.toggle('dark-mode', isDarkMode));
	// }, [isDarkMode]); // Run only once on mount

	// useEffect(() => {
	// 	// Update local storage and body class whenever isDarkMode changes
	// 	localStorage.setItem('darkTheme', isDarkMode ? 'true' : 'false');
	// 	console.log("darkkkkmode");
	// 	document.body.classList.toggle('dark-mode', isDarkMode);
	// }, [isDarkMode]);

	

	useEffect(() => {
		
		const startSessionRefresh = () => {
		  console.log('Starting session refresh interval');
		  const intervalId = setInterval(() => {
			refreshSession();
		  }, 3 * 60 * 1000); // Refresh session every 3 minutes
		  return () => clearInterval(intervalId);
		};
   
		startSessionRefresh();
	  }, []);
   
	  const refreshSession = async () => {
		try {
		  // Assuming you have the necessary user details in localStorage
		  const username = localStorage.getItem('userName');
		  const email = localStorage.getItem('emailId');
	 
		  const response = await postLoginDetails({ username, email });
	 
		  console.log(response, "Refreshed session response");
	 
		  // const { jwtToken, connectionId, isAdmin, isDarkTheme } = response.data.body.data[0];
		  const jwtToken = response.data.body.data[0].jwtToken;
			  const connectionId = response.data.body.data[0].connectionId
			  const isadmin = response.data.body.data[0].isAdmin;
			  const isDarkTheme = response.data.body.data[0].isDarkTheme;
	 
		  if (!jwtToken) {
			throw new Error('JWT token is empty');
		  }
	 
		  // Update the token and other session information in localStorage
		  localStorage.setItem('jwt', jwtToken);
		  localStorage.setItem('connectionId', connectionId);
		  localStorage.setItem('isAdmin', isadmin);
		  localStorage.setItem('isDarkTheme', isDarkTheme);
		  localStorage.setItem('darkTheme', isDarkTheme);
		} catch (error) {
		  console.error("Failed to refresh session", error);
	 
		  // On failure, clear storage and redirect to login
		  // localStorage.clear();
		  // sessionStorage.clear();
		  // window.location.href = '/';
		}
	  };

	useEffect(()=>{
		if(!(localStorage.getItem('userName') && localStorage.getItem('userId') && localStorage.getItem('jwt'))){
			navigate('/')
		}
	},[])


	return (
		(localStorage.getItem('userName') && localStorage.getItem('userId') && localStorage.getItem('jwt')) ?
		<div className={isDarkMode ? 'dark-theme' : ''}>
			{shouldShowHeader && 
				(window.location.pathname !== '/RepoWorkBench' && 
					window.location.pathname !== '/Workbench' && 
					window.location.pathname !== '/Estimation' && 
					window.location.pathname !== '/DeployModuleCodeEditor' && 
					window.location.pathname !== '/CICDDeploy' && 
					window.location.pathname !== '/BranchingStrategy' && 
					window.location.pathname !== '/ServiceReview' && 
					window.location.pathname !== '/Login' && 
					window.location.pathname !== '/estimation' && 
					window.location.pathname !== '/DataConfiguration' && 
					window.location.pathname !== '/ChatBot' &&

					window.location.pathname !== '/unauthorizedAccess') && 
				<Header isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} />}

				<Routes >
					<Route path='/' element={<Login isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode}/>}/>
					<Route path="/manage-organizations" element={<ManageOrganizations />} />
					<Route path="/about" element={<AboutPage />} />
					<Route path="/add-organization" element={<AddOrganization />} />
					<Route path="/inventory" element={<Inventory  isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} />} />
					<Route path="/workloadsGrid" element={<Workloads  isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} />} />
					<Route path="/workloadsDetails" element={<WorkloadDetailsPage />} />
					<Route path="/environmentDetails" element={<EnvironmentDetails />} />
					<Route path="/memberDetails" element={<MemberDetails />} />
					<Route path="/repositoryDetails" element={<RepositoryDetails />} />
					<Route path="/invoicesPage" element={<InvoicesComponent isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} />} />
					<Route path="/rulebook" element={<RulebookGrid />} />
					<Route path="/addRule" element={<AddRule />} />
					<Route path="/repositoryGrid" element={<RepositoryGrid />} />
					<Route path="/thresholdGridPage" element={<ThresholdGridPage isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} />} />
					<Route path="/editPrimaryDetails" element={<EditPrimaryDetails />} />
					<Route path="/providerConfiguration" element={<ProviderConfiguration isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} />} />
					<Route path="/environmentThresholdPage" element={<EnvironmentThresholdPage  isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode}  />} />
					<Route path="/CostManagementForm" element={<CostManagementForm />} />
					<Route path="/sidebar" element={<SideBar />} />
					<Route path="/loader" element={<LoadingComponent />} />
					<Route path="/B2AGrid" element={<B2AGridcomponent/>} />
		            <Route path="/B2AForm" element={<B2AformComponent/>} />
		            <Route path="/AWSB2A" element={<AWSB2AComponent />} />
		            <Route path="/AzureB2A" element={<AzureB2A />} />
		            <Route path="/RepoWorkBench" element={<RepoWorkbenchRender/>} />
		            <Route path="/Workbench" element={<WorkbenchRender  isDarkMode={isDarkMode} />} />
		            <Route path="/Estimation" element={<Estimation/>} />
			        <Route path="/ViewUserLogs" element={<ViewUserActivityLogs/>}/>
			        <Route path="/CostDashboard" element={<DashboardServiceProvider><CostDashboard/></DashboardServiceProvider>}/>
			        <Route path="/TemplateKBGrid" element={<TemplateKBGrid/>}/>
			        <Route path="/TemplateKBForm" element={<TemplateKbForm/>}/>
			        <Route path="/ManageUsers" element={<ManageUserGrid/>}/>
			        <Route path="/CICDDeploy" element={<CICDDeployment/>}/>
			        <Route path="/ArticleGrid" element={<ArticleGrid/>}/>
			        <Route path="/ViewArticle" element={<ViewArticle/>}/>
			        <Route path="/ArticleForm" element={<ArticleForm/>}/>
			        <Route path="/AddNewService1" element={<AddService />} />
			        <Route path="/AddNewServiceTest" element={<AddNewService1 />} />
			        <Route path="/SelfService" element={<SelfService />} />
			        <Route path="/DatabricksDashboard" element={<DatabricksContextProvider><DbuDashBoard /></DatabricksContextProvider>} />
			        <Route path="/Insights" element={<Insights />} />
			        <Route path="/InsightsView" element={<InsightsView />} />
			        <Route path="/UserManual1" element={<Usermanual1/>} />
			        <Route path="/" element={<Login  isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode}/>} />
			        <Route path="/Login" element={<Login  isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode}/>} />
			        <Route path="/ApprovalGrid" element={<ApprovalGrid />} />
			        <Route path="/Notification" element={<Notifications/>} /> 
			        <Route path="/ManageTemplates" element={<ManageTemplates/>} />
			        <Route path="/ServiceReview" element={<ServiceReviewAccordian/>} /> 
			        <Route path="/DeployModuleCodeEditor" element={<DeployModuleCodeEditor />} />
			        <Route path="/BranchingStrategy" element={<BranchingStrategy/>} />
			        <Route path="/RightSidebar" element={<RightSidebar/>} />
					<Route path="/DeployPopUp" element={<DeployPopUps/>} />
					<Route path='*' element={<NotFoundPage/>}/>
					<Route path= '/ServiceConfigurationMain' element={<ServiceConfigurationMain/>}/>
					<Route path= '/ModulesConfigGrid' element={<ModulesConfigGrid/>}/>
					<Route path= '/ImportServiceForm' element={<ImportServiceForm/>}/>
					<Route path='/EditServiceForm' element={<EditServiceForm/>}/>
					<Route path="/RightSidebar" element={<RightSidebar/>} />
					<Route path="/DataConfiguration" element={<DataConfiguration/>} />
					<Route path="/ChatBot" element={<ChatBot/>} />
					



					<Route path="/LocalsOutputsComponent" element={<LocalsOutputsComponent/>} />

			</Routes>
		</div> : <Routes>
		<Route path='/' element={<Login  isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode}/>}/>
		</Routes>
	);
}

function App() {
	return (
		<MsalProvider instance={msalInstance}>
		<Router>
			<FormProvider>
				<OrgProvider>
					<ModulesProvider>
						<EstimationProvider>
						<AppContent />
						</EstimationProvider>
					</ModulesProvider>
				</OrgProvider>
			</FormProvider>
		</Router>
		</MsalProvider>
	);
}

export default App;
