import { useEffect, useState,useContext } from "react";
import SideBar from "../../sidebar/SideBar";
import { getAllInvoicesApi } from "../../../Service/api";
import moment from "moment";
import Pagination from "../Pagination";
import Loader from "../../loader/loader";
import { OrgContext } from "../../../ContextProvider/OrgContext"; 
export default function InvoicesComponent({ isDarkMode, setIsDarkMode }){


    const [cloudProvider,setCloudProvider] = useState('AWS')
    const [awsInvoices,setAwsInvoices] = useState([])
    const [invoiceResponse,setInvoiceResponse] = useState([])
    const [billingDateFrom,setBillingDateFrom] = useState("") 
    const [billingDateTo,setBillingDateTo] = useState("")
    const [billingDateFromB,setBillingDateFromB] = useState("") 
    const [billingDateToB,setBillingDateToB] = useState("")
    const [sortColumn,setSortCOlumn] = useState("")
    const [sortOrder,setSortOrder] = useState("")
    const [searchWord,setSearchWord]= useState("")
    const [currentPage,setCurrentPage] = useState(1)
    const [lastPage,setLastPage] = useState(0)
    const [searchB,setSearchB] = useState('')
    const [dueDateFrom,setDueDateFrom] = useState('')
    const [dueDateFromB,setDueDateFromB] = useState('')
    const [dueDateTo,setDueDateTo] = useState('')
    const [dueDateToB,setDueDateToB] = useState('')
    const [status,setStatus] = useState('')
    const [statusB,setStatusB] = useState('')
    const [isLoading,setIsLoading] = useState(false)
    const [userId, setuserId] = useState(localStorage.getItem('userId'));

    const {
      userRoles,
      orgId,
      providerId,providerId2, setProviderId2,
      architectures,
      canCreateWorkload,
      canDeleteWorkload,
      canViewWorkload,
      setworkloadId,
      setuserRoles,setproviderId,setProviderName,workloadRole, setworkloadRole,
    
      // Add organization and provider fields here
      providerName, 
      orgData, 
      setOrgData, 
      setArchitectures,
    
      envId, // Environment ID
      workloadId, // Workload ID
      aiArchitecture, 
      viewMode, 
      envName, 
      language, 
      archType, 
      business, 
      rulebook, 
      archDesc, 
      services,isProjectAdmin, setIsProjectAdmin,
      setPulseIdentification,
      lastBuildID, 
      lastBuildDate, 
      lastBuildMessage,UTI, setUTI,
    
      repoId, 
      repoName,
    
      PulseOrganization_id,
      PulseVertical_id,
      PulseVertical_name,
      PulseOrganization_name,
      PulseIdentification, setEnvName,
      setSelectedLangforDownload,
      setEnvId // Function to update environment ID
    } = useContext(OrgContext);
    



    useEffect(() => {
        fetchInvoices()
    },[cloudProvider])


    const convertDate = (isoDateString) => {
        // Create a new Date object from the ISO string
        const date = new Date(isoDateString);
    
        // Format the date to MM/DD/YY using toLocaleDateString
        const formattedDate = date.toLocaleDateString('en-US', {
            year: '2-digit',   // Use 2-digit year
            month: '2-digit',  // Use 2-digit month
            day: '2-digit',    // Use 2-digit day
        });
    
        return formattedDate;
    };


    const fetchInvoices = async () => {
        setIsLoading(true)
        const payload = {
            userId : userId,
            orgId : orgId,
            cloudProvider: cloudProvider
        };
        try {

            setAwsInvoices([]);
            setInvoiceResponse([]);
            setBillingDateFrom("");
            setBillingDateTo("");
            setBillingDateFromB("");
            setBillingDateToB("");
            setSortCOlumn("");
            setSortOrder("");
            setSearchWord("");
            setCurrentPage(1);
            setLastPage(0);
            setSearchB("");
            setDueDateFrom("");
            setDueDateFromB("");
            setDueDateTo("");
            setDueDateToB("");
            setStatus("");
            setStatusB("");
            const response = await getAllInvoicesApi(payload);

            
           
            if (cloudProvider == 'AWS' && response.awsInvoices){
                let modifiedResponse = []
                for (let values of response?.awsInvoices){
                    let modifiedJson = {
                        billingPeriodFrom :  moment(values?.invoice_details?.billing_period.split(' to ')[0], "YYYY-MM-DD").format("MM/DD/YY"),
                        billingPeriodTo :moment(values?.invoice_details?.billing_period.split(' to ')[1], "YYYY-MM-DD").format("MM/DD/YY"),
                        dueAmount : values?.invoice_details?.due_amount,
                        s3Url : values?.S3Url
                    }
                    modifiedResponse.push(modifiedJson)
                }
                setInvoiceResponse(modifiedResponse)
            }
            else if (cloudProvider == 'Azure' && response.azureInvoices){
                let modifiedResponse = []
                for (let values of response?.azureInvoices[0]){
                    let modifiedJson = {
                       invoiceId : values.InvoiceId,
                       subscriptionId : values?.AccountId,
                       billingDateFrom : convertDate(values?.BillingPeriod.split(' to ')[0]),
                       billingDateTo : convertDate(values?.BillingPeriod.split(' to ')[0]),
                       dueDate : convertDate(values?.DueDate),
                       dueAmount : values?.DueAmount,
                       status : values?.Status,
                       s3Url : values?.S3InvoiceURL
                    }
                    modifiedResponse.push(modifiedJson)
                }
                setInvoiceResponse(modifiedResponse)
            }

            setIsLoading(false)
           
        } catch (error) {
            console.error('An error occurred while fetching invoices:', error);
        }
    };



    



    async function applyFilters() {
        try {
            if (cloudProvider == 'AWS') {
                let responseClone = [...invoiceResponse]; // Create a clone of the response to avoid mutating the original
                // 1. Apply date filters (if any)
                if (billingDateFrom || billingDateTo) {
                    responseClone = responseClone.filter(invoice => {
                        const billingFromDate = new Date(invoice.billingPeriodFrom); // Convert string to date
                        const billingToDate = new Date(invoice.billingPeriodTo);
                        const isAfterFrom = billingDateFrom ? billingFromDate >= new Date(billingDateFrom) : true;
                        const isBeforeTo = billingDateTo ? billingToDate <= new Date(billingDateTo) : true;
                        return isAfterFrom && isBeforeTo;
                    });
                }
                // 2. Apply search filter based on dueAmount (if any)
                if (searchWord) {
                    responseClone = responseClone.filter(invoice => {
                        // Search for the term in the dueAmount field
                        return invoice.dueAmount.toString().includes(searchWord);
                    });
                }
                // 3. Apply sorting (if any)
                if (sortColumn && sortOrder) {
                    responseClone = responseClone.sort((a, b) => {
                        let aValue = a[sortColumn];
                        let bValue = b[sortColumn];
        
                        // Ensure numeric sorting for 'dueAmount'
                        if (sortColumn === "dueAmount") {
                            aValue = parseFloat(aValue);
                            bValue = parseFloat(bValue);
                        }
        
                        // Compare values based on sortOrder (ascending or descending)
                        if (sortOrder === "asc") {
                            return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
                        } else {
                            return aValue > bValue ? -1 : aValue < bValue ? 1 : 0;
                        }
                    });
                }
                // Set the filtered and sorted data
                setAwsInvoices(responseClone);
    
                setLastPage(Math.ceil(responseClone.length / 10))
                // console.info(responseClone, "Filtered and Sorted Invoices");
        
            }else if (cloudProvider == 'Azure'){

                let responseClone = [...invoiceResponse]; // Clone the original response to avoid mutation

        // 1. Apply billing date filters (if any)
        if (billingDateFrom || billingDateTo) {
            responseClone = responseClone.filter(invoice => {
                const billingFromDate = new Date(invoice.billingDateFrom);
                const billingToDate = new Date(invoice.billingDateTo);

                const isAfterFrom = billingDateFrom ? billingFromDate >= new Date(billingDateFrom) : true;
                const isBeforeTo = billingDateTo ? billingToDate <= new Date(billingDateTo) : true;

                return isAfterFrom && isBeforeTo;
            });
        }

        // 2. Apply due date filters (if any)
        if (dueDateFrom || dueDateTo) {
            responseClone = responseClone.filter(invoice => {
                const dueFromDate = new Date(invoice.dueDate);
                const dueToDate = new Date(invoice.dueDate);

                const isAfterFrom = dueDateFrom ? dueFromDate >= new Date(dueDateFrom) : true;
                const isBeforeTo = dueDateTo ? dueToDate <= new Date(dueDateTo) : true;

                return isAfterFrom && isBeforeTo;
            });
        }

        // 3. Apply search filter on invoiceId (if any)
        if (searchWord) {
            responseClone = responseClone.filter(invoice => {
                // Check if invoiceId contains the search word (case-insensitive)
                return invoice.invoiceId.toLowerCase().includes(searchWord.toLowerCase());
            });
        }

        // 4. Apply status filter (if provided)
        if (status) {
            responseClone = responseClone.filter(invoice => {
                return invoice.status === status;
            });
        }

        // 5. Apply sorting (if sortColumn and sortOrder are provided)
        if (sortColumn && sortOrder) {
            responseClone = responseClone.sort((a, b) => {
                let aValue = a[sortColumn];
                let bValue = b[sortColumn];

                // Handle date sorting for billingDateFrom and dueDate
                if (sortColumn === "billingDateFrom" || sortColumn === "dueDate") {
                    aValue = new Date(aValue);
                    bValue = new Date(bValue);
                }

                // Handle numeric sorting for 'dueAmount'
                if (sortColumn === "dueAmount") {
                    aValue = parseFloat(aValue);
                    bValue = parseFloat(bValue);
                }

                // Handle string sorting for 'status'
                if (sortColumn === "status") {
                    aValue = aValue.toLowerCase();
                    bValue = bValue.toLowerCase();
                }

                // Compare values based on sortOrder (ascending or descending)
                if (sortOrder === "asc") {
                    return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
                } else {
                    return aValue > bValue ? -1 : aValue < bValue ? 1 : 0;
                }
            });
        }

                setAwsInvoices(responseClone)
                setLastPage(responseClone.length)
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    // console.info(invoiceResponse,"This is the invoice response data for binding the data")


    useEffect(()=>{
        if(invoiceResponse.length != 0){
            
            applyFilters()
        }
    },[invoiceResponse,sortOrder,sortColumn,searchWord,billingDateFrom,billingDateTo,dueDateFrom,dueDateTo,status])



    const bindHeaders = () =>{

       return  cloudProvider == 'AWS' ? 
            <tr> 
            
            <th className="position-sort-icon text-start border-0">
              Billing Period{" "}
              <span onClick={()=>{
                setSortCOlumn("billingPeriodFrom")
                setSortOrder(sortOrder == 'asc' ? 'desc' : 'asc')
              }}>
                <span className="cursor-pointer margin-left-2">
                  <img src="./images/table-sort.svg" alt="sort" style={{
      transform: (sortColumn === 'billingPeriodFrom' && sortOrder === 'asc') 
        ? "scaleY(-1)" 
        : "scaleY(1)",
      transition: "transform 0.1s ease-in-out"
    }} />
                </span>
              </span>
            </th>
            <th className="position-sort-icon text-end border-0">
            Due Amount{" "}
              <span  onClick={()=>{
                setSortCOlumn("dueAmount")
                setSortOrder(sortOrder == 'asc' ? 'desc' : 'asc')
              }}>
                <span className="cursor-pointer margin-left-2" on>
                  <img src="./images/table-sort.svg" alt="sort" style={{
      transform: (sortColumn === 'dueAmount' && sortOrder === 'asc') 
        ? "scaleY(-1)" 
        : "scaleY(1)",
      transition: "transform 0.1s ease-in-out"
    }}/>
                </span>
              </span>
            </th>
            <th className="text-center border-0">Action</th>
            </tr> :  <tr>
                    <th className="position-sort-icon text-start border-0">
                      <span>
                        Invoice ID
                        {/* <span class="cursor-pointer margin-left-2"><img
                                                          src="./images/table-sort.svg" alt="sort"></span> */}
                      </span>
                    </th>
                    <th className="text-start border-0">Subscription ID</th>
                    <th className="position-sort-icon text-start border-0">
              Billing Period{" "}
              <span onClick={()=>{
                setSortCOlumn("billingPeriodFrom")
                setSortOrder(sortOrder == 'asc' ? 'desc' : 'asc')
              }}>
                <span className="cursor-pointer margin-left-2">
                  <img src="./images/table-sort.svg" alt="sort" style={{
      transform: (sortColumn === 'billingPeriodFrom' && sortOrder === 'asc') 
        ? "scaleY(-1)" 
        : "scaleY(1)",
      transition: "transform 0.1s ease-in-out"
    }} />
                </span>
              </span>
            </th>
            <th className="position-sort-icon text-start border-0">
             Due Date{" "}
              <span onClick={()=>{
                setSortCOlumn("dueDate")
                setSortOrder(sortOrder == 'asc' ? 'desc' : 'asc')
              }}>
                <span className="cursor-pointer margin-left-2">
                  <img src="./images/table-sort.svg" alt="sort" style={{
      transform: (sortColumn === 'dueDate' && sortOrder === 'asc') 
        ? "scaleY(-1)" 
        : "scaleY(1)",
      transition: "transform 0.1s ease-in-out"
    }} />
                </span>
              </span>
            </th>
                    <th className="position-sort-icon text-end border-0">
            Due Amount{" "}
              <span  onClick={()=>{
                setSortCOlumn("dueAmount")
                setSortOrder(sortOrder == 'asc' ? 'desc' : 'asc')
              }}>
                <span className="cursor-pointer margin-left-2" on>
                  <img src="./images/table-sort.svg" alt="sort" style={{
      transform: (sortColumn === 'dueAmount' && sortOrder === 'asc') 
        ? "scaleY(-1)" 
        : "scaleY(1)",
      transition: "transform 0.1s ease-in-out"
    }}/>
                </span>
              </span>
            </th>
            <th className="position-sort-icon text-end border-0">
            Status{" "}
              <span  onClick={()=>{
                setSortCOlumn("status")
                setSortOrder(sortOrder == 'asc' ? 'desc' : 'asc')
              }}>
                <span className="cursor-pointer margin-left-2" on>
                  <img src="./images/table-sort.svg" alt="sort" style={{
      transform: (sortColumn === 'status' && sortOrder === 'asc') 
        ? "scaleY(-1)" 
        : "scaleY(1)",
      transition: "transform 0.1s ease-in-out"
    }}/>
                </span>
              </span>
            </th>
                    <th className="text-center border-0">Action</th>
                  </tr>
    }


    const bindBodyAWS = () => { 
        // Check if the cloud provider is AWS
        if (cloudProvider !== 'AWS') {
            return <></>;
        }
    
        // Calculate the range for pagination
        const itemsPerPage = 10;
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
    
        // Slice the invoices to show only the current page's items
        const invoicesToShow = awsInvoices.slice(startIndex, endIndex);
    
        // Return the table rows for the current page
        return invoicesToShow.map((values, index) => {
            return (
                <tr key={index}>
                    <td className="text-start">{values?.billingPeriodFrom} - {values?.billingPeriodTo}</td>
                    <td className="text-end">${values?.dueAmount}</td>
                    <td className="text-center">
                        <a href={values?.s3Url} download>
                            <img
                                src="images/upload-icon.svg"
                                alt="upload-icon"
                                className="w-18"
                            />
                        </a>
                    </td>
                </tr>
            );
        });
    };

    const bindbodyAzure = () => { 
        console.log("Inside the bind function!!!")
        // Check if the cloud provider is AWS
        if (cloudProvider !== 'Azure') {
            return <></>;
        }
    
        // Calculate the range for pagination
        const itemsPerPage = 10;
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
    
        // Slice the invoices to show only the current page's items
        const invoicesToShow = awsInvoices.slice(startIndex, endIndex);
    
        // Return the table rows for the current page
        return invoicesToShow.map((values, index) => {
            return (
                <tr>
    <td className="text-start">{values?.invoiceId}</td>
    <td className="text-start">{values?.subscriptionId}</td>
    <td className="text-start">{values?.billingDateFrom} - {values?.billingDateTo}</td>
    <td className="text-start">{values?.dueDate}</td>
    <td className="text-end">Rs.{values?.dueAmount}</td>
    <td className="text-center">
        <span className={`status ${values?.status == 'Due' ? 'inactive' : 'active'} me-2`}></span>{values?.status}
    </td>
    <td className="text-center">
        <a href={values.s3Url}>
            <img src="images/upload-icon.svg" alt="upload-icon" className="w-18" />
        </a>
    </td>
</tr>
            );
        });
    };
    
   

 
    return (
        <div className="container-fluid">
  <div className="row">
    <SideBar/>
    <div className="col-md-9 right-container-scroll">
    {
      isLoading ? <div className="container-backdrop d-flex align-items-center justify-content-center flex-column w-100">
      {/* Ensure the path to your GIF is correct */}
      <img src="images/Dots Loader 1.gif" alt="Loading..." className="loader-width" />
      {/* <p className="font-20 font-medium text-white mt-3">Loading...</p> */}
  </div> : 
    
   
      <div className="tab-content" id="pills-tabContent">
        {/* Workload start */}
        <div
          className="tab-pane fade show active"
          id="pills-Workloads"
          role="tabpanel"
          aria-labelledby="pills-Workloads-tab"
        >
          <div className="px-4 mt-4">
            {/* table-container heading start */}
            <div className="d-xl-flex justify-content-between align-items-center py-3">
              <h2 className="font-32 font-bold black-v2 mb-2 mt-xl-0">
                Invoices
              </h2>
              <div className="d-flex justify-content-center flex-nowrap gap-3">
                {/* dropdown */}
                <div className="dropdown">
                  <button
                    type="button"
                    className="btn custom-dropdown d-flex justify-content-between align-items-center gap-5
                                           font-12 font-regular color-black shadow-none"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="d-flex">
<img
    src={cloudProvider === 'Azure' ? 
        (isDarkMode ? 'images/azure-logo.svg' : 'images/azure-logo.svg') : 
        (isDarkMode ? 'images/aws-logo-dark.svg' : 'images/aws-logo.svg')} 
    alt={`${cloudProvider}-img`} 
    className="me-1 icon-width" 
/>
{" "}
                      {cloudProvider}
                    </span>
                    <img
                      src="images/drop-down-arrow.svg"
                      alt="dropdown-arrow"
                    />
                  </button>
                  <ul
                    className="dropdown-menu body-bg access-dropdown w-100"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li className="font-regular color-black font-12 p-2 custom-list" onClick={()=>{
                        setCloudProvider('AWS')
                    }}>
                      <span className="d-flex">
                        <img
                          src="images/aws-black-icon.svg"
                          alt="aws-img"
                          className="me-1 icon-width light-theme-img"
                        />
                        <img
                          src="images/aws-logo-dark.svg"
                          alt="aws-img"
                          className="me-1 icon-width dark-theme-img"
                        />{" "}
                        AWS
                      </span>
                    </li>
                    <li className="font-regular color-black font-12 p-2 custom-list" onClick={()=>{
                        setCloudProvider('Azure')
                    }}>
                      <span className="d-flex">
                        <img
                          src="images/azure-logo.svg"
                          alt="azure-logo"
                          className="me-1 icon-width"
                        />{" "}
                        Azure
                      </span>
                    </li>
                  </ul>
                </div>
                {/* dropdown */}
                {/* filter start */}
                <button
                  type="button"
                  className="filter-button px-2 position-relative"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                >
                  <img src="images/filter-blue.svg" alt="filter-icon" />
                </button>
                {/*filter*/}
                <div
                  className="dropdown-menu dropdown-menu-end border-0 custom-shadow dd-position background-white p-4 custom-filter-width"
                  aria-labelledby="dropdownMenuLink"
                >
                  <h5 className="font-medium font-16 mb-0 color-black mb-3">
                    Filter
                  </h5>
                  <div className="row">
                    
                    {cloudProvider == 'Azure' && <div className="col-md-12 mb-3">
                      <label
                        className="form-label font-14 font-medium black-v2"
                        htmlFor="Invoice-ID"
                      >
                        Invoice ID
                      </label>
                      <input
                        type="text"
                        id="Invoice-ID"
                        className="form-control custom-form px-3 py-2"
                        placeholder="Enter Invoice ID"
                        value={searchB}
                        onChange={(e)=>{
                            setSearchB(e?.target?.value)
                        }}
                      />
                    </div>}
                    {cloudProvider == 'Azure' && <div className="col-md-6 mb-3">
                      <label
                        className="form-label font-14 font-medium black-v2"
                        htmlFor="selectStatus"
                      >
                        Status
                      </label>
                      <select
                        className="form-select custom-dropdown shadow-none form-field-border py-1 font-14 font-regular black-v2 custom-select"
                        aria-label="Default select example"
                        id="selectStatus"
                        onChange={(e)=>{
                            setStatusB(e.target.value)
                        }}
                        value={statusB}
                      >
                        <option selected="">Select</option>
                        <option value={'Due'}>Due</option>
                        <option value={'Paid'}>Paid</option>
                      </select>
                    </div>}
                    <div className="col-md-12 mb-3">
                      <label className="font-14 font-medium black-v2">
                        Billing Period
                      </label>
                    </div>
                    <div className="col-md-6 mb-3 px-2">
                      <label
                        className="form-label font-14 font-medium label-color black-v2 form-text-color mb-1"
                        htmlFor="from"
                      >
                        From
                      </label>
                      <div className="position-relative">
                        <input
                          className="form-control custom-dropdown shadow-none py-1 font-14 font-regular black-v2"
                          type="date"
                          id="from"
                          placeholder="MM/DD/YYYY"
                          value={billingDateFromB}
                          onChange={(e)=>{
                            setBillingDateFromB(e.target.value)
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3 px-2">
                      <label
                        className="form-label font-14 font-medium label-color black-v2 form-text-color mb-1"
                        htmlFor="to"
                      >
                        To
                      </label>
                      <div className="position-relative">
                        <input
                          className="form-control custom-dropdown shadow-none py-1 font-14 font-regular black-v2"
                          type="date"
                          id="to"
                          placeholder="MM/DD/YYYY"
                          value={billingDateToB}
                          onChange={(e)=>{
                            setBillingDateToB(e.target.value)
                          }}
                        />
                      </div>
                    </div>
                    {cloudProvider == 'Azure' && <div className="col-md-12 mb-3">
                      <label className="font-14 font-medium black-v2">
                        Due Date
                      </label>
                    </div>}
                    {cloudProvider == 'Azure' && <div className="col-md-6 mb-4 px-2">
                      <label
                        className="form-label font-14 font-medium label-color black-v2 form-text-color mb-1"
                        htmlFor="fromnew"
                      >
                        From
                      </label>
                      <div className="position-relative">
                        <input
                          className="form-control custom-dropdown shadow-none py-1 font-14 font-regular black-v2"
                          type="date"
                          id="fromnew"
                          placeholder="MM/DD/YYYY"
                          onChange={(e)=>{
                            setDueDateFromB(e.target.value)
                          }}
                          value={dueDateFromB}
                        />
                      </div>
                    </div>}
                    {cloudProvider == 'Azure' && <div className="col-md-6 mb-4 px-2">
                      <label
                        className="form-label font-14 font-medium label-color black-v2 form-text-color mb-1"
                        htmlFor="tonew"
                      >
                        To
                      </label>
                      <div className="position-relative">
                        <input
                          className="form-control custom-dropdown shadow-none py-1 font-14 font-regular black-v2"
                          type="date"
                          id="tonew"
                          placeholder="MM/DD/YYYY"
                          onChange={(e)=>{
                            setDueDateToB(e.target.value)
                          }}
                          value={dueDateToB}
                        />
                      </div>
                    </div>}
                    <div className="col-md-12">
                      <div className="d-flex justify-content-end align-items-center gap-3">
                        <button className="button outline-button px-4"  onClick={()=>{
                             setBillingDateFrom('')
                             setBillingDateTo('')
                             setBillingDateFromB('')
                             setBillingDateToB('')
                             setSearchB('')
                             setSearchWord('')
                             setStatus('')
                             setStatusB('')
                             setDueDateFromB('')
                             setDueDateToB('')
                             setDueDateFrom('')
                             setDueDateTo('')
                        }}>
                          Clear All
                        </button>
                        <button
                          disabled=""
                          className="btn button primary-button px-3"
                          onClick={()=>{
                            setBillingDateFrom(billingDateFromB)
                            setBillingDateTo(billingDateToB)
                            if (cloudProvider == 'Azure'){
                                setSearchWord(searchB)
                                setDueDateFrom(dueDateFrom)
                                setDueDateTo(dueDateTo)
                                setStatus(statusB)
                            }
                          }}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/*filter*/}
                {/* filter end */}
                {/* search */}
                <div className="input-group input-search custom-border-box custom-search-box">
                  <input
                    type="search"
                    className="px-3 form-control common-bg border-0 custom-search $gray-v1"
                    placeholder="Search"
                    onChange={(e) => {
                        setSearchWord(e.target.value)
                    }}
                  />
                  <button
                    className="btn border-start-0  custom-search "
                    type="button"
                  >
                    <img src="images/search-icon.svg" alt="search" />
                  </button>
                </div>
                {/* search */}
              </div>
            </div>
            {/* table-container heading end */}
            {/* table start */}
            <div className="table-responsive mt-4 table-border">
              {awsInvoices.length != 0 && <table className="table custom-table">
                <thead>
                 {
                    bindHeaders()
                 }
                </thead>
                <tbody className="manage-work">
                  
                  {
                    cloudProvider == 'AWS' ? bindBodyAWS() : bindbodyAzure()
                  }
                </tbody>
              </table>}
              
               {awsInvoices.length == 0 && 
               <div className="d-flex flex-column justify-content-center align-items-center custom-height">
        <img src="images/nodata-found.svg" alt="No Data Found" />
        <h6 className="color-black font-bold font-16 mt-5 mb-0">No Data Found!</h6>
        <p className="font-medium font-12 mt-4 mb-0 secondary-text-color-v5">
            When invoices is there for your account that will be displayed here
        </p>
    </div>}
            </div>
            {/* table end */}
           <Pagination currentPage={currentPage} 
                       setCurrentPage = {setCurrentPage} 
                       totalItems={cloudProvider == 'AWS' ? awsInvoices.length : 10 }  
                       itemsPerPage = {10} 
                       displayedCount = {0}/>
          </div>
        </div>
        {/* Workload end */}
        
      </div>
     }
     </div>
  </div>
</div>

    )
}