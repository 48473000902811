import React, { useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { OrgContext } from '../../ContextProvider/OrgContext';

export function RepoWorkbenchNav() {
    const navigate = useNavigate();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState('Design'); // Default to 'Design'
    const { repoId } = useContext(OrgContext);

    useEffect(() => {
        // Update activeTab when location or repoId changes
        setActiveTab(getInitialActiveTab());
    }, [location.pathname, repoId]);

    function getInitialActiveTab() {
        if (location.pathname === '/RepoWorkBench') return 'Design';
        if (location.pathname === '/BranchingStrategy') return 'BranchingStrategy';
        if (location.pathname === '/CICDDeploy' && repoId) return 'CICD';
        return 'Design'; // Default to Design if no match or if repoId is not available
    }

    const handleTabClick = (tab, path) => {
        setActiveTab(tab);
        if (tab === 'CICD' && repoId) {
            navigate(path, { state: { repoId: repoId } });
        } else {
            navigate(path);
        }
    };  

    // ... rest of your component code ...

    return (
            <nav className="navbar navbar-expand-lg background-white custom-nav">
                <div className="container-fluid custom-container px-3">
                    <a className="navbar-brand" href="#">
                        <span className="d-flex">
                            <img src="images/logo.svg" alt="logo" className="header-logo light-theme-img" />
                            <img src="images/logo-dark.svg" alt="logo" className="header-logo dark-theme-img" />
                        </span>
                    </a>
                    <div className="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
                        <ul className="navbar-nav">
                            <li className={`nav-item custom-nav-item ${activeTab === 'Design' ? 'active' : ''}`}
                                onClick={() => handleTabClick('Design', '/RepoWorkBench')}>
                                <a className="nav-link" aria-current="page" href="#">Design</a>
                            </li>
                            <li className={`nav-item custom-nav-item ${activeTab === 'BranchingStrategy' ? 'active' : ''}`}
                                onClick={() => handleTabClick('BranchingStrategy', '/BranchingStrategy')}>
                                <a className="nav-link" aria-current="page" href="#">Branching Strategy</a>
                            </li>
                            <li className={`nav-item custom-nav-item ${activeTab === 'CICD' ? 'active' : ''}`}
                                onClick={() => handleTabClick('CICD', '/CICDDeploy')}>
                                <a className="nav-link" aria-current="page" href="#">CI/CD</a>
                            </li>
                        </ul>
                    </div>
                    <div className="btn-group">
                        <button type="button" className="button danger-button" onClick={() => navigate('/workloadsGrid')}>
                            <img src="images/button-exit.svg" alt="exit" className="me-1" /> Exit Workbench
                        </button>
                    </div>
                </div>
            </nav>
    
    );
}