
//PS_01 - Import statements
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useModuleContext } from '../../../ContextProvider/ModulesContxt';
import ImportModulesPopup from './ImportModulesPopup';
import { fetchModulesConfig } from '../../../Service/api';

//PS_02- Define component
const ModulesCards = (
  {
    modules, selectedProviderId, selectedProviderName,
    categoryData,
    setCategoryData,
    selectedCategory,
    setSelectedCategory,
    handleDelete,

  }) => {


    //PS_03- Get the context values
  const {

    selectedModule,
    setSelectedModule,
    userId,
    importModulePopup, setImportModulePopup

  } = useModuleContext()
  
//PS_04
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [editMode, setEditMode] = useState(false)
  const [deletePoup, setDeletePopup] = useState(false)
  const [moduleName, setModuleName] = useState("")
  const [moduleIdToDelete, setModuleIdToDelete] = useState("")


//PS_11- Triggers when module changes in renderCategories function
  useEffect(() => {
    renderCategories(modules)

  }, [modules]);


//PS_05-PS_10 - Handles the on clciking configure button
  const handleConfigureClick = async (moduleId) => {

    try {
      const response = await fetchModulesConfig(
        {
          "userId": userId,
          "moduleId": moduleId
        });

      console.log(response, "response for inputoutput")
      if (response.status === 200) {
        const data = response.data.data;
        //const data = response[0].data; // Accessing the first element of the response array

        // Destructure inputs and outputs into separate variables
        const { inputs: InputConfig, outputs: OutputConfig } = data;
        console.log(InputConfig, "InputConfig")
        console.log(OutputConfig, "OutputConfig")

        //   setModules(data);
        navigate('/ModulesConfigGrid', {
          state: {
            inputConfigurations: InputConfig,
            outputConfiguration: OutputConfig,
            moduleId: moduleId
          },
        });
      } else {
        setError(response.status);
      }
    } catch (err) {
      setError(err.message);
      console.log(error)
    }
  };

  //PS_13 - Handles the edit functionality
  const handleEditClick = (module, categoryId, categoryName) => {
    setSelectedModule({ ...module, categoryId, categoryName });
    setEditMode(true)
    console.log(selectedModule, "selectedModule when edit is clicked")
    setImportModulePopup(true)
    // Open your modal here, e.g. set showModal state to true
  };

  //PS_14 - renders the modules data
  const renderModules = (module, categoryId, categoryName) => {
    // Check if module is defined
    console.log(module , "Module Here for Rendering " , categoryName)
    if (!module) {
      console.error('Expected a module object but got:', module);
      return null; // or return some fallback UI
    }



    return (
      <div key={module.moduleId} className="col-md-3 mb-4">
        <div className="service-configurations-grid p-3 position-relative"
         style={module.custom == true ? {height:'100%'}:{}}>
          <div className="d-flex align-items-center gap-3">
            <img
              className="partner-logo-circle grid-blur"
              src={module.imageUrl}
              alt="partner-logo"
              width="32"
              height="32"
            />
            <div className="d-flex flex-column gap-2">
              <p className="font-18 font-bold black-v2 mb-0 partner-name grid-blur">
                {module.moduleName}
              </p>
              <span className="font-12 font-medium secondary-text-color-v1 grid-blur">
                Imported: <span>{module.createdTime?.slice(0, 22)}</span>
              </span>
            </div>
          </div>
          {module.custom == false ? (
             <div className="mt-4 d-flex flex-column gap-2">
             <p className="font-11 font-medium secondary-text-color-v1 mb-0 grid-blur text-truncate">
               Source: <span>{module.source}</span>
             </p>
             <p className="font-11 font-medium secondary-text-color-v1 mb-0 grid-blur">
               Target Version: <span>{module.targetVersion}</span>
             </p>
             <p className="font-11 font-medium secondary-text-color-v1 mb-0 grid-blur">
               Current Version: <span>{module.currentVersion}</span>
             </p>
           </div>

          ):
          (
            <div className="mt-4 d-flex flex-column gap-2">
            <p className="font-11 font-medium secondary-text-color-v1 mb-0 grid-blur text-truncate">
              GitUrl: <span>{module.gitUrl}</span>
            </p>
            </div>

          )}
          {/* Add action buttons here */}

          <div className="grid-options">
            <a
              href="#"
              className="color-black font-13 font-medium text-decoration-none d-block mb-2"
              // data-bs-toggle="modal"
              // data-bs-target="#staticBackdrop-21"
              onClick={() => {

                handleEditClick(module, categoryId, categoryName)
              }
              }

            >
              <img
                src="images/articles-edit-icon-blue.svg"
                alt="edit-icon"
                className="articles-option-icons me-2"

              />
              Edit
            </a>
            <a
              href="#"
              className="color-black font-13 font-medium text-decoration-none d-block mb-2"
              onClick={
                () => {
                  console.log("enteredwith moduleId", module.moduleId)

                  handleDeletePopUp(module.moduleId, module.moduleName)
                }}
            >
              <img
                src="images/articles-delete-icon.svg"
                alt="delete-icon"
                className="articles-option-icons me-2"
              />
              Delete
            </a>
            <a
              href="#"
              className="color-black font-13 font-medium text-decoration-none d-block mb-2"
              onClick={(() => {
                handleConfigureClick(module.moduleId)
              })}
            >
              <img
                src="images/configure.svg"
                alt="gear-icon"
                className="articles-option-icons me-2"
              />

              Configure
            </a>
          </div>
        </div>
      </div>

    );
  };



//PS_11-PS_12
const renderCategories = (categories) => {
  return categories.map((category) => (
    <div key={category.categoryId}>
      <h2 className="font-18 font-bold color-black">{category.categoryName}</h2>
      <div className="row mt-3 service-configurations-section">
        {/* Assuming each category has a modules array */}
        {/* {category.module && category.module.map((module, index) => (
          <div key={`${module.moduleId}_${index}`}>
            {renderModules(module, category.categoryId, category.categoryName)}
          </div>
        ))} */}
             {category.module && category.module.map((module) => (
          renderModules(module, category.categoryId, category.categoryName)
        ))}
      </div>
    </div>
  ));
};

//PS_16-PS_18
  function handleDeletePopUp(moduleId, moduleName) {
    setDeletePopup(true);
    setModuleName(moduleName);
    // Store the serviceId for later use
    setModuleIdToDelete(moduleId);
  }


  // /PS_19
  const closeDeletePopup = () => {
    setDeletePopup(false)

  }



  //PS_20
  return (
    <>

      <div className="row">
        {modules.length === 0 ? (
          <tr>
            <td colSpan="8" className="no-record-cell">
              <div className="no-record-found" style={{marginTop:'8%'}}>
                <img src="images/no-records-found.png" alt="No records found" className="no-record-icon" />

              </div>
            </td>
          </tr>
        ) : (
          modules && renderCategories(modules)
        )}
      </div>
      {/* {modules && renderCategories(modules)} */}

      {selectedModule ?
        <ImportModulesPopup
          module={selectedModule}
          setModule={setSelectedModule}
          selectedProviderId={selectedProviderId}
          selectedProviderName={selectedProviderName}
          categoryData={categoryData}
          setCategoryData={setCategoryData}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          editMode={editMode}
          setEditMode={setEditMode}

        /> : <>
          <ImportModulesPopup
            module={selectedModule}
            setModule={setSelectedModule}
            selectedProviderId={selectedProviderId}
            selectedProviderName={selectedProviderName}
            categoryData={categoryData}
            setCategoryData={setCategoryData}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            editMode={editMode}
            setEditMode={setEditMode}

          />

        </>}


      {deletePoup && (
        <div
          className="modal fade show"
          id="staticBackdrop-25"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden={!deletePoup}
          style={{ display: 'block', backgroundColor: "rgba(0,0,0,0.6)" }}
        >
          <div className="modal-dialog modal-dialog-centered justify-content-center">
            <div className="modal-content border-0">
              <div className="modal-header d-flex justify-content-end border-0 pb-0 ">
                <button
                  type="button"
                  className="btn shadow-none"
                  //data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={closeDeletePopup}
                >
                  <img
                    src="images/close-icon.svg"
                    alt="close-icon"
                    className="icon-hover shadow-none modal-close-filter"
                  />
                </button>
              </div>
              <div className="modal-body text-center pt-0 p-32">
                <img
                  src="images/delete-organization-icon.svg"
                  alt="delete the Organization"
                />
                <h6 className="font-20 font-bold black-v2 mt-4 pt-2">
                  You’re about to delete the Service
                </h6>
                <p className=" font-16 font-regular secondary-text-color-v5 mt-3 line-height-32 mb-0">
                  "{moduleName}"
                </p>
                <p className=" font-16 font-regular secondary-text-color-v5 mt-2 line-height-32">
                  Do you want to proceed?
                </p>
                <div className="d-flex justify-content-center gap-3 align-items-center mt-4 pt-3">
                  <button
                    type="button"
                    className="button outline-button text-nowrap px-32"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      handleDelete(moduleIdToDelete);
                      closeDeletePopup();
                    }}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className="button primary-button popup-btn px-32"
                    onClick={closeDeletePopup}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>

  );
};

export default ModulesCards;