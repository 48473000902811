import React, { useMemo, useState, useRef, useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as base64 from "base-64";
import * as htmlToImage from "html-to-image";
import "../../../../Components/ComponentCSS/workbench.css"
// import "../../../ComponentCSS/reactflow.css"
import RepoSideBarComponent from "./RepoSideBarComponent";

import {
    ReactFlow,
    ReactFlowProvider,
    Background,
    useNodesState,
    useEdgesState,
    addEdge,
    Controls,
    Handle,
    MarkerType,
    NodeResizer,
    useReactFlow,
    applyNodeChanges,
    applyEdgeChanges

} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import moment from "moment";

import Draggable from "react-draggable";
import RepoConfigureComponent from "./RepoConfigureComponent";
import { useOrgContext } from "../../../../ContextProvider/OrgContext";
import { getarchitectureByIDApi, saveArchietctureApi } from "../../../../Service/api";
import { RepoWorkbenchNav } from "../../RepoWorkBenchNav";
import WorkbenchLoader from "../WorkBenchLoader";


function RepoWorkbench() {

    const { 
        orgId, 
        
        envId,
        setEnvId,
        workloadId,
        providerId,
        providerName,
        aiArchitecture,
        envName,
        language,
        archType,
        business,
        rulebook,
        archDesc,
        services,
        repoId,
        repoName,
        colorMode,
        workloadRole
         
         
    } = useOrgContext();
    const [userId, setuserId] = useState(localStorage.getItem('userId'));
    console.log('Organization ID:', orgId);
	const [viewMode , setViewerMode] = useState(workloadRole == "Viewer" ? true : false)
  
    /**
     * React Flow  Variables Intialization
     */
    // React Flow Some Times Throws the ResizeObserver loop completed with undelivered notifications" ||
    //"ResizeObserver loop limit exceeded
    //TO Remove that  use this Below Code 
    useEffect(() => {
        const errorHandler = (e) => {
            if (
                e.message.includes(
                "ResizeObserver loop completed with undelivered notifications" ||
                    "ResizeObserver loop limit exceeded"
                )
            ) {
                const resizeObserverErr = document.getElementById(
                "webpack-dev-server-client-overlay"
                );
                if (resizeObserverErr) {
                resizeObserverErr.style.display = "none";
                }
            }
            };
            window.addEventListener("error", errorHandler);
        
            return () => {
            window.removeEventListener("error", errorHandler);
            };
    }, []);

    const initialNodes = [];
    let state = {"provider":"AWS"}

    const initialEdges = [];
    const reactFlowWrapper = useRef(null);
    const { screenToFlowPosition } = useReactFlow();
    const [nodes, setNodes] = useNodesState(initialNodes);
    const [edges, setEdges] = useEdgesState(initialEdges);
    const reactFlowInstance = useReactFlow();
    /**
     * Draggable Stack Maintance for the Undo and Redo funcionalites
     */
    const [undoStack, setUndoStack] = useState([]);
    const [redoStack, setRedoStack] = useState([]);
    /**
     * Workbench State Variables 
     */
    const [configureDetails, setConfigureDetails] = useState({ id: "" });

    const [accountStatus, setAccountStatus] = useState({});

    const [stateForCount, setStateForCount] = useState([]);
 
    const [userSelectedRepo, setUserSelectedRepo] = useState({
        "repository_id":repoId,
        "repository_name":repoName
    });
    const [selectedEnv , setSelectedEnv] = useState({ 
        environment_id :envId,
        environment_name :envName})
    const navigate = useNavigate();
    const[isToastPopupOpen , setIsToastPopUpOpen] =  useState(false)
    const [istoastMessage , setToastMessage] = useState({success :"" , failure :""})
    const [showLoader, setShowLoader] = useState(false)

    
    // Workbench Functionalities 

    // UseEffect 
    useEffect(()=>{
        getArchitectureById()
    },[envId])
    // Change of Architecture when the user Switches from Once Architecture to Another
    useEffect(()=>{
        if(selectedEnv.environment_id != envId 
                && 
                selectedEnv.environment_id
                ){
                setEnvId(selectedEnv.environment_id)
        }
    },[selectedEnv])
    
    /**
     * Save Architecture Function Which is Triggered on the every action Performed in the Workbench allowing 
     * the user to save all the architecture Drafted 
    */
    async function saveArchitecture(params) {
    try {
        console.log(nodes , "Nodesss")
        let nodeData = {
               nodes,
               edges
              };
        console.log(nodeData , "NodeData")
        if(nodeData["nodes"].length!=0){
            let payload  = {
                userId :userId,
                orgId:orgId,
                envId: envId,
                xml:JSON.stringify(nodeData)
            }
            console.log(payload, "Payload in Save Architecture")
            let res  = await saveArchietctureApi(payload)
            console.log(res ,"Response from Save architecture")
            if(params == 0) {
                console.log("Saveed Arch")
            }
        } 
       
    }
    catch(error) {
        console.log(error)
    }}
    /**
     * Get Architecture By Id Function Triggered Once the user is Navigated to Workbench 
    */
    
    async function getArchitectureById() {
        console.log("Triggering the GetArchitectureByID");
        if (envId !== "") {
          let payload = {
            userId,
            orgId,
            envId,
          };
    
          try {
            console.log(payload, "in Get Architecture");
            setShowLoader(true)
            let jsonData = await getarchitectureByIDApi(payload);
            setShowLoader(false)

            console.log(jsonData, "JSON Data");
    
            if (jsonData.success) {
              let parsedJson = JSON.parse(jsonData.data);
              console.log("parsedjson", parsedJson);
    
              const processedNodes = parsedJson.nodes.map(node => {
                const nodeElement = createNodeElement(node);
                return {
                  ...node,
                  type: nodeElement.type,
                  style: nodeElement.style,
                  data: {
                    ...node.data,
                    element: nodeElement.element,
                  },
                };
              });
              console.log(processedNodes, "Processed Nodes");
              setNodes(processedNodes);
              setEdges(parsedJson.edges);
             
            } else {
              console.error("Failed to fetch architecture data");
            }
          } catch (error) {
            console.error("Error in getArchitectureById:", error);
          }
        } else {
          console.error("envId is empty");
        }
      }

 
    // Helper function to create the node element
    function createNodeElement(node) {
        let isLocation = ["Account",
            "Region",
            "VPC", 
           "Availability_Zone",
            "Subnet",
            "Security_group" , 
           "Resource_Group" , 
           "VNET"].includes(node.data.serviceName);
   
        if(node.type == "location"){
               isLocation = true
           }
      let nodeStyle = {
        position: "absolute",
        zIndex: node.data.serviceName === "VPC" ? "-100000" : "100010",
      };
    
      if (isLocation) {
        const zIndexMap = {
          "Account": "-100005",
          "Region": "-100004",
          "VPC": "-100003",
          "Availability_Zone": "-100002",
          "Subnet": "-100001",
          "Security_group": "-100000"
        };
    
        const dimensionsMap = {
          "Account": 400,
          "Region": 350,
          "VPC": 300,
          "Availability_Zone": 250,
          "Subnet": 200,
          "Security_group": 150
        };
        let styleData =  node.style
        nodeStyle = {
          ...nodeStyle,
          ...styleData
        };
      }
    
      const imageClassName = isLocation ? "vpcImageStyle" : "img";
      console.log(node?.data.ConfigureDetail?.repositoryData?.repository_id  ,userSelectedRepo?.repository_id ,"kksfsd" , node)
      return {
        element: (
            <div className="container-hover"
            onMouseEnter={(e) => {
                // Show tooltip
                const tooltip = document.createElement('div');
                tooltip.innerHTML = `<strong>${node.data.serviceName}</strong><br>${node.data.description}`;
                tooltip.style.position = 'fixed';
                tooltip.style.top = `${e.clientY + 10}px`; // Position below cursor
                tooltip.style.left = `${e.clientX + 10}px`; // Position right of cursor
                tooltip.style.backgroundColor = 'black';
                tooltip.style.color = 'grey';
                tooltip.style.padding = '8px';
                tooltip.style.borderRadius = '4px';
                tooltip.style.zIndex = '100';
                tooltip.style.whiteSpace = 'pre-wrap';
                tooltip.style.maxWidth = '200px'; // Adjust as needed
                tooltip.className = 'custom-tooltip'; // Add class for additional styling
                document.body.appendChild(tooltip);
              }}
            onMouseLeave={() => {
                // Hide tooltip
                const tooltips = document.getElementsByClassName('custom-tooltip');
                while (tooltips.length > 0) {
                  tooltips[0].parentNode.removeChild(tooltips[0]);
                }
              }}
              style = {
                node?.data?.repositoryData?.repository_id &&
                node.data?.repositoryData?.repository_id == userSelectedRepo?.repository_id  ?
                {filter: 'blur(0px)' }  :   {filter: 'blur(3px)' }  
            }
            >
              <img
                id={node.id}
                width={25}
                height={25}
                src={node.data.imageName}
                className={imageClassName}
                alt="service-logo"
              />
            </div>
        ),
        style: nodeStyle,
        type: isLocation ? "location" : "service"
      };
    }

    /**
     * ^^^^^^^^^^^^ Node Funtionalities Handler Start  ^^^^^^^^^^^^^^^^^^^
     */
    /**
     * Stack Maintance for the Node Changes 
     * Used for Undo and Redo Operation
     */
    const onNodesChange = useCallback(
        (changes) => {
            setNodes((nds) => {
                const newNodes = applyNodeChanges(changes, nds);
                // pushToUndoStack(newNodes, edges);
                return newNodes;
            });

        },
        [edges]
    );
    /**
     * Stack Maintance for the Edges Changes 
     * Used for Undo and Redo Operation
     */
    const onEdgesChange = useCallback(
        (changes) => {
            setEdges((eds) => {
                const newEdges = applyEdgeChanges(changes, eds);
                pushToUndoStack(nodes, newEdges);
                return newEdges;
            });
           
        },
        [nodes]
    );
    /**
     * SerivceBind function  is to render the node in service node custom Type
     */
    const serviceBind = ({ data, id }) => {
        console.log(id, "id Herere")
        console.log(data, "Data Here!")
        return (
            <>

                <div
                // style={{ position: 'relative', border: '2px solid #000', padding: '10px', borderRadius: '5px' }}
                >
                    <span id={id + "1Source"}>
                        <Handle id={`${id}leftSource`}
                            type="source" position="left"
                        />
                        <Handle id={`${id}topSource`} type="source" position="top" />
                    </span>
                    <span id={id + "1Target"}>
                        <Handle id={`${id}leftTarget`} type="target" position="left" />
                        <Handle id={`${id}topTarget`} type="target" position="top" />
                    </span>
                    <div style={{ position: "relative", margin: "-1px 0px 0px 0px" }}>
                        {data.element}
                    </div>
                    <label
                        style={{ position: "absolute", color: "black", color: colorMode === "dark" ? "#FFFFFF" : "black" }}
                        className="nodeLabel"
                        id={id + "@nodeLabel"}

                    >
                        {data.imageServiceName}
                    </label>
                    <span id={id + "2Source"}>
                        <Handle id={`${id}rightSource`} type="source" position="right" />
                        <Handle id={`${id}bottomSource`} type="source" position="bottom" />
                    </span>
                    <span id={id + "2Target"}>
                        <Handle id={`${id}rightTarget`} type="target" position="right" />
                        <Handle id={`${id}bottomTarget`} type="target" position="bottom" />
                    </span>
                </div>
            </>
        );
    };

    /**
    * LocationBind function  is to render the node in service node custom Type
     */
    const locationBind = ({ data, id }) => {
        console.log(data, id, "Here")
        return (
            <>
                <NodeResizer style={{ "border": 'unset'}} />
                <div className={`locationMain ${id}`}>
                    <div
                        className="container-hover"
                        id={id + "@location"}
                        style={{
                            display: "flex",
                            padding: "11px 10px",
                        }}
                    >
                        {data.element}
                        <label
                            className="locationLabel"
                            id={id + "@nodeLabel"}
                            style={{ color: "black", color: colorMode === "dark" ? "#FFFFFF" : "black" }}

                        >
                            {data.imageServiceName}
                        </label>
                    </div>
                </div>
            </>
        );
    };

    /**
     * PC_WB_10
     */
    const nodeTypes = useMemo(
        () => ({
            service: serviceBind,
            location: (props) => locationBind(props, nodes),
        }),
        []
    );

    /**
     * Edges Connection is Handled Below 
     * Handled the Step Connection of the edges 
     */
  
    
    const onConnect = useCallback((params) => {
        console.log(params, "Params in Edges");
        
        // Ensure the source and target are in the correct order
        const { source, target, sourceHandle, targetHandle } = params;
        
        let edgeStyle = {
            id: `${source}${sourceHandle}${target}${targetHandle}${Math.random()}`,
            source,
            target,
            sourceHandle,
            targetHandle,
            configure: {},
            type: 'step',
            labelBgBorderRadius: 4,
            labelBgStyle: { color: '#fff', fillOpacity: 0.7 },
            markerEnd: {
                type: MarkerType.Arrow,
            },
        };
        
        setEdges((prevEdges) => [...prevEdges, edgeStyle]);
    
        
    }, [setEdges]);

    useEffect(()=>{
        console.log(edges , "Edgesss")
    },[edges])

    /**
     * PC_WB_09
     * onDragover function will be called when the user drags the nodes over the workbench area;
     */
    const onDragOver = useCallback((event) => {
        console.log("Drag Over Triggered ")
        event.preventDefault();
        event.dataTransfer.dropEffect = "move";

    }, []);
    /** Handling of Node Delete Scenario  */
    const onNodesDelete = useCallback(
        (deletedNodes) => {
            const deletedNodeIds = deletedNodes.map(node => node.id);
            // Remove the Tool Tip 
            const tooltips = document.getElementsByClassName('custom-tooltip');
            while (tooltips.length > 0) {
            tooltips[0].parentNode.removeChild(tooltips[0]);
            }
            setNodes((nds) => {
                const newNodes = nds.filter(node => !deletedNodeIds.includes(node.id));
                pushToUndoStack(newNodes, edges);
                return newNodes;
            });
            setEdges((eds) => {
                const newEdges = eds.filter(edge => !deletedNodeIds.includes(edge.source) && !deletedNodeIds.includes(edge.target));
                pushToUndoStack(nodes, newEdges);
                return newEdges;
            });
            
            
        },
        [nodes, edges]
    );
    /**
     * PC_WB_08
     * the onDrop function will be called when the user drops a node in the wokbench;
     */
    
    const onDrop = useCallback(
        (event) => {
            event.preventDefault();
            
            let serviceData;
            // Log the data being transferred
            const jsonData = event.dataTransfer.getData('application/json');
            const childName = event.dataTransfer.getData('application/childName');
            console.log("Data from transfer:", { jsonData, childName });
    
            // Attempt to parse JSON data
            if (jsonData) {
            try {
                serviceData = JSON.parse(jsonData);
                console.log("Parsed serviceData:", serviceData);
            } catch (error) {
                console.log("JSON parsing failed:", error);
            }
            }
    
            // If no serviceData was parsed, check for Account node
            if (!serviceData && childName === 'Account') {
            console.log("Account node detected");
            serviceData = {
                id: 'account-node-' + Math.random().toString(36),
                childName: 'Account',
                parentName: 'Account',
                nodeType: 'location',
                imageName: './images/Workbench/ServiceImages/AWS/Security/AWS-Organizations_Account_light-bg.png',
                serviceName: 'Account',
                containsService: true,
            };
            console.log("Created Account serviceData:", serviceData);
            } else if (!serviceData) {
            console.log("No valid service data found in the drop event");
            return; // Early exit if no valid data
            }
    
            // Calculate drop position
            const position = screenToFlowPosition({
            x: event.clientX,
            y: event.clientY,
            });
            console.log("Calculated position:", position);
    
            let nodeDataForCount = [...stateForCount];
            let countForLabel = nodeDataForCount.filter(data => data === serviceData.parentName).length + 1;
            let labelName = `${serviceData.parentName} ${countForLabel}`;
            let imageServiceName = `${serviceData.serviceName}_${countForLabel}`
    
            setStateForCount(prev => [...prev, serviceData.parentName]);
    
            const isAccount = serviceData.serviceName === "Account";
            
            // Determine node identity
            let nodeIdentity = serviceData.containsService ? "location" : "service";
            
            let htmlElement = "";
            let nodeStyle = {
            position: "absolute",
            zIndex: serviceData.serviceName === "vpc" ? "-100000" : "100010",
            };
    
            // Set node style based on service name location Binding Purposes
            if (["Account", "Region", "VPC", "Availability_Zone", "Subnet", "Security_group"].includes(serviceData.serviceName)) {
            const zIndexMap = {
                "Account": "-100005",
                "Region": "-100004",
                "VPC": "-100003",
                "Availability_Zone": "-100002",
                "Subnet": "-100001",
                "Security_group": "-100000"
            };
    
            const dimensionsMap = {
                "Account": 400,
                "Region": 350,
                "VPC": 300,
                "Availability_Zone": 250,
                "Subnet": 200,
                "Security_group": 150
            };
    
            nodeStyle = {
                position: "absolute",
                zIndex: zIndexMap[serviceData.serviceName] || "100010",
                width: dimensionsMap[serviceData.serviceName],
                height: dimensionsMap[serviceData.serviceName],
                className: "resizeLoop",
                color: getColorForService(serviceData.serviceName)
            };
            nodeIdentity = "location"
            }
    
            // Set HTML element based on node identity
            switch (nodeIdentity) {
        
            default:
                htmlElement = (
                <div>
                    <div className="container-hover"
                     onMouseEnter={(e) => {
                        // Show tooltip
                        const tooltip = document.createElement('div');
                        tooltip.innerHTML = `<strong>${serviceData.serviceName}</strong><br>${serviceData.serviceDR}`;
                        tooltip.style.position = 'fixed';
                        tooltip.style.top = `${e.clientY + 10}px`; // Position below cursor
                        tooltip.style.left = `${e.clientX + 10}px`; // Position right of cursor
                        tooltip.style.backgroundColor = 'black';
                        tooltip.style.color = 'grey';
                        tooltip.style.padding = '8px';
                        tooltip.style.borderRadius = '4px';
                        tooltip.style.zIndex = '100';
                        tooltip.style.whiteSpace = 'pre-wrap';
                        tooltip.style.maxWidth = '200px'; // Adjust as needed
                        tooltip.className = 'custom-tooltip'; // Add class for additional styling
                        document.body.appendChild(tooltip);
                      }}
                      onMouseLeave={() => {
                        // Hide tooltip
                        const tooltips = document.getElementsByClassName('custom-tooltip');
                        while (tooltips.length > 0) {
                          tooltips[0].parentNode.removeChild(tooltips[0]);
                        }
                      }}
                    
                    >
                 
                    <img
                        id={serviceData.id}
                        width={25}
                        height={25}
                        src={`${serviceData.imageName}`}
                        className={["VPC", "Account", "Availability_Zone", "Region", "Subnet", "Security_group"].includes(serviceData.serviceName) ? "vpcImageStyle" : "img"}
                        alt="service-logo"
                    />
                    </div>
                </div>
                );
                break;
            }
    
            // Construct the new node
            
            const newNode = {
              id: serviceData.id,
              type: nodeIdentity,
              position: position,
              style: nodeStyle,
              data: {
                element: htmlElement,
                resourceName: isAccount ? isAccount : labelName,
                serviceName: serviceData.serviceName,
                imageName: serviceData.imageName,
                imageServiceName :imageServiceName,
                fields: serviceData.fields || [],
                ConfigureDetail: {
                  subServieConfig: {
                    Action: {
                      create: {},
                      delete: {},
                      update: {}
                    },
                    type: "create"
                  }
                },
                description: serviceData.description || '',
                children: isAccount ? [] : undefined,
                repositoryData: {
                repository_name: "",
                repository_id: "",
                  branchData: {
                    branch_id: "",
                    branch_name: ""
                  }
                },
                Tags: [
                  {
                    "key": "cloudgen",
                    "value": true,
                    defaultTag: true
                  },
                  {
                    "key": "cloudgen",
                    "value": true,
                    defaultTag: false
                  }
                ]
              }
            };
            if(serviceData.serviceName == "Account"){
            // Account Detials Data Maintance 
            newNode["data"]["ConfigureDetail"] = {
                accountId :"",
                accountName:"",
                cloudproviderId:""
            }
            }
    
            // Update nodes state
            setNodes((nds) => {
            const newNodes = nds.concat(newNode);
            pushToUndoStack(newNodes, edges);
            return newNodes;
            });
        },
        [reactFlowInstance, stateForCount, accountStatus, state.provider, edges]
        );
    useEffect(()=>{
        console.log(nodes , "Nodesss")
    },[nodes])
    // Helper function to get color for service
    function getColorForService(serviceName) {
    const colorMap = {
        "VPC": "#8C4FFF",
        "Account": "#CD2264",
        "Availability_Zone": "#6610f2",
        "Region": "#00A4A6",
        "Subnet": "#0d6efd",
        "Security_group": "#DD3522"
    };
    return colorMap[serviceName] || "#000000";
    }

    /**
     * PC_WB_12
     * the following function will be called when the user double click on the
     * Node
     */
    
    const doubleClickLabel = (event, node) => {
        console.log("Node double-clicked:", node);
        console.log("Node type:", node.type);
        console.log("Node resourceName:", node.data.resourceName);
        // To Handle Only the Repo Tagged node to be selected 
        if(node.data?.repositoryData?.repository_id == userSelectedRepo?.repository_id){
         setConfigureDetails(node);
         }
        console.log("Edges in Double Clik", edges); 
      
    
        // You can do something with the connectedSourceNodes here,
        // such as setting them in state or passing them to another function
    }

    /**
     * ^^^^^^^^^^^^ Node Funtionalities Handler End  ^^^^^^^^^^^^^^^^^^^
     */
    /**
     * ^^^^^^^^^^^^ Draggable Funtionalities Handler Started  ^^^^^^^^^^^^^^^^^^^
     */
    const pushToUndoStack = (newNodes, newEdges) => {
        setUndoStack((prev) => [...prev, { nodes: newNodes, edges: newEdges }]);
        setRedoStack([]); // Clear redo stack on new action
    };


    const unDoFunction = () => {
        if (undoStack.length === 0) return;

        const lastState = undoStack[undoStack.length - 1];
        setRedoStack((prev) => [...prev, { nodes, edges }]);
        setNodes(lastState.nodes);
        setEdges(lastState.edges);
        setUndoStack((prev) => prev.slice(0, -1));
        
    };
    // Redo Function in the Draggable
    const reDoFunction = () => {
        if (redoStack.length === 0) return;

        const lastState = redoStack[redoStack.length - 1];
        setUndoStack((prev) => [...prev, { nodes, edges }]);
        setNodes(lastState.nodes);
        setEdges(lastState.edges);
        setRedoStack((prev) => prev.slice(0, -1));
    };

    /**
     * XML Donwload Functionality
     */
    function XMLDownload() {
        let nodeData = {
            nodes: nodes,
            edges: edges,
        };

        let stringifyNode = JSON.stringify(nodeData);
        stringifyNode = stringifyNode.replace(/&/g, "#@#@#");
        let architecutureName = "Test"
        let provider = "AWS"
        let technology = "Terraform"
        download(
            `${architecutureName}.xml`,
            `<?xml version="1.0" encoding="UTF-8" ?><cloudgen><${provider + "-" + technology
            }>` +
            stringifyNode +
            `</${provider + "-" + technology}></cloudgen>`
        );
    }

    /** 
     * XML Download Function 
    */
    function download(filename, text) {
        //console.log("xmlText", text);

        var element = document.createElement("a");
        element.setAttribute(
            "href",
            "data:text/plain;charset=utf-8," + encodeURIComponent(text)
        );
        element.setAttribute("download", filename);

        element.style.display = "none";
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }
    // DownLoadImage Function Download the Architecture Image in the specifed format 
    
    async function DownLoadImage(type) {
        try {
            document.getElementById("fittoscreen").click();

            const architectureElement = document.getElementById("myArchitecture");
            architectureElement.style.backgroundColor = "white";

            let dataUrl;
            if (type === "Jpeg") {
                dataUrl = await htmlToImage.toJpeg(architectureElement);
            } else if (type === "Png") {
                dataUrl = await htmlToImage.toPng(architectureElement);
            } else {
                throw new Error("Unsupported image type");
            }

            const link = document.createElement("a");
            link.download = `${envName}.${type.toLowerCase()}`;
            link.href = dataUrl;
            link.click();

            setTimeout(() => {
                architectureElement.style.backgroundColor = "transparent";
            }, 3000);
        } catch (error) {
            console.error(`Error in downloadImage: ${error}`);
        }
    }
   

    // Draggable Functionality ends here


    // Workbench Functionalites 
    /**
     * Label Submit updates the Node During the 
     * user Provided Configuration
     * Which Save the Architecture on the realtime Basis
    */

    function labelSubmit(configureValues) {
       
        const updatedNodes = nodes.map(node => {
            if (node.id === configureValues.id) {
              return { ...node, data: configureValues.data };
            }
            return node;
          });
        console.log(updatedNodes , "updated Nodes!")
        setNodes(updatedNodes); //
        if(viewMode == false){ 
        saveArchitecture()
        }
    }

    return (
        <>
            <RepoWorkbenchNav/>
            <div class="container-fluid p-0">
                <div class="content-center-container">
                <div class="row">
                    {/** Repo Side Bar Component */}
                        <RepoSideBarComponent
                           setSelectedEnv = {setSelectedEnv}
                           setUserSelectedRepo = {setUserSelectedRepo}
                        />
                      
                        
                        <div
                            className="reactflow-wrapper"
                            style={{ position: "absolute" , height:"84%"}}

                            ref={reactFlowWrapper}
                        >
                            <Draggable handle="#moveable"
                            defaultPosition={{ x: 700, y: 530 }}>
                                <div
                                    className="tools d-flex rounded shadow-sm"
                                    style={{ zIndex: "10" , 
                                        position:"relative", 
                                        bottom:'0'

                                    }}
                                >
                                    <button className="btn drag-btn" id="moveable">
                                        <img
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="bottom"
                                            title="Drag"
                                            src="images/drag-img.svg"
                                            className
                                            draggable={false}
                                        />
                                    </button>

                                    {/* <span class="border-2 border-start align-middle"></span> */}
                                    <button
                                        className="btn tool-btn"
                                        onClick={() => {
                                            unDoFunction();
                                        }}
                                        disabled={undoStack.length === 0}
                                    >
                                        <img
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="bottom"
                                            title="Undo"
                                            src="images/undo-icon.svg"
                                            className
                                        />
                                    </button>

                                    <button
                                        className="btn tool-btn"
                                        onClick={() => {
                                            reDoFunction();
                                        }}
                                        disabled={redoStack.length === 0}

                                    >
                                        <img
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="bottom"
                                            title="Redo"
                                            src="images/redo-icon.svg"
                                            className
                                        />
                                    </button>

                                    {/* <span class="border-2 border-start align-middle"></span> */}
                                    <button
                                        id="fittoscreen"
                                        className="btn tool-btn"
                                        onClick={() => {
                                            document.documentElement
                                                .getElementsByClassName(
                                                    "react-flow__controls-button react-flow__controls-fitview"
                                                )[0]
                                                .click();
                                        }}
                                    >
                                        <img
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="bottom"
                                            title="Fit to screen"
                                            src="images/fit-to-screen.svg"
                                            className
                                        />
                                    </button>
                                    <button
                                        className="btn tool-btn"
                                        onClick={() => {
                                            document.documentElement
                                                .getElementsByClassName(
                                                    "react-flow__controls-button react-flow__controls-zoomin"
                                                )[0]
                                                .click();
                                        }}
                                    >
                                        <img
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="bottom"
                                            title="Zoom in"
                                            src="images/zoom-in.svg"
                                            className
                                        />
                                    </button>
                                    <button
                                        className="btn tool-btn"
                                        onClick={() => {
                                            document.documentElement
                                                .getElementsByClassName(
                                                    "react-flow__controls-button react-flow__controls-zoomout"
                                                )[0]
                                                .click();
                                        }}
                                    >
                                        <img
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="bottom"
                                            title="Zoom out"
                                            src="images/zoom-out.svg"
                                            className
                                        />
                                    </button>
                                    {/* <span class="border-2 border-start align-middle"></span> */}
                                    <div className="dropdown d-flex">
                                        <button
                                            className="btn tool-btn"
                                            type="button"
                                            id="dropdownDOWNLOAD"
                                            data-bs-toggle="dropdown"
                                            disabled={nodes.length == 0 ? true : false}
                                            aria-expanded="false"
                                        >
                                            <img
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="bottom"
                                                title="Download"
                                                src="images/download.svg"
                                                className
                                            />
                                        </button>
                                        <ul className="dropdown-menu profile-menu border-0 py-1 px-2 shadow-sm">
                                            <li
                                                className="border-grey pb-1"
                                                onClick={() => {
                                                    XMLDownload()
                                                }}
                                            >
                                                <a className="dropdown-item font-semibold font-14 profile-dropdown py-2 px-2">
                                                    XML
                                                </a>
                                            </li>
                                            <li
                                                className="pt-1 pb-1 border-grey"
                                                onClick={() => {
                                                    DownLoadImage("Png");

                                                }}
                                            >
                                                <a className="dropdown-item font-semibold font-14 py-2 px-2 profile-dropdown">
                                                    PNG
                                                </a>
                                            </li>
                                            <li
                                                className="pt-1"
                                                onClick={() => {
                                                    DownLoadImage("Jpeg");
                                                }}
                                            >
                                                <a className="dropdown-item font-semibold font-14 py-2 px-2 profile-dropdown">
                                                    JPG
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <button
                                        className="btn tool-btn"
                                        data-bs-toggle="modal"
                                        data-bs-target="#commit"
                                        disabled={
                                            true
                                        }

                                    >
                                        <img
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="bottom"
                                            title="Commit"
                                            src="images/commit.svg"
                                            className
                                        />
                                    </button>
                                    <button
                                        className="btn tool-btn"
                                        data-bs-toggle="modal"
                                        data-bs-target="#commitHistory"
                                        disabled={ true }
                                    >
                                        <img
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="bottom"
                                            style={{ width: "18px" }}
                                            title="Commit history"
                                            src="images/commit-history-icon.svg"
                                            className
                                        />
                                    </button>
                                </div>
                            </Draggable>
                            <ReactFlow
                                nodes={nodes}
                                edges={edges}
                                onNodesChange={onNodesChange}
                                onEdgesChange={onEdgesChange}
                                onConnect={onConnect}
                                onDrop={onDrop}
                                onDragOver={onDragOver}
                                onNodeDoubleClick={(e, node) => {
                                    doubleClickLabel(e, node);
                                }}
                                onNodesDelete={onNodesDelete}
                                deleteKeyCode={["Delete", "Backspace"]}
                                fitView
                                className="validationflow"
                                nodeTypes={nodeTypes}
                                id="myArchitecture"
                                onPaneClick={(e)=>{
                                    if(configureDetails.id != ""){
                                            setConfigureDetails({id:""})
                                    }
                                }
                                }
                                // colorMode={colorMode}
                                


                            >
                                <Controls />
                                <Background />
                            </ReactFlow>
                        </div>

                    {/** Repo Configure Component */}
                      {/** Configure Component */}
                    {configureDetails.id != "" ?
                        <>
                    <RepoConfigureComponent
                        configureDetails ={configureDetails}
                        labelSubmit = {labelSubmit}
                        userSelectedRepo = {userSelectedRepo}
                    />
                        </> :  <></>

                        }
                    {showLoader ? 
                    <WorkbenchLoader/>:
                    <></>}

                        
                 </div>
                </div>
            </div>
          
        </>
    )
}




export default RepoWorkbench;