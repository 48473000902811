export default function AWSSDKLambda({ values, onChangeOfConfiguration, isViewer }) {
    try {

        return (
            <>
                <div
                    class="accordion-body p-0 estimation-container">
                    <div className="row py-4 mx-4 px-5" hidden={(values.isPriceAvailable == "no") ? false : true}>
                        <div className="col-md-6 px-3"><span className="font-semibold font-14 text-danger">{values.isPriceAvailable == "no" ? "The Price is not available for the region / the configuration that you have selected might be not available in the region" : null}</span></div>
                    </div>
                    <div className="row py-4 mx-4 px-5">
                        <div className="col-md-6 px-3">
                            <label htmlFor="region" className="form-label font-14 font-medium black-v2 mb-3">
                                Region
                            </label>
                            <select
                                className="form-select custom-form font-semibold font-14 form-select-sm mb-3"
                                id="region"
                                style={{ paddingBottom: '10px' }}
                                value={values.region}
                                name={"region"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            >
                                <option value="ap-south-2">ap-south-2</option><option value="ap-southeast-3">ap-southeast-3</option><option value="ap-southeast-1">ap-southeast-1</option><option value="us-gov-east-1">us-gov-east-1</option><option value="eu-west-2">eu-west-2</option><option value="af-south-1">af-south-1</option><option value="ap-northeast-1">ap-northeast-1</option><option value="ap-southeast-2">ap-southeast-2</option><option value="us-east-2">us-east-2</option><option value="eu-central-1">eu-central-1</option><option value="ca-central-1">ca-central-1</option><option value="ap-south-1">ap-south-1</option><option value="ap-northeast-3">ap-northeast-3</option><option value="sa-east-1">sa-east-1</option><option value="ap-east-1">ap-east-1</option><option value="eu-west-1">eu-west-1</option><option value="eu-south-1">eu-south-1</option><option value="ap-southeast-4">ap-southeast-4</option><option value="eu-north-1">eu-north-1</option><option value="eu-south-2">eu-south-2</option><option value="us-west-1">us-west-1</option><option value="us-west-2">us-west-2</option><option value="me-south-1">me-south-1</option><option value="eu-central-2">eu-central-2</option><option value="us-gov-west-1">us-gov-west-1</option><option value="ap-northeast-2">ap-northeast-2</option><option value="me-central-1">me-central-1</option><option value="us-east-1">us-east-1</option><option value="eu-west-3">eu-west-3</option>
                            </select>
                        </div>
                        <div className="col-md-6 px-3">
                            <label htmlFor="freeTier" className="form-label font-14 font-medium black-v2 mb-3">
                                Lambda Function
                            </label>
                            <select
                                className="form-select custom-form font-semibold font-14 form-select-sm"
                                id="freeTier"
                                style={{ paddingBottom: '10px' }}
                                value={values.freeTier}
                                name={"freeTier"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            >
                                <option value="includesFreeTier">Lambda Function - Include Free Tier</option>
                            </select>
                        </div>
                        <div className="col-md-6 px-3">
                            <label htmlFor="architecture" className="form-label font-14 font-medium black-v2 mb-3">
                                Architecture
                            </label>
                            <select
                                className="form-select custom-form font-semibold font-14 form-select-sm mb-3"
                                id="architecture"
                                value={values.architecture}
                                name={"architecture"}
                                style={{ paddingBottom: '10px' }}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            >
                                <option value="x86_64">X86</option>
                                <option value="Arm64">Arm</option>
                            </select>
                        </div>
                        <div className="col-md-6 px-3">
                            <label
                                htmlFor="noOfRequest"
                                className="form-label font-14 font-medium black-v2 mb-3"
                            >
                                Number Of Requests
                            </label>
                            <input
                                type="noOfRequest"
                                style={{ paddingBottom: '10px' }}
                                className="form-control custom-form text-field font-14 font-medium"
                                id="noOfRequest"
                                value={values.noOfRequest}
                                name={"noOfRequest"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            />
                        </div>
                        <div className="col-md-6 px-3">
                            <label htmlFor="noOfRequestUnit" className="form-label font-14 font-medium black-v2 mb-3">
                                Unit
                            </label>
                            <select
                                className="form-select custom-form font-semibold font-14 form-select-sm mb-3"
                                id="noOfRequestUnit"
                                style={{ paddingBottom: '10px' }}
                                value={values.noOfRequestUnit}
                                name={"noOfRequestUnit"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            >
                                <option value="perSecond">per second</option>
                                <option value="perMinute">per minute</option>
                                <option value="perHour">per hour</option>
                                <option value="perDay">per day</option>
                                <option value="perMonth">per month</option>
                                <option value="million">million per month</option>
                            </select>
                        </div>
                        <div className="col-md-6 px-3">
                            <label
                                htmlFor="durationOfEachRequest"
                                className="form-label font-14 font-medium black-v2 mb-3"
                            >
                                Duration of each request(in ms)
                            </label>
                            <input
                                type="number"
                                style={{ paddingBottom: '10px' }}
                                className="form-control custom-form text-field font-14 font-medium"
                                id="durationOfEachRequest"
                                value={values.durationOfEachRequest}
                                name={"durationOfEachRequest"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            />
                        </div>
                        <h6 style={{ paddingBottom: '10px', marginTop: "10px" }}>Amount of memory allocated</h6>
                        <div className="col-md-6 px-3">
                            <label
                                htmlFor="memoryAllocated"
                                className="form-label font-14 font-medium black-v2 mb-3"
                            >
                                Memory allocated
                            </label>
                            {
                                console.log(values.memoryAllocated, "values.memoryAllocated")
                            }
                            <input
                                // type="number"
                                className="form-control custom-form text-field font-14 font-medium"
                                id="memoryAllocated"
                                style={{ paddingBottom: '10px' }}
                                value={values.memoryAllocated}
                                name={"memoryAllocated"}
                                step={"0.001"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            />
                        </div>
                        <div className="col-md-6 px-3">
                            <label htmlFor="memoryAllocatedUnit" className="form-label font-14 font-medium black-v2 mb-3">
                                Unit
                            </label>
                            <select
                                className="form-select custom-form font-semibold font-14 form-select-sm mb-3"
                                id="memoryAllocatedUnit"
                                style={{ paddingBottom: '10px' }}
                                value={values.memoryAllocatedUnit}
                                name={"memoryAllocatedUnit"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            >
                                <option value="MB">MB</option>
                                <option value="GB">GB</option>
                            </select>
                        </div>
                        <h6 style={{ paddingBottom: '10px' }}>Amount of ephemeral storage allocated</h6>
                        <div className="col-md-6 px-3">
                            <label
                                htmlFor="emphemeralStorage"
                                className="form-label font-14 font-medium black-v2 mb-3"
                            >
                                Amount of ephemeral storage allocated
                            </label>
                            <input
                                type="number"
                                style={{ paddingBottom: '10px' }}
                                className="form-control custom-form text-field font-14 font-medium"
                                id="emphemeralStorage"
                                value={values.emphemeralStorage}
                                name={"emphemeralStorage"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            />
                        </div>
                        <div className="col-md-6 px-3">
                            <label htmlFor="emphemeralStorageUnit" className="form-label font-14 font-medium black-v2 mb-3">
                                Unit
                            </label>
                            <select
                                className="form-select custom-form font-semibold font-14 form-select-sm"
                                id="emphemeralStorageUnit"
                                style={{ paddingBottom: '10px' }}
                                value={values.emphemeralStorageUnit}
                                name={"emphemeralStorageUnit"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            >
                                <option value="MB">MB</option>
                                <option value="GB">GB</option>
                            </select>
                        </div>
                    </div>
                </div>

            </>
        );
    } catch (error) {
        //console.log(error);
    }
}