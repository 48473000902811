function RepoHeader(){

    return(
        <>
          <nav className="navbar navbar-expand-lg background-white custom-nav">
        <div className="container-fluid custom-container px-3">
          <a className="navbar-brand" href="#">
            <span className="d-flex">
              <img
                src="images/logo.svg"
                alt="logo"
                className="header-logo light-theme-img"
              />
              <img
                src="images/logo-dark.svg"
                alt="logo"
                className="header-logo dark-theme-img"
              />
            </span>
          </a>
          <div
            className="collapse navbar-collapse justify-content-center"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav">
              <li className="nav-item custom-nav-item">
                <a className="nav-link" aria-current="page" href="#">
                  Design
                </a>
              </li>
              <li className="nav-item custom-nav-item active">
                <a className="nav-link" aria-current="page" href="#">
                  Branching Strategy
                </a>
              </li>
              <li className="nav-item custom-nav-item ">
                <a className="nav-link" aria-current="page" href="#">
                  CI/CD
                </a>
              </li>
            </ul>
          </div>
          <div className="btn-group">
            <button type="button" className="button danger-button">
              <img src="images/button-exit.svg" alt="exit" className="me-1" /> Exit
              Workbench
            </button>
          </div>
        </div>
      </nav></>
    )
}

export default RepoHeader;