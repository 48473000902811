import React, { useState, useEffect } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { getAllTemplateKb, deleteTemplateKb } from "../../Service/api";
import Loader from "../loader/loader";
import WorkbechPopup from "../Organization/Workbench/WorkBench/PopupMaintanence/WorkBenchPopUp";
//PS_01 to PS_07 Declaring state variables
const TemplateKbGrid = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [displayCount, setDisplayCount] = useState(0);
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortColumn, setSortColumn] = useState("templatekb_name");
  const [count, setCount] = useState(0);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [popupMsg, setPopUpMsg] = useState({
    Msg: "",
    show: false,
    success: false,
  });
  //PS_08
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    const checkUserAndAdminStatus = () => {
      const userId = localStorage.getItem("userId");
      const adminStatus = localStorage.getItem("isAdmin");

      console.log("UserId in useEffect:", userId);
      if (!userId) {
        console.log("UserId not found in localStorage");
      }

      setIsAdmin(adminStatus === "true");
    };

    checkUserAndAdminStatus();
  }, []);

  //and PS_09 use effect for triggering
  useEffect(() => {
    fetchTemplates();
  }, [sortOrder, sortColumn]);

  useEffect(() => {
    const checkUserId = () => {
      const userId = localStorage.getItem("userId");
      console.log("UserId in useEffect:", userId);
      if (!userId) {
        console.log("UserId not found in localStorage");
      }
    };

    checkUserId();
  }, []);

  //PS_15 to PS_20 Function to fetch templates
  const fetchTemplates = async () => {
    const userId = localStorage.getItem("userId");
    const payload = {
      userId,
      sort: sortOrder,
      count,
      sortCoulmn: sortColumn,
    };

    try {
      setShowLoader(true);
      const response = await getAllTemplateKb(payload);
      setShowLoader(false);
      // Check if response and response.data exist
      if (response && response.data && response.data.body) {
        const { body } = response.data;

        if (body.success) {
          setTemplates(body.data);
          setTotalCount(body.rowCount);
          setDisplayCount(body.data.length);
        } else {
          console.error("API request was not successful:", body);
          setShowLoader(false);
        }
      } else {
        console.error("Unexpected response structure:", response);
        setShowLoader(false);
      }
    } catch (error) {
      console.error("Error fetching templates:", error);
      setShowLoader(false);
    }
  };

  //PS_21 to PS_24 Function to handle sort
  const handleSort = (column) => {
    setSortColumn(column);
    setSortOrder(sortOrder === "desc" ? "asc" : "desc");
    setCount(0);
  };

  //PS_25 to PS_31 Function to handle load more

  const handleLoadMore = async () => {
    const newCount = count + 10;
    setCount(newCount);

    const userId = localStorage.getItem("userId");
    const payload = {
      userId,
      sort: sortOrder,
      count: newCount,
      sortCoulmn: sortColumn,
    };

    try {
      const response = await getAllTemplateKb(payload);
      console.log("Full response:", response);

      if (response.data && response.data.body && response.data.body.success) {
        setTemplates((prevTemplates) => [
          ...prevTemplates,
          ...response.data.body.data,
        ]);
        setDisplayCount(
          (prevCount) => prevCount + response.data.body.data.length
        );
      } else {
        console.error("Unexpected response structure:", response);
      }
    } catch (error) {
      console.error("Error fetching more templates:", error);
    }
  };

  //PS_32 to PS_41 Function to handle delete

  const handleDelete = async () => {
    console.log("Entering handleDelete function");

    let userId;
    try {
      userId = localStorage.getItem("userId");
    } catch (error) {
      console.error("Error accessing localStorage:", error);
    }

    if (!selectedTemplate) {
      console.log("Exiting early: selectedTemplate is falsy");
      return;
    }

    if (!userId) {
      console.log("UserId is falsy, but continuing with the delete operation");
      // You might want to show a warning to the user or handle this case differently
    }

    const payload = {
      userId: userId || "default-user-id", // Use a default if userId is null
      templateId: selectedTemplate.templatekbId,
    };

    try {
      const response = await deleteTemplateKb(payload);

      if (response.data && response.data.body) {
        if (response.data.statusCode === 200 && response.data.body.success) {
          setPopUpMsg({
            ...popupMsg,
            ["Msg"]: "Template KB deleted successfully",
            ["show"]: true,
            ["success"]: true,
          });
          await fetchTemplates();
        } else {
          console.error("Error deleting template:", response.data.body.message);
          setPopUpMsg({
            ...popupMsg,
            ["Msg"]: "Something went wrong",
            ["show"]: true,
            ["success"]: false,
          });

          // Show error message
        }
      } else {
        setPopUpMsg({
          ...popupMsg,
          ["Msg"]: "Something went wrong",
          ["show"]: true,
          ["success"]: false,
        });
        console.error("Unexpected response structure:", response);
        // Show error message
      }
    } catch (error) {
      setPopUpMsg({
        ...popupMsg,
        ["Msg"]: "Something went wrong",
        ["show"]: true,
        ["success"]: false,
      });
      console.error("Error in deleteTemplateKb:", error);
      // Show error message
    }
  };

  //PS_43 to PS_44 Function to handle edit
  const handleEditClick = (template) => {
    navigate("/TemplateKbForm", {
      state: {
        template: {
          template_kb_name: template.templateName,
          cloudProviderId: template.providerId,
          architectureTypeId: template.architectureTypeId,
          businessDescription: template.business,
          architectureDescrption: template.architectureDescription, // Note the spelling
          terraformCode: template.teraformdata,
          cloudformationCode: template.cloudformationdata,
          armCode: template.armData,
          templateKbId: template.templatekbId, // Make sure this matches the property name from your API
        },
      },
    });
  };

  return (
    <>
      {!isAdmin ? (
        <div>You do not have access to this page.</div>
      ) : (
        <>
          {showLoader ? (
            <Loader />
          ) : (
            <div className="container-fluid custom-container right-container-scroll">
              <div className="d-flex justify-content-between align-items-center pt-4 mt-2">
                <h2 className="font-20 font-bold black-v2 mb-0">
                  Templates KB
                </h2>
                <button
                  type="button"
                  className="button primary-button text-nowrap"
                  onClick={() => navigate("/TemplateKBForm")}
                >
                  <img
                    src="images/add-icon.svg"
                    alt="add-icon"
                    className="me-2"
                  />
                  Add Template
                </button>
              </div>
              {templates.length === 0 ? (
                <div className="no-record-found">
                  <img
                    src="images/no-records-found.png"
                    alt="No records found"
                    className="no-record-icon"
                  />
                </div>
              ) : (
                <>
                  <div className="table-responsive mt-4 table-border">
                    <table className="table custom-table">
                      <thead>
                        <tr>
                          <th className="position-relative text-start border-0">
                            Template Name
                            <span
                              className="cursor-pointer margin-left-2"
                              onClick={() => handleSort("templatekb_name")}
                            >
                              <img
                                src="./images/table-sort.svg"
                                alt="sort"
                                className="ms-1"
                                style={{
                                  transform:
                                    sortColumn === "templatekb_name" &&
                                    sortOrder === "asc"
                                      ? "scaleY(-1)"
                                      : "scaleY(1)",
                                  transition: "transform 0.2s ease-in-out",
                                }}
                              />
                            </span>
                          </th>
                          <th className="position-relative text-start border-0">
                            Architecture Type
                            <span
                              className="cursor-pointer margin-left-2"
                              onClick={() => handleSort("architecture_type")}
                            >
                              <img
                                src="./images/table-sort.svg"
                                alt="sort"
                                className="ms-1"
                                style={{
                                  transform:
                                    sortColumn === "architecture_type" &&
                                    sortOrder === "asc"
                                      ? "scaleY(-1)"
                                      : "scaleY(1)",
                                  transition: "transform 0.2s ease-in-out",
                                }}
                              />
                            </span>
                          </th>
                          <th className="position-sort-icon text-start border-0">
                            Business
                          </th>
                          <th className="text-center border-0">Actions</th>
                        </tr>
                      </thead>
                      <tbody className="manage-work">
                        {templates.map((template) => (
                          <tr key={template.templatekbId}>
                            <td className="text-start">
                              {template.templateName}
                            </td>
                            <td className="text-start">
                              {template.architectureType}
                            </td>
                            <td className="text-start truncate">
                              {template.business}
                            </td>
                            <td className="text-center">
                              <div
                                className="d-flex gap-3 justify-content-center"
                                style={{ minWidth: "60px" }}
                              >
                                <a
                                  href="#"
                                  onClick={() => handleEditClick(template)}
                                >
                                  <img
                                    src="images/edit-icon-blue.svg"
                                    alt="edit-icon"
                                    className="action-icons"
                                  />
                                </a>
                                {!template.isDefault && (
                                  <a
                                    href="#"
                                    onClick={() =>
                                      setSelectedTemplate(template)
                                    }
                                    data-bs-toggle="modal"
                                    data-bs-target="#staticBackdrop-25"
                                  >
                                    <img
                                      src="images/delete-icon.svg"
                                      alt="delete-icon"
                                      className="icon-width-small"
                                    />
                                  </a>
                                )}
                                {template.isDefault && (
                                  <div style={{ width: "24px" }}></div> // Placeholder to maintain spacing
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="d-flex justify-content-between align-items-center my-3">
                    <p className="font-14 font-medium secondary-text-color mb-0">
                      # of Records:{" "}
                      <span className="font16 font-medium color-black">
                        {displayCount} out of {totalCount}
                      </span>
                    </p>
                  </div>
                  {displayCount < totalCount && (
                    <div className="d-flex justify-content-center align-items-center">
                      {showLoader ? (
                        <Loader />
                      ) : (
                        <button
                          type="button"
                          className="button secondary-outline-button tag-color text-nowrap"
                          onClick={handleLoadMore}
                        >
                          Load More
                        </button>
                      )}
                    </div>
                  )}
                </>
              )}

              {/* Delete Confirmation Modal */}
              <div
                className="modal fade"
                id="staticBackdrop-25"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered justify-content-center">
                  <div className="modal-content border-0">
                    <div className="modal-header d-flex justify-content-end border-0 pb-0 ">
                      <button
                        type="button"
                        className="btn shadow-none"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <img
                          src="images/close-icon.svg"
                          alt="close-icon"
                          className="icon-hover shadow-none modal-close-filter"
                        />
                      </button>
                    </div>
                    <div className="modal-body text-center pt-0 p-32">
                      <img
                        src="images/delete-organization-icon.svg"
                        alt="delete the Organization"
                      />
                      <h6 className="font-20 font-bold black-v2 mt-4 pt-2">
                        You're about to delete the Template
                      </h6>
                      <p className=" font-16 font-regular secondary-text-color-v5 mt-3 line-height-32 mb-0">
                        "{selectedTemplate?.templateName}"
                      </p>
                      <p className=" font-16 font-regular secondary-text-color-v5 mt-2 line-height-32">
                        Do you want to proceed?
                      </p>
                      <div className="d-flex justify-content-center gap-3 align-items-center mt-4 pt-3">
                        <button
                          type="button"
                          className="button outline-button text-nowrap px-32"
                          onClick={handleDelete}
                          data-bs-dismiss="modal"
                        >
                          Yes
                        </button>
                        <button
                          type="button"
                          className="button primary-button popup-btn px-32"
                          data-bs-dismiss="modal"
                        >
                          No
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {popupMsg.show && popupMsg.Msg !== "" && (
            <div
              style={{
                position: "absolute",
                top: "15%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 1000,
                maxWidth: "80%",
                width: "300px",
              }}
            >
              <WorkbechPopup popupMsg={popupMsg} setPopUpMsg={setPopUpMsg} />
            </div>
          )}
        </>
      )}
    </>
  );
};
export default TemplateKbGrid;
