import React, { useState, useEffect, useContext } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";
import { getCostByService } from "../../Service/api"; // Adjust the import path as needed
import { OrgContext } from "../../ContextProvider/OrgContext";
import moment from "moment";
import { DashboardContext } from "./Context/DashboardContext";

const COLORS = ["#f48fb1", "#64b5f6", "#a8e6cf", "#d7ccc8", "#90caf9"];

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "white",
          padding: "5px",
          border: "1px solid #cccccc",
        }}
      >
        <p style={{ margin: 0 }}>{`${payload[0].name}: $${payload[0].value.toFixed(2)}`}</p>
      </div>
    );
  }
  return null;
};

const CostByService = ({
  selectedProviderId,
  selectedWorkload,
  selectedConnector,
  fromDate,
  toDate
}) => {
  const [data, setData] = useState([]);
  const userId = localStorage.getItem("userId");
  const { orgId } = useContext(OrgContext);
  const {servicesE, setServicesE} = useContext(DashboardContext);

  const noDataStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    margin: 0,
    fontSize: '14px',
    color: '#333',
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const formattedFromDate = fromDate ? moment(fromDate, 'DD-MM-YYYY').format('YYYY-MM-DD') : '';
        const formattedToDate = toDate ? moment(toDate, 'DD-MM-YYYY').format('YYYY-MM-DD') : '';
    
        const payload = {
          userId: userId,
          orgId: orgId,
          filterCloudProvider: selectedProviderId,
          filterWorkload: Array.isArray(selectedWorkload) ? selectedWorkload : [],
          filterConnector: Array.isArray(selectedConnector) ? selectedConnector : [],
          fromDate: formattedFromDate,
          toDate: formattedToDate
        };
    
        const response = await getCostByService(payload);
    
        if (response.data && response.data.statusCode === 200 && response.data.body.success) {
          const groupedData = response.data.body.data.reduce((acc, item) => {
            if (!acc[item.serviceName]) {
              acc[item.serviceName] = 0;
            }
            acc[item.serviceName] += parseFloat(item.cost) || 0;
            return acc;
          }, {});
    
          const chartData = Object.entries(groupedData).map(
            ([name, value]) => ({ name, value: parseFloat(value.toFixed(2)) })
          );

          let serviceDataJ = chartData?.map((values,index) => {
            return {
              Services: values?.name,
              Actual_Cost: values?.value
            }
          });
          setServicesE(serviceDataJ);
          setData(chartData);
        } else {
         console.log("API request failed:", response.data?.body?.message || "Unknown error");
        }
      } catch (error) {
       console.log("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedProviderId, selectedWorkload, selectedConnector, fromDate, toDate, userId, orgId]);

  const totalCost = data.reduce((sum, item) => sum + item.value, 0);

  if (data.length === 0) {
    return (
      <div style={noDataStyle}>
        <p>No data found</p>
      </div>
    );
  }

  return (
    <div style={{ display: "flex" }}>
      <ResponsiveContainer width="60%" height={200}>
        <PieChart>
          <Pie
            data={data}
            cx={120}
            cy={100}
            innerRadius={60}
            outerRadius={80}
            fill="#8884d8"
            paddingAngle={5}
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <text x="50%" y="50%" textAnchor="middle" dominantBaseline="middle">
            <tspan x="45%" dy="-0.5em" fontSize="14" fill="#333">
              Total Cost
            </tspan>
            <tspan
              x="45%"
              dy="1.5em"
              fontSize="18"
              fontWeight="bold"
              fill="#333"
            >
              ${totalCost.toFixed(2)}
            </tspan>
          </text>
          <Tooltip content={<CustomTooltip />} />
        </PieChart>
      </ResponsiveContainer>

      <div className="legend-container ms-4">
        {data.map((entry, index) => (
          <div key={entry.name} className="d-flex justify-content-between mb-4">
            <p className="font-12 font-medium secondary-color mb-0">
              <span
                className={`legend legend-${COLORS[index % COLORS.length]}`}
                style={{
                  backgroundColor: COLORS[index % COLORS.length],
                  width: "10px",
                  height: "10px",
                  display: "inline-block",
                  marginRight: "5px",
                }}
              />
              {entry.name}
            </p>
            <p className="font-12 font-medium black-v2 mb-0 ms-2 text-nowrap" style={{ marginLeft: '20px' }}>
              ${entry.value.toFixed(2)}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CostByService;