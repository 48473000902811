
// this HTML is for Azure Container Apps
export default function Azure_SQL_DB({ values, onChangeOfConfiguration, isViewer }) {
    try {
        console.log("sagin aws S3",values,"34535345")
        return (
            <div id={`${values.instanceName.split(" ").join("_")}`} className="accordion-collapse collapse">
                <div className="accordion-body p-0 ">
                    <table className="table table-borderless cg-grid font-14 font-semibold mb-0">
                        <tbody>
                            <tr className="ms-acc-data-align">
                                <td colSpan={3}>
                                    <div className="row py-4 mx-4 px-5" hidden={(values.isPriceAvailable == "No") ? false : true}>
                                        <div className="col-md-6 px-3"><span className="font-semibold font-14 text-danger">{values.isPriceAvailable == "No" ? "The Price is not available for the region / the configuration that you have selected might be not available in the region" : null}</span></div>
                                    </div>
                                    <div className="row py-4 mx-4 px-5">
                                        <div className="col-md-6 px-3">
                                            <label htmlFor="region" className="form-label font-14 font-medium black-v2 mb-3">
                                                Region
                                            </label>
                                            <select
                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                id="region"
                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                value={values.region}
                                                name={"region"}
                                                onChange={(e) => {
                                                    onChangeOfConfiguration(e, values.instanceName);
                                                }} disabled={isViewer}
                                            >
                                            
                                            <optgroup label="United States"><option value="us-central" class="">Central US</option>
                                            <option value="us-east" class="">East US</option>
                                            <option value="us-east-2" class="">East US 2</option>
                                            <option value="us-north-central" class="">North Central US</option>
                                            <option value="us-south-central" class="">South Central US</option>
                                            <option value="us-west-central" class="">West Central US</option>
                                            <option value="us-west" class="">West US</option>
                                            <option value="us-west-2" class="">West US 2</option>
                                            <option value="us-west-3" class="">West US 3</option>
                                            </optgroup><optgroup label="United Kingdom"><option value="united-kingdom-south" class="">UK South</option>
                                            <option value="united-kingdom-west" class="">UK West</option>
                                            </optgroup><optgroup label="United Arab Emirates"><option value="uae-central" class="">UAE Central</option>
                                            <option value="uae-north" class="">UAE North</option>
                                            </optgroup><optgroup label="Switzerland"><option value="switzerland-north" class="">Switzerland North</option>
                                            <option value="switzerland-west" class="">Switzerland West</option>
                                            </optgroup><optgroup label="Sweden"><option value="sweden-central" class="">Sweden Central</option>
                                            <option value="sweden-south" class="">Sweden South</option>
                                            </optgroup><optgroup label="Spain"><option value="spain-central" class="">Spain Central</option>
                                            </optgroup><optgroup label="Qatar"><option value="qatar-central" class="">Qatar Central</option>
                                            </optgroup><optgroup label="Poland"><option value="poland-central" class="">Poland Central</option>
                                            </optgroup><optgroup label="Norway"><option value="norway-east" class="">Norway East</option>
                                            <option value="norway-west" class="">Norway West</option>
                                            </optgroup><optgroup label="Mexico"><option value="mexico-central" class="">Mexico Central</option>
                                            </optgroup><optgroup label="Korea"><option value="korea-central" class="">Korea Central</option>
                                            <option value="korea-south" class="">Korea South</option>
                                            </optgroup><optgroup label="Japan"><option value="japan-east" class="">Japan East</option>
                                            <option value="japan-west" class="">Japan West</option>
                                            </optgroup><optgroup label="Italy"><option value="italy-north" class="">Italy North</option>
                                            </optgroup><optgroup label="Israel"><option value="israel-central" class="">Israel Central</option>
                                            </optgroup><optgroup label="India"><option value="central-india" class="">Central India</option>
                                            <option value="south-india" class="">South India</option>
                                            <option value="west-india" class="">West India</option>
                                            </optgroup><optgroup label="Germany"><option value="germany-north" class="">Germany North</option>
                                            <option value="germany-west-central" class="">Germany West Central</option>
                                            </optgroup><optgroup label="France"><option value="france-central" class="">France Central</option>
                                            <option value="france-south" class="">France South</option>
                                            </optgroup><optgroup label="Europe"><option value="europe-north" class="">North Europe</option>
                                            <option value="europe-west" class="">West Europe</option>
                                            </optgroup><optgroup label="Canada"><option value="canada-central" class="">Canada Central</option>
                                            <option value="canada-east" class="">Canada East</option>
                                            </optgroup><optgroup label="Brazil"><option value="brazil-south" class="">Brazil South</option>
                                            <option value="brazil-southeast" class="">Brazil Southeast</option>
                                            </optgroup><optgroup label="Azure Government"><option value="usgov-arizona" class="">US Gov Arizona</option>
                                            <option value="usgov-texas" class="">US Gov Texas</option>
                                            <option value="usgov-virginia" class="">US Gov Virginia</option>
                                            </optgroup><optgroup label="Australia"><option value="australia-central" class="">Australia Central</option>
                                            <option value="australia-central-2" class="">Australia Central 2</option>
                                            <option value="australia-east" class="">Australia East</option>
                                            <option value="australia-southeast" class="">Australia Southeast</option>
                                            </optgroup><optgroup label="Asia Pacific"><option value="asia-pacific-east" class="">East Asia</option>
                                            <option value="asia-pacific-southeast" class="">Southeast Asia</option>
                                            </optgroup><optgroup label="Africa"><option value="south-africa-north" class="">South Africa North</option>
                                            <option value="south-africa-west" class="">South Africa West</option>
                                            </optgroup>
                                                

                                            </select>
                                        </div>

                                        <div className="col-md-6 px-3">
                                            <label htmlFor="type" className="form-label font-14 font-medium black-v2 mb-3">
                                            Type
                                            </label>
                                            <select
                                                className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                id="type"
                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                value={values.type}
                                                name={"type"}
                                                onChange={(e) => {
                                                    onChangeOfConfiguration(e, values.instanceName);
                                                    
                                                }} disabled={isViewer}
                                            >
                                                
                                                <option value="elastic">Elastic Pool</option>
                                                <option value="single">Single Database</option>

                                            </select>
                                        </div>
                                        {values.type === "elastic" ? 
                                        (
                                            <>
                                                <div className="col-md-6 px-3">
                                                    <label htmlFor="purchaseModel" className="form-label font-14 font-medium black-v2 mb-3"> Purchase Model
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                        id="purchaseModel"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.purchaseModel}
                                                        name={"purchaseModel"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} 
                                                        disabled={isViewer}
                                                    >
                                                        <option value="vcore">vCore</option>
                                                        <option value="dtu">DTU</option>
                                                    </select>
                                                </div>
                                                {values.purchaseModel === "vcore" ?(
                                                    <>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="vcoreTier" className="form-label font-14 font-medium black-v2 mb-3"> Service Tier
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                id="vcoreTier"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.vcoreTier}
                                                                name={"vcoreTier"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} 
                                                                disabled={isViewer}
                                                            >
                                                                <option value="general-purpose">General Purpose</option>
                                                                <option value="business-critical">Business Critical</option>
                                                                <option value="hyperscale">Hyperscale</option>
                                                            </select>
                                                        </div>
                                                        {values.vcoreTier === "general-purpose" ? (
                                                            <>
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="generation" className="form-label font-14 font-medium black-v2 mb-3"> Hardware Type
                                                                    </label>
                                                                    <select
                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                        id="generation"
                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                        value={values.generation}
                                                                        name={"generation"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} 
                                                                        disabled={isViewer}
                                                                    >
                                                                      <option value="gen5">Standard-series (Gen 5)</option>
                                                                      <option value="fsv2">Fsv2-series</option>
                                                                      <option value="dc">DC-series</option>

                                                                    </select>
                                                                </div>
                                                                
                                                                {values.generation === "gen5" ? (
                                                                    <>
                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="instanceSize" className="form-label font-14 font-medium black-v2 mb-3">
                                                                            Instance
                                                                            </label>
                                                                            <select
                                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                id="instanceSize"
                                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                value={values.instanceSize}
                                                                                name={"instanceSize"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} 
                                                                                disabled={isViewer}
                                                                            >
                                                                            <option value="2">2 vCore</option>
                                                                            <option value="4">4 vCore</option>
                                                                            <option value="6">6 vCore</option>
                                                                            <option value="8">8 vCore</option>
                                                                            <option value="10">10 vCore</option>
                                                                            <option value="12">12 vCore</option>
                                                                            <option value="14">14 vCore</option>
                                                                            <option value="16">16 vCore</option>
                                                                            <option value="18">18 vCore</option>
                                                                            <option value="20">20 vCore</option>
                                                                            <option value="24">24 vCore</option>
                                                                            <option value="32">32 vCore</option>
                                                                            <option value="40">40 vCore</option>
                                                                            <option value="80">80 vCore</option>
                                                                            <option value="128">128 vCore</option>
                                                                            
                                                                            </select>
                                                                        </div>
                                                                        <div className="row py-4 mx-4 px-5">    
                                                                            <h5 style={{ marginTop: "15px", marginBottom: "20px", color: "black", fontSize: "18px" }}>Compute</h5>
                                                                        </div>

                                                                        <div>
                                                                            <label htmlFor="zoneRedundancy" className="form-label font-14 font-medium black-v2 mb-3">
                                                                            Redundancy
                                                                            </label>
                                                                            <select
                                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                id="zoneRedundancy"
                                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                value={values.zoneRedundancy}
                                                                                name={"zoneRedundancy"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} 
                                                                                disabled={isViewer}
                                                                            >
                                                                            <option value="local">Locally Redundant</option>
                                                                            <option value="zone">Zone Redundant</option>
                                                                            
                                                                            </select>
                                                                        </div>
                                                                        
                                                                        


                                                                    </>
                                                                ) : values.generation === "fsv2" ? (
                                                                    <>
                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="instanceSize" className="form-label font-14 font-medium black-v2 mb-3">
                                                                            Instance
                                                                            </label>
                                                                            <select
                                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                id="instanceSize"
                                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                value={values.instanceSize}
                                                                                name={"instanceSize"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} 
                                                                                disabled={isViewer}
                                                                            >
                                                                            <option value="8">8 vCore</option>
                                                                            <option value="10">10 vCore</option>
                                                                            <option value="12">12 vCore</option>
                                                                            <option value="14">14 vCore</option>
                                                                            <option value="16">16 vCore</option>
                                                                            <option value="18">18 vCore</option>
                                                                            <option value="20">20 vCore</option>
                                                                            <option value="24">24 vCore</option>
                                                                            <option value="32">32 vCore</option>
                                                                            <option value="36">36 vCore</option>
                                                                            <option value="72">72 vCore</option>

                                                                            
                                                                            </select>
                                                                        </div>
                                                                        <div className="row py-4 mx-4 px-5">    
                                                                            <h5 style={{ marginTop: "15px", marginBottom: "20px", color: "black", fontSize: "18px" }}>Compute</h5>
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <>  
                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="instanceSize" className="form-label font-14 font-medium black-v2 mb-3">
                                                                            Instance
                                                                            </label>
                                                                            <select
                                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                id="instanceSize"
                                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                value={values.instanceSize}
                                                                                name={"instanceSize"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} 
                                                                                disabled={isViewer}
                                                                            >
                                                                           <option value="2">2 vCore</option>
                                                                           <option value="4">4 vCore</option>
                                                                           <option value="6">6 vCore</option>
                                                                           <option value="8">8 vCore</option>
                                                                           <option value="10">10 vCore</option>
                                                                           <option value="12">12 vCore</option>
                                                                           <option value="14">14 vCore</option>
                                                                           <option value="16">16 vCore</option>
                                                                           <option value="18">18 vCore</option>
                                                                           <option value="20">20 vCore</option>
                                                                           <option value="32">32 vCore</option>
                                                                           <option value="40">40 vCore</option>
                                                                           
                                                                            
                                                                            </select>
                                                                        </div>
                                                                        <div className="row py-4 mx-4 px-5">    
                                                                            <h5 style={{ marginTop: "15px", marginBottom: "20px", color: "black", fontSize: "18px" }}>Compute</h5>
                                                                        </div>
                                                                    </>
                                                                )}
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="elasticCount" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Pools
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                                        id="elasticCount"
                                                                        value={values.elasticCount}
                                                                        name={"elasticCount"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}

                                                                    />
                                                                </div>

                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="hours" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Hours
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                                        id="hours"
                                                                        value={values.hours}
                                                                        name={"hours"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}

                                                                    />
                                                                </div>

                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="hoursFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Hours Factor
                                                                    </label>
                                                                    <select
                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                        id="hoursFactor"
                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                        value={values.hoursFactor}
                                                                        name={"hoursFactor"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} 
                                                                        disabled={isViewer}
                                                                    >
                                                                    <option value="1" class="">Hours</option>
                                                                    <option value="24" class="">Days</option>
                                                                    <option value="730" class="">Month</option>
                                                                    </select>
                                                                </div>  

                                                                <div className="row py-4 mx-4 px-5">    
                                                                    <h5 style={{ marginTop: "15px", marginBottom: "20px", color: "black", fontSize: "18px" }}>Storage</h5>
                                                                </div>

                                                                        

                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="elasticStorageUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Data (GB)
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                                        id="elasticStorageUnits"
                                                                        value={values.elasticStorageUnits}
                                                                        name={"elasticStorageUnits"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}

                                                                    />
                                                                </div>

                                                                <h6 style={{ marginTop: "15px", marginBottom: "20px"}}>Backup Storage</h6>

                                                                <div >
                                                                    <label htmlFor="redundancy" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Redundancy
                                                                    </label>
                                                                    <select
                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                        id="redundancy"
                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                        value={values.redundancy}
                                                                        name={"redundancy"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} 
                                                                        disabled={isViewer}
                                                                    >
                                                                    <option value="lrs">LRS</option>
                                                                    <option value="zrs">ZRS</option>
                                                                    <option value="ra-grs">RA-GRS</option>
                                                                    </select>
                                                                </div>  

                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="backupStorageSize" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Point-In-Time Restore in (GB)
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                        id="backupStorageSize"
                                                                        value={values.backupStorageSize}
                                                                        name={"backupStorageSize"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}

                                                                    />
                                                                </div>

                                                                <h6 style={{ marginTop: "15px", marginBottom: "20px"}}>Long Term Retention</h6>                                                                

                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="ltrDatabaseSize" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Average database size during retention period
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                                        id="ltrDatabaseSize"
                                                                        value={values.ltrDatabaseSize}
                                                                        name={"ltrDatabaseSize"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}

                                                                    />
                                                                </div>

                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="ltrDatabaseSizeFactor" className="form-label font-14 font-medium black-v2 mb-3"  style={{marginTop:"6%"}}>
                                                                    Unit
                                                                    </label>
                                                                    <select
                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                        id="ltrDatabaseSizeFactor"
                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                        value={values.ltrDatabaseSizeFactor}
                                                                        name={"ltrDatabaseSizeFactor"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} 
                                                                        disabled={isViewer}
                                                                    >
                                                                    <option value="1" class="">GB</option>
                                                                    <option value="1024" class="">TB</option>
                                                                    </select>
                                                                </div> 

                                                                <h6 style={{ marginTop: "15px"}}>Retention Policy</h6> 

                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="WeeklyBackupRetention" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Weekly Backup Retention
                                                                    </label>
                                                                    <select
                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                        id="WeeklyBackupRetention"
                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                        value={values.WeeklyBackupRetention}
                                                                        name={"WeeklyBackupRetention"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} 
                                                                        disabled={isViewer}
                                                                    >
                                                                    <option value="yes" class="">Yes</option>
                                                                    <option value="no" class="">No</option>
                                                                    </select>
                                                                </div> 

                                                                {values.WeeklyBackupRetention === 'yes' ? (
                                                                    <div className="col-md-6 px-3">
                                                                        <label htmlFor="weeklyBackups" className="form-label font-14 font-medium black-v2 mb-3">
                                                                        Average database size during retention period
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className=" form-control  custom-form text-field font-14 font-medium"
                                                                            id="weeklyBackups"
                                                                            value={values.weeklyBackups}
                                                                            name={"weeklyBackups"}
                                                                            onChange={(e) => {
                                                                                onChangeOfConfiguration(e, values.instanceName);
                                                                            }} disabled={isViewer}

                                                                        />
                                                                    </div>
                                                                ): (null)}
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="MonthlyBackupRetention" className="form-label font-14 font-medium black-v2 mb-3"  style={{marginTop:"6%"}}>
                                                                    Monthly Backup Retention
                                                                    </label>
                                                                    <select
                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                        id="MonthlyBackupRetention"
                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                        value={values.MonthlyBackupRetention}
                                                                        name={"MonthlyBackupRetention"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} 
                                                                        disabled={isViewer}
                                                                    >
                                                                    <option value="yes" class="">Yes</option>
                                                                    <option value="no" class="">No</option>
                                                                    </select>
                                                                </div> 

                                                                {values.MonthlyBackupRetention === 'yes' ? (
                                                                    <div className="col-md-6 px-3">
                                                                        <label htmlFor="monthlyBackups" className="form-label font-14 font-medium black-v2 mb-3">
                                                                        Average database size during retention period
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className=" form-control  custom-form text-field font-14 font-medium"
                                                                            id="monthlyBackups"
                                                                            value={values.monthlyBackups}
                                                                            name={"monthlyBackups"}
                                                                            onChange={(e) => {
                                                                                onChangeOfConfiguration(e, values.instanceName);
                                                                            }} disabled={isViewer}

                                                                        />
                                                                    </div>
                                                                ): (null)}
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="YearlyBackupRetention" className="form-label font-14 font-medium black-v2 mb-3"  style={{marginTop:"6%"}}>
                                                                    Yearly Backup Retention  
                                                                    </label>
                                                                    <select
                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                        id="YearlyBackupRetention"
                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                        value={values.YearlyBackupRetention}
                                                                        name={"YearlyBackupRetention"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} 
                                                                        disabled={isViewer}
                                                                    >
                                                                    <option value="yes" class="">Yes</option>
                                                                    <option value="no" class="">No</option>
                                                                    </select>
                                                                </div> 

                                                                {values.YearlyBackupRetention === 'yes' ? (
                                                                    <div className="col-md-6 px-3">
                                                                        <label htmlFor="yearlyBackups" className="form-label font-14 font-medium black-v2 mb-3">
                                                                        Average database size during retention period
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className=" form-control  custom-form text-field font-14 font-medium"
                                                                            id="yearlyBackups"
                                                                            value={values.yearlyBackups}
                                                                            name={"yearlyBackups"}
                                                                            onChange={(e) => {
                                                                                onChangeOfConfiguration(e, values.instanceName);
                                                                            }} disabled={isViewer}

                                                                        />
                                                                    </div>
                                                                ): (null)}



                                                            </>
                                                        ) : values.vcoreTier === "business-critical" ? (
                                                            <>
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="generation" className="form-label font-14 font-medium black-v2 mb-3"> Hardware Type
                                                                    </label>
                                                                    <select
                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                        id="generation"
                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                        value={values.generation}
                                                                        name={"generation"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} 
                                                                        disabled={isViewer}
                                                                    >
                                                                      <option value="gen5">Standard-series (Gen 5)</option>
                                                                      <option value="dc">DC-series</option>

                                                                    </select>
                                                                </div>
                                                                {values.generation === "gen5" ? (
                                                                    <>
                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="instanceSize" className="form-label font-14 font-medium black-v2 mb-3">
                                                                            Instance
                                                                            </label>
                                                                            <select
                                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                id="instanceSize"
                                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                value={values.instanceSize}
                                                                                name={"instanceSize"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} 
                                                                                disabled={isViewer}
                                                                            >
                                                                            <option value="4">4 vCore</option>
                                                                            <option value="6">6 vCore</option>
                                                                            <option value="8">8 vCore</option>
                                                                            <option value="10">10 vCore</option>
                                                                            <option value="12">12 vCore</option>
                                                                            <option value="14">14 vCore</option>
                                                                            <option value="16">16 vCore</option>
                                                                            <option value="18">18 vCore</option>
                                                                            <option value="20">20 vCore</option>
                                                                            <option value="24">24 vCore</option>
                                                                            <option value="32">32 vCore</option>
                                                                            <option value="40">40 vCore</option>
                                                                            <option value="80">80 vCore</option>
                                                                            <option value="128">128 vCore</option>
                                                                            
                                                                            </select>
                                                                        </div>

                                                                    </>
                                                                ):( <>
                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="instanceSize" className="form-label font-14 font-medium black-v2 mb-3">
                                                                            Instance
                                                                            </label>
                                                                            <select
                                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                id="instanceSize"
                                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                value={values.instanceSize}
                                                                                name={"instanceSize"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} 
                                                                                disabled={isViewer}
                                                                            >
                                                                          <option value="2" class="">2 vCore</option>
                                                                          <option value="4" class="">4 vCore</option>
                                                                          <option value="6" class="">6 vCore</option>
                                                                          <option value="8" class="">8 vCore</option>
                                                                          <option value="10" class="">10 vCore</option>
                                                                          <option value="12" class="">12 vCore</option>
                                                                          <option value="14" class="">14 vCore</option>
                                                                          <option value="16" class="">16 vCore</option>
                                                                          <option value="18" class="">18 vCore</option>
                                                                          <option value="20" class="">20 vCore</option>
                                                                          <option value="32" class="">32 vCore</option>
                                                                          <option value="40" class="">40 vCore</option>

                                                                            
                                                                            
                                                                            </select>
                                                                        </div>
                                                                    

                                                                    </>
                                                                )}
                                                                <div className="row py-4 mx-4 px-5">    
                                                                    <h5 style={{ marginTop: "15px", marginBottom: "20px", color: "black", fontSize: "18px" }}>Compute</h5>
                                                                </div>

                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="elasticCount" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Pools
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                                        id="elasticCount"
                                                                        value={values.elasticCount}
                                                                        name={"elasticCount"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}

                                                                    />
                                                                </div>

                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="hours" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Hours
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                                        id="hours"
                                                                        value={values.hours}
                                                                        name={"hours"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}

                                                                    />
                                                                </div>

                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="hoursFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Hours Factor
                                                                    </label>
                                                                    <select
                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                        id="hoursFactor"
                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                        value={values.hoursFactor}
                                                                        name={"hoursFactor"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} 
                                                                        disabled={isViewer}
                                                                    >
                                                                    <option value="1" class="">Hours</option>
                                                                    <option value="24" class="">Days</option>
                                                                    <option value="730" class="">Month</option>
                                                                    </select>
                                                                </div>  

                                                                <div className="row py-4 mx-4 px-5">    
                                                                    <h5 style={{ marginTop: "15px", marginBottom: "20px", color: "black", fontSize: "18px" }}>Storage</h5>
                                                                </div>

                                                                        

                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="elasticStorageUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Data (GB)
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                                        id="elasticStorageUnits"
                                                                        value={values.elasticStorageUnits}
                                                                        name={"elasticStorageUnits"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}

                                                                    />
                                                                </div>

                                                                <h6 style={{ marginTop: "15px", marginBottom: "20px"}}>Backup Storage</h6>

                                                                <div>
                                                                    <label htmlFor="redundancy" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Redundancy
                                                                    </label>
                                                                    <select
                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                        id="redundancy"
                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                        value={values.redundancy}
                                                                        name={"redundancy"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} 
                                                                        disabled={isViewer}
                                                                    >
                                                                    <option value="lrs">LRS</option>
                                                                    <option value="zrs">ZRS</option>
                                                                    <option value="ra-grs">RA-GRS</option>
                                                                    </select>
                                                                </div>  

                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="backupStorageSize" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Point-In-Time Restore in (GB)
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                        id="backupStorageSize"
                                                                        value={values.backupStorageSize}
                                                                        name={"backupStorageSize"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}

                                                                    />
                                                                </div>

                                                                <h6 style={{ marginTop: "15px", marginBottom: "20px"}}>Long Term Retention</h6>   

                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="ltrDatabaseSize" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Average database size during retention period
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                                        id="ltrDatabaseSize"
                                                                        value={values.ltrDatabaseSize}
                                                                        name={"ltrDatabaseSize"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}

                                                                    />
                                                                </div>

                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="ltrDatabaseSizeFactor" className="form-label font-14 font-medium black-v2 mb-3"  style={{marginTop:"6%"}}>
                                                                    Unit
                                                                    </label>
                                                                    <select
                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                        id="ltrDatabaseSizeFactor"
                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                        value={values.ltrDatabaseSizeFactor}
                                                                        name={"ltrDatabaseSizeFactor"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} 
                                                                        disabled={isViewer}
                                                                    >
                                                                    <option value="1" class="">GB</option>
                                                                    <option value="1024" class="">TB</option>
                                                                    </select>
                                                                </div> 

                                                                <h6 style={{ marginTop: "15px", marginBottom: "20px"}}>Retention Policy</h6> 

                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="WeeklyBackupRetention" className="form-label font-14 font-medium black-v2 mb-3"  style={{marginTop:"6%"}}>
                                                                    Weekly Backup Retention
                                                                    </label>
                                                                    <select
                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                        id="WeeklyBackupRetention"
                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                        value={values.WeeklyBackupRetention}
                                                                        name={"WeeklyBackupRetention"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} 
                                                                        disabled={isViewer}
                                                                    >
                                                                    <option value="yes" class="">Yes</option>
                                                                    <option value="no" class="">No</option>
                                                                    </select>
                                                                </div> 

                                                                {values.WeeklyBackupRetention === 'yes' ? (
                                                                    <div className="col-md-6 px-3">
                                                                        <label htmlFor="weeklyBackups" className="form-label font-14 font-medium black-v2 mb-3">
                                                                        Average database size during retention period
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className=" form-control  custom-form text-field font-14 font-medium"
                                                                            id="weeklyBackups"
                                                                            value={values.weeklyBackups}
                                                                            name={"weeklyBackups"}
                                                                            onChange={(e) => {
                                                                                onChangeOfConfiguration(e, values.instanceName);
                                                                            }} disabled={isViewer}

                                                                        />
                                                                    </div>
                                                                ): (null)}
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="MonthlyBackupRetention" className="form-label font-14 font-medium black-v2 mb-3"  style={{marginTop:"6%"}}>
                                                                    Monthly Backup Retention
                                                                    </label>
                                                                    <select
                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                        id="MonthlyBackupRetention"
                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                        value={values.MonthlyBackupRetention}
                                                                        name={"MonthlyBackupRetention"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} 
                                                                        disabled={isViewer}
                                                                    >
                                                                    <option value="yes" class="">Yes</option>
                                                                    <option value="no" class="">No</option>
                                                                    </select>
                                                                </div> 

                                                                {values.MonthlyBackupRetention === 'yes' ? (
                                                                    <div className="col-md-6 px-3">
                                                                        <label htmlFor="monthlyBackups" className="form-label font-14 font-medium black-v2 mb-3">
                                                                        Average database size during retention period
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className=" form-control  custom-form text-field font-14 font-medium"
                                                                            id="monthlyBackups"
                                                                            value={values.monthlyBackups}
                                                                            name={"monthlyBackups"}
                                                                            onChange={(e) => {
                                                                                onChangeOfConfiguration(e, values.instanceName);
                                                                            }} disabled={isViewer}

                                                                        />
                                                                    </div>
                                                                ): (null)}
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="YearlyBackupRetention" className="form-label font-14 font-medium black-v2 mb-3"  style={{marginTop:"6%"}}>
                                                                    Yearly Backup Retention  
                                                                    </label>
                                                                    <select
                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                        id="YearlyBackupRetention"
                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                        value={values.YearlyBackupRetention}
                                                                        name={"YearlyBackupRetention"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} 
                                                                        disabled={isViewer}
                                                                    >
                                                                    <option value="yes" class="">Yes</option>
                                                                    <option value="no" class="">No</option>
                                                                    </select>
                                                                </div> 

                                                                {values.YearlyBackupRetention === 'yes' ? (
                                                                    <div className="col-md-6 px-3">
                                                                        <label htmlFor="yearlyBackups" className="form-label font-14 font-medium black-v2 mb-3">
                                                                        Average database size during retention period
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className=" form-control  custom-form text-field font-14 font-medium"
                                                                            id="yearlyBackups"
                                                                            value={values.yearlyBackups}
                                                                            name={"yearlyBackups"}
                                                                            onChange={(e) => {
                                                                                onChangeOfConfiguration(e, values.instanceName);
                                                                            }} disabled={isViewer}

                                                                        />
                                                                    </div>
                                                                ): (null)}
                                                            </>
                                                        ) : (
                                                            // this is for hyper scale
                                                            <>
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="generation" className="form-label font-14 font-medium black-v2 mb-3"> Hardware Type
                                                                    </label>
                                                                    <select
                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                        id="generation"
                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                        value={values.generation}
                                                                        name={"generation"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} 
                                                                        disabled={isViewer}
                                                                    >
                                                                      <option value="gen5">Standard-series (Gen 5)</option>
                                                                      <option value="premium">Premium</option>
                                                                      <option value="memoryoptimized">Premium-series, memory optimized</option>

                                                                    </select>
                                                                </div>
                                                            
                                                            {values.generation === "gen5" ? (
                                                                <>
                                                                    <div className="col-md-6 px-3">
                                                                        <label htmlFor="instanceSize" className="form-label font-14 font-medium black-v2 mb-3">
                                                                        Instance
                                                                        </label>
                                                                        <select
                                                                            className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                            id="instanceSize"
                                                                            style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                            value={values.instanceSize}
                                                                            name={"instanceSize"}
                                                                            onChange={(e) => {
                                                                                onChangeOfConfiguration(e, values.instanceName);
                                                                            }} 
                                                                            disabled={isViewer}
                                                                        >
                                                                        <option value="4" class="">4 vCore</option>
                                                                        <option value="6" class="">6 vCore</option>
                                                                        <option value="8" class="">8 vCore</option>
                                                                        <option value="10" class="">10 vCore</option>
                                                                        <option value="12" class="">12 vCore</option>
                                                                        <option value="14" class="">14 vCore</option>
                                                                        <option value="16" class="">16 vCore</option>
                                                                        <option value="18" class="">18 vCore</option>
                                                                        <option value="20" class="">20 vCore</option>
                                                                        <option value="24" class="">24 vCore</option>
                                                                        <option value="32" class="">32 vCore</option>
                                                                        <option value="40" class="">40 vCore</option>
                                                                        <option value="80" class="">80 vCore</option>

                                                                        </select>
                                                                    </div>
                                                                    

                                                                </>
                                                            ) : values.generation === "premium" ? (
                                                                <>
                                                                    <div className="col-md-6 px-3">
                                                                        <label htmlFor="instanceSize" className="form-label font-14 font-medium black-v2 mb-3">
                                                                        Instance
                                                                        </label>
                                                                        <select
                                                                            className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                            id="instanceSize"
                                                                            style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                            value={values.instanceSize}
                                                                            name={"instanceSize"}
                                                                            onChange={(e) => {
                                                                                onChangeOfConfiguration(e, values.instanceName);
                                                                            }} 
                                                                            disabled={isViewer}
                                                                        >
                                                                        <option value="4">4 vCore</option>
                                                                        <option value="6">6 vCore</option>
                                                                        <option value="8">8 vCore</option>
                                                                        <option value="10">10 vCore</option>
                                                                        <option value="12">12 vCore</option>
                                                                        <option value="14">14 vCore</option>
                                                                        <option value="16">16 vCore</option>
                                                                        <option value="18">18 vCore</option>
                                                                        <option value="20">20 vCore</option>
                                                                        <option value="24">24 vCore</option>
                                                                        <option value="32">32 vCore</option>
                                                                        <option value="40">40 vCore</option>
                                                                        <option value="64">64 vCore</option>
                                                                        <option value="80">80 vCore</option>
                                                                        <option value="128">128 vCore</option>


                                                                        </select>
                                                                    </div>
                                                                </>
                                                            ) :(
                                                                <>
                                                                    <div className="col-md-6 px-3">
                                                                        <label htmlFor="instanceSize" className="form-label font-14 font-medium black-v2 mb-3">
                                                                        Instance
                                                                        </label>
                                                                        <select
                                                                            className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                            id="instanceSize"
                                                                            style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                            value={values.instanceSize}
                                                                            name={"instanceSize"}
                                                                            onChange={(e) => {
                                                                                onChangeOfConfiguration(e, values.instanceName);
                                                                            }} 
                                                                            disabled={isViewer}
                                                                        >
                                                                        <option value="4">4 vCore</option>
                                                                        <option value="6">6 vCore</option>
                                                                        <option value="8">8 vCore</option>
                                                                        <option value="10">10 vCore</option>
                                                                        <option value="12">12 vCore</option>
                                                                        <option value="14">14 vCore</option>
                                                                        <option value="16">16 vCore</option>
                                                                        <option value="18">18 vCore</option>
                                                                        <option value="20">20 vCore</option>
                                                                        <option value="24">24 vCore</option>
                                                                        <option value="32">32 vCore</option>
                                                                        <option value="40">40 vCore</option>
                                                                        <option value="64">64 vCore</option>
                                                                        <option value="80">80 vCore</option>
                                                                        
                                                                        </select>
                                                                    </div>
                                                                </>
                                                            )}
                                                            <div className="row py-4 mx-4 px-5">    
                                                                <h5 style={{ marginTop: "15px", marginBottom: "20px", color: "black", fontSize: "18px" }}>Compute</h5>
                                                            </div>

                                                            <div >
                                                                <label htmlFor="zoneRedundancy" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Redundancy
                                                                </label>
                                                                <select
                                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                    id="zoneRedundancy"
                                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                    value={values.zoneRedundancy}
                                                                    name={"zoneRedundancy"}
                                                                    onChange={(e) => {
                                                                        onChangeOfConfiguration(e, values.instanceName);
                                                                    }} 
                                                                    disabled={isViewer}
                                                                >
                                                                <option value="local" class="">Locally Redundant</option><option value="zone" class="">Zone Redundant</option>

                                                                </select>
                                                            </div>
                                                            <div className="col-md-6 px-3">
                                                                <label htmlFor="elasticCount" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Pools
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                                    id="elasticCount"
                                                                    value={values.elasticCount}
                                                                    name={"elasticCount"}
                                                                    onChange={(e) => {
                                                                        onChangeOfConfiguration(e, values.instanceName);
                                                                    }} disabled={isViewer}

                                                                />
                                                            </div>

                                                            <div className="col-md-6 px-3">
                                                                <label htmlFor="hours" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Hours
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                                    id="hours"
                                                                    value={values.hours}
                                                                    name={"hours"}
                                                                    onChange={(e) => {
                                                                        onChangeOfConfiguration(e, values.instanceName);
                                                                    }} disabled={isViewer}

                                                                />
                                                            </div>

                                                            <div className="col-md-6 px-3">
                                                                <label htmlFor="hoursFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Hours Factor
                                                                </label>
                                                                <select
                                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                    id="hoursFactor"
                                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                    value={values.hoursFactor}
                                                                    name={"hoursFactor"}
                                                                    onChange={(e) => {
                                                                        onChangeOfConfiguration(e, values.instanceName);
                                                                    }} 
                                                                    disabled={isViewer}
                                                                >
                                                                <option value="1" class="">Hours</option>
                                                                <option value="24" class="">Days</option>
                                                                <option value="730" class="">Month</option>
                                                                </select>
                                                            </div>
                                                            <div className="row py-4 mx-4 px-5">    
                                                                <h5 style={{ marginTop: "15px", marginBottom: "20px", color: "black", fontSize: "18px" }}>High Availability Pool Replicas
                                                                </h5>
                                                            </div>
                                                            <div className="col-md-6 px-3">
                                                                <label htmlFor="singleHyperscaleReplicaUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                                High availability replicas per elastic pool
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                                    id="singleHyperscaleReplicaUnits"
                                                                    value={values.singleHyperscaleReplicaUnits}
                                                                    name={"singleHyperscaleReplicaUnits"}
                                                                    onChange={(e) => {
                                                                        onChangeOfConfiguration(e, values.instanceName);
                                                                    }} disabled={isViewer}

                                                                />
                                                            </div>
                                                            <div className="col-md-6 px-3">
                                                                <label htmlFor="singleHyperscaleReplicaHours" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Hours
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                                    id="singleHyperscaleReplicaHours"
                                                                    value={values.singleHyperscaleReplicaHours}
                                                                    name={"singleHyperscaleReplicaHours"}
                                                                    onChange={(e) => {
                                                                        onChangeOfConfiguration(e, values.instanceName);
                                                                    }} disabled={isViewer}

                                                                />
                                                            </div>

                                                            <div className="col-md-6 px-3">
                                                                <label htmlFor="singleHyperscaleReplicaHoursFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Hours Factor
                                                                </label>
                                                                <select
                                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                    id="singleHyperscaleReplicaHoursFactor"
                                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                    value={values.singleHyperscaleReplicaHoursFactor}
                                                                    name={"singleHyperscaleReplicaHoursFactor"}
                                                                    onChange={(e) => {
                                                                        onChangeOfConfiguration(e, values.instanceName);
                                                                    }} 
                                                                    disabled={isViewer}
                                                                >
                                                                <option value="1" class="">Hours</option>
                                                                <option value="24" class="">Days</option>
                                                                <option value="730" class="">Month</option>
                                                                </select>
                                                            </div>
                                                            <div className="row py-4 mx-4 px-5">    
                                                                <h5 style={{ marginTop: "15px", marginBottom: "20px", color: "black", fontSize: "18px" }}>Storage
                                                                </h5>
                                                            </div>
                                                            <div className="col-md-6 px-3">
                                                                <label htmlFor="singleHyperscaleReplicaHours" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Storage Unit
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                                    id="singleHyperscaleReplicaHours"
                                                                    value={values.singleHyperscaleReplicaHours}
                                                                    name={"singleHyperscaleReplicaHours"}
                                                                    onChange={(e) => {
                                                                        onChangeOfConfiguration(e, values.instanceName);
                                                                    }} disabled={isViewer}

                                                                />
                                                            </div>
                                                            <div className="col-md-6 px-3">
                                                                <label htmlFor="singleHyperscaleStorageUnitsFactor" className="form-label font-14 font-medium black-v2 mb-3"  style={{marginTop:"6%"}}>
                                                                Unit Factor
                                                                </label>
                                                                <select
                                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                    id="singleHyperscaleStorageUnitsFactor"
                                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                    value={values.singleHyperscaleStorageUnitsFactor}
                                                                    name={"singleHyperscaleStorageUnitsFactor"}
                                                                    onChange={(e) => {
                                                                        onChangeOfConfiguration(e, values.instanceName);
                                                                    }} 
                                                                    disabled={isViewer}
                                                                >
                                                                <option value="1">GB</option>
                                                                <option value="1024">TB</option>
                                                                </select>
                                                            </div>
                                                            <h6 style={{ marginTop: "15px", marginBottom: "20px"}}>Backup Storage</h6>
                                                                <div>
                                                                    <label htmlFor="redundancy" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Redundancy
                                                                    </label>
                                                                    <select
                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                        id="redundancy"
                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                        value={values.redundancy}
                                                                        name={"redundancy"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} 
                                                                        disabled={isViewer}
                                                                    >
                                                                    <option value="lrs">LRS</option>
                                                                    <option value="zrs">ZRS</option>
                                                                    <option value="ra-grs">RA-GRS</option>
                                                                    <option value="ra-gzrs">RA-GZRS</option>
                                                                        
                                                                    </select>
                                                                </div>
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="backupStorageSize" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Point-In-Time Restore in (GB)
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                        id="backupStorageSize"
                                                                        value={values.backupStorageSize}
                                                                        name={"backupStorageSize"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}

                                                                    />
                                                                </div>

                                                                <h6 style={{ marginTop: "15px", marginBottom: "20px"}}>Long Term Retention</h6>   

                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="ltrDatabaseSize" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Average database size during retention period
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                                        id="ltrDatabaseSize"
                                                                        value={values.ltrDatabaseSize}
                                                                        name={"ltrDatabaseSize"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}

                                                                    />
                                                                </div>

                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="ltrDatabaseSizeFactor" className="form-label font-14 font-medium black-v2 mb-3"  style={{marginTop:"6%"}}>
                                                                    Unit
                                                                    </label>
                                                                    <select
                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                        id="ltrDatabaseSizeFactor"
                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                        value={values.ltrDatabaseSizeFactor}
                                                                        name={"ltrDatabaseSizeFactor"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} 
                                                                        disabled={isViewer}
                                                                    >
                                                                    <option value="1" class="">GB</option>
                                                                    <option value="1024" class="">TB</option>
                                                                    </select>
                                                                </div> 

                                                                <h6 style={{ marginTop: "15px", marginBottom: "20px"}}>Retention Policy</h6> 

                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="WeeklyBackupRetention" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Weekly Backup Retention
                                                                    </label>
                                                                    <select
                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                        id="WeeklyBackupRetention"
                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                        value={values.WeeklyBackupRetention}
                                                                        name={"WeeklyBackupRetention"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} 
                                                                        disabled={isViewer}
                                                                    >
                                                                    <option value="yes" class="">Yes</option>
                                                                    <option value="no" class="">No</option>
                                                                    </select>
                                                                </div> 

                                                                {values.WeeklyBackupRetention === 'yes' ? (
                                                                    <div className="col-md-6 px-3">
                                                                        <label htmlFor="weeklyBackups" className="form-label font-14 font-medium black-v2 mb-3">
                                                                        Average database size during retention period
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className=" form-control  custom-form text-field font-14 font-medium"
                                                                            id="weeklyBackups"
                                                                            value={values.weeklyBackups}
                                                                            name={"weeklyBackups"}
                                                                            onChange={(e) => {
                                                                                onChangeOfConfiguration(e, values.instanceName);
                                                                            }} disabled={isViewer}

                                                                        />
                                                                    </div>
                                                                ): (null)}
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="MonthlyBackupRetention" className="form-label font-14 font-medium black-v2 mb-3"  style={{marginTop:"6%"}}>
                                                                    Monthly Backup Retention
                                                                    </label>
                                                                    <select
                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                        id="MonthlyBackupRetention"
                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                        value={values.MonthlyBackupRetention}
                                                                        name={"MonthlyBackupRetention"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} 
                                                                        disabled={isViewer}
                                                                    >
                                                                    <option value="yes" class="">Yes</option>
                                                                    <option value="no" class="">No</option>
                                                                    </select>
                                                                </div> 

                                                                {values.MonthlyBackupRetention === 'yes' ? (
                                                                    <div className="col-md-6 px-3">
                                                                        <label htmlFor="monthlyBackups" className="form-label font-14 font-medium black-v2 mb-3">
                                                                        Average database size during retention period
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className=" form-control  custom-form text-field font-14 font-medium"
                                                                            id="monthlyBackups"
                                                                            value={values.monthlyBackups}
                                                                            name={"monthlyBackups"}
                                                                            onChange={(e) => {
                                                                                onChangeOfConfiguration(e, values.instanceName);
                                                                            }} disabled={isViewer}

                                                                        />
                                                                    </div>
                                                                ): (null)}
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="YearlyBackupRetention" className="form-label font-14 font-medium black-v2 mb-3"  style={{marginTop:"6%"}}>
                                                                    Yearly Backup Retention  
                                                                    </label>
                                                                    <select
                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                        id="YearlyBackupRetention"
                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                        value={values.YearlyBackupRetention}
                                                                        name={"YearlyBackupRetention"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} 
                                                                        disabled={isViewer}
                                                                    >
                                                                    <option value="yes" class="">Yes</option>
                                                                    <option value="no" class="">No</option>
                                                                    </select>
                                                                </div> 

                                                                {values.YearlyBackupRetention === 'yes' ? (
                                                                    <div className="col-md-6 px-3">
                                                                        <label htmlFor="yearlyBackups" className="form-label font-14 font-medium black-v2 mb-3">
                                                                        Average database size during retention period
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className=" form-control  custom-form text-field font-14 font-medium"
                                                                            id="yearlyBackups"
                                                                            value={values.yearlyBackups}
                                                                            name={"yearlyBackups"}
                                                                            onChange={(e) => {
                                                                                onChangeOfConfiguration(e, values.instanceName);
                                                                            }} disabled={isViewer}

                                                                        />
                                                                    </div>
                                                                ): (null)}  
                                                            </>
                                                        )}
                                                    </>
                                                ):(
                                                    <>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="dtuTier" className="form-label font-14 font-medium black-v2 mb-3"> Service Tier
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                id="dtuTier"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.dtuTier}
                                                                name={"dtuTier"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} 
                                                                disabled={isViewer}
                                                            >
                                                                <option value="basic">Basic</option>
                                                                <option value="standard">Standard</option>
                                                                <option value="premium">Premium</option>

                                                            </select>
                                                        </div>
                                                        {values.dtuTier === "basic" ? (
                                                            <>
                                                               <div className="col-md-6 px-3">
                                                                    <label htmlFor="elasticPerformanceLevel" className="form-label font-14 font-medium black-v2 mb-3"> Performance Level
                                                                    </label>
                                                                    <select
                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                        id="elasticPerformanceLevel"
                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                        value={values.elasticPerformanceLevel}
                                                                        name={"elasticPerformanceLevel"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} 
                                                                        disabled={isViewer}
                                                                    >
                                                                       <option value="50">50 eDTUs: 5 GB included storage per pool, 100 DBs per pool</option>
                                                                       <option value="100">100 eDTUs: 10 GB included storage per pool, 200 DBs per pool</option>
                                                                       <option value="200">200 eDTUs: 20 GB included storage per pool, 500 DBs per pool</option>
                                                                       <option value="300">300 eDTUs: 29 GB included storage per pool, 500 DBs per pool</option>
                                                                       <option value="400">400 eDTUs: 39 GB included storage per pool, 500 DBs per pool</option>
                                                                       <option value="800">800 eDTUs: 78 GB included storage per pool, 500 DBs per pool</option>
                                                                       <option value="1200">1200 eDTUs: 117 GB included storage per pool, 500 DBs per pool</option>
                                                                       <option value="1600">1600 eDTUs: 156 GB included storage per pool, 500 DBs per pool</option>


                                                                    </select>
                                                                </div> 
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="elasticCount" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Pools
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        style={{paddingTop :"10px"}}
                                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                                        id="elasticCount"
                                                                        value={values.elasticCount}
                                                                        name={"elasticCount"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}

                                                                    />
                                                                </div>
                                                                <div className="col-md-6 px-3">
                                                                <label htmlFor="hours" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Hours
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    style={{paddingTop :"10px"}}
                                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                                    id="hours"
                                                                    value={values.hours}
                                                                    name={"hours"}
                                                                    onChange={(e) => {
                                                                        onChangeOfConfiguration(e, values.instanceName);
                                                                    }} disabled={isViewer}

                                                                />
                                                            </div>

                                                            <div className="col-md-6 px-3">
                                                                <label htmlFor="hoursFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Hours Factor
                                                                </label>
                                                                <select
                                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                    id="hoursFactor"
                                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                    value={values.hoursFactor}
                                                                    name={"hoursFactor"}
                                                                    onChange={(e) => {
                                                                        onChangeOfConfiguration(e, values.instanceName);
                                                                    }} 
                                                                    disabled={isViewer}
                                                                >
                                                                <option value="1" class="">Hours</option>
                                                                <option value="24" class="">Days</option>
                                                                <option value="730" class="">Month</option>
                                                                </select>
                                                            </div>
                                                            
                                                            </>
                                                        ): values.dtuTier === "standard" ? (
                                                            <>
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="elasticPerformanceLevel" className="form-label font-14 font-medium black-v2 mb-3"> Performance Level
                                                                    </label>
                                                                    <select
                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                        id="elasticPerformanceLevel"
                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                        value={values.elasticPerformanceLevel}
                                                                        name={"elasticPerformanceLevel"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} 
                                                                        disabled={isViewer}
                                                                    >
                                                                    <option value="50">50 eDTUs: 50 GB included storage per pool, 100 DBs per pool</option>
                                                                    <option value="100">100 eDTUs: 100 GB included storage per pool, 200 DBs per pool</option>
                                                                    <option value="200">200 eDTUs: 200 GB included storage per pool, 500 DBs per pool</option>
                                                                    <option value="300">300 eDTUs: 300 GB included storage per pool, 500 DBs per pool</option>
                                                                    <option value="400">400 eDTUs: 400 GB included storage per pool, 500 DBs per pool</option>
                                                                    <option value="800">800 eDTUs: 800 GB included storage per pool, 500 DBs per pool</option>
                                                                    <option value="1200">1200 eDTUs: 1200 GB included storage per pool, 500 DBs per pool</option>
                                                                    <option value="1600">1600 eDTUs: 1600 GB included storage per pool, 500 DBs per pool</option>
                                                                    <option value="2000">2000 eDTUs: 2000 GB included storage per pool, 500 DBs per pool</option>
                                                                    <option value="2500">2500 eDTUs: 2500 GB included storage per pool, 500 DBs per pool</option>
                                                                    <option value="3000">3000 eDTUs: 3000 GB included storage per pool, 500 DBs per pool</option>



                                                                    </select>
                                                                </div> 
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="elasticCount" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Pools
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        style={{paddingTop :"10px"}}
                                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                                        id="elasticCount"
                                                                        value={values.elasticCount}
                                                                        name={"elasticCount"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}

                                                                    />
                                                                </div>
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="hours" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Hours
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        style={{paddingTop :"10px"}}
                                                                        className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                        id="hours"
                                                                        value={values.hours}
                                                                        name={"hours"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}

                                                                    />
                                                                </div>

                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="hoursFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Hours Factor
                                                                    </label>
                                                                    <select
                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                        id="hoursFactor"
                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                        value={values.hoursFactor}
                                                                        name={"hoursFactor"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} 
                                                                        disabled={isViewer}
                                                                    >
                                                                    <option value="1" class="">Hours</option>
                                                                    <option value="24" class="">Days</option>
                                                                    <option value="730" class="">Month</option>
                                                                    </select>
                                                                </div>
                                                                <div>
                                                                    <label htmlFor="storageMax" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Storage
                                                                    </label>
                                                                    <select
                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                        id="storageMax"
                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                        value={values.storageMax}
                                                                        name={"storageMax"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} 
                                                                        disabled={isViewer}
                                                                    >
                                                                    <option value="50" class="">50 GB</option>
                                                                    <option value="250" class="">250 GB</option>
                                                                    <option value="500" class="">500 GB</option>
                                                                    </select>
                                                                </div>
                                                            </>
                                                        ): 
                                                            <>
                                                                 <div className="col-md-6 px-3">
                                                                    <label htmlFor="dtuTier" className="form-label font-14 font-medium black-v2 mb-3"> Performance Level
                                                                    </label>
                                                                    <select
                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                        id="dtuTier"
                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                        value={values.dtuTier}
                                                                        name={"dtuTier"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} 
                                                                        disabled={isViewer}
                                                                    >
                                                                    <option value="125">125 eDTUs: 250 GB included storage per pool, 50 DBs per pool</option>
                                                                    <option value="250">250 eDTUs: 500 GB included storage per pool, 100 DBs per pool</option>
                                                                    <option value="500">500 eDTUs: 750 GB included storage per pool, 100 DBs per pool</option>
                                                                    <option value="1000">1000 eDTUs: 1024 GB included storage per pool, 100 DBs per pool</option>
                                                                    <option value="1500">1500 eDTUs: 1536 GB included storage per pool, 100 DBs per pool</option>
                                                                    <option value="2000">2000 eDTUs: 2048 GB included storage per pool, 100 DBs per pool</option>
                                                                    <option value="2500">2500 eDTUs: 2560 GB included storage per pool, 100 DBs per pool</option>
                                                                    <option value="3000">3000 eDTUs: 3072 GB included storage per pool, 100 DBs per pool</option>
                                                                    <option value="3500">3500 eDTUs: 3584 GB included storage per pool, 100 DBs per pool</option>
                                                                    <option value="4000">4000 eDTUs: 4096 GB included storage per pool, 100 DBs per pool</option>




                                                                    </select>
                                                                </div> 
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="elasticCount" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Pools
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                        id="elasticCount"
                                                                        value={values.elasticCount}
                                                                        name={"elasticCount"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}

                                                                    />
                                                                </div>
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="hours" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Hours
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        style={{paddingTop :"10px"}}
                                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                                        id="hours"
                                                                        value={values.hours}
                                                                        name={"hours"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}
                                                                    />
                                                                </div>

                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="hoursFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Hours Factor
                                                                    </label>
                                                                    <select
                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                        id="hoursFactor"
                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                        value={values.hoursFactor}
                                                                        name={"hoursFactor"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} 
                                                                        disabled={isViewer}
                                                                    >
                                                                    <option value="1" class="">Hours</option>
                                                                    <option value="24" class="">Days</option>
                                                                    <option value="730" class="">Month</option>
                                                                    </select>
                                                                </div>
                                                                <div>
                                                                    <label htmlFor="storageMax" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Storage
                                                                    </label>
                                                                    <select
                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                        id="storageMax"
                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                        value={values.storageMax}
                                                                        name={"storageMax"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} 
                                                                        disabled={isViewer}
                                                                    >
                                                                    <option value="250" class="">250 GB</option>
                                                                    <option value="500" class="">500 GB</option>
                                                                    <option value="750" class="">750 GB</option>
                                                                    <option value="1024" class="">1 TB</option>
                                                                   
                                                                    </select>
                                                                </div>
                                                            </>
                                                        }
                                                        <h6 style={{ marginTop: "15px", marginBottom: "20px"}}>Backup Storage</h6>
                                                            <div >
                                                                <label htmlFor="redundancy" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Redundancy
                                                                </label>
                                                                <select
                                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                    id="redundancy"
                                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                    value={values.redundancy}
                                                                    name={"redundancy"}
                                                                    onChange={(e) => {
                                                                        onChangeOfConfiguration(e, values.instanceName);
                                                                    }} 
                                                                    disabled={isViewer}
                                                                >
                                                               <option value="lrs" class="">LRS</option>
                                                               <option value="zrs" class="">ZRS</option>
                                                               <option value="ra-grs" class="">RA-GRS</option>
                                                               <option value="ra-gzrs" class="">RA-GZRS</option>
                                                                </select>
                                                            </div>
                                                            {values.redundancy !== "ra-gzrs" ? (
                                                                <>
                                                                <h6 style={{ marginTop: "15px", marginBottom: "20px"}}>Long Term Retention</h6>

                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="ltrDatabaseSize" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Average database size during retention period
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        style={{paddingTop :"10px"}}
                                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                                        id="ltrDatabaseSize"
                                                                        value={values.ltrDatabaseSize}
                                                                        name={"ltrDatabaseSize"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}

                                                                    />
                                                                </div>

                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="ltrDatabaseSizeFactor" className="form-label font-14 font-medium black-v2 mb-3"
                                                                    style ={{marginTop : "6%"}}>
                                                                    Unit
                                                                    </label>
                                                                    <select
                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                        id="ltrDatabaseSizeFactor"
                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                        value={values.ltrDatabaseSizeFactor}
                                                                        name={"ltrDatabaseSizeFactor"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} 
                                                                        disabled={isViewer}
                                                                    >
                                                                    <option value="1" class="">GB</option>
                                                                    <option value="1024" class="">TB</option>
                                                                    </select>
                                                                </div> 
                                                                <h6 style={{ marginTop: "15px", marginBottom: "20px"}}>Retention Policy</h6>

                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="WeeklyBackupRetention" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Weekly Backup Retention
                                                                    </label>
                                                                    <select
                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                        id="WeeklyBackupRetention"
                                                                        style={{ marginTop: "7%", paddingTop: "8px", paddingBottom: "8px" }}
                                                                        value={values.WeeklyBackupRetention}
                                                                        name={"WeeklyBackupRetention"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} 
                                                                        disabled={isViewer}
                                                                    >
                                                                    <option value="yes" class="">Yes</option>
                                                                    <option value="no" class="">No</option>
                                                                    </select>
                                                                </div> 

                                                                {values.WeeklyBackupRetention === 'yes' ? (
                                                                    <div className="col-md-6 px-3">
                                                                        <label htmlFor="weeklyBackups" className="form-label font-14 font-medium black-v2 mb-3">
                                                                        Average database size during retention period
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className=" form-control  custom-form text-field font-14 font-medium"
                                                                            id="weeklyBackups"
                                                                            value={values.weeklyBackups}
                                                                            name={"weeklyBackups"}
                                                                            onChange={(e) => {
                                                                                onChangeOfConfiguration(e, values.instanceName);
                                                                            }} disabled={isViewer}

                                                                        />
                                                                    </div>
                                                                ): (null)}
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="MonthlyBackupRetention" className="form-label font-14 font-medium black-v2 mb-3"  style={{marginTop:"6%"}}>
                                                                    Monthly Backup Retention
                                                                    </label>
                                                                    <select
                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                        id="MonthlyBackupRetention"
                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                        value={values.MonthlyBackupRetention}
                                                                        name={"MonthlyBackupRetention"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} 
                                                                        disabled={isViewer}
                                                                    >
                                                                    <option value="yes" class="">Yes</option>
                                                                    <option value="no" class="">No</option>
                                                                    </select>
                                                                </div> 

                                                                {values.MonthlyBackupRetention === 'yes' ? (
                                                                    <div className="col-md-6 px-3">
                                                                        <label htmlFor="monthlyBackups" className="form-label font-14 font-medium black-v2 mb-3">
                                                                        Average database size during retention period
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className=" form-control  custom-form text-field font-14 font-medium"
                                                                            id="monthlyBackups"
                                                                            value={values.monthlyBackups}
                                                                            name={"monthlyBackups"}
                                                                            onChange={(e) => {
                                                                                onChangeOfConfiguration(e, values.instanceName);
                                                                            }} disabled={isViewer}

                                                                        />
                                                                    </div>
                                                                ): (null)}
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="YearlyBackupRetention" className="form-label font-14 font-medium black-v2 mb-3"  style={{marginTop:"6%"}}>
                                                                    Yearly Backup Retention  
                                                                    </label>
                                                                    <select
                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                        id="YearlyBackupRetention"
                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                        value={values.YearlyBackupRetention}
                                                                        name={"YearlyBackupRetention"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} 
                                                                        disabled={isViewer}
                                                                    >
                                                                    <option value="yes" class="">Yes</option>
                                                                    <option value="no" class="">No</option>
                                                                    </select>
                                                                </div> 

                                                                {values.YearlyBackupRetention === 'yes' ? (
                                                                    <div className="col-md-6 px-3">
                                                                        <label htmlFor="yearlyBackups" className="form-label font-14 font-medium black-v2 mb-3">
                                                                        Average database size during retention period
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className=" form-control  custom-form text-field font-14 font-medium"
                                                                            id="yearlyBackups"
                                                                            value={values.yearlyBackups}
                                                                            name={"yearlyBackups"}
                                                                            onChange={(e) => {
                                                                                onChangeOfConfiguration(e, values.instanceName);
                                                                            }} disabled={isViewer}

                                                                        />
                                                                    </div>
                                                                ): (null)}
                                                                </>
                                                            ): (null)}

                                                </>
                                            )}
                                            </>
                                        
                                        ) : (
                                            <>
                                                <div className="col-md-6 px-3">
                                                    <label htmlFor="purchaseModel" className="form-label font-14 font-medium black-v2 mb-3"> Purchase Model
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                        id="purchaseModel"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.purchaseModel}
                                                        name={"purchaseModel"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} 
                                                        disabled={isViewer}
                                                    >
                                                        <option value="vcore">vCore</option>
                                                        <option value="dtu">DTU</option>
                                                    </select>
                                                </div>
                                                {values.purchaseModel === "vcore" ?(
                                                    <>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="vcoreTier" className="form-label font-14 font-medium black-v2 mb-3"> Service Tier   
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                id="vcoreTier"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.vcoreTier}
                                                                name={"vcoreTier"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} 
                                                                disabled={isViewer}
                                                            >
                                                                <option value="general-purpose" class="">General Purpose</option>
                                                                <option value="business-critical" class="">Business Critical</option>
                                                                <option value="hyperscale" class="">Hyperscale</option>
                                                            </select>
                                                        </div>

                                                        {values.vcoreTier === "general-purpose"? (
                                                            <>
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="computeTier" className="form-label font-14 font-medium black-v2 mb-3"> Compute Tier 
                                                                    </label>
                                                                    <select
                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                        id="computeTier"
                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                        value={values.computeTier}
                                                                        name={"computeTier"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} 
                                                                        disabled={isViewer}
                                                                    >
                                                                        <option value="provisioned" class="">Provisioned</option>
                                                                        <option value="serverless" class="">Serverless</option>
                                                                    </select>
                                                                </div>
                                                                {values.computeTier === "provisioned" ? (
                                                                    <>
                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="generation" className="form-label font-14 font-medium black-v2 mb-3"> Hardware Type
                                                                            </label>
                                                                            <select
                                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                id="generation"
                                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                value={values.generation}
                                                                                name={"generation"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} 
                                                                                disabled={isViewer}
                                                                            >
                                                                                <option value="gen5">Standard-series (Gen 5)</option>
                                                                                <option value="fsv2">Fsv2-series</option>
                                                                                <option value="dc">DC-series</option>

                                                                            </select>
                                                                        </div>
                                                                       
                                                                        {values.generation === "gen5"? 
                                                                        (
                                                                            <>
                                                                                <div className="col-md-6 px-3">
                                                                                    <label htmlFor="instanceSize" className="form-label font-14 font-medium black-v2 mb-3"> Instance
                                                                                    </label>
                                                                                    <select
                                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                        id="instanceSize"
                                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                        value={values.instanceSize}
                                                                                        name={"instanceSize"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} 
                                                                                        disabled={isViewer}
                                                                                    >
                                                                                        <option value="2">2 vCore</option>
                                                                                        <option value="4">4 vCore</option>
                                                                                        <option value="6">6 vCore</option>
                                                                                        <option value="8">8 vCore</option>
                                                                                        <option value="10">10 vCore</option>
                                                                                        <option value="12">12 vCore</option>
                                                                                        <option value="14">14 vCore</option>
                                                                                        <option value="16">16 vCore</option>
                                                                                        <option value="18">18 vCore</option>
                                                                                        <option value="20">20 vCore</option>
                                                                                        <option value="24">24 vCore</option>
                                                                                        <option value="32">32 vCore</option>
                                                                                        <option value="40">40 vCore</option>
                                                                                        <option value="80">80 vCore</option>
                                                                                        <option value="128">128 vCore</option>

                                                                                    </select>
                                                                                </div>
                                                                                <div className="col-md-6 px-3">
                                                                                    <label htmlFor="disasterRecovery" className="form-label font-14 font-medium black-v2 mb-3"> Disaster Recovery
                                                                                    </label>
                                                                                    <select
                                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                        id="disasterRecovery"
                                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                        value={values.disasterRecovery}
                                                                                        name={"disasterRecovery"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} 
                                                                                        disabled={isViewer}
                                                                                    >
                                                                                       <option value="primary-or-georeplica">Primary or Geo replica</option>
                                                                                       <option value="standby-replica">Standby replica</option>
                                                                                    </select>
                                                                                </div>
                                                                                <div className="row py-4 mx-4 px-5">    
                                                                                    <h5 style={{ marginTop: "15px", marginBottom: "20px", color: "black", fontSize: "18px" }}>Compute</h5>
                                                                                </div>

                                                                                <div>
                                                                                    <label htmlFor="zoneRedundancy" className="form-label font-14 font-medium black-v2 mb-3">
                                                                                    Redundancy
                                                                                    </label>
                                                                                    <select
                                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                        id="zoneRedundancy"
                                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                        value={values.zoneRedundancy}
                                                                                        name={"zoneRedundancy"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} 
                                                                                        disabled={isViewer}
                                                                                    >
                                                                                    <option value="local">Locally Redundant</option>
                                                                                    <option value="zone">Zone Redundant</option>
                                                                                    
                                                                                    </select>
                                                                                </div>
                                                                                

                                                                            </>
                                                                        ): values.generation === "fsv2"? 
                                                                        (
                                                                            <>
                                                                                <div className="col-md-6 px-3">
                                                                                    <label htmlFor="instanceSize" className="form-label font-14 font-medium black-v2 mb-3"> Instance
                                                                                    </label>
                                                                                    <select
                                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                        id="instanceSize"
                                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                        value={values.instanceSize}
                                                                                        name={"instanceSize"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} 
                                                                                        disabled={isViewer}
                                                                                    >
                                                                                        <option value="8" class="">8 vCore</option>
                                                                                        <option value="10" class="">10 vCore</option>
                                                                                        <option value="12" class="">12 vCore</option>
                                                                                        <option value="14" class="">14 vCore</option>
                                                                                        <option value="16" class="">16 vCore</option>
                                                                                        <option value="18" class="">18 vCore</option>
                                                                                        <option value="20" class="">20 vCore</option>
                                                                                        <option value="24" class="">24 vCore</option>
                                                                                        <option value="32" class="">32 vCore</option>
                                                                                        <option value="36" class="">36 vCore</option>
                                                                                        <option value="72" class="">72 vCore</option>


                                                                                    </select>
                                                                                </div>
                                                                                <div className="col-md-6 px-3">
                                                                                    <label htmlFor="disasterRecovery" className="form-label font-14 font-medium black-v2 mb-3"> Disaster Recovery
                                                                                    </label>
                                                                                    <select
                                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                        id="disasterRecovery"
                                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                        value={values.disasterRecovery}
                                                                                        name={"disasterRecovery"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} 
                                                                                        disabled={isViewer}
                                                                                    >
                                                                                       <option value="primary-or-georeplica">Primary or Geo replica</option>
                                                                                       <option value="standby-replica">Standby replica</option>
                                                                                    </select>
                                                                                </div>
                                                                                <div className="row py-4 mx-4 px-5">    
                                                                                    <h5 style={{ marginTop: "15px", marginBottom: "20px", color: "black", fontSize: "18px" }}>Compute</h5>
                                                                                </div>
                                                                            </>
                                                                        ): 
                                                                        (   <>
                                                                                <div className="col-md-6 px-3">
                                                                                    <label htmlFor="instanceSize" className="form-label font-14 font-medium black-v2 mb-3"> Instance
                                                                                    </label>
                                                                                    <select
                                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                        id="instanceSize"
                                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                        value={values.instanceSize}
                                                                                        name={"instanceSize"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} 
                                                                                        disabled={isViewer}
                                                                                    >
                                                                                        <option value="2" class="">2 vCore</option>
                                                                                        <option value="4" class="">4 vCore</option>
                                                                                        <option value="6" class="">6 vCore</option>
                                                                                        <option value="8" class="">8 vCore</option>
                                                                                        <option value="10" class="">10 vCore</option>
                                                                                        <option value="12" class="">12 vCore</option>
                                                                                        <option value="14" class="">14 vCore</option>
                                                                                        <option value="16" class="">16 vCore</option>
                                                                                        <option value="18" class="">18 vCore</option>
                                                                                        <option value="20" class="">20 vCore</option>
                                                                                        <option value="32" class="">32 vCore</option>
                                                                                        <option value="40" class="">40 vCore</option>
                                                                                       
                                                                                    </select>
                                                                                </div>
                                                                                <div className="col-md-6 px-3">
                                                                                    <label htmlFor="disasterRecovery" className="form-label font-14 font-medium black-v2 mb-3"> Disaster Recovery
                                                                                    </label>
                                                                                    <select
                                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                        id="disasterRecovery"
                                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                        value={values.disasterRecovery}
                                                                                        name={"disasterRecovery"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} 
                                                                                        disabled={isViewer}
                                                                                    >
                                                                                       <option value="primary-or-georeplica">Primary or Geo replica</option>
                                                                                       <option value="standby-replica">Standby replica</option>
                                                                                    </select>
                                                                                </div>
                                                                                <div className="row py-4 mx-4 px-5">    
                                                                                    <h5 style={{ marginTop: "15px", marginBottom: "20px", color: "black", fontSize: "18px" }}>Compute</h5>
                                                                                </div>
                                                                            </>

                                                                        )}
                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="singleCount" className="form-label font-14 font-medium black-v2 mb-3">
                                                                            Databases
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                                id="singleCount"
                                                                                value={values.singleCount}
                                                                                name={"singleCount"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} disabled={isViewer}

                                                                            />
                                                                        </div>
                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="hours" className="form-label font-14 font-medium black-v2 mb-3">
                                                                            Hours
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                                id="hours"
                                                                                value={values.hours}
                                                                                name={"hours"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} disabled={isViewer}

                                                                            />
                                                                        </div>

                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="hoursFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                                                            Hours Factor
                                                                            </label>
                                                                            <select
                                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                id="hoursFactor"
                                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                value={values.hoursFactor}
                                                                                name={"hoursFactor"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} 
                                                                                disabled={isViewer}
                                                                            >
                                                                            <option value="1" class="">Hours</option>
                                                                            <option value="24" class="">Days</option>
                                                                            <option value="730" class="">Month</option>
                                                                            </select>
                                                                        </div>  
                                                                        <div className="row py-4 mx-4 px-5">    
                                                                            <h5 style={{ marginTop: "15px", marginBottom: "20px", color: "black", fontSize: "18px" }}>Storage</h5>
                                                                        </div>

                                                                        

                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="singleStorageUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                                            Data (GB)
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                                id="singleStorageUnits"
                                                                                value={values.singleStorageUnits}
                                                                                name={"singleStorageUnits"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} disabled={isViewer}

                                                                            />
                                                                        </div>

                                                                        <h6 style={{ marginTop: "15px", marginBottom: "20px"}}>Backup Storage</h6>

                                                                        <div>
                                                                            <label htmlFor="redundancy" className="form-label font-14 font-medium black-v2 mb-3">
                                                                            Redundancy
                                                                            </label>
                                                                            <select
                                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                id="redundancy"
                                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                value={values.redundancy}
                                                                                name={"redundancy"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} 
                                                                                disabled={isViewer}
                                                                            >
                                                                            <option value="lrs">LRS</option>
                                                                            <option value="zrs">ZRS</option>
                                                                            <option value="ra-grs">RA-GRS</option>
                                                                            </select>
                                                                        </div>  

                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="backupStorageSize" className="form-label font-14 font-medium black-v2 mb-3">
                                                                            Point-In-Time Restore in (GB)
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                                id="backupStorageSize"
                                                                                value={values.backupStorageSize}
                                                                                name={"backupStorageSize"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} disabled={isViewer}

                                                                            />
                                                                        </div>

                                                                        <h6 style={{ marginTop: "15px", marginBottom: "20px"}}>Long Term Retention</h6>   

                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="ltrDatabaseSize" className="form-label font-14 font-medium black-v2 mb-3">
                                                                            Average database size during retention period
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                                id="ltrDatabaseSize"
                                                                                value={values.ltrDatabaseSize}
                                                                                name={"ltrDatabaseSize"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} disabled={isViewer}

                                                                            />
                                                                        </div>

                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="ltrDatabaseSizeFactor" className="form-label font-14 font-medium black-v2 mb-3"  style={{marginTop:"6%"}}>
                                                                            Unit
                                                                            </label>
                                                                            <select
                                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                id="ltrDatabaseSizeFactor"
                                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                value={values.ltrDatabaseSizeFactor}
                                                                                name={"ltrDatabaseSizeFactor"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} 
                                                                                disabled={isViewer}
                                                                            >
                                                                            <option value="1" class="">GB</option>
                                                                            <option value="1024" class="">TB</option>
                                                                            </select>
                                                                        </div> 

                                                                        <h6 style={{ marginTop: "15px", marginBottom: "20px"}}>Retention Policy</h6> 

                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="WeeklyBackupRetention" className="form-label font-14 font-medium black-v2 mb-3"  style={{marginTop:"6%"}}>
                                                                            Weekly Backup Retention
                                                                            </label>
                                                                            <select
                                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                                id="WeeklyBackupRetention"
                                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                value={values.WeeklyBackupRetention}
                                                                                name={"WeeklyBackupRetention"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} 
                                                                                disabled={isViewer}
                                                                            >
                                                                            <option value="yes" class="">Yes</option>
                                                                            <option value="no" class="">No</option>
                                                                            </select>
                                                                        </div> 

                                                                        {values.WeeklyBackupRetention === 'yes' ? (
                                                                            <div className="col-md-6 px-3">
                                                                                <label htmlFor="weeklyBackups" className="form-label font-14 font-medium black-v2 mb-3">
                                                                                Average database size during retention period
                                                                                </label>
                                                                                <input
                                                                                    type="text"
                                                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                                                    id="weeklyBackups"
                                                                                    value={values.weeklyBackups}
                                                                                    name={"weeklyBackups"}
                                                                                    onChange={(e) => {
                                                                                        onChangeOfConfiguration(e, values.instanceName);
                                                                                    }} disabled={isViewer}

                                                                                />
                                                                            </div>
                                                                        ): (null)}
                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="MonthlyBackupRetention" className="form-label font-14 font-medium black-v2 mb-3"  style={{marginTop:"6%"}}>
                                                                            Monthly Backup Retention
                                                                            </label>
                                                                            <select
                                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                                id="MonthlyBackupRetention"
                                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                value={values.MonthlyBackupRetention}
                                                                                name={"MonthlyBackupRetention"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} 
                                                                                disabled={isViewer}
                                                                            >
                                                                            <option value="yes" class="">Yes</option>
                                                                            <option value="no" class="">No</option>
                                                                            </select>
                                                                        </div> 

                                                                        {values.MonthlyBackupRetention === 'yes' ? (
                                                                            <div className="col-md-6 px-3">
                                                                                <label htmlFor="monthlyBackups" className="form-label font-14 font-medium black-v2 mb-3">
                                                                                Average database size during retention period
                                                                                </label>
                                                                                <input
                                                                                    type="text"
                                                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                                                    id="monthlyBackups"
                                                                                    value={values.monthlyBackups}
                                                                                    name={"monthlyBackups"}
                                                                                    onChange={(e) => {
                                                                                        onChangeOfConfiguration(e, values.instanceName);
                                                                                    }} disabled={isViewer}

                                                                                />
                                                                            </div>
                                                                        ): (null)}
                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="YearlyBackupRetention" className="form-label font-14 font-medium black-v2 mb-3"  style={{marginTop:"6%"}}>
                                                                            Yearly Backup Retention  
                                                                            </label>
                                                                            <select
                                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                                id="YearlyBackupRetention"
                                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                value={values.YearlyBackupRetention}
                                                                                name={"YearlyBackupRetention"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} 
                                                                                disabled={isViewer}
                                                                            >
                                                                            <option value="yes" class="">Yes</option>
                                                                            <option value="no" class="">No</option>
                                                                            </select>
                                                                        </div> 

                                                                        {values.YearlyBackupRetention === 'yes' ? (
                                                                            <div className="col-md-6 px-3">
                                                                                <label htmlFor="yearlyBackups" className="form-label font-14 font-medium black-v2 mb-3">
                                                                                Average database size during retention period
                                                                                </label>
                                                                                <input
                                                                                    type="text"
                                                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                                                    id="yearlyBackups"
                                                                                    value={values.yearlyBackups}
                                                                                    name={"yearlyBackups"}
                                                                                    onChange={(e) => {
                                                                                        onChangeOfConfiguration(e, values.instanceName);
                                                                                    }} disabled={isViewer}

                                                                                />
                                                                            </div>
                                                                        ): (null)}
                                                                    </>
                                                                ):
                                                                (
                                                                    <>
                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="generation" className="form-label font-14 font-medium black-v2 mb-3"> Compute Tier 
                                                                            </label>
                                                                            <select
                                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                id="generation"
                                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                value={values.generation}
                                                                                name={"generation"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} 
                                                                                disabled={isViewer}
                                                                            >
                                                                                <option value="gen5" class="">Standard-series (Gen 5)</option>
                                                                            </select>
                                                                        </div>
                                                                        <div className="row py-4 mx-4 px-5">    
                                                                            <h5 style={{ marginTop: "15px", marginBottom: "20px", color: "black", fontSize: "18px" }}>Compute</h5>
                                                                        </div>
                                                                        <div >
                                                                            <label htmlFor="zoneRedundancy" className="form-label font-14 font-medium black-v2 mb-3"> Redundancy 
                                                                            </label>
                                                                            <select
                                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                id="zoneRedundancy"
                                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                value={values.zoneRedundancy}
                                                                                name={"zoneRedundancy"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} 
                                                                                disabled={isViewer}
                                                                            >
                                                                                <option value="local" class="">Locally Redundant</option>
                                                                                <option value="zone" class="">Zone Redundant</option>
                                                                            </select>
                                                                        </div>

                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="maxVCores" className="form-label font-14 font-medium black-v2 mb-3"> Maximum vCores
                                                                            </label>
                                                                            <select
                                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                id="maxVCores"
                                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                value={values.maxVCores}
                                                                                name={"maxVCores"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} 
                                                                                disabled={isViewer}
                                                                            >
                                                                                <option value="1">1</option>
                                                                                <option value="2">2</option>
                                                                                <option value="4">4</option>
                                                                                <option value="6">6</option>
                                                                                <option value="8">8</option>
                                                                                <option value="10">10</option>
                                                                                <option value="12">12</option>
                                                                                <option value="14">14</option>
                                                                                <option value="16">16</option>
                                                                                <option value="18">18</option>
                                                                                <option value="20">20</option>
                                                                                <option value="24">24</option>
                                                                                <option value="32">32</option>
                                                                                <option value="40">40</option>
                                                                                <option value="80">80</option>

                                                                            </select>
                                                                        </div>
                                                                        
                                                                        {values.maxVCores === "1" ? (
                                                                            <>
                                                                                <div className="col-md-6 px-3">
                                                                                    <label htmlFor="minVCores" className="form-label font-14 font-medium black-v2 mb-3">Minimum vCores
                                                                                    </label>
                                                                                    <select
                                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                        id="minVCores"
                                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                        value={values.minVCores}
                                                                                        name={"minVCores"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} 
                                                                                        disabled={isViewer}
                                                                                    >
                                                                                        <option value="0.5" class="">0.5</option>
                                                                                        <option value="0.75" class="">0.75</option>
                                                                                        <option value="1" class="">1</option>

                                                                                    </select>
                                                                                </div>
                                                                            </>
                                                                        
                                                                        ) : values.maxVCores === "2" ? (
                                                                            <>
                                                                                <div className="col-md-6 px-3">
                                                                                    <label htmlFor="minVCores" className="form-label font-14 font-medium black-v2 mb-3">Minimum vCores
                                                                                    </label>
                                                                                    <select
                                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                        id="minVCores"
                                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                        value={values.minVCores}
                                                                                        name={"minVCores"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} 
                                                                                        disabled={isViewer}
                                                                                    >
                                                                                        <option value="0.5" class="">0.5</option>
                                                                                        <option value="0.75" class="">0.75</option>
                                                                                        <option value="1" class="">1</option>
                                                                                        <option value="1.25" class="">1.25</option>
                                                                                        <option value="1.5" class="">1.5</option>
                                                                                        <option value="1.75" class="">1.75</option>
                                                                                        <option value="2" class="">2</option>


                                                                                    </select>
                                                                                </div>
                                                                            </>
                                                                        ) : values.maxVCores === "4" ? (
                                                                            <>
                                                                                <div className="col-md-6 px-3">
                                                                                    <label htmlFor="minVCores" className="form-label font-14 font-medium black-v2 mb-3">Minimum vCores
                                                                                    </label>
                                                                                    <select
                                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                        id="minVCores"
                                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                        value={values.minVCores}
                                                                                        name={"minVCores"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} 
                                                                                        disabled={isViewer}
                                                                                    >
                                                                                        <option value="0.5" class="">0.5</option>
                                                                                        <option value="0.75" class="">0.75</option>
                                                                                        <option value="1" class="">1</option>
                                                                                        <option value="1.25" class="">1.25</option>
                                                                                        <option value="1.5" class="">1.5</option>
                                                                                        <option value="1.75" class="">1.75</option>
                                                                                        <option value="2" class="">2</option>
                                                                                        <option value="2.25" class="">2.25</option>
                                                                                        <option value="2.5" class="">2.5</option>
                                                                                        <option value="3" class="">3</option>
                                                                                        <option value="4" class="">4</option>



                                                                                    </select>
                                                                                </div>
                                                                            </>
                                                                        ) : values.maxVCores === "6" ? (
                                                                            <>
                                                                                <div className="col-md-6 px-3">
                                                                                    <label htmlFor="minVCores" className="form-label font-14 font-medium black-v2 mb-3">Minimum vCores
                                                                                    </label>
                                                                                    <select
                                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                        id="minVCores"
                                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                        value={values.minVCores}
                                                                                        name={"minVCores"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} 
                                                                                        disabled={isViewer}
                                                                                    >
                                                                                        <option value="0.75" class="">0.75</option>
                                                                                        <option value="1" class="">1</option>
                                                                                        <option value="1.25" class="">1.25</option>
                                                                                        <option value="1.5" class="">1.5</option>
                                                                                        <option value="1.75" class="">1.75</option>
                                                                                        <option value="2" class="">2</option>
                                                                                        <option value="2.25" class="">2.25</option>
                                                                                        <option value="2.5" class="">2.5</option>
                                                                                        <option value="3" class="">3</option>
                                                                                        <option value="4" class="">4</option>
                                                                                        <option value="5" class="">5</option>
                                                                                        <option value="6" class="">6</option>

                                                                                    </select>
                                                                                </div>
                                                                            </>
                                                                        ) : values.maxVCores === "8" ? (
                                                                            <>
                                                                                <div className="col-md-6 px-3">
                                                                                    <label htmlFor="minVCores" className="form-label font-14 font-medium black-v2 mb-3">Minimum vCores
                                                                                    </label>
                                                                                    <select
                                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                        id="minVCores"
                                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                        value={values.minVCores}
                                                                                        name={"minVCores"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} 
                                                                                        disabled={isViewer}
                                                                                    >
                                                                                        <option value="1" class="">1</option>
                                                                                        <option value="1.25" class="">1.25</option>
                                                                                        <option value="1.5" class="">1.5</option>
                                                                                        <option value="1.75" class="">1.75</option>
                                                                                        <option value="2" class="">2</option>
                                                                                        <option value="2.25" class="">2.25</option>
                                                                                        <option value="2.5" class="">2.5</option>
                                                                                        <option value="3" class="">3</option>
                                                                                        <option value="4" class="">4</option>
                                                                                        <option value="5" class="">5</option>
                                                                                        <option value="6" class="">6</option>
                                                                                        <option value="8" class="">8</option>


                                                                                    </select>
                                                                                </div>
                                                                            </>
                                                                        ) : values.maxVCores === "10" ? (
                                                                            <>
                                                                                <div className="col-md-6 px-3">
                                                                                    <label htmlFor="minVCores" className="form-label font-14 font-medium black-v2 mb-3">Minimum vCores
                                                                                    </label>
                                                                                    <select
                                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                        id="minVCores"
                                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                        value={values.minVCores}
                                                                                        name={"minVCores"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} 
                                                                                        disabled={isViewer}
                                                                                    >
                                                                                        <option value="1.25" class="">1.25</option>
                                                                                        <option value="1.5" class="">1.5</option>
                                                                                        <option value="1.75" class="">1.75</option>
                                                                                        <option value="2" class="">2</option>
                                                                                        <option value="2.25" class="">2.25</option>
                                                                                        <option value="2.5" class="">2.5</option>
                                                                                        <option value="3" class="">3</option>
                                                                                        <option value="4" class="">4</option>
                                                                                        <option value="5" class="">5</option>
                                                                                        <option value="6" class="">6</option>
                                                                                        <option value="8" class="">8</option>
                                                                                        <option value="10" class="">10</option>

                                                                                    </select>
                                                                                </div>
                                                                            </>
                                                                        ) : values.maxVCores === "12" ? (
                                                                            <>
                                                                                <div className="col-md-6 px-3">
                                                                                    <label htmlFor="minVCores" className="form-label font-14 font-medium black-v2 mb-3">Minimum vCores
                                                                                    </label>
                                                                                    <select
                                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                        id="minVCores"
                                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                        value={values.minVCores}
                                                                                        name={"minVCores"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} 
                                                                                        disabled={isViewer}
                                                                                    >
                                                                                        <option value="1.5" class="">1.5</option>
                                                                                        <option value="1.75" class="">1.75</option>
                                                                                        <option value="2" class="">2</option>
                                                                                        <option value="2.25" class="">2.25</option>
                                                                                        <option value="2.5" class="">2.5</option>
                                                                                        <option value="3" class="">3</option>
                                                                                        <option value="4" class="">4</option>
                                                                                        <option value="5" class="">5</option>
                                                                                        <option value="6" class="">6</option>
                                                                                        <option value="8" class="">8</option>
                                                                                        <option value="10" class="">10</option>
                                                                                        <option value="12" class="">12</option>

                                                                                    </select>
                                                                                </div>
                                                                            </>
                                                                        ) : values.maxVCores === "14" ? (
                                                                            <>
                                                                                <div className="col-md-6 px-3">
                                                                                    <label htmlFor="minVCores" className="form-label font-14 font-medium black-v2 mb-3">Minimum vCores
                                                                                    </label>
                                                                                    <select
                                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                        id="minVCores"
                                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                        value={values.minVCores}
                                                                                        name={"minVCores"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} 
                                                                                        disabled={isViewer}
                                                                                    >
                                                                                       <option value="1.75" class="">1.75</option>
                                                                                       <option value="2" class="">2</option>
                                                                                       <option value="2.25" class="">2.25</option>
                                                                                       <option value="2.5" class="">2.5</option>
                                                                                       <option value="3" class="">3</option>
                                                                                       <option value="4" class="">4</option>
                                                                                       <option value="5" class="">5</option>
                                                                                       <option value="6" class="">6</option>
                                                                                       <option value="8" class="">8</option>
                                                                                       <option value="10" class="">10</option>
                                                                                       <option value="12" class="">12</option>
                                                                                       <option value="14" class="">14</option>

                                                                                        
                                                                                    </select>
                                                                                </div>
                                                                            </>
                                                                        ) : values.maxVCores === "16" ? (
                                                                            <>
                                                                                <div className="col-md-6 px-3">
                                                                                    <label htmlFor="minVCores" className="form-label font-14 font-medium black-v2 mb-3">Minimum vCores
                                                                                    </label>
                                                                                    <select
                                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                        id="minVCores"
                                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                        value={values.minVCores}
                                                                                        name={"minVCores"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} 
                                                                                        disabled={isViewer}
                                                                                    >
                                                                                      <option value="2" class="">2</option>
                                                                                      <option value="2.25" class="">2.25</option>
                                                                                      <option value="2.5" class="">2.5</option>
                                                                                      <option value="3" class="">3</option>
                                                                                      <option value="4" class="">4</option>
                                                                                      <option value="5" class="">5</option>
                                                                                      <option value="6" class="">6</option>
                                                                                      <option value="8" class="">8</option>
                                                                                      <option value="10" class="">10</option>
                                                                                      <option value="12" class="">12</option>
                                                                                      <option value="14" class="">14</option>
                                                                                      <option value="16" class="">16</option>
                                                                                        
                                                                                    </select>
                                                                                </div>
                                                                            </>
                                                                        ) : values.maxVCores === "18" ? (
                                                                            <>
                                                                                <div className="col-md-6 px-3">
                                                                                    <label htmlFor="minVCores" className="form-label font-14 font-medium black-v2 mb-3">Minimum vCores
                                                                                    </label>
                                                                                    <select
                                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                        id="minVCores"
                                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                        value={values.minVCores}
                                                                                        name={"minVCores"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} 
                                                                                        disabled={isViewer}
                                                                                    >
                                                                                      <option value="2.25" class="">2.25</option>
                                                                                      <option value="2.5" class="">2.5</option>
                                                                                      <option value="3" class="">3</option>
                                                                                      <option value="4" class="">4</option>
                                                                                      <option value="5" class="">5</option>
                                                                                      <option value="6" class="">6</option>
                                                                                      <option value="8" class="">8</option>
                                                                                      <option value="10" class="">10</option>
                                                                                      <option value="12" class="">12</option>
                                                                                      <option value="14" class="">14</option>
                                                                                      <option value="16" class="">16</option>
                                                                                      <option value="18" class="">18</option>
 
                                                                                    </select>
                                                                                </div>
                                                                            </>
                                                                        ) : values.maxVCores === "20" ? (
                                                                            <>
                                                                                <div className="col-md-6 px-3">
                                                                                    <label htmlFor="minVCores" className="form-label font-14 font-medium black-v2 mb-3">Minimum vCores
                                                                                    </label>
                                                                                    <select
                                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                        id="minVCores"
                                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                        value={values.minVCores}
                                                                                        name={"minVCores"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} 
                                                                                        disabled={isViewer}
                                                                                    >
                                                                                      <option value="2.5" class="">2.5</option>
                                                                                      <option value="3" class="">3</option>
                                                                                      <option value="4" class="">4</option>
                                                                                      <option value="5" class="">5</option>
                                                                                      <option value="6" class="">6</option>
                                                                                      <option value="8" class="">8</option>
                                                                                      <option value="10" class="">10</option>
                                                                                      <option value="12" class="">12</option>
                                                                                      <option value="14" class="">14</option>
                                                                                      <option value="16" class="">16</option>
                                                                                      <option value="18" class="">18</option>
                                                                                      <option value="20" class="">20</option>

 
                                                                                    </select>
                                                                                </div>
                                                                            </>
                                                                        ) : values.maxVCores === "24" ? (
                                                                            <>
                                                                                <div className="col-md-6 px-3">
                                                                                    <label htmlFor="minVCores" className="form-label font-14 font-medium black-v2 mb-3">Minimum vCores
                                                                                    </label>
                                                                                    <select
                                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                        id="minVCores"
                                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                        value={values.minVCores}
                                                                                        name={"minVCores"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} 
                                                                                        disabled={isViewer}
                                                                                    >
                                                                                    <option value="3" class="">3</option>
                                                                                    <option value="4" class="">4</option>
                                                                                    <option value="5" class="">5</option>
                                                                                    <option value="6" class="">6</option>
                                                                                    <option value="8" class="">8</option>
                                                                                    <option value="10" class="">10</option>
                                                                                    <option value="12" class="">12</option>
                                                                                    <option value="14" class="">14</option>
                                                                                    <option value="16" class="">16</option>
                                                                                    <option value="18" class="">18</option>
                                                                                    <option value="20" class="">20</option>
                                                                                    <option value="24" class="">24</option>


                                                                                    </select>
                                                                                </div>
                                                                            </>
                                                                        ) : values.maxVCores === "32" ? (
                                                                            <>
                                                                                <div className="col-md-6 px-3">
                                                                                    <label htmlFor="minVCores" className="form-label font-14 font-medium black-v2 mb-3">Minimum vCores
                                                                                    </label>
                                                                                    <select
                                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                        id="minVCores"
                                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                        value={values.minVCores}
                                                                                        name={"minVCores"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} 
                                                                                        disabled={isViewer}
                                                                                    >
                                                                                    <option value="4" class="">4</option>
                                                                                    <option value="5" class="">5</option>
                                                                                    <option value="6" class="">6</option>
                                                                                    <option value="8" class="">8</option>
                                                                                    <option value="10" class="">10</option>
                                                                                    <option value="12" class="">12</option>
                                                                                    <option value="14" class="">14</option>
                                                                                    <option value="16" class="">16</option>
                                                                                    <option value="18" class="">18</option>
                                                                                    <option value="20" class="">20</option>
                                                                                    <option value="24" class="">24</option>
                                                                                    <option value="32" class="">32</option>

                                                                                    </select>
                                                                                </div>
                                                                            </>
                                                                        ) : values.maxVCores === "40" ? (
                                                                            <>
                                                                                <div className="col-md-6 px-3">
                                                                                    <label htmlFor="minVCores" className="form-label font-14 font-medium black-v2 mb-3">Minimum vCores
                                                                                    </label>
                                                                                    <select
                                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                        id="minVCores"
                                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                        value={values.minVCores}
                                                                                        name={"minVCores"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} 
                                                                                        disabled={isViewer}
                                                                                    >
                                                                                    <option value="5" class="">5</option>
                                                                                    <option value="6" class="">6</option>
                                                                                    <option value="8" class="">8</option>
                                                                                    <option value="10" class="">10</option>
                                                                                    <option value="12" class="">12</option>
                                                                                    <option value="14" class="">14</option>
                                                                                    <option value="16" class="">16</option>
                                                                                    <option value="18" class="">18</option>
                                                                                    <option value="20" class="">20</option>
                                                                                    <option value="24" class="">24</option>
                                                                                    <option value="32" class="">32</option>
                                                                                    <option value="40" class="">40</option>
                                                                                    </select>
                                                                                </div>
                                                                            </>
                                                                        ) : values.maxVCores === "80" ? (
                                                                            <>
                                                                                <div className="col-md-6 px-3">
                                                                                    <label htmlFor="minVCores" className="form-label font-14 font-medium black-v2 mb-3">Minimum vCores
                                                                                    </label>
                                                                                    <select
                                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                        id="minVCores"
                                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                        value={values.minVCores}
                                                                                        name={"minVCores"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} 
                                                                                        disabled={isViewer}
                                                                                    >
                                                                                    <option value="10" class="">10</option>
                                                                                    <option value="12" class="">12</option>
                                                                                    <option value="14" class="">14</option>
                                                                                    <option value="16" class="">16</option>
                                                                                    <option value="18" class="">18</option>
                                                                                    <option value="20" class="">20</option>
                                                                                    <option value="24" class="">24</option>
                                                                                    <option value="32" class="">32</option>
                                                                                    <option value="40" class="">40</option>
                                                                                    <option value="80" class="">80</option>

                                                                                    </select>
                                                                                </div>
                                                                            </>
                                                                        ) : (null)}

                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="cpuUsed" className="form-label font-14 font-medium black-v2 mb-3">
                                                                            CPU used (vCores)
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                                id="cpuUsed"
                                                                                value={values.cpuUsed}
                                                                                name={"cpuUsed"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} disabled={isViewer}

                                                                            />
                                                                        </div>
                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="memoryUsed" className="form-label font-14 font-medium black-v2 mb-3">
                                                                            Memory used (GB)
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                                id="memoryUsed"
                                                                                value={values.memoryUsed}
                                                                                name={"memoryUsed"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} disabled={isViewer}

                                                                            />
                                                                        </div>
                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="duration" className="form-label font-14 font-medium black-v2 mb-3">
                                                                            Duration (in seconds, max 26,78,400 seconds (744 hours))
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                                id="duration"
                                                                                value={values.duration}
                                                                                name={"duration"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} disabled={isViewer}

                                                                            />
                                                                        </div>
                                                                        <div className="row py-4 mx-4 px-5">    
                                                                            <h5 style={{ marginTop: "15px", marginBottom: "20px", color: "black", fontSize: "18px" }}>Storage</h5>
                                                                        </div>

                                                                        

                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="singleStorageUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                                            Data (GB)
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                                id="singleStorageUnits"
                                                                                value={values.singleStorageUnits}
                                                                                name={"singleStorageUnits"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} disabled={isViewer}

                                                                            />
                                                                        </div>
                                                                        <h6 style={{ marginTop: "15px", marginBottom: "20px"}}>Backup Storage</h6>

                                                                        <div >
                                                                            <label htmlFor="redundancy" className="form-label font-14 font-medium black-v2 mb-3">
                                                                            Redundancy
                                                                            </label>
                                                                            <select
                                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                id="redundancy"
                                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                value={values.redundancy}
                                                                                name={"redundancy"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} 
                                                                                disabled={isViewer}
                                                                            >
                                                                            <option value="lrs">LRS</option>
                                                                            <option value="zrs">ZRS</option>
                                                                            <option value="ra-grs">RA-GRS</option>
                                                                            </select>
                                                                        </div>  

                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="backupStorageSize" className="form-label font-14 font-medium black-v2 mb-3">
                                                                            Point-In-Time Restore in (GB)
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                                id="backupStorageSize"
                                                                                value={values.backupStorageSize}
                                                                                name={"backupStorageSize"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} disabled={isViewer}

                                                                            />
                                                                        </div>

                                                                        <h6 style={{ marginTop: "15px", marginBottom: "20px"}}>Long Term Retention</h6>   

                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="ltrDatabaseSize" className="form-label font-14 font-medium black-v2 mb-3">
                                                                            Average database size during retention period
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                                id="ltrDatabaseSize"
                                                                                value={values.ltrDatabaseSize}
                                                                                name={"ltrDatabaseSize"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} disabled={isViewer}

                                                                            />
                                                                        </div>

                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="ltrDatabaseSizeFactor" className="form-label font-14 font-medium black-v2 mb-3"  style={{marginTop:"6%"}}>
                                                                            Unit
                                                                            </label>
                                                                            <select
                                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                id="ltrDatabaseSizeFactor"
                                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                value={values.ltrDatabaseSizeFactor}
                                                                                name={"ltrDatabaseSizeFactor"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} 
                                                                                disabled={isViewer}
                                                                            >
                                                                            <option value="1" class="">GB</option>
                                                                            <option value="1024" class="">TB</option>
                                                                            </select>
                                                                        </div> 

                                                                        <h6 style={{ marginTop: "15px", marginBottom: "20px"}}>Retention Policy</h6> 

                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="WeeklyBackupRetention" className="form-label font-14 font-medium black-v2 mb-3"  style={{marginTop:"6%"}}>
                                                                            Weekly Backup Retention
                                                                            </label>
                                                                            <select
                                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                                id="WeeklyBackupRetention"
                                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                value={values.WeeklyBackupRetention}
                                                                                name={"WeeklyBackupRetention"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} 
                                                                                disabled={isViewer}
                                                                            >
                                                                            <option value="yes" class="">Yes</option>
                                                                            <option value="no" class="">No</option>
                                                                            </select>
                                                                        </div> 

                                                                        {values.WeeklyBackupRetention === 'yes' ? (
                                                                            <div className="col-md-6 px-3">
                                                                                <label htmlFor="weeklyBackups" className="form-label font-14 font-medium black-v2 mb-3">
                                                                                Average database size during retention period
                                                                                </label>
                                                                                <input
                                                                                    type="text"
                                                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                                                    id="weeklyBackups"
                                                                                    value={values.weeklyBackups}
                                                                                    name={"weeklyBackups"}
                                                                                    onChange={(e) => {
                                                                                        onChangeOfConfiguration(e, values.instanceName);
                                                                                    }} disabled={isViewer}

                                                                                />
                                                                            </div>
                                                                        ): (null)}
                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="MonthlyBackupRetention" className="form-label font-14 font-medium black-v2 mb-3"  style={{marginTop:"6%"}}>
                                                                            Monthly Backup Retention
                                                                            </label>
                                                                            <select
                                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                                id="MonthlyBackupRetention"
                                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                value={values.MonthlyBackupRetention}
                                                                                name={"MonthlyBackupRetention"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} 
                                                                                disabled={isViewer}
                                                                            >
                                                                            <option value="yes" class="">Yes</option>
                                                                            <option value="no" class="">No</option>
                                                                            </select>
                                                                        </div> 

                                                                        {values.MonthlyBackupRetention === 'yes' ? (
                                                                            <div className="col-md-6 px-3">
                                                                                <label htmlFor="monthlyBackups" className="form-label font-14 font-medium black-v2 mb-3">
                                                                                Average database size during retention period
                                                                                </label>
                                                                                <input
                                                                                    type="text"
                                                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                                                    id="monthlyBackups"
                                                                                    value={values.monthlyBackups}
                                                                                    name={"monthlyBackups"}
                                                                                    onChange={(e) => {
                                                                                        onChangeOfConfiguration(e, values.instanceName);
                                                                                    }} disabled={isViewer}

                                                                                />
                                                                            </div>
                                                                        ): (null)}
                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="YearlyBackupRetention" className="form-label font-14 font-medium black-v2 mb-3"  style={{marginTop:"6%"}}>
                                                                            Yearly Backup Retention  
                                                                            </label>
                                                                            <select
                                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                                id="YearlyBackupRetention"
                                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                value={values.YearlyBackupRetention}
                                                                                name={"YearlyBackupRetention"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} 
                                                                                disabled={isViewer}
                                                                            >
                                                                            <option value="yes" class="">Yes</option>
                                                                            <option value="no" class="">No</option>
                                                                            </select>
                                                                        </div> 

                                                                        {values.YearlyBackupRetention === 'yes' ? (
                                                                            <div className="col-md-6 px-3">
                                                                                <label htmlFor="yearlyBackups" className="form-label font-14 font-medium black-v2 mb-3">
                                                                                Average database size during retention period
                                                                                </label>
                                                                                <input
                                                                                    type="text"
                                                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                                                    id="yearlyBackups"
                                                                                    value={values.yearlyBackups}
                                                                                    name={"yearlyBackups"}
                                                                                    onChange={(e) => {
                                                                                        onChangeOfConfiguration(e, values.instanceName);
                                                                                    }} disabled={isViewer}

                                                                                />
                                                                            </div>
                                                                        ): (null)}

                                                                    </>
                                                                )}
                                                            </>
                                                        ): values.vcoreTier === "business-critical" ?(
                                                            <>
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="generation" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Hardware Type
                                                                    </label>
                                                                    <select
                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                        id="generation"
                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                        value={values.generation}
                                                                        name={"generation"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} 
                                                                        disabled={isViewer}
                                                                    >
                                                                    <option value="gen5" class="">Standard-series (Gen 5)</option>
                                                                    <option value="dc" class="">DC-series</option>
                                                                    </select>
                                                                </div>   

                                                                {values.generation === "gen5" ? 
                                                                (   <>
                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="instanceSize" className="form-label font-14 font-medium black-v2 mb-3">
                                                                            Hardware Type
                                                                            </label>
                                                                            <select
                                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                id="instanceSize"
                                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                value={values.instanceSize}
                                                                                name={"instanceSize"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} 
                                                                                disabled={isViewer}
                                                                            >
                                                                            <option value="2" class="">2 vCore</option>
                                                                            <option value="4" class="">4 vCore</option>
                                                                            <option value="6" class="">6 vCore</option>
                                                                            <option value="8" class="">8 vCore</option>
                                                                            <option value="10" class="">10 vCore</option>
                                                                            <option value="12" class="">12 vCore</option>
                                                                            <option value="14" class="">14 vCore</option>
                                                                            <option value="16" class="">16 vCore</option>
                                                                            <option value="18" class="">18 vCore</option>
                                                                            <option value="20" class="">20 vCore</option>
                                                                            <option value="24" class="">24 vCore</option>
                                                                            <option value="32" class="">32 vCore</option>
                                                                            <option value="40" class="">40 vCore</option>
                                                                            <option value="80" class="">80 vCore</option>
                                                                            <option value="128" class="">128 vCore</option>
                                                                                
                                                                            </select>
                                                                        </div>   
                                                                    </>
                                                                ):
                                                                (
                                                                    <>
                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="instanceSize" className="form-label font-14 font-medium black-v2 mb-3">
                                                                            Hardware Type
                                                                            </label>
                                                                            <select
                                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                id="instanceSize"
                                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                value={values.instanceSize}
                                                                                name={"instanceSize"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} 
                                                                                disabled={isViewer}
                                                                            >
                                                                            <option value="2" class="">2 vCore</option>
                                                                            <option value="4" class="">4 vCore</option>
                                                                            <option value="6" class="">6 vCore</option>
                                                                            <option value="8" class="">8 vCore</option>
                                                                            <option value="10" class="">10 vCore</option>
                                                                            <option value="12" class="">12 vCore</option>
                                                                            <option value="14" class="">14 vCore</option>
                                                                            <option value="16" class="">16 vCore</option>
                                                                            <option value="18" class="">18 vCore</option>
                                                                            <option value="20" class="">20 vCore</option>
                                                                            <option value="32" class="">32 vCore</option>
                                                                            <option value="40" class="">40 vCore</option> 
                                                                            </select>
                                                                        </div>   
                                                                    </>
                                                                )}
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="disasterRecovery" className="form-label font-14 font-medium black-v2 mb-3"> Disaster Recovery
                                                                    </label>
                                                                    <select
                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                        id="disasterRecovery"
                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                        value={values.disasterRecovery}
                                                                        name={"disasterRecovery"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} 
                                                                        disabled={isViewer}
                                                                    >
                                                                        <option value="primary-or-georeplica">Primary or Geo replica</option>
                                                                        <option value="standby-replica">Standby replica</option>
                                                                    </select>
                                                                </div>
                                                                <div className="row py-4 mx-4 px-5">    
                                                                    <h5 style={{ marginTop: "15px", marginBottom: "20px", color: "black", fontSize: "18px" }}>Compute
                                                                    </h5>
                                                                </div>
                                                                
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="singleCount" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Databases
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                                        id="singleCount"
                                                                        value={values.singleCount}
                                                                        name={"singleCount"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}

                                                                    />
                                                                </div>
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="hours" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Hours
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                                        id="hours"
                                                                        value={values.hours}
                                                                        name={"hours"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}

                                                                    />
                                                                </div>

                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="hoursFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Hours Factor
                                                                    </label>
                                                                    <select
                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                        id="hoursFactor"
                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                        value={values.hoursFactor}
                                                                        name={"hoursFactor"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} 
                                                                        disabled={isViewer}
                                                                    >
                                                                    <option value="1" class="">Hours</option>
                                                                    <option value="24" class="">Days</option>
                                                                    <option value="730" class="">Month</option>
                                                                    </select>
                                                                </div>  
                                                                <div className="row py-4 mx-4 px-5">    
                                                                    <h5 style={{ marginTop: "15px", marginBottom: "20px", color: "black", fontSize: "18px" }}>Storage</h5>
                                                                </div>

                                                                        

                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="singleStorageUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Data (GB)
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                                        id="singleStorageUnits"
                                                                        value={values.singleStorageUnits}
                                                                        name={"singleStorageUnits"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}

                                                                    />
                                                                </div>

                                                                <h6 style={{ marginTop: "15px", marginBottom: "20px"}}>Backup Storage</h6>

                                                                <div>
                                                                    <label htmlFor="redundancy" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Redundancy
                                                                    </label>
                                                                    <select
                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                        id="redundancy"
                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                        value={values.redundancy}
                                                                        name={"redundancy"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} 
                                                                        disabled={isViewer}
                                                                    >
                                                                    <option value="lrs">LRS</option>
                                                                    <option value="zrs">ZRS</option>
                                                                    <option value="ra-grs">RA-GRS</option>
                                                                    </select>
                                                                </div>  

                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="backupStorageSize" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Point-In-Time Restore in (GB)
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                        id="backupStorageSize"
                                                                        value={values.backupStorageSize}
                                                                        name={"backupStorageSize"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}

                                                                    />
                                                                </div>

                                                                <h6 style={{ marginTop: "15px", marginBottom: "20px"}}>Long Term Retention</h6>   

                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="ltrDatabaseSize" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Average database size during retention period
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                                        id="ltrDatabaseSize"
                                                                        value={values.ltrDatabaseSize}
                                                                        name={"ltrDatabaseSize"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}

                                                                    />
                                                                </div>

                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="ltrDatabaseSizeFactor" className="form-label font-14 font-medium black-v2 mb-3"  style={{marginTop:"6%"}}>
                                                                    Unit
                                                                    </label>
                                                                    <select
                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                        id="ltrDatabaseSizeFactor"
                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                        value={values.ltrDatabaseSizeFactor}
                                                                        name={"ltrDatabaseSizeFactor"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} 
                                                                        disabled={isViewer}
                                                                    >
                                                                    <option value="1" class="">GB</option>
                                                                    <option value="1024" class="">TB</option>
                                                                    </select>
                                                                </div> 

                                                                <h6 style={{ marginTop: "15px", marginBottom: "20px"}}>Retention Policy</h6> 

                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="WeeklyBackupRetention" className="form-label font-14 font-medium black-v2 mb-3"  style={{marginTop:"6%"}}>
                                                                    Weekly Backup Retention
                                                                    </label>
                                                                    <select
                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                        id="WeeklyBackupRetention"
                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                        value={values.WeeklyBackupRetention}
                                                                        name={"WeeklyBackupRetention"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} 
                                                                        disabled={isViewer}
                                                                    >
                                                                    <option value="yes" class="">Yes</option>
                                                                    <option value="no" class="">No</option>
                                                                    </select>
                                                                </div> 

                                                                {values.WeeklyBackupRetention === 'yes' ? (
                                                                    <div className="col-md-6 px-3">
                                                                        <label htmlFor="weeklyBackups" className="form-label font-14 font-medium black-v2 mb-3">
                                                                        Average database size during retention period
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className=" form-control  custom-form text-field font-14 font-medium"
                                                                            id="weeklyBackups"
                                                                            value={values.weeklyBackups}
                                                                            name={"weeklyBackups"}
                                                                            onChange={(e) => {
                                                                                onChangeOfConfiguration(e, values.instanceName);
                                                                            }} disabled={isViewer}

                                                                        />
                                                                    </div>
                                                                ): (null)}
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="MonthlyBackupRetention" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"6%"}}>
                                                                    Monthly Backup Retention
                                                                    </label>
                                                                    <select
                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                        id="MonthlyBackupRetention"
                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                        value={values.MonthlyBackupRetention}
                                                                        name={"MonthlyBackupRetention"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} 
                                                                        disabled={isViewer}
                                                                    >
                                                                    <option value="yes" class="">Yes</option>
                                                                    <option value="no" class="">No</option>
                                                                    </select>
                                                                </div> 

                                                                {values.MonthlyBackupRetention === 'yes' ? (
                                                                    <div className="col-md-6 px-3">
                                                                        <label htmlFor="monthlyBackups" className="form-label font-14 font-medium black-v2 mb-3">
                                                                        Average database size during retention period
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className=" form-control  custom-form text-field font-14 font-medium"
                                                                            id="monthlyBackups"
                                                                            value={values.monthlyBackups}
                                                                            name={"monthlyBackups"}
                                                                            onChange={(e) => {
                                                                                onChangeOfConfiguration(e, values.instanceName);
                                                                            }} disabled={isViewer}

                                                                        />
                                                                    </div>
                                                                ): (null)}
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="YearlyBackupRetention" className="form-label font-14 font-medium black-v2 mb-3"  style={{marginTop:"6%"}}>
                                                                    Yearly Backup Retention  
                                                                    </label>
                                                                    <select
                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                        id="YearlyBackupRetention"
                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                        value={values.YearlyBackupRetention}
                                                                        name={"YearlyBackupRetention"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} 
                                                                        disabled={isViewer}
                                                                    >
                                                                    <option value="yes" class="">Yes</option>
                                                                    <option value="no" class="">No</option>
                                                                    </select>
                                                                </div> 

                                                                {values.YearlyBackupRetention === 'yes' ? (
                                                                    <div className="col-md-6 px-3">
                                                                        <label htmlFor="yearlyBackups" className="form-label font-14 font-medium black-v2 mb-3">
                                                                        Average database size during retention period
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className=" form-control  custom-form text-field font-14 font-medium"
                                                                            id="yearlyBackups"
                                                                            value={values.yearlyBackups}
                                                                            name={"yearlyBackups"}
                                                                            onChange={(e) => {
                                                                                onChangeOfConfiguration(e, values.instanceName);
                                                                            }} disabled={isViewer}

                                                                        />
                                                                    </div>
                                                                ): (null)}

                                                            </>
                                                        ):(
                                                            <>
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="computeTier" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Compute Tier
                                                                    </label>
                                                                    <select
                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                        id="computeTier"
                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                        value={values.computeTier}
                                                                        name={"computeTier"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} 
                                                                        disabled={isViewer}
                                                                    >
                                                                    <option value="provisioned" class="">Provisioned</option>
                                                                    <option value="serverless" class="">Serverless</option>
                                                                    </select>
                                                                </div> 
                                                                {values.computeTier === "provisioned" ?
                                                                (
                                                                    <>
                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="generation" className="form-label font-14 font-medium black-v2 mb-3">
                                                                            Hardware Type
                                                                            </label>
                                                                            <select
                                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                id="generation"
                                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                value={values.generation}
                                                                                name={"generation"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} 
                                                                                disabled={isViewer}
                                                                            >
                                                                            <option value="gen5" class="">Standard-series (Gen 5)</option>
                                                                            <option value="premium" class="">Premium</option>
                                                                            <option value="memoryoptimized" class="">Premium-series, memory optimized</option>
                                                                            <option value="dc" class="">DC-series</option>
                                                                            </select>
                                                                        </div> 
                                                                        {values.generation === "gen5" ? 
                                                                        (
                                                                            <>
                                                                                <div className="col-md-6 px-3">
                                                                                    <label htmlFor="instanceSize" className="form-label font-14 font-medium black-v2 mb-3">
                                                                                    Instance
                                                                                    </label>
                                                                                    <select
                                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                        id="instanceSize"
                                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                        value={values.instanceSize}
                                                                                        name={"instanceSize"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} 
                                                                                        disabled={isViewer}
                                                                                    >
                                                                                    <option value="2" class="">2 vCore</option>
                                                                                    <option value="4" class="">4 vCore</option>
                                                                                    <option value="6" class="">6 vCore</option>
                                                                                    <option value="8" class="">8 vCore</option>
                                                                                    <option value="10" class="">10 vCore</option>
                                                                                    <option value="12" class="">12 vCore</option>
                                                                                    <option value="14" class="">14 vCore</option>
                                                                                    <option value="16" class="">16 vCore</option>
                                                                                    <option value="18" class="">18 vCore</option>
                                                                                    <option value="20" class="">20 vCore</option>
                                                                                    <option value="24" class="">24 vCore</option>
                                                                                    <option value="32" class="">32 vCore</option>
                                                                                    <option value="40" class="">40 vCore</option>
                                                                                    <option value="80" class="">80 vCore</option>

                                                                                    
                                                                                    </select>
                                                                                </div>
                                                                            </>
                                                                        ) : values.generation === "premium" ? 
                                                                        (
                                                                            <>
                                                                                <div className="col-md-6 px-3">
                                                                                    <label htmlFor="instanceSize" className="form-label font-14 font-medium black-v2 mb-3">
                                                                                    Instance
                                                                                    </label>
                                                                                    <select
                                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                        id="instanceSize"
                                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                        value={values.instanceSize}
                                                                                        name={"instanceSize"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} 
                                                                                        disabled={isViewer}
                                                                                    >
                                                                                    <option value="2" class="">2 vCore</option>
                                                                                    <option value="4" class="">4 vCore</option>
                                                                                    <option value="6" class="">6 vCore</option>
                                                                                    <option value="8" class="">8 vCore</option>
                                                                                    <option value="10" class="">10 vCore</option>
                                                                                    <option value="12" class="">12 vCore</option>
                                                                                    <option value="14" class="">14 vCore</option>
                                                                                    <option value="16" class="">16 vCore</option>
                                                                                    <option value="18" class="">18 vCore</option>
                                                                                    <option value="20" class="">20 vCore</option>
                                                                                    <option value="24" class="">24 vCore</option>
                                                                                    <option value="32" class="">32 vCore</option>
                                                                                    <option value="40" class="">40 vCore</option>
                                                                                    <option value="64" class="">64 vCore</option>
                                                                                    <option value="80" class="">80 vCore</option>
                                                                                    <option value="128" class="">128 vCore</option>

                                                                                    </select>
                                                                                </div>
                                                                            </>
                                                                        ): values.generation === "memoryoptimized" ? 
                                                                        (
                                                                            <>
                                                                                <div className="col-md-6 px-3">
                                                                                    <label htmlFor="instanceSize" className="form-label font-14 font-medium black-v2 mb-3">
                                                                                    Instance
                                                                                    </label>
                                                                                    <select
                                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                        id="instanceSize"
                                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                        value={values.instanceSize}
                                                                                        name={"instanceSize"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} 
                                                                                        disabled={isViewer}
                                                                                    >
                                                                                    <option value="2" class="">2 vCore</option>
                                                                                    <option value="4" class="">4 vCore</option>
                                                                                    <option value="6" class="">6 vCore</option>
                                                                                    <option value="8" class="">8 vCore</option>
                                                                                    <option value="10" class="">10 vCore</option>
                                                                                    <option value="12" class="">12 vCore</option>
                                                                                    <option value="14" class="">14 vCore</option>
                                                                                    <option value="16" class="">16 vCore</option>
                                                                                    <option value="18" class="">18 vCore</option>
                                                                                    <option value="20" class="">20 vCore</option>
                                                                                    <option value="24" class="">24 vCore</option>
                                                                                    <option value="32" class="">32 vCore</option>
                                                                                    <option value="40" class="">40 vCore</option>
                                                                                    <option value="64" class="">64 vCore</option>
                                                                                    <option value="80" class="">80 vCore</option>


                                                                                    </select>
                                                                                </div>

                                                                            </>
                                                                        ): 
                                                                        (
                                                                            <>
                                                                                <div className="col-md-6 px-3">
                                                                                    <label htmlFor="instanceSize" className="form-label font-14 font-medium black-v2 mb-3">
                                                                                    Instance
                                                                                    </label>
                                                                                    <select
                                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                        id="instanceSize"
                                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                        value={values.instanceSize}
                                                                                        name={"instanceSize"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} 
                                                                                        disabled={isViewer}
                                                                                    >
                                                                                    <option value="2" class="">2 vCore</option>
                                                                                    <option value="4" class="">4 vCore</option>
                                                                                    <option value="6" class="">6 vCore</option>
                                                                                    <option value="8" class="">8 vCore</option>
                                                                                    <option value="10" class="">10 vCore</option>
                                                                                    <option value="12" class="">12 vCore</option>
                                                                                    <option value="14" class="">14 vCore</option>
                                                                                    <option value="16" class="">16 vCore</option>
                                                                                    <option value="18" class="">18 vCore</option>
                                                                                    <option value="20" class="">20 vCore</option>
                                                                                    <option value="32" class="">32 vCore</option>
                                                                                    <option value="40" class="">40 vCore</option>
                                                                                    </select>
                                                                                </div>
                                                                            </>
                                                                        )}

                                                                        <div className="row py-4 mx-4 px-5">    
                                                                            <h5 style={{ marginTop: "15px", marginBottom: "20px", color: "black", fontSize: "18px" }}>Compute</h5>
                                                                        </div>

                                                                        <div>
                                                                            <label htmlFor="zoneRedundancy" className="form-label font-14 font-medium black-v2 mb-3">
                                                                            Redundancy
                                                                            </label>
                                                                            <select
                                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                id="zoneRedundancy"
                                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                value={values.zoneRedundancy}
                                                                                name={"zoneRedundancy"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} 
                                                                                disabled={isViewer}
                                                                            >
                                                                            <option value="local">Locally Redundant</option>
                                                                            <option value="zone">Zone Redundant</option>
                                                                            
                                                                            </select>
                                                                        </div>

                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="singleCount" className="form-label font-14 font-medium black-v2 mb-3">
                                                                            Databases
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                                id="singleCount"
                                                                                value={values.singleCount}
                                                                                name={"singleCount"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} disabled={isViewer}

                                                                            />
                                                                        </div>
                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="hours" className="form-label font-14 font-medium black-v2 mb-3">
                                                                            Hours
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                                id="hours"
                                                                                value={values.hours}
                                                                                name={"hours"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} disabled={isViewer}

                                                                            />
                                                                        </div>

                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="hoursFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                                                            Hours Factor
                                                                            </label>
                                                                            <select
                                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                id="hoursFactor"
                                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                value={values.hoursFactor}
                                                                                name={"hoursFactor"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} 
                                                                                disabled={isViewer}
                                                                            >
                                                                            <option value="1" class="">Hours</option>
                                                                            <option value="24" class="">Days</option>
                                                                            <option value="730" class="">Month</option>
                                                                            </select>
                                                                        </div>

                                                                        <div className="row py-4 mx-4 px-5">    
                                                                            <h5 style={{ marginTop: "15px", marginBottom: "20px", color: "black", fontSize: "18px" }}>High Availability Replicas</h5>
                                                                        </div>
                                                                        
                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="singleHyperscaleReplicaUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                                            High availability replicas per database
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                                id="singleHyperscaleReplicaUnits"
                                                                                value={values.singleHyperscaleReplicaUnits}
                                                                                name={"singleHyperscaleReplicaUnits"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} disabled={isViewer}

                                                                            />
                                                                        </div>
                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="singleHyperscaleReplicaHours" className="form-label font-14 font-medium black-v2 mb-3">
                                                                            Hours
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                                id="singleHyperscaleReplicaHours"
                                                                                value={values.singleHyperscaleReplicaHours}
                                                                                name={"singleHyperscaleReplicaHours"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} disabled={isViewer}

                                                                            />
                                                                        </div>

                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="singleHyperscaleReplicaHoursFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                                                            Hours Factor
                                                                            </label>
                                                                            <select
                                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                id="singleHyperscaleReplicaHoursFactor"
                                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                value={values.singleHyperscaleReplicaHoursFactor}
                                                                                name={"singleHyperscaleReplicaHoursFactor"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} 
                                                                                disabled={isViewer}
                                                                            >
                                                                            <option value="1" class="">Hours</option>
                                                                            <option value="24" class="">Days</option>
                                                                            <option value="730" class="">Month</option>
                                                                            </select>
                                                                        </div>


                                                                        <div className="row py-4 mx-4 px-5">    
                                                                            <h5 style={{ marginTop: "15px", marginBottom: "20px", color: "black", fontSize: "18px" }}>Named Replicas
                                                                            </h5>
                                                                        </div>

                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="namedReplicaUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                                            Named replicas per database
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                                id="namedReplicaUnits"
                                                                                value={values.namedReplicaUnits}
                                                                                name={"namedReplicaUnits"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} disabled={isViewer}

                                                                            />
                                                                        </div>
                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="namedReplicaHours" className="form-label font-14 font-medium black-v2 mb-3">
                                                                            Hours
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                                id="namedReplicaHours"
                                                                                value={values.namedReplicaHours}
                                                                                name={"namedReplicaHours"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} disabled={isViewer}

                                                                            />
                                                                        </div>

                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="namedReplicaHoursFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                                                            Hours Factor
                                                                            </label>
                                                                            <select
                                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                id="namedReplicaHoursFactor"
                                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                value={values.namedReplicaHoursFactor}
                                                                                name={"namedReplicaHoursFactor"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} 
                                                                                disabled={isViewer}
                                                                            >
                                                                            <option value="1" class="">Hours</option>
                                                                            <option value="24" class="">Days</option>
                                                                            <option value="730" class="">Month</option>
                                                                            </select>
                                                                        </div>
                                                                        
                                                                        <div className="row py-4 mx-4 px-5">    
                                                                            <h5 style={{ marginTop: "15px", marginBottom: "20px", color: "black", fontSize: "18px" }}>Storage
                                                                            </h5>
                                                                        </div>

                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="singleHyperscaleStorageUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                                            Storage Unit
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                                id="singleHyperscaleStorageUnits"
                                                                                value={values.singleHyperscaleStorageUnits}
                                                                                name={"singleHyperscaleStorageUnits"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} disabled={isViewer}

                                                                            />
                                                                        </div>

                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="singleHyperscaleStorageUnitsFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                                                            Storage Unit Factor
                                                                            </label>
                                                                            <select
                                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                id="singleHyperscaleStorageUnitsFactor"
                                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                value={values.singleHyperscaleStorageUnitsFactor}
                                                                                name={"singleHyperscaleStorageUnitsFactor"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} 
                                                                                disabled={isViewer}
                                                                            >
                                                                            <option value="1" class="">GB</option>
                                                                            <option value="1024" class="">TB</option>
                                                                            </select>
                                                                        </div>

                                                                        <h6 style={{ marginTop: "15px", marginBottom: "20px"}}>Backup Storage</h6>

                                                                        <div >
                                                                            <label htmlFor="redundancy" className="form-label font-14 font-medium black-v2 mb-3">
                                                                            Redundancy
                                                                            </label>
                                                                            <select
                                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                id="redundancy"
                                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                value={values.redundancy}
                                                                                name={"redundancy"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} 
                                                                                disabled={isViewer}
                                                                            >
                                                                            <option value="lrs">LRS</option>
                                                                            <option value="zrs">ZRS</option>
                                                                            <option value="ra-grs">RA-GRS</option>
                                                                            <option value="ra-gzrs" class="">RA-GZRS</option>
                                                                            </select>
                                                                        </div>  

                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="backupStorageSize" className="form-label font-14 font-medium black-v2 mb-3">
                                                                            Point-In-Time Restore in (GB)
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                                id="backupStorageSize"
                                                                                value={values.backupStorageSize}
                                                                                name={"backupStorageSize"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} disabled={isViewer}

                                                                            />
                                                                        </div>

                                                                        <h6 style={{ marginTop: "15px", marginBottom: "20px"}}>Long Term Retention</h6>   

                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="ltrDatabaseSize" className="form-label font-14 font-medium black-v2 mb-3">
                                                                            Average database size during retention period
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                                id="ltrDatabaseSize"
                                                                                value={values.ltrDatabaseSize}
                                                                                name={"ltrDatabaseSize"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} disabled={isViewer}

                                                                            />
                                                                        </div>

                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="ltrDatabaseSizeFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                                                            Unit
                                                                            </label>
                                                                            <select
                                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                id="ltrDatabaseSizeFactor"
                                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                value={values.ltrDatabaseSizeFactor}
                                                                                name={"ltrDatabaseSizeFactor"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} 
                                                                                disabled={isViewer}
                                                                            >
                                                                            <option value="1" class="">GB</option>
                                                                            <option value="1024" class="">TB</option>
                                                                            </select>
                                                                        </div> 

                                                                        <h6 style={{ marginTop: "15px", marginBottom: "20px"}}>Retention Policy</h6> 

                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="WeeklyBackupRetention" className="form-label font-14 font-medium black-v2 mb-3"  style={{marginTop:"6%"}}>
                                                                            Weekly Backup Retention
                                                                            </label>
                                                                            <select
                                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                                id="WeeklyBackupRetention"
                                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                value={values.WeeklyBackupRetention}
                                                                                name={"WeeklyBackupRetention"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} 
                                                                                disabled={isViewer}
                                                                            >
                                                                            <option value="yes" class="">Yes</option>
                                                                            <option value="no" class="">No</option>
                                                                            </select>
                                                                        </div> 

                                                                        {values.WeeklyBackupRetention === 'yes' ? (
                                                                            <div className="col-md-6 px-3">
                                                                                <label htmlFor="weeklyBackups" className="form-label font-14 font-medium black-v2 mb-3">
                                                                                Average database size during retention period
                                                                                </label>
                                                                                <input
                                                                                    type="text"
                                                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                                                    id="weeklyBackups"
                                                                                    value={values.weeklyBackups}
                                                                                    name={"weeklyBackups"}
                                                                                    onChange={(e) => {
                                                                                        onChangeOfConfiguration(e, values.instanceName);
                                                                                    }} disabled={isViewer}

                                                                                />
                                                                            </div>
                                                                        ): (null)}
                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="MonthlyBackupRetention" className="form-label font-14 font-medium black-v2 mb-3"  style={{marginTop:"6%"}}>
                                                                            Monthly Backup Retention
                                                                            </label>
                                                                            <select
                                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                                id="MonthlyBackupRetention"
                                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                value={values.MonthlyBackupRetention}
                                                                                name={"MonthlyBackupRetention"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} 
                                                                                disabled={isViewer}
                                                                            >
                                                                            <option value="yes" class="">Yes</option>
                                                                            <option value="no" class="">No</option>
                                                                            </select>
                                                                        </div> 

                                                                        {values.MonthlyBackupRetention === 'yes' ? (
                                                                            <div className="col-md-6 px-3">
                                                                                <label htmlFor="monthlyBackups" className="form-label font-14 font-medium black-v2 mb-3">
                                                                                Average database size during retention period
                                                                                </label>
                                                                                <input
                                                                                    type="text"
                                                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                                                    id="monthlyBackups"
                                                                                    value={values.monthlyBackups}
                                                                                    name={"monthlyBackups"}
                                                                                    onChange={(e) => {
                                                                                        onChangeOfConfiguration(e, values.instanceName);
                                                                                    }} disabled={isViewer}

                                                                                />
                                                                            </div>
                                                                        ): (null)}
                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="YearlyBackupRetention" className="form-label font-14 font-medium black-v2 mb-3"  style={{marginTop:"6%"}}>
                                                                            Yearly Backup Retention  
                                                                            </label>
                                                                            <select
                                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                                id="YearlyBackupRetention"
                                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                value={values.YearlyBackupRetention}
                                                                                name={"YearlyBackupRetention"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} 
                                                                                disabled={isViewer}
                                                                            >
                                                                            <option value="yes" class="">Yes</option>
                                                                            <option value="no" class="">No</option>
                                                                            </select>
                                                                        </div> 

                                                                        {values.YearlyBackupRetention === 'yes' ? (
                                                                            <div className="col-md-6 px-3">
                                                                                <label htmlFor="yearlyBackups" className="form-label font-14 font-medium black-v2 mb-3">
                                                                                Average database size during retention period
                                                                                </label>
                                                                                <input
                                                                                    type="text"
                                                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                                                    id="yearlyBackups"
                                                                                    value={values.yearlyBackups}
                                                                                    name={"yearlyBackups"}
                                                                                    onChange={(e) => {
                                                                                        onChangeOfConfiguration(e, values.instanceName);
                                                                                    }} disabled={isViewer}

                                                                                />
                                                                            </div>
                                                                        ): (null)}

                                                                    </>
                                                                ):
                                                                (
                                                                    <>
                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="generation" className="form-label font-14 font-medium black-v2 mb-3">
                                                                            Hardware Type
                                                                            </label>
                                                                            <select
                                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                id="generation"
                                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                value={values.generation}
                                                                                name={"generation"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} 
                                                                                disabled={isViewer}
                                                                            >
                                                                           <option value="gen5" class="">Standard-series (Gen 5)</option></select>
                                                                            
                                                                        </div>

                                                                        <div className="row py-4 mx-4 px-5">    
                                                                            <h5 style={{ marginTop: "15px", marginBottom: "20px", color: "black", fontSize: "18px" }}>Compute</h5>
                                                                        </div>

                                                                        <div>
                                                                            <label htmlFor="serverlessRedundancy" className="form-label font-14 font-medium black-v2 mb-3">
                                                                            Redundancy
                                                                            </label>
                                                                            <select
                                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                id="serverlessRedundancy"
                                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                value={values.serverlessRedundancy}
                                                                                name={"serverlessRedundancy"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} 
                                                                                disabled={isViewer}
                                                                            >
                                                                           <option value="primaryreplica" class="">Primary Replica</option>
                                                                           <option value="namedreplica" class="">Named Replica or High Availability (HA) Replica</option>
                                                                           </select>
                                                                            
                                                                        </div>

                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="maxVCores" className="form-label font-14 font-medium black-v2 mb-3"> Maximum vCores
                                                                            </label>
                                                                            <select
                                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                id="maxVCores"
                                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                value={values.maxVCores}
                                                                                name={"maxVCores"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} 
                                                                                disabled={isViewer}
                                                                            >
                                                                                <option value="1">1</option>
                                                                                <option value="2">2</option>
                                                                                <option value="4">4</option>
                                                                                <option value="6">6</option>
                                                                                <option value="8">8</option>
                                                                                <option value="10">10</option>
                                                                                <option value="12">12</option>
                                                                                <option value="14">14</option>
                                                                                <option value="16">16</option>
                                                                                <option value="18">18</option>
                                                                                <option value="20">20</option>
                                                                                <option value="24">24</option>
                                                                                <option value="32">32</option>
                                                                                <option value="40">40</option>
                                                                                <option value="80">80</option>

                                                                            </select>
                                                                        </div>
                                                                        
                                                                        {values.maxVCores === "1" ? (
                                                                            <>
                                                                                <div className="col-md-6 px-3">
                                                                                    <label htmlFor="minVCores" className="form-label font-14 font-medium black-v2 mb-3">Minimum vCores
                                                                                    </label>
                                                                                    <select
                                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                        id="minVCores"
                                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                        value={values.minVCores}
                                                                                        name={"minVCores"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} 
                                                                                        disabled={isViewer}
                                                                                    >
                                                                                        <option value="0.5" class="">0.5</option>
                                                                                        <option value="0.75" class="">0.75</option>
                                                                                        <option value="1" class="">1</option>

                                                                                    </select>
                                                                                </div>
                                                                            </>
                                                                        
                                                                        ) : values.maxVCores === "2" ? (
                                                                            <>
                                                                                <div className="col-md-6 px-3">
                                                                                    <label htmlFor="minVCores" className="form-label font-14 font-medium black-v2 mb-3">Minimum vCores
                                                                                    </label>
                                                                                    <select
                                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                        id="minVCores"
                                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                        value={values.minVCores}
                                                                                        name={"minVCores"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} 
                                                                                        disabled={isViewer}
                                                                                    >
                                                                                        <option value="0.5" class="">0.5</option>
                                                                                        <option value="0.75" class="">0.75</option>
                                                                                        <option value="1" class="">1</option>
                                                                                        <option value="1.25" class="">1.25</option>
                                                                                        <option value="1.5" class="">1.5</option>
                                                                                        <option value="1.75" class="">1.75</option>
                                                                                        <option value="2" class="">2</option>


                                                                                    </select>
                                                                                </div>
                                                                            </>
                                                                        ) : values.maxVCores === "4" ? (
                                                                            <>
                                                                                <div className="col-md-6 px-3">
                                                                                    <label htmlFor="minVCores" className="form-label font-14 font-medium black-v2 mb-3">Minimum vCores
                                                                                    </label>
                                                                                    <select
                                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                        id="minVCores"
                                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                        value={values.minVCores}
                                                                                        name={"minVCores"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} 
                                                                                        disabled={isViewer}
                                                                                    >
                                                                                        <option value="0.5" class="">0.5</option>
                                                                                        <option value="0.75" class="">0.75</option>
                                                                                        <option value="1" class="">1</option>
                                                                                        <option value="1.25" class="">1.25</option>
                                                                                        <option value="1.5" class="">1.5</option>
                                                                                        <option value="1.75" class="">1.75</option>
                                                                                        <option value="2" class="">2</option>
                                                                                        <option value="2.25" class="">2.25</option>
                                                                                        <option value="2.5" class="">2.5</option>
                                                                                        <option value="3" class="">3</option>
                                                                                        <option value="4" class="">4</option>



                                                                                    </select>
                                                                                </div>
                                                                            </>
                                                                        ) : values.maxVCores === "6" ? (
                                                                            <>
                                                                                <div className="col-md-6 px-3">
                                                                                    <label htmlFor="minVCores" className="form-label font-14 font-medium black-v2 mb-3">Minimum vCores
                                                                                    </label>
                                                                                    <select
                                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                        id="minVCores"
                                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                        value={values.minVCores}
                                                                                        name={"minVCores"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} 
                                                                                        disabled={isViewer}
                                                                                    >
                                                                                        <option value="0.75" class="">0.75</option>
                                                                                        <option value="1" class="">1</option>
                                                                                        <option value="1.25" class="">1.25</option>
                                                                                        <option value="1.5" class="">1.5</option>
                                                                                        <option value="1.75" class="">1.75</option>
                                                                                        <option value="2" class="">2</option>
                                                                                        <option value="2.25" class="">2.25</option>
                                                                                        <option value="2.5" class="">2.5</option>
                                                                                        <option value="3" class="">3</option>
                                                                                        <option value="4" class="">4</option>
                                                                                        <option value="5" class="">5</option>
                                                                                        <option value="6" class="">6</option>

                                                                                    </select>
                                                                                </div>
                                                                            </>
                                                                        ) : values.maxVCores === "8" ? (
                                                                            <>
                                                                                <div className="col-md-6 px-3">
                                                                                    <label htmlFor="minVCores" className="form-label font-14 font-medium black-v2 mb-3">Minimum vCores
                                                                                    </label>
                                                                                    <select
                                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                        id="minVCores"
                                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                        value={values.minVCores}
                                                                                        name={"minVCores"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} 
                                                                                        disabled={isViewer}
                                                                                    >
                                                                                        <option value="1" class="">1</option>
                                                                                        <option value="1.25" class="">1.25</option>
                                                                                        <option value="1.5" class="">1.5</option>
                                                                                        <option value="1.75" class="">1.75</option>
                                                                                        <option value="2" class="">2</option>
                                                                                        <option value="2.25" class="">2.25</option>
                                                                                        <option value="2.5" class="">2.5</option>
                                                                                        <option value="3" class="">3</option>
                                                                                        <option value="4" class="">4</option>
                                                                                        <option value="5" class="">5</option>
                                                                                        <option value="6" class="">6</option>
                                                                                        <option value="8" class="">8</option>


                                                                                    </select>
                                                                                </div>
                                                                            </>
                                                                        ) : values.maxVCores === "10" ? (
                                                                            <>
                                                                                <div className="col-md-6 px-3">
                                                                                    <label htmlFor="minVCores" className="form-label font-14 font-medium black-v2 mb-3">Minimum vCores
                                                                                    </label>
                                                                                    <select
                                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                        id="minVCores"
                                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                        value={values.minVCores}
                                                                                        name={"minVCores"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} 
                                                                                        disabled={isViewer}
                                                                                    >
                                                                                        <option value="1.25" class="">1.25</option>
                                                                                        <option value="1.5" class="">1.5</option>
                                                                                        <option value="1.75" class="">1.75</option>
                                                                                        <option value="2" class="">2</option>
                                                                                        <option value="2.25" class="">2.25</option>
                                                                                        <option value="2.5" class="">2.5</option>
                                                                                        <option value="3" class="">3</option>
                                                                                        <option value="4" class="">4</option>
                                                                                        <option value="5" class="">5</option>
                                                                                        <option value="6" class="">6</option>
                                                                                        <option value="8" class="">8</option>
                                                                                        <option value="10" class="">10</option>

                                                                                    </select>
                                                                                </div>
                                                                            </>
                                                                        ) : values.maxVCores === "12" ? (
                                                                            <>
                                                                                <div className="col-md-6 px-3">
                                                                                    <label htmlFor="minVCores" className="form-label font-14 font-medium black-v2 mb-3">Minimum vCores
                                                                                    </label>
                                                                                    <select
                                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                        id="minVCores"
                                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                        value={values.minVCores}
                                                                                        name={"minVCores"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} 
                                                                                        disabled={isViewer}
                                                                                    >
                                                                                        <option value="1.5" class="">1.5</option>
                                                                                        <option value="1.75" class="">1.75</option>
                                                                                        <option value="2" class="">2</option>
                                                                                        <option value="2.25" class="">2.25</option>
                                                                                        <option value="2.5" class="">2.5</option>
                                                                                        <option value="3" class="">3</option>
                                                                                        <option value="4" class="">4</option>
                                                                                        <option value="5" class="">5</option>
                                                                                        <option value="6" class="">6</option>
                                                                                        <option value="8" class="">8</option>
                                                                                        <option value="10" class="">10</option>
                                                                                        <option value="12" class="">12</option>

                                                                                    </select>
                                                                                </div>
                                                                            </>
                                                                        ) : values.maxVCores === "14" ? (
                                                                            <>
                                                                                <div className="col-md-6 px-3">
                                                                                    <label htmlFor="minVCores" className="form-label font-14 font-medium black-v2 mb-3">Minimum vCores
                                                                                    </label>
                                                                                    <select
                                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                        id="minVCores"
                                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                        value={values.minVCores}
                                                                                        name={"minVCores"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} 
                                                                                        disabled={isViewer}
                                                                                    >
                                                                                       <option value="1.75" class="">1.75</option>
                                                                                       <option value="2" class="">2</option>
                                                                                       <option value="2.25" class="">2.25</option>
                                                                                       <option value="2.5" class="">2.5</option>
                                                                                       <option value="3" class="">3</option>
                                                                                       <option value="4" class="">4</option>
                                                                                       <option value="5" class="">5</option>
                                                                                       <option value="6" class="">6</option>
                                                                                       <option value="8" class="">8</option>
                                                                                       <option value="10" class="">10</option>
                                                                                       <option value="12" class="">12</option>
                                                                                       <option value="14" class="">14</option>

                                                                                        
                                                                                    </select>
                                                                                </div>
                                                                            </>
                                                                        ) : values.maxVCores === "16" ? (
                                                                            <>
                                                                                <div className="col-md-6 px-3">
                                                                                    <label htmlFor="minVCores" className="form-label font-14 font-medium black-v2 mb-3">Minimum vCores
                                                                                    </label>
                                                                                    <select
                                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                        id="minVCores"
                                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                        value={values.minVCores}
                                                                                        name={"minVCores"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} 
                                                                                        disabled={isViewer}
                                                                                    >
                                                                                      <option value="2" class="">2</option>
                                                                                      <option value="2.25" class="">2.25</option>
                                                                                      <option value="2.5" class="">2.5</option>
                                                                                      <option value="3" class="">3</option>
                                                                                      <option value="4" class="">4</option>
                                                                                      <option value="5" class="">5</option>
                                                                                      <option value="6" class="">6</option>
                                                                                      <option value="8" class="">8</option>
                                                                                      <option value="10" class="">10</option>
                                                                                      <option value="12" class="">12</option>
                                                                                      <option value="14" class="">14</option>
                                                                                      <option value="16" class="">16</option>
                                                                                        
                                                                                    </select>
                                                                                </div>
                                                                            </>
                                                                        ) : values.maxVCores === "18" ? (
                                                                            <>
                                                                                <div className="col-md-6 px-3">
                                                                                    <label htmlFor="minVCores" className="form-label font-14 font-medium black-v2 mb-3">Minimum vCores
                                                                                    </label>
                                                                                    <select
                                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                        id="minVCores"
                                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                        value={values.minVCores}
                                                                                        name={"minVCores"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} 
                                                                                        disabled={isViewer}
                                                                                    >
                                                                                      <option value="2.25" class="">2.25</option>
                                                                                      <option value="2.5" class="">2.5</option>
                                                                                      <option value="3" class="">3</option>
                                                                                      <option value="4" class="">4</option>
                                                                                      <option value="5" class="">5</option>
                                                                                      <option value="6" class="">6</option>
                                                                                      <option value="8" class="">8</option>
                                                                                      <option value="10" class="">10</option>
                                                                                      <option value="12" class="">12</option>
                                                                                      <option value="14" class="">14</option>
                                                                                      <option value="16" class="">16</option>
                                                                                      <option value="18" class="">18</option>
 
                                                                                    </select>
                                                                                </div>
                                                                            </>
                                                                        ) : values.maxVCores === "20" ? (
                                                                            <>
                                                                                <div className="col-md-6 px-3">
                                                                                    <label htmlFor="minVCores" className="form-label font-14 font-medium black-v2 mb-3">Minimum vCores
                                                                                    </label>
                                                                                    <select
                                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                        id="minVCores"
                                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                        value={values.minVCores}
                                                                                        name={"minVCores"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} 
                                                                                        disabled={isViewer}
                                                                                    >
                                                                                      <option value="2.5" class="">2.5</option>
                                                                                      <option value="3" class="">3</option>
                                                                                      <option value="4" class="">4</option>
                                                                                      <option value="5" class="">5</option>
                                                                                      <option value="6" class="">6</option>
                                                                                      <option value="8" class="">8</option>
                                                                                      <option value="10" class="">10</option>
                                                                                      <option value="12" class="">12</option>
                                                                                      <option value="14" class="">14</option>
                                                                                      <option value="16" class="">16</option>
                                                                                      <option value="18" class="">18</option>
                                                                                      <option value="20" class="">20</option>

 
                                                                                    </select>
                                                                                </div>
                                                                            </>
                                                                        ) : values.maxVCores === "24" ? (
                                                                            <>
                                                                                <div className="col-md-6 px-3">
                                                                                    <label htmlFor="minVCores" className="form-label font-14 font-medium black-v2 mb-3">Minimum vCores
                                                                                    </label>
                                                                                    <select
                                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                        id="minVCores"
                                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                        value={values.minVCores}
                                                                                        name={"minVCores"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} 
                                                                                        disabled={isViewer}
                                                                                    >
                                                                                    <option value="3" class="">3</option>
                                                                                    <option value="4" class="">4</option>
                                                                                    <option value="5" class="">5</option>
                                                                                    <option value="6" class="">6</option>
                                                                                    <option value="8" class="">8</option>
                                                                                    <option value="10" class="">10</option>
                                                                                    <option value="12" class="">12</option>
                                                                                    <option value="14" class="">14</option>
                                                                                    <option value="16" class="">16</option>
                                                                                    <option value="18" class="">18</option>
                                                                                    <option value="20" class="">20</option>
                                                                                    <option value="24" class="">24</option>


                                                                                    </select>
                                                                                </div>
                                                                            </>
                                                                        ) : values.maxVCores === "32" ? (
                                                                            <>
                                                                                <div className="col-md-6 px-3">
                                                                                    <label htmlFor="minVCores" className="form-label font-14 font-medium black-v2 mb-3">Minimum vCores
                                                                                    </label>
                                                                                    <select
                                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                        id="minVCores"
                                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                        value={values.minVCores}
                                                                                        name={"minVCores"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} 
                                                                                        disabled={isViewer}
                                                                                    >
                                                                                    <option value="4" class="">4</option>
                                                                                    <option value="5" class="">5</option>
                                                                                    <option value="6" class="">6</option>
                                                                                    <option value="8" class="">8</option>
                                                                                    <option value="10" class="">10</option>
                                                                                    <option value="12" class="">12</option>
                                                                                    <option value="14" class="">14</option>
                                                                                    <option value="16" class="">16</option>
                                                                                    <option value="18" class="">18</option>
                                                                                    <option value="20" class="">20</option>
                                                                                    <option value="24" class="">24</option>
                                                                                    <option value="32" class="">32</option>

                                                                                    </select>
                                                                                </div>
                                                                            </>
                                                                        ) : values.maxVCores === "40" ? (
                                                                            <>
                                                                                <div className="col-md-6 px-3">
                                                                                    <label htmlFor="minVCores" className="form-label font-14 font-medium black-v2 mb-3">Minimum vCores
                                                                                    </label>
                                                                                    <select
                                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                        id="minVCores"
                                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                        value={values.minVCores}
                                                                                        name={"minVCores"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} 
                                                                                        disabled={isViewer}
                                                                                    >
                                                                                    <option value="5" class="">5</option>
                                                                                    <option value="6" class="">6</option>
                                                                                    <option value="8" class="">8</option>
                                                                                    <option value="10" class="">10</option>
                                                                                    <option value="12" class="">12</option>
                                                                                    <option value="14" class="">14</option>
                                                                                    <option value="16" class="">16</option>
                                                                                    <option value="18" class="">18</option>
                                                                                    <option value="20" class="">20</option>
                                                                                    <option value="24" class="">24</option>
                                                                                    <option value="32" class="">32</option>
                                                                                    <option value="40" class="">40</option>
                                                                                    </select>
                                                                                </div>
                                                                            </>
                                                                        ) : values.maxVCores === "80" ? (
                                                                            <>
                                                                                <div className="col-md-6 px-3">
                                                                                    <label htmlFor="minVCores" className="form-label font-14 font-medium black-v2 mb-3">Minimum vCores
                                                                                    </label>
                                                                                    <select
                                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                        id="minVCores"
                                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                        value={values.minVCores}
                                                                                        name={"minVCores"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} 
                                                                                        disabled={isViewer}
                                                                                    >
                                                                                    <option value="10" class="">10</option>
                                                                                    <option value="12" class="">12</option>
                                                                                    <option value="14" class="">14</option>
                                                                                    <option value="16" class="">16</option>
                                                                                    <option value="18" class="">18</option>
                                                                                    <option value="20" class="">20</option>
                                                                                    <option value="24" class="">24</option>
                                                                                    <option value="32" class="">32</option>
                                                                                    <option value="40" class="">40</option>
                                                                                    <option value="80" class="">80</option>

                                                                                    </select>
                                                                                </div>
                                                                            </>
                                                                        ) : (null)}

                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="cpuUsed" className="form-label font-14 font-medium black-v2 mb-3">
                                                                            CPU used (vCores)
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                                id="cpuUsed"
                                                                                value={values.cpuUsed}
                                                                                name={"cpuUsed"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} disabled={isViewer}

                                                                            />
                                                                        </div>
                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="memoryUsed" className="form-label font-14 font-medium black-v2 mb-3">
                                                                            Memory used (GB)
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                                id="memoryUsed"
                                                                                value={values.memoryUsed}
                                                                                name={"memoryUsed"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} disabled={isViewer}

                                                                            />
                                                                        </div>
                                                                        <div className="col-md-6 px-3">
                                                                            <label htmlFor="duration" className="form-label font-14 font-medium black-v2 mb-3">
                                                                            Duration (in seconds, max 26,78,400 seconds (744 hours))
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                                id="duration"
                                                                                value={values.duration}
                                                                                name={"duration"}
                                                                                onChange={(e) => {
                                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                                }} disabled={isViewer}

                                                                            />
                                                                        </div>
                                                                        {values.serverlessRedundancy === "primaryreplica" ?
                                                                        (
                                                                            <>
                                                                                <div className="row py-4 mx-4 px-5">    
                                                                                    <h5 style={{ marginTop: "15px", marginBottom: "20px", color: "black", fontSize: "18px" }}>Storage
                                                                                    </h5>
                                                                                </div>

                                                                                <div className="col-md-6 px-3">
                                                                                                    
                                                                                    <label htmlFor="singleHyperscaleStorageUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                                                                    Storage Unit
                                                                                    </label>
                                                                                    <input
                                                                                        type="text"
                                                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                                                        id="singleHyperscaleStorageUnits"
                                                                                        value={values.singleHyperscaleStorageUnits}
                                                                                        name={"singleHyperscaleStorageUnits"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} disabled={isViewer}

                                                                                    />
                                                                                </div>

                                                                                <div className="col-md-6 px-3">
                                                                                    <label htmlFor="singleHyperscaleStorageUnitsFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                                                                    Storage Unit Factor
                                                                                    </label>
                                                                                    <select
                                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                        id="singleHyperscaleStorageUnitsFactor"
                                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                        value={values.singleHyperscaleStorageUnitsFactor}
                                                                                        name={"singleHyperscaleStorageUnitsFactor"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} 
                                                                                        disabled={isViewer}
                                                                                    >
                                                                                    <option value="1" class="">GB</option>
                                                                                    <option value="1024" class="">TB</option>
                                                                                    </select>
                                                                                </div>

                                                                                <h6 style={{ marginTop: "15px", marginBottom: "20px"}}>Backup Storage</h6>

                                                                                <div>
                                                                                    <label htmlFor="redundancy" className="form-label font-14 font-medium black-v2 mb-3">
                                                                                    Redundancy
                                                                                    </label>
                                                                                    <select
                                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                        id="redundancy"
                                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                        value={values.redundancy}
                                                                                        name={"redundancy"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} 
                                                                                        disabled={isViewer}
                                                                                    >
                                                                                    <option value="lrs">LRS</option>
                                                                                    <option value="zrs">ZRS</option>
                                                                                    <option value="ra-grs">RA-GRS</option>
                                                                                    <option value="ra-gzrs" class="">RA-GZRS</option>
                                                                                    </select>
                                                                                </div>  

                                                                                <div className="col-md-6 px-3">
                                                                                    <label htmlFor="backupStorageSize" className="form-label font-14 font-medium black-v2 mb-3">
                                                                                    Point-In-Time Restore in (GB)
                                                                                    </label>
                                                                                    <input
                                                                                        type="text"
                                                                                        className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                                        id="backupStorageSize"
                                                                                        value={values.backupStorageSize}
                                                                                        name={"backupStorageSize"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} disabled={isViewer}

                                                                                    />
                                                                                </div>

                                                                                <h6 style={{ marginTop: "15px", marginBottom: "20px"}}>Long Term Retention</h6>   

                                                                                <div className="col-md-6 px-3">
                                                                                    <label htmlFor="ltrDatabaseSize" className="form-label font-14 font-medium black-v2 mb-3">
                                                                                    Average database size during retention period
                                                                                    </label>
                                                                                    <input
                                                                                        type="text"
                                                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                                                        id="ltrDatabaseSize"
                                                                                        value={values.ltrDatabaseSize}
                                                                                        name={"ltrDatabaseSize"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} disabled={isViewer}

                                                                                    />
                                                                                </div>

                                                                                <div className="col-md-6 px-3">
                                                                                    <label htmlFor="ltrDatabaseSizeFactor" className="form-label font-14 font-medium black-v2 mb-3"  style={{marginTop:"6%"}}>
                                                                                    Unit
                                                                                    </label>
                                                                                    <select
                                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                                        id="ltrDatabaseSizeFactor"
                                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                        value={values.ltrDatabaseSizeFactor}
                                                                                        name={"ltrDatabaseSizeFactor"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} 
                                                                                        disabled={isViewer}
                                                                                    >
                                                                                    <option value="1" class="">GB</option>
                                                                                    <option value="1024" class="">TB</option>
                                                                                    </select>
                                                                                </div> 

                                                                                <h6 style={{ marginTop: "15px", marginBottom: "20px"}}>Retention Policy</h6> 

                                                                                <div className="col-md-6 px-3">
                                                                                    <label htmlFor="WeeklyBackupRetention" className="form-label font-14 font-medium black-v2 mb-3"  style={{marginTop:"6%"}}>
                                                                                    Weekly Backup Retention
                                                                                    </label>
                                                                                    <select
                                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                                        id="WeeklyBackupRetention"
                                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                        value={values.WeeklyBackupRetention}
                                                                                        name={"WeeklyBackupRetention"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} 
                                                                                        disabled={isViewer}
                                                                                    >
                                                                                    <option value="yes" class="">Yes</option>
                                                                                    <option value="no" class="">No</option>
                                                                                    </select>
                                                                                </div> 

                                                                                {values.WeeklyBackupRetention === 'yes' ? (
                                                                                    <div className="col-md-6 px-3">
                                                                                        <label htmlFor="weeklyBackups" className="form-label font-14 font-medium black-v2 mb-3">
                                                                                        Average database size during retention period
                                                                                        </label>
                                                                                        <input
                                                                                            type="text"
                                                                                            className=" form-control  custom-form text-field font-14 font-medium"
                                                                                            id="weeklyBackups"
                                                                                            value={values.weeklyBackups}
                                                                                            name={"weeklyBackups"}
                                                                                            onChange={(e) => {
                                                                                                onChangeOfConfiguration(e, values.instanceName);
                                                                                            }} disabled={isViewer}

                                                                                        />
                                                                                    </div>
                                                                                ): (null)}
                                                                                <div className="col-md-6 px-3">
                                                                                    <label htmlFor="MonthlyBackupRetention" className="form-label font-14 font-medium black-v2 mb-3"  style={{marginTop:"6%"}}>
                                                                                    Monthly Backup Retention
                                                                                    </label>
                                                                                    <select
                                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                                        id="MonthlyBackupRetention"
                                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                        value={values.MonthlyBackupRetention}
                                                                                        name={"MonthlyBackupRetention"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} 
                                                                                        disabled={isViewer}
                                                                                    >
                                                                                    <option value="yes" class="">Yes</option>
                                                                                    <option value="no" class="">No</option>
                                                                                    </select>
                                                                                </div> 

                                                                                {values.MonthlyBackupRetention === 'yes' ? (
                                                                                    <div className="col-md-6 px-3">
                                                                                        <label htmlFor="monthlyBackups" className="form-label font-14 font-medium black-v2 mb-3">
                                                                                        Average database size during retention period
                                                                                        </label>
                                                                                        <input
                                                                                            type="text"
                                                                                            className=" form-control  custom-form text-field font-14 font-medium"
                                                                                            id="monthlyBackups"
                                                                                            value={values.monthlyBackups}
                                                                                            name={"monthlyBackups"}
                                                                                            onChange={(e) => {
                                                                                                onChangeOfConfiguration(e, values.instanceName);
                                                                                            }} disabled={isViewer}

                                                                                        />
                                                                                    </div>
                                                                                ): (null)}
                                                                                <div className="col-md-6 px-3">
                                                                                    <label htmlFor="YearlyBackupRetention" className="form-label font-14 font-medium black-v2 mb-3"  style={{marginTop:"6%"}}>
                                                                                    Yearly Backup Retention  
                                                                                    </label>
                                                                                    <select
                                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                                        id="YearlyBackupRetention"
                                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                                        value={values.YearlyBackupRetention}
                                                                                        name={"YearlyBackupRetention"}
                                                                                        onChange={(e) => {
                                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                                        }} 
                                                                                        disabled={isViewer}
                                                                                    >
                                                                                    <option value="yes" class="">Yes</option>
                                                                                    <option value="no" class="">No</option>
                                                                                    </select>
                                                                                </div> 

                                                                                {values.YearlyBackupRetention === 'yes' ? (
                                                                                    <div className="col-md-6 px-3">
                                                                                        <label htmlFor="yearlyBackups" className="form-label font-14 font-medium black-v2 mb-3">
                                                                                        Average database size during retention period
                                                                                        </label>
                                                                                        <input
                                                                                            type="text"
                                                                                            className=" form-control  custom-form text-field font-14 font-medium"
                                                                                            id="yearlyBackups"
                                                                                            value={values.yearlyBackups}
                                                                                            name={"yearlyBackups"}
                                                                                            onChange={(e) => {
                                                                                                onChangeOfConfiguration(e, values.instanceName);
                                                                                            }} disabled={isViewer}

                                                                                        />
                                                                                    </div>
                                                                                ): (null)}
                                                                            </>
                                                                        ):(null)}

                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="dtuTier" className="form-label font-14 font-medium black-v2 mb-3"> Service Tier
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                id="dtuTier"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.dtuTier}
                                                                name={"dtuTier"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} 
                                                                disabled={isViewer}
                                                            >
                                                                <option value="basic" class="">Basic</option>
                                                                <option value="standard" class="">Standard</option>
                                                                <option value="premium" class="">Premium</option>
                                                            </select>
                                                        </div>
                                                        {values.dtuTier === "basic" ? (
                                                            <>
                                                                 <div className="col-md-6 px-3">
                                                                    <label htmlFor="singlePerformanceLevel" className="form-label font-14 font-medium black-v2 mb-3"> Performance Level
                                                                    </label>
                                                                    <select
                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                        id="singlePerformanceLevel"
                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                        value={values.singlePerformanceLevel}
                                                                        name={"singlePerformanceLevel"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} 
                                                                        disabled={isViewer}
                                                                    >
                                                                        <option value="b">B: 5 DTUs, 2 GB included storage per DB, $0.0067/hour</option>
                                                                    </select>
                                                                </div>
                                                            </>
                                                        ) : values.dtuTier === "standard" ? (
                                                            <>
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="singlePerformanceLevel" className="form-label font-14 font-medium black-v2 mb-3"> Performance Level
                                                                    </label>
                                                                    <select
                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                        id="singlePerformanceLevel"
                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                        value={values.singlePerformanceLevel}
                                                                        name={"singlePerformanceLevel"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} 
                                                                        disabled={isViewer}
                                                                    >
                                                                        <option value="s0">S0: 10 DTUs, 250 GB included storage per DB</option>
                                                                        <option value="s1">S1: 20 DTUs, 250 GB included storage per DB</option>
                                                                        <option value="s2">S2: 50 DTUs, 250 GB included storage per DB</option>
                                                                        <option value="s3">S3: 100 DTUs, 250 GB included storage per DB</option>
                                                                        <option value="s4">S4: 200 DTUs, 250 GB included storage per DB</option>
                                                                        <option value="s6">S6: 400 DTUs, 250 GB included storage per DB</option>
                                                                        <option value="s7">S7: 800 DTUs, 250 GB included storage per DB</option>
                                                                        <option value="s9">S9: 1600 DTUs, 250 GB included storage per DB</option>
                                                                        <option value="s12">S12: 3000 DTUs, 250 GB included storage per DB</option>

                                                                    </select>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="singlePerformanceLevel" className="form-label font-14 font-medium black-v2 mb-3"> Performance Level
                                                                    </label>
                                                                    <select
                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                        id="singlePerformanceLevel"
                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                        value={values.singlePerformanceLevel}
                                                                        name={"singlePerformanceLevel"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} 
                                                                        disabled={isViewer}
                                                                    >
                                                                        <option value="p1">P1: 125 DTUs, 500 GB included storage per DB</option>
                                                                        <option value="p2">P2: 250 DTUs, 500 GB included storage per DB</option>
                                                                        <option value="p4">P4: 500 DTUs, 500 GB included storage per DB</option>
                                                                        <option value="p6">P6: 1000 DTUs, 500 GB included storage per DB</option>
                                                                        <option value="p11">P11: 1750 DTUs, 4096 GB included storage per DB</option>
                                                                        <option value="p15">P15: 4000 DTUs, 4096 GB included storage per DB</option>
                                                                    </select>
                                                                </div>
                                                            </>
                                                        )}
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="singleCount" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Databases
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                id="singleCount"
                                                                value={values.singleCount}
                                                                name={"singleCount"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="hours" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Hours
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                id="hours"
                                                                value={values.hours}
                                                                name={"hours"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>

                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="hoursFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Hours Factor
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                id="hoursFactor"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.hoursFactor}
                                                                name={"hoursFactor"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} 
                                                                disabled={isViewer}
                                                            >
                                                            <option value="1" class="">Hours</option>
                                                            <option value="24" class="">Days</option>
                                                            <option value="730" class="">Month</option>
                                                            </select>
                                                        </div>  
                                                        
                                                        {values.dtuTier === "premium" ? (
                                                            <>
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="storageMax" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Storage
                                                                    </label>
                                                                    <select
                                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                        id="storageMax"
                                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                        value={values.storageMax}
                                                                        name={"storageMax"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} 
                                                                        disabled={isViewer}
                                                                    >
                                                                    <option value="500" class="">500 GB</option>
                                                                    <option value="750" class="">750 GB</option>
                                                                    <option value="1024" class="">1 TB</option>
                                                                    </select>
                                                                </div>
                                                            </>
                                                        ): (null)}
                                                        <h6 style={{ marginTop: "15px", marginBottom: "20px"}}>Backup Storage</h6>

                                                        <div>
                                                            <label htmlFor="redundancy" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Redundancy
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                id="redundancy"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.redundancy}
                                                                name={"redundancy"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} 
                                                                disabled={isViewer}
                                                            >
                                                            <option value="lrs">LRS</option>
                                                            <option value="zrs">ZRS</option>
                                                            <option value="ra-grs">RA-GRS</option>
                                                            <option value="ra-gzrs" class="">RA-GZRS</option>
                                                            </select>
                                                        </div> 
                                                        
                                                        <h6 style={{ marginTop: "15px", marginBottom: "20px"}}>Long Term Retention</h6>   
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="ltrDatabaseSize" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Average database size during retention period
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                id="ltrDatabaseSize"
                                                                value={values.ltrDatabaseSize}
                                                                name={"ltrDatabaseSize"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>

                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="ltrDatabaseSizeFactor" className="form-label font-14 font-medium black-v2 mb-3"  style={{marginTop:"6%"}}>
                                                            Unit
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                id="ltrDatabaseSizeFactor"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.ltrDatabaseSizeFactor}
                                                                name={"ltrDatabaseSizeFactor"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} 
                                                                disabled={isViewer}
                                                            >
                                                            <option value="1" class="">GB</option>
                                                            <option value="1024" class="">TB</option>
                                                            </select>
                                                        </div> 

                                                        <h6 style={{ marginTop: "15px", marginBottom: "20px"}}>Retention Policy</h6> 

                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="WeeklyBackupRetention" className="form-label font-14 font-medium black-v2 mb-3"  style={{marginTop:"6%"}}>
                                                            Weekly Backup Retention
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                id="WeeklyBackupRetention"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.WeeklyBackupRetention}
                                                                name={"WeeklyBackupRetention"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} 
                                                                disabled={isViewer}
                                                            >
                                                            <option value="yes" class="">Yes</option>
                                                            <option value="no" class="">No</option>
                                                            </select>
                                                        </div> 

                                                        {values.WeeklyBackupRetention === 'yes' ? (
                                                            <div className="col-md-6 px-3">
                                                                <label htmlFor="weeklyBackups" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Average database size during retention period
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                                    id="weeklyBackups"
                                                                    value={values.weeklyBackups}
                                                                    name={"weeklyBackups"}
                                                                    onChange={(e) => {
                                                                        onChangeOfConfiguration(e, values.instanceName);
                                                                    }} disabled={isViewer}

                                                                />
                                                            </div>
                                                        ): (null)}
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="MonthlyBackupRetention" className="form-label font-14 font-medium black-v2 mb-3"  style={{marginTop:"6%"}}>
                                                            Monthly Backup Retention
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                id="MonthlyBackupRetention"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.MonthlyBackupRetention}
                                                                name={"MonthlyBackupRetention"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} 
                                                                disabled={isViewer}
                                                            >
                                                            <option value="yes" class="">Yes</option>
                                                            <option value="no" class="">No</option>
                                                            </select>
                                                        </div> 

                                                        {values.MonthlyBackupRetention === 'yes' ? (
                                                            <div className="col-md-6 px-3">
                                                                <label htmlFor="monthlyBackups" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Average database size during retention period
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                                    id="monthlyBackups"
                                                                    value={values.monthlyBackups}
                                                                    name={"monthlyBackups"}
                                                                    onChange={(e) => {
                                                                        onChangeOfConfiguration(e, values.instanceName);
                                                                    }} disabled={isViewer}

                                                                />
                                                            </div>
                                                        ): (null)}
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="YearlyBackupRetention" className="form-label font-14 font-medium black-v2 mb-3"  style={{marginTop:"6%"}}>
                                                            Yearly Backup Retention  
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                id="YearlyBackupRetention"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.YearlyBackupRetention}
                                                                name={"YearlyBackupRetention"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} 
                                                                disabled={isViewer}
                                                            >
                                                            <option value="yes" class="">Yes</option>
                                                            <option value="no" class="">No</option>
                                                            </select>
                                                        </div> 

                                                        {values.YearlyBackupRetention === 'yes' ? (
                                                            <div className="col-md-6 px-3">
                                                                <label htmlFor="yearlyBackups" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Average database size during retention period
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                                    id="yearlyBackups"
                                                                    value={values.yearlyBackups}
                                                                    name={"yearlyBackups"}
                                                                    onChange={(e) => {
                                                                        onChangeOfConfiguration(e, values.instanceName);
                                                                    }} disabled={isViewer}

                                                                />
                                                            </div>
                                                        ): (null)}

                                                    </>
                                                )}
                                            </>
                                        )}
                                        

                                        
                                     
                                        
                                        
                                    </div>
                                    
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
                </div>
            </div>
        );
    } catch (error) {
        //console.log(error);
    }
}




// values.region = 'us-east'
// values.type = "elastic"
// values.purchaseModel = "dtu"
// values.vcoreTier = "general-purpose"
// values.generation = "gen5"
// values.instanceSize = "2"
// values.zoneRedundancy = "local"
// values.redundancy = "lrs"
// values.singleCount = "11111"
// values.dtuTier = "basic"
// values.computeTier = "provisioned" 

// values.elasticPerformanceLevel = "50"
// values.singlePerformanceLevel = "b"
// values.elasticCount = "1111111"
// values.hoursFactor = "1"
// values.hours = "111111"
// values.elasticStorageUnits = "111111111111"
// values.backupStorageSize = "1111111111"
// values.ltrDatabaseSizeFactor = "1"
// values.ltrDatabaseSize = "11111"

// values.WeeklyBackupRetention = "yes"
// values.MonthlyBackupRetention = "yes"
// values.YearlyBackupRetention = "yes"

// values.weeklyBackups = "11111"
// values.monthlyBackups = "11111"
// values.yearlyBackups = "1111"

// values.disasterRecovery = "primary-or-georeplica"

// values.singleHyperscaleReplicaUnits = "1111"
// values.singleHyperscaleReplicaHours = "1112"
// values.singleHyperscaleReplicaHoursFactor = "24"

// values.namedReplicaUnits = "122"
// values.namedReplicaHours = "123"
// values.namedReplicaHoursFactor = "24"

// values.singleHyperscaleStorageUnits = "177"
// values.singleHyperscaleStorageUnitsFactor = "178"

// values.cpuUsed = "1"
// values.memoryUsed = "33"
// values.duration = "199"

// values.singleStorageUnits = "1666"