// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* body {
    overflow: overlay
}

::-webkit-scrollbar {
    width: 5px;
    height: 3px;
    display: block
}

::-webkit-scrollbar-button {
    display: none
}

::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #D9D9D9;
}



::-webkit-scrollbar-track-piece {
    background-color: #00000000
}

::-webkit-scrollbar-thumb {
    background: #A1A1A1;
    opacity: .2;
    border-radius: 5px
}

.shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
} */

.drag-btn
{
  cursor: grab !important;
  padding-top: 0;
  padding-right: 6px;
  padding-left: 6px;
  border-radius: 0;
}`, "",{"version":3,"sources":["webpack://./src/Components/ComponentCSS/workbench.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;GAiCG;;AAEH;;EAEE,uBAAuB;EACvB,cAAc;EACd,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":["/* body {\n    overflow: overlay\n}\n\n::-webkit-scrollbar {\n    width: 5px;\n    height: 3px;\n    display: block\n}\n\n::-webkit-scrollbar-button {\n    display: none\n}\n\n::-webkit-scrollbar-track {\n    border-radius: 5px;\n    background-color: #D9D9D9;\n}\n\n\n\n::-webkit-scrollbar-track-piece {\n    background-color: #00000000\n}\n\n::-webkit-scrollbar-thumb {\n    background: #A1A1A1;\n    opacity: .2;\n    border-radius: 5px\n}\n\n.shadow-sm {\n    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;\n} */\n\n.drag-btn\n{\n  cursor: grab !important;\n  padding-top: 0;\n  padding-right: 6px;\n  padding-left: 6px;\n  border-radius: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
