//PS_01
import React, { useState, useEffect,useContext} from 'react';
//PS_02
import DeleteConfirmationModal from '../Organization/Delete_Popup'; 
//PS_03
import { useNavigate ,useLocation} from 'react-router-dom'; 
import { getAllRepoApi,deleteRepositoryApi,editRepositoryApi } from '../../Service/api';
import SideBar from '../sidebar/SideBar';

import { OrgContext } from '../../ContextProvider/OrgContext';
import Loader from '../loader/loader';

const RepositoryGrid = () => {
    //PS_04 create state variables for neccessary functinality
    const [repositoryGrid, setRepositoryGrid] = useState([]);
    const [searchCriteria, setSearchCriteria] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const [editIndex, setEditIndex] = useState(null);
    const [editRepoData, setEditRepoData] = useState({ repoName: '', techStack: [], status: false });
    const [sortOrder, setSortOrder] = useState('asc'); 
    const {
        userRoles,
        orgId,
        providerId,
        architectures,
        canCreateWorkload,
        canDeleteWorkload,
        canViewWorkload,
        setuserRoles,
        setRepository_id,setrepository_name,scm, setScm,

    
        // Add organization and provider fields here
        providerName,       
        orgData,           
        setOrgData,        
        setArchitectures,
    
        envId,             // Environment ID
        workloadId,        // Workload ID
        aiArchitecture,    
        viewMode,          
        envName,           
        language,          
        archType,          
        business,          
        rulebook,          
        archDesc,          
        services,
    
        lastBuildID,       
        lastBuildDate,     
        lastBuildMessage,
    
        repoId,            
        repoName,
    
        PulseOrganization_id,
        PulseVertical_id,
        PulseVertical_name,
        PulseOrganization_name,
        PulseIdentification,
    
        setEnvId           // Function to update environment ID
    } = useContext(OrgContext);
    const [userId, setuserId] = useState(localStorage.getItem('userId'));

    console.log("iddddd",workloadId);
    const handleEditClick = (repo) => {
        setEditIndex(repositoryGrid.findIndex(r => r.repoId === repo.repoId)); // Set the index of the repo being edited
        const parsedTechStack = JSON.parse(repo.techStack);
        setEditRepoData({ ...repo, techStack: parsedTechStack }); // Set the repo data to be edited
    };
    const [sortColumn, setSortColumn] = useState('templatekb_name'); // Default sort column

    const handleSaveEdit = async () => {
        const payload = {
            userId: userId, // Replace with actual user ID
            orgId: orgId, // Replace with actual organization ID
            workloadId:workloadId , // Replace with actual workload ID
            repoId: editRepoData.repoId,
            repositoryName: editRepoData.repoName,
            techstack: JSON.stringify(editRepoData.techStack), // Convert techStack back to string
        };
    
        try {
            const response = await editRepositoryApi(payload); // Call the API function
            if (response.body.success) {
                console.log('Repository edited successfully:', response.data);
    
                // Fetch updated repositories after successful edit
                await fetchRepositories();
    
                setEditIndex(null); // Exit edit mode after saving
            } else {
                console.error('Error editing repository');
            }
        } catch (error) {
            console.error('An error occurred while editing repository:', error);
        }
    };

//PS_05
    const [showModal, setShowModal] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const location = useLocation();
//PS_07
    const { workloadName } = location.state || {};
    const navigate=useNavigate()

    // PS_08  Fetch repositories on component mount
    //PS_09
    useEffect(() => {
        
        fetchRepositories();
        if (!location.state || !workloadName) {
            console.log("No state found, redirecting...");
           
        } else if (!workloadName) {
            console.log("No workload name found in state.");
        } else {
            console.log(`Workload Name: ${workloadName}`);
        }
    }, [location.state, workloadName,navigate]);

    const fetchRepositories = async () => {
        const payload = {
            userId : userId,
            orgId : orgId,
            workloadId:workloadId,// Replace with actual workload ID
            search: searchCriteria, // Search term from state
            status: statusFilter // Status filter
        };

        try {
            const response = await getAllRepoApi(payload); // Call the API function
            if (response.success) {
                console.log('Repositories fetched successfully:', response.data);
                setRepositoryGrid(response.data); // Update state with fetched repositories
                setScm(response.data[0].scm)
                
            } else {
                console.error('Error fetching repositories');
            }
        } catch (error) {
            console.error('An error occurred while fetching repositories:', error);
        }finally{
            setLoading(false);

        }
    };
    const handleClearStatusFilter = () => {
        // Clear the status filter
        setStatusFilter('');
        // Fetch repositories
        fetchRepositories();
    };
//PS_11
    
//PS_12
    const handleSearch = (event) => {
        setSearchCriteria(event.target.value);
        fetchRepositories();
    };
//PS_13
    const handleStatusChange = (event) => {
        setStatusFilter(event.target.value);
    };

//PS_15
    const openDeleteModal = (repo) => {
        setItemToDelete(repo);
        setShowModal(true);
    };
    //PS_16
    const handleDeleteConfirm = async () => {
        if (itemToDelete) {
           
                const payload = {
                    userId : "c7d86e9d-4819-4f5e-95f8-782d4c6774a3",
                    orgId : "28da4a71-14f5-47ce-a349-35840ebf2dcb",
                    workloadId:"73554aab-0f13-4605-99c3-6fd917c4f3be",// Replace with actual workload ID
                    repoName:itemToDelete.repoName, // Repository name from state
                    repoId: itemToDelete.repoId // Repository ID from state
                };
                console.log("paylod",payload);
        
                try {
                    const response = await deleteRepositoryApi(payload); // Call the API function
                    console.log("paylodw");

                    if (response.success) {
                        console.log('Repository deleted successfully:', response.data);
                        
                    } else {
                        console.error('Error deleting repository');
                      
                    }
                } catch (error) {
                    console.error('Failed to delete repository:', error);
                   
                }
            
            
        }
        closeDeleteModal();
    };
    const handleRepoClick = (e) => {
        // e.preventDefault(); // Prevent default anchor behavior
        setRepository_id(e.repoId); // Set the repository ID
        setrepository_name(e.repoName); // Set the repository name
        console.log("nameee",e.repoName);
        localStorage.setItem("repoId" , e.repoId)
        // Navigate to the RepoWorkbench route
        navigate('/RepoWorkBench'); // Adjust the path as necessary
    };

//PS_17
    const closeDeleteModal = () => {
        setShowModal(false);
        setItemToDelete(null);
    };
//PS_18
    const handleAddClick = () => {
        navigate('/repositoryDetails', { state: { mode: 'add' } });
    };
    const handleSort = () => {
        console.log("inn");
        const sortedData = [...repositoryGrid].sort((a, b) => {
            const nameA = a.repoName.toLowerCase();
            const nameB = b.repoName.toLowerCase();

            if (sortOrder === 'asc') {
                return nameA < nameB ? -1 : 1;
            } else {
                return nameA > nameB ? -1 : 1;
            }
        });

        // Toggle sort order for next click
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
setRepositoryGrid(sortedData)
    };
//PS_19
    // Navigate to environment details with mode for editing
    // const handleEditClick = (repo) => {
    //     navigate('/repositoryDetails', { state: { mode: 'edit', repo } });
    // };
    const [loading, setLoading] = useState(true);
    const isAnyFilterSelected = statusFilter;
    if (loading) {
        return <Loader />; // Show loader while loading
    }
    return (
        <div className="container-fluid">
        <div className="">
            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                    <SideBar/>
        <div className="col-md-9 right-container-scroll">
            <div className="px-4 mt-4">
                <div className="d-xl-flex justify-content-between align-items-center py-32">
                    <div className="d-flex align-items-center mb-2 mt-xl-0">
                        <button type="button" className="button outline-button me-3 custom-black" onClick={()=>{  navigate('/workloadsGrid')}}>Back to Workloads</button>
                        <h2 className="font-20 font-bold black-v2 mb-0">{workloadName}</h2>
                    </div>
                    <div className="d-flex gap-3">
                        <div className="input-group input-search custom-border-box custom-search-box">
                            <input
                                type="search"
                                className="px-3 form-control common-bg border-0 custom-search"
                                placeholder="Search"
                                value={searchCriteria}
                                onChange={handleSearch}
                            />
                            <button className="btn border-start-0 custom-search" type="button">
                                <img src="images/search-icon.svg" alt="search" />
                            </button>
                        </div>
                        <button type="button" className="filter-button px-2 position-relative" data-bs-toggle="dropdown">
                            <img src="images/filter-blue.svg" alt="filter-icon" />
                        </button>
                        <div className="dropdown-menu dropdown-menu-end border-0 custom-shadow dd-position p-4 background-white custom-filter-width">
                            <h5 className="font-medium font-16 mb-0 color-black mb-4">Filter</h5>
                            <div className="row">
                                <div className="col-md-6 mb-4 px-2">
                                    <label className="form-label font-14 font-medium black-v2 mb-2" htmlFor="Status">Status</label>
                                    <select
    className="form-select custom-dropdown shadow-none custom-border-box py-1 font-14 font-regular black-v2"
    id="Status"
    value={statusFilter}
    onChange={handleStatusChange}
    aria-label="Select Status"
>
    <option value="" disabled>Select</option> {/* Make the default option disabled */}
    <option value="true">Active</option>
    <option value="false">Inactive</option>
</select>
                                </div>
                               
                                <div className="col-md-12">
                                    <div className="d-flex justify-content-end align-items-center gap-3">
                                    <button
    className="button outline-button px-4"
    onClick={handleClearStatusFilter}
>
    Clear All
</button>                                        <button className="btn button primary-button px-3"  disabled={!isAnyFilterSelected} onClick={fetchRepositories}>Apply</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Add Repository Button */}
                        <button type="button" className="button primary-button text-nowrap"onClick={()=>{handleAddClick()} }>
                            <img src="images/add-icon.svg" alt="add-icon" className="me-2"/> Add Repository
                        </button>
                    </div>
                </div>

                {/* Repository Table */}
                <div className="table-responsive table-border">
                <table className="table custom-table">
    <thead>
        <tr>
            <th className="position-relative text-start border-0">
                Repository
                <span className="cursor-pointer margin-left-2" onClick={handleSort}>
                <img 
                    src="./images/table-sort.svg" 
                    alt="sort" 
                    style={{
                        transform: 
                            sortColumn === "templatekb_name" && sortOrder === "asc" 
                                ? "scaleY(-1)" // Flip icon for ascending order
                                : "scaleY(1)",  // Normal position for descending order
                        transition: "transform 0.2s ease-in-out", // Smooth transition
                        cursor: 'pointer' // Pointer cursor for better UX
                    }} 
                />
            </span>
            </th>
            <th className="text-start border-0">CI/CD Status</th>
            <th className="text-start border-0">Tech Stack</th>
            <th className="text-center border-0">Actions</th>
        </tr>
    </thead>
    <tbody className="manage-work">
        {repositoryGrid.length > 0 ? (
            repositoryGrid.map((repo, index) => (
                <tr key={repo.repoId}>
                    {editIndex === index ? (
                        <>
                            {/* Inline Edit Fields */}
                            <td>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={editRepoData.repoName}
                                    onChange={(e) => setEditRepoData({ ...editRepoData, repoName: e.target.value })}
                                    readOnly          
                                />
                            </td>
                            <td className="text-start">
                                <span className={`status ${editRepoData.status ? 'active' : 'inactive'} me-1`} />
                                {editRepoData.status ? 'Active' : 'Inactive'}
                            </td>
                            <td>
                                {/* Editable techStack as a comma-separated input */}
                                <input
                                    type="text"
                                    className="form-control"
                                    value={editRepoData.techStack.join(', ')} // Display as a comma-separated string for editing
                                    onChange={(e) => setEditRepoData({ ...editRepoData, techStack: e.target.value.split(',').map(item => item.trim()) })}
                                />
                            </td>
                            <td className="d-flex justify-content-center gap-3">
                                {/* Save and Cancel Buttons for Editing */}
                                <a href="#" onClick={handleSaveEdit}>
                                    <img src="/images/tick-large-icon.svg" alt="tick-icon" width={16} height={16} />
                                </a>

                                {/* Cancel Action */}
                                <a href="#" onClick={() => setEditIndex(null)}>
                                    <img src="/images/cross-large-icon.svg" alt="cross-icon" width={10} height={10} />
                                </a>
                            </td>
                        </>
                    ) : (
                        <>
                            {/* Display Repository Details */}
                            <td className="text-start">
                                <a href="#" className="primary-color text-decoration-none font-medium" onClick={() => handleRepoClick(repo)}>
                                    {repo.repoName}
                                </a>
                            </td>
                            <td className="text-start">
                                <span className={`status ${repo.status ? 'active' : 'inactive'} me-1`} />
                                {repo.status ? 'Active' : 'Inactive'}
                            </td>
                            <td className="text-start">
                                {/* Display techStack as badges */}
                                <div className="d-flex flex-wrap gap-2">
                                    {JSON.parse(repo.techStack).map((tech, techIndex) => (
                                        <span key={techIndex}>{tech}</span>
                                    ))}
                                </div>
                            </td>
                            <td className="d-flex justify-content-center gap-3">
                                {/* Edit Action */}
                                <a href="#" onClick={() => handleEditClick(repo)}>
                                    <img src="/images/edit-icon.svg" alt="edit-icon" />
                                </a>
                            </td>
                        </>
                    )}
                </tr>
            ))
        ) : (
            // No records found message
            <tr>
            <td colSpan={4} className="text-center">
                <div className="d-flex flex-column justify-content-center align-items-center custom-height">
                    <img src="images/nodata-found.svg" alt="No Data Found" />
                    <h6 className="color-black font-bold font-16 mt-5 mb-0">No Data Found!</h6>
                    <p className="font-medium font-12 mt-4 mb-0 secondary-text-color-v5">
                        Add new data to see the data here.
                    </p>
                </div>
            </td>
        </tr>
        )}
    </tbody>
</table>
                    {/* Number of Records Display */}
                  
                </div> 
                {repositoryGrid.length > 0 && (
                        <div className='d-flex justify-content-between align-items-center my-4'>
                            <p className='font-14 font-medium secondary-text-color mb-0'>
                                # of Records: 
                                <span> {repositoryGrid.length} out of {repositoryGrid.length}</span>
                            </p>
                        </div> 
                    )}
                {/* {showModal && (
                    <DeleteConfirmationModal
                        item={itemToDelete}
                        itemType={"repository"}
                        onDeleteConfirm={handleDeleteConfirm}
                        onClose={closeDeleteModal}
                    />
                )} */}
            </div> 
        </div> 
        </div></div></div></div></div>
    );
};

export default RepositoryGrid;