import React from 'react';


const RelativeLoader = () => {
    return (
        <div className="container-fluid p-0" style={{position :"relative"}}>
            <div className="container-backdrop d-flex align-items-center justify-content-center flex-column w-100">
                {/* Ensure the path to your GIF is correct */}
                <img src="images/Dots Loader 1.gif" alt="Loading..." className="loader-width" />
                {/* <p className="font-20 font-medium text-white mt-3">Loading...</p> */}
            </div>
        </div>
    );
};

export default RelativeLoader;