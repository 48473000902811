//PS_01
import React, { useState, useEffect } from 'react';
//PS_02
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom'; // For navigation
//PS_03
import { createRulesApi,updateRulesApi,getAllRuleCategoryApi} from '../../Service/api';

const AddRule = () => {
    //PS_04
    const [ruleFormData, setRuleFormData] = useState({
        ruleId: "",
        selectedCategory: "",
        ruleText: "",
        isDefaultChecked: false,
    });
    const [userId, setuserId] = useState(localStorage.getItem('userId'));

    //PS_05
    const [categories, setCategories] = useState([]);
//PS_06
    const location = useLocation();
    const state = location.state;
//PS_08
    const navigate =useNavigate();
  
//PS_09
    useEffect(() => {
        //PS_10
        loadRuleCategories()
        //PS_11
        if (state) {

            console.log("ddd",state);
            // If editing an existing rule, fetch its details
                setRuleFormData({
                    ...ruleFormData,
                    ruleId:state.rule.ruleId,
                    ruleText:state.rule.rule,
                    isDefaultChecked:state.rule.default_rule,
                    selectedCategory:state.rule.categoryId
                });
        }
    }, []);

    //PS_14
    const loadRuleCategories = async () => {
        const payload = {
            userId: userId,
        };
//PS_16
        try {
            const response = await getAllRuleCategoryApi(payload); // Call the API function
            if (response.success) {
                console.log('Rule categories fetched successfully:', response.data);
                setCategories(response.data); // Update state with fetched rule categories

               
            } else {
                console.error('Error fetching rule categories');
            }
        } catch (error) {
            console.error('An error occurred while fetching rule categories:', error);
        }
    };

    
    //PS_18 Function to handle input changes
    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setRuleFormData({
            ...ruleFormData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    // PS_19 Function to handle form submission
    const handleSubmit = async(event) => {
        const payload = {
            userId : userId,
            categoryId: ruleFormData.selectedCategory,
            rule: ruleFormData.ruleText,
            isDefault: ruleFormData.isDefaultChecked
        };
//PS_21
        try {
            const response = await createRulesApi(payload); // Call the API function
            if (response.body.success) {
                navigate('/ruleBook')
                console.log('Rule created successfully:', response.data);
             
            } else {
                console.error('Error creating rule');
            
            }
        } catch (error) {
            console.error('Failed to create rule:', error);
        }
    };
//PS_23
    const handleUpdateRules = async (event) => {
console.log("updatex");
        const payload = {
            userId :userId,
            categoryId: ruleFormData.selectedCategory,
            rule: ruleFormData.ruleText,
            isDefault: ruleFormData.isDefaultChecked,
            ruleId:ruleFormData.ruleId  // Rule ID from state
        };
//PS_25
        try {
            const response = await updateRulesApi(payload); // Call the API function
            if (response.body.success) {
                navigate('/ruleBook')

            } else {
                console.error('Error updating rule');
           
            }
        } catch (error) {
            console.error('Failed to update rule:', error);
        }
    };

    const isFormValid = () => {
        return ruleFormData.selectedCategory && ruleFormData.ruleText.trim() !== '';
    };
    return (
        <div className="container-fluid custom-container" style={{height:"100vh"}}>
            {/* Slide title start here */}
            <div className="d-block mt-4 pt-3 about-page-title">
                <div className="d-flex align-items-center mb-3 mb-md-0 ">
                    <a href="#" onClick={()=>{navigate('/ruleBook')}}>
                        <img src="images/back-arrow.svg" alt="back-arrow" className="icon-filter" title="Back" />
                    </a>
                    <h2 className="font-20 font-bold black-v2 mb-0 ms-3">Add New Rule</h2>
                </div>
            </div>
            {/* Slide title end here */}

            {/* Page Content Start here */}
            <div className="col-md-12">
                <div className="row add-organization-form mt-4 pt-3">
                    <div className="ps-5">
                        <div className="row kb-template-section">
                            <div className="col-md-3 mb-4 pb-3">
                                <label className="form-label font-14 font-medium" htmlFor="category">Category<span className="required">*</span></label>
                                <select 
                                    name="selectedCategory" 
                                    id="category" 
                                    className="form-select form-control custom-form shadow-none"
                                    value={ruleFormData.selectedCategory}
                                    onChange={handleChange}
                                >
                                    <option value="">Select Category</option>
                                    {categories.map(category => (
                            <option key={category.ruleCategoryId} value={category.ruleCategoryId}>
                                {category.ruleCategoryName}
                            </option>
                        ))}
                                </select>
                            </div>
                            <div className="col-md-12 mb-4 pb-3">
                                <label className="form-label font-14 font-medium" htmlFor="rule">Rule<span className="required">*</span></label>
                                <textarea 
                                    className="form-control custom-form custom-textarea font-14 font-medium shadow-none" 
                                    placeholder="Add Rule" 
                                    id="rule"
                                    name="ruleText"
                                    value={ruleFormData.ruleText}
                                    onChange={handleChange}
                                />
                                <div className="form-check mt-2">
                                    <input 
                                        type="checkbox" 
                                        className="form-check-input custom-checkbox shadow-none" 
                                        id="default-rule" 
                                        style={{ width: '14px' }}
                                        name="isDefaultChecked"
                                        checked={ruleFormData.isDefaultChecked}
                                        onChange={handleChange}
                                    />
                                    <label className="font-14 font-medium ms-1 black-v2" htmlFor="default-rule">Default Rule</label>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex align-items-center justify-content-between mt-5 pt-5 kb-template">
                            <button type="button" className="button cancel-button text-nowrap" onClick={()=>{navigate('/ruleBook')}}>Cancel</button>
                            <button type="button" className="button primary-button text-nowrap"  disabled={!isFormValid()}  onClick={()=>{state?.rule?.ruleId?handleUpdateRules():handleSubmit()}}>Save Details</button>
                        </div>

                    </div>
                </div>
            </div>
            {/* Page Content End here */}
        </div>
    );
};

export default AddRule;