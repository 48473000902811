import React, { useState, useEffect, useRef, useContext } from "react";
import { OrgContext } from "../../ContextProvider/OrgContext";
import { Editor } from "@monaco-editor/react";
import mermaid from "mermaid";
import { useLocation, useNavigate } from "react-router-dom";
import { cicdDeployment, cicdCommit, getAllBranches } from "../../Service/api";
import { RepoWorkbenchNav } from "../Organization/RepoWorkBenchNav";
import Loader from "../loader/loader";
import RelativeLoader from "../loader/RelativeLoader";
import WorkbechPopup from "../Organization/Workbench/WorkBench/PopupMaintanence/WorkBenchPopUp";

const CICDDeployment = () => {
  // const { orgId, RepositoryId, envId, repoName, services } = useContext(OrgContext);
  const { orgId, repoId, envId, repoName } = useContext(OrgContext);
  const [RepositoryId , setRepositoryId] = useState(repoId ? repoId :"")
  let location = useLocation()
  useEffect(()=>{
      console.log(location.state , "State")
      if(location.state){
          const {repoId} = location.state
          setRepositoryId(repoId)

      }
  },[location])
  
  const [yamlCode, setYamlCode] = useState("");
  const [mermaidCode, setMermaidCode] = useState("");
  const [codeMapping, setCodeMapping] = useState({});
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [commitMessage, setCommitMessage] = useState("");
  const [isKbChecked, setIsKbChecked] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [decorations, setDecorations] = useState([]);
  const mermaidRef = useRef(null);
  const editorRef = useRef(null);
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false)
	const [popupMsg, setPopUpMsg] = useState({"Msg":"",show:false,success:false})


  useEffect(() => {
    if (!repoId && !location.state?.repoId) {
      navigate("/workloadsGrid");
      return;
    }

    console.log(location.state, "State");
    if (location.state) {
      const { repoId } = location.state;
      setRepositoryId(repoId);
    }
  }, [repoId, location, navigate]);

  useEffect(() => {
    fetchDeploymentData();
    fetchBranches();
  }, []);

  
  
useEffect(() => {
  const style = document.createElement('style');
  style.textContent = `
    .highlightedLine {
      background-color: yellow !important;
    }
    .inlineHighlightedLine {
      background-color: yellow !important;
    }
  `;
  document.head.appendChild(style);

  return () => {
    document.head.removeChild(style);
  };
}, []);


useEffect(() => {
  const style = document.createElement('style');
  style.textContent = `
    .node rect { fill: #fff; stroke: #000; }
    .node text { fill: #000; font-family: Arial, sans-serif; font-size: 12px; }
    .edgeLabel { background-color: #fff; }
  `;
  document.head.appendChild(style);

  return () => {
    document.head.removeChild(style);
  };
}, []);


useEffect(() => {
  console.log("Updated codeMapping:", codeMapping,mermaidCode);

}, [codeMapping,mermaidCode]);

useEffect(()=>{

  if(mermaidCode){
  renderMermaidDiagram(mermaidCode);
  }
},[mermaidCode])
const fetchDeploymentData = async () => {
  try {
    const payload = {
      organizationId: orgId,
      userId: localStorage.getItem('userId'),
      repoId: RepositoryId,
    };
    setShowLoader(true);
    const response = await cicdDeployment(payload);
    setShowLoader(false);

    if (response && response.data && response.data.body && response.data.body.data) {
      let { cicdCode, cicdMermaidCode, cicdCodeMapping } = response.data.body.data;
      
      // Clean and parse cicdMermaidCode
      cicdMermaidCode = cleanMermaidCode(cicdMermaidCode);
      
      console.log("Cleaned Mermaid Code:", cicdMermaidCode);

      // Set YAML code
      setYamlCode(cicdCode);

      // Set Mermaid code
      setMermaidCode(cicdMermaidCode);

      // Parse and set code mapping
      const parsedCodeMapping = parseCodeMapping(cicdCodeMapping);
      console.log("Parsed Code Mapping:", parsedCodeMapping);
      setCodeMapping(parsedCodeMapping);

      // Render Mermaid diagram

// Remove 'flowchart TD' from the string
// const MermaidCode = cicdMermaidCode.replace(/flowchart TD/g, "").trim();

    }
  } catch (error) {
    console.log("Error fetching deployment data:", error);
  }
};
//   const fetchDeploymentData = async () => {
//     try {
//       const payload = {
//         organizationId: orgId,
//         userId: localStorage.getItem('userId'),
//         repoId: RepositoryId,
//       };
//       setShowLoader(true);
//       const response = await cicdDeployment(payload);
//       setShowLoader(false);

      
// if (response && response.data && response.data.body && response.data.body.data) {
//   let { cicdCode, cicdMermaidCode, cicdCodeMapping } = response.data.body.data;
  
//   // Clean and parse cicdMermaidCode
//   cicdMermaidCode = cleanMermaidCode(cicdMermaidCode);
  
//   console.log("Cleaned Mermaid Code:", cicdMermaidCode); // Debug log

//   // Set YAML code first
//   setYamlCode(cicdCode);

//   // Then set Mermaid code and code mapping
//   setMermaidCode(cicdMermaidCode);
//   setCodeMapping(parseCodeMapping(cicdCodeMapping)); // Parse the mapping here

//   // Render Mermaid diagram
//   renderMermaidDiagram(cicdMermaidCode);
// }
//     } catch (error) {
//       console.log("Error fetching deployment data:", error);
//     }
//   };

  useEffect(() => {
    mermaid.initialize({ 
      startOnLoad: true,
      securityLevel: 'loose',
      flowchart: {
        useMaxWidth: true,
        htmlLabels: true,
        curve: 'basis',
      },
    });
  }, []);
  
 
 const renderMermaidDiagram = (code) => {
  console.log("mermaidRef.current:", mermaidRef.current);
    console.log("Attempting to render diagram with code:", code);
    if (mermaidRef.current && code) {
      mermaidRef.current.innerHTML = ''; // Clear previous content
      const element = document.createElement("div");
      element.className = "mermaid";
      element.textContent = code;
      mermaidRef.current.appendChild(element);

      // Initialize and run Mermaid
      mermaid.initialize({ startOnLoad: false });
      mermaid.run({ nodes: mermaidRef.current.querySelectorAll('.mermaid') })
        .then(() => {
          console.log("Mermaid diagram rendered successfully");
          addClickListeners(); // Add click listeners after rendering
        })
        .catch(error => {
          console.error("Mermaid rendering failed:", error);
        });
    } else {
      console.log("mermaidRef or code is not available");
    }
  };
  
  const cleanMermaidCode = (code) => {
    if (typeof code !== 'string') return '';
    
    // Remove surrounding quotes, backticks, and 'mermaid' keyword
    code = code.replace(/^["'`]*mermaid?\s*/, '')
              .replace(/["'`]*$/, '')
              .trim();
    
    // Ensure the code starts with 'flowchart TD'
    // if (!code.startsWith('flowchart TD')) {
    //   code = 'flowchart TD\n' + code;
    // }
    
    // Replace problematic characters
    code = code.replace(/"/g, "'");
    
    return code;
  };

  
  
  
  
  
  const parseCodeMapping = (mappingString) => {
    // Remove any surrounding quotes or erroneous characters.
    const cleanMappingString = mappingString.replace(/["'`]+/g, '');
    const lines = cleanMappingString.split('\n').filter(line => line.includes(':'));
    const mapping = {};
  
    lines.forEach(line => {
      const [key, value] = line.split(':').map(part => part.trim());
      if (key && value) {
        // Convert the value string into an array of integers (line numbers)
        const lineNumbers = value.replace(/[\[\]]/g, '').split(',').map(numStr => parseInt(numStr.trim(), 10));
        mapping[key.toLowerCase()] = lineNumbers;
      }
    });
    
    console.log("Parsed code mapping:", mapping);
    return mapping;
  };

const parseAndSetCodeMapping = (cicdCodeMapping) => {
  const parsedCodeMapping = parseCodeMapping(cicdCodeMapping);
  setCodeMapping(parsedCodeMapping);
};


const handleNodeText = (node) => {
  // First, try to get textContent directly from the node label
  let text = node?.querySelector('.nodeLabel')?.textContent?.trim();
  // If not found, try to get it from <span> tags inside the node label
  if (!text) {
    text = node?.querySelector('.nodeLabel span')?.textContent?.trim();
  }
  return text;
};


const addClickListeners = () => {
  const svg = mermaidRef.current?.querySelector("svg");
  if (svg) {
    svg.querySelectorAll(".node").forEach((node) => {
      if (node instanceof SVGElement) {
        node.style.cursor = "pointer";
        node.addEventListener("click", (e) => {
          const nodeId = e.currentTarget.id;
          handleNodeClick(nodeId);
        });
      }
    });
  }
};


const handleNodeClick = (nodeId) => {
  // Get node label text by nodeId
  const labelElem = document.querySelector(`#${nodeId} .nodeLabel`);
  let nodeText = labelElem?.textContent?.trim().toLowerCase();
  
  console.log("Node clicked:", nodeId);
  console.log("Extracted node text:", nodeText);
  
  // Remove any additional text after the first word
  nodeText = nodeText.split(' ')[0];
  
  console.log("Simplified node text:", nodeText);
  console.log("Current code mapping:", codeMapping);

  // look for a matching key in the codeMapping object
  const mappingLineNumbers = codeMapping[nodeText];
  
  // If a match is found, highlight the respective lines
  if (mappingLineNumbers) {
    console.log("Highlighting lines:", mappingLineNumbers);
    highlightLines(mappingLineNumbers);
  } else {
    console.log("No mapping found for node text:", nodeText);
    clearHighlighting();
  }
}; 
const highlightLines = (lines) => {
  console.log("Highlighting lines:", lines);
  if (!Array.isArray(lines)) {
    console.error("Expected an array of line numbers");
    return;
  }
  if (editorRef.current && window.monaco) {
    const editor = editorRef.current;
    const monaco = window.monaco;

    // Clear existing decorations
    editor.deltaDecorations(decorations, []);

    const newDecorations = lines.map((line) => ({
      range: new monaco.Range(line, 1, line, Number.MAX_VALUE),
      options: { 
        isWholeLine: true, 
        className: "highlightedLine",
        inlineClassName: "inlineHighlightedLine"
      },
    }));

    console.log("New decorations:", newDecorations);

    const newDecorationsIds = editor.deltaDecorations([], newDecorations);
    console.log("New decoration IDs:", newDecorationsIds);
    setDecorations(newDecorationsIds);

    // Scroll to the first highlighted line
    if (lines.length > 0) {
      editor.revealLineInCenter(lines[0]);
    }
  } else {
    console.log("Editor or Monaco not available");
  }
};
  const clearHighlighting = () => {
    if (editorRef.current) {
      editorRef.current.deltaDecorations(decorations, []);
      setDecorations([]);
    }
  };

  const fetchBranches = async () => {
    try {
      const payload = {
        organizationId: orgId,
        userId: localStorage.getItem('userId'),
        repoId: RepositoryId,
      };
      const response = await getAllBranches(payload);
      console.log(response , "from the Get ALl Branchaes")
      if (response.data && Array.isArray(response.data)) {
        setBranches(response.data);
        if (response.data.length > 0) {
          setSelectedBranch(response.data[0].branch_id);
        }
      }
    } catch (error) {
      console.log("Error fetching branches:", error);
    }
  };
  const handleApplyClick = () => setShowPopup(true);

  const handlePopupApplyClick = async () => {
    try {
      const payload = {
        organizationId: orgId,
        userId: localStorage.getItem('userId'),
        repoId: RepositoryId,
        branchId: selectedBranch,
        commitMessage: commitMessage,
        isKb: isKbChecked.toString(),
      };
      
      const response = await cicdCommit(payload);
      setShowPopup(false);

      if (response.success) {
      setPopUpMsg({...popupMsg , ["Msg"]:"Commit Successfull" , ["show"]:true ,["success"]:true })

        console.log("Changes committed successfully!");
      } else {
      setPopUpMsg({...popupMsg , ["Msg"]:"Commit UnSuccessfull" , ["show"]:true ,["success"]:false })

        console.log("Failed to commit changes. Please try again.");
      }
    } catch (error) {
      setPopUpMsg({...popupMsg , ["Msg"]:"Commit UnSuccessfull" , ["show"]:true ,["success"]:false })
      console.log("Error committing changes:", error);
      setShowPopup(false);

    }
  };

  const handlePopupCancelClick = () => setShowPopup(false);

  const handleDownload = () => {
    const element = document.createElement("a");
    const file = new Blob([yamlCode], { type: "text/yaml" });
    element.href = URL.createObjectURL(file);
    element.download = "cicd_config.yaml";
    document.body.appendChild(element);
    element.click();
  };


  return (
    <>
     <RepoWorkbenchNav/>
      {showLoader ? 
        <RelativeLoader/> : 
      <>
      <div className="container-fluid body-background" style={{position:"absolute"}}>
        <div className="px-4 pt-5">
          <div className="row">
            <div className="col-md-12 mb-4">
              <div className="d-flex justify-content-between align-items-center">
                <p className="font-18 font-medium color-black m-0">CI/CD</p>
                <div className="d-flex align-items-center gap-3">
                  <button
                    type="button"
                    className="button secondary-outline-button px-4"
                    onClick={handleApplyClick}
                  >
                    Apply
                  </button>
                  <button
                    type="button"
                    className="button primary-button px-32 d-flex align-items-center"
                    onClick={handleDownload}
                  >
                    <img
                      src="images/download-icon.svg"
                      alt="download-icon"
                      className="me-2"
                    />
                    Download
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6 px-2">
                  <div className="code-container">
                  
<Editor
  height="528px"
  defaultLanguage="yaml"
  value={yamlCode}
  className="cic-container bg-black-v3"
  options={{
    readOnly: true,
    lineNumbers: "on",
    minimap: { enabled: false },
    renderLineHighlight: 'all',
    lineDecorationsWidth: 5,
  }}
  onMount={(editor, monaco) => {
    console.log("Monaco editor mounted");
    editorRef.current = editor;
    window.monaco = monaco;
  }}
  
/>
                  </div>
                </div>
                <div className="col-md-6 px-2">
                  <div className="code-container" ref={mermaidRef}></div>
                </div>
              </div>
            </div>
            <div className="col-md-12 mt-4 pt-3 mb-3">
              <div className="form-check d-flex align-items-center ps-3 gap-2">
                <input
                  className="form-check-input custom-checkbox shadow-none"
                  type="checkbox"
                  id="KnowledgeBasecheck"
                  checked={isKbChecked}
                  onChange={(e) => setIsKbChecked(e.target.checked)}
                />
                <label
                  className="form-check-label font-12 font-medium secondary-text-color-v4 pt-1"
                  htmlFor="KnowledgeBasecheck"
                >
                  Update to Knowledge Base
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
       }

      {showPopup && (
        <div
          className="modal fade show"
          id="applyModal"
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
          tabIndex="-1"
          aria-labelledby="applyModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered justify-content-center black-text">
            <div className="modal-content approvel-dialog border-0">
              <div className="modal-header border-0 p-32 pb-4">
                <h5
                  className="modal-title font-20 font-bold black-v2"
                  id="applyModalLabel"
                >
                  Apply
                </h5>
                <button
                  type="button"
                  className="btn shadow-none p-0"
                  onClick={(()=>{
                    setCommitMessage("")
                    handlePopupCancelClick()
                  })}
                >
                  <img
                    src="images/close-icon.svg"
                    alt="close-icon"
                    className="icon-hover shadow-none modal-close-filter"
                  />
                </button>
              </div>
              <div className="modal-body px-32 pt-0">
                <form>
                  <div className="mb-3">
                    <label
                      htmlFor="repositoryInput"
                      className="form-label font-14 font-medium black-v2"
                    >
                      Repository
                    </label>
                    <input
                      type="text"
                      className="form-control custom-form custom-dropdown font-14 font-medium py-3"
                      id="repositoryInput"
                      style={{ color: "black" }}
                      value={repoName}
                      disabled
                    />
                  </div>
                  <div className="my-4">
                    <label
                      htmlFor="branchSelect"
                      className="form-label font-14 font-medium black-v2"
                    >
                      Branch
                    </label>
                    <select
                      className="form-select custom-form custom-dropdown font-14 font-medium py-3"
                      id="branchSelect"
                      value={selectedBranch}
                      style={{ color: "black" }}
                      onChange={(e) => setSelectedBranch(e.target.value)}
                    >
                      <option value="">Select</option>
                      {branches.map((branch) => (
                        <option key={branch.branch_id} value={branch.branch_id}>
                          {branch.branch_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="my-4">
                    <label
                      htmlFor="commitMessageTextarea"
                      className="form-label font-14 font-medium black-v2"
                    >
                      Commit Message
                    </label>
                    <textarea
                      className="form-control custom-form custom-dropdown font-14 font-medium py-3"
                      id="commitMessageTextarea"
                      rows="3"
                      placeholder="Enter Description"
                      value={commitMessage}
                      style={{ color: "black" }}
                      onChange={(e) => setCommitMessage(e.target.value)}
                    ></textarea>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="button outline-button text-nowrap px-4"
                  onClick={()=>{
                    setCommitMessage("")
                    handlePopupCancelClick()}}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn button primary-button text-nowrap px-3"
                  onClick={()=>{
                    setCommitMessage("")
                    handlePopupApplyClick()}}
                  disabled={!selectedBranch || !commitMessage}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {popupMsg.show == true ?
      <WorkbechPopup 
        popupMsg ={popupMsg}
        setPopUpMsg ={setPopUpMsg}
      /> :<></>
      }
    </>
  );
};

export default CICDDeployment;


