import { useState } from "react";

export default function SamplePopUp(props) {

    const[comments,setComments]=useState("")

    const handleApprove = () => {  
        props.handleApproveRequest("Rejected",comments);  
       };

    

    return (
        <>
        <div
            className="modal fade"
            id="staticBackdrop-21"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog  modal-dialog-centered justify-content-center article-popup">
                <div className="modal-content border-0">
                    <div className="modal-header border-0 py-4 px-32">
                        <h1
                            className="modal-title font-20 font-bold black-v2"
                            id="staticBackdropLabel"
                        >
                            Rejection
                        </h1>
                        <button
                            type="button"
                            className="btn shadow-none pe-0"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            id="close"
                        >
                            <img
                                src="images/close-icon.svg"
                                alt="close-icon"
                                className="icon-hover shadow-none modal-close-filter"
                            />
                        </button>
                    </div>
                    <div className="modal-body p-32 pt-0">
                        <label
                            className="form-label font-14 font-medium black-v2 mb-3"
                            htmlFor="article-name"
                        >
                            Reason for Rejection <span className="danger-red">*</span>
                        </label>
                        <textarea
                            className="form-control custom-form article-grey-colorarea font-14 font-medium"
                            placeholder="Enter Reason for rejection"
                            id="architecture"
                            value={comments}
                            onChange={(e) => setComments(e.target.value)}
                        />
                        <div className="d-flex justify-content-end align-items-center mt-5 gap-3">
                            <button
                                type="button"
                                className="button outline-button text-nowrap"
                                data-bs-dismiss="modal"
                            >
                                Cancel
                            </button>
                            <button type="button" className="button primary-button text-nowrap" onClick={handleApprove} 
                           
                            //disabled={!}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        

     

        </>

        

        
    )
}