//PS_01
import React, { useState ,useContext,useEffect} from 'react';
//PS_02
import { useLocation, useNavigate } from 'react-router-dom';
import SideBar from '../sidebar/SideBar';
//PS_03
import Select from 'react-select';
//PS_04
import { FormContext } from './Workload_Grid';


const EnvironmentDetails = () => {

    const { formData, setFormData } = useContext(FormContext);
    console.log("sggggg",formData);

//PS_07
    const [environments, setEnvironments] = useState([]);
    const [nextButtonEnabled, setNextButtonEnabled] = useState(false);
   console.log("checkkkk",nextButtonEnabled);
    const [inputValue, setInputValue] = useState('');
    const [selectedOptions, setSelectedOptions] = useState([]);

    const navigate=useNavigate();
    debugger;
    const location =useLocation()
    console.log(formData , "formData")
    useEffect(() => {
        try {
       
        console.log(location , "Locatinosdfsdf" , formData)
        if (formData.environments) {
            // If workloadId exists, initialize selected options and environments
            setSelectedOptions(formData.environments.map(env => ({
                value: env.environment,
                label: env.environment,
                name: env.environment,
                identifier: env.uniqueTagIdentifier, // Assuming uniqueTagIdentifier is part of environments
                canDelete: env.canDelete // Include canDelete field
            })));
        }
    } catch (error) {
            console.log(error , "errror")  
    }
    }, [location]);
    
    useEffect(() => {
        // Check if workloadId exists in formData to enable/disable the Next button
        console.log("vaa1");
        console.log("vaa1",formData.environments);


        if (formData.workloadId || formData.environments.length) {
            console.log("vaa");
            setNextButtonEnabled(true); // Enable the button if workloadId exists
        } else {
            setNextButtonEnabled(false); // Disable the button if workloadId does not exist
        }
    }, [formData.workloadId]); 

    //PS_08 Handle adding new environment tags on Enter
    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && inputValue.trim() !== '') {
            const newTag = inputValue.trim();
            
            // Add the new tag to selected options if it doesn't already exist
            if (!selectedOptions.some(option => option.label === newTag)) {
                const uniqueIdentifier = generateUniqueTagIdentifier(newTag); // Generate unique identifier
                
                setSelectedOptions(prev => [
                    ...prev, 
                    { 
                        value: newTag, 
                        label: newTag, 
                        name: newTag, 
                        identifier: uniqueIdentifier,
                        canDelete: true // Assuming new tags can be deleted by default
                    }
                ]);
                
                setFormData((prev) => ({
                    ...prev,
                    environments: [
                        ...prev.environments, 
                        { environment: newTag, uniqueTagIdentifier: uniqueIdentifier, canDelete: false } // Include canDelete field
                    ]
                }));
            }
            
            setInputValue(''); // Clear input field after adding
            validateNextButton();
        }
    };
    console.log("soptions",selectedOptions);
    debugger;


    //PS_09 Handle selection change
    const handleChange = (selected) => {
        setSelectedOptions(selected);
        setEnvironments(selected.map(option => option.value)); // Update environments state
        
    };
    
    debugger;

   
  //PS_10
    const generateUniqueTagIdentifier = (environmentName) => {
        const uniqueNumber = Math.floor(Math.random() * 1000).toString().padStart(3, '0');
        // Replace these with actual organization and provider names as needed
 
        const organizationName =  formData.workloadName;
        const cloudProviderName =  formData.cloudProvider;
        return `${organizationName}-${cloudProviderName}-${environmentName}-${uniqueNumber}`;
    };
    debugger;
//PS_11
const handleDeleteEnvironment = (identifier) => {
    console.log("b4updateddd",selectedOptions);

    // Filter out the environment from selected options
    const updatedSelectedOptions = selectedOptions.filter(option => option.identifier !== identifier);
    
    // Filter out the environment from environments state
    const updatedEnvironments = environments.filter(env => env.uniqueTagIdentifier !== identifier);

    // Update states without modifying formData
    setSelectedOptions(updatedSelectedOptions);
    setEnvironments(updatedEnvironments);
    console.log("updateddd",updatedSelectedOptions);
    console.log("updateddd2",updatedEnvironments);


    // Update formData by filtering out the deleted environment
    setFormData(prev => ({
        ...prev,
        environments: prev.environments.filter(env => env.uniqueTagIdentifier !== identifier)
    }));

    validateNextButton(); // Check if Next button should be enabled
};

    console.log("optionsss",selectedOptions);
    debugger;

    //PS_12
    const validateNextButton = () => {
        setNextButtonEnabled(selectedOptions.length >= 0|| formData.environment.length>0); // Enable Next button if there are environments
    };
    debugger;

//PS_14
    const MultiValue = ({ data, removeProps }) => (
        <span className="blue-batch font-12 d-flex gap-1  align-items-center font-medium">
            {data.label}
            <a {...removeProps}>
                <img src="images/tags-close-icon.svg" alt="close-icon" className="icon-custom-filter" />
            </a>
        </span>
    );
    //PS_14 Custom components to limit displayed tags and show count
    const customStyles = {
        control: (base) => ({
            ...base,
            border: '1px solid #ccc',
                boxShadow: 'none',
                borderRadius: '4px',
                width:"1050px",
                minHeight: '50px',
                }),
        multiValue: (base) => ({
            ...base,
            display: 'flex',
            alignItems: 'center',
            marginRight: '5px',
        }),
        multiValueLabel: (base) => ({
            ...base,
            padding: '0',
        }),
        multiValueRemove: (base, { data }) => ({
            ...base,
            display: data.canDelete ? 'flex' : 'none', // Show or hide based on canDelete
        }),
        valueContainer: (base) => ({
            ...base,
            paddingRight: selectedOptions.length > 2 ? '30px' : '10px', // Adjust padding for count display
            position: 'relative',
        }),
    };
    debugger;

    //PS_14
    const customComponents = {
        MultiValue,
    };
    const handleCopyIdentifier = (identifier) => {
        navigator.clipboard.writeText(identifier).then(() => {
            // alert('Identifier copied to clipboard');
        }).catch((error) => {
            console.error('Could not copy identifier: ', error);
        });
    };
    
    const handleNextClick = () => {
        if (!nextButtonEnabled) return;

        // Set the selected options into formData
        setFormData((prevFormData) => ({
            ...prevFormData,
            environments: selectedOptions.map(option => ({
                environment: option.name,
                uniqueTagIdentifier: option.identifier,
                canDelete: option.canDelete,
            }))
        }));

        // Navigate after ensuring formData is updated
        navigate('/repositoryDetails');
    };
    return (
        <div className="container-fluid">
        <div className="">

            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                    <SideBar/>
        <div className="col-md-9 px-4 right-container-scroll1">
            
      
            <div className="tab-content" id="pills-tabContent">
           
                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
              
                    <div className="row px-3">
                    
                        <div className="col-md-12">
             
                        <p className="font-32 font-bold black-v2 mb-4 pb-3">{formData.workloadId?"Edit Workload":"Create Workload"}</p>
            <div className="row justify-content-center">
                                <div className="col-md-12 col-lg-8 mb-4">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex align-items-center flex-column gap-3">
                                            <div className="step-num completed d-flex justify-content-center align-items-center position-relative">
                                                <p className="font-medium font-16 m-0">1</p>
                                            </div>
                                            <span className="font-14 font-medium step-text">Workload Details</span>
                                        </div>

                                        <div className="d-flex align-items-center flex-column gap-3">
                                            <div className="step-num active d-flex justify-content-center align-items-center position-relative step-line-1 step-completed">
                                                <p className="font-medium font-16 m-0">2</p>
                                            </div>
                                            <span className="font-14 font-medium step-text active">Environments</span>
                                        </div>

                                        <div className="d-flex align-items-center flex-column gap-3">
                                            <div className="step-num completed d-flex justify-content-center align-items-center position-relative step-line-2 step-completed">
                                                <p className="font-medium font-16 m-0">3</p>
                                            </div>
                                            <span className="font-14 font-medium step-text">Repository & CI/CD</span>
                                        </div>

                                        <div className="d-flex align-items-center flex-column gap-3">
                                            <div className="step-num completed d-flex justify-content-center align-items-center position-relative step-line-3 step-completed">
                                                <p className="font-medium font-16 m-0">4</p>
                                            </div>
                                            <span className="font-14 font-medium step-text">Members</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
            <div className="col-md-12 mb-4 pb-2 add-organization-form">
                <label className="form-label font-14 font-medium black-v2" htmlFor="environments">Environment</label>
                <Select
                    isMulti
                   className='custom-select-form cust-select-width padding-left'

                    // styles={{ /* Custom styles if needed */ }}
                    // options={options}
                    onChange={handleChange}
                    styles={customStyles}
                    value={selectedOptions} // Set selected options here
                    placeholder="Type and press Enter to add"
                    noOptionsMessage={() => null}
                    onInputChange={(input) => setInputValue(input)} // Update input state
                    inputValue={inputValue} // Control the input value for react-select
                    onKeyDown={handleKeyDown} // Handle key down events for adding tags
                    components={customComponents}
                    />
            </div>
        </div>
                {/* Show table only if there are environments */}
                {selectedOptions.length > 0 && (
                    <>
                        {/* Table Start */}
                        <div className="col-md-12 mb-4 pb-3">
                            <div className="table-responsive mt-4 table-border">
                                <table className="table custom-table">
                                    <thead>
                                        <tr>
                                            <th className="text-start border-0">Environment</th>
                                            <th className="text-start border-0">Unique Tag Identifier</th>
                                            <th className="text-center border-0">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {selectedOptions.map(env => (
        <tr key={env.identifier}>
            <td className="text-start">{env.name}</td>
            <td className="text-start">
            <div className="d-flex align-items-center flex-nowrap gap-3 identifier-container">
                <span className="truncate-text">{env.identifier}</span>
                <button 
                    type="button" 
                    className="btn ms-2 p-0 lh-0 shadow-none"
                    onClick={() => handleCopyIdentifier(env.identifier)}
                >
                    <img src="images/copy-icon-grey.svg" alt="copy-icon-grey" />
                </button>
            </div>
        </td>            <td className="text-center">
                <button 
                    type="button" 
                    onClick={() => handleDeleteEnvironment(env.identifier)} 
                    className="btn p-0 lh-0 shadow-none"
                    disabled={!env.canDelete} // Disable button if canDelete is false
                >
                    <img src="images/bin-icon-red.svg" alt="bin-icon-red" />
                </button>
            </td>
        </tr>
    ))}
                                    </tbody>
                                </table>
                            </div>

                            {selectedOptions.length > 0 && (
                                <p className="font-14 font-medium secondary-text-color mb-0 mt-3">
                                    # of Records: 
                                    <span className="font16 font-medium color-black">
                                        {selectedOptions.length} out of {selectedOptions.length}
                                    </span>
                                </p>
                            )}
                        </div>
                        {/* Table End */}
                    </>
                )}
            </div>

            {/* Footer Buttons */}
         

           

        </div>
        </div>
        </div>
        <div className="d-flex justify-content-between">
                <button type="button" className="button cancel-button px-4 py-3 lh-1" onClick={()=>{navigate('/workloadsDetails')}}>Cancel</button>
                <button 
                type="button" 
                className={`btn button primary-button px-32 py-3 lh-1 ${!nextButtonEnabled ? 'disabled' : ''}`} 
                disabled={!nextButtonEnabled}
                onClick={handleNextClick}
            >
                Next
            </button>

            </div>
        </div>
           
        </div>
        </div>
        </div>
        </div>
        </div>
   
        
    );
};

export default EnvironmentDetails; // Ensure this line exports the component correctly