export default function AWS_SDK_DDB({ values, onChangeOfConfiguration, isViewer }) {
    try {

        return (
                <div
                    class="accordion-body p-0 estimation-container">
                    <div className="row py-4 mx-4 px-5">
                        <div className="col-md-6 px-3 mb-3">
                            <label htmlFor="region1" className="form-label font-14 font-medium black-v2 mb-3">
                                Region
                            </label>
                            <select
                            style={{paddingBottom: '10px' }}
                                className="form-select font-semibold font-14 form-select-sm custom-form"
                                id="region"
                                value={values.region}
                                name={"region"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            >
                                <option value="ap-south-2">ap-south-2</option><option value="ap-southeast-3">ap-southeast-3</option><option value="ap-southeast-1">ap-southeast-1</option><option value="us-gov-east-1">us-gov-east-1</option><option value="eu-west-2">eu-west-2</option><option value="af-south-1">af-south-1</option><option value="ap-northeast-1">ap-northeast-1</option><option value="ap-southeast-2">ap-southeast-2</option><option value="us-east-2">us-east-2</option><option value="eu-central-1">eu-central-1</option><option value="ca-central-1">ca-central-1</option><option value="ap-south-1">ap-south-1</option><option value="ap-northeast-3">ap-northeast-3</option><option value="sa-east-1">sa-east-1</option><option value="ap-east-1">ap-east-1</option><option value="eu-west-1">eu-west-1</option><option value="eu-south-1">eu-south-1</option><option value="ap-southeast-4">ap-southeast-4</option><option value="eu-north-1">eu-north-1</option><option value="eu-south-2">eu-south-2</option><option value="us-west-1">us-west-1</option><option value="us-west-2">us-west-2</option><option value="me-south-1">me-south-1</option><option value="eu-central-2">eu-central-2</option><option value="us-gov-west-1">us-gov-west-1</option><option value="ap-northeast-2">ap-northeast-2</option><option value="me-central-1">me-central-1</option><option value="us-east-1">us-east-1</option><option value="eu-west-3">eu-west-3</option>
                            </select>
                        </div>

                        <div className="col-md-6 px-3 mb-3">
                            <label htmlFor="reqType" className="form-label font-14 font-medium black-v2 mb-3">
                                Period
                            </label>
                            <select
                            style={{paddingBottom: '10px' }}
                                className="form-select font-semibold font-14 form-select-sm custom-form"
                                id="reqType"
                                value={values.reqType}
                                name={"reqType"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            >
                                <option value="1">per second</option>
                                <option value="2">per day</option>
                                <option value="3">per week</option>
                                <option value="4">per month</option>
                                <option value="5">million per month</option>
                                <option value="6">billion per month</option>
                                <option value="7">trillion per month</option>
                            </select>
                        </div>
                        <div className="col-md-6 px-3">
                            <label
                                htmlFor="request"
                                className="form-label font-14 font-medium black-v2 mb-3"
                            >
                                DynamoDB Streams
                            </label>
                            <input
                                type="text"
                                style={{paddingBottom: '10px'}}
                                className="form-control custom-form text-field font-14 font-medium"
                                id="request"
                                value={values.request}
                                name={"request"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            />
                        </div>

                    </div>

                </div>
        );
    } catch (error) {
        //console.log(error);
    }
}