//PS_02- Import statements
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import AddServicepopup from "./AddServicePopup"
import { useModuleContext } from "../../../ContextProvider/ModulesContxt"
import { getSelfServiceWithAI, importSelfService } from "../../../Service/api"
import Pagination from "../../CostManagement/Pagination"
import Loader from "../../loader/loader"
import { v4 as uuidv4 } from 'uuid';


function ImportServiceForm() {

    const {
        addServiceData,
        setAddServiceData,
        userId,
        providerName


    } = useModuleContext()


    //PS_03 - State variable declaration
    const location = useLocation()
    const importModuleId = location?.state?.importModuleId
    const importModuleName = location?.state?.importModuleName
    const selectedProvider = location?.state?.selectedProviderId
    const selectedProviderName = location?.state?.selectedProviderName
    const navigate = useNavigate()

    const [serviceData, setServiceData] = useState([]);
    const [selectedService, setSelectedService] = useState(null);
    const [successPopup, setSuccessPopup] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [editSelectedService, setEditSelectedService] = useState(null);

    const [searchTerm, setSearchTerm] = useState('');
    const [sortColumnName, setSortColumnName] = useState('');
    const [sortOrder, setSortOrder] = useState('ASC'); // ASC or DESC
    const [filteredConfigs, setFilteredConfigs] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const [showLoader, setShowLoader] = useState(false)
    const [currentCardPage, setCurrentCardPage] = useState(0);
    const [editIndex, setEditIndex] = useState(null);
    const [editId, setEditId] = useState(null);
    const [currentDate, setCurrentDate] = useState('');



    //PS_06- Initial triger to fetch service data
    useEffect(() => {
        if (importModuleId) {

            fetchServiceData();
        }
    }, [importModuleId]);

    console.log(selectedService, "selected service")

    //PS_04- Triggers when search,sorted
    useEffect(() => {

        if (selectedService) {
            const configs = Array.isArray(selectedService?.configuration) ? selectedService?.configuration : [];

            console.log(configs, "configss")

            const filtered = searchTerm
                ? configs.filter(config =>
                    config.input?.toLowerCase().includes(searchTerm.toLowerCase())
                )
                : configs;

            const sorted = [...filtered].sort((a, b) => {
                const valA = a[sortColumnName] ?? '';
                const valB = b[sortColumnName] ?? '';

                if (typeof valA === 'string' && typeof valB === 'string') {
                    return sortOrder === 'ASC'
                        ? valA.localeCompare(valB)
                        : valB.localeCompare(valA);
                }

                if (typeof valA === 'number' && typeof valB === 'number') {
                    return sortOrder === 'ASC'
                        ? valA - valB
                        : valB - valA;
                }

                return 0;
            });

            setFilteredConfigs(sorted);

        }
        else {
            setFilteredConfigs([]);
        }
    }, [searchTerm, sortColumnName, sortOrder, selectedService]);


    useEffect(() => {
        const formatDate = (date) => {
            const options = { year: 'numeric', month: 'short', day: 'numeric' };
            return date.toLocaleDateString('en-US', options);
        };

        const now = new Date();
        const formattedDate = formatDate(now);
        setCurrentDate(formattedDate);
    }, []);

    //PS_111-Handles search
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    //PS_112- Handles sort
    const handleSort = (columnName) => {
        setSortOrder(prevSortOrder => prevSortOrder === 'ASC' ? 'DESC' : 'ASC');
        setSortColumnName(columnName);
    };


    //PS_45-PS_54- Function to fetch the initial services data from AI
    const fetchServiceData = async (newServiceData) => {
        try {
            const payload =
            {
                userId: userId,
                providerId: selectedProvider,
                moduleId: importModuleId,
                providerName: selectedProviderName,
                addNewService: newServiceData ? newServiceData.serviceName : "Internet",
                addNew: newServiceData == undefined ? "false" : "true"

            };
            setShowLoader(true)
            console.log(serviceData, "before api servicedata")
            const response = await getSelfServiceWithAI(payload);
            console.log(serviceData, "after api servicedata")
            setShowLoader(false)
            console.log(response, "responseeee")

            if (response?.status == 200) {

                let addedService = response.data.data.map(serv => ({
                    ...serv,
                    id: uuidv4()  // Assign a unique identifier
                }));

                // If newServiceData is present, merge it into the addedService array
                if (newServiceData) {
                    const existingIndex = addedService.findIndex(serv => serv.serviceName === newServiceData.serviceName);
                    if (existingIndex !== -1) {
                        // If there's a match, update the existing service with newServiceData

                        console.log(newServiceData.file, "This is the file data of image")

                        try {
                            addedService[existingIndex] = {
                                ...addedService[existingIndex],
                                ...newServiceData,
                                id: addedService[existingIndex].id, // Preserve the existing id
                            };
                        } catch (error) {
                            console.log(error, "This is the error at the service creation.........")
                        }



                        console.log(addedService, "This is added service data...")

                    } else {
                        // If no match, add newServiceData to the array
                        addedService.push({
                            ...newServiceData,
                            id: uuidv4() // Assign a unique identifier
                        });
                    }
                }

                setServiceData(prevState =>
                    [...prevState, ...addedService]
                );
                setSelectedService(addedService[0]);
                console.log(addedService, "id service data")

            }

        } catch (error) {
            console.error(error);
            setShowLoader(false)
        }
    };



    //PS_58-PS_60- function for import functionality 
    const handleSaveServiceDetails = async () => {

        
        try {

            const updatedServices = serviceData.map(service => ({
                ...service,
                imageUpdate: true,
                imageUrl: "",
                containService: service.containService !== undefined ? service.containService : false, 
                description: service.description || ""
            }));
            console.log(updatedServices, "updatedServices")
            const payload = {
                user: 'user',
                providerId: '0a5816fa-5f85-4fd9-b716-62e4395c7b4a',
                moduleId: importModuleId,
                services: updatedServices,
            };
            setShowLoader(true)
            const response = await importSelfService(payload);
            setShowLoader(false)
            if (response.status === 200) {

                navigate('/ServiceConfigurationMain', {
                    state: {
                        activeTab: "Services" // Replace this with your actual state variable
                    }
                });


                setSuccessPopup(true);
            }
        } catch (error) {
            console.error(error);
            setShowLoader(false)

        }
    };


    //PS_66- Handles the delete services
    const handleDelete = (serviceId) => {

        setServiceData((prevServiceData) => {
            const updatedServiceData = prevServiceData.filter(service => service.id !== serviceId);
console.log("updatedServiceDataupdatedServiceData",updatedServiceData);

            if (updatedServiceData) {
                setSelectedService(null);
                setFilteredConfigs([]);
            }

            // Return updated service data
            return updatedServiceData;
        });
    };

    useEffect(() => {
        console.log('selectedService has changed:', selectedService); // Debug selectedService changes
    }, [selectedService]);


    useEffect(()=>{
        console.log("editSelectedService",editSelectedService);
        
    },[editSelectedService])
    //PS_133- Handles the binding of service cards
    const bindServiceCards = () => {
        if (!serviceData || serviceData.length === 0) {
            return <p className="color-black" style={{ paddingLeft: '43%' }}>No services available</p>;
        }

        const startIndex = currentCardPage * servicesPerPage;
        const selectedServices = serviceData?.slice(startIndex, startIndex + servicesPerPage);

        return selectedServices.map((service, index) => (
            <div
                key={service.id}
                className={`p-3 position-relative service-configurations-grid service-card overflow ${selectedService?.id == service.id ? "active" : ""}`}
                onClick={() => handleCardClick(service)}
            >
                <div className="d-flex align-items-center gap-3">
                    <img
                        className="partner-logo-circle grid-blur"
                        src={service.file || service.serviceIcon}
                        alt="partner-logo"
                        width='30px'
                        height='30px'
                    />
                    <p className="font-18 font-bold black-v2 mb-0 partner-name grid-blur"
                        title={service.serviceName}>
                        {service.serviceName.length > 10 ? `${service.serviceName?.slice(0, 10)}...` : service.serviceName}
                    </p>
                </div>
                <p className="font-12 font-medium secondary-text-color-v1 grid-blur mb-0 mt-3">
                    Created : <span>{currentDate}</span>
                </p>
                <div className="grid-options">
                    <a
                        href="#"
                        className="color-black font-13 font-medium text-decoration-none d-block mb-2"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop-22"

                        onClick={(e) => {
                            e.preventDefault();
                            setEditMode(true);
                            setEditSelectedService(service);
                            setEditId(service.id);
                        }}
                    >
                        <img
                            src="images/articles-edit-icon-blue.svg"
                            alt="edit-icon"
                            className="articles-option-icons me-2"
                        />
                        Edit
                    </a>
                    <a
                        href="#"
                        className="color-black font-13 font-medium text-decoration-none d-block mb-2"
                        onClick={(e) => {
                            e.preventDefault();
                            setSelectedService(service)
                            handleDelete(service.id);
                        }}
                    >
                        <img
                            src="images/articles-delete-icon.svg"
                            alt="delete-icon"
                            className="articles-option-icons me-2"
                        />
                        Delete
                    </a>
                </div>
            </div>
        ));
    };

    //PS_113- Handles  service card click
    const handleCardClick = (service) => {
        console.log("card service", service)
        setSelectedService(service);
        setCurrentPage(1)
    };

    //PS_115-PS_119- Function for rendering the configuration grid
    const renderTableRows = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;

        // Sort the configurations based on the status
        console.log(filteredConfigs, "Fileter Configgsss")
        const sortedConfigs = [...filteredConfigs].sort((a, b) => {
            if (a.status === b.status) return 0;
            return a.status == true ? -1 : 1;
        });

        const currentConfigurations = sortedConfigs?.slice(startIndex, endIndex);
        console.log(currentConfigurations , "currentConfigurations")
        if (currentConfigurations.length === 0) {
            return (
                <tr>
                    <td colSpan="6" className="text-center">
                        No configurations available
                    </td>
                </tr>
            );
        }

        return currentConfigurations.map((config, index) => (
            <tr key={config.input} className={config.status == true ? "row-selected-one" : ""}>
                <td>{startIndex + index + 1}</td>
                <td>{config.input}</td>
                <td>{config.type}</td>
                <td>
    {typeof config?.default === 'boolean' ? String(config.default) :
        Array.isArray(config?.default) ? (
            <span title={JSON.stringify(config.default)}>
                {JSON.stringify(config.default).length > 50 ?
                    `${JSON.stringify(config.default)?.slice(0, 50)}...` :
                    JSON.stringify(config.default)}
            </span>
        ) :
        typeof config?.default === 'object' && config?.default !== null ? (
            <span title={JSON.stringify(config.default)}>
                {JSON.stringify(config.default).length > 50 ?
                    `${JSON.stringify(config.default)?.slice(0, 50)}...` :
                    JSON.stringify(config.default)}
            </span>
        ) :
        (
            config?.default ? ( // Check if config.default exists and is not empty
                <span title={config.default}>
                    {String(config.default).length > 50 ? `${String(config.default)?.slice(0, 50)}...` : String(config.default)}
                </span>
            ) : (
                '" "' // Display this when config.default is empty, null or undefined
            )
        )}
</td>



               
                <td title={config.description}>
                    {config.description.length > 50
                        ? `${config.description?.slice(0, 50)}...`
                        : config.description}
                </td>
                <td className="text-nowrap text-center">
                    <div className="form-check form-switch d-flex justify-content-center row-selected">
                        <input
                            className="form-check-input custom-switcher"
                            type="checkbox"
                            role="switch"
                            checked={config.status == true}
                            onChange={(e) => handleCheckboxChange(config.input, e.target.checked)}
                        />
                    </div>
                </td>
            </tr>
        ));
    };

    //    //PS_120-124- Handles the toggle change in the grid Handles the toggle change in the grid
    const handleCheckboxChange = (input, checked) => {
        const updatedService = {
            ...selectedService,
            configuration: selectedService.configuration.map(config =>
                config.input === input ? { ...config, status: checked ? true : false } : config
            )
        };

        // Update the state with the new configuration
        setSelectedService(updatedService);
        updateServiceData(updatedService);

        // Sort the filtered configurations based on the new status
        const updatedFilteredConfigs = filteredConfigs.map(config =>
            config.input === input ? { ...config, status: checked ? true : false } : config
        ).sort((a, b) => {
            if (a.status === b.status) return 0;
            return a.status == true ? -1 : 1;
        });

        setFilteredConfigs(updatedFilteredConfigs); // Assuming you have a  state setter for filteredConfigs
    };

    //PS_125- Updates the services data when toggle changes
    const updateServiceData = (updatedService) => {
        console.log("updatedService3",updatedService);
        console.log("updatedService4",serviceData);


        let localServiceData = [...serviceData]; // Create a copy of the previous data

localServiceData = localServiceData.map(service =>
    service.id === updatedService.id ? { ...updatedService } : service
);

setServiceData(localServiceData);
    };

    const servicesPerPage = 5;

    //PS_128- Funtion to handle the cards next page
    const handleNextPage = () => {
        if ((currentCardPage + 1) * servicesPerPage < serviceData.length) {
            setCurrentCardPage(currentCardPage + 1);
        }
    };

    //PS_129 - - Funtion to handle the cards previous page
    const handlePrevPage = () => {
        if (currentCardPage > 0) {
            setCurrentCardPage(currentCardPage - 1);
        }
    };

    //PS_130- Handles the service cards edit 
    const editSubmit = (updatedServiceData) => {
        debugger;
        console.log(updatedServiceData, "updatedServiceData");
    
        setServiceData(prevState => {
            let selectedUpdatedService = null;
    
            const updatedServiceDataArray = prevState?.map(service => {
                if (service.id === editId) {
                    const updatedService = {
                        ...service,
                        ...updatedServiceData,
                        serviceIcon: updatedServiceData.file,
                        fileName: updatedServiceData.fileName
                    };
                    selectedUpdatedService = updatedService; // Capture the updated service
                    return updatedService;
                } else {
                    return service;
                }
            });
    
            //If an item was found, then the value is set here.
            if (selectedUpdatedService) {
                setSelectedService(selectedUpdatedService)
            }
            return updatedServiceDataArray
        });
    };
    

    console.log(addServiceData, "addServiceData")
    console.log(serviceData, "serviceData")



    return (
        <>
            <>
                {showLoader ?
                    <Loader /> :
                    <div className="container-fluid custom-container">
                        {/*  title start here*/}
                        <div className="d-flex align-items-center py-3 pt-4 mt-2">
                            <a onClick={() => {
                                navigate('/ServiceConfigurationMain', {
                                    state: {
                                        activeTab: "Services" // Replace this with your actual state variable
                                    }
                                });
                            }}>
                                <img
                                    src="images/back-arrow.svg"
                                    alt="back-arrow"
                                    title="Back"
                                    className="me-3 icon-filter"
                                />
                            </a>
                            <h2 className="font-20 font-bold black-v2 mb-0">Import New Service</h2>
                        </div>
                        {/*  title end here*/}
                        {/* Page Content Start here */}
                        <div className="row">
                            <div className="col-md-12 mt-4 ps-5">
                                <div className="d-flex align-items-end">
                                    <div className="col-md-6 col-lg-3 px-3">
                                        <label
                                            className="form-label font-14 font-medium black-v2"
                                            htmlFor="modules"
                                        >
                                            {" "}
                                            Modules<span className="required">*</span>
                                        </label>
                                        <div className="dropdown">
                                            <button
                                                type="button"
                                                className="btn cust-dropdown d-flex justify-content-between align-items-center shadow-none gap-3 cust-select-btn w-100 font-14 font-regular"
                                                id="modules"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                                disabled
                                                style={{ backgroundColor: "lightgrey" }}
                                            >
                                                {importModuleName}
                                                <img src="images/down-arrow-grey.svg" alt="down-arrow-grey" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 mt-5">
                                <h2 className="font-18 font-bold black-v2 mb-0">Services</h2>
                                <div
                                    id="carouselExampleControls"
                                    className="carousel slide service-carousel mt-4"
                                    data-bs-ride=""
                                >
                                    <div className="carousel-inner">
                                        {/* first carousel */}
                                        <div className="carousel-item active">
                                            <div className="d-flex gap-3 px-4 py-2">
                                                {bindServiceCards()}
                                            </div>
                                        </div>
                                        {/* first carousel */}

                                        <button
                                            className="carousel-control-prev custom-arrow d-flex justify-content-start "
                                            style={{ width: '2%' }}
                                            type="button"
                                            // data-bs-target="#carouselExampleControls"
                                            // data-bs-slide="prev"
                                            onClick={handlePrevPage}
                                            disabled={currentCardPage === 0}
                                        >
                                            <span
                                                className="carousel-control-prev-icon custom-prev icon-filter"
                                                aria-hidden="true"
                                            />
                                            <span className="visually-hidden">Previous</span>
                                        </button>
                                        <button
                                            className="carousel-control-next custom-arrow d-flex justify-content-end"
                                            style={{ width: '2%' }}
                                            type="button"
                                            // data-bs-target="#carouselExampleControls"
                                            // data-bs-slide="next"
                                            onClick={handleNextPage}
                                            disabled={(currentCardPage + 1) * servicesPerPage >= serviceData.length}
                                        >
                                            <span
                                                className="carousel-control-next-icon custom-next icon-filter"
                                                aria-hidden="true"
                                            />
                                            <span className="visually-hidden">Next</span>
                                        </button>
                                    </div>
                                    {/* carousel end */}
                                </div>
                            </div>
                            <div className="d-xl-flex justify-content-between align-items-center service-configurations-title-bar py-3 pt-4 mt-2">
                                <div className="d-flex align-items-center mb-3 mb-md-0 ">
                                    <h2 className="font-20 font-bold black-v2 mb-0">
                                        Service Configurations
                                    </h2>
                                </div>
                                <div className="d-flex justify-content-center flex-nowrap gap-3">
                                    {/* search */}
                                    <div className="input-group input-search custom-border-box custom-search-box">
                                        <input
                                            type="search"
                                            className="px-3 form-control common-bg border-0 custom-search gray-v1"
                                            placeholder="Search"
                                            value={searchTerm}
                                            onChange={handleSearchChange}
                                        />
                                        <button className="btn border-start-0 custom-search " type="button">
                                            <img src="images/search-icon.svg" alt="search" />
                                        </button>
                                    </div>
                                    {/* search */}
                                    <button type="button" className="button primary-button text-nowrap"
                                        data-bs-toggle="modal"
                                        data-bs-target="#staticBackdrop-22"
                                    >
                                        <img src="images/add-icon.svg" alt="add-icon" className="me-2" />
                                        Add Service
                                    </button>
                                    {/* {editMode && <AddServicepopup serviceToEdit={selectedService} setEditMode={setEditMode} />} */}
                                    <AddServicepopup
                                        serviceToEdit={editSelectedService}
                                        setEditSelectedService={setEditSelectedService}
                                        setEditMode={setEditMode}
                                        editMode={editMode}
                                        saveButton={fetchServiceData}
                                        updateButton={editSubmit}
                                        handleClose={() => setEditSelectedService(null)}
                                    />
                                </div>
                            </div>

                            {selectedService ? (
                                <div className="col-md-12 mb-4 pb-2">
                                    {/* table start */}
                                    <div className="table-responsive table-border">
                                        <table className="table custom-table">
                                            <thead>
                                                <tr>
                                                    <th className="text-start border-0">S.No</th>
                                                    <th className="text-start border-0" onClick={() => handleSort('input')}>
                                                        Inputs
                                                        <span className="cursor-pointer ms-2">
                                                            <img src="images/table-sort.svg" alt="sort-icon"
                                                                style={{
                                                                    transform: sortColumnName === "input" && sortOrder === "ASC" ? "scaleY(-1)" : "scaleY(1)",
                                                                    transition: "transform 0.2s ease-in-out"
                                                                }}
                                                            />
                                                        </span>
                                                    </th>
                                                    <th className="text-start border-0" onClick={() => handleSort('type')}>
                                                        Type
                                                        <span className="cursor-pointer ms-2">
                                                            <img src="images/table-sort.svg" alt="sort-icon"
                                                                style={{
                                                                    transform: sortColumnName === "type" && sortOrder === "ASC" ? "scaleY(-1)" : "scaleY(1)",
                                                                    transition: "transform 0.2s ease-in-out"
                                                                }}
                                                            />
                                                        </span>
                                                    </th>
                                                    <th className="text-start border-0">Default</th>
                                                    <th className="text-start border-0">Description</th>
                                                    <th className="text-center border-0">Enable</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {renderTableRows()}
                                            </tbody>

                                        </table>
                                    </div>
                                    {/* table end */}
                                    <Pagination
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        totalItems={filteredConfigs.length}
                                        itemsPerPage={itemsPerPage}
                                        displayedCount={filteredConfigs?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).length}
                                    />
                                </div>
                            ) :
                                (
                                    <tr>
                                           <td colSpan={8} className="text-center"> {/* Adjust colSpan based on the number of columns */}
                                                            <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: '160px' ,paddingLeft:'700px',paddingTop:'180px'}}>
                                                                <img src="images/nodata-found.svg" alt="No Data Found" />
                                                                <h6 className="color-black font-bold font-16 mt-5 mb-0">No Data Found!</h6>

                                                            </div>
                                                        </td>
                                    </tr>
                                )
                            }

                            <div className="col-md-12 mb-4">
                                <div className="d-flex justify-content-between" style={{ paddingTop: selectedService == null ? '25%' : '0' }}>
                                    <button type="button" className="button cancel-button px-4 py-3 lh-1"
                                        onClick={(() => {
                                            navigate('/ServiceConfigurationMain', {
                                                state: {
                                                    activeTab: "Services" // Replace this with your actual state variable
                                                }
                                            });
                                        })}
                                    >
                                        Cancel
                                    </button>
                                    <button type="button" className="btn button primary-button py-3 lh-1" onClick={handleSaveServiceDetails}
                                        disabled={serviceData.length == 0}
                                    >
                                        Save Details
                                    </button>
                                </div>
                            </div>

                        </div>


                        {/* Page Content End here */}
                    </div>
                }
            </>

        </>
    )
}

export default ImportServiceForm

