
import axios from 'axios';

export default async function Client(endpoint, payload,method, options) {

	// let jwtToken = localStorage.getItem('jwt'); // Retrieve the JWT from local storage
	let jwtToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiYWRtaW4iOnRydWUsImV4cCI6MTczNDU4MzQyN30.RHvgsmOWtccAkIGzhhWm5m_c928-7M3oeZNI--32Cgg"

		// Perform Conditional Check Over here
	if(!jwtToken){
		// Navigate to Login Page 
	}

		// Retreive the User id from the Local storage and Add Dynamicallly in hte Payload
	if(payload){
		// retrerive from the local stroage and add Dynamically here
			let userId =localStorage.getItem('userId')

			// let userId ="a2e1730f-7c47-417f-af9c-353990c32108"
			payload["userId"] = userId
	}
	
	const config = {
		method: method,
		url: endpoint,
		headers: {
			'Content-Type': 'application/json','Access-Control-Allow-Origin': "*","Access-Control-Allow-Header": "*",
		},
	};;
	console.log(config);
	// Include the JWT in the headers if available
	if (jwtToken) {
		config.headers['Authorization'] = `Bearer ${jwtToken}`;
	}

	// Include the payload for POST or PUT requests
	if (method === 'POST' || method === 'PUT') {
		config.data = payload; // Axios uses 'data' for request body
	}

	try {
        console.log(config , "Client -- before" , config.url)
		const response = await axios(config);
        console.log(response , "Client -- after")

		return response; // Return the data from the response
	} catch (error) {
		console.error('Error in Client function:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}
