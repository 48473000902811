import { createContext, useContext, useEffect, useState } from "react";
import OrganizationSidePannel from "./OrganizationSidePannel"
import moment from "moment";
import { fetchApprovalData } from "../../Service/api";
import { fetchAllCloudProvider } from "../../Service/api";
import { useNavigate } from "react-router-dom";
import { fetchAllEnvironment, fetchAllWorkload } from "../../Service/api";
import { OrgContext, useOrgContext } from "../../ContextProvider/OrgContext";
import SideBar from "../sidebar/SideBar";
import Loader from "../loader/loader";



function ApprovalGrid() {

    //PS_04
    const [approvalData, setApprovalData] = useState([]);
    const {setapprovalId } = useOrgContext()
    const [totalCount, setTotalCount] = useState(0);
    const [loadCount, setLoadCount] = useState(10)
    const [error, setError] = useState(null);
    const [search, setSearch] = useState("");
    const [sortOrder, setSortOrder] = useState("ASC");
    const [sortColumnName, setSortColumnName] = useState("status");
    let filterObj = {
        statusBy: '',
        workloadId: '',
        environmentId: ''

    }
    const [filterData, setFilterData] = useState(filterObj)
    const [noRecordsMsg, setNoRecordsMsg] = useState("No Records Found");
    const [selectedProvider, setSelectedProvider] = useState('AWS');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [cloudProviderData, setCloudProviderData] = useState([])
    const navigate = useNavigate()
    const [workloadData, setWorkloadData] = useState([])

    const [environmentData, setEnvironmentData] = useState([])
    const itemsPerPage = 10;

    const userId = localStorage.getItem('userId');
    console.log("userid", userId)

    const { orgId, pendingCount, setPendingCount } = useContext(OrgContext)
    const [loading, setLoading] = useState(true);

    console.log(orgId, "organization")

    const selectedProviderName = cloudProviderData.find(provider => provider.providerId === selectedProvider)?.providerName;
    const {
        envId,
        setEnvId,
        workloadId,
        providerId,
        providerName,
        envName,
        PusleIdentificaiton,
        workloadRole,
        UTI,
        colorMode,
        userRoles, setworkloadRole
    } = useOrgContext();

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    //PS_06 
    // Triggering useEffect to call getApprovalData
    //PS_10
    useEffect(() => {

        getApprovalData();
        getWorkLoadData()

    }, [sortOrder, sortColumnName, currentPage, selectedProvider]);

    useEffect(() => {
        getCloudProviderData()
    }, [])




    //PS_10 - PS_25
    // Fetch approval data based on current state
    const getApprovalData = async (filterDatas = filterData, searchData = search) => {
        const payload = {
            organizationId: orgId,
            userId: userId,
            providerId: selectedProvider,
            filter: filterDatas,
            search: searchData,
            pagination: currentPage,
            sort: {
                columnName: sortColumnName,
                order: sortOrder
            }

        };
        console.log(payload, "Paylaodd")
        try {
            //setLoading(true)
            const response = await fetchApprovalData(payload);
            // setLoading(false)
            console.log(response, "resp");

            if (response.status === 200) {
                const { body } = response.data;
                const { data, message, success } = body;

                if (data && data.grid) {
                    setApprovalData(data.grid);
                    setTotalCount(data.totalCount);
                    const { totalpage } = data; // Extract totalpage

                    // Set totalPages in state
                    setTotalPages(totalpage); // Assuming you have a state variable named totalPages

                    console.log(totalpage, "Total Pages");

                    console.log(data.grid, "app data")
                    console.log(totalCount, "totalCOunt")


                    // if (payload.search.trim() !== "") {
                    //     setFilterData(prev => ({ ...prev, noRecordsMsg: "No Search Record Found" }));
                    // }
                } else {
                    setApprovalData([]);
                    setTotalCount(0);
                    setTotalPages(1);
                }
            } else {
                throw new Error('Error fetching approval data');
            }
        } catch (error) {
            const errorData = {
                errorName: error.name,
                errorServiceName: "Front-end",
                errorFunctionName: "getApprovalData",
                errorSource: "frontend",
                errorDescription: "Could not fetch data " + error.message
            };
            setError(errorData);
        } finally {
            setLoading(false);
        }
    };


    //PS_81
    // Calculate the pending count
    useEffect(() => {
        const newPendingCount = calculatePendingCount(approvalData);
        setPendingCount(newPendingCount);
    }, [approvalData]);

    //PS_82
    const calculatePendingCount = (data) => {
        return data.filter(item => item.status === 'Pending').length;
    };

    //PS_26 - PS_32
    const renderApprovalData = () => {
        if (approvalData.length === 0) {
            return (
                <tr>
                    <td colSpan="7" style={{ textAlign: 'center', padding: '20px' }}>
                        No templates found matching your search.
                    </td>
                </tr>
            );
        }

        return approvalData.map((item, index) => (
            <tr key={index}>
                <td>{item.workspaceName}</td>
                <td>{item.environment || "N/A"}</td>
                <td>{item.requestType || "N/A"}</td>
                <td>{item.timeStamp || "N/A"}</td>
                <td>{item.requestedBy || "N/A"}</td>
                <td>{item.ApprovedBy || "N/A"}</td>
                <td>
                    <span className={`status me-2 ${item.status === 'Pending' ? 'pending' : item.status === 'Approved' ? 'active' : item.status === 'Rejected' ? 'inactive' : ''}`}></span>
                    <span>{item.status || "N/A"}</span>
                </td>
                {/* <td>{item.status || "N/A"}</td> */}
                <td>
                    <button type="button" class="button secondary-outline-button py-1"
                        onClick={() => handleViewClick(item)}
                        // disabled={item.status === 'Approved'}

                    >View</button>

                </td>
            </tr>

        ));
    };

    //PS_98-PS_99
    const getWorkLoadData = async () => {
        try {

            const payload = {
                "userId": userId,
                "orgId": orgId,
                "type": "clone",
                "connectorId": "",
                "orgIdGeneral": []
            }

            const response = await fetchAllWorkload(payload); // Replace with your actual API call function
            console.log(response, "response workload");

            if (response.status === 200) {
                setWorkloadData(response.data.data.workload); // Store the response in a state variable
            } else {
                console.error("Error fetching cloud provider data:", response.data.body.message);
            }
        } catch (error) {
            console.error("API Error:", {
                errorName: error.name,
                errorServiceName: "Front-end",
                errorFunctionName: "getCloudProviderData",
                errorSource: "frontend",
                errorDescription: "fetch failed: " + error.message
            });
        }
    };

    //PS_100-PS_101
    const getEnvironmentData = async (workloadId) => {
        try {
            const payload = {
                "userId": userId,
                "orgId": orgId,
                "workloadId": workloadId,
                "type": "budget"
            }

            const response = await fetchAllEnvironment(payload); // Replace with your actual API call function
            console.log(response, "response workload");

            if (response.status === 200) {
                setEnvironmentData(response.data.data); // Store the response in a state variable
            } else {
                console.error("Error fetching cloud provider data:", response.data.body.message);
            }
        } catch (error) {
            console.error("API Error:", {
                errorName: error.name,
                errorServiceName: "Front-end",
                errorFunctionName: "getCloudProviderData",
                errorSource: "frontend",
                errorDescription: "fetch failed: " + error.message
            });
        }
    };


    //PS_67
    const filterValidation = (e) => {
        const { id, value } = e.target;
        console.log(id, value)
        setFilterData(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    //PS_90
    const handleApply = async () => {

        await getApprovalData(filterData, search);
        //setInsightData("");
        console.log('Filters applied:', filterData);
    };

    //PS_91
    const handleClear = async () => {
        setFilterData({
            workloadId: "",
            environmentId: "",
            statusBy: ""
        });
        console.log(filterData, "hgjkl")
        // setEnvironmentData([])
        // setWorkloadData([])

        setSearch('')

        await getApprovalData(filterObj, search)
    };

    //PS_92
    const getCurrentDate = () => {
        const today = new Date();
        return today.toISOString().split('T')[0]; // Format YYYY-MM-DD
    };

    // PS_93
    const searchRecord = (e) => {
        const newValue = e.target.value;
        if (isValidInput(newValue)) {
            setSearch(newValue);
            getApprovalData(filterData, newValue);
            // setNoRecordsMsg(approvalData.length === 0 ? "No Search Record Found" : "");
        }
    };

    //PS_94
    const isValidInput = (input) => {
        const regex = /^[a-zA-Z0-9_-]*$/;
        return regex.test(input);
    };

    //PS_95 -PS_97
    const getCloudProviderData = async () => {
        try {
            const payload = {
                "userId": userId,
                "orgId": "", // Assuming orgId is intentionally left empty
                "type": "general"
            };

            const response = await fetchAllCloudProvider(payload); // Replace with your actual API call function
            console.log(response, "response");

            if (response.status === 200) {
                setCloudProviderData(response.data.data); // Store the response in a state variable

                for (let values of response.data.data) {

                    if (values?.providerName == 'AWS') {
                        console.log("Inside the data of the self service")
                        setSelectedProvider(values?.providerId)
                    }

                }


            } else {
                console.error("Error fetching cloud provider data:", response.data.body.message);
            }
        } catch (error) {
            console.error("API Error:", {
                errorName: error.name,
                errorServiceName: "Front-end",
                errorFunctionName: "getCloudProviderData",
                errorSource: "frontend",
                errorDescription: "fetch failed: " + error.message
            });
        }
    };

    const toggleSortOrder = (columnName) => {
        // Toggle between ASC and DESC
        const newSortOrder = sortOrder === "ASC" ? "DESC" : "ASC";
        setSortOrder(newSortOrder);
        setSortColumnName(columnName); // Set the column name dynamically
    };

    const handleViewClick = (item) => {
        console.log(item, "Items Here")
        switch (item.requestType) {
            case 'Estimation':
                console.log(item.environmentId, "item.environmentId")
                setEnvId(item.environmentId)
                setworkloadRole("admin")
                setapprovalId(item.approvalId)
                localStorage.setItem('approvalId', item.approvalId)
                navigate('/Workbench', { state: { 
                    environmentId: item.environmentId,
                    approvalStatus: item.status,
                     approvalId: item.approvalId,
                      isThreshholdSet: item.isThresholdSet  
                    } });
                break;
            case 'Deploy':
                navigate('/ServiceReview', { state: 
                    { environmentId: item.environmentId,
                      approvalId: item.approvalId, 
                      language: item.language } });
                break;

        }
    };

    if (loading) {
        return <Loader />; // Show loader while loading
    }

    return (
        <>

            <div className="container-fluid ps-0">
                <div className="row">
                    {/* sidebar-menu start */}
                    <SideBar pendingCount={pendingCount} />
                    {/*sidebar-menu end  */}
                    <div className="col-md-9 px-4 mt-4">
                        <div className="tab-content" id="pills-tabContent">
                            <div
                                className="tab-pane fade"
                                id="pills-home"
                                role="tabpanel"
                                aria-labelledby="pills-home-tab"
                            >
                                Dashboard
                            </div>
                            {/* Workload start */}
                            <div
                                className="tab-pane fade show active"
                                id="pills-Workloads"
                                role="tabpanel"
                                aria-labelledby="pills-Workloads-tab"
                            >
                                {/* table-container heading start */}
                                <div className="d-xl-flex justify-content-between align-items-center pt-32">
                                    <h2 className="font-32 font-bold black-v2 mb-2 mt-xl-0 d-flex align-items-center">
                                        Approvals
                                        <span className="notify-content header-notification ms-2 font-14 d-flex align-items-center justify-content-center">
                                            {pendingCount}
                                        </span>
                                    </h2>
                                    <div className="d-flex justify-content-center flex-nowrap gap-3">
                                        {/* dropdown */}


                                        <div className="dropdown">
                                            <button
                                                type="button"
                                                className="btn custom-dropdown d-flex justify-content-between align-items-center gap-3 font-12 font-regular color-black shadow-none"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                <span className="d-flex">
                                                    {selectedProviderName === "AWS" ? (
                                                        <>
                                                            <img
                                                                src="images/aws-black-icon.svg"
                                                                alt="aws-img"
                                                                className="me-1 icon-width light-theme-img"
                                                            />
                                                            <img
                                                                src="images/aws-logo-dark.svg"
                                                                alt="aws-img"
                                                                className="me-1 icon-width dark-theme-img"
                                                            />
                                                        </>
                                                    ) : (
                                                        <img
                                                            src="images/azure-logo.svg"
                                                            alt="azure-img"
                                                            className="me-1 icon-width"
                                                        />
                                                    )}
                                                    {selectedProviderName}
                                                </span>
                                                <img src="images/drop-down-arrow.svg" alt="dropdown-arrow" />
                                            </button>
                                            <ul
                                                className="dropdown-menu body-bg access-dropdown w-100"
                                                aria-labelledby="dropdownMenuButton1"
                                            >
                                                {cloudProviderData.map((provider) => (
                                                    <li
                                                        key={provider.providerId}
                                                        className="font-regular color-black font-12 p-2 custom-list"
                                                        onClick={() => setSelectedProvider(provider.providerId)}
                                                    >
                                                        <span className="d-flex align-items-center">
                                                            {provider.providerName === "AWS" ? (
                                                                <>
                                                                    <img
                                                                        src="images/aws-black-icon.svg"
                                                                        alt="aws-img"
                                                                        className="me-1 icon-width light-theme-img"
                                                                    />
                                                                    <img
                                                                        src="images/aws-logo-dark.svg"
                                                                        alt="aws-img"
                                                                        className="me-1 icon-width dark-theme-img"
                                                                    />
                                                                </>
                                                            ) : (
                                                                <img
                                                                    src="images/azure-logo.svg"
                                                                    alt="azure-logo"
                                                                    className="me-1 icon-width"
                                                                />
                                                            )}
                                                            {provider.providerName}
                                                        </span>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        {/* dropdown */}
                                        {/* filter start */}

                                        <button type="button" class="filter-button px-2 position-relative" data-bs-toggle="dropdown"><img src="images/filter-blue.svg" alt="filter-icon" /> </button>

                                        {/*filter*/}
                                        <div
                                            className="dropdown-menu dropdown-menu-end border-0 custom-shadow dd-position p-4 background-white custom-filter-width"
                                            aria-labelledby="dropdownMenuLink"
                                        >
                                            <h5 className="font-medium font-16 mb-0 color-black mb-4">
                                                Filter
                                            </h5>
                                            <div className="row">
                                                <div className="col-md-12 mb-2">

                                                </div>
                                                <div className="col-md-6 mb-4 px-2">
                                                    <label
                                                        className="form-label font-14 font-medium black-v2"
                                                        htmlFor="Status-4"
                                                    >
                                                        WorkLoad
                                                    </label>
                                                    <select
                                                        className="form-select custom-dropdown shadow-none custom-border-box font-14 font-regular black-v2 custom-select"
                                                        aria-label="Default select example"
                                                        id="workloadId"
                                                        onChange={
                                                            ((e) => {
                                                                filterValidation(e)
                                                                getEnvironmentData(e.target.value)

                                                            })

                                                        }
                                                        value={filterData.workloadId}
                                                    >
                                                        <option selected="">Select</option>
                                                        {workloadData.map(workload => (
                                                            <option key={workload.workloadId} value={workload.workloadId}>{workload.workloadName}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col-md-6 mb-4 px-2">
                                                    <label
                                                        className="form-label font-14 font-medium black-v2"
                                                        htmlFor="environmentId"
                                                    >
                                                        Environment
                                                    </label>
                                                    <select
                                                        className="form-select custom-dropdown shadow-none custom-border-box font-14 font-regular black-v2 custom-select"
                                                        aria-label="Default select example"
                                                        id="environmentId"
                                                        onChange={filterValidation}
                                                        value={filterData.environmentId}
                                                    >
                                                        <option selected="">Select</option>
                                                        {environmentData.map(environment => (
                                                            <option key={environment.envId} value={environment.envId}>{environment.envName}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col-md-6 mb-4 px-2">
                                                    <label
                                                        className="form-label font-14 font-medium black-v2 mb-1"
                                                        htmlFor="statusBy"
                                                    >
                                                        Status
                                                    </label>
                                                    <select
                                                        className="form-select custom-dropdown shadow-none form-field-border py-1 font-14 font-regular black-v2 custom-select"
                                                        aria-label="Default select example"
                                                        id="statusBy"
                                                        onChange={filterValidation}
                                                        value={filterData.statusBy}
                                                    >
                                                        <option selected="">Select</option>
                                                        <option value="Pending">Pending</option>
                                                        <option value="Approved">Approved</option>
                                                        <option value="Rejected">Rejected</option>

                                                    </select>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="d-flex justify-content-end align-items-center gap-3">
                                                        <button className="button outline-button px-4"
                                                            onClick={handleClear}>
                                                            Clear All
                                                        </button>
                                                        <button

                                                            className="button primary-button px-3"
                                                            onClick={handleApply}
                                                            disabled={!filterData.environmentId && !filterData.statusBy && !filterData.workloadId}
                                                        >
                                                            Apply
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/*filter*/}

                                        {/* filter start */}
                                        {/* search */}
                                        <div className="input-group input-search custom-border-box custom-search-box">
                                            <input
                                                type="search"
                                                className="px-3 form-control common-bg border-0 custom-search $gray-v1"
                                                placeholder="Search"
                                                value={search}
                                                onChange={(e) => { searchRecord(e) }}

                                            />
                                            <button
                                                className="btn border-start-0  custom-search "
                                                type="button"
                                                onClick={getApprovalData}
                                            >
                                                <img src="images/search-icon.svg" alt="search" />
                                            </button>
                                        </div>
                                        {/* search */}
                                    </div>
                                </div>
                                {/* table-container heading end */}
                                {/* table start */}
                                <div className="table-responsive mt-4 table-border">
                                    <table className="table custom-table">
                                        <thead>
                                            <tr>
                                                <th className="position-sort-icon text-start border-0">
                                                    {" "}
                                                    Workloads
                                                </th>
                                                <th className="text-start border-0">Environments</th>
                                                <th className="position-sort-icon text-start border-0">
                                                    Request Type{" "}
                                                </th>
                                                <th className="position-sort-icon text-start border-0">
                                                    Timestamp
                                                    <span className="cursor-pointer margin-left-2" hidden={approvalData.length === 0}>
                                                        <img src="./images/table-sort.svg" alt="sort"
                                                            // onClick={() => {
                                                            //     // setSortColumnName("request_date_time")
                                                            //     // setSortOrder("DESC")
                                                            //     toggleSortOrder()
                                                            // }
                                                            // } 
                                                            style={{
                                                                transform: sortColumnName === "request_date_time" && sortOrder === "ASC" ? "scaleY(-1)" : "scaleY(1)",
                                                                transition: "transform 0.2s ease-in-out",
                                                              }}

                                                            onClick={() => toggleSortOrder("request_date_time")}
                                                        />
                                                    </span>
                                                </th>
                                                <th className="position-sort-icon text-start border-0">
                                                    Requested by
                                                </th>
                                                <th className="position-sort-icon text-start border-0">
                                                    Approved by{" "}
                                                </th>
                                                <th className="position-sort-icon text-start border-0">
                                                    Status
                                                    <span className="cursor-pointer margin-left-2" hidden={approvalData.length === 0}>
                                                        <img src="./images/table-sort.svg" alt="sort"
                                                             style={{
                                                                transform: sortColumnName === "status" && sortOrder === "ASC" ? "scaleY(-1)" : "scaleY(1)",
                                                                transition: "transform 0.2s ease-in-out",
                                                              }}
                                                            onClick={() => toggleSortOrder("status")}
                                                        />
                                                    </span>
                                                </th>
                                                <th className="text-center border-0">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {approvalData.length === 0 ? (
                                                <tr>
                                                    <td colSpan="8" className="no-record-cell">
                                                        <div className="no-record-found"
                                                            style={{ top: '50%', left: '50%' }}
                                                        >
                                                            <img src="images/no-records-found.png" alt="No records found" className="no-record-icon"
                                                                style={{ width: '200px' }}
                                                            />

                                                        </div>
                                                    </td>
                                                </tr>
                                            ) : (
                                                renderApprovalData()
                                            )}


                                        </tbody>
                                    </table>


                                </div>
                                {/* table end */}
                                <div className="d-flex justify-content-between align-items-center my-4">
                                    <p className="font-14 font-medium secondary-text-color mb-0">
                                        # of Records{" "}
                                        <span className="font16 font-medium color-black">

                                            <span> {Math.min(loadCount, totalCount)}</span> out of <span>{totalCount}</span>
                                        </span>
                                    </p>
                                    {/* pagenation */}


                                    <div className="d-flex justify-content-end">
                                        <nav aria-label="Page navigation example">
                                            <ul className="pagination mb-0">
                                                <li className="page-item m-1 lh-1" style={{"cursor":"pointer"}}>
                                                    <a
                                                        className="page-link custom-pagenation px-3 py-2"
                                                        href="#"
                                                        aria-label="Previous"
                                                        onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
                                                    >
                                                        <span aria-hidden="true">
                                                            <img src="images/pagenation-left.svg" alt="previous-arrow" />
                                                        </span>
                                                    </a>
                                                </li>

                                                {/* Page numbers */}
                                                {(() => {
                                                    let startPage = Math.max(currentPage - 1, 1);
                                                    let endPage = Math.min(startPage + 2, totalPages);

                                                    if (endPage - startPage < 2) {
                                                        startPage = Math.max(endPage - 2, 1);
                                                    }

                                                    return [...Array(endPage - startPage + 1)].map((_, index) => (
                                                        <li key={startPage + index} className="page-item m-1 lh-1" style={{"cursor":"pointer"}}>
                                                            <a
                                                                className={`page-link custom-pagenation ${currentPage === startPage + index ? 'active' : ''}`}

                                                                onClick={() => handlePageChange(startPage + index)}
                                                            >
                                                                {startPage + index}
                                                            </a>
                                                        </li>
                                                    ));
                                                })()}

                                                {currentPage < totalPages - 2 && (
                                                    <>
                                                        <li className="page-item m-1 lh-1" style={{"cursor":"pointer"}}>
                                                            <a className="page-link custom-pagenation" href="#">
                                                                <img src="images/three-dots-img.svg" alt="three-dots-img" />
                                                            </a>
                                                        </li>
                                                        <li className="page-item m-1 lh-1" style={{"cursor":"pointer"}}>
                                                            <a
                                                                className="page-link custom-pagenation"
                                                                href="#"
                                                                onClick={() => handlePageChange(totalPages)}
                                                            >
                                                                {totalPages}
                                                            </a>
                                                        </li>
                                                    </>
                                                )}

                                                <li className="page-item m-1 lh-1" style={{"cursor":"pointer"}}>
                                                    <a
                                                        className="page-link custom-pagenation px-3 py-2"
                                                        href="#"
                                                        aria-label="Next"
                                                        onClick={() => handlePageChange(Math.min(currentPage + 1, totalPages))}
                                                    >
                                                        <span aria-hidden="true">
                                                            <img src="images/pagenation-right.svg" alt="next-arrow" />
                                                        </span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>



                                </div>
                            </div>
                            {/* Workload end */}
                            <div
                                className="tab-pane fade"
                                id="pills-b2a"
                                role="tabpanel"
                                aria-labelledby="pills-b2a-tab"
                            >
                                B2A
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ApprovalGrid