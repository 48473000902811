//PS_01 import necessary hooks and api functions
import React, { useState, useEffect ,useContext} from 'react';
//PS_02
import AwsConnectorModal from './AWS_connect';
import AzureConnectorModal from './AZURE_connect';
import SideBar from '../sidebar/SideBar';
import DeleteConfirmationModal from '../Organization/Delete_Popup';
import moment from 'moment';
import { getAllConnectorDetailsApi,getAllProvidersApi,deleteConnectorApi} from '../../Service/api';
import ConfirmationModal from './confimationModel';
import { OrgContext } from '../../ContextProvider/OrgContext';
import Loader from '../loader/loader';
import Pagination from '../CostManagement/Pagination';

const ProviderConfiguration = ({ isDarkMode, setIsDarkMode }) => {
    // PS_05 State variables
    const [providers, setProviders] = useState([]);
const [cloudProvider, setCloudProvider] = useState("AWS");
    const [searchTerm, setSearchTerm] = useState('');
    const [connectors, setConnectors] = useState([]);
    const [selectedproviderId, setselectedproviderId] = useState([]);
console.log("selectedproviderId",selectedproviderId);
    const [filteredConnectors, setFilteredConnectors] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [page, setPage] = useState(1);
    const recordsPerPage = 10;
    const [isAdmin, setisAdmin] = useState(localStorage.getItem('isAdmin')=="true"?true:false);

    const [showAwsModal, setShowAwsModal] = useState(false);
    const [showAzureModal, setShowAzureModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedConnector, setSelectedConnector] = useState(null);
  
    const {
        userRoles,
        orgId,
        providerId,
        architectures,
        canCreateWorkload,
        canDeleteWorkload,
        canViewWorkload,
        setuserRoles,
    
        // Add organization and provider fields here
        providerName,       
        orgData,           
        setOrgData, 
        setOrgId,       
        setArchitectures,
    
        envId,             // Environment ID
        workloadId,        // Workload ID
        aiArchitecture,    
        viewMode,          
        envName,           
        language,          
        archType,          
        business,          
        rulebook,          
        archDesc,          
        services,
    
        lastBuildID,       
        lastBuildDate,     
        lastBuildMessage,
    
        repoId,            
        repoName,
    
        PulseOrganization_id,
        PulseVertical_id,
        PulseVertical_name,
        PulseOrganization_name,
        PulseIdentification,
    
        setEnvId           // Function to update environment ID
    } = useContext(OrgContext);
   

   
    const [userId, setuserId] = useState(localStorage.getItem('userId'));

    //PS_28
    const decodeBase64 = (encodedData) => {
        console.log("decodeBase64 input:", encodedData);
        try {
            // Ensure encodedData is a string before proceeding
            if (typeof encodedData !== 'string') {
                throw new Error("Input must be a string");
            }
    
            // Trim whitespace and validate length
            const trimmedData = encodedData.trim();
            
            // Check for valid Base64 characters
            const base64Regex = /^[A-Za-z0-9+/]+={0,2}$/;
            if (!base64Regex.test(trimmedData) || trimmedData.length % 4 !== 0) {
                throw new Error("Invalid Base64 string");
            }
            
            const decodedString = atob(trimmedData); // Decodes a Base64-encoded string
            console.log("Decoded string:", decodedString);
            return decodedString;
        } catch (error) {
            console.error("Decoding error:", error);
            return null; // Return null or handle error as needed
        }
    };
//PS_04
    // Fetch connectors (simulate API call)
    useEffect(() => {
        // Simulate fetching data
        loadProviders()
        // setConnectors(sampleConnectors);
       
    }, []);

    const [loading, setLoading] = useState(true); // State for loading

    
    const loadConnectorDetails = async (providerID) => {
        const payload = {
            userId : userId,
            orgId : orgId,
            providerId:providerID,
            search: "" // Search term from state
        };
        setLoading(true); // Start loading

        try {
            const response = await getAllConnectorDetailsApi(payload); // Call the API function
            if (response.success) {
                setCurrentPage(1)
                setLoading(false)
                console.log('Connector details fetched successfully:', response.data);
                const decodedConnectors = response.data.map(item => {
                    return {
                        ...item, // Spread operator to keep other fields unchanged
                        accountId: decodeBase64(item.accountId), // Decode accountId
                        clientSecret: decodeBase64(item.clientSecret), // Decode clientSecret
                        subscriptionId: decodeBase64(item.subscriptionId), // Decode subscriptionId
                        tenantId: decodeBase64(item.tenantId), // Decode tenantId
                        secretKey: decodeBase64(item.secretKey), // Decode secretKey
                        clientId:decodeBase64(item.clientId),
                        accessKey: decodeBase64(item.accessKey), // Decode secretKey

                    };
                });
            
                setConnectors(decodedConnectors);  // Update state with decoded connector details
                setFilteredConnectors(decodedConnectors); 
                setTotalRecords(decodedConnectors.length);
            
            } else {
                console.error('Error fetching connector details');
            }
        } catch (error) {
            console.error('An error occurred while fetching connector details:', error);
        }finally {
            // setLoading(false); // Stop loading regardless of success or failure
        }
    };
    const loadProviders = async () => {
        console.log("checked providers");
        const payload = {
            userId : userId,
            orgId :orgId,
            type: "general"
        };

        try {
            const response = await getAllProvidersApi(payload); // Call the API function
            console.log("checked providers 2");

            if (response.success) {
                console.log('Providers fetched successfully:', response.data);
                setProviders(response.data); // Update state with fetched providers
                
                // Find the provider ID for AWS and load connectors
                const awsProvider = response.data.find(provider => provider.providerName === "AWS");
                if (awsProvider) {
                    setselectedproviderId(awsProvider.providerId); // Set selected provider ID
                    loadConnectorDetails(awsProvider.providerId); // Load connectors for AWS
                }
            } else {
                console.error('Error fetching providers');
            }
        } catch (error) {
            console.error('An error occurred while fetching providers:', error);
        }
    };
    // Filter connectors based on search term
   
//PS_20
    const handleDeleteClick = (connector) => {
        if (connector.canDelete) {
            setSelectedConnector(connector);
            setShowModal(true);
        } else {
            console.log('This connector cannot be deleted.');
        }
    };
//PS_21
    const handleDeleteConfirm =async () => {
        const payload = {
            userId : userId,
            orgId : orgId,
            connectorId: selectedConnector.connectorId // Connector ID from state
        };

        try {
            const response = await deleteConnectorApi(payload); // Call the API function
            if (response.success) {
loadConnectorDetails(selectedproviderId)             
            } else {
                console.error('Error deleting connector');
            }
        } catch (error) {
            console.error('Failed to delete connector:', error);
           
        }
        setShowModal(false);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    //PS_22
    const handleAzureEditClick = (connector) => {
        setSelectedConnector(connector);
        setShowAzureModal(true); // Open modal for editing
    };
    const handleEditClick = (connector) => {
        setSelectedConnector(connector);
        setShowAwsModal(true); // Open modal for editing
    };

//PS_23
    const handleAddConnector = () => {
        setSelectedConnector(null)
        if(cloudProvider === 'Azure'){

            setShowAzureModal(true);

        }else{setShowAwsModal(true); // Show the AWS connector modal
    }};
    
    const handleCloseAwsModal = () => {
        setShowAwsModal(false); // Hide the AWS connector modal
        loadConnectorDetails(selectedproviderId)
    };
 

    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
    
        // Filter connectors based on search term
        const filtered = connectors.filter(connector => {
            const connectorName = connector.connectorName ? connector.connectorName.toLowerCase() : '';
            const accountId = connector.accountId ? connector.accountId.toLowerCase() : '';
            const tenantId = connector.tenantId ? connector.tenantId.toLowerCase() : '';
    
            return (
                (cloudProvider === 'AWS' ? accountId.includes(value.toLowerCase()) : tenantId.includes(value.toLowerCase())) ||
                connectorName.includes(value.toLowerCase())
            );
        });
    
        setFilteredConnectors(filtered); // Update filtered connectors based on search term
    };
    const handleCloseAzureModal = () => {
        setShowAzureModal(false); // Hide the Azure connector modal
        loadConnectorDetails(selectedproviderId)

    };

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const handleShowConfirmation = (message) => {
        setErrorMessage(message);
        setShowConfirmationModal(true);
    };
  
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Set how many items you want per page

    // Calculate total items
    const totalItems = filteredConnectors.length;

    // Calculate current items for the current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredConnectors.slice(indexOfFirstItem, indexOfLastItem);

    // Calculate displayed record count
    const displayedCount = currentItems.length;
    // Pagination logic
    // const paginatedConnectors = filteredConnectors.slice((page - 1) * recordsPerPage, page * recordsPerPage);

    if (loading) {
        return <Loader />; // Show loader while loading
    }  
    return (
        <div className="container-fluid ps-0">
    
    <div className="row">
    <SideBar/>
    <div className="col-md-9 mt-4 right-container-scroll">
			<div className="tab-content" id="pills-tabContent">

		<div className="tab-pane fade show active" id="pills-Workloads" role="tabpanel" aria-labelledby="pills-Workloads-tab">

			<div className="px-4 mt-4">

				{/* Table Container Heading Start */}
				<div className="d-xl-flex justify-content-between align-items-center">
					<h2 className="font-32 font-bold black-v2 mb-2 mt-xl-0 mb-0">Providers</h2>
					<div className="d-flex justify-content-center flex-nowrap gap-3">
						{/* Cloud Provider Dropdown */}
						<div className="dropdown">
    <button 
        type="button" 
        className="btn custom-dropdown d-flex justify-content-between align-items-center gap-5 font-12 font-regular color-black shadow-none"
        id="dropdownMenuButton1" 
        data-bs-toggle="dropdown" 
        aria-expanded="false"
    >
        <span className="d-flex">
        <img 
    src={cloudProvider.toLowerCase() === 'aws' ? 
        (isDarkMode ? 'images/aws-logo-dark.svg' : 'images/aws-logo.svg') : 
        `images/${cloudProvider.toLowerCase()}-logo.svg`} 
    alt={`${cloudProvider}-img`} 
    className="me-1 icon-width" 
/>
            {cloudProvider}
        </span>
        <img src="images/drop-down-arrow.svg" alt="dropdown-arrow" />
    </button>
    <ul className="dropdown-menu body-bg access-dropdown w-100" aria-labelledby="dropdownMenuButton1">
        {providers.map((provider) => (
            <li 
                key={provider.providerId} // Use providerId as the key
                className="font-regular color-black font-12 p-2 custom-list"
                onClick={() => {
                    setCloudProvider(provider.providerName); // Set cloudProvider to providerName
                    loadConnectorDetails(provider.providerId); // Call fetchWorkloads with the new providerId
                    setselectedproviderId(provider.providerId)
                }} 
            >
                <span className="d-flex">
                <img 
    src={provider.providerName.toLowerCase() === 'aws' ? 
        (isDarkMode ? 'images/aws-logo-dark.svg' : 'images/aws-logo.svg') : 
        `images/${provider.providerName.toLowerCase()}-logo.svg`} 
    alt={`${provider.providerName}-img`} 
    className="me-1 icon-width" 
/>
                    {provider.providerName}
                </span>
            </li>
        ))}
    </ul>
</div>

                    {/* Search Bar */}
                    <div className="input-group input-search custom-border-box custom-search-box">
                        <input 
                            type="search"
                            className="px-3 form-control common-bg border-0 custom-search $gray-v1"
                            placeholder="Search"
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                        <button className="btn border-start-0 custom-search" type="button">
                            <img src="images/search-icon.svg" alt="search" />
                        </button>
                    </div>

                    {/* Add Connector Button */}
                    {(canCreateWorkload || isAdmin) && (
    <button type="button" className="button primary-button text-nowrap" onClick={handleAddConnector}>
        <img src="images/add-icon.svg" alt="add-icon" className="me-2"/> Add Connector
    </button>
)}

                    {cloudProvider === 'AWS' && showAwsModal && (
                <AwsConnectorModal 
                    showModal={showAwsModal} 
                    onClose={handleCloseAwsModal}  
                    connectorDetails={selectedConnector} 
                    onError={handleShowConfirmation} // Pass error handling function
                    provider={selectedproviderId}
                />
            )}
             {showConfirmationModal && (
                <ConfirmationModal 
                    isOpen={showConfirmationModal} 
                    onClose={() =>{ setShowConfirmationModal(false)}} 
                    message={errorMessage} 
                />
            )}
         {/* {cloudProvider === 'Azure' && (
            <button type='button' onClick={handleAddAzureConnector} className='button primary-button text-nowrap'>
                Add Connector
            </button>
        )} */}

        {/* Azure Connector Modal */}
        {cloudProvider === 'Azure' && showAzureModal && (
            <AzureConnectorModal showModal={showAzureModal} onClose={handleCloseAzureModal} connectorDetails={selectedConnector} provider={selectedproviderId}
            onError={handleShowConfirmation}  // Pass error handling function

/>
        )}
         {showConfirmationModal && (
                <ConfirmationModal 
                    isOpen={showConfirmationModal} 
                    onClose={() => setShowConfirmationModal(false)} 
                    message={errorMessage} 
                />
            )}
                </div>
            </div>

            {/* Table Start */}
            <div className="table-responsive mt-4 table-border">
          {loading ? (
                <Loader />
            ) : (
                <div>
                    <table className="table custom-table">
                        <thead>
                            <tr>
                                {cloudProvider === 'AWS' ? (
                                    <>
                                        <th className="text-start border-0">Connector</th>
                                        <th className="text-start border-0">Account ID</th>
                                        <th className="text-start border-0">Secret Key</th>
                                        <th className="text-start border-0">Age</th>
                                        <th className="text-start border-0">Created Date</th>
                                        <th className="text-start border-0">Modified Date</th>
                                        <th className="text-center border-0">Action</th>
                                    </>
                                ) : (
                                    <>
                                        <th className="text-start border-0">Connector</th>
                                        <th className="text-start border-0">Tenant ID</th>
                                        <th className="text-start border-0">Subscription ID</th>
                                        <th className="text-start border-0">Secret Key</th>
                                        <th className="text-start border-0">Age</th>
                                        <th className="text-start border-0">Created Date</th>
                                        <th className="text-start border-0">Modified Date</th>
                                        <th className="text-center border-0">Action</th>
                                    </>
                                )}
                            </tr>
                        </thead>
                        <tbody className="manage-work">
                            {currentItems.length > 0 ? (
                                currentItems.map((connector, index) => (
                                    <tr key={index}>
                                        {cloudProvider === 'AWS' ? (
                                            <>
                                                {/* AWS specific fields */}
                                                <td className="text-start">{connector.connectorName}</td>
                                                <td className="text-start">{connector.accountId}</td>
                                                <td className="text-start">**********
                                                    {/* {connector.secretKey ? `******${connector.secretKey.slice(6)}` : 'N/A'} */}
                                                </td>
                                                <td className="text-start">{connector.age}</td>
                                                <td className="text-start">{moment(connector.createdDate).format('MM/DD/YYYY')}</td>
                                                <td className="text-start">{moment(connector.modifiedDate).format('MM/DD/YYYY')}</td>

                                                {/* Action buttons */}
                                                <td className="text-center">
    {/* Edit and Delete actions */}
    <div className="d-flex justify-content-center gap-3">
      {(canCreateWorkload || isAdmin) && <a  style={{ cursor: 'pointer' }} onClick={() => handleEditClick(connector)}>
            <img src="images/edit-icon-blue.svg" alt="edit-icon" />
        </a>}
        {connector.canDelete && (canDeleteWorkload ||isAdmin )? (
            <a  style={{ cursor: 'pointer' }} onClick={() => handleDeleteClick(connector)}>
                <img src="/images/delete-icon.svg" alt="delete-icon" />
            </a>
        ) : (
            <span style={{ width: '24px' }} />
        )}
        {showModal && (
		<DeleteConfirmationModal 
			item={selectedConnector} 
			itemType={"connector"} 
			onDeleteConfirm={handleDeleteConfirm} 
			onClose={() => setShowModal(false)} 
		/>
	)}
    </div>
</td>
                                            </>
                                        ) : (
                                            <>
                                                {/* Azure specific fields */}
                                                <td className ="text-start">{connector.connectorName}</td> 
                                                <td className ="text-start">{connector.tenantId}</td> 
                                                <td className ="text-start">{connector.subscriptionId}</td> 
                                                <td className="text-start">**********
                                                    {/* {connector.clientSecret ? `******${connector.clientSecret.slice(6)}` : 'N/A'} */}
                                                </td>

                                                <td className ="text-start">{connector.age}</td> 
                                                <td className="text-start">{moment(connector.createdDate).format('MM/DD/YYYY')}</td>
                                                <td className="text-start">{moment(connector.modifiedDate).format('MM/DD/YYYY')}</td>

                                                {/* Action buttons */}
                                                <td className="text-center">
    {/* Edit and Delete actions */}
    <div className="d-flex justify-content-center gap-3">
     {(canCreateWorkload|| isAdmin) &&   <a  style={{ cursor: 'pointer' }} onClick={() => handleAzureEditClick(connector)}>
            <img src="images/edit-icon-blue.svg" alt="edit-icon" />
        </a>}
        {connector.canDelete &&(canDeleteWorkload || isAdmin)? (
            <a  style={{ cursor: 'pointer' }} onClick={() => handleDeleteClick(connector)}>
                <img src="/images/delete-icon.svg" alt="delete-icon" />
            </a>
        ) : (
            <span style={{ width: '24px' }} />  
        )}
         {showModal && (
		<DeleteConfirmationModal 
			item={selectedConnector} 
			itemType={"connector"} 
			onDeleteConfirm={handleDeleteConfirm} 
			onClose={() => setShowModal(false)} 
		/>
	)}
    </div>
</td>
                                            </>
                                        )}
                                    </tr> 
                                ))
                            ) : (
                                // No r9ecords found message
                                <tr>
                                    <td colSpan={7} className="text-center">
                                        {/* Adjust colSpan based on the number of columns */}
                                        <div className="d-flex flex-column justify-content-center align-items-center custom-height">
                                            <img src="images/nodata-found.svg" alt="No Data Found" />
                                            <h6 className="color-black font-bold font-16 mt-5 mb-0">No Data Found!</h6>
                                            <p className="font-medium font-12 mt-4 mb-0 secondary-text-color-v5">
                                                Add new data to see the data here.
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>

                    {/* Pagination Component */}
                  
                </div>
            )}
            
        </div> 
        {/* {totalItems > itemsPerPage && ( // Only show pagination if there are more items than can fit on one page */}
                        <Pagination 
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            totalItems={totalItems}
                            itemsPerPage={itemsPerPage}
                            displayedCount={displayedCount}
                        />
                     
                    {/* )} */}
                    
        </div> </div></div></div></div></div>
    );
};

export default ProviderConfiguration;