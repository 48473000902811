
// B2AContext.js
import React, { createContext, useState, useContext } from 'react';

// Create the context
const B2AContext = createContext();

// Create a provider component
export function B2AProvider({ children }) {
    const [orgId, setOrgId] = useState("6f47c8a1-9c2d-4e8b-b3a5-8d7f6e5d4c3b");
    const [userId, setUserId] = useState("c7d86e9d-4819-4f5e-95f8-782d4c6774a3");
    const [envId , setEnvId] = useState("986b6f95-50cf-4f42-95a1-b213f7b1da00")
    const [workloadId , setworkloadId] = useState("93f6aa1a-b004-46bf-bf5f-4f11ba31dea1")

    const [providerId, setProviderId] = useState("0a5816fa-5f85-4fd9-b716-62e4395c7b4a");
    const [providerName, setProviderName] =  useState("AWS")
    const [aiArchitecture, setaiArchitecture] = useState(true);
    const [envName,setEnvName] =  useState("DEV")
    const [language , setLanguage] = useState("")
    const [business , setBusiness] = useState("")
    const [archType,setArchType] = useState("")
    const [rulebook , setRulebook] = useState([])
    const [archDesc , setArchDesc] = useState("")
    const [services , setservices] = useState([])
  
    const [connectorId , setConnectorId] = useState("c4ac1a82-5bb0-4798-bb4e-b9082dba77f4")
    
  
    return (
        <B2AContext.Provider value={{ 
            orgId, 
            userId,
            envId,
            setEnvId,
            workloadId,
            providerId,
            providerName,
            aiArchitecture,
            envName,
            language,
            archType,
            business,
            rulebook,
            archDesc,
            services,
            connectorId
          
             
             
        }}>
            {children}
        </B2AContext.Provider>
    );
}

// Create a custom hook for using this context
export function useB2AContext() {
    return useContext(B2AContext);
}