// values["region"] = "us-east";
// values["tier"] = "standardssd";
// values["managedDiskType"] = "s4";
// values["managedDisks"] = "1";
// values["managedDisksTransactions"] = "1";
// values["AddSnapshot"] = "Yes";
// values["managedSnapshotRedundancy"] = "lrs";
// values["snapshotStorage"] = "1";
// values["managedDiskRedundancy"] = "lrs";
// values["storageV2Number"] = "1";
// values["storageV2Size"] = "1";
// values["storageV2HoursFactor"] = "1";
// values["storageV2Hours"] = "1";
// values["storageV2Iops"] = "1";
// values["storageV2Throughput"] = "1";            
// values["ultraSize"] = "8";
// values["ultraDisks"] = "1";
// values["ultraHoursFactor"] = "1";
// values["ultraHours"] = "1";
// values["ultraIops"] = "1";
// values["ultraThroughput"] = "1"; 

export default function Azure_Disk({ values, onChangeOfConfiguration, isViewer }) {
    try {
        return (
            <div id={`${values.instanceName.split(" ").join("_")}`} className="accordion-collapse collapse">
                <div className="accordioan-body p-0 ">
                    <table className="table table-borderless cg-grid font-14 font-semibold mb-0">
                        <tbody>
                            <tr className="ms-acc-data-align">
                                <td colSpan={3}>
                                    <div className="row py-4 mx-4 px-5" hidden={(values.isPriceAvailable == "No") ? false : true}>
                                        <div className="col-md-6 px-3"><span className="font-semibold font-14 text-danger">{values.isPriceAvailable == "No" ? "The Price is not available for the region / the configuration that you have selected might be not available in the region" : null}</span></div>
                                    </div>
                                    <div className="row py-4 mx-4 px-5">
                                        <div className="col-md-6 px-3">
                                            <label htmlFor="region" className="form-label font-14 font-medium black-v2 mb-3">
                                                Region
                                            </label>
                                            <select
                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                id="region"
                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                value={values.region}
                                                name={"region"}
                                                onChange={(e) => {
                                                    onChangeOfConfiguration(e, values.instanceName);
                                                }} disabled={isViewer}
                                            >

                                                <optgroup label="United States">
                                                    <option value="us-central">Central US</option>
                                                    <option value="us-east">East US</option>
                                                    <option value="us-east-2">East US 2</option>
                                                    <option value="us-north-central">North Central US</option>
                                                    <option value="us-south-central">South Central US</option>
                                                    <option value="us-west-central">West Central US</option>
                                                    <option value="us-west">West US</option>
                                                    <option value="us-west-2">West US 2</option>
                                                    <option value="us-west-3">West US 3</option>
                                                </optgroup>
                                                <optgroup label="United Kingdom">
                                                    <option value="united-kingdom-south">UK South</option>
                                                    <option value="united-kingdom-west">UK West</option>
                                                </optgroup>
                                                <optgroup label="United Arab Emirates">
                                                    <option value="uae-central">UAE Central</option>
                                                    <option value="uae-north">UAE North</option>
                                                </optgroup>
                                                <optgroup label="Switzerland">
                                                    <option value="switzerland-north">Switzerland North</option>
                                                    <option value="switzerland-west">Switzerland West</option>
                                                </optgroup>
                                                <optgroup label="Sweden">
                                                    <option value="sweden-central">Sweden Central</option>
                                                    <option value="sweden-south">Sweden South</option>
                                                </optgroup>
                                                <optgroup label="Spain">
                                                    <option value="spain-central">Spain Central</option>
                                                </optgroup>
                                                <optgroup label="Qatar">
                                                    <option value="qatar-central">Qatar Central</option>
                                                </optgroup>
                                                <optgroup label="Poland">
                                                    <option value="poland-central">Poland Central</option>
                                                </optgroup>
                                                <optgroup label="Norway">
                                                    <option value="norway-east">Norway East</option>
                                                    <option value="norway-west">Norway West</option>
                                                </optgroup>
                                                <optgroup label="Mexico">
                                                    <option value="mexico-central">Mexico Central</option>
                                                </optgroup>
                                                <optgroup label="Korea">
                                                    <option value="korea-central">Korea Central</option>
                                                    <option value="korea-south">Korea South</option>
                                                </optgroup>
                                                <optgroup label="Japan">
                                                    <option value="japan-east">Japan East</option>
                                                    <option value="japan-west">Japan West</option>
                                                </optgroup>
                                                <optgroup label="Italy">
                                                    <option value="italy-north">Italy North</option>
                                                </optgroup>
                                                <optgroup label="Israel">
                                                    <option value="israel-central">Israel Central</option>
                                                </optgroup>
                                                <optgroup label="India">
                                                    <option value="central-india">Central India</option>
                                                    <option value="south-india">South India</option>
                                                    <option value="west-india">West India</option>
                                                </optgroup>
                                                <optgroup label="Germany">
                                                    <option value="germany-north">Germany North</option>
                                                    <option value="germany-west-central">Germany West Central</option>
                                                </optgroup>
                                                <optgroup label="France">
                                                    <option value="france-central">France Central</option>
                                                    <option value="france-south">France South</option>
                                                </optgroup>
                                                <optgroup label="Europe">
                                                    <option value="europe-north">North Europe</option>
                                                    <option value="europe-west">West Europe</option>
                                                </optgroup>
                                                <optgroup label="Canada">
                                                    <option value="canada-central">Canada Central</option>
                                                    <option value="canada-east">Canada East</option>
                                                </optgroup>
                                                <optgroup label="Brazil">
                                                    <option value="brazil-south">Brazil South</option>
                                                    <option value="brazil-southeast">Brazil Southeast</option>
                                                </optgroup>
                                                <optgroup label="Australia">
                                                    <option value="australia-central">Australia Central</option>
                                                    <option value="australia-central-2">Australia Central 2</option>
                                                    <option value="australia-east">Australia East</option>
                                                    <option value="australia-southeast">Australia Southeast</option>
                                                </optgroup>
                                                <optgroup label="Asia Pacific">
                                                    <option value="asia-pacific-east">East Asia</option>
                                                    <option value="asia-pacific-southeast">Southeast Asia</option>
                                                </optgroup>
                                                <optgroup label="Africa">
                                                    <option value="south-africa-north">South Africa North</option>
                                                    <option value="south-africa-west">South Africa West</option>
                                                </optgroup>
                                            </select>
                                        </div>
                                        <div className="col-md-6 px-3">
                                            <label htmlFor="tier" className="form-label font-14 font-medium black-v2 mb-3">
                                                Tier
                                            </label>
                                            <select
                                                className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                id="tier"
                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                value={values.tier}
                                                name={"tier"}
                                                onChange={(e) => {
                                                    onChangeOfConfiguration(e, values.instanceName);
                                                }} disabled={isViewer}>
                                                <option value="standardhdd" >Standard HDD</option>
                                                <option value="standardssd" >Standard SSD</option>
                                                <option value="premiumssd" >Premium SSD</option>
                                                <option value="premiumssdv2" >Premium SSD v2</option>
                                                <option value="ultrassd" >Ultra Disk</option>
                                            </select>
                                        </div>

                                        {values.tier == "standardhdd" && <>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="managedDiskType" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Managed Disk Type
                                                </label>
                                                <select
                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                    id="managedDiskType"
                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                    value={values.managedDiskType}
                                                    name={"managedDiskType"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}>
                                                        <option value="s4">S4: 32 GiB, 500 Provisioned IOPS, 60 Provisioned MB/s</option>
                                                        <option value="s6">S6: 64 GiB, 500 Provisioned IOPS, 60 Provisioned MB/s</option>
                                                        <option value="s10">S10: 128 GiB, 500 Provisioned IOPS, 60 Provisioned MB/s</option>
                                                        <option value="s15">S15: 256 GiB, 500 Provisioned IOPS, 60 Provisioned MB/s</option>
                                                        <option value="s20">S20: 512 GiB, 500 Provisioned IOPS, 60 Provisioned MB/s</option>
                                                        <option value="s30">S30: 1,024 GiB, 500 Provisioned IOPS, 60 Provisioned MB/s</option>
                                                        <option value="s40">S40: 2,048 GiB, 500 Provisioned IOPS, 60 Provisioned MB/s</option>
                                                        <option value="s50">S50: 4,096 GiB, 500 Provisioned IOPS, 60 Provisioned MB/s</option>
                                                        <option value="s60">S60: 8,192 GiB, 1,300 Provisioned IOPS, 300 Provisioned MB/s</option>
                                                        <option value="s70">S70: 16,384 GiB, 2,000 Provisioned IOPS, 500 Provisioned MB/s</option>
                                                        <option value="s80">S80: 32,767 GiB, 2,000 Provisioned IOPS, 500 Provisioned MB/s</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="managedDisks" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Number of Disks
                                                </label>
                                                <input
                                                    type="text"
                                                    style={{paddingTop :"10px"}}
                                                    placeholder="Enter the amount"
                                                    className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                    id="managedDisks"
                                                    value={values.managedDisks}
                                                    name={"managedDisks"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}
                                                />
                                            </div>

                                            <div className="col-md-6 px-3">
                                                <label htmlFor="managedDisksTransactions" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Storage transactions
                                                </label>
                                                <input
                                                    type="text"
                                                    style={{paddingTop :"10px"}}
                                                    placeholder="Enter the amount"
                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                    id="managedDisksTransactions"
                                                    value={values.managedDisksTransactions}
                                                    name={"managedDisksTransactions"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}
                                                />
                                            </div>

                                            <div className="col-md-6 px-3">
                                                <label htmlFor="AddSnapshot" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Add Snapshot
                                                </label>
                                                <select
                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                    id="AddSnapshot"
                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                    value={values.AddSnapshot}
                                                    name={"AddSnapshot"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}>
                                                    <option value="No">No</option>
                                                    <option value="Yes">Yes</option>
                                                </select>
                                            </div>
                                            {values.AddSnapshot === "Yes" && <>
                                                <div className="col-md-6 px-3">
                                                    <label htmlFor="managedSnapshotRedundancy" className="form-label font-14 font-medium black-v2 mb-3">
                                                        Managed Snapshot Redundancy
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="managedSnapshotRedundancy"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.managedSnapshotRedundancy}
                                                        name={"managedSnapshotRedundancy"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="lrs">LRS</option>
                                                        <option value="zrs">ZRS</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-6 px-3">
                                                    <label htmlFor="snapshotStorage" className="form-label font-14 font-medium black-v2 mb-3">
                                                        Size of snapshot (GB)
                                                    </label>
                                                    <input
                                                        type="text"
                                                        style={{paddingTop :"10px"}}
                                                        placeholder="Enter the amount"
                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                        id="snapshotStorage"
                                                        value={values.snapshotStorage}
                                                        name={"snapshotStorage"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}
                                                    />
                                                </div>

                                            </>}
                                        </>}
                                        {values.tier == "standardssd" && <>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="managedDiskRedundancy" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Redundancy
                                                </label>
                                                <select
                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                    id="managedDiskRedundancy"
                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                    value={values.managedDiskRedundancy}
                                                    name={"managedDiskRedundancy"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}>
                                                    <option value="lrs">LRS</option>
                                                    <option value="zrs">ZRS</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="managedDiskType" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Managed Disk Type
                                                </label>
                                                <select
                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                    id="managedDiskType"
                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                    value={values.managedDiskType}
                                                    name={"managedDiskType"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}>
                                                    <option value="e1">E1: 4 GiB, 500 Provisioned IOPS, 100 Provisioned MB/s</option>
                                                    <option value="e2">E2: 8 GiB, 500 Provisioned IOPS, 100 Provisioned MB/s</option>
                                                    <option value="e3">E3: 16 GiB, 500 Provisioned IOPS, 100 Provisioned MB/s</option>
                                                    <option value="e4">E4: 32 GiB, 500 Provisioned IOPS, 100 Provisioned MB/s</option>
                                                    <option value="e6">E6: 64 GiB, 500 Provisioned IOPS, 100 Provisioned MB/s</option>
                                                    <option value="e10">E10: 128 GiB, 500 Provisioned IOPS, 100 Provisioned MB/s</option>
                                                    <option value="e15">E15: 256 GiB, 500 Provisioned IOPS, 100 Provisioned MB/s</option>
                                                    <option value="e20">E20: 512 GiB, 500 Provisioned IOPS, 100 Provisioned MB/s</option>
                                                    <option value="e30">E30: 1,024 GiB, 500 Provisioned IOPS, 100 Provisioned MB/s</option>
                                                    <option value="e40">E40: 2,048 GiB, 500 Provisioned IOPS, 100 Provisioned MB/s</option>
                                                    <option value="e50">E50: 4,096 GiB, 500 Provisioned IOPS, 100 Provisioned MB/s</option>
                                                    <option value="e60">E60: 8,192 GiB, 2,000 Provisioned IOPS, 400 Provisioned MB/s</option>
                                                    <option value="e70">E70: 16,384 GiB, 4,000 Provisioned IOPS, 600 Provisioned MB/s</option>
                                                    <option value="e80">E80: 32,767 GiB, 6,000 Provisioned IOPS, 750 Provisioned MB/s</option>
                                                </select>
                                            </div>

                                            <div className="col-md-6 px-3">
                                                <label htmlFor="managedDisks" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Number of Disks
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Enter the amount"
                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                    id="managedDisks"
                                                    value={values.managedDisks}
                                                    name={"managedDisks"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}
                                                />
                                            </div>

                                            <div className="col-md-6 px-3">
                                                <label htmlFor="managedDisksTransactions" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Storage transactions
                                                </label>
                                                <input
                                                    type="text"
                                                    style={{paddingTop :"10px"}}
                                                    placeholder="Enter the amount"
                                                    className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                    id="managedDisksTransactions"
                                                    value={values.managedDisksTransactions}
                                                    name={"managedDisksTransactions"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}
                                                />
                                            </div>

                                            <div className="col-md-6 px-3">
                                                <label htmlFor="AddSnapshot" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Add Snapshot
                                                </label>
                                                <select
                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                    id="AddSnapshot"
                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                    value={values.AddSnapshot}
                                                    name={"AddSnapshot"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}>
                                                    <option value="No">No</option>
                                                    <option value="Yes">Yes</option>
                                                </select>
                                            </div>
                                            {values.AddSnapshot === "Yes" && <>
                                                <div className="col-md-6 px-3">
                                                    <label htmlFor="snapshotStorage" className="form-label font-14 font-medium black-v2 mb-3">
                                                        Size of snapshot (GB)
                                                    </label>
                                                    <input
                                                        type="text"
                                                        style={{paddingTop :"10px"}}
                                                        placeholder="Enter the amount"
                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                        id="snapshotStorage"
                                                        value={values.snapshotStorage}
                                                        name={"snapshotStorage"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}
                                                    />
                                                </div>
                                            </>}
                                        </>}
                                        {values.tier == "premiumssd" && <>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="managedDiskRedundancy" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Redundancy
                                                </label>
                                                <select
                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                    id="managedDiskRedundancy"
                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                    value={values.managedDiskRedundancy}
                                                    name={"managedDiskRedundancy"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}>
                                                    <option value="lrs">LRS</option>
                                                    <option value="zrs">ZRS</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="managedDiskType" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Managed Disk Type
                                                </label>
                                                <select
                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                    id="managedDiskType"
                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                    value={values.managedDiskType}
                                                    name={"managedDiskType"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}>
                                                    <option value="p1">P1: 4 GiB, 120 Provisioned IOPS, 25 Provisioned MB/s</option>
                                                    <option value="p2">P2: 8 GiB, 120 Provisioned IOPS, 25 Provisioned MB/s</option>
                                                    <option value="p3">P3: 16 GiB, 120 Provisioned IOPS, 25 Provisioned MB/s</option>
                                                    <option value="p4">P4: 32 GiB, 120 Provisioned IOPS, 25 Provisioned MB/s</option>
                                                    <option value="p6">P6: 64 GiB, 240 Provisioned IOPS, 50 Provisioned MB/s</option>
                                                    <option value="p10">P10: 128 GiB, 500 Provisioned IOPS, 100 Provisioned MB/s</option>
                                                    <option value="p15">P15: 256 GiB, 1,100 Provisioned IOPS, 125 Provisioned MB/s</option>
                                                    <option value="p20">P20: 512 GiB, 2,300 Provisioned IOPS, 150 Provisioned MB/s</option>
                                                    <option value="p30">P30: 1,024 GiB, 5,000 Provisioned IOPS, 200 Provisioned MB/s</option>
                                                    <option value="p40">P40: 2,048 GiB, 7,500 Provisioned IOPS, 250 Provisioned MB/s</option>
                                                    <option value="p50">P50: 4,096 GiB, 7,500 Provisioned IOPS, 250 Provisioned MB/s</option>
                                                    <option value="p60">P60: 8,192 GiB, 16,000 Provisioned IOPS, 500 Provisioned MB/s</option>
                                                    <option value="p70">P70: 16,384 GiB, 18,000 Provisioned IOPS, 750 Provisioned MB/s</option>
                                                    <option value="p80">P80: 32,767 GiB, 20,000 Provisioned IOPS, 900 Provisioned MB/s</option>
                                                </select>
                                            </div>

                                            <div className="col-md-6 px-3">
                                                <label htmlFor="managedDisks" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Number of Disks
                                                </label>
                                                <input
                                                    type="text"
                                                    style={{paddingTop :"10px"}}
                                                    placeholder="Enter the amount"
                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                    id="managedDisks"
                                                    value={values.managedDisks}
                                                    name={"managedDisks"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}
                                                />
                                            </div>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="AddSnapshot" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Add Snapshot
                                                </label>
                                                <select
                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                    id="AddSnapshot"
                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                    value={values.AddSnapshot}
                                                    name={"AddSnapshot"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}>
                                                    <option value="No">No</option>
                                                    <option value="Yes">Yes</option>
                                                </select>
                                            </div>
                                            {values.AddSnapshot === "Yes" && <>
                                                <div className="col-md-6 px-3">
                                                    <label htmlFor="snapshotStorage" className="form-label font-14 font-medium black-v2 mb-3">
                                                        Size of snapshot (GB)
                                                    </label>
                                                    <input
                                                        type="text"
                                                        style={{paddingTop :"10px"}}
                                                        placeholder="Enter the amount"
                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                        id="snapshotStorage"
                                                        value={values.snapshotStorage}
                                                        name={"snapshotStorage"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}
                                                    />
                                                </div>
                                            </>}
                                        </>}

                                        {values.tier == "premiumssdv2" && <>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="storageV2Size" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Storage Costs - Disk Size (GiB)
                                                </label>
                                                <input
                                                    type="text"
                                                    style={{paddingTop :"10px"}}
                                                    placeholder="Enter the amount"
                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                    id="storageV2Size"
                                                    value={values.storageV2Size}
                                                    name={"storageV2Size"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}
                                                />
                                            </div>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="storageV2Number" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Storage Costs - Number of Disks
                                                </label>
                                                <input
                                                    type="text"
                                                    style={{paddingTop :"10px"}}
                                                    placeholder="Enter the amount"
                                                    className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                    id="storageV2Number"
                                                    value={values.storageV2Number}
                                                    name={"storageV2Number"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}
                                                />
                                            </div>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="storageV2Hours" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Storage Costs - Storage Hours
                                                </label>
                                                <input
                                                    type="text"
                                                    style={{paddingTop :"10px"}}
                                                    placeholder="Enter the amount"
                                                    className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                    id="storageV2Hours"
                                                    value={values.storageV2Hours}
                                                    name={"storageV2Hours"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}
                                                />
                                            </div>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="AddSnapshot" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Storage Hours Factor
                                                </label>
                                                <select
                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                    id="AddSnapshot"
                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                    value={values.AddSnapshot}
                                                    name={"AddSnapshot"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}>
                                                    <option value="1" >Hours</option>
                                                    <option value="24" >Days</option>
                                                    <option value="730" >Month</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="storageV2Hours" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Number of IOPS
                                                </label>
                                                <input
                                                    type="text"
                                                    style={{paddingTop :"10px"}}
                                                    placeholder="Enter the amount"
                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                    id="storageV2Hours"
                                                    value={values.storageV2Hours}
                                                    name={"storageV2Hours"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}
                                                />
                                            </div>

                                            <div className="col-md-6 px-3">
                                                <label htmlFor="storageV2Throughput" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Throughput
                                                </label>
                                                <input
                                                    type="text"
                                                    style={{paddingTop :"10px"}}
                                                    placeholder="Enter the amount"
                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                    id="storageV2Throughput"
                                                    value={values.storageV2Throughput}
                                                    name={"storageV2Throughput"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}
                                                />
                                            </div>
                                        </>}

                                        {values.tier == "ultrassd" && <>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="ultraSize" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Ultra Disk Size
                                                </label>
                                                <select
                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm "
                                                    id="ultraSize"
                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                    value={values.ultraSize}
                                                    name={"ultraSize"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}>
                                                    <option value="4" >4 GiB</option>
                                                    <option value="8" >8 GiB</option>
                                                    <option value="16" >16 GiB</option>
                                                    <option value="32" >32 GiB</option>
                                                    <option value="64" >64 GiB</option>
                                                    <option value="128" >128 GiB</option>
                                                    <option value="256" >256 GiB</option>
                                                    <option value="512" >512 GiB</option>
                                                    <option value="1024" >1 TiB</option>
                                                    <option value="2048" >2 TiB</option>
                                                    <option value="3072" >3 TiB</option>
                                                    <option value="4096" >4 TiB</option>
                                                    <option value="5120" >5 TiB</option>
                                                    <option value="6144" >6 TiB</option>
                                                    <option value="7168" >7 TiB</option>
                                                    <option value="8192" >8 TiB</option>
                                                    <option value="9216" >9 TiB</option>
                                                    <option value="10240" >10 TiB</option>
                                                    <option value="11264" >11 TiB</option>
                                                    <option value="12288" >12 TiB</option>
                                                    <option value="13312" >13 TiB</option>
                                                    <option value="14336" >14 TiB</option>
                                                    <option value="15360" >15 TiB</option>
                                                    <option value="16384" >16 TiB</option>
                                                    <option value="17408" >17 TiB</option>
                                                    <option value="18432" >18 TiB</option>
                                                    <option value="19456" >19 TiB</option>
                                                    <option value="20480" >20 TiB</option>
                                                    <option value="21504" >21 TiB</option>
                                                    <option value="22528" >22 TiB</option>
                                                    <option value="23552" >23 TiB</option>
                                                    <option value="24576" >24 TiB</option>
                                                    <option value="25600" >25 TiB</option>
                                                    <option value="26624" >26 TiB</option>
                                                    <option value="27648" >27 TiB</option>
                                                    <option value="28672" >28 TiB</option>
                                                    <option value="29696" >29 TiB</option>
                                                    <option value="30720" >30 TiB</option>
                                                    <option value="31744" >31 TiB</option>
                                                    <option value="32768" >32 TiB</option>
                                                    <option value="33792" >33 TiB</option>
                                                    <option value="34816" >34 TiB</option>
                                                    <option value="35840" >35 TiB</option>
                                                    <option value="36864" >36 TiB</option>
                                                    <option value="37888" >37 TiB</option>
                                                    <option value="38912" >38 TiB</option>
                                                    <option value="39936" >39 TiB</option>
                                                    <option value="40960" >40 TiB</option>
                                                    <option value="41984" >41 TiB</option>
                                                    <option value="43008" >42 TiB</option>
                                                    <option value="44032" >43 TiB</option>
                                                    <option value="45056" >44 TiB</option>
                                                    <option value="46080" >45 TiB</option>
                                                    <option value="47104" >46 TiB</option>
                                                    <option value="48128" >47 TiB</option>
                                                    <option value="49152" >48 TiB</option>
                                                    <option value="50176" >49 TiB</option>
                                                    <option value="51200" >50 TiB</option>
                                                    <option value="52224" >51 TiB</option>
                                                    <option value="53248" >52 TiB</option>
                                                    <option value="54272" >53 TiB</option>
                                                    <option value="55296" >54 TiB</option>
                                                    <option value="56320" >55 TiB</option>
                                                    <option value="57344" >56 TiB</option>
                                                    <option value="58368" >57 TiB</option>
                                                    <option value="59392" >58 TiB</option>
                                                    <option value="60416" >59 TiB</option>
                                                    <option value="61440" >60 TiB</option>
                                                    <option value="62464" >61 TiB</option>
                                                    <option value="63488" >62 TiB</option>
                                                    <option value="64512" >63 TiB</option>
                                                    <option value="65536" >64 TiB</option>
                                                </select>
                                            </div>

                                            <div className="col-md-6 px-3">
                                                <label htmlFor="ultraDisks" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Number of Disks
                                                </label>
                                                <input
                                                    type="text"
                                                    style={{paddingTop :"10px"}}
                                                    placeholder="Enter the amount"
                                                    className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                    id="ultraDisks"
                                                    value={values.ultraDisks}
                                                    name={"ultraDisks"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}
                                                />
                                            </div>

                                            <div className="col-md-6 px-3">
                                                <label htmlFor="ultraHours" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Storage Costs - Ultra Hours
                                                </label>
                                                <input
                                                    type="text"
                                                    style={{paddingTop :"10px"}}
                                                    placeholder="Enter the amount"
                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                    id="ultraHours"
                                                    value={values.ultraHours}
                                                    name={"ultraHours"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}
                                                />
                                            </div>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="ultraHoursFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Storage Costs - Ultra Hours Factor
                                                </label>
                                                <select
                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                    id="ultraHoursFactor"
                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                    value={values.ultraHoursFactor}
                                                    name={"ultraHoursFactor"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}>
                                                    <option value="1" >Hours</option>
                                                    <option value="24" >Days</option>
                                                    <option value="730" >Month</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="ultraIops" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Number of IOPS
                                                </label>
                                                <input
                                                    type="text"
                                                    style={{paddingTop :"10px"}}
                                                    placeholder="Enter the amount"
                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                    id="ultraIops"
                                                    value={values.ultraIops}
                                                    name={"ultraIops"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}
                                                />
                                            </div>

                                            <div className="col-md-6 px-3">
                                                <label htmlFor="ultraThroughput" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Throughput
                                                </label>
                                                <input
                                                    type="text"
                                                    style={{paddingTop :"10px"}}
                                                    placeholder="Enter the amount"
                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                    id="ultraThroughput"
                                                    value={values.ultraThroughput}
                                                    name={"ultraThroughput"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}
                                                />
                                            </div>
                                        </>}

                                    </div>{ }</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    } catch (error) {
        console.log(error);
    }
}