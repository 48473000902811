//PS_01- Import statements
import { useEffect, useMemo, useState } from "react";
import { useModuleContext } from "../../../ContextProvider/ModulesContxt";
import { getSelfServiceWithAI } from "../../../Service/api";

//PS_11 - Define a component
function AddServicepopup({ serviceToEdit, setEditSelectedService, setEditMode, editMode, saveButton, updateButton }) {

    const {
        addServiceData,
        setAddServiceData,

    } = useModuleContext()

    //PS_12- Declare state variables
    const [containServices, setcontainServices] = useState(false);
    const [formData, setFormData] = useState({
        serviceName: '',
        containService: containServices,
        serviceIcon: '',
        description: '',
        file: null,
        fileName: null
    });
    const[fileUpdate,setFileUpdate]=useState(null)
    const[fileUpdate2,setFileUpdate2]=useState(null)

    console.log(serviceToEdit, editMode, "serviceToeditt")
    console.log(formData.serviceName, "print serviceName")

    //PS_30- Initial useEffect to prepopulate data
    useEffect(() => {

        if (serviceToEdit) {
            setFormData({
                serviceName: serviceToEdit.serviceName || '',
                containService: serviceToEdit.containService || containServices,
                serviceIcon: serviceToEdit.serviceIcon || null,
                description: serviceToEdit.description || formData.description,
                fileName: serviceToEdit.fileName || null,
                file: serviceToEdit.file || null
            });
            setFileUpdate2(serviceToEdit.file)
            console.log("serviceToEdit.file",serviceToEdit);
            
        }
    }, [serviceToEdit]);

    //PS_17- PS_19 - When fields value changes
    const handleChange = (e) => {
        const { name, type, checked, value } = e.target;

        setFormData((prevState) => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    //PS_20-PS_23 - When file uploaded this function triggers
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFormData((prevState) => ({
                ...prevState,
                serviceIcon: file,
                fileName: file.name,
            }));
            setFileUpdate(file)
        }
    };

    //PS_24 - PS_27 - On click of import or update button
    const handleSubmit = async (e) => {

        debugger
        const { serviceIcon, ...restFormData } = formData;
        console.log("serviceDataserviceData1",formData);

console.log("fileUpdate",fileUpdate);

        let imgBase64 = '';
        if (fileUpdate) {
            imgBase64 = await convertFileToBase64(fileUpdate);
        }
        console.log("fileUpdate2",imgBase64);

        e.preventDefault();

        const serviceData = {

            ...restFormData,
            file: imgBase64 ||fileUpdate2,

        };
        console.log("serviceDataserviceData2",serviceData);
        
        setAddServiceData(serviceData)

        if (editMode) {
            updateButton(serviceData);
        } else {
            saveButton(serviceData);
        }


        console.log(serviceData, 'view formdata value in handleSubmit');
        handleClose();
        setFileUpdate(null)

    };

    console.log(formData.name, "servicename check")

    //PS_31- PS_32
    const handleClose = () => {
        setFormData({
            serviceName: '',
            containService: false,
            serviceIcon: '',
            description: '',
            file: null,
            fileName: null,
        });

        setEditMode(false);

        setEditSelectedService(null)
        const closeButton = document.getElementById('close');
        if (closeButton) {
            closeButton.click();
        }
    };

    //PS_33-PS_34
    const convertFileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader?.readAsDataURL(file); // Convert to Base64
        });
    };

    useMemo(()=>{
        console.log("formmdata",formData);
        
    },[formData])
    //PS_35 - Rendering part
    return (
        <>
            <div
                className="modal fade show"
                id="staticBackdrop-22"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered justify-content-center">
                    <div className="modal-content border-0">
                        <div className="modal-header border-0 p-4">
                            <h1
                                className="modal-title font-20 font-bold black-v2"
                                id="staticBackdropLabel"
                            >
                                {editMode ? 'Edit Service' : 'Add Service'}
                            </h1>
                            <button
                                type="button"
                                className="btn shadow-none"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                id="close"
                                onClick={handleClose}
                            >
                                <img
                                    src="images/close-icon.svg"
                                    alt="close-icon"
                                    className="icon-hover shadow-none modal-close-filter"
                                />
                            </button>
                        </div>
                        <div className="modal-body px-4 pt-0 service-scrl">
                            <div className="my-4">
                                <label className="form-label font-14 font-medium black-v2" htmlFor="serviceName">
                                    Name<span className="required">*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control custom-dropdown shadow-none color-black"
                                    id="serviceName"
                                    name="serviceName"
                                    value={formData.serviceName}
                                    onChange={handleChange}
                                    placeholder="Enter the Service Name"
                                />
                            </div>

                            <label className="font-14 font-medium ms-2 black-v2 service-checkbox">
                                <input
                                    type="checkbox"
                                    name="containService"
                                    className="form-check-input custom-checkbox shadow-none me-2"
                                    checked={formData.containService}
                                    onChange={handleChange}
                                />
                                Contains Service?
                            </label>

                            <div className="col-md-3 mb-4">
                            <div className="position-relative">
    <div className="d-flex align-items-center justify-content-between" style={{ paddingTop: '30px' }}>
        <label className="form-label font-14 font-medium color-black" htmlFor="serviceIcon"
            style={{
                whiteSpace: 'nowrap',
                marginRight: '8px',
            }}
        >
            Upload Service Icon<span className="required">*</span>
        </label>
        <button
            type="button"
            className="btn p-0 ms-1"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Kindly add updated icons with proper format - sample text need to add by dev team"
            style={{ marginLeft: '8px', marginTop:'-12px'
            }}
        >
            <img
                src="images/tooltip-icon.svg"
                alt="tooltip-icon"
                className="tooltip-icon"
            />
        </button>
    </div>
    <input
        type="file"
        className="form-control custom-dropdown arch-border shadow-none font-14 font-medium black-v2"
        id="serviceIcon"
        name="serviceIcon"
        onChange={handleFileChange}
        aria-label="upload"
    />
</div>


                                {formData.fileName && (
                                    <div className="mt-2 font-14 font-medium black-v2" style={{ whiteSpace: 'nowrap' }}>{formData.fileName}</div>
                                    
                                )}
                            </div>

                            <div className="col-md-8 mb-5">
                                <label className="form-label font-14 font-medium black-v2" htmlFor="description">
                                    Description <span className="required">*</span>
                                </label>
                                <textarea
                                    className="h-100 form-control custom-form custom-textarea font-14 font-medium"
                                    placeholder="Enter Description"
                                    id="description"
                                    name="description"
                                    value={formData.description}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="d-flex justify-content-end align-items-center mt-4 mb-2 gap-3">
                                <button
                                    type="button"
                                    className="button outline-button text-nowrap py-12"
                                    data-bs-dismiss="modal"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="button primary-button text-nowrap px-4 py-12"
                                    onClick={handleSubmit}
                                    // disabled={!fileUpdate}

                                    disabled={
                                        formData.serviceName === "" || 
                                        // formData.containService === "" || 
                                        formData.description === "" || 
                                        formData.serviceIcon == null || 
                                        formData.file === ""
                                        // !fileUpdate
                                    }
                                >
                                    {editMode ? 'Update' : 'Import'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


export default AddServicepopup;