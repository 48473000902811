//PS_01
import React, { useState, useEffect ,useContext} from 'react';
//PS_02
import DeleteConfirmationModal from '../Organization/Delete_Popup'; // Import the Delete Confirmation Modal
//PS_03
import { useNavigate } from 'react-router-dom';
//PS_04
import { deleteRulesApi ,getAllRuleCategoryApi, getAllRulesApi2} from '../../Service/api';
import { OrgContext } from '../../ContextProvider/OrgContext';
import Loader from '../loader/loader';

//PS_07
const RulebookGrid = () => {
    //PS_08 state variables
    const [categories, setCategories] = useState([]);
    const [ruleCategories, setRuleCategories] = useState([]);
//PS_09
    const [rules, setRules] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    //PS_10
    const [ruleToDelete, setRuleToDelete] = useState(null);
    //PS_11
    const [ruleToEdit, setRuleToEdit] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [loadMoreOffset, setLoadMoreOffset] = useState(0); 
    const [activeTab, setActiveTab] = useState(null);

    const [totalCount,setTotalCount] = useState(0)

    const {
        userRoles,
        providerId,
        architectures,
        canCreateWorkload,
        canDeleteWorkload,
        canViewWorkload,
        setuserRoles,
    
        // Add organization and provider fields here
        providerName,       
        orgData,           
        setOrgData, 
        setOrgId,       
        setArchitectures,
    
        envId,             // Environment ID
        workloadId,        // Workload ID
        aiArchitecture,    
        viewMode,          
        envName,           
        language,          
        archType,          
        business,          
        rulebook,          
        archDesc,          
        services,
    
        lastBuildID,       
        lastBuildDate,     
        lastBuildMessage,
    
        repoId,            
        repoName,
    
        PulseOrganization_id,
        PulseVertical_id,
        PulseVertical_name,
        PulseOrganization_name,
        PulseIdentification,
    
        setEnvId           // Function to update environment ID
    } = useContext(OrgContext);

    const [userId, setuserId] = useState(localStorage.getItem('userId'));

//PS_13
    const navigate =useNavigate();
    //PS_14
    useEffect(() => {
        // Simulate fetching categories and rules data
        loadRuleCategories()
        loadAllRules()
        // setCategories(mockCategories);
        // setRules(mockRules.slice(0, loadMoreOffset));
    }, [selectedCategory,loadMoreOffset]);

    // PS_15 Function to handle category selection
    const handleCategorySelection = (event) => {
        setSelectedCategory(event.target.value);
        // Filter rules based on selected category
        // const filteredRules = mockRules.filter(rule => rule.categoryId === event.target.value);
        // setRules(filteredRules.slice(0, loadMoreOffset));
    };
console.log(selectedCategory);
    //PS_16 Function to handle delete action
    const handleDelete = (rule) => {
        setRuleToDelete(rule);
        setShowDeleteModal(true);
    };

    //PS_17 Function to confirm deletion
    const confirmDelete = async() => {
        if (ruleToDelete) {
            const payload = {
                userId: userId,
                ruleId:  ruleToDelete.ruleId// Rule ID from state
            };
            //PS_18
            try {
                const response = await deleteRulesApi(payload); 
                
                debugger;// Call the API function
                if (response.statusCode === 200) {
                    debugger;
                    
                    await loadAllRules()
                   
                } else {
                    console.error('Error deleting rule');
                    
                }
            } catch (error) {
                console.error('Failed to delete rule:', error);
            }
            //PS_19
            setShowDeleteModal(false); // Close modal
            
        }
    };
//PS_20
    const loadRuleCategories = async () => {
        const payload = {
            userId: userId,
        };
//PS_21
        try {
            const response = await getAllRuleCategoryApi(payload); // Call the API function
            if (response.success) {
                console.log('Rule categories fetched successfully:', response.data);
                setCategories(response.data); // Update state with fetched rule categories

               
            } else {
                console.error('Error fetching rule categories');
            }
        } catch (error) {
            console.error('An error occurred while fetching rule categories:', error);
        }
    };
//PS_23
    const loadAllRules = async () => {
        const payload = {
            userId: userId,
            categoryId: selectedCategory,
            loadmore: loadMoreOffset
        };
//PS_24
        try {
            const response = await getAllRulesApi2(payload); // Call the API function
            if (response.body.success) {
                console.log("rulee1");

                setRules(response.body.data); // Update state with fetched rules
                // console.log("rulee");
                // console.log(response.body.data);
                setTotalCount(response.body.rowcount)

               


            } else {
                console.error('Error fetching rules');
            }
        } catch (error) {
            console.error('An error occurred while fetching rules:', error);
        }finally{
            setLoading(false)
        }
    };
    const [isAdmin, setisAdmin] = useState(localStorage.getItem('isAdmin')=="true"?true:false);


    // PS_26 Function to handle edit action
    const handleEdit = (rule) => {
        setRuleToEdit(rule);
        setIsEditing(true); 
        navigate('/addRule', { state: { rule: rule } });
    };
//PS_27
const handleLoadMore = () => {
    setLoadMoreOffset(prev => prev + 10); // Increase visible records by 10
};
const totalRecords = Object.keys(rules).reduce((total, categoryName) => {
    return total + rules[categoryName].length; // Sum up the lengths of each category's rules
}, 0);
    const [theme, setTheme] = useState('dark');
    const [loading, setLoading] = useState(true);

    if (loading) {
        return <Loader />; // Show loader while loading
    }
    return (
        
        <div className="container-fluid custom-container">
        {/* Slide title start here */}
        <div className="d-flex justify-content-between align-items-center pt-4 mt-2">
            <h2 className="font-20 font-bold black-v2 mb-0">Rulebook</h2>
            <div className="d-flex gap-3">
                {/* Dropdown */}
                <div className="input-group title-dropdown">
                    <select 
                        name="category" 
                        id="select-category" 
                        className="form-select" 
                        style={{ width: '200px' }} // Set width of dropdown
                        onChange={(e) => {
                            handleCategorySelection(e);
                            setActiveTab(e.target.value);
                        }}
                    >
                        <option value="">Select Category</option>
                        {categories.map(category => (
                            <option key={category.ruleCategoryId} value={category.ruleCategoryId}>
                                {category.ruleCategoryName}
                            </option>
                        ))}
                    </select>
                </div>
                {/* Add Rule Button */}
               {isAdmin &&<button type="button" className="button primary-button text-nowrap" onClick={() => navigate('/addRule')}>
                    <img src="images/add-icon.svg" alt="add-icon" className="me-2"/>Add Rule
                </button>}
            </div>
        </div>
        {/* Slide title end here */}
    
        {/* Page Content Start here */}
        {/* Table Start */}
        <div className="table-responsive mt-4 table-border rulebook-table-scroll">
            <table className="table custom-table">
                <thead>
                    <tr>
                        <th className="text-start border-0">Category</th>
                        <th className="text-start border-0">Rules</th>
                        <th className="text-center border-0">Actions</th>
                    </tr>
                </thead>

                <tbody>
                {activeTab ? (
    // Get the rules for the active tab
    rules[categories.find(category => category.ruleCategoryId === activeTab)?.ruleCategoryName]?.length > 0 ? (
        rules[categories.find(category => category.ruleCategoryId === activeTab)?.ruleCategoryName].map(rule => (
            <tr key={rule.ruleId}>
                <td className="text-start">{rule.categoryName}</td>
                <td className="text-start">{rule.rule}</td> 
                <td className="text-nowrap text-center">
                    <div className="d-flex align-items-center justify-content-center gap-3 me-1">
                        {isAdmin&&<button 
                            className="btn action-btn edit-action d-flex align-items-center justify-content-center border-0" 
                            type="button" 
                            onClick={() => handleEdit(rule)}
                        >
                            <img src="images/edit-icon-blue.svg" alt="edit-icon-blue"/>
                        </button>}
                        
                        {isAdmin && (
                            <button 
                                className="btn action-btn delete-action d-flex align-items-center justify-content-center border-0" 
                                type="button" 
                                onClick={() => {
                                    setRuleToDelete(rule); // Set the rule to delete
                                    setShowDeleteModal(true); // Show delete confirmation modal
                                }}
                            >
                                <img src="images/bin-icon-red.svg" alt="bin-icon-red"/>
                            </button>
                        )}
                    </div>
                </td>
            </tr>
        ))
    ) : (
        // No data found message for activeTab
        <tr>
            <td colSpan={3} className="text-center">
                <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: '100px' }}>
                    <p className="font-medium font-12 text-center">
                        No rules found for this category.
                    </p>
                </div>
            </td>
        </tr>
    )
) : (
    // If activeTab is not set, show all rules
    Object.keys(rules).length > 0 ? (
        Object.keys(rules).map(categoryName => (
            rules[categoryName].map(rule => (
                <tr key={rule.ruleId}>
                    <td className="text-start">{rule.categoryName}</td> {/* Displaying Category Name */}
                    <td className="text-start">{rule.rule}</td> {/* Displaying Rule Description */}
                    <td className="text-nowrap text-center">
                        <div className="d-flex align-items-center justify-content-center gap-3 me-1">
                          {isAdmin&&  <button 
                                className="btn action-btn edit-action d-flex align-items-center justify-content-center border-0" 
                                type="button" 
                                onClick={() => handleEdit(rule)}
                            >
                                <img src="images/edit-icon-blue.svg" alt="edit-icon-blue"/>
                            </button>}

                            {isAdmin && (
                                <button 
                                    className="btn action-btn delete-action d-flex align-items-center justify-content-center border-0" 
                                    type="button" 
                                    onClick={() => {
                                        setRuleToDelete(rule); // Set the rule to delete
                                        setShowDeleteModal(true); // Show delete confirmation modal
                                    }}
                                >
                                    <img src="images/bin-icon-red.svg" alt="bin-icon-red"/>
                                </button>
                            )}
                        </div>
                    </td>
                </tr>
            ))
        ))
    ) : (
        // No data found message for all rules
        <tr>
        <td colSpan={7} className="text-center"> {/* Adjust colSpan based on the number of columns */}
            <div className="d-flex flex-column justify-content-center align-items-center custom-height">
                <img src="images/nodata-found.svg" alt="No Data Found" />
                <h6 className="color-black font-bold font-16 mt-5 mb-0">No Data Found!</h6>
                <p className="font-medium font-12 mt-4 mb-0 secondary-text-color-v5">
                </p>
            </div>
        </td>
    </tr>
    )
)}
</tbody>
            </table>
    
            {/* Load More Button */}
            
        </div>
    

        {showDeleteModal && (
            <DeleteConfirmationModal 
                item={ruleToDelete} 
                itemType={"rule"} 
                onDeleteConfirm={confirmDelete} 
                onClose={() => setShowDeleteModal(false)} 
            />
        )}
    
        {/* Records Count Display */}
        <div className="d-flex justify-content-between align-items-center my-3">
        <p className="font-14 font-regular secondary-text-color mb-0">
            # of Records 
            <span className="font16 font-medium color-black"> 
                {totalRecords} out of {totalRecords}
            </span>
        </p>
    </div>
        {  (totalCount > (loadMoreOffset + 10) ) && 
                <div className="text-center mt-3">
                     <button type="button" className="button secondary-outline-button black-v2" onClick={handleLoadMore}>Load More</button>

                </div>
            }
           </div>
    );
};

export default RulebookGrid;