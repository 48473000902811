//SQ_NO_1.0 import neccesary modules, components
import React, { useState, useEffect, useContext } from 'react';
import MonacoEditor from 'react-monaco-editor';
import { getAllServiceReviewApi, getAllFilesAndContentsApi, saveEditedCodeApi, generateMainCodeApi } from '../../../../Service/api';
import { OrgContext } from '../../../../ContextProvider/OrgContext';
import ConfirmationModal from '../Main_file_generate_popup';
import Loader from '../../../loader/loader';
// Mock API functions
// const getAllServiceReview = () => {
//     return Promise.resolve({
//         statusCode: 200,
//         body: {
//             accounts: [
//                 { accountId: "809ee6d5-e5ea-4a45-a377-8be51c7872a2", accountName: "sagincreds" }
//             ]
//         }

//     });
// };

// const getAllFilesAndContents = (accountId) => {
//     return Promise.resolve({
//         statusCode: 200,
//         data: {
//             files: [
//                 { id: "file1", name: "var", content: "// Code for File One" },
//                 { id: "file2", name: "tfvar", content: "// Code for File Two" }
//             ],
//             contents: []
//         }
//     });
// };
const RightSidebar = ({ onClose,showLoader,setShowLoader,updateAccountDetails,  toastMsg,setToastMsg }) => {
//SQ_NO_1.1-1.7 - initialize state variables and import useOrgContext
const [accounts, setAccounts] = useState([]);
    const [files, setFiles] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [sourceCode, setSourceCode] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [editModechecked, setEditModechecked] = useState(false);
    const [loading, setLoading] = useState(false);
    

    const {
        userRoles,
        orgId,
        providerId,
        architectures,
        canCreateWorkload,
        canDeleteWorkload,
        canViewWorkload,
        setuserRoles,
        setRepository_id, setrepository_name, setPulseIdentification,

        setworkloadId, setEnvName, setUTI,

        // Add organization and provider fields here
        providerName,
        orgData, isProjectAdmin, setIsProjectAdmin,
        setOrgData,
        setArchitectures,

        envId,             // Environment ID
        workloadId,        // Workload ID
        aiArchitecture,
        viewMode,
        envName,
        language,
        archType,
        business,
        rulebook,
        archDesc,
        services,

        lastBuildID,
        lastBuildDate,
        lastBuildMessage,

        repoId,
        repoName,

        PulseOrganization_id,
        PulseVertical_id,
        PulseVertical_name,
        PulseOrganization_name,
        PulseIdentification, setproviderId, setProviderName
        ,

        setEnvId           // Function to update environment ID
    } = useContext(OrgContext);
    const [userId, setuserId] = useState(localStorage.getItem('userId'));
    const darkTheme = localStorage.getItem('darkTheme')

//SQ_NO_1.9 - to fetch accounts
useEffect(() => {
        fetchAccounts();
    }, []);

    const fetchAccounts = async () => {
        try {
            const payload = {
                organizationId: orgId, // Replace with dynamic value if needed
                envId: envId, // Replace with dynamic value if needed
                userId: userId, // Replace with dynamic value if needed
                "accountId": ""
            }
            const response = await getAllServiceReviewApi(payload);
            console.log(response , "sdfasse getAllServiceReviewApi")
            //SQ_NO_1.27
            if (response.body.success) {
                //SQ_NO_1.29
                setAccounts(response.body.accounts);
                updateAccountDetails(response.body.accounts)
                
            }
        } catch (error) {
            console.error("Error fetching accounts", error);
        }
    };
//SQ_NO_2.1
    const handleAccountSelect = async (accountId) => {
        setSelectedAccount(accountId);
        await fetchFiles(accountId); // Fetch files when an account is selected
    };
//SQ_NO_2.3
    const fetchFiles = async (accountId) => {
        try {
            const payload = {
                organizationId: orgId, // Replace with dynamic value if needed
                envId: envId, // Replace with dynamic value if needed
                userId: userId, // Replace with dynamic value if needed
                "accountId": accountId // Replace with dynamic value if needed
            };
//SQ_NO_2.4
            const response = await getAllFilesAndContentsApi(payload);
            if (response.body.success) {
              //SQ_NO_2.21
               let Allfiles = response.body.data.files.map((val, index) => {
                    if (val.name === "variable.tf" || val.name === "terraform.tfvars") {
                      val.code = val.code.map((val1, index) => {
                        const moduleMatch = val1.code.match(/variable\s*["']?([\w_]+)["']?\s*\{/)
                        if (moduleMatch) {
                          val1.module = moduleMatch[1].trim();
                        } else {
                          const varMatch = val1.code.match(/(\w+)\s*=/);
                          if (varMatch) {
                            val1.module = varMatch[1].trim();
                          }
                        }
                        return val1;
                      });
                    }
                    return val;
                  });
                  console.log(Allfiles , "Filessss")
                  setFiles(Allfiles);
                if (response.body.data.files.length > 0) {
                    // setSelectedFile(response.body.data.files[0].id); // Select the first file by default
                    // setSourceCode(response.body.data.files[0].code); // Set initial source code
                }
            }else{
                setToastMsg({ show: true, message:response?.body?.message, success: false });
  
            }
        } catch (error) {
            setToastMsg({ show: true, message:error?.response?.data?.body?.message, success: false });
            console.error("Error fetching files", error);
        }
    };
//PS_61
    const generateMainFile = async (fileId) => {
        try {
            const payload = {
                organizationId: orgId, // Replace with dynamic value if needed
                envId: envId, // Replace with dynamic value if needed
                userId: userId, // Replace with dynamic value if needed
                "accountId": selectedAccount, // Replace with dynamic value if needed
                contents: files
            };
            console.log("payload",payload);
            setShowLoader(true)

            const response = await generateMainCodeApi(payload);
            //SQ_NO_3.19
            if (response.body.success) {
                setIsModalOpen(false); // Close modal after proceeding
//SQ_NO_3.22
setToastMsg({ show: true, message: 'Main file generated successfully', success: true });

                setFiles(response.body.data.files);
                const matchedFile = response.body.data.files.find(file => file.id === fileId);
                if (matchedFile) {
                    setSelectedFile(fileId); // Select the matched file
                    setSourceCode(matchedFile.code); // Set initial source code with matched file code
                    setShowLoader(false)
                }
            }
        } catch (error) {
            setToastMsg({ show: true, message:error?.response?.data?.body?.message, success: false });

            console.error("Error fetching files", error);
        }
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
//SQ_NO_2.21
    const handleFileSelect = (fileId) => {
        const selectedFileData = files.find(file => file.id === fileId);
        if (selectedFileData) {
            //SQ_NO_2.32
            setSelectedFile(fileId);

            ////SQ_NO_2.57 Special handling for 'main.tf'
            if (fileId === 'main.tf') {
                setIsModalOpen(true);
                setSourceCode(selectedFileData.code); 
                // const generatedCode = generateMainFile(fileId);
                // setSourceCode(generatedCode);
            } else {
                // Check if the selected file's code is an array (for variable.tf)
                if (Array.isArray(selectedFileData.code)) {
                    // Concatenate all code blocks in the array
                    const combinedCode = selectedFileData.code.map(codeBlock => codeBlock.code).join('\n');
                
                    let finalCode = combinedCode;
                
                    // Check if fileId is "local.tf"
                    if (fileId === "local.tf") {
                      // Add "local = {" at the start and "}" at the end
                      finalCode = `local = {\n${combinedCode}\n}`;
                      console.log("finalCode",finalCode);

                    }
                
                    // Update source code with the final content
                    setSourceCode(finalCode);
                  }
                 else {
                    // If it's not an array, assume it's a single string (for other file types)
                    setSourceCode(selectedFileData.code); // Update source code with selected file content
                }
            }

            // Enable edit mode only if the selected file's name is var or tfvar
            if (['variable.tf', 'terraform.tfvars','local.tf','main.tf'].includes(selectedFileData.name)) {
                setEditMode(true);
                setEditModechecked(false)

                // setEditModechecked(true)
            } else {
                setEditMode(false);
                setEditModechecked(false)
            }
        }
    };

    //SQ_NO_3.0
    const handleProceed = () => {

        ////SQ_NO_3.1 Call generateMainFile function when user clicks "Proceed"
        const generatedCode = generateMainFile(selectedFile);
        // setSourceCode(generatedCode);
    };

    const toggleEditMode = () => {
        setEditModechecked(!editModechecked); // Toggle edit mode
    };
    
    
    
    
    //SQ_NO_2.48 
    const handleLocalsTfFile = (selectedFile, newCode) => {
        console.log("resourcesArray2");

        const startPattern = /^local\s*=\s*{\s*/;  // Pattern to match 'local = {'
        const endPattern = /\s*}\s*$/;  // Pattern to match '}'
      
        let finalCode = newCode;
      
        // Check if the code starts and ends with the patterns for 'local = {' and '}'
        if (startPattern.test(newCode) && endPattern.test(newCode)) {
          // Remove the start pattern 'local = {' and end pattern '}'
          finalCode = newCode.replace(startPattern, '').replace(endPattern, '');
        }
      console.log("finalCodefinalCode",finalCode);
      
        // Send the final code to parseTflocalsvarsFile
        const resourcesArray = parseTflocalsvarsFile(finalCode);
        console.log("resourcesArray2",resourcesArray);
        
        return resourcesArray;
      }
      
    
//SQ_NO_2.39    - to update onchange values
    const handleCodeChange = (newCode, index) => {
        console.log(files, "Files");
        console.log(selectedFile, "Selected File");
        console.log(newCode, "New Code");
    //SQ_NO_2.53
        setSourceCode(newCode); // Update source code on editor change
    
        // Determine which parsing function to use based on the file name
        let resourcesArray;
        //SQ_NO_2.40 selectedFile.endsWith('.tfvars') invoke parseTfvarsFile(newCode)
        if (selectedFile.endsWith('.tfvars')) {
            console.log("resourcesArrayresourcesArray",newCode);

            resourcesArray = parseTfvarsFile(newCode);
            console.log("resourcesArrayresourcesArray2",resourcesArray);
            
        } else if(selectedFile.startsWith('main.tf')){
            resourcesArray=newCode
            console.log("newCodenewCode",newCode);
            
        }
        //SQ_NO_2.48 selectedFile.startsWith('local.tf') invoke handleLocalsTfFile(selectedFile, newCode)
        else if (selectedFile.startsWith('local.tf')) {
            console.log("yess here");
            
            resourcesArray= handleLocalsTfFile(selectedFile, newCode);  
              } //SQ_NO_2.44 selectedFile.endsWith('.tf') invoke parseTfFile(newCode)
              else if (selectedFile.endsWith('.tf')) {
                console.log("yess here2");
                console.log("yess here22",newCode);
debugger
            // Assuming 'variable.tf' and other .tf files should use parseTfFile
            resourcesArray = parseTfFile(newCode);
            console.log(resourcesArray, "resourcesArray");

        }  
        else {
            console.error('Unknown file type');
            return; // Early exit if the file type is unknown
        } 
        debugger
    //SQ_NO_2.54 update Files state
        if (resourcesArray?.length > 0) {
            setFiles(prevFiles =>
                prevFiles.map(file => {
                 
                     if (file.id === selectedFile && file.id === "main.tf") {
                        return {
                            ...file,
                            code:resourcesArray
                        };
                    } else    if (file.id === selectedFile) {
                        console.log("Updating file:", file.name);
debugger
                        return {
                            ...file,
                            code: file.code.map((codeObj) => {
                                const resource = resourcesArray?.find(res => res.module === codeObj.module);
                                console.log("Updating file:2",  resource?.code);

                                if (resource) {
                                    return {    
                                        ...codeObj,
                                        code: resource.code,
                                    };
                                }
                                return codeObj;
                            })
                        };
                    }
                    return file;
                })
            );
        }
    };
    
    console.log("filesfiles",files);
    
    const parseTflocalsvarsFile = (code) => {
        // //SQ_NO_2.49 Updated regex pattern to capture all relevant structures
        const blockPattern = /([a-zA-Z_0-9]+)\s*=\s*({[^}]*}|{[\s\S]*?}|\[[^\]]*]|\[[\s\S]*\]|\S+)/g;
        
        let match;
        let resourcesArray = [];
    
        while ((match = blockPattern.exec(code)) !== null) {
            const variableName = match[1];
            const variableValue = match[2].trim();
    
            let source = 'terraform-aws-modules/vpc/aws';  // Default value
            let version = { custom: true, version: '5.17.0' };  // Default value
    
            resourcesArray.push({
                module: variableName.trim(),
                code: variableValue,
                source,
                version
            });
        }
    
        return resourcesArray;
    };
    
    // Function to parse `.tf` files

    const parseTfFile = (newCode) => {
        console.log("newCode", newCode);

        return newCode.split(/(?<=\})\s*\n+/)  // Split on closing brace followed by optional spaces and a newline
            .map((codeBlock) => {
                console.log("codeBlock", codeBlock);

                // Regex to match variable blocks and capture the variable name
                const variableMatch = codeBlock.match(/variable\s*["']?([\w_]+)["']?\s*\{/);
                let moduleName = "";
                if (variableMatch) {
                    moduleName = variableMatch[1].trim();
                }

                let source = "terraform-aws-modules/vpc/aws"; // Default source
                let version = { custom: true, version: '5.17.0' }; // Default version
                
                if (moduleName) {
                    // Get module details from existing files state (if available)
                    const existingFile = files.find(file => file.name === selectedFile);
                    if (existingFile) {
                        const matchedCodeObj = existingFile.code.find(codeObj => codeObj.module === moduleName);
                        if (matchedCodeObj) {
                            source = matchedCodeObj.source || source;
                            version = matchedCodeObj.version || version;
                        }
                    }

                    return {
                        module: moduleName,
                        source,
                        version,
                        code: codeBlock.trim(),
                    };
                }
                return null;
            })
            .filter(Boolean);
    };


    
    
    // Function to parse `.tfvars` files correctly
    const parseTfvarsFile = (newCode) => {
        const blockPattern = /(\w+)\s*=\s*(\{[\s\S]*?\}|\[[\s\S]*?\])/g;
        let match;
        let resourcesArray = [];
    
        while ((match = blockPattern.exec(newCode)) !== null) {
            const moduleName = match[1].trim();
            const codeBlock = match[0].trim();  // The entire match is the code block
        
            const defaultSource = 'terraform-aws-modules/vpc/aws';
            const defaultVersion = { custom: true, version: '5.17.0' };
        
            // Add the parsed resource to the resources array
            resourcesArray.push({
                module: moduleName,
                source: defaultSource,
                version: defaultVersion,
                code: codeBlock
            });
        }
    
        return resourcesArray;
    };

    // const parselocalsTFFile = (code) => {
    //     // Updated regex pattern to capture all relevant structures
    //     const blockPattern = /([a-zA-Z_0-9]+)\s*=\s*({[^}]*}|{[\s\S]*?}|\[[^\]]*]|\[[\s\S]*\]|\S+)/g;
        
    //     let match;
    //     let resourcesArray = [];
    
    //     while ((match = blockPattern.exec(code)) !== null) {
    //         const variableName = match[1];
    //         const variableValue = match[2].trim();
    
    //         let source = 'terraform-aws-modules/vpc/aws';  // Default value
    //         let version = { custom: true, version: '5.17.0' };  // Default value
    
    //         resourcesArray.push({
    //             name: variableName.trim(),
    //             code: variableValue,
    //             source,
    //             version
    //         });
    //     }
    
    //     return resourcesArray;
    // };
    



    useEffect(() => {
        console.log("files", files);
    }, [files])
////SQ_NO_4.0
    const handleSubmit = async () => {
        // if (editModechecked) {
        //     const dataToSubmit = prepareDataForSubmit();
        //     console.log("Data to submit to backend:", dataToSubmit);
        //     var editedContents = dataToSubmit
        // }
        // You can now send this data to your backend
        // fetch('/api/submit', { method: 'POST', body: JSON.stringify(dataToSubmit) });
        // const contents=JSON.stringify(files, null, 2)
        const payload = {
            organizationId: orgId, // Replace with dynamic value if needed
            envId: envId, // Replace with dynamic value if needed
            userId: userId, // Replace with dynamic value if needed
            accountId: selectedAccount, // Replace with dynamic value if needed
            contents:  files // This should be an array of objects with id and code
        };
        try {
            if(payload.accountId){

            // Call your API to save edited code
            const response = await saveEditedCodeApi(payload);
            //SQ_NO_4.18
            if (response.body.success) {
                console.log("Edited code saved successfully");

                setToastMsg({ show: true, message: response?.body?.message, success: true });

                // You can update state or perform any other actions as necessary
            } else {
                console.error('Error saving edited code:', response.message);
                // Handle error response (e.g., show a notification)
            }

        }
        } catch (error) {
            console.log("errror",error);
            
            setToastMsg({ show: true, message:error?.response?.data?.body?.message, success: false });

            console.error('An error occurred while saving edited code:', error);
            // Handle error response (e.g., show a notification)
        } finally {
            onClose();

        }
    
    };

    // Prepare the data for submission - Parse combined code back into individual resources
    const prepareDataForSubmit = () => {

        return files.map(file => {
            // Check if file name is tfvars or variable.tf
            if (file.name === 'terraform.tfvars' || file.name === 'variable.tf') {
                console.log(file.name, "Name")
                return file.code.map(codeObj => {
                    if (typeof codeObj.code === 'string') {
                        // Perform regex test for var or tfvar files
                        const resourcesArray = codeObj.code.split(/(?<=\})\s*\n+/).map((codeBlock) => {
                            let moduleMatch;
                            if (file.name === 'terraform.tfvars') {
                                moduleMatch = codeBlock.match(/(\w+)\s*=\s*\{/); // Regex for tfvar files
                            } else if (file.name === 'variable.tf') {
                                moduleMatch = codeBlock.match(/variable\s+"(\w+)"\s*\{/); // Regex for var files
                            }

                            if (moduleMatch) {
                                return {
                                    ...codeObj, // Return the original structure with modifications if necessary
                                    code: codeBlock.trim()
                                };
                            }
                            return null;
                        }).filter(Boolean);

                        return resourcesArray;
                    }
                    return null;
                }).flat();
            } else {
                // Return other files with their original structure
                return [file];
            }
        }).flat().filter(Boolean); // Flatten and filter out any null values
    };


    // if (loading) {
    //     return <Loader />; // Show loader while loading
    // } 

    return (
        <>
       
        <div className="col-md-4 mt-3 ps-1 pe-3 content-right-container">
            <div className="recommended-container pe-3 py-3">
                <div className="d-flex justify-content-between align-items-center">
                    <h2 className="font-20 font-bold mb-0 color-black">Codespace</h2>
                    <a href="#" onClick={handleSubmit}>


                        <img src="images/close-icon.svg" alt="close-icon" className="icon-custom-filter" />
                    </a>
                </div>
                <div className="d-flex flex-column pt-4 h-100">
                    <div className="mb-3">
                        <label className="form-label font-14 font-medium black-v2" htmlFor="account">Account</label>
                        <select
                            className="form-select custom-dropdown arch-border shadow-none custom-border-box font-14 font-regular black-v2 custom-select"
                            aria-label="Default select example"
                            id="account"
                            onChange={(e) => handleAccountSelect(e.target.value)}
                        >
                            <option value="">Select</option>
                            {accounts.map(account => (
                                <option key={account.accountId} value={account.accountId}>
                                    {account.accountName}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-4">
                        <label className="form-label font-14 font-medium black-v2" htmlFor="file">File</label>
                        <select
                            className="form-select custom-dropdown arch-border shadow-none custom-border-box font-14 font-regular black-v2 custom-select"
                            aria-label="Default select example"
                            id="file"
                            onChange={(e) => handleFileSelect(e.target.value)}
                        >
                            <option value="">Select</option>
                            {files.map(file => (
                                <option key={file.id} value={file.id}>
                                    {file.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-2 w-100 flex-1">
                        {/* Source Code and Edit Mode Toggle */}
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <p className="font-14 font-medium black-v2 mb-0">Source Code</p>
                            <div className="form-check form-switch d-flex">
                                <label
                                    className="form-check-label font-12 font-regular black-v2 mb-0 switch-left-label" style={{ paddingRight: "45px" }}
                                    htmlFor="flexSwitchCheckChecked-2"
                                >
                                    Edit Mode
                                </label>
                                <input
                                    className="form-check-input custom-switcher mt-0"
                                    type="checkbox"
                                    role="switch"
                                    id="flexSwitchCheckChecked-2"
                                    checked={editModechecked}
                                    onChange={toggleEditMode}
                                    disabled={!editMode}
                                />
                            </div>
                        </div>
                        
                        <div className="generate-code-container mb-2 w-100 flex-1" style={{height:'0%'}}>
                            {/* Monaco Editor */}
                            <MonacoEditor
                                width="100%"
                                height="320"    
                                language="javascript" // Change according to your needs
                                value={sourceCode}
                                onChange={handleCodeChange}
                                options={{ readOnly: !editModechecked }}
                                theme={darkTheme == "true" ? 'vs-dark' : 'vs-light'}

                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {isModalOpen&&
        <ConfirmationModal 
        isOpen={isModalOpen} 
        onClose={() => {setIsModalOpen(false);}} 
        message="Credentials are invalid" 
        onProceed={handleProceed} // Pass proceed handler
    />}
        </>
    );
};

export default RightSidebar;
