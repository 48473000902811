
import React from 'react';

const WorkbenchLoader = ({ isLoading }) => {
    return (
        <div className="modal fade show loader-overlay" style={{
            display: isLoading ? 'block' : 'block',
            backgroundColor: 'rgba(0,0,0,0.5)',
            position: 'fixed', 
            top: 0, 
            left: 0, 
            right: 0, 
            bottom: 0, 
            zIndex: 11000 // Make sure this is the highest z-index
        }}
            data-bs-backdrop="static" 
            data-bs-keyboard="false" 
            tabIndex="-1"
            aria-labelledby="loaderLabel" 
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered justify-content-center">
                <div className="bg-transparent">
                    <div className="modal-body d-flex align-items-center justify-content-center">
                        <img src="images/Dots Loader 1.gif" alt="Loading..." className="loader-width" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WorkbenchLoader;

