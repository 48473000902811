import React, { useContext, useEffect, useState } from "react";

import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Line, ComposedChart, } from 'recharts';
import { fetchOverViewCardData, fetchUtilizationGrid, fetchWorkspace } from "../../Service/api";
import { fetchAllOrganization } from "../../Service/api";
import { filter } from "jszip";
import { OrgContext } from "../../ContextProvider/OrgContext";
import { DatabricksContext } from "./Databricks";

function DbuUtilizationGrid({ isExporting }) {

  // State Declaration
  const { cardData, setCardData } = useContext(DatabricksContext);

  //PS_01
  const { gridData, setGridData } = useContext(DatabricksContext)
  const [error, setError] = useState(null);
  const [expandedItems, setExpandedItems] = useState({});
  const [organizationData, setOrganizationData] = useState([])
  const [workspaceData, setWorkspaceData] = useState([])
  const [chartData, setChartData] = useState([]);
  const [search, setSearch] = useState("");
  const [noRecordsMsg, setNoRecordsMsg] = useState("No Records Found");
  let filterObj = {
    clientId: '',
    workspaceId: '',
    targetStatus: '',
    peroid: 'monthly',
    startDate: '',
    endDate: ''
  }
  // const [sortOrder, setSortOrder] = useState("desc");
  // const [sortColumnName, setSortColumnName] = useState("commitments");
  const [sortOrder, setSortOrder] = useState('ASC');
  const [sortColumnName, setSortColumnName] = useState('client');

  const [filterData, setFilterData] = useState(filterObj)
  const [totalActivityLogCount, setTotalActivityLogCount] = useState(0);
  const [loadCount, setLoadCount] = useState(10);
  const userId = localStorage.getItem('userId');
  console.log("userid", userId)

  const { orgId } = useContext(OrgContext)

  //PS_05
  useEffect(() => {
    getUtilizationGridData(filterData, search);
  }, [search, sortOrder, sortColumnName, loadCount]);

  //PS_86
  useEffect(() => {
    getOrganizationData()
    getWorkspaceData()
  }, [])


  // PS_06 - PS_20
  const getUtilizationGridData = async (filterData = filterObj, searchData) => {
    const userDetails = {
      "organizationId": orgId,
      "userId": userId,
      "filter": filterData,
      "sort": {
        "columName": sortColumnName,
        "order": sortOrder
      },
      "search": searchData,
      "loadMore": 10

    }
    try {
      const response = await fetchUtilizationGrid(userDetails);

      if (response.status === 200 && response.data && response.data.body) {
        const { data, success } = response.data.body;

        if (success && data) {
          const { OveralcostDetails, gridData, dateRange } = data;

          // Update your state with the parsed data
          setCardData({
            actualCost: OveralcostDetails.actualCost,
            forecastedCost: OveralcostDetails.forecastedCost,
            commitments: OveralcostDetails.commitments,
            overage: OveralcostDetails.overage,
          });

          //Process chart data
          const processedChartData = processChartData(OveralcostDetails, dateRange);
          setChartData(processedChartData);
          setGridData(gridData);
          setTotalActivityLogCount(data.totalRecords);
        } else {
          setError('No data available or invalid data structure.');
        }
      } else {
        setGridData([])
        setTotalActivityLogCount(0);
        setError('Invalid response status or structure.');
      }
    } catch (error) {
      setError('An error occurred while fetching data');
      console.error('Error in getUtilizationGridData:', error);

      const errorData = {
        errorName: error.name,
        errorServiceName: "Front-end",
        errorfunctionName: "getUtilizationGridData",
        errorSource: "frontend",
        errorDescription: "Couldn't fetch Card Data: " + error.message
      };

      // await postErrorLog(errorData);
    }
  };




  //PS_83 - PS_85
  const toggleExpand = (itemId) => {
    setExpandedItems(prevState => ({
      ...prevState,
      [itemId]: !prevState[itemId]
    }));
  };

  const sortedData = [...gridData].sort((a, b) => {
    if (sortColumnName === 'client') {
      return sortOrder === 'ASC' 
        ? a.clientName.localeCompare(b.clientName) 
        : b.clientName.localeCompare(a.clientName);
    }
    if (sortColumnName === 'commitments') {
      return sortOrder === 'ASC' 
        ? a.bduCommitments - b.bduCommitments 
        : b.bduCommitments - a.bduCommitments;
    }
    if (sortColumnName === 'cost') {
      return sortOrder === 'ASC' 
        ? a.dbuCost - b.dbuCost 
        : b.dbuCost - a.dbuCost;
    }
    if (sortColumnName === 'difference') {
      return sortOrder === 'ASC' 
        ? a.difference - b.difference 
        : b.difference - a.difference;
    }
    return 0;
  });

  // PS_16 - PS_17
  const bindUtilizationGridData = () => {
    if (!sortedData) return null;

    if (isExporting) {
      Object.keys(expandedItems).forEach(key => {
        expandedItems[key] = true;
      });


      sortedData.forEach((client, clientIndex) => {
        client.workspaces.forEach((workspace, workspaceIndex) => {
          expandedItems[`workspace-${clientIndex}-${workspaceIndex}`] = true;
        });
      });
    }

    return sortedData.map((client, clientIndex) => [
      <tr key={`client-${clientIndex}`} className={`accordion-middle ${isExporting ? 'show' : ''}`}>
        <td className="text-center">
          <span className="notify-viewmore" onClick={() => toggleExpand(`client-${clientIndex}`)}>
            <img
              className={`notifications-arrow cursor-pointer table-accordion-arrow ${expandedItems[`client-${clientIndex}`] ? '' : 'collapsed'}`}
              src="images/notifify-right-arrow.svg"
              alt="notifications-arrow"
            />
          </span>
        </td>
        <td className="text-nowrap text-start">{client.clientName}</td>
        <td className="text-nowrap text-end cursor-pointer">${client.bduCommitments}</td>
        <td className="text-nowrap text-end">${client.dbuCost}</td>
        <td className="text-nowrap text-end">${client.difference}</td>
      </tr>,
      <tr key={`client-details-${clientIndex}`} className={isExporting ? '' : expandedItems[`client-${clientIndex}`] ? '' : 'd-none'}>
        <td colSpan={5} className="border-table p-0">
          <span className="table-responsive mt-4 table-border border-0">
            <table className="table custom-table">
              <tbody>
                {client.workspaces && client.workspaces.map((workspace, workspaceIndex) => [
                  <tr key={`workspace-${clientIndex}-${workspaceIndex}`} className="accordion-middle">
                    <td className="text-center width-70px">
                      <span className="notify-viewmore" onClick={() => toggleExpand(`workspace-${clientIndex}-${workspaceIndex}`)}>
                        <img
                          className={`notifications-arrow cursor-pointer table-accordion-arrow ${expandedItems[`workspace-${clientIndex}-${workspaceIndex}`] ? '' : 'collapsed'}`}
                          src="images/notifify-right-arrow.svg"
                          alt="notifications-arrow"
                        />
                      </span>
                    </td>
                    <td className="text-start ps-0 width-325">{workspace.workspaceName}</td>
                    <td className="text-end width-280px">${workspace.bduCommitments}</td>
                    <td className="text-end width-324px">${workspace.dbuCost}</td>
                    <td className="text-end width-324px">${workspace.difference}</td>
                  </tr>,
                  <tr key={`workspace-details-${clientIndex}-${workspaceIndex}`} className={isExporting ? '' : expandedItems[`workspace-${clientIndex}-${workspaceIndex}`] ? '' : 'd-none'}>
                    <td colSpan={5} className="py-0 pe-0 ps-5">
                      <span className="table-responsive mt-4 table-border border-0">
                        <table className="table custom-table">
                          <tbody>
                            {workspace.computes && workspace.computes.map((compute, computeIndex) => (
                              <tr key={`compute-${clientIndex}-${workspaceIndex}-${computeIndex}`}>
                                <td className="text-start custum-table-first-width">{compute.computeName}</td>
                                <td className="text-end width-330px">${compute.bduCommitments}</td>
                                <td className="text-end width-330px">${compute.dbuCost}</td>
                                <td className="text-end width-324px">${compute.difference}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </span>
                    </td>
                  </tr>
                ])}
              </tbody>
            </table>
          </span>
        </td>
      </tr>
    ]);
  };


  //PS_80 -PS_82
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };


  //PS_99 -PS_101
  const processChartData = (overallCostDetails, dateRange) => {
    const sortedDateRange = dateRange.sort((a, b) => new Date(a.time) - new Date(b.time));

    let accumulatedCost = 0;
    return sortedDateRange.map((item, index) => {
      accumulatedCost += item.cost;
      return {
        date: new Date(item.time).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }),
        accumulatedCost: accumulatedCost,
        commitments: overallCostDetails.commitments,
        overage: index === sortedDateRange.length - 1 ? overallCostDetails.overage : 0,
        forecastedCost: overallCostDetails.forecastedCost
      };
    });
  };


  //PS_96 -PS_98
  const getOrganizationData = async () => {
    try {
      const payload = {
        "userId": userId,
        "orgId": orgId
      }

      const response = await fetchAllOrganization(payload); // Replace with your actual API call function
      console.log(response, "response");

      if (response.status === 200) {
        setOrganizationData(response.data.data); // Store the response in a state variable
      } else {
        console.error("Error fetching cloud provider data:", response.data.body.message);
      }
    } catch (error) {
      console.error("API Error:", {
        errorName: error.name,
        errorServiceName: "Front-end",
        errorFunctionName: "getCloudProviderData",
        errorSource: "frontend",
        errorDescription: "fetch failed: " + error.message
      });
    }
  };

  //PS_73- PS_79
  const getWorkspaceData = async () => {
    try {
      const payload = {
        "organizationId": orgId,
        "userId": userId
      }
      const response = await fetchWorkspace(payload); // Replace with your actual API call function
      console.log(response, "workspace response");

      if (response.status === 200) {
        setWorkspaceData(response.data.body.data); // Store the response in a state variable
      } else {
        console.error("Error fetching cloud provider data:", response.data.body.message);
      }
    } catch (error) {
      console.error("API Error:", {
        errorName: error.name,
        errorServiceName: "Front-end",
        errorFunctionName: "getWorkspaceData",
        errorSource: "frontend",
        errorDescription: "fetch failed: " + error.message
      });
    }
  };


  //PS_94 -PS_95
  const searchRecord = (e) => {
    const newValue = e.target.value;
    if (isValidInput(newValue)) {
      setSearch(newValue);
      getUtilizationGridData(newValue);
      setNoRecordsMsg(gridData.length === 0 ? "No Search Record Found" : "");
    }
  };

  // PS_92-PS_93
  const isValidInput = (input) => {
    const regex = /^[a-zA-Z0-9_-]*$/;
    return regex.test(input);
  };

  //PS_91
  // const filterValidation = (e) => {

  //   const { id, value } = e.target;
  //   console.log(id, value )
  //   setFilterData(prevState => ({
  //     ...prevState,
  //     [id]: value
  //   }));
  // };


  const filterValidation = (e) => {
    const { id, value } = e.target;

    if (id === 'startDate' || id === 'endDate') {
      const currentDate = new Date();
      const sixMonthsAgo = new Date(currentDate.setMonth(currentDate.getMonth() - 6));
      const sixMonthsLater = new Date(new Date().setMonth(new Date().getMonth() + 6));

      const inputDate = new Date(value);

      if (inputDate >= sixMonthsAgo && inputDate <= sixMonthsLater) {
        setFilterData(prevState => ({
          ...prevState,
          [id]: value
        }));
      } else {
        // If the date is outside the allowed range, don't update the state
        e.preventDefault();
      }
    } else {
      setFilterData(prevState => ({
        ...prevState,
        [id]: value
      }));
    }
  };





  //PS_90
  const handleApply = async () => {
    // Logic to apply filters

    await getUtilizationGridData(filterData, search);
    console.log('Filters applied:', filterData);
  };

  // PS_89
  const handleClear = () => {
    setFilterData({
      clientId: '',
      workspaceId: '',
      targetStatus: '',
      peroid: '',
      startDate: '',
      endDate: ''
    });

    setOrganizationData([])
    setWorkspaceData([])
    getUtilizationGridData(filterData, search)
  };

  //PS_88
  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split('T')[0]; // Format YYYY-MM-DD
  };


  //PS_87
  const toggleSortOrder = (columnName) => {
    // Toggle between ASC and DESC
    const newSortOrder = sortOrder === "ASC" ? "DESC" : "ASC";
    setSortOrder(newSortOrder);
    setSortColumnName(columnName); // Set the column name dynamically
  };

  return (
    <>
      {/* search and filter start */}
      <div className="d-flex justify-content-between align-items-center my-4">
        <h5 className="m-0 font-16 font-bold black-v3">
          Utilization &amp; Forecasted Cost
        </h5>
        <div className="d-flex align-items-center">
          {/* search */}
          <div className="input-group input-search custom-border-box custom-search-box me-3">
            <input
              type="search"
              className="px-3 form-control common-bg border-0 custom-search $gray-v1"
              placeholder="Search"
              onChange={(e) => { searchRecord(e) }}

            />
            <button
              className="btn border-start-0 custom-search "
              type="button"
              onClick={getUtilizationGridData}
            >
              <img src="images/search-icon.svg" alt="search" />
            </button>
          </div>
          {/* search */}
          {/* filter start */}
          <button
            type="button"
            className="filter-button position-relative p-2"
            id="dropdownMenuClickableInside"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
          >
            <img src="images/filter-blue.svg" alt="filter-icon" />{" "}
          </button>
          {/*filter*/}
          <div
            className="dropdown-menu dropdown-menu-end border-0 custom-shadow dd-position background-white custom-filter-width p-4"
            aria-labelledby="dropdownMenuClickableInside"
          >
            <h5 className="font-bold font-16 mb-0 color-black mb-4">
              Filter
            </h5>
            <div className="row">
              <div className="col-md-6 mb-4">
                <label
                  className="form-label font-14 font-medium black-v2"
                  htmlFor="organization"
                >
                  Client
                </label>
                <select
                  className="form-select custom-dropdown shadow-none custom-border-box py-1 font-14 font-regular black-v2 custom-select"
                  aria-label="Default select example"
                  id="clientId"
                  value={filterData.organizationId}
                  onChange={filterValidation}

                >
                  <option selected="">Select</option>
                  {organizationData.map(organization => (
                    <option key={organization.orgId} value={organization.orgId}>{organization.orgName}</option>
                  ))}
                </select>
              </div>
              <div className="col-md-6 mb-4">
                <label
                  className="form-label font-14 font-medium black-v2"
                  htmlFor="cloud-provider"
                >
                  Workspace
                </label>
                <select
                  className="form-select custom-dropdown shadow-none form-field-border py-1 font-14 font-regular black-v2 custom-select"
                  aria-label="Default select example"
                  id="workspaceId"
                  value={filterData.workspaceId}
                  onChange={filterValidation}
                >
                  <option selected="">Select</option>
                  {workspaceData.map(workspace => (
                    <option key={workspace.workspace_id} value={workspace.workspace_id}>
                      {workspace.workspace_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-6 mb-4">
                <label
                  className="form-label font-14 font-medium black-v2"
                  htmlFor="Target-Status"
                >
                  Target Status
                </label>
                <select
                  className="form-select custom-dropdown shadow-none form-field-border py-1 font-14 font-regular black-v2 custom-select"
                  aria-label="Default select example"
                  id="targetStatus"
                  onChange={filterValidation}
                  value={filterData.targetStatus}
                >
                  <option selected="">Select</option>
                  <option value="Reached">Reached</option>
                  <option value="Not Reached">Not Reached</option>

                </select>
              </div>
              <div className="col-md-6 mb-4">
                <label
                  className="form-label font-14 font-medium black-v2"
                  htmlFor="Period"
                >
                  Period
                </label>
                <select
                  className="form-select custom-dropdown shadow-none form-field-border py-1 font-14 font-regular black-v2 custom-select"
                  aria-label="Default select example"
                  id="peroid"
                  onChange={filterValidation}
                  value={filterData.peroid}

                >
                  <option selected>Select</option>
                  <option value="custom">custom</option>
                  <option value="weekly">weekly</option>
                  <option value="monthly">monthly</option>
                  <option value="yearly">yearly</option>
                </select>
              </div>
              <div className="col-md-12 mb-2">
                <label className="font-14 font-medium black-v2">
                  Date Range
                </label>
              </div>
              <div className="col-md-6 mb-4">
                <label
                  className="form-label font-14 font-medium label-color form-text-color black-v2"
                  htmlFor="from"
                >
                  From
                </label>
                <div className="position-relative">
                  <input
                    className="form-control custom-dropdown"
                    type="date"
                    id="startDate"
                    onChange={filterValidation}
                    value={filterData.startDate ? new Date(filterData.startDate).toISOString().split('T')[0] : ''}
                    max={new Date(new Date().setMonth(new Date().getMonth() + 6)).toISOString().split('T')[0]}
                    min={new Date(new Date().setMonth(new Date().getMonth() - 6)).toISOString().split('T')[0]}
                  />
                </div>
              </div>
              <div className="col-md-6 mb-4">
                <label
                  className="form-label font-14 font-medium label-color form-text-color black-v2"
                  htmlFor="to1"
                >
                  To
                </label>
                <div className="position-relative">
                  <input
                    className="form-control custom-dropdown"
                    type="date"
                    id="endDate"
                    onChange={filterValidation}
                    value={filterData.endDate ? new Date(filterData.endDate).toISOString().split('T')[0] : ''}
                    min={filterData.startDate || new Date(new Date().setMonth(new Date().getMonth() - 6)).toISOString().split('T')[0]}
                    max={new Date(new Date().setMonth(new Date().getMonth() + 6)).toISOString().split('T')[0]}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="d-flex justify-content-end align-items-center pt-3 gap-3">
                  <button className="button outline-button" onClick={handleClear}>
                    Clear All
                  </button>
                  <button className="button primary-button"
                    onClick={handleApply}
                    disabled={!filterData.clientId && !filterData.workspaceId && !filterData.startDate && !filterData.endDate}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/*filter*/}
        </div>
      </div>
      {/* search and filter end */}
      {/* chart starts here */}
      <div className="col-md-12">
        <div className="chart-container-3 p-32">
          <div className="d-flex align-items-center mb-5">
            <div className="me-5 mb-4">
              <p className="font-14 font-regular color-black mb-1 text-nowrap">
                Actual Cost
              </p>
              <p className="font-19 font-medium color-black m-0">
                {formatCurrency(cardData.actualCost)}
              </p>
            </div>
            <div className="me-5 mb-4">
              <p className="font-14 font-regular color-black mb-1 text-nowrap">
                Forecasted Cost
              </p>
              <p className="font-19 font-medium color-black m-0">
                {formatCurrency(cardData.forecastedCost)}
              </p>
            </div>
            <div className="mb-4">
              <p className="font-14 font-regular color-black mb-1 text-nowrap">
                Commitments
              </p>
              <p className="font-19 font-medium color-black m-0">
                {formatCurrency(cardData.commitments)}
              </p>
            </div>
          </div>
          <div className="w-100 chart-container my-4 d-flex justify-content-center align-items-start" style={{ height: '400px' }}>
            <div className="position-relative" style={{ width: '100%', height: '300px' }}>
              <ResponsiveContainer width="100%" height="100%">
                <ComposedChart data={chartData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                  <defs>
                    <linearGradient id="colorAccumulatedCost" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#00ff00" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#00ff00" stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id="colorForecastedCost" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#90EE90" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#90EE90" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <XAxis dataKey="date" />
                  <YAxis />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="accumulatedCost"
                    stroke="#00ff00"
                    fillOpacity={1}
                    fill="url(#colorAccumulatedCost)"
                  />
                  <Line
                    type="monotone"
                    dataKey="commitments"
                    stroke="#ff0000"
                    strokeWidth={2}
                    strokeDasharray="5 5"
                    dot={{ fill: '#ff0000', radius: 4 }}
                  />
                  <Area
                    type="monotone"
                    dataKey="overage"
                    stroke="#FFC0CB"
                    fillOpacity={1}
                    fill="#FFC0CB"
                  />
                  <Area
                    type="monotone"
                    dataKey="forecastedCost"
                    stroke="#90EE90"
                    fillOpacity={1}
                    fill="url(#colorForecastedCost)"
                  />
                </ComposedChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className="mt-4 d-flex justify-content-center align-items-center gap-4">
            <p className="font-12 font-medium secondary-color mb-1 d-flex align-items-center gap-1">
              <span className="legend legend-success-light" />
              Accumulated Cost{" "}
            </p>
            <p className="font-12 font-medium secondary-color mb-1 d-flex align-items-center gap-1">
              <span className="legend legend-red" />
              Commitments
            </p>
            <p className="font-12 font-medium secondary-color mb-1 d-flex align-items-center gap-1">
              <span className="legend legend-info-bg-v3" />
              Overage
            </p>
            <p className="font-12 font-medium secondary-color mb-1 d-flex align-items-center gap-1">
              <span className="legend legend-success-v6" />
              Forecasted Cost
            </p>
          </div>
        </div>
      </div>
      {/* chart ends here */}
      {/* table-container-start */}
      <h5 className="font-16 font-bold black-v2 mt-4 mb-3">
        Databricks Utilization
      </h5>
      {/* Page Content Start here */}
      <div className="table-responsive mt-4 table-border manage-user-table p-0">
        <table className="table custom-table">
          <thead className="parent-table-title">
            <tr>
              <th className="border-0" style={{ width: 0 }} />
              <th className="position-relative text-start border-0" onClick={() => toggleSortOrder('client')}>
                Clients
                <span className="cursor-pointer margin-left-2">
                  <img src="./images/table-sort.svg" 
                  alt="sort" 
                  style={{
                    transform: sortColumnName === "client" && sortOrder === "ASC" ? "scaleY(-1)" : "scaleY(1)",
                    transition: "transform 0.2s ease-in-out",
                  }}/>
                </span>
              </th>
              <th className="position-sort-icon text-end border-0" onClick={() => toggleSortOrder('commitments')}>
                DBU Commitments
                <span className="cursor-pointer margin-left-2">
                  <img src="./images/table-sort.svg"
                   alt="sort"
                   style={{
                    transform: sortColumnName === "commitments" && sortOrder === "ASC" ? "scaleY(-1)" : "scaleY(1)",
                    transition: "transform 0.2s ease-in-out",
                  }} />
                </span>
              </th>
              <th className="position-relative text-end border-0" onClick={() => toggleSortOrder('cost')}>
                DBU Cost
                <span className="cursor-pointer margin-left-2">
                  <img src="./images/table-sort.svg" 
                  alt="sort"
                  style={{
                    transform: sortColumnName === "cost" && sortOrder === "ASC" ? "scaleY(-1)" : "scaleY(1)",
                    transition: "transform 0.2s ease-in-out",
                  }} />
                </span>
              </th>
              <th className="position-relative text-end border-0" onClick={() => toggleSortOrder('difference')}>
                Difference
                <span className="cursor-pointer margin-left-2">
                  <img src="./images/table-sort.svg"
                   alt="sort" 
                   style={{
                    transform: sortColumnName === "difference" && sortOrder === "ASC" ? "scaleY(-1)" : "scaleY(1)",
                    transition: "transform 0.2s ease-in-out",
                  }}/>
                </span>
              </th>
            </tr>
          </thead>
          <tbody className="parent-table-data">
            {bindUtilizationGridData()}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-between align-items-center my-3 exclude-from-pdf ">
        <p className="font-14 font-regular secondary-text-color mb-0">
          # of Records{" "}
          <span className="font16 font-medium color-black">
            {" "}
            <span>{Math.min(loadCount, totalActivityLogCount)}</span> out of <span>{totalActivityLogCount}</span>
          </span>
        </p>
      </div>
      <div className="d-block text-center my-3 mb-5 exclude-from-pdf">
        <button
          type="button"
          className="button secondary-outline-button black-v2 text-nowrap"
          hidden={totalActivityLogCount === 0 || totalActivityLogCount <= loadCount}
          onClick={() => { setLoadCount(prevCount => prevCount + 10) }}
        >
          Load More
        </button>
      </div></>

  )
}

export default DbuUtilizationGrid;