import React, { createContext, useContext, useEffect, useState } from 'react';
import { OrgContext } from '../../../ContextProvider/OrgContext';
import { fetchAllCloudProvider, fetchAllService, fetchCategoryData, postServiceDetails, updateServiceData } from  "../../../Service/api"  //"../../Service/api";
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';


// 1. Create the context
const AddServiceContext = createContext();

// 2. Create a provider component
const AddServiceProvider = ({ children }) => {
  
    const [userId,setUserId] = useState(localStorage.getItem('userId'))
    const [selfServiceData,setSelfServiceData] = useState({
        "userId": localStorage.getItem('userId'),
        "cloudproviderId": "",
        "categoryId": "",
        "serviceName": "",
        "uploadIconBase64": "",
        "containsService": false,
        "description": "",
        "InputConfiguration": [],
        "TerraformCode": "",
        "armcode": "arm",
        "CloudFormationCode": "",
        "filetype": "",
        "filename": "",
        "serviceId" : ""
    })
    const [fieldType,setFieldType] = useState("text")
    const [categories,setCategories] = useState([])
    const [cloudProviders,setCloudProviders] = useState([])
    const {orgId} = useContext(OrgContext)
    const [langKb,setLangKb] = useState("terraform")
    const [providerName,setProviderName] = useState("")
    const [isAddField,setIsAddField] = useState(false)
    const [services,setServices] = useState([])
    const location = useLocation()

    // console.log(location,"This is the location")

    useEffect(() => {
        getCloudProviderData()
    },[])

    useEffect(
        () => {
            fetchAllCategoryData()
        } , [selfServiceData?.cloudproviderId]
    )


    useEffect(()=>{
            editServiceData()
    },[])

    function editServiceData(){
        try {

          

            if (location?.state?.serviceEditData){


               let data = location?.state?.serviceEditData

               let configsData = [];

               console.info(data,"This is the service data to be monitored...")

               for (let values of data?.fields){
                console.info('fieldId =====>',values?.fieldId)
                  let configJson ={
                    "fieldName": values?.fieldName,
                    "fieldType": values?.fieldType,
                    "value": values.inputValue,
                    "isDependsOn": values?.isdependsOn,
                    "required": values?.required,
                    "dependsOn": values?.dependsOnId,
                    "depends_on_configuration_id": values?.depends_on_configuration_id,
                    "uuid" : values?.fieldId,
                    "fieldId":values?.fieldId
                  }
                  configsData?.push(configJson)
               }
               

                let formData = {
                    "userId": localStorage.getItem('userId'),
                    "cloudproviderId": data?.cloudproviderId,
                    "categoryId": data?.categoryId,
                    "serviceName": data?.serviceName,
                    "uploadIconBase64": data?.serviceImage,
                    "containsService": data?.containsService,
                    "description": data?.description,
                    "InputConfiguration": configsData,
                    "TerraformCode": data?.TerraformCode,
                    "armcode": data?.armcode,
                    "CloudFormationCode": data?.CloudFormationCode,
                    "filetype": "",
                    "filename": "",
                    "serviceId" : data?.service_id
                }
                
                setSelfServiceData(formData)
                
            }
            
        } catch (error) {
            console.log(error.message)
        }
    }



    useEffect(()=>{
        if (location?.state?.serviceEditData && cloudProviders.length != 0){
            let data = location?.state?.serviceEditData
            let providerId = data?.cloudproviderId
            let providerNameCopy = providerName
            for (let values of cloudProviders){
               
              if (values?.providerId == providerId ){
                providerNameCopy = values?.providerName
              }
            }
            setProviderName(providerNameCopy)
        }
    },[cloudProviders])





   

    // function to get the category and cloud provider data
    const getCloudProviderData = async () => {
        try {
            const payload = {
                "userId": userId,
                "orgId": "", // Assuming orgId is intentionally left empty
                "type": "general"
            };

            const response = await fetchAllCloudProvider(payload); // Replace with your actual API call function
            console.log(response, "This is the repo");

            if (response.status === 200) {

              
                setCloudProviders(response.data.data); 
            } else {
                console.error("Error fetching cloud provider data:", response.data.body.message);
            }
        } catch (error) {
            console.error("API Error:", {
                errorName: error.name,
                errorServiceName: "Front-end",
                errorFunctionName: "getCloudProviderData",
                errorSource: "frontend",
                errorDescription: "fetch failed: " + error.message
            });
        }
    };

    //PS_19 - PS_25
    // Category dropdown function
    const fetchAllCategoryData = async () => {
        try {

            const payload = {
                "cloud_provider_id": selfServiceData?.cloudproviderId ,
                "userId": userId
            }
            const response = await fetchCategoryData(payload);

            console.log(response,"This is the repo")
            if (response.status == 200) {
                console.log(response, "resp")
                setCategories(response.data.body.data);
            } else {
                throw new Error('Error fetching category data');
            }
        } catch (err) {
            console.log(err)
        }
    };

    useEffect(()=>{
        getAllService()
    },[selfServiceData?.cloudproviderId])


    const getAllService = async () => {
        if (selfServiceData?.cloudproviderId){
            const payload = {
                "cloudproviderID": selfServiceData?.cloudproviderId,
                "userId": userId,
                "orgId": "",
                "searchData": '',
                "categoryId": ''
            }
            try {
               
                const response = await fetchAllService(payload);

           
                if (response.status === 200) {
                    const { body } = response.data;
                    const { data } = body;



                    let serviceArray = []
                    for (let serviceNew of data) {
                        for (let values of serviceNew.services){
                            let serviceDetail = {
                                serviceId : values?.service_id,
                                serviceName : values?.serviceName,
                                fields : values?.fields
                            }
                            serviceArray.push(serviceDetail)
                        }
                    }
    
                    setServices(serviceArray);
    
                } else {
                    throw new Error('Error fetching service data');
                }
            } catch (err) {
               console.log(err?.message)
            } 
        }
        
    };


  return (
    <AddServiceContext.Provider value={{ userId, setUserId , selfServiceData , setSelfServiceData , categories , setCategories , cloudProviders ,setCloudProviders , langKb, setLangKb , providerName,setProviderName , isAddField,setIsAddField , fieldType,setFieldType,services,setServices }}>
      {children}
    </AddServiceContext.Provider>
  );
};

export {AddServiceProvider,AddServiceContext}