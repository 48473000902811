import React, { useState, useEffect } from 'react';
import { fetchRecommendationsApi, getAccountDetailsApi } from '../../../../Service/api';
import { useOrgContext } from '../../../../ContextProvider/OrgContext';
import ChatBot from './ChatBot';

export default function AccountConfigureComponent({
	configureDetails,
	labelSubmit,
	accounts,
	setAccounts, onClose,
	processSelectedService
}) {
	console.log(configureDetails.data.ConfigureDetail.accountId, configureDetails, "Accoutttt");
	console.log(configureDetails, "Configure Details")
	const [selectedAccount, setSelectedAccount] = useState(configureDetails.data.ConfigureDetail.accountId);
	console.log(selectedAccount, "SelectedAcccount");
	const [activeTab, setActiveTab] = useState('configure');
	const [recommendations, setRecommendations] = useState([]);
	const [isLoadingRecommendations, setIsLoadingRecommendations] = useState(false);
	const [recommendationError, setRecommendationError] = useState(null);
	const [region, setRegion] = useState('');

	const {
		orgId,
		envId,
		providerId,
		workloadRole,
	} = useOrgContext();

	const [userId, setuserId] = useState(localStorage.getItem('userId'));
	const [viewMode, setViewMode] = useState(workloadRole.toLowerCase() === 'viewer');

	useEffect(() => {
		fetchAccountDetails();
	}, []);

	useEffect(() => {
		if (configureDetails.data.serviceName === "Region") {
			let temp = { ...configureDetails };
			console.log(configureDetails, "Configure Detaus");
			let type = temp.data.ConfigureDetail.subServieConfig.type;
			if (temp.data.ConfigureDetail?.subServieConfig?.Action[type]?.Region) {
				console.log(type, "Type Here");
				console.log(temp.data.ConfigureDetail.subServieConfig.Action[type]["Region"], "Region Here");
				setRegion(temp.data.ConfigureDetail.subServieConfig.Action[type]["Region"]);
			}
		}
	}, [configureDetails]);

	const fetchAccountDetails = async () => {
		try {
			let payload = {
				"userId": userId,
				"orgId": orgId,
				"providerId": providerId,
				"search": ""
			};
			let res = await getAccountDetailsApi(payload);
			console.log(res, "getAccountDetailsApi");
			if (res.success) {
				setAccounts(res.data);
				if (res.data.length > 0 && selectedAccount !== "" && selectedAccount !== undefined && selectedAccount !== null) {
					setSelectedAccount(res.data[0].connectorId);
				}
			}
		} catch (err) {
			console.log(err, "Error");
		}
	};

	const handleAccountChange = (e) => {
		const connectorId = e.target.value;
		setSelectedAccount(connectorId);
		updateConfigureDetails(connectorId);
	};

	const handleRegionChange = (e) => {
		const regionValue = e.target.value;
		setRegion(regionValue);
		updateConfigureDetails(selectedAccount, regionValue);
	};


	const updateConfigureDetails = (connectorId, regionValue = region) => {
		let temp = { ...configureDetails };

		if (configureDetails.data.serviceName != "Region") {
			const selectedAccountObj = accounts.find(account => account.connectorId === connectorId);
			console.log(temp, "temp")
			if (selectedAccountObj) {
				temp.data.ConfigureDetail = {
					accountId: selectedAccountObj.connectorId,
					accountName: selectedAccountObj.connectorName,
					cloudproviderId: providerId,
					permanentAccountId: temp.data.ConfigureDetail.permanentAccountId,
				};
				temp.data.imageServiceName = selectedAccountObj.connectorName
					? selectedAccountObj.connectorName
					: temp.data.imageServiceName;
			}
		}
		else {
			let type = temp.data.ConfigureDetail.subServieConfig.type;
			console.log(type, regionValue, "Region Value eher")
			if (type && regionValue) {
				temp.data.ConfigureDetail.subServieConfig.Action[type]["Region"] = regionValue;
			}
			temp.data.imageServiceName = regionValue ? regionValue : temp.data.imageServiceName

			console.log(temp, "Temppp");
		}
		labelSubmit(temp);
	};

	useEffect(() => {
		if (activeTab === 'recommendation') {
			fetchRecommendations();
		}
	}, [activeTab]);

	const fetchRecommendations = async () => {
		setIsLoadingRecommendations(true);
		setRecommendationError(null);

		try {
			const payload = { organizationId: orgId, envId, userId };
			console.log("Fetch Recommendation payload:", payload);

			const response = await fetchRecommendationsApi(payload);
			console.log("Fetch Recommendation response:", response);

			if (response.statusCode === 200 && response.body && response.body.data) {
				let parsedData;
				if (typeof response.body.data === "string") {
					parsedData = JSON.parse(response.body.data);
				} else {
					parsedData = response.body.data;
				}

				setRecommendations(parsedData);
			} else {
				throw new Error("Invalid response structure");
			}
		} catch (error) {
			console.error("Error fetching recommendations:", error);
			setRecommendationError("Failed to load recommendations. Please try again.");
		} finally {
			setIsLoadingRecommendations(false);
		}
	};

	
	return (
		<div className="col-md-3 mt-3 ps-1 pe-3 content-right-container">
			<div className="recommended-container pe-3">
				{/* Tab navigation */}
				<div className="custom-tab-scroll position-relative">
            <ul className="nav nav-pills d-flex gap-1 flex-nowrap mb-1" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button
                        className={`nav-link theme-tab cust-tab font-14 secondary-text-color font-medium px-3 py-2 ${activeTab === 'configure' ? 'active' : ''}`}
                        onClick={() => setActiveTab('configure')}
                        type="button"
                        role="tab"
                    >
                        Configure
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button
                        className={`nav-link theme-tab cust-tab font-14 secondary-text-color font-medium px-3 py-2 ${activeTab === 'recommendation' ? 'active' : ''}`}
                        onClick={() => setActiveTab('recommendation')}
                        type="button"
                        role="tab"
                    >
                        Recommendation
                    </button>
                </li>
            </ul>
            <div className="position-absolute" style={{ top: 0, right: 0 }}>
                <a href="#" onClick={onClose}>
                    <img
                        src="images/close-icon.svg"
                        alt="close-icon"
                        className="icon-custom-filter"
                        style={{ width: '20px', height: '24px' }} // Adjust size as needed
                    />
                </a>
            </div>
        </div>

				{/* Tab content */}
				<div className="tab-content" id="pills-tabContent">
					{/* Configure tab */}
					<div className={`tab-pane fade ${activeTab === 'configure' ? 'show active' : ''} pe-1 custom-area-scrl`} id="dash-Configure" role="tabpanel">
						<form>
							<div className="config-container">
								<p className="mb-3 font-18 font-bold black-v2">Configurations</p>
								<div className="row">
									{configureDetails.data.serviceName === "Account" ? (
										<div className="col-md-12 mb-4">
											<label className="form-label font-14 font-medium black-v2" htmlFor="Account">Account</label>
											<select
												id="Account"
												className="form-select form-control custom-form font-14 font-medium"
												value={selectedAccount}
												onChange={handleAccountChange}
												disabled={viewMode}
											>
												<option key={""} value={""}>
													{"Select Account"}
												</option>
												{accounts.map(account => (
													<option key={account.connectorId} value={account.connectorId}>
														{account.connectorName}
													</option>
												))}
											</select>
										</div>
									) : (
										<div className="col-md-12 mb-4">
											<label className="form-label font-14 font-medium black-v2" htmlFor="Region">Region</label>
											<select
												id="Region"
												className="form-control custom-form font-14 font-medium"
												value={region}
												onChange={handleRegionChange}
												disabled={viewMode}
											>
												<option value="">Select Region</option>
<option key="af-south-1" value="af-south-1">af-south-1</option>
<option key="ap-east-1" value="ap-east-1">ap-east-1</option>
<option key="ap-northeast-1" value="ap-northeast-1">ap-northeast-1</option>
<option key="ap-northeast-2" value="ap-northeast-2">ap-northeast-2</option>
<option key="ap-northeast-3" value="ap-northeast-3">ap-northeast-3</option>
<option key="ap-south-1" value="ap-south-1">ap-south-1</option>
<option key="ap-south-2" value="ap-south-2">ap-south-2</option>
<option key="ap-southeast-1" value="ap-southeast-1">ap-southeast-1</option>
<option key="ap-southeast-2" value="ap-southeast-2">ap-southeast-2</option>
<option key="ap-southeast-3" value="ap-southeast-3">ap-southeast-3</option>
<option key="ap-southeast-4" value="ap-southeast-4">ap-southeast-4</option>
<option key="ca-central-1" value="ca-central-1">ca-central-1</option>
<option key="eu-central-1" value="eu-central-1">eu-central-1</option>
<option key="eu-central-2" value="eu-central-2">eu-central-2</option>
<option key="eu-north-1" value="eu-north-1">eu-north-1</option>
<option key="eu-south-1" value="eu-south-1">eu-south-1</option>
<option key="eu-south-2" value="eu-south-2">eu-south-2</option>
<option key="eu-west-1" value="eu-west-1">eu-west-1</option>
<option key="eu-west-2" value="eu-west-2">eu-west-2</option>
<option key="eu-west-3" value="eu-west-3">eu-west-3</option>
<option key="me-central-1" value="me-central-1">me-central-1</option>
<option key="me-south-1" value="me-south-1">me-south-1</option>
<option key="sa-east-1" value="sa-east-1">sa-east-1</option>
<option key="us-east-1" value="us-east-1">us-east-1</option>
<option key="us-east-2" value="us-east-2">us-east-2</option>
<option key="us-gov-east-1" value="us-gov-east-1">us-gov-east-1</option>
<option key="us-gov-west-1" value="us-gov-west-1">us-gov-west-1</option>
<option key="us-west-1" value="us-west-1">us-west-1</option>
<option key="us-west-2" value="us-west-2">us-west-2</option>
											</select>
										</div>
									)}
								</div>
							</div>
							<div className="config-container">
								<div className="row">
									{/* Additional configurations can be placed here */}
								</div>
							</div>
						</form>
					</div>
					{/* Recommendation tab */}
					<div className={`tab-pane fade ${activeTab === 'recommendation' ? 'show active' : ''} pt-4 tab-scrl`} id="dash-Recommendation" role="tabpanel">

						 {isLoadingRecommendations ? (
							<p>Loading recommendations...</p>
						) : recommendationError ? (
							<p className="error-message">{recommendationError}</p>
						) : recommendations.length === 0 ? (
							<p>No recommendations available.</p>
						) : (
							recommendations.map((recommendationGroup, groupIndex) => (
								<div key={groupIndex} className="recommendation-group mb-4">
									<h2 className="font-20 color-black font-bold mb-3">{recommendationGroup.head}</h2>
									<p className="font-16 font-regular secondary-text-color-v5 mb-4">{recommendationGroup.Description}</p>

									{recommendationGroup.recommendations.map((recommendation, index) => (
										<div key={index} className="recommendation-section mb-4">
											<div className="d-flex align-items-center gap-3 mb-3">
												<p className="font-16 color-black font-medium mb-0">{recommendation.service}</p>
												{recommendation.speedup !== "N/A" && (
													<button className="domain-btn font-14 font-medium bg-primary-dark-v1">
														<img src="images/faster-analysis.svg" alt="faster-analysis" className="dark-theme-icons" />
														{recommendation.speedup}
													</button>
												)}
											</div>
											<p className="font-16 font-regular secondary-text-color-v5">{recommendation.recommendation}</p>

											<ul className="nav pb-3 custom-border-bottom" id={`myTab-${groupIndex}-${index}`} role="tablist">
												<li className="nav-item" role="presentation">
													<button
														className="nav-link active work-tab pros-tab me-3"
														id={`home-tab-${groupIndex}-${index}`}
														data-bs-toggle="tab"
														data-bs-target={`#home-${groupIndex}-${index}`}
														type="button"
														role="tab"
														aria-controls={`home-${groupIndex}-${index}`}
														aria-selected="true"
													>
														Pros <span>({recommendation.pros.length})</span>
													</button>
												</li>
												<li className="nav-item" role="presentation">
													<button
														className="nav-link work-tab cons-tab"
														id={`profile-tab-${groupIndex}-${index}`}
														data-bs-toggle="tab"
														data-bs-target={`#profile-${groupIndex}-${index}`}
														type="button"
														role="tab"
														aria-controls={`profile-${groupIndex}-${index}`}
														aria-selected="false"
													>
														Cons <span>({recommendation.cons.length})</span>
													</button>
												</li>
											</ul>

											<div className="tab-content" id={`myTabContent-${groupIndex}-${index}`}>
												<div
													className="tab-pane fade show active"
													id={`home-${groupIndex}-${index}`}
													role="tabpanel"
													aria-labelledby={`home-tab-${groupIndex}-${index}`}
												>
													<h6 className="font-18 font-bold success-v4 my-3">Pros</h6>
													<ul className="work-custom-list ps-0 pb-3 custom-border-bottom">
														{recommendation.pros.map((pro, proIndex) => (
															<li key={proIndex} className="font-16 font-regular secondary-text-color-v5 mb-3">
																{pro.prosDescription}
															</li>
														))}
													</ul>
												</div>
												<div
													className="tab-pane fade"
													id={`profile-${groupIndex}-${index}`}
													role="tabpanel"
													aria-labelledby={`profile-tab-${groupIndex}-${index}`}
												>
													<h6 className="font-18 font-bold danger-red my-3">Cons</h6>
													<ul className="work-custom-list ps-0 pb-3 custom-border-bottom">
														{recommendation.cons.map((con, conIndex) => (
															<li key={conIndex} className="font-16 font-regular secondary-text-color-v5 mb-3">
																{con.consDescription}
															</li>
														))}
													</ul>
												</div>
											</div>
										</div>
									))}
								</div>
							))
						)} 
						{/* <ChatBot
						processSelectedService={processSelectedService}
						 /> */}

					</div>
				</div>
			</div>
		</div>
	);
}