import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import { fetchRecommendationsApi, getLocalsAndOutputsApi, fetchDataConfigs } from '../../../../Service/api';
import { useOrgContext } from '../../../../ContextProvider/OrgContext';
import { LEFT } from 'react-swipeable';
import MonacoEditor from 'react-monaco-editor';
import { OrgContext } from '../../../../ContextProvider/OrgContext';
import Select from 'react-select';
import { logDOM } from '@testing-library/react';


const ConfigureComponent = (props) => {
	////console.log(props , "Props herere")
	const { orgId, envId, workloadRole, providerId } = useOrgContext();
	const [userId, setuserId] = useState(localStorage.getItem('userId'));
	const [viewMode, setViewMode] = useState(workloadRole.toLowerCase() == 'viewer' ? true : false)

	//SQ_NO_1.1 get propos from workbench component
	const { configureDetails, repositories, labelSubmit, connectedSourceNodes,
		setconfigureDetails, onClose, nodeDetails, overlappedNodes,
		accountIds, uniqueNames } = props;
	////console.log(nodeDetails , "nodeDetails2")
	////console.log(connectedSourceNodes , "Connected Soruce Node")

	////console.log(configureDetails , "Configure Details" ,repositories )
	// State declarations
	const [activeTab, setActiveTab] = useState('configure');

	const [formData, setFormData] = useState({
		Existing: "false",
		New: "false",
	});
	const [nodeId, setnodeId] = useState([]);
	const [tags, setTags] = useState([]);
	const [newTagKey, setNewTagKey] = useState('');
	const [newTagValue, setNewTagValue] = useState('');
	const [recommendations, setRecommendations] = useState([]);
	const [isLoadingRecommendations, setIsLoadingRecommendations] = useState(false);
	const [recommendationError, setRecommendationError] = useState(null);
	const [clearedFields, setClearedFields] = useState({});
	const [formFieldValues, setFormFieldValues] = useState({}); // Changed variable name here
	//SQ_NO_2.29- initialize state variable to store selected fields nodeIDS
	const [selectnodeIds, setselectnodeIds] = useState(localStorage.getItem('userId'));
	const [selectnodeIds2, setselectnodeIds2] = useState("");
	const [fieldName, setFieldName] = useState("")




	const [localsData, setLocalsData] = useState([]);
	const [outputsData, setOutputsData] = useState([]);
	// UseEffect for initializing formData and tags
	useEffect(() => {
		fetchLocalsData();
	}, []);
	const fetchLocalsData = async () => {
		const payload = {
			organizationId: orgId, // Replace with dynamic value if needed
			envId: envId, // Replace with dynamic value if needed
			userId: userId,// Replace with dynamic value if needed
		};

		try {
			// Call your API to get locals and outputs
			const response = await getLocalsAndOutputsApi(payload);
			////console.log("successs",response);

			if (response.body.success) {
				////console.log("successs");
				setLocalsData(response.body.data.locals);
				setOutputsData(response.body.data.outputs);
			}
		} catch (error) {
			console.error('Error fetching data:', error);
		} finally {
		}
	};
	//SQ_NO_2.1  - initial useEffect to store configureDetails into FormData
	useEffect(() => {
		try {
			if (configureDetails) {
				////console.log(configureDetails , "configuredetailss")
				const initialData = {};
				const initialClearedFields = {};
				const initialInputStates = {}; // New object for input states
				const initialFormFieldValues = {};
				const initialFormValues = {};
				const initialFormValues2 = {};
				const nodeId = {}
				const selectnodeId = {}



				configureDetails.data.fields.forEach(field => {
					if (field.type === "list(string)") {
						////console.log(field , "Into List of Stringsdds ")
						if (field.UserValue.values && Array.isArray(field.UserValue.values) && field.UserValue.values.length > 0) {
							// Populate initialFormValues2 based on the field name and its value
							initialFormValues2[field.name] = {
								inputValue: { values: field.UserValue.values ? field.UserValue.values : [] }, // Use fieldValue directly
								values: [] // Initialize values as an empty array
							};
						}
					}
					if (field.type === "list(object)") {
						////console.log(field ,"field hereer",field.name)
						////console.log(initialFormFieldValues ,"beforeee")
						initialFormFieldValues[field.name] = field.UserValue !== undefined ? field.UserValue : []
						let test = initialFormFieldValues[field.name]
						var val = { [field.name]: test }
						setFormFieldValues(val); // Set the form field values here

						////console.log(val ,"field initialFormFieldValues afterrrrrrrr")

					}
					// Set UserValue or fallback to field value or empty string
					initialData[field.name] = field.UserValue !== undefined ? field.UserValue : (field.value || "");
					initialClearedFields[field.name] = false; // Use 'name' here as well

					nodeId[field.name] = field.node_id !== undefined ? field.node_id : null



					// Initialize input state for each field
					if (field.type === "list(string)") {
						////console.log(field , "Into List of String ")
						let fieldValue = field.value;

						selectnodeId[field.name] = Array.isArray(field.node_id) && field.node_id.length > 0
							? field.node_id // Set as an array if it exists and is not empty
							: null;

						if (typeof (field.value) !== "array" && typeof (field.value) == "string") {
							try {
								fieldValue = JSON.parse(field.value)
							} catch (error) {
								console.error(`Error parsing field value: ${field.value}`, error);
								fieldValue = [];
							}
						}
						if (typeof (field.UserValue) !== "array" && typeof (field.value) == "string") {
							try {
								field.UserValue = []
							} catch (error) {
								console.error(`Error parsing field value: ${field.value}`, error);
								field.UserValue = [];
							}
						}
						if (typeof (field.default) !== "array" && typeof (field.value) == "string") {
							try {
								field.default = JSON.parse(field.default)
							} catch (error) {
								console.error(`Error parsing field value: ${field.value}`, error);
								field.default = [];
							}
						}

						initialInputStates[field.name] = {
							inputValue: field.UserValue !== undefined ? field.UserValue : fieldValue || [],
							users: []
						};

						initialFormFieldValues[field.name] = {
							[field.name]: field.UserValue !== undefined ? field.UserValue : fieldValue || []
						};

						initialFormValues[field.name] = {
							inputValue: field.UserValue ? field.UserValue : fieldValue || [],
							values: []
						};
					} else {
						initialInputStates[field.name] = {
							inputValue: field.UserValue !== undefined ? field.UserValue : (field.value || ""),
							users: []
						};

						initialFormFieldValues[field.name] = {
							[field.name]: field.UserValue !== undefined ? field.UserValue : (field.value || "")
						};

						initialFormValues[field.name] = {
							inputValue: field.UserValue || field.value || "",
							values: []
						};
					}

				});
				const subServiceConfig = configureDetails.data.ConfigureDetail.subServieConfig.Action.create;
				////console.log("subServiceConfig",subServiceConfig.unique_Name);
				if (subServiceConfig && subServiceConfig?.unique_Name) {
					setFormData(prev => ({
						...prev,
						[configureDetails.id]: {
							unique_Name: subServiceConfig?.unique_Name // Set unique_Name under configureDetails.id
						}
					}));
				}

				Object.keys(subServiceConfig).forEach(field => {
					////console.log("fieldValue",field);

					const fieldValue = subServiceConfig[field].values; // Get the 'values' for each field
					////console.log("fieldValue",fieldValue);
	// if (fieldValue && Array.isArray(fieldValue) && fieldValue.length > 0) {
	// 					// Populate initialFormValues2 based on the field name and its value
	// 					initialFormValues2[field] = {
	// 						inputValue: { values: fieldValue ? fieldValue : [] }, // Use fieldValue directly
	// 						values: [] // Initialize values as an empty array
	// 					};
	// 				}
					// Check if fieldValue is defined and has elements
				
				});




				////console.log("initialFormFieldValues", initialFormFieldValues);
				////console.log("initialFormValues", initialFormValues);
				console.log("initialInputStates", initialInputStates);

				////console.log("initialData", initialData);
				console.log("configureDetails2", configureDetails);
				console.log("initialFormValues2", initialFormValues2);



				setFormData({
					...initialData,
					[configureDetails.id]: {
						unique_Name: configureDetails?.data?.unique_Name || ''
					},
					fileuploadFileContent: configureDetails?.data?.fileuploadFileContent
					,
					fileuploadFileName: configureDetails?.data?.fileuploadFileContent?.fileName,

					New: configureDetails.data.newRosource ? true : false,
					Existing: configureDetails.data.newRosource ? false : true,
					repository_id: configureDetails.data.repositoryData.repository_id || '',
					repository_name: configureDetails.data.repositoryData.repository_name || '',
				});

				// console.log("nodeId",subServiceConfig.unique_Name);
				//console.log("selectnodeId",selectnodeId);


				setClearedFields(initialClearedFields);
				setTags(configureDetails.data.Tags || []);
				setInputStates(initialInputStates); // Set the input states here
				setFormValues(initialFormValues2);
				setnodeId(nodeId)
				setselectnodeIds(selectnodeId)
			}
		} catch (error) {
			////console.log(error, "Error in the useEffect");
		}
	}, [configureDetails]);

	////console.log("yess",formData);


	// Debug logs
	useEffect(() => {
		////console.log(configureDetails, "Configure Details");
		getDataConfiguration()
	}, [configureDetails]);


	// UseEffect for fetching recommendations
	useEffect(() => {
		if (activeTab === 'recommendation') {
			fetchRecommendations();
		}
	}, [activeTab]);

	const [dataFile, setDataFile] = useState([]);
	const [accountId, setaccountId] = useState(overlappedNodes);
	const [dataconfig, setdataconfig] = useState([]);
	const darkTheme = localStorage.getItem('darkTheme')
	const [inputStates, setInputStates] = useState({});






	useEffect(() => {
		try {
			const transformedData = props.initialDataConfig
			?.filter(category => category.accountId === accountId)
			.flatMap(category =>
				category.configData.map(config => ({
					id: category.accountId,
					name: `${config.resourceName}.${config.Name}`,
					resourceName: config.resourceName,
					value: config.fieldName.reduce((acc, field) => {
						acc[field.field] = field.value;
						return acc;
					}, {})
				}))
			);
		////console.log(transformedData ,"Transformeed Data HERER")
		setDataFile(transformedData);
		////console.log("transformedData", transformedData);
		} catch (error) {
			console.log(error);
			
		}
		////console.log(props.initialDataConfig , "Inital Config" , accountId , overlappedNodes)
		
	}, [accountId, props.initialDataConfig]);


	const getDataConfiguration = async () => {

		try {

			const payload = {
				userId: userId,
				// accountId: accountsData,
				accountId: accountIds,
				providerId: providerId,
			};
			//SQ_NO_2.7- to retrieve the fetchDataConfig data
			const response = await fetchDataConfigs(payload);

			////console.log(response,"dataConfigResponse")
			if (response?.success) {
				////console.log(response?.data?.configResource,"dataConfigResponse2")

				setdataconfig(response?.data?.configResource)
			}

		} catch (err) {

		}
	};
	// Modify handleInputChange to handle file uploads
	const convertFileToBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve({ fileName: file.name, fileContent: reader.result });
			reader.onerror = error => reject(error);
		});
	};


	const [error, setError] = useState("");

	//SQ_NO_2.39 to store Onchange values in formdata
	const handleInputChange = async (e) => {
		
		if (!e || !e.target) {
			console.error("Event or target is undefined");
			return;
		}


		const { name, value, type, checked, files } = e.target;
		//console.log("currentvalue3", name);


		console.log({ name, value, type, checked, files }, "handleInputChange");

		let newValue;

		try {
			if (type === 'file') {
				const file = files[0];
				
				// Check file size
				const fileSizeInMB = file.size / (1024 * 1024);
				if (fileSizeInMB > 10) {
					console.log('File size should be less than 10 MB');
					
					setError('File size should be less than 10 MB');
					setFormData(prev => ({
						...prev,
						[`${name}FileName`]: "",
						[`${name}FileContent`]: ""
					}));
					return;
				}
	
				const base64File = await convertFileToBase64(file);
	
				// Set both fileName and fileContent to formData
				setFormData(prev => ({
					...prev,
					[name + 'FileName']: base64File.fileName,
					[name + 'FileContent']: base64File
				}));
				setError(""); // Clear any previous error message
			} else {
				newValue = type === 'checkbox' ? checked : value;
			}
			if (name === 'Existing' || name === 'New') {
				newValue = type === 'checkbox' ? checked : value;
			}
			if (name === "Existing" && newValue) {
				setFormData(prev => ({
					...prev,
					Existing: newValue,
					New: false
				}));
			}
			// If the checkbox being selected is "New", uncheck "Existing"
			else if (name === "New" && newValue) {
				setFormData(prev => ({
					...prev,
					New: newValue,
					Existing: false
				}));
			}

			if (name === 'repository') {
				const selectedRepo = repositories.find(repo => repo.repository_id === value);
				if (selectedRepo) {
					setFormData(prev => ({
						...prev,
						repository_id: selectedRepo.repository_id,
						repository_name: selectedRepo.repository_name
					}));
				}
			} else if (name === "unique_Name") {
				//SQ_NO_3.40 - set onChange values into FormData with configureDetails.id
				setFormData(prev => ({
					...prev,
					[configureDetails.id]: {
						...prev[configureDetails.id],
						[name]: newValue
					}
				}));
				console.log("Unique name already exists", newValue);
				console.log("Unique name already exists2", uniqueNames);


				if (newValue === '' || !uniqueNames.includes(newValue)) {
					setError("");
				} else {
					setError("Unique name already exists");
				}
			} else {
				////SQ_NO_3.40 - set onchanfe values into FromData
				setFormData(prev => ({ ...prev, [name]: newValue }));
			}

			// Update clearedFields state
			setClearedFields(prev => ({
				...prev,
				[name]: value === ''
			}));
		} catch (error) {
			console.error("Error in handleInputChange", error);
		}
	};
	useEffect(()=>{
		console.log(formData,"formData");
		
	},[formData])
	const handleNodeIdChange = async (e) => {
		if (!e || !e.target) {
			console.error("Event or target is undefined");
			return;
		}

		const { name, value, type, checked, files } = e.target;
		////console.log({ name, value, type, checked, files }, "handleInputChange");

		let newValue;

		try {


			if (type === 'file') {
				const file = files[0];
				newValue = await convertFileToBase64(file);
			} else {
				newValue = type === 'checkbox' ? checked : value;
			}

			if (name === 'repository') {
				const selectedRepo = repositories.find(repo => repo.repository_id === value);
				if (selectedRepo) {
					setFormData(prev => ({
						...prev,
						repository_id: selectedRepo.repository_id,
						repository_name: selectedRepo.repository_name
					}));
				}
			} else {
				setnodeId(prev => ({ ...prev, [name]: newValue }));
			}

			// Update clearedFields state
			setClearedFields(prev => ({
				...prev,
				[name]: value === ''
			}));
		} catch (error) {
			console.error("Error in handleInputChange", error);
		}
	};
	////console.log("formdataaaId",nodeId);
	//SQ_NO_2.42
	const handleFieldBlur = (fieldName) => {
		//console.log("blur");

		///SQ_NO_2.43  Call updateConfigureDetails when the field loses focus
		updateConfigureDetails(fieldName);
	};
	//SQ_NO_2.44 - to update node details
	const updateConfigureDetails = (fieldName) => {
		//console.log(selectnodeIds, "selectnodeIds")
		try {
			let updatedConfigureDetails = { ...configureDetails };
			let serviceType = updatedConfigureDetails.data.ConfigureDetail.subServieConfig.type;
			////console.log("formData2",formData);

			if (serviceType === "create" || serviceType === "update") {
				// Update only if the keys contain values in them
				let filteredFormData = Object.keys(formData).reduce((acc, key) => {
					if (formData[key]) { // Check if the key has a value	
						acc[key] = formData[key];
					}
					return acc;
				}, {});

				updatedConfigureDetails.data.ConfigureDetail.subServieConfig["Action"][serviceType] = { ...filteredFormData };
			}

			////console.log(updatedConfigureDetails, "updatedConfigureDetails");
			// Update UserValue based on the new field structure
			updatedConfigureDetails.data.fields = updatedConfigureDetails.data.fields.map(field => ({
				...field,
				UserValue: clearedFields[field.name] ? '' : (
					field.type === 'file upload' && formData[field.name]
						? formData[field.name] // Use the Base64 encoded content or file object
						: formData[field.name]
				)
			}));

			updatedConfigureDetails.data.fields = updatedConfigureDetails.data.fields.map(field => ({
				...field,
				node_id: clearedFields[field.name] ? '' : (
					field.type === 'file upload' && nodeId[field.name]
						? nodeId[field.name] // Use the Base64 encoded content or file object
						: "sankar"
				)
			}));


			updatedConfigureDetails.data.fields = updatedConfigureDetails.data.fields.map(field => ({
				...field,
				node_id: clearedFields[field.name] ? '' : (
					field.type === 'list(string)' && selectnodeIds[field.name]
						? selectnodeIds[field.name] // Use the Base64 encoded content or file object
						: nodeId[field.name]

				)

			}));


			// Ensure UserValue is updated for the specific field that triggered blur
			// const currentField = updatedConfigureDetails.data.fields.find(f => f.name === fieldName);
			// if (currentField) {
			// 	currentField.UserValue = formData[fieldName] || formData[fieldName]; // Update UserValue for the specific field

			// }



			////console.log("currentField",currentField);

			// Update repository data
			updatedConfigureDetails.data.repositoryData = {
				repository_id: formData.repository_id || '',
				repository_name: formData.repository_name || '',
				branchData: {
					branchId: '',
					branchName: ''
				}
			};

			// Update tags
			updatedConfigureDetails.data.Tags = tags.map(tag =>
				tag.defaultTag ? tag : { key: tag.key, value: tag.value, defaultTag: tag.defaultTag }
			);
			updatedConfigureDetails.unique_Name = formData[configureDetails.id]?.unique_Name
			updatedConfigureDetails.data.fileuploadFileContent = formData?.fileuploadFileContent
			updatedConfigureDetails.data.newRosource = formData.Existing ? false : true

			console.log(updatedConfigureDetails, "Updated Data");

			labelSubmit(updatedConfigureDetails);
		} catch (error) {
			////console.log(error, "Error in updateConfigureDetails");
		}
	};



	const fetchRecommendations = async () => {
		setIsLoadingRecommendations(true);
		setRecommendationError(null);

		try {
			const payload = { organizationId: orgId, envId, userId };
			////console.log("Fetch Recommendation payload:", payload);
			const response = await fetchRecommendationsApi(payload);
			////console.log("Fetch Recommendation response:", response);

			if (response.statusCode === 200 && response.body.data) {
				let parsedData;
				if (typeof response.body.data === "string") {
					parsedData = JSON.parse(response.body.data);
				} else {
					parsedData = response.body.data;
				}

				setRecommendations(parsedData);
			} else {
				throw new Error("Invalid response structure");
			}
		} catch (error) {
			console.error("Error fetching recommendations:", error);
			setRecommendationError("Failed to load recommendations. Please try again.");
		} finally {
			setIsLoadingRecommendations(false);
		}
	};

	// Function to handle form submission
	const handleFormSubmit = (e) => {
		e.preventDefault();
		updateConfigureDetails();
	};

	// Functions for tag management
	const addTag = () => {
		try {
			if (newTagKey.trim() && newTagValue.trim()) {
				setTags([...tags, { key: newTagKey, value: newTagValue, defaultTag: false }]);
				setNewTagKey('');
				setNewTagValue('');
			}
		} catch (error) {
			////console.log(error , "Error  in addTag")	
		}

	};
	// Remove Tags is used remove Tags from 
	const removeTag = (index) => {
		const newTags = tags.filter((_, i) => i !== index);
		setTags(newTags);
	};
	// Edit Tags is used to Edit the Tags in the Environement 
	const editTag = (index, key, value) => {
		const newTags = [...tags];
		newTags[index] = { ...newTags[index], key, value };
		setTags(newTags);
	};



	////SQ_NO_3.4 Function to handle input change and filter data
	const handleInputChange2 = (fieldName, e) => {
		try {
			const value = e.target.value;
			//console.log("value222",value);
	
			setInputStates(prev => ({
				...prev,
				[fieldName]: {
					...prev[fieldName],
					inputValue: value,
				}
			}));
	
			const event = {
				target: {
					name: fieldName,
					value: value, // Use user's name as the value
					type: 'text', // Assuming it's a text input
				}
			};
	
	
	
			handleInputChange(event);
	
			let filteredUsers = [];
			////SQ_NO_3.5 Filter `nodeDetails` to "modules" users
			if (value) {
				if (value.startsWith("modules.")) {
					const nestedValue = value.slice(8);
					////console.log("nestedValue",nestedValue);
	
					const parts = nestedValue.split('.');
					////console.log("parts",parts);
	
					if (parts.length === 1) {
						filteredUsers = nodeDetails
							.filter(local =>
								local.name.toLowerCase().includes(parts[0].toLowerCase())
							)
							.map(local => ({
								...local,
								type: "modules",
								level: 1
							}));
						//console.log("usss",filteredUsers);
	
	
						if (filteredUsers.length === 1) {
							const userIds = filteredUsers
								?.filter(user => user.level === 1) // Filter users with level 1
								?.map(user => user.id) // Map to get their ids
								.join(','); // Join the IDs into a single string, separated by a comma
	
							// //console.log("userIds2", userIds);
	
							if (userIds?.length > 0) {
								//console.log("usss2", userIds);
	
								// Set the updated IDs against fieldName
								setnodeId({ [fieldName]: userIds });
							}
						}
	
	
	
					} else if (parts.length === 2) {
						const local = nodeDetails.find(l => l.name === parts[0]);
						////console.log("locallocal",local);
	
						if (local && local.value) {
							filteredUsers = Object.keys(local.value)
								.filter(key =>
									key.toLowerCase().includes(parts[1].toLowerCase())
								)
								.map(key => ({
									name: key,
									type: "modules",
									level: 2,
									parent: parts[0]
								}));
						}
					}
				}
				////SQ_NO_3.14 Filter `dataFile` to "data" users
	
				else if (value.startsWith("data.")) {
					const nestedValue = value.slice(5);
					////console.log("nestedValue",nestedValue);
	
					const parts = nestedValue.split('.');
					////console.log("parts",parts);
	
					if (parts.length === 1) {
						filteredUsers = dataFile
							.filter(local =>
								local.name.toLowerCase().includes(parts[0].toLowerCase())
							)
							.map(local => ({
								...local,
								type: "data",
								level: 1
							}));
					}
					else if (parts.length === 3) {
						const local = dataFile.find(l => l.name === `${parts[0]}.${parts[1]}`);
						////console.log("locallocal",local);
	
						if (local && local.value) {
							filteredUsers = Object.keys(local.value)
								.filter(key =>
									key.toLowerCase().includes(parts[2].toLowerCase())
								)
								.map(key => ({
									name: key,
									type: "data",
									level: 2,
									parent: `${parts[0]}.${parts[1]}`
								}));
						}
					}
				}
			}
			////console.log("filteredUsers",filteredUsers);
	
			setInputStates(prev => ({
				...prev,
				[fieldName]: {
					...prev[fieldName],
					users: filteredUsers, // Store filtered users with the added type key
				}
			}));
		} catch (error) {
			console.log(error,"error");
			
		}
	
	};

	//SQ_NO_3.8 Function to handle selection of a suggestion and also updated the node_id against the fields
	const handleUserSelect = (fieldName, user) => {
		////console.log(user,"nodeID22");
try {
	let value;
	if (user.level === 1) {
		value = `modules.${user.name}`;
	} else if (user.level === 2) {
		value = `modules.${user.parent}.${user.name}`;
	}

	const event = {
		target: {
			name: fieldName,
			value: value,
			type: 'text',
		}
	};
	const event2 = {
		target: {
			name: fieldName,
			value: user.id,
			type: 'text',
		}
	};

	if (user?.level == 1) {
		handleNodeIdChange(event2);
	}
	setInputStates(prev => ({
		...prev,
		[fieldName]: {
			...prev[fieldName],
			inputValue: value,
			users: [], // Clear dropdown after selection
		}
	}));
	////console.log(user , "user Hree")

	handleInputChange(event);

	helperFuntion(fieldName, user.level)
} catch (error) {
	console.log(error);
	
}
	
	};
	const helperFuntion = (fieldName, level) => {
	try {
		if (level == 2) {
			setFieldName(fieldName)
		}	
	} catch (error) {
		console.log(error);

	}
		
	}
	useEffect(() => {
		if (fieldName) {
			handleFieldBlur(fieldName)
		}
	}, [fieldName])
	//SQ_NO_3.17  Function to handle selection of a suggestion and also updated the node_id against the fields
	const handleUserSelect3 = (fieldName, user) => {
		////console.log("user00",user);
try {
	let value;
		if (user.level === 1) {
			value = `data.${user.name}`;
		} else if (user.level === 2) {
			value = `data.${user.parent}.${user.name}`;
		}

		const event = {
			target: {
				name: fieldName,
				value: value,
				type: 'text',
			}
		};

		setInputStates(prev => ({
			...prev,
			[fieldName]: {
				...prev[fieldName],
				inputValue: value,
				users: [], // Clear dropdown after selection
			}
		}));
		////console.log(user , "user Hree")

		// handleInputChange(event);
		// handleFieldBlur(fieldName)
		handleInputChange(event);

	helperFuntion(fieldName, user.level)
} catch (error) {
	console.log(error);
	
}
		
	};
	const handleUserSelect2 = (fieldName, user) => {
	try {
		let value;
		if (user.level === 1) {
			value = `modules.${user.name}`;
		} else if (user.level === 2) {
			value = `modules.${user.parent}.${user.name}`;
		}

		const event = {
			target: {
				name: fieldName,
				value: value,
				type: 'text',
			}
		};

		setInputStates(prev => ({
			...prev,
			[fieldName]: {
				...prev[fieldName],
				inputValue: value,
				users: [], // Clear dropdown after selection
			}
		}));
		////console.log(user , "user Hree")
		setInputValues(prev => ({
			...prev,
			[fieldName]: "guruuuu" // Set input value for specific field
		}));

		handleInputChange(event);
	} catch (error) {
		console.log(error);
		
	}
	

	};
	const handleUserSelect4 = (fieldName, user) => {
		try {
			let value;
		if (user.level === 1) {
			value = `data.${user.name}`;
		} else if (user.level === 2) {
			value = `data.${user.parent}.${user.name}`;
		}

		const event = {
			target: {
				name: fieldName,
				value: value,
				type: 'text',
			}
		};

		setInputStates(prev => ({
			...prev,
			[fieldName]: {
				...prev[fieldName],
				inputValue: value,
				users: [], // Clear dropdown after selection
			}
		}));
		////console.log(user , "user Hree")
		setInputValues(prev => ({
			...prev,
			[fieldName]: "guruuuu" // Set input value for specific field
		}));

		handleInputChange(event);
		} catch (error) {
			console.log(error);
			
		}
		
	};

	// setFormValues(prev => ({
	// 	...prev,
	// 	[fieldName]: {
	// 		...prev[fieldName],
	// 		inputValue: {
	// 			...prev[fieldName]?.inputValue,
	// 			values: [...(prev[fieldName]?.inputValue?.values || []), inputValue], // Add new item
	// 		}
	// 	}
	// }));
	// Function to handle changes in input fields
	const handleFieldChange = (fieldName, key, value, index) => {
		// Update form field values
		setFormFieldValues(prevValues => {
			// Create a deep copy of previous values
			const updatedValues = { ...prevValues };

			// Ensure the fieldName array exists
			if (!updatedValues[fieldName]) {
				updatedValues[fieldName] = [];
			}

			// Create or update the specific index
			updatedValues[fieldName][index] = {
				...updatedValues[fieldName][index],
				[key]: value
			};

			return updatedValues;
		});

		// Update form data similarly
		setFormData(prevData => {
			// Create a deep copy of previous data
			const updatedData = { ...prevData };

			// Ensure the fieldName array exists
			if (!updatedData[fieldName]) {
				updatedData[fieldName] = [];
			}

			// Create or update the specific index
			updatedData[fieldName][index] = {
				...updatedData[fieldName][index],
				[key]: value
			};

			return updatedData;
		});
	};

	////console.log("formFieldValues3",formFieldValues);


	const handleSelectChange = (selectedOptions, fieldName) => {
		const values = selectedOptions ? selectedOptions.map(option => option.value) : [];
		setFormFieldValues(prev => ({
			...prev,
			[fieldName]: {
				...prev[fieldName],
				values // Update the values array with selected options
			}
		}));
	};

	const [selectedOptions, setSelectedOptions] = useState([]);
	const [inputValue, setInputValue] = useState('');
	const [inputValue2, setInputValue2] = useState('');

	const customStyles2 = {
		control: (base) => ({
			...base,
			border: '1px solid #ccc',
			boxShadow: 'none',
			borderRadius: '4px',
			width: "240px",
			minHeight: '50px',
		}),
		multiValue: (base) => ({
			...base,
			display: 'flex',
			alignItems: 'center',
			marginRight: '5px',
		}),
		multiValueLabel: (base) => ({
			...base,
			padding: '0',
		}),
		multiValueRemove: (base, { data }) => ({
			...base,
			display: data.canDelete ? 'flex' : 'none', // Show or hide based on canDelete
		}),
		valueContainer: (base) => ({
			...base,
			paddingRight: selectedOptions.length > 2 ? '30px' : '10px', // Adjust padding for count display
			position: 'relative',
		}),
	};
	const customStyles = {
		control: (base) => ({
			...base,
			border: '1px solid #ccc',
			boxShadow: 'none',
			borderRadius: '4px',
			width: "440px",
			minHeight: '50px',
		}),
		multiValue: (base) => ({
			...base,
			display: 'flex',
			alignItems: 'center',
			marginRight: '5px',
		}),
		multiValueLabel: (base) => ({
			...base,
			padding: '0',
		}),
		multiValueRemove: (base, { data }) => ({
			...base,
			display: data.canDelete ? 'flex' : 'none', // Show or hide based on canDelete
		}),
		valueContainer: (base) => ({
			...base,
			paddingRight: selectedOptions.length > 2 ? '30px' : '10px', // Adjust padding for count display
			position: 'relative',
		}),
	};
	const MultiValue = ({ data, removeProps }) => (
		<span className="blue-batch font-12 d-flex gap-1  align-items-center font-medium">
			{data.label}
			<a {...removeProps}>
				<img src="images/tags-close-icon.svg" alt="close-icon" className="icon-custom-filter" />
			</a>
		</span>
	);
	const customComponents = {
		MultiValue,
	};
	const [formValues, setFormValues] = useState({});
	const handleChange = (selectedOptions, fieldName) => {
		// Get current values from state

		try {
			const currentValues = formValues[fieldName]?.inputValue?.values || [];
			console.log("removedIndices", currentValues);
	
			// Determine the new values based on selected options
			const newValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
	
	
			const currentSelectNodeIds = selectnodeIds[fieldName] || [];
	
			// 2. Extract selected values from selectedOptions
			const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
	
			const valueNodeIdPairs = currentValues.map((value, index) => ({
				value: value,
				nodeId: currentSelectNodeIds[index]
			}));
			// 4. Prepare updated values and node IDs
			let updatedValues = [];
			let updatedNodeIds = [];
			let usedNodeIds = new Set(); // Track which nodeIds are already used
	
			selectedValues.forEach(selectedValue => {
				const firstMatchingPair = valueNodeIdPairs.find(pair =>
					pair.value === selectedValue && !usedNodeIds.has(pair.nodeId)
				);
	
				if (firstMatchingPair) {
					updatedValues.push(selectedValue);
					updatedNodeIds.push(firstMatchingPair.nodeId);
					usedNodeIds.add(firstMatchingPair.nodeId);
				}
			});
	
			// 7. Update selectnodeId state
			setselectnodeIds(prev => ({
				...prev,
				[fieldName]: updatedNodeIds
			}));
	
			// Log for verification
			console.log("removedPairs", valueNodeIdPairs);
			console.log("removedPairs", selectedValues);
			console.log("Updated Values:", updatedValues);
			console.log("removedPairs2", updatedNodeIds);
	
	
			// Update formValues state
			setFormValues(prev => {
				const fieldData = prev[fieldName] ?? {};
				const inputData = fieldData.inputValue ?? {};
	
				return {
					...prev,
					[fieldName]: {
						...fieldData,
						inputValue: {
							...inputData,
							values: newValues // Update to new selected values
						}
					}
				};
			});
	
			// Update formData state
			setFormData(prev => {
				const fieldData = prev[fieldName] ?? {};
	
				return {
					...prev,
					[fieldName]: {
						...fieldData,
						values: newValues // Update to new selected values
					}
				};
			});
	
	
		} catch (error) {
		console.log(error);
			
		}
	
	};

	const [inputValues, setInputValues] = useState({}); // Store input values per field
	// Update handleKeyDown to accept fieldName as a parameter
	const handleKeyDown = (event, fieldName) => {
	try {
		if (event.key === 'Enter' &&
			inputValues[fieldName]) {
			//console.log(inputValues[fieldName], "INput Fields name", fieldName)

			if (inputValues[fieldName].startsWith("data.")) {
				//console.log(selectnodeIds, "selectnodeIds")
				const currentSelection = selectnodeIds[fieldName] || [];
				const updatedUserIds = [...currentSelection, inputValues[fieldName]]; // Spread existing IDs into a new array
				//console.log(updatedUserIds, "updatedUserIds")
				setselectnodeIds(prev => ({
					...prev,
					[fieldName]: updatedUserIds // Update the specific field with unique user IDs
				}));
			}


			const newValue = inputValues[fieldName];

			setFormValues(prev => {
				const fieldData = prev[fieldName] ?? {};
				const inputData = fieldData.inputValue ?? {};
				const valuesArray = Array.isArray(inputData.values) ? inputData.values : [];

				return {
					...prev,
					[fieldName]: {
						...fieldData,
						inputValue: {
							...inputData,
							values: [...valuesArray, newValue] // Add new item if array is valid
						}
					}
				};
			});

			setFormData(prev => {
				const fieldData = prev[fieldName] ?? {};
				const valuesArray = formValues[fieldName]?.inputValue?.values || [];

				return {
					...prev,
					[fieldName]: {
						...fieldData,
						values: [...valuesArray, newValue] // Add new item if array is valid
					}
				};
			});

			setInputValues(prev => ({
				...prev,
				[fieldName]: '' // Clear specific field's input after adding
			}));
			//console.log("skkk", selectnodeIds2);

			// Ensure selectnodeIds2 is handled as an array of strings
			const newIds = Array.isArray(selectnodeIds2) ? selectnodeIds2 : [selectnodeIds2];

			setselectnodeIds(prev => {
				const existingIds = prev[fieldName] || [];
				//console.log("setselectnodeIds2",existingIds);

				const updatedIds = [...existingIds, ...newIds];
				//console.log("setselectnodeIds1",updatedIds);

				return {
					[fieldName]: updatedIds
				};
			});




		}
		handleFieldBlur(fieldName)
	} catch (error) {
		console.log(error);
		
	}
	};


	const handleChange2 = (selectedOptions, fieldName) => {
		const values = selectedOptions ? selectedOptions.map(option => option.value) : [];
		////console.log("values33",values);

		setFormValues(prev => {
			// Ensure we have the nested structures we expect
			const fieldData = prev[fieldName] ?? {};
			const inputData = fieldData.inputValue ?? {};
			const valuesArray = Array.isArray(inputData.values) ? inputData.values : [];

			return {
				...prev,
				[fieldName]: {
					...fieldData,
					inputValue: {
						...inputData,
						values: [...values] // Add new item if array is valid
					}
				}
			};
		});
		setFormData(prev => {
			// Ensure we have the nested structures we expect
			const fieldData = prev[fieldName] ?? {};
			const inputData = fieldData.inputValue ?? {};
			const valuesArray = formValues[fieldName]?.inputValue?.values || [];
			////console.log("valuesArray",formValues[fieldName]?.inputValue?.values);


			return {
				...prev,
				[fieldName]: {
					...fieldData,

					values: [...valuesArray] // Add new item if array is valid

				}
			};
		});
	};


	// Update handleKeyDown to accept fieldName as a parameter
	const handleKeyDown2 = (event, fieldName, index) => {
		////console.log("inndex",index);

		if (event.key === 'Enter' && inputValue2) {
			// Add new value to the list
			////console.log()
			// setFormValues(prev => ({
			//     ...prev,
			//     [fieldName].inputValue: {
			//         ...prev[fieldName].inputValue,
			//         ["values"]: [...prev[fieldName]?.values, inputValue],
			// 		 // Add new item
			//     }
			// }));
			setFormValues(prev => {
				// Ensure we have the nested structures we expect
				const fieldData = prev[fieldName] ?? {};
				const inputData = fieldData?.inputValue ?? {};
				const valuesArray = Array.isArray(inputData.values) ? inputData.values : [];

				return {
					...prev,
					[fieldName]: {
						...fieldData,
						inputValue: {
							...inputData,
							values: [...valuesArray, inputValue2] // Add new item if array is valid
						}
					}
				};
			});



			////console.log("formvalues2222",formValues);

			setFormData(prev => {
				// Ensure we have the nested structures we expect
				const fieldData = prev[fieldName] ?? {};
				const inputData = fieldData.inputValue ?? {};
				const valuesArray = formValues[fieldName]?.inputValue?.values || [];
				////console.log("valuesArray",formValues[fieldName]?.inputValue?.values);


				return {
					...prev,
					[fieldName]: {
						...fieldData,

						values: [...valuesArray, inputValue2] // Add new item if array is valid

					}
				};
			});



			setInputValue2(''); // Clear input after adding
		}
	};
	////console.log("formvalues2",formValues);
	////console.log("formvalues22",formData);

	const handleAddObject = (fieldName) => {
		setconfigureDetails(prevDetails => {
			const newFields = prevDetails.data.fields.map(field => {
				if (field.name === fieldName && field.type === 'list(object)') {
					// Duplicate the object
					const newObject = { ...field }; // Create a shallow copy of the field

					// Ensure UserValue is an array before spreading
					newObject.UserValue = [
						...(Array.isArray(newObject.UserValue) ? newObject.UserValue : []),
						newObject.UserValue ? newObject.UserValue[0] : undefined
					];

					// Ensure value is also set correctly
					newObject.value = [
						...(Array.isArray(newObject.UserValue) ? newObject.UserValue : []),
						newObject.UserValue ? newObject.UserValue[0] : undefined
					];

					return newObject; // Return the modified field

				}
				return field; // Return unchanged fields
			});
			////console.log("newFields",newFields);

			return {
				...prevDetails,
				data: {
					...prevDetails.data,
					fields: newFields // Update fields with the new list
				}
			};
		});
	};
	////console.log("working",configureDetails);
	//SQ_NO_3.31 to store onchange values and render dropdown values based on parts
	const handleInputChange3 = (input, fieldName) => {
		try {
			setInputValues(prev => ({
				...prev,
				[fieldName]: input // Set input value for specific field
			}));
			setInputStates(prev => ({
				...prev,
				[fieldName]: {
					...prev[fieldName],
					inputValue: input,
				}
			}));
	
			const event = {
				target: {
					name: fieldName,
					value: input, // Use user's name as the value
					type: 'text', // Assuming it's a text input
				}
			};
	
			let filteredUsers = [];
	
	
			if (input) {
				if (input.startsWith("modules.")) {
					const nestedValue = input.slice(8);
					////console.log("nestedValue",nestedValue);
	
					const parts = nestedValue.split('.');
					////console.log("parts",parts);
					//SQ_NO_3.32 Filter `nodeDetails` for "modules." users
					if (parts.length === 1) {
						filteredUsers = nodeDetails
							.filter(local =>
								local.name.toLowerCase().includes(parts[0].toLowerCase())
							)
							.map(local => ({
								...local,
								type: "modules",
								level: 1
							}));
	
						if (filteredUsers.length == 1) {
							const userIds = filteredUsers
								?.filter(user => user.level === 1) // Filter users with level 1
								?.map(user => user.id); // Map to get their ids
	
							////console.log("userIds2", userIds);
	
							if (userIds?.length >= 1) {
								// Assuming you have a way to get the current state for fieldName
								// const currentSelection = selectnodeIds[fieldName] || []; // Get existing IDs or an empty array if none exist
	
								// Combine existing IDs with new userIds
								// const combinedUserIds = [...currentSelection, ...userIds];
	
								// Create a Set to filter out duplicates and convert it back to an array
								const updatedUserIds = Array.from(new Set(userIds));
	
								// Set the updated IDs against fieldName
								setselectnodeIds2(updatedUserIds);
							}
						}
	
					} else if (parts.length === 2) {
						const local = nodeDetails.find(l => l.name === parts[0]);
						////console.log("locallocal",local);
	
						if (local && local.value) {
							filteredUsers = Object.keys(local.value)
								.filter(key =>
									key.toLowerCase().includes(parts[1].toLowerCase())
								)
								.map(key => ({
									name: key,
									type: "modules",
									level: 2,
									parent: parts[0]
								}));
						}
					}
					////console.log("filteredUserscheck",filteredUsers);
	
				} else if (input.startsWith("data.")) {
					const nestedValue = input.slice(5);
					////console.log("nestedValue",nestedValue);
	
					const parts = nestedValue.split('.');
					//console.log("parts", parts);
	
					if (parts.length === 1) {
						filteredUsers = dataFile
							.filter(local =>
								local.name.toLowerCase().includes(parts[0].toLowerCase())
							)
							.map(local => ({
								...local,
								type: "data",
								level: 1
							}));
	
	
	
	
	
	
					}
					else if (parts.length === 2) {
						filteredUsers = dataFile
							.filter(local =>
								local.name.toLowerCase().includes(parts[0].toLowerCase())
							)
							.map(local => ({
								...local,
								type: "data",
								level: 1
							}));
	
						if (filteredUsers.length === 1) {
							// Get existing IDs or an empty array if none exist
							const currentSelection = selectnodeIds[fieldName] || [];
							//console.log("currentSelection", currentSelection, input);
	
							// Generate a unique ID with a random number appended to a base name
							// const baseName = "data_ID"; // Base name for the ID
							// const randomNumber = Math.floor(Math.random() * 10000); // Generate a random number between 0 and 9999
							// const uniqueId = `${baseName}_${randomNumber}`; // Combine base name with random number
	
							// Check if the unique ID is already in the current selection
							if (!currentSelection.includes(input)) {
								// Create a new array that includes all current IDs and then push the unique ID
								const updatedUserIds = [...currentSelection, inputValues[fieldName]]; // Spread existing IDs into a new array
	
								// Create a Set to filter out duplicates and convert it back to an array
								const uniqueUserIds = updatedUserIds // Use Set to ensure uniqueness
	
								// Log the unique user IDs for debugging
								//console.log("uniqueUserIds", uniqueUserIds);
	
								// Set the updated IDs against fieldName
								// setselectnodeIds(prev => ({
								// 	...prev,
								// 	[fieldName]: uniqueUserIds // Update the specific field with unique user IDs
								// }));
							}
						}
					} else if (parts.length === 3) {
						const local = dataFile.find(l => l.name === `${parts[0]}.${parts[1]}`);
						//console.log("locallocal2", local);
	
						if (local && local.value) {
							filteredUsers = Object.keys(local.value)
								.filter(key =>
									key.toLowerCase().includes(parts[2].toLowerCase())
								)
								.map(key => ({
									name: key,
									type: "data",
									level: 2,
									parent: `${parts[0]}.${parts[1]}`
								}));
						}
	
	
					}
	
				}
			}
	
	
			//console.log("filteredUsers", filteredUsers);
	
			setInputStates(prev => ({
				...prev,
				[fieldName]: {
					...prev[fieldName],
					users: filteredUsers, // Store filtered users with the added type key
				}
			}));
		
		} catch (error) {
		console.log(error);
			
		}

	};


	useEffect(() => {
		// Initialize Bootstrap tooltips
		//console.log("selected nodes", selectnodeIds);

	}, [selectnodeIds]);
	useEffect(() => {
		// Initialize Bootstrap tooltips
		const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
		tooltipTriggerList.forEach(tooltipTriggerEl => {
			new window.bootstrap.Tooltip(tooltipTriggerEl);
		});

		// Cleanup function to dispose of tooltips on unmount
		return () => {
			tooltipTriggerList.forEach(tooltipTriggerEl => {
				const tooltipInstance = window.bootstrap.Tooltip.getInstance(tooltipTriggerEl);
				if (tooltipInstance) {
					tooltipInstance.dispose();
				}
			});
		};
	}, []);

	const formatFieldName = (str) => {
		try {
			console.log(str, "Stringgggg here")
			return str
				.split('_')
				.map(word => word.charAt(0).toUpperCase() + word.slice(1))
				.join(' ');
		} catch (error) {
			console.log(str, "Stringgggg sdfgds")

			console.log(error, "Errror rherer")
		}
		// Split by underscores and capitalize each word

	};


	useEffect(() => {
		//console.log(inputStates, "Input States")
	}, [inputStates])

	//SQ_NO_2.5- to render dynamic fields
	const renderField = (field) => {
		try {
			let options = [];
			const dependencyValue = isDependencyFulfilled(field.dependsOn);
			////console.log(dependencyValue, "Dependency Valueeee");

			// Disable if dependency is not fulfilled
			const isDisabled = false; // Adjust this logic as needed
			////console.log(isDependencyFulfilled(field.dependsOn), field.name, "Here the Logs", field);

			// Use the new structure's name for form data
			let fieldValue = clearedFields[field.name] ? '' : (formData[field.name] || '');
			////console.log("fieldValue222",fieldValue);

			if (dependencyValue) {
				fieldValue = dependencyValue;
			}

			// Handle options for dropdowns, if applicable
			if (field?.value && typeof (field.value) == "string") {
				// Assuming value is now stored in 'value' and is a JSON string
				try {
					options = JSON.parse(field.value);
				} catch (error) {
					console.error("Error parsing options:", error);
				}
			}
			// if (field?.value && typeof (field.value) == "object") {
			// 	// Assuming value is now stored in 'value' and is a JSON string
			// 	try {
			// 		fieldValue = JSON.stringify(field.value);
			// 		console.log("typeof (field.value)2", JSON.stringify(field.value));
			// 		console.log("typeof (field.value)3", field.value);


			// 	} catch (error) {
			// 		console.error("Error parsing options:", error);
			// 	}
			// }


			switch (field.type) {
				case 'number':
					const formattedName1 = formatFieldName(field.name);
					return (
						<div className="my-4" key={field.name} style={{ position: 'relative' }}>
							<label className="form-label font-14 font-medium black-v2" htmlFor={field.name}>
								{field.name || field.description}
								<button type="button" className="btn p-0 ms-1 shadow-none" data-bs-toggle="tooltip"
									data-bs-placement="right"
									data-bs-title={field.description}>
									<img src="images/tooltip-icon.svg" alt="tooltip-icon" className="toolttip-icon" />
								</button>
							</label>
							<input
						type="text"
						className="form-control custom-dropdown arch-border shadow-none"
						id={field.name}
						name={field.name}
						value={formData[field.name]||fieldValue}
						onChange={handleInputChange}
						onBlur={() => handleFieldBlur(field.name)}
						placeholder={`Enter ${formattedName1}`}

						required={field.required}
						disabled={isDisabled ||  viewMode}
					/>
							
						</div>

					);
					
				case 'string':
					const formattedName2 = formatFieldName(field.name);
					return (
						<div className="my-4" key={field.name} style={{ position: 'relative' }} onBlur={() => handleFieldBlur(field.name)}
						>
							<label className="form-label font-14 font-medium black-v2" htmlFor={field.name}>
								{field.name || field.description}
								<button type="button" className="btn p-0 ms-1 shadow-none" data-bs-toggle="tooltip"
									data-bs-placement="right" data-bs-title={field.description}>
									<img src="images/tooltip-icon.svg" alt="tooltip-icon" className="toolttip-icon" />
								</button>
							</label>
							<input
								type="text"
								className="form-control custom-dropdown font-14 font-medium black-v2"
								placeholder={`Enter ${formattedName2}`}
								value={inputStates[field.name]?.inputValue} // Use inputValue for display
								onChange={(e) => handleInputChange2(field.name, e)} // Handle change to filter suggestions
							/>

							{/* Check for suggestions configuration */}
							{field?.configSuggestion && (
								<>
									{/* Handle suggestions when inputValue starts with "modules." */}
									{inputStates[field.name]?.inputValue.startsWith("modules.") && inputStates[field.name]?.users?.length > 0 && (
										<ul className="dropdown-menu cust-drop-menu custom-form show" style={{ position: 'absolute', zIndex: 1000 ,width:"100%", maxHeight: '20vh', overflowY: 'auto'}}>
											{inputStates[field.name].users.map((user, index) => (
												<li key={index}
													className="dropdown-item black-v2"
													onClick={() => handleUserSelect(field.name, user)}>
													{user.name} {/* Display username */}
												</li>
											))}
										</ul>
									)}

									{/* Handle suggestions when inputValue starts with "data." */}
									{inputStates[field.name]?.inputValue.startsWith("data.") && inputStates[field.name]?.users?.length > 0 && (
										<ul className="dropdown-menu cust-drop-menu custom-form show" style={{ position: 'absolute', zIndex: 1000 ,width:"100%", maxHeight: '20vh', overflowY: 'auto'}}>
											{inputStates[field.name].users.map((user, index) => (
												<li key={index}
													className="dropdown-item black-v2"
													onClick={() => handleUserSelect3(field.name, user)}>
													{user.name} {/* Display username */}
												</li>
											))}
										</ul>
									)}
								</>
							)}
						</div>
					);

				case 'list(string)':
					////console.log(inputValue , "Input Value")
					////console.log(formValues[field.name] , "Input Value")
					const formattedName = formatFieldName(field.name);

					if (typeof (field.value) == "string") {
						field.value = JSON.parse(field.value)
					}
					return (
						<div className="my-4" key={field.name} style={{ position: 'relative' }} onBlur={() => handleFieldBlur(field.name)}
						>
							<label className="form-label font-14 font-medium black-v2" htmlFor={field.name}>
								{field.name || field.description}
								<button type="button" className="btn p-0 ms-1 shadow-none" data-bs-toggle="tooltip"
									data-bs-placement="right"
									data-bs-title={field.description}>
									<img src="images/tooltip-icon.svg" alt="tooltip-icon" className="toolttip-icon" />
								</button>
							</label>
							<Select
								isMulti
								className='custom-select-form cust-select-width padding-left'
								onChange={(selectedOptions) => handleChange(selectedOptions, field.name)}
								placeholder={`Enter ${formattedName}`}
								styles={customStyles}
								noOptionsMessage={() => null}
								onInputChange={(input) => handleInputChange3(input, field.name)} // Update input state for specific field
								inputValue={inputValues[field.name] || ''} // Control the input value for react-select
								onKeyDown={(event) => handleKeyDown(event, field.name)}
								value={
									formValues[field.name]?.inputValue.values.length !== 0 ?
										formValues[field.name]?.inputValue.values.map(item => ({ value: item, label: item })) :
										[]
								}
								components={customComponents}
							/>
							{field?.configSuggestion && (<>

								{inputStates[field.name]?.users?.length > 0 && (
									<ul className="dropdown-menu cust-drop-menu custom-form show"
										style={{ position: 'absolute', zIndex: 1000 ,width:"100%", maxHeight: '20vh', overflowY: 'auto'}}>
										{inputStates[field.name].users.map((user, index) => (
											<li key={index}
												className="dropdown-item black-v2"
												onClick={() => {
													inputStates[field.name] == "modules." ?
														handleUserSelect2(field.name, user) :
														handleUserSelect4(field.name, user)
												}}>
												{user.name} {/* Display username */}
											</li>
										))}
									</ul>
								)}</>)}

						</div>
					);
				case 'map(map(any))':
					return (
						<div className="my-4" key={field.name}   >
							<label className="form-label font-14 font-medium black-v2" htmlFor={field.name}>
								{field.name || field.description}
								<button type="button" className="btn p-0 ms-1 shadow-none" data-bs-toggle="tooltip"
									data-bs-placement="right"
									data-bs-title={field.description}>
									<img src="images/tooltip-icon.svg" alt="tooltip-icon" className="toolttip-icon" />
								</button>
							</label>
							<div className="generate-code-container2 mt-4 mb-2 w-100">
								<div className=" theme-border-top code-editor border rounded-3" onBlur={() => { handleFieldBlur(field.name) }}>

									<MonacoEditor
										width="100%"
										height="100"
										language="javascript"
										value={fieldValue}
										theme={darkTheme == "true" ? 'vs-dark' : 'vs-light'}
										options={{
											selectOnLineNumbers: true,
											automaticLayout: true,
										}}
										onChange={(newValue) => {
											////console.log("Raw input:", newValue); // Log raw input

											try {
												const trimmedValue = newValue.trim();
												////console.log("Trimmed input:", trimmedValue); // Log trimmed input

												// Check length for hidden characters
												////console.log("Length of trimmed input:", trimmedValue.length);

												// Attempt to parse JSON
												// const parsedJson = JSON.parse(trimmedValue);
												const formattedJson = JSON.stringify(trimmedValue, null, 2); // Pretty print with 2 spaces
												handleInputChange({ target: { name: field.name, value: newValue } });
											} catch (error) {
												console.error("Invalid JSON:", error.message); // Log specific error message
											}
										}}


									/>
								</div>
							</div>
						</div>
					);

				case 'bool':
					return (
						<div className="my-4" key={field.name}>
							<input
								className="form-check-input arch-check shadow-none me-2 black-v2"
								type="checkbox"
								id={field.name}
								name={field.name}
								checked={Boolean(fieldValue)}
								onChange={(e) => handleInputChange(e, field.name)}
								onBlur={() => handleFieldBlur(field.name)}
								disabled={isDisabled || viewMode}
							/>
							<label className="form-check-label font-14 font-medium black-v2" htmlFor={field.name}>
								{field.name || field.description}
								<button type="button" className="btn p-0 ms-1 shadow-none" data-bs-toggle="tooltip"
									data-bs-placement="right"
									data-bs-title={field.description}>
									<img src="images/tooltip-icon.svg" alt="tooltip-icon" className="toolttip-icon" />
								</button>
							</label>
						</div>
					);

				case 'dropdown':
					return (
						<div className="my-4" key={field.name}>
							<label className="form-label font-14 font-medium black-v2" htmlFor={field.name}>
								{field.name || field.description}
								<button type="button" className="btn p-0 ms-1 shadow-none" data-bs-toggle="tooltip"
									data-bs-placement="right"
									data-bs-title={field.description}>
									<img src="images/tooltip-icon.svg" alt="tooltip-icon" className="toolttip-icon" />
								</button>
							</label>
							<select
								className="form-select custom-dropdown arch-border shadow-none custom-border-box font-14 font-medium black-v2 custom-select"
								id={field.name}
								name={field.name}
								value={formData[field.name] || ''}
								onChange={handleInputChange}
								onBlur={() => handleFieldBlur(field.name)}
								required={field.required}
								disabled={isDisabled || viewMode}
							>
								<option value="">Select</option>
								{options.map((option, index) => (
									<option key={index} value={option}>
										{option}
									</option>
								))}
							</select>
						</div>
					);

				case 'radio':
					return (
						<div className="my-4" key={field.name}>
							<p className="font-14 font-medium black-v2">{field.name || field.description}</p>
							<div className="d-lg-flex">
								{options.map((option, index) => (
									<div className="form-check me-2 me-lg-4" key={`${index}_${option}`}>
										<input
											className="form-check-input shadow-none me-3"
											type="radio"
											name={field.name}
											id={`${field.name}_${option.trim()}`}
											value={option.trim()}
											checked={(formData[field.name] === option.trim()) || false}
											onChange={handleInputChange}
											onBlur={() => handleFieldBlur(field.name)}
											required={field.required}
											disabled={isDisabled || viewMode}
										/>
										<label className="form-check-label font-14 font-regular color-black" htmlFor={`${field.name}_${option.trim()}`}>
											{option.trim()}
										</label>
									</div>
								))}
							</div>
						</div>
					);

				case 'file upload':
					return (
						<div className="my-4" key={field.name}>
							<label className="form-label font-14 font-medium black-v2" htmlFor={field.name}>
								{field.name || field.description}
								<button type="button" className="btn p-0 ms-1 shadow-none" data-bs-toggle="tooltip"
									data-bs-placement="right"
									data-bs-title={field.description}>
									<img src="images/tooltip-icon.svg" alt="tooltip-icon" className="toolttip-icon" />
								</button>
							</label>
							<input
								type="file"
								className="form-control custom-dropdown arch-border shadow-none font-14 font-medium black-v2"
								id={field.name}
								name={field.name}
								onChange={(e) => handleInputChange(e, field.name)}
								onBlur={() => handleFieldBlur(field.name)}
								required={field.required}
								disabled={isDisabled || viewMode}
							/>
							{formData[field.name] ?(
								<div className="mt-2 font-14 font-medium black-v2">
									<strong>Selected file:</strong> {formData[field.name].fileName}
								</div>
							):<div className="invalid-feedback d-block">{error}</div>}

{error && (
										<div className="invalid-feedback d-block">{error}</div> // Ensure d-block is used to make it visible
									)}						</div>
					);
				// 				   case 'list(object)': // Handle the list of objects
				//             return (
				//                 <div className="my-4" key={field.name}>
				//                     <div className="d-flex justify-content-between align-items-center my-4">
				//                         <h4 className="form-label font-14 font-medium black-v2 mb-0">
				//                             {field.name}
				//                             <button type="button" className="btn p-0 ms-1 shadow-none" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-title="sample content">
				//                                 <img src="images/tooltip-icon.svg" alt="tooltip-icon" className="toolttip-icon " />
				//                             </button>
				//                         </h4>
				//                         <button type="button" className="button primary-button text-nowrap" onClick={() => handleAddObject(field.name)}>
				//                             <img src="images/add-icon.svg" alt="add-icon" className="me-2" />Add
				//                         </button>
				//                     </div>

				//                     {field.value.map((item, index) => (
				//                         <div className="border rounded-3 p-3 mb-3" key={index}>
				//                             <div className="row">
				//                                 {Object.entries(item).map(([key, value]) => {
				//                                     if (typeof value === 'string') {
				//                                         return (
				//                                             <div className="col-md-6 mb-4" key={key}>
				//                                                 <label className="form-label font-14 font-medium" htmlFor={key}>{key}</label>
				// 												<input
				// 														   type="text"
				// 														   id={key}
				// 														   className="form-control custom-form"
				// 														   value={formFieldValues[field.name][index]?.[key] || ''} // Bind value for string fields
				// 														   onChange={(e) => handleFieldChange(field.name, key, e.target.value,index)} // Update state on change
				// 														   onBlur={() => handleFieldBlur(field.name)}
				// />
				//                                             </div>
				//                                         );
				//                                     } else if (Array.isArray(value)) {
				//                                         return (
				//                                             <div className="col-md-12 mb-4" key={key}>
				//                                                 <label className="form-label font-14 font-medium">{key}</label>
				//                                                 <div className="region-input d-flex gap-2 cust-scroll flex-wrap">
				// 												<Select
				// 														isMulti
				// 														// options={field.options} // Assuming options are passed in configureDetails
				// 														// onChange={(selectedOptions) => handleSelectChange(selectedOptions, field.name)}
				// 														className="basic-multi-select"
				// 														classNamePrefix="select"
				// 														onChange={(selectedOptions) => handleChange2(selectedOptions, field.name)}		
				// 														placeholder="Type and press Enter to add"
				// 														styles={customStyles2}

				// 									noOptionsMessage={() => null}
				// 									onInputChange={(input) => setInputValue2(input)} // Update input state
				// 									inputValue={inputValue2} // Control the input value for react-select
				// 									onKeyDown={(event) => handleKeyDown2(event, field.name,index)} 						
				// 									value={
				// 										formValues[field.name]?.inputValue.values.length !== 0 ? 
				// 										formValues[field.name]?.inputValue.values.map(item => ({ value: item, label: item })) : 
				// 										[]
				// 										}													 components={customComponents}
				// 																 onBlur={() => handleFieldBlur(field.name)}


				// 													/>                                                </div>
				//                                             </div>
				//                                         );
				//                                     }
				//                                     return null; // Return null for unsupported types
				//                                 })}
				//                             </div>
				//                         </div>
				//                     ))}
				//                 </div>
				//             );
								default:
					return (
						<div className="my-4" key={field.name}  >
							<label className="form-label font-14 font-medium black-v2" htmlFor={field.name}>
								{field.name || field.description}
								<button type="button" className="btn p-0 ms-1 shadow-none" data-bs-toggle="tooltip"
									data-bs-placement="right"
									data-bs-title={field.description}>
									<img src="images/tooltip-icon.svg" alt="tooltip-icon" className="toolttip-icon" />
								</button>
							</label>
							<div className="generate-code-container3 mt-4 mb-2 w-100">
								<div className=" theme-border-top code-editor border rounded-3" onBlur={() => { handleFieldBlur(field.name) }}>
									<MonacoEditor
										width="100%"
										height="200"
										language="javascript"
										theme={darkTheme == "true" ? 'vs-dark' : 'vs-light'}
										value={fieldValue}
										options={{
											selectOnLineNumbers: true,
											automaticLayout: true,
										}}
										onChange={(newValue) => {
											////console.log("Raw input:", newValue); // Log raw input

											try {
												const trimmedValue = newValue.trim();
												////console.log("Trimmed input:", trimmedValue); // Log trimmed input

												// Check length for hidden characters
												////console.log("Length of trimmed input:", trimmedValue.length);

												// Attempt to parse JSON
												// const parsedJson = JSON.parse(trimmedValue);
												const formattedJson = JSON.stringify(trimmedValue, null, 2); // Pretty print with 2 spaces

												handleInputChange({ target: { name: field.name, value: newValue } });
											} catch (error) {
												console.error("Invalid JSON:", error.message); // Log specific error message
											}
										}}
										onBlur={() => {
											////console.log(`Blur event triggered for field: ${field.name}`); // Debug log
											handleFieldBlur(field.name);
										}}
									/>
								</div>
							</div>
						</div>
					);
			}
		} catch (err) {
			////console.log(err, "Error in the Render Field");
		}
	};


	const renderRepositoryField = () => {
		try {
			return (
				<div className="my-4">
					<label className="form-label font-14 font-medium black-v2" htmlFor="repository">Tag to Repository</label>
					<select
						className="form-select custom-dropdown arch-border shadow-none custom-border-box font-14 font-medium black-v2 custom-select"
						id="repository"
						name="repository"
						value={formData.repository_id || ''}
						onChange={handleInputChange}
						onBlur={() => handleFieldBlur('repository')}
						disabled={viewMode}
						required
					>
						<option value="">Select a repository</option>
						{repositories.map((repo) => (
							<option key={repo.repository_id} value={repo.repository_id}>{repo.repository_name}</option>
						))}
					</select>
				</div>
			);
		} catch (error) {
			////console.log(error , "Error in teh renderRepositoryField")
		}
	};
	// Depends on Function to Verify the Particular Field is associated with other 
	const isDependencyFulfilled = (dependsOn) => {
		try {
			if (!dependsOn || !connectedSourceNodes) return null;

			////console.log(dependsOn, "dependsOn");
			////console.log(connectedSourceNodes, "Connected Source Node");

			const sourceNode = connectedSourceNodes.find(
				(node) => node.data.serviceName === dependsOn.serviceName
			);
			////console.log(sourceNode, "SourceNode");

			if (sourceNode) {
				const field = sourceNode.data.fields.find(
					(f) => f.name === dependsOn.fieldName // Changed from fieldName to name
				);
				////console.log(field, "Field here");

				return field ? field.UserValue : null; // Return UserValue if field exists
			}

			return null; // Return null if no source node is found
		} catch (error) {
			////console.log(error, "Error in isDependencyFulfilled");
		}
	};

	// Main render
	return (
		<div className="col-md-4 mt-3 ps-1 pe-3 content-right-container">
			<div className="recommended-container pe-3 recommended-container-height">
				{/* Tab navigation */}
				<div className="custom-tab-scroll position-relative">
            <ul className="nav nav-pills d-flex gap-1 flex-nowrap mb-1" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button
                        className={`nav-link theme-tab cust-tab font-14 secondary-text-color font-medium px-3 py-2 ${activeTab === 'configure' ? 'active' : ''}`}
                        onClick={() => setActiveTab('configure')}
                        type="button"
                        role="tab"
                    >
                        Configure
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button
                        className={`nav-link theme-tab cust-tab font-14 secondary-text-color font-medium px-3 py-2 ${activeTab === 'recommendation' ? 'active' : ''}`}
                        onClick={() => setActiveTab('recommendation')}
                        type="button"
                        role="tab"
                    >
                        Recommendation
                    </button>
                </li>
            </ul>
            <div className="position-absolute" style={{ top: 0, right: 0 }}>
                <a href="#" onClick={onClose}>
                    <img
                        src="images/close-icon.svg"
                        alt="close-icon"
                        className="icon-custom-filter"
                        style={{ width: '20px', height: '24px' }} // Adjust size as needed
                    />
                </a>
            </div>
        </div>

				{/* Tab content */}
				<div className="d-flex my-4">
					<div className="me-4">
						<input
							className="form-check-input arch-check shadow-none me-2"
							type="checkbox"
							id="Existing"
							name="Existing"
							checked={Boolean(formData.Existing)}
							onChange={(e) => handleInputChange(e, "Existing")}
							onBlur={() => handleFieldBlur("Existing")}
						/>
						<label className="form-check-label font-14 font-medium black-v2" htmlFor="Existing">
							Existing
						</label>
					</div>
					<div style={{paddingLeft:"4%"}}>
						<input
							className="form-check-input arch-check shadow-none me-2"
							type="checkbox"
							id="New"
							name="New"
							checked={Boolean(formData.New)}
							onChange={(e) => handleInputChange(e, "New")}
							onBlur={() => handleFieldBlur("New")}
						/>
						<label className="form-check-label font-14 font-medium black-v2" htmlFor="New">
							New
						</label>
					</div>
				</div>
				<div className="tab-content" id="pills-tabContent">
					{/* Configure tab */}
					<div className={`tab-pane fade ${activeTab === 'configure' ? 'show active' : ''} pe-1 custom-area-scrl`} id="dash-Configure" role="tabpanel">
						<form onSubmit={handleFormSubmit}>
							{configureDetails?.id &&
								<div className="my-4">
									<label className="form-label font-14 font-medium black-v2">
										Unique Name
									</label>
									<input
										type="text"
										className="form-control custom-dropdown font-14 font-medium black-v2"
										placeholder={`Enter Unique Name`}
										id="unique_Name"
										name="unique_Name"
										value={formData[configureDetails.id]?.unique_Name || ''} // Access the value via configureDetails.id
										onChange={handleInputChange} // Handle change to filter suggestions
										onBlur={() => handleFieldBlur("unique_Name")}
									/>
									{/* {error && <span className="error-message">{error}</span>} Display error message */}

									{error && (
										<div className="invalid-feedback d-block">{error}</div> // Ensure d-block is used to make it visible
									)}

								</div>}

							{configureDetails?.data?.fields?.map(renderField)}
							{configureDetails?.data?.serviceName.toLowerCase() == "lambda" &&
							<>
								<div className="col-md-3 mb-4" >
									<label className="form-label font-14 font-medium black-v2" htmlFor="fileupload">

										File Upload
									</label>
									<input
										type="file"
										className="form-control custom-dropdown arch-border shadow-none font-14 font-medium black-v2"
										id="fileupload"
										name="fileupload"
										onChange={(e) => handleInputChange(e, "fileupload")}
										onBlur={() => handleFieldBlur("fileupload")}
									/>
										
								</div>
								{formData["fileuploadFileName"] && (
									<p style={{marginTop:"-14px"}}>Selected File: {formData["fileuploadFileName"]}</p>
								)}
								
{error && (
									<div className="invalid-feedback d-block" style={{marginTop:"-14px"}}>{error}</div> // Ensure d-block is used to make it visible
								)}	</>
							}

							{renderRepositoryField()}
							{/* <div className="mt-4 mb-2 w-100">
								<p className="font-14 font-medium black-v2">Tags</p>
								<div className="table-responsive mt-2 table-border">
									<table className="table custom-table rech-table">
										<thead>
											<tr>
												<th className="text-start border-0">Tags</th>
												<th className="text-start border-0">Value</th>
												<th className="text-start border-0"></th>
											</tr>
										</thead>
										
										<tbody className="manage-work">
										{tags.map((tag, index) => (
											tag?.value && tag?.key ? (
											<tr key={index}>
												<td className="text-start">
												<input
													type="text"
													className="form-control custom-dropdown arch-border shadow-none py-1 font-14 font-medium black-v2"
													value={tag.key}
													onChange={(e) => editTag(index, e.target.value, tag.value)}
													readOnly={tag.defaultTag}
													disabled={viewMode}
												/>
												</td>
												<td className="text-start">
												<input
													type="text"
													className="form-control custom-dropdown arch-border shadow-none py-1 font-14 font-medium black-v2"
													value={tag.value}
													onChange={(e) => editTag(index, tag.key, e.target.value)}
													readOnly={tag.defaultTag}
													disabled={viewMode}
												/>
												</td>
												<td className="text-center">
												{!tag.defaultTag && (
													<a 
													href="#"
													onClick={(e) => {
														if (!viewMode) {
														e.preventDefault();
														removeTag(index);
														}
													}}
													>
													<img 
														src="images/bin-icon-red.svg" 
														alt="delete-icon" 
														className="icon-width-14" 
													/>
													</a>
												)}
												</td>
											</tr>
											) : null
										))}
										
										<tr>
											<td className="text-start">
											<input
												type="text"
												className="form-control custom-dropdown arch-border shadow-none py-1 font-14 font-medium black-v2"
												placeholder="Enter Tag"
												value={newTagKey}
												disabled={viewMode}
												onChange={(e) => setNewTagKey(e.target.value)}
											/>
											</td>
											<td className="text-start">
											<input
												type="text"
												className="form-control custom-dropdown arch-border shadow-none py-1 font-14 font-medium black-v2"
												placeholder="Enter Value"
												value={newTagValue}
												disabled={viewMode}
												onChange={(e) => setNewTagValue(e.target.value)}
											/>
											</td>
											<td className="text-center">
											<a 
												href="#"
												onClick={(e) => {
												e.preventDefault();
												addTag();
												}}
											>
												<img 
												src="images/add-plus-icon.svg" 
												alt="add-icon" 
												className="icon-width-14" 
												/>
											</a>
											</td>
										</tr>
										</tbody>
									</table>
								</div>
							</div> */}

							{/* <button type="submit" className="btn btn-primary mt-3">Save Configuration</button> */}
						</form>
					</div>


					{/* Recommendation tab */}
					<div className={`tab-pane fade ${activeTab === 'recommendation' ? 'show active' : ''} pt-4 tab-scrl`} id="dash-Recommendation" role="tabpanel">
						{isLoadingRecommendations ? (
							<p>Loading recommendations...</p>
						) : recommendationError ? (
							<p className="error-message">{recommendationError}</p>
						) : recommendations.length === 0 ? (
							<p>No recommendations available.</p>
						) : (
							recommendations.map((recommendationGroup, groupIndex) => (
								<div key={groupIndex} className="recommendation-group mb-4">
									<h2 className="font-20 color-black font-bold mb-3">{recommendationGroup.head}</h2>
									<p className="font-16 font-regular secondary-text-color-v5 mb-4">{recommendationGroup.Description}</p>

									{recommendationGroup.recommendations.map((recommendation, index) => (
										<div key={index} className="recommendation-section mb-4">
											<div className="d-flex align-items-center gap-3 mb-3">
												<p className="font-16 color-black font-medium mb-0">{recommendation.service}</p>
												{recommendation.speedup !== "N/A" && (
													<button className="domain-btn font-14 font-medium bg-primary-dark-v1">
														<img src="images/faster-analysis.svg" alt="faster-analysis" className="dark-theme-icons" />
														{recommendation.speedup}
													</button>
												)}
											</div>
											<p className="font-16 font-regular secondary-text-color-v5">{recommendation.recommendation}</p>

											<ul className="nav pb-3 custom-border-bottom" id={`myTab-${groupIndex}-${index}`} role="tablist">
												<li className="nav-item" role="presentation">
													<button
														className="nav-link active work-tab pros-tab me-3"
														id={`home-tab-${groupIndex}-${index}`}
														data-bs-toggle="tab"
														data-bs-target={`#home-${groupIndex}-${index}`}
														type="button"
														role="tab"
														aria-controls={`home-${groupIndex}-${index}`}
														aria-selected="true"
													>
														Pros <span>({recommendation.pros.length})</span>
													</button>
												</li>
												<li className="nav-item" role="presentation">
													<button
														className="nav-link work-tab cons-tab"
														id={`profile-tab-${groupIndex}-${index}`}
														data-bs-toggle="tab"
														data-bs-target={`#profile-${groupIndex}-${index}`}
														type="button"
														role="tab"
														aria-controls={`profile-${groupIndex}-${index}`}
														aria-selected="false"
													>
														Cons <span>({recommendation.cons.length})</span>
													</button>
												</li>
											</ul>

											<div className="tab-content" id={`myTabContent-${groupIndex}-${index}`}>
												<div
													className="tab-pane fade show active"
													id={`home-${groupIndex}-${index}`}
													role="tabpanel"
													aria-labelledby={`home-tab-${groupIndex}-${index}`}
												>
													<h6 className="font-18 font-bold success-v4 my-3">Pros</h6>
													<ul className="work-custom-list ps-0 pb-3 custom-border-bottom">
														{recommendation.pros.map((pro, proIndex) => (
															<li key={proIndex} className="font-16 font-regular secondary-text-color-v5 mb-3">
																{pro.prosDescription}
															</li>
														))}
													</ul>
												</div>
												<div
													className="tab-pane fade"
													id={`profile-${groupIndex}-${index}`}
													role="tabpanel"
													aria-labelledby={`profile-tab-${groupIndex}-${index}`}
												>
													<h6 className="font-18 font-bold danger-red my-3">Cons</h6>
													<ul className="work-custom-list ps-0 pb-3 custom-border-bottom">
														{recommendation.cons.map((con, conIndex) => (
															<li key={conIndex} className="font-16 font-regular secondary-text-color-v5 mb-3">
																{con.consDescription}
															</li>
														))}
													</ul>
												</div>
											</div>
										</div>
									))}
								</div>
							))
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
export default ConfigureComponent;