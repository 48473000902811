import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { OrgContext, useOrgContext } from "../../ContextProvider/OrgContext";
import { FormContext } from "../Organization/Workload_Grid";
import { lang } from "moment";

const DeploySideBar = ({ activeItemName }) => {
    // const { language } = useOrgContext();
    const { selectedAccount, approvalStatus, selectedLangforDownload, setSelectedLangforDownload, providerName } = useContext(OrgContext)
    const[formData,setFormdata]=useState([])

    const[language,setLanguage]=useState("")

    useEffect(() => {
        
        const storedFormData = localStorage.getItem('formdata');

        if (storedFormData) {
          try {
            const formData = JSON.parse(storedFormData);
            setFormdata(formData)
            const language = formData?.language;
            setSelectedLangforDownload(language)
        
            console.log("Language:", language);

          } catch (error) {
            console.error('Error parsing form data from local storage', error);
          }
        } else {
          console.log('No formData found in local storage');
        }

    }, [])
    const [selectedLanguage, setSelectedLanguage] = useState(selectedLangforDownload || "");
    const [activeItem, setActiveItem] = useState(activeItemName);


    const navigate = useNavigate();
    const location = useLocation();
    console.log(location, "Loc")


    const [stateData, setStateData] = useState(null)
    useEffect(() => {
        console.log(location, "Locationsdfsf")
        if (location.state) {
            setStateData(location.state)
        }
    }, [location, stateData])

    const handleLanguageChange = (language) => {

        setSelectedLangforDownload(language)


    };

    console.log(selectedLanguage, "selectedLanguage")
    console.log(selectedLangforDownload, "selectedLanguage1")

    const [isCodeGenerator, setIsCodeGenerator] = useState(false)

    const handleNavigation = (route, item) => {
        try {


            setActiveItem(item);

            let navigationState = {
                selectLang: selectedLanguage,
                isRegenerate: false,
                activeItem: item,
                selectedAccount: selectedAccount,
                // selectedLangforDownload: selectedLangforDownload

            };
            if (location.state?.approvalId) {
                navigationState["approvalId"] = location.state.approvalId || null
            }
            if (location.state?.environmentId) {
                navigationState["environmentId"] = location.state.environmentId || null
            }

            // Set isCodeGenerator based on the item
            if (item === "CodeGeneration") {
                setIsCodeGenerator(true)
                navigationState.isCodeGenerator = true; // Set true for CodeGeneration

            } else if (item === "Deploy") {
                navigationState.isCodeGenerator = false; // Set false for Deploy
            }

            // Navigate with the constructed state
            navigate(route, { state: navigationState });
        } catch (error) {
            console.log(error, "Error")
        }
    };

    console.log(selectedLanguage, "selectedLanguage")

    const getLanguageImage = (language) => { 
        switch (language.toLowerCase()) {
            case 'cloud formation':
            case 'cloudformation':
                return 'cloud-formation-img.svg';
            case 'terraform':
                return 'terraform-img.svg';
            case 'arm':
                return 'arm-img.svg';
            default:
                return 'terraform-img.svg'; // Default image
        }
    };

    return (
        <div className="col-md-3 mt-3 custom-border-right ps-4 pe-0">
            <h3 className="font-18 font-medium black-v2 mb-3">Configure IaC</h3>
            <div className="dropdown mb-5">
                <button
                    type="button"
                    className="btn custom-dropdown d-flex justify-content-between align-items-center gap-3 font-15 font-medium black-v4 border-radius-8 shadow-none width-95"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    disabled={activeItem === "Deploy"}
                >
                    <span className="d-flex color-black">
                        {/* <img
                            
                            src={`images/${(selectedLanguage.toLowerCase() == 'cloud formation' || selectedLangforDownload.toLowerCase() == 'cloud formation') ? 'cloud-formation-img.svg' : 
                                  (selectedLanguage == 'Terraform' || selectedLangforDownload.toLowerCase() == 'terraform')  ? 'terraform-img.svg' : 
                                  'arm-img.svg'}`}
                            alt={`${selectedLanguage}-img`}
                            className="me-2 icon-width"
                        /> */}

                        <img
                            src={`images/${getLanguageImage(activeItem === "Deploy" ? selectedLanguage : (selectedLangforDownload || selectedLanguage))}`}
                            alt={`${activeItem === "Deploy" ? selectedLanguage : (selectedLangforDownload || selectedLanguage)}-img`}
                            className="me-2 icon-width"
                        />

                        {activeItem === "Deploy" ? selectedLanguage : (selectedLangforDownload || selectedLanguage)}
                        {/* {activeItem === "Deploy" ? selectedLanguage : selectedLangforDownload} */}



                    </span>
                    <img src="images/drop-down-arrow.svg" alt="dropdown-arrow" />
                </button>
                <ul
                    className="dropdown-menu body-bg access-dropdown width-95"
                    aria-labelledby="dropdownMenuButton1"
                >

                    <li className="font-regular color-black font-12 p-2 custom-list" onClick={() => handleLanguageChange('Terraform')}>
                        <span className="d-flex">
                            <img src="images/terraform-img.svg" alt="terraform-img" className="me-1 icon-width" />
                            Terraform
                        </span>
                    </li>
                    {formData.cloudProvider != "AWS" && formData.cloudProvider != "aws" ?
                        <li className="font-regular color-black font-12 p-2 custom-list" onClick={() => handleLanguageChange('Arm')}>
                            <span className="d-flex">
                                <img src="images/arm-img.svg" alt="arm-img" className="me-1 icon-width" />
                                ARM
                            </span>
                        </li>
                        :
                        <li className="font-regular color-black font-12 p-2 custom-list" onClick={() => handleLanguageChange('Cloud Formation')}>
                            <span className="d-flex">
                                <img src="images/cloud-formation-img.svg" alt="Cloud" className="me-1 icon-width" />
                                Cloud Formation
                            </span>
                        </li>
                    }
                </ul>
            </div>
            <ul className="nav nav-pills flex-column mb-auto flex-nowrap nav-overflow">
                <li
                    className={`side-nav-item d-flex align-items-center ${activeItem === "ServiceReview" ? 'active' : ''}`}
                    onClick={() => handleNavigation('/ServiceReview', "ServiceReview")}
                >
                    <img src="images/service-icon.svg" alt="service" className="side-icon" />
                    <span>Service Review</span>
                </li>
                <li
                    className={`side-nav-item d-flex align-items-center ${activeItem === "CodeGeneration" ? 'active' : ''}`}
                    onClick={() => handleNavigation('/DeployModuleCodeEditor', "CodeGeneration")}
                >
                    <img src="images/code-icon.svg" alt="code-icon" className="side-icon" />
                    <span>Code Generation</span>
                </li>
                <li
                    className={`side-nav-item d-flex align-items-center ${activeItem === "Deploy" ? 'active' : ''}`}
                    onClick={() => handleNavigation('/DeployModuleCodeEditor', "Deploy")}
                >
                    <img src="images/deploy-icon.svg" alt="deploy-icon" className="side-icon" />
                    <span>Deploy</span>
                </li>
            </ul>
        </div>
    );
};

export default DeploySideBar;