import React, { useState, useEffect, useContext } from 'react';
import { getAccountCount, getOverAllCost } from '../../Service/api';
import { OrgContext } from '../../ContextProvider/OrgContext';
import { useNavigate } from 'react-router-dom';

const OverallCost = () => {
  const [accountData, setAccountData] = useState(null);
  const [overallCost, setOverallCost] = useState(null);
  const { orgId } = useContext(OrgContext);
  const userId = localStorage.getItem('userId');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accountResponse = await getAccountCount({ orgId, userId });
        if (accountResponse.data && accountResponse.data.body && accountResponse.data.body.success) {
          setAccountData(accountResponse.data.body.data);
        } else {
          console.error('Failed to fetch account data:', accountResponse.data?.body?.message);
        }
    
        const costResponse = await getOverAllCost({ orgId, userId });
        if (costResponse.data && costResponse.data.success) {
          setOverallCost(costResponse.data.data.overAllCost);
        } else {
          console.error('Failed to fetch overall cost:', costResponse.data?.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (orgId && userId) {
      fetchData();
    }
  }, [orgId, userId]);

  const renderContent = () => {
    if (!accountData) {
      return null;
    }

    const hasAws = accountData.aws_accounts > 0;
    const hasAzure = accountData.azure_accounts > 0;
    const hasWorkloads = accountData.workload_count > 0;

    const handleCardClick = (providerName) => {
      navigate('/inventory', { state: { cardProviderName: providerName } });
    };

    if (hasAws && hasAzure) {
      // Render 4 columns layout
      return (
        <>
          <div className="col-md-6 col-lg-6 col-xl-3 mb-4 px-2">
            <div className="count-container d-flex justify-content-between align-items-center gap-2">
              <div>
                <p className="font-14 font-regular color-black mb-2">#Workloads</p>
                <p className="font-19 font-bold color-black m-0">{accountData.workload_count}</p>
              </div>
              <div className="cost-cont workload">
                <img src="images/cube-img.svg" alt="cube-img" />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-6 col-xl-3 mb-4 px-2" onClick={() => handleCardClick('AWS')}>
            <div className="count-container d-flex justify-content-between align-items-center gap-2">
              <div>
                <p className="font-14 font-regular color-black mb-2">#AWS Accounts</p>
                <p className="font-19 font-bold color-black m-0">{accountData.aws_accounts}</p>
              </div>
              <div className="cost-cont aws-account">
                <img src="images/aws-img.svg" alt="aws-img" />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-6 col-xl-3 mb-4 px-2" onClick={() => handleCardClick('Azure')}>
            <div className="count-container d-flex justify-content-between align-items-center gap-2">
              <div>
                <p className="font-14 font-regular color-black mb-2 text-nowrap">#Azure Subscriptions</p>
                <p className="font-19 font-bold color-black m-0">{accountData.azure_accounts}</p>
              </div>
              <div className="cost-cont azure-account">
                <img src="images/azure-img.svg" alt="azure-img" />
              </div>
            </div>
          </div>
        </>
      );
    } else {
      // Render 3 columns layout
      return (
        <>
          {hasWorkloads && (
            <div className="col-md-6 col-lg-4 mb-4 px-2">
              <div className="count-container d-flex justify-content-between align-items-center gap-2">
                <div>
                  <p className="font-14 font-regular color-black mb-2">#Workloads</p>
                  <p className="font-19 font-bold color-black m-0">{accountData.workload_count}</p>
                </div>
                <div className="cost-cont workload">
                  <img src="images/cube-img.svg" alt="cube-img" />
                </div>
              </div>
            </div>
          )}
          {hasAws && (
            <div className="col-md-6 col-lg-4 mb-4 px-2" onClick={() => handleCardClick('AWS')}>
              <div className="count-container d-flex justify-content-between align-items-center gap-2">
                <div>
                  <p className="font-14 font-regular color-black mb-2">#AWS Accounts</p>
                  <p className="font-19 font-bold color-black m-0">{accountData.aws_accounts}</p>
                </div>
                <div className="cost-cont aws-account">
                  <img src="images/aws-img.svg" alt="aws-img" />
                </div>
              </div>
            </div>
          )}
          {hasAzure && (
            <div className="col-md-6 col-lg-4 mb-4 px-2" onClick={() => handleCardClick('Azure')}>
              <div className="count-container d-flex justify-content-between align-items-center gap-2">
                <div>
                  <p className="font-14 font-regular color-black mb-2 text-nowrap">#Azure Subscriptions</p>
                  <p className="font-19 font-bold color-black m-0">{accountData.azure_accounts}</p>
                </div>
                <div className="cost-cont azure-account">
                  <img src="images/azure-img.svg" alt="azure-img" />
                </div>
              </div>
            </div>
          )}
        </>
      );
    }
  };

  return (
    <div className="col-md-12">
      <div className="row">
        {renderContent()}
        <div className={`col-md-6 ${accountData && (accountData.aws_accounts > 0 && accountData.azure_accounts > 0) ? 'col-lg-6 col-xl-3' : 'col-lg-4'} mb-4 px-2`}>
          <div className="count-container d-flex justify-content-between align-items-center gap-2">
            <div>
              <p className="font-14 font-regular color-black mb-2">Overall Cost</p>
              <p className="font-19 font-medium color-black m-0">
                ${overallCost ? overallCost.toFixed(2) : '0.00'}
              </p>
            </div>
            <div className="cost-cont overall-cost">
              <img src="images/dollar-img.svg" alt="dollar-img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverallCost;