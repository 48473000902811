// values["region"] = "us-east"
// values["tier"] = "Consumption"
// values["executionTime"] = "1088888800"
// values["executionCount"] = "9999"
// values["memorySize"] = "1536"
// values["instance"] = "ep1-payg"
// values["preWarmedCount"] = "9999"
// values["preWarmedCountHoursFactor"] = "24"
// values["preWarmedCountHours"] = "31"
// values["additionalUnitsCount"] = "9999"
// values["additionalUnitsCountHours"] = "31"
// values["additionalUnitsCountHoursFactor"] = "24" 

export default function Azure_FunctionApp({ values, onChangeOfConfiguration, isViewer }) {
    try {
        return (
            <div id={`${values.instanceName.split(" ").join("_")}`} className="accordion-collapse collapse">
                <div className="accordioan-body p-0 ">
                    <table className="table table-borderless cg-grid font-14 font-semibold mb-0">
                        <tbody>
                            <tr className="ms-acc-data-align">
                                <td colSpan={3}>
                                    <div className="row py-4 mx-4 px-5" hidden={(values.isPriceAvailable == "No") ? false : true}>
                                        <div className="col-md-6 px-3"><span className="font-semibold font-14 text-danger">{values.isPriceAvailable == "No" ? "The Price is not available for the region / the configuration that you have selected might be not available in the region" : null}</span></div>
                                    </div>
                                    <div className="row py-4 mx-4 px-5">
                                        <div className="col-md-6 px-3">
                                            <label htmlFor="region" className="form-label font-14 font-medium black-v2 mb-3">
                                                Region
                                            </label>
                                            <select
                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                id="region"
                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                value={values.region}
                                                name={"region"}
                                                onChange={(e) => {
                                                    onChangeOfConfiguration(e, values.instanceName);
                                                }} disabled={isViewer}
                                            >
                                                <optgroup label="United States">
                                                    <option value="us-central">Central US</option>
                                                    <option value="us-east">East US</option>
                                                    <option value="us-east-2">East US 2</option>
                                                    <option value="us-north-central">North Central US</option>
                                                    <option value="us-south-central">South Central US</option>
                                                    <option value="us-west-central">West Central US</option>
                                                    <option value="us-west">West US</option>
                                                    <option value="us-west-2">West US 2</option>
                                                    <option value="us-west-3">West US 3</option>
                                                </optgroup>
                                                <optgroup label="United Kingdom">
                                                    <option value="united-kingdom-south">UK South</option>
                                                    <option value="united-kingdom-west">UK West</option>
                                                </optgroup>
                                                <optgroup label="United Arab Emirates">
                                                    <option value="uae-central">UAE Central</option>
                                                    <option value="uae-north">UAE North</option>
                                                </optgroup>
                                                <optgroup label="Switzerland">
                                                    <option value="switzerland-north">Switzerland North</option>
                                                    <option value="switzerland-west">Switzerland West</option>
                                                </optgroup>
                                                <optgroup label="Sweden">
                                                    <option value="sweden-central">Sweden Central</option>
                                                    <option value="sweden-south">Sweden South</option>
                                                </optgroup>
                                                <optgroup label="Spain">
                                                    <option value="spain-central">Spain Central</option>
                                                </optgroup>
                                                <optgroup label="Qatar">
                                                    <option value="qatar-central">Qatar Central</option>
                                                </optgroup>
                                                <optgroup label="Poland">
                                                    <option value="poland-central">Poland Central</option>
                                                </optgroup>
                                                <optgroup label="Norway">
                                                    <option value="norway-east">Norway East</option>
                                                    <option value="norway-west">Norway West</option>
                                                </optgroup>
                                                <optgroup label="Mexico">
                                                    <option value="mexico-central">Mexico Central</option>
                                                </optgroup>
                                                <optgroup label="Korea">
                                                    <option value="korea-central">Korea Central</option>
                                                    <option value="korea-south">Korea South</option>
                                                </optgroup>
                                                <optgroup label="Japan">
                                                    <option value="japan-east">Japan East</option>
                                                    <option value="japan-west">Japan West</option>
                                                </optgroup>
                                                <optgroup label="Italy">
                                                    <option value="italy-north">Italy North</option>
                                                </optgroup>
                                                <optgroup label="Israel">
                                                    <option value="israel-central">Israel Central</option>
                                                </optgroup>
                                                <optgroup label="India">
                                                    <option value="central-india">Central India</option>
                                                    <option value="south-india">South India</option>
                                                    <option value="west-india">West India</option>
                                                </optgroup>
                                                <optgroup label="Germany">
                                                    <option value="germany-north">Germany North</option>
                                                    <option value="germany-west-central">Germany West Central</option>
                                                </optgroup>
                                                <optgroup label="France">
                                                    <option value="france-central">France Central</option>
                                                    <option value="france-south">France South</option>
                                                </optgroup>
                                                <optgroup label="Europe">
                                                    <option value="europe-north">North Europe</option>
                                                    <option value="europe-west">West Europe</option>
                                                </optgroup>
                                                <optgroup label="Canada">
                                                    <option value="canada-central">Canada Central</option>
                                                    <option value="canada-east">Canada East</option>
                                                </optgroup>
                                                <optgroup label="Brazil">
                                                    <option value="brazil-south">Brazil South</option>
                                                    <option value="brazil-southeast">Brazil Southeast</option>
                                                </optgroup>
                                                <optgroup label="Australia">
                                                    <option value="australia-central">Australia Central</option>
                                                    <option value="australia-central-2">Australia Central 2</option>
                                                    <option value="australia-east">Australia East</option>
                                                    <option value="australia-southeast">Australia Southeast</option>
                                                </optgroup>
                                                <optgroup label="Asia Pacific">
                                                    <option value="asia-pacific-east">East Asia</option>
                                                    <option value="asia-pacific-southeast">Southeast Asia</option>
                                                </optgroup>
                                                <optgroup label="Africa">
                                                    <option value="south-africa-north">South Africa North</option>
                                                    <option value="south-africa-west">South Africa West</option>
                                                </optgroup>
                                            </select>
                                        </div>
                                        <div className="col-md-6 px-3">
                                            <label htmlFor="tier" className="form-label font-14 font-medium black-v2 mb-3">
                                                Tier
                                            </label>
                                            <select
                                                className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                id="tier"
                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                value={values.tier}
                                                name={"tier"}
                                                onChange={(e) => {
                                                    onChangeOfConfiguration(e, values.instanceName);
                                                }} disabled={isViewer}>
                                                <option value="consumption">Consumption</option>
                                                <option value="premium">Premium</option>
                                            </select>
                                        </div>
                                        {values.tier == "consumption" && <>
                                            {!["uae-central", "switzerland-west", "qatar-central", "norway-west", "korea-south", "germany-north", "brazil-southeast", "usgov-arizona", "usgov-texas", "usgov-virginia"].includes(values.region) && <>
                                                <div className="col-md-6 px-3">
                                                    <label htmlFor="memorySize" className="form-label font-14 font-medium black-v2 mb-3">
                                                        Memory Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                        id="memorySize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.memorySize}
                                                        name={"memorySize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="128">128</option>
                                                        <option value="256">256</option>
                                                        <option value="384">384</option>
                                                        <option value="512">512</option>
                                                        <option value="640">640</option>
                                                        <option value="768">768</option>
                                                        <option value="896">896</option>
                                                        <option value="1024">1024</option>
                                                        <option value="1152">1152</option>
                                                        <option value="1280">1280</option>
                                                        <option value="1408">1408</option>
                                                        <option value="1536">1536</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-6 px-3">
                                                    <label htmlFor="executionTime" className="form-label font-14 font-medium black-v2 mb-3">
                                                        Execution time (in milliseconds)
                                                    </label>
                                                    <input
                                                        type="text"
                                                        style={{paddingBottom: '9px'}}
                                                        className=" form-control  custom-form  text-field font-14 font-medium"
                                                        id="executionTime"
                                                        value={values.executionTime}
                                                        name={"executionTime"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}
                                                    />
                                                </div>
                                                <div className="col-md-6 px-3">
                                                    <label htmlFor="executionCount" className="form-label font-14 font-medium black-v2 mb-3">
                                                        Executions per month
                                                    </label>
                                                    <input
                                                        type="text"
                                                        style={{paddingBottom: '9px'}}
                                                        className=" form-control  custom-form  text-field font-14 font-medium"
                                                        id="executionCount"
                                                        value={values.executionCount}
                                                        name={"executionCount"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}
                                                    />
                                                </div>
                                            </>}
                                        </>}
                                        {values.tier == "premium" && <>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="instance" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Instance
                                                </label>
                                                <select
                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                    id="instance"
                                                    style={{ marginTop: "15px", paddingTop: "8px", paddingBottom: "8px" }}
                                                    value={values.instance}
                                                    name={"instance"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}>
                                                    <option value="ep1-payg">EP1: 1 Core(s), 3.5 GB RAM, 250 GB Storage</option>
                                                    <option value="ep2-payg">EP2: 2 Core(s), 7 GB RAM, 250 GB Storage</option>
                                                    <option value="ep3-payg">EP3: 4 Core(s), 14 GB RAM, 250 GB Storage</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="preWarmedCount" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Minimum instances (shared by plan)  - Instances
                                                </label>
                                                <input
                                                    type="text"
                                                    style={{paddingBottom: '9px'}}
                                                    className=" form-control  custom-form  text-field font-14 font-medium mb-3"
                                                    id="preWarmedCount"
                                                    value={values.preWarmedCount}
                                                    name={"preWarmedCount"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}
                                                />
                                            </div>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="preWarmedCountHours" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Minimum instances (shared by plan) - Instances Count Hours
                                                </label>
                                                <input
                                                    type="text"
                                                    style={{paddingBottom: '9px' , marginTop : "23px",marginBottom : "20px !important" }}
                                                    className=" form-control  custom-form  text-field font-14 font-medium mb-3"
                                                    id="preWarmedCountHours"
                                                    value={values.preWarmedCountHours}
                                                    name={"preWarmedCountHours"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}
                                                />
                                            </div>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="preWarmedCountHoursFactor " className="form-label font-14 font-medium black-v2 mb-3">
                                                    Minimum instances (shared by plan) - Instance Count Hours Factor
                                                </label>
                                                <select
                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                    id="preWarmedCountHoursFactor "
                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                    value={values.preWarmedCountHoursFactor}
                                                    name={"preWarmedCountHoursFactor "}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}>
                                                    <option value="1">Hours</option>
                                                    <option value="24">Days</option>
                                                    <option value="730">Month</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="additionalUnitsCount" className="form-label font-14 font-medium black-v2 mb-3"
                                                style={{ marginTop :":23px"}}>
                                                    Additional scaled out units - Instances
                                                </label>
                                                <input
                                                    type="text"
                                                    style={{paddingBottom: '9px'}}
                                                    className=" form-control  custom-form  text-field font-14 font-medium"
                                                    id="additionalUnitsCount"
                                                    value={values.additionalUnitsCount}
                                                    name={"additionalUnitsCount"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}
                                                />
                                            </div>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="additionalUnitsCountHours" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Additional scaled out units - Instances Count Hours
                                                </label>
                                                <input
                                                    type="text"
                                                    style={{paddingBottom: '9px'}}
                                                    className=" form-control  custom-form  text-field font-14 font-medium  mb-3"
                                                    id="additionalUnitsCountHours"
                                                    value={values.additionalUnitsCountHours}
                                                    name={"additionalUnitsCountHours"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}
                                                />
                                            </div>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="additionalUnitsCountHoursFactor " className="form-label font-14 font-medium black-v2 mb-3">
                                                    Additional scaled out units - Instance Count Hours Factor
                                                </label>
                                                <select
                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                    id="additionalUnitsCountHoursFactor "
                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                    value={values.additionalUnitsCountHoursFactor}
                                                    name={"additionalUnitsCountHoursFactor "}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}>
                                                    <option value="1">Hours</option>
                                                    <option value="24">Days</option>
                                                    <option value="730">Month</option>
                                                </select>
                                            </div>
                                        </>}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    } catch (error) {
        //console.log(error);
    }
}