
//  this HTML part is for APP services
export default function Azure_AppService({ values, onChangeOfConfiguration, isViewer }) {
    try {

        return (
            <div id={`${values.instanceName.split(" ").join("_")}`} className="accordion-collapse collapse">
                <div className="accordioan-body p-0 ">
                    <table className="table table-borderless cg-grid font-14 font-semibold mb-0">
                        <tbody>
                            <tr className="ms-acc-data-align">
                                <td colSpan={3}>
                                    <div className="row py-4 mx-4 px-5" hidden={(values.isPriceAvailable == "No") ? false : true}>
                                        <div className="col-md-6 px-3"><span className="font-semibold font-14 text-danger">{values.isPriceAvailable == "No" ? "The Price is not available for the region / the configuration that you have selected might be not available in the region" : null}</span></div>
                                    </div>
                                    <div className="row py-4 mx-4 px-5">
                                        <div className="col-md-6 px-3">
                                            <label htmlFor="region1" className="form-label font-14 font-medium black-v2 mb-3">
                                                Region
                                            </label>
                                            <select
                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                id="region"
                                                value={values.region}
                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                name={"region"}
                                                onChange={(e) => {
                                                    onChangeOfConfiguration(e, values.instanceName);
                                                }} disabled={isViewer}
                                            >
                                                <option value="us-central">us-central</option>
                                                <option value="us-east">us-east</option>
                                                <option value="us-east-2">us-east-2</option>
                                                <option value="us-north-central">us-north-central</option>
                                                <option value="us-south-central">us-south-central</option>
                                                <option value="us-west-central">us-west-central</option>
                                                <option value="us-west">us-west</option>
                                                <option value="us-west-2">us-west-2</option>
                                                <option value="us-west-3">us-west-3</option>

                                                <option value="united-kingdom-south">united-kingdom-south</option>
                                                <option value="united-kingdom-west">united-kingdom-west</option>

                                                <option value="uae-central">uae-central</option>
                                                <option value="uae-north">uae-north</option>

                                                <option value="switzerland-north">switzerland-north</option>
                                                <option value="switzerland-west">switzerland-west</option>

                                                <option value="sweden-central">sweden-central</option>
                                                <option value="sweden-south">sweden-south</option>

                                                <option value="spain-central">spain-central</option>

                                                <option value="qatar-central">qatar-central</option>

                                                <option value="poland-central">poland-central</option>

                                                <option value="norway-east">norway-east</option>
                                                <option value="norway-west">norway-west</option>

                                                <option value="mexico-central">mexico-central</option>

                                                <option value="korea-central">korea-central</option>
                                                <option value="korea-south">korea-south</option>

                                                <option value="japan-east">japan-east</option>
                                                <option value="japan-west">japan-west</option>

                                                <option value="italy-north">italy-north</option>

                                                <option value="israel-central">israel-central</option>

                                                <option value="central-india">central-india</option>
                                                <option value="south-india">south-india</option>
                                                <option value="west-india">west-india</option>

                                                <option value="germany-north">germany-north</option>
                                                <option value="germany-west-central">germany-west-central</option>

                                                <option value="france-central">france-central</option>
                                                <option value="france-south">france-south</option>

                                                <option value="europe-north">europe-north</option>
                                                <option value="europe-west">europe-west</option>

                                                <option value="canada-central">canada-central</option>
                                                <option value="canada-east">canada-east</option>

                                                <option value="brazil-south">brazil-south</option>
                                                <option value="brazil-southeast">brazil-southeast</option>
                                                {/* <option value="usgov-arizona">usgov-arizona</option>
                                                <option value="usgov-texas">usgov-texas</option>
                                                <option value="usgov-virginia">usgov-virginia</option> */}

                                                <option value="australia-central">australia-central</option>
                                                <option value="australia-central-2">australia-central-2</option>
                                                <option value="australia-east">australia-east</option>
                                                <option value="australia-southeast">australia-southeast</option>

                                                <option value="asia-pacific-east">asia-pacific-east</option>
                                                <option value="asia-pacific-southeast">asia-pacific-southeast</option>

                                                <option value="south-africa-north">south-africa-north</option>
                                                <option value="south-africa-west">south-africa-west</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6 px-3">
                                            <label htmlFor="type" className="form-label font-14 font-medium black-v2 mb-3">
                                                Operating system
                                            </label>
                                            <select
                                                className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                id="type"
                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                value={values.type}
                                                name={"type"}

                                                onChange={(e) => {
                                                    onChangeOfConfiguration(e, values.instanceName);
                                                    if (values["type"] === "windows") {
                                                        values["tier"] = 'free';
                                                        values["size"] = "f1";
                                                    }
                                                    else if (values["type"] === "linux") {
                                                        values["tier"] = 'free';
                                                        values["size"] = "f1";
                                                    }

                                                }} disabled={isViewer}
                                            >
                                                <option value="windows">Windows</option>
                                                <option value="linux">Linux</option>

                                            </select>
                                        </div>

                                        {values["type"] === 'windows' ? (
                                            <>
                                                <div>
                                                    <label htmlFor="tier" className="form-label font-14 font-medium black-v2 mb-3">
                                                        Tier
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="tier"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.tier}
                                                        name={"tier"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                            if (values["tier"] === 'free') {
                                                                values["size"] = "f1";
                                                            }
                                                            else if (values["tier"] === 'shared') {
                                                                values["size"] = "d1";
                                                            }
                                                            else if (values["tier"] === 'basic') {
                                                                values["size"] = "b1";
                                                            }
                                                            else if (values["tier"] === 'standard') {
                                                                values["size"] = "s1";
                                                            }
                                                            else if (values["tier"] === 'premiumv2') {
                                                                values["size"] = "p1v2";
                                                            }
                                                            else if (values["tier"] === 'premiumv3') {
                                                                values["size"] = "p0v3";
                                                            }

                                                            else if (values["tier"] === 'isolatedv2') {
                                                                values["size"] = "i1v2";
                                                            }

                                                        }} disabled={isViewer}
                                                    >

                                                        <option value="free">Free</option>
                                                        <option value="shared">Shared</option>
                                                        <option value="basic">Basic</option>
                                                        <option value="standard">Standard</option>
                                                        <option value="premiumv2">Premium V2</option>
                                                        <option value="premiumv3">Premium V3</option>
                                                        <option value="isolated">Isolated</option>
                                                        <option value="isolatedv2">Isolated V2</option>
                                                        <option value="isolatedv2dedicatedhost">Isolated V2 (Dedicated Host)</option>


                                                    </select>
                                                </div>

                                                {values["tier"] === 'free' ? (
                                                    <>
                                                        <div className="row py-4 mx-4 px-5">
                                                            <h6 style={{ marginTop: "2%", marginLeft: "-13%" }}>Free</h6>
                                                        </div>
                                                        <div>
                                                            <label htmlFor="size" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Size
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                id="size"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.size}
                                                                name={"size"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            >

                                                                <option value="f1">F1: Shared Cores(s), 1 GB RAM, 1 GB</option>

                                                            </select>
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="instances" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Instances
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{ paddingTop: "10px" }}
                                                                className=" form-control  custom-form  text-field font-14 font-medium mb-2"
                                                                id="instances"
                                                                value={values.instances}
                                                                name={"instances"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="hours" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Hours
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{ paddingTop: "10px" }}
                                                                className=" form-control  custom-form  text-field font-14 font-medium mb-3"
                                                                id="hours"
                                                                value={values.hours}
                                                                name={"hours"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>
                                                        <div>
                                                            <label htmlFor="hoursFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Hours Factor
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                id="hoursFactor"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.hoursFactor}
                                                                name={"hoursFactor"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            >
                                                                <option value="1">Hours</option>
                                                                <option value="24">Days</option>
                                                                <option value="730">Month</option>
                                                            </select>
                                                        </div>
                                                    </>

                                                ) : values["tier"] === 'shared' ? (
                                                    <>
                                                        <div className="row py-4 mx-4 px-5">
                                                            <h6 style={{ marginTop: "2%", marginLeft: "-13%" }}>Shared</h6>
                                                        </div>
                                                        <div >
                                                            <label htmlFor="size" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Size
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                id="size"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.size}
                                                                name={"size"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            >

                                                                <option value="d1">D1: Shared Cores(s), 1 GB RAM, 1 GB Storage</option>

                                                            </select>
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="instances" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Instances
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{ paddingTop: "10px" }}
                                                                className=" form-control  custom-form  text-field font-14 font-medium"
                                                                id="instances"
                                                                value={values.instances}
                                                                name={"instances"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="hours" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Hours
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{ paddingTop: "10px" }}
                                                                className=" form-control  custom-form  text-field font-14 font-medium"
                                                                id="hours"
                                                                value={values.hours}
                                                                name={"hours"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>
                                                        <div >
                                                            <label htmlFor="hoursFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Hours Factor
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                id="hoursFactor"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.hoursFactor}
                                                                name={"hoursFactor"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            >
                                                                <option value="1">Hours</option>
                                                                <option value="24">Days</option>
                                                                <option value="730">Month</option>
                                                            </select>
                                                        </div>
                                                    </>
                                                ) : values["tier"] === 'basic' ? (
                                                    <>
                                                        <div className="row py-4 mx-4 px-5">

                                                            <h6 style={{ marginTop: "2%", marginLeft: "-13%" }}>Basic</h6>
                                                        </div>
                                                        <div>
                                                            <label htmlFor="size" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Size
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                id="size"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.size}
                                                                name={"size"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            >

                                                                <option value="b1">B1: 1 Cores(s), 1.75 GB RAM, 10 GB Storage</option>
                                                                <option value="b2">B2: 2 Cores(s), 3.5 GB RAM, 10 GB Storage</option>
                                                                <option value="b3">B3: 4 Cores(s), 7 GB RAM, 10 GB Storage</option>

                                                            </select>
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="instances" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Instances
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{ paddingTop: "10px" }}
                                                                className=" form-control  custom-form  text-field font-14 font-medium mb-3"
                                                                id="instances"
                                                                value={values.instances}
                                                                name={"instances"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="hours" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Hours
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{ paddingTop: "10px" }}
                                                                className=" form-control  custom-form  text-field font-14 font-medium"
                                                                id="hours"
                                                                value={values.hours}
                                                                name={"hours"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>
                                                        <div>
                                                            <label htmlFor="hoursFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Hours Factor
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                id="hoursFactor"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.hoursFactor}
                                                                name={"hoursFactor"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            >
                                                                <option value="1">Hours</option>
                                                                <option value="24">Days</option>
                                                                <option value="730">Month</option>
                                                            </select>
                                                        </div>
                                                    </>
                                                ) : values["tier"] === 'standard' ? (
                                                    <>
                                                        <div className="row py-4 mx-4 px-5">
                                                            <h6 style={{ marginTop: "2%", marginLeft: "-13%" }}>Standard</h6>
                                                        </div>
                                                        <div >
                                                            <label htmlFor="size" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Size
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                id="size"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.size}
                                                                name={"size"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            >

                                                                <option value="s1">S1: 1 Cores(s), 1.75 GB RAM, 50 GB Storage</option>
                                                                <option value="s2">S2: 2 Cores(s), 3.5 GB RAM, 50 GB Storage</option>
                                                                <option value="s3">S3: 4 Cores(s), 7 GB RAM, 50 GB Storage</option>

                                                            </select>
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="instances" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Instances
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{ paddingTop: "10px" }}
                                                                className=" form-control  custom-form  text-field font-14 font-medium"
                                                                id="instances"
                                                                value={values.instances}
                                                                name={"instances"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="hours" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Hours
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{ paddingTop: "10px" }}
                                                                className=" form-control  custom-form  text-field font-14 font-medium mb-3"
                                                                id="hours"
                                                                value={values.hours}
                                                                name={"hours"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>
                                                        <div >
                                                            <label htmlFor="hoursFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Hours Factor
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                id="hoursFactor"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.hoursFactor}
                                                                name={"hoursFactor"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            >
                                                                <option value="1">Hours</option>
                                                                <option value="24">Days</option>
                                                                <option value="730">Month</option>
                                                            </select>
                                                        </div>
                                                    </>
                                                ) : values["tier"] === 'premiumv2' ? (
                                                    <>
                                                        <div className="row py-4 mx-4 px-5">

                                                            <h6 style={{ marginTop: "2%", marginLeft: "-13%" }}>Premium V2</h6>
                                                        </div>
                                                        <div>
                                                            <label htmlFor="size" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Size
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                id="size"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.size}
                                                                name={"size"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            >

                                                                <option value="p1v2">P1V2: 1 Cores(s), 3.5 GB RAM, 250 GB Storage</option>
                                                                <option value="p2v2">P2V2: 2 Cores(s), 7 GB RAM, 250 GB Storage</option>
                                                                <option value="p3v2">P3V2: 4 Cores(s), 14 GB RAM, 250 GB Storage</option>

                                                            </select>
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="instances" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Instances
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className=" form-control  custom-form  text-field font-14 font-medium mb-3"
                                                                id="instances"
                                                                value={values.instances}
                                                                name={"instances"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="hours" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Hours
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className=" form-control  custom-form  text-field font-14 font-medium"
                                                                id="hours"
                                                                value={values.hours}
                                                                name={"hours"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>
                                                        <div >
                                                            <label htmlFor="hoursFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Hours Factor
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                id="hoursFactor"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.hoursFactor}
                                                                name={"hoursFactor"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            >
                                                                <option value="1">Hours</option>
                                                                <option value="24">Days</option>
                                                                <option value="730">Month</option>
                                                            </select>
                                                        </div>
                                                    </>
                                                ) : values["tier"] === 'premiumv3' ? (
                                                    <>
                                                        <div className="row py-4 mx-4 px-5">
                                                            <h6 style={{ marginTop: "2%", marginLeft: "-13%" }}>Premium V3</h6>
                                                        </div>
                                                        <div>
                                                            <label htmlFor="size" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Size
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                id="size"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.size}
                                                                name={"size"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            >
                                                                <option value="p0v3">P0V3: 1 Cores(s), 4 GB RAM, 250 GB Storage</option>
                                                                <option value="p1v3">P1V3: 2 Cores(s), 8 GB RAM, 250 GB Storage</option>
                                                                <option value="p1mv3">P1MV3: 2 Cores(s), 16 GB RAM, 250 GB Storage</option>
                                                                <option value="p2v3">P2V3: 4 Cores(s), 16 GB RAM, 250 GB Storage</option>
                                                                <option value="p2mv3">P2MV3: 4 Cores(s), 32 GB RAM, 250 GB Storage</option>
                                                                <option value="p3v3">P3V3: 8 Cores(s), 32 GB RAM, 250 GB Storage</option>
                                                                <option value="p3mv3">P3MV3: 8 Cores(s), 64 GB RAM, 250 GB Storage</option>
                                                                <option value="p4mv3">P4MV3: 16 Cores(s), 128 GB RAM, 250 GB Storage</option>
                                                                <option value="p5mv3">P5MV3: 32 Cores(s), 256 GB RAM, 250 GB Storage</option>

                                                            </select>
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="instances" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Instances
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{ paddingTop: "10px" }}
                                                                className=" form-control  custom-form  text-field font-14 font-medium"
                                                                id="instances"
                                                                value={values.instances}
                                                                name={"instances"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="hours" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Hours
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{ paddingTop: "10px" }}
                                                                className=" form-control  custom-form  text-field font-14 font-medium mb-3"
                                                                id="hours"
                                                                value={values.hours}
                                                                name={"hours"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>
                                                        <div>
                                                            <label htmlFor="hoursFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Hours Factor
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                id="hoursFactor"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.hoursFactor}
                                                                name={"hoursFactor"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            >
                                                                <option value="1">Hours</option>
                                                                <option value="24">Days</option>
                                                                <option value="730">Month</option>
                                                            </select>
                                                        </div>
                                                    </>
                                                ) : values["tier"] === 'isolated' ? (
                                                    <>
                                                        <div className="row py-4 mx-4 px-5">
                                                            <h6 style={{ marginTop: "2%", marginLeft: "-13%" }}>Isolated</h6>

                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="isolatedOneStampInstances" className="form-label font-14 font-medium black-v2 mb-3">
                                                                I1 Instances
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{ paddingTop: "10px" }}
                                                                className=" form-control  custom-form  text-field font-14 font-medium mb-3"
                                                                id="isolatedOneStampInstances"
                                                                value={values.isolatedOneStampInstances}
                                                                name={"isolatedOneStampInstances"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="isolatedTwoStampInstances" className="form-label font-14 font-medium black-v2 mb-3">
                                                                I2 Instances
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{ paddingTop: "10px" }}
                                                                className=" form-control  custom-form  text-field font-14 font-medium"
                                                                id="isolatedTwoStampInstances"
                                                                value={values.isolatedTwoStampInstances}
                                                                name={"isolatedTwoStampInstances"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="isolatedThreeStampInstances" className="form-label font-14 font-medium black-v2 mb-3">
                                                                I3 Instances
                                                            </label>
                                                            <input
                                                                type="text"

                                                                style={{ paddingTop: "10px" }}
                                                                className=" form-control  custom-form  text-field font-14 font-medium"
                                                                id="isolatedThreeStampInstances"
                                                                value={values.isolatedThreeStampInstances}
                                                                name={"isolatedThreeStampInstances"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="hours" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Hours
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{ paddingTop: "10px" }}
                                                                className=" form-control  custom-form  text-field font-14 font-medium mb-3"
                                                                id="hours"
                                                                value={values.hours}
                                                                name={"hours"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>
                                                        <div>
                                                            <label htmlFor="hoursFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Hours Factor
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                id="hoursFactor"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.hoursFactor}
                                                                name={"hoursFactor"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            >
                                                                <option value="1">Hours</option>
                                                                <option value="24">Days</option>
                                                                <option value="730">Month</option>
                                                            </select>
                                                        </div>
                                                    </>
                                                ) : values["tier"] === 'isolatedv2' ? (
                                                    <>
                                                        <div className="row py-4 mx-4 px-5">
                                                            <h6 style={{ marginTop: "2%", marginLeft: "-13%" }}>Isolated V2</h6>
                                                        </div>
                                                        <div>
                                                            <label htmlFor="size" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Size
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                id="size"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.size}
                                                                name={"size"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            >

                                                                <option value="i1v2">I1v2: 2 Core(s), 8 GB RAM, 1024 GB Storage</option>
                                                                <option value="i1mv2">I1mv2: 2 Core(s), 16 GB RAM, 1024 GB Storage</option>
                                                                <option value="i2v2">I2v2: 4 Core(s), 16 GB RAM, 1024 GB Storage</option>
                                                                <option value="i2mv2">I2mv2: 4 Core(s), 32 GB RAM, 1024 GB Storage</option>
                                                                <option value="i3v2">I3v2: 8 Core(s), 32 GB RAM, 1024 GB Storage</option>
                                                                <option value="i3mv2">I3mv2: 8 Core(s), 64 GB RAM, 1024 GB Storage</option>
                                                                <option value="i4v2">I4v2: 16 Core(s), 64 GB RAM, 1024 GB Storage</option>
                                                                <option value="i4mv2">I4mv2: 16 Core(s), 128 GB RAM, 1024 GB Storage</option>
                                                                <option value="i5v2">I5v2: 32 Core(s), 128 GB RAM, 1024 GB Storage</option>
                                                                <option value="i5mv2">I5mv2: 32 Core(s), 256 GB RAM, 1024 GB Storage</option>
                                                                <option value="i6v2">I6v2: 64 Core(s), 256 GB RAM, 1024 GB Storage</option>

                                                            </select>
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="instances" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Instances
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{ paddingTop: "10px" }}
                                                                className=" form-control  custom-form  text-field font-14 font-medium mb-3"
                                                                id="instances"
                                                                value={values.instances}
                                                                name={"instances"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="hours" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Hours
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{ paddingTop: "10px" }}
                                                                className=" form-control  custom-form  text-field font-14 font-medium"
                                                                id="hours"
                                                                value={values.hours}
                                                                name={"hours"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>
                                                        <div>
                                                            <label htmlFor="hoursFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Hours Factor
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                id="hoursFactor"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.hoursFactor}
                                                                name={"hoursFactor"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            >
                                                                <option value="1">Hours</option>
                                                                <option value="24">Days</option>
                                                                <option value="730">Month</option>
                                                            </select>
                                                        </div>
                                                    </>
                                                ) : values["tier"] === 'isolatedv2dedicatedhost' ? (
                                                    <>
                                                        <div className="row py-4 mx-4 px-5">
                                                            <h6 style={{ marginTop: "2%", marginLeft: "-13%" }}>Isolated V2 (Dedicated Host)</h6>
                                                        </div>

                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="isolatedV2DedicatedHostInstances" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Core(s)
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{ paddingTop: "10px" }}
                                                                className=" form-control  custom-form  text-field font-14 font-medium"
                                                                id="isolatedV2DedicatedHostInstances"
                                                                value={values.isolatedV2DedicatedHostInstances}
                                                                name={"isolatedV2DedicatedHostInstances"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="hours" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Hours
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{ paddingTop: "10px" }}
                                                                className=" form-control  custom-form  text-field font-14 font-medium mb-3"
                                                                id="hours"
                                                                value={values.hours}
                                                                name={"hours"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>
                                                        <div>
                                                            <label htmlFor="hoursFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Hours Factor
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                id="hoursFactor"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.hoursFactor}
                                                                name={"hoursFactor"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            >
                                                                <option value="1">Hours</option>
                                                                <option value="24">Days</option>
                                                                <option value="730">Month</option>
                                                            </select>
                                                        </div>
                                                    </>) : null}
                                            </>
                                        ) : (
                                            <>
                                                <div>
                                                    <label htmlFor="tier" className="form-label font-14 font-medium black-v2 mb-3">
                                                        Tier
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="tier"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.tier}
                                                        name={"tier"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                            if (values["tier"] === 'free') {
                                                                values["size"] = "f1";
                                                            }
                                                            else if (values["tier"] === 'basic') {
                                                                values["size"] = "b1";
                                                            }
                                                            else if (values["tier"] === 'standard') {
                                                                values["size"] = "s1";
                                                            }
                                                            else if (values["tier"] === 'premiumv2') {
                                                                values["size"] = "p1v2";
                                                            }
                                                            else if (values["tier"] === 'premiumv3') {
                                                                values["size"] = "p0v3";
                                                            }

                                                            else if (values["tier"] === 'isolatedv2') {
                                                                values["size"] = "i1v2";
                                                            }
                                                        }} disabled={isViewer}
                                                    >

                                                        <option value="free">Free</option>
                                                        <option value="basic">Basic</option>
                                                        <option value="standard">Standard</option>
                                                        <option value="premiumv2">Premium V2</option>
                                                        <option value="premiumv3">Premium V3</option>
                                                        <option value="isolated">Isolated</option>
                                                        <option value="isolatedv2">Isolated V2</option>
                                                        <option value="isolatedv2dedicatedhost">Isolated V2 (Dedicated Host)</option>


                                                    </select>
                                                </div>

                                                {values["tier"] === 'free' ? (
                                                    <>
                                                        <div className="row py-4 mx-4 px-5">
                                                            <h6 style={{ marginTop: "2%", marginLeft: "-13%" }}>Free</h6>
                                                        </div>
                                                        <div>
                                                            <label htmlFor="size" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Size
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                id="size"
                                                                style={{ paddingTop: "8px", paddingBottom: "8px"}}
                                                                value={values.size}
                                                                name={"size"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}                                                  >

                                                                <option value="f1">F1: Shared Cores(s), 1 GB RAM, 1 GB</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="instances"
                                                            className="form-label font-14 font-medium black-v2 mb-3">
                                                                Instances
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{ paddingTop: "10px"}}
                                                                className=" form-control  custom-form  text-field font-14 font-medium"
                                                                id="instances"
                                                                value={values.instances}
                                                                name={"instances"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="hours" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Hours
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{ paddingTop: "10px" }}
                                                                className=" form-control  custom-form  text-field font-14 font-medium mb-3"
                                                                id="hours"
                                                                value={values.hours}
                                                                name={"hours"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>
                                                        <div >
                                                            <label htmlFor="hoursFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Hours Factor
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                id="hoursFactor"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.hoursFactor}
                                                                name={"hoursFactor"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            >
                                                                <option value="1">Hours</option>
                                                                <option value="24">Days</option>
                                                                <option value="730">Month</option>
                                                            </select>
                                                        </div>
                                                    </>

                                                ) : values["tier"] === 'basic' ? (
                                                    <>
                                                        <div className="row py-4 mx-4 px-5">
                                                            <h6 style={{ marginTop: "2%", marginLeft: "-13%" }}>Basic</h6>
                                                        </div>
                                                        <div>
                                                            <label htmlFor="size" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Size
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                id="size"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.size}
                                                                name={"size"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            >

                                                                <option value="b1">B1: 1 Cores(s), 1.75 GB RAM, 10 GB Storage</option>
                                                                <option value="b2">B2: 2 Cores(s), 3.5 GB RAM, 10 GB Storage</option>
                                                                <option value="b3">B3: 4 Cores(s), 7 GB RAM, 10 GB Storage</option>

                                                            </select>
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="instances" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Instances
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{ paddingTop: "10px" }}
                                                                className=" form-control  custom-form  text-field font-14 font-medium mb-3"
                                                                id="instances"
                                                                value={values.instances}
                                                                name={"instances"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="hours" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Hours
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{ paddingTop: "10px" }}
                                                                className=" form-control  custom-form  text-field font-14 font-medium "
                                                                id="hours"
                                                                value={values.hours}
                                                                name={"hours"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>
                                                        <div>
                                                            <label htmlFor="hoursFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Hours Factor
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                id="hoursFactor"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.hoursFactor}
                                                                name={"hoursFactor"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            >
                                                                <option value="1">Hours</option>
                                                                <option value="24">Days</option>
                                                                <option value="730">Month</option>
                                                            </select>
                                                        </div>
                                                    </>
                                                ) : values["tier"] === 'standard' ? (
                                                    <>
                                                        <div className="row py-4 mx-4 px-5">
                                                            <h6 style={{ marginTop: "2%", marginLeft: "-13%" }}>Standard</h6>
                                                        </div>
                                                        <div >
                                                            <label htmlFor="size" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Size
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                id="size"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.size}
                                                                name={"size"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            >

                                                                <option value="s1">S1: 1 Cores(s), 1.75 GB RAM, 50 GB Storage</option>
                                                                <option value="s2">S2: 2 Cores(s), 3.5 GB RAM, 50 GB Storage</option>
                                                                <option value="s3">S3: 4 Cores(s), 7 GB RAM, 50 GB Storage</option>

                                                            </select>
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="instances" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Instances
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{ paddingTop: "10px" }}
                                                                className=" form-control  custom-form  text-field font-14 font-medium"
                                                                id="instances"
                                                                value={values.instances}
                                                                name={"instances"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="hours" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Hours
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{ paddingTop: "10px" }}
                                                                className=" form-control  custom-form  text-field font-14 font-medium mb-3"
                                                                id="hours"
                                                                value={values.hours}
                                                                name={"hours"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>
                                                        <div>
                                                            <label htmlFor="hoursFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Hours Factor
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                id="hoursFactor"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.hoursFactor}
                                                                name={"hoursFactor"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            >
                                                                <option value="1">Hours</option>
                                                                <option value="24">Days</option>
                                                                <option value="730">Month</option>
                                                            </select>
                                                        </div>
                                                    </>
                                                ) : values["tier"] === 'premiumv2' ? (
                                                    <>
                                                        <div className="row py-4 mx-4 px-5">
                                                            <h6 style={{ marginTop: "2%", marginLeft: "-13%" }}>Premium V2</h6>
                                                        </div>
                                                        <div>
                                                            <label htmlFor="size" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Size
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                id="size"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.size}
                                                                name={"size"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            >

                                                                <option value="p1v2">P1V2: 1 Cores(s), 3.5 GB RAM, 250 GB Storage</option>
                                                                <option value="p2v2">P2V2: 2 Cores(s), 7 GB RAM, 250 GB Storage</option>
                                                                <option value="p3v2">P3V2: 4 Cores(s), 14 GB RAM, 250 GB Storage</option>

                                                            </select>
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="instances" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Instances
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{ paddingTop: "10px" }}
                                                                className=" form-control  custom-form  text-field font-14 font-medium"
                                                                id="instances"
                                                                value={values.instances}
                                                                name={"instances"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="hours" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Hours
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{ paddingTop: "10px" }}
                                                                className=" form-control  custom-form  text-field font-14 font-medium mb-3"
                                                                id="hours"
                                                                value={values.hours}
                                                                name={"hours"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>
                                                        <div >
                                                            <label htmlFor="hoursFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Hours Factor
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                id="hoursFactor"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.hoursFactor}
                                                                name={"hoursFactor"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            >
                                                                <option value="1">Hours</option>
                                                                <option value="24">Days</option>
                                                                <option value="730">Month</option>
                                                            </select>
                                                        </div>
                                                    </>
                                                ) : values["tier"] === 'premiumv3' ? (
                                                    <>
                                                        <div className="row py-4 mx-4 px-5">
                                                            <h6 style={{ marginTop: "2%", marginLeft: "-13%" }}>Premium V3</h6>
                                                        </div>
                                                        <div>
                                                            <label htmlFor="size" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Size
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                id="size"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.size}
                                                                name={"size"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            >

                                                                <option value="p0v3">P0V3: 1 Cores(s), 4 GB RAM, 250 GB Storage</option>
                                                                <option value="p1v3">P1V3: 2 Cores(s), 8 GB RAM, 250 GB Storage</option>
                                                                <option value="p1mv3">P1MV3: 2 Cores(s), 16 GB RAM, 250 GB Storage</option>
                                                                <option value="p2v3">P2V3: 4 Cores(s), 16 GB RAM, 250 GB Storage</option>
                                                                <option value="p2mv3">P2MV3: 4 Cores(s), 32 GB RAM, 250 GB Storage</option>
                                                                <option value="p3v3">P3V3: 8 Cores(s), 32 GB RAM, 250 GB Storage</option>
                                                                <option value="p3mv3">P3MV3: 8 Cores(s), 64 GB RAM, 250 GB Storage</option>
                                                                <option value="p4mv3">P4MV3: 16 Cores(s), 128 GB RAM, 250 GB Storage</option>
                                                                <option value="p5mv3">P5MV3: 32 Cores(s), 256 GB RAM, 250 GB Storage</option>


                                                            </select>
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="instances" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Instances
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{ paddingTop: "10px" }}
                                                                className=" form-control  custom-form  text-field font-14 font-medium"
                                                                id="instances"
                                                                value={values.instances}
                                                                name={"instances"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="hours" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Hours
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{ paddingTop: "10px" }}
                                                                className=" form-control  custom-form  text-field font-14 font-medium mb-3"
                                                                id="hours"
                                                                value={values.hours}
                                                                name={"hours"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>
                                                        <div>
                                                            <label htmlFor="hoursFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Hours Factor
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                id="hoursFactor"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.hoursFactor}
                                                                name={"hoursFactor"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            >
                                                                <option value="1">Hours</option>
                                                                <option value="24">Days</option>
                                                                <option value="730">Month</option>
                                                            </select>
                                                        </div>
                                                    </>
                                                ) : values["tier"] === 'isolated' ? (
                                                    <>
                                                        <div className="row py-4 mx-4 px-5">
                                                            <h6 style={{ marginTop: "2%", marginLeft: "-13%" }}>Isolated</h6>
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="isolatedOneStampInstances" className="form-label font-14 font-medium black-v2 mb-3">
                                                                I1 Instances
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{ paddingTop: "10px" }}
                                                                className=" form-control  custom-form  text-field font-14 font-medium"
                                                                id="isolatedOneStampInstances"
                                                                value={values.isolatedOneStampInstances}
                                                                name={"isolatedOneStampInstances"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="isolatedTwoStampInstances" className="form-label font-14 font-medium black-v2 mb-3">
                                                                I2 Instances
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{ paddingTop: "10px" }}
                                                                className=" form-control  custom-form  text-field font-14 font-medium mb-3"
                                                                id="isolatedTwoStampInstances"
                                                                value={values.isolatedTwoStampInstances}
                                                                name={"isolatedTwoStampInstances"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="isolatedThreeStampInstances" className="form-label font-14 font-medium black-v2 mb-3">
                                                                I3 Instances
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{ paddingTop: "10px" }}
                                                                className=" form-control  custom-form  text-field font-14 font-medium mb-3"
                                                                id="isolatedThreeStampInstances"
                                                                value={values.isolatedThreeStampInstances}
                                                                name={"isolatedThreeStampInstances"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="hours" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Hours
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{ paddingTop: "10px" }}
                                                                className=" form-control  custom-form  text-field font-14 font-medium"
                                                                id="hours"
                                                                value={values.hours}
                                                                name={"hours"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>
                                                        <div >
                                                            <label htmlFor="hoursFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Hours Factor
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                id="hoursFactor"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.hoursFactor}
                                                                name={"hoursFactor"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            >
                                                                <option value="1">Hours</option>
                                                                <option value="24">Days</option>
                                                                <option value="730">Month</option>
                                                            </select>
                                                        </div>
                                                    </>
                                                ) : values["tier"] === 'isolatedv2' ? (
                                                    <>
                                                        <div className="row py-4 mx-4 px-5">

                                                            <h6 style={{ marginTop: "2%", marginLeft: "-13%" }}>Isolated V2</h6>
                                                        </div>
                                                        <div>
                                                            <label htmlFor="size" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Size
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                                id="size"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.size}
                                                                name={"size"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            >

                                                                <option value="i1v2">I1v2: 2 Core(s), 8 GB RAM, 1024 GB Storage</option>
                                                                <option value="i1mv2">I1mv2: 2 Core(s), 16 GB RAM, 1024 GB Storage</option>
                                                                <option value="i2v2">I2v2: 4 Core(s), 16 GB RAM, 1024 GB Storage</option>
                                                                <option value="i2mv2">I2mv2: 4 Core(s), 32 GB RAM, 1024 GB Storage</option>
                                                                <option value="i3v2">I3v2: 8 Core(s), 32 GB RAM, 1024 GB Storage</option>
                                                                <option value="i3mv2">I3mv2: 8 Core(s), 64 GB RAM, 1024 GB Storage</option>
                                                                <option value="i4v2">I4v2: 16 Core(s), 64 GB RAM, 1024 GB Storage</option>
                                                                <option value="i4mv2">I4mv2: 16 Core(s), 128 GB RAM, 1024 GB Storage</option>
                                                                <option value="i5v2">I5v2: 32 Core(s), 128 GB RAM, 1024 GB Storage</option>
                                                                <option value="i5mv2">I5mv2: 32 Core(s), 256 GB RAM, 1024 GB Storage</option>
                                                                <option value="i6v2">I6v2: 64 Core(s), 256 GB RAM, 1024 GB Storage</option>

                                                            </select>
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="instances" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Instances
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{ paddingTop: "10px" }}
                                                                className=" form-control  custom-form  text-field font-14 font-medium"
                                                                id="instances"
                                                                value={values.instances}
                                                                name={"instances"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="hours" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Hours
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className=" form-control  custom-form  text-field font-14 font-medium mb-3"
                                                                id="hours"
                                                                value={values.hours}
                                                                name={"hours"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>
                                                        <div >
                                                            <label htmlFor="hoursFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Hours Factor
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                id="hoursFactor"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.hoursFactor}
                                                                name={"hoursFactor"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            >
                                                                <option value="1">Hours</option>
                                                                <option value="24">Days</option>
                                                                <option value="730">Month</option>
                                                            </select>
                                                        </div>
                                                    </>
                                                ) : values["tier"] === 'isolatedv2dedicatedhost' ? (
                                                    <>
                                                        <div className="row py-4 mx-4 px-5">
                                                            <h6 style={{ marginTop: "2%", marginLeft: "-13%" }}>Isolated V2 (Dedicated Host)</h6>
                                                        </div>

                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="isolatedV2DedicatedHostInstances" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Core(s)
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{ paddingTop: "10px" }}
                                                                className=" form-control  custom-form  text-field font-14 font-medium"
                                                                id="isolatedV2DedicatedHostInstances"
                                                                value={values.isolatedV2DedicatedHostInstances}
                                                                name={"isolatedV2DedicatedHostInstances"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="hours" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Hours
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{ paddingTop: "10px" }}
                                                                className=" form-control  custom-form  text-field font-14 font-medium mb-3"
                                                                id="hours"
                                                                value={values.hours}
                                                                name={"hours"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>
                                                        <div>
                                                            <label htmlFor="hoursFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Hours Factor
                                                            </label>
                                                            <select
                                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                                id="hoursFactor"
                                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                                value={values.hoursFactor}
                                                                name={"hoursFactor"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}
                                                            >
                                                                <option value="1">Hours</option>
                                                                <option value="24">Days</option>
                                                                <option value="730">Month</option>
                                                            </select>
                                                        </div>
                                                    </>) : null}
                                            </>
                                        )}


                                    </div>
                                    {

                                    }</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    } catch (error) {
        //console.log(error);
    }
}




// values["region"] = "us-east";
// values["type"] = "windows";
// values["tier"] = "free";
// values["size"] = "f1";
// values["instances"] = "1";
// values["hoursFactor"] = "1";
// values["hours"] = "2"
// values["isolatedOneStampInstances"] = "3";
// values["isolatedTwoStampInstances"] = "4";
// values["isolatedThreeStampInstances"] = "5";
// values["instanceType"] = "i1v2"
// values["isolatedV2StampInstances"] = "6";
// values["isolatedV2DedicatedHostInstances"] = "7";