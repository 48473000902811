
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { fetchRecommendationsApi } from '../../../../Service/api';
import { useOrgContext } from '../../../../ContextProvider/OrgContext';
import { LEFT } from 'react-swipeable';

const ConfigureComponent = (props) => {
	const { orgId, envId ,workloadRole  } = useOrgContext();
	const [userId, setuserId] = useState(localStorage.getItem('userId'));
	const [viewMode,setViewMode] = useState(workloadRole.toLowerCase() == 'viewer' ? true : false)

	const { configureDetails, repositories, labelSubmit, connectedSourceNodes } = props;
	console.log(connectedSourceNodes , "Connected Soruce Node")
	console.log(configureDetails , "Configure Details" ,repositories )
	// State declarations
	const [activeTab, setActiveTab] = useState('configure');
	const [formData, setFormData] = useState({});
	const [tags, setTags] = useState([]);
	const [newTagKey, setNewTagKey] = useState('');
	const [newTagValue, setNewTagValue] = useState('');
	const [recommendations, setRecommendations] = useState([]);
	const [isLoadingRecommendations, setIsLoadingRecommendations] = useState(false);
	const [recommendationError, setRecommendationError] = useState(null);
	const [clearedFields, setClearedFields] = useState({});

	// UseEffect for initializing formData and tags
	
	useEffect(() => {
	try {
		if (configureDetails) {
			const initialData = {};
			const initialClearedFields = {};
			configureDetails.data.fields.forEach(field => {
			initialData[field.fieldName] = field.UserValue !== undefined ? field.UserValue : (field.value || "");
			initialClearedFields[field.fieldName] = false;
			});
			setFormData({
			...initialData,
			repository_id: configureDetails.data.repositoryData.repository_id || '',
			repository_name: configureDetails.data.repositoryData.repository_name || '',
			});
			setClearedFields(initialClearedFields);

			setTags(configureDetails.data.Tags || []);
		}
	} catch (error) {
			console.log(error , "error in the UseEffect")	
	}
	}
	, [configureDetails]);

	// Debug logs
	useEffect(() => {
		console.log(configureDetails, "Configure Details");
	}, [configureDetails]);

	useEffect(() => {
		console.log(formData, "Form Data");
	}, [formData]);

	// UseEffect for fetching recommendations
	useEffect(() => {
		if (activeTab === 'recommendation') {
			fetchRecommendations();
		}
	}, [activeTab]);

	 // Modify handleInputChange to handle file uploads
	 const convertFileToBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve({ fileName: file.name, fileContent: reader.result });
			reader.onerror = error => reject(error);
		});
	};
	 const handleInputChange = async (e) => {
		try {
			
		
	   const { name, value, type, checked, files } = e.target;
	   let newValue;
	 
	   if (type === 'file') {
		 const file = files[0];
		 newValue = await convertFileToBase64(file);
	   } else {
		 newValue = type === 'checkbox' ? checked : value;
	   }
	 
	   if (name === 'repository') {
		 const selectedRepo = repositories.find(repo => repo.repository_id === value);
		 if (selectedRepo) {
		   setFormData(prev => ({
			 ...prev,
			 repository_id: selectedRepo.repository_id,
			 repository_name: selectedRepo.repository_name
		   }));
		 }
	   } else {
		 setFormData(prev => ({ ...prev, [name]: newValue }));
	   }
	 
	   // Update clearedFields state
	   setClearedFields(prev => ({
		 ...prev,
		 [name]: value === ''
	   }));
	} catch (error) {
		console.log(error , "Error in the handleInputChange")
	}

	 };

	// Function to handle field blur events
	const handleFieldBlur = (fieldName) => {
		updateConfigureDetails();
	};

	// Function to update configureDetails and submit changes

	
	
	const updateConfigureDetails = () => {
		try {
			let updatedConfigureDetails = { ...configureDetails };
			let serviceType = updatedConfigureDetails.data.ConfigureDetail.subServieConfig.type;

			if (serviceType === "create" || serviceType === "update") {
				// Here Update only if the Keys contains Value in it 
				let filteredFormData = Object.keys(formData).reduce((acc, key) => {
					if (formData[key]) { // Check if the key has a value
						acc[key] = formData[key];
					}
					return acc;
				}, {});
				updatedConfigureDetails.data.ConfigureDetail.subServieConfig["Action"][serviceType] = { ...filteredFormData  };
			}
			console.log(updatedConfigureDetails , "updatedConfigureDetailsupdatedConfigureDetails")
			updatedConfigureDetails.data.fields = updatedConfigureDetails.data.fields.map(field => ({
				...field,
				UserValue: clearedFields[field.fieldName] ? '' : (
					field.fieldType === 'file upload' && formData[field.fieldName]
						? formData[field.fieldName]  // use the Base64 encoded content
						: formData[field.fieldName]
				)
			}));

			updatedConfigureDetails.data.repositoryData = {
				repository_id: formData.repository_id || '',
				repository_name: formData.repository_name || '',
				branchData: {
					branchId: '',
					branchName: ''
				}
			};

			updatedConfigureDetails.data.Tags = tags.map(tag => 
				tag.defaultTag ? tag : { key: tag.key, value: tag.value, defaultTag: tag.defaultTag }
			);

			console.log(updatedConfigureDetails, "Updated Data");
			labelSubmit(updatedConfigureDetails);
		} catch (error) {
			console.log(error, "Error in updateConfigureDetails");
		}
	};

	// Function to fetch recommendations

	const fetchRecommendations = async () => {
		setIsLoadingRecommendations(true);
		setRecommendationError(null);

		try {
			const payload = { organizationId :orgId, envId, userId };
			console.log("Fetch Recommendation payload:", payload);

			const response = await fetchRecommendationsApi(payload);
			console.log("Fetch Recommendation response:", response);

			if (response.statusCode === 200 && response.body.data) {
			let parsedData;
			if (typeof response.body.data === "string") {
				parsedData = JSON.parse(response.body.data);
			} else {
				parsedData = response.body.data;
			}

			setRecommendations(parsedData);
				} else {
			throw new Error("Invalid response structure");
				}
		} catch (error) {
			console.error("Error fetching recommendations:", error);
			setRecommendationError("Failed to load recommendations. Please try again.");
		} finally {
			setIsLoadingRecommendations(false);
		}
			};

	// Function to handle form submission
	const handleFormSubmit = (e) => {
		e.preventDefault();
		updateConfigureDetails();
	};

	// Functions for tag management
	const addTag = () => {
		try {
		if (newTagKey.trim() && newTagValue.trim()) {
			setTags([...tags, { key: newTagKey, value: newTagValue, defaultTag: false }]);
			setNewTagKey('');
			setNewTagValue('');
		}
		} catch (error) {
			console.log(error , "Error  in addTag")	
		}

	};
	// Remove Tags is used remove Tags from 
	const removeTag = (index) => {
		const newTags = tags.filter((_, i) => i !== index);
		setTags(newTags);
	};
	// Edit Tags is used to Edit the Tags in the Environement 
	const editTag = (index, key, value) => {
		const newTags = [...tags];
		newTags[index] = { ...newTags[index], key, value };
		setTags(newTags);
	};

	// Render function for form fields
	const renderField = (field) => {
		try {
			let options = [];
			const dependencyValue = isDependencyFulfilled(field.dependsOn);
			console.log(dependencyValue , "Dependency Valueeee")
			// const isDisabled = dependencyValue != null ?  true : false; // Disable if dependency is not fulfilled
			const isDisabled = false; // Disable if dependency is not fulfilled
			console.log( isDependencyFulfilled(field.dependsOn) ,field.fieldName , "Here the Logs", field )
			let fieldValue = clearedFields[field.fieldName] ? '' : (formData[field.fieldName] || '');

			if(dependencyValue){
				fieldValue = dependencyValue
			}
			if (field?.inputValue) {
				// Remove brackets, split by comma, and trim each option
				options = field.inputValue.replace(/^\[|\]$/g, '').split(',').map(option => option.trim().replace(/^['"]|['"]$/g, ''));
			}
			switch (field.fieldType) {
				case 'text':
				return (
				<div className="my-4" key={field.fieldName}>
					<label
						className="form-label font-14 font-medium black-v2"
						htmlFor={field.fieldName}
					>
						{field.fieldName}
					</label>
					<input
						type="text"
						className="form-control custom-dropdown arch-border shadow-none font-14 font-medium black-v2"
						id={field.fieldName}
						name={field.fieldName}
						value={fieldValue}
						onChange={handleInputChange}
						onBlur={() => handleFieldBlur(field.fieldName)}
						required={field.required}
						disabled={isDisabled ||  viewMode}
					/>
				</div>
				);

				case 'dropdown':
						return (
							<div className="my-4" key={field.fieldName}>
							<label className="form-label font-14 font-medium black-v2" htmlFor={field.fieldName}>
								{field.fieldName}
							</label>
							<select
								className="form-select custom-dropdown arch-border shadow-none custom-border-box font-14 font-medium black-v2 custom-select"
								id={field.fieldName}
								name={field.fieldName}
								value={formData[field.fieldName] || ''}
								onChange={handleInputChange}
								onBlur={() => handleFieldBlur(field.fieldName)}
								required={field.required}
								disabled = {viewMode}
							>
								<option value="">Select</option>
								{options.map((option, index) => (
									<option key={index} value={option}>
										{option}
									</option>
								))}
							</select>
						</div>
						);
				case 'radio':
					return (
						<div className="my-4" key={field.fieldName}>
							<p className="font-14 font-medium black-v2">{field.fieldName}</p>
							<div className="d-lg-flex">
								{field.inputValue.split(',').map((option, index) => (
									<div className="form-check me-2 me-lg-4" key={index}>
										<input
											className="form-check-input shadow-none me-3"
											type="radio"
											name={field.fieldName}
											id={`${field.fieldName}_${option.trim()}`}
											value={option.trim()}
											checked={formData[field.fieldName] === option.trim()}
											onChange={handleInputChange}
											onBlur={() => handleFieldBlur(field.fieldName)}
											required={field.required}
											disabled = {viewMode}
										/>
										<label className="form-check-label font-14 font-regular color-black" htmlFor={`${field.fieldName}_${option.trim()}`}>
											{option.trim()}
										</label>
									</div>
								))}
							</div>
						</div>
					);
				case 'check box':
					return (
						<div className="my-4" key={field.fieldName}>
							<input
								className="form-check-input arch-check shadow-none me-2 "
								type="checkbox"
								id={field.fieldName}
								name={field.fieldName}
								checked={formData[field.fieldName] || false}
								onChange={handleInputChange}
								onBlur={() => handleFieldBlur(field.fieldName)}
								disabled = {viewMode}
							/>
							<label className="form-check-label font-14 font-medium black-v2 " htmlFor={field.fieldName}>
								{field.fieldName}
							</label>
						</div>
					);
				case 'file upload':
					return (
						<div className="my-4" key={field.fieldName}>
							<label className="form-label font-14 font-medium black-v2" htmlFor={field.fieldName}>{field.fieldName}</label>
							<input
								type="file"
								className="form-control custom-dropdown arch-border shadow-none font-14 font-medium black-v2"
								id={field.fieldName}
								name={field.fieldName}
								onChange={handleInputChange}
								onBlur={() => handleFieldBlur(field.fieldName)}
								required={field.required}
								disabled = {viewMode}
							/>
							{formData[field.fieldName] && (
								<div className="mt-2 font-14 font-medium black-v2">
									<strong>Selected file:</strong> {formData[field.fieldName].fileName}
								</div>
							)}
						</div>
					);
				default:
					return null;
			}
			}
		catch(err){
				console.log(err , "Error in the Render Field")
		}

	};

	// Render function for repository selection
	const renderRepositoryField = () => {
		try {
		return (
			<div className="my-4">
				<label className="form-label font-14 font-medium black-v2" htmlFor="repository">Tag to Repository</label>
				<select
					className="form-select custom-dropdown arch-border shadow-none custom-border-box font-14 font-medium black-v2 custom-select"
					id="repository"
					name="repository"
					value={formData.repository_id || ''}
					onChange={handleInputChange}
					onBlur={() => handleFieldBlur('repository')}
					disabled = {viewMode}
					required
				>
					<option value="">Select a repository</option>
					{repositories.map((repo) => (
						<option key={repo.repository_id} value={repo.repository_id}>{repo.repository_name}</option>
					))}
				</select>
			</div>
		);
		} catch (error) {
				console.log(error , "Error in teh renderRepositoryField")
		}
	};
	// Depends on Function to Verify the Particular Field is associated with other 
	const isDependencyFulfilled = (dependsOn) => {
		try {
		if (!dependsOn || !connectedSourceNodes) return null;
		console.log(dependsOn, "dependsOn");
		console.log(connectedSourceNodes, "Connected Source Node");

		const sourceNode = connectedSourceNodes.find(
			(node) => node.data.serviceName === dependsOn.serviceName
		);
		console.log(sourceNode, "SourceNode");

		if (sourceNode) {
			const field = sourceNode.data.fields.find(
				(f) => f.fieldName === dependsOn.fieldName
			);
			console.log(field, "Field heerere");
			return field ? field.UserValue : null;
		}
		

		return null;
		} catch (error) {
			console.log(error , "Error ")	
		}
		};

	// Main render
	return (
		<div className="col-md-3 mt-3 ps-1 pe-3 content-right-container">
			<div className="recommended-container pe-3">
				{/* Tab navigation */}
				<div className="custom-tab-scroll">
					<ul className="nav nav-pills d-flex gap-1 flex-nowrap mb-1" id="pills-tab" role="tablist">
						<li className="nav-item" role="presentation">
							<button
								className={`nav-link theme-tab cust-tab font-14 secondary-text-color font-medium px-3 py-2 ${activeTab === 'configure' ? 'active' : ''}`}
								onClick={() => setActiveTab('configure')}
								type="button"
								role="tab"
							>
								Configure
							</button>
						</li>
						<li className="nav-item" role="presentation">
							<button
								className={`nav-link theme-tab cust-tab font-14 secondary-text-color font-medium px-3 py-2 ${activeTab === 'recommendation' ? 'active' : ''}`}
								onClick={() => setActiveTab('recommendation')}
								type="button"
								role="tab"
							>
								Recommendation
							</button>
						</li>
					</ul>
				</div>

				{/* Tab content */}
				<div className="tab-content" id="pills-tabContent">
					{/* Configure tab */}
					<div className={`tab-pane fade ${activeTab === 'configure' ? 'show active' : ''} pe-1 custom-area-scrl`} id="dash-Configure" role="tabpanel">
						<form onSubmit={handleFormSubmit}>

							{configureDetails?.data?.fields?.map(renderField)}
							{renderRepositoryField()}
							{/* Tags section */}
							<div className="mt-4 mb-2 w-100">
								<p className="font-14 font-medium black-v2">Tags</p>
								<div className="table-responsive mt-2 table-border">
									<table className="table custom-table rech-table">
										<thead>
											<tr>
												<th className="text-start border-0">Tags</th>
												<th className="text-start border-0">Value</th>
												<th className="text-start border-0"></th>
											</tr>
										</thead>
										
										<tbody className="manage-work">
										{tags.map((tag, index) => (
											tag?.value && tag?.key ? (
											<tr key={index}>
												<td className="text-start">
												<input
													type="text"
													className="form-control custom-dropdown arch-border shadow-none py-1 font-14 font-medium black-v2"
													value={tag.key}
													onChange={(e) => editTag(index, e.target.value, tag.value)}
													readOnly={tag.defaultTag}
													disabled={viewMode}
												/>
												</td>
												<td className="text-start">
												<input
													type="text"
													className="form-control custom-dropdown arch-border shadow-none py-1 font-14 font-medium black-v2"
													value={tag.value}
													onChange={(e) => editTag(index, tag.key, e.target.value)}
													readOnly={tag.defaultTag}
													disabled={viewMode}
												/>
												</td>
												<td className="text-center">
												{!tag.defaultTag && (
													<a 
													href="#"
													onClick={(e) => {
														if (!viewMode) {
														e.preventDefault();
														removeTag(index);
														}
													}}
													>
													<img 
														src="images/bin-icon-red.svg" 
														alt="delete-icon" 
														className="icon-width-14" 
													/>
													</a>
												)}
												</td>
											</tr>
											) : null
										))}
										
										{/* Row for adding a new tag */}
										<tr>
											<td className="text-start">
											<input
												type="text"
												className="form-control custom-dropdown arch-border shadow-none py-1 font-14 font-medium black-v2"
												placeholder="Enter Tag"
												value={newTagKey}
												disabled={viewMode}
												onChange={(e) => setNewTagKey(e.target.value)}
											/>
											</td>
											<td className="text-start">
											<input
												type="text"
												className="form-control custom-dropdown arch-border shadow-none py-1 font-14 font-medium black-v2"
												placeholder="Enter Value"
												value={newTagValue}
												disabled={viewMode}
												onChange={(e) => setNewTagValue(e.target.value)}
											/>
											</td>
											<td className="text-center">
											<a 
												href="#"
												onClick={(e) => {
												e.preventDefault();
												addTag();
												}}
											>
												<img 
												src="images/add-plus-icon.svg" 
												alt="add-icon" 
												className="icon-width-14" 
												/>
											</a>
											</td>
										</tr>
										</tbody>
									</table>
								</div>
							</div>
							{/* <button type="submit" className="btn btn-primary mt-3">Save Configuration</button> */}
						</form>
					</div>


						{/* Recommendation tab */}
					<div className={`tab-pane fade ${activeTab === 'recommendation' ? 'show active' : ''} pt-4 tab-scrl`} id="dash-Recommendation" role="tabpanel">
				{isLoadingRecommendations ? (
					<p>Loading recommendations...</p>
				) : recommendationError ? (
					<p className="error-message">{recommendationError}</p>
				) : recommendations.length === 0 ? (
					<p>No recommendations available.</p>
				) : (
					recommendations.map((recommendationGroup, groupIndex) => (
					<div key={groupIndex} className="recommendation-group mb-4">
						<h2 className="font-20 color-black font-bold mb-3">{recommendationGroup.head}</h2>
						<p className="font-16 font-regular secondary-text-color-v5 mb-4">{recommendationGroup.Description}</p>

						{recommendationGroup.recommendations.map((recommendation, index) => (
						<div key={index} className="recommendation-section mb-4">
							<div className="d-flex align-items-center gap-3 mb-3">
							<p className="font-16 color-black font-medium mb-0">{recommendation.service}</p>
							{recommendation.speedup !== "N/A" && (
								<button className="domain-btn font-14 font-medium bg-primary-dark-v1">
								<img src="images/faster-analysis.svg" alt="faster-analysis" className="dark-theme-icons" />
								{recommendation.speedup}
								</button>
							)}
							</div>
							<p className="font-16 font-regular secondary-text-color-v5">{recommendation.recommendation}</p>

							<ul className="nav pb-3 custom-border-bottom" id={`myTab-${groupIndex}-${index}`} role="tablist">
							<li className="nav-item" role="presentation">
								<button 
								className="nav-link active work-tab pros-tab me-3" 
								id={`home-tab-${groupIndex}-${index}`}
								data-bs-toggle="tab" 
								data-bs-target={`#home-${groupIndex}-${index}`} 
								type="button" 
								role="tab"
								aria-controls={`home-${groupIndex}-${index}`} 
								aria-selected="true"
								>
								Pros <span>({recommendation.pros.length})</span>
								</button>
							</li>
							<li className="nav-item" role="presentation">
								<button 
								className="nav-link work-tab cons-tab" 
								id={`profile-tab-${groupIndex}-${index}`}
								data-bs-toggle="tab" 
								data-bs-target={`#profile-${groupIndex}-${index}`} 
								type="button" 
								role="tab"
								aria-controls={`profile-${groupIndex}-${index}`} 
								aria-selected="false"
								>
								Cons <span>({recommendation.cons.length})</span>
								</button>
							</li>
							</ul>

							<div className="tab-content" id={`myTabContent-${groupIndex}-${index}`}>
							<div 
								className="tab-pane fade show active" 
								id={`home-${groupIndex}-${index}`} 
								role="tabpanel"
								aria-labelledby={`home-tab-${groupIndex}-${index}`}
							>
								<h6 className="font-18 font-bold success-v4 my-3">Pros</h6>
								<ul className="work-custom-list ps-0 pb-3 custom-border-bottom">
								{recommendation.pros.map((pro, proIndex) => (
									<li key={proIndex} className="font-16 font-regular secondary-text-color-v5 mb-3">
									{pro.prosDescription}
									</li>
								))}
								</ul>
							</div>
							<div 
								className="tab-pane fade" 
								id={`profile-${groupIndex}-${index}`} 
								role="tabpanel"
								aria-labelledby={`profile-tab-${groupIndex}-${index}`}
							>
								<h6 className="font-18 font-bold danger-red my-3">Cons</h6>
								<ul className="work-custom-list ps-0 pb-3 custom-border-bottom">
								{recommendation.cons.map((con, conIndex) => (
									<li key={conIndex} className="font-16 font-regular secondary-text-color-v5 mb-3">
									{con.consDescription}
									</li>
								))}
								</ul>
							</div>
							</div>
						</div>
						))}
					</div>
					))
				)}
					</div>
				</div>
				</div>
		</div>
	);
}
export default ConfigureComponent;
