
//Import statements: PS_02
import React, { useEffect, useState } from 'react';
import WorkbenchPopup from '../../Organization/Workbench/WorkBench/PopupMaintanence/WorkBenchPopUp';
import { editImportedModule, fetchCategoryData, saveImportModules } from '../../../Service/api';
import { useModuleContext } from '../../../ContextProvider/ModulesContxt';
import WorkbenchPopupComponent from '../../Organization/Workbench/WorkBench/PopupMaintanence/WorkBenchPopUpComponent';
import Loader from '../../loader/loader';
import WorkbenchLoader from '../../Organization/Workbench/WorkBenchLoader';

//PS_03 - Props passed
function ImportModulesPopup({ module, setModule,
    //selectedProviderId,
    //   selectedProviderNamee,
    selectedCategory,
    setSelectedCategory,
    editMode, setEditMode,
    getAllModules
}) {

    //PS_04-  INITIALIZE context variables
    const {
        importModulePopup, setImportModulePopup,
        selectedProviderId,
        cloudProviderData,
        categoryData, setCategoryData,
        setReload

    } = useModuleContext()

    const initialState = {
        name: '',
        source: '',
        category: '',
        version: '',
        file: null,
        fileName: '',
        gitUrl: '',
        patToken: ''
    };

    //PS_05-PS_06
    const selectedProviderName = cloudProviderData.find(provider => provider.providerId === selectedProviderId)?.providerName;
    const userId = localStorage.getItem('userId')
    const [formData, setFormData] = useState(initialState);
    const [popupMsg, setPopUpMsg] = useState({ "Msg": "", show: false, success: false })
    const [msg, setMsg] = useState('');
    const [moduleIcon, setModuleIcon] = useState(null)
    const [customModules, setCustomModules] = useState(true)
    const [awsModules, setAwsModules] = useState(false)
    const [btndisable, setBtnDisable] = useState(false)

    const [showLoader, setShowLoader] = useState(false)



    console.log(editMode, module, "module for edit")

    //PS_08 - Initial fetch for fetching category
    useEffect(() => {
        fetchAllCategoryData()
    }, [selectedCategory, selectedProviderId])


    //PS_09- Update formData state when module prop changes
    useEffect(() => {
        editImportedModuleById()
    }, [module]);

    console.log(module, "Editmodule")

    //PS_10- prepopulating the edit module values if exist
    const editImportedModuleById = () => {

        console.log("outside editImportedModuleById function", module)
        if (module) {
            console.log("inside editImportedModuleById function", module)
            setFormData({
                moduleId: module.moduleId,
                name: module.moduleName || '', // Ensure fallback for undefined values
                source: module.source || '',
                category: module.categoryId || '', // Ensure fallback for undefined values
                version: module.currentVersion || '',
                file: module.imageUrl,  // Reset file if needed
                fileName: module.imageUrl ? module.imageUrl.split('/').pop() : '', // Reset fileName
                gitUrl: module.gitUrl || '',
                patToken: module.patToken || ''
            });
            if (module.custom === true) {
                setCustomModules(true);
                setAwsModules(false);
            } else {
                setAwsModules(true);
                setCustomModules(false);
            }

        }

    }



    //PS_59-PS_62 - To update the edited values
    const handleUpdate = async () => {
        setBtnDisable(true)

        try {
            const { file, ...restFormData } = formData; // Destructure to get file separately
            console.log(restFormData, "import module formdata");
            console.log(formData, "formdata consoleee")

            // Convert file to Base64
            let imgBase64 = '';
            const s3Url = /^https:\/\/(s3[-.][a-z0-9-]+\.amazonaws\.com|[a-z0-9-]+\.s3\.amazonaws\.com)\/.+/i;


            //  if ((formData.file) != null ) {
            if (moduleIcon) {
                imgBase64 = await convertFileToBase64(file);
            }


            // Prepare the payload
            const payload = {
                userId: userId,
                ...restFormData,
                providerId: selectedProviderId, // Add providerId
                providerName: selectedProviderName, // Add providerName
                imgBase64: imgBase64 || formData.file,
                categoryId: formData.category,
                versionUpdate: "false",
                imageUpdate: imgBase64 == "" ? "false" : "true"
            };
            setShowLoader(true)


            const response = await editImportedModule(payload);
            console.log(response, "Response from saveImportModules");

            if(response.data.success){
            console.log(response, "Response from saveImportModules");
            setBtnDisable(false)

            handleClose()
            setReload(true)
            const fileInput = document.getElementById('serviceIcon');
            fileInput.value = '';


            }

        } catch (error) {
            console.error("Error submitting form:", error);
            setBtnDisable(false)
        } finally{
            setShowLoader(false)
        }
    };


    //PS_20 -PS_21 - Handle field onchange
    const handleChange = (e) => {

        console.log(formData, "formdata in handleChange")
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    //PS_23-PS_26 : Handles File selection 
    const handleFileChange = (e) => {

        console.log(formData, "formdata in handleFileChange")

        const file = e.target.files[0];
        if (file) {
            setFormData((prev) => ({
                ...prev,
                file,
                fileName: file.name // Store the file name

            }));
            setModuleIcon(file)
        }
    };

    //PS_63 - PS_68 -To fetch category data
    const fetchAllCategoryData = async () => {
        try {
            const payload = {
                "cloud_provider_id": selectedProviderId,
                "userId": userId
            }
            const response = await fetchCategoryData(payload);
            if (response.status === 200) {
                console.log(response, "resp")
                setCategoryData(response.data.body.data);
            } else {
                throw new Error('Error fetching category data');
            }
        } catch (err) {
            //postErrorLog(err.message || err.toString());
            // setError(err);
        }
    };

    //PS_57-PS_62 - Handle import submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Regex pattern for validating GitHub URL
        const gitHubUrlPattern = /^(https?:\/\/)?(www\.)?github\.com\//i;

        if (formData.gitUrl && !gitHubUrlPattern.test(formData.gitUrl)) {
            setPopUpMsg({ ...popupMsg, ["Msg"]: "Invalid GitHub URL", ["show"]: true, ["success"]: false });
            return;
        }

        try {
            const { file, ...restFormData } = formData; // Destructure to get file separately
            console.log(restFormData, "import module formdata");

            // Convert file to Base64
            let imgBase64 = '';
            if (file) {
                imgBase64 = await convertFileToBase64(file);
            }

            // Prepare the payload
            const payload = {
                userId: userId,
                name: restFormData.name||"",
                source: restFormData.source||"",
                category: restFormData.category||"",
                version: restFormData.version||"",
                fileName: restFormData.fileName||"",
                gitUrl:restFormData.gitUrl||"",
                patToken: restFormData.patToken||"",

                providerId: selectedProviderId, // Add providerId
                providerName: selectedProviderName, // Add providerName
                imgBase64: imgBase64,
                categoryId: formData.category,
                custom: customModules == true ? true : false,
            };
setShowLoader(true)
            setBtnDisable(true)
            const response = await saveImportModules(payload);
            console.log(response, "Response from saveImportModules");
            if (response.status === 200) {
                setBtnDisable(false)
                handleClose()
                setReload(true)
                const fileInput = document.getElementById('serviceIcon');
                fileInput.value = '';

            } else {
                setBtnDisable(false)
                setPopUpMsg({ ...popupMsg, ["Msg"]: response.message, ["show"]: true, ["success"]: false })
            }

        } catch (error) {
            setBtnDisable(false)
            console.error("Error submitting form:", error);

            setPopUpMsg({ ...popupMsg, ["Msg"]: error, ["show"]: true, ["success"]: false })
        } finally{
            setShowLoader(false)

        }
    };

    // PS_69 - Helper function to convert file to Base64
    const convertFileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(file); // Convert to Base64
        });
    };




    //PS_55 -PS_56 - Handle on closing of popup
    const handleClose = () => {

        setFormData({
            name: '',
            source: '',
            category: '',
            version: '',
            file: null,
            fileName: '',
        });
        setModule(null)
        setImportModulePopup(false)
        setEditMode(false)

    };

    const handleCustomModulesChange = () => {
        setFormData(initialState);
        setCustomModules(true);
        setAwsModules(false);
    };

    const handleAwsModulesChange = () => {
        setFormData(initialState);
        setCustomModules(false);
        setAwsModules(true);
    };

    console.log(awsModules, customModules, "radio button check")



   
    //PS_72 - Rendering part
    return (
        <>

            {importModulePopup && (
                <div
                    className="modal fade show"
                    id="staticBackdrop-21"
                    // data-bs-backdrop="static"
                    // data-bs-keyboard="false"
                    // tabIndex={-1}
                    // aria-labelledby="staticBackdropLabel"
                    // aria-hidden="true"
                    style={{ display: 'block', backgroundColor: "rgba(0,0,0,0.6)" }}
                >
                    <div className="modal-dialog modal-dialog-centered justify-content-center">
                        <div className="modal-content border-0">
                            {/* <form onSubmit={handleSubmit}> */}
                            <div className="modal-header border-0 p-4">
                                <h1
                                    className="modal-title font-20 font-bold black-v2"
                                    id="staticBackdropLabel"
                                >
                                    {editMode ? "Update Module" : "Import Module"}
                                </h1>
                                <button
                                    type="button"
                                    className="btn shadow-none"
                                    // data-bs-dismiss="modal"
                                    // aria-label="Close"
                                    // id='close'
                                    onClick={handleClose}
                                >
                                    <img
                                        src="images/close-icon.svg"
                                        alt="close-icon"
                                        className="icon-hover shadow-none modal-close-filter"
                                    />
                                </button>
                            </div>
                            <div>
                                {
                                    popupMsg.show == true && popupMsg.Msg != "" ?
                                        <WorkbenchPopup
                                            popupMsg={popupMsg}
                                            setPopUpMsg={setPopUpMsg}
                                        />
                                        : <></>
                                }
                            </div>

                            <div className="d-flex gap-5" style={{ paddingLeft: '5%' }}>
                                <div className="form-check mt-3">
                                    <input
                                        className="form-check-input shadow-none m2e-2"
                                        type="radio"
                                        name="flexRadioDefault"
                                        id="flexRadioDefault1"
                                        checked={customModules}
                                        onChange={handleCustomModulesChange}
                                        disabled={editMode}
                                    />
                                    <label className="form-check-label font-14 font-regular secondary-text-color-v4" htmlFor="flexRadioDefault1">
                                        Custom
                                    </label>
                                </div>
                                <div className="form-check mt-3">
                                    <input
                                        className="form-check-input shadow-none me-2"
                                        type="radio"
                                        name="flexRadioDefault"
                                        id="flexRadioDefault11"
                                        checked={awsModules}
                                        onChange={handleAwsModulesChange}
                                        disabled={editMode}
                                    />
                                    <label className="form-check-label font-14 font-regular secondary-text-color-v4" htmlFor="flexRadioDefault11">
                                        AWS
                                    </label>
                                </div>
                            </div>

                            <div className="modal-body px-4 pt-0 service-scrl">
                                {/* Name Section */}
                                <div className="my-4">
                                    <label className="form-label font-14 font-medium black-v2 " htmlFor="name">Name</label>
                                    <input
                                        type="text"
                                        className="form-control custom-dropdown shadow-none color-black"
                                        id="name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        placeholder="Enter the Module Name"
                                    />
                                </div>


                                {/* Source Section */}
                                {awsModules && (
                                    <div className="my-4">
                                        <label className="form-label font-14 font-medium black-v2" htmlFor="source">Source</label>
                                        <input
                                            type="text"
                                            className="form-control custom-form shadow-none color-black"
                                            id="source"
                                            name="source"
                                            value={formData.source}
                                            onChange={handleChange}
                                            placeholder="Enter the Source Name"
                                            disabled={editMode}
                                        />
                                    </div>
                                )}

                                {/* gitHubUrl */}
                                {customModules && (
                                    <div className="my-4">
                                        <label className="form-label font-14 font-medium black-v2" htmlFor="source">Github URL</label>
                                        <input
                                            type="text"
                                            className="form-control custom-form shadow-none color-black"
                                            id="gitUrl"
                                            name="gitUrl"
                                            value={formData.gitUrl}
                                            onChange={handleChange}
                                            placeholder="Enter the GitHub URL"
                                            disabled={editMode}
                                        />
                                    </div>
                                )}

                                {/* PAT Token */}
                                {customModules && (
                                    <div className="my-4">
                                        <label className="form-label font-14 font-medium black-v2" htmlFor="source">PAT Token</label>
                                        <input
                                            type="text"
                                            className="form-control custom-form shadow-none color-black"
                                            id="patToken"
                                            name="patToken"
                                            value={formData.patToken}
                                            onChange={handleChange}
                                            placeholder="Enter the PAT token"
                                            disabled={editMode}
                                        />
                                    </div>
                                )}



                                {/* category Fields */}
                                <label className="form-label font-14 font-medium black-v2" htmlFor="source">Category</label>
                                <div className="input-group title-dropdown">
                                    <select
                                        name="category"
                                        id="select-category"
                                        className="form-select font-14 font-regular black-v2"
                                        value={formData.category}
                                        onChange={handleChange}
                                        disabled={module !== null}
                                    >
                                        <option  >Select Category</option>
                                        {categoryData?.map((category) => (
                                            <option key={category.serviceCategoryId} value={category.serviceCategoryId}>
                                                {category.serviceCategoryName}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                {/* Service Icon */}
                                <div className="col-md-3 mb-4">
    <div className="d-flex align-items-center justify-content-between" style={{ paddingTop: '30px' }}>
        <label className="form-label font-14 font-medium color-black" htmlFor="serviceIcon" style={{ whiteSpace: 'nowrap', paddingTop: '10px' }}>
            Upload Service Icon<span className="required">*</span>
        </label>
        <button
            type="button"
            className="btn p-0 ms-1 tooltip-top"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Kindly add updated icons with proper format - sample text need to add by dev team"
        >
          
            <img
                src="images/tooltip-icon.svg"
                alt="tooltip-icon"
                className="tooltip-icon "
            />
        </button>
    </div>
    <input
        type="file"
        className="form-control custom-file"
        id="serviceIcon"
        name="file"
        onChange={handleFileChange}
        aria-label="upload"
    />
    {formData.fileName && (
        <div className="mt-2 font-14 font-medium black-v2" style={{ whiteSpace: 'nowrap' }}>
            {formData.fileName}
        </div>
    )}
</div>


                                {/* Versions */}
                                {awsModules && (
                                    <div className="my-4">
                                        <label className="form-label font-14 font-medium black-v2" htmlFor="version">Version</label>
                                        <input
                                            type="text"
                                            className="form-control custom-form shadow-none color-black"
                                            id="version"
                                            name="version"
                                            value={formData.version}
                                            onChange={handleChange}
                                            placeholder="Enter the Version"
                                            disabled={editMode}

                                        />
                                    </div>
                                )}

                                {/* Action Buttons */}
                                <div className="d-flex justify-content-end align-items-center mt-4 mb-2 gap-3">
                                    <button
                                        type="button"
                                        className="button outline-button text-nowrap py-12"
                                        data-bs-dismiss="modal"
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </button>


                                    {editMode ? (
                                        <button
                                            type="submit"
                                            className="button primary-button text-nowrap px-4 py-12"
                                            onClick={handleUpdate} // Call the update function
                                            disabled={
                                                formData.name === "" ||
                                                formData.category === "" ||
                                                formData.file == null ||
                                                (awsModules && formData.source === "") ||
                                                (awsModules && formData.version === "") ||
                                                (customModules && formData.gitUrl === "") ||
                                                (customModules && formData.patToken === "") ||
                                                btndisable
                                            }
                                        >
                                            Update
                                        </button>
                                    ) : (
                                        <button
                                            type="submit"
                                            className="button primary-button text-nowrap px-4 py-12"
                                            onClick={handleSubmit} // Call the submit function
                                            disabled={
                                                formData.name === "" ||
                                                formData.category === "" ||
                                                formData.file == null ||
                                                (awsModules && formData.source === "") ||
                                                (awsModules && formData.version === "") ||
                                                (customModules && formData.gitUrl === "") ||
                                                (customModules && formData.patToken === "") ||
                                                btndisable
                                            }
                                        >
                                            Import
                                        </button>
                                    )}
                                </div>
                            </div>
                            {/* </form> */}
                        </div>
                    </div>
                </div>
            )}
                  {showLoader && (
                <WorkbenchLoader isLoading={showLoader} />
            )}
        </>
    );
}

export default ImportModulesPopup;