import React from 'react';

const Pagination = ({ currentPage, setCurrentPage, totalItems, itemsPerPage,displayedCount }) => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <div className="d-flex justify-content-between align-items-center my-4">
            <p className="font-14 font-regular secondary-text-color mb-0">
                # of Records:
                <span className="font16 font-medium black-v2">
                    <span>{Math.min(displayedCount, totalItems)}</span> out of <span>{totalItems}</span>
                </span>
            </p>

            {/* Pagination */}
            <div className="d-flex justify-content-end">
                <nav aria-label="Page navigation example">
                    <ul className="pagination mb-0">
                        <li className="page-item m-1 lh-1" style={{"cursor":"pointer"}}>
                            <a 
                                className="page-link custom-pagenation px-3 py-2" 
                                 
                                aria-label="Previous" 
                                onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
                            >
                                <span aria-hidden="true">
                                    <img src="images/pagenation-left.svg" alt="previous-arrow" />
                                </span>
                            </a>
                        </li>


                        {
                            console.info([...Array(totalPages)],"This is the pagination check")
                        }

                        {/* Page numbers */}
                        {[...Array(totalPages)].map((_, index) => {
    const startPage = Math.max(1, currentPage - 1); // At least page 1
    const endPage = Math.min(totalPages, currentPage + 1); // At most total pages

    // Only render the `<li>` elements within the calculated range
    if (index + 1 >= startPage && index + 1 <= endPage) {
        return (
            <li key={index} className="page-item m-1 lh-1" style={{ cursor: "pointer" }}>
                <a
                    className={`page-link custom-pagenation ${currentPage === index + 1 ? 'active' : ''}`}
                    onClick={() => handlePageChange(index + 1)}
                >
                    {index + 1}
                </a>
            </li>
        );
    }
    return null; // Don't render anything outside the range
})}

                        <li className="page-item m-1 lh-1" style={{"cursor":"pointer"}}>
                            <a 
                                className="page-link custom-pagenation" 
                                 
                                onClick={() => handlePageChange(totalPages)} 
                            >
                                <img src="images/three-dots-img.svg" alt="three-dots-img" />
                            </a>
                        </li>

                        <li className="page-item m-1 lh-1" style={{"cursor":"pointer"}}>
                            <a 
                                className="page-link custom-pagenation" 
                                 
                                onClick={() => handlePageChange(totalPages)}
                            >
                                {totalPages}
                            </a>
                        </li>

                        <li className="page-item m-1 lh-1" style={{"cursor":"pointer"}}>
                            <a 
                                className="page-link custom-pagenation px-3 py-2" 
                                 
                                aria-label="Next" 
                                onClick={() => handlePageChange(Math.min(currentPage + 1, totalPages))}
                            >
                                <span aria-hidden="true">
                                    <img src="images/pagenation-right.svg" alt="next-arrow" />
                                </span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default Pagination;