//PS_03
import React, { useState, useEffect,useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Select from 'react-select';
import { getOrganizationDetailsByIdApi,createOrganizationApi ,editOrganizationApi} from '../../Service/api';
import { OrgContext } from '../../ContextProvider/OrgContext';


const AddOrganization = () => {
    //PS_35 navigate function
    const navigate = useNavigate();

    //PS_04 create state variables for necessary functionality
    const location = useLocation();
    const state = location.state;

    const [orgId, setorgId] = useState('');

    const [inputValue, setInputValue] = useState('');
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [organizationFormData, setOrganizationFormData] = useState({
        orgId: '',
        orgName: '',
        industryName: '',
        revenue: '',
        noOfEmployees: '',
        logo: '',
        mail: '',
        teamsUrl: '',
        tags: []
    });

    const [employeeOptions] = useState(['Select', '1-500', '501-1000', '1001-2000', '3000-5000', '5001-10000', '>10000']);
    const [industryOptions] = useState(['Select', 'Finance', 'E-commerce', 'Health care', 'Manufacturing', 'IT', 'Retail', 'Media and entertainment', 'Research and development', 'Logistics', 'Telecommunications', 'Education', 'Law Firm', 'Gaming']);
    const [errorMessages, setErrorMessages] = useState({});
    const [logoFile, setLogoFile] = useState(null);
    const [logoPreviewUrl, setLogoPreviewUrl] = useState('');
    const [fileName, setFileName] = useState('');
    const [logoUpdated, setLogoUpdated] = useState("false");
    const [userId, setuserId] = useState(localStorage.getItem('userId'));

 const {
        userRoles,
        providerId,
        architectures,
        canCreateWorkload,
        canDeleteWorkload,
        canViewWorkload,
        setuserRoles,
    
        // Add organization and provider fields here
        providerName,       
        orgData,           
        setOrgData, 
        setOrgId,       
        setArchitectures,
    
        envId,             // Environment ID
        workloadId,        // Workload ID
        aiArchitecture,    
        viewMode,          
        envName,           
        language,          
        archType,          
        business,          
        rulebook,          
        archDesc,          
        services,
    
        lastBuildID,       
        lastBuildDate,     
        lastBuildMessage,
    
        repoId,            
        repoName,
    
        PulseOrganization_id,
        PulseVertical_id,
        PulseVertical_name,
        PulseOrganization_name,
        PulseIdentification,
    
        setEnvId           // Function to update environment ID
    } = useContext(OrgContext);
    //PS_27 handle tags
    const handleChange = (selected) => {
        setSelectedOptions(selected);
    };
    

  
    // PS_06 Load organization details if editing
    useEffect(() => {
        console.log(state);
        //PS_07
        if (state && state?.orgId) {
            console.log("in add",state);
            setorgId(state?.orgId)
            // Simulate fetching organization details with mock data
            loadOrganizationDetails(state.orgId);
            setorgId( state.orgId)
        }
    }, [state]);

    const isFormValid = () => {
        return organizationFormData.orgName && 
               organizationFormData.industryName && 
               organizationFormData.revenue && 
               organizationFormData.noOfEmployees && 
               organizationFormData.mail&&
               organizationFormData.teamsUrl // Add other required fields as necessary
    };
    //PS_25 handle tags
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent form submission
        }
        if (e.key === 'Enter' && inputValue.trim() !== '') {
            const newTag = inputValue.trim();
            console.log("tagssdsa",newTag);
            // Add the new tag to selected options if it doesn't already exist
            if (!selectedOptions.some(option => option.label === newTag)) {
                setSelectedOptions(prev => [...prev, { value: newTag, label: newTag, name: newTag }]);
               
            }
            setInputValue(''); // Clear input field after adding
            setErrorMessages(prevErrors => ({
                ...prevErrors,
                tags: ''
            }));
            
        }
    };
    const loadOrganizationDetails = async (organizationId) => {
        console.log("org",organizationId);
        const payload = {
            userId : userId,
            orgId : organizationId,
        }
        try {
            // PS_07 Call your API to get organization details by ID
            const response = await getOrganizationDetailsByIdApi(payload); // Call the API function
            if (response.success) {
                console.log("Organization details fetched successfully");
                console.log(response.data); // Log the fetched data
    
                //PS_11 Update state with organization details
                setOrganizationFormData(response.data); // Ensure you have setOrganizationDetails defined
                setSelectedOptions(
                    response.data.tags
                        .filter(tag => tag.tagName !== "UTI") // Filter out tags with name "UTI"
                        .map(tag => ({
                            value: tag.tagId, // Use tagId as value
                            label: tag.tagName, // Use tagName as label
                            name: tag.tagName // You can also use tagName for name or modify as needed
                        }))
                );
                    
                setLogoPreviewUrl(response.data.logo) 
                // setLogoPreviewUrl(URL.createObjectURL(response.data.logo));

            } else {
                // Handle error
                console.error('Error fetching organization details');
            }
        } catch (error) {
            console.error('An error occurred while fetching organization details', error);
        }
    };
    //PS_12 handleInputChange
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        
        // Clear the specific error message for the field being edited
        setErrorMessages(prevErrors => ({
            ...prevErrors,
            [name]: '' // Clear the error message for this field
        }));
    
        // Update organization form data
        setOrganizationFormData({ ...organizationFormData, [name]: value });
        
    };
//PS_15 validate input Fields
const validateFields = () => {
    let errors = {};

    // Organization Name Validation
    if (!organizationFormData.orgName) {
        errors.orgName = "Organization Name is required.";
    } else if (orgData.some(org => org.orgName === organizationFormData.orgName && org.orgId !== orgId)) {
        // Check if the organization name exists but is not the current organization being edited
        errors.orgName = "Organization Name already exists.";
    }

    // Industry Validation
    if (!organizationFormData.industryName || organizationFormData.industryName === "Select") {
        errors.industryName = "Please enter the industry.";
    }

    // Revenue Validation
    if (!organizationFormData.revenue) {
        errors.revenue = "Please select revenue.";
    }

    // Number of Employees Validation
    if (!organizationFormData.noOfEmployees || organizationFormData.noOfEmployees === "Select") {
        errors.noOfEmployees = "Please select the Number of employees.";
    }

    // Logo Validation
    // if (!logoFile) {
    //     errors.logo = "Upload the logo as JPG or PNG format and not more than 1 MB.";
    // }

    if (!logoPreviewUrl) { // Check if a logo preview URL is set, indicating a logo has been uploaded
        errors.logo = "Upload the logo as JPG or PNG format and not more than 1 MB.";
    } else if (errorMessages.logo) { // Check for any existing error messages related to the logo
        errors.logo = errorMessages.logo; // Use the existing error message from logo upload validation
    }

    // Validate Email ID
    if (organizationFormData.mail && !/^[\w-.]+@(avasoft\.com|zeb\.co)$/.test(organizationFormData.mail)) {
        errors.mail = "Email ID must be a valid address ending with @avasoft.com or @zeb.co";
    }

    // Validate Teams URL
    if (organizationFormData.teamsUrl && !/^https?:\/\/.+/.test(organizationFormData.teamsUrl)) {
        errors.teamsUrl = "Teams URL must be a valid link.";
    }

    // Validate Tags
    if (selectedOptions.length === 0) {
        errors.tags = "Please add at least one default tag.";
    }

    setErrorMessages(errors);
    return Object.keys(errors).length === 0; // Return true if no errors
};
    //PS_28
    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission behavior
        if (selectedOptions.length > 0) {
            setOrganizationFormData(prevData => ({
                ...prevData,
                tags: [...prevData.tags, ...selectedOptions] // Merge selected options into tags
            }));
            console.log('Tags updated:', organizationFormData.tags);
        } 
        if (!validateFields()) return;

        const payload = {
            userId: userId,
            orgName: organizationFormData.orgName,
            industryId: organizationFormData.industryName,
            revenue: organizationFormData.revenue,
            noOfEmployeesId: organizationFormData.noOfEmployees,
            logo: organizationFormData.logo, 
            tags: selectedOptions.map(option => option.label), 
            teamsUrl: organizationFormData.teamsUrl,
            mail: organizationFormData.mail,
        };

        const payload2 = {
            userId:userId,
            orgName: organizationFormData.orgName,
            orgId: orgId,
            industryId: organizationFormData.industryName,
            revenue: organizationFormData.revenue,
            noOfEmployeesId: organizationFormData.noOfEmployees,
            logo: organizationFormData.logo, 
            tags: selectedOptions.map(option => option.label), 
            teamsUrl: organizationFormData.teamsUrl,
            mail: organizationFormData.mail,
            logoUpdate: logoUpdated=="true"?true:false 
                };
        console.log("payload", payload2);

        const finalTags = [...new Set([...payload.tags, "UTI"])];
        payload.tags = finalTags; // Update payload with final tags including "UTI"
        payload2.tags = finalTags; // Update payload2 with final tags including "UTI"
        try {
            let response;
            if (orgId) {
                response = await editOrganizationApi(payload2);
            } else {
                response = await createOrganizationApi(payload);
            }
            if (response?.success) {
                navigate('/manage-organizations');
            } else {
                console.error('Error creating organization');
            }
        } catch (error) {
            console.error('Failed to create organization:', error);
        }
    };


    // PS_20 Handle the logo
    const handleLogoChange = (event) => {
        const file = event.target.files?.[0];
    
        if (file) {
            // Validate file size (1MB)
            if (file.size > 1048576) {
                setErrorMessages(prevErrors => ({
                    ...prevErrors,
                    logo: "Upload the logo as JPG or PNG format and not more than 1 MB."
                }));
                return;
            }
    
            // Validate image resolution
            const img = new Image();
            img.src = URL.createObjectURL(file);
            img.onload = () => {
                if (img.width < 32 || img.height < 32) {
                    setErrorMessages(prevErrors => ({
                        ...prevErrors,
                        logo: "Image resolution must be greater than 32 pixels."
                    }));
                    return;
                }
    
                // If validation passes
                setLogoFile(file);
                setLogoPreviewUrl(URL.createObjectURL(file));
                setLogoUpdated("true");
                // Convert file to Base64 and update organization form data
                convertFileToBase64(file).then((base64) => {
                    const paddedBase64 = padBase64String(base64);
                    setOrganizationFormData({ ...organizationFormData, logo: paddedBase64 });
                }).catch((error) => {
                    console.error("Error converting file to Base64:", error);
                });
                
                // Clear logo error message when a new file is selected
                setErrorMessages(prevErrors => ({
                    ...prevErrors,
                    logo: ''
                }));
                
                setFileName(file.name); // Set the file name for display
            };
            
            // Clear previous error messages when selecting a new file
            setErrorMessages(prevErrors => ({
                ...prevErrors,
                logo: ''
            }));
            
        }
    };
    // Function to convert file to Base64
    const convertFileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
    
            reader.onload = () => {
                resolve(reader.result); // This is the Base64 string
            };
    
            reader.onerror = (error) => {
                reject(error);
            };
        });
    };
    const padBase64String = (base64) => {
        const padding = '='.repeat((4 - (base64.length % 4)) % 4);
        return base64 + padding;
    };

    // PS_21 Handle logo removal
    const handleRemoveLogo = () => {
        setLogoFile(null);
        setLogoUpdated("false"); // Reset logo update state
        setLogoPreviewUrl('');
        setFileName('');
        setOrganizationFormData({ ...organizationFormData, logo: '' });
        const fileInput = document.getElementById('logo');
        fileInput.value = '';
        setErrorMessages(prevErrors => ({
            ...prevErrors,
            logo: ''
        }));
    };

    const MultiValue = ({ data, removeProps }) => (
        <span className="blue-batch font-12 d-flex gap-1  align-items-center font-medium">
            {data.label}
            <a {...removeProps}>
                <img src="images/tags-close-icon.svg" alt="close-icon" className="icon-custom-filter" />
            </a>
        </span>
    );
    const customStyles = {
        control: (base) => ({
            ...base,
            border: '1px solid #ccc',
                boxShadow: 'none',
                borderRadius: '4px',
                width:"1400px",
                minHeight: '50px',
            }),

        multiValue: (base) => ({
            ...base,
            display: 'flex',
            alignItems: 'center',
            marginRight: '5px',
        }),
        multiValueLabel: (base) => ({
            ...base,
            padding: '0',
        }),
        multiValueRemove: (base) => ({
            ...base,
            display: 'none', 
        }),
        valueContainer: (base) => ({
            ...base,
            paddingRight: selectedOptions.length > 2 ? '30px' : '10px', 
            position: 'relative',
        }),
    };
    const customComponents = {
        MultiValue,
    };

    const handleOrgNameChange = (e) => {
    const value = e.target.value;

    // Regular expression to allow only letters (a-z, A-Z)
    const regex = /^[A-Za-z]*$/;

    if (regex.test(value) || value === '') {
        setOrganizationFormData({ ...organizationFormData, orgName: value });
        setErrorMessages({ ...errorMessages, orgName: '' }); // Clear error message if valid
    } else {
        setErrorMessages({ ...errorMessages, orgName: 'Please enter only letters (no numbers, spaces, or special characters).' });
    }
};
    useEffect(() => {
        // Initialize Bootstrap tooltips
        const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        tooltipTriggerList.forEach(tooltipTriggerEl => {
            new window.bootstrap.Tooltip(tooltipTriggerEl);
        });
    }, []);

    return (
        <div className="container-fluid custom-container">
            {/* Slide title start here */}
            <div className="d-block mt-4">
                <div className="d-flex align-items-center mb-3 mb-md-0 ">
                    <a onClick={() => navigate('/manage-organizations')}><img src="images/back-arrow.svg" alt="back-arrow" title="Back" /></a>
                    <h2 className="font-20 font-bold black-v2 mb-0 ms-3">Add Organization</h2>
                </div>
            </div>
            {/* Slide title end here */}

            {/* Page Content Start here */}
            <form >
                <div className="row add-organization-form">
                    <div className="col-md-12 mt-4 ps-4">
                        <div className="ps-4">
                            <h3 className="font-18 font-medium mb-4 pb-2 black-v2">Primary Details</h3>
                            <div className="row">
                            <div className="col-md-3 mb-4">
    <label className="form-label font-14 font-medium" htmlFor="orgName">
        Organization Name<span className="required">*</span>
    </label>
    <input 
        type="text" 
        id="orgName" 
        name="orgName"
        className={`form-control custom-form ${errorMessages.orgName ? 'is-invalid' : ''}`} 
        placeholder="Enter Organization Name" 
        value={organizationFormData.orgName} 
        onChange={handleOrgNameChange} // Use the new handler
    />
    {errorMessages.orgName && <div className="invalid-feedback">{errorMessages.orgName}</div>}
</div>
                                <div className="col-md-3 mb-4">
                                    <label className="form-label font-14 font-medium" htmlFor="industryName">Industry<span className="required">*</span></label>
                                    <select 
                                        name="industryName" 
                                        id="industryName" 
                                        className={`form-select form-control custom-form ${errorMessages.industryName ? 'is-invalid' : ''}`} 
                                        value={organizationFormData.industryName} 
                                        onChange={handleInputChange}
                                    >
                                        {industryOptions.map((option, index) => (
                                            <option key={index} value={option}>{option}</option>
                                        ))}
                                    </select>
                                    {errorMessages.industryName && <div className="invalid-feedback">{errorMessages.industryName}</div>}
                                </div>
                                <div className="col-md-3 position-relative mb-4">
        <label className="form-label font-14 font-medium" htmlFor="revenue">
            Revenue<span className="required">*</span>
        </label>
        <div className="input-with-icon-wrapper">
            <input 
                type="text" 
                id="revenue" 
                name="revenue"
                className={`form-control22 custom-form ${errorMessages.revenue ? 'is-invalid' : ''}`} 
                placeholder="Enter Revenue" 
                value={organizationFormData.revenue} 
                onChange={handleInputChange} 
                style={{ paddingLeft: '47px' }} // Adjust padding to make space for the icon
            />
            <img 
                src="images/revenue-icon.svg" 
                alt="Revenue-icon" 
                className="revenue-icon light-theme-img position-absolute" 
                style={{ width: '44px', left: '2px', top: '50%', transform: 'translateY(-50%)' }} 
            />
            <img 
                src="images/revenue-icon-dark.svg" 
                alt="Revenue-icon" 
                className="revenue-icon dark-theme-img position-absolute" 
                style={{ width: '44px', left: '14px', top: '70%', transform: 'translateY(-50%)' }} 
            />
        </div>
        {errorMessages.revenue && <div className="invalid-feedback">{errorMessages.revenue}</div>}
    </div>
                                <div className="col-md-3 mb-4">
                                    <label className="form-label font-14 font-medium" htmlFor="noOfEmployees">Number of Employees<span className="required">*</span></label>
                                    <select 
                                        name="noOfEmployees" 
                                        id="noOfEmployees" 
                                        className={`form-select form-control custom-form ${errorMessages.noOfEmployees ? 'is-invalid' : ''}`} 
                                        value={organizationFormData.noOfEmployees} 
                                        onChange={handleInputChange}
                                    >
                                        {employeeOptions.map((option, index) => (
                                            <option key={index} value={option}>{option}</option>
                                        ))}
                                    </select>
                                    {errorMessages.noOfEmployees && <div className="invalid-feedback">{errorMessages.noOfEmployees}</div>}
                                </div>
                                {/* <div className="col-md-3 mb-4">
                                <label class="form-label font-14 font-medium" for="region">Organization Logo<span class="required">*</span></label>
                                <label
                  class="file-upload-outline position-absolute rounded-3 d-flex align-items-center gap-2 font-14 font-medium">
                  Upload Logo
                </label>
                                    <input type="file" id="logo" name="logo" accept=".png,.jpg,.jpeg" onChange={(e) => handleInputChange(e)} />
                                </div>
                            </div> */}

<div className="col-md-3 mb-4">
    <div className="position-relative">
        <label className="form-label font-14 font-medium" htmlFor="logo">
            Organization Logo<span className="required">*</span>
        </label>
        
        <label className="file-upload-outline position-absolute rounded-3 d-flex align-items-center gap-2 font-14 font-medium">
            Upload Logo
        </label>
        <input 
            type="file" 
            className="form-control custom-file" 
            id="logo" 
            aria-label="upload" 
            accept=".png,.jpg,.jpeg" 
            onChange={handleLogoChange}
        />
        
        {/* Display uploaded logo if available */}
        {logoPreviewUrl && (
            <div className="mt-2">
                <img 
                    src={logoPreviewUrl} 
                    alt="Uploaded Logo" 
                    className="uploaded-partner-logo me-3"
                    style={{ width: '150px', height: 'auto' }} // Adjust width as needed
                /> 
                <img 
                    src="images/cancel-upload-icon.svg" 
                    alt="Remove Logo" 
                    className="ms-2 cursor-pointer"
                    onClick={handleRemoveLogo}
                />
            </div>
        )}
    </div>

    {/* Display error message for Logo */}
    {errorMessages.logo && (
        <div className="invalid-feedback d-block">{errorMessages.logo}</div> // Ensure d-block is used to make it visible
    )}
</div>  
      </div>

                            {/* Tags Section */}
                            <h3 className="font-18 font-medium mt-3 mb-4 black-v2">Tags</h3>
<div className="row">
    <div className="col-md-12 mb-4">
        <label className="form-label font-14 font-medium" htmlFor="define-tags">Define Tags</label>
        <button 
            type="button" 
            className="btn p-0 ms-1" 
            data-bs-toggle="tooltip" 
            data-bs-placement="right" 
            data-bs-title="Define Tagging policy for the organization, so the resources created are consistent"
        >
            <img 
                src="images/tooltip-icon.svg" 
                alt="tooltip-icon" 
                className="toolttip-icon" 
            />
        </button>
        
        <Select
            isMulti
            className='custom-select-form cust-select-width padding-left'
            onChange={handleChange}
            styles={customStyles}
            value={selectedOptions} // Set selected options here
            placeholder="Type and press Enter to add"
            noOptionsMessage={() => null}
            onInputChange={(input) => setInputValue(input)} // Update input state
            inputValue={inputValue} // Control the input value for react-select
            onKeyDown={handleKeyDown} // Handle key down events for adding tags
            components={customComponents}
        />
        
        {/* Display error message for Tags */}
        {errorMessages.tags && (
            <div className='invalid-feedback d-block'>{errorMessages.tags}</div> // Ensure d-block is used to make it visible
        )}
    </div>
</div>


                            {/* Alert Details Section */}
                            <h3 className='font-18 font-medium mt-3 mb-4 black-v2'>Alert Details</h3>
                            <div className='row'>
                                {/* Alert Email ID */}
                                <div className='col-md-6 mb-4'>
                                    <label htmlFor='mail' className='form-label'>Alert Email ID</label>
                                    <input type='email' id='mail' name='mail' placeholder='Enter Alert Email ID'
                                           value={organizationFormData.mail} onChange={handleInputChange}
                                           className={`form-control ${errorMessages.mail ? 'is-invalid' : ''} custom-form`} />
                                    {errorMessages.mail && <div className='invalid-feedback'>{errorMessages.mail}</div>}
                                </div>

                                {/* Teams Integration */}
                                <div className='col-md-6 mb-4'>
                                    <label htmlFor='teamsUrl' className='form-label'>Teams Integration</label>
                                    <input type='text' id='teamsUrl' name='teamsUrl' placeholder='Enter team webhook URL'
                                           value={organizationFormData.teamsUrl} onChange={handleInputChange}
                                           className={`form-control ${errorMessages.teamsUrl ? 'is-invalid' : ''} custom-form`} />
                                    {errorMessages.teamsUrl && <div className='invalid-feedback'>{errorMessages.teamsUrl}</div>}
                                </div>
                            </div>

                            {/* Footer Buttons */}
                            <div className='modal-footer border-0 justify-content-end py-4 gap-1'>
                                <button type='button' className='button cancel-button' onClick={() => navigate('/manage-organizations')}>Cancel</button>
                                <button type='submit' className='button primary-button'  disabled={!isFormValid()}  onClick={handleSubmit}>Save</button>
                            </div>

                        </div>
                    </div>
                </div>
            </form>

            {/* Page Content End here */}
        </div>
    );
};

export default AddOrganization;