//Importing the packages
import React, { useState, useEffect, useContext } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";
import { getCostByRegion } from "../../Service/api"; // Import the API function
import { OrgContext } from "../../ContextProvider/OrgContext";
import moment from "moment";
import { DashboardContext } from "./Context/DashboardContext";
//PS_01 Defining the colors
const COLORS = ["#f48fb1", "#64b5f6", "#81c784", "#ffb74d", "#ba68c8"]; // Add more colors if needed
//PS_02 Define a function for hovering scenario
const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "white",
          padding: "5px",
          border: "1px solid #cccccc",
        }}
      >
        <p style={{ margin: 0 }}>{`${
          payload[0].name
        }: $${payload[0].value.toFixed(2)}`}</p>
      </div>
    );
  }
  return null;
};
//Declare a compoenent
const CostByRegion = ({
  selectedProviderId,
  selectedConnectors,
  fromDate,
  toDate,
}) => {
  //PS_03 Initialse a state variable to store the fetched data
  const [data, setData] = useState([]);
  //PS_04 Get the userId from local storage
  const userId = localStorage.getItem("userId");
  //PS_05 Get the organization id from context
  const { orgId } = useContext(OrgContext);
  //PS_06 Get the regions variable from context
  const { regions, setRegions } = useContext(DashboardContext);
  //PS_19 Define the style for no data found
  const noDataStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    margin: 0,
    fontSize: '14px',
    color: '#333',
  };
  //PS_07 to PS_16 Use effect to trigger
  useEffect(() => {
    //Declare a function named fetchCostByRegion
    const fetchCostByRegion = async () => {
      try {
        // Parse and format dates
        const formattedFromDate = fromDate
          ? moment(fromDate, "DD-MM-YYYY").format("YYYY-MM-DD")
          : "";
        const formattedToDate = toDate
          ? moment(toDate, "DD-MM-YYYY").format("YYYY-MM-DD")
          : "";
        //Set the payload
        const payload = {
          userId,
          orgId,
          filterCloudProvider: selectedProviderId || "",
          filterConnector: selectedConnectors
            .map((c) => c.connectorId)
            .join(","),
          filterStartDate: formattedFromDate,
          filterEndDate: formattedToDate,
        };
        //Calling the api
        const response = await getCostByRegion(payload);
        //Mapping the response
        if (response.data && response.data.body && response.data.body.success) {
          const transformedData = response.data.body.data.map((item) => ({
            ...item,
            cost: item.cost ? parseFloat(item.cost) : null,
          }));

          //Mapping the transformed data
          let modifiedData = transformedData?.map((value, index) => {
            return {
              Regions: value?.region ? value?.region : "Global",
              Actual_Cost: value?.costForAzure
                ? value?.costForAzure
                : value?.costForAws
                ? value?.costForAws
                : 0,
            };
          });
          //Update the state variables
          setRegions(modifiedData);
          setData(transformedData);
        } else {
          console.log(
            "Failed to fetch cost by region data:",
            response.data?.body?.message || "Unknown error"
          );
        }
        //Catch errors if any
      } catch (error) {
        console.log("Error fetching cost by region:", error);
      }
    };
    //Call the function
    fetchCostByRegion();
  }, [selectedProviderId, selectedConnectors, fromDate, toDate]);
  //PS_17 Map the data for graph
  const pieData = data.map((entry) => ({
    name: entry.region,
    value: entry.costForAws + entry.costForAzure,
  }));
  //PS_18 Calculate the total value
  const totalCost = pieData.reduce((acc, current) => acc + current.value, 0);
//If length is zero show no data found
  if (data.length === 0) {
    return (
      <div style={noDataStyle}>
        <p>No data found</p>
      </div>
    );
  }


  return (
    <div className="tags-align">
    <div   style={{ display: "flex" }}>
        <>
          <ResponsiveContainer width="60%" height={200}>
            <PieChart>
              <Pie
                data={pieData}
                cx={120}
                cy={100}
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="value"
              >
                {pieData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <text
                x="50%"
                y="50%"
                textAnchor="middle"
                dominantBaseline="middle"
              >
                <tspan x="45%" dy="-0.5em" fontSize="14" fill="#333">
                  Total Cost
                </tspan>
                <tspan
                  x="45%"
                  dy="1.5em"
                  fontSize="18"
                  fontWeight="bold"
                  fill="#333"
                >
                  ${totalCost.toFixed(2)}
                </tspan>
              </text>
              <Tooltip content={CustomTooltip} />
            </PieChart>
          </ResponsiveContainer>

          <div className="legend-container ms-4">
            {data.map((entry, index) => (
              <div
                key={`${entry.region}-${index}`}
                className="d-flex justify-content-between mb-4"
              >
                <p className="font-12 font-medium secondary-color mb-0">
                  <span
                    className={`legend legend-${COLORS[index % COLORS.length]}`}
                    style={{
                      backgroundColor: COLORS[index % COLORS.length],
                      width: "10px",
                      height: "10px",
                      display: "inline-block",
                      marginRight: "5px",
                    }}
                  />
                  {entry.region}
                </p>
                <p className="font-12 font-medium black-v2 mb-0 ms-2 text-nowrap">
                  ${(entry.costForAws + entry.costForAzure).toFixed(2)}
                </p>
              </div>
            ))}
          </div>
        </>
        </div>
    </div>
  );
};

export default CostByRegion;
