export default function ToastMessage({setIsToastPopUpOpen , istoastMessage}) {
	
    return(
		<>
			<div className="custom-toast-container">
				<div className="d-flex align-items-center gap-3 mb-0">
					<img src="images/tick-green-small.svg" alt="tick-green" className="width-25" />
					<p className="font-14 font-regular text-white mb-0">{istoastMessage}</p>
				</div>
				<button type="button" className="btn p-0 shadow-none" onClick={() => {setIsToastPopUpOpen(false)}}>
					<img src="images/cross-white.svg" alt="cross-icon" className="w-9" />
				</button>
		</div>
		</>
	)
}
