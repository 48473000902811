
import React, { useEffect, useState } from "react";
import { get_version_historyAPI } from "../../../../../Service/api";
import { useOrgContext } from "../../../../../ContextProvider/OrgContext";

// Version History Pop Up Component
export default function VersionHistoryPopUp({isVersionHistoryModalOpen ,setIsVersionHistoryModalOpen,viewCommitHistory ,restoreCommitHistory}) {
  const { orgId, userId, envId } = useOrgContext();
	console.log("Clickeedd")
  // State to store version history data
  const [versionHistory, setVersionHistory] = useState([]);
  
  useEffect(() => {
    // Fetch version history when component mounts or when props change
    if(isVersionHistoryModalOpen){
    get_version_history();
    }
  }, [isVersionHistoryModalOpen]);

  // Function to fetch version history from API
  async function get_version_history() {
    try {
      let payload = {
        organizationId: orgId, // Add organization ID if required
        userId: userId, // Add user ID if required
        envId: envId // Add environment ID if required
      };
      
      // Call API to get version history
      const response = await get_version_historyAPI(payload);
      console.log(response , "Respone From Version History")
      // Check if the API call was successful
      if (response.success) {
        setVersionHistory(response.data);
      } else {
        console.error("Failed to fetch version history:", response.body.message);
      }
    } catch (error) {
      console.error("Error fetching version history:", error);
    }
  }

  // Function to format date string
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString();
  };

  //  PS_107  Function to handle viewing commit data
  const handleViewCommit = (commitData) => {
    // Implement logic to view commit data
    console.log("Viewing commit data:", commitData);
    viewCommitHistory(commitData)
    setIsVersionHistoryModalOpen(false)

  };
  // const handleRestoreCommit = (commitData) =>{
  //   restoreCommitHistory(commitData)
  //   setIsVersionHistoryModalOpen(false)
  // }

  return (
    <>
    

    <div className="modal fade show" id="versionHistroyPopup" style={{display: 'block', backgroundColor: 'rgba(0,0,0,0.5)'}} 
      data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
      aria-labelledby="staticBackdropLabel" aria-hidden="true">
            
        <div className="modal-dialog modal-xl modal-dialog-centered justify-content-center">
          <div className="modal-content popup-table-width border-0">
            <div className="modal-header border-0 p-4">
              <h1
                className="modal-title font-20 font-bold black-v2"
                id="staticBackdropLabel"
              >
                Version History
              </h1>
              <button
                type="button"
                className="btn shadow-none"
                onClick={()=>{setIsVersionHistoryModalOpen(false)}}
              >
                <img
                  src="images/close-icon.svg"
                  alt="close-icon"
                  className="icon-hover shadow-none modal-close-filter"
                />
              </button>
            </div>
            <div className="modal-body px-4">
              <div className="table-responsive table-border popup-table">
                <table className="table custom-table">
                  <thead>
                    <tr>
                      <th className="text-center border-0">Version</th>
                      <th className="text-start border-0">Modified By</th>
                      <th className="text-start border-0">Modified On</th>
                      <th className="text-center border-0">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {versionHistory.map((entry) => (
                      <tr key={entry.versionNumber}>
                        <td className="text-center">{entry.versionNumber}</td>
                        <td className="text-start">{entry.modifiedBy}</td>
                        <td className="text-start">{formatDate(entry.modifiedOn)}</td>
                        <td className="text-center">
                          <a onClick={() => handleViewCommit(entry.data)}>
                            <img src="images/view-icon.svg" alt="view-icon" />
                          </a>
                          {/* <button onClick={() => handleRestoreCommit(entry.data)}>
                            <img src="images/Restore.svg" alt="view-icon" />
                          </button> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}