//PS_02
import React, { useState, useEffect ,useRef,useContext} from 'react';
import Pagination from './Pagination';
//PS_04
import ReactApexChart from 'react-apexcharts';
import { useTheme } from '@mui/material/styles';
import { getAllEnvThresholdApi,deleteBudgetApi } from '../../Service/api';
import { useNavigate, useLocation } from 'react-router-dom';
import { OrgContext } from '../../ContextProvider/OrgContext';

import DeleteConfirmationModal from '../Organization/Delete_Popup';
//PS_05
import SideBar from '../sidebar/SideBar';


const EnvironmentThresholdPage = ({ isDarkMode, setIsDarkMode }) => {
	//PS_06 state variables
	const [environmentThresholdData, setEnvironmentThresholdData] = useState([]);

	const [sortColumn, setSortColumn] = useState('envName');
	const [sortOrder, setSortOrder] = useState('asc');
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [envToDelete, setenvToDelete] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const navigate = useNavigate();
	const location = useLocation();
	const state = location.state;
	const {
		userRoles,
		orgId,
		providerId,
		architectures,
		canCreateWorkload,
		canDeleteWorkload,
		canViewWorkload,
		setuserRoles,

		// Add organization and provider fields here
		providerName, 
		orgData, 
		setOrgData, 
		setOrgId, 
		setArchitectures,

		envId, // Environment ID
		workloadId, // Workload ID
		aiArchitecture, 
		viewMode, 
		envName, 
		language, 
		archType, 
		business, 
		rulebook, 
		archDesc, 
		services,

		lastBuildID, 
		lastBuildDate, 
		lastBuildMessage,

		repoId, 
		repoName,

		PulseOrganization_id,
		PulseVertical_id,
		PulseVertical_name,
		PulseOrganization_name,
		PulseIdentification,

		setEnvId // Function to update environment ID
	} = useContext(OrgContext);

	const [userId, setuserId] = useState(localStorage.getItem('userId'));


	const itemsPerPage = 5;

	//PS_08 Fetch environment threshold data on component mount
	useEffect(() => {
		
		// Use mock data instead of fetching from an API
		loadEnvThresholds()
		setTextColor(localStorage.getItem('darkTheme') == "true" ?  "#ffffff":"#000000" )

		// setEnvironmentThresholdData(mockEnvironmentData.envi);
		// calculateGraphData(mockEnvironmentData.envi);
	}, []);

	useEffect(() => {
        // Update local storage and body class whenever isDarkMode changes
        localStorage.setItem('darkTheme', isDarkMode ? 'true' : 'false');
        document.body.classList.toggle('dark-mode', isDarkMode);
        setTextColor(isDarkMode ? "#ffffff" : "#000000")
    }, [isDarkMode]);
	const loadEnvThresholds = async () => {
		const payload = {
			userId: userId,
			orgId: orgId,
			workloadId: state?.workloadId,
			pagination: "0"
		};

		try {
			const response = await getAllEnvThresholdApi(payload); // Call the API function
			if (response.success) {
				console.log('Environment thresholds fetched successfully:', response.data);

				// Check if 'envi' exists and is an array before setting state
				const enviData = response.data.envi;
				if (Array.isArray(enviData)) {
					setEnvironmentThresholdData(enviData); // Update state with fetched environment thresholds
					calculateGraphData(enviData); // Pass enviData directly
				} else {
					console.warn('No valid environment thresholds found:', enviData);
					setEnvironmentThresholdData([]); // Set to empty array if no valid data
					calculateGraphData([])
				}
			} else {
				console.error('Error fetching environment thresholds');
			}
		} catch (error) {
			console.error('An error occurred while fetching environment thresholds:', error);
		}
};

	const theme = useTheme();
	const chartRef = useRef(null);
	const [textColor, setTextColor] = useState(theme.palette.text.primary);
	const [chartData, setChartData] = useState({ labels: [], datasets: [] });

	// const mockEnvironmentData = {
	// envi: [
	// { envName: "env-1", cost: 0.0, budget: 0.0 },
	// { envName: "env-2", cost: 0.0, budget: 0.0 },

	// ],	
	// };

	const highCost = Math.max(...environmentThresholdData?.map(item => item.budget));
	const yAxisStep = highCost / 5;
//PS_17
const calculateGraphData = (data) => {
	console.log("don");

	const labels = data?.map(item => item.envName);

	// Map actual costs, replacing 0 or undefined with 0
	const actualCosts = data?.map(item => (item.cost > 0 ? item.cost : 0));

	// Map budgets, replacing 0 or undefined with 0
	const budgets = data?.map(item => (item.budget > 0 ? item.budget : 0));
console.log("don",budgets);
	setChartData({
		labels,
		datasets: [
			{
				name: 'Actual Cost',
				data: actualCosts,
				borderColor: 'green',
				fill: false,
			},
			{
				name: 'Budget',
				data: budgets,
				borderColor: 'red',
				fill: false,
			},
		],
	});
};
//PS_18
	const options = {
		chart: {
			type: 'line',
			zoom: { enabled: false },
			toolbar: { show: false },
			background: 'transparent',
		},
		colors: ['green', 'red'],
		stroke: {
			curve: 'straight', // Set to straight lines
			width: [2, 2],
			color: ['green', 'red'],
			backgroundcolor:['green', 'red'],
			background:['green', 'red'],
			fill:['green', 'red'],
		},
		dataLabels: { enabled: false },
		markers: { size: 0 },
		xaxis: {
			categories: chartData.labels,
			labels: {
				style: { colors: textColor },
				rotate: -45,
				hideOverlappingLabels: true,
			},
		},
		yaxis: {
			min: 0,
			max: highCost,
			tickAmount: 4, // Set to 4 to display 5 ticks including 0
			labels: {
				style: { colors: textColor },
				formatter(value) {
					return `$${value?.toFixed(2)}`;
				}
			}
		},
		grid:{
		// borderColor:textColor === '#ffffff' ? '#888888' : '#f1f1f1',
		borderColor: isDarkMode == false ? 'rgba(0, 0, 0, 0.1)' : '#ffffff', // Set the grid line color

			strokeDashArray:[5,5]
		}, 
		tooltip:{
			shared:true,
			intersect:false,
			y:{
				formatter(value){
					return `$${value?.toFixed(2)}`; // Prepend $ symbol in tooltips
				}
			}
		},
		legend:{
			show:false // Disable the legend
		}
	};


	//PS_19 Sorting function
	const handleSort = (column) => {
		const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
		setSortColumn(column);
		setSortOrder(newSortOrder);

		// Sort the data based on selected column and order
		const sortedData = [...environmentThresholdData].sort((a, b) => {
			if (newSortOrder === 'asc') {
				return a[column] > b[column] ? 1 : -1;
			} else {
				return a[column] < b[column] ? 1 : -1;
			}
		});

		setEnvironmentThresholdData(sortedData);
	};

	// Pagination logic
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = environmentThresholdData?.slice(indexOfFirstItem, indexOfLastItem);

	const [costStatus, setCostStatus] = useState('Select');
	const [cloudProvider, setCloudProvider] = useState('AWS');

	// Sample data for dropdowns (you can replace this with your actual data)
	const costStatusOptions = ['Select', 'Monthly', 'Two', 'Three'];
	const cloudProviders = [
		{ name: 'AWS', icon: 'images/aws-black-icon.svg' },
		{ name: 'Azure', icon: 'images/azure-logo.svg' },
	];

	const handleAddBudget = () => {
		navigate('/CostManagementForm') // Implement your add budget logic here
	};
	const handleEdit = (item) => {
		console.log(item ,"sdfsd")
		console.log(`Edit environment with ID ${item.envId}`);
        if (item?.envId) {
            navigate('/CostManagementForm', { 
                state: { editMode: true, envId: item.envId }
            });
        }
		// Implement your edit logic here 
	};


	const confirmDelete = async (envId) => {
		const payload = {
			userId :userId,
			orgId : orgId,
			envId: envToDelete.envId 
		};
console.log("payy",payload);
		try {
			const response = await deleteBudgetApi(payload); // Call the API function
			if (response.success) {
				loadEnvThresholds()
				console.log('Budget deleted successfully:', response.data); 
			} else {
				console.error('Error deleting budget');
			}
		} catch (error) {
			console.error('Failed to delete budget:', error);
		}
		setShowDeleteModal(false); // Close modal

	};


	return (
		<div class="container-fluid">
		<div class="">

			<div class="row">
				<div class="col-md-12">
					<div class="row">
						<SideBar/>
		<div className="col-md-9 px-4 pt-4 right-container-scroll">
			<div className="row">
			<div className="col-md-12">
			<div className="d-flex justify-content-between align-items-center mb-3">
				<p className="font-32 font-bold black-v2 mb-0">Budget</p>
				<div className="d-flex align-items-center gap-3">


					{/* Dropdown for Cloud Provider */}
					{/* <div className="dropdown">
						<button 
							type="button" 
							className="btn custom-dropdown d-flex justify-content-between align-items-center gap-5 font-14 font-regular color-black shadow-none cust-dropdown-width h-48"
							id="dropdownMenuButton1"
							data-bs-toggle="dropdown"
							aria-expanded="false"
							disabled 
						>
							<span className="d-flex">
								<img src={cloudProviders?.find(provider => provider.name === cloudProvider)?.icon} alt={`${cloudProvider} logo`} className="me-1 icon-width light-theme-img" />
								{cloudProvider}
							</span>
							<img src="images/drop-down-arrow.svg" alt="dropdown-arrow" />
						</button>
						<ul className="dropdown-menu body-bg access-dropdown cust-dropdown-width" aria-labelledby="dropdownMenuButton1">
							{cloudProviders?.map((provider, index) => (
								<li key={index} className="font-regular color-black font-14 p-2 custom-list" onClick={() => setCloudProvider(provider.name)}>
									<span className="d-flex">
										<img src={provider.icon} alt={`${provider.name} logo`} className="me-1 icon-width light-theme-img" />
										{provider.name}
									</span>
								</li>
							))}
						</ul>
					</div> */}

					{/* Add Budget Button */}
					<button type="button"
						class="button primary-button font-14 font-medium d-flex align-items-center justify-content-center h-48" onClick={handleAddBudget}><img
							src="images/plus-icon-white.svg" alt="plus-icon-white"
							class="me-2 plus-icon-width"/>Add Budget</button>
				</div>
			</div>
		</div>
		<div className="col-md-12">
	<div className="count-container-2" style={{ padding: '20px', position: 'relative' }}>
		{/* Back to Workloads Button */}
		<div style={{ position: 'absolute', top: '10px', left: '20px', zIndex: 10 }}>
			<button className="btn button cancel-button py-2 px-4 font-14 font-medium black-v2 lh-1" onClick={()=>{navigate('/thresholdGridPage')}}>
				Back to Workloads
			</button>
		</div>
		<div className="w-100 chart-container mb-4" style={{ height: '250px', overflow: 'hidden', paddingTop: '50px' }}>
	{/* Legend positioned in the top right corner */}
	<div className="d-flex justify-content-end align-items-center gap-4 mt-3" style={{ position: 'absolute', right: '20px', top: '10px' }}>
		<p className="font-12 font-medium secondary-text-color-v4 mb-1">
			<span className="legend legend-success me-1"></span>Actual Cost
		</p>
		<p className="font-12 font-medium secondary-text-color-v4 mb-1">
			<span className="legend legend-red me-1"></span>Budget
		</p>
	</div>

	{chartData?.labels && chartData.labels.length > 0 ? (
		<ReactApexChart
			options={options}
			series={chartData.datasets}
			type="line"
			height="100%" // Set height to 100% of the container
		/>
	) : (
		<div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
			<p className="font-medium font-12 text-center">
				No data available for the graph.
			</p>
		</div>
	)}
</div>
	</div>

	{/* Budget Grid */}
	<div className="table-responsive mt-4 table-border">
		<table className="table custom-table acc-table">
			<thead>
			<tr>
                    <th onClick={() => handleSort('envName')} className="text-nowrap text-start border-0">
                        Environment
                        <span className="cursor-pointer ms-2">
                            <img 
                                src="./images/table-sort.svg" 
                                alt="sort" 
                                style={{
                                    transform: 
                                        sortColumn === "envName" && sortOrder === "asc" 
                                            ? "scaleY(-1)" // Flip icon for ascending order
                                            : "scaleY(1)",  // Normal position for descending order
                                    transition: "transform 0.2s ease-in-out", // Smooth transition
                                    cursor: 'pointer' // Pointer cursor for better UX
                                }} 
                            />
                        </span>
                    </th>
                    <th onClick={() => handleSort('budget')} className="text-nowrap text-end border-0">
                        Budget
                        <span className="cursor-pointer ms-2">
                            <img 
                                src="./images/table-sort.svg" 
                                alt="sort" 
                                style={{
                                    transform: 
                                        sortColumn === "budget" && sortOrder === "asc" 
                                            ? "scaleY(-1)" 
                                            : "scaleY(1)",  
                                    transition: "transform 0.2s ease-in-out",
                                    cursor: 'pointer'
                                }} 
                            />
                        </span>
                    </th>
                    <th onClick={() => handleSort('actualCost')} className="text-nowrap text-end border-0">
                        Actual Cost
                        <span className="cursor-pointer ms-2">
                            <img 
                                src="./images/table-sort.svg" 
                                alt="sort" 
                                style={{
                                    transform: 
                                        sortColumn === "actualCost" && sortOrder === "asc" 
                                            ? "scaleY(-1)" 
                                            : "scaleY(1)",  
                                    transition: "transform 0.2s ease-in-out",
                                    cursor: 'pointer'
                                }} 
                            />
                        </span>
                    </th>
                    <th onClick={() => handleSort('forecastedCost')} className="text-nowrap text-end border-0">
                        Forecasted Cost
                        <span className="cursor-pointer ms-2">
                            <img 
                                src="./images/table-sort.svg" 
                                alt="sort" 
                                style={{
                                    transform: 
                                        sortColumn === "forecastedCost" && sortOrder === "asc" 
                                            ? "scaleY(-1)" 
                                            : "scaleY(1)",  
                                    transition: "transform 0.2s ease-in-out",
                                    cursor: 'pointer'
                                }} 
                            />
                        </span>
                    </th>
                    <th className="text-nowrap text-end border-0">Actions</th> {/* Actions column without sorting */}
                </tr>
			</thead>
			<tbody>
				{currentItems.length > 0 ? (
					currentItems?.map((item) => (
						<tr key={item.envId}>
							<td className="text-nowrap text-start">{item.envName}</td>
							<td className="text-nowrap text-end">${item.budget}</td>
							<td className={`text-nowrap text-end ${item.actualCost > item.budget ? 'text-danger' : 'text-success'}`}>${item.cost}</td>
							<td className="text-nowrap text-end">${item.forecasted}</td>
							<td className="text-nowrap text-end">
								{/* Actions */}
								<div className="d-flex justify-content-center gap-3">
									{/* Edit Action */}
									{state.architectureName !== "DataOps" && (
  <a href="#" onClick={() => handleEdit(item)}>
    <img src="/images/edit-icon.svg" alt="edit-icon" />
  </a>
)}	{/* Delete Action */}
									<a  onClick={()=>{setenvToDelete(item); // Set the rule to delete
										setShowDeleteModal(true);}}>
											<img src="/images/delete-icon.svg" alt="delete-icon" />
										</a>


								</div>
							</td>
						</tr>
					))
				) : (
					<tr>
					<td colSpan={5} className="text-center"> {/* Adjust colSpan based on the number of columns */}
						<div className="d-flex flex-column justify-content-center align-items-center" style={{ height: '160px' }}>
							<img src="images/nodata-found.svg" alt="No Data Found" />
							<h6 className="color-black font-bold font-16 mt-5 mb-0">No Data Found!</h6>

						</div>
					</td>
				</tr>
				)}
			</tbody>
		</table>
	</div>
	{showDeleteModal && (
		<DeleteConfirmationModal 
			item={envToDelete} 
			itemType={"budget"} 
			onDeleteConfirm={confirmDelete} 
			onClose={() =>{setShowDeleteModal(false)}} 
		/>
		)}
	{/* Pagination Controls */}

</div>
	</div>
	</div>
	</div></div></div></div></div>
	);
};

export default EnvironmentThresholdPage; 
