import { useContext, useEffect } from "react"
import { AddServiceContext } from "./context/AddServiceContext"

export default function AddNewServiceFields(){


    const { 
        categories,
        cloudProviders,
        selfServiceData,
        setSelfServiceData,
        providerName,
        setProviderName,
        
    } = useContext(AddServiceContext)


    useEffect (()=>{
      if (selfServiceData?.cloudproviderId){
        let providerId = selfServiceData?.cloudproviderId; 
        let providerNameCopy = providerName
        for (let values of cloudProviders){
          if (values?.providerId == providerId ){
            providerNameCopy = values?.providerName
          }
        }
        setProviderName(providerNameCopy)
      }
    },[selfServiceData?.cloudproviderId])


    const handleChange = (e) => {
      const { name, value, type, checked, files } = e.target;
    
      if (type === "file" && files[0]) {
        const file = files[0];
        const reader = new FileReader();
    
        reader.onloadend = () => {
          setSelfServiceData((prevData) => ({
            ...prevData,
            uploadIconBase64: reader.result,       // Set base64 string of file
            filetype: file.type.split("/")[1],     // Set file extension (e.g., "png")
            filename: file.name                    // Set file name
          }));
        };
    
        reader.readAsDataURL(file); // Trigger file reading as base64
      } else {
        setSelfServiceData((prevData) => ({
          ...prevData,
          [name]: type === "checkbox" ? checked : value,
        }));
      }
    };

    


    return(
        <>
        <div className="row">
      <div className="col-md-3 mb-4">
        <label htmlFor="cloudproviderId" className="form-label font-14 font-medium">
          Cloud Provider<span className="required">*</span>
        </label>
        <select
          name="cloudproviderId"
          id="cloud-provider"
          className="form-select form-control custom-form"
          value={selfServiceData.cloudproviderId}
          onChange={handleChange}
        >
          <option value="">Select</option>
          {cloudProviders?.map((value) => (
            <option key={value.providerId} value={value.providerId}>
              {value.providerName}
            </option>
          ))}
        </select>
      </div>

      <div className="col-md-3 mb-4">
        <label htmlFor="categoryId" className="form-label font-14 font-medium">
          Category<span className="required">*</span>
        </label>
        <select
          name="categoryId"
          id="category"
          className="form-select form-control custom-form"
          value={selfServiceData.categoryId}
          onChange={handleChange}
        >
          <option value="">Select</option>
          {
            categories?.map((value,index) => {
              return (
                 <option value={value?.serviceCategoryId}>{value?.serviceCategoryName}</option>
              )
            })
          }
        </select>
      </div>

      <div className="col-md-3 mb-4">
        <label htmlFor="serviceName" className="form-label font-14 font-medium">
          Service<span className="required">*</span>
        </label>
        <input
          type="text"
          name="serviceName"
          id="service-name"
          className="form-control custom-form"
          placeholder="Enter Service Name"
          value={selfServiceData.serviceName}
          onChange={handleChange}
        />
      </div>

      <div className="col-md-3 mb-4">
                  <div className="position-relative">
                    <label
                      className="form-label font-14 font-medium"
                      htmlFor="region"
                    >
                      Upload Service Icon<span className="required">*</span>
                    </label>
                    <label className="file-upload-outline position-absolute rounded-3 d-flex align-items-center gap-2 font-14 font-medium">
                      Upload Icon
                    </label>
                    <button
                      type="button"
                      className="btn p-0 ms-1"
                      data-bs-toggle="tooltip"
                      data-bs-placement="right"
                      data-bs-title="Kindly add updated icons with proper formar - sample text need to add by dev team"
                    >
                    </button>
                    <input
                      type="file"
                      className="form-control custom-file"
                      id="orgLogo"
                      aria-label="upload"
                      onChange={handleChange}
                      accept=".jpg, .jpeg, .png"
                    />
                  </div>
                  {
                   selfServiceData?.uploadIconBase64 && 
                  <img src={selfServiceData?.uploadIconBase64} alt="" width={"50px"} height={"50px"} style={{"position":"absolute", "top":"199px", "right":"175px"}}/> } {
                    selfServiceData?.uploadIconBase64 &&   
                  <img
                  src="images/bin-icon-red.svg"
                  alt="logo"
                  onClick={()=>{
                    setSelfServiceData((prevData) => ({
                      ...prevData,
                      uploadIconBase64: "",       // Set base64 string of file
                      filetype: "",     // Set file extension (e.g., "png")
                      filename:""                    // Set file name
                    }));
                  }}
                  className="edit-icon cursor-pointer"
                  style={{"position":"absolute", "top":"199px", "right":"155px"}}
                />

                  
                  }
                
                </div>
              
              
            
                

      <div className="col-md-3 mb-4">
        <div className="d-flex align-items-center">
          <input
            type="checkbox"
            name="containsService"
            id="service-checkbox"
            className="form-check-input custom-checkbox shadow-none mt-0"
            checked={selfServiceData.containsService}
            onChange={handleChange}
          />
          <label
            htmlFor="service-checkbox"
            className="font-14 font-medium ms-2 black-v2 service-checkbox"
          >
            Contains Services?
          </label>
        </div>
      </div>
    </div>
 
              <div className="row">
                <div className="col-md-9 mt-2 mb-4">
                  <label
                    className="form-label font-14 font-medium black-v2"
                    htmlFor="description"
                  >
                    Description<span className="required">*</span>
                  </label>
                  <textarea
                    className="form-control custom-form custom-textarea font-14 font-regular"
                    placeholder="Enter Description"
                    name="description"
                    id="description"
                    defaultValue={""}
                    value={selfServiceData.description}
                    onChange={handleChange}
                  />
                </div>
              </div>
        </>
    )
}