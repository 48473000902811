import React, { useEffect } from 'react';

export default function B2APopup({
	popupMsg,
	setPopUpMsg
}) {

	useEffect(() => {
		// If popup message is shown, set a timeout to hide it after 3 seconds
		if (popupMsg.show) {
			const timer = setTimeout(() => {
				setPopUpMsg({...popupMsg, show: false, Msg: "", success: false });
			}, 3000);

			// Clear timeout if the component is unmounted or if popupMsg changes
			return () => clearTimeout(timer);
		}
	}, [popupMsg, setPopUpMsg]);
	return (
		<div className="container-fluid"

			style={{position:"relative", top : "10%" , left : "25%"}}
			>
					<div className="custom-toast-container d-flex align-items-center justify-content-between">
						<div className="d-flex align-items-center gap-3 mb-0">
							<img
								src={popupMsg.success === false ? "images/error-icon-red.svg" : "images/tick-green-small.svg"}
								alt="status-icon"
								className="width-25"
							/>
							<p className="font-14 font-regular text-white mb-0">
								{popupMsg.Msg}
							</p>
						</div>
						<button type="button" className="btn p-0 shadow-none"
							onClick={() => {
								setPopUpMsg({ ...popupMsg, Msg: "", show: false, success: false });
							}}
						>
							<img src="images/cross-white.svg" alt="cross-icon" className="w-9" />
						</button>
					</div>
		</div>
	);
}
