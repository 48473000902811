

export default function AwsSDKKMS({ values, onChangeOfConfiguration, isViewer }) {
    try {

        return (

            <>
                <div class="accordion-body p-0 estimation-container">
                    <div className="row py-4 mx-4 px-5" hidden={(values.isPriceAvailable == "No") ? false : true}>
                        <div className="col-md-6 px-3"><span className="font-semibold font-14 text-danger">{values.isPriceAvailable == "No" ? "The Price is not available for the region / the configuration that you have selected might be not available in the region" : null}</span></div>
                    </div>
                    <div className="row py-4 mx-4 px-5">
                        <div className="col-md-6 px-3">
                            <label htmlFor="region1"
                                style={{ paddingTop: "20px" }}
                                className="form-label font-14 font-medium black-v2 mb-3">
                                Region
                            </label>
                            <select
                                className="form-select custom-form font-semibold font-14 form-select-sm"
                                id="region"
                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                value={values.region}
                                name={"region"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            >
                                <option value="ap-south-2">ap-south-2</option><option value="ap-southeast-3">ap-southeast-3</option><option value="ap-southeast-1">ap-southeast-1</option><option value="us-gov-east-1">us-gov-east-1</option><option value="eu-west-2">eu-west-2</option><option value="af-south-1">af-south-1</option><option value="ap-northeast-1">ap-northeast-1</option><option value="ap-southeast-2">ap-southeast-2</option><option value="us-east-2">us-east-2</option><option value="eu-central-1">eu-central-1</option><option value="ca-central-1">ca-central-1</option><option value="ap-south-1">ap-south-1</option><option value="ap-northeast-3">ap-northeast-3</option><option value="sa-east-1">sa-east-1</option><option value="ap-east-1">ap-east-1</option><option value="eu-west-1">eu-west-1</option><option value="eu-south-1">eu-south-1</option><option value="ap-southeast-4">ap-southeast-4</option><option value="eu-north-1">eu-north-1</option><option value="eu-south-2">eu-south-2</option><option value="us-west-1">us-west-1</option><option value="us-west-2">us-west-2</option><option value="me-south-1">me-south-1</option><option value="eu-central-2">eu-central-2</option><option value="us-gov-west-1">us-gov-west-1</option><option value="ap-northeast-2">ap-northeast-2</option><option value="me-central-1">me-central-1</option><option value="us-east-1">us-east-1</option><option value="eu-west-3">eu-west-3</option>
                            </select>
                        </div>
                        <div className="col-md-6 px-3">
                            <label htmlFor="cmk" className="form-label font-14 font-medium black-v2 mb-3">
                                Number of customer managed Customer Master Keys (CMK)
                            </label>
                            <input
                                type="text"
                                style={{ paddingTop: "9px" }}
                                className="form-control custom-form text-field font-14 font-medium mb-3"
                                id="cmk"
                                value={values.cmk}
                                name={"cmk"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}

                            />
                        </div>


                        <div className="col-md-6 px-3">
                            <label 
                            style={{paddingTop:"20px"}}
                            htmlFor="symmetricRequests" className="form-label font-14 font-medium black-v2 mb-3">
                                Number of symmetric requests
                            </label>
                            <input
                                type="text"
                                style={{ paddingTop: "9px" }}
                                className="form-control custom-form text-field font-14 font-medium mb-3"
                                id="symmetricRequests"
                                value={values.symmetricRequests}
                                name={"symmetricRequests"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}

                            />
                        </div>

                        <div className="col-md-6 px-3">
                            <label htmlFor="asymmetricRequestsExcepted" className="form-label font-14 font-medium black-v2 mb-3">
                                Number of asymmetric requests except RSA 2048
                            </label>
                            <input
                                type="text"
                                style={{ paddingTop: "9px" }}
                                className="form-control custom-form text-field font-14 font-medium mb-3"
                                id="asymmetricRequestsExcepted"
                                value={values.asymmetricRequestsExcepted}
                                name={"asymmetricRequestsExcepted"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}

                            />
                        </div>

                        <div className="col-md-6 px-3">
                            <label htmlFor="asymmetricRequestsInvolved" className="form-label font-14 font-medium black-v2 mb-3">
                                Number of asymmetric requests involving RSA 2048
                            </label>
                            <input
                                type="text"
                                style={{ paddingTop: "9px" }}
                                className="form-control custom-form text-field font-14 font-medium mb-3"
                                id="asymmetricRequestsInvolved"
                                value={values.asymmetricRequestsInvolved}
                                name={"asymmetricRequestsInvolved"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}

                            />
                        </div>

                        <div className="col-md-6 px-3">
                            <label htmlFor="ECCGenerateDataKeyPair" className="form-label font-14 font-medium black-v2 mb-3">
                                Number of ECC GenerateDataKeyPair requests
                            </label>
                            <input
                                type="text"
                                style={{ paddingTop: "9px" }}
                                className="form-control custom-form text-field font-14 font-medium mb-3"
                                id="ECCGenerateDataKeyPair"
                                value={values.ECCGenerateDataKeyPair}
                                name={"ECCGenerateDataKeyPair"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    } catch (error) {
        //console.log(error);
    }
}