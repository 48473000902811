export default function AwsSDKAwsApiGateway({ values, onChangeOfConfiguration, isViewer }) {
    try {
        return (
                <div
                    class="accordion-body p-0 estimation-container">
                    <div className="row py-4 mx-4 px-5">
                        <div className="col-md-6 px-3">
                            <label htmlFor="region" className="form-label font-14 font-medium black-v2 mb-3">
                                Region
                            </label>
                            <select
                                className="form-select custom-form font-semibold font-14 form-select-sm mb-3"
                                id="region"
                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                value={values.region}
                                name={"region"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            >
                                <option value="ap-south-2">ap-south-2</option><option value="ap-southeast-3">ap-southeast-3</option><option value="ap-southeast-1">ap-southeast-1</option><option value="us-gov-east-1">us-gov-east-1</option><option value="eu-west-2">eu-west-2</option><option value="af-south-1">af-south-1</option><option value="ap-northeast-1">ap-northeast-1</option><option value="ap-southeast-2">ap-southeast-2</option><option value="us-east-2">us-east-2</option><option value="eu-central-1">eu-central-1</option><option value="ca-central-1">ca-central-1</option><option value="ap-south-1">ap-south-1</option><option value="ap-northeast-3">ap-northeast-3</option><option value="sa-east-1">sa-east-1</option><option value="ap-east-1">ap-east-1</option><option value="eu-west-1">eu-west-1</option><option value="eu-south-1">eu-south-1</option><option value="ap-southeast-4">ap-southeast-4</option><option value="eu-north-1">eu-north-1</option><option value="eu-south-2">eu-south-2</option><option value="us-west-1">us-west-1</option><option value="us-west-2">us-west-2</option><option value="me-south-1">me-south-1</option><option value="eu-central-2">eu-central-2</option><option value="us-gov-west-1">us-gov-west-1</option><option value="ap-northeast-2">ap-northeast-2</option><option value="me-central-1">me-central-1</option><option value="us-east-1">us-east-1</option><option value="eu-west-3">eu-west-3</option>
                            </select>
                        </div>
                        <div className="col-md-6 px-3">
                            <label htmlFor="APIType" className="form-label font-14 font-medium black-v2 mb-3">
                                Select API Type
                            </label>
                            <select
                                className="form-select custom-form font-semibold font-14 form-select-sm"
                                id="APIType"
                                value={values.APIType}
                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                name={"APIType"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            >
                                <option value="API Gateway HTTP">API Gateway HTTP</option>
                                <option value="API Gateway REST">API Gateway REST</option>
                            </select>
                        </div>


                        {values.APIType === "API Gateway HTTP" && <>
                            <div>
                                <label htmlFor="APIRequestsUnit" className="form-label font-14 font-medium black-v2 mb-3" >
                                    HTTP API requests units
                                </label>
                                <select
                                    className="form-select custom-form font-semibold font-14 form-select-sm"
                                    id="APIRequestsUnit"
                                    value={values.APIRequestsUnit}
                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                    name={"APIRequestsUnit"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}>

                                    <option value="exact number">exact number</option>
                                    <option value="thousands">thousands</option>
                                    <option value="millions">millions</option>
                                    <option value="billions">billions</option>
                                </select>
                            </div>
                            <div className="col-md-6 px-3" style={{ marginTop: "20px" }}>
                                <label htmlFor="Requests" className="form-label font-14 font-medium black-v2 mb-3">
                                    Requests
                                </label>
                                <input
                                    type="text"
                                    style={{paddingBottom: '10px' }}
                                    placeholder="Enter number of HTTP API requests in units selected"
                                    className="form-control custom-form text-field font-14 font-medium mb-3"
                                    id="Requests"
                                    value={values.Requests}
                                    name={"Requests"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                />
                            </div>


                            <div className="col-md-6 px-3" style={{ marginTop: "20px" }}>
                                <label htmlFor="UnitByTime" className="form-label font-14 font-medium black-v2 mb-3">
                                    Unit
                                </label>
                                <select
                                    className="form-select custom-form font-semibold font-14 form-select-sm"
                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                    id="UnitByTime"
                                    value={values.UnitByTime}
                                    name={"UnitByTime"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                >
                                    <option value="per month">per month</option>
                                    <option value="per day">per day</option>
                                    <option value="per hour">per hour</option>
                                    <option value="per second">per second</option>
                                    <option value="per minute">per minute</option>
                                </select>
                            </div>
                            <div className="col-md-6 px-3">
                                <label htmlFor="AverageSizeRequest" className="form-label font-14 font-medium black-v2 mb-3">
                                    Average size of each request
                                </label>
                                <input
                                    type="text"
                                    style={{paddingBottom: '10px' }}
                                    placeholder="Enter amount"
                                    className="form-control custom-form text-field font-14 font-medium"
                                    id="AverageSizeRequest"
                                    value={values.AverageSizeRequest}
                                    name={"AverageSizeRequest"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                />
                            </div>
                            <div className="col-md-6 px-3">
                                <label htmlFor="UnitBySize" className="form-label font-14 font-medium black-v2 mb-3">
                                    Unit
                                </label>
                                <select
                                    className="form-select custom-form font-semibold font-14 form-select-sm"
                                    id="UnitBySize"
                                    value={values.UnitBySize}
                                    name={"UnitBySize"}
                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                >
                                    <option value="Bytes">Bytes</option>
                                    <option value="KB">KB</option>
                                    <option value="MB">MB</option>
                                </select>
                            </div></>}


                        {values.APIType === "API Gateway REST" && <>
                            <div>
                                <label htmlFor="APIRequestsUnit1" className="form-label font-14 font-medium black-v2 mb-3">
                                    REST API request units
                                </label>
                                <select
                                    className="form-select custom-form font-semibold font-14 form-select-sm"
                                    id="APIRequestsUnit1"
                                    value={values.APIRequestsUnit1}
                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                    name={"APIRequestsUnit1"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                >
                                    <option value="exact number">exact number</option>
                                    <option value="thousands">thousands</option>
                                    <option value="millions">millions</option>
                                    <option value="billions">billions</option>
                                </select>
                            </div>


                            <div className="col-md-6 px-3">
                                <label htmlFor="Requests1" className="form-label font-14 font-medium black-v2 mb-3" style={{ marginTop: "20px" }}>
                                    Requests
                                </label>
                                <input
                                    type="text"
                                    style={{paddingBottom: '10px' }}
                                    placeholder="Enter number of HTTP API requests in units selected"
                                    className="form-control custom-form text-field font-14 font-medium mb-3"
                                    id="Requests1"
                                    value={values.Requests1}
                                    name={"Requests1"}

                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                />
                            </div>
                            <div className="col-md-6 px-3">
                                <label htmlFor="UnitByTime1" className="form-label font-14 font-medium black-v2 mb-3" style={{ marginTop: "20px" }}>
                                    Unit
                                </label>
                                <select
                                    className="form-select custom-form font-semibold font-14 form-select-sm"
                                    id="UnitByTime1"
                                    value={values.UnitByTime1}
                                    name={"UnitByTime1"}
                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                >
                                    <option value="per month">per month</option>
                                    <option value="per day">per day</option>
                                    <option value="per hour">per hour</option>
                                    <option value="per second">per second</option>
                                    <option value="per minute">per minute</option>
                                </select>
                            </div>
                            <div className="col-md-6 px-3">
                                <label htmlFor="Cachememorysize" className="form-label font-14 font-medium black-v2 mb-3">
                                    Cache memory size (GB)
                                </label>
                                <select
                                    className="form-select custom-form font-semibold font-14 form-select-sm"
                                    id="Cachememorysize"
                                    value={values.Cachememorysize}
                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                    name={"Cachememorysize"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}>
                                    <option value="None">None</option>
                                    <option value="0.5">0.5</option>
                                    <option value="1.6">1.6</option>
                                    <option value="6.1">6.1</option>
                                    <option value="13.5">13.5</option>
                                    <option value="28.4">28.5</option>
                                    <option value="58.2">58.2</option>
                                    <option value="118">118</option>
                                    <option value="237">237</option>
                                </select>
                            </div>
                        </>}
                    </div>
                </div>
        );
    } catch (error) {
        //console.log(error);
    }
}