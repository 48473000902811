export default function AWS_SDK_CloudFront({ values, onChangeOfConfiguration, isViewer }) {
    try {
        return (
                <div
                    class="accordion-body p-0 estimation-container">
                    <div className="row py-4 mx-4 px-5">
                        <div className="col-md-6 px-3 ">
                            <label htmlFor="region" className="form-label font-14 font-medium black-v2 mb-3">
                                Region
                            </label>
                            <select
                                className="form-select font-semibold font-14 form-select-sm custom-form custom-form"
                                id="region"
                                style={{paddingTop :"10px"}}                                
                                value={values.region}
                                name={"region"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            >
                                <option value="ap-south-2">ap-south-2</option><option value="ap-southeast-3">ap-southeast-3</option><option value="ap-southeast-1">ap-southeast-1</option><option value="us-gov-east-1">us-gov-east-1</option><option value="eu-west-2">eu-west-2</option><option value="af-south-1">af-south-1</option><option value="ap-northeast-1">ap-northeast-1</option><option value="ap-southeast-2">ap-southeast-2</option><option value="us-east-2">us-east-2</option><option value="eu-central-1">eu-central-1</option><option value="ca-central-1">ca-central-1</option><option value="ap-south-1">ap-south-1</option><option value="ap-northeast-3">ap-northeast-3</option><option value="sa-east-1">sa-east-1</option><option value="ap-east-1">ap-east-1</option><option value="eu-west-1">eu-west-1</option><option value="eu-south-1">eu-south-1</option><option value="ap-southeast-4">ap-southeast-4</option><option value="eu-north-1">eu-north-1</option><option value="eu-south-2">eu-south-2</option><option value="us-west-1">us-west-1</option><option value="us-west-2">us-west-2</option><option value="me-south-1">me-south-1</option><option value="eu-central-2">eu-central-2</option><option value="us-gov-west-1">us-gov-west-1</option><option value="ap-northeast-2">ap-northeast-2</option><option value="me-central-1">me-central-1</option><option value="us-east-1">us-east-1</option><option value="eu-west-3">eu-west-3</option>
                            </select>
                        </div>
                        <div className="col-md-6 px-3">
                            <label htmlFor="fromLocation" className="form-label font-14 font-medium black-v2 mb-3">
                                Country
                            </label>
                            <select
                                style={{paddingTop :"10px"}}
                                className="form-select font-semibold font-14 form-select-sm custom-form mb-3 custom-form"
                                id="fromLocation"
                                value={values.fromLocation}
                                name={"fromLocation"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            >
                                <option value="Canada">Canada</option>
                                <option value="United States">United States</option>
                                <option value="Asia Pacific">Asia Pacific</option>
                                <option value="Australia">Australia</option>
                                <option value="Europe">Europe</option>
                                <option value="India">India</option>
                                <option value="Japan">Japan</option>
                                <option value="Middle East">Middle East</option>
                                <option value="South Africa">South Africa</option>
                                <option value="South America">South America</option>
                            </select>
                        </div>
                        <div className="col-md-6 px-3">
                            <label
                                htmlFor="dataTransferOut"
                                className="form-label font-14 font-medium black-v2 mb-3"
                            >
                                Data transfer out to internet
                            </label>
                            <input
                                type="text"
                                style={{paddingTop :"10px"}}
                                className="form-control custom-form text-field font-14 font-medium custom-form"
                                id="dataTransferOut"
                                value={values.dataTransferOut}
                                name={"dataTransferOut"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            />
                        </div>
                        <div className="col-md-6 px-3">
                            <label htmlFor="dataTransferOutUnit" className="form-label font-14 font-medium black-v2 mb-3">
                                Unit
                            </label>
                            <select
                                style={{paddingTop :"10px"}}
                                className="form-select font-semibold font-14 form-select-sm custom-form mb-3 custom-form"
                                id="dataTransferOutUnit"
                                value={values.dataTransferOutUnit}
                                name={"dataTransferOutUnit"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            >
                                <option value="GB">GB per Month</option>
                                <option value="TB">TB per Month</option>
                                <option value="PB">PB per Month</option>
                            </select>
                        </div>
                        <div className="col-md-6 px-3">
                            <label
                                htmlFor="dataTransferOutOrigin"
                                className="form-label font-14 font-medium black-v2 mb-3"
                            >
                                Data transfer out to origin
                            </label>
                            <input
                                type="text"
                                style={{paddingTop :"10px"}}
                                className="form-control custom-form text-field font-14 font-medium mb-3 custom-form"
                                id="dataTransferOutOrigin"
                                value={values.dataTransferOutOrigin}
                                name={"dataTransferOutOrigin"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            />
                        </div>
                        <div className="col-md-6 px-3">
                            <label htmlFor="dataTransferOutOriginUnit" className="form-label font-14 font-medium black-v2 mb-3">
                                Unit
                            </label>
                            <select
                            style={{paddingTop :"10px"}}
                                className="form-select font-semibold font-14 form-select-sm custom-form mb-3 custom-form"
                                id="dataTransferOutOriginUnit"
                                value={values.dataTransferOutOriginUnit}
                                name={"dataTransferOutOriginUnit"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            >
                                <option value="GB">GB per Month</option>
                                <option value="TB">TB per Month</option>
                                <option value="PB">PB per Month</option>
                            </select>
                        </div>
                        <div className="col-md-6 px-3">
                            <label
                                htmlFor="noOfRequests"
                                className="form-label font-14 font-medium black-v2 mb-3"
                            >
                                Number of requests (HTTPS)
                            </label>
                            <input
                                type="text"
                                style={{paddingTop :"10px"}}
                                className="form-control custom-form text-field font-14 font-medium custom-form"
                                id="noOfRequests"
                                value={values.noOfRequests}
                                name={"noOfRequests"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            />
                        </div>
                        <div className="col-md-6 px-3">
                            <label htmlFor="noOfRequestsUnit" className="form-label font-14 font-medium black-v2 mb-3">
                                Unit
                            </label>
                            <select
                            style={{paddingTop :"10px"}}
                                className="form-select font-semibold font-14 form-select-sm custom-form custom-form"
                                id="noOfRequestsUnit"
                                value={values.noOfRequestsUnit}
                                name={"noOfRequestsUnit"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            >
                                <option value="per month">per month</option>
                                <option value="per second">per second</option>
                                <option value="million per month">million per month</option>
                            </select>
                        </div>
                    </div>
                </div>
        );
    } catch (error) {
        //console.log(error);
    }
}