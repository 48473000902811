export default function AWSSDKSecretsManager({ values, onChangeOfConfiguration, isViewer }) {
  console.log(values, "Values!!")
  return (
    <>
      <div
        class="accordion-body p-0 estimation-container">
        <div
          className="row  py-4 mx-4 px-5"
          hidden={values.isPriceAvailable == "no" ? false : true}
        >
          <div className="col-md-6 px-3">
            <span className="font-semibold font-14 text-danger">
              {values.isPriceAvailable == "no"
                ? "The Price is not available for the region / the configuration that you have selected might be not available in the region"
                : null}
            </span>
          </div>
        </div>
        <div className="row  py-4 mx-4 px-5">
          <div className="col-md-6 px-3">
            <label htmlFor="zone" className="form-label">
              Region
            </label>
            <select
              style={{ paddingTop: "10px" }}
              className="form-select custom-form font-semibold font-14 form-select-sm"
              aria-label="Default select example"
              id="region"
              value={values.region}
              name={"region"}
              onChange={(e) => {
                onChangeOfConfiguration(e, values.instanceName);
              }} disabled={isViewer}
            >
              <option value="ap-south-2">ap-south-2</option><option value="ap-southeast-3">ap-southeast-3</option><option value="ap-southeast-1">ap-southeast-1</option><option value="us-gov-east-1">us-gov-east-1</option><option value="eu-west-2">eu-west-2</option><option value="af-south-1">af-south-1</option><option value="ap-northeast-1">ap-northeast-1</option><option value="ap-southeast-2">ap-southeast-2</option><option value="us-east-2">us-east-2</option><option value="eu-central-1">eu-central-1</option><option value="ca-central-1">ca-central-1</option><option value="ap-south-1">ap-south-1</option><option value="ap-northeast-3">ap-northeast-3</option><option value="sa-east-1">sa-east-1</option><option value="ap-east-1">ap-east-1</option><option value="eu-west-1">eu-west-1</option><option value="eu-south-1">eu-south-1</option><option value="ap-southeast-4">ap-southeast-4</option><option value="eu-north-1">eu-north-1</option><option value="eu-south-2">eu-south-2</option><option value="us-west-1">us-west-1</option><option value="us-west-2">us-west-2</option><option value="me-south-1">me-south-1</option><option value="eu-central-2">eu-central-2</option><option value="us-gov-west-1">us-gov-west-1</option><option value="ap-northeast-2">ap-northeast-2</option><option value="me-central-1">me-central-1</option><option value="us-east-1">us-east-1</option><option value="eu-west-3">eu-west-3</option>
            </select>
          </div>
          <div className="col-md-6 px-3">
            <label for="SecretsManagerAverage" className="form-label">
              Number of secrets
            </label>
            <input
              type="number"
              style={{ paddingTop: "10px" }}
              className="form-control custom-form form-control custom-form-sm mb-3"
              id="SecretsManagerAverage"
              value={values.SecretsManagerAverage}
              name={"SecretsManagerAverage"}
              onChange={(e) => {
                onChangeOfConfiguration(e, values.instanceName);
              }} disabled={isViewer}
            />
          </div>
          <h6 style={{ paddingBottom: '10px'}}>Average duration of each secret</h6>
          <div className="col-md-6 px-3">
            <label for="averageDurationOfEachSecret" className="form-label">
              Average Duration
            </label>
            <input
              type="number"
              style={{ paddingTop: "10px" }}
              className="form-control custom-form form-control custom-form-sm mb-3"
              id="averageDurationOfEachSecret"
              value={values.averageDurationOfEachSecret}
              name={"averageDurationOfEachSecret"}
              onChange={(e) => {
                onChangeOfConfiguration(e, values.instanceName);
              }} disabled={isViewer}
            />
          </div>
          <div className="col-md-6 px-3">
            <label for="averageUnitDurtion" className="form-label">
              Average Unit Durtion
            </label>
            <select
              style={{ paddingTop: "10px" }}
              className="form-select custom-form  font-medium font-14 text-field mb-3"
              aria-label="Default select example"
              id="averageUnitDurtion"
              value={values.averageUnitDurtion}
              name={"averageUnitDurtion"}
              onChange={(e) => {
                onChangeOfConfiguration(e, values.instanceName);
              }} disabled={isViewer}
            >
              <option value={"hours"}>Hours</option>
              <option value={"days"}>Days</option>
            </select>
          </div>
          <h6 style={{ paddingBottom: '10px' }}>API Calls</h6>
          <div className="col-md-6 px-3">
            <label for="SecretsManagerAPICalls" className="form-label">
              API Calls
            </label>
            <input
              type="number"
              style={{ paddingTop: "10px" }}
              className="form-control custom-form form-control custom-form-sm mb-3"
              id="SecretsManagerAPICalls"
              value={values.SecretsManagerAPICalls}
              name={"SecretsManagerAPICalls"}
              onChange={(e) => {
                onChangeOfConfiguration(e, values.instanceName);
              }} disabled={isViewer}
            />
          </div>
          <div className="col-md-6 px-3">
            <label for="apiCallsPerUnit" className="form-label">
              Api Calls Per Unit
            </label>
            <select
              style={{ paddingTop: "10px" }}
              className="form-select custom-form  font-medium font-14 text-field mb-3"
              aria-label="Default select example"
              id="apiCallsPerUnit"
              value={values.apiCallsPerUnit}
              name={"apiCallsPerUnit"}
              onChange={(e) => {
                onChangeOfConfiguration(e, values.instanceName);
              }} disabled={isViewer}
            >
              <option value={"perHour"}>per Hour</option>
              <option value={"perDay"}>per Day</option>
              <option value={"perMonth"}>per Month</option>
              <option value={"millionPerMonth"}>million Per Month</option>
              <option value={"billionPerMonth"}>billion Per Month</option>
            </select>
          </div>

        </div>
      </div>
    </>

  );
}