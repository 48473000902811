import { useContext } from "react";
import AddNewServiceFields from "./AddNewServiceFields";
import AddServiceButtons from "./AddServiceButtons";
import AddServiceTitle from "./AddServiceTitle";
import InputConfiguration from "./InputConfigurations";
import LanguageCodeEditor from "./LanguageCodeEditor";
import LanguageTab from "./LanguageTab";
import { AddServiceContext } from "./context/AddServiceContext";

export default function AddNewService(){

   const { userId } = useContext(AddServiceContext)

    return(
        <div className="container-fluid custom-container">
        {/* Slide title start here*/}
        <AddServiceTitle/>
        {/* Slide title end here*/}
        {/* Page Content Start here */}
        <div className="row add-organization-form">
          <div className="col-md-12 mt-4 pt-3 ps-4">
            <div className="ps-4">
              <AddNewServiceFields/>
               <InputConfiguration/>
               <div className="row">
        <h3 className="font-14 font-medium mt-3 black-v2 mb-3">
          Language<span className="required">*</span>
        </h3>
        {/* tab Starts here */}
        <div className="kb-template mb-5">
          {/* tab title starts here */}
           <LanguageTab/>
          {/* tab title Ends here */}
          {/* tab content starts here */}
          <LanguageCodeEditor/>
          {/* tab title content Ends here  - Edit option */}
          <AddServiceButtons/>
        </div>
      </div>
            </div>
          </div>
        </div>
        {/* Page Content End here */}
      </div>
    )
}