


// SQ_1.2- 1.4 : Import statements
import { useState } from "react";
import { useModuleContext } from "../../../ContextProvider/ModulesContxt";
import { v4 as uuidv4 } from 'uuid';
import MonacoEditor from 'react-monaco-editor';

function AddResourcePopup({ resourcePopup, setResourcePopup }) {

  //SQ_1.6 : State declaration
  const { resources, setResources } = useModuleContext();
  const [resourceFormData, setResourceFormData] = useState({ name: '', code: '' });
  const [selectedResourceId, setSelectedResourceId] = useState(null);
  const darkTheme = localStorage.getItem('darkTheme');
  const isFormEmpty = !resourceFormData.name || !resourceFormData.code;

  //SQ_1.7-1.8 :  Function to handle adding a new resource
  const addResource = () => {
    setResourceFormData({ name: '', code: '' });
    setSelectedResourceId(null);
  };

  //SQ_1.9-1.10 :  Function to handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setResourceFormData(prevState => ({ ...prevState, [name]: value }));
  };

  // SQ_1.11 - SQ_1.12: Function to handle deleting a resource
  const deleteResource = (id) => {
    // Clear the form data and reset the selected resource ID
    setResourceFormData({ name: '', snippet: '' });
    setSelectedResourceId(null);

    // Update the resources state to remove the selected resource
    console.log(resources.filter(resource => resource.id !== id), "resourcess")
    setResources(resources.filter(resource => resource.id !== id));
  };

  // SQ_1.14 - SQ_1.15: Function to handle updating or adding a resource
  const updateResource = () => {
    if (selectedResourceId) {
      setResources(resources.map(resource =>
        resource.id === selectedResourceId ? { ...resource, ...resourceFormData } : resource
      ));
    } else {
      const newResource = { id: uuidv4(), ...resourceFormData };
      setResources([...resources, newResource]);
    }
    setResourceFormData({ name: '', code: '' });
    setSelectedResourceId(null);
  };

  console.log(resources, "resourcescheck")

  // SQ_1.17 :  Function to handle closing the popup
  const closePopup = () => {
    setResourcePopup(false);
  };

  // SQ_1.18 - SQ_1.28:  Rendering part
  return (
    resourcePopup && (
      <div className="modal fade show" style={{ display: 'block', backgroundColor: "rgba(0,0,0,0.7)" }}>
        <div className="modal-dialog modal-xl modal-dialog-centered justify-content-center">
          <div className="modal-content border-0">
            <div className="modal-header border-0 p-4 ">
              <h1 className="modal-title font-20 font-medium black-v2 ps-2" id="staticBackdropLabel">
                Resources
              </h1>
              <button type="button" className="btn shadow-none" onClick={closePopup}>
                <img src="images/close-icon.svg" alt="close-icon" className="icon-hover shadow-none modal-close-filter" />
              </button>
            </div>
            <div className="modal-body px-4 pt-0">
              <div className="row ps-4">
                {/* sidebar-menu start */}
                <div className="col-md-3 p-0 ">
                  <button type="button" className="button primary-button text-nowrap w-100 mb-4" onClick={addResource}>
                    <img src="images/add-icon.svg" alt="add-icon" className="me-2" />
                    Add Resources
                  </button>
                  <div className="custom-border-right px-0 custom-white resources-left-pane service-scrl">
                    <ul className="nav nav-pills flex-column mb-auto flex-nowrap nav-overflow" id="pills-tab" role="tablist">
                      {resources.map(resource => (
                        // <li className="nav-item" key={resource.id}>
                        //   <button
                        //     className={`nav-link side-nav-item modal-menu d-flex align-items-center justify-content-between ${selectedResourceId === resource.id ? 'active' : ''}`}
                        //     onClick={() => {
                        //       setSelectedResourceId(resource.id);
                        //       setResourceFormData({ name: resource.name, code: resource.code });
                        //     }}
                        //   >
                        //     <span>{resource.name}</span>
                        //     <span className="cursor-pointer" onClick={() => deleteResource(resource.id)}>
                        //       <img src="images/delete-icon.svg" alt="delete-icon" />
                        //     </span>
                        //   </button>
                        // </li>          
                        <li className="nav-item side-nav-item d-flex align-items-center justify-content-between" key={resource.id}  onClick={() => {
                          setSelectedResourceId(resource.id);
                          setResourceFormData({ name: resource.name, code: resource.code });
                        }}>
                          <div
                            className={`nav-link modal-menu p-0 text-truncate color-black ${selectedResourceId === resource.id ? '' : ''}`}
                           
                          >
                            <span>{resource.name}</span>
                          </div>
                          <span className="cursor-pointer" onClick={() => deleteResource(resource.id)}>
                            <img src="images/delete-icon.svg" alt="delete-icon" />
                          </span>
                        </li>
                      ))}

                    </ul>
                  </div>
                </div>
                {/*sidebar-menu end  */}
                <div className="col-md-9 px-4">
                  <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade active show" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                      <div className="row">
                        <div className="col-md-6 mb-4 px-2">
                          <label className="form-label font-14 font-medium black-v2" htmlFor="name">
                            Name
                          </label>
                          <input
                            type="text"
                            className="form-control custom-dropdown shadow-none color-black"
                            id="name"
                            name="name"
                            value={resourceFormData.name}
                            onChange={handleChange}
                            placeholder="Enter the Name"
                          />
                        </div>
                        <div className="mb-2 col-md-12">
                          <label className="form-label font-14 font-medium black-v2">
                            Snippet
                          </label>
                          <div className="theme-border-top code-editor border rounded-3 resources-snippet">
                            <div className="monaco-editor-wrapper" style={{ position: 'relative', height: '400px' }}>
                              <MonacoEditor
                                width="100%"
                                height="70%"
                                language={'json'}
                                theme={darkTheme === "true" ? 'vs-dark' : 'vs-light'}
                                value={resourceFormData.code}
                                onChange={(newValue) => setResourceFormData(prevState => ({ ...prevState, code: newValue }))}
                                options={{ readOnly: false }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="pills-Workloads" role="tabpanel" aria-labelledby="pills-Workloads-tab"></div>
                    <div className="tab-pane fade show active" id="pills-b2a" role="tabpanel" aria-labelledby="pills-b2a-tab"></div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end align-items-center my-4 gap-3">
                <button type="button" className="button outline-button text-nowrap px-4" onClick={closePopup}>
                  Cancel
                </button>
                <button type="button" className="button primary-button text-nowrap px-3" 
                disabled={isFormEmpty}
                onClick={updateResource}>
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default AddResourcePopup;