import { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

// import { getInstanceForDMS } from "../../../Service/api";
export default function AwsSDKDMS({ values, onChangeOfConfiguration, isViewer }) {
    let { state } = useLocation();
    const navigate = useNavigate();
    const [instance, setInstance] = useState([]);
    const [region, setregion] = useState(values.region);
    console.log("came to set region", region)
    const getInitialData = async () => {
        // let fetchedInstance = await getInstanceForDMS({ region: region }, state.provider,
        //     state.jwt,
        //     "")
        // console.log("()()-+00++", fetchedInstance)
        // if (fetchedInstance.status == 200) {
        //     setInstance(fetchedInstance.data)
        // }
    }
    useEffect(
        () => {
            getInitialData()
        }, [region])


    console.log("instance in the state varaible of DMS", instance)
    const bindingInstance = () => {
        return instance.map((data) =>
            <option value={data} key={data}>{data}</option>
        )
    }
    try {
        console.log("saginDMS---", values)
        // if()
        const isFinding = instance.includes('t2.micro')
        if (isFinding === true) values["SelectedInstance"] = "t2.micro"
        else values["SelectedInstance"] = instance[0]
        return (
                <div
                    class="accordion-body p-0 estimation-container">
                    <div className="row py-4 mx-4 px-5">
                        <div >
                            <label htmlFor="region1" className="form-label font-14 font-medium black-v2 mb-3">
                                Region
                            </label>
                            <select
                                className="form-select custom-form font-semibold font-14 form-select-sm mb-3"
                                id="region"
                                value={values.region}
                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                name={"region"}
                                onChange={(e) => {
                                    console.log("when came to set region", e.target.value)
                                    onChangeOfConfiguration(e, values.instanceName);
                                    setregion(e.target.value)
                                }} disabled={isViewer}
                            >
                                <option value="ap-south-2">ap-south-2</option><option value="ap-southeast-3">ap-southeast-3</option><option value="ap-southeast-1">ap-southeast-1</option><option value="us-gov-east-1">us-gov-east-1</option><option value="eu-west-2">eu-west-2</option><option value="af-south-1">af-south-1</option><option value="ap-northeast-1">ap-northeast-1</option><option value="ap-southeast-2">ap-southeast-2</option><option value="us-east-2">us-east-2</option><option value="eu-central-1">eu-central-1</option><option value="ca-central-1">ca-central-1</option><option value="ap-south-1">ap-south-1</option><option value="ap-northeast-3">ap-northeast-3</option><option value="sa-east-1">sa-east-1</option><option value="ap-east-1">ap-east-1</option><option value="eu-west-1">eu-west-1</option><option value="eu-south-1">eu-south-1</option><option value="ap-southeast-4">ap-southeast-4</option><option value="eu-north-1">eu-north-1</option><option value="eu-south-2">eu-south-2</option><option value="us-west-1">us-west-1</option><option value="us-west-2">us-west-2</option><option value="me-south-1">me-south-1</option><option value="eu-central-2">eu-central-2</option><option value="us-gov-west-1">us-gov-west-1</option><option value="ap-northeast-2">ap-northeast-2</option><option value="me-central-1">me-central-1</option><option value="us-east-1">us-east-1</option><option value="eu-west-3">eu-west-3</option>
                            </select>
                        </div>



                        <div >
                            <label htmlFor="DMStype" className="form-label font-14 font-medium black-v2 mb-3">
                                Database Migration Service
                            </label>
                            <select
                                className="form-select custom-form font-semibold font-14 form-select-sm mb-3"
                                id="DMStype"
                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                value={values.DMStype}
                                name={"DMStype"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            >
                                <option value="OnDemand">AWS Database Migration Service On-Demand Instances</option>
                                <option value="OnServerless">AWS Database Migration Service Serverless</option>
                            </select>
                        </div>
                        {values.DMStype === "OnDemand" && <><div className="col-md-6 px-3">
                            <label htmlFor="Numberofinstances" className="form-label font-14 font-medium black-v2 mb-3">
                                Number of instances
                            </label>
                            <input
                                type="text"
                                style={{paddingTop:"10px"}}
                                className="form-control custom-form text-field font-14 font-medium"
                                id="Numberofinstances"
                                value={values.Numberofinstances}
                                name={"Numberofinstances"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            />
                        </div>
                            {/* instance drop down */}
                            <div className="col-md-6 px-3">
                                <label htmlFor="SelectedInstance" className="form-label font-14 font-medium black-v2 mb-3">
                                    SKU
                                </label>
                                <select
                                    className="form-select custom-form font-semibold font-14 form-select-sm mb-3"
                                    id="SelectedInstance"
                                    value={values.SelectedInstance}
                                    name={"SelectedInstance"}
                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                >
                                    <option value="c4.2xlarge">c4.2xlarge</option>
                                                <option value="c4.4xlarge">c4.4xlarge</option>
                                                <option value="c4.large">c4.large</option>
                                                <option value="c4.xlarge">c4.xlarge</option>
                                                <option value="c5.12xlarge">c5.12xlarge</option>
                                                <option value="c5.18xlarge">c5.18xlarge</option>
                                                <option value="c5.24xlarge">c5.24xlarge</option>
                                                <option value="c5.2xlarge">c5.2xlarge</option>
                                                <option value="c5.4xlarge">c5.4xlarge</option>
                                                <option value="c5.9xlarge">c5.9xlarge</option>
                                                <option value="c5.large">c5.large</option>
                                                <option value="c5.xlarge">c5.xlarge</option>
                                                <option value="c6i.12xlarge">c6i.12xlarge</option>
                                                <option value="c6i.16xlarge">c6i.16xlarge</option>
                                                <option value="c6i.24xlarge">c6i.24xlarge</option>
                                                <option value="c6i.2xlarge">c6i.2xlarge</option>
                                                <option value="c6i.32xlarge">c6i.32xlarge</option>
                                                <option value="c6i.4xlarge">c6i.4xlarge</option>
                                                <option value="c6i.8xlarge">c6i.8xlarge</option>
                                                <option value="c6i.large">c6i.large</option>
                                                <option value="c6i.xlarge">c6i.xlarge</option>
                                                <option value="r4.2xlarge">r4.2xlarge</option>
                                                <option value="r4.4xlarge">r4.4xlarge</option>
                                                <option value="r4.8xlarge">r4.8xlarge</option>
                                                <option value="r4.xlarge">r4.xlarge</option>
                                                <option value="r4.large">r4.large</option>
                                                <option value="r5.12xlarge">r5.12xlarge</option>
                                                <option value="r5.16xlarge">r5.16xlarge</option>
                                                <option value="r5.24xlarge">r5.24xlarge</option>
                                                <option value="r5.2xlarge">r5.2xlarge</option>
                                                <option value="r5.4xlarge">r5.4xlarge</option>
                                                <option value="r5.8xlarge">r5.8xlarge</option>
                                                <option value="r5.xlarge">r5.xlarge</option>
                                                <option value="r5.large">r5.large</option>
                                                <option value="r6i.12xlarge">r6i.12xlarge</option>
                                                <option value="r6i.16xlarge">r6i.16xlarge</option>
                                                <option value="r6i.24xlarge">r6i.24xlarge</option>
                                                <option value="r6i.2xlarge">r6i.2xlarge</option>
                                                <option value="r6i.32xlarge">r6i.32xlarge</option>
                                                <option value="r6i.4xlarge">r6i.4xlarge</option>
                                                <option value="r6i.8xlarge">r6i.8xlarge</option>
                                                <option value="r6i.large">r6i.large</option>
                                                <option value="r6i.xlarge">r6i.xlarge</option>
                                                <option value="t2.large">t2.large</option>
                                                <option value="t2.medium">t2.medium</option>
                                                <option value="t2.micro">t2.micro</option>
                                                <option value="t2.small">t2.small</option>
                                                <option value="t3.large">t3.large</option>
                                                <option value="t3.medium">t3.medium</option>
                                                <option value="t3.micro">t3.micro</option>
                                                <option value="t3.large">t3.large</option>
                                                <option value="t3.small">t3.small</option>
                                    {/* <option selected value={0}>Select</option> */}
                                    {instance && bindingInstance()}
                                </select>
                            </div>
                            <div className="col-md-6 px-3">
                                <label htmlFor="DeploymentOption" className="form-label font-14 font-medium black-v2 mb-3">
                                    Deployment option
                                </label>
                                <select
                                    className="form-select custom-form font-semibold font-14 form-select-sm mb-3"
                                    id="DeploymentOption"
                                    value={values.DeploymentOption}
                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                    name={"DeploymentOption"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                >
                                    <option value="Multiple">Multiple</option>
                                    <option value="Single">Single</option>
                                </select>
                            </div>

                            <div className="col-md-6 px-3">
                                <label htmlFor="storageDMS" className="form-label font-14 font-medium black-v2 mb-3">
                                    Storage for each database migration instance
                                </label>
                                <select
                                    className="form-select custom-form font-semibold font-14 form-select-sm"
                                    id="storageDMS"
                                    value={values.storageDMS}
                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                    name={"storageDMS"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                >
                                    <option value="gp2">General Purpose SSD (gp2)</option>
                                </select>
                            </div>
                            <div className="col-md-6 px-3">
                                <label htmlFor="StorageAmount" className="form-label font-14 font-medium black-v2 mb-3">
                                    Storage amount (single AZ)
                                </label>
                                <input
                                    type="text"
                                    style={{paddingTop:"10px"}}
                                    className="form-control custom-form text-field font-14 font-medium"
                                    id="StorageAmount"
                                    value={values.StorageAmount}
                                    name={"StorageAmount"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                />
                            </div>

                            <div className="col-md-6 px-3">
                                <label htmlFor="StorageAmountUnit" className="form-label font-14 font-medium black-v2 mb-3">
                                    Unit
                                </label>
                                <select
                                    className="form-select custom-form font-semibold font-14 form-select-sm"
                                    id="StorageAmountUnit"
                                    value={values.StorageAmountUnit}
                                    name={"StorageAmountUnit"}
                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                >
                                    <option value="gb">GB</option>
                                </select>
                            </div>
                        </>}
                        {/* on serverless */}
                        {values.DMStype === "OnServerless" && <><div className="col-md-6 px-3">
                            <label htmlFor="NumberofDCU" className="form-label font-14 font-medium black-v2 mb-3">
                                Number of DMS Capacity Units (DCUs)
                            </label>
                            <select
                                className="form-select custom-form font-semibold font-14 form-select-sm"
                                id="NumberofDCU"
                                value={values.NumberofDCU}
                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                name={"NumberofDCU"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            >
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="4">4</option>
                                <option value="8">8</option>
                                <option value="16">16</option>
                                <option value="32">32</option>
                                <option value="64">64</option>
                                <option value="128">128</option>
                                <option value="192">192</option>
                                <option value="256">256</option>
                                <option value="384">384</option>
                            </select>
                        </div>
                            <div className="col-md-6 px-3">
                                <label htmlFor="AvailabilityZone" className="form-label font-14 font-medium black-v2 mb-3">
                                    Availability Zone
                                </label>
                                <select
                                    className="form-select custom-form font-semibold font-14 form-select-sm"
                                    id="AvailabilityZone"
                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                    value={values.AvailabilityZone}
                                    name={"AvailabilityZone"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                >
                                    <option value="Single">Single</option>
                                    <option value="Multiple">Multiple</option>
                                </select>
                            </div>
                            {/* <div className="col-md-6 px-3">
                                            <label htmlFor="MigrationRuntimehours" className="form-label font-14 font-medium black-v2 mb-3">
                                            DMS Homogeneous Data Migrations
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control custom-form text-field font-14 font-medium"
                                                id="MigrationRuntimehours"
                                                value={values.MigrationRuntimehours}
                                                name={"MigrationRuntimehours"}
                                                onChange={(e) => {
                                                    onChangeOfConfiguration(e, values.instanceName);
                                                }} disabled={isViewer}
                                            />
                                        </div> */}
                        </>}


                    </div>
                </div>
        );
    } catch (error) {
        //console.log(error);
    }
}



