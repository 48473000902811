import { useEffect, useState } from "react";
import { aiSuggestionApi } from "../../../../Service/api"
import { useOrgContext } from "../../../../ContextProvider/OrgContext";


function ChatBot({
  processSelectedService
}) {

  const { providerId, envId, } = useOrgContext()
  const userId = localStorage.getItem('userId');

  const [serviceSuggestedData, setServiceSuggestedData] = useState([])
  const [suggestion, setSuggestion] = useState("")
  const[selectedServiceData,setSelectedServiceData]=useState("")
  const [query, setQuery] = useState(""); 

  useEffect(() => {

    getAiSuggestion()

  }, [])




  const getAiSuggestion = async () => {

    try {

      const payload = {
        userId: userId,
        query: query,
        providerId: providerId,
        environmentId: envId
      };
      const response = await aiSuggestionApi(payload);
      console.log(response, "aiResponse")

      if (response.status === 200) {
        const { data } = response.data;

        if (data && data.length > 0 && data[0].AIsugestion && data[0].AIsugestion.service) {
          const suggestedServices = data[0].AIsugestion.service;
          const suggestion = data[0].AIsugestion.suggestion;
          setServiceSuggestedData(suggestedServices);
          setSuggestion(suggestion)
        }
      }
    } catch (err) {
      console.log(err, "error in  AI suggestion")
    }
  };

  const handleServiceClick = (service) => {
    setSelectedServiceData(service);
  };

  useEffect(() => {
    if (selectedServiceData) {
      console.log(selectedServiceData , "Selected serviceData")
      processSelectedService(selectedServiceData.name);
    }
  }, [selectedServiceData]);

  const handleSendClick = () => {
    getAiSuggestion(query); // Call getAiSuggestion with the query state
    setQuery("")
  };



  console.log(serviceSuggestedData, "serviceSuggestedData")
  console.log(selectedServiceData, "selectedServiceData")



  
  


  return (
    <>

      <div
        className="tab-pane fade show active pt-4 tab-scrl"
        id="insights"
        role="tabpanel"
        aria-labelledby="insights"
      >
        <div className="chatbot-container d-flex flex-column h-100 service-scrl">
          <div className="chat-box my-2 flex-1 overflow-auto flex-grow-1 gap-3 align-items-center justify-content-center d-flex flex-column">
            <img src="images/nochat-img.svg" alt="chat-icon" />
            <p className="font-14 font-medium black-v2">
              Provide your requirements here
            </p>
            <textarea
              className="h-100 form-control custom-form custom-textarea font-14 font-medium"
              placeholder=""
              id="suggestion"
              value={suggestion}
              onChange={((e) => { setSuggestion(e.target.value) })}
            />
            <div className="row mt-3">
              {serviceSuggestedData.map((service) => (
                <div 
                className="col-md-4"
                 key={service.service_id}
                 onClick={() => handleServiceClick(service)}
                 style={{ cursor: 'pointer' }} 
                 >
                  <p className="popup-text">{service.name}</p>
                </div>
              ))}
            </div>

          </div>
          <div className="input-group flex-shrink-0 mb-2 mt-auto">
          <input
              type="text"
              className="chat-input py-3 form-control border-end-0 shadow-none font-14 font-regular"
              placeholder="Enter your requirement"
              value={query}
              onChange={(e) => setQuery(e.target.value)} // Update query state
              aria-label="Input group example"
              aria-describedby="btnGroupAddon"
            />
            <button
              type="button"
              className="py-3 input-group-text bg-white shadow-none"
              id="btnGroupAddon"
              onClick={handleSendClick} // Set onClick handler
            >
              <img src="images/send-icon.svg" alt="search-icon" />
            </button>
          </div>
        </div>
      </div>
    </>

  )
}
export default ChatBot;