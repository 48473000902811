//PS_02 Import necessary React hooks and API functions
import React, { useState, useEffect,useContext,createContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getOrganizationDetailsApi,deleteOrganizationApi ,getAllWorkloadsApi,getAllProvidersApi} from '../../Service/api';
import { OrgContext } from '../../ContextProvider/OrgContext';
import Loader from '../loader/loader';

const ManageOrganizations = () => {
   // PS_03 create state variables for necessary functionality
    const [organizationDetails, setOrganizationDetails] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [showDeleteConfirmationPopup, setShowDeleteConfirmationPopup] = useState(false);
    const [deleteOrganizationId, setDeleteOrganizationId] = useState('');
    const [deleteConfirmationText, setDeleteConfirmationText] = useState('');
    const [userId, setuserId] = useState(localStorage.getItem('userId'));
    const [isAdmin, setisAdmin] = useState(localStorage.getItem('isAdmin')=="true"?true:false);
    const [loading, setLoading] = useState(true);
console.log("orgggg");
    console.log("admin",isAdmin);

    const cloudgenVersion = process.env.REACT_APP_VERSION


    const {
        userRoles,
        orgId,
        providerId,
        architectures,
        canCreateWorkload,
        canDeleteWorkload,
        canViewWorkload,
        setuserRoles,
    
        // Add organization and provider fields here
        providerName,       
        orgData,           
        setOrgData, 
        setOrgId,       
        setArchitectures,
    
        envId,             // Environment ID
        workloadId,        // Workload ID
        aiArchitecture,    
        viewMode,          
        envName,           
        language,          
        archType,          
        business,          
        rulebook,          
        archDesc,          
        services,
    
        lastBuildID,       
        lastBuildDate,     
        lastBuildMessage,
    
        repoId,            
        repoName,
    
        PulseOrganization_id,
        PulseVertical_id,
        PulseVertical_name,
        PulseOrganization_name,
        PulseIdentification,
    
        setEnvId           // Function to update environment ID
    } = useContext(OrgContext);

    //PS_04 Createuse useEffect to call loadOrganizations on component mount
    useEffect(() => {
        
        loadOrganizations();
        
    }, []);


    //PS_05 Try to fetch organization details using getOrganizationDetailsApi

    const navigate = useNavigate();
    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredOrganizations = organizationDetails.filter(org =>
        org.orgName.toLowerCase().includes(searchTerm.toLowerCase())
    );
   // PS_24
    const handleDeleteClick = (orgId) => {
        setShowDeleteConfirmationPopup(true);
        setDeleteOrganizationId(orgId);
    };
   // PS_22
    const confirmDelete = async () => {
        const payload = {
            userId : userId,
            orgId : deleteOrganizationId,
            search:""
        }
        if (deleteConfirmationText === "DELETE") {
            // setOrganizationDetails(prevDetails => 
            //     prevDetails.filter(org => org.orgId !== deleteOrganizationId)
            // );
            try {
                //PS_24 Call your API to get organizations
                const response = await deleteOrganizationApi(payload); // Adjust endpoint as necessary
                if (response.success) {
                    
                    // Update state with organizations data
                    setShowDeleteConfirmationPopup(false);
            setDeleteConfirmationText('');
            loadOrganizations()
                } else {
                    // Handle error
                    console.error('Error fetching organizations');
                }
            } catch (error) {
                console.error('An error occurred while fetching organizations', error);
            }
            // console.log('Organization deleted successfully!');
            
        } else {
            console.log('Please type "DELETE" to confirm.');
        }
    };
    //PS_09
    const loadOrganizations = async () => {
        const payload = {
            userId : userId,
            search:""
        }
        try {
            // Call your API to get organizations
            const response = await getOrganizationDetailsApi(payload); // Adjust endpoint as necessary
            if (response.success ) {
                console.log("Organizations fetched successfully");
                console.log(response.data);
                // Update state with organizations data
                setOrganizationDetails(response.data);
                setOrgData(response.data) // Ensure you have setOrganizationsData defined
            } else {
                // Handle error
                console.error('Error fetching organizations');
            }
        } catch (error) {
            console.error('An error occurred while fetching organizations', error);
        } finally{
            setLoading(false);

        }
    };
//PS_31
    const loadProviders = async (orgid) => {
        console.log("Checked providers");
        const payload = {
            userId: userId,
            orgId: orgId?orgId:orgid,
            type: "general"
        };
    
        try {
            const response = await getAllProvidersApi(payload); // Call the API function
            console.log("Checked providers 2");
    
            if (response.success) {
                // Find the provider IDs for AWS and Azure
                const awsProvider = response.data.find(provider => provider.providerName === "AWS");
                const azureProvider = response.data.find(provider => provider.providerName === "Azure");
                console.log("nooo");

                // Load workloads for AWS if found
                if (awsProvider) {
                    await fetchWorkloads(awsProvider.providerId);
                }
    
                // Load workloads for Azure if found
                if (azureProvider) {
                    await fetchWorkloads(azureProvider.providerId);
                }

                console.log("yessss");
                navigate('/CostDashboard'); 
            } else {
                console.error('Error fetching providers');
            }
        } catch (error) {
            console.error('An error occurred while fetching providers:', error);
        }
    };
  //PS_36  
    const fetchWorkloads = async (selectedProviderId) => {
        console.log("Fetching workloads...");
        const payload = {
            userId: userId,
            orgId: orgId,
            from: "",
            to: "",
            status: "",
            search: "", // Search query
            providerId: selectedProviderId,
            pagination: "0" // Calculate starting index for pagination
        };
    
        try {
            const response = await getAllWorkloadsApi(payload); // Call the API function
            console.log("API call completed");
    
            if (response.success) {
                const roles = response.data.gridData.map(workload => workload.role);
                
                // Update userRoles state with new roles while preserving existing ones
                setuserRoles(prevRoles => [...prevRoles, ...roles]);
                console.log("okayy",roles);
            } else {
                console.error('Error fetching workloads');
            }
        } catch (error) {
            console.error('An error occurred while fetching workloads:', error);
        } finally {
            setLoading(false); // Stop loading regardless of success or failure
        }
    };
    if (loading) {
        return <Loader />; // Show loader while loading
    }

    return (
        <>
        <div className="container-fluid custom-container right-container-scroll">
            <div className="d-flex justify-content-between align-items-center mt-4">
                <h2 className="font-20 font-bold black-v2 mb-0">Manage Organizations</h2>
                <div className="d-flex gap-3">
                    <div className="input-group input-search custom-border-box custom-search-box">
                        <input
                            type="search"
                            className="px-3 form-control common-bg border-0 custom-form"
                            placeholder="Search"
                            value={searchTerm}
                            onChange={handleSearch}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter' && searchTerm.length > 2) {
                                    // Perform search action
                                }
                            }}
                        />
                        <button className="btn border-start-0 custom-form custom-search" type="button" onClick={() => {
                            if (searchTerm.length > 0) {
                                // Perform search action
                                setSearchTerm('');
                            }
                        }}>
                            <img src="images/search-icon.svg" alt="search" />
                        </button>
                        {/* {searchTerm.length > 0 && (
                            <button className="btn border-start-0 custom-form custom-search" type="button" onClick={() => {
                                setSearchTerm('');
                                // Clear search results
                            }}>
                            </button>
                        )} */}
                    </div>
                  {isAdmin&&<button
                        type="button"
                        className="button primary-button text-nowrap"
                        onClick={() => navigate('/add-organization')}
                    >
                        <img src="images/add-icon.svg" alt="add-icon" className="me-2" /> Add Organization
                    </button>}
                </div>
            </div>

            {/* {filteredOrganizations.length === 0 && searchTerm.length > 2 && (
                <div className="row mt-4 pt-2 manage-workloads-parent justify-content-center">
                    <img src="images/no-data-found.png" alt="No Data Found" className="no-data-found-image" />
                </div>
            )} */}

<div className="row mt-4 pt-2 manage-workloads-parent">
            {filteredOrganizations.length === 0 ? (
                <div className="col-md-12">
                    <div className="record-container d-flex align-items-center justify-content-center flex-column">
                        <div className="no-rec-img">
                            <img src="images/no-rec-found-img.png" alt="No Organization Found" className="w-100 h-100" />
                        </div>
                        <h6 className="color-black font-bold font-16 mt-5 mb-0">No Organization Found!!</h6>
                        {/* <p className="font-medium font-12 mt-4 mb-0 secondary-text-color-v5">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla imperdiet lorem sapien, quis accumsan erat tincidunt eget.
                        </p> */}
                    </div>
                </div>
            ) : (
                filteredOrganizations.map((org) => (
                    <div className="col-md-3 mb-4" key={org.orgId}>
                        <div className="manage-workloads-section p-3">
                            <div 
                                className="d-flex align-items-center justify-content-between" 
                                onClick={() => { 
                                    setOrgId(org.orgId); // Set the organization ID in state
                                    loadProviders(org.orgId)
                                    localStorage.setItem('orgId', org.orgId); // Store orgId in localStorage with a key
                                  // Navigate to workloadsGrid
                                }}
                            >
                                <div className="d-flex align-items-center">
                                    <img className="partner-logo-circle" src={org.orgUrl} alt={org.orgName} />
                                    <p className="font-17 font-medium black-v2 ms-3 mb-0 partner-name">{org.orgName}</p>
                                </div>
                                <a href="#" className="bg-buttton-arrow">
                                    <span className="Workload-right-arrow"></span>
                                </a>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                                <p className="mt-4 font-12 font-medium secondary-text-color mb-0 d-flex align-items-center">
                                    Workloads <span className="ms-2 font-16 font-bold black-v2">{org.workloadCount}</span>
                                </p>

                                {isAdmin && (
                                    <div className="position-relative Workload-options">
                                        <button type="button" data-bs-toggle="dropdown" aria-expanded="false" className="btn border-0 shadow-none p-0">
                                            <img src="images/articles-options-dots.svg" alt="options" className="articles-option-dots" />
                                        </button>

                                        <div className="dropdown-menu dropdown-menu-end border-0 bg-transparent">
                                            <div className="workload-dropdown p-3">
                                                <a href="#" onClick={() => navigate('/add-organization', { state: { orgId: org.orgId } })} 
                                                   className="color-black font-13 font-medium text-decoration-none d-block mb-2">
                                                    <img src="images/articles-edit-icon-blue.svg" alt="edit-icon" className="workload-option-icons" /> Edit
                                                </a>
                                                {org.delete && (
                                                    <a href="#" onClick={() => handleDeleteClick(org.orgId)} 
                                                       className="color-black font-13 font-medium text-decoration-none d-block mt-2">
                                                        <img src="images/articles-delete-icon.svg" alt="delete-icon" className="workload-option-icons" /> Delete
                                                    </a>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                ))
            )}
        </div>

            {showDeleteConfirmationPopup && (
    <div className="modal fade show" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }} id="staticBackdrop-22" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered justify-content-center">
            <div className="modal-content border-0">
                <div className="modal-header d-flex justify-content-end border-0 pb-0 ">
                    <button type="button" className="btn shadow-none" onClick={() => setShowDeleteConfirmationPopup(false)} aria-label="Close">
                        <img src="images/close-icon.svg" alt="close-icon" className="icon-hover shadow-none modal-close-filter" />
                    </button>
                </div>
                <div className="modal-body text-center pt-0 p-32">
                    <img src="images/delete-organization-icon.svg" alt="delete the Organization" />
                    <h6 className="font-20 font-bold black-v2 mt-4 pt-2">You're about to delete the Organization</h6>
                    <h6 className="font-20 font-bold black-v2 pt-2">"{organizationDetails.find(org => org.orgId === deleteOrganizationId)?.orgName}"</h6>
                    <p className="font-16 font-regular secondary-color mt-3 line-height-32">
                        Deleting the Organization will result in deleting the Workloads, Connectors, Templates, Architectures etc.
                    </p>
                    <input 
                        type="text" 
                        className="form-control custom-dropdown shadow-none" 
                        id="conform-delete"
                        placeholder='Type "DELETE" to confirm' 
                        value={deleteConfirmationText}
                        onChange={(e) => setDeleteConfirmationText(e.target.value)} 
                    />
                    <div className="d-flex justify-content-end gap-3 align-items-center mt-4 pt-3">
                        <button type="button" className="button outline-button text-nowrap px-4" onClick={() => setShowDeleteConfirmationPopup(false)}>Cancel</button>
                        <button 
                            type="button" 
                            className="button danger-button popup-btn px-4" 
                            onClick={confirmDelete} 
                            disabled={deleteConfirmationText !== "DELETE"} // Disable if text is not "DELETE"
                        >
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
)}

           
        </div>
         <div class="position-fixed bottom-0 copyrights-section">
         <div class="custom-copy-rights">
  <p class="mb-0 font-12 font-medium secondary-color text-center">© CloudGen v1.0.1</p></div>
       </div>
       </>
    );
};

export default ManageOrganizations;



