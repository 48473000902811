// authConfig.js
import { Configuration, PopupRequest } from "@azure/msal-browser";
import { useLocation, useNavigate } from "react-router-dom";



export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID,
        authority: process.env.REACT_APP_AUTHORITY,
        redirectUri: process.env.REACT_APP_REDIRECT_URI
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
        }
};

console.log(process.env.REACT_APP_REDIRECT_URI,"This is the redirect uri...")

console.log("Inside the Auth config")


export const loginRequest = {
    scopes: ["User.Read"]
    // tenantId: "cd5dbd24-330a-4174-8e00-dc550f63976b",
    // clientId: "b8940b5c-aeba-41ad-a994-54ca6a5cf584",
};

export const graphConfig = {
    graphEndpoint: process.env.REACT_APP_GRAPH_ENDPOINT
};

const TENANT_ID = "cd5dbd24-330a-4174-8e00-dc550f63976b";
export const isAuth = (accounts) => {
    // console.log(accounts,"KKKK");
    return accounts.find((_account) => _account.tenantId === TENANT_ID);
  };


// REACT_APP_CLIENT_ID = '908e68b7-d048-4a78-80a0-50d1fa87a4b7'
// REACT_APP_AUTHORITY = 'https://login.microsoftonline.com/0a6e3df2-2314-4232-97e1-04006154b5bf/'
// REACT_APP_REDIRECT_URI = 'http://localhost:3000/'
// REACT_APP_GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/users/YOUR_USER_ID/memberOf'