
import { type } from "@testing-library/user-event/dist/type";
import React, { useEffect, useRef, useState } from "react";

import { fetchServicesApi,fetchEnvironmentsAPI,getAllTagsApi } from "../../../../Service/api";

import AssignTags from "./PopupMaintanence/AssignTags";
import { useOrgContext } from "../../../../ContextProvider/OrgContext";
import LocalsOutputsComponent from "./IAC_Component";
import { useNavigate } from "react-router-dom";
import DataConfiguration from "./DataConfiguration";
import Loader from "../../../loader/loader";
// Static data to simulate API responses

export default function SideBarComponent({
	assignTagsUpdate , 
	setRepositories ,
	setSelectedEnv,
	showAssignTags,
	setShowAssignTags,
	setActiveTab,
	setIACModelOpen,
	setDataConfigModal,
	isDarkMode,isLoading,setIsLoading
	}) {
	const { orgId,envId,workloadId,providerId,workloadRole,providerName, userRoles} = useOrgContext();
	const [userId, setuserId] = useState(localStorage.getItem('userId'));
	// constants
console.log("isDarkModeisDarkMode",isDarkMode);

	// Other Use States
	const [draggable, setDraggable] = useState(true);
	const [sideSearch, setSideSearch] = useState("");
	const [error, setError] = useState(null);
		const [viewMode,setViewMode] = useState(workloadRole.toLowerCase() == 'viewer' ? true : false)
	const [isCollapsed, setIsCollapsed] = useState(false);


	// Search Functionalites 
	const [expandedCategories, setExpandedCategories] = useState([]);
	// Service Rendering 
	const [services, setServices] = useState([]);
	// Environments Rendering 
	const [environments, setEnvironments] = useState([]);
	const [environmentID, setEnvironmentID] = useState(envId);
	// Assign Tags State Varaible 
	const [assignedTags, setAssignedTags] = useState([]);
	const accordionRef = useRef(null);
	const navigate=useNavigate()

	
	

	const toggleSidebar = () => {
		setIsCollapsed((prevState) => !prevState);
	  };
	
	// Page Onload Useeffect inorder to retreive the Service for the Workbench
	useEffect(() => {
		//PS_07 Simulate API calls
	fetchEnvironments()
		// setServices(staticServices);

	}, []);

	useEffect(() => {
		//PS_16 if (providerId) {
		fetchServices();
		// }
	}, []);
	/**
	* This Function retrieves the Services in from the configured Self Service
	* With Respect to the Provider the fetch Services is intiated 
	*/
	//PS_17
	const fetchServices = async (searchWord = "") => {

		setError(null);
		try {
			// const requestBody = {
			// cloud_provider_id: providerId,
			// userId: userId,
			// orgId: orgId,
			// search_word: searchWord
			// }
				
				const requestBody = {
					"userId": userId,
					"orgId": orgId,
					"cloud_provider_id": providerId,
				}
				console.log(requestBody , "requestBody ")
				setIsLoading(true)
			let response = await fetchServicesApi(requestBody)
				console.log(response , "Fetch ServiceAPI ")
			if (response.success) {
				console.log("Fetched services:", response.data);
				// setServices(response.data);
				// setServices(staticResponse.data.body.data);
				setServices(response.data);
			} else {
				throw new Error(response.data.body.message || 'Failed to fetch services');
			}
		} catch (error) {
			console.error("Error fetching services:", error);
			setError('Failed to load services. Please try again.');
		} finally {
setIsLoading(false)
		}
	};
		/**
	* This Function retrieves the Environemtents in t 
	* With Respect to the Provider the fetch Services is intiated 
	*/
	//PS_09
	const fetchEnvironments = async () => {
		try {
			const requestBody = {
				workloadId: workloadId, // Replace with actual workload ID
				userId: userId,
				organizationId: orgId,
					envId :envId
			};
				console.log(requestBody, "REquestBody")
				let response = await fetchEnvironmentsAPI(requestBody)
				console.log(response , "response from fetchEnvironments ")
			if (response.success) {
				// Set the Environements and Repo Details Over here 
				setEnvironments(response.data.environment);
					setRepositories(response.data.repository)
			} else {
				console.log("Error in the Retrieavl of Environments")
			}
		} catch (error) {
			console.log("Error fetching environments:", error);

		}
	};
	const handleEnvironmentChange = async(e)=>{
		setEnvironmentID(e.target.value)
		let obj = {
			environment_id :e.target.value,
			environment_name :e.target.name
		}
		setSelectedEnv(obj)
		// It Should update the Value in the Workbench or Context provider
		}


	/**
	* Search Functionality in the Side Bar Component
	*/
	const handleSearchChange = (e) => {
		const searchTerm = e.target.value;
		console.log("searchhh2",searchTerm);
		
		setSideSearch(searchTerm);

		 if(searchTerm && searchTerm.toLowerCase()=="account"||  searchTerm.toLowerCase()=="region"){
			console.log("searchhh");
			setExpandedCategories("Account")

		} else if (searchTerm) {
			const matchedCategories = services.reduce((acc, category) => {
				const matchedServices = category.services.filter(service => 
					// Lower Comparison takes place even if the user searches in caps 	
					service.serviceName.toLowerCase().includes(searchTerm.toLowerCase())
				);
				if (matchedServices.length > 0) {
					acc.push(category.serviceCategory);
				}
				return acc;
			}, []);
			setExpandedCategories(matchedCategories);
		}
	else {
			setExpandedCategories([]);
		}
		};
//PS_30
	const toggleCategory = (category) => {
		setExpandedCategories(prev => 
			prev.includes(category) 
				? prev.filter(c => c !== category) 
				: [...prev, category]
		);
		};

		// Asssign Tags Opertions 
		// Toggle the Assign Tags modal
	const toggleAssignTags = () => {
		setShowAssignTags(!showAssignTags);
		};



	// React Flow On Drag Functionality 
	/** 
		* When the users Drag and Drop the service from the side Bar component 
		* This Functionality is triggered
	*/
	const [selectedModule, setSelectedModule] = useState('cloudgen');
//PS_28.1
	const onDragStart = (event, serviceCategory, service) => {
		if (draggable) {
			console.log('serviceCategory:', serviceCategory);
			console.log('service:', service);

			const serviceData = {
				id: (service.serviceName || '') + "_" + Math.random(),
				unique_Name:"",
				childName: service.serviceName,
				parentName: serviceCategory.serviceCategory,
				nodeType: service.containsService ? 'location' : 'service',
				imageName: service.serviceImageBase64,
				serviceDR: service.description ,
				serviceName: service.serviceName,
				subServiceName: '',
				serviceFamily: serviceCategory.serviceCategory ,
				famImage: serviceCategory.serviceCategoryImage ,
				serImage: service.serviceImage ,
				allSubService: JSON.stringify(service.fields || []),
				costParent: service.serviceName ,
				description: service.description ,
				module_name: service.module_name ,
				terraform_module_urls:service?.terraform_module_urls,
				// fields: service.fields || [],
				updated_input:service?.updated_input,
				terraform_input:service?.terraform_input,
				terraform_output:service?.terraform_output,
				updated_output:service?.updated_output,
				fields: selectedModule === 'cloudgen' ?  service.updated_input|| [] : service.terraform_input || [],
// 				fields:  [
// 					// {
// 					// 	"value": "{}",
// 					// 	"description": "Additional tags for the public route tables",
// 					// 	"name": "public_route_table_tags",
// 					// 	"required": "False",
// 					// 	"type": "map(string)"
// 					// },
// 					{
// 						"value": "{}",
// 						"description": "Maps of Customer Gateway's attributes (BGP ASN and Gateway's Internet-routable external IP address)",
// 						"name": "customer_gateways",
// 						"required": "False",
// 						"type": "map(map(any))"
// 					},
// 					{
// 						"value": "\"64512\"",
// 						"description": "The Autonomous System Number (ASN) for the Amazon side of the gateway. By value the virtual private gateway is created with the current value Amazon ASN",
// 						"name": "amazon_side_asn",
// 						"required": "False",
// 						"type": "string"
// 					},
// 					{
// 						"value": [
// 							"Development",
// 							"Testing",
// 							"Staging",
// 							"Production"
// 						],
// 						"description": "Additional tags for the private subnets",
// 						"name": "private_subnet_tags",
// 						"required": "False",
// 						"type": "list(string)"
// 					}
// ,					
// 					{
// 						"value": "true",
// 						"description": "Indicates whether to respond to DNS queries for instance hostnames with DNS AAAA records. value: `true`",
// 						"name": "redshift_subnet_enable_resource_name_dns_aaaa_record_on_launch",
// 						"required": "False",
// 						"type": "bool"
// 					},
// 					{
// 						"value":"[\nsankar:guru, \n guru:sankar]",
// 						"description": "Name of redshift subnet group",
// 						"name": "redshift_subnet_group_name",
// 						"required": false,
// 						"type": "list(object)"
// 					}
					
// 				]
// 	,			 
			
				containsService: !!service.containsService,
				moduleName : service?.moduleName,
				source : service?.source,
				version : service?.version,
				// is_parent:service?.is_parent,
				//  module: selectedModule,
            custom: selectedModule === 'cloudgen', // Set custom to true if 'cloudgen' is selected
        
			

			};

			console.log('serviceData:', serviceData);

			// Set all the data as a single JSON string
			event.dataTransfer.setData('application/json', JSON.stringify(serviceData));

			// Optionally, you can still set individual data if needed
			Object.entries(serviceData).forEach(([key, value]) => {
				let stringValue = '';
				if (value !== null && value !== undefined) {
					stringValue = typeof value === 'object' ? JSON.stringify(value) : value.toString();
				}
				event.dataTransfer.setData(`application/${key}`, stringValue);
			});

			event.dataTransfer.effectAllowed = 'move';
		}
		};

		//PS_28.1
	const onDragStartAccount = (event) => {
		if (draggable) {
			console.log("account dragged"); // Log that the account is being dragged

			const serviceData = {
				id: 'account-node-' + Math.random().toString(36).substr(2, 9), // Generate unique ID
				childName: 'Account',
				parentName: 'Account',
				nodeType: 'location',
				
				imageName: "./images/Workbench/ServiceImages/AWS/Security/AWS-Organizations_Account_light-bg.png",
				serviceDR: 'Account',
				serviceName: 'Account',
				subServiceName: 'Account',
				serviceFamily: 'Account',
				famImage: './images/Workbench/ServiceImages/AWS/Security/AWS-Organizations_Account_light-bg.png',
				serImage: './images/Workbench/ServiceImages/AWS/Security/AWS-Organizations_Account_light-bg.png',
				allSubService: JSON.stringify([]), // Change this to an empty array
				costParent: 'Account',
				description: 'Account',
				fields: [], // Ensure this is not an array, just a string
				containsService: true, // This should be a boolean
				position: { x: 0, y: 0 }, // Initial position (will be updated on drop)
			};

			console.log("account service data", serviceData); // Log the serviceData object
			event.dataTransfer.setData('application/json', JSON.stringify(serviceData));
			event.dataTransfer.effectAllowed = 'move'; // Allow move effect
		}

		// The following line is incorrect; it should reference the variable serviceData directly
		// console.log("account service data".serviceData);
		};
		const onDragStartRegion = (event) => {
			if (draggable) {
				console.log("Region dragged"); // Log that the account is being dragged
	
				const serviceData = {
					id: 'Region-node-' + Math.random().toString(36).substr(2, 9), // Generate unique ID
					childName: 'Region',
					parentName: 'Region',
					nodeType: 'location',
					imageName: "./images/Region_light-bg.png",
					
					serviceDR: 'Region',
					serviceName: 'Region',
					subServiceName: 'Region',
					serviceFamily: 'Region',
					famImage: './images/Region_light-bg.png',
					serImage: './images/Region_light-bg.png',
					allSubService: JSON.stringify([]), // Change this to an empty array
					costParent: 'Region',
					description: 'Region',
					fields: [{
						"value": "",
						"description": "",
						"name": "Region",
						"required": "False",
						"type": "string"
					}], // Ensure this is not an array, just a string
					containsService: true, // This should be a boolean
					position: { x: 0, y: 0 }, // Initial position (will be updated on drop)
				};
	
				console.log("account service data", serviceData); // Log the serviceData object
				event.dataTransfer.setData('application/json', JSON.stringify(serviceData));
				event.dataTransfer.effectAllowed = 'move'; // Allow move effect
			}
	
			// The following line is incorrect; it should reference the variable serviceData directly
			// console.log("account service data".serviceData);
			};

		const handleTabChange = (tab) => {
			setActiveTab(tab);
			setIACModelOpen(true)

		};
		  
	return (
		<>
		
		<div className={`col-md-3 mt-3 ps-3 pe-1 content-left-container ${isCollapsed ? 'collapsed' : ''}`}
			style={{position:"relative",zIndex:10}}
			>
			<div className="recommended-container recommended-container-height pe-3" style={{marginRight:"-16px"}}>
				{!isCollapsed ? 
				<>
				<div className="logo-container d-flex justify-content-between align-items-center">
				<img 
    src={providerName.toLowerCase() === 'aws' ? 
        (isDarkMode ? 'images/aws-logo-dark.svg' : 'images/aws-logo-big.svg') : 
        `"images/azure-logo.svg"`} 
		alt="aws" 
/>				<button id="navCollapseBtn" class="border-0 bg-transparent"><img src="images/double-arrow.svg" alt="arrow"  onClick={toggleSidebar}/></button>
				</div>

				{/* Search input */}
				<div className="input-group input-search custom-border-box custom-search-box my-4">
					<input 
						type="search"
						className="px-3 form-control common-bg border-0 custom-form custom-search font-14 font-medium black-v2"
						placeholder="Search"
						value={sideSearch}
						onChange={handleSearchChange}
					/>
					<button className="btn border-start-0 custom-form custom-search " type="button">
						<img src="images/search-blue.svg" alt="search"/>
					</button>
				</div>
				<div>
            <div className="d-flex border rounded-3 hidetxt">
			<button type="button" class="button primary-button text-nowrap px-3"
			 style={{width: '100%'}}
			onClick={(()=>{
				//SQ_NO_2.49 set state of setDataConfigModal(true) to overlap Data Configuration component 
				 setDataConfigModal(true)})} >
				Data Configuration
			</button>
			{/* {dataConfiguration &&
                <DataConfiguration
				dataConfiguration={dataConfiguration}
				setDataConfigModal={setDataConfigModal} 
				/>} */}
               
            </div>

            {/* Conditionally render LocalsOutputsComponent based on active tab */}
           
        </div>

				{/* Assign Tags button */}
					

				{/* Environment dropdown */}
				<div className="my-4 d-flex align-items-center">
					<label className="form-label font-16 font-medium black-v2 mb-0 lh-0 me-4" htmlFor="Runtime-223">
						Environment
					</label>
					<select 
						className="form-select custom-dropdown arch-border shadow-none 
            custom-border-box 
            py-2 
            font-14 font-medium black-v2 custom-select"
						id="Runtime-223"
						value={environmentID}
						onChange={(e) => {
							handleEnvironmentChange(e)
							}
							}
					>
						<option value="">Select Environment</option>
						{environments.map((env) => (
							<option key={env.environment_id} value={env.environment_id} name= {env.environment_name}>{env.environment_name}</option>
						))}	
					</select>
				</div>
				<div className="mb-4 d-flex align-items-center hidetxt">
    <label className="form-label font-16 font-medium black-v2 secondary-text-color-v4 mb-0 lh-0 me-4 flex-shrink-0 w-25"
        htmlFor="Runtime-223">Module</label>
 <select
    className="form-select custom-dropdown arch-border shadow-none custom-border-box py-2 font-16 font-medium black-v2 custom-select"
    aria-label="Default select example" 
    id="Runtime-223"
    value={selectedModule}
    onChange={(e) => {
        setSelectedModule(e.target.value); // Update state with selected module
    }}
>
    <option value="cloudgen">Cloudgen</option>
    <option value="aws">AWS</option>
</select>
</div>

				{/* Services section */}
				<div className="mt-5" ref={accordionRef}>
					<h6 className="font-18 font-bold black-v2">Services</h6>


					{!isLoading && !error && (
						<ul className="nav nav-pills flex-column mb-auto flex-nowrap nav-overflow custom-nav-scrl pe-2"
						style = {{ cursor: "pointer" }}
						>
							{/* Account category */} 
							<React.Fragment>
							<li className={`side-nav-item-rec side-nav-drop d-flex align-items-center 
                                justify-content-between 
                                 my-3
                                ${expandedCategories.includes('Account') ? 'show' : 'collapsed'}`
                                }

                                    onClick={() => toggleCategory('Account')}>
                                    <span className="d-flex align-items-center justify-content-between w-100"
                                        // data-bs-toggle="dropdown" 
                                        aria-expanded="false">
                                        <span className="d-flex align-items-center gap-2">
                                            <img
                                                src="./images/Workbench/ServiceFamilyImages/sidenav-application.svg"
                                                alt="Account"
                                                className="me-2 dark-icons"
                                                style={{ width: '18px', height: '16px', cursor: "pointer" }}
                                            />
                                            <span className="font-14 font-medium black-v2">Account</span>
                                        </span>
                                        <span className="acc-btn">
                                            <img src="images/rec-acc-icon.svg" alt="list-arrow" className="list-arrow side-icon" />
                                        </span>
                                    </span>
                                </li>
                                <li className={`sub collapse ${expandedCategories.includes('Account') ? 'show' : ''}`}
                                >
                                    <div className="sub-list list-unstyled font-12 font-medium mb-32">
                                        <div className="row mt-33">
                                            <div className="col-md-6 px-2">
                                                <div
                                                    className="font-8 color-black font-regular mb-0 custom-border-box d-flex justify-content-center align-items-center flex-column py-2 flex-column gap-2 text-decoration-none"
                                                    draggable={true}
                                                    onDragStart={(e) => {
                                                        if (viewMode == false) {
                                                            onDragStartAccount(e)
                                                        }
                                                    }}

                                                    disabled={viewMode}
                                                    style={viewMode ? { cursor: "not-allowed", opacity: "0.2" } : { cursor: "pointer" }}
                                                >
                                                    <img
                                                        src="./images/Workbench/ServiceImages/AWS/Security/AWS-Organizations_Account_light-bg.png"
                                                        alt="Account"
                                                        style={{ width: '35px', height: '35px' }}
                                                    />
                                                    Account
                                                </div>
                                            </div>
                                            <div className="col-md-6 px-2">
                                                <div
                                                    className="font-8 color-black font-regular mb-0 custom-border-box d-flex justify-content-center align-items-center flex-column py-2 flex-column gap-2 text-decoration-none"
                                                    draggable={true}
                                                    onDragStart={(e) => {
                                                        if (viewMode == false) {
                                                            onDragStartRegion(e)
                                                        }
                                                    }}

                                                    disabled={viewMode}
                                                    style={viewMode ? { cursor: "not-allowed", opacity: "0.2" } : { cursor: "pointer" }}
                                                >
                                                    <img
                                                        src="./images/Region_light-bg.png"
                                                        alt="Account"
                                                        style={{ width: '35px', height: '35px' }}
                                                    />
                                                    Region
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>

							</React.Fragment>
							{isLoading && <p>Loading services...</p>}
							{error && <p className="error-message">{error}</p>}
						{services.map((serviceCategory, index) => (
							<React.Fragment key={index}>
								<li className={`side-nav-item-rec side-nav-drop d-flex
								 align-items-center 
								 my-3
								justify-content-between ${expandedCategories.includes(serviceCategory.serviceCategory) ? 'show' : 'collapsed'}`}
									style = {{ cursor: "pointer" }}
									onClick={() => toggleCategory(serviceCategory.serviceCategory)}>
									<span className="d-flex align-items-center justify-content-between w-100"

										aria-expanded="false">
										<span className="d-flex align-items-center gap-2">
											<img 
												src={serviceCategory.serviceCategoryImage}
												alt={serviceCategory.serviceCategory}
												className="me-2 dark-icons"
												style={{ width: '18px', height: '16px', cursor: "pointer" }}

												onError={(e) => {
													e.target.onerror = null;
													e.target.src = 'images/default-category-icon.svg';
												}}
											/>
											<span className="font-14 font-medium black-v2">{serviceCategory.serviceCategory}</span>
										</span>
										<span className="acc-btn">
											<img src="images/rec-acc-icon.svg" alt="list-arrow" className="list-arrow side-icon" />
										</span>
									</span>
								</li>
								<li className={`sub collapse ${expandedCategories.includes(serviceCategory.serviceCategory) ? 'show' : ''}`}>
									<div className="sub-list list-unstyled font-12 font-medium mb-32">
										<div className="row mt-33">
										{serviceCategory.services.map((service, serviceIndex) => (
    <div key={serviceIndex} className="col-md-4 px-22">
        <div
            className="font-8 color-black font-regular mb-0 custom-border-box d-flex justify-content-center align-items-center flex-column py-2 flex-column gap-2 text-decoration-none"
            draggable={true}
            onDragStart={(e) => {
                if(viewMode == false){
                    onDragStart(e, serviceCategory, service);
                }
            }}
            disabled={viewMode}
            style={viewMode ? { cursor: "not-allowed", opacity: "0.2" } : { cursor: "pointer" }}
            title={service.serviceName.length > 15 ? service.serviceName : ''}
        >
            <img 
                src={service.serviceImage}
                alt={service.serviceName}
                style={{ width: '35px', height: '35px' }}
                onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = 'images/default-service-icon.svg';
                }}
            />
            <span>{service.serviceName.length > 15 ? `${service.serviceName.substring(0, 15)}...` : service.serviceName}</span>
        </div>
    </div>
))}
										</div>
									</div>
								</li>
								</React.Fragment>
						))}
						</ul>
					)}
				</div>
				</> :
				// Collapsed View
				<>
				<div className="logo-container justify-content-between align-items-center" style={{display:"block",marginLeft:"-10px"}}>
				<img 
    src={providerName.toLowerCase() === 'aws' ? 
        (isDarkMode ? 'images/aws-logo-dark.svg' : 'images/aws-logo-big.svg') : 
        `"images/azure-logo.svg"`} 
		alt="aws" 
/>
				<button id="navCollapseBtn" class="border-0 bg-transparent"><img src="images/double-arrow.svg" alt="arrow" onClick={toggleSidebar} style={{marginTop:"2px"}}/></button>
				</div>	 
				<div className="input-group input-search custom-search-box my-4">
					<button className="btn border-start-0 custom-form custom-search " type="button" style={{ marginLeft: '-11px' }}>
						<img src="images/search-blue.svg" alt="search" />
					</button>
				</div>
				<div></div>
				<div className="mt-3" ref={accordionRef}>
						<ul className="nav nav-pills flex-column mb-auto flex-nowrap nav-overflow custom-nav-scrl2 pe-2"
						style = {{ cursor: "pointer"}}
						>
							{/* Account category */} 
							<React.Fragment>
								<li className={`side-nav-item-rec side-nav-drop d-flex align-items-center 
								justify-content-between 
								 my-3
								`
								}
									onClick={() => toggleCategory('Account')}>
									<span className="d-flex align-items-center justify-content-between w-100"
										// data-bs-toggle="dropdown" 
										aria-expanded="false">
										<span className="d-flex align-items-center gap-2">
											<img 
												src="./images/Workbench/ServiceFamilyImages/sidenav-application.svg"
												alt="Account"
												className="me-2 dark-icons"
												style={{ width: '18px', height: '16px',cursor: "pointer" }}
											/>
										</span>
										
									</span>
								</li>
								
							</React.Fragment>
							
						{services.map((serviceCategory, index) => (
							<React.Fragment key={index}>
								<li className={`side-nav-item-rec side-nav-drop d-flex
								 align-items-center 
								 my-3
								justify-content-between `}
									style = {{ cursor: "pointer" }}
									onClick={() => toggleCategory(serviceCategory.serviceCategory)}>
									<span className="d-flex align-items-center justify-content-between w-100"

										aria-expanded="false">
										<span className="d-flex align-items-center gap-2">
											<img 
												src={serviceCategory.serviceCategoryImage}
												alt={serviceCategory.serviceCategory}
												className="me-2 dark-icons"
												style={{ width: '18px', height: '16px', cursor: "pointer" }}

												onError={(e) => {
													e.target.onerror = null;
													e.target.src = 'images/default-category-icon.svg';
												}}
											/>
										</span>
										
									</span>
								</li>
								
								</React.Fragment>
						))}
						</ul>
					
				</div>


				</>}
			</div>

		</div>
		
		 </>
	);
}
