
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default async function Client(endpoint, payload, method, options) {


	//const navigate=useNavigate()

	let jwtToken = localStorage.getItem('jwt'); // Retrieve the JWT from local storage

	// Perform Conditional Check Over here
	if (!jwtToken) {

		// Navigate to Login Page 
	}

	// Retreive the User id from the Local storage and Add Dynamicallly in hte Payload
	if (payload) {
		// retrerive from the local stroage and add Dynamically here
		let userId = localStorage.getItem('userId')

		payload["userId"] = userId
	}

	const config = {
		method: method,
		url: endpoint,
		headers: {
			'Content-Type': 'application/json', 'Access-Control-Allow-Origin': "*", "Access-Control-Allow-Header": "*",
		},
	};;
	console.log(config,"config");
	// Include the JWT in the headers if available
	if (jwtToken) {
		config.headers['Authorization'] = `Bearer ${jwtToken}`;
	}

	// Include the payload for POST or PUT requests
	if (method === 'POST' || method === 'PUT') {
		config.data = payload; // Axios uses 'data' for request body
	}

	try {
		console.log(config, "Client -- before", config.url)
		const response = await axios(config);
		console.log(response, "Client -- after")


		// "token expired."
		// "token is missing."
		// "token invalid"

		
		return response; // Return the data from the response
	} catch (error) {
		console.error('Error in Client function:', error);
		console.log(error.response.data.message,"statement")
		if (error.response.data.message == "token expired." || error.response.data.message == "token invalid") {
			window.location.href = '/';
			localStorage.clear()
			sessionStorage.clear()

		}




		throw error; // Rethrow or handle the error as necessary
	}
}
