import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getOrganizationDetailsByIdApi } from "../../Service/api";
import { OrgContext } from "../../ContextProvider/OrgContext";

const SideBar = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [pending, setPendingCount] = useState("");
  const { state } = useLocation();
  const [orgDetails, setOrgDetails] = useState(null);
  const { orgId , pendingCount} = useContext(OrgContext);
  
  // State to manage dropdown visibility
  const [isCollapseOpen, setCollapseOpen] = useState(false);

  // Check if user is admin
  const isAdmin = localStorage.getItem("isAdmin") === "true";

  useEffect(() => {
    const fetchOrgDetails = async () => {
      try {
        const userId = localStorage.getItem('userId');
        const orgId = localStorage.getItem('orgId');
    
        if (!userId || !orgId) {
          console.error('userId or orgId not found in localStorage');
          return; // Exit the function if either is missing
        }
    
        const payload = { 
          orgId: orgId,
          userId: userId
        };
    
        const response = await getOrganizationDetailsByIdApi(payload);
        console.log(response, "Response from getOrganizationDetailsByIdApi");
        setOrgDetails(response.data);
      } catch (error) {
        console.error("Error fetching organization details:", error);
      }
    };

    fetchOrgDetails();
  }, [orgId]);

  console.log(props.pendingCount, "this is the props");

  useEffect(() => {}, [state]);

  const handleNavigation = (path) => {
    navigate(path);
  };

  const isActive = (path) => {
    return location.pathname === path ? "active" : "";
  };
  
  
  const toggleCollapse = () => {
    setCollapseOpen((prev) => !prev);
  };

  return (
      <>
          {/* sidebar-menu start */}
          <div className="col-md-3 custom-border-right custom-white ps-5 pe-1 pt-2">
            <div className="about-page-title pt-4">
              <div className="d-flex align-items-center mb-3 mb-md-0 ">
                <a onClick={() => handleNavigation("/manage-organizations")}>
                  <img
                    src="images/back-arrow.svg"
                    alt="back-arrow"
                    title="Back"
                    className="me-3 icon-filter"
                  />
                </a>
                {orgDetails && (
                  <>
                    <img
                      src={orgDetails.logo}
                      alt="partner-logo"
                      className="partner-logo icon-width-48"
                    />
                    <h2 className="font-20 font-bold black-v2 mb-0 ms-3 ">
                      {orgDetails.orgName}
                    </h2>
                  </>
                )}
              </div>
            </div>
            <ul className="nav nav-pills flex-column mb-auto flex-nowrap nav-overflow mt-3 pt-2">
              <li className={`side-nav-item d-flex align-items-center ${isActive("/CostDashboard")}`} onClick={() => handleNavigation("/CostDashboard")}>
                <img src="images/dashboard-icon.svg" alt="Dashboard" className="side-icon" />
                <span>Dashboard</span>
              </li>
              <li className={`side-nav-item d-flex align-items-center ${isActive("/WorkloadsGrid")}`} onClick={() => handleNavigation("/WorkloadsGrid")}>
                <img src="images/workload-icon.svg" alt="Workloads" className="side-icon" />
                <span>Workloads</span>
              </li>
              <li className={`side-nav-item d-flex align-items-center ${isActive("/B2AGrid")}`} onClick={() => handleNavigation("/B2AGrid")}>
                <img src="images/b2a-icon.svg" alt="B2A" className="side-icon" />
                <span>B2A</span>
              </li>
              <li className={`side-nav-item d-flex align-items-center ${isActive("/inventory")}`} onClick={() => handleNavigation("/inventory")}>
                <img src="images/inventry-icon.svg" alt="Inventory" className="side-icon" />
                <span>Inventory</span>
              </li>
              <li className={`side-nav-item side-nav-drop d-flex align-items-center justify-content-between ${isCollapseOpen ? '' : 'collapsed'}`} onClick={toggleCollapse}>
                <span className="d-flex align-items-center w-100">
                  <span className="d-flex align-items-center gap-2">
                    <img src="images/cost-management-icon.svg" alt="cost-management-icon" className="side-icon" />
                    <span>Cost Management</span>
                  </span>
                  <img src="images/drop-down-arrow.svg" alt="list-arrow" className="list-arrow ms-2 side-icon" />
                </span>
              </li>
              <li className={`side-sub-nav sub ${isCollapseOpen ? 'show' : ''} collapse px-4 bg-primary-v2`} id="contentGenList">
                <ul className="sub-list list-unstyled ps-4 font-12 font-medium bg-primary-v2">
                  <li className={`cursor-pointer sub-menu-list py-3 text-nowrap ${isActive("/threshold")}`} onClick={() => handleNavigation("/thresholdGridPage")}>
                    Budget
                  </li>
                  <li className={`cursor-pointer sub-menu-list py-3 text-nowrap ${isActive("/invoice")}`} onClick={() => handleNavigation("/invoicesPage")}>
                    Invoices
                  </li>
                </ul>
              </li>
              <li className={`side-nav-item d-flex align-items-center ${isActive("/providerConfiguration")}`} onClick={() => handleNavigation("/providerConfiguration")}>
                <img src="images/provider-conf-icon.svg" alt="provider-conf-icon" className="side-icon" />
                <span>Provider Configurations</span>
              </li>
              {isAdmin && (
                <li className={`side-nav-item d-flex align-items-center ${isActive("/ApprovalGrid")}`} onClick={() => handleNavigation("/ApprovalGrid")}>
                  <img src="images/approval-icon.svg" alt="approval-icon" className="side-icon" />
                  <span className="d-flex justify-content-center align-items-center">
                    Approvals <span className="notify-content ms-2">{pendingCount}</span>
                  </span>
                </li>
              )}
              <li className={`side-nav-item d-flex align-items-center ${isActive("/about")}`} onClick={() => handleNavigation("/about")}>
                <img src="images/about-icon.svg" alt="about-icon" className="side-icon" />
                <span>About</span>
              </li>
            </ul>
          </div>
      </>
          
  );
};
 

export default SideBar;