import React, { useEffect, useState } from 'react';
import MonacoEditor from 'react-monaco-editor';

const EditOutputPopup = ({ isOpen, onClose, onSave, selectedOutput }) => {
    const [outputData, setOutputData] = useState({ name: '', description: '', snippet: '', sensitive: false });
    const [key, setKey] = useState(Date.now); // Force re-render of MonacoEditor

    useEffect(() => {
        if (selectedOutput) {
            setOutputData(selectedOutput);
        } else {
            setOutputData({ name: '', description: '', snippet: '', sensitive: false });
        }
    }, [selectedOutput]);

      useEffect(() => {
            if (isOpen) {
              // Force re-render by changing the key
              setKey(Date.now());
            }
          }, [isOpen]);

    const handleSave = () => {
        onSave(outputData);
        onClose();
    };

    return (
        <div className={`modal fade ${isOpen ? 'show' : ''}`} style={{ display: isOpen ? 'block' : 'none' }} aria-hidden={!isOpen}>
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content border-0">
                    <div className="modal-header border-0 py-4 px-32">
                        <h1 className="modal-title font-20 font-bold black-v2">Edit Output</h1>
                        <button type="button" className="btn shadow-none p-0" onClick={onClose} aria-label="Close">
                            <img src="images/close-icon.svg" alt="close-icon" className="icon-hover shadow-none modal-close-filter" />
                        </button>
                    </div>
                    <div className="modal-body px-32">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row mb-4">
                                    <div className="col-md-6 mb-4 px-2">
                                        <label className="form-label font-14 font-medium black-v2" htmlFor="name">Name</label>
                                        <input 
                                            type="text" 
                                            className="form-control custom-dropdown shadow-none" 
                                            id="name" 
                                            placeholder="Enter the Name"
                                            value={outputData.name}
                                            onChange={(e) => setOutputData({ ...outputData, name: e.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-6 mb-4 px-2">
                                        <label className="form-label font-14 font-medium black-v2">Sensitive</label>
                                        <div className="form-check form-switch mt-2">
                                            <input 
                                                className="form-check-input custom-switcher" 
                                                type="checkbox" 
                                                role="switch"
                                                checked={outputData.sensitive}
                                                onChange={() => setOutputData({ ...outputData, sensitive: !outputData.sensitive })}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-12 mb-4 px-2">
                                        <label className="form-label font-14 font-medium black-v2" htmlFor="desc">Description</label>
                                        <textarea 
                                            className="form-control custom-form custom-dropdown article-grey-colorarea font-14 font-medium" 
                                            placeholder="Enter Description" 
                                            id="desc"
                                            value={outputData.description}
                                            onChange={(e) => setOutputData({ ...outputData, description: e.target.value })}
                                        />
                                    </div>
                                    <label className="form-label font-14 font-medium black-v2">Values</label>
<div className=" generate-code-container2 mb-2 col-md-12">
                                        <MonacoEditor
                                                                                     key={key}
                                           width="100%"
                                           height="100"
                                                                                       language="javascript"
                                            value={outputData.snippet}
                                            onChange={(newValue) => setOutputData({ ...outputData, snippet: newValue })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-end align-items-center mb-2 mt-32 gap-3">
                            <button type="button" className="button outline-button text-nowrap py-12" onClick={onClose}>Cancel</button>
                            <button type="button" className="button primary-button text-nowrap px-32 py-12" onClick={handleSave}>Update</button>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    );
};

export default EditOutputPopup;
