//Importing the packages and context and api
import React, { useContext, useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { OrgContext } from '../../ContextProvider/OrgContext';
import { getCostByPeriod } from '../../Service/api';
import moment from 'moment';
import { DashboardContext } from './Context/DashboardContext';
//Declare a componenet
const CostByPeriod = ({ selectedPeriodProviders, period, fromDate, toDate }) => {
  //PS_02 Get orgId from context
  const {orgId} = useContext(OrgContext)
  //PS_01 Initialise state variables
  const [data, setData] = useState([]);
  const [highestCost, setHighestCost] = useState(0);
  const [error, setError] = useState(null);
  //PS_03 Get period data from context
  const {periodE,setperiodE} = useContext(DashboardContext)
//PS_15 Defining the styles for no data found
  const noDataStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    margin: 0,
    fontSize: '14px',
    color: '#333',
  };
//PS_04 To PS_14 Useeffect to trigger 
  useEffect(() => {
    //Declare a fnction to fetch the period data
    const fetchData = async () => {
      setError(null);
      //Get userId from session storage
      const userId = localStorage.getItem('userId');
    
      // Parse and format dates
      const formattedFromDate = fromDate ? moment(fromDate, 'DD-MM-YYYY').format('YYYY-MM-DD') : '';
      const formattedToDate = toDate ? moment(toDate, 'DD-MM-YYYY').format('YYYY-MM-DD') : '';
    //Set the payload
      const payload = {
        userId: userId,
        orgId: orgId,
        filterCloudProvider: selectedPeriodProviders, // This is now a string, either a provider ID or empty
        filterConnector: "",
        filterPeriod: period,
        filterStartDate: formattedFromDate,
        filterEndDate: formattedToDate
      };
    //Call the api
      try {
        const response = await getCostByPeriod(payload);
    //Update the variable with the fetched response
        if (response.data && response.data.body && response.data.body.success) {
          const transformedData = response.data.body.data.map(item => ({
            ...item,
            cost: item.cost ? parseFloat(item.cost) : 0,
            // Add any other fields that need to be parsed here
          }));
          setData(transformedData);
          //Map the values
          let transformDatasForPerion = transformedData?.map((values,index) =>{
            return {
              "Period": values?.period,
              "Actual_Cost": values?.costForAws ? values?.costForAws : values?.costForAzure ?  values?.costForAzure : 0
            }
          })
          //Update the varibales
          setHighestCost(response.data.body.highestCost ? parseFloat(response.data.body.highestCost) : 0);
          setperiodE(transformDatasForPerion)
        } else {
          const errorMessage = response.data?.body?.message || "Failed to fetch data";
         console.log("Unexpected response structure:", response);
          setError(errorMessage);
        }
        //Catch the error if any
      } catch (error) {
       console.log('Error fetching cost by period data:', error);
        setError("An error occurred while fetching data");
      }
    }
//Calling fetchData() in useeffect
    fetchData();
  }, [orgId, selectedPeriodProviders, period, fromDate, toDate]);

  return (
    <div style={{ width: '100%', height: 220, minWidth: '300px' }}>
      <ResponsiveContainer>
        {error ? (
          <div style={noDataStyle}>
            <p>No data found</p>
          </div>
        ) : data.length > 0 ? (
          <BarChart
            data={data}
            margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="period" />
            <YAxis domain={[0, highestCost]} tick={{fontSize: 10}}/>
            <Tooltip />
            <Bar dataKey="costForAws" fill="#9FE0E9" name="AWS"  barSize={35} />
            <Bar dataKey="costForAzure" fill="#EFDC8F" name="Azure"   barSize={35}/>
          </BarChart>
        ) : (
          <div style={noDataStyle}>
            <p>No data found</p>
          </div>
        )}
      </ResponsiveContainer>
    </div>
  );
};

export default CostByPeriod;