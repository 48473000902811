//Importing the packages , context and api
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ComposedChart } from 'recharts';
import { getCostByProvider } from '../../Service/api';
import { OrgContext } from '../../ContextProvider/OrgContext';
import moment from 'moment';
import { DashboardContext } from './Context/DashboardContext';
//PS_01 Decalre a component
const CostByProvider = ({ selectedProviderId, selectedConnectors, fromDate, toDate, selectedGroupBy }) => {
    //Initialise the state  variables
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [showFullGraph, setShowFullGraph] = useState(true);
    const scrollContainerRef = useRef(null);
    //PS_02 Getting providers from context
    const {providers, setProviders} = useContext(DashboardContext)
    //PS_03 Getting the organization id from context
    const { orgId } = useContext(OrgContext);
    //PS_04 Getting userId from local storage
    const userId = localStorage.getItem('userId');
    //Defining styles for no data found
    const noDataStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        margin: 0,
        fontSize: '14px',
        color: '#333',
    };
//PS_05 to PS_18 Use effect to trigger the data
    useEffect(() => {
        //Declare a function named fetchData to get provider data
        const fetchData = async () => {
            setError(null);
            try {
                //Formatting date
                const formattedFromDate = fromDate ? moment(fromDate, 'DD-MM-YYYY').format('YYYY-MM-DD') : '';
                const formattedToDate = toDate ? moment(toDate, 'DD-MM-YYYY').format('YYYY-MM-DD') : '';
                //Update the variables
                setShowFullGraph(!formattedFromDate && !formattedToDate);
                //Set the payload
                const payload = {
                    userId: userId,
                    orgId: orgId,
                    providerId: selectedProviderId,
                    connectorId: selectedConnectors.map(c => c.connectorId),
                    from: formattedFromDate,
                    to: formattedToDate,
                    groupBy: selectedGroupBy.map(g => g.workloadId)
                };
                //calling api
                const response = await getCostByProvider(payload);

                if (response.data && response.data.success) {
                    //Mapping the response
                    const transformedData = response.data.data.map(item => {
                        const actual = item.cost ? parseFloat(item.cost) : 0;
                        const actualBudget = item.budget ? parseFloat(item.budget) : 0;
                        const budget = showFullGraph ? Math.max(0, actualBudget - actual) : 0;
                        //Setting the data after parsing
                        return {
                            name: item.workloadName,
                            actual: actual,
                            budget: budget,
                            actualBudget: actualBudget,
                            total: showFullGraph ? actual + budget : 0
                        };
                    });
                    //Mapping the provider data
                    let providerData = transformedData?.map((value,index) => {
                        return {
                            Providers: value?.name ? value?.name : "",
                            Budget: value?.budget ? value?.budget : 0,
                            Actual_Cost: value?.actualBudget ? value?.actualBudget : 0 
                          }
                    });
                    //Updating the state variables
                    setProviders(providerData)
                    setData(transformedData);
                } else {
                    setError(response.data?.message || 'Failed to fetch data');
                }
                //Catch errors if any
            } catch (err) {
                setError('Error fetching data: ' + (err.response?.data?.message || err.message));
            } 
        };
        //Call fetchData()
        fetchData();
    }, [userId, orgId, selectedProviderId, selectedConnectors, fromDate, toDate, selectedGroupBy]);

    if (error) return <div style={noDataStyle}><p>No data found</p></div>;
    //If length is zero show no data.
  if (data.length === 0) {
    return <div style={noDataStyle}><p>No data found</p></div>;
  }
//PS_19 & PS_20 Define a  function for hovering scenario
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip" style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
                    <p className="label">{`${label}`}</p>
                    <p className="intro">{`Actual Cost: $${payload[0].value.toFixed(2)}`}</p>
                    <p className="intro">{`Actual Budget: $${payload[0].payload.actualBudget.toFixed(2)}`}</p>
                    {showFullGraph && <p className="intro">{`Remaining Budget: $${payload[1].value.toFixed(2)}`}</p>}
                    {showFullGraph && <p className="intro">{`Total: $${payload[2].value.toFixed(2)}`}</p>}
                </div>
            );
        }
        return null;
    };

    if (error) return <div>No data found</div>;

    return (
        <div style={{ width: '100%', height: 300, minWidth: '300px', position: 'relative' }}>
            <div 
                ref={scrollContainerRef}
                style={{ 
                    width: '100%', 
                    height: '100%', 
                    overflowX: 'auto',
                    overflowY: 'hidden'
                }}
            >
                
                    <div style={{ width: `${data.length * 200}px`, height: '100%' }}>
                        <ResponsiveContainer width="100%" height="100%">
                            <ComposedChart
                                data={data}
                                margin={{
                                    top: 20,
                                    right: 30,
                                    left: 20,
                                    bottom: 65,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis
                                    dataKey="name"
                                    angle={-40}
                                    textAnchor="end"
                                    height={60}
                                    interval={0}
                                    tick={{ fontSize: 12 }}
                                    tickFormatter={(name) => name.length > 9 ? `${name.slice(0, 6)}...` : name}
                                />
                                <YAxis tick={{fontSize: 10}}/>
                                <Tooltip content={<CustomTooltip />} />
                                <Bar dataKey="actual" stackId="a" fill="#AFD492" name="Actual Cost" barSize={35}/>
                                {showFullGraph && <Bar dataKey="budget" stackId="a" fill="#FF9C9C" name="Remaining Budget" barSize={35}/>}
                                {showFullGraph && 
                                    <Line 
                                        type="linear" 
                                        dataKey="total" 
                                        stroke="#60737C" 
                                        strokeWidth={2} 
                                        dot={{ r: 4, fill: "#6f42c1" }} 
                                        activeDot={{ r: 8 }}
                                    />
                                }
                            </ComposedChart>
                        </ResponsiveContainer>
                    </div>
              
            </div>
        </div>
    );
};

export default CostByProvider;