
// this HTML is for Azure Container Apps
export default function Azure_Open_AI({ values, onChangeOfConfiguration, isViewer }) {
    try {
        console.log("sagin aws S3",values,"34535345")
        return (
            <div id={`${values.instanceName.split(" ").join("_")}`} className="accordion-collapse collapse">
                <div className="accordion-body p-0 ">
                    <table className="table table-borderless cg-grid font-14 font-semibold mb-0">
                        <tbody>
                            <tr className="ms-acc-data-align">
                                <td colSpan={3}>
                                    <div className="row py-4 mx-4 px-5" hidden={(values.isPriceAvailable == "No") ? false : true}>
                                        <div className="col-md-6 px-3"><span className="font-semibold font-14 text-danger">{values.isPriceAvailable == "No" ? "The Price is not available for the region / the configuration that you have selected might be not available in the region" : null}</span></div>
                                    </div>
                                    
                                    <div className="row py-4 mx-4 px-5">
                                        <div className="col-md-6 px-3">
                                            <label htmlFor="region" className="form-label font-14 font-medium black-v2 mb-3">
                                                Region
                                            </label>
                                            <select
                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                id="region"
                                                value={values.region}
                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                name={"region"}
                                                onChange={(e) => {
                                                    onChangeOfConfiguration(e, values.instanceName);
                                                }} disabled={isViewer}
                                            >
                                               <optgroup label="United States">
                                                    <option value="us-east" class="">East US</option>
                                                    <option value="us-east-2" class="">East US 2</option>
                                                    <option value="us-north-central" class="">North Central US</option>
                                                    <option value="us-south-central" class="">South Central US</option>
                                                    <option value="us-west" class="">West US</option>
                                                    <option value="us-west-3" class="">West US 3</option>
                                               </optgroup>
                                               <optgroup label="United Kingdom">
                                                    <option value="united-kingdom-south" class="">UK South</option>
                                               </optgroup>
                                               <optgroup label="United Arab Emirates">
                                                    <option value="uae-north" class="">UAE North</option>
                                               </optgroup>
                                               <optgroup label="Switzerland">
                                                    <option value="switzerland-north" class="">Switzerland North</option>
                                                    <option value="switzerland-west" class="">Switzerland West</option>
                                               
                                               </optgroup>
                                               <optgroup label="Sweden">
                                                    <option value="sweden-central" class="">Sweden Central</option>
                                               
                                               </optgroup>
                                               <optgroup label="Poland">
                                                    <option value="poland-central" class="">Poland Central</option>
                                               
                                               </optgroup>
                                               <optgroup label="Norway">
                                                    <option value="norway-east" class="">Norway East</option>
                                               
                                               </optgroup>
                                               <optgroup label="Mexico">
                                                    <option value="mexico-central" class="">Mexico Central</option>
                                               
                                               </optgroup>
                                               <optgroup label="Korea">
                                                    <option value="korea-central" class="">Korea Central</option>
                                               
                                               </optgroup>
                                               <optgroup label="Japan">
                                                    <option value="japan-east" class="">Japan East</option>
                                               
                                               </optgroup>
                                               <optgroup label="India">
                                                    <option value="south-india" class="">South India</option>
                                               
                                               </optgroup>
                                               <optgroup label="Germany">
                                                    <option value="germany-west-central" class="">Germany West Central</option>
                                               
                                               </optgroup>
                                               <optgroup label="France">
                                                    <option value="france-central" class="">France Central</option>
                                               
                                               </optgroup>
                                               <optgroup label="Europe">
                                                    <option value="europe-west" class="">West Europe</option>
                                               
                                               </optgroup>
                                               <optgroup label="Canada">
                                                    <option value="canada-central" class="">Canada Central</option>
                                               
                                                <option value="canada-east" class="">Canada East</option>
                                               
                                               </optgroup>
                                               <optgroup label="Brazil">
                                                    <option value="brazil-south" class="">Brazil South</option>
                                               
                                               </optgroup>
                                               
                                               <optgroup label="Australia">
                                                    <option value="australia-east" class="">Australia East</option>
                                               
                                               </optgroup>
                                               <optgroup label="Africa">
                                                    <option value="south-africa-north" class="">South Africa North</option>
                                               
                                               </optgroup>

                                            
                                            </select>
                                        </div>

                                        <div className="col-md-6 px-3">
                                            <label htmlFor="selectedModelSeries" className="form-label font-14 font-medium black-v2 mb-3">
                                            Model type
                                            </label>
                                            <select
                                                className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                id="selectedModelSeries"
                                                value={values.selectedModelSeries}
                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                name={"selectedModelSeries"}
                                                onChange={(e) => {
                                                    onChangeOfConfiguration(e, values.instanceName);
                                                }} disabled={isViewer}
                                            >
                                               <option value="language-models" class="">Language Models</option>
                                               <option value="assistants-api" class="">Assistants API</option>
                                               <option value="base-models" class="">Base Models</option>
                                               <option value="fine-tuning-models" class="">Fine-tuning Models</option>
                                               <option value="image-models" class="">Image Models</option>
                                               <option value="embedding-models" class="">Embedding Models</option>
                                               <option value="speech-models" class="">Speech Models</option>
                                               
                                            </select>
                                        </div>
                                        {values.selectedModelSeries === "language-models" ? (
                                            <>
                                                <div className="col-md-6 px-3">
                                                    <label htmlFor="modelForLanguage" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Model 
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                        id="modelForLanguage"
                                                        value={values.modelForLanguage}
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        name={"modelForLanguage"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}
                                                    >
                                                    <option value="gpt4o-global-deployment" class="">GPT-4o-Global-Deployment-128K</option>
                                                    <option value="gpt4o-regional-api" class="">GPT-4o-Regional-API-128K</option>
                                                    <option value="gpt-turbo-sixteenk-0125" class="">GPT-3.5-Turbo-0125-16K</option>
                                                    <option value="gpt-turbo-instruct" class="">GPT-3.5-Turbo-Instruct-4K</option>
                                                    <option value="gptfour-onetwentyeightk" class="">GPT-4-Turbo-128K</option>
                                                    <option value="gptfour-turbovision-onetwentyeightk" class="">GPT-4-Turbo-Vision-128K</option>
                                                    <option value="gptfour-eightk" class="">GPT-4-8K</option>
                                                    <option value="gptfour-thirtytwok" class="">GPT-4-32K</option>
                                                    <option value="gpt-turbo-fourk-0301" class="">GPT-3.5-Turbo-0301-4K</option>
                                                    <option value="gpt-turbo-fourk-0613" class="">GPT-3.5-Turbo-0613-4K</option>
                                                    <option value="gpt-turbo-sixteenk-0613" class="">GPT-3.5-Turbo-0613-16K</option>
                                                    <option value="gpt-turbo-sixteenk-1106" class="">GPT-3.5-Turbo-1106-16K</option>


                                                    
                                                    </select>
                                                </div>
                                                {values.modelForLanguage === "gpt4o-global-deployment" ? (
                                                    <>
                                                        {(values.region !== "switzerland-west" && values.region !== " mexico-central" && values.region !== "canada-central") && (
                                                            <>
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="prompt" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Input
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        style={{paddingTop : "10px"}}
                                                                        className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                                        id="prompt"
                                                                        value={values.prompt}
                                                                        name={"prompt"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}

                                                                    />
                                                                </div>
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="completion" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Output
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        style={{paddingTop : "10px"}}                                                                        
                                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                                        id="completion"
                                                                        value={values.completion}
                                                                        name={"completion"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}

                                                                    />
                                                                </div>
                                                            </>
                                                        )}
                                                    </>
                                                ) : values.modelForLanguage === "gpt4o-regional-api" ? (
                                                    <>
                                                       {(values.region !== 'united-kingdom-south' &&
                                                            values.region !== 'uae-north' &&
                                                            values.region !== 'switzerland-north' &&
                                                            values.region !== 'switzerland-west' &&
                                                            values.region !== 'poland-central' &&
                                                            values.region !== 'norway-east' &&
                                                            values.region !== 'mexico-central' &&
                                                            values.region !== 'korea-central' &&
                                                            values.region !== 'japan-east' &&
                                                            values.region !== 'south-india' &&
                                                            values.region !== 'germany-west-central' &&
                                                            values.region !== 'france-central' &&
                                                            values.region !== 'europe-west' &&
                                                            values.region !== 'canada-central' &&
                                                            values.region !== 'canada-east' &&
                                                            values.region !== 'brazil-south' &&
                                                            values.region !== 'australia-east' &&
                                                            values.region !== 'south-africa-north') && (
                                                            <>
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="prompt" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Input
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        style={{paddingTop : "10px"}}
                                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                                        id="prompt"
                                                                        value={values.prompt}
                                                                        name={"prompt"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}

                                                                    />
                                                                </div>
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="completion" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Output
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        style={{paddingTop : "10px"}}
                                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                                        id="completion"
                                                                        value={values.completion}
                                                                        name={"completion"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}

                                                                    />
                                                                </div>
                                                            </>
                                                        )} 
                                                    </>
                                                ) : values.modelForLanguage === "gpt-turbo-sixteenk-0125" ? (
                                                    <>
                                                        {(values.region !== 'us-west' &&
                                                            values.region !== 'us-west-3' &&
                                                            values.region !== 'uae-north' &&
                                                            values.region !== 'switzerland-north' &&
                                                            values.region !== 'switzerland-west' &&
                                                            values.region !== 'poland-central' &&
                                                            values.region !== 'norway-east' &&
                                                            values.region !== 'mexico-central' &&
                                                            values.region !== 'korea-central' &&
                                                            values.region !== 'japan-east' &&
                                                            values.region !== 'south-india' &&
                                                            values.region !== 'germany-west-central' &&
                                                            values.region !== 'europe-west' &&
                                                            values.region !== 'canada-central' &&
                                                            values.region !== 'brazil-south' &&
                                                            values.region !== 'south-africa-north') && (
                                                            <>
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="prompt" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Input
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        style={{paddingTop : "10px"}}
                                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                                        id="prompt"
                                                                        value={values.prompt}
                                                                        name={"prompt"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}

                                                                    />
                                                                </div>
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="completion" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Output
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        style={{paddingTop : "10px"}}
                                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                                        id="completion"
                                                                        value={values.completion}
                                                                        name={"completion"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}

                                                                    />
                                                                </div>
                                                            </>
                                                        )}  
                                                    </>
                                                ) : values.modelForLanguage === "gpt-turbo-instruct" ? (
                                                    <>
                                                    {(values.region !== 'us-east-2' &&
                                                            values.region !== 'us-north-central' &&
                                                            values.region !== 'us-south-central' &&
                                                            values.region !== 'us-west' &&
                                                            values.region !== 'us-west-3' &&
                                                            values.region !== 'united-kingdom-south' &&
                                                            values.region !== 'uae-north' &&
                                                            values.region !== 'switzerland-north' &&
                                                            values.region !== 'switzerland-west' &&
                                                            values.region !== 'poland-central' &&
                                                            values.region !== 'norway-east' &&
                                                            values.region !== 'mexico-central' &&
                                                            values.region !== 'korea-central' &&
                                                            values.region !== 'japan-east' &&
                                                            values.region !== 'south-india' &&
                                                            values.region !== 'germany-west-central' &&
                                                            values.region !== 'france-central' &&
                                                            values.region !== 'europe-west' &&
                                                            values.region !== 'canada-central' &&
                                                            values.region !== 'canada-east' &&
                                                            values.region !== 'brazil-south' &&
                                                            values.region !== 'australia-east' &&
                                                            values.region !== 'south-africa-north') && (
                                                            <>
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="prompt" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Input
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        style={{paddingTop : "10px"}}
                                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                                        id="prompt"
                                                                        value={values.prompt}
                                                                        name={"prompt"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}

                                                                    />
                                                                </div>
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="completion" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Output
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        style={{paddingTop : "10px"}}
                                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                                        id="completion"
                                                                        value={values.completion}
                                                                        name={"completion"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}

                                                                    />
                                                                </div>
                                                            </>
                                                        )}  
                                                    </>
                                                ) : values.modelForLanguage === "gptfour-onetwentyeightk" ? (
                                                    <>
                                                        {(['us-east-2', 'us-west', 'united-kingdom-south', 'switzerland-north', 
                                                        'sweden-central', 'norway-east', 'south-india', 'france-central', 
                                                        'canada-east', 'australia-east'].includes(values.region)) && (
                                                            <>
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="prompt" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Input
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        style={{paddingTop : "10px"}}
                                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                                        id="prompt"
                                                                        value={values.prompt}
                                                                        name={"prompt"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}

                                                                    />
                                                                </div>
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="completion" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Output
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        style={{paddingTop : "10px"}}
                                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                                        id="completion"
                                                                        value={values.completion}
                                                                        name={"completion"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}

                                                                    />
                                                                </div>
                                                            </>
                                                        )}
                                                    </>
                                                ) : values.modelForLanguage === "gptfour-turbovision-onetwentyeightk" ? (
                                                    <>
                                                        {(['us-east-2', 'us-west', 'united-kingdom-south', 'switzerland-north', 
                                                        'sweden-central', 'france-central', 'canada-east', 'australia-east'].includes(values.region)) && (
                                                            <>
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="prompt" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Input
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        style={{paddingTop : "10px"}}
                                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                                        id="prompt"
                                                                        value={values.prompt}
                                                                        name={"prompt"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}

                                                                    />
                                                                </div>
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="completion" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Output
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        style={{paddingTop : "10px"}}
                                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                                        id="completion"
                                                                        value={values.completion}
                                                                        name={"completion"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}

                                                                    />
                                                                </div>
                                                            </>
                                                        )}
                                                    </>
                                                ) : values.modelForLanguage === "gptfour-eightk" ? (
                                                    <>
                                                        {(['us-east', 'us-east-2', 'us-north-central', 'us-south-central', 
                                                            'united-kingdom-south', 'switzerland-north', 'sweden-central', 
                                                            'japan-east', 'france-central', 'canada-east', 'australia-east'].includes(values.region)) && (
                                                            <>
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="prompt" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Input
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        style={{paddingTop : "10px"}}
                                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                                        id="prompt"
                                                                        value={values.prompt}
                                                                        name={"prompt"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}

                                                                    />
                                                                </div>
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="completion" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Output
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        style={{paddingTop : "10px"}}
                                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                                        id="completion"
                                                                        value={values.completion}
                                                                        name={"completion"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}

                                                                    />
                                                                </div>
                                                            </>
                                                        )}
                                                    </>
                                                ) : values.modelForLanguage === "gptfour-thirtytwok" ? (
                                                    <>
                                                        {(['us-east', 'us-east-2', 'us-north-central', 'us-south-central', 
                                                            'united-kingdom-south', 'switzerland-north', 'sweden-central', 
                                                            'japan-east', 'france-central', 'canada-east', 'australia-east'].includes(values.region)) && (
                                                            <>
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="prompt" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Input
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        style={{paddingTop : "10px"}}
                                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                                        id="prompt"
                                                                        value={values.prompt}
                                                                        name={"prompt"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}

                                                                    />
                                                                </div>
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="completion" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Output
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        style={{paddingTop : "10px"}}
                                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                                        id="completion"
                                                                        value={values.completion}
                                                                        name={"completion"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}

                                                                    />
                                                                </div>
                                                            </>
                                                        )}
                                                    </>
                                                ) : values.modelForLanguage === "gpt-turbo-fourk-0301" ? (
                                                    <>
                                                        {(['us-east', 'us-east-2', 'us-north-central', 'us-south-central', 
                                                            'united-kingdom-south', 'switzerland-north', 'sweden-central', 
                                                            'mexico-central', 'japan-east', 'france-central', 'europe-west', 
                                                            'canada-east', 'australia-east'].includes(values.region)) && (
                                                            <>
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="prompt" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Input
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        style={{paddingTop : "10px"}}
                                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                                        id="prompt"
                                                                        value={values.prompt}
                                                                        name={"prompt"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}

                                                                    />
                                                                </div>
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="completion" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Output
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        style={{paddingTop : "10px"}}
                                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                                        id="completion"
                                                                        value={values.completion}
                                                                        name={"completion"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}

                                                                    />
                                                                </div>
                                                            </>
                                                        )}
                                                    </>
                                                ) : values.modelForLanguage === "gpt-turbo-fourk-0613" ? (
                                                    <>
                                                        {(['us-east', 'us-east-2', 'us-north-central', 'us-south-central', 
                                                            'united-kingdom-south', 'switzerland-north', 'sweden-central', 
                                                            'japan-east', 'france-central', 'europe-west', 
                                                            'canada-east', 'australia-east'].includes(values.region)) && (
                                                            <>
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="prompt" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Input
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        style={{paddingTop : "10px"}}
                                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                                        id="prompt"
                                                                        value={values.prompt}
                                                                        name={"prompt"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}

                                                                    />
                                                                </div>
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="completion" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Output
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        style={{paddingTop : "10px"}}
                                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                                        id="completion"
                                                                        value={values.completion}
                                                                        name={"completion"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}

                                                                    />
                                                                </div>
                                                            </>
                                                        )}
                                                    </>
                                                ) : values.modelForLanguage === "gpt-turbo-sixteenk-0613" ? (
                                                    <>
                                                        {(['us-east', 'us-east-2', 'us-north-central', 'us-south-central', 
                                                            'united-kingdom-south', 'switzerland-north', 'sweden-central', 
                                                            'japan-east', 'france-central', 'europe-west', 
                                                            'canada-east', 'australia-east'].includes(values.region)) && (
                                                            <>
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="prompt" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Input
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        style={{paddingTop : "10px"}}
                                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                                        id="prompt"
                                                                        value={values.prompt}
                                                                        name={"prompt"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}

                                                                    />
                                                                </div>
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="completion" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Output
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        style={{paddingTop : "10px"}}
                                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                                        id="completion"
                                                                        value={values.completion}
                                                                        name={"completion"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}

                                                                    />
                                                                </div>
                                                            </>
                                                        )}
                                                    </>
                                                ) : values.modelForLanguage === "gpt-turbo-sixteenk-1106" ? (
                                                    <>
                                                        {(['us-east', 'us-north-central', 'us-south-central', 'us-west',
                                                            'united-kingdom-south', 'sweden-central', 'south-india', 
                                                            'france-central', 'canada-east', 'australia-east'].includes(values.region)) && (
                                                            <>
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="prompt" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Input
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        style={{paddingTop : "10px"}}
                                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                                        id="prompt"
                                                                        value={values.prompt}
                                                                        name={"prompt"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}

                                                                    />
                                                                </div>
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="completion" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Output
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        style={{paddingTop : "10px"}}
                                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                                        id="completion"
                                                                        value={values.completion}
                                                                        name={"completion"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}

                                                                    />
                                                                </div>
                                                            </>
                                                        )}
                                                    </>
                                                ) : 
                                                (null)}
                                            </>
                                        ) : values.selectedModelSeries === "assistants-api" ? (
                                            <>
                                                <div className="col-md-6 px-3">
                                                    <label htmlFor="modelForAssistantsAPI" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Model
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="modelForAssistantsAPI"
                                                        value={values.modelForAssistantsAPI}
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        name={"modelForAssistantsAPI"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}
                                                    >
                                                    <option value="code-interpreter" class="">Code Interpreter</option>
                                                    </select>
                                                </div>  
                                                {(!['us-west-3', 'uae-north', 'switzerland-west', 'mexico-central', 'germany-west-central'].includes(values.region)) && (
                                                            <>
                                                                <div className="col-md-6 px-3">
                                                                    <label htmlFor="sessions" className="form-label font-14 font-medium black-v2 mb-3">
                                                                    Sessions
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        style={{paddingTop : "10px"}}
                                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                                        id="sessions"
                                                                        value={values.sessions}
                                                                        name={"sessions"}
                                                                        onChange={(e) => {
                                                                            onChangeOfConfiguration(e, values.instanceName);
                                                                        }} disabled={isViewer}

                                                                    />
                                                                </div>
                                                            </>
                                                        )}
                                            </>
                                        ) : values.selectedModelSeries === "base-models" ? (
                                            <>
                                                <div className="col-md-6 px-3">
                                                    <label htmlFor="baseModel" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Model
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="baseModel"
                                                        value={values.baseModel}
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        name={"baseModel"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}
                                                    >
                                                    <option value="babbage002" class="">Babbage-002</option>
                                                    <option value="davinci002" class="">Davinci-002</option>
                                                    </select>
                                                </div> 

                                                {(['us-east', 'us-east-2', 'us-north-central', 'us-south-central', 'switzerland-north', 
                                                    'mexico-central', 'japan-east', 'france-central', 'europe-west', 'australia-east'].includes(values.region)) && (
                                                    <>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="tokens" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Tokens
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop : "10px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                id="tokens"
                                                                value={values.tokens}
                                                                name={"tokens"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        ) : values.selectedModelSeries === "fine-tuning-models" ? (
                                            <>
                                                <div className="col-md-6 px-3">
                                                    <label htmlFor="modelForFineTuning" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Model
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="modelForFineTuning"
                                                        value={values.modelForFineTuning}
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        name={"modelForFineTuning"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}
                                                    >
                                                    <option value="babbage002" class="">Babbage-002</option>
                                                    <option value="davinci002" class="">Davinci-002</option>
                                                    <option value="gpt-turbo-fourk" class="">GPT-3.5-Turbo-4K</option>
                                                    <option value="gpt-turbo-sixteenk" class="">GPT-3.5-Turbo-16K</option>
                                                    </select>
                                                </div> 
                                                {(['us-east-2', 'us-north-central', 'switzerland-west', 'sweden-central'].includes(values.region)) && (
                                                    <>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="fineTuningTraining" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Training
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                id="fineTuningTraining"
                                                                value={values.fineTuningTraining}
                                                                name={"fineTuningTraining"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="fineTuningHosting" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Hosting
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                id="fineTuningHosting"
                                                                value={values.fineTuningHosting}
                                                                name={"fineTuningHosting"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="fineTuningInput" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Input
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                id="fineTuningInput"
                                                                value={values.fineTuningInput}
                                                                name={"fineTuningInput"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <label htmlFor="fineTuningOutput" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Output
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                id="fineTuningOutput"
                                                                value={values.fineTuningOutput}
                                                                name={"fineTuningOutput"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        ) : values.selectedModelSeries === "image-models" ? (
                                            <>
                                                <div className="col-md-6 px-3">
                                                    <label htmlFor="modelForImage" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Model
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="modelForImage"
                                                        value={values.modelForImage}
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        name={"modelForImage"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}
                                                    >
                                                    <option value="dall-e-two" class="">Dall-E-2</option>
                                                    <option value="dall-e-three" class="">Dall-E-3</option>
                                                    </select>
                                                </div> 

                                                {values.modelForImage === "dall-e-two" ? 
                                                (
                                                    <>
                                                        {values.region === 'us-east' && 
                                                        (<div className="col-md-6 px-3">
                                                            <label htmlFor="standardLowInput" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Standard (1024 x 1024 resolution)
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop : "10px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                id="standardLowInput"
                                                                value={values.standardLowInput}
                                                                name={"standardLowInput"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>)}  
                                                        
                                                    </>
                                                ): 
                                                (
                                                    <>
                                                        {(['us-east', 'sweden-central', 'australia-east'].includes(values.region)) && (
                                                        <>
                                                            <div className="col-md-6 px-3">
                                                                <label htmlFor="standardLowInput" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Standard (1024 x 1024 resolution)
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    style={{paddingTop : "10px"}}
                                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                                    id="standardLowInput"
                                                                    value={values.standardLowInput}
                                                                    name={"standardLowInput"}
                                                                    onChange={(e) => {
                                                                        onChangeOfConfiguration(e, values.instanceName);
                                                                    }} disabled={isViewer}

                                                                />
                                                            </div>

                                                            <div className="col-md-6 px-3">
                                                                <label htmlFor="standardHighInput" className="form-label font-14 font-medium black-v2 mb-3">
                                                                Standard (1024 x 1792 resolution)
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    style={{paddingTop : "10px"}}
                                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                                    id="standardHighInput"
                                                                    value={values.standardHighInput}
                                                                    name={"standardHighInput"}
                                                                    onChange={(e) => {
                                                                        onChangeOfConfiguration(e, values.instanceName);
                                                                    }} disabled={isViewer}

                                                                />
                                                            </div>

                                                            <div className="col-md-6 px-3">
                                                                <label htmlFor="hdLowInput" className="form-label font-14 font-medium black-v2 mb-3">
                                                                HD (1024 x 1024 resolution)
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    style={{paddingTop : "10px"}}
                                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                                    id="hdLowInput"
                                                                    value={values.hdLowInput}
                                                                    name={"hdLowInput"}
                                                                    onChange={(e) => {
                                                                        onChangeOfConfiguration(e, values.instanceName);
                                                                    }} disabled={isViewer}

                                                                />
                                                            </div>

                                                            <div className="col-md-6 px-3">
                                                                <label htmlFor="hdHighInput" className="form-label font-14 font-medium black-v2 mb-3">
                                                                HD (1024 x 1792 resolution)
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                                    id="hdHighInput"
                                                                    value={values.hdHighInput}
                                                                    name={"hdHighInput"}
                                                                    onChange={(e) => {
                                                                        onChangeOfConfiguration(e, values.instanceName);
                                                                    }} disabled={isViewer}

                                                                />
                                                            </div>
                                                         </>
                                                )}
                                                    </>
                                                )}
                                            </>
                                        ) : values.selectedModelSeries === "embedding-models" ? (
                                            <>
                                                <div className="col-md-6 px-3">
                                                    <label htmlFor="modelForEmbedding" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Model
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="modelForEmbedding"
                                                        value={values.modelForEmbedding}
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        name={"modelForEmbedding"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}
                                                    >
                                                    <option value="ada" class="">Ada</option>
                                                    <option value="text-embedding-3-large" class="">Text-Embedding-3-Large</option>
                                                    <option value="text-embedding-3-small" class="">Text-Embedding-3-Small</option>
                                                    </select>
                                                </div>

                                                {values.modelForEmbedding === "ada"?
                                                (
                                                    <>
                                                        {['us-east', 'us-east-2', 'us-north-central', 'us-south-central', 
                                                        'united-kingdom-south', 'switzerland-north', 'sweden-central', 
                                                        'japan-east', 'france-central', 'europe-west', 
                                                        'canada-east', 'australia-east'].includes(values.region)  && 
                                                        (<div className="col-md-6 px-3">
                                                            <label htmlFor="tokens" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Tokens
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop : "10px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                id="tokens"
                                                                value={values.tokens}
                                                                name={"tokens"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>)} 
                                                    </>
                                                ):
                                                values.modelForEmbedding === "text-embedding-3-large"?
                                                (
                                                    <>
                                                        {['us-east', 'us-east-2', 'us-north-central', 'us-south-central', 
                                                        'united-kingdom-south', 'switzerland-north', 'sweden-central', 
                                                        'norway-east', 'france-central', 'europe-west', 
                                                        'canada-east', 'australia-east'].includes(values.region)  && 
                                                        (<div className="col-md-6 px-3">
                                                            <label htmlFor="tokens" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Tokens
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop : "10px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                id="tokens"
                                                                value={values.tokens}
                                                                name={"tokens"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>)} 
                                                    </>
                                                ):(
                                                    <>
                                                        {['us-east', 'us-east-2', 'us-north-central', 'us-south-central', 'us-west',
                                                        'united-kingdom-south', 'switzerland-north', 'sweden-central', 
                                                        'norway-east', 'japan-east', 'france-central', 'europe-west', 
                                                        'canada-east', 'australia-east'].includes(values.region)  && 
                                                        (<div className="col-md-6 px-3">
                                                            <label htmlFor="tokens" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Tokens
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop : "10px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                id="tokens"
                                                                value={values.tokens}
                                                                name={"tokens"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>)} 
                                                    </>
                                                )}
                                            </>
                                        ) : values.selectedModelSeries === "speech-models" ? (
                                            <>
                                                <div className="col-md-6 px-3">
                                                    <label htmlFor="modelForSpeech" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Model
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="modelForSpeech"
                                                        value={values.modelForSpeech}
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        name={"modelForSpeech"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}
                                                    >
                                                    <option value="whisper" class="">Whisper</option>
                                                    <option value="text-to-speech" class="">Text To Speech</option>
                                                    <option value="text-to-speech-hd" class="">Text To Speech HD</option>
                                                    </select>
                                                </div>

                                                {values.modelForSpeech === "whisper" ? 
                                                (
                                                    <>
                                                        {['us-north-central', 'europe-west'].includes(values.region)  && 
                                                        (<div className="col-md-6 px-3">
                                                            <label htmlFor="speechInput" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Hours
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop : "10px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                id="speechInput"
                                                                value={values.speechInput}
                                                                name={"speechInput"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>)}
                                                    </>
                                                ): 
                                                values.modelForSpeech === "text-to-speech" ?
                                                (
                                                    <>
                                                        {['us-north-central', 'sweden-central'].includes(values.region)  && 
                                                        (<div className="col-md-6 px-3">
                                                            <label htmlFor="speechInput" className="form-label font-14 font-medium black-v2 mb-3">
                                                            x1 million characters
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop : "10px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                id="speechInput"
                                                                value={values.speechInput}
                                                                name={"speechInput"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>)}
                                                    </>
                                                ):
                                                (
                                                    <>
                                                        {['us-north-central', 'sweden-central'].includes(values.region)  && 
                                                        (<div className="col-md-6 px-3">
                                                            <label htmlFor="speechInput" className="form-label font-14 font-medium black-v2 mb-3">
                                                            x1 million characters
                                                            </label>
                                                            <input
                                                                type="text"
                                                                style={{paddingTop : "10px"}}
                                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                                id="speechInput"
                                                                value={values.speechInput}
                                                                name={"speechInput"}
                                                                onChange={(e) => {
                                                                    onChangeOfConfiguration(e, values.instanceName);
                                                                }} disabled={isViewer}

                                                            />
                                                        </div>)}
                                                    </>
                                                )}
                                            </>
                                        ) : 
                                        (null)}
                                    </div>
                                    
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
                </div>
            </div>
        );
    } catch (error) {
        //console.log(error);
    }
}



// values.region = 'us-east'
// values.selectedModelSeries = "base-models"
// values.modelForLanguage = "gpt-turbo-sixteenk-0125";
// values.prompt = "10000000000";
// values.completion = "10000000000"
// values.modelForAssistantsAPI = "code-interpreter"
// values.sessions = "10000000054390"
// values.baseModel = "babbage002"
// values.tokens = "1000000004234230"
// values.modelForFineTuning = "babbage002"
// values.fineTuningInput = "10004324234000"
// values.fineTuningOutput = "1004234234230000"
// values.fineTuningHosting = "10023423420000"
// values.modelForImage = "dall-e-two"
// values.standardLowInput = "1000423423423000"
// values.standardHighInput = "100234234230000" 
// values.hdLowInput = "104234234230000"
// values.hdHighInput = "10042342342300"
// values.modelForEmbedding = "ada"
// values.modelForSpeech = "whisper"