import {
    ReactFlowProvider,
} from '@xyflow/react';
import { useNavigate } from 'react-router-dom';
import React, { useMemo, useState, useRef, useCallback, useEffect } from "react";
import SideBarComponent from "./SideBarComponent";
import Workbench from './Workbench';
import { OrgProvider, useOrgContext } from '../../../../ContextProvider/OrgContext';
import { WorkbenchNav } from '../../WorkBenchNav';


function WorkbenchRender(isDarkMode) {
    console.log("isDarkModeisDarkMode2",isDarkMode.isDarkMode);
    
    const navigate = useNavigate();
    return (
       
        <div className="container-fluid">
             <WorkbenchNav/>
            {/* <div className="px-3 pt-2"> */}
                    <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <ReactFlowProvider>
                            
                                <Workbench  isDarkMode={isDarkMode.isDarkMode}
                                />
                            </ReactFlowProvider>

                        </div>
                    </div>
                </div>
            {/* </div> */}
        </div>
    );
}

export default WorkbenchRender;