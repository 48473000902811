import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";


function OrganizationSidePannel(props){
    const [pending,setPendingCount]=useState("")
    const {state}=useLocation()

    console.log(props.pendingCount,"this is the props")

    useEffect(() => {

      }, [state]);

    return(
        <>
         <div className="col-md-3 custom-border-right custom-white ps-5 pe-1 pt-2">
        <div className="about-page-title pt-4">
          <div className="d-flex align-items-center mb-3 mb-md-0 ">
            <a href="#">
              <img
                src="images/back-arrow.svg"
                alt="back-arrow"
                title="Back"
                className="me-3 icon-filter"
              />
            </a>
            <img
              src="images/rac-logo.svg"
              alt="partner-logo"
              className="partner-logo icon-width-48"
            />
            <h2 className="font-20 font-bold black-v2 mb-0 ms-3 ">
              Rent-A-Center
            </h2>
          </div>
        </div>
        <ul className="nav nav-pills flex-column mb-auto flex-nowrap nav-overflow mt-5 pt-2">
          <li className="side-nav-item d-flex align-items-center active">
            <img
              src="images/dashboard-icon.svg"
              alt="Dashboard"
              className="side-icon"
            />
            <span>Dashboard</span>
          </li>
          <li className="side-nav-item d-flex align-items-center ">
            <img
              src="images/workload-icon.svg"
              alt="workload-icon"
              className="side-icon"
            />
            <span>Workloads</span>
          </li>
          <li className="side-nav-item d-flex align-items-center">
            <img
              src="images/b2a-icon.svg"
              alt="b2a-icon"
              className="side-icon"
            />
            <span>B2A</span>
          </li>
          <li className="side-nav-item d-flex align-items-center">
            <img
              src="images/inventry-icon.svg"
              alt="webcom-icon"
              className="side-icon"
            />
            <span>Inventory</span>
          </li>
          <li
            className="side-nav-item side-nav-drop d-flex align-items-center justify-content-between collapsed "
            data-bs-toggle="collapse"
            data-bs-target="#contentGenList"
          >
            <span
              className="d-flex align-items-center w-100"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span className="d-flex align-items-center gap-2">
                <img
                  src="images/cost-management-icon.svg"
                  alt="cost-management-icon"
                  className="side-icon"
                />
                <span>Cost Management</span>
              </span>
              <img
                src="images/drop-down-arrow.svg"
                alt="list-arrow"
                className="list-arrow ms-2 side-icon"
              />
            </span>
          </li>
          <li
            className="side-sub-nav sub collapse px-4 bg-primary-v2"
            id="contentGenList"
          >
            <ul className="sub-list list-unstyled ps-4 font-12 font-medium bg-primary-v2">
              <li className="cursor-pointer active sub-menu-list py-3 text-nowrap ">
                Budget{" "}
              </li>
              <li className="cursor-pointer sub-menu-list py-3 text-nowrap">
                Invoices{" "}
              </li>
            </ul>
          </li>
          <li className="side-nav-item d-flex align-items-center">
            <img
              src="images/provider-conf-icon.svg"
              alt="provider-conf-icon"
              className="side-icon"
            />
            <span>Provider Configurations</span>
          </li>
          <li className="side-nav-item d-flex align-items-center">
            <img
              src="images/approval-icon.svg"
              alt="approval-icon"
              className="side-icon"
            />
            <span className="d-flex justify-content-center align-items-center">
              Approvals <span className="notify-content ms-2">{props.pendingCount}</span>
            </span>
          </li>
          <li className="side-nav-item d-flex align-items-center">
            <img
              src="images/about-icon.svg"
              alt="about-icon"
              className="side-icon"
            />
            <span>About</span>
          </li>
        </ul>
      </div>
        </>
    )

}

export default OrganizationSidePannel