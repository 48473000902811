import React, { useState, useEffect, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate, useLocation } from "react-router-dom";
import {
  createArticle,
  getArticleDetail,
  updateArticle,
  deleteArticle,
} from "../../Service/api";
import Loader from "../loader/loader";
import WorkbenchPopup from "../Organization/Workbench/WorkBench/PopupMaintanence/WorkBenchPopUp";

//PS_01 to PS_11 Declaring state variables
const ArticleForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [newArticleName, setNewArticleName] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [activeTab, setActiveTab] = useState("recent");
  const darkTheme = localStorage.getItem('darkTheme') === 'true';

  // For updating existing articles
  const [updateArticleName, setUpdateArticleName] = useState("");
  const [updateDescription, setUpdateDescription] = useState("");
  const [articleContent, setArticleContent] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [articleId, setArticleId] = useState(null);
  const [initialContent, setInitialContent] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const quillRef = useRef(null);
  const [isDeleteSuccessful, setIsDeleteSuccessful] = useState(false);
  const [popupMsg, setPopUpMsg] = useState({
    Msg: "",
    show: false,
    success: false,
  });
  const [popupArticleName, setPopupArticleName] = useState("");
  const [popupDescription, setPopupDescription] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const Font = ReactQuill.Quill.import("formats/font");
  Font.whitelist = [
    "Garamond",
    "Georgia",
    "Gulim",
    "Gungsuh",
    "KaiTi",
    "JasmineUPC",
    "Mangal",
    "Meiryo",
    "MingLiU",
    "MingLiU_HKSCS",
    "PMingLiU",
    "PMingLiU-ExtB",
    "SimHei",
    "SimSun",
    "SimSun-ExtB",
    "Batang",
    "Calibri",
    "Cambria",
    "Candara",
    "Century",
    "Consolas",
    "Constantia",
    "Corbel",
    "FangSong",
    "Tahoma",
    "Verdana",
  ];
  ReactQuill.Quill.register(Font, true);

  //PS_13 Use effects for triggering
  
useEffect(() => {
  const { state } = location;
  const userId = localStorage.getItem("userId");
  if (state) {
    if (state.articleId) {
      setIsEditMode(true);
      setArticleId(state.articleId);
      fetchArticleDetails(userId, state.articleId);
    }
    if (state.activeTab) {
      setActiveTab(state.activeTab);
    }
  }
}, [location]);

  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = `
      .ql-toolbar .ql-picker.ql-font .ql-picker-options {
        max-height: 200px; /* adjust this value to your preference */
        overflow-y: auto;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  
useEffect(() => {
  if (quillRef.current) {
    const editor = quillRef.current.getEditor();
    const toolbar = editor.getModule('toolbar');
    
    const updateToolbarStyles = () => {
      const toolbarElement = toolbar.container;
      if (darkTheme) {
        toolbarElement.classList.add('ql-dark');
      } else {
        toolbarElement.classList.remove('ql-dark');
      }
    };

    updateToolbarStyles();
  }
}, [darkTheme]);

  const handleDelete = () => {
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const response = await deleteArticle({ userId, articleId });
      console.log("Full response:", response);

      if (response.data && response.data.body && response.data.body.success) {
        setIsDeleteSuccessful(true);
        setShowDeleteModal(false);
        setPopUpMsg({
          ...popupMsg,
          ["Msg"]: "Article deleted successfully",
          ["show"]: true,
          ["success"]: true,
        });
        setTimeout(() => {
          setPopUpMsg({ ...popupMsg, ["show"]: false });
          navigate("/ArticleGrid");
        }, 3000);
      } else {
        console.error("Unexpected API response structure:", response);
        setPopUpMsg({
          ...popupMsg,
          ["Msg"]: "Something went wrong",
          ["show"]: true,
          ["success"]: false,
        });
      }
    } catch (error) {
      console.error("Error deleting article:", error);
      setPopUpMsg({
        ...popupMsg,
        ["Msg"]: "Something went wrong",
        ["show"]: true,
        ["success"]: false,
      });
    }
  };

  const handleUploadOrUpdate = () => {
    setPopupArticleName(isEditMode ? updateArticleName : newArticleName);
    setPopupDescription(isEditMode ? updateDescription : newDescription);
    setShowModal(true);
  };

  const handlePublishFromModal = () => {
    if (isEditMode) {
      setUpdateArticleName(popupArticleName);
      setUpdateDescription(popupDescription);
    } else {
      setNewArticleName(popupArticleName);
      setNewDescription(popupDescription);
    }
    handlePublish(popupArticleName, popupDescription);
    setShowModal(false);
  };

  //PS_14 to PS_18 Function to fetch articles

  const fetchArticleDetails = async (userId, articleId) => {
    try {
      setShowLoader(true);
      const response = await getArticleDetail({ userId, articleId });
      setShowLoader(false);
      if (response.data && response.data.success) {
        const article = response.data.article;
        if (article) {
          setUpdateArticleName(article.articleName);
          setUpdateDescription(article.description);
          setArticleContent(article.articleData);
        } else {
          console.error("Article data not found in the response");
        }
      } else {
        console.error("Unexpected API response structure:", response);
      }
    } catch (error) {
      console.error("Error fetching article details:", error);
    } finally {
      setShowLoader(false);
    }
  };

  //PS_19 to PS_28 Function to handle publish

  const handlePublish = async (articleName, description) => {
    if (!articleName || !description || !articleContent) {
      // Show error message to user
      return;
    }

    setIsUploading(true);

    const userId = localStorage.getItem("userId");
    const payload = {
      userId,
      articleName,
      articleDescription: description,
      articleData: articleContent,
    };

    try {
      let response;
      if (isEditMode) {
        payload.articleId = articleId;
        response = await updateArticle(payload);
      } else {
        response = await createArticle(payload);
      }

      if (response.data && response.data.body && response.data.body.success) {
        setShowModal(false);
        if (isEditMode) {
          setUpdateArticleName(articleName);
          setUpdateDescription(description);
        } else {
          setNewArticleName(articleName);
          setNewDescription(description);
        }
        setPopUpMsg({
          Msg: "Article saved successfully",
          show: true,
          success: true,
        });
        setTimeout(() => {
          setPopUpMsg({ ...popupMsg, show: false });
          navigate("/ArticleGrid", { state: { activeTab } });
        }, 3000);
      } else {
        throw new Error("Unexpected response structure");
      }
    } catch (error) {
      console.error("Error publishing article:", error);
      setPopUpMsg({
        Msg: "Something went wrong",
        show: true,
        success: false,
      });
    } finally {
      setIsUploading(false);
    }
  };
  //PS_29
  const handleCancel = () => {
    navigate("/ArticleGrid", { state: { activeTab } });
  };
  //PS_30
  const handleOpenModal = () => {
    setShowModal(true);
  };
  //PS_31
  const handleCloseModal = () => {
    setShowModal(false);
  };
  //PS_32
  const isContentChanged = () => {
    return isEditMode ? articleContent !== initialContent : !!articleContent;
  };

  //PS_33 and PS_34
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ size: ["small", false, "large", "huge"] }],
      [{ color: [] }, { background: [] }],
      [{ font: Font.whitelist }],
      [{ align: [] }],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "size",
    "color",
    "background",
    "font",
    "align",
    "link",
    "image",
    "video",
    "clean",
  ];

  return (
    <>
      {showLoader ? (
        <Loader />
      ) : (
        <div className="container-fluid custom-container" style={{height:"100vh"}}>
          {/* Slide title start here*/}
          <div className="d-flex justify-content-between align-items-center pt-4 mt-2">
            <div className="d-flex align-items-center mb-3 mb-md-0">
            <a href="#" onClick={() => navigate("/ArticleGrid", { state: { activeTab } })}>
  <img
    src="images/back-arrow.svg"
    alt="back-arrow"
    title="Back"
    className="icon-filter"
  />
</a>
              <h2 className="font-20 font-bold black-v2 mb-0 ms-3">
                {isEditMode ? updateArticleName : "New Article"}
              </h2>
            </div>

            {isEditMode ? (
              <div className="d-flex align-items-center">
                <button
                  type="button"
                  className="button secondary-outline-button text-nowrap tag-color me-2"
                  onClick={handleDelete}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="button primary-button text-nowrap custom-btn-size-1"
                  onClick={handleUploadOrUpdate}
                >
                  Update
                </button>
              </div>
            ) : (
              <button
                type="button"
                className="button primary-button text-nowrap custom-btn-size-1"
                onClick={handleUploadOrUpdate}
              >
                Upload
              </button>
            )}
          </div>

          <div className="row pe-2 my-4">
    <div className="col-md-12">
    
<ReactQuill
  ref={quillRef}
  value={articleContent}
  onChange={setArticleContent}
  modules={modules}
  bounds={".app"}
  theme="snow"
  formats={formats}
  placeholder="Enter content here"
  className={`custom-quill ${darkTheme ? 'ql-dark' : ''}`}
/>
    </div>
  </div>
          {/* Modal for Upload Article */}

          {showModal && (
            <>
              {/* Backdrop with blur */}
              <div
                className="modal-backdrop show d-block"
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  zIndex: 1040,
                }}
              ></div>

              {/* Modal */}
              <div
                className="modal fade show"
                style={{
                  display: "block",
                  zIndex: 1050,
                }}
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                aria-hidden="false"
              >
                <div className="modal-dialog modal-dialog-centered justify-content-center article-popup">
                  <div className="modal-content border-0">
                    <div className="modal-header border-0 py-4 px-32">
                      <h1
                        className="modal-title font-20 font-bold black-v2"
                        id="staticBackdropLabel"
                      >
                        {isEditMode ? "Update Article" : "Upload Article"}
                      </h1>
                      <button
                        type="button"
                        className="btn shadow-none pe-0"
                        onClick={handleCloseModal}
                        aria-label="Close"
                      >
                        <img
                          src="images/close-icon.svg"
                          alt="close-icon"
                          className="icon-hover shadow-none modal-close-filter"
                        />
                      </button>
                    </div>
                    <div className="modal-body px-32 pt-2">
                      <label
                        className="form-label font-14 font-medium black-v2 mt-4"
                        htmlFor="article-name"
                      >
                        Article Name <span className="danger-red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control custom-form custom-dropdown article-grey-colorarea font-14 font-medium black-v2"
                        id="article-name"
                        placeholder="Enter Article Name"
                        value={popupArticleName}
                        onChange={(e) => setPopupArticleName(e.target.value)}
                        style={{ color: "black" }}
                      />
                      <label
                        className="form-label font-14 font-medium black-v2 mt-4"
                        htmlFor="article-name"
                      >
                        Description <span className="danger-red">*</span>
                      </label>
                      <textarea
                        className="form-control custom-form custom-dropdown article-grey-colorarea font-14 font-medium black-v2"
                        placeholder="Enter Description"
                        id="architecture"
                        value={popupDescription}
                        onChange={(e) => setPopupDescription(e.target.value)}
                        style={{ color: "black" }}
                      />

                      <div className="d-flex justify-content-end align-items-center my-4 gap-3">
                        <button
                          type="button"
                          className="button outline-button text-nowrap"
                          onClick={handleCloseModal}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="button primary-button text-nowrap"
                          onClick={handlePublishFromModal}
                          disabled={!popupArticleName || !popupDescription}
                        >
                          Publish
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {showDeleteModal && (
            <div
              className="modal fade show"
              style={{
                display: "block",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <div className="modal-dialog modal-dialog-centered justify-content-center">
                <div className="modal-content border-0">
                  <div className="modal-header d-flex justify-content-end border-0 pb-0">
                    <button
                      type="button"
                      className="btn shadow-none"
                      onClick={() => setShowDeleteModal(false)}
                    >
                      <img
                        src="images/close-icon.svg"
                        alt="close-icon"
                        className="icon-hover shadow-none modal-close-filter"
                      />
                    </button>
                  </div>
                  <div className="modal-body text-center pt-0 p-32">
                    <img
                      src="images/delete-organization-icon.svg"
                      alt="delete the Article"
                    />
                    <h6 className="font-20 font-bold black-v2 mt-4 pt-2">
                      You're about to delete an article
                    </h6>
                    <p className="font-16 font-regular secondary-text-color-v5 mt-3 line-height-32 mb-0">
                      {isEditMode ? updateArticleName : newArticleName}
                    </p>
                    <p className="font-16 font-regular secondary-text-color-v5 mt-2 line-height-32">
                      Do you want to proceed?
                    </p>
                    <div className="d-flex justify-content-center gap-3 align-items-center mt-4 pt-3">
                      <button
                        type="button"
                        className="button outline-button text-nowrap px-32"
                        onClick={confirmDelete}
                      >
                        Yes
                      </button>
                      <button
                        type="button"
                        className="button primary-button popup-btn px-32"
                        onClick={() => setShowDeleteModal(false)}
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {popupMsg.show && (
            <div
              style={{
                position: "fixed",
                top: "15%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 1060,
                maxWidth: "80%",
                width: "300px",
              }}
            >
              <WorkbenchPopup popupMsg={popupMsg} setPopUpMsg={setPopUpMsg} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ArticleForm;


