import { useEffect, useState, useContext } from "react";
import Accordion from "./AccordianItem";
import Budget from "./Budget";
import AWSSDKLambda from "./AWS/AwsSDKLambda";
import { useLocation, useNavigate } from "react-router-dom";
import * as base64 from "base-64";
import _, { random, values } from "lodash";
import * as XLSX from "xlsx-js-style";
import FileSaver from "file-saver";
import AwsSDKNAT from "./AWS/AwsSDKNAT";
import AWSSDKSecretsManager from "./AWS/AwsSDKSecretManager";
import AWS_SDK_CloudFront from "./AWS/AWS_SDK_CloudFront";
import AWS_SDK_CommonRDS from "./AWS/AWS_SDK_CommonRDS";
import AWS_SDK_DDB from "./AWS/AWS_SDK_DDB";
import AwsSDKAwsApiGateway from "./AWS/AwsSDKApiGateway";
import AWS_SDK_EFS from "./AWS/AWS_SDK_EFS";
import AWS_SDK_Lambda from "./AWS/AWS_SDK_Lambda";
import AWS_SDK_RDS from "./AWS/AWS_SDK_RDS";
import scrapedCost from "./AWS/ScrapPrize";
import AWS_SDK_RDS_MYSQL from "./AWS/AWS_SDK_RDS_MYSQL";
import AWS_SDK_RDS_SQL from "./AWS/AWS_SDK_RDS_SQL"
import AwsSDKCognito from "./AWS/AwsSDKCognitoUserPools";
import AwsSDKDMS from "./AWS/AwsSDKDMS";
import AwsSDKEC2 from "./AWS/AwsSDKEC2";
import AwsSDKECR from "./AWS/AwsSDKECR";
import AwsSDKECS from "./AWS/AwsSDKECS";
import AwsSDKEKS from "./AWS/AwsSDKEKS";
import AwsSDKKMS from "./AWS/AwsSDKKMS";
import AwsSDKLB from "./AWS/AwsSDKLB";
import AwsSDKS3 from "./AWS/AwsSDKS3";
import AwsSDKRDS from "./AWS/AwsSDKRDS";
import AwsSDKSNS from "./AWS/SNS"
import Azure_AppService from "./Azure/AzureAppservice";
import AzureContainerRegistry from "./Azure/AzureContainerRegistry";
import Azure_Front_Door from "./Azure/AzureFrontDoor";
import Azure_FunctionApp from "./Azure/AzureFunction";
import Azure_Kubernetes from "./Azure/AzureKubernetes";
import Azure_Open_AI from "./Azure/AzureOpenai";
import AzurePrivateEndpoint from "./Azure/AzurePrivateEndpoint";
import Azure_Virtual_Desktop from "./Azure/AzureVirtualDesktop";
import Azure_Virtual_Machine from "./Azure/AzureVirtualMachine";
import Azure_Container_Apps from "./Azure/ContainerApps";
import Azure_Databrick from "./Azure/DataBricks";
import Azure_Disk from "./Azure/Disk";
import AzureKeyVault from "./Azure/KeyVault";
import AzureLoadBalancer from "./Azure/LoadBalancer";
import AzureLogAnalytics from "./Azure/LogAnalytics";
import AzurePublicIpAddress from "./Azure/PublicIpAddress";
import Azure_SQL_DB from "./Azure/SQLDB";
import Azure_StorageAccount from "./Azure/StorageAccount";
import AzureVirtualNetworkPeering from "./Azure/VirtualNetworkPeering";
import { getCostServices, getEstimateDetailsApi, getTodayInrPrice } from "../../../Service/api";
import { listApprovers, rejectApprove, requestApproval, setThreshold } from "../../../Service/api";
import { WorkbenchNav } from "../WorkBenchNav";
import { EstimationContext } from "../../../ContextProvider/EstimationContext";
import { OrgContext } from "../../../ContextProvider/OrgContext";
import WorkbenchLoader from "../Workbench/WorkBenchLoader";
import Loader from "../../loader/loader";
import WorkbenchPopup from "../Workbench/WorkBench/PopupMaintanence/WorkBenchPopUp";
import { loader } from "@monaco-editor/react";

function EstimationContent() {
    const location = useLocation();
    const { environmentId, approvalStatus, isThreshholdSet } = location.state || {};
    const { orgId, workloadId, providerName, envId, workloadRole, envName, approvalId } = useContext(OrgContext)

    const [isDisabled, setIsDisabled] = useState(false);
    const [isViewMode, setIsViewMode] = useState(!!approvalId);
    const [showApprovalModal, setShowApprovalModal] = useState(false);
    const [approvers, setApprovers] = useState({ Admin: [], ProjectAdmin: [] });
    const [showToast, setShowToast] = useState(false);
    const [renderTrigger, setRenderTrigger] = useState(0);
    const [awsSelectedAdmin, setAwsSelectedAdmin] = useState("");
    const [awsSelectedProjectAdmin, setAwsSelectedProjectAdmin] = useState("");
    const [awsPricingCalculatorURL, setAwsPricingCalculatorURL] = useState("");
    const [azureSelectedAdmin, setAzureSelectedAdmin] = useState("");
    const [azureSelectedProjectAdmin, setAzureSelectedProjectAdmin] = useState("");
    const [azurePricingCalculatorURL, setAzurePricingCalculatorURL] = useState("");
    const [showThresholdToast, setShowThresholdToast] = useState(false);
    const [thresholdToastMessage, setThresholdToastMessage] = useState('');
    const [showRejectionModal, setShowRejectionModal] = useState(false);
    const [rejectionReason, setRejectionReason] = useState('');
    const [showApprovalToast, setShowApprovalToast] = useState(false);
    const [showActionToast, setShowActionToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [configChanged, setConfigChanged] = useState(false);
    const [billingPeriod, setBillingPeriod] = useState(1);
    const [showLoader, setShowLoader] = useState(false)
    const [isapprovalStatus, setisapprovalStatus] = useState(false)
    const [popupMsg, setPopUpMsg] = useState({ "Msg": "", show: false, success: false })
    const [PricingCalculatorCost, setPricingCalculatorCost] = useState("")

    const {
        setEstimateAsBudget,
        setSetEstimateAsBudget,
        estimationNodes,
        setEstimationNodes
    } = useContext(EstimationContext);

    const userRoles = workloadRole
    console.log(envId, "Env Id for the context....")



    useEffect(() => {
        console.log(location, "Location IN Estimation")

    }, [location])




    useEffect(() => {
        if (approvalId) {
            setIsViewMode(true);
            setIsDisabled(true);
            setSetEstimateAsBudget(isThreshholdSet);
        } else {
            setIsViewMode(false);
            setIsDisabled(false);
        }
    }, [approvalId, isThreshholdSet]);

    useEffect(() => {
        if (userRoles.includes('viewer')) {
            setIsViewMode(true);
            setIsDisabled(true);
        }
    }, [userRoles]);
    useEffect(() => {
        getEstimateDetails(envId)
    }, []);




    useEffect(() => {
        if (isViewMode) {
            setIsDisabled(true);
        }
    }, [isViewMode]);

    useEffect(() => {
        fetchApprovers();
    }, [renderTrigger]);

    const fetchApprovers = async () => {
        const userId = sessionStorage.getItem(
            "userId"
        );
        const payload = {
            userId,
            envId,
            orgId: "",
        };
        try {
            const response = await listApprovers(payload);
            setApprovers(response.data);
        } catch (error) {
            console.error("Error fetching approvers:", error);
        }
    };


    const handleInputChange = (e) => {
        if (isViewMode) return;

        const { name, type, checked, value } = e.target;
        if (type === "checkbox") {
            setSetEstimateAsBudget(checked);
        } else if (name === "billingPeriod") {
            setBillingPeriod(Number(value));
        } else {
            onChangeOfConfiguration(e, e.target.getAttribute("data-instance-name"));
        }
        setConfigChanged(true);
    }

    const navigate = useNavigate();
    const [provider, setProvider] = useState(providerName)
    const [lowestPrice, setLowestPrice] = useState([]);
    const [highestPrice, setHighestPrice] = useState([]);
    const [costArray, setCostArray] = useState([]);
    const [currency, setCurrency] = useState("USD");
    const [serviceFamilyArray, setServiceFamilyArray] = useState([]);
    const [totalPrice, setTotalPrice] = useState();
    //Estimation_23
    const [searchWord, setSearchWord] = useState("");
    const [duration, setDuration] = useState(1);
    const [isSearchAvailable, setIsSearchAVailable] = useState(false);
    const [isViewer, setIsViewer] = useState(false);
    const [OptionClick, setOptionClick] = useState(false)



    const Mapping = {
        "AWS": {
            "awslambda": {
                "GCP": "Cloud Functions",
                "Azure": "Azure Functions"
            },
            "Lambda": {
                "GCP": "Cloud Functions",
                "Azure": "Azure Function"
            },
            "API Gateway": {
                "GCP": "Cloud Endpoints",
                "Azure": null
            },
            "EC2": {
                "GCP": "Compute Engine",
                "Azure": "Azure Virtual Machine"
            },
            "EKS": {
                "GCP": "Google Kubernetes Engine (GKE)",
                "Azure": "Azure Kubernetes"

            },
            "ECS": {
                "GCP": "Google Kubernetes Engine (GKE)",
                "Azure": "Azure Container Apps"
            },
            "ECR": {
                "GCP": "Google Container Registry",
                "Azure": "Azure Container Registry"
            },
            "S3 - Standard": {
                "GCP": "Cloud Storage",
                "Azure": "Storage Account"
            },
            "EFS": {
                "GCP": "Google Cloud Filestore",
                "Azure": null
            },
            "EBS": {
                "GCP": "Cloud Storage",
                "Azure": "Disk"
            },
            "VPC": {
                "GCP": "Google Cloud Virtual Private Cloud",
                "Azure": "Virtual Network"
            },
            "NAT Gateway": {
                "GCP": "Google Cloud Virtual Private Cloud",
                "Azure": "Virtual Network"
            },
            "Subnet": {
                "GCP": "Google Cloud Subnet",
                "Azure": "Subnet"
            },
            "Cloudfront": {
                "GCP": "CDN",
                "Azure": "CDN"
            },
            "Load balancer": {
                "GCP": "Cloud Load Balancing",
                "Azure": "Azure Load Balancing"
            },
            "Load balancer - Application": {
                "GCP": "Cloud Load Balancing",
                "Azure": "Azure Load Balancing"
            },
            "Load balancer - Network": {
                "GCP": "Cloud Load Balancing",
                "Azure": "Azure Load Balancing"
            },
            "Secret Manager": {
                "GCP": "Cloud Load Balancing",
                "Azure": "Key Vault"
            },
            "Secret manager": {
                "GCP": "Cloud Load Balancing",
                "Azure": "Key Vault"
            },
            "DynamoDB": {
                "GCP": "Cloud Bigtable",
                "Azure": "Azure SQL Database"
            },
            "RDS": {
                "GCP": "Cloud SQL",
                "Azure": null
            },
            "KMS": {
                "GCP": null,
                "Azure": null
            },
            "NA": {
                "GCP": "Databricks",
                "Azure": "Databricks"
            }
        },
        "Azure": {
            "VNet": {
                "AWS": "VPC",
                "GCP": "Google Cloud Virtual Private Cloud",
            },
            "Azure Function": {
                "AWS": "awslambda",
                "GCP": "Cloud Functions"
            },
            "Azure App Service": {
                "AWS": null,
                "GCP": "Google App Engine"
            },
            "Azure Kubernetes": {
                "AWS": "EKS",
                "GCP": "Google Kubernetes Engine (GKE)"
            },
            "Azure Container Registry": {
                "AWS": "ECR",
                "GCP": "Google Container Registry"
            },
            "Subnet": {
                "AWS": "Subnet",
                "GCP": "Google Cloud Subnet"
            },
            "CDN": {
                "AWS": "Cloudfront",
                "GCP": "CDN"
            },
            "Azure Load Balancing": {
                "AWS": "LoadBalancers",
                "GCP": "Cloud Load Balancing"
            },
            "Azure SQL Database": {
                "AWS": "DynamoDB",
                "GCP": "Cloud Bigtable"
            },
            "Cosmos DB": {
                "AWS": "RDS",
                "GCP": "Cloud SQL"
            },
            "NA": {
                "AWS": "RDS - Aurora",
                "GCP": "Cloud SQL"
            },
            "Azure Virtual Machine": {
                "AWS": "EC2",
                "GCP": "Compute Engine"
            }
        },
        "GCP": {
            "Cloud Functions": {
                "AWS": "Lambda",
                "Azure": "Azure Functions"
            },
            "Cloud Endpoints": {
                "AWS": "API Gateway",
                "Azure": null
            },
            "Google App Engine": {
                "AWS": null,
                "Azure": "Azure App Service"
            },
            "Google Kubernetes Engine (GKE)": {
                "AWS": "EKS",
                "Azure": "Azure Kubernetes"
            },
            "Google Container Registry": {
                "AWS": "ECR",
                "Azure": "Azure Container Registry"
            },
            "Google Cloud Storage": {
                "AWS": "S3 Bucket",
                "Azure": "Storage Account"
            },
            "Google Cloud Filestore": {
                "AWS": "EFS",
                "Azure": null
            },
            "Cloud Storage": {
                "AWS": "EBS",
                "Azure": "Disk"
            },
            "Google Cloud Virtual Private Cloud": {
                "AWS": "VPC",
                "Azure": "VNet"
            },
            "Google Cloud Subnet": {
                "AWS": "Subnet",
                "Azure": "Subnet"
            },
            "CDN": {
                "AWS": "Cloudfront",
                "Azure": "CDN"
            },
            "Cloud Load Balancing": {
                "AWS": "ELB",
                "Azure": "Azure Load Balancing"
            },
            "Cloud Bigtable": {
                "AWS": "DynamoDB",
                "Azure": "Azure SQL Database"
            },
            "Cloud SQL": {
                "AWS": "RDS",
                "Azure": "Cosmos DB"
            },
            "Databricks": {
                "AWS": null,
                "Azure": "Databricks"
            }
        }
    }
    //newly added;
    const [isNotChange, setIsNotChange] = useState(true);

    //Estimation_27;Estimation_28
    //This useefffect will be callled when the user changes the searchWord;
    useEffect(() => {
        isSearchAvailableFunction();
    }, [searchWord]);
    const parsePrice = (price) => {
        if (typeof price === 'string' && price.includes('$')) {
            price = Number(price)
            if (price == NaN) {
                price = 0
            }
            try {
                return parseFloat(price.replace('$', '')) || 0;
            }
            catch (error) {
                return price
            }
        }
        else {
            price = Number(price)
            if (price == NaN) {
                price = 0
            }
        }
        return typeof price === 'number' ? price : 0;
    };

    //Estimation_02;Estimation_11
    //This useEffect function will be invoked when the page load and will called when the user duration and currency dropdown;
    useEffect(() => {

        getConfigurationArray("nope", providerName);
    }, [duration, currency, renderTrigger]);

    // Helper function to convert prices
    const convertPrices = (data, rate) => {
        try {
            data.forEach(item => {
                item.totalPrice = parsePrice(item.totalPrice) * rate;
            });
        }
        catch (error) {
            console.log(error)
        }


    };
    //  THis function is Prepopulate the Cost when the User of other Roles Requested for Approval 

    async function getEstimateDetails(envId) {
        try {
            let payload = {
                "organizationId": orgId,
                "userId": localStorage.getItem('userId'),
                "envId": envId
            }
            let res = await getEstimateDetailsApi(payload)

            console.log(res.data, "This is the data for the response...")
            console.log(res, "Res for the getEstimateDetailsApi")
            debugger;
            if (res.success == true && res.message != "No record found for the given environment_id") {
                let ConfigurationData = res.data.ConfigurationData
                let approvalStatus = res.data.approvalStatus
                let isThresholdSet = res.data.isThresholdSet
                setPricingCalculatorCost(res.data.PricingCalculatorCost)


                console.log(typeof(ConfigurationData), "typeof")
                console.log(ConfigurationData, "typeof")



                console.log(ConfigurationData, "This is the configuration data to be fetched...")


                setCostArray(res.data.ConfigurationData)

                console.log("After the conf", "This is the configuration data to be fetched...")
                // Total Price is updated
                // setTimeout(() => {
                // }, 5000);

    //             let chartEle = document.documentElement.getElementsByClassName("hole");
    //             if (Number.isInteger(totalPrice)) {
    //                 totalPrice = totalPrice.toFixed(1); // Convert to string with one decimal place
    //             }
    //             console.log(totalPrice, 'check it !');
    //             setTotalPrice( Number(totalPrice))
    //             console.log(typeof totalPrice, "TotalPrice", totalPrice?.toFixed(2))
    //             console.log(totalPrice?.toFixed(2) != 0.0, chartEle[0] != undefined, 'Check ');

    //             if (totalPrice?.toFixed(2) != 0.0 && chartEle[0] != undefined) {
    //                 chartEle[0].style.position = "relative";
    //                 chartEle[0].innerHTML = `
    //   <div class="position-absolute top-50 start-50 translate-middle"><p class="font-14 font-bold widget-data-color" style="text-align: center;color: #67748e;margin: 0;font-size: 12px;margin-bottom: 5px;">Total Cost</p>
    //   <p class="font-16 font-bold widget-data-color estimationPrice" style="margin: 0;" data-bs-toggle="tooltip" data-bs-placement="right" title="${currency == "USD" ? "$" : "₹"
    //                     } ${totalPrice?.toFixed(2)}"><span class="font-12">${currency == "USD" ? "$" : "₹"
    //                     }</span>${totalPrice?.toFixed(2)}</p></div>
    //   `;
    //             }
    //             else {
    //                 chartEle[0].style.position = "relative";
    //                 chartEle[0].innerHTML = `
    //   <div class="position-absolute top-50 start-50 translate-middle"><p class="font-14 font-bold widget-data-color" style="text-align: center;color: #67748e;margin: 0;font-size: 12px;margin-bottom: 5px;">Total Cost</p>
    //   <p class="font-16 font-bold widget-data-color estimationPrice" style="margin: 0;" data-bs-toggle="tooltip" data-bs-placement="right" title="${currency == "USD" ? "$" : "₹"
    //                     } ${totalPrice?.toFixed(2)}"><span class="font-12">${currency == "USD" ? "$" : "₹"
    //                     }</span>${totalPrice?.toFixed(2)}</p></div>`;
    //             }
                setSetEstimateAsBudget(isThresholdSet)



                if (approvalStatus?.toLowerCase() == "pending") {
                    setisapprovalStatus(true)
                    return false

                } else {
                    setisapprovalStatus(true)
                    return true
                }

            } else {
                return true
            }


        }
        catch (err) {
            console.log(err, "Error in getEstimateDetails ")
            return true
        }

    }



    console.log(costArray,"This is the cost Array....")

    //Estimation_03;Estimation_09;Estimation_26
    //This function is used to get the cost of the services using the api
    async function getConfigurationArray(type, provider) {
        // debugger
        try {
            if (provider) {
                console.log(type, provider, "Current providerName State")
                setIsNotChange(true);
                let newNodes = [];
                let i = 1;


                if (userRoles == "VersionView@view" || userRoles == "Viewer") {
                    setIsViewer(true);
                }
                // Skip 
                try {
                    for (let values of estimationNodes) {
                        // debugger;
                        console.log(values?.data.serviceFamily, "instanceName")
                        let jsonData = {
                            serviceName: values?.data.serviceName,
                            instanceName: values.instanceName || values.data.instanceName,
                            id: values.id,
                            serviceFamily: values?.data.serviceFamily || values?.data.ConfigureDetail?.serviceFamily,
                        };
                        console.log(jsonData)
                        newNodes.push(jsonData);
                        i++;
                    }
                } catch (error) {
                    console.log(error)
                }
                let newArrOptionClick = []
                if (type == "OptionClick" && provider != "") {
                    let temp = newNodes
                    console.log(temp, "Temp")
                    for (let val of temp) {

                        // Check if the service name exists in the mapping and handle possible variations
                        let serviceName = val.serviceName;


                        if (!Mapping[providerName][serviceName]) {
                            // Handle variations or misspellings if necessary
                            // For example, convert to lowercase and remove spaces for comparison
                            serviceName = serviceName?.toLowerCase().replace(/\s+/g, '');
                        }
                        // console.log(Mapping[providerName][serviceName][providerName])
                        // console.log(Mapping[providerName][serviceName])

                        if (Mapping[providerName][serviceName] && Mapping[providerName][serviceName][provider]) {
                            let serviceNames = Mapping[providerName][serviceName][provider];
                            console.log(serviceNames, "ServiceName Azure");

                            // If the service name is not null or undefined, add it to newArrOptionClick
                            if (serviceNames != null && serviceNames != undefined && serviceNames != "") {
                                let jsonData = {
                                    serviceName: serviceNames,
                                    serviceNames: serviceNames,
                                    instanceName: newArrOptionClick.some(item => item.serviceName === serviceNames) ?
                                        (() => {
                                            // Find the count of occurrences of the service name
                                            let count = newArrOptionClick.filter(item => item.serviceName === serviceNames).length;
                                            // Increment the count by 1
                                            count++;
                                            // Append the count to the service name
                                            return `${serviceNames} ${count}`;
                                        })() :
                                        `${serviceNames}1`,
                                    serviceFamily: "Network",

                                };
                                if (providerName == "Azure" && type == "OptionClick") {
                                    jsonData["id"] = providerName + random(10).toString()
                                }
                                newArrOptionClick.push(jsonData);
                            }
                        }
                    }
                    newNodes = newArrOptionClick
                }
                let region = ""
                for (let values of newNodes) {
                    // debugger
                    if (providerName == "Azure" || providerName == "Azure" && type != "OptionClick" || provider == "Azure") {
                        if (values.serviceName == "Azure App Service" || values.serviceName == "App_Service") {
                            values["serviceName"] = "Azure App Service"
                            values["serviceFamily"] = "Application";
                            values["region"] = "us-east";
                            values["type"] = "windows";
                            values["tier"] = "free";
                            values["size"] = "f1";
                            values["instances"] = "1";
                            values["hoursFactor"] = "1";
                            values["hours"] = "2"
                            values["isolatedOneStampInstances"] = "3";
                            values["isolatedTwoStampInstances"] = "4";
                            values["isolatedThreeStampInstances"] = "5";
                            values["instanceType"] = "i1v2"
                            values["isolatedV2StampInstances"] = "6";
                            values["isolatedV2DedicatedHostInstances"] = "7";
                            values["isChanges"] = true;

                        } else if (values.serviceName == "Azure Container Registry" || values.serviceName == "Container_Registry") {
                            values["serviceName"] = "Azure Container Registry"
                            values["serviceFamily"] = values?.serviceFamily || "Containers";
                            values["region"] = "us-east-2";
                            values["tier"] = "basic";
                            values["days"] = "1";
                            values["storageUnitsFactor"] = "1";
                            values["storageUnits"] = "1";
                            values["cpus"] = "1";
                            values["buildUnits"] = "1";
                            values["dataTransferType"] = "interregion";
                            values["destinationRegion"] = "";
                            values["interRegionUnits"] = "1";
                            values["routedVia"] = "microsoftglobalnetwork";
                            values["internetEgressUnits"] = "1";
                            values["addGeoReplication"] = "Yes";
                            values["geoReplicatedRegions"] = "1";
                            values["tier"] = "premium";
                            values["tier"] = "standard";
                            values["dataTransferType"] = "interregion";
                            values["routedVia"] = "publicinternet";
                            values["isChanges"] = true;
                        } else if (values.serviceName == "Azure Front Door" || values.serviceName == "Azure_Front_Door") {
                            values["serviceFamily"] = "Network";
                            values["isChanges"] = true;
                            values["tier"] = "frontdoorstandard";
                            values["outboundDataTransferZones_0"] = "classiczone5";
                            values["outboundDataTransferUnits_0"] = "1000000000000";
                            values["outboundDataTransferUnitsFactor_0"] = "1";
                            values["outboundDataTransferUnits_0"] = "1";
                            values["inboundUnits"] = "1";
                            values["inboundUnitsFactor"] = "1";
                            values["routingUnits"] = "1";
                            values["routingHoursFactor"] = "730";
                            values["routingHours"] = "1";
                            values["additionalRoutingUnits"] = "1";
                            values["additionalRoutingHoursFactor"] = "24";
                            values["additionalRoutingHours"] = "1";
                            values["additionalDomainUnits"] = "1";
                            values["policyUnits"] = "1";
                            values["customRules"] = "1";
                            values["customRequestUnits"] = "1";
                            values["managedRulesetUnits"] = "1";
                            values["managedRulesetRequests"] = "1";
                            values["zone"] = "zone8"
                            values["baseCost"] = "yes"
                            values["internetEgressFactor"] = "1"
                            values["internetEgressCount"] = "1"
                            values["internetIngressFactor"] = "1"
                            values["internetIngressCount"] = "1"
                            values["standardPremiumRequests"] = "1"

                        } else if (values.serviceName == "Azure Functions" || values.serviceName == "Azure_Functions" || values.serviceName == "Azure Function" || values.serviceName == "Azure Function- Premium" || values.serviceName == "Azure Function- Consumption") {
                            values["serviceFamily"] = "Compute"
                            values["region"] = "us-east"
                            values["tier"] = "consumption"
                            values["executionTime"] = "10000000"
                            values["executionCount"] = "100"
                            values["memorySize"] = "1536"
                            values["instance"] = "ep1-payg"
                            values["preWarmedCount"] = "1"
                            values["preWarmedCountHoursFactor"] = "24"
                            values["preWarmedCountHours"] = "31"
                            values["additionalUnitsCount"] = "1"
                            values["additionalUnitsCountHours"] = "31"
                            values["additionalUnitsCountHoursFactor"] = "24"
                            values["isChanges"] = true;
                        } else if (values.serviceName == "AKS" || values.serviceName == "Azure Kubernetes") {
                            values["serviceName"] = "Azure Kubernetes"
                            values["serviceFamily"] = "Containers";
                            values["region"] = "us-east";
                            values["kubernetesServiceOfferTier"] = "standard";
                            values["operatingSystem"] = "linux";
                            values["category"] = "generalpurpose";
                            values["instanceSeries"] = "a"
                            values["instanceType"] = "A2: 2"
                            values["clusterCount"] = "0"
                            values["addSnapShot"] = "yes"
                            values["count"] = "1"
                            values["hours"] = "1"
                            values["hoursFactor"] = "1"

                            values["managedDiskTier"] = "standardhdd"
                            values["managedSnapshotRedundancy"] = "lrs"
                            values["addSnapShot"] = "no"
                            values["snapshotStorage"] = "1"
                            values["managedDiskType"] = "p1"
                            values["managedDisks"] = "0"
                            values["isChanges"] = true;
                        } else if (values.serviceName == "Azure Open AI" || values.serviceName == "Azure_Open_AI" || values.serviceName == "AzureOpenAI" || values.serviceName == "Open AI") {
                            values["serviceFamily"] = "AI";
                            values["serviceName"] = "Azure Open AI";
                            values["region"] = 'us-east'
                            values["selectedModelSeries"] = "base-models"
                            values["modelForLanguage"] = "gpt-turbo-sixteenk-0125";
                            values["prompt"] = "1";
                            values["completion"] = "1"
                            values["modelForAssistantsAPI"] = "code-interpreter"
                            values["sessions"] = "1"
                            values["baseModel"] = "babbage002"
                            values["tokens"] = "1"
                            values["modelForFineTuning"] = "babbage002"
                            values["fineTuningInput"] = "1"
                            values["fineTuningOutput"] = "1"
                            values["fineTuningHosting"] = "1"
                            values["modelForImage"] = "dall-e-two"
                            values["standardLowInput"] = "1"
                            values["standardHighInput"] = "1"
                            values["hdLowInput"] = "1"
                            values["hdHighInput"] = "1"
                            values["modelForEmbedding"] = "ada"
                            values["modelForSpeech"] = "whisper"
                            values["isChanges"] = true;
                        } else if (values.serviceName == "Azure Private Endpoint" || values.serviceName == "Private Endpoint" || values.serviceName == "Azure_Private_Endpoint" || values.serviceName == "Azure_Private_Endpoint") {
                            values["serviceFamily"] = "Network";
                            values["region"] = "us-east"
                            values["privateEndpointUnits"] = "1";
                            values["privateEndpointHoursFactor"] = "730";
                            values["privateEndpointHours"] = "1";
                            values["outboundDataFactor"] = "1024";
                            values["outboundDataUnits"] = "1";
                            values["inboundDataFactor"] = "1024";
                            values["inboundDataUnits"] = "1";
                            values["isChanges"] = true;
                        } else if (values.serviceName == "Azure Virtual Desktop") {
                            values["serviceFamily"] = "Compute";
                            values["isChanges"] = true;
                            values["region"] = "us-east"
                            values["serviceType"] = "avd"
                            values["virtualCores"] = "1"
                            values["hoursPerMonth"] = "1"
                            values["type"] = "personal"
                            values["session"] = "single-session"
                            values["workloadType"] = "power"
                            values["scalableOption"] = "constant-availability"
                            values["managedDiskTier"] = "premiumssd"
                            values["managedDiskType"] = "p10"
                            values["dataTransferType"] = "internetegress"
                            values["namedUsers"] = "1"
                            values["usageHours"] = "1"
                            values["peakConcurrencyCount"] = "1"
                            values["offPeakConcurrencyCount"] = "1"
                            values["category"] = "computeoptimized"
                            values["instanceSeries"] = "f"
                            values["destinationRegion"] = "us-east"
                            values["interRegionUnits"] = "1"
                            values["routedVia"] = "microsoftglobalnetwork"
                            values["internetEgressUnits"] = "1"
                            values["instance"] = "F2 :2"
                            values["osCoreUsage"] = true
                        } else if (values.serviceName == "Azure Virtual Machine") {
                            values["serviceName"] = "Azure Virtual Machine"
                            values["serviceFamily"] = "Compute";
                            values["region"] = 'us-east'
                            values["operatingSystem"] = "linux"
                            values["type"] = "ubuntu"
                            values["tier"] = "basic"
                            values["category"] = "generalpurpose"
                            values["instanceSeries"] = "a"
                            values["instanceType"] = "A1: 1"
                            values["managedDiskTier"] = "standardssd"
                            values["snapshotStorage"] = "1"
                            values["managedSnapshotRedundancy"] = "lrs"
                            values["addSnapShot"] = "yes"
                            values["storageV2Size"] = "1"
                            values["storageV2Number"] = "1"
                            values["storageV2HoursFactor"] = "1"
                            values["storageV2Hours"] = "1"
                            values["storageV2Iops"] = "1"
                            values["storageV2Throughput"] = "1"
                            values["managedDiskType"] = "p1"
                            values["managedDisks"] = "1"
                            values["managedDiskRedundancy"] = "zrs"
                            values["count"] = "1"
                            values["hoursFactor"] = "1"
                            values["hours"] = "1"
                            values["isChanges"] = true;
                        } else if (values.serviceName == "Container Apps" || values.serviceName == "Azure Container Apps" || values.serviceName == "Container_Apps" || values.serviceName == "Azure_Container_Apps") {
                            values["serviceName"] = "Azure Container Apps"
                            values["serviceFamily"] = values?.serviceFamily || "Network"
                            values["region"] = 'us-east';
                            values["planType"] = 'consumption';
                            values["requestsInMillions"] = '1';
                            values["activeUsageConcurrentRequests"] = '1';
                            values["activeUsageExecutionMsPerRequest"] = '1';
                            values["dedicatedInstances"] = '1';
                            values["dedicatedInstanceHoursFactor"] = "730";
                            values["dedicatedInstanceHours"] = "1";
                            values["dedicatedInstanceCount"] = "1";
                            values["workload"] = 'D4';
                            values["tier"] = 'free';
                            values["vCpuCount"] = "0.25";
                            values["memorySize"] = "2";
                            values["gpuWorkload"] = "A100";
                            values["sessions"] = "1";
                            values["sessionHours"] = "1";
                            values["sessionHoursFactor"] = "730";
                            values["RequiredMinimumReplicas"] = "no";
                            values["isChanges"] = true;
                        } else if (values.serviceName == "Databricks") {
                            values["serviceName"] = "Databricks"
                            values["serviceFamily"] = "Compute";
                            values["region"] = "us-east";
                            values["workload"] = "jobs-compute";
                            values["tier"] = "premium";
                            values["category"] = "computeoptimized";
                            values["clustersize"] = "twoxsmall";
                            values["clusters"] = "1";
                            values["dbuHours"] = "1";
                            values["dbuHoursFactor"] = "1";
                            values["instance"] = "1";
                            values["instanceHours"] = "1";
                            values["instanceHoursFactor"] = "1";
                            values["count"] = "1";
                            values["hoursFactor"] = "1";
                            values["hours"] = "1";
                            values["instanceSeries"] = "f";
                            values["instanceType"] = "F8:8";
                            values["isChanges"] = true;
                        } else if (values.serviceName == "Disk") {
                            values["serviceName"] = "Disk"
                            values["serviceFamily"] = "Container";
                            values["region"] = "us-east";
                            values["tier"] = "standardssd";
                            values["managedDiskType"] = "e1";
                            values["managedDisks"] = "1";
                            values["managedDisksTransactions"] = "1";
                            values["AddSnapshot"] = "Yes";
                            values["managedSnapshotRedundancy"] = "lrs";
                            values["snapshotStorage"] = "1";
                            values["managedDiskRedundancy"] = "lrs";
                            values["storageV2Number"] = "1";
                            values["storageV2Size"] = "1";
                            values["storageV2HoursFactor"] = "1";
                            values["storageV2Hours"] = "1";
                            values["storageV2Iops"] = "1";
                            values["storageV2Throughput"] = "1";
                            values["ultraSize"] = "8";
                            values["ultraDisks"] = "1";
                            values["ultraHoursFactor"] = "1";
                            values["ultraHours"] = "1";
                            values["ultraIops"] = "1";
                            values["ultraThroughput"] = "1";
                            values["isChanges"] = true;

                        } else if (values.serviceName == "Key Vault" || values.serviceName == "Key_Vault" || values.serviceName == "Azure Key Vault" || values.serviceName == "Azure_Key_Vault") {
                            values.serviceName = "Key Vault"
                            values["serviceFamily"] = "Security & Identity";
                            values["operations"] = "1";
                            values["advancedOperations"] = "1";
                            values["renewals"] = "1";
                            values["keys"] = "1";
                            values["advancedKeys"] = "1";
                            values["hsmPoolsUnits"] = "1";
                            values["hsmPoolsHours"] = "1";
                            values["hsmPoolsHoursFactor"] = "1";
                            values["isChanges"] = true;
                            values["region"] = "us-east";
                        } else if (values.serviceName == "Azure Load Balancing" || values.serviceName == "Load Balancing") {
                            values["serviceName"] = "Azure Load Balancing"
                            values["serviceFamily"] = "Network";
                            values["tier"] = "gateway";
                            values["rules"] = "1";
                            values["capacityFactor"] = "1024";
                            values["capacity"] = "5000";
                            values["gatewayHours"] = "1";
                            values["chainHours"] = "1";
                            values["dataProcessedUnitFactor"] = "1";
                            values["dataProcessedUnits"] = "1";
                            values["isChanges"] = true;
                            values["region"] = "us-east";
                        } else if (values.serviceName == "Log Analytic") {
                            values["region"] = "us-east";
                            values["serviceFamily"] = "Network"
                            values["dailyLogsIngested"] = "1";
                            values["basicLogsIngested"] = "1";
                            values["searchJobsTotalNumberOfQueries"] = "1";
                            values["searchJobsTotalScannedPerQueries"] = "1";
                            values["isChanges"] = true;
                        } else if (values.serviceName == "Public IPAddress" || values.serviceName == "Public IPAddress") {
                            values["serviceFamily"] = "Network";
                            values["region"] = "us-east"
                            values["type"] = "basicarm";
                            values["dynamicAddresses"] = "1";
                            values["dynamicAddressHoursFactor"] = "24";
                            values["dynamicAddressHours"] = "31";
                            values["staticAddresses"] = "1";
                            values["staticAddressHoursFactor"] = "24";
                            values["staticAddressHours"] = "31";
                            values["publicIPPrefix"] = "1";
                            values["publicIPPrefixHours"] = "31";
                            values["publicIPPrefixHoursFactor"] = "24";
                            values["isChanges"] = true;
                        } else if (values.serviceName == "Azure SQL Database" || values.serviceName == "Azure_SQL_Database" || values.serviceName == "SQL Database" || values.serviceName == "SQL_Database") {
                            values["serviceName"] = "Azure SQL Database"
                            values["serviceFamily"] = "Database";
                            values["region"] = 'us-east'
                            values["type"] = "elastic"
                            values["purchaseModel"] = "dtu"
                            values["vcoreTier"] = "general-purpose"
                            values["generation"] = "gen5"
                            values["instanceSize"] = "2"
                            values["zoneRedundancy"] = "local"
                            values["redundancy"] = "lrs"
                            values["singleCount"] = "1"
                            values["dtuTier"] = "basic"
                            values["computeTier"] = "provisioned"
                            values["elasticPerformanceLevel"] = "50"
                            values["singlePerformanceLevel"] = "b"
                            values["elasticCount"] = "1"
                            values["hoursFactor"] = "1"
                            values["hours"] = "1"
                            values["elasticStorageUnits"] = "1"
                            values["backupStorageSize"] = "1"
                            values["ltrDatabaseSizeFactor"] = "1"
                            values["ltrDatabaseSize"] = "1"
                            values["WeeklyBackupRetention"] = "yes"
                            values["MonthlyBackupRetention"] = "yes"
                            values["YearlyBackupRetention"] = "yes"
                            values["weeklyBackups"] = "1"
                            values["monthlyBackups"] = "1"
                            values["yearlyBackups"] = "1"
                            values["disasterRecovery"] = "primary-or-georeplica"
                            values["singleHyperscaleReplicaUnits"] = "1"
                            values["singleHyperscaleReplicaHours"] = "1"
                            values["singleHyperscaleReplicaHoursFactor"] = "24"
                            values["namedReplicaUnits"] = "122"
                            values["namedReplicaHours"] = "123"
                            values["namedReplicaHoursFactor"] = "24"
                            values["singleHyperscaleStorageUnits"] = "177"
                            values["singleHyperscaleStorageUnitsFactor"] = "178"
                            values["cpuUsed"] = "1"
                            values["memoryUsed"] = "33"
                            values["duration"] = "199"
                            values["singleStorageUnits"] = "1666";
                            values["isChanges"] = true;
                        } else if (values.serviceName == "Storage Account" || values.serviceName == "Azure_Storage_Account" || values.serviceName == "Storage_Account" || values.serviceName == "Azure Storage Account") {
                            values["id"] = values.id
                            values["serviceName"] = "Storage Account"
                            values["serviceFamily"] = "Container";
                            values["region"] = "us-east"
                            values["storageAccountName"] = "block-blob"
                            values["performanceTier"] = "standard"
                            values["count"] = "1"
                            values["storageUnits"] = "1"
                            values["blobWriteOperations"] = "1"
                            values["blobCreateContainerOperations"] = "1"
                            values["blobReadOperations"] = "1"
                            values["blobOtherOperations"] = "1"
                            values["storageAccountType"] = "general-purpose"
                            values["storageAccountType"] = "general-purpose-v2"
                            values["fileStructure"] = "flat"
                            values["accessTier"] = "hot"
                            values["redundancy"] = "lrs"
                            values["blobWriteOperationsV1"] = "1"
                            values["blobCreateContainerOperationsV1"] = "1"
                            values["blobReadOperationsV1"] = "1"
                            values["blobOtherOperationsV1"] = "1"
                            values["blobDataRetrievalUnits"] = "1"
                            values["blobDataRetrieval"] = "1"
                            values["blobDataWriteUnits"] = "1"
                            values["blobDataWrite"] = "1"
                            values["blobArchiveRead"] = "1"
                            values["blobArchiveRetrievalUnits"] = "1"
                            values["blobArchiveRetrieval"] = "1";
                            values["isChanges"] = true;
                        } else if (values.serviceName == "Virtual Network" || values.serviceName == "VNet") {
                            values["serviceName"] = "Virtual Network";
                            values["serviceFamily"] = "Network";
                            values["vNet1Region"] = "us-east";
                            values["vNet2Region"] = "us-east";
                            values["vNet1PeeringFactor"] = "1";
                            values["vNet1Peering"] = "1";
                            values["vNet2PeeringFactor"] = "1";
                            values["vNet2Peering"] = "1";
                            values["isChanges"] = true;
                        }
                        values["id"] = values.serviceName + Math.random(10)
                    }
                    if (providerName === "AWS" && type != "OptionClick" || provider == "AWS") {
                        //SQ_73 to SQ_77
                        // set the defualt value and Pre-populate the fields for DynamoDB
                        if (values.serviceName == "DynamoDB" || values.serviceName == "AWSDynamoDB" || values.serviceName == "Dynamo DB") {
                            values["serviceFamily"] = "Database";
                            values["serviceName"] = "DynamoDB";
                            values["region"] = region !== "" ? region : "us-east-1";
                            values["request"] = "1000000";
                            values["reqType"] = "4";
                        }
                        //SQ_63 to SQ_72
                        // set the defualt value and Pre-populate the fields for CloudFront
                        else if (values.serviceName == "Cloudfront" || values.serviceName == "CloudFront") {
                            values["servivceName"] = "Cloudfront";
                            values["dataTransferOut"] = "1";
                            values["dataTransferOutUnit"] = "GB";
                            values["fromLocation"] = "Canada";
                            values["dataTransferOutOrigin"] = "1";
                            values["dataTransferOutOriginUnit"] = "GB";
                            values["noOfRequests"] = "1";
                            values["noOfRequestsUnit"] = "per month";
                            values["serviceFamily"] = "Storage";
                            values["region"] = region !== "" ? region : "us-east-1";
                        }
                        //SQ_46 to SQ_53
                        // set the defualt value and Pre-populate the fields for EFS
                        else if (values.serviceName == "EFS" || values.serviceName == "Amazon EFS") {
                            values["EFSstorageType"] = "1";
                            values["region"] = region !== "" ? region : "us-east-1";
                            values["capacity"] = "10";
                            values["capacityType"] = "1";
                            values["frequentpercentage"] = "20";
                            values["InfrequentAccessRequest"] = "10";
                            values["InfrequentAccessRequestType"] = "1";
                            values["ThroughputType"] = "1";
                            values["provisionedThroughput"] = "10";
                            values["provisionedThroughputUnit"] = "2";
                            values["serviceFamily"] = "Storage";

                        }
                        //SQ_37 to SQ_45 and SQ_54 to SQ_62
                        // set the defualt value and Pre-populate the fields for RDS
                        else if (values.serviceName == "RDS" || values.serviceName == "Amazon RDS" || values.serviceName === "RDS Aurora MYSQL" || values.serviceName === "RDS Aurora Serverless MYSQL" || values.serviceName === "RDS MYSQL" || values.serviceName == "RDS Proxy") {
                            values["EngineType"] = values["EngineType"] ? values["EngineType"] : 'MySQL' || "MySQL"
                            if (values["EngineType"] == "MySQL") {
                                try {
                                    values['EngineType'] = "MySQL"
                                    values['sqltype'] = "mysql"
                                    values["region"] = region !== "" ? region : "us-east-1";
                                    values["utilization1"] = "730";
                                    values["utilizationUnit1"] = "perMonth";
                                    values["deploymentOption1"] = "Single-AZ";
                                    values["instanceType1"] = "db.m4.large";
                                    values["noOfNodes1"] = "100";
                                    values["RDSproxy1"] = "No";
                                    values["Storage1"] = "100";
                                    values["storageType1"] = "gp2 General Purpose (SSD)";
                                    values["StorageUnit1"] = "GB";
                                    values["EngineType"] = "MySQL";
                                    // 2 for thiru
                                    values["serviceFamily"] = "Database"
                                    values["location2"] = "US East (Ohio)";
                                    values['Mysqltype2'] = "Aurora MySQL-Compatible"
                                    values["AuroraMySQLClusterConfiguration2"] = "Aurora Standard"
                                    values["AuroraMySQLCompatible2"] = "True"
                                    values["Nodes2"] = 1
                                    values["AuroraMySQLInstanceType2"] = "db.t3.small";
                                    values["AuroraMySQLCompatibleinstancesValue2"] = 10
                                    values["AuroraMySQLCompatibleinstancesUnit2"] = "%Utilized/Month"
                                    values["AuroraMySQLCompatibleinstancesInstanceFamily2"] = "Memory optimized"
                                    values["AuroraMySQLCompatibleinstancesPricingModel2"] = "OnDemand"
                                    values["RDSProxy2"] = "Yes"
                                    values["DatabaseStorage2"] = "True"
                                    values["StorageamountUnit2"] = "GB"
                                    values["StorageamountValue2"] = 1
                                    values["BaselineIOrateValue2"] = 1
                                    values["BaselineIOrateUnit2"] = "per second"
                                    values["PeakIOrateValue2"] = 1
                                    values["PeakIOrateUnit2"] = "per second"
                                    values["DurationofpeakIOactivityValue2"] = 1
                                    values["DurationofpeakIOactivityUnit2"] = "hours per month"
                                    values["DatabaseStorageAuroraIOOptimized2"] = "True"
                                    values["AuroraIOStorageamountValue2"] = 1
                                    values["AuroraIOStorageamountUnit2"] = "GB"
                                    // 3 for sagin
                                    values["AuroraMySQLClusterConfiguration"] = "Aurora Standard";
                                    values["AuroraServerlessVersion"] = "Aurora Serverless v1";
                                    values["NoofACU"] = "1";
                                    values["ACUunit"] = "per hour";
                                    values["RDSProxy"] = "No";
                                    values["StorageAmount"] = "1";
                                    values["StorageAmountUnit"] = "mb";
                                    values["BaselineIOrateValue"] = "1";
                                    values["BaselineIOrateunit"] = "per second";
                                    values["PeakIOrateValue"] = "1";
                                    values["PeakIorateunit"] = "per second";
                                    values["PeakDurationValue"] = "1";
                                    values["PeakDurationunit"] = "hours per month";
                                }
                                catch (err) {
                                    console.log(err, "RDS Err!!")
                                }

                            }
                            else if (values?.ConfigureValue["EngineType"] == "Microsoft SQL Server") {
                                values["region"] = region !== "" ? region : "us-east-1";
                                values["utilization"] = "730";
                                values["serviceFamily"] = "Database"
                                values["utilizationUnit"] = "perMonth";
                                values["deploymentOption"] = "Single-AZ";
                                values["serviceFamily"] = "Database"
                                values["instanceType"] = "db.m4.large";
                                values["noOfNodes"] = "0";
                                values["databaseEdition"] = "Standard";
                                values["RDSproxy"] = "No";
                                values["Storage"] = "20";
                                values["storageType"] = "gp2 General Purpose (SSD)";
                                values["StorageUnit"] = "GB";
                                values["EngineType"] = "Microsoft SQL Server";

                            }
                        }

                        // PS_CE_41 - PS_CE_44 to check whtether it EKS and assign the respective key values
                        else if (values.serviceName == "EKS") {
                            values["serviceFamily"] = "Compute";
                            values["eksClusters"] = 1;
                            values["serviceName"] = "EKS"
                            values["region"] = region != "" ? region : "us-east-1";
                        }
                        // PS_CE_34 - PS_CE_40 to check whtether it KMS and assign the respective key values
                        else if (values.serviceName == "AWSKMS" || values.serviceName == "KMS") {
                            values["cmk"] = 5;
                            values["symmetricRequests"] = 2000000;
                            values["asymmetricRequestsExcepted"] = 10;
                            values["asymmetricRequestsInvolved"] = 10;
                            values["ECCGenerateDataKeyPair"] = 10;
                            values["serviceName"] = "KMS"
                            values["region"] = region != "" ? region : "us-east-1";
                        }
                        // PS_CE_76 - PS_CE_80 to check whtether it VPC and assign the respective key values
                        else if (values.serviceName == "NAT" || values.serviceName == "VPC") {
                            values["noOfNAT"] = 1;
                            values["dataPerNAT"] = 1;
                            values["unitNAT"] = "GB per month";
                            values["subServiceName"] = "NAT Gateway";
                            values["serviceName"] = "VPC"
                            values["region"] = region != "" ? region : "us-east-1";
                        }
                        // PS_CE_45 - PS_CE_75 to check whtether it LoadBalancers and assign the respective key values
                        else if (values.serviceName == "Elastic Load Balancing" || values.serviceName == "Elastic Load Balancing V2" || values.serviceName == "LoadBalancers" || values.serviceName == "Load balancer - Application" || values.serviceName == "Load balancer - Network") {
                            console.log(values.subService, ")))))))))))))))))))))))))))")
                            values["subService"] = (values.subService == "alb") ? "Application" : (values.subService == "nlb") ? "Network" : "Application" || "Application";
                            values["serviceFamily"] = "Network";
                            values["serviceName"] = "Elastic Load Balancing";
                            values["loadBalancer"] = 1;
                            values["bytesLambda"] = 1;
                            values["unitBL"] = "GB per hour";
                            values["bytesEC2"] = 1;
                            values["unitBE"] = "GB per hour";
                            values["newConnections"] = 1;
                            values["unitNC"] = "per second";
                            values["connectionDuration"] = 1;
                            values["unitCD"] = "seconds";
                            values["reqPerSecond"] = 1;
                            values["ruleEval"] = 1;
                            values["serviceName"] = "LoadBalancers"
                            values["type"] = values.subService;
                            values["region"] = region != "" ? region : "us-east-1";
                            values["NLBforTCP"] = 1;
                            values["unitTCP"] = "GB per hour";
                            values["newTCPconn"] = 1;
                            values["unitTCPconn"] = "per second";
                            values["TCPconnDuration"] = 1;
                            values["unitTCPconnDur"] = "seconds";
                            values["NLBforUDP"] = 1;
                            values["unitUDP"] = "GB per hour";
                            values["newUDPflows"] = 2;
                            values["unitUDPflows"] = "per second";
                            values["UDPflowDuration"] = 1;
                            values["unitUDPflowDur"] = "seconds";
                            values["NLBforTLS"] = 1;
                            values["unitTLS"] = "GB per hour";
                            values["newTLSconn"] = 1;
                            values["unitTLSconn"] = "per second";
                            values["TLSconnDuration"] = 1;
                            values["unitTLSconnDur"] = "seconds";
                        }
                        // PS_CE_36 to PS_CE_49
                        // set the defualt value and Pre-populate the fields for API_Gateway 
                        else if (values.serviceName == "API Gateway" || values.serviceName == "API_Gateway" || values.serviceName == "API Gateway V2" || values.serviceName == "APIGateway" || values.serviceName == "API Gateway- HTTP" || values.serviceName == "API Gateway- REST") {
                            values["location"] = values.location ? values.location : "US East (Ohio)";
                            values["region"] = values.region ? values.region : "us-east-1";
                            values["serviceFamily"] = "Network";
                            values["serviceName"] = "API Gateway";
                            values["APIType"] = "API Gateway HTTP";
                            values["APIRequestsUnit"] = "millions";
                            values["Requests"] = 10;
                            values["UnitByTime"] = "per month";
                            values["AverageSizeRequest"] = 10;
                            values["UnitBySize"] = "KB";
                            values["APIRequestsUnit1"] = "millions";
                            values["Requests1"] = 2;
                            values["UnitByTime1"] = "per month";
                            values["Cachememorysize"] = "None";
                        }
                        // PS_CE_50 to PS_CE_72
                        // set the defualt value and Pre-populate the fields for SNS
                        else if (values.serviceName == "SNS" || values.serviceName == "SNS - Standard" || values.serviceName == "SNS - FIFO") {
                            values["SNStype"] = "SNS-Standard";
                            values["serviceFamily"] = "Application and Integration";
                            values["serviceName"] = "SNS";
                            values["location"] = values.location ? values.location : "US East (Ohio)";
                            values["region"] = values.region ? values.region : "us-east-1";
                            values["standardReqType"] = "million per month";
                            values["httpNotificationType"] = "million per month";
                            values["emailNotificationType"] = "million per month";
                            values["sqsNotificationType"] = "million per month";
                            values["kinesisDataType"] = "million per month";
                            values["standardReq"] = 100;
                            values["httpNotification"] = 100;
                            values["emailNotification"] = 100;
                            values["sqsNotification"] = 100;
                            values["kinesisData"] = 100;
                            values["Requests"] = 100;
                            values["RequestsUnit"] = "million per month";
                            values["AverageMessageSize"] = "KB";
                            values["AverageMessageSizeUnit"] = 100;
                            values["subscriptions"] = 100;
                            values["RetentionPeriod"] = 100;
                            values["RetentionPeriodUnit"] = "days"
                        }
                        // PS_CE_33 to PS_CE_34 these are the default field selected of s3 services 
                        else if (values.serviceName == "Amazon S3" || values.serviceName == "S3 Bucket" || values.serviceName == "S3 - Standard") {
                            values["serviceFamily"] = "Storage"
                            values["standardStorage"] = "1";
                            values["standardStorageunit"] = "gb";
                            values["dataMoved"] = "specified_amount";
                            values["AverageObjectSize"] = "1";
                            values["ObjectSizeUnit"] = "bytes";
                            values["putRequest"] = "1";
                            values["getRequest"] = "1";
                            values["dataScanned"] = "1";
                            values["dataReturned"] = "1";
                            values["dataReturnedUnit"] = "gb";
                            values["dataScannedUnit"] = "gb";
                            values["serviceName"] = "Amazon S3";
                            values["bucketName"] = values.ConfigureValue?.Bucket ? values.ConfigureValue?.Bucket : "S3";
                            values["region"] = region != "" ? region : "us-east-1";
                        }
                        // PS_CE_35 for these are the default field selected of ECR services 
                        else if (values.serviceName == "ECR") {
                            values["serviceFamily"] = "Container"
                            values["dataStored"] = "0";
                            values["dataStoredunit"] = "mb";
                            values["serviceName"] = "ECR";
                            values["region"] = region != "" ? region : "us-east-1";
                        }
                        // PS_CE_36 for these are the default field selected of DMS services 
                        else if (values.serviceName == "DMS" || values.serviceName == "DMS - Serverless") {
                            values["serviceFamily"] = "Database";
                            values["Numberofinstances"] = "1";
                            values["SelectedInstance"] = "t2.micro";
                            values["DeploymentOption"] = "Single";
                            values["StorageAmount"] = "1";
                            values["StorageAmountUnit"] = "gb";
                            values["DMStype"] = "OnDemand";
                            values["NumberofDCU"] = "1";
                            values["AvailabilityZone"] = "Single";
                            values["storageDMS"] = "gp2";
                            values['serviceName'] = "DMS";
                            values['region'] = region != "" ? region : "us-east-1";
                        }
                        else if (values.serviceName == "Secret Manager" || values.serviceName == "Secret_Manager" || values.serviceName == "Secret manager") {
                            // values["region"] = values.ConfigureValue.region ? values.ConfigureValue.region : "us-east-1";
                            values['region'] = region != "" ? region : "us-east-1";
                            values["SecretsManagerAverage"] = "10";
                            values["SecretsManagerAPICalls"] = "10";
                            values["averageDurationOfEachSecret"] = "10";
                            values["averageUnitDurtion"] = "hours";
                            values["apiCallsPerUnit"] = "perHour";
                            values['serviceFamily'] = "Security & Identity";
                        }
                        // PS_CE_42-46
                        else if (values.serviceName == "Cognito - User Pools" || values.serviceName == "Amazon Cognito" || values.serviceName == 'Cognito user pools' || values.serviceName == "Cognito- User Pool") {
                            // values["region"] = values.ConfigureValue.region ?values.ConfigureValue.region : "us-east-1" ;
                            values["region"] = region !== "" ? region : "us-east-1";
                            values["CognitoMAU"] = "10";
                            values["CognitoSecurityFeatures"] = "disable";
                            values["CognitoSAMLFederation"] = "10";
                            values["serviceFamily"] = "Security & Identity";

                        }
                        // SQ_CE_59-66  
                        else if (values.serviceName == "EC2" || values.serviceName == "Amazon EC2") {
                            console.log(values, "ASDADASDASDSDD")
                            // values.region = values.ConfigureValue.region ? values.ConfigureValue.region : "us-east-1" ;
                            values["region"] = region !== "" ? region : "us-east-1";
                            values["tenancy"] = "sharedInstances";
                            values["instanceType"] = values?.ConfigureValue?.instanceType
                                ? values.ConfigureValue.instanceType
                                : "t3.micro";
                            values["operatingSystem"] = "Linux";
                            values["noOfInstance"] = "1";
                            values["WorkLoads"] = "Constant usage";
                            values["Usage"] = "100";
                            values["UsageType"] = "Hours/Month";
                            values["serviceFamily"] = "Compute";

                        }
                        // PS_CE_47-58
                        else if (values.serviceName == "AWSLambda" || values.serviceName == "Lambda" || values.serviceName == "awslambda") {
                            values["architecture"] = values?.ConfigureValue?.architecture ? values?.ConfigureValue?.architecture : "Arm64";
                            values["architecture"] = "Arm64";
                            values["durationOfEachRequest"] = "10";
                            values["emphemeralStorage"] = "10";
                            values["emphemeralStorageUnit"] = "GB";
                            values["memoryAllocated"] = "10";
                            values["memoryAllocatedUnit"] = "GB";
                            values["noOfRequest"] = "10000000";
                            values["freeTier"] = "includesFreeTier";
                            values["noOfRequestUnit"] = "perMonth";
                            values["serviceName"] = "AWSLambda";
                            values["serviceFamily"] = "Compute";
                            values["region"] = region != "" ? region : "us-east-1";
                        }
                        // PS_CE_47-58
                        else if (values.serviceName == "ECS" || values.serviceName == "Amazon ECS") {
                            // values.region = values.ConfigureValue.region ? values.ConfigureValue.region : "us-east-1"
                            values["region"] = region !== "" ? region : "us-east-1";
                            values["cpuArchitecture"] = "x86";
                            values["noTask"] = 2;
                            values["averageDuration"] = 10;
                            values["vCPUAllocated"] = 4;
                            values["memoryAllocated"] = 8;
                            values["memoryAllocatedUnit"] = "GB";
                            values["os"] = "Linux";
                            values["ephemeralStorage"] = 20;
                            values["ephemeralStorageUnit"] = "GB";
                            values["averageDurationType"] = "minute";
                            values["taskType"] = "month";
                            values["serviceFamily"] = "Compute";

                        }
                    }
                }
                if (costArray.length != 0) {
                    newNodes = [];
                    console.log(costArray, "PPS")
                    for (let valueOne of costArray) {
                        for (let valueTwo of valueOne.serviceArray) {
                            for (let valuethree of valueTwo.instanceArray) {
                                console.log(valuethree, "HERE")
                                newNodes.push(valuethree);
                            }
                        }
                    }
                }

                newNodes = newNodes.filter(item => item.serviceName !== null && item.serviceName !== undefined && item.serviceName !== "");
                let timePeriod =
                    duration === 1 ? "Month" :
                        duration === 0.0013875 ? "Hour" :
                            duration === 0.03333 ? "Day" :
                                duration === 12 ? "Year" :
                                    "Month";

                console.log(timePeriod);
                let payload = {
                    serviceArray: newNodes,
                    provider: provider
                }
                console.log(payload, "Payloadddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddd")
                setShowLoader(true)
                let responseDataAxios = await getCostServices(payload)
                setShowLoader(false)
                console.log(responseDataAxios, "Response data Azios")
                let responseData
                if (responseDataAxios.data) {
                    responseData = responseDataAxios.data.serviceArray;
                }
                //*Currency Converter;

                //Estimation_14;Estimation_15;Estimation_21
                if (currency === "INR") {
                    let inrValue;
                    try {

                        let payload = {
                            "userId": localStorage.getItem('userId')
                        }
                        const inrPrice = await getTodayInrPrice(payload);
                        inrValue = inrPrice.statusCode === 200 ? inrPrice.data.data[0].currentINRRate : 84.67;
                    } catch (error) {
                        console.error("Error fetching INR price:", error);
                        inrValue = 84.67;
                    }
                    convertPrices(responseData, inrValue);
                }

                // Duration conversion
                convertPrices(responseData, Number(duration));

                //*Duration Converter;
                //Estimation_12


                let serviceFamilyArray = [];

                //for loop for getting the unique service family name;
                for (let values of responseData) {
                    if (!serviceFamilyArray.includes(values.serviceFamily)) {
                        serviceFamilyArray.push(values.serviceFamily);
                    }
                }

                let serviceArray = [];
                for (let values of serviceFamilyArray) {
                    let json = {
                        serviceFamily: values,
                        serviceArray: [],
                        serviceFamilyPrice: 0,
                    };
                    serviceArray.push(json);
                }
                console.log(serviceArray, "serviceArray?")
                for (let values of responseData) {
                    for (let valueOne of serviceArray) {
                        if (valueOne.serviceFamily == values.serviceFamily) {
                            valueOne.serviceFamilyPrice += values.totalPrice;
                        }
                    }
                }

                for (let values of responseData) {
                    for (let valueOne of serviceArray) {
                        if (
                            valueOne.serviceFamily == values.serviceFamily &&
                            valueOne.serviceArray.every(
                                (value) => value.serviceName != values.serviceName
                            )
                        ) {
                            let json = {
                                serviceName: values.serviceName,
                                instanceArray: [],
                                servicePrice: 0,
                            };
                            valueOne.serviceArray.push(json);
                        }
                    }
                }

                for (let values of serviceArray) {
                    for (let valueOne of values.serviceArray) {
                        for (let valueTwo of responseData) {
                            if (valueOne.serviceName == valueTwo.serviceName) {
                                valueOne.servicePrice += valueTwo.totalPrice;
                            }
                        }
                    }
                }

                for (let values of serviceArray) {
                    for (let valueOne of values.serviceArray) {
                        for (let valueTwo of responseData) {
                            if (
                                valueOne.serviceName == valueTwo.serviceName &&
                                values.serviceFamily == valueTwo.serviceFamily
                            ) {
                                valueOne.instanceArray.push(valueTwo);
                            }
                        }
                    }
                }

                let servicePriceArray = [];

                for (let values of serviceArray) {
                    for (let valueOne of values.serviceArray) {
                        servicePriceArray.push(valueOne);
                    }
                }

                // //console.log(servicePriceArray);
                let sortedArray = _.orderBy(
                    servicePriceArray,
                    ["servicePrice"],
                    ["desc"]
                );

                //download("test.json", JSON.stringify(serviceArray));
                //console.table(sortedArray);

                if (sortedArray.length > 1) {
                    setHighestPrice([sortedArray[0]]);
                } else {
                    setHighestPrice([
                        {
                            instanceArray: ["-"],
                            serviceName: "-",
                            servicePrice: "-",
                        },
                    ]);
                }

                if (sortedArray.length > 1) {
                    setLowestPrice([sortedArray[sortedArray.length - 1]]);
                } else {
                    setLowestPrice([
                        {
                            instanceArray: ["-"],
                            serviceName: "-",
                            servicePrice: "-",
                        },
                    ]);
                }

                setCostArray(serviceArray);

                let newServiceArray = [];

                let totalPrice = 0;
                console.log(serviceArray, "serviceArray !!")
                for (let values of serviceArray) {
                    let newService = {
                        serviceFamily: values.serviceFamily,
                        serviceFamilyCost: values.serviceFamilyPrice,
                    };

                    totalPrice += values.serviceFamilyPrice;

                    newServiceArray.push(newService);
                }

                setTotalPrice(totalPrice);
                //Budget Component Percentage Loop Starts here;
                for (let values of newServiceArray) {
                    values.familyPercentage = Math.round(
                        (values.serviceFamilyCost / totalPrice) * 100
                    );
                }

                setServiceFamilyArray(newServiceArray);
                // setIsLoading(false);


                let chartEle = document.documentElement.getElementsByClassName("hole");
                console.log(chartEle, 'chart');
                console.log(totalPrice, 'chart');
                if (Number.isInteger(totalPrice)) {
                    totalPrice = totalPrice.toFixed(1); // Convert to string with one decimal place
                }

                console.log(totalPrice, 'check it !');
                totalPrice = Number(totalPrice)
                console.log(typeof totalPrice, "TotalPrice", totalPrice?.toFixed(2))
                console.log(totalPrice?.toFixed(2) != 0.0, chartEle[0] != undefined, 'Check ');

                if (totalPrice?.toFixed(2) != 0.0 && chartEle[0] != undefined) {
                    // debugger;
                    chartEle[0].style.position = "relative";
                    chartEle[0].innerHTML = `
          <div class="position-absolute top-50 start-50 translate-middle"><p class="font-14 font-bold widget-data-color" style="text-align: center;color: #67748e;margin: 0;font-size: 12px;margin-bottom: 5px;">Total Cost</p>
          <p class="font-16 font-bold widget-data-color estimationPrice" style="margin: 0;" data-bs-toggle="tooltip" data-bs-placement="right" title="${currency == "USD" ? "$" : "₹"
                        } ${totalPrice?.toFixed(2)}"><span class="font-12">${currency == "USD" ? "$" : "₹"
                        }</span>${totalPrice?.toFixed(2)}</p></div>
          `;
                }
                else {
                    chartEle[0].style.position = "relative";
                    chartEle[0].innerHTML = `
          <div class="position-absolute top-50 start-50 translate-middle"><p class="font-14 font-bold widget-data-color" style="text-align: center;color: #67748e;margin: 0;font-size: 12px;margin-bottom: 5px;">Total Cost</p>
          <p class="font-16 font-bold widget-data-color estimationPrice" style="margin: 0;" data-bs-toggle="tooltip" data-bs-placement="right" title="${currency == "USD" ? "$" : "₹"
                        } ${totalPrice?.toFixed(2)}"><span class="font-12">${currency == "USD" ? "$" : "₹"
                        }</span>${totalPrice?.toFixed(2)}</p></div>
          `;
                }
                // download("costArray.json",JSON.stringify(costArray));
            }
        } catch (error) {
            setShowLoader(false)
            console.log(error);
            return;
        }
    }

    //*onChange configuration;
    //onChangeOfConfiguration function is called when the user changes the configuration in the field
    function onChangeOfConfiguration(e, instanceName) {
        if (isViewMode) return; // Prevent changes in view mode

        try {
            let newArray = [...costArray];
            let value = e.target.value;

            if (e.target.type === "text" || e.target.type == "number") {
                value = value.replace(/[^0-9 ]/g, "");
            }
            for (let valueOne of newArray) {
                for (let valueTwo of valueOne.serviceArray) {
                    for (let valuethree of valueTwo.instanceArray) {
                        if (instanceName == valuethree.instanceName) {
                            valuethree[`${e.target.name}`] = value;
                            if (providerName == "Azure" || providerName == "Azure") {
                                valuethree["isChanges"] = true;
                            }
                        }
                    }
                }
            }

            setIsNotChange(false);
            setCostArray(newArray);
            setConfigChanged(true);
        } catch (error) {
            console.log(error.message ? error.message : error);
        }
    }

    const getProviderState = () => {
        if (providerName === "AWS") {
            return {
                selectedAdmin: awsSelectedAdmin,
                setSelectedAdmin: setAwsSelectedAdmin,
                selectedProjectAdmin: awsSelectedProjectAdmin,
                setSelectedProjectAdmin: setAwsSelectedProjectAdmin,
                pricingCalculatorURL: awsPricingCalculatorURL,
                setPricingCalculatorURL: setAwsPricingCalculatorURL,
            };
        } else {
            return {
                selectedAdmin: azureSelectedAdmin,
                setSelectedAdmin: setAzureSelectedAdmin,
                selectedProjectAdmin: azureSelectedProjectAdmin,
                setSelectedProjectAdmin: setAzureSelectedProjectAdmin,
                pricingCalculatorURL: azurePricingCalculatorURL,
                setPricingCalculatorURL: setAzurePricingCalculatorURL,
            };
        }
    };

    const {
        selectedAdmin,
        setSelectedAdmin,
        selectedProjectAdmin,
        setSelectedProjectAdmin,
        pricingCalculatorURL,
        setPricingCalculatorURL,
    } = getProviderState();

    const callRequestApprovalAPI = async () => {
        const { selectedAdmin, selectedProjectAdmin, pricingCalculatorURL } =
            getProviderState();
        const userId = sessionStorage.getItem("userId");
        const payload = {
            userId,
            admin: selectedAdmin,
            ProjectAdmin: selectedProjectAdmin,
            PricingCalculatorURL: pricingCalculatorURL,
            providerName: provider?.toLowerCase(),
            providerId: "",
            envID: envId,
            workload_id: workloadId,
            organizationId: orgId,
            is_threshold_set: setEstimateAsBudget.toString(),
            serviceArray: costArray
        };

        try {
            let res = await requestApproval(payload);
            if (res.success) {
                setPopUpMsg({ ...popupMsg, ["Msg"]: "Approval Sent", ["show"]: true, ["success"]: true })

            } else {
                setPopUpMsg({ ...popupMsg, ["Msg"]: "Approval API Error", ["show"]: true, ["success"]: true })

            }
        } catch (error) {
            console.log(error, "Error")
        }
    };


    const handleRequestApproval = async () => {
        if (setEstimateAsBudget) {
            await callSetThresholdAPI();
        }
        setShowApprovalModal(true);
    };

    const showActionToastMessage = (message) => {
        setToastMessage(message);
        setShowActionToast(true);
        setTimeout(() => {
            setShowActionToast(false);
            setToastMessage("");
        }, 3000);
    };


    const renderActionButton = () => {
        if (approvalId) {
            if (isapprovalStatus === true || (userRoles.includes('admin') || userRoles.includes('project admin'))) {
                return (
                    <>
                        <button onClick={handleReject} className="button secondary-outline-button me-4">Reject</button>
                        <button onClick={handleApprove} className="button primary-button">Approve</button>
                    </>
                );
            }
            return null;
        } else {
            if (userRoles.includes('admin') || userRoles.includes('project admin')) {
                return <button onClick={handleEstimate} className="button primary-button">Estimate</button>;
            } else if (userRoles.includes('viewer')) {
                return null;
            } else if (userRoles.includes('architect') || userRoles.includes('admin')) {
                if (configChanged) {
                    return <button onClick={handleEstimate} className="button primary-button">Estimate</button>;
                } else {
                    return <button onClick={handleRequestApproval} className="button primary-button">Request for Approval</button>;
                }
            }
        }
    };



    const callSetThresholdAPI = async () => {
        const userId = localStorage.getItem("userId");
        const payload = {
            module: "Estimation",
            userId,
            envID: envId,
            mode: "auto",
            period: billingPeriod === 12 ? "yearly" : billingPeriod === 1 ? "monthly" : "weekly",
            cloudProvider: provider,
            budget: totalPrice.toString(),
            organizationId: orgId,
        };

        try {
            console.log(payload, "payload for the Threshold API")
            await setThreshold(payload);
            showToastMessage("Threshold set successfully");
        } catch (error) {
            console.error("Error setting threshold:", error);
            showToastMessage("Error setting threshold");
        }
    };


    const handleEstimate = async () => {
        if (userRoles.includes('admin') || userRoles.includes('project admin')) {
            if (setEstimateAsBudget) {
                await callSetThresholdAPI();
            }
            await getConfigurationArray("nope", provider);
        } else if (userRoles.includes('architect')) {
            await getConfigurationArray("nope", provider);
        }
        setConfigChanged(false);
    };



    const handleReject = () => {
        setShowRejectionModal(true);
    };

    const handleApprove = async () => {
        try {
            if (setEstimateAsBudget) {
                await callSetThresholdAPI();
            }
            setShowLoader(true)
            let handleApproveData = await sendApprovalDecision("Approved", "Testing");
            setShowLoader(false)
            showActionToastMessage(`Approved successfully`);
        } catch (error) {
            console.error("Error in approval/rejection process:", error);
            showToastMessage("Error in approval/rejection process");
        }
    };


    const sendApprovalDecision = async (status, comments) => {
        const payload = {
            organizationId: orgId,
            userId: localStorage.getItem("userId"),
            approvalId: approvalId,
            status: status,
            comments: comments,
        };
        console.log(payload)

        try {
            setShowLoader(true)
            let data = await rejectApprove(payload);
            setShowLoader(false)
            console.log(data, "data")
            showActionToastMessage(`${status} successfully`);
        } catch (error) {
            console.error("Error in approval/rejection process:", error);
            showToastMessage("Error in approval/rejection process");
        }
    };
    const showToastMessage = (message) => {
        setToastMessage(message);
        setShowToast(true);
        setTimeout(() => setShowToast(false), 3000);
    };

    //Estimation_29;Estimation_30;
    //isSearchAvailableFunction  function is used to show the no data found image in when the serach word that user entered in not available;
    function isSearchAvailableFunction() {
        try {
            for (let values of costArray) {
                if (
                    values.serviceFamily
                        ?.toLowerCase()
                        .includes(searchWord.trim()?.toLowerCase()) ||
                    values.serviceArray.some((value) => {
                        return value.serviceName
                            ?.toLowerCase()
                            .includes(searchWord.trim()?.toLowerCase());
                    })
                ) {
                    setIsSearchAVailable(false);
                    break;
                    return;
                }
                setIsSearchAVailable(true);
            }
        } catch (error) {
            //console.log(error.message);
            return;
        }
    }
    //Excel_CCG_02
    //bindDataInHiddenTable this function is used to create a excel using the xlsx-js-style and the function will download the excel in the user machine in the name of the Architecture
    async function bindDataInHiddenTable() {
        let data = [];
        for (let values of costArray) {
            for (let valueOne of values.serviceArray) {
                for (let valueTwo of valueOne.instanceArray) {
                    try {
                        let description = "";

                        for (let key in valueTwo) {
                            if (
                                key != "serviceName" &&
                                key != "instanceName" &&
                                key != "id" &&
                                key != "serviceFamily" &&
                                key != "isPriceAvailable" &&
                                key != "totalPrice"
                            ) {
                                description += `${key} = ${valueTwo[key]} \n`;
                            }
                        }

                        let json = {
                            "Service category": valueTwo.serviceFamily,
                            "Service type": valueTwo.serviceName,
                            Region: valueTwo.region || valueTwo.location || valueTwo.zone,
                            Description: description,
                            "Estimated monthly cost":
                                currency == "USD"
                                    ? "$" + valueTwo.totalPrice.toFixed(2)
                                    : "₹" + valueTwo.totalPrice.toFixed(2),
                        };
                        data.push(json);
                    } catch (err) {
                        //console.log(err.message, "Hidden detail@@@@@");
                        return;
                    }
                }
            }
        }

        const ws = XLSX.utils.book_new();

        let header = [
            {
                v: "Service Family",
                t: "s",
                s: { font: { bold: true, color: { rgb: "000" } } },
            },
            {
                v: "Service",
                t: "s",
                s: { font: { bold: true, color: { rgb: "000" } } },
            },
            {
                v: "Region",
                t: "s",
                s: { font: { bold: true, color: { rgb: "000" } } },
            },
            {
                v: "Description",
                t: "s",
                s: { font: { bold: true, color: { rgb: "000" } } },
            },
            {
                v: "Estimated cost",
                t: "s",
                s: { font: { bold: true, color: { rgb: "000" } } },
            },
        ];

        XLSX.utils.sheet_add_aoa(ws, [header]);

        ws["!cols"] = [
            { width: 30 },
            { width: 30 },
            { width: 30 },
            { width: 70 },
            { width: 30 },
        ];

        XLSX.utils.sheet_add_json(ws, data, { origin: "A2", skipHeader: true });
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

        // //console.log(wb["Sheets"]["data"]["A1"],"Array");

        const excelBuffer = await XLSX.write(wb, {
            bookType: "xlsx",
            type: "array",
            cellStyles: true,
        });

        setTimeout(() => {
            const finalData = new Blob([excelBuffer], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
            });
            FileSaver.saveAs(
                finalData,
                `${envName}.xlsx`
            );
        }, 5000);
    }
    useEffect(() => {

        if (approvalId == "") {
            if (provider != "") {
                if (provider == providerName) {
                    getConfigurationArray("nope", provider)
                } else {
                    console.log("Option Click!!")
                    getConfigurationArray("OptionClick", provider)
                }
                setTotalPrice(0)
            }

        }


    }, [provider])

    useEffect(() => {
        setIsSearchAVailable(
            !costArray.some((values) =>
                values.serviceFamily?.toLowerCase().includes(searchWord.trim()?.toLowerCase()) ||
                values.serviceArray.some((value) =>
                    value.serviceName?.toLowerCase().includes(searchWord.trim()?.toLowerCase())
                )
            )
        );
    }, [searchWord, costArray]);

    const handleSearch = (e) => {
        setSearchWord(e.target.value);
    };

    const handleSendRequest = async () => {
        try {
            await callRequestApprovalAPI();
            setShowApprovalModal(false);
            showToastMessage("Approval request sent successfully");
            setTimeout(() => {
            }, 2000);
        } catch (error) {
            console.error("Error sending request:", error);
            showToastMessage("Error sending request");
        }
    };

    return (
        <>
            <WorkbenchNav />
            {showLoader ?
                <Loader /> : <>
                    {
                        popupMsg.show == true && popupMsg.Msg != "" ?
                            <WorkbenchPopup
                                popupMsg={popupMsg}
                                setPopUpMsg={setPopUpMsg}
                            />
                            : <></>
                    }
                    <div className="container-fluid">
                        <div className="p-4 mt-3">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <p className="font-22 font-bold black-v2 mb-0">Total Budget Insights</p>
                                <div className="d-flex align-items-center gap-3">
                                    <label
                                        htmlFor="Billing-Period"
                                        className="font-14 font-medium secondary-color mb-0 text-nowrap"
                                    >
                                        Billing Period
                                    </label>
                                    <select
                                        id="Billing-Period"
                                        className="form-select form-control custom-form font-14 font-medium monthly-select"
                                        value={duration}
                                        onChange={(e) => {
                                            setDuration(e.target.value);

                                        }}
                                    >

                                        <option value={12}>Yearly</option>
                                        <option value={1} selected>
                                            Monthly
                                        </option>
                                        <option value={0.25}>Weekly</option>
                                        <option value={0.03333}>Daily</option>
                                        <option value={0.0013875}>Hourly</option>
                                    </select>

                                    <label
                                        htmlFor="Currency"
                                        className="font-14 font-medium secondary-color mb-0 text-nowrap"
                                    >
                                        Currency
                                    </label>
                                    {/* dropdown ends here */}
                                    <div className="dropdown">
                                        <button
                                            type="button"
                                            // className="btn cust-dropdown d-flex justify-content-between align-items-center cust-select-btn custom-drop-width"
                                            class="form-select form-control custom-form font-14 font-medium monthly-select"
                                            id="dropdownMenuButton1"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <span className="d-flex align-items-center font-14 font-regular color-black"
                                                onClick={(e) => {
                                                    setCurrency("USD");
                                                }}
                                            >
                                                <img
                                                    src={currency === 'USD' ? "images/united-states-flag.svg" : "images/india-flag.svg"}
                                                    alt={currency === 'USD' ? "us-icon" : "india-icon"}
                                                    className="me-2 flag-icon-width"
                                                />{" "}
                                                {currency}
                                            </span>{" "}
                                            {/* <img
                                                src="images/down-arrow-grey.svg"
                                                alt="down-arrow-grey"
                                                className="acc-arrow-img"
                                            /> */}
                                        </button>
                                        <ul
                                            className="dropdown-menu body-bg cust-drop-menu px-2 py-1 custom-drop-width"
                                            aria-labelledby="dropdownMenuButton1"
                                        >


                                            <li className="font-regular color-black font-12 my-1"
                                                onClick={(e) => {
                                                    setCurrency("INR");
                                                }}
                                            >
                                                <a
                                                    href="#"
                                                    className="d-flex align-items-center custom-drop-item-2 text-decoration-none font-12 font-regular black-v2 mb-1"
                                                >
                                                    <img
                                                        src="images/india-flag.svg"
                                                        alt="fox-icon"
                                                        className="me-2 flag-icon-width"
                                                    />
                                                    India
                                                </a>
                                            </li>
                                            {currency === "INR"}{
                                                <li className="font-regular color-black font-12 my-1"
                                                    onClick={(e) => {
                                                        setCurrency("USD");
                                                    }}
                                                >
                                                    <a
                                                        href="#"
                                                        className="d-flex align-items-center custom-drop-item-2 text-decoration-none font-12 font-regular black-v2 mb-1"
                                                    >
                                                        <img
                                                            src="images/united-states-flag.svg"
                                                            alt="fox-icon"
                                                            className="me-2 flag-icon-width"
                                                        />
                                                        USD
                                                    </a>
                                                </li>
                                            }



                                        </ul>
                                    </div>
                                    {/* dropdown ends here */}
                                    <button type="button" className="button cancel-button p-14"
                                        onClick={() => {
                                            bindDataInHiddenTable();
                                        }}
                                    >
                                        <img src="images/excel-icon.svg" alt="excel-icon" />
                                    </button>
                                </div>
                            </div>
                            <div className="tab-scroll mb-4">
                                <ul
                                    className="nav nav-pills mb-4 d-flex gap-3 primary-border-bottom"
                                    id="pills-tab"
                                    role="tablist"
                                >
                                    <li className="nav-item custom-bg rounded-start" role="presentation">
                                        <button

                                            className={`nav-link theme-tab-2 position-relative cus-tab-border bg-transparent font-14 font-medium secondary-color secondary-text-color ${provider === "AWS" ? "active" : ""}`}
                                            data-bs-toggle="pill"
                                            data-bs-target="#AWS-tab"
                                            type="button"
                                            role="tab"
                                            aria-selected="true"
                                            onClick={() => {
                                                setOptionClick(true)
                                                setProvider("AWS")
                                                setCostArray([])
                                            }}
                                        >
                                            AWS
                                        </button>
                                    </li>
                                    <li className="nav-item custom-bg" role="presentation">
                                        <button
                                            className={`nav-link theme-tab-2 position-relative cus-tab-border bg-transparent font-14 font-medium secondary-color secondary-text-color ${provider?.toLowerCase() === "azure" ? "active" : ""}`}
                                            data-bs-toggle="pill"
                                            data-bs-target="#Azure-tab"
                                            type="button"
                                            role="tab"
                                            aria-selected="false"
                                            onClick={() => {
                                                setProvider("Azure")
                                                setOptionClick(true)
                                                setCostArray([])
                                            }}
                                        >
                                            Azure
                                        </button>
                                    </li>
                                </ul>
                                <div className="tab-content" id="pills-tabContent">
                                    <div
                                        className="tab-pane fade show active"
                                        id="AWS-tab"
                                        role="tabpanel"
                                        aria-labelledby="AWS-tab"
                                    >
                                        <div className="row">
                                            <div className="col-md-12 col-xl-7 mb-5">
                                                <Budget
                                                    serviceFamilyArray={serviceFamilyArray}
                                                    currency={currency}
                                                    isViewMode={isViewMode}
                                                />
                                            </div>
                                            <div className="col-md-12 col-xl-5 mb-5">
                                                <div className="row">
                                                    <div className="col-md-12 col-lg-6 col-xl-12 mb-4">
                                                        <div className="budget-container">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <div className="d-flex align-items-center gap-3">
                                                                    <img
                                                                        src="images/error-icon-red.svg"
                                                                        alt="error-icon-red"
                                                                    />
                                                                    <div>
                                                                        <p className="font-16 font-medium secondary-color mb-3">
                                                                            Service with High Cost
                                                                        </p>
                                                                        <p className="font-16 font-medium secondary-text-color mb-0">
                                                                            {highestPrice[0]?.instanceArray[0]?.serviceFamily} -{" "}
                                                                            {highestPrice[0]?.serviceName}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <p className="font-18 font-medium color-black mb-0">
                                                                        {currency == "INR" ? "₹" : "$"}
                                                                        {" "}
                                                                        {highestPrice[0]?.servicePrice != "-"
                                                                            ? highestPrice[0]?.servicePrice?.toFixed(2)
                                                                            : highestPrice[0]?.servicePrice}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 col-lg-6 col-xl-12">
                                                        <div className="budget-container">
                                                            <div className="d-flex justify-content-between align-items-center">

                                                                <div className="d-flex align-items-center gap-3">
                                                                    <img
                                                                        src="images/tick-success.svg"
                                                                        alt="tick-success-img"
                                                                    />
                                                                    <div>
                                                                        <p className="font-16 font-medium secondary-color mb-3">
                                                                            Service with Low Cost
                                                                        </p>
                                                                        <p className="font-16 font-medium secondary-text-color mb-0">
                                                                            {lowestPrice[0]?.instanceArray[0]?.serviceFamily} -{" "}
                                                                            {lowestPrice[0]?.serviceName}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <p className="font-18 font-medium color-black mb-0">
                                                                        {currency == "INR" ? "₹" : "$"}{" "}
                                                                        {" "}
                                                                        {lowestPrice[0]?.servicePrice != "-"
                                                                            ? lowestPrice[0]?.servicePrice?.toFixed(2)
                                                                            : lowestPrice[0]?.servicePrice}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-12 mb-5">
                                                <p class="font-20 font-bold black-v2 mb-0">
                                                    Cost Based on Services
                                                </p>
                                                <div className="d-flex justify-content-end">
                                                    <div className="input-group input-search custom-border-box custom-search-box cust-search-width"
                                                        style={{ width: '25%' }}
                                                    >
                                                        <input
                                                            type="search"
                                                            className="px-3 form-control common-bg border-0 custom-search gray-v1"
                                                            placeholder="Search"
                                                            value={searchWord}
                                                            onChange={handleSearch}
                                                        />
                                                        <button className="btn border-start-0 custom-search " type="button">
                                                            <img src="images/search-icon-violet.svg" alt="search" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12 mt-4">
                                                <div className="accordion" id="accordionExample">
                                                    {
                                                        isSearchAvailable ? (
                                                            <div className="text-center">
                                                                <img src="images/nodata-found.svg" alt="no data found icon" className="no-data-found-sty" />
                                                                <p className="text-center font-16 mt-2 font-medium color-grey">No Data Found</p>
                                                            </div>
                                                        ) :
                                                            (
                                                                costArray.map((values, index) => (
                                                                    <div className="accordion-item estimation-accordion bg-transparent border-0 pt-1" key={index}>
                                                                        <div>
                                                                            <div className="estimation-container accordion-header d-flex align-items-center justify-content-between" id={`heading${index}`}>
                                                                                <div className="d-flex align-items-center gap-4">
                                                                                    <button
                                                                                        className={`custom-acc-btn d-flex align-items-center gap-2 p-0 
                                                                        font-14 btn border-0 shadow-none ${searchWord !== '' &&
                                                                                                (values.serviceFamily?.toLowerCase().includes(searchWord.trim()?.toLowerCase()) ||
                                                                                                    values.serviceArray.some((value) =>
                                                                                                        value.serviceName?.toLowerCase().includes(searchWord.trim()?.toLowerCase())
                                                                                                    ))
                                                                                                ? ''
                                                                                                : 'collapsed'
                                                                                            }`}
                                                                                        type="button"
                                                                                        data-bs-toggle="collapse"
                                                                                        data-bs-target={`#collapse${index}`}
                                                                                        aria-expanded={
                                                                                            searchWord !== '' &&
                                                                                                (values.serviceFamily?.toLowerCase().includes(searchWord.trim()?.toLowerCase()) ||
                                                                                                    values.serviceArray.some((value) =>
                                                                                                        value.serviceName?.toLowerCase().includes(searchWord.trim()?.toLowerCase())
                                                                                                    ))
                                                                                                ? 'true'
                                                                                                : 'false'
                                                                                        }
                                                                                    >
                                                                                        <span className="cust-acc-arrow d-flex align-items-center justify-content-center">
                                                                                            <img src="images/violet-arrow-right.svg" alt="arrow-right" />
                                                                                        </span>
                                                                                    </button>
                                                                                    <span className="font-14 font-medium color-black">{values.serviceFamily}</span>
                                                                                </div>
                                                                                <p className="font-14 font-medium secondary-color m-0">
                                                                                    # of Services <span className="font-16 font-bold color-black ms-3">{values.serviceArray.length}</span>
                                                                                </p>
                                                                                <p className="font-14 font-medium secondary-color m-0">
                                                                                    # Cost{' '}
                                                                                    <span className="font-16 font-bold color-black ms-3">
                                                                                        {currency === 'INR' ? '₹' : '$'}
                                                                                        {Number(values.serviceFamilyPrice.toFixed(2))}
                                                                                    </span>
                                                                                </p>
                                                                            </div>

                                                                            <div
                                                                                id={`collapse${index}`}
                                                                                className={`accordion-collapse collapse ${searchWord !== '' &&
                                                                                    (values.serviceFamily?.toLowerCase().includes(searchWord.trim()?.toLowerCase()) ||
                                                                                        values.serviceArray.some((value) =>
                                                                                            value.serviceName?.toLowerCase().includes(searchWord.trim()?.toLowerCase())
                                                                                        ))
                                                                                    ? 'show'
                                                                                    : ''
                                                                                    }`}
                                                                                aria-labelledby={`heading${index}`}
                                                                                data-bs-parent="#accordionExample"
                                                                            >
                                                                                <div className="accordion-body p-0">
                                                                                    {values.serviceArray.map((service, serviceIndex) => (
                                                                                        <div className="accordion" id={`accordionExample${serviceIndex}`} key={serviceIndex}>
                                                                                            <div className="accordion-item estimation-accordion bg-transparent border-0 mx-5">
                                                                                                <div>
                                                                                                    <div
                                                                                                        className="estimation-container accordion-header d-flex align-items-center justify-content-between"
                                                                                                        id={`headingTwo${serviceIndex}`}
                                                                                                    >
                                                                                                        <div className="d-flex align-items-center gap-4">
                                                                                                            <button
                                                                                                                className={`custom-acc-btn d-flex align-items-center gap-2 p-0 font-14 btn border-0 shadow-none ${searchWord !== '' &&
                                                                                                                    (values.serviceFamily?.toLowerCase().includes(searchWord.trim()?.toLowerCase()) ||
                                                                                                                        service.serviceName?.toLowerCase().includes(searchWord.trim()?.toLowerCase()))
                                                                                                                    ? ''
                                                                                                                    : 'collapsed'
                                                                                                                    }`}
                                                                                                                type="button"
                                                                                                                data-bs-toggle="collapse"
                                                                                                                data-bs-target={`#collapseTwo${serviceIndex}`}
                                                                                                                aria-expanded={
                                                                                                                    searchWord !== '' &&
                                                                                                                        (values.serviceFamily?.toLowerCase().includes(searchWord.trim()?.toLowerCase()) ||
                                                                                                                            service.serviceName?.toLowerCase().includes(searchWord.trim()?.toLowerCase()))
                                                                                                                        ? 'true'
                                                                                                                        : 'false'
                                                                                                                }
                                                                                                            >
                                                                                                                <span className="cust-acc-arrow d-flex align-items-center justify-content-center">
                                                                                                                    <img src="images/violet-arrow-right.svg" alt="arrow-right" />
                                                                                                                </span>
                                                                                                            </button>
                                                                                                            <span className="font-14 font-medium color-black">{service.serviceName}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class="accordion-item estimation-accordion  bg-transparent border-0 pt-1">
                                                                                                        <div
                                                                                                            id={`collapseTwo${serviceIndex}`}
                                                                                                            className={`accordion-collapse collapse ${searchWord !== '' &&
                                                                                                                (values.serviceFamily?.toLowerCase().includes(searchWord.trim()?.toLowerCase()) ||
                                                                                                                    service.serviceName?.toLowerCase().includes(searchWord.trim()?.toLowerCase()))
                                                                                                                ? 'show'
                                                                                                                : ''
                                                                                                                }`}
                                                                                                            aria-labelledby={`headingTwo${serviceIndex}`}
                                                                                                            data-bs-parent={`#accordionExample${serviceIndex}`}
                                                                                                        >

                                                                                                            <div className="accordion-body p-0">
                                                                                                                {service.instanceArray.map((valueTwo, instanceIndex) => (
                                                                                                                    <div key={instanceIndex}>
                                                                                                                        {/* valueTwo details */}
                                                                                                                        <div className="estimation-container d-flex align-items-center justify-content-between">
                                                                                                                            <div
                                                                                                                                class="d-flex align-items-center gap-4">
                                                                                                                                <button
                                                                                                                                    class="custom-acc-btn d-flex align-items-center gap-2 p-0 font-14 btn border-0 shadow-none collapsed"
                                                                                                                                    type="button"
                                                                                                                                    data-bs-toggle="collapse"
                                                                                                                                    data-bs-target={`#${valueTwo?.instanceName
                                                                                                                                        ?.split(" ")
                                                                                                                                        ?.join(
                                                                                                                                            "_"
                                                                                                                                        )}`}
                                                                                                                                    aria-expanded="false"
                                                                                                                                    aria-controls="collapse-411">
                                                                                                                                    <span
                                                                                                                                        class="cust-acc-arrow d-flex align-items-center justify-content-center"><img
                                                                                                                                            src="images/violet-arrow-right.svg"
                                                                                                                                            alt="arrow-right" /></span>
                                                                                                                                </button>
                                                                                                                                <span className="font-14 font-medium color-black">{valueTwo.instanceName}</span>

                                                                                                                            </div>
                                                                                                                            <p className="font-14 font-medium secondary-color m-0">
                                                                                                                                # Cost{' '}
                                                                                                                                <span className="font-16 font-bold color-black ms-3">
                                                                                                                                    {currency === 'INR' ? '₹' : '$'}
                                                                                                                                    {valueTwo.totalPrice.toFixed(2)}
                                                                                                                                </span>
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                        {/* Service-specific Configuration */}

                                                                                                                        <div
                                                                                                                            id={`${valueTwo?.instanceName?.split(" ").join("_")}`}
                                                                                                                            className="accordion-collapse collapse"
                                                                                                                            aria-labelledby="headingthree-41"
                                                                                                                        >
                                                                                                                            {

                                                                                                                                (valueTwo.serviceName ==
                                                                                                                                    "DynamoDB" || valueTwo.serviceName == "AWSDynamoDB") &&
                                                                                                                                    (providerName ==
                                                                                                                                        "AWS") ? (
                                                                                                                                    <AWS_SDK_DDB
                                                                                                                                        values={valueTwo}
                                                                                                                                        onChangeOfConfiguration={
                                                                                                                                            onChangeOfConfiguration
                                                                                                                                        }
                                                                                                                                        isViewer={isViewMode}
                                                                                                                                    />
                                                                                                                                ) : (valueTwo.serviceName ==
                                                                                                                                    "Cloudfront" || valueTwo.serviceName == "CloudFront") &&
                                                                                                                                    (providerName ==
                                                                                                                                        "AWS") ? (
                                                                                                                                    <AWS_SDK_CloudFront
                                                                                                                                        values={valueTwo}
                                                                                                                                        onChangeOfConfiguration={
                                                                                                                                            onChangeOfConfiguration
                                                                                                                                        }
                                                                                                                                        isViewer={isViewMode}
                                                                                                                                    />
                                                                                                                                ) : (valueTwo.serviceName === "RDS Aurora MYSQL" || valueTwo.serviceName === "RDS Aurora Serverless MYSQL" || valueTwo.serviceName === "RDS MYSQL" || valueTwo.serviceName == "Amazon RDS" || valueTwo.serviceName == "RDS") && (valueTwo.EngineType == "MySQL") ? (
                                                                                                                                    <AWS_SDK_RDS_MYSQL
                                                                                                                                        values={valueTwo}
                                                                                                                                        onChangeOfConfiguration={
                                                                                                                                            onChangeOfConfiguration
                                                                                                                                        }
                                                                                                                                        isViewer={isViewMode}
                                                                                                                                    />


                                                                                                                                ) :
                                                                                                                                    (valueTwo.serviceName === "RDS Aurora MYSQL" || valueTwo.serviceName === "RDS Aurora Serverless MYSQL" || valueTwo.serviceName === "RDS MYSQL" || valueTwo.serviceName == "Amazon RDS" || valueTwo.serviceName == "RDS") && (valueTwo.EngineType == "Microsoft SQL Server") ? (
                                                                                                                                        // sriram separate component
                                                                                                                                        <AWS_SDK_RDS_SQL
                                                                                                                                            values={valueTwo}
                                                                                                                                            onChangeOfConfiguration={
                                                                                                                                                onChangeOfConfiguration
                                                                                                                                            }
                                                                                                                                            isViewer={isViewMode}
                                                                                                                                        />
                                                                                                                                    ) :
                                                                                                                                        (valueTwo.serviceName ==
                                                                                                                                            "Amazon EFS" || valueTwo.serviceName == "EFS") &&
                                                                                                                                            (providerName ==
                                                                                                                                                "AWS") ? (
                                                                                                                                            <AWS_SDK_EFS
                                                                                                                                                values={valueTwo}
                                                                                                                                                onChangeOfConfiguration={
                                                                                                                                                    onChangeOfConfiguration
                                                                                                                                                }
                                                                                                                                                isViewer={isViewMode}
                                                                                                                                            />
                                                                                                                                        ) :
                                                                                                                                            (valueTwo.serviceName == "EKS") &&
                                                                                                                                                (providerName ==
                                                                                                                                                    "AWS") ? (
                                                                                                                                                <AwsSDKEKS
                                                                                                                                                    values={valueTwo}
                                                                                                                                                    onChangeOfConfiguration={
                                                                                                                                                        onChangeOfConfiguration
                                                                                                                                                    }
                                                                                                                                                    isViewer={isViewMode}
                                                                                                                                                />
                                                                                                                                            ) :
                                                                                                                                                (valueTwo.serviceName == "AWSKMS" || valueTwo.serviceName == "KMS")
                                                                                                                                                    ? (
                                                                                                                                                        <AwsSDKKMS
                                                                                                                                                            values={valueTwo}
                                                                                                                                                            onChangeOfConfiguration={
                                                                                                                                                                onChangeOfConfiguration
                                                                                                                                                            }
                                                                                                                                                            isViewer={isViewMode}
                                                                                                                                                        />
                                                                                                                                                    ) :
                                                                                                                                                    (valueTwo.serviceName == "VPC")
                                                                                                                                                        ? (
                                                                                                                                                            <AwsSDKNAT
                                                                                                                                                                values={valueTwo}
                                                                                                                                                                onChangeOfConfiguration={
                                                                                                                                                                    onChangeOfConfiguration
                                                                                                                                                                }
                                                                                                                                                                isViewer={isViewMode}
                                                                                                                                                            />
                                                                                                                                                        ) :
                                                                                                                                                        (valueTwo.serviceName == "Elastic Load Balancing" || valueTwo.serviceName == "Elastic Load Balancing V2" || valueTwo.serviceName == "LoadBalancers")
                                                                                                                                                            ? (
                                                                                                                                                                <AwsSDKLB
                                                                                                                                                                    values={valueTwo}
                                                                                                                                                                    onChangeOfConfiguration={
                                                                                                                                                                        onChangeOfConfiguration
                                                                                                                                                                    }
                                                                                                                                                                    isViewer={isViewMode}
                                                                                                                                                                />
                                                                                                                                                            ) :

                                                                                                                                                            (valueTwo.serviceName == "API_Gateway" || valueTwo.serviceName == "API Gateway HTTP" || valueTwo.serviceName == "API Gateway" || valueTwo.serviceName == "API Gateway- HTTP" || valueTwo.serviceName == "API Gateway- REST")
                                                                                                                                                                ? (
                                                                                                                                                                    <AwsSDKAwsApiGateway
                                                                                                                                                                        values={valueTwo}
                                                                                                                                                                        onChangeOfConfiguration={
                                                                                                                                                                            onChangeOfConfiguration
                                                                                                                                                                        }
                                                                                                                                                                        isViewer={isViewMode}
                                                                                                                                                                    />
                                                                                                                                                                ) :

                                                                                                                                                                (valueTwo.serviceName == "SNS" || valueTwo.serviceName == "SNS - Standard" || valueTwo.serviceName == "SNS - FIFO") ? (
                                                                                                                                                                    <AwsSDKSNS
                                                                                                                                                                        values={valueTwo}
                                                                                                                                                                        onChangeOfConfiguration={
                                                                                                                                                                            onChangeOfConfiguration
                                                                                                                                                                        }
                                                                                                                                                                        isViewer={isViewMode}
                                                                                                                                                                    />
                                                                                                                                                                ) :
                                                                                                                                                                    (valueTwo.serviceName ==
                                                                                                                                                                        "S3 Bucket" || valueTwo.serviceName == "Amazon S3") ? (
                                                                                                                                                                        <AwsSDKS3
                                                                                                                                                                            values={valueTwo}
                                                                                                                                                                            onChangeOfConfiguration={
                                                                                                                                                                                onChangeOfConfiguration
                                                                                                                                                                            }
                                                                                                                                                                            isViewer={isViewMode}
                                                                                                                                                                        />

                                                                                                                                                                    ) :
                                                                                                                                                                        (valueTwo.serviceName ==
                                                                                                                                                                            "ECR" || valueTwo.serviceName == "AWS ECR") ? (

                                                                                                                                                                            <AwsSDKECR
                                                                                                                                                                                values={valueTwo}
                                                                                                                                                                                onChangeOfConfiguration={
                                                                                                                                                                                    onChangeOfConfiguration
                                                                                                                                                                                }
                                                                                                                                                                                isViewer={isViewMode}
                                                                                                                                                                            />
                                                                                                                                                                        ) :

                                                                                                                                                                            (valueTwo.serviceName ==
                                                                                                                                                                                "DMS" || valueTwo.serviceName == "DMS - Serverless")
                                                                                                                                                                                ? (

                                                                                                                                                                                    <AwsSDKDMS
                                                                                                                                                                                        values={valueTwo}
                                                                                                                                                                                        onChangeOfConfiguration={
                                                                                                                                                                                            onChangeOfConfiguration
                                                                                                                                                                                        }
                                                                                                                                                                                        isViewer={isViewMode}
                                                                                                                                                                                    />
                                                                                                                                                                                ) :
                                                                                                                                                                                (valueTwo.serviceName ==
                                                                                                                                                                                    "Lambda" || valueTwo.serviceName == "AWSLambda") ? (
                                                                                                                                                                                    <AWSSDKLambda
                                                                                                                                                                                        values={valueTwo}
                                                                                                                                                                                        onChangeOfConfiguration={
                                                                                                                                                                                            onChangeOfConfiguration
                                                                                                                                                                                        }
                                                                                                                                                                                        isViewer={isViewMode}
                                                                                                                                                                                    />
                                                                                                                                                                                )
                                                                                                                                                                                    :
                                                                                                                                                                                    (valueTwo.serviceName ==
                                                                                                                                                                                        "Secret Manager" || valueTwo.serviceName == "Secret_Manager" || valueTwo.serviceName == "Secret manager") ? (
                                                                                                                                                                                        <AWSSDKSecretsManager
                                                                                                                                                                                            values={valueTwo}
                                                                                                                                                                                            onChangeOfConfiguration={
                                                                                                                                                                                                onChangeOfConfiguration
                                                                                                                                                                                            }
                                                                                                                                                                                            isViewer={isViewMode}
                                                                                                                                                                                        />
                                                                                                                                                                                    )
                                                                                                                                                                                        :
                                                                                                                                                                                        (valueTwo.serviceName ==
                                                                                                                                                                                            "Cognito - User Pools" || valueTwo.serviceName == "Amazon Cognito" || valueTwo.serviceName == 'Cognito user pools' || valueTwo.serviceName == "Cognito- User Pool") ? (
                                                                                                                                                                                            <AwsSDKCognito
                                                                                                                                                                                                values={valueTwo}
                                                                                                                                                                                                onChangeOfConfiguration={
                                                                                                                                                                                                    onChangeOfConfiguration
                                                                                                                                                                                                }
                                                                                                                                                                                                isViewer={isViewMode}
                                                                                                                                                                                            />
                                                                                                                                                                                        ) :
                                                                                                                                                                                            (valueTwo.serviceName ==
                                                                                                                                                                                                "EC2" || valueTwo.serviceName == "Amazon EC2")
                                                                                                                                                                                                ? (
                                                                                                                                                                                                    <AwsSDKEC2
                                                                                                                                                                                                        values={valueTwo}
                                                                                                                                                                                                        onChangeOfConfiguration={
                                                                                                                                                                                                            onChangeOfConfiguration
                                                                                                                                                                                                        }
                                                                                                                                                                                                        isViewer={isViewMode}
                                                                                                                                                                                                    />
                                                                                                                                                                                                ) :
                                                                                                                                                                                                (valueTwo.serviceName ==
                                                                                                                                                                                                    "ECS" || valueTwo.serviceName == "Amazon ECS") ? (
                                                                                                                                                                                                    <AwsSDKECS
                                                                                                                                                                                                        values={valueTwo}
                                                                                                                                                                                                        onChangeOfConfiguration={
                                                                                                                                                                                                            onChangeOfConfiguration
                                                                                                                                                                                                        }
                                                                                                                                                                                                        isViewer={isViewMode}
                                                                                                                                                                                                    />
                                                                                                                                                                                                ) :

                                                                                                                                                                                                    (valueTwo.serviceName ==
                                                                                                                                                                                                        "Azure App Service" || valueTwo.serviceName == "App_Service") ? (
                                                                                                                                                                                                        <Azure_AppService
                                                                                                                                                                                                            values={valueTwo}
                                                                                                                                                                                                            onChangeOfConfiguration={
                                                                                                                                                                                                                onChangeOfConfiguration
                                                                                                                                                                                                            }
                                                                                                                                                                                                            isViewer={isViewMode}
                                                                                                                                                                                                        />
                                                                                                                                                                                                    ) :

                                                                                                                                                                                                        (valueTwo.serviceName ==
                                                                                                                                                                                                            "Azure Container Registry" || valueTwo.serviceName == "Container_Registry") ? (
                                                                                                                                                                                                            <AzureContainerRegistry
                                                                                                                                                                                                                values={valueTwo}
                                                                                                                                                                                                                onChangeOfConfiguration={
                                                                                                                                                                                                                    onChangeOfConfiguration
                                                                                                                                                                                                                }
                                                                                                                                                                                                                isViewer={isViewMode}
                                                                                                                                                                                                            />
                                                                                                                                                                                                        ) :
                                                                                                                                                                                                            (valueTwo.serviceName ==
                                                                                                                                                                                                                "Azure Front Door" || valueTwo.serviceName == "Azure_Front_Door") ? (
                                                                                                                                                                                                                <Azure_Front_Door
                                                                                                                                                                                                                    values={valueTwo}
                                                                                                                                                                                                                    onChangeOfConfiguration={
                                                                                                                                                                                                                        onChangeOfConfiguration
                                                                                                                                                                                                                    }
                                                                                                                                                                                                                    isViewer={isViewMode}
                                                                                                                                                                                                                />
                                                                                                                                                                                                            ) :
                                                                                                                                                                                                                (valueTwo.serviceName ==
                                                                                                                                                                                                                    "Azure Functions" || valueTwo.serviceName == "Azure Function- Consumption" || valueTwo.serviceName == "Azure Function" || valueTwo.serviceName == "Azure Function- Premium") ? (
                                                                                                                                                                                                                    <Azure_FunctionApp
                                                                                                                                                                                                                        values={valueTwo}
                                                                                                                                                                                                                        onChangeOfConfiguration={
                                                                                                                                                                                                                            onChangeOfConfiguration
                                                                                                                                                                                                                        }
                                                                                                                                                                                                                        isViewer={isViewMode}
                                                                                                                                                                                                                    />
                                                                                                                                                                                                                ) :
                                                                                                                                                                                                                    (valueTwo.serviceName ==
                                                                                                                                                                                                                        "AKS" || valueTwo.serviceName == "Azure_Kubernetes"
                                                                                                                                                                                                                        || valueTwo.serviceName == "Azure Kubernetes"
                                                                                                                                                                                                                    ) ? (
                                                                                                                                                                                                                        <Azure_Kubernetes
                                                                                                                                                                                                                            values={valueTwo}
                                                                                                                                                                                                                            onChangeOfConfiguration={
                                                                                                                                                                                                                                onChangeOfConfiguration
                                                                                                                                                                                                                            }
                                                                                                                                                                                                                            isViewer={isViewMode}
                                                                                                                                                                                                                        />
                                                                                                                                                                                                                    ) :
                                                                                                                                                                                                                        (valueTwo.serviceName ==
                                                                                                                                                                                                                            "Azure Open AI" || valueTwo.serviceName == "Azure_Open_AI") ? (
                                                                                                                                                                                                                            <Azure_Open_AI
                                                                                                                                                                                                                                values={valueTwo}
                                                                                                                                                                                                                                onChangeOfConfiguration={
                                                                                                                                                                                                                                    onChangeOfConfiguration
                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                isViewer={isViewMode}
                                                                                                                                                                                                                            />
                                                                                                                                                                                                                        ) :
                                                                                                                                                                                                                            (valueTwo.serviceName ==
                                                                                                                                                                                                                                "Azure Private Endpoint" ||
                                                                                                                                                                                                                                valueTwo.serviceName ==
                                                                                                                                                                                                                                "Private Endpoint" ||
                                                                                                                                                                                                                                valueTwo.serviceName == "Azure_Private_Endpoint" ||
                                                                                                                                                                                                                                valueTwo.serviceName == "Azure_Private_Endpoint"
                                                                                                                                                                                                                            ) ? (
                                                                                                                                                                                                                                <AzurePrivateEndpoint
                                                                                                                                                                                                                                    values={valueTwo}
                                                                                                                                                                                                                                    onChangeOfConfiguration={
                                                                                                                                                                                                                                        onChangeOfConfiguration
                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                    isViewer={isViewMode}
                                                                                                                                                                                                                                />
                                                                                                                                                                                                                            ) :

                                                                                                                                                                                                                                (valueTwo.serviceName ==
                                                                                                                                                                                                                                    "Virtual Desktop" ||
                                                                                                                                                                                                                                    valueTwo.serviceName ==
                                                                                                                                                                                                                                    "Virtual_Desktop" ||
                                                                                                                                                                                                                                    valueTwo.serviceName == "Azure_Virtual_Desktop" ||
                                                                                                                                                                                                                                    valueTwo.serviceName == "Azure Virtual Desktop"
                                                                                                                                                                                                                                ) ? (
                                                                                                                                                                                                                                    <Azure_Virtual_Desktop
                                                                                                                                                                                                                                        values={valueTwo}
                                                                                                                                                                                                                                        onChangeOfConfiguration={
                                                                                                                                                                                                                                            onChangeOfConfiguration
                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                        isViewer={isViewMode}
                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                ) :
                                                                                                                                                                                                                                    (valueTwo.serviceName ==
                                                                                                                                                                                                                                        "Virtual Machine" ||
                                                                                                                                                                                                                                        valueTwo.serviceName ==
                                                                                                                                                                                                                                        "Virtual_Machine" ||
                                                                                                                                                                                                                                        valueTwo.serviceName == "Azure_Virtual_Machine" ||
                                                                                                                                                                                                                                        valueTwo.serviceName == "Azure Virtual Machine"
                                                                                                                                                                                                                                    ) ? (
                                                                                                                                                                                                                                        <Azure_Virtual_Machine
                                                                                                                                                                                                                                            values={valueTwo}
                                                                                                                                                                                                                                            onChangeOfConfiguration={
                                                                                                                                                                                                                                                onChangeOfConfiguration
                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                            isViewer={isViewMode}
                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                    ) :
                                                                                                                                                                                                                                        (valueTwo.serviceName ==
                                                                                                                                                                                                                                            "Container Apps" ||
                                                                                                                                                                                                                                            valueTwo.serviceName ==
                                                                                                                                                                                                                                            "Azure Container Apps" ||
                                                                                                                                                                                                                                            valueTwo.serviceName == "Container_Apps" ||
                                                                                                                                                                                                                                            valueTwo.serviceName == "Azure_Container_Apps"
                                                                                                                                                                                                                                        ) ? (
                                                                                                                                                                                                                                            <Azure_Container_Apps
                                                                                                                                                                                                                                                values={valueTwo}
                                                                                                                                                                                                                                                onChangeOfConfiguration={
                                                                                                                                                                                                                                                    onChangeOfConfiguration
                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                isViewer={isViewMode}
                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                        ) :
                                                                                                                                                                                                                                            (valueTwo.serviceName ==
                                                                                                                                                                                                                                                "Azure Databricks" ||
                                                                                                                                                                                                                                                valueTwo.serviceName == "Databricks"

                                                                                                                                                                                                                                            ) ? (
                                                                                                                                                                                                                                                <Azure_Databrick
                                                                                                                                                                                                                                                    values={valueTwo}
                                                                                                                                                                                                                                                    onChangeOfConfiguration={
                                                                                                                                                                                                                                                        onChangeOfConfiguration
                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                    isViewer={isViewMode}
                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                            ) :
                                                                                                                                                                                                                                                (valueTwo.serviceName ==
                                                                                                                                                                                                                                                    "Disk" ||
                                                                                                                                                                                                                                                    valueTwo.serviceName == "Azure Disks"


                                                                                                                                                                                                                                                ) ? (
                                                                                                                                                                                                                                                    <Azure_Disk
                                                                                                                                                                                                                                                        values={valueTwo}
                                                                                                                                                                                                                                                        onChangeOfConfiguration={
                                                                                                                                                                                                                                                            onChangeOfConfiguration
                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                        isViewer={isViewMode}
                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                ) :
                                                                                                                                                                                                                                                    (valueTwo.serviceName ==
                                                                                                                                                                                                                                                        "Key Vault" ||
                                                                                                                                                                                                                                                        valueTwo.serviceName == "Key_Vault" ||
                                                                                                                                                                                                                                                        valueTwo.serviceName ==
                                                                                                                                                                                                                                                        "Azure Key Vault" ||
                                                                                                                                                                                                                                                        valueTwo.serviceName == "Azure_Key_Vault"

                                                                                                                                                                                                                                                    ) ? (
                                                                                                                                                                                                                                                        <AzureKeyVault
                                                                                                                                                                                                                                                            values={valueTwo}
                                                                                                                                                                                                                                                            onChangeOfConfiguration={
                                                                                                                                                                                                                                                                onChangeOfConfiguration
                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                            isViewer={isViewMode}
                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                    ) :
                                                                                                                                                                                                                                                        (valueTwo.serviceName == "Azure Load Balancing"
                                                                                                                                                                                                                                                        ) ? (
                                                                                                                                                                                                                                                            <AzureLoadBalancer
                                                                                                                                                                                                                                                                values={valueTwo}
                                                                                                                                                                                                                                                                onChangeOfConfiguration={
                                                                                                                                                                                                                                                                    onChangeOfConfiguration
                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                isViewer={isViewMode}
                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                        ) :
                                                                                                                                                                                                                                                            (valueTwo.serviceName ==
                                                                                                                                                                                                                                                                "Log Analytic" ||
                                                                                                                                                                                                                                                                valueTwo.serviceName == "Azure_Log_Analytic" ||
                                                                                                                                                                                                                                                                valueTwo.serviceName ==
                                                                                                                                                                                                                                                                "Log_Analytic" ||
                                                                                                                                                                                                                                                                valueTwo.serviceName == "Azure Log Analytic"

                                                                                                                                                                                                                                                            ) ? (
                                                                                                                                                                                                                                                                <AzureLogAnalytics
                                                                                                                                                                                                                                                                    values={valueTwo}
                                                                                                                                                                                                                                                                    onChangeOfConfiguration={
                                                                                                                                                                                                                                                                        onChangeOfConfiguration
                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                    isViewer={isViewMode}
                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                            ) :
                                                                                                                                                                                                                                                                (valueTwo.serviceName ==
                                                                                                                                                                                                                                                                    "Public IP Address" ||
                                                                                                                                                                                                                                                                    valueTwo.serviceName == "Public IPAddress" ||
                                                                                                                                                                                                                                                                    valueTwo.serviceName ==
                                                                                                                                                                                                                                                                    "Azure Public IP Address" ||
                                                                                                                                                                                                                                                                    valueTwo.serviceName == "Azure_Public_IP_Address"

                                                                                                                                                                                                                                                                ) ? (
                                                                                                                                                                                                                                                                    <AzurePublicIpAddress
                                                                                                                                                                                                                                                                        values={valueTwo}
                                                                                                                                                                                                                                                                        onChangeOfConfiguration={
                                                                                                                                                                                                                                                                            onChangeOfConfiguration
                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                        isViewer={isViewMode}
                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                ) :
                                                                                                                                                                                                                                                                    (valueTwo.serviceName ==
                                                                                                                                                                                                                                                                        "Azure SQL Database" ||
                                                                                                                                                                                                                                                                        valueTwo.serviceName == "Azure_SQL_Database" ||
                                                                                                                                                                                                                                                                        valueTwo.serviceName ==
                                                                                                                                                                                                                                                                        "SQL Database" ||
                                                                                                                                                                                                                                                                        valueTwo.serviceName == "SQL_Database"

                                                                                                                                                                                                                                                                    ) ? (
                                                                                                                                                                                                                                                                        <Azure_SQL_DB
                                                                                                                                                                                                                                                                            values={valueTwo}
                                                                                                                                                                                                                                                                            onChangeOfConfiguration={
                                                                                                                                                                                                                                                                                onChangeOfConfiguration
                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                            isViewer={isViewMode}
                                                                                                                                                                                                                                                                        />
                                                                                                                                                                                                                                                                    ) :
                                                                                                                                                                                                                                                                        (valueTwo.serviceName ==
                                                                                                                                                                                                                                                                            "Storage Account" ||
                                                                                                                                                                                                                                                                            valueTwo.serviceName == "Azure_Storage_Account" ||
                                                                                                                                                                                                                                                                            valueTwo.serviceName ==
                                                                                                                                                                                                                                                                            "Storage_Account" ||
                                                                                                                                                                                                                                                                            valueTwo.serviceName == "Azure Storage Account"

                                                                                                                                                                                                                                                                        ) ? (
                                                                                                                                                                                                                                                                            <Azure_StorageAccount
                                                                                                                                                                                                                                                                                values={valueTwo}
                                                                                                                                                                                                                                                                                onChangeOfConfiguration={
                                                                                                                                                                                                                                                                                    onChangeOfConfiguration
                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                isViewer={isViewMode}
                                                                                                                                                                                                                                                                            />
                                                                                                                                                                                                                                                                        ) :
                                                                                                                                                                                                                                                                            (valueTwo.serviceName ==
                                                                                                                                                                                                                                                                                "Virtual Network" ||
                                                                                                                                                                                                                                                                                valueTwo.serviceName == "Virtual_Network" ||
                                                                                                                                                                                                                                                                                valueTwo.serviceName ==
                                                                                                                                                                                                                                                                                "Azure_Virtual_Network_Peering" ||
                                                                                                                                                                                                                                                                                valueTwo.serviceName == "Azure Virtual Network"

                                                                                                                                                                                                                                                                            ) ? (
                                                                                                                                                                                                                                                                                <AzureVirtualNetworkPeering
                                                                                                                                                                                                                                                                                    values={valueTwo}
                                                                                                                                                                                                                                                                                    onChangeOfConfiguration={
                                                                                                                                                                                                                                                                                        onChangeOfConfiguration
                                                                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                                                                    isViewer={isViewMode}
                                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                            ) : (valueTwo.serviceName === "Azure Virtual Network Peering") ? (
                                                                                                                                                                                                                                                                                <AzureVirtualNetworkPeering
                                                                                                                                                                                                                                                                                    values={valueTwo}
                                                                                                                                                                                                                                                                                    onChangeOfConfiguration={onChangeOfConfiguration}
                                                                                                                                                                                                                                                                                    isViewer={isViewMode}
                                                                                                                                                                                                                                                                                />
                                                                                                                                                                                                                                                                            ) : (approvalId !== "" && valueTwo.serviceName === "Pricing Calculator Cost") ?
                                                                                                                                                                                                                                                                                (
                                                                                                                                                                                                                                                                                    <scrapedCost
                                                                                                                                                                                                                                                                                        values={valueTwo}
                                                                                                                                                                                                                                                                                        scrapedCost={PricingCalculatorCost}
                                                                                                                                                                                                                                                                                        isViewer={isViewer}
                                                                                                                                                                                                                                                                                    />
                                                                                                                                                                                                                                                                                ) : (
                                                                                                                                                                                                                                                                                    <h6 style={{ textAlign: "center", marginTop: "5%" }}>Currently, the service is not supported.</h6>
                                                                                                                                                                                                                                                                                )





                                                                                                                            }


                                                                                                                        </div>

                                                                                                                        {/* Service-specific Configuration */}


                                                                                                                    </div>
                                                                                                                ))}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            )}
                                                </div>
                                            </div>
                                            {/* first sub acc end */}
                                        </div>

                                        {/* sixth acc end */}
                                    </div>
                                </div>
                            </div>
                            {/* accordion ends here */}
                            <div className="col-md-12 mt-5 pt-4">
                                <div className="text-end">
                                    {renderActionButton()}
                                </div>
                            </div>
                            {showRejectionModal && (
                                <div
                                    className="modal fade show"
                                    style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                                    id="staticBackdrop-21"
                                    data-bs-backdrop="static"
                                    data-bs-keyboard="false"
                                    tabIndex={-1}
                                    aria-labelledby="staticBackdropLabel"
                                    aria-hidden="true"
                                >
                                    <div className="modal-dialog modal-dialog-centered justify-content-center article-popup">
                                        <div className="modal-content border-0">
                                            <div className="modal-header border-0 py-4 px-32">
                                                <h1
                                                    className="modal-title font-20 font-bold black-v2"
                                                    id="staticBackdropLabel"
                                                >
                                                    Rejection
                                                </h1>
                                                <button
                                                    type="button"
                                                    className="btn shadow-none pe-0"
                                                    onClick={() => setShowRejectionModal(false)}
                                                    aria-label="Close"
                                                >
                                                    <img
                                                        src="images/close-icon.svg"
                                                        alt="close-icon"
                                                        className="icon-hover shadow-none modal-close-filter"
                                                    />
                                                </button>
                                            </div>
                                            <div className="modal-body p-32 pt-0">
                                                <label
                                                    className="form-label font-14 font-medium black-v2 mb-3"
                                                    htmlFor="rejection-reason"
                                                >
                                                    Reason for Rejection <span className="danger-red">*</span>
                                                </label>
                                                <textarea
                                                    className="form-control custom-form article-grey-colorarea font-14 font-medium"
                                                    placeholder="Enter Reason for rejection"
                                                    id="rejection-reason"
                                                    value={rejectionReason}
                                                    onChange={(e) => setRejectionReason(e.target.value)}
                                                />
                                                <div className="d-flex justify-content-end align-items-center mt-5 gap-3">
                                                    <button
                                                        type="button"
                                                        className="button outline-button text-nowrap"
                                                        onClick={() => setShowRejectionModal(false)}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="button primary-button text-nowrap"
                                                        onClick={() => {
                                                            sendApprovalDecision("Rejected", rejectionReason);
                                                            setShowRejectionModal(false);
                                                        }}
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {showApprovalModal && (
                                <div
                                    className="modal fade show"
                                    style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                                    id="staticBackdrop-10"
                                    data-bs-backdrop="static"
                                    data-bs-keyboard="false"
                                    tabIndex={-1}
                                    aria-labelledby="staticBackdropLabel"
                                    aria-hidden="true"
                                >
                                    <div className="modal-dialog modal-dialog-centered justify-content-center">
                                        <div className="modal-content approvel-dialog border-0">
                                            <div className="modal-header border-0 p-32 pb-4">
                                                <h1
                                                    className="modal-title font-20 font-bold black-v2"
                                                    id="staticBackdropLabel"
                                                >
                                                    <img src="images/alert-icon.svg" alt="alert-icon" /> Approval
                                                    Needed
                                                </h1>
                                                <button
                                                    type="button"
                                                    className="btn shadow-none p-0"
                                                    onClick={() => setShowApprovalModal(false)}
                                                    aria-label="Close"
                                                >
                                                    <img
                                                        src="images/close-icon.svg"
                                                        alt="close-icon"
                                                        className="icon-hover shadow-none modal-close-filter"
                                                    />
                                                </button>
                                            </div>
                                            <div className="modal-body px-32 pt-0">
                                                <p className="font-16 font-regular secondary-text-color-v4 mb-32">
                                                    Approval needed to proceed to further actions.
                                                </p>
                                                <div className="my-4">
                                                    <label
                                                        className="form-label font-14 font-medium black-v2"
                                                        htmlFor="admin-select"
                                                    >
                                                        Select Admin
                                                        <span className="danger-red">*</span>
                                                    </label>
                                                    <select
                                                        className="form-select custom-dropdown shadow-none custom-border-box font-14 font-regular black-v2 custom-select"
                                                        id="admin-select"
                                                        value={selectedAdmin}
                                                        onChange={(e) => setSelectedAdmin(e.target.value)}
                                                    >
                                                        <option value="">Select</option>
                                                        {approvers.Admin.map((admin) => (
                                                            <option key={admin.user_id} value={admin.user_id}>
                                                                {admin.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="my-4">
                                                    <label
                                                        className="form-label font-14 font-medium black-v2"
                                                        htmlFor="project-admin-select"
                                                    >
                                                        Select Project Admin
                                                    </label>
                                                    <select
                                                        className="form-select custom-dropdown shadow-none custom-border-box font-14 font-regular black-v2 custom-select"
                                                        id="project-admin-select"
                                                        value={selectedProjectAdmin}
                                                        onChange={(e) => setSelectedProjectAdmin(e.target.value)}
                                                    >
                                                        <option value="">Select</option>
                                                        {approvers.ProjectAdmin.map((admin) => (
                                                            <option key={admin.user_id} value={admin.user_id}>
                                                                {admin.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="my-4">
                                                    <label
                                                        className="form-label font-14 font-medium black-v2"
                                                        htmlFor="pricing-calculator-url"
                                                    >
                                                        {providerName === "AWS" ? "AWS" : "Azure"} Pricing Calculator
                                                        URL
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control custom-dropdown shadow-none"
                                                        id="pricing-calculator-url"
                                                        placeholder="Enter URL"
                                                        value={pricingCalculatorURL}
                                                        onChange={(e) => setPricingCalculatorURL(e.target.value)}
                                                    />
                                                </div>
                                                <div className="d-flex justify-content-end align-items-center mt-5 mb-2 gap-3">
                                                    <button
                                                        type="button"
                                                        className="button outline-button text-nowrap px-4"
                                                        onClick={() => setShowApprovalModal(false)}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn button primary-button text-nowrap px-3"
                                                        onClick={handleSendRequest}
                                                        disabled={!selectedAdmin}
                                                    >
                                                        Send Request
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {showThresholdToast && (
                                <div
                                    className="container-fluid"
                                    style={{
                                        position: "fixed",
                                        top: "20px",
                                        left: "50%",
                                        transform: "translateX(-50%)",
                                        zIndex: 1060,
                                    }}
                                >
                                    <div className="p-4">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <div className="custom-toast-container d-flex align-items-center justify-content-between">
                                                <div className="d-flex align-items-center gap-3 mb-0">
                                                    <img
                                                        src="images/tick-green-small.svg"
                                                        alt="tick-green"
                                                        className="width-25"
                                                    />
                                                    <p className="font-14 font-regular text-white mb-0">
                                                        {thresholdToastMessage}
                                                    </p>
                                                </div>
                                                <button
                                                    type="button"
                                                    className="btn p-0 shadow-none"
                                                    onClick={() => setShowThresholdToast(false)}
                                                >
                                                    <img
                                                        src="images/cross-white.svg"
                                                        alt="cross-icon"
                                                        className="w-9"
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* Add the toast message */}

                            {showApprovalToast && (
                                <div
                                    className="container-fluid"
                                    style={{
                                        position: "fixed",
                                        top: "20px",
                                        left: "50%",
                                        transform: "translateX(-50%)",
                                        zIndex: 1060,
                                    }}
                                >
                                    <div className="p-4">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <div className="custom-toast-container d-flex align-items-center justify-content-between">
                                                <div className="d-flex align-items-center gap-3 mb-0">
                                                    <img
                                                        src="images/tick-green-small.svg"
                                                        alt="tick-green"
                                                        className="width-25"
                                                    />
                                                    <p className="font-14 font-regular text-white mb-0">
                                                        Approval sent successfully
                                                    </p>
                                                </div>
                                                <button
                                                    type="button"
                                                    className="btn p-0 shadow-none"
                                                    onClick={() => setShowApprovalToast(false)}
                                                >
                                                    <img
                                                        src="images/cross-white.svg"
                                                        alt="cross-icon"
                                                        className="w-9"
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {showActionToast && (
                                <div
                                    className="container-fluid"
                                    style={{
                                        position: "fixed",
                                        top: "20px",
                                        left: "50%",
                                        transform: "translateX(-50%)",
                                        zIndex: 1060,
                                    }}
                                >
                                    <div className="p-4">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <div className="custom-toast-container d-flex align-items-center justify-content-between">
                                                <div className="d-flex align-items-center gap-3 mb-0">
                                                    <img
                                                        src="images/tick-green-small.svg"
                                                        alt="tick-green"
                                                        className="width-25"
                                                    />
                                                    <p className="font-14 font-regular text-white mb-0">
                                                        {toastMessage}
                                                    </p>
                                                </div>
                                                <button
                                                    type="button"
                                                    className="btn p-0 shadow-none"
                                                    onClick={() => setShowActionToast(false)}
                                                >
                                                    <img
                                                        src="images/cross-white.svg"
                                                        alt="cross-icon"
                                                        className="w-9"
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div >
                </>}
        </>
    )
}

export default function Estimation({ approvalId, approvalStatus }) {
    // const hardcodedApprovalId = '1f150a82-07b1-4954-991a-daf82d69241f';
    // const hardcodedApprovalStatus = 'Pending';
    return (


        <EstimationContent approvalId={approvalId} approvalStatus={approvalStatus} />

        // <EstimationProvider>
        //   <EstimationContent approvalId={hardcodedApprovalId} approvalStatus={hardcodedApprovalStatus} />
        // </EstimationProvider>
    );
}