// values["region"]= "us-east-2",
// values["tier"] = "basic";
// values["days"] = "1";
// values["storageUnitsFactor"] = "1";
// values["storageUnits"] = "1";
// values["cpus"] = "1";
// values["buildUnits"] = "1";
// values["dataTransferType"] = "interregion";
// values["destinationRegion"] = "";
// values["interRegionUnits"] = "1";
// values["routedVia"] = "microsoftglobalnetwork";
// values["internetEgressUnits"] = "1";
// values["addGeoReplication"] = "Yes";
// values["geoReplicatedRegions"] = "1";
// values["tier"] = "premium";
// values["tier"] = "standard";
// values["dataTransferType"] = "interregion";
// values["routedVia"] = "publicinternet";

import { useState } from "react";
export default function AzureContainerRegistry({ values, onChangeOfConfiguration, isViewer }) {

    const azureRegions = [
        "us-central", "us-east", "us-east-2", "us-north-central", "us-south-central",
        "us-west-central", "us-west", "us-west-2", "us-west-3", "united-kingdom-south",
        "united-kingdom-west", "uae-central", "uae-north", "switzerland-north",
        "switzerland-west", "sweden-central", "sweden-south", "spain-central",
        "qatar-central", "poland-central", "norway-east", "norway-west", "mexico-central",
        "korea-central", "korea-south", "japan-east", "japan-west", "italy-north",
        "israel-central", "central-india", "south-india", "west-india", "germany-north",
        "germany-west-central", "france-central", "france-south", "europe-north",
        "europe-west", "canada-central", "canada-east", "brazil-south", "brazil-southeast",
        "australia-central", "australia-central-2", "australia-east", "australia-southeast",
        "asia-pacific-east", "asia-pacific-southeast", "south-africa-north", "south-africa-west"
    ];

    const [sourceRegion, setSourceRegion] = useState('');
    const [destinationRegion, setDestinationRegion] = useState('');

    const handleSourceRegionChange = (event) => {
        setSourceRegion(event.target.value);
        setDestinationRegion('');
    };

    const handleDestinationRegionChange = (event) => {
        setDestinationRegion(event.target.value);
    };
    try {
        return (

            <>
                <div className="row py-4 mx-4 px-5" hidden={(values.isPriceAvailable == "No") ? false : true}>
                    <div className="col-md-6 px-3"><span className="font-semibold font-14 text-danger">{values.isPriceAvailable == "No" ? "The Price is not available for the region / the configuration that you have selected might be not available in the region" : null}</span></div>
                </div>
                <div className="row py-4 mx-4 px-5">
                    <div className="col-md-6 px-3">
                        <label htmlFor="region" className="form-label font-14 font-medium black-v2 mb-3">
                            Region
                        </label>
                        <select
                            className=" form-select custom-form  font-semibold font-14 form-select-sm"
                            id="region"
                            style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                            value={values.region}
                            name={"region"}
                            onChange={(e) => { onChangeOfConfiguration(e, values.instanceName); handleSourceRegionChange(e) }}
                            disabled={isViewer}>
                            <option value="us-central">Central US</option>
                            <option value="us-east">East US</option>
                            <option value="us-east-2">East US 2</option>
                            <option value="us-north-central">North Central US</option>
                            <option value="us-south-central">South Central US</option>
                            <option value="us-west-central">West Central US</option>
                            <option value="us-west">West US</option>
                            <option value="us-west-2">West US 2</option>
                            <option value="us-west-3">West US 3</option>
                            <option value="united-kingdom-south">UK South</option>
                            <option value="united-kingdom-west">UK West</option>
                            <option value="uae-central">UAE Central</option>
                            <option value="uae-north">UAE North</option>
                            <option value="switzerland-north">Switzerland North</option>
                            <option value="switzerland-west">Switzerland West</option>
                            <option value="sweden-central">Sweden Central</option>
                            <option value="sweden-south">Sweden South</option>
                            <option value="spain-central">Spain Central</option>
                            <option value="qatar-central">Qatar Central</option>
                            <option value="poland-central">Poland Central</option>
                            <option value="norway-east">Norway East</option>
                            <option value="norway-west">Norway West</option>
                            <option value="mexico-central">Mexico Central</option>
                            <option value="korea-central">Korea Central</option>
                            <option value="korea-south">Korea South</option>
                            <option value="japan-east">Japan East</option>
                            <option value="japan-west">Japan West</option>
                            <option value="italy-north">Italy North</option>
                            <option value="israel-central">Israel Central</option>
                            <option value="central-india">Central India</option>
                            <option value="south-india">South India</option>
                            <option value="west-india">West India</option>
                            <option value="germany-north">Germany North</option>
                            <option value="germany-west-central">Germany West Central</option>
                            <option value="france-central">France Central</option>
                            <option value="france-south">France South</option>
                            <option value="europe-north">North Europe</option>
                            <option value="europe-west">West Europe</option>
                            <option value="canada-central">Canada Central</option>
                            <option value="canada-east">Canada East</option>
                            <option value="brazil-south">Brazil South</option>
                            <option value="brazil-southeast">Brazil Southeast</option>
                            <option value="australia-central">Australia Central</option>
                            <option value="australia-central-2">Australia Central 2</option>
                            <option value="australia-east">Australia East</option>
                            <option value="australia-southeast">Australia Southeast</option>
                            <option value="asia-pacific-east">East Asia</option>
                            <option value="asia-pacific-southeast">Southeast Asia</option>
                            <option value="south-africa-north">South Africa North</option>
                            <option value="south-africa-west">South Africa West</option>
                        </select>
                    </div>
                    <div className="col-md-6 px-3">
                        <label htmlFor="tier" className="form-label font-14 font-medium black-v2 mb-3">
                            Tier
                        </label>
                        <select
                            className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                            id="tier"
                            style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                            value={values.tier}
                            name={"tier"}
                            onChange={(e) => {
                                onChangeOfConfiguration(e, values.instanceName);
                            }} disabled={isViewer}>
                            <option value="basic">Basic</option>
                            <option value="standard">Standard</option>
                            <option value="premium">Premium</option>
                        </select>
                    </div>

                    {values.tier === "basic" && <>
                        <div className="col-md-6 px-3">
                            <label htmlFor="days" className="form-label font-14 font-medium black-v2 mb-3">
                                Days
                            </label>
                            <input
                                type="text"
                                style={{paddingTop :"10px"}}
                                className=" form-control  custom-form text-field font-14 font-medium"
                                id="days"
                                value={values.days}
                                name={"days"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            />
                        </div>

                        <div className="col-md-6 px-3">
                            <label htmlFor="storageUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                Extra Storage Units
                            </label>
                            <input
                                type="text"
                                style={{paddingTop :"10px"}}
                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                id="storageUnits"
                                value={values.storageUnits}
                                name={"storageUnits"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            />
                        </div>

                        <div className="col-md-6 px-3">
                            <label htmlFor="storageUnitsFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                Extra Storage Units Factor
                            </label>
                            <select
                                className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                id="storageUnitsFactor"
                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                value={values.storageUnitsFactor}
                                name={"storageUnitsFactor"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}>
                                <option value="1">GB</option>
                                <option value="1024">TB</option>
                            </select>
                        </div>

                        <div className="col-md-6 px-3">
                            <label htmlFor="cpus" className="form-label font-14 font-medium black-v2 mb-3">
                                Container Build (Preview) CPUs
                            </label>
                            <input
                                type="text"
                                style={{paddingTop :"10px"}}
                                className=" form-control  custom-form text-field font-14 font-medium"
                                id="cpus"
                                value={values.cpus}
                                name={"cpus"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            />
                        </div>

                        <div className="col-md-6 px-3">
                            <label htmlFor="buildUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                Container Build (Preview) Seconds
                            </label>
                            <input
                                type="text"
                                style={{paddingTop :"10px"}}
                                placeholder="Enter the amount"
                                className=" form-control  custom-form text-field font-14 font-medium"
                                id="buildUnits"
                                value={values.buildUnits}
                                name={"buildUnits"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            />
                        </div>

                        <div className="col-md-6 px-3">
                            <label htmlFor="dataTransferType" className="form-label font-14 font-medium black-v2 mb-3">
                                Bandwidth - Data Transfer Type
                            </label>
                            <select
                                className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                id="dataTransferType"
                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                value={values.dataTransferType}
                                name={"dataTransferType"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}>
                                <option value="interregion">Inter Region</option>
                                <option value="internetegress">Internet Egress</option>
                            </select>
                        </div>

                        <div className="col-md-6 px-3">
                            <label htmlFor="region" className="form-label font-14 font-medium black-v2 mb-3">
                                Source Region
                            </label>
                            <select
                                className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                id="region"
                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                value={values.region}
                                name={"region"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}>
                                <option value="us-central">Central US</option>
                                <option value="us-east">East US</option>
                                <option value="us-east-2">East US 2</option>
                                <option value="us-north-central">North Central US</option>
                                <option value="us-south-central">South Central US</option>
                                <option value="us-west-central">West Central US</option>
                                <option value="us-west">West US</option>
                                <option value="us-west-2">West US 2</option>
                                <option value="us-west-3">West US 3</option>
                                <option value="united-kingdom-south">UK South</option>
                                <option value="united-kingdom-west">UK West</option>
                                <option value="uae-central">UAE Central</option>
                                <option value="uae-north">UAE North</option>
                                <option value="switzerland-north">Switzerland North</option>
                                <option value="switzerland-west">Switzerland West</option>
                                <option value="sweden-central">Sweden Central</option>
                                <option value="sweden-south">Sweden South</option>
                                <option value="spain-central">Spain Central</option>
                                <option value="qatar-central">Qatar Central</option>
                                <option value="poland-central">Poland Central</option>
                                <option value="norway-east">Norway East</option>
                                <option value="norway-west">Norway West</option>
                                <option value="mexico-central">Mexico Central</option>
                                <option value="korea-central">Korea Central</option>
                                <option value="korea-south">Korea South</option>
                                <option value="japan-east">Japan East</option>
                                <option value="japan-west">Japan West</option>
                                <option value="italy-north">Italy North</option>
                                <option value="israel-central">Israel Central</option>
                                <option value="central-india">Central India</option>
                                <option value="south-india">South India</option>
                                <option value="west-india">West India</option>
                                <option value="germany-north">Germany North</option>
                                <option value="germany-west-central">Germany West Central</option>
                                <option value="france-central">France Central</option>
                                <option value="france-south">France South</option>
                                <option value="europe-north">North Europe</option>
                                <option value="europe-west">West Europe</option>
                                <option value="canada-central">Canada Central</option>
                                <option value="canada-east">Canada East</option>
                                <option value="brazil-south">Brazil South</option>
                                <option value="brazil-southeast">Brazil Southeast</option>
                                <option value="australia-central">Australia Central</option>
                                <option value="australia-central-2">Australia Central 2</option>
                                <option value="australia-east">Australia East</option>
                                <option value="australia-southeast">Australia Southeast</option>
                                <option value="asia-pacific-east">East Asia</option>
                                <option value="asia-pacific-southeast">Southeast Asia</option>
                                <option value="south-africa-north">South Africa North</option>
                                <option value="south-africa-west">South Africa West</option>
                            </select>
                        </div>

                        {values.dataTransferType == "interregion" && <>
                            <div className="col-md-6 px-3">
                                <label htmlFor="destinationRegion" className="form-label font-14 font-medium black-v2 mb-3">
                                    Destination Region
                                </label>
                                <select
                                    className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                    id="destinationRegion"
                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                    value={destinationRegion}
                                    name={"destinationRegion"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                        handleDestinationRegionChange(e);
                                    }}
                                    disabled={isViewer}>
                                    {azureRegions
                                        .filter(region => region !== sourceRegion)
                                        .map((region, index) => (
                                            <option key={index} value={region}>
                                                {region}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                        </>}
                        {values.dataTransferType == "internetegress" && <>
                            <div className="col-md-6 px-3">
                                <label htmlFor="routedVia" className="form-label font-14 font-medium black-v2 mb-3">
                                    Bandwidth - Routed Via
                                </label>
                                <select
                                    className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                    id="routedVia"
                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                    value={values.routedVia}
                                    name={"routedVia"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}>
                                    <option value="microsoftglobalnetwork">Microsoft Global Network</option>
                                    <option value="publicinternet">Public Internet</option>
                                </select>
                            </div>
                        </>}
                        {!["us-west-3", "sweden-south", "spain-central", "poland-central", "mexico-central", "italy-north", "israel-central"].includes(values.region) && (
                            <div>
                                <label htmlFor="interRegionUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                    Outbound Data Transfer - GB
                                </label>
                                <input
                                    type="text"
                                    style={{paddingTop :"10px"}}
                                    className=" form-control  custom-form text-field font-14 font-medium"
                                    id="interRegionUnits"
                                    value={values.interRegionUnits}
                                    name="interRegionUnits"
                                    onChange={(e) => onChangeOfConfiguration(e, values.instanceName)}
                                    disabled={isViewer}
                                />
                            </div>)}
                    </>}
                    {values.tier === "standard" && <>
                        <div className="col-md-6 px-3">
                            <label htmlFor="days" className="form-label font-14 font-medium black-v2 mb-3">
                                Days
                            </label>
                            <input
                                type="text"
                                style={{paddingTop :"10px"}}
                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                id="days"
                                value={values.days}
                                name={"days"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            />
                        </div>

                        <div className="col-md-6 px-3">
                            <label htmlFor="storageUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                Extra Storage Units
                            </label>
                            <input
                                type="text"
                                style={{paddingTop :"10px"}}
                                className=" form-control  custom-form text-field font-14 font-medium"
                                id="storageUnits"
                                value={values.storageUnits}
                                name={"storageUnits"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            />
                        </div>

                        <div className="col-md-6 px-3">
                            <label htmlFor="storageUnitsFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                Extra Storage Units Factor
                            </label>
                            <select
                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                id="storageUnitsFactor"
                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                value={values.storageUnitsFactor}
                                name={"storageUnitsFactor"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}>
                                <option value="1">GB</option>
                                <option value="1024">TB</option>
                            </select>
                        </div>

                        <div className="col-md-6 px-3">
                            <label htmlFor="cpus" className="form-label font-14 font-medium black-v2 mb-3">
                                Container Build (Preview) CPUs
                            </label>
                            <input
                                type="text"
                                style={{paddingTop :"10px"}}
                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                id="cpus"
                                value={values.cpus}
                                name={"cpus"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            />
                        </div>

                        <div className="col-md-6 px-3">
                            <label htmlFor="buildUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                Container Build (Preview) Seconds
                            </label>
                            <input
                                type="text"
                                style={{paddingTop :"10px"}}
                                placeholder="Enter the amount"
                                className=" form-control  custom-form text-field font-14 font-medium"
                                id="buildUnits"
                                value={values.buildUnits}
                                name={"buildUnits"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            />
                        </div>

                        <div className="col-md-6 px-3">
                            <label htmlFor="dataTransferType" className="form-label font-14 font-medium black-v2 mb-3">
                                Bandwidth - Data Transfer Type
                            </label>
                            <select
                                className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                id="dataTransferType"
                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                value={values.dataTransferType}
                                name={"dataTransferType"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}>
                                <option value="interregion">Inter Region</option>
                                <option value="internetegress">Internet Egress</option>
                            </select>
                        </div>

                        <div className="col-md-6 px-3">
                            <label htmlFor="region" className="form-label font-14 font-medium black-v2 mb-3">
                                Source Region
                            </label>
                            <select
                                className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                id="region"
                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                value={values.region}
                                name={"region"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}>
                                <option value="us-central">Central US</option>
                                <option value="us-east">East US</option>
                                <option value="us-east-2">East US 2</option>
                                <option value="us-north-central">North Central US</option>
                                <option value="us-south-central">South Central US</option>
                                <option value="us-west-central">West Central US</option>
                                <option value="us-west">West US</option>
                                <option value="us-west-2">West US 2</option>
                                <option value="us-west-3">West US 3</option>
                                <option value="united-kingdom-south">UK South</option>
                                <option value="united-kingdom-west">UK West</option>
                                <option value="uae-central">UAE Central</option>
                                <option value="uae-north">UAE North</option>
                                <option value="switzerland-north">Switzerland North</option>
                                <option value="switzerland-west">Switzerland West</option>
                                <option value="sweden-central">Sweden Central</option>
                                <option value="sweden-south">Sweden South</option>
                                <option value="spain-central">Spain Central</option>
                                <option value="qatar-central">Qatar Central</option>
                                <option value="poland-central">Poland Central</option>
                                <option value="norway-east">Norway East</option>
                                <option value="norway-west">Norway West</option>
                                <option value="mexico-central">Mexico Central</option>
                                <option value="korea-central">Korea Central</option>
                                <option value="korea-south">Korea South</option>
                                <option value="japan-east">Japan East</option>
                                <option value="japan-west">Japan West</option>
                                <option value="italy-north">Italy North</option>
                                <option value="israel-central">Israel Central</option>
                                <option value="central-india">Central India</option>
                                <option value="south-india">South India</option>
                                <option value="west-india">West India</option>
                                <option value="germany-north">Germany North</option>
                                <option value="germany-west-central">Germany West Central</option>
                                <option value="france-central">France Central</option>
                                <option value="france-south">France South</option>
                                <option value="europe-north">North Europe</option>
                                <option value="europe-west">West Europe</option>
                                <option value="canada-central">Canada Central</option>
                                <option value="canada-east">Canada East</option>
                                <option value="brazil-south">Brazil South</option>
                                <option value="brazil-southeast">Brazil Southeast</option>
                                <option value="australia-central">Australia Central</option>
                                <option value="australia-central-2">Australia Central 2</option>
                                <option value="australia-east">Australia East</option>
                                <option value="australia-southeast">Australia Southeast</option>
                                <option value="asia-pacific-east">East Asia</option>
                                <option value="asia-pacific-southeast">Southeast Asia</option>
                                <option value="south-africa-north">South Africa North</option>
                                <option value="south-africa-west">South Africa West</option>
                            </select>
                        </div>

                        {values.dataTransferType == "interregion" && <>
                            <div className="col-md-6 px-3">
                                <label htmlFor="destinationRegion" className="form-label font-14 font-medium black-v2 mb-3">
                                    Destination Region
                                </label>
                                <select
                                    className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                    id="destinationRegion"
                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                    value={destinationRegion}
                                    name={"destinationRegion"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                        handleDestinationRegionChange(e);
                                    }}
                                    disabled={isViewer}>
                                    {azureRegions
                                        .filter(region => region !== sourceRegion)
                                        .map((region, index) => (
                                            <option key={index} value={region}>
                                                {region}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        </>}
                        {values.dataTransferType == "internetegress" && <>
                            <div className="col-md-6 px-3">
                                <label htmlFor="routedVia" className="form-label font-14 font-medium black-v2 mb-3">
                                    Bandwidth - Routed Via
                                </label>
                                <select
                                    className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                    id="routedVia"
                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                    value={values.routedVia}
                                    name={"routedVia"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}>
                                    <option value="microsoftglobalnetwork">Microsoft Global Network</option>
                                    <option value="publicinternet">Public Internet</option>
                                </select>
                            </div>
                        </>}
                        {!["us-west-3", "sweden-south", "spain-central", "poland-central", "mexico-central", "italy-north", "israel-central"].includes(values.region) && (
                            <div>
                                <label htmlFor="interRegionUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                    Outbound Data Transfer - GB
                                </label>
                                <input
                                    type="text"
                                    style={{paddingTop :"10px"}}
                                    className=" form-control  custom-form text-field font-14 font-medium"
                                    id="interRegionUnits"
                                    value={values.interRegionUnits}
                                    name="interRegionUnits"
                                    onChange={(e) => onChangeOfConfiguration(e, values.instanceName)}
                                    disabled={isViewer}
                                />
                            </div>
                        )}
                    </>}
                    {values.tier === "premium" && <>

                        <div className="col-md-6 px-3">
                            <label htmlFor="days" className="form-label font-14 font-medium black-v2 mb-3">
                                Days
                            </label>
                            <input
                                type="text"
                                style={{paddingTop :"10px"}}
                                className=" form-control  custom-form text-field font-14 font-medium"
                                id="days"
                                value={values.days}
                                name={"days"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            />
                        </div>
                        <div className="col-md-6 px-3">
                            <label htmlFor="addGeoReplication" className="form-label font-14 font-medium black-v2 mb-3">
                                Add Geo Replication
                            </label>
                            <select
                                className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                id="addGeoReplication"
                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                value={values.addGeoReplication}
                                name={"addGeoReplication"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}>
                                <option value="No">No</option>
                                <option value="Yes">Yes</option>
                            </select>
                        </div>
                        {values.addGeoReplication === "Yes" &&
                            <>
                                <div className="col-md-6 px-3">
                                    <label htmlFor="geoReplicatedRegions" className="form-label font-14 font-medium black-v2 mb-3">
                                        Geo Replication - Regions
                                    </label>
                                    <input
                                        type="text"
                                        className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                        id="geoReplicatedRegions"
                                        value={values.geoReplicatedRegions}
                                        name={"geoReplicatedRegions"}
                                        onChange={(e) => {
                                            onChangeOfConfiguration(e, values.instanceName);
                                        }} disabled={isViewer}
                                    />
                                </div>
                            </>}
                        <div className="col-md-6 px-3">
                            <label htmlFor="storageUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                Extra Storage Units
                            </label>
                            <input
                                type="text"
                                style={{paddingTop :"10px"}}
                                className=" form-control  custom-form text-field font-14 font-medium"
                                id="storageUnits"
                                value={values.storageUnits}
                                name={"storageUnits"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            />
                        </div>

                        <div className="col-md-6 px-3">
                            <label htmlFor="storageUnitsFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                Extra Storage Units Factor
                            </label>
                            <select
                                className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                id="storageUnitsFactor"
                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                value={values.storageUnitsFactor}
                                name={"storageUnitsFactor"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}>
                                <option value="1">GB</option>
                                <option value="1024">TB</option>
                            </select>
                        </div>

                        <div className="col-md-6 px-3">
                            <label htmlFor="cpus" className="form-label font-14 font-medium black-v2 mb-3">
                                Container Build (Preview) CPUs
                            </label>
                            <input
                                type="text"
                                style={{paddingTop :"10px"}}
                                className=" form-control  custom-form text-field font-14 font-medium"
                                id="cpus"
                                value={values.cpus}
                                name={"cpus"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            />
                        </div>

                        <div className="col-md-6 px-3">
                            <label htmlFor="buildUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                Container Build (Preview) Seconds
                            </label>
                            <input
                                type="text"
                                style={{paddingTop :"10px"}}
                                placeholder="Enter the amount"
                                className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                id="buildUnits"
                                value={values.buildUnits}
                                name={"buildUnits"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            />
                        </div>

                        <div className="col-md-6 px-3">
                            <label htmlFor="dataTransferType" className="form-label font-14 font-medium black-v2 mb-3">
                                Bandwidth - Data Transfer Type
                            </label>
                            <select
                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                id="dataTransferType"
                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                value={values.dataTransferType}
                                name={"dataTransferType"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}>
                                <option value="interregion">Inter Region</option>
                                <option value="internetegress">Internet Egress</option>
                            </select>
                        </div>

                        <div className="col-md-6 px-3">
                            <label htmlFor="region" className="form-label font-14 font-medium black-v2 mb-3">
                                Source Region
                            </label>
                            <select
                                className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                id="region"
                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                value={values.region}
                                name={"region"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}                                                >
                                <option value="us-central">Central US</option>
                                <option value="us-east">East US</option>
                                <option value="us-east-2">East US 2</option>
                                <option value="us-north-central">North Central US</option>
                                <option value="us-south-central">South Central US</option>
                                <option value="us-west-central">West Central US</option>
                                <option value="us-west">West US</option>
                                <option value="us-west-2">West US 2</option>
                                <option value="us-west-3">West US 3</option>
                                <option value="united-kingdom-south">UK South</option>
                                <option value="united-kingdom-west">UK West</option>
                                <option value="uae-central">UAE Central</option>
                                <option value="uae-north">UAE North</option>
                                <option value="switzerland-north">Switzerland North</option>
                                <option value="switzerland-west">Switzerland West</option>
                                <option value="sweden-central">Sweden Central</option>
                                <option value="sweden-south">Sweden South</option>
                                <option value="spain-central">Spain Central</option>
                                <option value="qatar-central">Qatar Central</option>
                                <option value="poland-central">Poland Central</option>
                                <option value="norway-east">Norway East</option>
                                <option value="norway-west">Norway West</option>
                                <option value="mexico-central">Mexico Central</option>
                                <option value="korea-central">Korea Central</option>
                                <option value="korea-south">Korea South</option>
                                <option value="japan-east">Japan East</option>
                                <option value="japan-west">Japan West</option>
                                <option value="italy-north">Italy North</option>
                                <option value="israel-central">Israel Central</option>
                                <option value="central-india">Central India</option>
                                <option value="south-india">South India</option>
                                <option value="west-india">West India</option>
                                <option value="germany-north">Germany North</option>
                                <option value="germany-west-central">Germany West Central</option>
                                <option value="france-central">France Central</option>
                                <option value="france-south">France South</option>
                                <option value="europe-north">North Europe</option>
                                <option value="europe-west">West Europe</option>
                                <option value="canada-central">Canada Central</option>
                                <option value="canada-east">Canada East</option>
                                <option value="brazil-south">Brazil South</option>
                                <option value="brazil-southeast">Brazil Southeast</option>
                                <option value="australia-central">Australia Central</option>
                                <option value="australia-central-2">Australia Central 2</option>
                                <option value="australia-east">Australia East</option>
                                <option value="australia-southeast">Australia Southeast</option>
                                <option value="asia-pacific-east">East Asia</option>
                                <option value="asia-pacific-southeast">Southeast Asia</option>
                                <option value="south-africa-north">South Africa North</option>
                                <option value="south-africa-west">South Africa West</option>
                            </select>
                        </div>

                        {values.dataTransferType == "interregion" && <>
                            <div className="col-md-6 px-3">
                                <label htmlFor="destinationRegion" className="form-label font-14 font-medium black-v2 mb-3">
                                    Destination Region
                                </label>
                                <select
                                    className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                    id="destinationRegion"
                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                    value={destinationRegion}
                                    name={"destinationRegion"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                        handleDestinationRegionChange(e);
                                    }}
                                    disabled={isViewer}>
                                    {azureRegions
                                        .filter(region => region !== sourceRegion)
                                        .map((region, index) => (
                                            <option key={index} value={region}>
                                                {region}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        </>}
                        {values.dataTransferType == "internetegress" && <>
                            <div className="col-md-6 px-3">
                                <label htmlFor="routedVia" className="form-label font-14 font-medium black-v2 mb-3">
                                    Bandwidth - Routed Via
                                </label>
                                <select
                                    className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                    id="routedVia"
                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                    value={values.routedVia}
                                    name={"routedVia"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}>
                                    <option value="microsoftglobalnetwork">Microsoft Global Network</option>
                                    <option value="publicinternet">Public Internet</option>
                                </select>
                            </div>
                        </>}
                        {!["us-west-3", "sweden-south", "spain-central", "poland-central", "mexico-central", "italy-north", "israel-central"].includes(values.region) && (
                            <div>
                                <label htmlFor="interRegionUnits" className="form-label font-14 font-medium black-v2 mb-3">
                                    Outbound Data Transfer - GB
                                </label>
                                <input
                                    type="text"
                                    style={{paddingTop :"10px"}}
                                    className=" form-control  custom-form text-field font-14 font-medium"
                                    id="interRegionUnits"
                                    value={values.interRegionUnits}
                                    name="interRegionUnits"
                                    onChange={(e) => onChangeOfConfiguration(e, values.instanceName)}
                                    disabled={isViewer}
                                />
                            </div>)}
                    </>}
                </div>
            </>
        );
    } catch (error) {
        //console.log(error);
    }
}





