//PS_01
import React, { useState,useContext ,useEffect} from 'react';
//PS_02
import SideBar from '../sidebar/SideBar';
//PS_03
import { useNavigate } from 'react-router-dom';
//PS_04
import Select from 'react-select';
import {createRepoAndBranch,validateScmCredsApi} from '../../Service/api';
//PS_05
import { FormContext } from './Workload_Grid';
import { useLocation } from 'react-router-dom'; // For navigation
import { OrgContext } from '../../ContextProvider/OrgContext';
import PopupComponent from '../Toast/toastPopup';
import WorkbenchPopup from './Workbench/WorkBench/PopupMaintanence/WorkBenchPopUp';
import ToastPopup from '../Toast/toastPopup';

// SCM tools with images
const scmTools = [
    { id: 1, name: 'github', icon: 'images/github-icon.svg' },
    { id: 2, name: 'GitLab', icon: 'images/gitlab.png' },
    { id: 3, name: 'bitbucket', icon: 'images/bitbucket-icon.svg' },
    { id: 4, name: 'azure devops', icon: 'images/azure-devops-icon.svg' },
];

const RepositoryDetails = () => {
    console.log("Rendering RepositoryDetails");
    const location = useLocation();
    const state = location.state;
    const [userId, setuserId] = useState(localStorage.getItem('userId'));

//PS_06
    const [repoName, setRepoName] = useState('');
    const { formData, setFormData } = useContext(FormContext);

    const [techStack, setTechStack] = useState([]); // Initialize as an empty array    const [branchesInput, setBranchesInput] = useState('');
    const [repositories, setRepositories] = useState([]);
 //PS_07   
    const [selectedScmTool, setSelectedScmTool] = useState('');

    const [scmCredentials, setScmCredentials] = useState({
        accessToken: '',
        organization: '',
        workspace: '',
        project: '',
        workspace:'',
        app_password:'',
        pat_token:''
    });
    const {
        userRoles,
        orgId,scm,
        providerId,
        architectures,
        canCreateWorkload,
        canDeleteWorkload,
        canViewWorkload,
        setuserRoles,
        setRepository_id,setrepository_name,
    
    
        // Add organization and provider fields here
        providerName,       
        orgData,           
        setOrgData,        
        setArchitectures,
    
        envId,             // Environment ID
        workloadId,        // Workload ID
        aiArchitecture,    
        viewMode,          
        envName,           
        language,          
        archType,          
        business,          
        rulebook,          
        archDesc,          
        services,
    
        lastBuildID,       
        lastBuildDate,     
        lastBuildMessage,
    
        repoId,            
        
    
        PulseOrganization_id,
        PulseVertical_id,
        PulseVertical_name,
        PulseOrganization_name,
        PulseIdentification,
    
        setEnvId           // Function to update environment ID
    } = useContext(OrgContext);
    console.log("scm",scm);
    const [selectedOptions, setSelectedOptions] = useState([]);
    //PS_08
    const [inputValue, setInputValue] = useState('');
    const [inputValue2, setInputValue2] = useState('');
//PS_09
    const [editIndex, setEditIndex] = useState(null);
    const [editRepoData, setEditRepoData] = useState({ name: '', techStack: [], branches: [] });
    const navigate=useNavigate();
    console.log(formData , "Formdata")
    useEffect(() => {
        try {
            // if (formData?.workloadId) {
                // Set your actual state variables based on formData
                if(formData.repoDetails.length){
                    console.log("yeah");
                setRepositories(formData.repoDetails);
                }
                if (formData.scmToolName || scm) {
                    const scmValue = formData.scmToolName || scm;
                    setSelectedScmTool(options.find(option => option.value === scmValue) || null);
                }  console.log("ffff",scm);
             
                // Update scmCredentials based on selected SCM tool
                let updatedScmCreds = {};
                switch (formData.scmToolName ) {
                    case 'github':
                    case 'GitLab':
                        updatedScmCreds = {
                            accessToken: formData.scmCreds.accessToken ||  formData.scmCreds.access_token ,
                        };
                        break;
                    case 'azure devops':
                        updatedScmCreds = {
                            organization: formData.scmCreds.organization || formData.scmCreds.organization_name,
                            pat_token: formData.scmCreds.pat_token || "",
                            project: formData.scmCreds.project || ""
                        };
                        break;
                    case 'bitbucket':
                        updatedScmCreds = {
                            workspace: formData.scmCreds.workspace || '',
                            username: formData.scmCreds.username || '',
                            app_password: formData.scmCreds.app_password || ''
                        };
                        break;
                    default:
                        updatedScmCreds = {}; // Reset or handle default case
                }
    
                // Set the updated SCM credentials
                setScmCredentials(prevCreds => ({
                    ...prevCreds,
                    ...updatedScmCreds
                }));
            // }
        } catch (error) {
            console.log(error , "errror")
        }
       
    },[location]);
    //PS_10
    const handleSaveEdit = () => {
        const updatedRepos = [...repositories];
        updatedRepos[editIndex] = { ...editRepoData }; // No change needed here if techStack is already an array
        setRepositories(updatedRepos);
        setEditIndex(null);
        setEditRepoData({ name: '', techStack: [], branches: [] }); // Reset after saving
    };

//PS_11 Add a new repository
const handleAddRepository = () => {
    if (selectedOptions.length > 0) {
        const newRepo = {
            name: repoName,
            techStack: techStack, // This should now be an array
            branches: selectedOptions?.map(option => option.value),
        };
        setRepositories((prev) => [...prev, newRepo]);
        
        // Reset input fields after adding
        setRepoName('');
        setTechStack([]); // Reset techStack as an empty array
        setSelectedOptions([]); // Reset selected options
    }
};

    console.log("scm",scmCredentials);

    // PS_12 Delete a repository
    const handleDeleteRepository = (index) => {
        setRepositories((prev) => prev.filter((_, i) => i !== index));
    };

    // PS_13 Edit a repository
    const handleEditRepository = (index) => {
        const repoToEdit = repositories[index];
        setRepoName(repoToEdit.name);
        setTechStack(repoToEdit.techStack);
        // setBranchesInput(repoToEdit.branches.join(', ')); // Join branches for input
        // handleDeleteRepository(index); // Remove member from list for editing
    };
    const handleEditRepo = (index, repo) => {
        setEditIndex(index);
        setEditRepoData(repo);
    };
//PS_14
    const handleChange = (selected) => {
        setSelectedOptions(selected);
    };


const [errorMessage, setErrorMessage] = useState('');

const handleChange2 = (e) => {
    const value = e.target.value;

    // Regular expression to allow only letters (a-z, A-Z)
    const regex = /^[A-Za-z]*$/;

    if (regex.test(value) || value === '') {
        setRepoName(value);
        setErrorMessage(''); // Clear error message if valid
    } else {
        setErrorMessage('Please enter only letters (no numbers, spaces, or special characters).');
    }
};
//PS_15
    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && inputValue.trim() !== '') {
            const newTag = inputValue.trim();
            // Add the new tag to selected options if it doesn't already exist
            if (!selectedOptions.some(option => option.label === newTag)) {
                setSelectedOptions(prev => [...prev, { value: newTag, label: newTag, name: newTag }]);
               
            }
            setInputValue(''); // Clear input field after adding
            
        }
    };
 //PS_16 
    const handleKeyDown2 = (e) => {
        if (e.key === 'Enter' && inputValue2.trim() !== '') {
            const newTag =  inputValue2.trim();
            
            // Add the new tag to editRepoData.branches if it doesn't already exist
            if (!editRepoData.branches.includes(newTag)) {
                setEditRepoData(prev => ({
                    ...prev,
                    branches: [...prev.branches, newTag], // Add new tag to branches
                    branchesInput: '' // Clear input field after adding
                }));
            }
            setInputValue2('');
        }
    };
//PS_17
    const MultiValue = ({ data, removeProps }) => (
        <span className="blue-batch font-12 d-flex gap-1  align-items-center font-medium">
            {data.label}
            <a {...removeProps}>
                <img src="images/tags-close-icon.svg" alt="close-icon" className="icon-custom-filter" />
            </a>
        </span>
    );
    // PS_18 Custom components to limit displayed tags and show count
    const customStyles = {
        control: (base) => ({
        ...base,
        border: '1px solid #ccc',
            boxShadow: 'none',
            borderRadius: '4px',
            }),
        multiValue: (base) => ({
            ...base,
            display: 'flex',
            alignItems: 'center',
            marginRight: '5px',
        }),
        multiValueLabel: (base) => ({
            ...base,
            padding: '0',
        }),
        multiValueRemove: (base) => ({
            ...base,
            display: 'none', // Hide default remove button
        }),
        valueContainer: (base) => ({
            ...base,
            paddingRight: selectedOptions.length > 2 ? '30px' : '10px', // Adjust padding for count display
            position: 'relative',
        }),
    };
    //PS_19
    const customComponents = {
        MultiValue,
    };

   
    const handleCreateRepoAndBranch = async () => {
        let payload;
        console.log("sdfg");
        // const repoNames = repositories.map(repo => repo.name);
        // const branchNames = repositories.flatMap(repo => repo.branches); // Flattening in case of multiple branches
        // const techstack = repositories.map(repo => repo.techStack);
        // Construct payload based on selected SCM tool
        if (selectedScmTool.value == 'azure devops') {
            payload = {
                userId : userId,
                orgId : orgId,
                workloadId:workloadId,
            repoDetails:repositories,
                scmcreds: {
                    organization: scmCredentials.organization,
                    pat_token: scmCredentials.pat_token,
                    project: scmCredentials.project,
                },
                scmTools: selectedScmTool.value,
            };
        } else if (selectedScmTool.value =='bitbucket') {
            payload = {
                userId : userId,
                orgId : orgId,
                workloadId:workloadId,
                repoDetails:repositories,
                scmcreds: {
                    workspace: scmCredentials.workspace,
                    username: scmCredentials.username,
                    app_password: scmCredentials.app_password,
                },
                scmTools: selectedScmTool.value,
            };
        } else if (selectedScmTool.value == 'github') {
            payload = {
                userId : userId,
                orgId : orgId,
                workloadId:workloadId,
                repoDetails:repositories,

            
                scmcreds: {
                    access_token: scmCredentials.accessToken,
                },
                scmTools: selectedScmTool.value,
            };
        } else if (selectedScmTool.value == 'GitLab') {
            payload = {
                userId : userId,
            orgId : orgId,
            workloadId:workloadId,
            repoDetails:repositories,
                scmcreds: {
                    access_token: scmCredentials.accessToken,
                },
                scmTools: selectedScmTool.value,
            };
        } 
        console.log("sggg",payload);
        try {
            let response=await createRepoAndBranch(payload);

       
            // Check the response for success
            if (response.status) {
                setToastMsg({ show: true, message: 'Repository Created successfully!', success: true });
                navigate('/repositoryGrid')
            } else {
            }
        } catch (error) {
            setToastMsg({ show: true, message: 'Repo name already exists', success: false });

            console.error('Failed to create repositories and branches:', error);
        }

        // Clear messages after processing
        setTimeout(() => { 
    
        }, 5000); // Clear messages after 5 seconds
    };
 
    
    const handleValidateScmCreds = async () => {
        let payload;
        if (selectedScmTool.value === 'github' || selectedScmTool.value === 'GitLab') {
            payload = {
                scm_type: selectedScmTool.value,
                access_token: scmCredentials.accessToken,
            };
        } else if (selectedScmTool.value === 'azure devops') {
            payload = {
                scm_type: "azure devops",
                organization_name: scmCredentials.organization, // Replace with actual organization name
                pat_token:scmCredentials.pat_token,
                project:scmCredentials.project  // Replace with actual project name
            };
        } else if (selectedScmTool.value === 'bitbucket') {
            payload = {
                scm_type: "bitbucket",
                workspace:scmCredentials.workspace, // Replace with actual workspace
                username: scmCredentials.username, // Replace with actual username
                app_password: scmCredentials.app_password,// Using access token as app password
            };
        }
        console.log("payyload",payload);

        try {
            const response = await validateScmCredsApi(payload); // Call the API function
            if (response) {
                return response
                
            } else {
                console.error('Error validating SCM credentials');
            }
          
        } catch (error) {
            console.error('An error occurred while validating SCM credentials:', error);
        }
    };
    const [toastMsg, setToastMsg] = useState({ show: false, message: '', success: true });

    const handleSave = () => {
      

        // Determine scmToolName and scmCreds based on selected SCM tool
        let scmToolName = selectedScmTool.value;
        let scmCreds;

        if (scmToolName === 'github' || scmToolName === 'GitLab') {
            scmCreds = {
                scm_type: scmToolName,
                access_token: scmCredentials.accessToken,
            };
        } else if (scmToolName === 'azure devops') {
            scmCreds = {
                scm_type: "azure devops",
                organization: scmCredentials.organization,
                pat_token: scmCredentials.pat_token,
                project: scmCredentials.project
            };
        } else if (scmToolName === 'bitbucket') {
            scmCreds = {
                scm_type: "bitbucket",
                workspace: scmCredentials.workspace,
                username: scmCredentials.username,
                app_password: scmCredentials.app_password,
            };
        }
console.log("sadsfsg");
        // Update formData with new values
        setFormData(prevFormData => ({
            ...prevFormData,
            repoDetails:repositories,
            scmToolName, // Set the selected SCM tool name
            scmCreds // Set the SCM credentials
        }));
        console.log("repoDetails",repositories);
        navigate('/memberDetails')

    };
    const [popupMsg, setPopUpMsg] = useState({"Msg":"",show:false,success:false})

    const handleClick = async () => {
        if (isNextButtonEnabled) {
        try {
            const validationResponse = await handleValidateScmCreds(); // Call the validation function
                
            if (validationResponse.body.success)  {
                setToastMsg({ show: true, message: 'SCM credentials are valid!', success: true });
                // Proceed based on the mode
                if (state?.mode == "add") {
                    console.log("in create");
                    await handleCreateRepoAndBranch(); // Call create function
                } else {
                     handleSave(); // Call save function
                }

              
            } else {
                setToastMsg({ show: true, message: 'SCM credentials are invalid', success: false });

                console.error('SCM Credentials validation failed:', validationResponse.message);
                // Handle validation failure (e.g., show an error message)
            }
        } catch (error) {
            setToastMsg({ show: true, message: 'SCM credentials are invalid', success: false });
            console.error('An error occurred during validation:', error);
            // Handle any errors that occurred during the validation process
        }}
    }; 

    const options = scmTools.map(tool => ({
        value: tool.name,
        label: (
            <div style={{ display: 'flex', alignItems: 'center' }} className='font-14 font-medium black-v2'>
                <img className='font-14 font-medium black-v2' src={tool.icon} alt={tool.name} style={{ width: 20, height: 20, marginRight: 10 }} />
                {tool.name.charAt(0).toUpperCase() + tool.name.slice(1)} {/* Capitalize first letter */}
            </div>
        ),
    }));
    
console.log("selected",selectedScmTool);
const [isNextButtonEnabled, setIsNextButtonEnabled] = useState(false);

useEffect(() => {
    validateNextButton();
}, [selectedScmTool, scmCredentials]);

const validateNextButton = () => {
    let isValid = selectedScmTool !== null;

    if (selectedScmTool) {
        switch (selectedScmTool.value) {
            case 'github':
            case 'GitLab':
                isValid = isValid && scmCredentials?.accessToken?.trim() !== '';
                break;
            case 'azure devops':
                isValid = isValid && scmCredentials?.organization?.trim() !== '' && scmCredentials?.pat_token?.trim() !== '' && scmCredentials?.project?.trim() !== '';
                break;
            case 'bitbucket':
                isValid = isValid && scmCredentials?.workspace?.trim() !== '' && scmCredentials?.username?.trim() !== '' && scmCredentials?.app_password?.trim() !== '';
                break;
            default:
                isValid = false; // Handle other cases if necessary
        }
    }

    setIsNextButtonEnabled(isValid);
};

    return (
        <>
      {toastMsg.show && (
            <ToastPopup 
                toastMsg={toastMsg}
                setToastMsg={setToastMsg}
            />
        )}
        <div className="container-fluid">
        <div className="">

            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                    <SideBar/>
        <div className="col-md-9 px-4 right-container-scroll1">
            
      
            <div className="tab-content" id="pills-tabContent">
           
                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
              
                    <div className="row px-3">
                   
                        <div className="col-md-12">
             
                        <h1 className="font-32 font-bold black-v2 mb-4 pb-3">
    {formData.workloadId ? "Edit Workload" : state?.mode === "add" ? "Create Repository" : "Create Workload"}
</h1>
            <div className="row justify-content-center">
                                <div className="col-md-12 col-lg-8 mb-4">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex align-items-center flex-column gap-3">
                                            <div className="step-num completed d-flex justify-content-center align-items-center position-relative">
                                                <p className="font-medium font-16 m-0">1</p>
                                            </div>
                                            <span className="font-14 font-medium step-text">Workload Details</span>
                                        </div>

                                        <div className="d-flex align-items-center flex-column gap-3">
                                            <div className="step-num completed d-flex justify-content-center align-items-center position-relative step-line-1 step-completed">
                                                <p className="font-medium font-16 m-0">2</p>
                                            </div>
                                            <span className="font-14 font-medium step-text">Environments</span>
                                        </div>

                                        <div className="d-flex align-items-center flex-column gap-3">
                                            <div className="step-num active d-flex justify-content-center align-items-center position-relative step-line-2 step-completed">
                                                <p className="font-medium font-16 m-0">3</p>
                                            </div>
                                            <span className="font-14 font-medium step-text active">Repository & CI/CD</span>
                                        </div>

                                        <div className="d-flex align-items-center flex-column gap-3">
                                            <div className="step-num completed d-flex justify-content-center align-items-center position-relative step-line-3 step-completed">
                                                <p className="font-medium font-16 m-0">4</p>
                                            </div>
                                            <span className="font-14 font-medium step-text ">Members</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
        <div className="col-md-12">
            <p className="font-20 font-bold black-v2 mb-3">Repository</p>
            <div className="row">
                <div className="col-md-12 mb-4 pb-2">
                    {/* Inline Form for Adding Repository */}
                    <div className="table-responsive table-border">
                        <table className="table custom-table">
                            <thead>
                                <tr>
                                    <th className="text-start border-0">Repository</th>
                                    <th className="text-start border-0">Tech Stack</th>
                                    <th className="text-start border-0">Branches</th>
                                    <th className="text-center border-0">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* Input Fields Row */}
                             {formData?.workloadId==""&&    <tr>
                                    <td className="text-start align-middle">
                                    <input
                type="text"
                id="repository-name"
                className="form-control custom-form font-14 font-medium shadow-none"
                placeholder="Enter Repository Name"
                value={repoName}
                onChange={handleChange2}
            />
            {errorMessage && <div className="invalid-feedback">{errorMessage}</div>}
                                    </td>
                                    <td className="text-start align-middle">
    <input
        type="text"
        id="tech-stack"
        className="form-control custom-form font-14 font-medium shadow-none"
        placeholder="Enter Tech Stack (comma separated)"
        value={techStack.join(', ')} // Join array for display in input
        onChange={(e) => {
            const values = e.target.value.split(',')?.map(item => item.trim()); // Split and trim values
            setTechStack(values); // Set techStack as an array
        }}
    />
</td>
                                    <td className="text-start align-middle">
                                    <Select
                    isMulti
                    className='custom-select-form cust-select-width padding-left'

                    // styles={{ /* Custom styles if needed */ }}
                    // options={options}
                    onChange={handleChange}
                    styles={customStyles}
                    value={selectedOptions} // Set selected options here
                    placeholder="Type and press Enter to add"
                    noOptionsMessage={() => null}
                    onInputChange={(input) => setInputValue(input)} // Update input state
                    inputValue={inputValue} // Control the input value for react-select
                    onKeyDown={handleKeyDown} // Handle key down events for adding tags
                    components={customComponents}
                    />
                    {/* Display count if more than two selections */}
                 
        </td>
                                    <td className="text-center align-middle">
                                        <button type="button" 
                                                className={`btn action-btn d-flex align-items-center justify-content-center border-0 ${!(repoName && techStack && selectedOptions.length !== 0 ) ? 'disabled' : ''}`}
                                                onClick={handleAddRepository}
                                                disabled={!(repoName && techStack && selectedOptions.length !== 0 )}>
                                            <img src="images/add-plus-icon.svg" alt="add-action-icon" />
                                        </button>
                                    </td>
                                </tr>}

                                {/* Display Repositories */}
                                {repositories?.map((repo, index) => (
                    <tr key={index}>
                        {editIndex === index ? (
                            <>
                                <td  className="text-start align-middle">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={editRepoData.name}
                                        onChange={(e) => setEditRepoData({ ...editRepoData, name: e.target.value })}
                                        readOnly={!!formData.workloadId}
                                    />
                                </td>
                                <td  className="text-start align-middle" >
                             
    <input
        type="text"
        className="form-control"
        value={editRepoData.techStack.join(', ')} // Display with ", "
        onChange={(e) => {
            const values = e.target.value.split(',')?.map(item => item.trim()); // Split and trim
            setEditRepoData({ ...editRepoData, techStack: values }); // Update techStack as an array

        }}
    />

</td>
                                <td  className="text-start align-middle">
                                     <Select
                                                   className='custom-select-form margin-width '

 isMulti
 styles={customStyles}
 value={editRepoData?.branches?.map(branch => ({ value: branch, label: branch }))}
 onChange={(selected) => {
setEditRepoData({ ...editRepoData, branches: selected?.map(option => option.value) });
setInputValue2('');
 }}
 noOptionsMessage={() => null}
 onInputChange={(input) => setInputValue2(input)}
 inputValue={inputValue2}
onKeyDown={handleKeyDown2}
 components={customComponents}
 placeholder="Select branches.."
 isDisabled={!!formData.workloadId}
 />
                                </td>
                                
                                <td className="text-center">
    {/* Save and Cancel Buttons for Editing */}
    <a href="#" onClick={() => handleSaveEdit()}>
        <img src="/images/tick-large-icon.svg" alt="tick-icon" width={16} height={16} style={{ marginRight: '8px' }} />
    </a>

    {/* Cancel Action */}
    <a href="#" onClick={() => setEditIndex(null)}>
        <img src="/images/cross-large-icon.svg" alt="cross-icon" width={10} height={10} />
    </a>
</td>
                            </>
                        ) : (
                            <>
                                 <td className="text-start align-middle">{repo.name}</td>
            <td className="text-start align-middle">{repo.techStack.join(', ')}</td>
            <td className="text-start align-middle">
                <div className="text-start align-middle">
                    {repo?.branches?.map((branch, branchIndex) => (
                        <span key={branchIndex} className="blue-batch font-11 font-medium">
                            {branch}
                        </span>
                    ))}
                </div>
            </td>
            <td className="text-center align-middle">
    <div>
        {/* Edit Action */}
        <span className="icon-container">
            <a href="#" onClick={() => handleEditRepo(index, repo)}>
                <img src="/images/edit-icon.svg" alt="edit-icon" />
            </a>
        </span>
        {/* Delete Action */}
        <span className="icon-container">
            <a href="#" onClick={() => handleDeleteRepository(index)}>
                <img src="/images/delete-icon.svg" alt="delete-icon" />
            </a>
        </span>
    </div>
</td>



                             
                            </>
                        )}</tr>
                    ))}
                            </tbody>
                        </table>
                    </div>

                    {/* Display number of records */}
                    {repositories.length > 0 && (
                        <p className='font-14 font-medium secondary-text-color mb-0 mt-3'>
                            # of Records:
                            <span className='font16 font-medium color-black'>
                                {repositories.length} out of {repositories.length}
                            </span>
                        </p>
                    )}
                </div>

                {/* SCM Credentials Section */}
                <div className="col-md-4 mb-4 pb-3">
    <p className="font-20 font-bold black-v2 mb-3">SCM Credentials</p>

    {/* SCM Tool Dropdown with Images */}
    <label htmlFor="scm-tool" className="form-label font-14 font-medium black-v2">SCM Tool</label>
    <Select

            id="scm-tool"
            className="form-select form-control custom-form font-medium font-14 custom-select-form p-0 border-0 font-14 font-medium black-v2"
            value={selectedScmTool}
            onChange={setSelectedScmTool}
            options={options}
            isDisabled={!!formData.workloadId} // Disable dropdown if workloadId exists
            placeholder="Choose SCM Tool"
        />

{selectedScmTool && selectedScmTool.value == 'github' && (
        <div className="w-50 ps-2"> 
            <label htmlFor="access-token" className="form-label font-14 font-medium black-v2" style={{paddingTop:'15%'}} >Access Token</label>
            <input 
                type="text" 
                id="access-token" 
                className="form-control custom-form font-14 font-medium shadow-none mb-3 custom-input-size black-v2" 
                placeholder="Enter Access Token" 
                value={scmCredentials.accessToken} 
                onChange={(e) => setScmCredentials({ ...scmCredentials, accessToken: e.target.value })} 
                style={{width:'231%'}}

            />
        </div>
    )}
    
    {selectedScmTool && selectedScmTool.value === 'GitLab' && (
        <div className="w-50 ps-2"> 
            <label htmlFor="access-token" className="form-label font-14 font-medium black-v2" style={{paddingTop:'15%'}} >Access Token</label>
            <input 
                type="text" 
                id="access-token" 
                className="form-control custom-form font-14 font-medium shadow-none mb-3 custom-input-size" 
                placeholder="Enter Access Token" 
                value={scmCredentials.accessToken} 
                onChange={(e) => setScmCredentials({ ...scmCredentials, accessToken: e.target.value })} 
                // readOnly={!!formData.workloadId} // Make read-only if workloadId exists
                style={{width:'231%'}}

            />
        </div>
    )}

{selectedScmTool && selectedScmTool.value === 'azure devops' && (
        <>
            <div className="form-group custom-form-size">
                <div className="d-flex justify-content-between mb-3">
                    <div className="w-50 pe-2">
                        <label htmlFor="organization" className="form-label font-14 font-medium black-v2" style={{paddingTop:'35%'}}>Organization</label>
                        <input 
                            type="text" 
                            id="organization" 
                            className="form-control custom-form font-14 font-medium shadow-none custom-input-size" 
                            placeholder="Enter Organization" 
                            value={scmCredentials.organization} 
                            onChange={(e) => setScmCredentials({ ...scmCredentials, organization: e.target.value })} 
                            // readOnly={!!formData.workloadId} // Make read-only if workloadId exists
                            style={{width:'231%'}}
                        />
                    </div>
                    <div className="w-50 ps-2">
                        <label htmlFor="pat-token" className="form-label font-14 font-medium black-v2" style={{marginLeft:'212%',whiteSpace:'nowrap',marginTop:'32%'}}>PAT Token</label>
                        <input 
                            type="text" 
                            id="pat-token" 
                            className="form-control custom-form font-14 font-medium shadow-none custom-input-size" 
                            placeholder="Enter PAT Token" 
                            value={scmCredentials.pat_token} 
                            onChange={(e) => setScmCredentials({ ...scmCredentials, pat_token: e.target.value })} 
                            // readOnly={!!formData.workloadId} // Make read-only if workloadId exists
                            style={{marginLeft:'212%',width:'220%'}}
                        />
                    </div>
                </div>
                <div className="w-50 ps-2"> 
                    <label htmlFor="project" className="form-label font-14 font-medium black-v2">Project</label>
                    <input 
                        type="text" 
                        id="project" 
                        className="form-control custom-form font-14 font-medium shadow-none mb-3 custom-input-size" 
                        placeholder="Enter Project" 
                        value={scmCredentials.project} 
                        onChange={(e) => setScmCredentials({ ...scmCredentials, project: e.target.value })} 
                        // readOnly={!!formData.workloadId} // Make read-only if workloadId exists
                        style={{width:'200%'}}
                    />
                </div>
            </div>
        </>
    )}

{selectedScmTool && selectedScmTool.value === 'bitbucket' && (
        <>
            <div className="form-group custom-form-size">
                <div className="d-flex justify-content-between mb-3">
                    <div className="w-50 pe-2">
                        <label htmlFor="workspace" className="form-label font-14 font-medium black-v2" style={{paddingTop:'35%'}}>Workspace</label>
                        <input 
                            type="text" 
                            id="workspace" 
                            className="form-control custom-form font-14 font-medium shadow-none custom-input-size" 
                            placeholder="Enter Workspace" 
                            value={scmCredentials.workspace} 
                            onChange={(e) => setScmCredentials({ ...scmCredentials, workspace: e.target.value })} 
                            // readOnly={!!formData.workloadId} // Make read-only if workloadId exists
                            style={{width:'231%'}}

                        />
                    </div>
                    <div className="w-50 ps-2">
                        <label htmlFor="username" className="form-label font-14 font-medium black-v2" style={{marginLeft:'212%',whiteSpace:'nowrap',marginTop:'32%'}}>Username</label>
                        <input 
                            type="text" 
                            id="username" 
                            className="form-control custom-form font-14 font-medium shadow-none custom-input-size" 
                            placeholder="Enter Username" 
                            value={scmCredentials.username} 
                            onChange={(e) => setScmCredentials({ ...scmCredentials, username: e.target.value })} 
                            // readOnly={!!formData.workloadId} // Make read-only if workloadId exists
                            style={{marginLeft:'212%',width:'220%'}}

                        />
                    </div>
                </div>
                <div className="w-50 ps-2"> 
                    <label htmlFor="app-password" className="form-label font-14 font-medium black-v2">App Password</label>
                    <input 
                        type="password" // Use password input for security
                        id="app-password" 
                        className="form-control custom-form font-14 font-medium shadow-none mb-3 custom-input-size" 
                        placeholder="Enter App Password" 
                        value={scmCredentials.app_password} 
                        onChange={(e) => setScmCredentials({ ...scmCredentials, app_password: e.target.value })} 
                        // readOnly={!!formData.workloadId} // Make read-only if workloadId exists
                        style={{width:'200%'}}

                    />
                </div>
            </div>
        </>
    )}
  
                    
    {/* Add other SCM tools similarly... */}

</div>

                {/* Footer Buttons */}
                <div className='d-flex justify-content-between align-items-center my-4 gap-3'>
                    {/* Cancel Button */}
                    <button type='button' onClick={()=>{state?.mode === "add" ? navigate('/repositoryGrid'):navigate('/environmentDetails')}} 
                        className='button cancel-button px-4 py-3 lh-1'>Cancel</button>

                    {/* Next Button */}
                    {repositories.length > 0 && selectedScmTool&& (
                        <button type='button' 
                            
                        className={`btn button primary-button px-4 py-3 lh-1 ${!isNextButtonEnabled ? 'disabled' : ''}`}
                        disabled={!isNextButtonEnabled}                            // onClick={()=>{ navigate('/memberDetails')}}
                            // onClick={()=>{handleCreateRepoAndBranch()}}
                            onClick={()=>{handleClick()}}

                            >  {state?.mode === "add" ? "Create Repository" : "Next"}</button> 
                    )}
                </div>

            </div> 
        </div> 
        </div> 
        </div> 
        </div> 
        </div> 
        </div> 
        </div> 
        </div> 
        </div> 
        </div> 
        </div> 
        </div>
        </> 
    );
};

export default RepositoryDetails; // Ensure this line exports the component correctly