import { Editor } from "@monaco-editor/react";
import { useContext } from "react";
import { AddServiceContext } from "./context/AddServiceContext";

export default function LanguageCodeEditor(){
   const {
    langKb,
    selfServiceData,
    setSelfServiceData
   } = useContext(AddServiceContext)


   {
    console.log(selfServiceData,"THis si stshashs")
   }



   function editorChange(code) {
    try {
      if (langKb === "terraform") {
        setSelfServiceData((prevData) => ({
          ...prevData,
          TerraformCode: code, // Update TerraformCode
        }));
      } else if (langKb === "cloudFormation") {
        setSelfServiceData((prevData) => ({
          ...prevData,
          CloudFormationCode: code, // Update CloudFormationCode
        }));
      } else if (langKb === "arm") {
        setSelfServiceData((prevData) => ({
          ...prevData,
          armcode: code, // Update armcode
        }));
      }
    } catch (error) {
      console.log(error.message);
    }
  }

    return (
        <div className="tab-content" id="pills-tabContent">
          {
            (langKb == "terraform") ? <div
            className="tab-pane fade show active"
            id="terraform"
            role="tabpanel"
            aria-labelledby="terraform"

        >
            <Editor
                language={"hcl"}
                theme="vs-dark"
                height="25vh"
                value={selfServiceData?.TerraformCode}
                onChange={(code)=>{editorChange(code)}}
                options={{
                    fontSize: 14,
                    minimap: {
                        enabled: false,
                    },
                }}
            />
        </div> : (langKb == "cloudFormation") ? <div
            className="tab-pane fade show active"
            id="cloudformation"
            role="tabpanel"
            aria-labelledby="terraform"

        >
            <Editor
                language={"text"}
                theme="vs-dark"
                height="25vh"
                value={selfServiceData?.CloudFormationCode}
                onChange={(code)=>{editorChange(code)}}
                options={{
                    fontSize: 14,
                    minimap: {
                        enabled: false,
                    },
                }}
            />
        </div> : <div
            className="tab-pane fade show active"
            id="arm"
            role="tabpanel"
            aria-labelledby="terraform"

        >
            <Editor
                language={"text"}
                theme="vs-dark"
                height="25vh"
                value={selfServiceData?.armcode}
                onChange={(code)=>{editorChange(code)}}
                options={{
                    fontSize: 14,
                    minimap: {
                        enabled: false,
                    },
                }}
            />
        </div>
          }
                    
                  </div>
    )
}