
export default function AWS_SDK_RDS({ values, onChangeOfConfiguration, isViewer }) {
    try {
        return (
                <div
                    class="accordion-body p-0 estimation-container">
                    <div className="row py-4 mx-4 px-5">
                        <div className="col-md-6 px-3">
                            <label htmlFor="region" className="form-label font-14 font-medium black-v2 mb-3">
                                Region
                            </label>
                            <select
                                className="form-select custom-form font-semibold font-14 form-select-sm"
                                id="region"
                                value={values.region}
                                name={"region"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            >
                                <option value="ap-south-2">ap-south-2</option><option value="ap-southeast-3">ap-southeast-3</option><option value="ap-southeast-1">ap-southeast-1</option><option value="us-gov-east-1">us-gov-east-1</option><option value="eu-west-2">eu-west-2</option><option value="af-south-1">af-south-1</option><option value="ap-northeast-1">ap-northeast-1</option><option value="ap-southeast-2">ap-southeast-2</option><option value="us-east-2">us-east-2</option><option value="eu-central-1">eu-central-1</option><option value="ca-central-1">ca-central-1</option><option value="ap-south-1">ap-south-1</option><option value="ap-northeast-3">ap-northeast-3</option><option value="sa-east-1">sa-east-1</option><option value="ap-east-1">ap-east-1</option><option value="eu-west-1">eu-west-1</option><option value="eu-south-1">eu-south-1</option><option value="ap-southeast-4">ap-southeast-4</option><option value="eu-north-1">eu-north-1</option><option value="eu-south-2">eu-south-2</option><option value="us-west-1">us-west-1</option><option value="us-west-2">us-west-2</option><option value="me-south-1">me-south-1</option><option value="eu-central-2">eu-central-2</option><option value="us-gov-west-1">us-gov-west-1</option><option value="ap-northeast-2">ap-northeast-2</option><option value="me-central-1">me-central-1</option><option value="us-east-1">us-east-1</option><option value="eu-west-3">eu-west-3</option>
                            </select>
                        </div>
                        <div className="col-md-6 px-3">
                            <label htmlFor="EFSstorageType" className="form-label font-14 font-medium black-v2 mb-3">
                                Storage Type
                            </label>
                            <select
                                className="form-select custom-form font-semibold font-14 form-select-sm"
                                id="EFSstorageType"
                                value={values.EFSstorageType}
                                name={"EFSstorageType"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            >
                                <option value="1">Standard Storage</option>
                                <option value="2">One Zone Storage</option>
                            </select>
                        </div>
                        <div className="col-md-6 px-3">
                            <label
                                htmlFor="noOfNodes"
                                className="form-label font-14 font-medium black-v2 mb-3"
                            >
                                Nodes
                            </label>
                            <input
                                type="text"
                                className="form-control custom-form text-field font-14 font-medium"
                                id="noOfNodes"
                                value={values.noOfNodes}
                                name={"noOfNodes"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            />
                        </div>

                        <div className="col-md-6 px-3">
                            <label
                                htmlFor="utilization"
                                className="form-label font-14 font-medium black-v2 mb-3"
                            >
                                Utilization
                            </label>
                            <input
                                type="text"
                                className="form-control custom-form text-field font-14 font-medium"
                                id="utilization"
                                value={values.utilization}
                                name={"utilization"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            />
                        </div>
                        <div className="col-md-6 px-3">
                            <label htmlFor="utilizationUnit" className="form-label font-14 font-medium black-v2 mb-3">
                                Unit
                            </label>
                            <select
                                className="form-select custom-form font-semibold font-14 form-select-sm"
                                id="utilizationUnit"
                                value={values.utilizationUnit}
                                name={"utilizationUnit"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            >
                                <option value="1">Hours/Day</option>
                                <option value="2">Hours/Week</option>
                                <option value="3">Hours/Month</option>
                                <option value="4">%Utilized/Month</option>
                            </select>
                        </div>
                        <div className="col-md-6 px-3">
                            <label htmlFor="deploymentOption" className="form-label font-14 font-medium black-v2 mb-3">
                                Deployment Option
                            </label>
                            <select
                                className="form-select custom-form font-semibold font-14 form-select-sm"
                                id="deploymentOption"
                                value={values.deploymentOption}
                                name={"deploymentOption"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            >
                                <option value="Multi-AZ">Multi-AZ</option>
                                <option value="Single-AZ">Single-AZ</option>
                            </select>
                        </div>
                        {values.ConfigureValue.EngineType === "Microsoft SQL Server" && <div className="col-md-6 px-3">
                            <label htmlFor="instanceType" className="form-label font-14 font-medium black-v2 mb-3">
                                Instance Type
                            </label>
                            <select
                                className="form-select custom-form font-semibold font-14 form-select-sm"
                                id="instanceType"
                                value={values.instanceType}
                                name={"instanceType"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            >
                                <option value="db.m4.10xlarge">db.m4.10xlarge</option>
                                <option value="db.m4.16xlarge">db.m4.16xlarge</option>
                                <option value="db.m4.2xlarge">db.m4.2xlarge</option>
                                <option value="db.m4.4xlarge">db.m4.4xlarge</option>
                                <option value="db.m4.large">db.m4.large</option>
                                <option value="db.m4.xlarge">db.m4.xlarge</option>
                                <option value="db.m5.12xlarge">db.m5.12xlarge</option>
                                <option value="db.m5.16xlarge">db.m5.16xlarge</option>
                                <option value="db.m5.24xlarge">db.m5.24xlarge</option>
                                <option value="db.m5.2xlarge">db.m5.2xlarge</option>
                                <option value="db.m5.4xlarge">db.m5.4xlarge</option>
                                <option value="db.m5.8xlarge">db.m5.8xlarge</option>
                                <option value="db.m5.large">db.m5.large</option>
                                <option value="db.m5.xlarge">db.m5.xlarge</option>
                                <option value="db.m5d.12xlarge">db.m5d.12xlarge</option>
                                <option value="db.m5d.16xlarge">db.m5d.16xlarge</option>
                                <option value="db.m5d.24xlarge">db.m5d.24xlarge</option>
                                <option value="db.m5d.2xlarge">db.m5d.2xlarge</option>
                                <option value="db.m5d.4xlarge">db.m5d.4xlarge</option>
                                <option value="db.m5d.8xlarge">db.m5d.8xlarge</option>
                                <option value="db.m5d.large">db.m5d.large</option>
                                <option value="db.m5d.xlarge">db.m5d.xlarge</option>
                                <option value="db.m6i.12xlarge">db.m6i.12xlarge</option>
                                <option value="db.m6i.16xlarge">db.m6i.16xlarge</option>
                                <option value="db.m6i.24xlarge">db.m6i.24xlarge</option>
                                <option value="db.m6i.2xlarge">db.m6i.2xlarge</option>
                                <option value="db.m6i.32xlarge">db.m6i.32xlarge</option>
                                <option value="db.m6i.4xlarge">db.m6i.4xlarge</option>
                                <option value="db.m6i.8xlarge">db.m6i.8xlarge</option>
                                <option value="db.m6i.large">db.m6i.large</option>
                                <option value="db.m6i.xlarge">db.m6i.xlarge</option>
                                <option value="db.r3.large">db.r3.large</option>
                                <option value="db.r4.16xlarge">db.r4.16xlarge</option>
                                <option value="db.r4.2xlarge">db.r4.2xlarge</option>
                                <option value="db.r4.4xlarge">db.r4.4xlarge</option>
                                <option value="db.r4.8xlarge">db.r4.8xlarge</option>
                                <option value="db.r4.large">db.r4.large</option>
                                <option value="db.r4.xlarge">db.r4.xlarge</option>
                                <option value="db.r5.12xlarge">db.r5.12xlarge</option>
                                <option value="db.r5.16xlarge">db.r5.16xlarge</option>
                                <option value="db.r5.24xlarge">db.r5.24xlarge</option>
                                <option value="db.r5.2xlarge">db.r5.2xlarge</option>
                                <option value="db.r5.4xlarge">db.r5.4xlarge</option>
                                <option value="db.r5.8xlarge">db.r5.8xlarge</option>
                                <option value="db.r5.large">db.r5.large</option>
                                <option value="db.r5.xlarge">db.r5.xlarge</option>
                                <option value="db.r5b.12xlarge">db.r5b.12xlarge</option>
                                <option value="db.r5b.16xlarge">db.r5b.16xlarge</option>
                                <option value="db.r5b.24xlarge">db.r5b.24xlarge</option>
                                <option value="db.m5d.2xlarge">db.m5d.2xlarge</option>
                                <option value="db.m5d.4xlarge">db.m5d.4xlarge</option>
                                <option value="db.m5d.8xlarge">db.m5d.8xlarge</option>
                                <option value="db.m5d.large">db.m5d.large</option>
                                <option value="db.m5d.xlarge">db.m5d.xlarge</option>
                                <option value="db.m6i.12xlarge">db.m6i.12xlarge</option>
                                <option value="db.m6i.16xlarge">db.m6i.16xlarge</option>
                                <option value="db.m6i.24xlarge">db.m6i.24xlarge</option>
                                <option value="db.m6i.2xlarge">db.m6i.2xlarge</option>
                                <option value="db.m6i.32xlarge">db.m6i.32xlarge</option>
                                <option value="db.m6i.4xlarge">db.m6i.4xlarge</option>
                                <option value="db.m6i.8xlarge">db.m6i.8xlarge</option>
                                <option value="db.m6i.large">db.m6i.large</option>
                                <option value="db.m6i.xlarge">db.m6i.xlarge</option>
                                <option value="db.r3.large">db.r3.large</option>
                                <option value="db.r4.16xlarge">db.r4.16xlarge</option>
                                <option value="db.r4.2xlarge">db.r4.2xlarge</option>
                                <option value="db.r4.4xlarge">db.r4.4xlarge</option>
                                <option value="db.r4.8xlarge">db.r4.8xlarge</option>
                                <option value="db.r4.large">db.r4.large</option>
                                <option value="db.r4.xlarge">db.r4.xlarge</option>
                                <option value="db.r5.12xlarge">db.r5.12xlarge</option>
                                <option value="db.r5.16xlarge">db.r5.16xlarge</option>
                                <option value="db.r5.24xlarge">db.r5.24xlarge</option>
                                <option value="db.r5.2xlarge">db.r5.2xlarge</option>
                                <option value="db.r5.4xlarge">db.r5.4xlarge</option>
                                <option value="db.r5.8xlarge">db.r5.8xlarge</option>
                                <option value="db.r5.large">db.r5.large</option>
                                <option value="db.r5.xlarge">db.r5.xlarge</option>
                                <option value="db.r5b.12xlarge">db.r5b.12xlarge</option>
                                <option value="db.r5b.16xlarge">db.r5b.16xlarge</option>
                                <option value="db.r5b.24xlarge">db.r5b.24xlarge</option>
                            </select>
                        </div>}
                        {values.ConfigureValue.EngineType === "MySQL" && <div className="col-md-6 px-3">
                            <label htmlFor="instanceType" className="form-label font-14 font-medium black-v2 mb-3">
                                Instance Type
                            </label>
                            <select
                                className="form-select custom-form font-semibold font-14 form-select-sm"
                                id="instanceType"
                                value={values.instanceType}
                                name={"instanceType"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            >
                                <option value="db.m1.large">db.m1.large</option>
                                <option value="db.m1.medium">db.m1.medium</option>
                                <option value="db.m1.small">db.m1.small</option>
                                <option value="db.m1.xlarge">db.m1.xlarge</option>
                                <option value="db.m2.2xlarge">db.m2.2xlarge</option>
                                <option value="db.m2.4xlarge">db.m2.4xlarge</option>
                                <option value="db.m2.xlarge">db.m2.xlarge</option>
                                <option value="db.m3.2xlarge">db.m3.2xlarge</option>
                                <option value="db.m3.large">db.m3.large</option>
                                <option value="db.m3.medium">db.m3.medium</option>
                                <option value="db.m3.xlarge">db.m3.xlarge</option>
                                <option value="db.m4.10xlarge">db.m4.10xlarge</option>
                                <option value="db.m4.16xlarge">db.m4.16xlarge</option>
                                <option value="db.m4.2xlarge">db.m4.2xlarge</option>
                                <option value="db.m4.4xlarge">db.m4.4xlarge</option>
                                <option value="db.m4.large">db.m4.large</option>
                                <option value="db.m4.xlarge">db.m4.xlarge</option>
                                <option value="db.m5.12xlarge">db.m5.12xlarge</option>
                                <option value="db.m5.16xlarge">db.m5.16xlarge</option>
                                <option value="db.m5.24xlarge">db.m5.24xlarge</option>
                                <option value="db.m5.2xlarge">db.m5.2xlarge</option>
                                <option value="db.m5.4xlarge">db.m5.4xlarge</option>
                                <option value="db.m5.8xlarge">db.m5.8xlarge</option>
                                <option value="db.m5.large">db.m5.large</option>
                                <option value="db.m5.xlarge">db.m5.xlarge</option>
                                <option value="db.m5d.12xlarge">db.m5d.12xlarge</option>
                                <option value="db.m5d.16xlarge">db.m5d.16xlarge</option>
                                <option value="db.m5d.24xlarge">db.m5d.24xlarge</option>
                                <option value="db.m5d.2xlarge">db.m5d.2xlarge</option>
                                <option value="db.m5d.4xlarge">db.m5d.4xlarge</option>
                                <option value="db.m5d.8xlarge">db.m5d.8xlarge</option>
                                <option value="db.m5d.large">db.m5d.large</option>
                                <option value="db.m5d.xlarge">db.m5d.xlarge</option>
                                <option value="db.m6g.12xlarge">db.m6g.12xlarge</option>
                                <option value="db.m6g.16xlarge">db.m6g.16xlarge</option>
                                <option value="db.m6g.2xlarge">db.m6g.2xlarge</option>
                                <option value="db.m6g.4xlarge">db.m6g.4xlarge</option>
                                <option value="db.m6g.8xlarge">db.m6g.8xlarge</option>
                                <option value="db.m6g.large">db.m6g.large</option>
                                <option value="db.m6g.xlarge">db.m6g.xlarge</option>
                                <option value="db.m6gd.12xlarge">db.m6gd.12xlarge</option>
                                <option value="db.m6gd.16xlarge">db.m6gd.16xlarge</option>
                                <option value="db.m6gd.2xlarge">db.m6gd.2xlarge</option>
                                <option value="db.m6gd.4xlarge">db.m6gd.4xlarge</option>
                                <option value="db.m6gd.8xlarge">db.m6gd.8xlarge</option>
                                <option value="db.m6gd.large">db.m6gd.large</option>
                                <option value="db.m6gd.xlarge">db.m6gd.xlarge</option>
                                <option value="db.m6i.12xlarge">db.m6i.12xlarge</option>
                                <option value="db.m6i.16xlarge">db.m6i.16xlarge</option>
                                <option value="db.m6i.24xlarge">db.m6i.24xlarge</option>
                                <option value="db.m6i.2xlarge">db.m6i.2xlarge</option>
                                <option value="db.m6i.32xlarge">db.m6i.32xlarge</option>
                                <option value="db.m6i.4xlarge">db.m6i.4xlarge</option>
                                <option value="db.m6i.8xlarge">db.m6i.8xlarge</option>
                                <option value="db.m6i.large">db.m6i.large</option>
                                <option value="db.m6i.xlarge">db.m6i.xlarge</option>
                                <option value="db.m7g.12xlarge">db.m7g.12xlarge</option>
                                <option value="db.m7g.16xlarge">db.m7g.16xlarge</option>
                                <option value="db.m7g.2xlarge">db.m7g.2xlarge</option>
                                <option value="db.m7g.4xlarge">db.m7g.4xlarge</option>
                                <option value="db.m7g.8xlarge">db.m7g.8xlarge</option>
                                <option value="db.m7g.large">db.m7g.large</option>
                                <option value="db.m7g.xlarge">db.m7g.xlarge</option>
                                <option value="db.r3.2xlarge">db.r3.2xlarge</option>
                                <option value="db.r3.4xlarge">db.r3.4xlarge</option>
                                <option value="db.r3.8xlarge">db.r3.8xlarge</option>
                                <option value="db.r3.large">db.r3.large</option>
                                <option value="db.r3.xlarge">db.r3.xlarge</option>
                                <option value="db.r4.16xlarge">db.r4.16xlarge</option>
                                <option value="db.r4.2xlarge">db.r4.2xlarge</option>
                                <option value="db.r4.4xlarge">db.r4.4xlarge</option>
                                <option value="db.r4.8xlarge">db.r4.8xlarge</option>
                                <option value="db.r4.large">db.r4.large</option>
                                <option value="db.r4.xlarge">db.r4.xlarge</option>
                                <option value="db.r5.12xlarge">db.r5.12xlarge</option>
                                <option value="db.r5.12xlarge.tpc2.mem2x">db.r5.12xlarge.tpc2.mem2x</option>
                                <option value="db.r5.16xlarge">db.r5.16xlarge</option>
                                <option value="db.r5.24xlarge">db.r5.24xlarge</option>
                                <option value="db.r5.2xlarge">db.r5.2xlarge</option>
                                <option value="db.r5.2xlarge.tpc1.mem2x">db.r5.2xlarge.tpc1.mem2x</option>
                                <option value="db.r5.2xlarge.tpc2.mem4x">db.r5.2xlarge.tpc2.mem4x</option>
                                <option value="db.r5.2xlarge.tpc2.mem8x">db.r5.2xlarge.tpc2.mem8x</option>
                                <option value="db.r5.4xlarge">db.r5.4xlarge</option>
                                <option value="db.r5.4xlarge.tpc2.mem2x">db.r5.4xlarge.tpc2.mem2x</option>
                                <option value="db.r5.4xlarge.tpc2.mem3x">db.r5.4xlarge.tpc2.mem3x</option>
                                <option value="db.r5.4xlarge.tpc2.mem4x">db.r5.4xlarge.tpc2.mem4x</option>
                                <option value="db.r5.6xlarge.tpc2.mem4x">db.r5.6xlarge.tpc2.mem4x</option>
                                <option value="db.r5.8xlarge">db.r5.8xlarge</option>
                                <option value="db.r5.8xlarge.tpc2.mem3x">db.r5.8xlarge.tpc2.mem3x</option>
                                <option value="db.r5.large">db.r5.large</option>
                                <option value="db.r5.large.tpc1.mem2x">db.r5.large.tpc1.mem2x</option>
                                <option value="db.r5.xlarge">db.r5.xlarge</option>
                                <option value="db.r5.xlarge.tpc2.mem2x">db.r5.xlarge.tpc2.mem2x</option>
                                <option value="db.r5.xlarge.tpc2.mem4x">db.r5.xlarge.tpc2.mem4x</option>
                                <option value="db.r5b.12xlarge">db.r5b.12xlarge</option>
                                <option value="db.r5b.12xlarge.tpc2.mem2x">db.r5b.12xlarge.tpc2.mem2x</option>
                                <option value="db.r5b.16xlarge">db.r5b.16xlarge</option>
                                <option value="db.r5b.24xlarge">db.r5b.24xlarge</option>
                                <option value="db.r5b.2xlarge">db.r5b.2xlarge</option>
                                <option value="db.r5b.2xlarge.tpc1.mem2x">db.r5b.2xlarge.tpc1.mem2x</option>
                                <option value="db.r5b.2xlarge.tpc2.mem4x">db.r5b.2xlarge.tpc2.mem4x</option>
                                <option value="db.r5b.2xlarge.tpc2.mem8x">db.r5b.2xlarge.tpc2.mem8x</option>
                                <option value="db.r5b.4xlarge">db.r5b.4xlarge</option>
                                <option value="db.r5b.4xlarge.tpc2.mem2x">db.r5b.4xlarge.tpc2.mem2x</option>
                                <option value="db.r5b.4xlarge.tpc2.mem3x">db.r5b.4xlarge.tpc2.mem3x</option>
                                <option value="db.r5b.4xlarge.tpc2.mem4x">db.r5b.4xlarge.tpc2.mem4x</option>
                                <option value="db.r5b.6xlarge.tpc2.mem4x">db.r5b.6xlarge.tpc2.mem4x</option>
                                <option value="db.r5b.8xlarge">db.r5b.8xlarge</option>
                                <option value="db.r5b.8xlarge.tpc2.mem3x">db.r5b.8xlarge.tpc2.mem3x</option>
                                <option value="db.r5b.large">db.r5b.large</option>
                                <option value="db.r5b.large.tpc1.mem2x">db.r5b.large.tpc1.mem2x</option>
                                <option value="db.r5b.xlarge">db.r5b.xlarge</option>
                                <option value="db.r5b.xlarge.tpc2.mem2x">db.r5b.xlarge.tpc2.mem2x</option>
                                <option value="db.r5b.xlarge.tpc2.mem4x">db.r5b.xlarge.tpc2.mem4x</option>
                                <option value="db.r5d.12xlarge">db.r5d.12xlarge</option>
                                <option value="db.r5d.16xlarge">db.r5d.16xlarge</option>
                                <option value="db.r5d.24xlarge">db.r5d.24xlarge</option>
                                <option value="db.r5d.2xlarge">db.r5d.2xlarge</option>
                                <option value="db.r5d.4xlarge">db.r5d.4xlarge</option>
                                <option value="db.r5d.8xlarge">db.r5d.8xlarge</option>
                                <option value="db.r5d.large">db.r5d.large</option>
                                <option value="db.r5d.xlarge">db.r5d.xlarge</option>
                                <option value="db.r6g.12xlarge">db.r6g.12xlarge</option>
                                <option value="db.r6g.16xlarge">db.r6g.16xlarge</option>
                                <option value="db.r6g.2xlarge">db.r6g.2xlarge</option>
                                <option value="db.r6g.4xlarge">db.r6g.4xlarge</option>
                                <option value="db.r6g.8xlarge">db.r6g.8xlarge</option>
                                <option value="db.r6g.large">db.r6g.large</option>
                                <option value="db.r6g.xlarge">db.r6g.xlarge</option>
                                <option value="db.r6gd.12xlarge">db.r6gd.12xlarge</option>
                                <option value="db.r6gd.16xlarge">db.r6gd.16xlarge</option>
                                <option value="db.r6gd.2xlarge">db.r6gd.2xlarge</option>
                                <option value="db.r6gd.4xlarge">db.r6gd.4xlarge</option>
                                <option value="db.r6gd.8xlarge">db.r6gd.8xlarge</option>
                                <option value="db.r6gd.large">db.r6gd.large</option>
                                <option value="db.r6gd.xlarge">db.r6gd.xlarge</option>
                                <option value="db.r6i.12xlarge">db.r6i.12xlarge</option>
                                <option value="db.r6i.16xlarge">db.r6i.16xlarge</option>
                                <option value="db.r6i.24xlarge">db.r6i.24xlarge</option>
                                <option value="db.r6i.2xlarge">db.r6i.2xlarge</option>
                                <option value="db.r6i.32xlarge">db.r6i.32xlarge</option>
                                <option value="db.r6i.4xlarge">db.r6i.4xlarge</option>
                                <option value="db.r6i.8xlarge">db.r6i.8xlarge</option>
                                <option value="db.r6i.large">db.r6i.large</option>
                                <option value="db.r6i.xlarge">db.r6i.xlarge</option>
                                <option value="db.r7g.12xlarge">db.r7g.12xlarge</option>
                                <option value="db.r7g.16xlarge">db.r7g.16xlarge</option>
                                <option value="db.r7g.2xlarge">db.r7g.2xlarge</option>
                                <option value="db.r7g.4xlarge">db.r7g.4xlarge</option>
                                <option value="db.r7g.8xlarge">db.r7g.8xlarge</option>
                                <option value="db.r7g.large">db.r7g.large</option>
                                <option value="db.r7g.xlarge">db.r7g.xlarge</option>
                                <option value="db.t1.micro">db.t1.micro</option>
                                <option value="db.t2.2xlarge">db.t2.2xlarge</option>
                                <option value="db.t2.large">db.t2.large</option>
                                <option value="db.t2.medium">db.t2.medium</option>
                                <option value="db.t2.micro">db.t2.micro</option>
                                <option value="db.t2.small">db.t2.small</option>
                                <option value="db.t2.xlarge">db.t2.xlarge</option>
                                <option value="db.t3.2xlarge">db.t3.2xlarge</option>
                                <option value="db.t3.large">db.t3.large</option>
                                <option value="db.t3.medium">db.t3.medium</option>
                                <option value="db.t3.micro">db.t3.micro</option>
                                <option value="db.t3.small">db.t3.small</option>
                                <option value="db.t3.xlarge">db.t3.xlarge</option>
                                <option value="db.t4g.2xlarge">db.t4g.2xlarge</option>
                                <option value="db.t4g.large">db.t4g.large</option>
                                <option value="db.t4g.medium">db.t4g.medium</option>
                                <option value="db.t4g.micro">db.t4g.micro</option>
                                <option value="db.t4g.small">db.t4g.small</option>
                                <option value="db.t4g.xlarge">db.t4g.xlarge</option>
                                <option value="db.x1.16xlarge">db.x1.16xlarge</option>
                                <option value="db.x1.32xlarge">db.x1.32xlarge</option>
                                <option value="db.x1e.16xlarge">db.x1e.16xlarge</option>
                                <option value="db.x1e.2xlarge">db.x1e.2xlarge</option>
                                <option value="db.x1e.32xlarge">db.x1e.32xlarge</option>
                                <option value="db.x1e.4xlarge">db.x1e.4xlarge</option>
                                <option value="db.x1e.8xlarge">db.x1e.8xlarge</option>
                                <option value="db.x1e.xlarge">db.x1e.xlarge</option>
                                <option value="db.x2g.12xlarge">db.x2g.12xlarge</option>
                                <option value="db.x2g.16xlarge">db.x2g.16xlarge</option>
                                <option value="db.x2g.2xlarge">db.x2g.2xlarge</option>
                                <option value="db.x2g.4xlarge">db.x2g.4xlarge</option>
                                <option value="db.x2g.8xlarge">db.x2g.8xlarge</option>
                                <option value="db.x2g.large">db.x2g.large</option>
                                <option value="db.x2g.xlarge">db.x2g.xlarge</option>
                                <option value="db.x2idn.16xlarge">db.x2idn.16xlarge</option>
                                <option value="db.x2idn.24xlarge">db.x2idn.24xlarge</option>
                                <option value="db.x2idn.32xlarge">db.x2idn.32xlarge</option>
                                <option value="db.x2iedn.16xlarge">db.x2iedn.16xlarge</option>
                                <option value="db.x2iedn.24xlarge">db.x2iedn.24xlarge</option>
                                <option value="db.x2iedn.2xlarge">db.x2iedn.2xlarge</option>
                                <option value="db.x2iedn.32xlarge">db.x2iedn.32xlarge</option>
                                <option value="db.x2iedn.4xlarge">db.x2iedn.4xlarge</option>
                                <option value="db.x2iedn.8xlarge">db.x2iedn.8xlarge</option>
                                <option value="db.x2iedn.xlarge">db.x2iedn.xlarge</option>
                                <option value="db.x2iezn.12xlarge">db.x2iezn.12xlarge</option>
                                <option value="db.x2iezn.2xlarge">db.x2iezn.2xlarge</option>
                                <option value="db.x2iezn.4xlarge">db.x2iezn.4xlarge</option>
                                <option value="db.x2iezn.6xlarge">db.x2iezn.6xlarge</option>
                                <option value="db.x2iezn.8xlarge">db.x2iezn.8xlarge</option>
                                <option value="db.z1d.12xlarge">db.z1d.12xlarge</option>
                                <option value="db.z1d.2xlarge">db.z1d.2xlarge</option>
                                <option value="db.z1d.3xlarge">db.z1d.3xlarge</option>
                                <option value="db.z1d.6xlarge">db.z1d.6xlarge</option>
                                <option value="db.z1d.large">db.z1d.large</option>
                                <option value="db.z1d.xlarge">db.z1d.xlarge</option>
                            </select>
                        </div>}

                        {values.ConfigureValue.EngineType === "Microsoft SQL Server" &&
                            <div className="col-md-6 px-3">
                                <label htmlFor="databaseEdition" className="form-label font-14 font-medium black-v2 mb-3">
                                    Database Edition
                                </label>
                                <select
                                    className="form-select custom-form font-semibold font-14 form-select-sm"
                                    id="databaseEdition"
                                    value={values.databaseEdition}
                                    name={"databaseEdition"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                >
                                    <option value="Standard">Standard</option>
                                    <option value="Enterprise">Enterprise</option>
                                </select>
                            </div>
                        }


                        <div className="col-md-6 px-3">
                            <label htmlFor="RDSproxy" className="form-label font-14 font-medium black-v2 mb-3">
                                RDS Proxy
                            </label>
                            <select
                                className="form-select custom-form font-semibold font-14 form-select-sm"
                                id="RDSproxy"
                                value={values.RDSproxy}
                                name={"RDSproxy"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            >
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                        </div>

                        <div className="col-md-6 px-3">
                            <label
                                htmlFor="provisionedThroughput"
                                className="form-label font-14 font-medium black-v2 mb-3"
                            >
                                Storage
                            </label>
                            <input
                                type="text"
                                className="form-control custom-form text-field font-14 font-medium"
                                id="Storage"
                                value={values.Storage}
                                name={"Storage"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            />
                        </div>
                        <div className="col-md-6 px-3">
                            <label htmlFor="provisionedThroughputUnit" className="form-label font-14 font-medium black-v2 mb-3">
                                Unit
                            </label>
                            <select
                                className="form-select custom-form font-semibold font-14 form-select-sm"
                                id="StorageUnit"
                                value={values.StorageUnit}
                                name={"StorageUnit"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            >
                                <option value="MB">MB</option>
                                <option value="GB">GB</option>
                                <option value="TB">TB</option>
                            </select>
                        </div>
                        <div className="col-md-6 px-3">
                            <label htmlFor="storageType" className="form-label font-14 font-medium black-v2 mb-3">
                                Storage Type
                            </label>
                            <select
                                className="form-select custom-form font-semibold font-14 form-select-sm"
                                id="storageType"
                                value={values.storageType}
                                name={"storageType"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            >
                                <option value="gp2">gp2</option>
                                <option value="gp3">gp3</option>
                                <option value="io1">io1</option>
                                <option value="io2">io2</option>
                                <option value="Magnetic">Magnetic</option>
                            </select>
                        </div>


                    </div>
                </div>
        );
    } catch (error) {
        //console.log(error);
    }
}