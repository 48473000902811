const createNode = ( position, size, serviceData, nodeType) => {
	console.log(serviceData , "ServiceData")
	const dimensionsMap = {
		"Account": 400,
		"Region": 350,
		"VPC": 300,
		"Availability_Zone": 250,
		"Subnet": 200,
		"Security_group": 150
};

let nodeIdentity = nodeType;
let nodeStyle = {
	position: "absolute",
	zIndex: serviceData.serviceName.toLowerCase() === "vpc" ? "-100000" : "100010",
	width: size.width,
	height: size.height,
	className: "resizeLoop",
	color: getColorForService(serviceData.serviceName)
};

let htmlElement = (
	<div>
		<div className="container-hover"
			onMouseEnter={(e) => {
				// Show tooltip
				const tooltip = document.createElement('div');
				tooltip.innerHTML = `<strong>${serviceData.serviceName}</strong><br>`;
				tooltip.style.position = 'fixed';
				tooltip.style.top = `${e.clientY + 10}px`;
				tooltip.style.left = `${e.clientX + 10}px`;
				tooltip.style.backgroundColor = 'black';
				tooltip.style.color = 'grey';
				tooltip.style.padding = '8px';
				tooltip.style.borderRadius = '4px';
				tooltip.style.zIndex = '100';
				tooltip.style.whiteSpace = 'pre-wrap';
				tooltip.style.maxWidth = '200px';
				tooltip.className = 'custom-tooltip';
				document.body.appendChild(tooltip);
			}}
			onMouseLeave={() => {
				// Hide tooltip
				const tooltips = document.getElementsByClassName('custom-tooltip');
				while (tooltips.length > 0) {
					tooltips[0].parentNode.removeChild(tooltips[0]);
				}
			}}
		>
			<img
				id={serviceData.id}
				width={25}
				height={25}
				src={`${serviceData.imageName}`}
				className={["VPC", "Region", "Subnet", "Security_group"].includes(serviceData.serviceName) ? "vpcImageStyle" : "img"}
				alt="service-logo"
				onError={(e) => {
					e.target.onerror = null; // Prevents looping
					e.target.src = `./images/Workbench/AIArchitecture/AWS/aws-logo.svg`; // Set to your default image path
				}}
			/>
		</div>
	</div>
);
let id = serviceData.serviceName + "_" + Math.random()
	return {
		id: id,
		type: nodeIdentity,
		position: {
			x: isNaN(position.x) ? 0 : position.x,
			y: isNaN(position.y) ? 0 : position.y
		},
		// parentNode: "",
		style: nodeStyle,
		data: {
			element: htmlElement,
			resourceName: serviceData.serviceName,
			serviceName: serviceData.serviceName,
			imageName: serviceData.imageName,
			labelName : serviceData.labelName,
			serviceFamily : serviceData.serviceFamily ? serviceData.serviceFamily : "Other",
			imageServiceName: serviceData.serviceName,
	fields: serviceData.fields.fields ? serviceData.fields.fields :  serviceData.fields || [],
	ConfigureDetail: {
		subServieConfig: {
			Action: {
				create: {},
				delete: {},
				update: {}
			},
			type: "create"
		}
	},
	description: serviceData.description || '',
	children: undefined,
	repositoryData: {
		repository_name: "",
		repository_id: "",
		branchData: {
			branch_id: "",
			branch_name: ""
		}
	},
	Tags: [
		{
			"key": "cloudgen",
			"value": true,
			defaultTag: true
		} 
	]
	}
	};
};
// Helper function to get color for service
function getColorForService(serviceName) {
	const colorMap = {
		"VNET": "#8C4FFF",
		"Account": "#CD2264",
		"Availability_Zone": "#6610f2",
		"Resource_Group": "#00A4A6",
		"Subnet": "#DD3522",
		"Security_group": "#DD3522"
	};
	return colorMap[serviceName] || "#000000";
} 

const processAwsNodesFromResponse = (awsResources) => {
	// awsResources = 
        // {
        //     "orphans":{
        //        "Route53":[
        //           {
        //              "name":"route53.example.com",
        //              "position":{
        //                 "x":200,
        //                 "y":50
        //              },
        //              "ai_configuration":{
        //                 "fields":[
        //                    {
        //                       "fieldName":"location",
        //                       "fieldType":"Dropdown",
        //                       "options":[
        //                          "us-east-1",
        //                          "us-west-1",
        //                          "eu-west-1"
        //                       ],
        //                       "value":"us-east-1"
        //                    },
        //                    {
        //                       "fieldName":"CidrBlock",
        //                       "fieldType":"text",
        //                       "value":"10.0.0.0/16"
        //                    },
        //                    {
        //                       "fieldName":"instanceTenancy",
        //                       "fieldType":"text",
        //                       "value":"default"
        //                    },
        //                    {
        //                       "fieldName":"isDefault",
        //                       "fieldType":"text",
        //                       "value":"true"
        //                    }
        //                 ],
        //                 "serviceImage":"https://example.com/route53.svg",
        //                 "servicename":"Route53"
        //              }
        //           }
        //        ]
        //     },
        //     "vpcs":[
        //        {
        //           "name":"ApplicationVPC",
        //           "ai_configuration":{
        //              "fields": [
        //                  {
        //                      "fieldName": "CidrBlock",
        //                      "fieldType": "text",
        //                      "value": "172.31.0.0/16"
        //                  },
        //                  {
        //                      "fieldName": "InstanceTenancy",
        //                      "fieldType": "text",
        //                      "value": "default"
        //                  },
        //                  {
        //                      "fieldName": "IsDefault",
        //                      "fieldType": "text",
        //                      "value": "true"
        //                  }
        //              ],
        //              "servicename": "VPC"
        //          },
        //           "subservice":{
        //              "subnets":[
        //                 {
        //                    "id":"subnet-abc123",
        //                    "name":"PublicSubnet1",
        //                    "ai_configuration": {
        //                       "fields": [
        //                           {
        //                               "fieldName": "InstanceTenancy",
        //                               "fieldType": "text",
        //                               "value": "default"
        //                           },
        //                           {
        //                               "fieldName": "IsDefault",
        //                               "fieldType": "text",
        //                               "value": "false"
        //                           },
        //                           {
        //                               "fieldName": "CidrBlock",
        //                               "fieldType": "text",
        //                               "value": "10.22.0.0/18"
        //                           },
        //                           {
        //                               "fieldName": "State",
        //                               "fieldType": "text",
        //                               "value": "available"
        //                           }
        //                       ],
        //                       "servicename": "VPC",
        //                       "tags": [
        //                           {
        //                               "Key": "Cloudgen",
        //                               "Value": "false"
        //                           },
        //                           {
        //                               "Key": "Owner",
        //                               "Value": "Jasper"
        //                           },
        //                           {
        //                               "Key": "Environment",
        //                               "Value": "Dev"
        //                           },
        //                           {
        //                               "Key": "Purpose",
        //                               "Value": "Cloudgen Infra Creation"
        //                           },
        //                           {
        //                               "Key": "Name",
        //                               "Value": "ZEB-APS1-CLOUDGEN-VPC-DEV"
        //                           },
        //                           {
        //                               "Key": "Application",
        //                               "Value": "CloudGen"
        //                           },
        //                           {
        //                               "Key": "Domain",
        //                               "Value": "Cloud"
        //                           }
        //                       ]
        //                   },
        //                    "subservice":{
        //                       "EC2":[
        //                          {
        //                             "name":"WebServer1",
        //                             "ai_configuration":{
        //                                "fields":[
        //                                   {
        //                                      "fieldName":"location",
        //                                      "fieldType":"Dropdown",
        //                                      "options":[
        //                                         "us-east-1",
        //                                         "us-west-1",
        //                                         "eu-west-1"
        //                                      ],
        //                                      "value":"us-east-1"
        //                                   },
        //                                   {
        //                                      "fieldName":"servicename",
        //                                      "fieldType":"text",
        //                                      "value":"subnets"
        //                                   },
        //                                   {
        //                                      "fieldName":"CidrBlock",
        //                                      "fieldType":"text",
        //                                      "value":"10.0.1.0/24"
        //                                   },
        //                                   {
        //                                      "fieldName":"DefaultForAz",
        //                                      "fieldType":"text",
        //                                      "value":"true"
        //                                   },
        //                                   {
        //                                      "fieldName":"AvailabilityZone",
        //                                      "fieldType":"text",
        //                                      "value":"us-east-1a"
        //                                   }
        //                                ],
        //                                "serviceImage":"https://example.com/ec2.svg",
        //                                "servicename":"EC2",
        //                                "tags":[
        //                                   {
        //                                      "Key":"Name",
        //                                      "Value":"WebServer1"
        //                                   }
        //                                ]
        //                             }
        //                          }
        //                       ]
        //                    }
        //                 },
        //                 {
        //                    "id":"subnet-def456",
        //                    "name":"PrivateSubnet1",
        //                    "width":400,
        //                    "height":200
        //                 }
        //              ],
        //              "Kinesis":[
        //                 {
        //                    "name":"DataStreamingKinesis",
        //                    "position":{
        //                       "x":400,
        //                       "y":300
        //                    },
        //                    "ai_configuration":{
        //                       "fields":[
        //                          {
        //                             "fieldName":"location",
        //                             "fieldType":"Dropdown",
        //                             "options":[
        //                                "us-east-1",
        //                                "us-west-1",
        //                                "eu-west-1"
        //                             ],
        //                             "value":"us-east-1"
        //                          },
        //                          {
        //                             "fieldName":"CidrBlock",
        //                             "fieldType":"text",
        //                             "value":"10.0.0.0/16"
        //                          },
        //                          {
        //                             "fieldName":"DefaultForAz",
        //                             "fieldType":"text",
        //                             "value":"true"
        //                          },
        //                          {
        //                             "fieldName":"AvailabilityZone",
        //                             "fieldType":"text",
        //                             "value":"us-east-1a"
        //                          }
        //                       ],
        //                       "serviceImage":"https://example.com/kinesis.svg",
        //                       "servicename":"Kinesis",
        //                       "tags":[
        //                          {
        //                             "Key":"Name",
        //                             "Value":"DataStreamingKinesis"
        //                          }
        //                       ]
        //                    }
        //                 }
        //              ]
        //           }
        //        }
        //     ]
        //  }
	if (!awsResources) return { nodes: [], edges: [] };
	console.log("processAwsResources",awsResources)
	const nodes = [];
	const edges = [];
	let id = 0;

	// Constants for layout and spacing
	const PADDING = 15;
	const SERVICE_SPACING = 15; // Spacing between individual services inside subnets
	const VERTICAL_SPACE_ABOVE = 15; // Space above the first row of services
	const MIN_VPC_WIDTH = 300;
	const MIN_VPC_HEIGHT = 300;
	const MIN_SUBNET_WIDTH = 200;
	const MIN_SUBNET_HEIGHT = 200;
	const SERVICE_WIDTH = 25;
	const SERVICE_HEIGHT = 25;
	const MAX_SERVICES_PER_ROW = 5; // Maximum services per row inside a subnet
	const ORPHAN_PADDING = 10;



	let maxX = 0;
	let maxY = 0;

	// Process VPCs
	const vpcNodes = (awsResources.vpcs || [])
		.map((vpc, vpcIndex) => {
			console.log("Inovked Insides")
			let vpcWidth = MIN_VPC_WIDTH;
			let vpcHeight = MIN_VPC_HEIGHT;
			let vpcSubnodes = [];

			// Process Subnets
			if (vpc.subservice?.subnets) {
				const filteredSubnets = vpc.subservice.subnets.filter(subnet => subnet.ai_configuration);
				console.log(filteredSubnets , "filteredSubnets")
				const subnetsPerRow = Math.min(5, filteredSubnets.length); // 5 subnets per row
				let subnetWidth = MIN_SUBNET_WIDTH;
				let subnetHeight = MIN_SUBNET_HEIGHT;

				// Track the heights of previous subnets for vertical alignment
				let subnetYPositions = [];

				filteredSubnets.forEach((subnet, subnetIndex) => {
					// Retrieve services within the subnet
					const services = subnet.subservice || {};
					const serviceKeys = Object.keys(services);
					const totalServices = serviceKeys.reduce((sum, key) => sum + (services[key].filter(s => s.ai_configuration).length || 0), 0);
					console.log(services , serviceKeys , "test the INdividual Service Here")
					// Calculate position for each subnet
					const subnetX = (subnetIndex % subnetsPerRow) * (subnetWidth + PADDING) + PADDING;
					const subnetY = Math.floor(subnetIndex / subnetsPerRow) * (subnetHeight + PADDING) + PADDING;

					// Store the height of this subnet for the next iterations
					subnetYPositions.push(subnetY + subnetHeight + PADDING); // Add the height plus padding

						// Node Creation Dependencies For Subnet 
					let position = { x: subnetX, y: subnetY, z: -100001 }
					let size = { width: subnetWidth, height: subnetHeight }

					let serviceData = {
						serviceName: "Subnet" ,
						imageName:"./images/Workbench/AIArchitecture/AWS/subnet.svg",
						fields: subnet.ai_configuration.fields || [],
						labelName : subnet.ai_configuration.name
					}
					let nodeType = "location"
						// Creation of Node Performed Here
					const subnetNode = createNode(position,size,serviceData,nodeType);

					vpcSubnodes.push(subnetNode);


						// Create individual nodes for each service, with spacing applied
						let serviceIndex = 0; // Keep track of service index
						console.log(services , "services  here ")
						serviceKeys.forEach(serviceType => {
							services[serviceType].forEach(service => {
								console.log(service , "ServiceYYYY")
								if (service.ai_configuration) {
									// Correctly positioning the services inside the specific subnet
									const row = Math.floor(serviceIndex / MAX_SERVICES_PER_ROW);
									const col = serviceIndex % MAX_SERVICES_PER_ROW;

									// Calculate serviceX and serviceY
									const serviceX = PADDING + col * (SERVICE_WIDTH + SERVICE_SPACING) + subnetX;
									const serviceY = subnetY + VERTICAL_SPACE_ABOVE + PADDING + row * (SERVICE_HEIGHT + SERVICE_SPACING); // Add vertical space above
										// Node Creation Dependencies
										let position = { x: serviceX, y: serviceY, z: 100000 }
										let size = { width: SERVICE_WIDTH, height: SERVICE_HEIGHT }
										let serviceData = {
											serviceName: service.ai_configuration.servicename || service.name,
											imageName: service.ai_configuration.serviceImage,
											fields: service.ai_configuration.fields || [],
										labelName: service.name
											}
											let nodeType = "service"
									const individualNode =createNode(position,size,serviceData,nodeType);
									console.log(individualNode , "individualNode")
									vpcSubnodes.push(individualNode);
									serviceIndex++;
								}
							});
						});

						// Adjust subnet size based on number of rows of services
						const rows = Math.ceil(totalServices / MAX_SERVICES_PER_ROW);
						subnetHeight = Math.max(MIN_SUBNET_HEIGHT, (SERVICE_HEIGHT + SERVICE_SPACING) * rows + VERTICAL_SPACE_ABOVE + PADDING);

						// Set the height for the subnet node
						subnetNode.style.height = subnetHeight;

						// Update the height of the subnet for the next positioning
						subnetYPositions[subnetIndex] = subnetY + subnetHeight + PADDING;

				});

				// Calculate the size of the VPC based on the subnets' sizes
				vpcWidth = Math.max(MIN_VPC_WIDTH, (subnetWidth + PADDING) * subnetsPerRow + PADDING * 2);
				vpcHeight = Math.max(MIN_VPC_HEIGHT, (subnetHeight + PADDING) * Math.ceil(filteredSubnets.length / subnetsPerRow) + PADDING * 2);

				const vpcX = (vpcIndex % 2) * (vpcWidth + PADDING * 2);
				const vpcY = Math.floor(vpcIndex / 2) * (vpcHeight + PADDING * 2);

				maxX = Math.max(maxX, vpcX + vpcWidth);
				maxY = Math.max(maxY, vpcY + vpcHeight);
				// Node Creation Dependencies
				let position = { x: vpcX, y: vpcY, z: -100002 }
				let size = { width: vpcWidth, height: vpcHeight }
				
				let serviceData = {
					serviceName: "VPC" ,
					imageName: "./images/Workbench/AIArchitecture/AWS/vpc.svg",
					fields: vpc.ai_configuration.fields || [],
					labelName : vpc.name
				}
				let nodeType = "location"
				const vpcNode = createNode(position,size,serviceData,nodeType);

				nodes.push(vpcNode);
				vpcSubnodes.forEach(subnode => {
					subnode.parentNode = vpcNode.id;
					nodes.push(subnode);
				});

				// Handle orphan nodes positioning
				let orphanX = vpcX + vpcWidth + ORPHAN_PADDING; // Start to the right of the VPC
				let orphanY = vpcY; // Align with the VPC's Y position

				// Process orphan resources (if any)

					let orphanCount = 0; // Count orphans to manage row positioning
					Object.entries(awsResources.orphans).forEach(([resourceType, resources]) => {

						resources.forEach(resource => {
							if (resource.ai_configuration && Object.keys(resource.ai_configuration).length > 0) {
								// Node Creation Dependencies
							let position = { x: orphanX, y: orphanY, z: 100000 }
							let size = { width: SERVICE_WIDTH, height: SERVICE_HEIGHT }
							let serviceData = {
								serviceName: resource.ai_configuration.servicename || resource.name,
								imageName: resource.ai_configuration.serviceImage,
								fields: resource.ai_configuration.fields|| [],
								labelName : resource.name
							}
							let nodeType = "service"
							const orphanNode = createNode(position,size,serviceData,nodeType);

							nodes.push(orphanNode);
								orphanCount++;

								orphanX += SERVICE_WIDTH + PADDING; // Move to the right for the next orphan

								// Move to the next row if the maximum number of orphans per row is reached
								if (orphanCount % MAX_SERVICES_PER_ROW === 0) {
									orphanX = vpcX + vpcWidth + ORPHAN_PADDING; // Reset to the start
									orphanY += SERVICE_HEIGHT + ORPHAN_PADDING; // Move down to the next row
								}
							}
						});
					});


				return vpcNode;
			}
		});


	console.log("Final Nodesss in the AI AWS architecture", nodes )
	return nodes

};

export default processAwsNodesFromResponse;
