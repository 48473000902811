import { useEffect, useState } from "react";
import { WorkbenchNav } from "../../WorkBenchNav"
import DataResourcePopup from "./DataResourcePopup";
import { deleteResourceData, fetchDataConfigs, fetchServiceReviewData } from "../../../../Service/api";
import { useOrgContext } from "../../../../ContextProvider/OrgContext";
import Loader from "../../../loader/loader";
import CategoryAccordion from "./CategoryAccordian";
import ResourceCards from "./ResourceCards";

function DataConfiguration(props) {
    let { setDataConfigModal, accountIds } = props
    const dataConfigurations =
    {
        "configResource": [],
        "dataConfig": []

    }

    // SQ_NO_1.6-1.8
    const { providerId, orgId, envId, } = useOrgContext()
    const userId = localStorage.getItem('userId');

    // SQ_NO_1.4 -1.5
    const [showLoader, setShowLoader] = useState(false);
    const [error, setError] = useState(null);
    const [dataConfiguration, setDataConfiguration] = useState({
        dataConfig: dataConfigurations.dataConfig,
        configResource: dataConfigurations.configResource
    });
    const [serviceConfigs, setServiceConfigs] = useState([]);
    const [configuredResources, setConfiguredResources] = useState([]);
    const [accountsData, setAccountsData] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState("");

    const [resourceData, setResourceData] = useState([]);
    const [showDataResourcePopup, setShowDataResourcePopup] = useState(false)
    const [selectedResource, setSelectedResource] = useState(null);
    const [reload, setReload] = useState(false)
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredServiceConfigs, setFilteredServiceConfigs] = useState([]);
    const [expandedCategoryId, setExpandedCategoryId] = useState(null);
    const [expandedServiceId, setExpandedServiceId] = useState(null);
    const [expandedResourceId, setExpandedResourceId] = useState(null);
    const [groupedResourceData, setGroupedResourceData] = useState([]);


    useEffect(() => {
        if (accountIds.length > 0) {
            setAccountsData(accountIds);
            setSelectedAccount(accountIds[0]?.accountId);
        }
    }, [accountIds]);



    // SQ_NO -1.32 - 1.33: UseEffect to bind service and resource configs   
    useEffect(() => {

        if (dataConfiguration.dataConfig && dataConfiguration.configResource) {


            // Set service configurations
            setServiceConfigs(dataConfiguration.dataConfig);

            // Set configured resources
            setConfiguredResources(dataConfiguration.configResource);

            // Extract accounts and set accounts data
            // const accounts = dataConfiguration.configResource.map(resource => resource.accountId);
            // setAccountsData(accounts);

            // // Set initial selected account
            // if (accounts.length > 0) {
            //     setSelectedAccount(accounts[0]);
            // }

        }

    }, [dataConfiguration]);




    //  SQ_NO: 2.17-2.21 : Handle selected account change 
    useEffect(() => {
        if (selectedAccount && configuredResources.length > 0) {
            const selectedConfig = configuredResources.find(resource => resource.accountId === selectedAccount);
            if (selectedConfig) {
                const groupedData = groupByResourceName(selectedConfig.configData);
                setGroupedResourceData(groupedData);
            }
        }
    }, [selectedAccount, configuredResources]);

    //  SQ_NO: 1.32 - 1.34
    useEffect(() => {

        getDataConfiguration()
    }, [selectedAccount,reload])

    //  SQ_NO: 3.3-3.8
    useEffect(() => {
        const filtered = serviceConfigs?.filter(category =>
            category?.services.some(service =>
                service?.resources.some(resource =>
                    resource?.resourceName?.toLowerCase()?.includes(searchTerm?.toLowerCase())
                )
            )
        );
        setFilteredServiceConfigs(filtered);

        // Identify and expand the matching category and service
        if (searchTerm && filtered.length > 0) {
            console.log("entered if")

            filtered.forEach(category => {
                category.services.forEach(service => {
                    if (service.resources.some(resource => resource.resourceName.toLowerCase().includes(searchTerm.toLowerCase()))) {
                        setExpandedCategoryId(category.categoryId);
                        setExpandedServiceId(service.serviceId);
                    }
                });
            });
        }  else if (!searchTerm) {
            console.log("entered else if")

            setExpandedCategoryId(null);
            setExpandedServiceId(null);
        } else {
            console.log("entered else when searchTerm not matched")
            // Reset to initial state when there's no search term
            setFilteredServiceConfigs(serviceConfigs);
            setExpandedCategoryId(null);
            setExpandedServiceId(null);
        }
    }, [searchTerm, serviceConfigs]);

    //SQ_NO: 2.3-2.15 - Fetch dataConfigurations
    const getDataConfiguration = async () => {

        try {

            const payload = {
                userId: userId,
                accountId: [selectedAccount],
                //accountId: ["e69acbcf-aac5-4473-bbcb-81d00872d16e", "e9a61a79-cd58-4f2b-941c-9090b60579fd", "e69acbcf-aac5-4473-bbcb-81d00872d16e"],
                providerId: providerId,
                environmentId: envId
            };

            setShowLoader(true);
            //SQ_NO : 2.4 -2.11
            const response = await fetchDataConfigs(payload);
            setShowLoader(false);

            console.log(response, "dataConfigResponse")

            if (response.status === 200) {

                setDataConfiguration({
                    dataConfig: response.data.data.dataConfig,
                    configResource: response.data.data.configResource
                });
                // setServiceConfigs(response.data.data.dataConfig);

                // // Set configured resources
                // setConfiguredResources(response.data.data.configResource);
                setReload(false)
            }
        } catch (err) {
            setShowLoader(false);
            setError(err.message);
        }
    };


    // SQ_NO_8.56 - SQ_NO_8.58 : Close popup
    const handleClosePopup = () => {
        setShowDataResourcePopup(false);
        setSelectedResource(null);
        setReload(true)
    };

    // SQ_NO_3.0-3.2
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    //SQ_NO_4.1-4.6 : Category click
    const handleCategoryClick = (categoryId) => {
        setExpandedCategoryId(expandedCategoryId === categoryId ? null : categoryId);
        setExpandedServiceId(null);
    };

    //SQ_NO_5.1-5.5
    const handleServiceClick = (serviceId) => {
        setExpandedServiceId(expandedServiceId === serviceId ? null : serviceId);
    };
    //SQ_NO_6.1-6.5
    const handleResourceClick = (resourceId) => {
        setExpandedResourceId(expandedResourceId === resourceId ? null : resourceId);
    };

    // SQ_NO 8.36-8.38: Function to count how many "Name" properties exist in the array
    const countNames = (resource) => {
        return resource.resources ? resource.resources.length : 0;
    };

    //SQ_NO 8.58- 8.63: Grouping resourceName
    const groupByResourceName = (data) => {
        const groupedData = data.reduce((acc, item) => {
            const { resourceName } = item;
            if (!acc[resourceName]) {
                acc[resourceName] = [];
            }
            acc[resourceName].push(item);
            return acc;
        }, {});

        return Object.entries(groupedData).map(([resourceName, resources]) => ({
            resourceName,
            resources
        }));
    };

    //SQ_8.7 - 8.19
    const categoryBind = () => {
        return (
            <div>
                {filteredServiceConfigs.map((category) => (
                    <CategoryAccordion
                        key={category.categoryId}
                        category={category}
                        handleCategoryClick={handleCategoryClick}
                        handleServiceClick={handleServiceClick}
                        expandedCategoryId={expandedCategoryId}
                        expandedServiceId={expandedServiceId}
                        setShowDataResourcePopup={setShowDataResourcePopup}
                        showDataResourcePopup={showDataResourcePopup}
                        onAddResourceClick={onAddResourceClick}
                        handleResourceNameClick={handleResourceNameClick}


                    />
                ))}
            </div>
        );
    };


    //SQ_8.68 : Category cards add click
    const onAddResourceClick = (resource) => {
        console.log(resource, "resourceonclick")

        setSelectedResource(resource);

    };

    //SQ_8.69 - 8.70 : Resource name edit click 
    const handleResourceNameBtnClick = (resourceName, resource) => {
        try {

            // Check for the resourceName in serviceConfigs data to get resourceId
            const matchingServiceConfig = serviceConfigs?.find(services => {
                return services?.services?.some(service =>
                    service?.resources?.some(res => res?.resourceName === resourceName)
                );
            });

            let resourceId = resource.dataId;
            if (matchingServiceConfig) {
                const matchingResource = matchingServiceConfig?.services
                    ?.flatMap(service => service.resources)
                    .find(res => res.resourceName === resourceName);
                if (matchingResource) {
                    resourceId = matchingResource.resourceId;
                }
            }

            // Ensure fieldName is always an array
            const fieldName = Array.isArray(resource.fieldName)
                ? resource.fieldName
                : Object.entries(resource.fieldName).map(([field, value]) => ({ field, value }));

            const selectedResourceWithId = {
                ...resource,
                fieldName: fieldName,
                resourceId: resourceId,
            };

            setSelectedResource(selectedResourceWithId);
            setShowDataResourcePopup(true);
        } catch (error) {
            console.error("Error handling resource click:", error);
            // Optionally, set an error state or display a message to the user
        }
    };

    //SQ_8.71 : Add click in resource cards
    const handleAddDataClick = (resourceName) => {
        try {

            const matchingServiceConfig = serviceConfigs?.reduce((acc, services) => {
                services?.services?.forEach((service) => {
                    service?.resources?.forEach((res) => {
                        if (res.resourceName === resourceName) {
                            acc.push(res);
                        }
                    });
                });
                return acc;
            }, []);

            if (matchingServiceConfig.length > 0) {
                // Assuming one matching config
                const [matchingResource] = matchingServiceConfig;
                const selectedResourceWithId = {
                    resourceName: matchingResource.resourceName,
                    resourceId: matchingResource.resourceId,
                    fieldName: Array.isArray(matchingResource.fieldName) ? matchingResource.fieldName : [],
                    code: matchingResource.code || '',
                };
                setSelectedResource(selectedResourceWithId);
                setShowDataResourcePopup(true);
            }
        } catch (error) {
            console.error("Error while finding matching resource:", error);
        }
    };

    //SQ_8.72 : Handles delete resource click
    const handleDeleteResource = async (resourceGroup) => {
        try {
            // Check if the resource group exists
            if (!resourceGroup || !resourceGroup.resources) {
                console.error("Invalid resource group provided");
                return;
            }

            // Loop through each resource in the group and delete it
            for (const resource of resourceGroup.resources) {
                const payload = { userId: userId, dataId: resource.dataId };
                setShowLoader(true);
                console.log('Deleting resource with dataId:', resource.dataId); // Debug log
                await deleteResourceData(payload); // Await each API call to delete resource
            }

            // After all resources are deleted, refresh the data
            await getDataConfiguration();

        } catch (err) {
            setError(err.message);
        } finally {
            setShowLoader(false);
        }
    };

    //SQ_NO 8.20- 8.29: To bind resource configurations
    const renderResourceCards = () => {

        if (selectedAccount === "" || selectedAccount === "Select") {
            return (
                <div className="d-flex flex-column justify-content-center align-items-center custom-height">
                    <img src="images/nodata-found.svg" alt="No Data Found" />
                    <h6 className="color-black font-bold font-16 mt-5 mb-0">No Resources Found!</h6>
                    <p className="font-medium font-12 mt-4 mb-0 secondary-text-color-v5">
                        The resources of services will be shown here
                    </p>
                </div>
            );
        }

        return groupedResourceData.map((resource, index) => (
            <ResourceCards
                key={resource.resourceName + index}
                resource={resource}
                handleResourceClick={handleResourceClick}
                expandedResourceId={expandedResourceId}
                setShowDataResourcePopup={setShowDataResourcePopup}
                nameCount={countNames(resource)} // Pass count here
                handleResourceNameBtnClick={handleResourceNameBtnClick}
                handleAddDataClick={handleAddDataClick}
                handleDeleteResource={handleDeleteResource}


            />
        ));
    };

    //SQ_NO 8.64- 8.67: Handles resource name in category accordian
    const handleResourceNameClick = (resourceName) => {
        const resourceExists = groupedResourceData.some(resource => resource.resourceName === resourceName);

        if (resourceExists) {
            // Highlight the existing resource card
            setExpandedResourceId(resourceName);
        } else {
            // Add the resource card dynamically
            const newResource = {
                resourceName,
                resources: []
            };
            setGroupedResourceData([...groupedResourceData, newResource]);
        }
    };

    console.log(dataConfiguration, "dataConfiguration")
    console.log(searchTerm, "searchWOrd")
    console.log(filteredServiceConfigs, "filteredServiceConfigs")
    console.log(reload, "reload");
    console.log(serviceConfigs, "serviceConfiguration")
    console.log(configuredResources, "configuredResources")
    console.log(accountsData, "accountsData")
    console.log(selectedAccount, "selectedAccount")
    console.log(resourceData, "resourceData")
    console.log(accountIds, "accountIds")
    console.log(selectedResource, "selectedResource")
    console.log(groupedResourceData, "groupedResourceData")
    console.log(props, "props")









    return (
        <>

            <>
                {showLoader ?
                    <Loader /> :

                    <div className="container-fluid">
                        <div className="d-flex justify-content-between align-items-center my-3">
                            <div className="d-flex align-items-center">
                                <a href="#">
                                    <img
                                        src="images/back-arrow.svg"
                                        alt="back-arrow"
                                        title="Back"
                                        className="icon-filter"
                                        onClick={(() => {
                                             getDataConfiguration();
                                            setDataConfigModal(false)
                                        })}
                                    />
                                </a>
                                <h2 className="font-18 font-bold black-v2 mb-0 ms-3">
                                    Data Configuration
                                </h2>
                            </div>
                            <div className="d-flex align-items-center gap-3">
                                <label className="font-14 font-medium secondary-text-color">
                                    Account
                                </label>
                                <select
                                    className="form-select custom-dropdown shadow-none py-2 font-14 font-regular black-v2 custom-select acc-dropdown"
                                    aria-label="Default select example"
                                    id="Account"
                                    value={selectedAccount}
                                    onChange={(e) => setSelectedAccount(e.target.value)}
                                >
                                    <option value="">Select</option>
                                    {accountsData.map((account) => (
                                        <option key={account.accountId} value={account.accountId}>
                                            {account.accountName}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            {/* sidebar-menu start */}
                            <div className="col-md-3 ps-5 pe-1 color-black">
                                <h2 className="font-18 font-bold black-v2 mb-0">Services</h2>

                                {showDataResourcePopup && (
                                    <DataResourcePopup
                                        showDataResourcePopup={showDataResourcePopup}
                                        setShowDataResourcePopup={setShowDataResourcePopup}
                                        selectedResource={selectedResource}
                                        onClose={handleClosePopup}
                                        selectedAccount={selectedAccount}
                                        setShowLoader={setShowLoader}
                                    />
                                )}
                                {/* search */}
                                <div className="input-group input-search custom-border-box custom-search-box my-3">
                                    <input
                                        type="search"
                                        className="px-3 form-control common-bg border-0 custom-search gray-v1"
                                        placeholder="Search"
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                    />
                                    <button className="btn border-start-0  custom-search " type="button">
                                        <img src="images/search-icon.svg" alt="search" />
                                    </button>
                                </div>
                                {/* accordion starts */}
                                <div class="accordion services-container" id="accordionExample">
                                    {categoryBind()}
                                </div>
                                {/* accordion ends */}
                            </div>



                            {/*sidebar-menu end  */}
                            <div className="col-md-9">
                                <h2 className="font-18 font-bold black-v2 mb-0">
                                    Configured Resources
                                </h2>

                                {/* resourceCard */}
                                {/* <div className="accordion my-3" id="accordion2"> */}
                                {renderResourceCards()}
                                {/* </div> */}
                                {/* resourceCard */}
                            </div>
                        </div>
                    </div>
                }
            </>




        </>
    )
}
export default DataConfiguration