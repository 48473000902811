// ConfirmationModal.js
import React from 'react';

const ConfirmationModal = ({ isOpen, onClose, message }) => {
    return (
        <div className={`modal fade ${isOpen ? 'show' : ''}`} style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }} tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden={!isOpen}>
            <div className="modal-dialog modal-dialog-centered justify-content-center">
                <div className="modal-content border-0">
                    <div className="modal-header d-flex justify-content-end border-0 pb-0">
                        <button type="button" className="btn shadow-none" onClick={onClose} aria-label="Close">
                            <img src="images/close-icon.svg" alt="close-icon" className="icon-hover shadow-none" />
                        </button>
                    </div>
                    <div className="modal-body text-center pt-0 p-32">
                        <img src="images/conform-img.svg" alt="conform-img" />
                        <h6 className="font-20 font-bold black-v2 mt-4 mb-3">Unable to Add Connector</h6>
                        <div className="d-flex justify-content-center">
                            <p className="font-16 font-regular w-75 secondary-text-color-v4 mb-0 lh-32">
                                {message || "You’re about to update..."} {/* Default message */}
                            </p>
                        </div>
                        <div className="d-flex justify-content-center align-items-center gap-3 mt-4">
                            <button type="button" className="button primary-button py-12 px-4" onClick={onClose}>Okay</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationModal;