import Client from "./client";
import getEndpoint from "./endpoints";
export async function getOrganizationDetailsApi(payload) {
	const endpoint = getEndpoint('Organization', 'getAllOrganization'); // Corrected endpoint retrieval
console.log("endpoint",endpoint);
	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response.data


	} catch (error) {
		console.error('Error fetching INR price:', error);
		throw error; // Rethrow or handle the error as necessary
	} 
}

export async function deleteOrganizationApi(payload) {
	const endpoint = getEndpoint('Organization', 'delete_organization'); // Retrieve the endpoint for deleting an organization
	console.log("endpoint", endpoint);

	try {
		const response = await Client(endpoint, payload, "POST"); // Use DELETE method for deletion
		console.log(response, "responses");
		return response.data; // Return the response from the API
	} catch (error) {
		console.error('Error deleting organization:', error); // Log any errors that occur
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function getOrganizationDetailsByIdApi(payload) {
	const endpoint = getEndpoint('Organization', 'get_organization_details_by_id'); // Adjust this based on your endpoint structure
	console.log("Fetching organization details from endpoint:", endpoint);

	try {
		const response = await Client(endpoint, payload, "POST"); // Use GET method to fetch details
		console.log(response, "Response from get organization details");
		return response.data; // Return the response from the API
	} catch (error) {
		console.error('Error fetching organization details:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function createOrganizationApi(payload) {
	const endpoint = getEndpoint('Organization', 'create_organization'); // Adjust this based on your endpoint structure
	console.log("Creating organization at endpoint:", endpoint);

	try {
		const response = await Client(endpoint, payload, "POST"); // Use POST method for creation
		console.log(response, "Response from create organization");
		return response.data; // Return the response from the API
	} catch (error) {
		console.error('Error creating organization:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function getAllWorkloadsApi(payload) {
	const endpoint = getEndpoint('Organization', 'get_all_workloads'); // Adjust this based on your endpoint structure
	console.log("Fetching workloads from endpoint:", endpoint);

	try {
		const response = await Client(endpoint, payload, "POST"); // Use POST method for fetching workloads
		console.log(response, "Response from get all workloads");
		return response.data; // Return the response from the API
	} catch (error) {
		console.error('Error fetching workloads:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function getAllProvidersApi(payload) {
	const endpoint = getEndpoint('Organization', 'get_all_provider'); // Adjust this based on your endpoint structure
	console.log("Fetching providers from endpoint:", endpoint);

	try {
		const response = await Client(endpoint, payload, "POST"); // Use POST method for fetching providers
		console.log(response, "Response from get all providers");
		return response.data; // Return the response from the API
	} catch (error) {
		console.error('Error fetching  	providers:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function deleteWorkloadApi(payload) {
	const endpoint = getEndpoint('Organization', 'delete_workload'); // Adjust this based on your endpoint structure
	console.log("Deleting workload at endpoint:", endpoint);

	try {
		const response = await Client(endpoint, payload, "POST"); // Use DELETE method for deleting workloads
		console.log(response, "Response from delete workload");
		return response.data; // Return the response from the API
	} catch (error) {
		console.error('Error deleting workload:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function cloneArchitectureApi(payload) {
	const endpoint = getEndpoint('Organization', 'clone_architecture'); // Adjust this based on your endpoint structure
	console.log("Cloning architecture at endpoint:", endpoint);

	try {
		const response = await Client(endpoint, payload, "POST"); // Use POST method for cloning architecture
		console.log(response, "Response from clone architecture");
		return response.data; // Return the response from the API
	} catch (error) {
		console.error('Error cloning architecture:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}
export async function editOrganizationApi(payload) {
	const endpoint = getEndpoint('Organization', 'edit_organization'); // Adjust this based on your endpoint structure
	console.log("Editing organization at endpoint:", endpoint);

	try {
		const response = await Client(endpoint, payload, "POST"); // Use PUT method for editing organization
		console.log(response, "Response from edit organization");
		return response.data; // Return the response from the API
	} catch (error) {
		console.error('Error editing organization:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function getAllMethodsApi(payload) {
	const endpoint = getEndpoint('Organization', 'get_all_methods'); // Adjust this based on your endpoint structure
	console.log("Fetching methods from endpoint:", endpoint);

	try {
		const response = await Client(endpoint, payload, "POST"); // Use POST method for fetching methods
		console.log(response, "Response from get all methods");
		return response.data; // Return the response from the API
	} catch (error) {
		console.error('Error fetching methods:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function getAllRulesApi(payload) {
	const endpoint = getEndpoint('Organization', 'get_all_rules'); // Adjust this based on your endpoint structure
	console.log("Fetching rules from endpoint:", endpoint);

	try {
		const response = await Client(endpoint, payload, "POST"); // Use POST method for fetching rules
		console.log(response, "Response from get all rules");
		return response.data; // Return the response from the API
	} catch (error) {
		console.error('Error fetching rules:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}
//PS_34
export async function getAllRulesApi2(payload) {
	const endpoint = getEndpoint('b2a_services', 'get_all_rules'); // Adjust this based on your endpoint structure
	console.log("Fetching rules from endpoint:", endpoint);

	try {
		const response = await Client(endpoint, payload, "POST"); // Use POST method for fetching rules
		console.log(response, "Response from get all rules");
		return response.data; // Return the response from the API
	} catch (error) {
		console.error('Error fetching rules:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function getArchTypeByAiApi(payload) {
	const endpoint = getEndpoint('Organization', 'get_arch_type_by_ai'); // Adjust this based on your endpoint structure
	console.log("Fetching architecture type by AI from endpoint:", endpoint);

	try {
		const response = await Client(endpoint, payload, "POST"); // Use POST method for fetching architecture types
		console.log(response, "Response from get arch type by AI");
		return response.data; // Return the response from the API
	} catch (error) {
		console.error('Error fetching architecture type by AI:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function getAllRepoApi(payload) {
	const endpoint = getEndpoint('Organization', 'get_all_repo'); // Adjust this based on your endpoint structure
	console.log("Fetching repositories from endpoint:", endpoint);

	try {
		const response = await Client(endpoint, payload, "POST"); // Use POST method for fetching repositories
		console.log(response, "Response from get all repo");
		return response.data; // Return the response from the API
	} catch (error) {
		console.error('Error fetching repositories:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function deleteRepositoryApi(payload) {
	const endpoint = getEndpoint('Organization', 'delete_repository'); // Adjust this based on your endpoint structure
	console.log("Deleting repository at endpoint:", endpoint);

	try {
		const response = await Client(endpoint, payload, "POST"); // Use DELETE method for deleting a repository
		console.log(response, "Response from delete repository");
		return response.data; // Return the response from the API
	} catch (error) {
		console.error('Error deleting repository:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function getAllRolesApi(payload) {
	const endpoint = getEndpoint('Organization', 'get_all_roles'); // Adjust this based on your endpoint structure
	console.log("Fetching roles from endpoint:", endpoint);

	try {
		const response = await Client(endpoint, payload, "POST"); // Use POST method for fetching roles
		console.log(response, "Response from get all roles");
		return response.data; // Return the response from the API
	} catch (error) {
		console.error('Error fetching roles:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function getAllServicesApi(payload) {
	const endpoint = getEndpoint('b2a_services', 'get_all_services'); // Adjust this based on your endpoint structure
	console.log("Fetching services from endpoint:", endpoint);

	try {
		const response = await Client(endpoint, payload, "POST"); // Use POST method for fetching services
		console.log(response, "Response from get all services");
		return response.data; // Return the response from the API
	} catch (error) {
		console.error('Error fetching services:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}
//PS_33
export async function getAllRuleCategoryApi(payload) {
	const endpoint = getEndpoint('Organization', 'get_all_rule_category'); // Adjust this based on your endpoint structure
	console.log("Fetching rule categories from endpoint:", endpoint);

	try {
		const response = await Client(endpoint, payload, "POST"); // Use POST method for fetching rule categories
		console.log(response, "Response from get all rule categories");
		return response.data; // Return the response from the API
	} catch (error) {
		console.error('Error fetching rule categories:', error);
		throw error; // Rethrow or handle the error as necessary
	}
	}
	export async function getAllConnectorDetailsApi(payload) {
		const endpoint = getEndpoint('Organization', 'get_all_connector_details'); // Adjust this based on your endpoint structure
		console.log("Fetching connector details from endpoint:", endpoint);

		try {
			const response = await Client(endpoint, payload, "POST"); // Use POST method for fetching connector details
			console.log(response, "Response from get all connector details");
			return response.data; // Return the response from the API
		} catch (error) {
			console.error('Error fetching connector details:', error);
			throw error; // Rethrow or handle the error as necessary
		}
	}

	export async function createAwsConnectorApi(payload) {
		const endpoint = getEndpoint('cost_management', 'create_aws_connector'); // Adjust this based on your endpoint structure
		console.log("Creating AWS connector at endpoint:", endpoint);

		try {
			const response = await Client(endpoint, payload, "POST"); // Use POST method for creating a connector
			console.log(response, "Response from create AWS connector");
			return response.data; // Return the response from the API
		} catch (error) {
			console.error('Error creating AWS connector:', error);
			throw error; // Rethrow or handle the error as necessary
		}
	}

	export async function testAwsConnectorApi(payload) {
		const endpoint = getEndpoint('cost_management', 'test_aws_connector'); // Adjust this based on your endpoint structure
		console.log("Testing AWS connector at endpoint:", endpoint);

		try {
			const response = await Client(endpoint, payload, "POST"); // Use POST method for testing the connector
			console.log(response, "Response from test AWS connector");
			return response.data; // Return the response from the API
		} catch (error) {
			console.error('Error testing AWS connector:', error);
			throw error; // Rethrow or handle the error as necessary
		}
	}

	export async function updateAwsConnectorApi(payload) {
		const endpoint = getEndpoint('cost_management', 'update_aws_connector'); // Adjust this based on your endpoint structure
		console.log("Updating AWS connector at endpoint:", endpoint);

		try {
			const response = await Client(endpoint, payload, "POST"); // Use PUT method for updating a connector
			console.log(response, "Response from update AWS connector");
			return response.data; // Return the response from the API
		} catch (error) {
			console.error('Error updating AWS connector:', error);
			throw error; // Rethrow or handle the error as necessary
		}
	}

	export async function deleteConnectorApi(payload) {
		const endpoint = getEndpoint('cost_management', 'delete_connector'); // Adjust this based on your endpoint structure
		console.log("Deleting connector at endpoint:", endpoint);

		try {
			const response = await Client(endpoint, payload, "POST"); // Use DELETE method for deleting a connector
			console.log(response, "Response from delete connector");
			return response.data; // Return the response from the API
		} catch (error) {
			console.error('Error deleting connector:', error);
			throw error; // Rethrow or handle the error as necessary
		}
	}

export async function getAllInvoicesApi(payload) {
	const endpoint = getEndpoint('cost_management', 'get_all_invoices'); // Adjust this based on your endpoint structure
	console.log("Fetching invoices from endpoint:", endpoint);

	try {
		const response = await Client(endpoint, payload, "POST"); // Use POST method for fetching invoices
		console.log(response, "Response from get all invoices");
		return response.data; // Return the response from the API
	} catch (error) {
		console.error('Error fetching invoices:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}
export async function getAllEnvThresholdApi(payload) {
	const endpoint = getEndpoint('cost_management', 'get_all_env_threshold'); // Adjust this based on your endpoint structure
	console.log("Fetching environment thresholds from endpoint:", endpoint);

	try {
		const response = await Client(endpoint, payload, "POST"); // Use POST method for fetching environment thresholds
		console.log(response, "Response from get all env thresholds");
		return response.data; // Return the response from the API
	} catch (error) {
		console.error('Error fetching environment thresholds:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function getAllProjectThresholdApi(payload) {
	const endpoint = getEndpoint('cost_management', 'get_all_project_threshold'); // Adjust this based on your endpoint structure
	console.log("Fetching project thresholds from endpoint:", endpoint);

	try {
		const response = await Client(endpoint, payload, "POST"); // Use POST method for fetching project thresholds
		console.log(response, "Response from get all project thresholds");
		return response.data; // Return the response from the API
	} catch (error) {
		console.error('Error fetching project thresholds:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function deleteBudgetApi(payload) {
	const endpoint = getEndpoint('cost_management', 'delete_budget'); // Adjust this based on your endpoint structure
	console.log("Deleting budget at endpoint:", endpoint);

	try {
		const response = await Client(endpoint, payload, "POST"); // Use DELETE method for deleting a budget
		console.log(response, "Response from delete budget");
		return response.data; // Return the response from the API
	} catch (error) {
		console.error('Error deleting budget:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}
//PS_30
export async function createRulesApi(payload) {
	const endpoint = getEndpoint('b2a_services', 'create_rules'); // Adjust this based on your endpoint structure
	console.log("Creating rules at endpoint:", endpoint);

	try {
		const response = await Client(endpoint, payload, "POST"); // Use POST method for creating rules
		console.log(response, "Response from create rules");
		return response.data; // Return the response from the API
	} catch (error) {
		console.error('Error creating rules:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}
//PS_32
export async function deleteRulesApi(payload) {
	const endpoint = getEndpoint('b2a_services', 'delete_rules'); // Adjust this based on your endpoint structure
	console.log("Deleting rule at endpoint:", endpoint);

	try {
		const response = await Client(endpoint, payload, "POST"); // Use DELETE method for deleting a rule
		console.log(response, "Response from delete rules");
		return response.data; // Return the response from the API
	} catch (error) {
		console.error('Error deleting rule:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}
//PS_31
export async function updateRulesApi(payload) {
	const endpoint = getEndpoint('b2a_services', 'update_rules'); // Adjust this based on your endpoint structure
	console.log("Updating rules at endpoint:", endpoint);

	try {
		const response = await Client(endpoint, payload, "POST"); // Use PUT method for updating rules
		console.log(response, "Response from update rules");
		return response.data; // Return the response from the API
	} catch (error) {
		console.error('Error updating rules:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}
export async function getAllAwsServicesApi(payload) {
	const endpoint = getEndpoint('Dashboard', 'get_all_inventory_services'); // Adjust this based on your endpoint structure
	console.log("Fetching AWS services from endpoint:", endpoint);

	try {
		const response = await Client(endpoint, payload, "POST"); // Use POST method for fetching AWS services
		console.log(response, "Response from get all AWS services");
		return response.data; // Return the response from the API
	} catch (error) {
		console.error('Error fetching AWS services:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function getAllAzureServicesApi(payload) {
	const endpoint = getEndpoint('Organization', 'get_all_azure_services'); // Adjust this based on your endpoint structure
	console.log("Fetching Azure services from endpoint:", endpoint);

	try {
		const response = await Client(endpoint, payload, "POST"); // Use POST method for fetching Azure services
		console.log(response, "Response from get all Azure services");
		return response.data; // Return the response from the API
	} catch (error) {
		console.error('Error fetching Azure services:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function searchUsersApi(payload) {
	const endpoint = getEndpoint('workbench', 'searchUsers'); // Adjust this based on your endpoint structure
	console.log("Searching users at endpoint:", endpoint);

	try {
		const response = await Client(endpoint, payload, "POST"); // Use POST method for searching users
		console.log(response, "Response from search users");
		return response.data; // Return the response from the API
	} catch (error) {
		console.error('Error searching users:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function createAzureConnectorApi(payload) {
	const endpoint = getEndpoint('cost_management', 'create_azure_connector'); // Adjust this based on your endpoint structure
	console.log("Creating Azure connector at endpoint:", endpoint);

	try {
		const response = await Client(endpoint, payload, "POST"); // Use POST method for creating a connector
		console.log(response, "Response from create Azure connector");
		return response.data; // Return the response from the API
	} catch (error) {
		console.error('Error creating Azure connector:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function testAzureConnectorApi(payload) {
	const endpoint = getEndpoint('cost_management', 'test_azure_connector'); // Adjust this based on your endpoint structure
	console.log("Testing Azure connector at endpoint:", endpoint);

	try {
		const response = await Client(endpoint, payload, "POST"); // Use POST method for testing the connector
		console.log(response, "Response from test Azure connector");
		return response.data; // Return the response from the API
	} catch (error) {
		console.error('Error testing Azure connector:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function updateAzureConnectorApi(payload) {
	const endpoint = getEndpoint('cost_management', 'update_azure_connector'); // Adjust this based on your endpoint structure
	console.log("Updating Azure connector at endpoint:", endpoint);

	try {
		const response = await Client(endpoint, payload, "POST"); // Use PUT method for updating a connector
		console.log(response, "Response from update Azure connector");
		return response.data; // Return the response from the API
	} catch (error) {
		console.error('Error updating Azure connector:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

// API function for creating a repository and branch in Azure DevOps
export async function createRepoAndBranch(payload) {
	const endpoint = getEndpoint('Organization', 'add_repository'); // Adjust this based on your endpoint structure
	console.log("Creating Azure repo and branch at endpoint:", endpoint);

	try {
		const response = await Client(endpoint, payload, "POST");
		console.log(response, "Response from create Azure repo and branch");
		return response.data; // Return the response from the API
	} catch (error) {
		console.error('Error creating Azure repo and branch:', error);
		throw error; 
	}
}

export async function getResourceGroupApi(payload) {
	const endpoint = getEndpoint('Dashboard', 'getResourceGroup'); // Adjust this based on your endpoint structure
	console.log("Fetching resource groups at endpoint:", endpoint);

	try {
		const response = await Client(endpoint, payload, "POST"); // Use POST method for fetching resource groups
		console.log(response, "Response from get resource groups");
		return response.data; // Return the response from the API
	} catch (error) {
		console.error('Error fetching resource groups:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

// API function for fetching workspace details in Azure
export async function getWorkspaceAzureApi(payload) {
	const endpoint = getEndpoint('Dashboard', 'getWorkspaceAzure'); // Adjust this based on your endpoint structure
	console.log("Fetching Azure workspace at endpoint:", endpoint);

	try {
		const response = await Client(endpoint, payload, "POST"); // Use POST method for fetching workspace
		console.log(response, "Response from get Azure workspace");
		return response.data; // Return the response from the API
	} catch (error) {
		console.error('Error fetching Azure workspace:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

// API function for fetching AWS workspace details
export async function getWorkspaceAwsApi(payload) {
	const endpoint = getEndpoint('Dashboard', 'getWorkspaceAws'); // Adjust this based on your endpoint structure
	console.log("Fetching AWS workspace at endpoint:", endpoint);

	try {
		const response = await Client(endpoint, payload, "POST"); // Use POST method for fetching workspace
		console.log(response, "Response from get AWS workspace");
		return response.data; // Return the response from the API
	} catch (error) {
		console.error('Error fetching AWS workspace:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

// API function for fetching AWS compute details
export async function getComputeAwsApi(payload) {
	const endpoint = getEndpoint('Dashboard', 'getComputeAws'); // Adjust this based on your endpoint structure
	console.log("Fetching AWS compute at endpoint:", endpoint);

	try {
		const response = await Client(endpoint, payload, "POST"); // Use POST method for fetching compute details
		console.log(response, "Response from get AWS compute");
		return response.data; // Return the response from the API
	} catch (error) {
		console.error('Error fetching AWS compute:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

// API function for fetching all workloads by organization ID
export async function getAllWorkloadByOrgIdApi(payload) {
	const endpoint = getEndpoint('Organization', 'get_all_workload_by_org_id'); // Adjust this based on your endpoint structure
	console.log("Fetching workloads at endpoint:", endpoint);

	try {
		const response = await Client(endpoint, payload, "POST"); // Use POST method for fetching workloads
		console.log(response, "Response from get all workloads");
		return response.data; // Return the response from the API
	} catch (error) {
		console.error('Error fetching workloads:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

// API function for fetching all environments by workload ID
export async function getAllEnvByWorkloadIdApi(payload) {
	const endpoint = getEndpoint('cost_management', 'get_all_env_by_workload_id'); // Adjust this based on your endpoint structure
	// const endpoint = "http://127.0.0.1:8080/cost_management/get_all_env_by_workload_id" // Adjust this based on your endpoint structure
	console.log("Fetching environments at endpoint:", endpoint);

	try {
		const response = await Client(endpoint, payload, "POST"); // Use POST method for fetching environments
		console.log(response, "Response from get all environments");
		return response.data; // Return the response from the API
	} catch (error) {
		console.error('Error fetching environments:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

// API function for fetching all organizations
export async function getAllOrgApi(payload) {
	const endpoint = getEndpoint('Organization', 'get_all_org'); // Adjust this based on your endpoint structure
	console.log("Fetching organizations at endpoint:", endpoint);

	try {
		const response = await Client(endpoint, payload, "POST"); // Use POST method for fetching organizations
		console.log(response, "Response from get all organizations");
		return response.data; // Return the response from the API
	} catch (error) {
		console.error('Error fetching organizations:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

// API function for validating SCM credentials
export async function validateScmCredsApi(payload) {
	const endpoint = getEndpoint('Organization', 'validate_scm_creds'); // Adjust this based on your endpoint structure
	console.log("Validating SCM credentials at endpoint:", endpoint);

	try {
		const response = await Client(endpoint, payload, "POST"); // Use POST method for validating credentials
		console.log(response, "Response from validate SCM credentials");
		return response.data; // Return the response from the API
	} catch (error) {
		console.error('Error validating SCM credentials:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

// API function for fetching Azure compute details
export async function getComputeAzureApi(payload) {
	const endpoint = getEndpoint('Dashboard', 'getComputeAzure'); // Adjust this based on your endpoint structure
	console.log("Fetching Azure compute at endpoint:", endpoint);

	try {
		const response = await Client(endpoint, payload, "POST"); // Use POST method for fetching compute details
		console.log(response, "Response from get Azure compute");
		return response.data; // Return the response from the API
	} catch (error) {
		console.error('Error fetching Azure compute:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

// API function for setting the threshold
export async function setThresholdApi(payload) {
	const endpoint = getEndpoint('estimation', 'set_threshold'); // Adjust this based on your endpoint structure
	console.log("Setting threshold at endpoint:", endpoint);

	try {
		const response = await Client(endpoint, payload, "POST"); // Use POST method for setting the threshold
		console.log(response, "Response from set threshold");
		return response.data; // Return the response from the API
	} catch (error) {
		console.error('Error setting threshold:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

// API function for updating dark theme preference
export async function updateDarkThemeApi(payload) {
	const endpoint = getEndpoint('Organization', 'update_dark_theme'); // Adjust this based on your endpoint structure
	console.log("Updating dark theme preference at endpoint:", endpoint);

	try {
		const response = await Client(endpoint, payload, "POST"); // Use POST method for updating dark theme
		console.log(response, "Response from update dark theme");
		return response.data; // Return the response from the API
	} catch (error) {
		console.error('Error updating dark theme preference:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

// API function for editing a repository
export async function editRepositoryApi(payload) {
	const endpoint = getEndpoint('Organization', 'edit_repository'); // Adjust this based on your endpoint structure
	console.log("Editing repository at endpoint:", endpoint);

	try {
		const response = await Client(endpoint, payload, "POST"); // Use POST method for editing repositories
		console.log(response, "Response from edit repository");
		return response.data; // Return the response from the API
	} catch (error) {
		console.error('Error editing repository:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

// API function for creating a workload
export async function createWorkloadApi(payload) {
	const endpoint = getEndpoint('Organization', 'create_workload'); // Adjust this based on your endpoint structure
	console.log("Creating workload at endpoint:", endpoint);

	try {
		const response = await Client(endpoint, payload, "POST"); // Use POST method for creating workloads
		console.log(response, "Response from create workload");
		return response.data; // Return the response from the API
	} catch (error) {
		console.error('Error creating workload:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function updateWorkloadApi(payload) {
	const endpoint = getEndpoint('Organization', 'update_workload'); // Adjust this based on your endpoint structure
	console.log("Updating workload at endpoint:", endpoint);

	try {
		const response = await Client(endpoint, payload, "POST"); // Use PUT method for updating workloads
		console.log(response, "Response from update workload");
		return response.data; // Return the response from the API
	} catch (error) {
		console.error('Error updating workload:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export const getWorkloadDetailsById = async (payload) => {
	const endpoint = getEndpoint('b2a_services','get_workload_details_by_id'); // Adjust based on your API base URL
	try {
		const response = await Client(endpoint, payload, "POST"); 
		return response.data; // Return the data from the response
	} catch (error) {
		console.error('Error fetching workload details:', error);
		throw error; // Rethrow error for handling in the component
	}
};




// keerthana code 


export async function getCostByPeriod(payload) {
	const endpoint = getEndpoint('Dashboard', 'GetCostByPeriod'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response


	} catch (error) {
		console.error('Error getting user logs:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function getUniqueTagsByOrg(payload) {
	const endpoint = getEndpoint('Organization', 'GetUniqueTagsByOrg'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response


	} catch (error) {
		console.error('Error getting user logs:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}


export async function syncNowAzure(payload) {
	const endpoint = getEndpoint('Organization', 'syncNowAzure'); 
	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response


	} catch (error) {
		console.error('Error getting user logs:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function syncNowAws(payload) {
	const endpoint = getEndpoint('Organization', 'syncNowAws'); 

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response


	} catch (error) {
		console.error('Error getting user logs:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}


export async function syncNowAzureInventory(payload) {
	const endpoint = getEndpoint('Organization', 'syncNowAzureInventory'); 
	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response
	} catch (error) {
		console.error('Error getting user logs:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function syncNowAwsInventory(payload) {
	const endpoint = getEndpoint('Organization', 'syncNowAwsInventory'); 

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response


	} catch (error) {
		console.error('Error getting user logs:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}



export async function getAllConnectors(payload) {
	const endpoint = getEndpoint('Organization', 'GetAllConnectors'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response


	} catch (error) {
		console.error('Error getting user logs:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}


export async function cicdDeployment(payload) {
	const endpoint = getEndpoint('CICD', 'CicdDeploy'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response


	} catch (error) {
		console.error('Error performing CICD Deployment:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function cicdCommit(payload) {
	const endpoint = getEndpoint('CICD', 'CicdCommit'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response.data.body


	} catch (error) {
		console.error('Error performing CICD Commit:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function getAllBranches(payload) {
	const endpoint = getEndpoint('CICD', 'Branch'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response.data


	} catch (error) {
		console.error('Error fetching branches:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function createArticle(payload) {
	const endpoint = getEndpoint('Article', 'CreateArticle'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response


	} catch (error) {
		console.error('Error creating article:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function getAllArticles(payload) {
	const endpoint = getEndpoint('Article', 'GetAllArticle'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response


	} catch (error) {
		console.error('Error getting article:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function updateArticle(payload) {
	const endpoint = getEndpoint('Article', 'EditArticle'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response


	} catch (error) {
		console.error('Error editing article:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function deleteArticle(payload) {
	const endpoint = getEndpoint('Article', 'DeleteArticle'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response


	} catch (error) {
		console.error('Error deleting article:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function searchArticle(payload) {
	const endpoint = getEndpoint('Article', 'SearchArticle'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response


	} catch (error) {
		console.error('Error searching article:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function getArticleDetail(payload) {
	const endpoint = getEndpoint('Article', 'GetArticleDetail'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response


	} catch (error) {
		console.error('Error getting article detail:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function createTemplateKb(payload) {
	const endpoint = getEndpoint('TemplateKB', 'CreateTemplate'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response


	} catch (error) {
		console.error('Error getting article detail:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function updateTemplateKb(payload) {
	const endpoint = getEndpoint('TemplateKB', 'UpdateTemplate'); // Corrected endpoint retrieval

	console.log(payload, "updatesssssssssssssssssssssssssssssssssssssssssssssss")

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response


	} catch (error) {
		console.error('Error getting article detail:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function deleteTemplateKb(payload) {
	const endpoint = getEndpoint('TemplateKB', 'DeleteTemplate'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response


	} catch (error) {
		console.error('Error getting deleting template kb:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function getAllTemplateKb(payload) {
	const endpoint = getEndpoint('TemplateKB', 'GetAllTemplate'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response


	} catch (error) {
		console.error('Error getting article detail:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function getArchiTypeByAi(payload) {
	const endpoint = getEndpoint('TemplateKB', 'GetArchiTypeByAi'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response


	} catch (error) {
		console.error('Error getting article detail:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}


export async function getAllProviders(payload) {
	const endpoint = getEndpoint('Organization', 'GetAllProviders'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response


	} catch (error) {
		console.error('Error getting providers:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function getAllOrganization(payload) {
	const endpoint = getEndpoint('Organization', 'getAllOrganization'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response


	} catch (error) {
		console.error('Error getting organization:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function getAllRoles(payload) {
	const endpoint = getEndpoint('Organization', 'GetAllRoles'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response


	} catch (error) {
		console.error('Error getting roles:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function getAllWorkloadsByOrgId(payload) {
	const endpoint = getEndpoint('Organization', 'get_all_workload_by_org_id'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response


	} catch (error) {
		console.error('Error getting workloads:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function createUserRole(payload) {
	const endpoint = getEndpoint('Users', 'CreateUser'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response


	} catch (error) {
		console.error('Error getting workloads:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function EditUserRole(payload) {
	const endpoint = getEndpoint('Users', 'EditUser'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response


	} catch (error) {
		console.error('Error getting workloads:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function DeleteUserRole(payload) {
	const endpoint = getEndpoint('Users', 'DeleteUser'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response


	} catch (error) {
		console.error('Error getting workloads:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function ActivateUserRole(payload) {
	const endpoint = getEndpoint('Users', 'ActivateUser'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response


	} catch (error) {
		console.error('Error getting workloads:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function GetAllUsers(payload) {
	const endpoint = getEndpoint('Users', 'GetAllUser'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response


	} catch (error) {
		console.error('Error getting workloads:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function getActivityLogs(payload) {
	const endpoint = getEndpoint('Users', 'ActivityLogs'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response


	} catch (error) {
		console.error('Error getting user logs:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function getCostByWorkloads(payload) {
	const endpoint = getEndpoint('Dashboard', 'GetCostByWorkloads'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response


	} catch (error) {
		console.error('Error getting user logs:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function getCostByProvider(payload) {
	const endpoint = getEndpoint('Dashboard', 'GetCostByProviders'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response


	} catch (error) {
		console.error('Error getting user logs:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function getCostByTags(payload) {
	const endpoint = getEndpoint('Dashboard', 'GetCostByTags'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response


	} catch (error) {
		console.error('Error getting user logs:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}


export async function getCostByRegion(payload) {
	const endpoint = getEndpoint('Dashboard', 'GetCostByRegion'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response


	} catch (error) {
		console.error('Error getting user logs:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}


export async function getCostByService(payload) {
	const endpoint = getEndpoint('Dashboard', 'GetCostByService'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response


	} catch (error) {
		console.error('Error getting user logs:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

// export async function getPipelineData(payload) {
// 	const endpoint = getEndpoint('Dashboard', 'GetPipelineData');
// 	try {
// 	  const response = await Client(endpoint, payload, "POST");
// 	  return {
// 		success: response.data.body.success,
// 		data: response.data.body.data
// 	  };
// 	} catch (error) {
// 	  console.error('Error getting pipeline data:', error);
// 	  return { success: false, data: [] };
// 	}
//   }
  
//   export async function getCommitData(payload) {
// 	const endpoint = getEndpoint('Dashboard', 'GetCommitData');
// 	try {
// 	  const response = await Client(endpoint, payload, "POST");
// 	  return {
// 		success: response.data.success,
// 		data: response.data.data
// 	  };
// 	} catch (error) {
// 	  console.error('Error getting commit data:', error);
// 	  return { success: false, data: [] };
// 	}
//   }
  



export async function getPipelineData(payload) {
    const endpoint = getEndpoint('Dashboard', 'GetPipelineData');

    try {
        const response = await Client(endpoint, payload, "POST");
        
        if (response.data && response.data.body && response.data.body.data) {
            const flattenedData = response.data.body.data.reduce((acc, item) => {
                if (Array.isArray(item)) {
                    return [...acc, ...item];
                }
                return acc;
            }, []);

            return {
                success: true,
                data: flattenedData
            };
        } else {
            console.error("Pipeline API error: Unexpected response structure", response.data);
            return {
                success: false,
                data: [],
                message: "Failed to fetch pipeline data: Unexpected response structure"
            };
        }
    } catch (error) {
        console.error('Error getting pipeline data:', error);
        return {
            success: false,
            data: [],
            message: "An error occurred while fetching pipeline data"
        };
    }
}

export async function getCommitData(payload) {
    const endpoint = getEndpoint('Dashboard', 'GetCommitData');

    try {
        const response = await Client(endpoint, payload, "POST");

        if (response.data && response.data.success) {
            return {
                success: true,
                data: response.data.data
            };
        } else {
            console.error("Commit API error:", response.data);
            return {
                success: false,
                data: [],
                message: response.data.message || "Failed to fetch commit data"
            };
        }
    } catch (error) {
        console.error('Error getting commit data:', error);
        if (error.response) {
            console.error('Error response:', error.response.data);
            console.error('Error status:', error.response.status);
            console.error('Error headers:', error.response.headers);
        }
        return {
            success: false,
            data: [],
            message: "An error occurred while fetching commit data"
        };
    }
}
   
export async function getIACCoverage(payload) {
	const endpoint = getEndpoint('Dashboard', 'GetIACCoverage'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response


	} catch (error) {
		console.error('Error getting user logs:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function getAccountCount(payload) {
	const endpoint = getEndpoint('Dashboard', 'GetAccountCount'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response


	} catch (error) {
		console.error('Error getting user logs:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function getOverAllCost(payload) {
	const endpoint = getEndpoint('Dashboard', 'GetOverAllCost'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response


	} catch (error) {
		console.error('Error getting user logs:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

// harideesha code 


export async function fetchAllService(payload) {
	const endpoint = getEndpoint('SelfService', 'getAllService'); 
	let result = await Client(endpoint,payload,"POST");; 
		return result
	}

	export async function deleteServiceRequest(payload) {
		const endpoint = getEndpoint('SelfService', 'deleteService'); 
		let result = await Client(endpoint,payload,"POST");; 
			return result
		}


	export async function postServiceDetails(payload) {
		const endpoint = getEndpoint('SelfService', 'createService'); 
		let result = await Client(endpoint,payload,"POST");; 
			return result
		}


			export async function updateServiceData(payload) {
				const endpoint = getEndpoint('SelfService', 'updateServiceData'); 
				let result = await Client(endpoint,payload,"POST");; 
					return result
				}

	export async function fetchCategoryData(payload) {
		const endpoint = getEndpoint('SelfService', 'getAllCategory'); 
	let result = await Client(endpoint,payload,"POST");; 
		return result
			}




			export async function fetchOverViewCardData(payload) {
				const endpoint = getEndpoint('Databricks', 'getOverViewCardData'); 
				let result = await Client(endpoint,payload,"POST");; 
					return result
				}


				export async function fetchDbuUtilChartData(payload) { 
					const endpoint = getEndpoint('Databricks', 'getDbuUtilization'); 
					let result = await Client(endpoint,payload,"POST");; 
					return result; 
				}

				export async function fetchTrendChartData(payload) { 
					const endpoint = getEndpoint('Databricks', 'getTrendsForDataBricks'); 
					let result = await Client(endpoint,payload,"POST");; 
					return result; 
				}

				export async function fetchUtilizationGrid(payload) { 
					const endpoint = getEndpoint('Databricks', 'getGridDatabricks'); 
					let result = await Client(endpoint,payload,"POST");; 
					return result; 
				}

				export async function fetchWorkspace(payload) { 
					const endpoint = getEndpoint('Databricks', 'getWorkspaceData'); 
					let result = await Client(endpoint,payload,"POST");; 
					return result; 
				}





				// export async function postErrorLog(errorData) {
				// let result = await client("POST","userService/postErrorLog", errorData)
				// return result;
				// }

				export async function fetchAllInsightData(payload) {
					const endpoint = getEndpoint('Insights', 'getAllInsights');
						let result = await Client(endpoint,payload,"POST");;
						return result
					}

					export async function fetchAllInsightById(payload) {
						const endpoint = getEndpoint('Insights', 'getAllInsightsById');
							let result = await Client(endpoint,payload,"POST");;
							return result
						}


						export async function postLoginDetails(payload) {
							console.log(payload,"This is payload for the data to be login")
							const endpoint = getEndpoint('Login', 'login'); 
								let result = await Client(endpoint,payload,"POST");;
								return result
							}


export async function fetchApprovalData(payload) {
	const endpoint = getEndpoint('Documentation', 'getAllApproval'); 
	let result = await Client(endpoint,payload,"POST");; 
		return result
	}


	export async function fetchAllNotifications(payload) {
		const endpoint = getEndpoint('Notifications', 'getAllNotifications'); 
		let result = await Client(endpoint,payload,"POST");; 
			return result
		}

		export async function updateNotification(payload) {
			const endpoint = getEndpoint('Notifications', 'updateNotification'); 
			let result = await Client(endpoint,payload,"POST");; 
				return result
			}

			export async function fetchAllCloudProvider(payload) {
				const endpoint = getEndpoint('Organization', 'GetAllProviders'); 
				let result = await Client(endpoint,payload,"POST");; 
					return result
				}

				export async function fetchAllOrganization(payload) {

					const endpoint = getEndpoint('Organization', 'get_all_org'); 

					let result = await Client(endpoint,payload,"POST");; 

						return result
					}

		// getAllProvider:"/organization/get_all_provider",
		// getAllOrganization:"/organization/get_all_org"


			export async function fetchTemplates(payload) {
				const endpoint = getEndpoint('Templates', 'getAllTemplates');
				let result = await Client(endpoint,payload,"POST");;
				return result
			}

			export async function fetchZebPulseTemplates(payload) {
				const endpoint = getEndpoint('Templates', 'getAllZebPulseTemplates');
				let result = await Client(endpoint,payload,"POST");;
				return result
			}

			export async function approveTemplates(payload) {
				const endpoint = getEndpoint('Templates', 'approveTemplate');
				let result = await Client(endpoint,payload,"POST");;
				return result
			}

			export async function deleteTemplates(payload) {
				const endpoint = getEndpoint('Templates', 'deleteTemplate');
				let result = await Client(endpoint,payload,"POST");;
				return result
			}

			export async function cloneTemplates(payload) {
				const endpoint = getEndpoint('Templates', 'cloneTemplate');
				let result = await Client(endpoint,payload,"POST");;
				return result
			}

			// export async function editTemplates(payload) {
			// const endpoint = getEndpoint('Templates', 'editTemplate');
			// let result = await Client(endpoint,payload,"POST");;
			// return result
			// }

			export async function approveZebPulseTemplates(payload) {
				const endpoint = getEndpoint('Templates', 'approveZebPulseTemplate');
				let result = await Client(endpoint,payload,"POST");;
				return result
			}

			export async function deleteZebPulseTemplates(payload) {
				const endpoint = getEndpoint('Templates', 'deleteZebPulseTemplate');
				let result = await Client(endpoint,payload,"POST");;
				return result
			}

			export async function cloneZebPulseTemplates(payload) {
				const endpoint = getEndpoint('Templates', 'cloneZebPulseTemplate');
				let result = await Client(endpoint,payload,"POST");;
				return result
			}

			// export async function editZebPulseTemplates(payload) {
			// const endpoint = getEndpoint('Templates', 'editZebPulseTemplate');
			// let result = await Client(endpoint,payload,"POST");;
			// return result
			// }

			// export async function getAllOrganization(payload) {
			// const endpoint = getEndpoint('Templates', 'editZebPulseTemplate');
			// let result = await Client(endpoint,payload,"POST");;
			// return result
			// }


			export async function fetchAllWorkload(payload) {
				const endpoint = getEndpoint('Templates', 'workload');
				let result = await Client(endpoint,payload,"POST");;
				return result
			}

			export async function fetchAllEnvironment(payload) {
				const endpoint = getEndpoint('Templates', 'environment');
				let result = await Client(endpoint,payload,"POST");;
				return result
			}


			export async function fetchARMCode(payload) {
				const endpoint = getEndpoint('Deploy', 'getArmCode');
				let result = await Client(endpoint,payload,"POST");;
				return result
				}

				export async function fetchCfCode(payload) {
					const endpoint = getEndpoint('Deploy', 'getCloudFormationCode');
					let result = await Client(endpoint,payload,"POST");;
					return result
				}

				export async function fetchTfCode(payload) {
					const endpoint = getEndpoint('Deploy', 'getTerraformCode');
					let result = await Client(endpoint,payload,"POST");;
					return result
				}

				export async function requestForDeploy(payload) {
					const endpoint = getEndpoint('Deploy', 'requestForDeploy');
					let result = await Client(endpoint,payload,"POST");;
					return result
				}

				export async function approveDeploy(payload) {
					const endpoint = getEndpoint('Deploy', 'approveDeploy');
					let result = await Client(endpoint,payload,"POST");;
					return result
				}

				export async function deploytfcode(payload) {
					const endpoint = getEndpoint('Deploy', 'deploytfcode');
					let result = await Client(endpoint,payload,"POST");;
					return result
				}

				export async function deploycfcodeApi(payload) {
					const endpoint = getEndpoint('Deploy', 'deploycfcode');
					let result = await Client(endpoint,payload,"POST");;
					return result
				}

				export async function deployarmcodeApi(payload) {
					const endpoint = getEndpoint('Deploy', 'deployarmcode');
					let result = await Client(endpoint,payload,"POST");;
					return result
				}

				export async function fetchApproversList(payload) {
					const endpoint = getEndpoint('Estimation', 'ListApprovers');
					let result = await Client(endpoint,payload,"POST");;
					return result
				}


export async function fetchBranchingDetails(payload) {
	const endpoint = getEndpoint('BranchingStrategy', 'getBranchingDataStrategy');
		let result = await Client(endpoint,payload,"POST");;
		return result
	}



export async function fetchServiceReviewData(payload) {
	const endpoint = getEndpoint('Deploy', 'getAllServicesReview'); 
	console.log(endpoint)
	let result = await Client(endpoint,payload,"POST");; 
		return result
	}






export async function getTodayInrPrice(payload) {
	const endpoint = getEndpoint('Estimation', 'GetTodayInrPrice'); // Corrected endpoint retrieval

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "responses")
			return response


	} catch (error) {
		console.error('Error fetching INR price:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function getCostServices(payload) {
	let endpoint 
	if(payload["provider"] == "AZURE" || payload["provider"] == "Azure"){
		endpoint = getEndpoint('Estimation', 'GetAzureCost'); // Corrected endpoint retrieval
	} 
	else if(payload["provider"] == "AWS"){
		endpoint = getEndpoint('Estimation', 'GetAWSCost'); // Corrected endpoint retrieval
		// endpoint = "http://127.0.0.1:8080/estimation/get_aws_cost"
	} 

	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "Response in getCostServices")
			return response.data.body


	} catch (error) {
		console.error('Error fetching INR price:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}



export async function setThreshold(payload) {
	const endpoint = getEndpoint('Estimation', 'SetThreshold'); // Corrected endpoint retrieval
	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "Response in getCostServices")
			return response.data.body


	} catch (error) {
		console.error('Error fetching INR price:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function requestApproval(payload) {
	const endpoint = getEndpoint('Estimation', 'RequestApproval'); // Corrected endpoint retrieval
	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "Response in getCostServices")
			return response.data.body


	} catch (error) {
		console.error('Error fetching INR price:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

export async function listApprovers(payload) {
	const endpoint = getEndpoint('Estimation', 'ListApprovers'); // Corrected endpoint retrieval
	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "Response in getCostServices")
			return response.data.body


	} catch (error) {
		console.error('Error fetching INR price:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}
export async function rejectApprove(payload) {
	const endpoint = getEndpoint('Estimation', 'RejectApprove'); // Corrected endpoint retrieval
	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "Response in getCostServices")
			return response.data.body


	} catch (error) {
		console.error('Error fetching INR price:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

// 
// Workbench Endpoints
/**
	* Fetch Service Api Retreives the Service from the Self service configured!!
	*/
export async function fetchServicesApi(payload) {
	const endpoint = getEndpoint('workbench', 'fetchServicesApi'); // Corrected endpoint retrieval
	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "Response in getCostServices")
			return response.data.body


	} catch (error) {
		console.error('Error fetching INR price:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}
/**
	* Fetch Service Api Retreives the Service from the Self service configured!!
	*/
export async function fetchEnvironmentsAPI(payload) {
	const endpoint = getEndpoint('workbench', 'getAllRepoAndEnv'); // Corrected endpoint retrieval
	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "Response in getCostServices")
			return response.data.body


	} catch (error) {
		console.error('Error fetching INR price:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}
/**
	* getAllTagsApi Api Retreives the Service from the Self service configured!!
	*/
export async function getAllTagsApi(payload) {
	const endpoint = getEndpoint('workbench', 'getAllTags'); // Corrected endpoint retrieval
	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "Response in getAllTagsApi")
			return response.data.body


	} catch (error) {
		console.error('Error fetching INR price:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}
/**
	* updateTags Api Retreives the updates the Tags Configued
	*/
export async function updateTagsApi(payload) {
	const endpoint = getEndpoint('workbench', 'updateTags'); // Corrected endpoint retrieval
	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "Response in updateTagsApi")
			return response.data.body


	} catch (error) {
		console.error('Error fetching INR price:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}
/**
	* fetchRepositoriesApi Retreives the Repos and Env Based on the Workload!
	*/
export async function fetchRepositoriesApi(payload) {
	const endpoint = getEndpoint('workbench', 'getAllRepoAndEnv'); // Corrected endpoint retrieval
	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "Response in getCostServices")
			return response.data.body


	} catch (error) {
		console.error('Error fetching INR price:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}
/**
	* fetchRepositoriesApi Retreives the Repos and Env Based on the Workload!
	*/
export async function fetchRecommendationsApi(payload) {
	const endpoint = getEndpoint('workbench', 'getRecommendation'); // Corrected endpoint retrieval
	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "Response in getCostServices")
			return response.data


	} catch (error) {
		console.error('Error fetching INR price:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}

/**
	* Draggable Apis
	*/
// Commit Message Insertion API
export async function insertCommitAPI(payload) {
	const endpoint = getEndpoint('workbench', 'insertCommit'); // Corrected endpoint retrieval
	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "Response in insertCommit")
			return response.data.body


	} catch (error) {
		console.error('Error fetching INR price:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}
// Version History Retrieval Apis
export async function get_version_historyAPI(payload) {
	const endpoint = getEndpoint('workbench', 'getVersionHistory'); // Corrected endpoint retrieval
	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "Response in get_version_historyAPI")
			return response.data.body


	} catch (error) {
		console.error('Error fetching INR price:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}
// Account Retreival API
export async function getAccountDetailsApi(payload) {

	const endpoint = getEndpoint('Organization', 'get_all_connector_details'); // Corrected endpoint retrieval
	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "Response in getAccountDetailsApi")
			return response.data


	} catch (error) {
		console.error('Error fetching INR price:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}
// get Review DriftsApi API
export async function getReviewDriftsApi(payload) {

	const endpoint = getEndpoint('workbench', 'getVersionHistory'); // Corrected endpoint retrieval
	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "Response in getReviewDriftsApi")
			return response.data.body


	} catch (error) {
		console.error('Error fetching INR price:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}
// saveTemplate API
// This API saves the AWS/ Azure Template , this action is performed in the Workbench
export async function saveTemplateApi(payload) {

	const endpoint = getEndpoint('workbench', 'saveTemplate'); // Corrected endpoint retrieval
	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "Response in saveTemplateApi")
			return response.data.body


	} catch (error) {
		console.error('Error fetching INR price:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}
// save_pulse_template API
// This API saves the AWS/ Azure Pulse Template , this action is performed in the Workbench
export async function savePulseTemplateApi(payload) {

	const endpoint = getEndpoint('workbench', 'save_pulse_template'); // Corrected endpoint retrieval
	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "Response in savePulseTemplateApi")
			return response.data.body


	} catch (error) {
		console.error('Error fetching INR price:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}
// save Architecture API
//SQ_NO_3.7 This API Saves the Architecture in the repeated Basis 
export async function saveArchietctureApi(payload) {

	const endpoint = getEndpoint('workbench', 'saveArchietcture'); // Corrected endpoint retrieval
	try {
		//SQ_NO_3.19
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "Response in saveArchietctureApi")
			return response.data.body


	} catch (error) {
		console.error('Error fetching INR price:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}
// GetArchitectureByID API
//SQ_NO_1.43 This API Retreives the Architecture when the user navigated to the Workbench 
export async function getarchitectureByIDApi(payload) {

	const endpoint = getEndpoint('workbench', 'getarchitecture_byid'); // Corrected endpoint retrieval
	try {
		//SQ_NO_1.54
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "Response in getarchitectureByIDApi")
			////SQ_NO_ 1.57
			return response.data.body


	} catch (error) {
		console.error('Error fetching INR price:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}
// This API Retreives the Drifts when the user navigated to the Workbench 
export async function getDriftsApi(payload) {

	const endpoint = getEndpoint('workbench', 'getDrifts'); // Corrected endpoint retrieval
	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "Response in getDriftsApi")
			return response.data.body


	} catch (error) {
		console.error('Error fetching INR price:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}
/**
	* AI Architecture Generation API
	*/
export async function aiArchitectureGenerationApi(payload) {
	const endpoint = getEndpoint('Organization', 'generate_arch_with_ai'); // Corrected endpoint retrieval
	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "Response in aiArchitectureGenerationAPI")
			return response.data.body


	} catch (error) {
		console.error('Error fetching INR price:', error);
		throw error; // Rethrow or handle the error as necessary
	}

}
/**
	* Repo Workbench 
	*/
export async function retrieveBranchApi(payload) {
	const endpoint = getEndpoint('deploy', 'getAllBranches'); // Corrected endpoint retrieval
	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "Response in retrieveBranchApi")
			return response.data


	} catch (error) {
		console.error('Error fetching INR price:', error);
		throw error; // Rethrow or handle the error as necessary
	}

}
// PS_11 - PS_18
//Drifts Scenario which captures all the drifts 
export async function fetchdriftApi(payload) {
	let endpoint = getEndpoint('workbench', 'driftTerraform');
	if(payload.language == "Terraform"){
		endpoint = getEndpoint('workbench', 'driftTerraform'); 
	}// Corrected endpoint retrieval
	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "Response in fetchdriftApi")
			return response.data.body


	} catch (error) {
		console.error('Error fetching INR price:', error);
		throw error; // Rethrow or handle the error as necessary
	}

}




/**
	* B2A Service API Endpoints 
	*/

// Get Region Api Retrives the Region 
export async function get_all_regionApi(payload) {
	const endpoint = getEndpoint('b2a_services', 'get_all_region'); // Corrected endpoint retrieval
	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "Response in get_all_regionApi")
			return response.data


	} catch (error) {
		console.error('Error fetching INR price:', error);
		throw error; // Rethrow or handle the error as necessary
	}

}
// Get Region Api Retrives the Tags On the Region 
export async function get_all_tags_b2a_Api(payload) {
	const endpoint = getEndpoint('b2a_services', 'get_all_tags'); // Corrected endpoint retrieval
	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "Response in get_all_regionApi")
			return response.data


	} catch (error) {
		console.error('Error fetching INR price:', error);
		throw error; // Rethrow or handle the error as necessary
	}

}
// Get Region Api Retrives the Tags On the Region 
export async function get_all_resources_azure(payload) {
	const endpoint = getEndpoint('b2a_services', 'get_all_resources_azure'); // Corrected endpoint retrieval
	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "Response in get_all_resources_azureApi")
			return response.data


	} catch (error) {
		console.error('Error fetching INR price:', error);
		throw error; // Rethrow or handle the error as necessary
	}

}
// Get Region Api Retrives the get_all_fields On the GRID Component , Ie) Connectors 

export async function get_all_b2aApi(payload) {
	const endpoint = getEndpoint('b2a_services', 'get_all_b2a'); // Corrected endpoint retrieval
	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "Response in get_all_b2aAPi")
			return response.data


	} catch (error) {
		console.error('Error fetching INR price:', error);
		throw error; // Rethrow or handle the error as necessary
	}

}

// Get All Provider for the B2A Form

export async function get_all_ProviderApi(payload) {
	const endpoint = getEndpoint('Organization', 'get_all_provider'); // Corrected endpoint retrieval
	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "Response in get_all_ProviderApi")
			return response.data


	} catch (error) {
		console.error('Error fetching INR price:', error);
		throw error; // Rethrow or handle the error as necessary
	}

}

// Get all Connector for the B2A form 
export async function fetchConnectorsApi(payload) {
	const endpoint = getEndpoint('Organization', 'get_all_connector_details'); // Corrected endpoint retrieval
	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "Response in fetchConnectorsApi")
			return response.data


	} catch (error) {
		console.error('Error fetching INR price:', error);
		throw error; // Rethrow or handle the error as necessary
	}

}


// Save B2A is used to save the Form Component 
export async function saveb2Api(payload) {
	const endpoint = getEndpoint('b2a_services', 'save_B2a'); // Corrected endpoint retrieval
	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "Response in saveb2Api")
			return response.data


	} catch (error) {
		console.error('Error fetching INR price:', error);
		throw error; // Rethrow or handle the error as necessary
	}

}
// Save B2A is used to save the Form Component 
export async function fetchLocationApi(payload) {
	const endpoint = getEndpoint('b2a_services', 'get_all_location'); // Corrected endpoint retrieval
	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "Response in saveb2Api")
			return response.data


	} catch (error) {
		console.error('Error fetching INR price:', error);
		throw error; // Rethrow or handle the error as necessary
	}

}
// Save B2A is used to save the Form Component 
export async function fetchTagsAzureApi(payload) {
	const endpoint = getEndpoint('b2a_services', 'get_all_tags_azure'); // Corrected endpoint retrieval
	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "Response in saveb2Api")
			return response.data


	} catch (error) {
		console.error('Error fetching INR price:', error);
		throw error; // Rethrow or handle the error as necessary
	}

}
// Save B2A is used to save the Form Component 
export async function fetchAllVpcApi(payload) {
	const endpoint = getEndpoint('b2a_services', 'get_all_vpc'); // Corrected endpoint retrieval
	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "Response in fetchAllVpcApi")
			return response.data


	} catch (error) {
		console.error('Error fetching INR price:', error);
		throw error; // Rethrow or handle the error as necessary
	}

}


// AWS B2A Scenario Retrieval 

export async function get_all_resources_aws(payload) {
	let endpoint = getEndpoint('b2a_services', 'get_all_resources_aws');
	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "Response in get_all_resources_aws")
			return response.data


	} catch (error) {
		console.error('Error fetching INR price:', error);
		throw error; // Rethrow or handle the error as necessary
	}

}
// Generate ARM Content API 

export async function generateARMContentApi(payload) {
	let endpoint = getEndpoint('b2a_services', 'get_arm_iac');
	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "Response in generateARMContentApi")
			return response.data.body


	} catch (error) {
		console.error('Error fetching INR price:', error);
		throw error; // Rethrow or handle the error as necessary
	}

}
// Generate ARM Content API 
export async function generateTerraformContentApi(payload) {
	let endpoint = getEndpoint('b2a_services', 'generate_azureTerraformCode');
	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "Response in generateTerraformContentApi")
			return response.data


	} catch (error) {
		console.error('Error fetching INR price:', error);
		throw error; // Rethrow or handle the error as necessary
	}

}
// Generate ARM Content API 

export async function generateCloudFormationContentApi(payload) {
	let endpoint = getEndpoint('b2a_services', 'get_cf_iac');
	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "Response in generateCloudFormationContentApi")
			return response.data.body


	} catch (error) {
		console.error('Error fetching INR price:', error);
		throw error; // Rethrow or handle the error as necessary
	}

}
// Generate ARM Content API 
export async function generateAWSTerraformerApi(payload) {
	let endpoint = getEndpoint('b2a_services', 'generate_awsTerraformCode');
	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "Response in generateAWSTerraformerApi")
			return response.data.body


	} catch (error) {
		console.error('Error fetching INR price:', error);
		throw error; // Rethrow or handle the error as necessary
	}

}
// Clone the Architecture
export async function handleCloneSaveApi(payload) {
	let endpoint = getEndpoint('b2a_services', 'clone_b2a');
	try {
		const response = await Client(endpoint,payload,"POST");
			console.log(response , "Response in handleCloneSaveApi")
			return response.data.body


	} catch (error) {
		console.error('Error fetching INR price:', error);
		throw error; // Rethrow or handle the error as necessary
	}

}

// API function for fetching all organizations
export async function get_all_resourceGroupApi(payload) {
	const endpoint = getEndpoint('b2a_services', 'get_all_resource_grp'); // Adjust this based on your endpoint structure
	console.log("Fetching get_all_resourceGroupApi at endpoint:", endpoint);

	try {
		const response = await Client(endpoint, payload, "POST"); // Use POST method for fetching organizations
		console.log(response, "Response from get_all_resourceGroupApi");
		return response.data; // Return the response from the API
	} catch (error) {
		console.error('Error fetching organizations:', error);
		throw error; // Rethrow or handle the error as necessary
	}
}


export async function logOutUser(payload) {
	const endpoint = getEndpoint('Login', 'logout'); 
		let result = await Client(endpoint,payload,"POST");
		return result
	}


// Sankar Endpoints
export async function get_threshold_detailsApi(payload) {
	try {
		const endpoint = getEndpoint('estimation', 'get_threshold_details'); 
		let result = await Client(endpoint,payload,"POST");
		return result.data.body

	} catch (error) {
		console.log(error , "Error in the get_threshold_detailsApi")
		return error
	}
}
// Thiru Endpoint
export async function getEstimateDetailsApi(payload) {
	try {
		const endpoint = getEndpoint('workbench', 'getEstimateDetails'); 
		let result = await Client(endpoint,payload,"POST");
		console.log(result , "getEstimateDetailss")
		return result.data.body

	} catch (error) {
		console.log(error , "Error in the getEstimateDetailss")
		return error
	}
}

export async function fetchAllModule(payload) {
	try {
		const endpoint = getEndpoint('ServiceConfiguration', 'fetchAllModule'); 
		let result = await Client(endpoint,payload,"POST");
		return result

	} catch (error) {
		console.log(error , "Error in the fetchAllModule")
		return error
	}
}

export async function saveImportModules(payload) {
	try {
		const endpoint = getEndpoint('ServiceConfiguration', 'saveImportModules'); 
		let result = await Client(endpoint,payload,"POST");
		console.log(result,"saveImportModules Api")
		return result

	} catch (error) {
		console.log(error.response.data , "Error in the saveImportModules")
		error.response ["status"] = 400
		return error.response.data
	}
}

export async function editImportedModule(payload) {
	try {
		const endpoint = getEndpoint('ServiceConfiguration', 'editImportedModule'); 
		let result = await Client(endpoint,payload,"POST");
		return result

	} catch (error) {
		console.log(error , "Error in the editImportedModule")
		return error
	}
}

export async function deleteModuleById(payload) {
	try {
		const endpoint = getEndpoint('ServiceConfiguration', 'deleteModuleById'); 
		let result = await Client(endpoint,payload,"POST");
		return result

	} catch (error) {
		console.log(error , "Error in the deleteModuleById")
		return error
	}
}

export async function fetchModulesConfig(payload) {
	try {
		const endpoint = getEndpoint('ServiceConfiguration', 'fetchModulesConfig'); 
		let result = await Client(endpoint,payload,"POST");
		return result

	} catch (error) {
		console.log(error , "Error in the fetchModulesConfig")
		return error
	}
}

export async function updateModuleConfig(payload) {
	try {
		const endpoint = getEndpoint('ServiceConfiguration', 'updateModuleConfig'); 
		let result = await Client(endpoint,payload,"POST");
		return result

	} catch (error) {
		console.log(error , "Error in the updateModuleConfig")
		return error
	}
}

export async function getSelfServiceWithAI(payload) {
	try {
		
		const endpoint = getEndpoint('ServiceConfiguration', 'getSelfServiceWithAI'); 
		let result = await Client(endpoint,payload,"POST");
		return result

	} catch (error) {
		console.log(error , "Error in the getSelfServiceWithAI")
		return error
	}
}

export async function importSelfService(payload) {
	try {
		const endpoint = getEndpoint('ServiceConfiguration', 'importSelfService'); 
		let result = await Client(endpoint,payload,"POST");
		return result

	} catch (error) {
		console.log(error , "Error in the updateSelfService")
		return error
	}
}

export async function editServiceDetails(payload) {
	try {
		const endpoint = getEndpoint('ServiceConfiguration', 'editServiceDetails'); 
		let result = await Client(endpoint,payload,"POST");
		return result

	} catch (error) {
		console.log(error , "Error in the saveServiceDetails")
		return error
	}
}

export async function updateSelfService(payload) {
	try {
		const endpoint = getEndpoint('ServiceConfiguration', 'updateSelfService'); 
		let result = await Client(endpoint,payload,"POST");
		return result

	} catch (error) {
		console.log(error , "Error in the updateSelfService")
		return error
	}
}



export async function getLocalsAndOutputsApi(payload) {
    const endpoint = getEndpoint('workbench', 'getAllLocalsAndOutputs'); // Adjusted endpoint retrieval
    console.log("endpoint", endpoint);
    try {
        const response = await Client(endpoint, payload, "POST");
        console.log(response, "responses");
        return response.data;
    } catch (error) {
        console.error('Error fetching locals and outputs:', error);
        throw error; // Rethrow or handle the error as necessary
    }
}

export async function addUpdateDeleteLocalsApi(payload) {
    const endpoint = getEndpoint('workbench', 'addAndUpdateAndDeleteLocals'); // Adjusted endpoint retrieval
    console.log("endpoint", endpoint);
    try {
        const response = await Client(endpoint, payload, "POST");
        console.log(response, "responses");
        return response.data; // Return the data from the response
    } catch (error) {
        console.error('Error adding, updating, or deleting locals:', error);
        throw error; // Rethrow or handle the error as necessary
    }
}

export async function updateAndDeleteOutputsApi(payload) {
    const endpoint = getEndpoint('workbench', 'updateAndDeleteOutputs'); // Adjusted endpoint retrieval
    console.log("endpoint", endpoint);
    try {
        const response = await Client(endpoint, payload, "POST");
        console.log(response, "responses");
        return response.data; // Return the data from the response
    } catch (error) {
        console.error('Error updating or deleting outputs:', error);
        throw error; // Rethrow or handle the error as necessary
    }
}


export async function getAllServiceReviewApi(payload) {
	//SQ_NO_2.2  Gets the endpoint from endpoint file
    const endpoint = getEndpoint('deploy', 'getAllServicesReview'); // Adjusted endpoint retrieval
    console.log("endpoint", endpoint);
    try {
		//SQ_NO_2.14
        const response = await Client(endpoint, payload, "POST");
        console.log(response, "responses");
        return response.data; // Return the data from the response
    } catch (error) {
        console.error('Error fetching service reviews:', error);
        throw error; // Rethrow or handle the error as necessary
    }
}


export async function getAllFilesAndContentsApi(payload) {
    const endpoint = getEndpoint('workbench', 'getAllFilesAndContents'); // Adjusted endpoint retrieval
    console.log("endpoint", endpoint);
    try {
        const response = await Client(endpoint, payload, "POST");
        console.log(response, "responses");
        return response.data; // Return the data from the response
    } catch (error) {
        console.error('Error fetching files and contents:', error);
        throw error; // Rethrow or handle the error as necessary
    }
}

export async function saveEditedCodeApi(payload) {
    const endpoint = getEndpoint('workbench', 'saveEditCode'); // Adjusted endpoint retrieval
    console.log("endpoint", endpoint);
    try {
        const response = await Client(endpoint, payload, "POST");
        console.log(response, "responses");
        return response.data; // Return the data from the response
    } catch (error) {
        console.error('Error saving edited code:', error);
        throw error; // Rethrow or handle the error as necessary
    }
}

export async function generateMainCodeApi(payload) {
    const endpoint = getEndpoint('workbench', 'generateMainFile'); // Adjusted endpoint retrieval
    console.log("endpoint", endpoint);
    try {
        const response = await Client(endpoint, payload, "POST");
        console.log(response, "responses");
        return response.data; // Return the data from the response
    } catch (error) {
        console.error('Error saving edited code:', error);
        throw error; // Rethrow or handle the error as necessary
    }
}

export async function getAllDocumentation(payload) {
    const endpoint = getEndpoint('deploy', 'getAllDocumentation'); // Adjusted endpoint retrieval
    console.log("endpoint", endpoint);
    try {
        const response = await Client(endpoint, payload, "POST");
        console.log(response, "responses");
        return response.data; // Return the data from the response
    } catch (error) {
        console.error('Error fetching documentation', error);
        throw error; // Rethrow or handle the error as necessary
    }
}
////SQ_NO_2.26

export const fetchDataConfigs = async (payload) => {
	//SQ_NO_2.27
    const endpoint = getEndpoint('dataConfig', 'getDataConfigs');
	try {
		//SQ_NO_2.39
        const response = await Client(endpoint, payload, "POST");
        console.log(response, "response for data in api");
        return response; // Return the data from the response
    } catch (error) {
        console.error('Error fetching dataCOnfig', error);
        throw error; // Rethrow or handle the error as necessary
    }
};


export const deleteResourceData = async (payload) => {
    const endpoint = getEndpoint('dataConfig', 'deleteDataConfig');
    const response = await Client(endpoint, payload,"POST");
    return response.data;
};
export const updateCodeSnippetApi = async (payload) => {
    const endpoint = getEndpoint('dataConfig', 'updateCodeSnippet');
    const response = await Client(endpoint, payload,"POST");
    return response.data;
};

export const saveResourceData = async (payload) => {
    const endpoint = getEndpoint('dataConfig', 'saveResourceData');
    const response = await Client(endpoint, payload,"POST");
    return response.data;
};

export const aiSuggestionApi = async (payload) => {
    const endpoint = getEndpoint('workbench', 'aiSuggestion');
    const response = await Client(endpoint, payload,"POST");
    return response;
};












