
import React from 'react';

const AccordionItem = ({ item, parentId }) => {
    const itemId = `${parentId}-${item.title.replace(/\s+/g, '-')}`;
    return (
        <>
            <div className="estimation-container accordion-header d-flex align-items-center justify-content-between" id={`heading-${itemId}`}>
                <div className="d-flex align-items-center gap-4">
                    <button
                        className="custom-acc-btn d-flex align-items-center gap-2 p-0 font-14 btn border-0 shadow-none collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse-${itemId}`}
                        aria-expanded="false"
                        aria-controls={`collapse-${itemId}`}
                    >
                        <span className="cust-acc-arrow d-flex align-items-center justify-content-center">
                            <img src="images/violet-arrow-right.svg" alt="arrow-right" />
                        </span>
                    </button>
                    <span className="font-14 font-medium color-black">{item.title}</span>
                </div>
                {item.servicesCount !== undefined && (
                    <p className="font-14 font-medium secondary-color m-0">
                        # of Services <span className="font-16 font-bold color-black ms-3">{item.servicesCount}</span>
                    </p>
                )}
                {item.cost !== undefined && (
                    <p className="font-14 font-medium secondary-color m-0">
                        # Cost <span className="font-16 font-bold color-black ms-3">${item.cost.toFixed(2)}</span>
                    </p>
                )}
            </div>
            <div id={`collapse-${itemId}`} className="accordion-collapse collapse" aria-labelledby={`heading-${itemId}`} data-bs-parent={`#${parentId}`}>
                <div className="accordion-body p-0">
                    {item.subItems && item.subItems.length > 0 && (
                        <div className="accordion" id={`accordion-${itemId}`}>
                            {item.subItems.map((subItem, index) => (
                                <AccordionItem key={index} item={subItem} parentId={`accordion-${itemId}`} />
                            ))}
                        </div>
                    )}
                    {item.fields && item.fields.length > 0 && (
                        <div className="row px-2 py-32 mx-5">
                            <div className="col-md-12">
                                <div className="row">
                                    {item.fields.map((field, index) => (
                                        <div className="col-md-6 px-3" key={index}>
                                            <label className="form-label font-14 font-medium black-v2 mb-3" htmlFor={field.id}>
                                                {field.label}
                                            </label>
                                            <input
                                                type="text"
                                                id={field.id}
                                                className="form-control custom-form font-14 font-medium"
                                                placeholder={field.placeholder}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

        </>
    );
};

const Accordion = ({ data }) => {
    return (
        <>
            {
                data.map((item, index) => (
                    <AccordionItem key={index} item={item} parentId="accordionExample" />
                ))
            }
        </>

    );
};

export default Accordion

