
// Helper function to get color for service
function getColorForService(serviceName) {
	const colorMap = {
		"VNET": "#8C4FFF",
		"Account": "#CD2264",
		"Availability_Zone": "#6610f2",
		"Resource_Group": "#00A4A6",
		"Subnet": "#DD3522",
		"Security_group": "#DD3522"
	};
	return colorMap[serviceName] || "#000000";
} 
/** 
	* ProcessAzureResources This function serves the Pupose of Creation of Node of each services and
	* Binding of the Architecture
*/
// Fields Refactoring 

// Fields Refactoring 
function serviceFieldsRefactor(params) {
    try {
        if (params && Array.isArray(params)) {
            const seenFields = new Set();  // To track unique field identifiers
            return params.reduce((accumulator, field) => {
                // Create a unique identifier for the field based on fieldName and fieldType
                const fieldIdentifier = `${field.fieldName}-${field.fieldType}`;

                // If the field has not been seen before, process it
                if (!seenFields.has(fieldIdentifier)) {
                    seenFields.add(fieldIdentifier);

                    let refactoredField = {
                        dependsOn: null,
                        fieldName: field.fieldName,
                        fieldType: field.fieldType,
                        inputValue: "",
                        required: true,
                        UserValue: ""
                    };

                    // If 'value' exists in the original field, use it for 'inputValue'
                    if ('value' in field) {
                        try {
                            const parsedValue = JSON.parse(field.value);
                            refactoredField.inputValue = Array.isArray(parsedValue) && parsedValue.length > 0 ? parsedValue[0] : "";
                        } catch (e) {
                            refactoredField.inputValue = field.value;
                        }
                    }

                    // If 'userValue' exists in the original field, use it for 'UserValue'
                    if ('userValue' in field) {
                        refactoredField.UserValue = field.userValue;
                    }

                    accumulator.push(refactoredField);
                }

                return accumulator;
            }, []);
        }
        return [];
    } catch (error) {
        console.log(error);
        return [];
    }
}
let NodeData = []
// Helper to create a node
// createNode function is used to Generate the Node
const createNode = ( position, size, serviceData, nodeType) => {
	// console.log(serviceData , "service Data Here in Azure")
	// const dimensionsMap = {
	// 	"Account": 400,
	// 	"Region": 350,
	// 	"VPC": 300,
	// 	"Availability_Zone": 250,
	// 	"Subnet": 200,
	// 	"Security_group": 150
	// };

	let nodeIdentity = nodeType;
	// Node Style Properties
	let nodeStyle = {
		position: "absolute",
		zIndex: (serviceData.serviceName.toLowerCase() === "virtual network" ) ? 
			"-100003" : 
			(serviceData.serviceName.toLowerCase() === "subnet") ? 
				"-100001" : 
				(serviceData.serviceName === "Resource_Group" ||serviceData.serviceName.toLowerCase() == "resource group"  ) ? 
					"-100003" : 
					"100010",
		width: size.width,
		height: size.height,
		className: "resizeLoop",
		color: getColorForService(serviceData.serviceName)
	};
	// Node HTML Element
	let htmlElement = (
		<div>
			<div className="container-hover"
				onMouseEnter={(e) => {
					// Show tooltip
					const tooltip = document.createElement('div');
					tooltip.innerHTML =  `<strong>${serviceData.serviceName}</strong><br>${serviceData.serviceDR}`;
					tooltip.style.position = 'fixed';
					tooltip.style.top = `${e.clientY + 10}px`;
					tooltip.style.left = `${e.clientX + 10}px`;
					tooltip.style.backgroundColor = 'black';
					tooltip.style.color = 'grey';
					tooltip.style.padding = '8px';
					tooltip.style.borderRadius = '4px';
					tooltip.style.zIndex = '100';
					tooltip.style.whiteSpace = 'pre-wrap';
					tooltip.style.maxWidth = '200px';
					tooltip.className = 'custom-tooltip';
					document.body.appendChild(tooltip);
				}}
				onMouseLeave={() => {
					// Hide tooltip
					const tooltips = document.getElementsByClassName('custom-tooltip');
					while (tooltips.length > 0) {
						tooltips[0].parentNode.removeChild(tooltips[0]);
					}
				}}
			>
				<img
					id={serviceData.id}
					width={25}
					height={25}
					src={`${serviceData.imageName}`}
					className={["VPC", "Region", "Subnet", "Security_group"].includes(serviceData.serviceName) ? "vpcImageStyle" : "img"}
					alt="service-logo"
					onError={(e) => {
						e.target.onerror = null; // Prevents looping
						e.target.src = `./images/Workbench/AIArchitecture/Azure/AzureLogo.svg`; // Set to your default image path
					}}
				/>
			</div>
		</div>
	);
	// Creation of Unique Entittes here to Difffernetiate Each Nodes 
	// Here Label Names , Image ServiceName and service Data Refactoring is performed Here
	let id = serviceData.serviceName + "_" + Math.random()
	let serviceFields = serviceFieldsRefactor (serviceData.fields)
	let nodeDataForCount = [...NodeData];
    let countForLabel = nodeDataForCount.filter(data => data === serviceData.serviceName).length + 1;
    let labelName = `${serviceData.serviceName} ${countForLabel}`;
    let imageServiceName = `${serviceData.serviceName}_${countForLabel}`
	NodeData.push( serviceData.serviceName)

	return {
		id: id,
		type: nodeIdentity,
		instanceName : labelName,
		position: {
			x: isNaN(position.x) ? 0 : position.x,
			y: isNaN(position.y) ? 0 : position.y
		},
		// parentNode: "",
		style: nodeStyle,
		data: {
			element: htmlElement,
			resourceName: serviceData.serviceName,
			serviceName: serviceData.serviceName,
			serviceFamily : serviceData.serviceFamily ? serviceData.serviceFamily : "Other",
			imageName: serviceData.imageName,
			imageServiceName: imageServiceName,
			instanceName : labelName,
			fields: serviceFields || [],
			ConfigureDetail: {
				subServieConfig: {
					Action: {
						create: {},
						delete: {},
						update: {}
					},
					type: "create"
				}
			},
			description: serviceData.serviceDR || '',
			children: undefined,
			repositoryData: {
				repository_name: "",
				repository_id: "",
				branchData: {
					branch_id: "",
					branch_name: ""
				}
			},
			Tags: [
				{
					"key": "cloudgen",
					"value": true,
					defaultTag: true
				} 
			]
		}
	};
	};
// processAzureNodesFromResponse Function Generates the Architecture Based on the Node Retrieved
const processAzureNodesFromResponse = (azureResources) => {
	if (!azureResources || Object.keys(azureResources).length === 0) {
		return [];
		}
		console.log(azureResources, "azureResources");

		const nodes = [];
		const edges = [];
		let id = 0;

		const PADDING = 30;
		const SERVICE_SPACING = 15;
		const VERTICAL_SPACE_ABOVE = 30;
		const VERTICAL_SPACE_BETWEEN = 30;
		const MIN_RG_WIDTH = 700;
		const MIN_VNET_WIDTH = 400;
		const MIN_VNET_HEIGHT = 400;
		const MIN_SUBNET_WIDTH = 300;
		const MIN_SUBNET_HEIGHT = 300;
		const SERVICE_WIDTH = 25;
		const SERVICE_HEIGHT = 25;
		const MAX_SERVICES_PER_ROW = 5;
		const MAX_VNETS_PER_ROW = 4; // Max VNETs in a row

		const resourceGroup = azureResources.resourceGroupName || 'Unknown Resource Group';
		let rgWidth = MIN_RG_WIDTH;
		let rgHeight = VERTICAL_SPACE_ABOVE;
		const rgSubnodes = [];
		// Construction of Architecture Inside the VNET and Subnet Processing Done here
		if (azureResources.subservice?.vnet) {
			const vnets = azureResources.subservice.vnet;
			let vnetX = PADDING;
			let vnetY = VERTICAL_SPACE_ABOVE;
			let maxVnetHeightInRow = MIN_VNET_HEIGHT;
			let trackVnetNodes = []
			// Iteration of VNet to Form VNet and Subnet Structure 
			vnets.forEach((vnet, vnetIndex) => {
				let vnetWidth = MIN_VNET_WIDTH;
				let vnetHeight = VERTICAL_SPACE_ABOVE;
				const vnetSubnodes = [];
				let subnetX = vnetX + PADDING;
				let subnetY = vnetY + VERTICAL_SPACE_ABOVE;

				if (vnetIndex % MAX_VNETS_PER_ROW === 0 && vnetIndex !== 0) {
					let index = vnetIndex - MAX_VNETS_PER_ROW;
					vnetY += trackVnetNodes[index].style.height + VERTICAL_SPACE_BETWEEN;
					vnetX = PADDING;
					maxVnetHeightInRow = MIN_VNET_HEIGHT;
					subnetX = vnetX + PADDING;
					subnetY = vnetY + VERTICAL_SPACE_ABOVE;
				}
				// Subnet Calcaultion and Service Preparations Perfoemd Here
				if (vnet.subservice?.subnet) {
					const subnets = vnet.subservice.subnet;
					let totalSubnetHeight = VERTICAL_SPACE_ABOVE;
					// Iteration of Subnet 
					subnets.forEach((subnet, subnetIndex) => {
						let subnetWidth = MIN_SUBNET_WIDTH;
						let subnetHeight = MIN_SUBNET_HEIGHT;
						const subnetServices = [];

						if (subnet.subservice) {
							// Subnet service Postion Calcualtion Perfomed Below
							let serviceX = subnetX+PADDING;
							let serviceY = subnetY + VERTICAL_SPACE_ABOVE + 5;
							let maxServiceX = PADDING;
							let maxServiceY = PADDING;
							// Iteration of Subnet service and Dynamic Addition of X and Y calcualtion Perfomed Here
							Object.entries(subnet.subservice).forEach(([serviceType, service], serviceIndex) => {
								if (serviceIndex % MAX_SERVICES_PER_ROW === 0 && serviceIndex !== 0) {
									serviceX = subnetX + PADDING;
									serviceY += SERVICE_HEIGHT + SERVICE_SPACING;
								}

								let position = { x: serviceX, y: serviceY };
								let size = { width: SERVICE_WIDTH, height: SERVICE_HEIGHT };
								let serviceData = {
									serviceName: service.serviceName,
									imageName: service.serviceIcon,
									fields: subnet.fields || [],
									serviceFamily: "Others",
									serviceDR: service.description ? service.description :  service.serviceName
								};
								let nodeType = "service";

								const serviceNode = createNode(position, size, serviceData, nodeType);

								subnetServices.push(serviceNode);

								serviceX += SERVICE_WIDTH + SERVICE_SPACING;
								maxServiceX = Math.max(maxServiceX, serviceX);
								maxServiceY = Math.max(maxServiceY, serviceY);
							});

							subnetWidth = Math.max(subnetWidth, maxServiceX - subnetX + PADDING);
							subnetHeight = Math.max(subnetHeight, maxServiceY - subnetY + PADDING);
						}

						let position = { x: subnetX, y: subnetY };
						let size = { width: subnetWidth, height: subnetHeight };
						let serviceData = {
							serviceName: subnet.serviceName || "Subnet",
							imageName: subnet.serviceIcon || "./images/Workbench/AIArchitecture/Azure/Subnet.svg",
							fields: subnet.fields || [],
							serviceFamily: subnet.serviceFamily || "Network",
							serviceDR: subnet.description ? subnet.description :  subnet.serviceName

						};
						let nodeType = "location";
						const subnetNode = createNode(position, size, serviceData, nodeType);

						vnetSubnodes.push(subnetNode);
						vnetSubnodes.push(...subnetServices);

						totalSubnetHeight += subnetHeight + VERTICAL_SPACE_BETWEEN;
						vnetWidth = Math.max(vnetWidth, subnetWidth + PADDING * 2);
						subnetY += subnetHeight + VERTICAL_SPACE_BETWEEN;
					});

					vnetHeight = Math.max(vnetHeight, totalSubnetHeight);
				}

				let position = { x: vnetX, y: vnetY };
				let size = { width: vnetWidth, height: vnetHeight };
				let serviceData = {
					serviceName: vnet.serviceName || "VNET",
					imageName: vnet.serviceIcon || "./images/Workbench/AIArchitecture/Azure/Virtual_Network.png",
					fields: vnet.fields || [],
					serviceFamily:vnet.serviceFamily ||  "Network",
					serviceDR: vnet.description ? vnet.description :  vnet.serviceName


				};
				let nodeType = "location";
				const vnetNode = createNode(position, size, serviceData, nodeType);

				rgSubnodes.push(vnetNode);
				rgSubnodes.push(...vnetSubnodes);
				trackVnetNodes.push(vnetNode);

				vnetX += vnetWidth + PADDING;
				maxVnetHeightInRow = Math.max(maxVnetHeightInRow, vnetHeight);
				rgWidth = Math.max(rgWidth, vnetX);
				rgHeight = Math.max(rgHeight, vnetY + vnetHeight + VERTICAL_SPACE_BETWEEN);
			});
		}
		// Services Outside the VNET and Subent which is services will be placed inside the Resource group 
		if (azureResources.subservice) {
			let serviceX = PADDING;
			let serviceY = rgHeight + VERTICAL_SPACE_ABOVE;
			let maxServiceX = PADDING;
			let maxServiceY = PADDING;

			Object.entries(azureResources.subservice).forEach(([serviceType, service], serviceIndex) => {
		// Processing Services Outside the Vnet 
			if (serviceType !== 'vnet') {
				// Orphans services Calcualtion Perfomed Here
				if (serviceIndex % MAX_SERVICES_PER_ROW === 0 && serviceIndex !== 0) {
					serviceX = PADDING;
					serviceY += SERVICE_HEIGHT + SERVICE_SPACING;
				}
				// console.log(service , "Orphans Binding ")
				const serviceData = {
					serviceName: service.serviceName || 'Unknown',
					imageName: service.serviceIcon || '',
					fields: service.fields || [],
					serviceFamily: service.serviceFamily || service.serviceName,
					serviceDR: service.description ? service.description :  service.serviceName

				};
				let position = { x: serviceX, y: serviceY },
					size = { width: SERVICE_WIDTH, height: SERVICE_HEIGHT },
					nodeType = "service";

				const serviceNode = createNode(position, size, serviceData, nodeType);

				nodes.push(serviceNode);

				maxServiceX = Math.max(maxServiceX, serviceX + SERVICE_WIDTH);
				maxServiceY = Math.max(maxServiceY, serviceY + SERVICE_HEIGHT);

				serviceX += SERVICE_WIDTH + SERVICE_SPACING;
			}
			});
			// Modification of the Resource group Width and Heigh based on the service Rendering Performed Here 
			rgWidth = Math.max(rgWidth, maxServiceX + PADDING);
			rgHeight = Math.max(rgHeight, maxServiceY + PADDING);
			}
			// Resource Group Creation is Performed Here 
			let serviceData = {
				serviceName: "Resource_Group",
				imageName: "./images/Workbench/AIArchitecture/Azure/Resource_Group.svg",
				fields: [],
				serviceDR: "Resource_Group",
				serviceFamily: "Network"
			};
			let position = { x: 0, y: 0 },
				size = { width: rgWidth, height: rgHeight },
				nodeType = "location";

			const rgNode = createNode(position, size, serviceData, nodeType);

			nodes.push(rgNode);
			rgSubnodes.forEach(subnode => {
				subnode.parentNode = rgNode.id;
				nodes.push(subnode);
			});
			// console.log(nodes, 'Nodes');
			return nodes;
};

export default processAzureNodesFromResponse;
