import React, { useState, useEffect, useRef, useContext } from "react";
import { Bar } from 'react-chartjs-2';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { fetchCardData, fetchDbuUtilChartData, fetchTrendChartData, getAllOrganization } from "../../Service/api";
import { fetchAllOrganization } from "../../Service/api";
import { OrgContext } from "../../ContextProvider/OrgContext";
import { DatabricksContext } from "./Databricks";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function ChartComponent() {



  const filterUtilizationData = {
    timeFrame: "monthly",
    startDate: "",
    endDate: ""
  };


  const filterTrendsData = {
    timeframe: "Week",
    startDate: "",
    endDate: ""
  };


  //PS_02 :State declaration
  const { utilisationChart, SetutilisationChart } = useContext(DatabricksContext)
  const { trendsChart, SetTrendsChart } = useContext(DatabricksContext)
  const [error, setError] = useState(null);
  const chartRef = useRef(null);
  const [utilFilterdata, setUtilFilterData] = useState(filterUtilizationData)
  const [trendsFilterdata, settrendsFilterData] = useState(filterTrendsData)
  const [selectedOption, setSelectedOption] = useState('Monthly');
  const [selectedOptionforTrends, setSelectedOptionforTrends] = useState('Week');

  const [showCustomDateRange, setShowCustomDateRange] = useState(false);
  const [showCustomDateRangeTrends, setShowCustomDateRangeTrends] = useState(false);
  const [showCustomDateRangeUtilisation, setShowCustomDateRangeUtilisation] = useState(false);
  const [organizationData, setOrganizationData] = useState([])
  const [selectedOrg, setSelectedOrg] = useState("")
  const userId = localStorage.getItem('userId');
  console.log("userid", userId)

  const { orgId } = useContext(OrgContext)

  //PS_05 and PS_28
  useEffect(() => {
    getUtilisationChartData(utilFilterdata);
    // getTrendsData(trendsFilterdata)
  }, [selectedOrg]);

  useEffect(() => {
  if(selectedOrg){
    getTrendsData(trendsFilterdata)
  }
  }, [selectedOrg]);
  useEffect(() => {
    getOrganizationData()
  }, [])

  //PS_05
  useEffect(() => {
    // Destroy the previous chart instance before creating a new one
    if (chartRef.current) {
      chartRef.current.destroy();
    }
  }, [utilisationChart]);


  //PS_06 - PS_12
  const getUtilisationChartData = async (filterData = utilFilterdata, option) => {

    try {
      const payload = {
        "organizationId": orgId,
        "userId": userId,
        timeFrame: option,
        startDate: filterData.startDate,
        endDate: filterData.endDate
      }

      const response = await fetchDbuUtilChartData(payload);

      if (response.status === 200) {
        console.log(response, "response for chart")

        SetutilisationChart(response.data.body.data)
        console.log(utilisationChart, "utilchart")
      } else {
        setError(`Error: ${response.status}`);
      }
      //SetutilisationChart(UtilChartData)
    } catch (error) {
      setError('An error occurred while fetching data');

      const errorData = {
        errorName: error.name,
        errorServiceName: "Front-end",
        errorfunctionName: "getUtilisationChartData",
        errorSource: "frontend",
        errorDiscription: "Couldnt fetch Card Data" + error.message
      };

      //   await postErrorLog(errorData);
    }
  };

  //PS_29- PS_35
  const getTrendsData = async (filterData = trendsFilterdata, option,orgId) => {
    try {
      const payload = {
        "organizationId":orgId?orgId: selectedOrg,
        "userId": userId,
        "filter": {
          timeframe: option?option:filterData.timeframe,
          startDate: filterData.startDate,
          endDate: filterData.endDate
        }
      }
      console.log("payload",payload);


      // const payload= {
      //   "organizationId":"3487029b-9dac-4d04-b42c-5b768cbcc54f",
      //   "userId":"9b103314-7550-47ee-a64d-9c3bb2a42093",
      //   "filter":{
      //       "timeframe":"monthly",
      //       "startDate":"28-10-24",
      //       "endDate":"28-10-24"
      //   }

      const response = await fetchTrendChartData(payload);
      if (response.status === 200) {
        SetTrendsChart(response.data.body.data);
      } else {
        setError(`Error: ${response.status}`);
      }
      //SetTrendsChart(trendsData)
    } catch (error) {
      setError('An error occurred while fetching data');

      const errorData = {
        errorName: error.name,
        errorServiceName: "Front-end",
        errorfunctionName: "getUtilisationChartData",
        errorSource: "frontend",
        errorDiscription: "Couldnt fetch Card Data" + error.message
      };

      //   await postErrorLog(errorData);
    }
  };

  console.log(trendsChart, "trendsdata")

  //PS_59 - PS_60
  const data = {
    labels: utilisationChart.map(data => data.projectName),
    datasets: [
      {
        label: 'Commitments',
        data: utilisationChart.map(data => data.commitments),
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
      },
      {
        label: 'Actual Cost',
        data: utilisationChart.map(data => data.actualCost),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
    ],
  };

  //PS_61 - PS_62
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: true,
          drawBorder: false,
          color: 'rgba(0, 0, 0, 0.1)',
          lineWidth: 1,
          drawTicks: false,
          drawOnChartArea: true,
          borderDash: [10, 5], // This creates the dotted line effect
        },
        ticks: {
          display: true,
        },
      },
      x: {
        grid: {
          display: false, // This will hide the vertical grid lines
          drawBorder: false,
          drawOnChartArea: false, // This will prevent drawing on the chart area
          drawTicks: true,
        },
        ticks: {
          display: true,
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
      },
    },
  };

  //PS_63
  const chartWrapperStyle = {
    width: '500px', // Set the desired width
    height: '300px', // Set the desired height
    position: 'relative',
    marginTop: '-60px',
  };


  //PS_64
  const trendsChartData = {
    labels: trendsChart.slice(0, 5).map(item => item.timeframe),
    datasets: [
      {
        label: 'High',
        data: trendsChart.slice(0, 5).map(item => item.status === 'high' ? item.cost : 0),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        barPercentage: 0.2,
        categoryPercentage: 0.8,
        barThickness: 20,
      },
      {
        label: 'Low',
        data: trendsChart.map(item => item.status === 'low' ? item.cost : 0),
        backgroundColor: 'rgba(255, 99, 132, 0.6)',
        barPercentage: 0.2,
        categoryPercentage: 0.8,
        barThickness: 20,
      },
    ],
  };

  //PS_65
  const trendsOptions = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y', // This will switch the chart to a horizontal bar chart
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
      x: {
        stacked: false,
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return '$' + value / 1000 + 'k';
          }
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          //color: 'rgba(0, 0, 0, 0.1)',
          color: 'rgba(0, 0, 0, 0.07)',
          borderDash: [5, 5], // Optional: if you want dotted lines on the x-axis
        },
      },
    },
    plugins: {
      legend: {
        display: true, // Set this to true if you want to display the legend
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.x !== null) {
              label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.x);
            }
            return label;
          }
        }
      }
    },
  };


  //PS_66
  const handleOptionSelect = async (option) => {
    console.log("Selected option:", option);
    setSelectedOption(option);
    setShowCustomDateRangeUtilisation(option === 'custom');

    // Update timeFrame based on the selected option
    setUtilFilterData((prev) => ({
      ...prev,
      timeFrame: option.toLowerCase() // Assuming you want it in lowercase
    }));

    await getUtilisationChartData(utilFilterdata, option);

  };

  //PS_67
  const filterValidation = (e) => {
    console.log(e, "hjkl")
    const { id, value } = e.target;
    console.log(id, value)
    setUtilFilterData(prevState => ({
      ...prevState,
      [id]: value
    }));
  };

  //PS_77 -PS_78
  const filterValidationTrends = (e) => {
    console.log(e, "trendshjkl")
    const { id, value } = e.target;
    console.log(id, value)
    settrendsFilterData(prevState => ({
      ...prevState,
      [id]: value
    }));
  };

  // PS_68
  const handleApply = async () => {

    await getUtilisationChartData(utilFilterdata);
    //setInsightData("");
    console.log('Filters applied:', utilFilterdata);
    setShowCustomDateRangeTrends(false)
  };

  // PS_69
  const handleClear = async () => {
    setUtilFilterData({
      timeFrame: "monthly",
      startDate: "",
      endDate: ""
    });
    //setSelectedOption('Select an option');
    setShowCustomDateRangeUtilisation(false)
    console.log(utilFilterdata, "hgjkl")
    // setEnvironmentData([])
    // setWorkloadData([])


    await getUtilisationChartData(filterUtilizationData)
  };

  // PS_70
  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split('T')[0]; // Format YYYY-MM-DD
  };

  //PS_71
  const handleTrendsOptionSelect = async (option) => {
    console.log("Trends selected option:", option);
    setSelectedOptionforTrends(option);
    setShowCustomDateRangeTrends(option === 'custom');

    if (option !== 'custom') {
      settrendsFilterData((prev) => ({
        ...prev,
        timeframe: option.toLowerCase()
      }));
      await getTrendsData(trendsFilterdata, option,"");
    }
  };

  //PS_72
  const handleTrendsClear = () => {
    settrendsFilterData(filterTrendsData); // Reset to initial filter data
    setSelectedOptionforTrends(' Week');
    setShowCustomDateRangeTrends(false);
  };

  //PS_73
  const handleApplyTrends = async () => {
    // Handle apply logic for trends chart here
    await getTrendsData(trendsFilterdata)
    setShowCustomDateRangeTrends(false)
    console.log("Applying trends with data:", trendsFilterdata);

    // Call your API or logic here using trendsFilterdata
  };

  //PS_74 - PS_76
  const getOrganizationData = async () => {
    try {
      // const payload = {
      //   "userId": "93b5a05f-73e9-4a42-923f-2b3f3d821287",
      //   "orgId": "28da4a71-14f5-47ce-a349-35840ebf2dcb"
      // }
      const payload = {
        "userId": userId,
        "orgId": selectedOrg,
        "search": ""
      }

      const response = await getAllOrganization(payload); // Replace with your actual API call function
      console.log(response, "response");

      if (response.status === 200) {
        setOrganizationData(response.data.data); // Store the response in a state variable
        console.log("orggg",response.data.data[0].orgId);
        getTrendsData(trendsFilterdata,"",response.data.data[0].orgId)
      } else {
        console.error("Error fetching cloud provider data:", response.data.body.message);
      }
    } catch (error) {
      console.error("API Error:", {
        errorName: error.name,
        errorServiceName: "Front-end",
        errorFunctionName: "getCloudProviderData",
        errorSource: "frontend",
        errorDescription: "fetch failed: " + error.message
      });
    }
  };

  const handleOrgSelect = (orgId) => {
    setSelectedOrg(orgId);
  };

  const getSelectedOrgName = () => {
    const selected = organizationData.find(org => org.orgId === selectedOrg);
    if (selected) {
      return selected.orgName;
    } else if (organizationData && organizationData.length > 0) {
      return organizationData[0].orgName;
    } else {
      return null; // or any default value you want to return when organizationData is empty or undefined
    }
    };


  return (
    <>

      {/* chart starts here */}
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-12 col-lg-6 px-2">
            <div className="chart-container-3 px-3 py-4">
              <div className="d-flex justify-content-between align-items-center">
                <p className="font-14 font-bold black-v3 mb-0">
                  DBU Utilization

                </p>

                {/* dropdown */}
                <div className="dropdown position-relative">
                  <button
                    type="button"
                    className="btn custom-dropdown d-flex justify-content-between align-items-center gap-3 py-1 font-12 font-regular black-v2 shadow-none"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {selectedOption}

                    <img src="images/dropdown-arrow.svg" className="cust-dropdown-arrow"
                      alt="dropdown-arrow" />
                  </button>

                  <ul className="dropdown-menu body-bg py-0 dash-dropdown" aria-labelledby="dropdownMenuButton">
                    <li onClick={() => handleOptionSelect('monthly')} className="font-regular color-black font-11 py-2 px-3 custom-list">
                      Monthly
                    </li>
                    <li onClick={() => handleOptionSelect('yearly')} className="font-regular color-black font-11 py-2 px-3 custom-list">
                      Yearly
                    </li>
                    <li onClick={() => handleOptionSelect('weekly')} className="font-regular color-black font-11 py-2 px-3 custom-list">
                      Weekly
                    </li>
                    <li onClick={() => handleOptionSelect('custom')} className="font-regular color-black font-11 py-2 px-3 custom-list">
                      Custom
                    </li>
                  </ul>

                  {showCustomDateRangeUtilisation && (
                    <div
                      className={`dropdown-menu dropdown-menu-end border-0 custom-shadow dd-position background-white custom-filter-width p-4 position-absolute ${showCustomDateRangeUtilisation ? 'show' : 'hide'}`}
                      aria-labelledby="dropdownMenuClickableInside"
                      style={{
                        "top": "30px",
                        "left": "100px"
                      }}
                    >

                      <h5 className="font-medium font-16 mb-0 color-black mb-4">Set Custom Date Range for Utilisation</h5>
                      <div className="row">
                        <div className="col-md-12 mb-2">
                          <label className="font-14 font-medium black-v2">Date Range</label>
                        </div>

                        <div className="col-md-6 mb-4">
                          <label className="form-label font-14 font-medium black-v2 label-color form-text-color" htmlFor="startDate">
                            From:</label>
                          <div className="position-relative">
                            <input
                              className="form-control"
                              type="date"
                              id="startDate"
                              value={utilFilterdata.startDate}
                              onChange={filterValidation}
                              min={utilFilterdata.startDate || new Date(new Date().setMonth(new Date().getMonth() - 6)).toISOString().split('T')[0]}
                              max={new Date(new Date().setMonth(new Date().getMonth() + 6)).toISOString().split('T')[0]}
                            />
                          </div>
                        </div>

                        <div className="col-md-6 mb-4">
                          <label
                            className="form-label font-14 font-medium label-color black-v2 form-text-color"
                            htmlFor="endDate">To:</label>

                          <div className="position-relative">
                            <input
                              className="form-control"
                              type="date"
                              id="endDate"
                              value={utilFilterdata.endDate}
                              onChange={filterValidation}
                              min={utilFilterdata.startDate || new Date(new Date().setMonth(new Date().getMonth() - 6)).toISOString().split('T')[0]}
                              max={new Date(new Date().setMonth(new Date().getMonth() + 6)).toISOString().split('T')[0]}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="d-flex justify-content-end align-items-center pt-3 gap-3">


                            <button className="btn button outline-button px-4" onClick={handleClear}>Clear All</button>
                            <button className="button primary-button" onClick={handleApply}>Apply</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                </div>
                {/* dropdown */}

              </div>
              <div className="w-100 chart-container-2 mt-4 mb-3 d-flex justify-content-center">
                <div style={chartWrapperStyle}>
                  <Bar data={data} options={options} />
                  <a href="#">

                  </a>

                </div>
              </div>
              <div className="mt-4 mb-1 d-flex justify-content-center align-items-center gap-4">
                <p className="font-12 font-medium secondary-text-color-v4 mb-1 d-flex align-items-center text-center">
                  <span className="legend legend-blue-light me-1" />
                  Commitments
                </p>
                <p className="font-12 font-medium secondary-text-color-v4 mb-1 d-flex align-items-center text-center">
                  <span className="legend legend-success-light me-1" />
                  Actual Cost
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-12 col-lg-6 px-2">
            <div className="chart-container-3 px-3 py-4">
              <div className="d-flex justify-content-between align-items-center">
                {/* dropdown */}

                <div className="d-flex align-items-center">
                  <h5 className="font-14 font-bold black-v2 m-0">Trends by</h5>
                  <div className="dropdown position-relative">
                    <button
                      type="button"
                      className="btn color-violet d-flex justify-content-between align-items-center gap-3 ps-1 font-14 font-bold primary-color shadow-none py-1"
                      id="dropdownMenuClickableInside"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span className="color-violet">
                        {selectedOptionforTrends === 'custom'
                          ? 'Custom'
                          : selectedOptionforTrends.charAt(0).toUpperCase() + selectedOptionforTrends.slice(1)}
                      </span>
                      <img src="images/dropdown-arrow.svg" alt="dropdown-arrow" className="icon-filter" />
                    </button>
                    <ul className="dropdown-menu body-bg dash-dropdown custom-white" aria-labelledby="dropdownMenuClickableInside">
                      {['monthly', 'yearly', 'weekly', 'custom'].map((option) => (
                        <li
                          key={option}
                          onClick={() => handleTrendsOptionSelect(option)}
                          className={`font-regular color-black font-11 py-2 px-3 custom-list ${selectedOptionforTrends === option ? 'selected' : ''}`}
                        >
                          {option.charAt(0).toUpperCase() + option.slice(1)}
                        </li>
                      ))}
                    </ul>

                    {showCustomDateRangeTrends && (
                      <div
                        className={`dropdown-menu dropdown-menu-end border-0 custom-shadow dd-position background-white custom-filter-width p-4 position-absolute ${showCustomDateRangeTrends ? 'show' : 'hide'}`}
                        aria-labelledby="dropdownMenuClickableInside"
                        style={{
                          "top": "30px",
                          "left": "60px"
                        }}
                      >
                        <h5 className="font-medium font-16 mb-0 color-black mb-4">Custom Date Range for Trends</h5>
                        <div className="row">
                          <div className="col-md-12 mb-2">
                            <label className="font-14 font-medium black-v2">Date Range</label>
                          </div>
                          <div className="col-md-6 mb-4">
                            <label className="form-label font-14 font-medium label-color black-v2 mb-1" htmlFor="startDate">From</label>
                            <div className="position-relative">
                              <input
                                className="form-control"
                                type="date"
                                id="startDate"
                                onChange={filterValidationTrends}
                                value={trendsFilterdata.startDate}
                               min={trendsFilterdata.startDate || new Date(new Date().setMonth(new Date().getMonth() - 6)).toISOString().split('T')[0]}
                               max={new Date(new Date().setMonth(new Date().getMonth() + 6)).toISOString().split('T')[0]}
                              />
                            </div>
                          </div>
                          <div className="col-md-6 mb-4">
                            <label className="form-label font-14 font-medium label-color black-v2 mb-1" htmlFor="endDate">To</label>
                            <div className="position-relative">
                              <input
                                className="form-control"
                                type="date"
                                id="endDate"
                                onChange={filterValidationTrends}
                                value={trendsFilterdata.endDate}
                                min={trendsFilterdata.startDate || new Date(new Date().setMonth(new Date().getMonth() - 6)).toISOString().split('T')[0]}
                                max={new Date(new Date().setMonth(new Date().getMonth() + 6)).toISOString().split('T')[0]}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="d-flex justify-content-end align-items-center gap-3">
                              <button className="btn button outline-button px-4" onClick={handleTrendsClear}>Clear All</button>
                              <button
                                disabled={!trendsFilterdata.startDate || !trendsFilterdata.endDate}
                                className="btn button primary-button px-3"
                                onClick={handleApplyTrends}
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>





                {/* dropdown */}
                {/* dropdown */}
                <div className="dropdown">
                  <button
                    type="button"
                    className="btn custom-dropdown d-flex justify-content-between align-items-center gap-3 py-1 font-12 font-medium color-black shadow-none"
                    id="dropdownMenuButton12"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="selected-org-name">{getSelectedOrgName()}</span>
                    <img src="images/dropdown-arrow.svg" alt="dropdown-arrow" className="cust-dropdown-arrow" />
                  </button>
                  <ul
                    className="dropdown-menu body-bg access-dropdown"
                    aria-labelledby="dropdownMenuButton12"
                  >
                    {organizationData.map((org) => (
                      <li
                        key={org.orgId}
                        className="font-regular color-black font-12 p-2 custom-list"
                        onClick={() => handleOrgSelect(org.orgId)}
                        style={{ cursor: 'pointer' }}
                      >
                        <span className="d-flex">{org.orgName}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                {/* dropdown */}
              </div>
              <div className="w-100 chart-container-2 mt-4 mb-3 d-flex justify-content-center">
                <Bar data={trendsChartData} options={trendsOptions} />
                <a href="#">

                </a>
              </div>
              <div className="mt-4 mb-1 d-flex justify-content-center align-items-center gap-4">
                <p className="font-12 font-medium secondary-text-color-v4 mb-1 d-flex align-items-center text-center">
                  <span className="legend legend-success-light me-1" />
                  High
                </p>
                <p className="font-12 font-medium secondary-text-color-v4 mb-1 d-flex align-items-center text-center">
                  <span className="legend legend-red-lite me-1" />
                  Low
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* chart ends here */}
    </>
  )
}
export default ChartComponent;