// values["region"] = "us-east";
// values["workload"] = "jobs-compute";
// values["tier"] = "premium";
// values["category"] = "computeoptimized";
// values["clustersize"] = "twoxsmall";
// values["clusters"] = "1";
// values["dbuHours"] = "1";
// values["dbuHoursFactor"] = "1";
// values["instance"] = "1";
// values["instanceHours"] = "1";
// values["instanceHoursFactor"] = "1";
// values["count"] = "1";
// values["hoursFactor"] = "1";
// values["hours"] = "1";
// values["instanceSeries"] = "f";
// values["instanceType"] = "F8:8"; 

export default function Azure_Databrick({ values, onChangeOfConfiguration, isViewer }) {
    try {
        return (
            <div id={`${values.instanceName.split(" ").join("_")}`} className="accordion-collapse collapse">
                <div className="accordioan-body p-0 ">
                    <table className="table table-borderless cg-grid font-14 font-semibold mb-0">
                        <tbody>
                            <tr className="ms-acc-data-align">
                                <td colSpan={3}>
                                    <div className="row py-4 mx-4 px-5" hidden={(values.isPriceAvailable == "No") ? false : true}>
                                        <div className="col-md-6 px-3"><span className="font-semibold font-14 text-danger">{values.isPriceAvailable == "No" ? "The Price is not available for the region / the configuration that you have selected might be not available in the region" : null}</span></div>
                                    </div>
                                    <div className="row py-4 mx-4 px-5">
                                        <div className="col-md-6 px-3">
                                            <label htmlFor="region" className="form-label font-14 font-medium black-v2 mb-3">
                                                Region
                                            </label>
                                            <select
                                                className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                id="region"
                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                value={values.region}
                                                name={"region"}
                                                onChange={(e) => {
                                                    onChangeOfConfiguration(e, values.instanceName);
                                                }} disabled={isViewer}
                                            >

                                                <optgroup label="United States">
                                                    <option value="us-central">Central US</option>
                                                    <option value="us-east">East US</option>
                                                    <option value="us-east-2">East US 2</option>
                                                    <option value="us-north-central">North Central US</option>
                                                    <option value="us-south-central">South Central US</option>
                                                    <option value="us-west-central">West Central US</option>
                                                    <option value="us-west">West US</option>
                                                    <option value="us-west-2">West US 2</option>
                                                    <option value="us-west-3">West US 3</option>
                                                </optgroup>
                                                <optgroup label="United Kingdom">
                                                    <option value="united-kingdom-south">UK South</option>
                                                    <option value="united-kingdom-west">UK West</option>
                                                </optgroup>
                                                <optgroup label="United Arab Emirates">
                                                    <option value="uae-central">UAE Central</option>
                                                    <option value="uae-north">UAE North</option>
                                                </optgroup>
                                                <optgroup label="Switzerland">
                                                    <option value="switzerland-north">Switzerland North</option>
                                                    <option value="switzerland-west">Switzerland West</option>
                                                </optgroup>
                                                <optgroup label="Sweden">
                                                    <option value="sweden-central">Sweden Central</option>
                                                    <option value="sweden-south">Sweden South</option>
                                                </optgroup>
                                                <optgroup label="Spain">
                                                    <option value="spain-central">Spain Central</option>
                                                </optgroup>
                                                <optgroup label="Qatar">
                                                    <option value="qatar-central">Qatar Central</option>
                                                </optgroup>
                                                <optgroup label="Poland">
                                                    <option value="poland-central">Poland Central</option>
                                                </optgroup>
                                                <optgroup label="Norway">
                                                    <option value="norway-east">Norway East</option>
                                                    <option value="norway-west">Norway West</option>
                                                </optgroup>
                                                <optgroup label="Mexico">
                                                    <option value="mexico-central">Mexico Central</option>
                                                </optgroup>
                                                <optgroup label="Korea">
                                                    <option value="korea-central">Korea Central</option>
                                                    <option value="korea-south">Korea South</option>
                                                </optgroup>
                                                <optgroup label="Japan">
                                                    <option value="japan-east">Japan East</option>
                                                    <option value="japan-west">Japan West</option>
                                                </optgroup>
                                                <optgroup label="Italy">
                                                    <option value="italy-north">Italy North</option>
                                                </optgroup>
                                                <optgroup label="Israel">
                                                    <option value="israel-central">Israel Central</option>
                                                </optgroup>
                                                <optgroup label="India">
                                                    <option value="central-india">Central India</option>
                                                    <option value="south-india">South India</option>
                                                    <option value="west-india">West India</option>
                                                </optgroup>
                                                <optgroup label="Germany">
                                                    <option value="germany-north">Germany North</option>
                                                    <option value="germany-west-central">Germany West Central</option>
                                                </optgroup>
                                                <optgroup label="France">
                                                    <option value="france-central">France Central</option>
                                                    <option value="france-south">France South</option>
                                                </optgroup>
                                                <optgroup label="Europe">
                                                    <option value="europe-north">North Europe</option>
                                                    <option value="europe-west">West Europe</option>
                                                </optgroup>
                                                <optgroup label="Canada">
                                                    <option value="canada-central">Canada Central</option>
                                                    <option value="canada-east">Canada East</option>
                                                </optgroup>
                                                <optgroup label="Brazil">
                                                    <option value="brazil-south">Brazil South</option>
                                                    <option value="brazil-southeast">Brazil Southeast</option>
                                                </optgroup>
                                                <optgroup label="Australia">
                                                    <option value="australia-central">Australia Central</option>
                                                    <option value="australia-central-2">Australia Central 2</option>
                                                    <option value="australia-east">Australia East</option>
                                                    <option value="australia-southeast">Australia Southeast</option>
                                                </optgroup>
                                                <optgroup label="Asia Pacific">
                                                    <option value="asia-pacific-east">East Asia</option>
                                                    <option value="asia-pacific-southeast">Southeast Asia</option>
                                                </optgroup>
                                                <optgroup label="Africa">
                                                    <option value="south-africa-north">South Africa North</option>
                                                    <option value="south-africa-west">South Africa West</option>
                                                </optgroup>
                                            </select>
                                        </div>

                                        <div className="col-md-6 px-3">
                                            <label htmlFor="workload" className="form-label font-14 font-medium black-v2 mb-3">
                                                Workload
                                            </label>
                                            <select
                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                id="workload"
                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                value={values.workload}
                                                name={"workload"}
                                                onChange={(e) => {
                                                    onChangeOfConfiguration(e, values.instanceName);
                                                }} disabled={isViewer}>
                                                <option value="all-purpose-compute" >All-Purpose Compute</option>
                                                <option value="jobs-compute" >Jobs Compute</option>
                                                <option value="sql-compute" >SQL Compute</option>
                                                <option value="sql-serverless" >Serverless SQL</option>
                                            </select>
                                        </div>

                                        {values.workload === "sql-serverless" && <>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="tier" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Tier
                                                </label>
                                                <select
                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                    id="tier"
                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                    value={values.tier}
                                                    name={"tier"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}>
                                                    <option value="premium" >Premium</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="category" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Category
                                                </label>
                                                <select
                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                    id="category"
                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                    value={values.category}
                                                    name={"category"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}>
                                                    <option value="generalpurpose" >General Purpose</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Cluster Size
                                                </label>
                                                <select
                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                    id="clustersize"
                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                    value={values.clustersize}
                                                    name={"clustersize"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}>
                                                    <option value="twoxsmall" >2X-Small, 4 Databrick Unit(s)</option>
                                                    <option value="xsmall" >X-Small, 6 Databrick Unit(s)</option>
                                                    <option value="small" >Small, 12 Databrick Unit(s)</option>
                                                    <option value="medium" >Medium, 24 Databrick Unit(s) </option>
                                                    <option value="large" >Large, 40 Databrick Unit(s) </option>
                                                    <option value="xlarge" >X-Large, 80 Databrick Unit(s) </option>
                                                    <option value="twoxlarge" >2X-Large, 144 Databrick Unit(s) </option>
                                                    <option value="threexlarge" >3X-Large, 272 Databrick Unit(s) </option>
                                                    <option value="fourxlarge" >4X-Large, 528 Databrick Unit(s) </option>
                                                </select>
                                            </div>

                                            <div className="col-md-6 px-3">
                                                <label htmlFor="clusters" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Clusters
                                                </label>
                                                <input
                                                    type="text"
                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                    id="clusters"
                                                    value={values.clusters}
                                                    name={"clusters"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}
                                                />
                                            </div>

                                            <div className="col-md-6 px-3">
                                                <label htmlFor="dbuHours" className="form-label font-14 font-medium black-v2 mb-3">
                                                    DBU (Databricks Unit) Hours
                                                </label>
                                                <input
                                                    type="text"
                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                    id="dbuHours"
                                                    value={values.dbuHours}
                                                    name={"dbuHours"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}
                                                />
                                            </div>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="dbuHoursFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                                    DBU (Databricks Unit) Hours Factor
                                                </label>
                                                <select
                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                    id="dbuHoursFactor"
                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                    value={values.dbuHoursFactor}
                                                    name={"dbuHoursFactor"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}>
                                                    <option value="1" >Hours</option>
                                                    <option value="24" >Days</option>
                                                    <option value="730" >Month</option>
                                                </select>
                                            </div>
                                        </>}

                                        {values.workload === "sql-compute" && <>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="tier" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Tier
                                                </label>
                                                <select
                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                    id="tier"
                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                    value={values.tier}
                                                    name={"tier"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}>
                                                    <option value="premium" >Premium</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="category" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Category
                                                </label>
                                                <select
                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                    id="category"
                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                    value={values.category}
                                                    name={"category"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}>
                                                    <option value="generalpurpose" >General Purpose</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Cluster Size
                                                </label>
                                                <select
                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                    id="clustersize"
                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                    value={values.clustersize}
                                                    name={"clustersize"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}>
                                                    <option value="twoxsmall">2X-Small, 4 Database Units, 1 Driver, 1 Workers</option>
                                                    <option value="xsmall">X-Small, 6 Database Units, 1 Driver, 2 Workers</option>
                                                    <option value="small">Small, 12 Database Units, 1 Driver, 4 Workers</option>
                                                    <option value="medium">Medium, 24 Database Units, 1 Driver, 8 Workers</option>
                                                    <option value="large">Large, 40 Database Units, 1 Driver, 16 Workers</option>
                                                    <option value="xlarge">X-Large, 80 Database Units, 1 Driver, 32 Workers</option>
                                                    <option value="twoxlarge">2X-Large, 144 Database Units, 1 Driver, 64 Workers</option>
                                                    <option value="threexlarge">3X-Large, 272 Database Units, 1 Driver, 128 Workers</option>
                                                    <option value="fourxlarge">4X-Large, 528 Database Units, 1 Driver, 256 Workers</option>
                                                </select>
                                            </div>

                                            <div className="col-md-6 px-3">
                                                <label htmlFor="instance" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Compute - Instance
                                                </label>
                                                <input
                                                    type="text"
                                                    style={{paddingTop :"10px"}}
                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                    id="instance"
                                                    value={values.instance}
                                                    name={"instance"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}
                                                />
                                            </div>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="instanceHours" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Compute - Instance Hours
                                                </label>
                                                <input
                                                    type="text"
                                                    style={{paddingTop :"10px"}}
                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                    id="instanceHours"
                                                    value={values.instanceHours}
                                                    name={"instanceHours"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}
                                                />
                                            </div>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="instanceHoursFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Compute - Instance Hours Factor
                                                </label>
                                                <select
                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                    id="instanceHoursFactor"
                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                    value={values.instanceHoursFactor}
                                                    name={"instanceHoursFactor"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}>
                                                    <option value="1" >Hours</option>
                                                    <option value="24" >Days</option>
                                                    <option value="730" >Month</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="dbuHours" className="form-label font-14 font-medium black-v2 mb-3">
                                                    DBU (Databricks Unit) Hours
                                                </label>
                                                <input
                                                    type="text"
                                                    style={{paddingTop :"10px"}}
                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                    id="dbuHours"
                                                    value={values.dbuHours}
                                                    name={"dbuHours"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}
                                                />
                                            </div>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="dbuHoursFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                                    DBU (Databricks Unit) Hours Factor
                                                </label>
                                                <select
                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                    id="dbuHoursFactor"
                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                    value={values.dbuHoursFactor}
                                                    name={"dbuHoursFactor"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}>
                                                    <option value="1" >Hours</option>
                                                    <option value="24" >Days</option>
                                                    <option value="730" >Month</option>
                                                </select>
                                            </div>
                                        </>}

                                        {values.workload === "all-purpose-compute" && <>
                                            <div>
                                                <label htmlFor="tier" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Tier
                                                </label>
                                                <select
                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                    id="tier"
                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                    value={values.tier}
                                                    name={"tier"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}>
                                                    <option value="standard" >Standard</option>
                                                    <option value="premium" >Premium</option>
                                                </select>
                                            </div>

                                            <div className="col-md-6 px-3">
                                                <label htmlFor="category" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                    Category
                                                </label>
                                                <select
                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3    "
                                                    id="category"
                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                    value={values.category}
                                                    name={"category"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}>
                                                    <option value="computeoptimized" class="">Compute optimised</option>
                                                    <option value="generalpurpose" class="">General purpose</option>
                                                    <option value="gpu" class="">GPU</option>
                                                    <option value="memoryoptimized" class="">Memory optimised</option>
                                                    <option value="storageoptimized" class="">Storage optimised</option>
                                                </select>
                                            </div>

                                            {values.category === "computeoptimized" && <>
                                                <div className="col-md-6">
                                                    <label htmlFor="instanceSeries" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                        Instance Series
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="instanceSeries"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.instanceSeries}
                                                        name={"instanceSeries"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="f" class="">F-series</option>
                                                        <option value="fs" class="">Fs-series</option>
                                                        <option value="fsv2" class="">Fsv2-series</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {values.category === "generalpurpose" && <>
                                                <div className="col-md-6">
                                                    <label htmlFor="instanceSeries" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                        Instance Series
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="instanceSeries"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.instanceSeries}
                                                        name={"instanceSeries"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="dav4" class="">Da v4-series</option>
                                                        <option value="dadsv5" class="">Dadsv5-series</option>
                                                        <option value="dasv4" class="">Das v4-series</option>
                                                        <option value="dasv5" class="">Dasv5-series</option>
                                                        <option value="dcasv5" class="">DCasv5-series</option>
                                                        <option value="ddv4" class="">Dd v4-series</option>
                                                        <option value="ddsv4" class="">Dds v4-series</option>
                                                        <option value="ddsv5" class="">Ddsv5-series</option>
                                                        <option value="dsv2" class="">Dsv2-series</option>
                                                        <option value="dsv3" class="">Dsv3-series</option>
                                                        <option value="dsv5" class="">Dsv5-series</option>
                                                        <option value="dv2" class="">Dv2-series</option>
                                                        <option value="dv3" class="">Dv3-series</option>
                                                        <option value="ecadsv5" class="">ECadsv5-series</option>
                                                        <option value="ecasv5" class="">ECasv5-series</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {values.category === "gpu" && <>
                                                <div className="col-md-6">
                                                    <label htmlFor="instanceSeries" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                        Instance Series
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="instanceSeries"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.instanceSeries}
                                                        name={"instanceSeries"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="nca100v4" class="">NC A100 v4-series</option>
                                                        <option value="ncast4v3" class="">NC_T4_v3-series</option>
                                                        <option value="nc" class="">NC-series</option>
                                                        <option value="ncsv3" class="">NCsv3-series</option>
                                                        <option value="ndasrv4" class="">ND A100 v4-series</option>
                                                        <option value="nvv5" class="">NVads A10 v5-series</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {values.category === "memoryoptimized" && <>
                                                <div className="col-md-6">
                                                    <label htmlFor="instanceSeries" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                        Instance Series
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="instanceSeries"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.instanceSeries}
                                                        name={"instanceSeries"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="dsv2" class="">Dsv2-series</option>
                                                        <option value="dv2" class="">Dv2-series</option>
                                                        <option value="eav4" class="">Ea v4-series</option>
                                                        <option value="eadsv5" class="">Eadsv5-series</option>
                                                        <option value="easv4" class="">Eas v4-series</option>
                                                        <option value="easv5" class="">Easv5-series</option>
                                                        <option value="edv4" class="">Ed v4-series</option>
                                                        <option value="edsv4" class="">Eds v4-series</option>
                                                        <option value="edsv5" class="">Edsv5-series</option>
                                                        <option value="esv3" class="">Esv3-series</option>
                                                        <option value="esv4" class="">Esv4-series</option>
                                                        <option value="esv5" class="">Esv5-series</option>
                                                        <option value="ev3" class="">Ev3-series</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {values.category === "storageoptimized" && <>
                                                <div className="col-md-6">
                                                <label htmlFor="instanceSeries" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                        Instance Series
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="instanceSeries"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.instanceSeries}
                                                        name={"instanceSeries"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="lasv3" class="">Lasv3-series</option>
                                                        <option value="lsv2" class="">Lsv2-series</option>
                                                        <option value="lsv3" class="">Lsv3-series</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {/* computeoptimized and aginst instanceSeries */}
                                            {(values.category === "computeoptimized" && values.instanceSeries == "f") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3">
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="F4: 4">F4: 4 vCPUs, 8 GB RAM, 64 GB Temporary storage, 0.50 Databricks Unit(s)</option>
                                                        <option value="F8: 8">F8: 8 vCPUs, 16 GB RAM, 128 GB Temporary storage, 1.00 Databricks Unit(s)</option>
                                                        <option value="F16: 16">F16: 16 vCPUs, 32 GB RAM, 256 GB Temporary storage, 2.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "computeoptimized" && values.instanceSeries == "fs") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" >
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="F4s: 4">F4s: 4 vCPUs, 8 GB RAM, 16 GB Temporary storage, 0.50 Databricks Unit(s)</option>
                                                        <option value="F8s: 8">F8s: 8 vCPUs, 16 GB RAM, 32 GB Temporary storage, 1.00 Databricks Unit(s)</option>
                                                        <option value="F16s: 16">F16s: 16 vCPUs, 32 GB RAM, 256 GB Temporary storage, 2.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}
                                            {(values.category === "computeoptimized" && values.instanceSeries == "fsv2") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3">
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="F4s v2">F4s v2: 4 vCPUs, 8 GB RAM, 32 GB Temporary storage, 0.75 Databricks Unit(s)</option>
                                                        <option value="F8s v2">F8s v2: 8 vCPUs, 16 GB RAM, 64 GB Temporary storage, 1.50 Databricks Unit(s)</option>
                                                        <option value="F16s v2">F16s v2: 16 vCPUs, 32 GB RAM, 128 GB Temporary storage, 3.00 Databricks Unit(s)</option>
                                                        <option value="F32s v2">F32s v2: 32 vCPUs, 64 GB RAM, 256 GB Temporary storage, 6.00 Databricks Unit(s)</option>
                                                        <option value="F64s v2">F64s v2: 64 vCPUs, 128 GB RAM, 512 GB Temporary storage, 12.00 Databricks Unit(s)</option>
                                                        <option value="F64s v2">F64s v2: 64 vCPUs, 128 GB RAM, 512 GB Temporary storage, 12.00 Databricks Unit(s)</option>
                                                        <option value="F72s v2">F72s v2: 72 vCPUs, 144 GB RAM, 576 GB Temporary storage, 13.50 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {/* generalpurpose and aginst instanceSeries */}
                                            {(values.category === "generalpurpose" && values.instanceSeries == "dav4") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3">
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="D4a v4">D4a v4: 4 vCPUs, 16 GB RAM, 100 GB Temporary storage, 0.75 Databricks Unit(s)</option>
                                                        <option value="D8a v4">D8a v4: 8 vCPUs, 32 GB RAM, 200 GB Temporary storage, 1.50 Databricks Unit(s)</option>
                                                        <option value="D16a v4">D16a v4: 16 vCPUs, 64 GB RAM, 400 GB Temporary storage, 3.00 Databricks Unit(s)</option>
                                                        <option value="D32a v4">D32a v4: 32 vCPUs, 128 GB RAM, 800 GB Temporary storage, 6.00 Databricks Unit(s)</option>
                                                        <option value="D48a v4">D48a v4: 48 vCPUs, 192 GB RAM, 1200 GB Temporary storage, 9.00 Databricks Unit(s)</option>
                                                        <option value="D64a v4">D64a v4: 64 vCPUs, 256 GB RAM, 1600 GB Temporary storage, 12.00 Databricks Unit(s)</option>
                                                        <option value="D96a v4">D96a v4: 96 vCPUs, 384 GB RAM, 2400 GB Temporary storage, 18.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "generalpurpose" && values.instanceSeries == "dadsv5") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" >
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="D96ads v5">D96ads v5: 96 vCPUs, 384 GB RAM, 3600 GB Temporary storage, 24.00 Databricks Unit(s)</option>
                                                        <option value="D4ads v5">D4ads v5: 4 vCPUs, 16 GB RAM, 150 GB Temporary storage, 1.00 Databricks Unit(s)</option>
                                                        <option value="D8ads v5">D8ads v5: 8 vCPUs, 32 GB RAM, 300 GB Temporary storage, 2.00 Databricks Unit(s)</option>
                                                        <option value="D16ads v5">D16ads v5: 16 vCPUs, 64 GB RAM, 600 GB Temporary storage, 4.00 Databricks Unit(s)</option>
                                                        <option value="D32ads v5">D32ads v5: 32 vCPUs, 128 GB RAM, 1200 GB Temporary storage, 8.00 Databricks Unit(s)</option>
                                                        <option value="D48ads v5">D48ads v5: 48 vCPUs, 192 GB RAM, 1800 GB Temporary storage, 12.00 Databricks Unit(s)</option>
                                                        <option value="D64ads v5">D64ads v5: 64 vCPUs, 256 GB RAM, 2400 GB Temporary storage, 16.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "generalpurpose" && values.instanceSeries == "dasv4") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" >
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="D8as v4">D8as v4: 8 vCPUs, 32 GB RAM, 64 GB Temporary storage, 1.50 Databricks Unit(s)</option>
                                                        <option value="D16as v4">D16as v4: 16 vCPUs, 64 GB RAM, 128 GB Temporary storage, 3.00 Databricks Unit(s)</option>
                                                        <option value="D32as v4">D32as v4: 32 vCPUs, 128 GB RAM, 256 GB Temporary storage, 6.00 Databricks Unit(s)</option>
                                                        <option value="D48as v4">D48as v4: 48 vCPUs, 192 GB RAM, 384 GB Temporary storage, 9.00 Databricks Unit(s)</option>
                                                        <option value="D64as v4">D64as v4: 64 vCPUs, 256 GB RAM, 512 GB Temporary storage, 12.00 Databricks Unit(s)</option>
                                                        <option value="D96as v4">D96as v4: 96 vCPUs, 384 GB RAM, 768 GB Temporary storage, 18.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "generalpurpose" && values.instanceSeries == "dasv5") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" >
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="D4as v5">D4as v5: 4 vCPUs, 16 GB RAM, O GB Temporary storage, 1.00 Databricks Unit(s)</option>
                                                        <option value="D8as v5">D8as v5: 8 vCPUs, 32 GB RAM, O GB Temporary storage, 2.00 Databricks Unit(s)</option>
                                                        <option value="D16as v5">D16as v5: 16 vCPUs, 64 GB RAM, 0 GB Temporary storage, 4.00 Databricks Unit(s)</option>
                                                        <option value="D32as v5">D32as v5: 32 vCPUs, 128 GB RAM, 0 GB Temporary storage, 8.00 Databricks Unit(s)</option>
                                                        <option value="D48as v5">D48as v5: 48 vCPUs, 192 GB RAM, 0 GB Temporary storage, 12.00 Databricks Unit(s)</option>
                                                        <option value="D64as v5">D64as v5: 64 vCPUs, 256 GB RAM, 0 GB Temporary storage, 16.00 Databricks Unit(s)</option>
                                                        <option value="D96as v5:">D96as v5: 96 vCPUs, 384 GB RAM, 0 GB Temporary storage, 24.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "generalpurpose" && values.instanceSeries == "dcasv5") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3">
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="DC4as v5">DC4as v5: 4 vCPUs, 16 GB RAM, 0 GB Temporary storage, 1.00 Databricks Unit(s)</option>
                                                        <option value="DC8as v5">DC8as v5: 8 vCPUs, 32 GB RAM, 0 GB Temporary storage, 2.00 Databricks Unit(s)</option>
                                                        <option value="DC16as v5">DC16as v5: 16 vCPUs, 64 GB RAM, 0 GB Temporary storage, 4.00 Databricks - Unit(s)</option>
                                                        <option value="DC32as v5">DC32as v5: 32 vCPUs, 128 GB RAM, 0 GB Temporary storage, 8.00 Databricks</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "generalpurpose" && values.instanceSeries == "ddv4") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3">
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="D4d v4">D4d v4: 4 vCPUs, 16 GB RAM, 150 GB Temporary storage, 0.75 Databricks Unit(s)</option>
                                                        <option value="D8d v4">D8d v4: 8 vCPUs, 32 GB RAM, 300 GB Temporary storage, 1.50 Databricks Unit(s)</option>
                                                        <option value="D16d v4">D16d v4: 16 vCPUs, 64 GB RAM, 600 GB Temporary storage, 3.00 Databricks Unit(s)</option>
                                                        <option value="D32d v4">D32d v4: 32 vCPUs, 128 GB RAM, 1200 GB Temporary storage, 6.00 Databricks Unit(s)</option>
                                                        <option value="D48d v4">D48d v4: 48 vCPUs, 192 GB RAM, 1800 GB Temporary storage, 9.00 Databricks Unit(s)</option>
                                                        <option value="D64d v4">D64d v4: 64 vCPUs, 256 GB RAM, 2400 GB Temporary storage, 12.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "generalpurpose" && values.instanceSeries == "ddsv4") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" >
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="D4ds v4">D4ds v4: 4 vCPUs, 16 GB RAM, 150 GB Temporary storage, 0.75 Databricks Unit(s)</option>
                                                        <option value="D8ds v4">D8ds v4: 8 vCPUs, 32 GB RAM, 300 GB Temporary storage, 1.50 Databricks Unit(s)</option>
                                                        <option value="D16ds v4">D16ds v4: 16 vCPUs, 64 GB RAM, 600 GB Temporary storage, 3.00 Databricks Unit(s)</option>
                                                        <option value="D32ds v4">D32ds v4: 32 vCPUs, 128 GB RAM, 1200 GB Temporary storage, 6.00 Databricks Unit(s)</option>
                                                        <option value="D48ds v4">D48ds v4: 48 vCPUs, 192 GB RAM, 1800 GB Temporary storage, 9.00 Databricks Unit(s)</option>
                                                        <option value="D64ds v4">D64ds v4: 64 vCPUs, 256 GB RAM, 2400 GB Temporary storage, 12.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "generalpurpose" && values.instanceSeries == "ddsv5") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3">
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="D4ds v5">D4ds v5: 4 vCPUs, 16 GB RAM, 150 GB Temporary storage, 1.00 Databricks Unit(s)</option>
                                                        <option value="D8ds v5">D8ds v5: 8 vCPUs, 32 GB RAM, 300 GB Temporary storage, 2.00 Databricks Unit(s)</option>
                                                        <option value="D16ds v5">D16ds v5: 16 vCPUs, 64 GB RAM, 600 GB Temporary storage, 4.00 Databricks Unit(s)</option>
                                                        <option value="D32ds v5: 32">D32ds v5: 32 vCPUs, 128 GB RAM, 1200 GB Temporary storage, 8.00 Databricks Unit(s)</option>
                                                        <option value="D32ds v5: 48">D32ds v5: 48 vCPUs, 192 GB RAM, 1800 GB Temporary storage, 12.00 Databricks Unit(s)</option>
                                                        <option value="D64ds v5: 6">D64ds v5: 64 vCPUs, 256 GB RAM, 2400 GB Temporary storage, 16.00 Databricks Unit(s)</option>
                                                        <option value="D96ds v5: 96">D96ds v5: 96 vCPUs, 384 GB RAM, 3600 GB Temporary storage, 24.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "generalpurpose" && values.instanceSeries == "dsv2") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" >
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="DS12 v2: 4">DS12 v2: 4 vCPUs, 28 GB RAM, 56 GB Temporary storage, 1.00 Databricks Unit(s)</option>
                                                        <option value="DS3 v2: 4">DS3 v2: 4 vCPUs, 14 GB RAM, 28 GB Temporary storage, 0.75 Databricks Unit(s)</option>
                                                        <option value="DS13 v2: 8 ">DS13 v2: 8 vCPUs, 56 GB RAM, 112 GB Temporary storage, 2.00 Databricks Unit(s)</option>
                                                        <option value="DS4 v2: 8">DS4 v2: 8 vCPUs, 28 GB RAM, 56 GB Temporary storage, 1.50 Databricks Unit(s)</option>
                                                        <option value="DS14 v2: 16">DS14 v2: 16 vCPUs, 112 GB RAM, 224 GB Temporary storage, 4.00 Databricks Unit(s)</option>
                                                        <option value="DS5 v2: 16">DS5 v2: 16 vCPUs, 56 GB RAM, 112 GB Temporary storage, 3.00 Databricks Unit(s)</option>
                                                        <option value="DS15 v2: 20">DS15 v2: 20 vCPUs, 140 GB RAM, 280 GB Temporary storage, 5.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "generalpurpose" && values.instanceSeries == "dsv3") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" >
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="D4s v3: 4">D4s v3: 4 vCPUs, 16 GB RAM, 32 GB Temporary storage, 0.75 Databricks Unit(s)</option>
                                                        <option value="D8s v3: 8 ">D8s v3: 8 vCPUs, 32 GB RAM, 64 GB Temporary storage, 1.50 Databricks Unit(s)</option>
                                                        <option value="D16s v3: 16">D16s v3: 16 vCPUs, 64 GB RAM, 128 GB Temporary storage, 3.00 Databricks Unit(s)</option>
                                                        <option value="D32s v3: 32">D32s v3: 32 vCPUs, 128 GB RAM, 256 GB Temporary storage, 6.00 Databricks Unit(s)</option>
                                                        <option value="D64s v3: 64">D64s v3: 64 vCPUs, 256 GB RAM, 512 GB Temporary storage, 12.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "generalpurpose" && values.instanceSeries == "dsv5") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3">
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="D4s v5: 4">D4s v5: 4 vCPUs, 16 GB RAM, 0 GB Temporary storage, 1.00 Databricks Unit(s)</option>
                                                        <option value="D8s v5: 8">D8s v5: 8 vCPUs, 32 GB RAM, 0 GB Temporary storage, 2.00 Databricks Unit(s)</option>
                                                        <option value="D16s v5: 16">D16s v5: 16 vCPUs, 64 GB RAM, 0 GB Temporary storage, 4.00 Databricks Unit(s)</option>
                                                        <option value="D32s v5: 32">D32s v5: 32 vCPUs, 128 GB RAM, 0 GB Temporary storage, 8.00 Databricks Unit(s) </option>
                                                        <option value="D48s v5: 48">D48s v5: 48 vCPUs, 192 GB RAM, 0 GB Temporary storage, 12.00 Databricks Unit(s)</option>
                                                        <option value="D64s v5: 64">D64s v5: 64 vCPUs, 256 GB RAM, 0 GB Temporary storage, 16.00 Databricks Unit(s)</option>
                                                        <option value="D96s v5: 96">D96s v5: 96 vCPUs, 384 GB RAM, 0 GB Temporary storage, 24.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "generalpurpose" && values.instanceSeries == "dv2") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3">
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="D3 v2: 4">D3 v2: 4 vCPUs, 14 GB RAM, 200 GB Temporary storage, 0.75 Databricks Unit(s)</option>
                                                        <option value="D4 v2: 8">D4 v2: 8 vCPUs, 28 GB RAM, 400 GB Temporary storage, 1.50 Databricks Unit(s)</option>
                                                        <option value="D5 v2: 16">D5 v2: 16 vCPUs, 56 GB RAM, 800 GB Temporary storage, 3.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "generalpurpose" && values.instanceSeries == "dv3") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3">
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="D8 v3: 8">D8 v3: 8 vCPUs, 32 GB RAM, 200 GB Temporary storage, 1.50 Databricks Unit(s)</option>
                                                        <option value="D16 v3: 16">D16 v3: 16 vCPUs, 64 GB RAM, 400 GB Temporary storage, 3.00 Databricks Unit(s)</option>
                                                        <option value="D32 v3: 32">D32 v3: 32 vCPUs, 128 GB RAM, 800 GB Temporary storage, 6.00 Databricks Unit(s), </option>
                                                        <option value="D64 v3: 64">D64 v3: 64 vCPUs, 256 GB RAM, 1600 GB Temporary storage, 12.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "generalpurpose" && values.instanceSeries == "ecadsv5") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" >
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="EC8ads v5: 8">EC8ads v5: 8 vCPUs, 64 GB RAM, 300 GB Temporary storage, 2.75 Databricks Unit(s)</option>
                                                        <option value="EC16ads v5: 16">EC16ads v5: 16 vCPUs, 128 GB RAM, 600 GB Temporary storage, 5.50 Databricks Unit(s)</option>
                                                        <option value="EC32ads v5: 32">EC32ads v5: 32 vCPUs, 192 GB RAM, 1200 GB Temporary storage, 11.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "generalpurpose" && values.instanceSeries == "ecasv5") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" >
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="EC8as v5: 8">EC8as v5: 8 vCPUs, 64 GB RAM, 0 GB Temporary storage, 2.75 Databricks Unit(s)</option>
                                                        <option value="EC16as v5: 16">EC16as v5: 16 vCPUs, 128 GB RAM, 0 GB Temporary storage, 5.50 Databricks Unit(s)</option>
                                                        <option value="EC32as v5: 32">EC32as v5: 32 vCPUs, 192 GB RAM, 0 GB Temporary storage, 11.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {/* gpu and aginst instanceSeries  */}
                                            {(values.category === "gpu" && values.instanceSeries == "nca100v4") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" >
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="NC24ads A100 v4">NC24ads A100 v4: 24 Cores, 220 GB RAM, 958 GB Temporary storage, 10.00 Databricks Unit(s)</option>
                                                        <option value="NC48ads A100 v4">NC48ads A100 v4: 48 Cores, 440 GB RAM, 1916 GB Temporary storage, 20.00 Databricks Unit(s)</option>
                                                        <option value="NC96ads A100 v4">NC96ads A100 v4: 96 Cores, 880 GB RAM, 3832 GB Temporary storage, 44.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "gpu" && values.instanceSeries == "ncast4v3") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3">
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="NC4as T4 v3: 4 ">NC4as T4 v3: 4 vCPUs, 28 GB RAM, 180 GB Temporary storage, 1.00 Databricks Unit(s)</option>
                                                        <option value="NC8as T4 v3: 8 ">NC8as T4 v3: 8 vCPUs, 56 GB RAM, 360 GB Temporary storage, 1.50 Databricks Unit(s)</option>
                                                        <option value="NC16as T4 v3: 16">NC16as T4 v3: 16 vCPUs, 110 GB RAM, 360 GB Temporary storage, 2.50 Databricks Unit(s)</option>
                                                        <option value="NC64as T4 v3: 64">NC64as T4 v3: 64 vCPUs, 440 GB RAM, 2880 GB Temporary storage, 10.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "gpu" && values.instanceSeries == "nc") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" >
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="NC12: 12">NC12: 12 vCPUs, 112 GB RAM, 680 GB Temporary storage, 3.00 Databricks Unit(s)</option>
                                                        <option value="NC24: 24">NC24: 24 vCPUs, 224 GB RAM, 1440 GB Temporary storage, 6.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "gpu" && values.instanceSeries == "ncsv3") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3">
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="NC6s v3: 6">NC6s v3: 6 vCPUs, 112 GB RAM, 736 GB Temporary storage, 5.00 Databricks Unit(s)</option>
                                                        <option value="NC12s v3: 12">NC12s v3: 12 vCPUs, 224 GB RAM, 1474 GB Temporary storage, 10.00 Databricks Unit(s)</option>
                                                        <option value="NC24s v3: 24">NC24s v3: 24 vCPUs, 448 GB RAM, 2948 GB Temporary storage, 20.00 Databricks Unit(s), </option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "gpu" && values.instanceSeries == "ndasrv4") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" >
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="ND96asr: 96">ND96asr: 96 Cores, 900 GB RAM, 6500 GB Temporary storage, 44.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "gpu" && values.instanceSeries == "nvv5") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" >
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="NV36adms A10 v5">NV36adms A10 v5: 36 vCPUs, 880 GB RAM, 720 GB Temporary storage, 6.00 Databricks Unit(s)</option>
                                                        <option value="NV36ads A10 v5">NV36ads A10 v5: 36 vCPUs, 440 GB RAM, 720 GB Temporary storage, 4.00 Databricks Unit(s)</option>
                                                        <option value="NV72ads A10 v5">NV72ads A10 v5: 72 vCPUs, 880 GB RAM, 1400 GB Temporary storage, 8.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {/* memoryoptimized and aginst instanceSeries  */}
                                            {(values.category === "memoryoptimized" && values.instanceSeries == "dsv2") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3">
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="DS12 v2: 4">DS12 v2: 4 vCPUs, 28 GB RAM, 56 GB Temporary storage, 1.00 Databricks Unit(s)</option>
                                                        <option value="DS13 v2: 8">DS13 v2: 8 vCPUs, 56 GB RAM, 112 GB Temporary storage, 2.00 Databricks Unit(s)</option>
                                                        <option value="DS14 v2: 16">DS14 v2: 16 vCPUs, 112 GB RAM, 224 GB Temporary storage, 4.00 Databricks Unit(s)</option>
                                                        <option value="DS15 v2: 20">DS15 v2: 20 vCPUs, 140 GB RAM, 280 GB Temporary storage, 5.00 Databricks Unit(s), </option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "memoryoptimized" && values.instanceSeries == "dv2") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3">
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="D12 v2: 4">D12 v2: 4 vCPUs, 28 GB RAM, 200 GB Temporary storage, 1.00 Databricks Unit(s)</option>
                                                        <option value="D13 v2: 8">D13 v2: 8 vCPUs, 56 GB RAM, 400 GB Temporary storage, 2.00 Databricks Unit(s)</option>
                                                        <option value="D14 v2: 16">D14 v2: 16 vCPUs, 112 GB RAM, 800 GB Temporary storage, 4.00 Databricks Unit(s)</option>
                                                        <option value="D15 v2: 20">D15 v2: 20 vCPUs, 140 GB RAM, 1000 GB Temporary storage, 5.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "memoryoptimized" && values.instanceSeries == "eav4") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3">
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="E4a v4: 4">E4a v4: 4 vCPUs, 32 GB RAM, 100 GB Temporary storage, 1.00 Databricks Unit(s)</option>
                                                        <option value="E8a v4: 8">E8a v4: 8 vCPUs, 64 GB RAM, 200 GB Temporary storage, 2.00 Databricks Unit(s)</option>
                                                        <option value="E16a v4: 16">E16a v4: 16 vCPUs, 128 GB RAM, 400 GB Temporary storage, 4.00 Databricks Unit(s)</option>
                                                        <option value="E20a v4: 20">E20a v4: 20 vCPUs, 160 GB RAM, 500 GB Temporary storage, 5.00 Databricks Unit(s)</option>
                                                        <option value="E32a v4: 32">E32a v4: 32 vCPUs, 256 GB RAM, 800 GB Temporary storage, 8.00 Databricks Unit(s)</option>
                                                        <option value="E48a v4: 48">E48a v4: 48 vCPUs, 384 GB RAM, 1200 GB Temporary storage, 12.00 Databricks Unit(s)</option>
                                                        <option value="E64a v4: 64">E64a v4: 64 vCPUs, 512 GB RAM, 1600 GB Temporary storage, 16.00 Databricks Unit(s)</option>
                                                        <option value="E96a v4: 96">E96a v4: 96 vCPUs, 672 GB RAM, 2400 GB Temporary storage, 24.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "memoryoptimized" && values.instanceSeries == "eadsv5") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" >
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="E4ads v5: 4">E4ads v5: 4 vCPUs, 32 GB RAM, 150 GB Temporary storage, 1.50 Databricks Unit(s)</option>
                                                        <option value="E8ads v5: 8">E8ads v5: 8 vCPUs, 64 GB RAM, 300 GB Temporary storage, 2.75 Databricks Unit(s)</option>
                                                        <option value="E16ads v5: 16">E16ads v5: 16 vCPUs, 128 GB RAM, 600 GB Temporary storage, 5.50 Databricks Unit(s)</option>
                                                        <option value="E20ads v5: 20">E20ads v5: 20 vCPUs, 160 GB RAM, 750 GB Temporary storage, 7.00 Databricks Unit(s)</option>
                                                        <option value="E32ads v5: 32">E32ads v5: 32 vCPUs, 256 GB RAM, 1200 GB Temporary storage, 11.00 Databricks Unit(s)</option>
                                                        <option value="E48ads v5: 48">E48ads v5: 48 vCPUs, 384 GB RAM, 1800 GB Temporary storage, 16.50 Databricks Unit(s)</option>
                                                        <option value="E64ads v5: 64">E64ads v5: 64 vCPUs, 512 GB RAM, 2400 GB Temporary storage, 22.00 Databricks Unit(s)</option>
                                                        <option value="E96ads v5: 96">E96ads v5: 96 vCPUs, 672 GB RAM, 3600 GB Temporary storage, 33.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "memoryoptimized" && values.instanceSeries == "easv4") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3">
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="E4as v4: 4">E4as v4: 4 vCPUs, 32 GB RAM, 64 GB Temporary storage, 1.00 Databricks Unit(s)</option>
                                                        <option value="E8as v4: 8">E8as v4: 8 vCPUs, 64 GB RAM, 128 GB Temporary storage, 2.00 Databricks Unit(s)</option>
                                                        <option value="E16as v4: 16">E16as v4: 16 vCPUs, 128 GB RAM, 256 GB Temporary storage, 4.00 Databricks Unit(s)</option>
                                                        <option value="E20as v4: 20">E20as v4: 20 vCPUs, 160 GB RAM, 320 GB Temporary storage, 5.00 Databricks Unit(s)</option>
                                                        <option value="E32as v4: 32">E32as v4: 32 vCPUs, 256 GB RAM, 512 GB Temporary storage, 8.00 Databricks Unit(s)</option>
                                                        <option value="E48as v4: 48">E48as v4: 48 vCPUs, 384 GB RAM, 768 GB Temporary storage, 12.00 Databricks Unit(s)</option>
                                                        <option value="E64as v4: 64">E64as v4: 64 vCPUs, 512 GB RAM, 1024 GB Temporary storage, 16.00 Databricks Unit(s)</option>
                                                        <option value="E96as v4: 96">E96as v4: 96 vCPUs, 672 GB RAM, 1344 GB Temporary storage, 24.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "memoryoptimized" && values.instanceSeries == "easv5") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3">
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="E4as v5: 4">E4as v5: 4 vCPUs, 32 GB RAM, O GB Temporary storage, 1.50 Databricks Unit(s)</option>
                                                        <option value="E8as v5: 8">E8as v5: 8 vCPUs, 64 GB RAM, 0 GB Temporary storage, 2.75 Databricks Unit(s)</option>
                                                        <option value="E16as v5: 16">E16as v5: 16 vCPUs, 128 GB RAM, 0 GB Temporary storage, 5.50 Databricks Unit(s)</option>
                                                        <option value="E20as v5: 20">E20as v5: 20 vCPUs, 160 GB RAM, 0 GB Temporary storage, 7.00 Databricks Unit(s)</option>
                                                        <option value="E32as v5: 32">E32as v5: 32 vCPUs, 256 GB RAM, 0 GB Temporary storage, 11.00 Databricks Unit(s)</option>
                                                        <option value="E48as v5: 48">E48as v5: 48 vCPUs, 384 GB RAM, 0 GB Temporary storage, 16.50 Databricks Unit(s)</option>
                                                        <option value="E64as v5: 64">E64as v5: 64 vCPUs, 512 GB RAM, 0 GB Temporary storage, 22.00 Databricks Unit(s)</option>
                                                        <option value="E96as v5: 96">E96as v5: 96 vCPUs, 672 GB RAM, 0 GB Temporary storage, 33.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "memoryoptimized" && values.instanceSeries == "edv4") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" >
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="E4d v4: 4">E4d v4: 4 vCPUs, 32 GB RAM, 150 GB Temporary storage, 1.00 Databricks Unit(s)</option>
                                                        <option value="E8d v4: 8">E8d v4: 8 vCPUs, 64 GB RAM, 300 GB Temporary storage, 2.00 Databricks Unit(s)</option>
                                                        <option value="E16d v4: 16">E16d v4: 16 vCPUs, 128 GB RAM, 600 GB Temporary storage, 4.00 Databricks Unit(s)</option>
                                                        <option value="E20d v4: 20">E20d v4: 20 vCPUs, 160 GB RAM, 750 GB Temporary storage, 5.00 Databricks Unit(s)</option>
                                                        <option value="E32d v4: 32">E32d v4: 32 vCPUs, 256 GB RAM, 1200 GB Temporary storage, 8.00 Databricks Unit(s)</option>
                                                        <option value="E48d v4: 48">E48d v4: 48 vCPUs, 384 GB RAM, 1800 GB Temporary storage, 12.00 Databricks Unit(s)</option>
                                                        <option value="E64d v4: 64">E64d v4: 64 vCPUs, 504 GB RAM, 2400 GB Temporary storage, 16.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "memoryoptimized" && values.instanceSeries == "edsv4") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" >
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="E64ds v4: 64">E64ds v4: 64 vCPUs, 504 GB RAM, 2400 GB Temporary storage, 16.00 Databricks Unit(s)</option>
                                                        <option value="E16ds v4: 16">E16ds v4: 16 vCPUs, 128 GB RAM, 600 GB Temporary storage, 4.00 Databricks Unit(s)</option>
                                                        <option value="E32ds v4: 32">E32ds v4: 32 vCPUs, 256 GB RAM, 1200 GB Temporary storage, 8.00 Databricks Unit(s)</option>
                                                        <option value="E8ds v4: 8">E8ds v4: 8 vCPUs, 64 GB RAM, 300 GB Temporary storage, 2.00 Databricks Unit(s)</option>
                                                        <option value="E4ds v4: 4">E4ds v4: 4 vCPUs, 32 GB RAM, 150 GB Temporary storage, 1.00 Databricks Unit(s)</option>
                                                        <option value="E20ds v4: 20">E20ds v4: 20 vCPUs, 160 GB RAM, 750 GB Temporary storage, 5.00 Databricks Unit(s)</option>
                                                        <option value="E48ds v4: 48">E48ds v4: 48 vCPUs, 384 GB RAM, 1800 GB Temporary storage, 12.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "memoryoptimized" && values.instanceSeries == "edsv5") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3">
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="E4ds v5: 4">E4ds v5: 4 vCPUs, 32 GB RAM, 150 GB Temporary storage, 1.50 Databricks Unit(s)</option>
                                                        <option value="E8ds v5: 8">E8ds v5: 8 vCPUs, 64 GB RAM, 300 GB Temporary storage, 2.75 Databricks Unit(s)</option>
                                                        <option value="E16ds v5: 16">E16ds v5: 16 vCPUs, 128 GB RAM, 600 GB Temporary storage, 5.50 Databricks Unit(s)</option>
                                                        <option value="E20ds v5: 20">E20ds v5: 20 vCPUs, 160 GB RAM, 750 GB Temporary storage, 7.00 Databricks Unit(s), </option>
                                                        <option value="E32ds v5: 32">E32ds v5: 32 vCPUs, 256 GB RAM, 1200 GB Temporary storage, 11.00 Databricks Unit(s)</option>
                                                        <option value="E48ds v5: 48">E48ds v5: 48 vCPUs, 384 GB RAM, 1800 GB Temporary storage, 16.50 Databricks Unit(s)</option>
                                                        <option value="E64ds v5: 64">E64ds v5: 64 vCPUs, 512 GB RAM, 2400 GB Temporary storage, 22.00 Databricks Unit(s)</option>
                                                        <option value="E96ds v5: 96">E96ds v5: 96 vCPUs, 672 GB RAM, 3600 GB Temporary storage, 33.00 Databricks Unit(s)</option>

                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "memoryoptimized" && values.instanceSeries == "esv3") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" >
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="E8s v3: 8">E8s v3: 8 vCPUs, 64 GB RAM, 128 GB Temporary storage, 2.00 Databricks Unit(s)</option>
                                                        <option value="E16s v3: 16">E16s v3: 16 vCPUs, 128 GB RAM, 256 GB Temporary storage, 4.00 Databricks Unit(s)</option>
                                                        <option value="E32s v3: 32">E32s v3: 32 vCPUs, 256 GB RAM, 512 GB Temporary storage, 8.00 Databricks Unit(s)</option>
                                                        <option value="E64s v3: 64">E64s v3: 64 vCPUs, 432 GB RAM, 864 GB Temporary storage, 16.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "memoryoptimized" && values.instanceSeries == "esv4") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" >
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="E4s v4: 4">E4s v4: 4 vCPUs, 32 GB RAM, 0 GB Temporary storage, 1.00 Databricks Unit(s)</option>
                                                        <option value="E8s v4: 8">E8s v4: 8 vCPUs, 64 GB RAM, 0 GB Temporary storage, 2.00 Databricks Unit(s)</option>
                                                        <option value="E16s v4: 16">E16s v4: 16 vCPUs, 128 GB RAM, 0 GB Temporary storage, 4.00 Databricks Unit(s)</option>
                                                        <option value="E20s v4: 20">E20s v4: 20 vCPUs, 160 GB RAM, 0 GB Temporary storage, 5.00 Databricks Unit(s)</option>
                                                        <option value="E32s v4: 32">E32s v4: 32 vCPUs, 256 GB RAM, 0 GB Temporary storage, 8.00 Databricks Unit(s)</option>
                                                        <option value="E48s v4: 48">E48s v4: 48 vCPUs, 384 GB RAM, 0 GB Temporary storage, 12.00 Databricks Unit(s)</option>
                                                        <option value="E64s v4: 64">E64s v4: 64 vCPUs, 504 GB RAM, 0 GB Temporary storage, 16.00 Databricks Unit(s)</option>
                                                        <option value="E80is v4: 80">E80is v4: 80 vCPUs, 504 GB RAM, 2400 GB Temporary storage, 20.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "memoryoptimized" && values.instanceSeries == "esv5") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" >
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="E4s v5: 4">E4s v5: 4 vCPUs, 32 GB RAM, 0 GB Temporary storage, 1.50 Databricks Unit(s)</option>
                                                        <option value="E8s v5: 8">E8s v5: 8 vCPUs, 64 GB RAM, O GB Temporary storage, 2.75 Databricks Unit(s)</option>
                                                        <option value="E16s v5: 16">E16s v5: 16 vCPUs, 128 GB RAM, 0 GB Temporary storage, 5.50 Databricks Unit(s)</option>
                                                        <option value="E20s v5: 20">E20s v5: 20 vCPUs, 160 GB RAM, 0 GB Temporary storage, 7.00 Databricks Unit(s)</option>
                                                        <option value="E32s v5: 32">E32s v5: 32 vCPUs, 256 GB RAM, 0 GB Temporary storage, 11.00 Databricks Unit(s)</option>
                                                        <option value="E48s v5: 48">E48s v5: 48 vCPUs, 384 GB RAM, 0 GB Temporary storage, 16.50 Databricks Unit(s)</option>
                                                        <option value="E64s v5: 64">E64s v5: 64 vCPUs, 512 GB RAM, 0 GB Temporary storage, 22.00 Databricks Unit(s)</option>
                                                        <option value="E96s v5: 96">E96s v5: 96 vCPUs, 672 GB RAM, 0 GB Temporary storage, 33.00 Databricks Unit(s)</option>

                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "memoryoptimized" && values.instanceSeries == "ev3") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" >
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="E8 v3: 8">E8 v3: 8 vCPUs, 64 GB RAM, 200 GB Temporary storage, 2.00 Databricks Unit(s)</option>
                                                        <option value="E16 v3: 16">E16 v3: 16 vCPUs, 128 GB RAM, 400 GB Temporary storage, 4.00 Databricks Unit(s)</option>
                                                        <option value="E32 v3: 32">E32 v3: 32 vCPUs, 256 GB RAM, 800 GB Temporary storage, 8.00 Databricks Unit(s)</option>
                                                        <option value="E64 v3: 64">E64 v3: 64 vCPUs, 432 GB RAM, 1600 GB Temporary storage, 16.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}


                                            {/* storageoptimized and aginst instanceSeries  */}
                                            {(values.category === "storageoptimized" && values.instanceSeries == "lasv3") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" >
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>

                                                        <option value="L8as v3: 8">L8as v3: 8 vCPUs, 64 GB RAM, 80 GB Temporary storage, 2.75 Databricks Unit(s)</option>
                                                        <option value="L16as v3: 16">L16as v3: 16 vCPUs, 128 GB RAM, 160 GB Temporary storage, 5.50 Databricks Unit(s)</option>
                                                        <option value="L32as v3: 32">L32as v3: 32 vCPUs, 256 GB RAM, 320 GB Temporary storage, 11.00 Databricks Unit(s)</option>
                                                        <option value="L48as v3: 48">L48as v3: 48 vCPUs, 384 GB RAM, 480 GB Temporary storage, 16.50 Databricks Unit(s)</option>
                                                        <option value="L64as v3: 64">L64as v3: 64 vCPUs, 512 GB RAM, 640 GB Temporary storage, 22.00 Databricks Unit(s)</option>
                                                        <option value="L80as v3: 80">L80as v3: 80 vCPUs, 640 GB RAM, 800 GB Temporary storage, 27.50 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}
                                            {(values.category === "storageoptimized" && values.instanceSeries == "lsv2") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" >
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="L8s v2: 8">L8s v2: 8 vCPUs, 64 GB RAM, 80 GB Temporary storage, 2.00 Databricks Unit(s)</option>
                                                        <option value="L16s v2: 16">L16s v2: 16 vCPUs, 128 GB RAM, 160 GB Temporary storage, 4.00 Databricks Unit(s)</option>
                                                        <option value="L32s v2: 32">L32s v2: 32 vCPUs, 256 GB RAM, 320 GB Temporary storage, 8.00 Databricks Unit(s)</option>
                                                        <option value="L64s v2: 64">L64s v2: 64 vCPUs, 512 GB RAM, 640 GB Temporary storage, 16.00 Databricks Unit(s)</option>
                                                        <option value="L80s v2: 80">L80s v2: 80 vCPUs, 640 GB RAM, 800 GB Temporary storage, 20.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "storageoptimized" && values.instanceSeries == "lsv3") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" >
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="L8s v3: 8">L8s v3: 8 vCPUs, 64 GB RAM, 80 GB Temporary storage, 2.75 Databricks Unit(s)</option>
                                                        <option value="L16s v3: 16">L16s v3: 16 vCPUs, 128 GB RAM, 160 GB Temporary storage, 5.50 Databricks Unit(s)</option>
                                                        <option value="L32s v3: 32">L32s v3: 32 vCPUs, 256 GB RAM, 320 GB Temporary storage, 11.00 Databricks Unit(s)</option>
                                                        <option value="L48s v3: 48">L48s v3: 48 vCPUs, 384 GB RAM, 480 GB Temporary storage, 16.50 Databricks Unit(s)</option>
                                                        <option value="L64s v3: 64">L64s v3: 64 vCPUs, 512 GB RAM, 640 GB Temporary storage, 22.00 Databricks Unit(s)</option>
                                                        <option value="L80s v3: 80">L80s v3: 80 vCPUs, 640 GB RAM, 800 GB Temporary storage, 27.50 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            <div className="col-md-6 px-3">
                                                <label htmlFor="count" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                    Virtual machines
                                                </label>
                                                <input
                                                    type="text"
                                                    style={{paddingTop :"10px"}}
                                                    className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                    id="count"
                                                    value={values.count}
                                                    name={"count"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}
                                                />
                                            </div>

                                            <div className="col-md-6 px-3">
                                                <label htmlFor="hours" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                    Hours
                                                </label>
                                                <input
                                                    type="text"
                                                    style={{paddingTop :"10px"}}
                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                    id="hours"
                                                    value={values.hours}
                                                    name={"hours"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}
                                                />
                                            </div>

                                            <div className="col-md-6 px-3">
                                                <label htmlFor="hoursFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Hours Factor
                                                </label>
                                                <select
                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                    id="hoursFactor"
                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                    value={values.hoursFactor}
                                                    name={"hoursFactor"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}>
                                                    <option value="1" >Hours</option>
                                                    <option value="24" >Days</option>
                                                    <option value="730" >Month</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="dbuHours" className="form-label font-14 font-medium black-v2 mb-3">
                                                    DBU (Databricks Unit) Hours
                                                </label>
                                                <input
                                                    type="text"
                                                    style={{paddingTop :"10px"}}
                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                    id="dbuHours"
                                                    value={values.dbuHours}
                                                    name={"dbuHours"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}
                                                />
                                            </div>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="dbuHoursFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                                    DBU (Databricks Unit) Hours Factor
                                                </label>
                                                <select
                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                    id="dbuHoursFactor"
                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                    value={values.dbuHoursFactor}
                                                    name={"dbuHoursFactor"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}>
                                                    <option value="1" >Hours</option>
                                                    <option value="24" >Days</option>
                                                    <option value="730" >Month</option>
                                                </select>
                                            </div>


                                        </>}

                                        {values.workload === "jobs-compute" && <>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="tier" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Tier
                                                </label>
                                                <select
                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                    id="tier"
                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                    value={values.tier}
                                                    name={"tier"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}>
                                                    <option value="standard" >Standard</option>
                                                    <option value="premium" >Premium</option>
                                                </select>
                                            </div>

                                            <div className="col-md-6 px-3">
                                                <label htmlFor="category" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Category
                                                </label>
                                                <select
                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                    id="category"
                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                    value={values.category}
                                                    name={"category"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}>
                                                    <option value="computeoptimized" class="">Compute optimised</option>
                                                    <option value="generalpurpose" class="">General purpose</option>
                                                    <option value="gpu" class="">GPU</option>
                                                    <option value="memoryoptimized" class="">Memory optimised</option>
                                                    <option value="storageoptimized" class="">Storage optimised</option>
                                                </select>
                                            </div>

                                            {values.category === "computeoptimized" && <>
                                                <div>
                                                    <label htmlFor="instanceSeries" className="form-label font-14 font-medium black-v2 mb-3">
                                                        Instance Series
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="instanceSeries"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.instanceSeries}
                                                        name={"instanceSeries"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="f" class="">F-series</option>
                                                        <option value="fs" class="">Fs-series</option>
                                                        <option value="fsv2" class="">Fsv2-series</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {values.category === "generalpurpose" && <>
                                                <div>
                                                    <label htmlFor="instanceSeries" className="form-label font-14 font-medium black-v2 mb-3">
                                                        Instance Series
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="instanceSeries"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.instanceSeries}
                                                        name={"instanceSeries"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="dav4" class="">Da v4-series</option>
                                                        <option value="dadsv5" class="">Dadsv5-series</option>
                                                        <option value="dasv4" class="">Das v4-series</option>
                                                        <option value="dasv5" class="">Dasv5-series</option>
                                                        <option value="dcasv5" class="">DCasv5-series</option>
                                                        <option value="ddv4" class="">Dd v4-series</option>
                                                        <option value="ddsv4" class="">Dds v4-series</option>
                                                        <option value="ddsv5" class="">Ddsv5-series</option>
                                                        <option value="dsv2" class="">Dsv2-series</option>
                                                        <option value="dsv3" class="">Dsv3-series</option>
                                                        <option value="dsv5" class="">Dsv5-series</option>
                                                        <option value="dv2" class="">Dv2-series</option>
                                                        <option value="dv3" class="">Dv3-series</option>
                                                        <option value="ecadsv5" class="">ECadsv5-series</option>
                                                        <option value="ecasv5" class="">ECasv5-series</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {values.category === "gpu" && <>
                                                <div>
                                                    <label htmlFor="instanceSeries" className="form-label font-14 font-medium black-v2 mb-3" >
                                                        Instance Series
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="instanceSeries"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.instanceSeries}
                                                        name={"instanceSeries"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="nca100v4" class="">NC A100 v4-series</option>
                                                        <option value="ncast4v3" class="">NC_T4_v3-series</option>
                                                        <option value="nc" class="">NC-series</option>
                                                        <option value="ncsv3" class="">NCsv3-series</option>
                                                        <option value="ndasrv4" class="">ND A100 v4-series</option>
                                                        <option value="nvv5" class="">NVads A10 v5-series</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {values.category === "memoryoptimized" && <>
                                                <div>
                                                    <label htmlFor="instanceSeries" className="form-label font-14 font-medium black-v2 mb-3">
                                                        Instance Series
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="instanceSeries"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.instanceSeries}
                                                        name={"instanceSeries"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="dsv2" class="">Dsv2-series</option>
                                                        <option value="dv2" class="">Dv2-series</option>
                                                        <option value="eav4" class="">Ea v4-series</option>
                                                        <option value="eadsv5" class="">Eadsv5-series</option>
                                                        <option value="easv4" class="">Eas v4-series</option>
                                                        <option value="easv5" class="">Easv5-series</option>
                                                        <option value="edv4" class="">Ed v4-series</option>
                                                        <option value="edsv4" class="">Eds v4-series</option>
                                                        <option value="edsv5" class="">Edsv5-series</option>
                                                        <option value="esv3" class="">Esv3-series</option>
                                                        <option value="esv4" class="">Esv4-series</option>
                                                        <option value="esv5" class="">Esv5-series</option>
                                                        <option value="ev3" class="">Ev3-series</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {values.category === "storageoptimized" && <>
                                                <div>
                                                    <label htmlFor="instanceSeries" className="form-label font-14 font-medium black-v2 mb-3">
                                                        Instance Series
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="instanceSeries"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.instanceSeries}
                                                        name={"instanceSeries"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="lasv3" class="">Lasv3-series</option>
                                                        <option value="lsv2" class="">Lsv2-series</option>
                                                        <option value="lsv3" class="">Lsv3-series</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {/* computeoptimized and aginst instanceSeries */}
                                            {(values.category === "computeoptimized" && values.instanceSeries == "f") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="F4: 4">F4: 4 vCPUs, 8 GB RAM, 64 GB Temporary storage, 0.50 Databricks Unit(s)</option>
                                                        <option value="F8: 8">F8: 8 vCPUs, 16 GB RAM, 128 GB Temporary storage, 1.00 Databricks Unit(s)</option>
                                                        <option value="F16: 16">F16: 16 vCPUs, 32 GB RAM, 256 GB Temporary storage, 2.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}
                                            {(values.category === "computeoptimized" && values.instanceSeries == "fs") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="F4s: 4">F4s: 4 vCPUs, 8 GB RAM, 16 GB Temporary storage, 0.50 Databricks Unit(s)</option>
                                                        <option value="F8s: 8">F8s: 8 vCPUs, 16 GB RAM, 32 GB Temporary storage, 1.00 Databricks Unit(s)</option>
                                                        <option value="F16s: 16">F16s: 16 vCPUs, 32 GB RAM, 256 GB Temporary storage, 2.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}
                                            {(values.category === "computeoptimized" && values.instanceSeries == "fsv2") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="F4s v2">F4s v2: 4 vCPUs, 8 GB RAM, 32 GB Temporary storage, 0.75 Databricks Unit(s)</option>
                                                        <option value="F8s v2">F8s v2: 8 vCPUs, 16 GB RAM, 64 GB Temporary storage, 1.50 Databricks Unit(s)</option>
                                                        <option value="F16s v2">F16s v2: 16 vCPUs, 32 GB RAM, 128 GB Temporary storage, 3.00 Databricks Unit(s)</option>
                                                        <option value="F32s v2">F32s v2: 32 vCPUs, 64 GB RAM, 256 GB Temporary storage, 6.00 Databricks Unit(s)</option>
                                                        <option value="F64s v2">F64s v2: 64 vCPUs, 128 GB RAM, 512 GB Temporary storage, 12.00 Databricks Unit(s)</option>
                                                        <option value="F64s v2">F64s v2: 64 vCPUs, 128 GB RAM, 512 GB Temporary storage, 12.00 Databricks Unit(s)</option>
                                                        <option value="F72s v2">F72s v2: 72 vCPUs, 144 GB RAM, 576 GB Temporary storage, 13.50 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {/* generalpurpose and aginst instanceSeries */}
                                            {(values.category === "generalpurpose" && values.instanceSeries == "dav4") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="D4a v4">D4a v4: 4 vCPUs, 16 GB RAM, 100 GB Temporary storage, 0.75 Databricks Unit(s)</option>
                                                        <option value="D8a v4">D8a v4: 8 vCPUs, 32 GB RAM, 200 GB Temporary storage, 1.50 Databricks Unit(s)</option>
                                                        <option value="D16a v4">D16a v4: 16 vCPUs, 64 GB RAM, 400 GB Temporary storage, 3.00 Databricks Unit(s)</option>
                                                        <option value="D32a v4">D32a v4: 32 vCPUs, 128 GB RAM, 800 GB Temporary storage, 6.00 Databricks Unit(s)</option>
                                                        <option value="D48a v4">D48a v4: 48 vCPUs, 192 GB RAM, 1200 GB Temporary storage, 9.00 Databricks Unit(s)</option>
                                                        <option value="D64a v4">D64a v4: 64 vCPUs, 256 GB RAM, 1600 GB Temporary storage, 12.00 Databricks Unit(s)</option>
                                                        <option value="D96a v4">D96a v4: 96 vCPUs, 384 GB RAM, 2400 GB Temporary storage, 18.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "generalpurpose" && values.instanceSeries == "dadsv5") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="D96ads v5">D96ads v5: 96 vCPUs, 384 GB RAM, 3600 GB Temporary storage, 24.00 Databricks Unit(s)</option>
                                                        <option value="D4ads v5">D4ads v5: 4 vCPUs, 16 GB RAM, 150 GB Temporary storage, 1.00 Databricks Unit(s)</option>
                                                        <option value="D8ads v5">D8ads v5: 8 vCPUs, 32 GB RAM, 300 GB Temporary storage, 2.00 Databricks Unit(s)</option>
                                                        <option value="D16ads v5">D16ads v5: 16 vCPUs, 64 GB RAM, 600 GB Temporary storage, 4.00 Databricks Unit(s)</option>
                                                        <option value="D32ads v5">D32ads v5: 32 vCPUs, 128 GB RAM, 1200 GB Temporary storage, 8.00 Databricks Unit(s)</option>
                                                        <option value="D48ads v5">D48ads v5: 48 vCPUs, 192 GB RAM, 1800 GB Temporary storage, 12.00 Databricks Unit(s)</option>
                                                        <option value="D64ads v5">D64ads v5: 64 vCPUs, 256 GB RAM, 2400 GB Temporary storage, 16.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "generalpurpose" && values.instanceSeries == "dasv4") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="D8as v4">D8as v4: 8 vCPUs, 32 GB RAM, 64 GB Temporary storage, 1.50 Databricks Unit(s)</option>
                                                        <option value="D16as v4">D16as v4: 16 vCPUs, 64 GB RAM, 128 GB Temporary storage, 3.00 Databricks Unit(s)</option>
                                                        <option value="D32as v4">D32as v4: 32 vCPUs, 128 GB RAM, 256 GB Temporary storage, 6.00 Databricks Unit(s)</option>
                                                        <option value="D48as v4">D48as v4: 48 vCPUs, 192 GB RAM, 384 GB Temporary storage, 9.00 Databricks Unit(s)</option>
                                                        <option value="D64as v4">D64as v4: 64 vCPUs, 256 GB RAM, 512 GB Temporary storage, 12.00 Databricks Unit(s)</option>
                                                        <option value="D96as v4">D96as v4: 96 vCPUs, 384 GB RAM, 768 GB Temporary storage, 18.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "generalpurpose" && values.instanceSeries == "dasv5") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="D4as v5">D4as v5: 4 vCPUs, 16 GB RAM, O GB Temporary storage, 1.00 Databricks Unit(s)</option>
                                                        <option value="D8as v5">D8as v5: 8 vCPUs, 32 GB RAM, O GB Temporary storage, 2.00 Databricks Unit(s)</option>
                                                        <option value="D16as v5">D16as v5: 16 vCPUs, 64 GB RAM, 0 GB Temporary storage, 4.00 Databricks Unit(s)</option>
                                                        <option value="D32as v5">D32as v5: 32 vCPUs, 128 GB RAM, 0 GB Temporary storage, 8.00 Databricks Unit(s)</option>
                                                        <option value="D48as v5">D48as v5: 48 vCPUs, 192 GB RAM, 0 GB Temporary storage, 12.00 Databricks Unit(s)</option>
                                                        <option value="D64as v5">D64as v5: 64 vCPUs, 256 GB RAM, 0 GB Temporary storage, 16.00 Databricks Unit(s)</option>
                                                        <option value="D96as v5:">D96as v5: 96 vCPUs, 384 GB RAM, 0 GB Temporary storage, 24.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "generalpurpose" && values.instanceSeries == "dcasv5") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="DC4as v5">DC4as v5: 4 vCPUs, 16 GB RAM, 0 GB Temporary storage, 1.00 Databricks Unit(s)</option>
                                                        <option value="DC8as v5">DC8as v5: 8 vCPUs, 32 GB RAM, 0 GB Temporary storage, 2.00 Databricks Unit(s)</option>
                                                        <option value="DC16as v5">DC16as v5: 16 vCPUs, 64 GB RAM, 0 GB Temporary storage, 4.00 Databricks - Unit(s)</option>
                                                        <option value="DC32as v5">DC32as v5: 32 vCPUs, 128 GB RAM, 0 GB Temporary storage, 8.00 Databricks</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "generalpurpose" && values.instanceSeries == "ddv4") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="D4d v4">D4d v4: 4 vCPUs, 16 GB RAM, 150 GB Temporary storage, 0.75 Databricks Unit(s)</option>
                                                        <option value="D8d v4">D8d v4: 8 vCPUs, 32 GB RAM, 300 GB Temporary storage, 1.50 Databricks Unit(s)</option>
                                                        <option value="D16d v4">D16d v4: 16 vCPUs, 64 GB RAM, 600 GB Temporary storage, 3.00 Databricks Unit(s)</option>
                                                        <option value="D32d v4">D32d v4: 32 vCPUs, 128 GB RAM, 1200 GB Temporary storage, 6.00 Databricks Unit(s)</option>
                                                        <option value="D48d v4">D48d v4: 48 vCPUs, 192 GB RAM, 1800 GB Temporary storage, 9.00 Databricks Unit(s)</option>
                                                        <option value="D64d v4">D64d v4: 64 vCPUs, 256 GB RAM, 2400 GB Temporary storage, 12.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "generalpurpose" && values.instanceSeries == "ddsv4") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="D4ds v4">D4ds v4: 4 vCPUs, 16 GB RAM, 150 GB Temporary storage, 0.75 Databricks Unit(s)</option>
                                                        <option value="D8ds v4">D8ds v4: 8 vCPUs, 32 GB RAM, 300 GB Temporary storage, 1.50 Databricks Unit(s)</option>
                                                        <option value="D16ds v4">D16ds v4: 16 vCPUs, 64 GB RAM, 600 GB Temporary storage, 3.00 Databricks Unit(s)</option>
                                                        <option value="D32ds v4">D32ds v4: 32 vCPUs, 128 GB RAM, 1200 GB Temporary storage, 6.00 Databricks Unit(s)</option>
                                                        <option value="D48ds v4">D48ds v4: 48 vCPUs, 192 GB RAM, 1800 GB Temporary storage, 9.00 Databricks Unit(s)</option>
                                                        <option value="D64ds v4">D64ds v4: 64 vCPUs, 256 GB RAM, 2400 GB Temporary storage, 12.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "generalpurpose" && values.instanceSeries == "ddsv5") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="D4ds v5">D4ds v5: 4 vCPUs, 16 GB RAM, 150 GB Temporary storage, 1.00 Databricks Unit(s)</option>
                                                        <option value="D8ds v5">D8ds v5: 8 vCPUs, 32 GB RAM, 300 GB Temporary storage, 2.00 Databricks Unit(s)</option>
                                                        <option value="D16ds v5">D16ds v5: 16 vCPUs, 64 GB RAM, 600 GB Temporary storage, 4.00 Databricks Unit(s)</option>
                                                        <option value="D32ds v5: 32">D32ds v5: 32 vCPUs, 128 GB RAM, 1200 GB Temporary storage, 8.00 Databricks Unit(s)</option>
                                                        <option value="D32ds v5: 48">D32ds v5: 48 vCPUs, 192 GB RAM, 1800 GB Temporary storage, 12.00 Databricks Unit(s)</option>
                                                        <option value="D64ds v5: 6">D64ds v5: 64 vCPUs, 256 GB RAM, 2400 GB Temporary storage, 16.00 Databricks Unit(s)</option>
                                                        <option value="D96ds v5: 96">D96ds v5: 96 vCPUs, 384 GB RAM, 3600 GB Temporary storage, 24.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "generalpurpose" && values.instanceSeries == "dsv2") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="DS12 v2: 4">DS12 v2: 4 vCPUs, 28 GB RAM, 56 GB Temporary storage, 1.00 Databricks Unit(s)</option>
                                                        <option value="DS3 v2: 4">DS3 v2: 4 vCPUs, 14 GB RAM, 28 GB Temporary storage, 0.75 Databricks Unit(s)</option>
                                                        <option value="DS13 v2: 8 ">DS13 v2: 8 vCPUs, 56 GB RAM, 112 GB Temporary storage, 2.00 Databricks Unit(s)</option>
                                                        <option value="DS4 v2: 8">DS4 v2: 8 vCPUs, 28 GB RAM, 56 GB Temporary storage, 1.50 Databricks Unit(s)</option>
                                                        <option value="DS14 v2: 16">DS14 v2: 16 vCPUs, 112 GB RAM, 224 GB Temporary storage, 4.00 Databricks Unit(s)</option>
                                                        <option value="DS5 v2: 16">DS5 v2: 16 vCPUs, 56 GB RAM, 112 GB Temporary storage, 3.00 Databricks Unit(s)</option>
                                                        <option value="DS15 v2: 20">DS15 v2: 20 vCPUs, 140 GB RAM, 280 GB Temporary storage, 5.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "generalpurpose" && values.instanceSeries == "dsv3") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="D4s v3: 4">D4s v3: 4 vCPUs, 16 GB RAM, 32 GB Temporary storage, 0.75 Databricks Unit(s)</option>
                                                        <option value="D8s v3: 8 ">D8s v3: 8 vCPUs, 32 GB RAM, 64 GB Temporary storage, 1.50 Databricks Unit(s)</option>
                                                        <option value="D16s v3: 16">D16s v3: 16 vCPUs, 64 GB RAM, 128 GB Temporary storage, 3.00 Databricks Unit(s)</option>
                                                        <option value="D32s v3: 32">D32s v3: 32 vCPUs, 128 GB RAM, 256 GB Temporary storage, 6.00 Databricks Unit(s)</option>
                                                        <option value="D64s v3: 64">D64s v3: 64 vCPUs, 256 GB RAM, 512 GB Temporary storage, 12.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "generalpurpose" && values.instanceSeries == "dsv5") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="D4s v5: 4">D4s v5: 4 vCPUs, 16 GB RAM, 0 GB Temporary storage, 1.00 Databricks Unit(s)</option>
                                                        <option value="D8s v5: 8">D8s v5: 8 vCPUs, 32 GB RAM, 0 GB Temporary storage, 2.00 Databricks Unit(s)</option>
                                                        <option value="D16s v5: 16">D16s v5: 16 vCPUs, 64 GB RAM, 0 GB Temporary storage, 4.00 Databricks Unit(s)</option>
                                                        <option value="D32s v5: 32">D32s v5: 32 vCPUs, 128 GB RAM, 0 GB Temporary storage, 8.00 Databricks Unit(s) </option>
                                                        <option value="D48s v5: 48">D48s v5: 48 vCPUs, 192 GB RAM, 0 GB Temporary storage, 12.00 Databricks Unit(s)</option>
                                                        <option value="D64s v5: 64">D64s v5: 64 vCPUs, 256 GB RAM, 0 GB Temporary storage, 16.00 Databricks Unit(s)</option>
                                                        <option value="D96s v5: 96">D96s v5: 96 vCPUs, 384 GB RAM, 0 GB Temporary storage, 24.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "generalpurpose" && values.instanceSeries == "dv2") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="D3 v2: 4">D3 v2: 4 vCPUs, 14 GB RAM, 200 GB Temporary storage, 0.75 Databricks Unit(s)</option>
                                                        <option value="D4 v2: 8">D4 v2: 8 vCPUs, 28 GB RAM, 400 GB Temporary storage, 1.50 Databricks Unit(s)</option>
                                                        <option value="D5 v2: 16">D5 v2: 16 vCPUs, 56 GB RAM, 800 GB Temporary storage, 3.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "generalpurpose" && values.instanceSeries == "dv3") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="D8 v3: 8">D8 v3: 8 vCPUs, 32 GB RAM, 200 GB Temporary storage, 1.50 Databricks Unit(s)</option>
                                                        <option value="D16 v3: 16">D16 v3: 16 vCPUs, 64 GB RAM, 400 GB Temporary storage, 3.00 Databricks Unit(s)</option>
                                                        <option value="D32 v3: 32">D32 v3: 32 vCPUs, 128 GB RAM, 800 GB Temporary storage, 6.00 Databricks Unit(s), </option>
                                                        <option value="D64 v3: 64">D64 v3: 64 vCPUs, 256 GB RAM, 1600 GB Temporary storage, 12.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "generalpurpose" && values.instanceSeries == "ecadsv5") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="EC8ads v5: 8">EC8ads v5: 8 vCPUs, 64 GB RAM, 300 GB Temporary storage, 2.75 Databricks Unit(s)</option>
                                                        <option value="EC16ads v5: 16">EC16ads v5: 16 vCPUs, 128 GB RAM, 600 GB Temporary storage, 5.50 Databricks Unit(s)</option>
                                                        <option value="EC32ads v5: 32">EC32ads v5: 32 vCPUs, 192 GB RAM, 1200 GB Temporary storage, 11.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "generalpurpose" && values.instanceSeries == "ecasv5") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="EC8as v5: 8">EC8as v5: 8 vCPUs, 64 GB RAM, 0 GB Temporary storage, 2.75 Databricks Unit(s)</option>
                                                        <option value="EC16as v5: 16">EC16as v5: 16 vCPUs, 128 GB RAM, 0 GB Temporary storage, 5.50 Databricks Unit(s)</option>
                                                        <option value="EC32as v5: 32">EC32as v5: 32 vCPUs, 192 GB RAM, 0 GB Temporary storage, 11.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {/* gpu and aginst instanceSeries  */}
                                            {(values.category === "gpu" && values.instanceSeries == "nca100v4") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="NC24ads A100 v4">NC24ads A100 v4: 24 Cores, 220 GB RAM, 958 GB Temporary storage, 10.00 Databricks Unit(s)</option>
                                                        <option value="NC48ads A100 v4">NC48ads A100 v4: 48 Cores, 440 GB RAM, 1916 GB Temporary storage, 20.00 Databricks Unit(s)</option>
                                                        <option value="NC96ads A100 v4">NC96ads A100 v4: 96 Cores, 880 GB RAM, 3832 GB Temporary storage, 44.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "gpu" && values.instanceSeries == "ncast4v3") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="NC4as T4 v3: 4 ">NC4as T4 v3: 4 vCPUs, 28 GB RAM, 180 GB Temporary storage, 1.00 Databricks Unit(s)</option>
                                                        <option value="NC8as T4 v3: 8 ">NC8as T4 v3: 8 vCPUs, 56 GB RAM, 360 GB Temporary storage, 1.50 Databricks Unit(s)</option>
                                                        <option value="NC16as T4 v3: 16">NC16as T4 v3: 16 vCPUs, 110 GB RAM, 360 GB Temporary storage, 2.50 Databricks Unit(s)</option>
                                                        <option value="NC64as T4 v3: 64">NC64as T4 v3: 64 vCPUs, 440 GB RAM, 2880 GB Temporary storage, 10.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "gpu" && values.instanceSeries == "nc") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="NC12: 12">NC12: 12 vCPUs, 112 GB RAM, 680 GB Temporary storage, 3.00 Databricks Unit(s)</option>
                                                        <option value="NC24: 24">NC24: 24 vCPUs, 224 GB RAM, 1440 GB Temporary storage, 6.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "gpu" && values.instanceSeries == "ncsv3") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="NC6s v3: 6">NC6s v3: 6 vCPUs, 112 GB RAM, 736 GB Temporary storage, 5.00 Databricks Unit(s)</option>
                                                        <option value="NC12s v3: 12">NC12s v3: 12 vCPUs, 224 GB RAM, 1474 GB Temporary storage, 10.00 Databricks Unit(s)</option>
                                                        <option value="NC24s v3: 24">NC24s v3: 24 vCPUs, 448 GB RAM, 2948 GB Temporary storage, 20.00 Databricks Unit(s), </option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "gpu" && values.instanceSeries == "ndasrv4") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="ND96asr: 96">ND96asr: 96 Cores, 900 GB RAM, 6500 GB Temporary storage, 44.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "gpu" && values.instanceSeries == "nvv5") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="NV36adms A10 v5">NV36adms A10 v5: 36 vCPUs, 880 GB RAM, 720 GB Temporary storage, 6.00 Databricks Unit(s)</option>
                                                        <option value="NV36ads A10 v5">NV36ads A10 v5: 36 vCPUs, 440 GB RAM, 720 GB Temporary storage, 4.00 Databricks Unit(s)</option>
                                                        <option value="NV72ads A10 v5">NV72ads A10 v5: 72 vCPUs, 880 GB RAM, 1400 GB Temporary storage, 8.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {/* memoryoptimized and aginst instanceSeries  */}
                                            {(values.category === "memoryoptimized" && values.instanceSeries == "dsv2") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="DS12 v2: 4">DS12 v2: 4 vCPUs, 28 GB RAM, 56 GB Temporary storage, 1.00 Databricks Unit(s)</option>
                                                        <option value="DS13 v2: 8">DS13 v2: 8 vCPUs, 56 GB RAM, 112 GB Temporary storage, 2.00 Databricks Unit(s)</option>
                                                        <option value="DS14 v2: 16">DS14 v2: 16 vCPUs, 112 GB RAM, 224 GB Temporary storage, 4.00 Databricks Unit(s)</option>
                                                        <option value="DS15 v2: 20">DS15 v2: 20 vCPUs, 140 GB RAM, 280 GB Temporary storage, 5.00 Databricks Unit(s), </option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "memoryoptimized" && values.instanceSeries == "dv2") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="D12 v2: 4">D12 v2: 4 vCPUs, 28 GB RAM, 200 GB Temporary storage, 1.00 Databricks Unit(s)</option>
                                                        <option value="D13 v2: 8">D13 v2: 8 vCPUs, 56 GB RAM, 400 GB Temporary storage, 2.00 Databricks Unit(s)</option>
                                                        <option value="D14 v2: 16">D14 v2: 16 vCPUs, 112 GB RAM, 800 GB Temporary storage, 4.00 Databricks Unit(s)</option>
                                                        <option value="D15 v2: 20">D15 v2: 20 vCPUs, 140 GB RAM, 1000 GB Temporary storage, 5.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "memoryoptimized" && values.instanceSeries == "eav4") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="E4a v4: 4">E4a v4: 4 vCPUs, 32 GB RAM, 100 GB Temporary storage, 1.00 Databricks Unit(s)</option>
                                                        <option value="E8a v4: 8">E8a v4: 8 vCPUs, 64 GB RAM, 200 GB Temporary storage, 2.00 Databricks Unit(s)</option>
                                                        <option value="E16a v4: 16">E16a v4: 16 vCPUs, 128 GB RAM, 400 GB Temporary storage, 4.00 Databricks Unit(s)</option>
                                                        <option value="E20a v4: 20">E20a v4: 20 vCPUs, 160 GB RAM, 500 GB Temporary storage, 5.00 Databricks Unit(s)</option>
                                                        <option value="E32a v4: 32">E32a v4: 32 vCPUs, 256 GB RAM, 800 GB Temporary storage, 8.00 Databricks Unit(s)</option>
                                                        <option value="E48a v4: 48">E48a v4: 48 vCPUs, 384 GB RAM, 1200 GB Temporary storage, 12.00 Databricks Unit(s)</option>
                                                        <option value="E64a v4: 64">E64a v4: 64 vCPUs, 512 GB RAM, 1600 GB Temporary storage, 16.00 Databricks Unit(s)</option>
                                                        <option value="E96a v4: 96">E96a v4: 96 vCPUs, 672 GB RAM, 2400 GB Temporary storage, 24.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "memoryoptimized" && values.instanceSeries == "eadsv5") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="E4ads v5: 4">E4ads v5: 4 vCPUs, 32 GB RAM, 150 GB Temporary storage, 1.50 Databricks Unit(s)</option>
                                                        <option value="E8ads v5: 8">E8ads v5: 8 vCPUs, 64 GB RAM, 300 GB Temporary storage, 2.75 Databricks Unit(s)</option>
                                                        <option value="E16ads v5: 16">E16ads v5: 16 vCPUs, 128 GB RAM, 600 GB Temporary storage, 5.50 Databricks Unit(s)</option>
                                                        <option value="E20ads v5: 20">E20ads v5: 20 vCPUs, 160 GB RAM, 750 GB Temporary storage, 7.00 Databricks Unit(s)</option>
                                                        <option value="E32ads v5: 32">E32ads v5: 32 vCPUs, 256 GB RAM, 1200 GB Temporary storage, 11.00 Databricks Unit(s)</option>
                                                        <option value="E48ads v5: 48">E48ads v5: 48 vCPUs, 384 GB RAM, 1800 GB Temporary storage, 16.50 Databricks Unit(s)</option>
                                                        <option value="E64ads v5: 64">E64ads v5: 64 vCPUs, 512 GB RAM, 2400 GB Temporary storage, 22.00 Databricks Unit(s)</option>
                                                        <option value="E96ads v5: 96">E96ads v5: 96 vCPUs, 672 GB RAM, 3600 GB Temporary storage, 33.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "memoryoptimized" && values.instanceSeries == "easv4") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="E4as v4: 4">E4as v4: 4 vCPUs, 32 GB RAM, 64 GB Temporary storage, 1.00 Databricks Unit(s)</option>
                                                        <option value="E8as v4: 8">E8as v4: 8 vCPUs, 64 GB RAM, 128 GB Temporary storage, 2.00 Databricks Unit(s)</option>
                                                        <option value="E16as v4: 16">E16as v4: 16 vCPUs, 128 GB RAM, 256 GB Temporary storage, 4.00 Databricks Unit(s)</option>
                                                        <option value="E20as v4: 20">E20as v4: 20 vCPUs, 160 GB RAM, 320 GB Temporary storage, 5.00 Databricks Unit(s)</option>
                                                        <option value="E32as v4: 32">E32as v4: 32 vCPUs, 256 GB RAM, 512 GB Temporary storage, 8.00 Databricks Unit(s)</option>
                                                        <option value="E48as v4: 48">E48as v4: 48 vCPUs, 384 GB RAM, 768 GB Temporary storage, 12.00 Databricks Unit(s)</option>
                                                        <option value="E64as v4: 64">E64as v4: 64 vCPUs, 512 GB RAM, 1024 GB Temporary storage, 16.00 Databricks Unit(s)</option>
                                                        <option value="E96as v4: 96">E96as v4: 96 vCPUs, 672 GB RAM, 1344 GB Temporary storage, 24.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "memoryoptimized" && values.instanceSeries == "easv5") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="E4as v5: 4">E4as v5: 4 vCPUs, 32 GB RAM, O GB Temporary storage, 1.50 Databricks Unit(s)</option>
                                                        <option value="E8as v5: 8">E8as v5: 8 vCPUs, 64 GB RAM, 0 GB Temporary storage, 2.75 Databricks Unit(s)</option>
                                                        <option value="E16as v5: 16">E16as v5: 16 vCPUs, 128 GB RAM, 0 GB Temporary storage, 5.50 Databricks Unit(s)</option>
                                                        <option value="E20as v5: 20">E20as v5: 20 vCPUs, 160 GB RAM, 0 GB Temporary storage, 7.00 Databricks Unit(s)</option>
                                                        <option value="E32as v5: 32">E32as v5: 32 vCPUs, 256 GB RAM, 0 GB Temporary storage, 11.00 Databricks Unit(s)</option>
                                                        <option value="E48as v5: 48">E48as v5: 48 vCPUs, 384 GB RAM, 0 GB Temporary storage, 16.50 Databricks Unit(s)</option>
                                                        <option value="E64as v5: 64">E64as v5: 64 vCPUs, 512 GB RAM, 0 GB Temporary storage, 22.00 Databricks Unit(s)</option>
                                                        <option value="E96as v5: 96">E96as v5: 96 vCPUs, 672 GB RAM, 0 GB Temporary storage, 33.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "memoryoptimized" && values.instanceSeries == "edv4") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="E4d v4: 4">E4d v4: 4 vCPUs, 32 GB RAM, 150 GB Temporary storage, 1.00 Databricks Unit(s)</option>
                                                        <option value="E8d v4: 8">E8d v4: 8 vCPUs, 64 GB RAM, 300 GB Temporary storage, 2.00 Databricks Unit(s)</option>
                                                        <option value="E16d v4: 16">E16d v4: 16 vCPUs, 128 GB RAM, 600 GB Temporary storage, 4.00 Databricks Unit(s)</option>
                                                        <option value="E20d v4: 20">E20d v4: 20 vCPUs, 160 GB RAM, 750 GB Temporary storage, 5.00 Databricks Unit(s)</option>
                                                        <option value="E32d v4: 32">E32d v4: 32 vCPUs, 256 GB RAM, 1200 GB Temporary storage, 8.00 Databricks Unit(s)</option>
                                                        <option value="E48d v4: 48">E48d v4: 48 vCPUs, 384 GB RAM, 1800 GB Temporary storage, 12.00 Databricks Unit(s)</option>
                                                        <option value="E64d v4: 64">E64d v4: 64 vCPUs, 504 GB RAM, 2400 GB Temporary storage, 16.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "memoryoptimized" && values.instanceSeries == "edsv4") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="E64ds v4: 64">E64ds v4: 64 vCPUs, 504 GB RAM, 2400 GB Temporary storage, 16.00 Databricks Unit(s)</option>
                                                        <option value="E16ds v4: 16">E16ds v4: 16 vCPUs, 128 GB RAM, 600 GB Temporary storage, 4.00 Databricks Unit(s)</option>
                                                        <option value="E32ds v4: 32">E32ds v4: 32 vCPUs, 256 GB RAM, 1200 GB Temporary storage, 8.00 Databricks Unit(s)</option>
                                                        <option value="E8ds v4: 8">E8ds v4: 8 vCPUs, 64 GB RAM, 300 GB Temporary storage, 2.00 Databricks Unit(s)</option>
                                                        <option value="E4ds v4: 4">E4ds v4: 4 vCPUs, 32 GB RAM, 150 GB Temporary storage, 1.00 Databricks Unit(s)</option>
                                                        <option value="E20ds v4: 20">E20ds v4: 20 vCPUs, 160 GB RAM, 750 GB Temporary storage, 5.00 Databricks Unit(s)</option>
                                                        <option value="E48ds v4: 48">E48ds v4: 48 vCPUs, 384 GB RAM, 1800 GB Temporary storage, 12.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "memoryoptimized" && values.instanceSeries == "edsv5") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="E4ds v5: 4">E4ds v5: 4 vCPUs, 32 GB RAM, 150 GB Temporary storage, 1.50 Databricks Unit(s)</option>
                                                        <option value="E8ds v5: 8">E8ds v5: 8 vCPUs, 64 GB RAM, 300 GB Temporary storage, 2.75 Databricks Unit(s)</option>
                                                        <option value="E16ds v5: 16">E16ds v5: 16 vCPUs, 128 GB RAM, 600 GB Temporary storage, 5.50 Databricks Unit(s)</option>
                                                        <option value="E20ds v5: 20">E20ds v5: 20 vCPUs, 160 GB RAM, 750 GB Temporary storage, 7.00 Databricks Unit(s), </option>
                                                        <option value="E32ds v5: 32">E32ds v5: 32 vCPUs, 256 GB RAM, 1200 GB Temporary storage, 11.00 Databricks Unit(s)</option>
                                                        <option value="E48ds v5: 48">E48ds v5: 48 vCPUs, 384 GB RAM, 1800 GB Temporary storage, 16.50 Databricks Unit(s)</option>
                                                        <option value="E64ds v5: 64">E64ds v5: 64 vCPUs, 512 GB RAM, 2400 GB Temporary storage, 22.00 Databricks Unit(s)</option>
                                                        <option value="E96ds v5: 96">E96ds v5: 96 vCPUs, 672 GB RAM, 3600 GB Temporary storage, 33.00 Databricks Unit(s)</option>

                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "memoryoptimized" && values.instanceSeries == "esv3") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="E8s v3: 8">E8s v3: 8 vCPUs, 64 GB RAM, 128 GB Temporary storage, 2.00 Databricks Unit(s)</option>
                                                        <option value="E16s v3: 16">E16s v3: 16 vCPUs, 128 GB RAM, 256 GB Temporary storage, 4.00 Databricks Unit(s)</option>
                                                        <option value="E32s v3: 32">E32s v3: 32 vCPUs, 256 GB RAM, 512 GB Temporary storage, 8.00 Databricks Unit(s)</option>
                                                        <option value="E64s v3: 64">E64s v3: 64 vCPUs, 432 GB RAM, 864 GB Temporary storage, 16.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "memoryoptimized" && values.instanceSeries == "esv4") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="E4s v4: 4">E4s v4: 4 vCPUs, 32 GB RAM, 0 GB Temporary storage, 1.00 Databricks Unit(s)</option>
                                                        <option value="E8s v4: 8">E8s v4: 8 vCPUs, 64 GB RAM, 0 GB Temporary storage, 2.00 Databricks Unit(s)</option>
                                                        <option value="E16s v4: 16">E16s v4: 16 vCPUs, 128 GB RAM, 0 GB Temporary storage, 4.00 Databricks Unit(s)</option>
                                                        <option value="E20s v4: 20">E20s v4: 20 vCPUs, 160 GB RAM, 0 GB Temporary storage, 5.00 Databricks Unit(s)</option>
                                                        <option value="E32s v4: 32">E32s v4: 32 vCPUs, 256 GB RAM, 0 GB Temporary storage, 8.00 Databricks Unit(s)</option>
                                                        <option value="E48s v4: 48">E48s v4: 48 vCPUs, 384 GB RAM, 0 GB Temporary storage, 12.00 Databricks Unit(s)</option>
                                                        <option value="E64s v4: 64">E64s v4: 64 vCPUs, 504 GB RAM, 0 GB Temporary storage, 16.00 Databricks Unit(s)</option>
                                                        <option value="E80is v4: 80">E80is v4: 80 vCPUs, 504 GB RAM, 2400 GB Temporary storage, 20.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "memoryoptimized" && values.instanceSeries == "esv5") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="E4s v5: 4">E4s v5: 4 vCPUs, 32 GB RAM, 0 GB Temporary storage, 1.50 Databricks Unit(s)</option>
                                                        <option value="E8s v5: 8">E8s v5: 8 vCPUs, 64 GB RAM, O GB Temporary storage, 2.75 Databricks Unit(s)</option>
                                                        <option value="E16s v5: 16">E16s v5: 16 vCPUs, 128 GB RAM, 0 GB Temporary storage, 5.50 Databricks Unit(s)</option>
                                                        <option value="E20s v5: 20">E20s v5: 20 vCPUs, 160 GB RAM, 0 GB Temporary storage, 7.00 Databricks Unit(s)</option>
                                                        <option value="E32s v5: 32">E32s v5: 32 vCPUs, 256 GB RAM, 0 GB Temporary storage, 11.00 Databricks Unit(s)</option>
                                                        <option value="E48s v5: 48">E48s v5: 48 vCPUs, 384 GB RAM, 0 GB Temporary storage, 16.50 Databricks Unit(s)</option>
                                                        <option value="E64s v5: 64">E64s v5: 64 vCPUs, 512 GB RAM, 0 GB Temporary storage, 22.00 Databricks Unit(s)</option>
                                                        <option value="E96s v5: 96">E96s v5: 96 vCPUs, 672 GB RAM, 0 GB Temporary storage, 33.00 Databricks Unit(s)</option>

                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "memoryoptimized" && values.instanceSeries == "ev3") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="E8 v3: 8">E8 v3: 8 vCPUs, 64 GB RAM, 200 GB Temporary storage, 2.00 Databricks Unit(s)</option>
                                                        <option value="E16 v3: 16">E16 v3: 16 vCPUs, 128 GB RAM, 400 GB Temporary storage, 4.00 Databricks Unit(s)</option>
                                                        <option value="E32 v3: 32">E32 v3: 32 vCPUs, 256 GB RAM, 800 GB Temporary storage, 8.00 Databricks Unit(s)</option>
                                                        <option value="E64 v3: 64">E64 v3: 64 vCPUs, 432 GB RAM, 1600 GB Temporary storage, 16.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}


                                            {/* storageoptimized and aginst instanceSeries  */}
                                            {(values.category === "storageoptimized" && values.instanceSeries == "lasv3") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>

                                                        <option value="L8as v3: 8">L8as v3: 8 vCPUs, 64 GB RAM, 80 GB Temporary storage, 2.75 Databricks Unit(s)</option>
                                                        <option value="L16as v3: 16">L16as v3: 16 vCPUs, 128 GB RAM, 160 GB Temporary storage, 5.50 Databricks Unit(s)</option>
                                                        <option value="L32as v3: 32">L32as v3: 32 vCPUs, 256 GB RAM, 320 GB Temporary storage, 11.00 Databricks Unit(s)</option>
                                                        <option value="L48as v3: 48">L48as v3: 48 vCPUs, 384 GB RAM, 480 GB Temporary storage, 16.50 Databricks Unit(s)</option>
                                                        <option value="L64as v3: 64">L64as v3: 64 vCPUs, 512 GB RAM, 640 GB Temporary storage, 22.00 Databricks Unit(s)</option>
                                                        <option value="L80as v3: 80">L80as v3: 80 vCPUs, 640 GB RAM, 800 GB Temporary storage, 27.50 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}
                                            {(values.category === "storageoptimized" && values.instanceSeries == "lsv2") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="L8s v2: 8">L8s v2: 8 vCPUs, 64 GB RAM, 80 GB Temporary storage, 2.00 Databricks Unit(s)</option>
                                                        <option value="L16s v2: 16">L16s v2: 16 vCPUs, 128 GB RAM, 160 GB Temporary storage, 4.00 Databricks Unit(s)</option>
                                                        <option value="L32s v2: 32">L32s v2: 32 vCPUs, 256 GB RAM, 320 GB Temporary storage, 8.00 Databricks Unit(s)</option>
                                                        <option value="L64s v2: 64">L64s v2: 64 vCPUs, 512 GB RAM, 640 GB Temporary storage, 16.00 Databricks Unit(s)</option>
                                                        <option value="L80s v2: 80">L80s v2: 80 vCPUs, 640 GB RAM, 800 GB Temporary storage, 20.00 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {(values.category === "storageoptimized" && values.instanceSeries == "lsv3") && <>
                                                <div>
                                                    <label htmlFor="clustersize" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                        Cluster Size
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                        id="clustersize"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.clustersize}
                                                        name={"clustersize"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}>
                                                        <option value="L8s v3: 8">L8s v3: 8 vCPUs, 64 GB RAM, 80 GB Temporary storage, 2.75 Databricks Unit(s)</option>
                                                        <option value="L16s v3: 16">L16s v3: 16 vCPUs, 128 GB RAM, 160 GB Temporary storage, 5.50 Databricks Unit(s)</option>
                                                        <option value="L32s v3: 32">L32s v3: 32 vCPUs, 256 GB RAM, 320 GB Temporary storage, 11.00 Databricks Unit(s)</option>
                                                        <option value="L48s v3: 48">L48s v3: 48 vCPUs, 384 GB RAM, 480 GB Temporary storage, 16.50 Databricks Unit(s)</option>
                                                        <option value="L64s v3: 64">L64s v3: 64 vCPUs, 512 GB RAM, 640 GB Temporary storage, 22.00 Databricks Unit(s)</option>
                                                        <option value="L80s v3: 80">L80s v3: 80 vCPUs, 640 GB RAM, 800 GB Temporary storage, 27.50 Databricks Unit(s)</option>
                                                    </select>
                                                </div>
                                            </>}

                                            <div className="col-md-6 px-3">
                                                <label htmlFor="count" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                    Virtual machines
                                                </label>
                                                <input
                                                    type="text"
                                                    style={{paddingTop :"10px"}}
                                                    className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                    id="count"
                                                    value={values.count}
                                                    name={"count"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}
                                                />
                                            </div>

                                            <div className="col-md-6 px-3">
                                                <label htmlFor="hours" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop:"20px"}}>
                                                    Hours
                                                </label>
                                                <input
                                                    type="text"
                                                    style={{paddingTop :"10px"}}
                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                    id="hours"
                                                    value={values.hours}
                                                    name={"hours"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}
                                                />
                                            </div>

                                            <div className="col-md-6 px-3">
                                                <label htmlFor="hoursFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Hours Factor
                                                </label>
                                                <select
                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                    id="hoursFactor"
                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                    value={values.hoursFactor}
                                                    name={"hoursFactor"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}>
                                                    <option value="1" >Hours</option>
                                                    <option value="24" >Days</option>
                                                    <option value="730" >Month</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="dbuHours" className="form-label font-14 font-medium black-v2 mb-3">
                                                    DBU (Databricks Unit) Hours
                                                </label>
                                                <input
                                                    type="text"
                                                    style={{paddingTop :"10px"}}
                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                    id="dbuHours"
                                                    value={values.dbuHours}
                                                    name={"dbuHours"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}
                                                />
                                            </div>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="dbuHoursFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                                    DBU (Databricks Unit) Hours Factor
                                                </label>
                                                <select
                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                    id="dbuHoursFactor"
                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                    value={values.dbuHoursFactor}
                                                    name={"dbuHoursFactor"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}>
                                                    <option value="1" >Hours</option>
                                                    <option value="24" >Days</option>
                                                    <option value="730" >Month</option>
                                                </select>
                                            </div>
                                        </>}
                                    </div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    } catch (error) {
        //console.log(error);
    }
}