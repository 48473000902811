import { useContext, useEffect, useState } from "react";
import Header from "../Header/Header";
import { fetchAllCloudProvider, fetchAllNotifications, fetchAllOrganization, updateNotification } from "../../Service/api";
import moment from "moment";
import { OrgContext } from "../../ContextProvider/OrgContext";

function Notifications() {

  const [allNotifications, setAllNotifications] = useState([]);
  const [activeTab, setActiveTab] = useState('all');
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [errorToaster, setErrorToaster] = useState(false);
  const [cloudProviderData, setCloudProviderData] = useState([])
  const [organizationData, setOrganizationData] = useState([])
  const [filterData, setFilterData] = useState({
    startDate: '',
    endDate: '',
    organization: '',
    cloudProvider: ''
  });
  const userId = localStorage.getItem('userId');
  console.log("userid",userId)

  const{orgId}=useContext(OrgContext)

  console.log(orgId,"organization")


  // PS_13
  // Fetch notifications based on filters
  useEffect(() => {
    getNotificationData(filterData);
    getCloudProviderData()
    getOrganizationData()
  }, []);

console.log(filterData.organization,"filterData")


  
  // PS_14 - PS_16
  const getNotificationData = async (filterDatas = filterData) => {
    try {
      const payload = {
        "userId": userId,
         "orgId": filterDatas.organization,
        "fromDate": filterDatas.startDate,
        "ToDate": filterDatas.endDate,
        "cloudProviderId": filterDatas.cloudProvider
      };


      console.log(payload, "payload")
      const response = await fetchAllNotifications(payload);
      console.log(response , "Response for the FetchAll Notification")
      if (response.status === 200) {
        setAllNotifications(response.data.body.data);
        setFilteredNotifications(response.data.body.data);
        // setFilteredNotifications(getFilteredNotifications());

      }
    } catch (error) {
      console.error('API Error:', {
        errorName: error.name,
        errorServiceName: "Front-end",
        errorFunctionName: "getNotificationData",
        errorSource: "frontend",
        errorDescription: "fetch failed" + error.message
      });
    }
  };


  const getCloudProviderData = async () => {
    try {
      const payload = {
        "userId": userId,
        "orgId": "", // Assuming orgId is intentionally left empty
        "type": "general"
      };

      const response = await fetchAllCloudProvider(payload); // Replace with your actual API call function
      console.log(response, "response");

      if (response.status === 200) {
        setCloudProviderData(response.data.data); // Store the response in a state variable
      } else {
        console.error("Error fetching cloud provider data:", response.data.body.message);
      }
    } catch (error) {
      console.error("API Error:", {
        errorName: error.name,
        errorServiceName: "Front-end",
        errorFunctionName: "getCloudProviderData",
        errorSource: "frontend",
        errorDescription: "fetch failed: " + error.message
      });
    }
  };

  const getOrganizationData = async () => {
    try {
      const payload = {
        "userId": userId,
        "orgId": orgId
      }

      const response = await fetchAllOrganization(payload); // Replace with your actual API call function
      console.log(response, "responseorg");

      if (response.status === 200) {
        setOrganizationData(response.data.data); // Store the response in a state variable
      } else {
        console.error("Error fetching cloud provider data:", response.data.body.message);
      }
    } catch (error) {
      console.error("API Error:", {
        errorName: error.name,
        errorServiceName: "Front-end",
        errorFunctionName: "getCloudProviderData",
        errorSource: "frontend",
        errorDescription: "fetch failed: " + error.message
      });
    }
  };



  //PS_17 - PS_18
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setFilteredNotifications(getFilteredNotifications(tab));
  };

  //PS_19 - PS_23
  const getFilteredNotifications = (tab = activeTab) => {
    switch (tab) {
      case 'all':
        return allNotifications;
      case 'read':
        return allNotifications?.filter(notification => notification?.isread == true);
      case 'unread':
        return allNotifications?.filter(notification => notification?.isread == false);
      default:
        return allNotifications;
    }
  };

  //PS_25
  const renderNotifications = () => {
    
    if (!filteredNotifications || filteredNotifications.length === 0) {
      return (
        <div className="no-record-found">
          <img src="images/no-records-found.png" className="no-record-icon" alt="No records found" />
        </div>
      );
    }

    return filteredNotifications?.map((notification, index) => (
      <li key={index} className={`${notification.isread == false ? 'active' : ''} position-relative`}
        onClick={() => {
          updateNotificationData(notification.notificationtableId);
        }}>
        <div className="d-flex align-items-center justify-content-between title-container">
          <div className="d-flex align-items-center">
            <img
              className="partner-logo"
              // src={`images/${notification.organizationLogo}-logo.svg`}
              src={notification.organizationLogo}
              alt="partner-logo"
            />
            {/* <img
              className="partner-logo dark-theme-img"
              // src={`images/${notification.organizationLogo}-logo-dark.svg`}
              src={notification.organizationLogo}
              alt="partner-logo"
            /> */}
            <div className="ms-4 ps-2">
              <a className="font-16 font-medium black-v2 text-decoration-none" href="#">
                {notification.tittle}
                <span className="ms-3 font-14 font-medium secondary-text-color">
                {moment(notification.timestamp).local().format('MMMM Do YYYY, h:mm A')}
                </span>
              </a>
              <p className="mb-0 font-14 font-regular mt-2 pt-1 secondary-text-color-v4">
                {notification.message}
              </p>
            </div>
          </div>

        </div>
      </li>
    ));
  };

  //PS_33 - PS_36
  const updateNotificationData = async (notificationId) => {
    try {
      const payload = {
        userId: userId,
        notificationtableId: notificationId,
      };
      const response = await updateNotification(payload);
      if (response.status === 200) {
        console.log('Notification updated successfully:', response.data);

        await getNotificationData(filterData);

        handleTabChange('read');
      } else {
        console.error('Failed to update notification:', response);
      }
    } catch (error) {
      console.error('API Error:', {
        errorName: error.name,
        errorServiceName: "Front-end",
        errorFunctionName: "updateNotificationData",
        errorSource: "frontend",
        errorDescription: "Update failed: " + error.message
      });
    }
  };

  //PS_40
  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split('T')[0]; // Format YYYY-MM-DD
  };

  //PS_37
  const filterValidation = (e) => {
    debugger
    const { id, value } = e.target;
    console.log(id, value )
    setFilterData(prevState => ({
      ...prevState,
      [id]: value
    }));
  };


// PS_38

  const handleApply = async () => {
    // Logic to apply filters

    await getNotificationData(filterData);
    //setAllNotifications([]);
    console.log('Filters applied:', filterData);
    setTimeout(() => {
      const closeButton = document.getElementById('filter');
      if (closeButton) {
        closeButton.click();
      }
    }, 2000);
  };

  // PS_39
  const handleClear = () => {
    
    setFilterData({
      organization: '',
      cloudProvider: '',
      startDate: '',
      endDate: ''
    });
    setTimeout(() => {
      const closeButton = document.getElementById('filter');
      if (closeButton) {
        closeButton.click();
      }
    }, 2000);
    // setCloudProviderData([])
    // setOrganizationData([])
    getNotificationData(filterData)
  };



  return (
    <>

      {/* header starts */}
      
      {/* header ends */}

      <div className="container-fluid custom-container right-container-scroll">
        {/* Slide title start here*/}
        <div className="d-flex justify-content-between align-items-center pt-4 mt-2 notifications-title-bar">
          <h2 className="font-20 font-bold black-v2 mb-0">Notifications</h2>
          <div className="d-flex gap-3">
            {/* filter start */}
            <button
              type="button"
              className="filter-button position-relative"
              data-bs-auto-close="false"
              data-bs-toggle="dropdown"
              id="filter"
            >
              <img src="images/filter-blue.svg" alt="filter-icon" />{" "}
            </button>
            {/*filter*/}
            <div className="dropdown-menu dropdown-menu-end border-0 custom-shadow dd-position background-white custom-filter-width">
              <h5 className="font-bold font-20 color-black mb-3">Filter</h5>
              <div className="row">
                <div className="col-md-6 mb-4">
                  <label
                    className="form-label font-14 font-medium black-v2"
                    htmlFor="organization"
                  >
                    Organization
                  </label>
                  <select
                    className="form-select custom-dropdown shadow-none custom-border-box py-1 font-14 font-regular black-v2 custom-select"
                    aria-label="Default select example"
                    id="organization"
                    onChange={filterValidation}
                    value={filterData.organization}
                  >
                    <option selected="">Select</option>
                    {organizationData.map(organization => (
                      <option key={organization.orgId} value={organization.orgId}>{organization.orgName}</option>
                    ))}
                  </select>
                </div>
                <div className="col-md-6 mb-4">
                  <label
                    className="form-label font-14 font-medium black-v2"
                    htmlFor="cloud-provider"
                  >
                    Cloud Provider
                  </label>
                  <select
                    className="form-select custom-dropdown shadow-none form-field-border py-1 font-14 font-regular black-v2 custom-select"
                    aria-label="Default select example"
                    id="cloudProvider"
                    onChange={filterValidation}
                    value={filterData.cloudProvider}
                  >
                    <option selected="">Select</option>
                    {cloudProviderData.map(cloudProvider => (
                      <option key={cloudProvider.providerId} value={cloudProvider.providerId}>{cloudProvider.providerName}</option>
                    ))}
                  </select>
                </div>
                <div className="col-md-12 mb-2">
                  <label className="font-14 font-medium black-v2">Date Range</label>
                </div>
                <div className="col-md-6 mb-4 calender-input">
                  <label className="form-label font-14 font-medium label-color" htmlFor="startDate">From</label>
                  <input
                    className="form-control"
                    type="date"
                    id="startDate"
                    onChange={filterValidation}
                    value={filterData.startDate ? new Date(filterData.startDate).toISOString().split('T')[0] : ''}
                    max={getCurrentDate()}
                  />
                 
                </div>

                <div className="col-md-6 mb-4 calender-input">
                  <label className="form-label font-14 font-medium label-color" htmlFor="endDate">To</label>
                  <input
                    className="form-control"
                    type="date"
                    id="endDate"
                    onChange={filterValidation}
                    value={filterData.endDate ? new Date(filterData.endDate).toISOString().split('T')[0] : ''}
                    min={filterData.startDate} // Ensure end date is after start date
                  />
                 
                </div>

                <div className="col-md-12">
                  <div className="d-flex justify-content-end align-items-center gap-3">
                    <button className="button outline-button" onClick={handleClear}>Clear All</button>
                    <button
                      type="button"
                      className="button primary-button"
                      onClick={handleApply}
                      disabled={!filterData.organization && !filterData.cloudProvider && !filterData.startDate && !filterData.endDate} // Disable if no filters are set
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/*filter ends*/}
          </div>
        </div>
        {/* Slide title end here*/}
        {/* Page Content Start here */}
        <div className="notification-tab-parent">
          <div className="notification-tab-title mb-2">
            <ul className="nav nav-tabs custom-border-bottom" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${activeTab === 'all' ? 'active' : ''}`}
                  onClick={() => handleTabChange('all')}
                >
                  All
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${activeTab === 'read' ? 'active' : ''}`}
                  onClick={() => handleTabChange('read')}
                >
                  Read
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${activeTab === 'unread' ? 'active' : ''}`}
                  onClick={() => handleTabChange('unread')}
                >
                  Unread
                </button>
              </li>
            </ul>
          </div>
          <div className="notification-tab-content mt-4 pt-2">
            <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade show active" id="all-pane" role="tabpanel" aria-labelledby="all" tabIndex={0}>
                <ul className="list-unstyled">
                {allNotifications.length === 0 ? (
                                                <tr>
                                                    <td colSpan="8" className="no-record-cell">
                                                        <div className="no-record-found">
                                                            <img src="images/no-records-found.png" alt="No records found" className="no-record-icon" />

                                                        </div>
                                                    </td>
                                                </tr>
                                            ) : (
                                              renderNotifications()
                                            )}
                 {/* // {renderNotifications()} */}
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* Page Content End here */}
      </div>
    </>

  )
}
export default Notifications;