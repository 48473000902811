
import React from 'react';

const CategoryAccordion = ({
    category,
    handleCategoryClick,
    handleServiceClick,
    expandedCategoryId,
    expandedServiceId,
    setShowDataResourcePopup,
    onAddResourceClick,
    handleResourceNameClick 
}) => {
    const isCategoryExpanded = expandedCategoryId === category.categoryId;

    return (
        <div className={`accordion-item${isCategoryExpanded ? '' : ' mb-0'} border-0 background-white`}>
            <div className="w-100">
                <div className="accordion-header d-flex align-items-center" id={`heading-${category.categoryId}`} style={{paddingTop:'8%',paddingBottom:'0%'}}>
                <button
                        className={`cust-acc-btn d-flex align-items-center gap-2 font-14 btn border-0 me-3 px-0 ${isCategoryExpanded ? '' : 'collapsed'}`}
                        type="button"
                        onClick={() => handleCategoryClick(category.categoryId)}
                        aria-expanded={isCategoryExpanded}
                        aria-controls={`collapse-${category.categoryId}`}
                    >
                        {/* Empty span just to occupy space for the ::before pseudo-element */}
                        <span></span>
                    </button>
                    <span className="d-flex align-items-center gap-3">
                        <img
                            src={category.categoryImage}
                            alt={`${category.category}-icon`}
                            className="dark-icons"
                        />
                        <span className="font-14 font-medium black-v2">{category.category}</span>
                    </span>
                </div>
                <div
                    id={`collapse-${category.categoryId}`}
                    className={`accordion-collapse collapse${isCategoryExpanded ? ' show' : ''}`}
                    aria-labelledby={`heading-${category.categoryId}`}
                >
                    <div className="accordion-body px-4 pe-0">
                        <div className="accordion" id={`service-accordion-${category.categoryId}`}>
                            {category.services.map(service => {
                                const isServiceExpanded = expandedServiceId === service.serviceId;
                                return (
                                    <div className={`accordion-item${isServiceExpanded ? '' : ' mb-0'} border-0 background-white`} key={service.serviceId}>
                                        <div className="w-100">
                                            <div className="accordion-header d-flex align-items-center" id={`sub-heading-${service.serviceId}`} >
                                            <button
                                                    className={`cust-acc-btn d-flex align-items-center gap-2 font-14 btn border-0 me-3 px-0 ${isServiceExpanded ? '' : 'collapsed'}`}
                                                    type="button"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleServiceClick(service.serviceId);
                                                    }}
                                                    aria-expanded={isServiceExpanded}
                                                    aria-controls={`collapse-service-${service.serviceId}`}
                                                >
                                                    {/* Empty span to occupy space for the ::before pseudo-element */}
                                                    <span></span>
                                                </button>
                                                <span className="font-12 font-medium">{service.serviceName}</span>
                                            </div>
                                            <div
                                                id={`collapse-service-${service.serviceId}`}
                                                className={`accordion-collapse collapse${isServiceExpanded ? ' show' : ''}`}
                                                aria-labelledby={`sub-heading-${service.serviceId}`}
                                            >
                                                <div className="accordion-body py-2 ms-3 pe-0">
                                                    <ul className="p-0 list-style-none w-100 gap-2 target-nav-item pe-0">
                                                        {service.resources.map(resource => (
                                                            <li className="d-flex justify-content-between align-items-center color-black" key={resource.resourceId}>
                                                                <button className="font-regular font-12 border-0 bg-transparent text-truncate py-2  color-black"
                                                                  onClick={() => handleResourceNameClick(resource.resourceName)} >
                                                                    {resource.resourceName}
                                                                </button>
                                                                {/* <button className="add-icon border-0 bg-transparent p-0 d-flex"
                                                                    onClick={() => {
                                                                        onAddResourceClick(resource);
                                                                        setShowDataResourcePopup(true);
                                                                    }}>
                                                                    <img src="images/plus-icon-blue.svg" alt="add-icon" />
                                                                </button> */}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CategoryAccordion;