import { useCallback, useContext, useEffect, useState } from "react";
import Header from "../Header/Header"
import { useLocation, useNavigate } from "react-router-dom";
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import Editor from '@monaco-editor/react';
import { fetchAllCloudProvider, fetchCategoryData, postServiceDetails, updateServiceData } from "../../Service/api";
import { OrgContext } from "../../ContextProvider/OrgContext";

function AddNewService1() {

    const [selectedProvider, setSelectedProvider] = useState('');
    const [containsServices, setContainsServices] = useState(false)
    const [category, setCategory] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [serviceName, setServiceName] = useState('');
    const [serviceIcon, setServiceIcon] = useState(null);
    const [serviceIconName, setServiceIconName] = useState("")
    const [description, setDescription] = useState('');


    const [showDependsOnModal, setShowDependsOnModal] = useState(false);
    const [iconName, setIconName] = useState("")

    const [dependsOnFeature, setDependsOnFeature] = useState('');
    const [serviceFields, setServiceFields] = useState([]);
    const [selectedDependencies, setSelectedDependencies] = useState([]);
    const [currentDependency, setCurrentDependency] = useState(null);

    const [previousFieldNames, setPreviousFieldNames] = useState([]);
    const [isEditing, setIsEditing] = useState(false)
    const [inputConfiguration, setInputConfiguration] = useState([]);
    const [fieldType, setFieldType] = useState('');
    const [showDropdownModal, setShowDropdownModal] = useState(false)
    // const [values, setValues] = useState([]);
    const [values, setValues] = useState([{ value: '' }]);
    const [inputValues, setInputValues] = useState(values.map(() => ''));
    const [isDependsOn, setIsDependsOn] = useState(false);
    const [dependsOnService, setDependsOnService] = useState('');
    const [dependsOnField, setDependsOnField] = useState('');
    const [labelName, setLabelName] = useState('');
    const [isRequired, setIsRequired] = useState(false);
    const [language, setLanguage] = useState('terraform');
    const [terraformCode, setTerraformCode] = useState("");
    const [cloudFormationCode, setCloudFormationCode] = useState("");
    const [armCode, setArmCode] = useState('');
    const [error, setError] = useState('')
    const [fileType, setFileType] = useState("")

    const [providerData, setproviderData] = useState([])
    const [servicesData, setservicesData] = useState([])
    const [categoryData, setcategoryData] = useState([])
    const [serviceData, setserviceData] = useState([])
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const userId = localStorage.getItem('userId');
    console.log("userid", userId)

    const { orgId } = useContext(OrgContext)

    console.log(orgId, "organization")

    const navigate = useNavigate();

    const location = useLocation();

    useEffect(() => {
        getCloudProviderData()

    }, [])

    useEffect(() => {
        if (selectedProvider) {
            fetchAllCategoryData();
        }

    }, [selectedProvider])

    // useEffect(() => {
    //     console.log("selected cloud provider", selectedProvider)
    // }, [selectedProvider])


    const getCloudProviderData = async () => {
        try {
            const payload = {
                "userId": userId,
                "orgId": "", // Assuming orgId is intentionally left empty
                "type": "general"
            };

            const response = await fetchAllCloudProvider(payload); // Replace with your actual API call function
            console.log(response, "response");

            if (response.status === 200) {
                setproviderData(response.data.data); // Store the response in a state variable

                const awsProvider = response.data.data.find(provider => provider.providerName.toLowerCase() === 'aws');

                if (awsProvider) {
                    setSelectedProvider(awsProvider.providerId)

                } else {
                    console.error("AWS provider not found in the response");
                }

            } else {
                console.error("Error fetching cloud provider data:", response.data.body.message);
            }
        } catch (error) {
            console.error("API Error:", {
                errorName: error.name,
                errorServiceName: "Front-end",
                errorFunctionName: "getCloudProviderData",
                errorSource: "frontend",
                errorDescription: "fetch failed: " + error.message
            });
        }
    };



    const fetchAllCategoryData = async () => {
        try {

            const payload = {
                "cloud_provider_id": selectedProvider,
                "userId": userId
            }
            const response = await fetchCategoryData(payload);
            if (response.status === 200) {
                console.log(response, "resp")
                setcategoryData(response.data.body.data);
            } else {
                throw new Error('Error fetching category data');
            }
        } catch (err) {
            //postErrorLog(err.message || err.toString());
            setError(err);
        }
    };


    useEffect(() => {
        if (location.state) {
            console.log('Entire location object:', location); // Add this line
            const servicesData = location.state?.serviceData;

            setservicesData(servicesData)
            console.log(servicesData, "services")

            const serviceData = location.state?.serviceEditData || {};
            setserviceData(serviceData)
            console.log(serviceData, "editserviceData")
        }
    }, [location])


    //PS_58 :Initial useEffect trigger when navigated on clicking edit button
    useEffect(() => {
        if (serviceData) {
            setSelectedProvider(serviceData.cloudproviderId || '');
            setSelectedCategory(serviceData.categoryId || '');
            setContainsServices(serviceData.containsService || false);
            setServiceName(serviceData.serviceName || '');
            setServiceIcon(serviceData.serviceIcon || null);
            setIconName(serviceData.serviceImage || '');
            setDescription(serviceData.description || '');
            setInputConfiguration(serviceData.fields || []);
            setTerraformCode(serviceData.TerraformCode || '');
            setArmCode(serviceData.armcode || '');
            setCloudFormationCode(serviceData.CloudFormationCode || '');
            setServiceIconName(serviceData.InputConfiguration)

        }
    }, [serviceData]);



    const DragHandle = sortableHandle(() => <span className="drag-handle">::</span>);

    //PS_11 - PS_14 : Validation check for the uploaded service image
    const validateAndSetServiceIcon = (file) => {
        if (!file) {
            setError("No file selected");
            return;
        }

        const validExtensions = ['png', 'jpg'];
        const maxSize = 1 * 1024 * 1024; // 1MB

        // Check file extension
        const extension = file.name.split('.').pop().toLowerCase();
        if (!validExtensions.includes(extension)) {

            setError('Invalid file type. Only PNG and JPG are allowed.');

            return;
        }

        // Check file size
        if (file.size > maxSize) {
            setError('File size exceeds 1MB limit.');

            return;
        }

        setFileType(extension);

        // Check image dimensions
        const img = new Image();
        img.onload = () => {
            URL.revokeObjectURL(img.src);
            if (img.width !== 32 || img.height !== 32) {
                setError('Image dimensions must be exactly 32x32 pixels');

            } else {
                // Convert the image to Base64 and set it
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64data = reader.result; // This will be your Base64 string
                    setServiceIcon(base64data); // Store Base64 string in state
                    setServiceIconName(file.name)
                    console.log("Service icon set:", serviceIconName);
                };
                reader.readAsDataURL(file); // Convert file to Base64
            }
        };
        img.onerror = () => {
            URL.revokeObjectURL(img.src);
            setError('Error loading image');
        };
        img.src = URL.createObjectURL(file);
    };

    //PS_15 :To remove the service icon uploaded
    const removeServiceIcon = () => {
        setServiceIcon(null);
        setServiceIconName("")
    };

    //PS_50 -PS_51
    const handleSaveButtonClick = () => {
        const requestData = {
            userId: userId,
            cloudproviderId: selectedProvider,
            categoryId: selectedCategory,
            serviceName: serviceName,
            uploadIconBase64: serviceIcon,
            containsService: containsServices,
            description: description,
            InputConfiguration: inputConfiguration,
            TerraformCode: terraformCode,
            armcode: armCode,
            CloudFormationCode: cloudFormationCode,
            filetype: fileType,
            filename: serviceIconName

        };
        console.log(requestData, "requestData")
        console.log(fieldType, "fieldType")

        postServiceDetails(requestData)
            .then((response) => {
                if (response.statusCode === 200) {
                    navigate('/services', { state: { message: 'Service saved successfully!' } });
                }
            })
            .catch((error) => console.log(error));

        setSelectedProvider('')
        setSelectedCategory('')
        setContainsServices(false)
        setServiceName('')
        setServiceIcon(null)
        setServiceIconName('')
        setDescription('')
        setInputConfiguration([])
        setTerraformCode('')
        setArmCode('')
        setCloudFormationCode('')
        setIsRequired(false)
        setTerraformCode("")
        setArmCode("")
        setCloudFormationCode("")
        navigate('/SelfService')

    };

    const handleUpdateService = () => {
        const requestData = {
            userId: userId,
            cloudproviderId: selectedProvider,
            categoryId: selectedCategory,
            serviceName: serviceName,
            uploadIconBase64: serviceIcon,
            containsService: containsServices,
            description: description,
            InputConfiguration: inputConfiguration,
            TerraformCode: terraformCode,
            armcode: armCode,
            CloudFormationCode: cloudFormationCode,
            filetype: fileType,
            filename: serviceIconName

        };
        console.log(requestData, "requestData")
        console.log(fieldType, "fieldType")
        requestData.serviceId = serviceData.service_id

        updateServiceData(requestData)
            .then((response) => {
                if (response.statusCode === 200) {
                    navigate('/services', { state: { message: 'Service saved successfully!' } });
                }
            })
            .catch((error) => console.log(error));

        setSelectedProvider('')
        setSelectedCategory('')
        setContainsServices(false)
        setServiceName('')
        setServiceIcon(null)
        setServiceIconName('')
        setDescription('')
        setInputConfiguration([])
        setIsRequired(false)
        setTerraformCode("")
        setArmCode("")
        setCloudFormationCode("")
        // navigate('/SelfService')

    };


    // PS_55 - PS_56 : // Clear state and navigate back to services component
    const handleCancelButtonClick = () => {
        setSelectedProvider('');
        setCategory('');
        setServiceName('');
        setServiceIcon(null);
        setDescription('');
        setInputConfiguration([]);
        navigate('/SelfService');
    };

    //PS_43: To handle the sortable input fields
    const SortableItem = SortableElement(({ value, index }) => (
        <div key={index} className="col-md-8 mb-3 custom-function-field">
            <input
                type="text"
                id="fuction"
                className="form-control custom-form"
                placeholder=""
                value={value.fieldName}
                onChange={(e) => {
                    console.log("sdfsdfs")
                    handleEditField(e.target.value, index)
                }
                }
                disabled={!isEditing}
            />
            <span className="function-field-img"></span>
            <span className="function-field-options">
                <button
                    style={{ border: "none" }}
                    className="cursor-pointer font-medium font-14 primary-color text-decoration-none me-2"
                    onClick={(e) => {
                        setIsEditing(true)
                    }}
                >
                    Edit
                </button>
                <button
                    style={{ border: "none" }}
                    className="cursor-pointer font-medium font-14 danger-red text-decoration-none ms-2"
                    onClick={() => handleDeleteField(index)}  >
                    Delete
                </button>
            </span>

        </div>
    ));


    //PS_46 -To edit the  field
    const handleEditField = useCallback((newFieldName, index) => {
        console.log('handleEditField called', newFieldName, index);
        const newInputConfiguration = [...inputConfiguration];
        newInputConfiguration[index].fieldName = newFieldName;
        setInputConfiguration(newInputConfiguration);
    }, [inputConfiguration]);

    //PS_47 - to delete the field
    // const handleDeleteField = useCallback((index) => {
    //     console.log("delete feild is entered")
    //     const newInputConfiguration = [...inputConfiguration];
    //     newInputConfiguration.splice(index, 1);
    //     setInputConfiguration(newInputConfiguration);
    // }, [inputConfiguration]);

    const handleDeleteField = useCallback((index) => {
        console.log("Deleting field at index:", index);
        const newInputConfiguration = inputConfiguration.filter((_, i) => i !== index);
        console.log("New input configuration:", newInputConfiguration);
        setInputConfiguration(newInputConfiguration);
    }, [inputConfiguration]);

    //PS_44 - To sort the sortable container by dragging
    // const SortableList = SortableContainer(({ items }) => (
    //     <ul>
    //         {items.map((item, index) => (
    //             <SortableItem key={index} index={index} value={item} />
    //         ))}
    //     </ul>
    // ));

    const SortableList = SortableContainer(({ items }) => (
        <ul>
            {items.map((item, index) => (
                <SortableItem key={item.service_id} index={index} value={item} /> // Use a unique key if available
            ))}
        </ul>
    ));

    //PS_45 - To update the sorted order of  fields in input confirguration
    const handleSortEnd = ({ oldIndex, newIndex }) => {
        const newInputConfiguration = [...inputConfiguration];
        const item = newInputConfiguration.splice(oldIndex, 1)[0];
        newInputConfiguration.splice(newIndex, 0, item);
        setInputConfiguration(newInputConfiguration);
    };




    //PS_28 : To handle dependson when depends on toggle is enabled
    const handleDependsOnToggle = (isChecked) => {

        setIsDependsOn(isChecked);
        if (isChecked) {

            setShowDependsOnModal(true);
        } else {
            setDependsOnService('');
            setDependsOnFeature('');
        }
    };

    //PS_36: To close the depends on modal
    const handleCloseDependsOnModal = () => {

        setShowDependsOnModal(false)
        // Reset the toggle if the user cancels
    };

    //PS_29  : To extract service names
    const serviceNames = servicesData?.flatMap(category =>
        category.services.map(service => ({
            serviceName: service.serviceName,
            serviceId: service.service_id
        }))
    );

    //PS_30
    // const serviceIds = servicesData?.flatMap(category =>
    //     category.services.map(service => service.serviceId)
    // );

    //PS_31 -PS_33 : Initial render for seggregating service names from the servicesData
    useEffect(() => {
        if (dependsOnService) {
            const selectedService = servicesData
                ?.flatMap(category => category.services)
                .find(service => service.service_id === dependsOnService);

            if (selectedService) {
                setServiceFields(selectedService.fields.map(field => field.fieldName));
            } else {
                setServiceFields([]);
            }
        } else {
            setServiceFields([]);
        }
        setDependsOnFeature('');
    }, [dependsOnService, servicesData]);



    //PS_65 :To update the current dependency
    const getCurrentDependency = () => {
        return currentDependency;
    };

    // PS_65: To update the dependency values in parent array
    const updateDependencyInParent = (newDependency) => {
        setCurrentDependency(newDependency);
    };


    //PS_18 - PS_20 : To handle addfield each time new field is selected
    const handleAddField = () => {
        if (labelName !== '') {
            const newField = {
                fieldName: labelName,
                fieldType: fieldType,
                value: "",
                isDependsOn: isDependsOn,
                required: isRequired,
                dependsOn: {
                    serviceId: dependsOnService,
                    fieldName: dependsOnField,
                },
                depends_on_configuration_id: ""
            };
            console.log(isRequired, "isReuired")
            if (fieldType === 'dropdown') {
                newField.value = inputValues;
            }

            setInputConfiguration([...inputConfiguration, newField]);
            setPreviousFieldNames([...previousFieldNames, labelName]);
            //setFieldType('');
            setIsDependsOn(false);
            setLabelName('');
            setInputValues([]);
            setIsRequired(false)
        }
    };

    //PS_47 - PS_49: To handle the name of the label
    const handleLabelNameChange = (e) => {
        setLabelName(e.target.value);
    };

    //PS_54: To handle the field type selected
    const handleFieldTypeChange = (e) => {
        console.log(e, "EEE")
        // console.log('handleFieldTypeChange called with value:', e.target.value);
        setFieldType(e);

    };

    //PS_57: To handle the fieldType selection is dropdown
    useEffect(() => {
        console.log(fieldType, "Changedd Field Typeeee")
        if (fieldType === 'dropdown') {
            setShowDropdownModal(true);
        } else {
            setShowDropdownModal(false);
        }
    }, [fieldType]);





    //PS_37 : To handle edit in dependsOn
    const handleEditDependsOn = () => {
        // Assuming you have the current dependencies stored somewhere
        // You might need to adjust this based on how you're storing the data
        const currentDependency = getCurrentDependency(); // implement this function
        if (currentDependency) {
            setDependsOnService(currentDependency.service);
            setDependsOnFeature(currentDependency.feature);
            setSelectedDependencies([currentDependency]);
        }
        setShowDependsOnModal(true);
    };

    //PS_36: To handle delete in dependsOn
    const handleDeleteDependsOn = () => {
        // Clear the local state
        setDependsOnService('');
        setDependsOnFeature('');
        setSelectedDependencies([]);

        // Update the parent component
        updateDependencyInParent(null);

        // Turn off the toggle
        setIsDependsOn(false);

        // You might want to show a confirmation message
        alert('Dependency has been removed.');
    };

    //PS_34: To handle save in depends on 
    const handleSaveDependsOn = () => {
        setInputConfiguration([...inputConfiguration]);
        setShowDependsOnModal(false)

    };

    //PS_62- PS_64
    //To handle the code the selected and store the data accordingly
    const handleCodeChange = (newCode) => {
        switch (language) {
            case 'terraform':
                setTerraformCode(newCode);
                break;
            case 'cloudformation':
                setCloudFormationCode(newCode);
                break;
            case 'arm':
                setArmCode(newCode);
                break;
            default:
                break;
        }
    };

    // PS_21: To handle new values and store if fieldType is dropdown
    const handleAddValue = () => {
        console.log(values)
        setValues([...values, '']);
        setInputValues([...inputValues, '']);
    };

    //PS_22: To delete the values stored 
    const handleDeleteValue = (index) => {
        setValues(values.filter((_, i) => i !== index));
        setInputValues(inputValues.filter((_, i) => i !== index));
    };


    //PS_42: To handle the values in input field
    const handleInputChange = (index, value) => {
        setInputValues(inputValues.map((val, i) => i === index ? value : val));
    };

    // PS_24: To handle save for dropdwon values
    const handleSaveDropdown = () => {
        console.log("dropdown values", inputValues)
        //setInputValues(inputValues);
        setShowDropdownModal(false);
    };



    useEffect(() => {
        const isAnyFieldEmpty =
            !selectedProvider ||
            !selectedCategory ||
            !serviceName ||
            !serviceIcon ||
            !description ||
            !armCode &&
            !cloudFormationCode &&
            !terraformCode;

        setIsButtonDisabled(isAnyFieldEmpty);
    }, [
        selectedProvider,
        selectedCategory,
        serviceName,
        serviceIcon,
        description,
        armCode,
        cloudFormationCode,
        terraformCode,
    ]);

    console.log(category, "category")

    return (
        <>
            {/* header starts */}
           
            {/* header ends */}
            <div className="container-fluid custom-container">
                {/* Slide title start here*/}
                <div className="d-block mt-4 pt-2">
                    <div className="d-flex align-items-center mb-3 mb-md-0 ">
                        <a href="#">
                            <img
                                src="images/back-arrow.svg"
                                alt="back-arrow"
                                title="Back"
                                className="icon-filter"
                                onClick={() => { navigate('/SelfService') }}
                            />
                        </a>
                        <h2 className="font-20 font-bold black-v2 mb-0 ms-3 ">
                            Add New Service
                        </h2>
                    </div>
                </div>
                {/* Slide title end here*/}
                {/* Page Content Start here */}
                <div className="row add-organization-form">
                    <div className="col-md-12 mt-4 pt-3 ps-4">
                        <div className="ps-4">
                            <div className="row">
                                <div className="col-md-3 mb-4">
                                    <label
                                        className="form-label font-14 font-medium"
                                        htmlFor="cloud-provider"
                                    >
                                        Cloud Provider<span className="required">*</span>
                                    </label>
                                    <select
                                        name="region"
                                        id="cloud-provider"
                                        className="form-select form-control custom-form"
                                        value={selectedProvider}
                                        onChange={(e) => setSelectedProvider(e.target.value)}
                                    >
                                        <option selected="">Select</option>
                                        {providerData.map((provider) => (
                                            <option key={provider.providerId} value={provider.providerId}>
                                                {provider.providerName}
                                            </option>
                                        ))}
                                    </select>

                                </div>
                                <div className="col-md-3 mb-4">
                                    <label
                                        className="form-label font-14 font-medium"
                                        htmlFor="category"
                                    >
                                        Category<span className="required">*</span>
                                    </label>
                                    <select
                                        name="region"
                                        id="select-category"
                                        className="form-select font-14 font-regular"
                                        value={selectedCategory}
                                        onChange={(e) => { setSelectedCategory(e.target.value) }}

                                    >
                                        <option value="" disabled>Select Category</option>
                                        {categoryData?.map((category) => (
                                            <option key={category.serviceCategoryId} value={category.serviceCategoryId}>
                                                {category.serviceCategoryName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label
                                        className="form-label font-14 font-medium"
                                        htmlFor="service-name"
                                    >
                                        Service<span className="required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="service-name"
                                        className="form-control custom-form"
                                        placeholder="Enter Service Name"
                                        value={serviceName}
                                        onChange={(e) => setServiceName(e.target.value)}
                                    />
                                </div>
                                <div className="col-md-3 mb-4">
                                    <div className="position-relative">
                                        <label
                                            className="form-label font-14 font-medium"
                                            htmlFor="region"
                                        >
                                            Upload Service Icon<span className="required">*</span>
                                        </label>
                                        <label className="file-upload-outline position-absolute rounded-3 d-flex align-items-center gap-2 font-14 font-medium">
                                            Upload Icon
                                        </label>
                                        <button
                                            type="button"
                                            className="btn p-0 ms-1"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="right"
                                            data-bs-title="Kindly add updated icons with proper formar - sample text need to add by dev team"
                                            onClick={() => setError('')}
                                        >
                                            <img
                                                src="images/tooltip-icon.svg"
                                                alt="toolttip-icon"
                                                className="toolttip-icon"
                                            />
                                        </button>
                                        <input
                                            type="file"
                                            className="form-control custom-file"
                                            id="orgLogo"
                                            aria-label="upload"
                                            onChange={(e) => validateAndSetServiceIcon(e.target.files[0])}

                                        // disabled={serviceIcon !== ""}
                                        />

                                        <div className="selected-file">
                                            <span>{serviceIconName}{''}</span>

                                            {serviceIconName && ( // Only render the close icon if serviceIconName is not empty
                                                <a href="#" onClick={(e) => {
                                                    e.preventDefault();
                                                    removeServiceIcon();
                                                }}>

                                                    <img
                                                        src="images/tags-close-icon.svg"
                                                        alt="close-icon"
                                                        className="light-theme-img"
                                                    />
                                                </a>
                                            )}
                                        </div>
                                        {error && error.length > 0 && (
                                            <div
                                                style={{ color: 'red', fontSize: 'small' }}
                                                className="error-message"
                                            >
                                                {error}
                                            </div>
                                        )}

                                    </div>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <div className="d-flex align-items-center">
                                        <input
                                            type="checkbox"
                                            id="service-checkbox"
                                            className="form-check-input custom-checkbox shadow-none mt-0"
                                            style={{ width: 14 }}
                                            checked={containsServices}
                                            onChange={(e) => setContainsServices(e.target.checked)}
                                        />
                                        <label
                                            className="font-14 font-medium ms-2 black-v2 service-checkbox"
                                            htmlFor="service-checkbox"
                                        >
                                            Contains Services ?
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-9 mt-2 mb-4">
                                    <label
                                        className="form-label font-14 font-medium black-v2"
                                        htmlFor="description"
                                    >
                                        Description<span className="required">*</span>
                                    </label>
                                    <textarea
                                        className="form-control custom-form custom-textarea font-14 font-regular"
                                        placeholder="Enter Description"
                                        id="description"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                    />
                                </div>
                            </div>

                            {/* InputConfiguration start */}
                            <div className="row position-relative">
                                <h2 className="font-18 font-bold black-v2 mt-2 mb-3">
                                    Inputs Configuration
                                </h2>




                                <div className="add-new-field-dropdown mb-4 pb-2 position-relative">
                                    <button
                                        type="button"
                                        className="add-new-field-button position-relative px-2 font-medium"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        onClick={handleAddField}
                                    >
                                        <img src="images/plus-icon-blue.svg" alt="plus-icon" className="me-2" />
                                        Add New Field{" "}
                                        <img src="images/dropdown-blue.svg" alt="dropdown-icon" className="ms-3" />
                                    </button>
                                    <ul
                                        className="dropdown-menu dropdown-menu-end border-0 dd-position px-1 px-1 add-new-field-width"
                                        aria-labelledby="dropdownMenuLink"
                                        data-popper-placement="bottom-end"
                                    //     onClick={(e) => {
                                    //         console.log("UN Orderd List Items Trigg")
                                    //         console.log(e, "eeeeeee")
                                    //         handleFieldTypeChange(e.target.value)
                                    //     }
                                    // }

                                    >
                                        <li className="py-2 px-2">
                                            <a
                                                href="#"
                                                className="font-14 font-medium text-decoration-none color-black"
                                                value={"text"}
                                                onClick={() => {
                                                    console.log("Liitems Triggered ")
                                                    handleFieldTypeChange("text")
                                                }}
                                            >
                                                Text{" "}
                                            </a>
                                        </li>
                                        <li className="py-2 px-2">
                                            <a
                                                href="#"
                                                className="font-14 font-medium text-decoration-none color-black"
                                                value={"dropdown"}
                                                onClick={() => handleFieldTypeChange("dropdown")}

                                            // }
                                            >
                                                Dropdown
                                            </a>
                                        </li>

                                        <li className="py-2 px-2">
                                            <a
                                                href="#"
                                                className="font-14 font-medium text-decoration-none color-black"
                                                value={"check box"}
                                                onClick={() => handleFieldTypeChange("check box")}
                                            >
                                                Checkbox
                                            </a>
                                        </li>
                                        <li className="py-2 px-2">
                                            <a
                                                href="#"
                                                className="font-14 font-medium text-decoration-none color-black"
                                                value={"file upload"}
                                                onClick={() => handleFieldTypeChange("file upload")}
                                            >
                                                File Upload
                                            </a>
                                        </li>
                                    </ul>
                                </div>


                                <SortableList items={inputConfiguration} onSortEnd={handleSortEnd} />
                                {fieldType !== '' && (
                                    <div>
                                        <div className="col-md-8 mb-4 pb-3 mt-4">
                                            <label
                                                className="form-label font-14 font-medium"
                                                htmlFor="label-1"
                                            >
                                                Label 1
                                            </label>
                                            <input
                                                type="text"
                                                id="label-1"
                                                className="form-control custom-form"
                                                placeholder="Enter Label"
                                                value={labelName}
                                                onChange={handleLabelNameChange}
                                            />
                                        </div>
                                        <div className="col-md-8 d-flex mb-4 pb-3">
                                            <div className="d-flex gap-5">
                                                <div className="form-check form-switch">
                                                    <input
                                                        className="form-check-input custom-switcher"
                                                        type="checkbox"
                                                        role="switch"
                                                        id="flexSwitchCheckChecked-2"
                                                        defaultChecked=""
                                                        onChange={(e) => { setIsRequired(e.target.value) }}
                                                    />
                                                    <label
                                                        className="form-check-label font-14 font-medium  black-v2 mb-0"
                                                        htmlFor="flexSwitchCheckChecked-2"
                                                    >
                                                        Required Field
                                                    </label>
                                                </div>
                                                <div className="d-flex align-items-center gap-3">
                                                    <div className="form-check form-switch d-flex align-items-center">
                                                        <input
                                                            className="form-check-input custom-switcher"
                                                            type="checkbox"
                                                            role="switch"
                                                            id="flexSwitchCheckChecked-2"
                                                            checked={isDependsOn}
                                                            onChange={(e) => handleDependsOnToggle(e.target.checked)}
                                                        />
                                                        <label
                                                            className="form-check-label font-14 font-medium black-v2 mb-0 me-2"
                                                            htmlFor="flex-2"
                                                        >
                                                            Depends on
                                                        </label>
                                                        {isDependsOn && (
                                                            <>
                                                                <button
                                                                    className="btn btn-link p-0 me-2"
                                                                    onClick={handleEditDependsOn}
                                                                >
                                                                    <img src="images/edit-icon.svg" alt="Edit" className="edit-icon" />
                                                                </button>
                                                                <button
                                                                    className="btn btn-link p-0"
                                                                    onClick={handleDeleteDependsOn}
                                                                >
                                                                    <img src="images/delete-icon.svg" alt="Delete" className="delete-icon" />
                                                                </button>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                )}







                            </div>
                            {/* InputConfiguration end */}

                            {showDropdownModal && (
                                <div className="modal fade show" id="staticBackdrop-15" style={{ display: 'block', backgroundColor: "rgba(0,0,0,0.6)" }} aria-modal="true" role="dialog">
                                    <div className="modal-dialog modal-dialog-centered style={{ maxWidth: '40%' }}">
                                        <div className="modal-content border-0">
                                            <div className="modal-header border-0 py-4 px-32">
                                                <h1 className="modal-title font-20 font-bold black-v2" id="staticBackdropLabel">Assign Dropdown values</h1>
                                                <button type="button" className="btn shadow-none p-0" onClick={() => setShowDropdownModal(false)}>
                                                    <img src="images/close-icon.svg" alt="close-icon" className="icon-hover shadow-none modal-close-filter" />
                                                </button>
                                            </div>
                                            <div className="modal-body p-32">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <label className="form-label font-14 font-medium black-v2">Value</label>
                                                        {values.map((value, index) => (
                                                            <div key={index} className="mb-3 d-flex align-items-center">
                                                                <input
                                                                    type="text"
                                                                    id={`Value-${index}`}
                                                                    className="form-control custom-form custom-dropdown font-14 font-medium"
                                                                    placeholder="Enter Value"
                                                                    value={inputValues[index]}
                                                                    onChange={(e) => handleInputChange(index, e.target.value)}
                                                                    style={{ width: '40%' }}
                                                                />
                                                                <button type="button" className="btn p-0 ml-2" onClick={() => {
                                                                    console.log("Deleting field at index:", index); // Check what index is being passed
                                                                    handleDeleteValue(index);
                                                                }}>
                                                                    <img src="images/bin-icon-red.svg" alt="bin-icon-red" />
                                                                </button>
                                                                <span className="mx-2"></span>
                                                                <button type="button" className="btn p-0" onClick={handleAddValue}>
                                                                    <img src="images/add-plus-icon.svg" alt="plus-blue-icon" />
                                                                </button>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className="text-end my-0">
                                                    <button type="button" className="btn button primary-button px-3" onClick={handleSaveDropdown}>Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {showDependsOnModal && (
                                <div className="modal fade show" id="staticBackdrop-26" style={{ display: 'block', backgroundColor: "rgba(0,0,0,0.6)" }} aria-modal="true" role="dialog">
                                    <div className="modal-dialog modal-dialog-centered modal-lg">
                                        <div className="modal-content border-0">
                                            <div className="modal-header border-0 py-4 px-32">
                                                <h1 className="modal-title font-20 font-bold black-v2" id="staticBackdropLabel">Depends on</h1>
                                                <button type="button" className="btn shadow-none p-0" onClick={handleCloseDependsOnModal}>
                                                    <img src="images/close-icon.svg" alt="close-icon" className="icon-hover shadow-none modal-close-filter" />
                                                </button>
                                            </div>
                                            <div className="modal-body p-32">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="row mb-4">
                                                            <div className="col-md-6 mb-3 px-2">
                                                                <label className="form-label font-14 font-medium black-v2" htmlFor="Service">Service</label>
                                                                <select
                                                                    id="Service"
                                                                    className="form-select form-control custom-form font-14 font-medium read-only"
                                                                    value={dependsOnService}
                                                                    onChange={(e) => setDependsOnService(e.target.value)}
                                                                >
                                                                    <option value="">Select Service</option>
                                                                    {serviceNames.map((service) => (
                                                                        <option key={service.serviceId} value={service.serviceId}>
                                                                            {service.serviceName}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="col-md-6 mb-3 px-2">
                                                                <label className="form-label font-14 font-medium black-v2" htmlFor="Feature">Feature</label>
                                                                <select
                                                                    id="Feature"
                                                                    className="form-select form-control custom-form font-14 font-medium read-only"
                                                                    value={dependsOnFeature}
                                                                    onChange={(e) => {
                                                                        setDependsOnFeature(e.target.value)
                                                                        setDependsOnField(e.target.value)
                                                                    }}
                                                                >
                                                                    <option value="">Select Feature</option>
                                                                    {serviceFields.map((field, index) => (
                                                                        <option key={index} value={field}>{field}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="d-flex justify-content-end align-items-center mb-2 mt-32 gap-3">
                                                    <button type="button" className="button outline-button text-nowrap py-12" onClick={handleCloseDependsOnModal}>Cancel</button>
                                                    <button type="button" className="button primary-button text-nowrap px-3 py-12" onClick={handleSaveDependsOn}>Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}



                            <div className="row">
                                <h3 className="font-14 font-medium mt-3 black-v2 mb-3">
                                    Language<span className="required">*</span>
                                </h3>
                                {/* Language Starts here */}

                                <div className="kb-template mb-5">
                                    {/* Language tab title starts here */}
                                    {selectedProvider === '0a5816fa-5f85-4fd9-b716-62e4395c7b4a' ? (
                                        <div className="custom-tab-scroll mb-4">
                                            <ul
                                                className="nav nav-pills d-flex gap-1 flex-nowrap mb-1"
                                                id="pills-tab"
                                                role="tablist"
                                            >
                                                <li className="nav-item" role="presentation">
                                                    <button
                                                        className="nav-link theme-tab active font-14 secondary-text-color font-medium px-3 py-1"
                                                        data-bs-toggle="pill"
                                                        data-bs-target="#terraform"
                                                        type="button"
                                                        role="tab"
                                                        aria-selected="true"
                                                        onClick={() => setLanguage('terraform')}
                                                    >
                                                        Terraform
                                                    </button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button
                                                        className="nav-link theme-tab font-14 secondary-text-color font-medium px-3 py-1"
                                                        data-bs-toggle="pill"
                                                        data-bs-target="#cloud-formation"
                                                        type="button"
                                                        role="tab"
                                                        aria-selected="false"
                                                        onClick={() => setLanguage('cloudformation')}
                                                    >
                                                        Cloud Formation
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    ) : (
                                        <div className="custom-tab-scroll mb-4">
                                            <ul
                                                className="nav nav-pills d-flex gap-1 flex-nowrap mb-1"
                                                id="pills-tab"
                                                role="tablist"
                                            >
                                                <li className="nav-item" role="presentation">
                                                    <button
                                                        className="nav-link theme-tab active font-14 secondary-text-color font-medium px-3 py-1"
                                                        data-bs-toggle="pill"
                                                        data-bs-target="#arm"
                                                        type="button"
                                                        role="tab"
                                                        aria-selected="true"
                                                        onClick={() => setLanguage('arm')}
                                                    >
                                                        ARM
                                                    </button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button
                                                        className="nav-link theme-tab font-14 secondary-text-color font-medium px-3 py-1"
                                                        data-bs-toggle="pill"
                                                        data-bs-target="#terraform"
                                                        type="button"
                                                        role="tab"
                                                        aria-selected="false"
                                                        onClick={() => setLanguage('terraform')}
                                                    >
                                                        Terraform
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    )}
                                    {/* Language title Ends here */}
                                    {/* Language content starts here */}

                                    <div className="tab-content" id="pills-tabContent">
                                        {selectedProvider === 'aws' ? (
                                            <div
                                                className="tab-pane fade show active"
                                                id="terraform"
                                                role="tabpanel"
                                                aria-labelledby="terraform"

                                            >
                                                <Editor
                                                    language={"text"}
                                                    theme="vs-dark"
                                                    height="25vh"
                                                    value={""}
                                                    onChange={(newCode) => handleCodeChange(newCode)}
                                                    options={{
                                                        fontSize: 14,
                                                        minimap: {
                                                            enabled: false,
                                                        },
                                                    }}
                                                />
                                            </div>
                                        ) : (
                                            <div
                                                className="tab-pane fade show active"
                                                id="arm"
                                                role="tabpanel"
                                                aria-labelledby="arm"
                                            >
                                                <Editor
                                                    language={"text"}
                                                    theme="vs-dark"
                                                    height="25vh"
                                                    value={cloudFormationCode}
                                                    onChange={(newCode) => handleCodeChange(newCode)}
                                                    options={{
                                                        fontSize: 14,
                                                        minimap: {
                                                            enabled: false,
                                                        },
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>


                                    {/* Language title content Ends here  - Edit option */}

                                    <div className="d-flex align-items-center justify-content-between">
                                        <button
                                            type="button"
                                            className="button cancel-button text-nowrap" onClick={handleCancelButtonClick}
                                        >
                                            Cancel
                                        </button>
                                        {!serviceData || (serviceData && Object.keys(serviceData).length === 0) ? (
                                            <button
                                                type="button"
                                                className="button primary-button text-nowrap"
                                                onClick={handleSaveButtonClick}
                                                disabled={isButtonDisabled}
                                            >
                                                Save Details
                                            </button>
                                        ) : (
                                            <button
                                                type="button"
                                                className="button primary-button text-nowrap"
                                                onClick={handleUpdateService}
                                                disabled={isButtonDisabled}
                                            >
                                                Update Service
                                            </button>
                                        )}

                                        {/* <button
                                            type="button"
                                            className="button primary-button text-nowrap"
                                            onClick={handleSaveButtonClick}
                                        >
                                            Save Details
                                        </button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Page Content End here */}
            </div>
        </>



    )
}
export default AddNewService1;


