import { createContext, useEffect, useState } from "react";




const DashboardContext = createContext();


const DashboardServiceProvider = ({ children }) => { 


    

  const [widgets, setWidgets] = useState({
    Workloads: "",
    AWS_Accounts: "",
    Azure_Subscriptions: "",
    Overall_Cost: "",
  });
  
  const [iac, setIac] = useState({
    Codified: "",
    Regeneration: "",
    Drifts: "",
  });
  
  const [assets, setAssets] = useState({
    Total_Service_Count: "",
    AWS: "",
    Azure: "",
  });
  
  const [pipelineExecutions, setPipelineExecutions] = useState([
    {
      Repository: "Repo1",
      Branch: "Main",
      Description: "",
      Status: "Success",
      Date_Time: "8/24/2024 12:12",
    },
    {
      Repository: "Repo2",
      Branch: "Dev",
      Description: "",
      Status: "Fail",
      Date_Time: "8/24/2024 12:12",
    },
  ]);
  
  const [commits, setCommits] = useState([
    {
      Repository: "Repo1",
      Branch: "Main",
      Commit_Tag: "user auth/ CR",
      Date_Time: "8/24/2024 12:12",
    },
    {
      Repository: "Repo2",
      Branch: "Dev",
      Commit_Tag: "Invoice/CR",
      Date_Time: "8/24/2024 12:12",
    },
  ]);
  
  
  const [workloads, setWorkloads] = useState([
    {
      Workloads: "Pricing",
      Budget: 0,
      Actual_Cost: 100,
    },
    {
      Workloads: "Emily",
      Budget: 100,
      Actual_Cost: 1200,
    },
  ]);
  
  const [providers, setProviders] = useState([
    {
      Providers: "Pricing",
      Budget: null,
      Actual_Cost: null,
    },
    {
      Providers: "Emily",
      Budget: null,
      Actual_Cost: null,
    },
  ]);
  
  const [tagsE, setTagsE] = useState([
    {
      Tags: "Pricing",
      Budget: null,
      Actual_Cost: null,
    },
    {
      Tags: "Emily",
      Budget: null,
      Actual_Cost: null,
    },
  ]);
  
  const [regions, setRegions] = useState([
    {
      Regions: "Pricing",
      Budget: null,
      Actual_Cost: null,
    },
    {
      Regions: "Emily",
      Budget: null,
      Actual_Cost: null,
    },
  ]);
  
  const [servicesE, setServicesE] = useState([
    {
      Services: "Pricing",
      Budget: null,
      Actual_Cost: null,
    },
    {
      Services: "Emily",
      Budget: null,
      Actual_Cost: null,
    },
  ]);

  const [periodE,setperiodE] = useState([
                {
                  "Period": "Pricing",
                  "Budget": null,
                  "Actual_Cost": null
                },
                {
                  "Period": "Emily",
                  "Budget": null,
                  "Actual_Cost": null
        }])

    const [exportJson,setExportJson] = useState({})
    const [isExport,setIsExport] = useState(false)
    const [isExportPDF,setIsExportPDF] = useState(false)


    const [exportWorkloadData,setExportWorkloadData] = useState([])
    

    useEffect(()=>{
      setExcelData()
    },[widgets,iac,assets,pipelineExecutions,commits,workloads,providers,tagsE,regions,servicesE,periodE])

    function setExcelData(){


      console.log(providers,"This is the provider account!!!")

      console.log(servicesE,"This is the service EE")

      let excelData = {
        Widgets: widgets,
        IAC: iac,
        Assets: assets,
        Pipeline_Execution: pipelineExecutions,
        Commits: commits,
        Workloads: workloads,
        Providers: providers,
        Tags: tagsE,
        Period: periodE,
        Regions: regions,
        Services: servicesE,
      }

      setExportJson(excelData)
      
    }

    return (
        <DashboardContext.Provider value={{ exportJson,setExportJson,iac,setIac, assets, setAssets , workloads, setWorkloads, providers, setProviders,regions, setRegions,periodE,setperiodE,servicesE, setServicesE,tagsE, setTagsE , pipelineExecutions, setPipelineExecutions,commits, setCommits,exportWorkloadData,setExportWorkloadData,isExport,setIsExport,isExportPDF,setIsExportPDF}}>
          {children}
        </DashboardContext.Provider>
      );
    };
export { DashboardServiceProvider, DashboardContext}