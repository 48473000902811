// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .react-flow__resize-control.line.top,
.react-flow__resize-control.line.right,
.react-flow__resize-control.line.left,
.react-flow__resize-control.line.bottom {
  border-style: 'none' !important; 

  border: white !important;
} */

.react-flow__resize-control.line.top,
.react-flow__resize-control.line.right,
.react-flow__resize-control.line.left,
.react-flow__resize-control.line.bottom {
  border: none !important;
  border-style: none !important;
  border-width: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/Components/Organization/Workbench/WorkBench/ReactflowCustom.css"],"names":[],"mappings":"AAAA;;;;;;;GAOG;;AAEH;;;;EAIE,uBAAuB;EACvB,6BAA6B;EAC7B,0BAA0B;AAC5B","sourcesContent":["/* .react-flow__resize-control.line.top,\n.react-flow__resize-control.line.right,\n.react-flow__resize-control.line.left,\n.react-flow__resize-control.line.bottom {\n  border-style: 'none' !important; \n\n  border: white !important;\n} */\n\n.react-flow__resize-control.line.top,\n.react-flow__resize-control.line.right,\n.react-flow__resize-control.line.left,\n.react-flow__resize-control.line.bottom {\n  border: none !important;\n  border-style: none !important;\n  border-width: 0 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
