import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

/**
 * ToastPopup Component
 * 
 * This component displays a toast notification that automatically disappears after a specified duration.
 * It can show both success and error messages with different styling.
 *
 * @param {Object} props
 * @param {Object} props.toastMsg - Object containing toast message details
 * @param {boolean} props.toastMsg.show - Whether to show the toast
 * @param {string} props.toastMsg.message - The message to display
 * @param {boolean} props.toastMsg.success - Whether it's a success message (true) or error message (false)
 * @param {Function} props.setToastMsg - Function to update the toast message state
 */


export default function ToastPopup({toastMsg, setToastMsg }) {

    // Styles for the toast container
    useEffect(() => {
        // If popup message is shown, set a timeout to hide it after 3 seconds
        if (toastMsg.show) {
          const timer = setTimeout(() => {
            setToastMsg({ ...toastMsg, show: false, message: "", success: false });
          }, 3000);
     
          // Clear timeout if the component is unmounted or if toastMsg changes
          return () => clearTimeout(timer);
        }
      }, [toastMsg, setToastMsg]);
     
      console.log(toastMsg, "toastMsg");
     
      return (
        /* Add zIndex and other styling to position the popup overlay */
        <div className="container-fluid" style={{ position: 'fixed', top: '10%', left: '50%', transform: 'translateX(-50%)', zIndex: 1000 }}>
        {toastMsg.show && (
         
          <div className={`${toastMsg.success ? 'custom-toast-container' : 'custom-toast-container-1'} d-flex align-items-center justify-content-between`}
            style={{ backgroundColor: toastMsg.success ? 'green' : 'red', padding: '1rem', borderRadius: '5px',width:'280px',marginLeft:'39%' }}>
            <div className="d-flex align-items-center gap-3 mb-0">
              <img
                src={toastMsg.success ? 'images/tick-green-small.svg' : 'images/error-icon-red.svg'}
                alt="status-icon"
                className="width-25"
              />
              <p className="font-14 font-regular text-white mb-0">
                {toastMsg.message}
              </p>
            </div>
            <button
              type="button"
              className="btn p-0 shadow-none"
              onClick={() => {
                setToastMsg({ ...toastMsg, message: '', show: false, success: false });
              }}
            >
              <img src="images/cross-white.svg" alt="cross-icon" className="w-9" />
            </button>
          </div>
        )}
      </div>
      );
    }
    