import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useOrgContext } from '../../../../ContextProvider/OrgContext';
import { fetchEnvironmentsAPI, fetchServicesApi } from '../../../../Service/api';


// SideBarComponentWB is a functional component that renders a sidebar with services, environments, and repositories.
const RepoSideBarComponent = ({setSelectedEnv , setUserSelectedRepo}) => {

	// Props Destruct

	const { orgId,userId,envId,workloadId,providerId, providerName,workloadRole} = useOrgContext();
		console.log(envId , "envId")
		console.log(workloadId , "workloadId")
		console.log(providerId , "providerId")

	const location = useLocation();
	const [draggable, setDraggable] = useState(true);
	const [environmentID, setEnvironmentID] = useState(envId);

	const [services, setServices] = useState([]);
	const [environments, setEnvironments] = useState([]);
	const [repo,setRepo] = useState([])
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const [expandedCategories, setExpandedCategories] = useState([]);
	const [repositories, setRepositories] = useState([]);
	const [viewMode , setViewerMode] = useState(workloadRole == "Viewer" ? true : false)

	const accordionRef = useRef(null);
	// Page Onload Useeffect inorder to retreive the Service for the Workbench
	useEffect(() => {
		// Simulate API calls
		fetchEnvironments()

		}, []);

	useEffect(() => {
		// if (providerId) {
			fetchServices();
		// }
	}, []);
	/**
	* This Function retrieves the Services in from the configured Self Service
	* With Respect to the Provider the fetch Services is intiated 
	*/
	const fetchServices = async (searchWord = "") => {
		// setIsLoading(true);
		setError(null);
		try {
			// const requestBody = {
			// cloud_provider_id: providerId,
			// userId: userId,
			// orgId: orgId,
			// search_word: searchWord
			// }
				// const requestBody = {
				// // "userId": userId,
				// "userId": "d69a7ccf-822a-4749-b650-3f97e7b84969",
				// // "orgId": orgId
				// "orgId": "7822b753-78c1-473f-a707-42febeb3fd46",
				// // "cloud_provider_id": providerId,
				// "cloud_provider_id": "0a5816fa-5f85-4fd9-b716-62e4395c7b4a",
				// }
				const requestBody = {
					"userId": userId,
					"orgId": orgId,
					"cloud_provider_id": providerId,
				}

			let response = await fetchServicesApi(requestBody)
				console.log(response , "Fetch ServiceAPI ")
			if (response.success) {
				console.log("Fetched services:", response.data);
				// setServices(response.data);
				// setServices(staticResponse.data.body.data);
				setServices(response.data);
			} else {
				throw new Error(response.data.body.message || 'Failed to fetch services');
			}
		} catch (error) {
			console.error("Error fetching services:", error);
			setError('Failed to load services. Please try again.');
		} finally {
			setIsLoading(false);
		}
	};
		/**
	* This Function retrieves the Environemtents in t 
	* With Respect to the Provider the fetch Services is intiated 
	*/
	const fetchEnvironments = async () => {
		try {
			const requestBody = {
				workloadId: workloadId, // Replace with actual workload ID
				userId: userId,
				organizationId: orgId,
					envId :envId
			};

				let response = await fetchEnvironmentsAPI(requestBody)
				console.log(response , "response from fetchEnvironments ")
			if (response.success) {
				// Set the Environements and Repo Details Over here 
				setEnvironments(response.data.environment);
					setRepositories(response.data.repository)



			} else {
				console.log("Error in the Retrieavl of Environments")
			}
		} catch (error) {
			console.log("Error fetching environments:", error);

		}
	};
	const handleEnvironmentChange = async(e)=>{
		setEnvironmentID(e.target.value)
		let obj = {
			environment_id :e.target.value,
			environment_name :e.target.name
		}
		setSelectedEnv(obj)
		// It Should update the Value in the Workbench or Context provider
	}
	// React Flow On Drag Functionality 
	/** 
		* When the users Drag and Drop the service from the side Bar component 
		* This Functionality is triggered
	*/
	const onDragStart = (event, serviceCategory, service) => {
		if (draggable) {
			console.log('serviceCategory:', serviceCategory);
			console.log('service:', service);

			const serviceData = {
				id: (service.serviceName || '') + "_" + Math.random(),
				childName: service.serviceName,
				parentName: serviceCategory.serviceCategory,
				nodeType: service.containsService ? 'location' : 'service',
				imageName: service.serviceImage,
				serviceDR: service.description ,
				serviceName: service.serviceName,
				subServiceName: '',
				serviceFamily: serviceCategory.serviceCategory ,
				famImage: serviceCategory.serviceCategoryImage ,
				serImage: service.serviceImage ,
				allSubService: JSON.stringify(service.fields || []),
				costParent: service.serviceName ,
				description: service.description ,
				fields: service.fields || [],
				containsService: !!service.containsService,
			};

			console.log('serviceData:', serviceData);

			// Set all the data as a single JSON string
			event.dataTransfer.setData('application/json', JSON.stringify(serviceData));

			// Optionally, you can still set individual data if needed
			Object.entries(serviceData).forEach(([key, value]) => {
				let stringValue = '';
				if (value !== null && value !== undefined) {
					stringValue = typeof value === 'object' ? JSON.stringify(value) : value.toString();
				}
				event.dataTransfer.setData(`application/${key}`, stringValue);
			});

			event.dataTransfer.effectAllowed = 'move';
		}
		};
	const onDragStartAccount = (event) => {
		if (draggable) {
			console.log("account dragged"); // Log that the account is being dragged

			const serviceData = {
				id: 'account-node-' + Math.random().toString(36).substr(2, 9), // Generate unique ID
				childName: 'Account',
				parentName: 'Account',
				nodeType: 'location',
				imageName: './images/Workbench/ServiceImages/AWS/Security/AWS-Organizations_Account_light-bg.png',
				serviceDR: 'Account',
				serviceName: 'Account',
				subServiceName: 'Account',
				serviceFamily: 'Account',
				famImage: './images/Workbench/ServiceImages/AWS/Security/AWS-Organizations_Account_light-bg.png',
				serImage: './images/Workbench/ServiceImages/AWS/Security/AWS-Organizations_Account_light-bg.png',
				allSubService: JSON.stringify([]), // Change this to an empty array
				costParent: 'Account',
				description: 'Account',
				fields: [], // Ensure this is not an array, just a string
				containsService: true, // This should be a boolean
				position: { x: 0, y: 0 }, // Initial position (will be updated on drop)
			};

			console.log("account service data", serviceData); // Log the serviceData object
			event.dataTransfer.setData('application/json', JSON.stringify(serviceData));
			event.dataTransfer.effectAllowed = 'move'; // Allow move effect
		}

		// The following line is incorrect; it should reference the variable serviceData directly
		// console.log("account service data".serviceData);
		};
	const toggleCategory = (category) => {
		setExpandedCategories(prev => 
			prev.includes(category) 
				? prev.filter(c => c !== category) 
				: [...prev, category]
		);
		};


	return (
	<>
		<div className="col-md-3 mt-4 ps-3 pe-1 content-left-container"
			style={{position:"relative",zIndex:10}}
		>
			<div className="recommended-container pe-3">
				{/* AWS Logo */}
				<img src={providerName == "AWS" ? "images/aws-logo-big.svg":"images/azure-logo.svg"} alt="aws" className="light-theme-img" />
				{/* <img src="images/aws-dark-logo-large.svg" alt="aws" className="dark-theme-img" /> */}

				{/* Repository Dropdown */}
				<div className="my-4 d-flex justify-content-between align-items-center ">
					<label className="form-label font-14 font-medium black-v2 mb-0 lh-0 me-4" htmlFor="Repository-223">
						Repository
					</label>
					<select className="form-select custom-form arch-border shadow-none custom-border-box py-2 
					font-14 font-medium black-v2 custom-select repo-select"
				aria-label="Default select example" 
				id="Repository-223"
				onChange={(e)=>{

					let obj = {
						"repository_id" : e.target.value,
						"repository_name" : e.target.name
					}
					console.log(obj , "here")
					setUserSelectedRepo(obj)
					}} > 

			{repositories.map((val) => (
			val.repository_id && val.repository_name ? (
				<option key={val.repository_id} value={val.repository_id} name={val.repository_name}>{val.repository_name}</option>
			) : null
			))}
				</select>
				</div>

				{/* Environment dropdown */}
			<div className="my-4 d-flex align-items-center">
				<label className="form-label font-16 font-medium black-v2 mb-0 lh-0 me-4" htmlFor="Runtime-223">
					Environment
				</label>
				<select 
					className="form-select custom-dropdown arch-border shadow-none custom-border-box py-2 font-14 font-medium black-v2 
					custom-select"
					id="Runtime-223"
					value={environmentID}
					onChange={(e) => {
						handleEnvironmentChange(e)
						}
						}
				>
					<option value="">Select Environment</option>
					{environments.map((env) => (
						<option key={env.environment_id} value={env.environment_id} name= {env.environment_name}>{env.environment_name}</option>
					))}
				</select>
			</div>

				{/* Services section */}
			<div className="mt-5" ref={accordionRef}>
				<h6 className="font-18 font-bold black-v2">Services</h6>


				{!isLoading && !error && (
					<ul className="nav nav-pills flex-column mb-auto flex-nowrap nav-overflow custom-nav-scrl pe-2" 
					style = {{ cursor: "pointer" }}>
						{/* Account category */} 
						<React.Fragment>
							<li className={`side-nav-item-rec side-nav-drop d-flex align-items-center justify-content-between my-3 
							${expandedCategories.includes('Account') ? 'show' : 'collapsed'}`}
								onClick={() => toggleCategory('Account')}>
								<span className="d-flex align-items-center justify-content-between w-100"

									aria-expanded="false">
									<span className="d-flex align-items-center gap-2">
										<img 
											src="./images/Workbench/ServiceFamilyImages/sidenav-application.svg"
											alt="Account"
											className="me-2 dark-icons"
											style={{ width: '18px', height: '16px' }}
										/>
										<span className="font-14 font-medium black-v2">Account</span>
									</span>
									<span className="acc-btn">
										<img src="images/rec-acc-icon.svg" alt="list-arrow" className="list-arrow side-icon" />
									</span>
								</span>
							</li>
							<li className={`sub collapse ${expandedCategories.includes('Account') ? 'show' : ''}`}>
								<div className="sub-list list-unstyled font-12 font-medium mb-32">
									<div className="row mt-3">
										<div className="col-md-4 px-2">
											<div
												className="font-8 color-black font-regular mb-0 custom-border-box d-flex justify-content-center align-items-center flex-column py-2 flex-column gap-2 text-decoration-none"
												draggable={false}
												style={{cursor:"not-allowed", opacity:"0.2"}}
												// onDragStart={(e) => onDragStartAccount(e)}
											>
												<img 
													src="./images/Workbench/ServiceImages/AWS/Security/AWS-Organizations_Account_light-bg.png"
													alt="Account"
													style={{ width: '35px', height: '35px' }}
												/>
												Account
											</div>
										</div>
									</div>
								</div>
							</li>
						</React.Fragment>
						{/* {isLoading && <p>Loading services...</p>} */}
						{/* {error && <p className="error-message">{error}</p>} */}
			{services.map((serviceCategory, index) => (
				<React.Fragment key={index}>
					<li className={`side-nav-item-rec side-nav-drop d-flex align-items-center justify-content-between my-3
						${expandedCategories.includes(serviceCategory.serviceCategory) ? 'show' : 'collapsed'}`}
						onClick={() => toggleCategory(serviceCategory.serviceCategory)}

					>
						<span className="d-flex align-items-center justify-content-between w-100"

							aria-expanded="false">
							<span className="d-flex align-items-center gap-2">
								<img 
									src={serviceCategory.serviceCategoryImage}
									alt={serviceCategory.serviceCategory}
									className="me-2 dark-icons"
									style={{ width: '18px', height: '16px' }}
									onError={(e) => {
										e.target.onerror = null;
										e.target.src = 'images/default-category-icon.svg';
									}}
								/>
								<span className="font-14 font-medium black-v2">{serviceCategory.serviceCategory}</span>
							</span>
							<span className="acc-btn">
								<img src="images/rec-acc-icon.svg" alt="list-arrow" className="list-arrow side-icon" />
							</span>
						</span>
						</li>
						<li className={`sub collapse ${expandedCategories.includes(serviceCategory.serviceCategory) ? 'show' : ''}`}>
							<div className="sub-list list-unstyled font-12 font-medium mb-32">
								<div className="row mt-3">
									{serviceCategory.services.map((service, serviceIndex) => (
										<div key={serviceIndex} className="col-md-4 px-2">
											<div
												className="font-8 color-black font-regular mb-0 custom-border-box d-flex justify-content-center align-items-center flex-column py-2 flex-column gap-2 text-decoration-none"
												draggable={false}
												style={{cursor:"not-allowed", opacity:"0.2"}}
												// onDragStart={(e) => onDragStart(e, serviceCategory, service)}
											>
												<img 
													src={service.serviceImage}
													alt={service.serviceName}
													style={{ width: '35px', height: '35px' }}
													onError={(e) => {
														e.target.onerror = null;
														e.target.src = 'images/default-service-icon.svg';
													}}
												/>
												{service.serviceName}
											</div>
										</div>
									))}
								</div>
							</div>
						</li>
						</React.Fragment>
					))}
					</ul>
					)}
				</div>
					</div>
					</div>

	</>


	);
};

export default RepoSideBarComponent;

