//PS_01
import { useState, useEffect, useContext, useRef } from "react";
//PS_02
import { useNavigate, useLocation } from "react-router-dom";
//PS_03
import { logOutUser, updateDarkThemeApi } from "../../Service/api";
import Notifications from "../Organization/Notification";
import { OrgContext } from "../../ContextProvider/OrgContext";
// import { signOut } from 'your-auth-library'; // Replace with your actual auth library for Microsoft AD

//PS_04
export default function Header({ isDarkMode, setIsDarkMode }) {
  //PS_05
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [tab, setTab] = useState('');
  const [logoutPopup, setLogoutPopup] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [notificationData, setNotificationData] = useState([])
  const [isOpen, setIsOpen] = useState("")
  const audioRef = useRef(null);

  const userName = localStorage?.getItem('userName');
  const emailId = localStorage?.getItem('emailId');
  const [gotNotification, setGotNotification] = useState("")
  const [logoImage, setLogoImage] = useState('');
  const userImage = localStorage.getItem('userImage');
  const {
    userRoles,
    orgId,
    providerId,
    architectures,
    canCreateWorkload,
    canDeleteWorkload,
    canViewWorkload,
    setProviderName,
    setuserRoles, providerId2, setProviderId2,

    // Add organization and provider fields here
    providerName,
    orgData,
    setOrgData,
    setArchitectures,

    envId,             // Environment ID
    workloadId,        // Workload ID
    aiArchitecture,
    viewMode,
    envName,
    language,
    archType,
    business,
    rulebook,
    archDesc,
    services,

    lastBuildID,
    lastBuildDate,
    lastBuildMessage,

    repoId,
    repoName,

    PulseOrganization_id,
    PulseVertical_id,
    PulseVertical_name,
    PulseOrganization_name,
    PulseIdentification, colorMode,
    setColorMode,

    setEnvId           // Function to update environment ID
  } = useContext(OrgContext);

  //PS_33-PS_57 :FUnction for default image formation for profile image

  function createLogoImage() {
    let nameParts = userName?.split(' ');
    let firstName = nameParts[0] || '';
    let lastName = nameParts[nameParts.length - 1] || '';
    let logoLetters = (firstName.charAt(0) + lastName.charAt(0)).toUpperCase();

    const canvasSize = 40; // Size of the canvas (width and height)
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = canvasSize;
    canvas.height = canvasSize;

    // Draw circle
    context.fillStyle = '#f0f0f0'; // Circle color
    context.beginPath();
    context.arc(canvasSize / 2, canvasSize / 2, canvasSize / 2, 0, Math.PI * 2, true);
    context.closePath();
    context.fill();

    // Calculate font size based on canvas size
    const fontSize = Math.round(canvasSize / 2); // Adjust this ratio as needed
    context.font = `bold ${fontSize}px Arial`; // Set font size dynamically
    context.fillStyle = '#333'; // Text color
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    context.fillText(logoLetters, canvasSize / 2, canvasSize / 2);

    // Convert to base64
    const dataUrl = canvas.toDataURL('image/png');
    setLogoImage(dataUrl);
  }




  const playNotificationSound = () => {
    // Check if the audio element exists and only play if it's user-initiated
    if (audioRef.current) {
      audioRef.current.play().catch((error) => {
        console.log("Audio play failed: ", error);
      });
    }
  };


  console.log(notificationData, "This is the notification data from notification.")

  // Mock user data
  const mockUserData = {
    first_name: "Joanna",
    last_name: "Clark",
    email: "johannaclark@avasoft.com",
    profileUrl: "images/Profile-image.svg" // Placeholder for profile image
  };



  useEffect(() => {

    createLogoImage()
    console.log(localStorage.getItem('userId'), "Fusuus")

    if (true) {
      // URL for the WebSocket connection


      const wsUrl = `${process.env.REACT_APP_WEB_SOCKET_URL}${localStorage.getItem('userId')}`;

      // Creating a new WebSocket instance
      const socket = new WebSocket(wsUrl);

      // Handle WebSocket connection open
      socket.onopen = () => {
        console.info('WebSocket connection established!!!!!!!!!');

      };

      // Handle incoming messages from the WebSocket server
      socket.onmessage = (event) => {


        // setIsOpen("show")
        console.log("Added the smaple commit!!!");
        setGotNotification("active")
        playNotificationSound()


        let notifications = notificationData;

        try {
          debugger
          let responseData = JSON.parse(event.data)
          notifications.push(responseData[0])
        } catch (error) {

        }


        setTimeout(
          () => {
            setIsOpen("")
          }, 5000
        )

        setNotificationData(notifications); // Update state with the message
      };

      // // Handle WebSocket connection close
      // socket.onclose = (event) => {
      //   console.log('WebSocket connection closed', event);

      // };

      // Handle errors
      // socket.onerror = (error) => {
      //   console.error('WebSocket error:', error);
      // };

      // Cleanup function to close the WebSocket connection
      // return () => {
      //   if (socket.readyState === WebSocket.OPEN) {
      //     socket.close(); // Close the connection when component unmounts
      //   }
      // };
    }

  }, []);


  //PS_11
  useEffect(() => {
    setTab(pathname);
    // Simulate fetching user details
    setUserDetails(mockUserData);
  }, [pathname]);
  //PS_12
  const handleSignOut = async () => {
    try {
      // await signOut(); // Implement your sign-out logic for Microsoft AD
      // navigate('/');
    } catch (e) {
      console.log('Error signing out:', e);
    }
  };
  const [isAdmin, setisAdmin] = useState(localStorage.getItem('isAdmin') == "true" ? true : false);

  //PS_13 
  const toggleDarkMode = () => {
    const newDarkMode = !isDarkMode; // Calculate new dark mode state
    setIsDarkMode(newDarkMode); // Update state immediately
    handleUpdateDarkTheme(newDarkMode); // Pass new state to API function
  };
  //PS_14
  const handleUpdateDarkTheme = async (newDarkMode) => {
    const payload = {
      userId: localStorage.getItem('userId'),
      darkCheck: newDarkMode ? "true" : "false", // Use new state here
    };
    //PS_15
    try {
      const response = await updateDarkThemeApi(payload); // Call the API function
      if (response.success) {
        localStorage.setItem('darkTheme', newDarkMode ? 'true' : 'false'); // Update local storage
        setColorMode(newDarkMode ? 'dark' : 'light')
      } else {
        console.error('Error updating dark theme preference');
      }
    } catch (error) {
      console.error('An error occurred while updating dark theme preference:', error);
    }
  };

  const location = useLocation(); // Get current location
  const [activeHeader, setActiveHeader] = useState(''); // State to track the active header

  // Function to handle navigation and set active header
  const handleNavigation = (path, header) => {
    setActiveHeader(header); // Set the clicked header as active
    navigate(path); // Navigate to the specified path
  };



  const handleLogout = async () => { // Make sure to declare the function as async
    try {
      const emailId = localStorage.getItem('emailId'); // Replace 'emailId' with your actual key
      if (!emailId) {
        console.error("No email ID found in local storage");
        return; // Exit if no email ID is found
      }

      // Prepare payload
      const payload = { email: emailId };

      // Make API call
      const response = await logOutUser(payload); // Ensure logOutUser is defined as an async function

      console.log(response, "resp");

      if (response.status === 200) {
        localStorage.clear();
        sessionStorage.clear()

        navigate('/'); // Navigate to login page
      } else {
        console.error("Logout failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null); // Reference to the dropdown menu
  const buttonRef = useRef(null); // Reference to the toggle button
  //PS_18
  const toggleDropdown = () => {
    console.log(dropdownOpen, "close");

    setDropdownOpen(prev => !prev); // Toggle dropdown state
    console.log(dropdownOpen, "open");
  };

  //PS_19  Close dropdown if clicked outside
  const handleClickOutside = (event) => {
    // Check if click is outside of both dropdown and button
    if (
      dropdownRef.current && !dropdownRef.current.contains(event.target) &&
      buttonRef.current && !buttonRef.current.contains(event.target)
    ) {
      setDropdownOpen(false); // Close dropdown if clicked outside
    }
  };

  //PS_20  Use effect to handle clicks outside of the dropdown
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>

      <nav className={`navbar navbar-expand-lg background-white custom-nav ${isDarkMode ? 'dark' : ''}`}>
        <div className="container-fluid custom-container">
          <a className="navbar-brand" href="#">
            <span className="d-flex" onClick={() => handleNavigation('/manage-organizations', 'Organizations')} style={{ cursor: 'pointer' }}>
              <img src="images/logo.svg" alt="logo" className="header-logo light-theme-img" />
              <img src="images/logo-dark.svg" alt="logo" className="header-logo dark-theme-img" />
            </span>
          </a>

          <div className="collapse navbar-collapse ms-5" id="navbarSupportedContent">
            <ul className="navbar-nav">
              <li className={`nav-item custom-nav-item ${activeHeader === 'Organizations' || location.pathname === '/manage-organizations' ? 'active' : ''}`}>
                <a className="nav-link g-border" onClick={() => handleNavigation('/manage-organizations', 'Organizations')} style={{ cursor: 'pointer' }}>Organizations</a>
              </li>
              <li className={`nav-item custom-nav-item ${activeHeader === 'Templates' ? 'active' : ''}`}>
                <a className="nav-link g-border" onClick={() => handleNavigation('/ManageTemplates', 'Templates')} style={{ cursor: 'pointer' }}>Templates</a>
              </li>
              <li className={`nav-item dropdown custom-nav-item ${activeHeader === 'Knowledge Base' ? 'active' : ''}`}>
                <a className="nav-link dropdown-toggle g-border" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Knowledge Base<img src="images/header-dropdown-arrow.svg" alt="dropdown-arrow" className="ms-2 icon-filter" />
                </a>
                <ul className="dropdown-menu">
                  <li><a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => handleNavigation('/ArticleGrid', 'Knowledge Base')} >Documentation</a></li>
                  <li><a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => handleNavigation('/Insights', 'Knowledge Base')}>Insights</a></li>
                  <li><a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => handleNavigation('/UserManual1', 'Knowledge Base')}>User Manual</a></li>
                </ul>
              </li>
              {isAdmin && <li className={`nav-item custom-nav-item ${activeHeader === 'Databricks' ? 'active' : ''}`}>
                <a className="nav-link g-border" onClick={() => handleNavigation('/DatabricksDashboard', 'Databricks')} style={{ cursor: 'pointer' }}>Databricks</a>
              </li>}
              {isAdmin && <li className={`nav-item dropdown custom-nav-item ${activeHeader === 'Admin' ? 'active' : ''}`}>
                <a className="nav-link dropdown-toggle g-border" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Admin<img src="images/header-dropdown-arrow.svg" alt="dropdown-arrow" className="ms-2 icon-filter" />
                </a>
                <ul className="dropdown-menu">
                  {/* Add Admin options here */}
                  <li><a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => handleNavigation('/ManageUsers', 'Admin')}>Manage Users</a></li>
                  <li><a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => handleNavigation('/TemplateKBGrid', 'Admin')}>Template KB</a></li>

                  <li><a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => handleNavigation('/ServiceConfigurationMain', 'Admin')}>Self Service</a></li>
                  <li><a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => handleNavigation('/rulebook', 'Admin')}>Rule Book</a></li>
                </ul>
              </li>}
            </ul>
          </div>

          {/* Notification and Profile Section */}
          <div className="btn-group">
            {/* Notification Dropdown */}
            <div className="dropdown me-3 d-flex align-items-center position-relative">
              <span
                className={`position-relative cursor-pointer notification-dropdown ${gotNotification}`}
                data-bs-auto-close="outside"
                data-bs-toggle="dropdown"
                aria-expanded="true"
                role="button"
                onClick={() => {
                  setIsOpen(isOpen == "show" ? "" : "show")
                  setGotNotification("")
                }}
              >
                <img
                  className="notif-icon light-theme-img"
                  src="images/notification-icon-wo-rd.svg"
                  alt="notification-icon"
                />
                <img
                  className="notif-icon dark-theme-img"
                  src="images/notification-icon-wo-rd-dark.svg"
                  alt="notification-icon"
                />
              </span>
              <div className={`dropdown-menu dropdown-menu-end p-4 notifications-dropdown ${isOpen}`} style={{
                position: "absolute",
                right: "0px"
              }} >
                <div className="mb-3">
                  <h5 className="font-18 font-bold color-black">Notifications</h5>
                </div>
                <ul className="list-unstyled notification-container">
                  {
                    notificationData.map((value, index) => {

                      return (
                        <li>
                          <div className="d-flex align-items-center justify-content-between title-container mt-3 align-items-center">
                            <div className="d-flex align-items-center">
                              <img
                                className="partner-logo light-theme-img"
                                src="images/aws-logo.svg"
                                alt="partner-logo"
                              />
                              <img
                                className="partner-logo dark-theme-img"
                                src="images/dark-theme-aws-logo.svg"
                                alt="partner-logo"
                              />
                              <a
                                href="#"
                                className="font-14 font-medium black-v2 text-decoration-none"
                              >
                                {
                                  value?.highlight
                                }
                              </a>
                            </div>
                            <a href="#">
                              <span className="notify-viewmore" onClick={() => {
                                navigate('Notification')
                                setTimeout(() => {
                                  const dropdownToggle = document.querySelector('.notification-dropdown');
                                  if (dropdownToggle) {
                                    dropdownToggle.click();
                                  }
                                }, 1);
                              }

                              }>
                                <img
                                  className="notifications-arrow"
                                  src="images/notifify-right-arrow.svg"
                                  alt="notifications-arrow"
                                />
                              </span>
                            </a>
                          </div>
                          <p className="font-10 font-medium mt-2 secondary-text-color line-height-18">
                            {value?.message}
                          </p>
                          <p className="font-10 font-medium mt-3 secondary-text-color-v5">
                            {
                              value?.dateTime
                            }
                            {/* 06/27/2024 23:57 */}
                          </p>
                        </li>
                      )

                    })
                  }


                </ul>
                <a
                  href="#"
                  className="font-14 font-bold color-black text-end primary-color mb-0 d-block text-decoration-none "
                  onClick={() => {
                    navigate('Notification')
                    setTimeout(() => {
                      const dropdownToggle = document.querySelector('.notification-dropdown');
                      if (dropdownToggle) {
                        dropdownToggle.click();
                      }
                    }, 1);
                  }}

                >
                  View All Notifications
                </a>
              </div>
            </div>

            {/* Profile Dropdown */}
            <div style={{ position: 'relative' }}> {/* Ensure parent div has relative positioning */}
              {userDetails && (
                <>
                  <button
                    type='button'
                    onClick={toggleDropdown} // Toggle dropdown on button click
                    className={`btn border-0 shadow-none background-white p-0 ${isDarkMode ? 'dark' : ''}`}
                    ref={buttonRef}
                  >
                    {/* User Profile Image */}
                    {userImage && userImage !== 'null' && userImage !== 'undefined' ? (
                      <img
                        src={`data:image/jpeg;base64,${localStorage.getItem('userImage')}`}
                        alt='header-profile-img'
                        className={`custom-profile-img ${isDarkMode ? 'dark' : ''}`}
                      />
                    ) : (
                      <img
                        src={logoImage}
                        alt='logout-profile-img'
                        className='logout-profile-img me-3'
                      />
                    )}
                  </button>

                  {/* Profile Dropdown Menu */}
                  {dropdownOpen && ( // Only render if dropdown is open
                    <div
                      ref={dropdownRef} // Attach ref to the dropdown menu
                      className={`dropdown-menu dropdown-menu-end text-center border-0 logout-container ${isDarkMode ? 'dark' : ''}`}
                      style={{
                        display: 'block',
                        position: 'absolute',
                        top: '100%', // Positiossn below the button
                        left: '-310px',   // Align with the left edge of the button
                        zIndex: 1000 // Ensure it appears above other content
                      }}
                    >
                      {/* User Info */}
                      <div className='logout-section'>
                        {/* Display First Name and Last Name */}
                        {userImage && userImage !== 'null' && userImage !== 'undefined' ? (
                          <img
                            src={`data:image/jpeg;base64,${localStorage.getItem('userImage')}`}
                            alt='logout-profile-img'
                            className='logout-profile-img me-3'
                          />
                        ) : (
                          <img
                            src={logoImage}
                            alt='logout-profile-img'
                            className='logout-profile-img me-3'
                          />
                        )}

                        <div className='me-1'>
                          {/* Display Full Name and Email */}
                          <p className='font-16 font-medium m-0 color-black text-start'>
                            {userName}
                          </p>
                          <span className='font-9 font-medium secondary-text-color mt-1 mb-0 text-decoration-none'>
                            {emailId}
                          </span>
                        </div>

                        {/* Logout Icon */}
                        <img
                          src='images/Logout-icon.svg'
                          alt='logout-icon'
                          onClick={handleLogout}
                          className='logout-icon ms-4 cursor-pointer'
                        />
                      </div>

                      {/* Dark Mode Toggle */}
                      <div className='mt-3 d-flex align-items-center justify-content-between'>
                        <span
                          aria-label='Toggle Dark Mode'
                          onClick={toggleDarkMode}
                          className="color-black"
                          style={{ cursor: 'pointer' }}
                        >
                          Dark Theme
                          {/* Theme Switcher Icon */}
                          <img src='images/theme-switch-icon.svg' alt='theme-switch-icon' style={{ marginLeft: '8px' }} />
                        </span>

                        {/* Toggle Switch */}
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input custom-switcher cursor-pointer"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckChecked-1"
                            checked={isDarkMode}
                            onChange={toggleDarkMode}
                            aria-label="Toggle Dark Mode"
                          />
                        </div>
                      </div>

                      {/* Logout Confirmation Popup (optional) */}
                      {logoutPopup && (
                        <>
                          {/* Modal Code Here */}
                        </>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>

        </div>
      </nav>

      {/* Implement your modal for logout confirmation if needed */}

    </>
  );
}