import React, { useEffect, useState, useRef, useContext } from 'react';
import mermaid from 'mermaid';
import styled from 'styled-components';
import RepoHeader from './RepoHeader';
import { fetchBranchingDetails } from '../../Service/api';
import { OrgContext } from '../../ContextProvider/OrgContext';
import { RepoWorkbenchNav } from '../Organization/RepoWorkBenchNav';
import RelativeLoader from '../loader/RelativeLoader';
import Loader from '../loader/loader';
import DeployLoader from './DeployLoader';

const Container = styled.div`  
  width: 100vw;  
  overflow-x: auto;  
`;

const DiagramContainer = styled.div`
 

  width: 100vw;  
  max-height: 80vh;                                                                                                                                                                                                                                                                          

  overflow-y: auto; /* Enables scrolling if content overflows */
  overflow-x: auto;
  position: relative;
 


  .mermaid-diagram {
    font-family: 'Arial', sans-serif;
    min-width: 50vw;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

 

  position: relative;

  .mermaid-diagram {
    font-family: 'Arial', sans-serif;
  }

  .mermaid-diagram .commit {  
   fill: #ffcccb;  
   stroke: #000000;  
   stroke-width: 1px;  
   cursor: pointer;  
  
   .commit-id {  
    display: none; /* Hide the commit ID by default */  
   }  
  
   .commit-date {  
    display: none; /* Hide the commit date by default */  
   }  
  
   &:hover {  
    fill: #ccc; /* Change the fill color on hover */  
    stroke: #666; /* Change the stroke color on hover */  
  
  
  
    .commit-date {  
      display: block; /* Display the commit date on hover */  
    }  
   }  
  }  


  .mermaid-diagram .branch {
    stroke: #000000;
    stroke-width: 1px;
  }

  .mermaid-diagram .merge {
    stroke: #000000;
    stroke-width: 1px;
  }

  .mermaid-diagram .label {
    font-weight: bold;
  }

  .mermaid-diagram #arrowhead {
    fill: #000000;
    stroke: #000000;
    stroke-width: 1px;
  }

  .mermaid-diagram .grid .tick line {
    stroke: #ddd;
    opacity: 0.3;
    shape-rendering: crispEdges;
  }

  .mermaid-diagram .grid path {
    stroke-width: 0;
  }

  .mermaid-diagram line,
  .mermaid-diagram path {
    stroke: #000000 !important;
    stroke-width: 1px !important;
  }

  .mermaid-diagram text {
    stroke: none;
    fill: #000000;
  }

  .mermaid-diagram .commit, .mermaid-diagram .commit-id {
    cursor: pointer;
  }
`;

const Tooltip = styled.div`
  position: fixed;
  background-color: #333;
  color: white;
  padding: 10px;
  border-radius: 4px;
  font-size: 12px;
  z-index: 1000;
  max-width: 200px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
`;

const BranchingStrategy = () => {
  const [svgCode, setSvgCode] = useState('');
  const [tooltip, setTooltip] = useState({ show: false, text: '', x: 0, y: 0 });
  const diagramRef = useRef(null);
  const initData = `
       `

  const [branchingDetails, setBranchingDetails] = useState("");
  const [error, setError] = useState(null);
  const [commitDetails, setCommitDetails] = useState([])
  const [showLoader, setShowLoader] = useState(true)

  const userId = localStorage.getItem('userId');
  console.log("userid", userId)

  const { orgId, repoId } = useContext(OrgContext)

  console.log(orgId, "organization")


  //PS_07
  useEffect(() => {
    getBranchingDetails();
  }, [])

  // PS_08-PS_15
  //Function to fetch the branching details
  useEffect(() => {
    if (branchingDetails != "") {
      renderDiagram()
    }
  }, [branchingDetails])


  const getBranchingDetails = async () => {
    try {
      // Define payload for API call
      const payload = {

        "organizationId": orgId,
        "userId": userId,
        "repoId": repoId

      };

      // Fetch data from API
      console.log("before calling api")
      setShowLoader(true)
      const response = await fetchBranchingDetails(payload);
      setShowLoader(false)
      // Check if the response is successful

      if (response.status === 200) {
        // Update state with fetched data



        setBranchingDetails(response.data.body.data.code);
        setCommitDetails(response.data.body.data.commitData)

        console.log(branchingDetails, "hjiko")
        console.log(commitDetails, "njakmsdl")

      } else {
        throw new Error('Failed to fetch data');
      }
    } catch (error) {
      // Handle error and log it
      setShowLoader(false)
      const errorData = {
        errorName: error.name,
        errorServiceName: "Front-end",
        errorFunctionName: "getBranchingDetails",
        errorSource: "frontend",
        errorDescription: "fetch failed: " + error.message,
      };

      // postErrorLog(errorData); // Function to log errors
      setError(errorData); // Update state with error information
    }
  };





  //PS_16
  useEffect(() => {
    mermaid.initialize({
      startOnLoad: false,
      theme: 'default',
      gitGraph: {
        useMaxWidth: true,
        showCommitLabel: true,
        rotateCommitLabel: true,
      }
    });

  }, []);
  //PS_17- PS_20
  const renderDiagram = async () => {

    try {
      console.log(branchingDetails)
      const { svg } = await mermaid.render('mermaid-diagram', branchingDetails);
      setSvgCode(svg);

      setTimeout(() => updateSvgNodes(), 0);

    } catch (error) {
      console.error("Error rendering diagram:", error);
    }
  };

  //PS_31
  const updateSvgNodes = () => {
    // Ensure that the diagramRef has been set and contains an SVG element
    if (diagramRef.current) {
      const svg = diagramRef.current.querySelector('svg');
      if (svg) {
        svg.querySelectorAll('.commit').forEach((commit, index) => {
          // Assuming commitDetails[index] corresponds to the commit node
          // Set the data-commit-id attribute for each commit node
          commit.dataset.commitId = commitDetails[index]?.id;

          // Hide the commit ID text
          const textElements = commit.querySelectorAll('text');
          textElements.forEach(textElement => {
            textElement.style.display = 'none';
          });
        });
      }
    }
  };


  // PS_21-PS_27
  //Clean up for commit details
  useEffect(() => {
    if (diagramRef.current) {
      const svg = diagramRef.current.querySelector('svg');
      if (svg) {
        svg.querySelectorAll('.commit').forEach(commit => {
          commit.addEventListener('mouseover', handleHover);
          commit.addEventListener('mouseout', () => setTooltip({ show: false, text: '', x: 0, y: 0 }));
        });
      }
    }

    // Cleanup function to remove event listeners
    return () => {
      if (diagramRef.current) {
        const svg = diagramRef.current.querySelector('svg');
        if (svg) {
          svg.querySelectorAll('.commit').forEach(commit => {
            commit.removeEventListener('mouseover', handleHover);
            commit.removeEventListener('mouseout', () => setTooltip({ show: false, text: '', x: 0, y: 0 }));
          });
        }
      }
    };
  }, [svgCode]);



  //PS_32
  const fetchCommitDetails = (commitId) => {
    // Find the commit with the matching id
    const commit = commitDetails.find(commit => commit.id === commitId);

    if (commit) {
      return {
        date: commit.date,
        author: commit.author,
        message: commit.message,
        branch: commit.branch,
        files_changed: commit.files_changed,
        url: commit.url,
        is_merge: commit.is_merge
      };
    } else {
      return {
        date: 'Unknown',
        author: 'Unknown',
        message: 'No details available',
        branch: 'Unknown',
        files_changed: [],
        url: '',
        is_merge: false
      };
    }
  };


  //PS_33
  // Update the handleClick function to handleHover
  const handleHover = (event) => {
    const commitElement = event.target.closest('.commit');
    const commitId = commitElement.dataset.commitId; // Assuming you have a data-commit-id attribute
    if (commitId) {
      const { date, author, message, url } = fetchCommitDetails(commitId);
      setTooltip({
        show: true,
        text: `Commit ID: ${commitId}\nDate: ${date}\nURL: ${url}`,
        x: event.clientX + 10,
        y: event.clientY + 10
      });
    }
  };

  //PS_34 -PS_35
  // Add a click event listener to the document to close the tooltip when clicking outside
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (diagramRef.current && !diagramRef.current.contains(event.target)) {
        setTooltip({ show: false, text: '', x: 0, y: 0 });
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);


  //PS_28 - PS_30
  //Rendering of the container and mermaid diagram
  return (

    <>

      <RepoWorkbenchNav />
      <div className="px-4 pt-5 right-container-scroll">
        <div className="row">
          <div className="col-md-12">
            <p className="font-20 font-bold color-black mb-4 pb-2">
              Branching Strategy
            </p>
            <div className="mermaid-diagram" dangerouslySetInnerHTML={{ __html: svgCode }} />
          </div>
        </div>
      </div>
      {showLoader ?
        <Loader /> :
        <>
          <Container>

            <DiagramContainer ref={diagramRef}>
              {tooltip.show && (
                <Tooltip style={{ left: `${tooltip.x}px`, top: `${tooltip.y}px` }}>
                  {tooltip.text.split('\n').map((line, index) => (
                    <div key={index}>{line}</div>
                  ))}
                </Tooltip>
              )}


            </DiagramContainer>

          </Container>
        </>
      }

    </>



  );
};

export default BranchingStrategy;