import { useState } from "react";
import { createArticle } from "../../Service/api";

function DocumentationPopup({ documentation, onClose, onCopy, documentName, setDocumentName, isPopupOpen,setPopUpMsg,popupMsg,setShowLoader,showLoader,setIsPopupOpen }) {

  const userId = localStorage.getItem('userId');
  
  const handleNameChange = (event) => {
    setDocumentName(event.target.value);
  };


  const handleUpload = async () => {
    // setUploadbtnDisable(true)

    const payload = {
      userId: userId,
      articleName: documentName,
      articleDescription: documentName,
      articleData: documentation
    }

    try {
      setShowLoader(true)
      const response = await createArticle(payload);
      setShowLoader(false)

      console.log('Document uploaded successfully:', response.data);
      setDocumentName("")
      // const closeButton = document.getElementById('close');
      // if (closeButton) {
      //   closeButton.click();
      // }
      setIsPopupOpen(false)
      setPopUpMsg({ ...popupMsg, ["Msg"]: "Documentation saved successfully", ["show"]: true, ["success"]: true })
   
    } catch (error) {
      console.error('Error uploading document:', error);
      setPopUpMsg({ ...popupMsg, ["Msg"]: "Error in savind documentation", ["show"]: true, ["success"]: false })
     setShowLoader(false)
    }
  };



  // Filter out the top HTML tags from the documentation
  const filterHTMLContent = (htmlContent) => {
    return htmlContent.replace(/<!DOCTYPE html>|<html(.*?)>|<\/html>|<head>|<\/head>|<meta(.*?)>|<title>(.*?)<\/title>|<style(.*?)>(.*?)<\/style>|<body>|<\/body>/gis, '');
  };

  return (
    <>
      {isPopupOpen && (
        <div
          className="modal fade show"
        //  id="staticBackdrop-28"
          tabIndex={-1}
        style={{ display: 'block', backgroundColor: "rgba(0,0,0,0.6)" }}
        >

          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content  border-0">
              <div className="modal-header border-0 py-4 px-32">
                <h1
                  className="modal-title font-20 font-bold black-v2"
                  id="staticBackdropLabel"
                >
                  Documentations
                </h1>
                <div className="d-flex gap-3">
                  <button
                    type="button"
                    className="btn shadow-none p-0"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    id='close'
                    onClick={onClose}
                  >
                    <img
                      src="images/close-icon.svg"
                      alt="close-icon"
                      className="icon-hover shadow-none modal-close-filter"
                    />
                  </button>
                </div>
              </div>
              <div className="modal-body px-32">
                <div className="row">
                  <div className="col-md-12 doc-container mb-3">
                    <div className="col-md-4 mb-4 pb-2">
                      <label className="form-label font-14 font-medium color-black" htmlFor="Name" placeholder ="Enter the Name">
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control custom-form color-black"
                        id="Name"
                        placeholder="Enter the Document Name"
                        value={documentName}
                        onChange={handleNameChange}
                      />
                    </div>
                    <div className="col-md-12 doc-container mb-3">
                      <div
                        className="font-14 color-black"
                        dangerouslySetInnerHTML={{ __html: filterHTMLContent(documentation) }}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center my-0">
                    <button type="button" className="button outline-button px-4 py-12"
                      // onClick={onClose}
                      onClick={(() => {
                        setDocumentName("")
                        const closeButton = document.getElementById('close');
                        if (closeButton) {
                          closeButton.click();
                        }
                      })}
                    >
                      Cancel
                    </button>
                    <div className="d-flex gap-2">
                      <button
                        type="button"
                        className="button secondary-outline-button px-4 py-12"
                        onClick={onCopy}
                      >
                        {" "}
                        <img
                          className="me-2"
                          src="images/clone-icon.svg"
                          alt="copy-icon"
                        />
                        Copy
                      </button>
                      <button
                        type="button"
                        className="button primary-button px-4 py-12"
                        onClick={handleUpload}
                        disabled={!documentName}
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      )}



    </>
  )
}

export default DocumentationPopup;