import { useState } from "react";

function DeployPopUps({ onClose, modalType, onDeploy, adminData, projectAdminData, handleRequestForDeploy }) {

  const [mode, setMode] = useState('Complete');
  const [selectedAdmin, setSelectedAdmin] = useState('');
  const [selectedProjectAdmin, setSelectedProjectAdmin] = useState('');


  const handleDeploy = () => {
    onDeploy(mode);
    onClose();
  };

  const handleSendRequest = () => {
    handleRequestForDeploy();
    onClose();
  };


  const renderModalContent = () => {
    switch (modalType) {
      case 'approval':
        return (
          <div>
            <div
              className="modal-backdrop "
              id="staticBackdrop-10"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabIndex={-1}
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
              style={{ display: 'block', backgroundColor: "rgba(0,0,0,0.6)" }}
            >
              <div className="modal-dialog  modal-dialog-centered justify-content-center">
                <div className="modal-content approvel-dialog border-0">
                  <div className="modal-header border-0 p-32 pb-4">
                    <h1
                      className="modal-title font-20 font-bold black-v2"
                      id="staticBackdropLabel"
                    >
                      <img src="images/alert-icon.svg" alt="alert-icon" /> Approval Needed
                    </h1>
                    <button
                      type="button"
                      className="btn shadow-none p-0"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={onClose}
                    >
                      <img
                        src="images/close-icon.svg"
                        alt="close-icon"
                        className="icon-hover shadow-none modal-close-filter"
                      />
                    </button>
                  </div>
                  <div className="modal-body px-32 pt-0">
                    <p className="font-16 font-regular secondary-text-color-v4 mb-32">
                      Approval needed to proceed to further actions.
                    </p>
                    <div className="my-4">
                      <label
                        className="form-label font-14 font-medium black-v2"
                        htmlFor="Status-21"
                      >
                        Select Admin
                        <span className="danger-red">*</span>
                      </label>
                      <select
                        className="form-select custom-dropdown shadow-none custom-border-box font-14 font-regular black-v2 custom-select"
                        aria-label="Default select example"
                        id="Status-21"
                        value={selectedAdmin}
                        onChange={(e) => setSelectedAdmin(e.target.value)}
                      >
                        <option value="">Select</option>
                        {/* {adminData.map((admin, index) => (
                          <option key={index} value={admin.id}>{admin.name}</option>
                        ))} */}
                      </select>
                    </div>
                    <div className="my-4">
                      <label
                        className="form-label font-14 font-medium black-v2"
                        htmlFor="Status-24"
                      >
                        Select project Admin
                      </label>
                      {/* <input type="text" class="form-control custom-dropdown shadow-none" id="Status-24"
                                  placeholder="Enter URL"> */}
                     <select
                        className="form-select custom-dropdown shadow-none custom-border-box font-14 font-regular black-v2 custom-select"
                        aria-label="Default select example"
                        id="Status-24"
                        value={selectedProjectAdmin}
                        onChange={(e) => setSelectedProjectAdmin(e.target.value)}
                      >
                        <option value="">Select</option>
                        {/* {projectAdminData.map((admin, index) => (
                          <option key={index} value={admin.id}>{admin.name}</option>
                        ))} */}
                      </select>
                    </div>

                    <div className="d-flex justify-content-end align-items-center mt-5 mb-2 gap-3">
                      <button
                        type="button"
                        className="button outline-button text-nowrap px-4"
                        onClick={onClose}
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn button primary-button text-nowrap px-3"
                       // disabled={!selectedAdmin || !selectedProjectAdmin}
                        onClick={handleSendRequest}
                      >
                        Send Request
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case 'arm':
        return (
          <div className="modal-body px-32 pt-1">
            <div className="mb-2">
              <label className="form-label font-14 font-medium black-v2" htmlFor="mode">Mode</label>
              <select
                className="form-select custom-dropdown shadow-none custom-border-box font-14 font-regular black-v2 custom-select"
                aria-label="Default select example"
                id="mode"
                value={mode}
                onChange={(e) => setMode(e.target.value)}
              >
                <option value="complete">Complete</option>
                <option value="incremental">Incremental</option>
              </select>
            </div>
            <p className="font-10 font-regular secondary-color">
              <img src="images/tooltip-dark.svg" alt="tooltip-icon" className="me-1 cursor-pointer" />
              Incremental Mode updates the data with the current data
            </p>
            <div className="d-flex justify-content-end align-items-center mt-4 mb-2 gap-3">
              <button
                type="button"
                className="button outline-button text-nowrap py-12 px-4"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                type="button"
                className="button primary-button text-nowrap py-12 px-4"
                onClick={handleDeploy}
              >
                Deploy
              </button>
            </div>
          </div>
        );

      case 'deployed':
        return (
          <div className="modal-body text-center pt-0 p-32">
            <img src="images/approved-tick.svg" alt="approved-tick" />
            <h6 className="font-20 font-bold black-v2 mt-3">Architecture has been deployed</h6>
            <div className="mt-4">
              <input
                className="form-check-input custom-checkbox shadow-none me-2"
                type="checkbox"
                value=""
                id="check-list"
              />
              <label htmlFor="check-list" className="font-16 font-regular secondary-color">
                Add current architecture to Knowledge Base
              </label>
            </div>
            <div className="d-flex justify-content-center align-items-center mt-4">
              <button
                type="button"
                className="button primary-button px-5 py-12"
                onClick={onClose}
              >
                OK
              </button>
            </div>
          </div>
        );
      case 'approver':
        return (
          <div
            className="modal fade"
            id="staticBackdrop-21"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog  modal-dialog-centered justify-content-center article-popup">
              <div className="modal-content border-0">
                <div className="modal-header border-0 py-4 px-32">
                  <h1
                    className="modal-title font-20 font-bold black-v2"
                    id="staticBackdropLabel"
                  >
                    Rejection
                  </h1>
                  <button
                    type="button"
                    className="btn shadow-none pe-0"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <img
                      src="images/close-icon.svg"
                      alt="close-icon"
                      className="icon-hover shadow-none modal-close-filter"
                    />
                  </button>
                </div>
                <div className="modal-body p-32 pt-0">
                  <label
                    className="form-label font-14 font-medium black-v2 mb-3"
                    htmlFor="article-name"
                  >
                    Reason for Rejection <span className="danger-red">*</span>
                  </label>
                  <textarea
                    className="form-control custom-form article-grey-colorarea font-14 font-medium"
                    placeholder="Enter Reason for rejection"
                    id="architecture"
                    defaultValue={""}
                  />
                  <div className="d-flex justify-content-end align-items-center mt-5 gap-3">
                    <button
                      type="button"
                      className="button outline-button text-nowrap"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button type="button" className="button primary-button text-nowrap">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

        )
      // Add more cases for other modal types if needed
      default:
        return null;
    }
  };

  return (

    
    <div
      className="modal-backdrop"
      id="staticBackdrop-10"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
      aria-labelledby="staticBackdropLabel"
      aria-hidden="false"
      style={{ display: 'block', backgroundColor: "rgba(0,0,0,0.7)" }}
    >
      <div className="modal-dialog modal-dialog-centered justify-content-center">
        <div className="modal-content approvel-dialog border-0">
          <div className="modal-header border-0 p-32 pb-4">
            <h1 className="modal-title font-20 font-bold black-v2" id="staticBackdropLabel">
              <img src="images/alert-icon.svg" alt="alert-icon" /> {modalType === 'approval' ? 'Approval Needed' : 'Deploy'}
            </h1>
            <button
              type="button"
              className="btn shadow-none p-0"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onClose}
            >
              <img
                src="images/close-icon.svg"
                alt="close-icon"
                className="icon-hover shadow-none modal-close-filter"
              />
            </button>
          </div>

          <div
            className="modal fade"
            id="staticBackdrop-21"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog  modal-dialog-centered justify-content-center article-popup">
              <div className="modal-content border-0">
                <div className="modal-header border-0 py-4 px-32">
                  <h1
                    className="modal-title font-20 font-bold black-v2"
                    id="staticBackdropLabel"
                  >
                    Rejection
                  </h1>
                  <button
                    type="button"
                    className="btn shadow-none pe-0"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <img
                      src="images/close-icon.svg"
                      alt="close-icon"
                      className="icon-hover shadow-none modal-close-filter"
                    />
                  </button>
                </div>
                <div className="modal-body p-32 pt-0">
                  <label
                    className="form-label font-14 font-medium black-v2 mb-3"
                    htmlFor="article-name"
                  >
                    Reason for Rejection <span className="danger-red">*</span>
                  </label>
                  <textarea
                    className="form-control custom-form article-grey-colorarea font-14 font-medium"
                    placeholder="Enter Reason for rejection"
                    id="architecture"
                    defaultValue={""}
                  />
                  <div className="d-flex justify-content-end align-items-center mt-5 gap-3">
                    <button
                      type="button"
                      className="button outline-button text-nowrap"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button type="button" className="button primary-button text-nowrap">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {renderModalContent()}
        </div>
      </div>
    </div>
  );
}

export default DeployPopUps