import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

/**
 * ToastPopup Component
 * 
 * This component displays a toast notification that automatically disappears after a specified duration.
 * It can show both success and error messages with different styling.
 *
 * @param {Object} props
 * @param {Object} props.toastMsg - Object containing toast message details
 * @param {boolean} props.toastMsg.show - Whether to show the toast
 * @param {string} props.toastMsg.message - The message to display
 * @param {boolean} props.toastMsg.success - Whether it's a success message (true) or error message (false)
 * @param {Function} props.setToastMsg - Function to update the toast message state
 */


export default function ToastPopup({ toastMsg, setToastMsg }) {
    useEffect(() => {
        // If toast message is shown, set a timeout to hide it after 3 seconds
        if (toastMsg.show) {
            const timer = setTimeout(() => {
                // Hide the toast after 3 seconds
                setToastMsg({ show: false, message: "", success: false });
            }, 3000);

            // Clear timeout if the component is unmounted or if toastMsg changes
            return () => clearTimeout(timer);
        }
    }, [toastMsg, setToastMsg]);

    // Styles for the toast container
    const toastContainerStyle = {
        position: 'fixed',
        top: '20px',
        left: '50%',
        transform: 'translateX(-50%)', // Center horizontally
        zIndex: 1050,
        minWidth: '300px',
        maxWidth: '90%', // Ensure it doesn't exceed screen width on smaller devices
        textAlign: 'center', // Center text in the container
        backgroundColor: toastMsg.success ? '#28a745' : '#dc3545', // Green for success, red for error
        color: '#ffffff', // White text color for both cases
        borderRadius: '5px', // Optional rounded corners for aesthetics
        padding: '15px', // Padding inside the container
    };

    return (
        <div className="toast-container" style={toastContainerStyle}>
            {toastMsg.show && (
                <div>
                    <div className="d-flex align-items-center gap-3 mb-0">
                        <img
                            src={toastMsg.success ? "images/tick-green-small.svg" : "images/error-icon-red.svg"}
                            alt="status-icon"
                            className="width-25"
                        />
                        <p className="font-14 font-regular mb-0">
                            {toastMsg.message}
                        </p>
                    </div>
                    <button 
                        type="button" 
                        className="btn p-0 shadow-none"
                        onClick={() => {
                            // Hide the toast when button is clicked
                            setToastMsg({ show: false, message: "", success: false });
                        }}
                    >
                        {/* Optional close icon */}
                        {/* <img src="images/cross-white.svg" alt="cross-icon" className="w-9" /> */}
                    </button>
                </div>
            )}
        </div>
    );
}
// PropTypes for type checking
ToastPopup.propTypes = {
    toastMsg: PropTypes.shape({
        show: PropTypes.bool.isRequired,
        message: PropTypes.string.isRequired,
        success: PropTypes.bool.isRequired,
    }).isRequired,
    setToastMsg: PropTypes.func.isRequired,
};