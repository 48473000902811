
import React, {createContext, useState, useContext, useEffect } from 'react';
import Modularization from '../Components/Admin/ServiceConfigurations/Modularization';
// import { getAllModules, deleteModule, getCloudProviders, getCategories } from './api';

export const ModulesContext= createContext();

export function ModulesProvider  ({ children })  {
  const [modules, setModules] = useState([]);
  const [selectedModule, setSelectedModule] = useState(null);
  const [versions, setVersions] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState("");
  const [providers, setProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState("");
  const [categories, setCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  
  const [categoryData, setCategoryData] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState('');
  const [cloudProviderData, setCloudProviderData] = useState([])
  const [selectedProviderId, setSelectedProviderId] = useState('');
  const selectedProviderNameFilter = cloudProviderData.find(provider => provider.providerId === selectedProviderId)?.providerName;
  const[selectedProviderNamee,setSelectedProviderNamee]=useState(selectedProviderNameFilter)

  const[addServiceData,setAddServiceData]=useState([])
  const [importModulePopup, setImportModulePopup] = useState(false);
  const[providerName,setProviderName]=useState("")
  const[reload,setReload]=useState(false)
  const [resources, setResources] = useState([]);

  

  const userId=localStorage.getItem('userId')

  




  return (
    <ModulesContext.Provider 
    value={{
      modules,
      selectedModule,
      setSelectedModule,
      versions,
      selectedVersion,
      setSelectedVersion,
      providers,
      selectedProvider,
      setSelectedProvider,
      categories,
      selectedCategory,
      setSelectedCategory,
      searchTerm,
      setSearchTerm,
      setModules,
      setVersions,
      categoryData,
      setCategoryData,
      addServiceData,
      setAddServiceData,userId,
      importModulePopup,
      setImportModulePopup,
      providerName,
      setProviderName,
      selectedProviderId,
      setSelectedProviderId,
      selectedProviderNamee,
      setSelectedProviderNamee,
      cloudProviderData,
      setCloudProviderData,
      reload,
      setReload,
      resources,
      setResources
    }}
    >
        {children}
 </ModulesContext.Provider>
  );
};

export function useModuleContext() {
  return useContext(ModulesContext);
}