import { useNavigate } from "react-router-dom";
import React, { useState, useEffect, createContext, useContext } from 'react';

import Header from "../Header/Header";
import { fetchAllInsightById, fetchAllInsightData } from "../../Service/api";
import moment from "moment";


// const OrganizationContext = createContext();

function Insights() {


  // PS_04 - useState declaration
  const navigate = useNavigate();

  const [insightData, setInsightData] = useState([]);
  const [activeTab, setActiveTab] = useState('all');
  const [awsData, setAwsData] = useState([]);
  const [azureData, setAzureData] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [insightViewData, setInsightViewData] = useState([]);
  const [filterData, setFilterData] = useState({
    fromDate: '',
    ToDate: '',
  })

  const userId = localStorage.getItem('userId');
  console.log("userid",userId)

  //PS_07
  // Effect to fetch insight data on mount and filter change
  useEffect(() => {
    getAllInsightData();

  }, []);



  //PS_08 - PS_22
  // Function to handle API call and data processing

  const getAllInsightData = async () => {
    try {
      const response = await fetchAllInsightData({ userId: userId, ...filterData });
     
      if (response.status === 200) {
        // const data = await response.json(); 
       
        const data = await response.data.body.data;

        setInsightData(data);
        
        partitionInsights(data);
      }
    } catch (error) {
      // postErrorLog({ error: error.message }); 
      console.log(error)
    }
  };

  console.log(insightData,"insights")



  // PS_14 -PS_16
  // Function to partition insights
  const partitionInsights = (data) => {
    const awsInsights = data.filter(insight => insight.provider_id === 'aws');
    const azureInsights = data.filter(insight => insight.provider_id === 'azure');
    setAwsData(awsInsights);
    setAzureData(azureInsights);
  };



  //PS_27 -PS_29
  // Function to handle tab switch
  const handleTabSwitch = (tab) => {
    setActiveTab(tab);
  };

  //PS_33- PS_34
  const handleViewInsightsClick = async (rawIsights_id) => {
    try {
      const response = await fetchAllInsightById({ userId: userId, featureId: rawIsights_id });
      if (response.status === 200) {
        const data = await response.data.body.data.organization;
        console.log(data)
        navigate('/InsightsView', { state: { insightViewData: data } });

      }

    } catch (error) {
      console.log(error);
    }
  };


  //PS_30 - PS_32
  const renderInsightItem = (insight,index) => (
    <li className={`position-relative ${index % 2 === 0 ? 'active' : ''}`} key={insight.rawIsights_id}>
      <div className="d-flex align-items-center justify-content-between title-container">
        <div className="d-flex align-items-center">
          <img
            className="partner-logo light-theme-img"
            src={insight.provider_id === "aws" ? "images/aws-black-icon.svg" : "images/azure-logo-small.svg"}
            alt="partner-logo"
          />
          <img
            className="partner-logo dark-theme-img"
            src={insight.provider_id === "aws" ? "images/aws-logo-dark.svg" : "images/azure-logo-small.svg"}
            alt="partner-logo"
          />
          <div className="ms-4 ps-2">
            <p className="mb-0 d-flex align-items-center">
              <a
                href="#"
                className="font-16 font-medium black-v2 insights-title d-flex align-items-center text-decoration-none"
              >
                {insight.highleveldata}
              </a>
              <span className="ms-3 font-14 font-medium secondary-text-color-v3">
              {moment(insight.time).local().format('MMMM Do YYYY, h:mm A')}
              </span>
            </p>
            <p className="mb-0 font-14 font-regular mt-3 secondary-text-color-v4">
              {insight.insightsdata}
            </p>
          </div>
        </div>
        <a
          href="#"
          className="primary-color font-16 font-medium text-decoration-none"
          onClick={(e) => {
            e.preventDefault();
            handleViewInsightsClick(insight.rawIsights_id);
          }}
        >
          View Insights
        </a>
      </div>
    </li>
  );

  //PS_39
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  //PS_35 - PS_38
  const renderTabContent = () => {
    let insightsToRender;
    switch (activeTab) {
      case 'aws':
        insightsToRender = awsData;
        break;
      case 'azure':
        insightsToRender = azureData;
        break;
      default:
        insightsToRender = insightData;
    }
    console.log(insightsToRender, "what error")

    if (!insightsToRender || insightsToRender.length === 0) {
      return (
        <div className="no-record-found">
          <img src="images/no-records-found.png" className="no-record-icon" />
        </div>
      );
    }

    return (
      <ul className="list-unstyled">
        {insightsToRender?.map(renderInsightItem)}
      </ul>
    );
  }


//PS_60
  const filterValidation = (e) => {
    debugger
    const { id, value } = e.target;
    console.log(id, value)
    setFilterData(prevState => ({
      ...prevState,
      [id]: value
    }));
  };

  //PS_62
  const handleApply = async () => {
    // Logic to apply filters

    await getAllInsightData(filterData);
    //setInsightData("");
    console.log('Filters applied:', filterData);
    setTimeout(() => {
      const closeButton = document.getElementById('filter');
      if (closeButton) {
        closeButton.click();
      }
    }, 2000);

  };

  //PS_63
  const handleClear = () => {
    setFilterData({
      fromDate: '',
      ToDate: ''
    });
    getAllInsightData(filterData)
  };

  //PS_64
  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split('T')[0]; // Format YYYY-MM-DD
  };





  //PS_39
  return (
    <>


      <>
        {/* header starts */}
        {/* <Header /> */}
        {/* header ends */}

        <div className="container-fluid custom-container right-container-scroll">
          {/* Slide title start here*/}
          <div className="d-flex justify-content-between align-items-center pt-4 mt-2 notifications-title-bar">
            <h2 className="font-20 font-bold black-v2 mb-0">Insights</h2>
            <div className="d-flex gap-3">
              {/* filter start */}
              <button
                type="button"
                className="filter-button position-relative"
                data-bs-auto-close="false"
                data-bs-toggle="dropdown"
                id="filter"
              >
                <img src="images/filter-blue.svg" alt="filter-icon" />{" "}
              </button>
              {/*filter*/}
              <div
                className="dropdown-menu dropdown-menu-end border-0 custom-shadow dd-position background-white custom-filter-width"
                aria-labelledby="dropdownMenuLink"
              >
                <h5 className="font-medium font-16 mb-0 color-black mb-4">Filter</h5>
                <div className="row">
                  <div className="col-md-12 mb-2">
                    <label className="font-14 font-medium black-v2">Date Range</label>
                  </div>
                  <div className="col-md-6 mb-4">
                    <label
                      className="form-label font-14 font-medium black-v2 label-color form-text-color"
                      htmlFor="from"
                    >
                      From
                    </label>
                    <div className="position-relative">
                      <input
                        className="form-control"
                        type="date"
                        id="fromDate"
                        onChange={filterValidation}
                        value={filterData.fromDate ? new Date(filterData.fromDate).toISOString().split('T')[0] : ''}
                        max={getCurrentDate()}
                      />
                      
                    </div>
                  </div>
                  <div className="col-md-6 mb-4">
                    <label
                      className="form-label font-14 font-medium label-color black-v2 form-text-color"
                      htmlFor="to"
                    >
                      To
                    </label>
                    <div className="position-relative">
                      <input
                        className="form-control"
                        type="date"
                        id="ToDate"
                        onChange={filterValidation}
                        value={filterData.ToDate ? new Date(filterData.ToDate).toISOString().split('T')[0] : ''}
                        min={filterData.fromDate} // Ensure end date is after start date
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="d-flex justify-content-end align-items-center pt-3 gap-3">
                      <button className="button outline-button" onClick={handleClear}>Clear All</button>
                      <button disabled={!filterData.fromDate && !filterData.ToDate} className="button primary-button" onClick={handleApply}>
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/*filter*/}
            </div>
          </div>
          {/* Slide title end here*/}
          {/* Page Content Start here */}
          <div className="notification-tab-parent">
            <div className="notification-tab-title mb-2">
              <ul
                className="nav nav-tabs custom-border-bottom"
                id="myTab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${activeTab === 'all' ? 'active' : ''}`}
                    onClick={() => handleTabSwitch('all')}
                  >
                    All
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${activeTab === 'azure' ? 'active' : ''}`}
                    onClick={() => handleTabSwitch('azure')}
                  >
                    Azure
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${activeTab === 'aws' ? 'active' : ''}`}
                    onClick={() => handleTabSwitch('aws')}
                  >
                    AWS
                  </button>
                </li>
              </ul>
            </div>

            <div className="notification-tab-content mt-4">
              <div className="tab-content" id="myTabContent">
                <div
                  className={`tab-pane fade ${activeTab === 'all' ? 'show active' : ''}`}
                  id="all-tab-pane"
                  role="tabpanel"
                  aria-labelledby="all-tab"
                  tabIndex={0}
                >
                  {renderTabContent()}
                </div>
                <div
                  className={`tab-pane fade ${activeTab === 'azure' ? 'show active' : ''}`}
                  id="azure-tab-pane"
                  role="tabpanel"
                  aria-labelledby="azure-tab"
                  tabIndex={0}
                >
                  {renderTabContent()}
                </div>
                <div
                  className={`tab-pane fade ${activeTab === 'aws' ? 'show active' : ''}`}
                  id="aws-tab-pane"
                  role="tabpanel"
                  aria-labelledby="aws-tab"
                  tabIndex={0}
                >
                  {renderTabContent()}
                </div>
              </div>
            </div>
          </div>

          {/* Page Content End here */}
        </div>
      </>


    </>
  )
}
export default Insights;