import React, { useContext, useState, useEffect } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import { getCostByTags } from '../../Service/api';
import { OrgContext } from '../../ContextProvider/OrgContext';
import moment from 'moment';
import { DashboardContext } from './Context/DashboardContext';

const COLORS = ["#f48fb1", "#64b5f6", "#a8e6cf", "#d7ccc8", "#90caf9"];

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip" style={{ backgroundColor: 'white', padding: '5px', border: '1px solid #cccccc' }}>
        <p style={{ margin: 0 }}>{`${payload[0].name}: $${payload[0].value.toFixed(2)}`}</p>
      </div>
    );
  }
  return null;
};

const CostByTags = ({ selectedProviderId, fromDate, toDate, selectedTagIds }) => {
  const { orgId } = useContext(OrgContext)
  const [data, setData] = useState([]);
  const {tagsE, setTagsE} = useContext(DashboardContext)

  const noDataStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '200px',
    width: '100%',
    margin: 0,
    fontSize: '14px',
    color: '#333',
  };

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    const fetchData = async () => {
      if (!userId || !orgId || !selectedProviderId) {
        console.log('Missing required data:', { userId, orgId, selectedProviderId });
        return;
      }
    
      try {
        const formattedFromDate = fromDate ? moment(fromDate, 'DD-MM-YYYY').format('YYYY-MM-DD') : '';
        const formattedToDate = toDate ? moment(toDate, 'DD-MM-YYYY').format('YYYY-MM-DD') : '';
    
        const payload = {
          userId: userId,
          orgId: orgId,
          providerId: selectedProviderId,
          tagId: selectedTagIds,
          from: formattedFromDate,
          to: formattedToDate
        };
    
        const response = await getCostByTags(payload);
    
        if (response.data && response.data.success) {
          const newData = response.data.data.map(item => ({
            name: item.uniqueTag,
            value: parseFloat(item.cost) || 0
          }));

          let exportDatas = newData?.map((value,index) => {
            return {
              Tags: value?.name,
              Actual_Cost: value?.value
            }
          })

          setTagsE(exportDatas)
          setData(newData);
        } else {
          console.log("API request failed:", response.data ? response.data.message : "Unknown error");
        }
      } catch (error) {
        console.log("Error fetching data:", error);
      } 
    };

    fetchData();
  }, [orgId, selectedProviderId, fromDate, toDate, selectedTagIds]);

  const totalCost = data.reduce((acc, current) => acc + current.value, 0);

  if (data.length === 0) {
    return (
      <div style={noDataStyle}>
        <p>No data found</p>
      </div>
    );
  }

  return (
    <div className='tags-align'>
      <div style={{ display: 'flex' }}>
        <ResponsiveContainer width="60%" height={200}>
          <PieChart>
            <Pie
              data={data}
              cx={120}
              cy={100}
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              paddingAngle={5}
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <text x="50%" y="50%" textAnchor="middle" dominantBaseline="middle">
              <tspan x="45%" dy="-0.5em" fontSize="14" fill="#333">Total Cost</tspan>
              <tspan x="45%" dy="1.5em" fontSize="18" fontWeight="bold" fill="#333">${totalCost.toFixed(2)}</tspan>
            </text>
            <Tooltip content={CustomTooltip} />
          </PieChart>
        </ResponsiveContainer>

        <div className="legend-container ms-4">
          {data.map((entry, index) => (
            <div key={entry.name} className="d-flex justify-content-between mb-4">
              <p className="font-12 font-medium secondary-color mb-0">
                <span className={`legend legend-${COLORS[index % COLORS.length]}`} style={{ backgroundColor: COLORS[index % COLORS.length], width: '10px', height: '10px', display: 'inline-block', marginRight: '5px' }} />
                {entry.name}
              </p>
              <p className="font-12 font-medium black-v2 mb-0 ms-2 text-nowrap">${entry.value.toFixed(2)}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CostByTags;