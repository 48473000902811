//PS_01
import React, { useState, useEffect, useContext } from 'react';
//PS_02
import SideBar from '../sidebar/SideBar';
//PS_03
import { OrgContext } from '../../ContextProvider/OrgContext';
//PS_04
import { getResourceGroupApi, getWorkspaceAzureApi, getWorkspaceAwsApi, getComputeAwsApi, getAllEnvByWorkloadIdApi, getComputeAzureApi, getAllWorkloadByOrgIdApi, setThresholdApi, get_threshold_detailsApi } from '../../Service/api';

import { useNavigate, useLocation } from 'react-router-dom';
import ToastPopup from '../Toast/toastPopup';

const mockProviders = [
	{ id: 1, name: 'AWS' },
	{ id: 2, name: 'Azure' },
];

const CostManagementForm = () => {
	//PS_05
	const [budgetFormData, setBudgetFormData] = useState({
		workloadName: '',
		cloudProvider: '',
		environment: '',
		budget: '',
		uniqueTagIdentifier: '',
		resetPeriod: '',
		fromDate: '',
		toDate: '',
		clientId: '',
		workspaceId: '',
		initBudget: '',
		fromDate: '',
		toDate: '',
		fromDate2: '',
		toDate2: '',
		thresholds: [],
		alertEmailId: '',
		teamsIntegrationUrl: ''
	});
	//PS_06
	const [workloads, setWorkloads] = useState([]);
	const [environments, setEnvironments] = useState([]);
	const [envId, setEnvId] = useState([]);
	const [awsWorkspaces, setAwsWorkspaces] = useState([])
	const [resourceGroupId, setresourceGroupId] = useState([])
	const [providers, setProviders] = useState([]);
	const [editingIndex, setEditingIndex] = useState(-1);
	const [editingComputeTypeIndex, setEditingComputeTypeIndex] = useState(-1);
	const [resourceGroups, setResourceGroups] = useState([]);
	const [selectedWorkspaces, setSelectedWorkspaces] = useState([]);
	const [selectedResourceGroup, setSelectedResourceGroup] = useState('');
	// const {architectures}=useContext(OrgContext)
	const [errorMessages, setErrorMessages] = useState({});

	const [resourceGroupsData, setResourceGroupsData] = useState([]);
	const [errorMessage, setErrorMessage] = useState('');
	const [toastMsg, setToastMsg] = useState({ show: false, message: '', success: true });


	const {
		orgId,
		userRoles,
		providerId,
		architectures,
		canCreateWorkload,
		canDeleteWorkload,
		canViewWorkload,
		setuserRoles,

		// Add organization and provider fields here
		providerName,
		orgData,
		setOrgData,
		setOrgId,
		setarchitectures,

		workloadId, // Workload ID
		aiArchitecture,
		viewMode,
		envName,
		language,
		archType,
		business,
		rulebook,
		archDesc,
		services,

		lastBuildID,
		lastBuildDate,
		lastBuildMessage,

		repoId,
		repoName,

		PulseOrganization_id,
		PulseVertical_id,
		PulseVertical_name,
		PulseOrganization_name,
		PulseIdentification,

	} = useContext(OrgContext);
	const [userId, setuserId] = useState(localStorage.getItem('userId'));
	const location = useLocation()
	const [editMode, seteditMode] = useState(false)
	// //PS_07 Fetch initial data
	useEffect(() => {
		// setWorkloads(mockWorkloads);
		setProviders(mockProviders);
		loadWorkloads()
	}, []);
	useEffect(() => {
		console.log(location, "Location In the ADD Budget")
		if (location.state) {
			const {
				editMode,
				envId
			} = location.state
			if (editMode == true && envId) {
				seteditMode(true)
				get_threshold_details()
				loadWorkloads()
			}

		}
	}, [location])

	useEffect(() => {
		console.log(editMode, "eDit")
	}, [editMode])



	const get_threshold_details = async () => {
		try {
			const payload = {
				userId,
				organizationId: orgId,
				envID: location.state.envId
			};
			console.log("Payload for the GetThreshold Details:", payload);

			const res = await get_threshold_detailsApi(payload);
			console.log("Response for the get_threshold_detailsApi:", res);

			if (res.success) {
				const data = res.data;
				const obj = {
					ComputeType: data.ComputeType || {},
					workloadName: data.workLoadId || "",
					cloudProvider: data.cloudProvider || "",
					environment: data.envID || "",
					budget: data.budget || 0,
					uniqueTagIdentifier: data.UniqueTag || "",
					resetPeriod: data.period || "",
					clientId: data.email || "",
					workspaceId: data.workLoadId || "",
					fromDate: data.fromDate || "",
					toDate: data.toDate || "",
					fromDate2: data.fromDate || "",
					toDate2: data.toDate || "",
					thresholds: data.Conditions?.Threshold || [],
					alertEmailId: data.Conditions?.AlertEmailID || "",
					teamsIntegrationUrl: data.Conditions?.TeamsIntegration || "",
					envName: data.envName || "",
					initBudget: data.Conditions?.InitBudget || 0,
					clientId: data.DatabricksConfiguration?.clientId || "",
					secretId: data.DatabricksConfiguration?.secretId || "",
					subcriptionId: data.DatabricksConfiguration?.subcriptionId || "",
					tenantId: data.DatabricksConfiguration?.tenantId || "",
				};

				// Set the state
				setBudgetFormData(obj);

				// Load environments
				await loadEnvironments(data.workLoadId);
				setResourceGroupsData(data.Workspaces || []);
				// Handle workspaces based on cloud provider
				if (data.Workspaces && data.Workspaces.length > 0) {
					if (data.cloudProvider.toLowerCase() === "aws") {
						setWorkspaces(data.Workspaces);
						await loadWorkspaceDetails2(); // Load AWS workspaces
					} else if (data.cloudProvider.toLowerCase() === "azure") {
						const flattenedWorkspaces = data.Workspaces.flatMap(group =>
							group.Workspaces.map(ws => ({
								workspaceId: ws.WorkspaceName,
								workspaceName: ws.WorkspaceName,
								computeType: ws.ComputeType
							}))
						);
						setWorkspacesAzure(flattenedWorkspaces);
						setResourceGroupsData(data.Workspaces);
						let credsObj = {

							organizationId: orgId,
							userId: userId,
							tenantId: obj.tenantId,
							subcriptionId: obj.subcriptionId,
							secretId: obj.secretId,
							clientId: obj.clientId

						};

						// Load Azure specific data
						await loadResourceGroups("edit", credsObj);
						if (resourceGroups.length > 0) {
							const firstResourceGroup = resourceGroups[0];
							setSelectedResourceGroup(firstResourceGroup.resourceGroupId);
							await loadWorkspaceDetails(firstResourceGroup.resourceGroupId);
						}

					}
				}
				// Set thresholds
				if (data.Conditions?.Threshold) {
					setNewThresholds(data.Conditions.Threshold);
				}

			} else {
				console.error("Failed to get threshold details:", res.message);
			}
		} catch (error) {
			console.error("Error in get_threshold_details:", error);
		}
	};


	useEffect(() => {
		console.log(budgetFormData, "BudgetFormData")
	}, [budgetFormData])

	const validateFields = () => {
		let errors = {};
		// Validate Alert Email ID
		if (budgetFormData.alertEmailId && !/^[\w-.]+@(avasoft\.com|zeb\.co)$/.test(budgetFormData.alertEmailId)) {
			errors.alertEmailId = "Email ID must be a valid address ending with @avasoft.com or @zeb.co";
		}

		// Validate Teams Integration URL
		if (budgetFormData.teamsIntegrationUrl && !/^https?:\/\/.+/.test(budgetFormData.teamsIntegrationUrl)) {
			errors.teamsIntegrationUrl = "Teams Integration URL must be a valid link.";
		}

		setErrorMessages(errors);
		return Object.keys(errors).length === 0; // Return true if no errors
	};
	//PS_08 
	const handleSave = async () => {
		if (!validateFields()) return;

		let payload;

		if (budgetFormData.cloudProvider === 'AWS') {
			let moduleType = "cloud"; // Default module value

			// Check if the architecture is serverless
			if (architectures === "DataOps") {
				moduleType = "Databricks"; // Change module to Databricks if serverless
			}

			payload = {
				mode: "manual",
				// module: "cloud",
				module: moduleType,
				type: editMode == true ? "edit" : "create",
				userId: userId,
				organizationId: orgId,
				envID: budgetFormData.environment ? budgetFormData.environment : envId,
				workLoadId: budgetFormData.workloadName,
				cloudProvider: budgetFormData.cloudProvider,
				budget: budgetFormData.budget,
				UniqueTag: budgetFormData.uniqueTagIdentifier,
				period: budgetFormData.resetPeriod,
				fromDate: budgetFormData.fromDate2,
				toDate: budgetFormData.toDate2,
				email: clientId,
				isComplete: "true",
				Conditions: {
					InitBudget: budgetFormData.initBudget,
					fromDate: budgetFormData.fromDate,
					toDate: budgetFormData.toDate,
					AlertEmailID: budgetFormData.alertEmailId,
					TeamsIntegration: budgetFormData.teamsIntegrationUrl,
					Threshold: budgetFormData.thresholds
				},
				ComputeType: { ...computeTypesMap } // Ensure this is structured correctly
			};
		} else if (budgetFormData.cloudProvider === 'Azure') {
			let moduleType = "cloud"; // Default module value
			const { clientId, secretId, tenantId, subcriptionId } = budgetFormData;

			// Check if the architecture is serverless
			if (architectures === "DataOps") {
				moduleType = "Databricks"; // Change module to Databricks if serverless
			}
			payload = {
				mode: "manual",
				module: moduleType,

				// module: "cloud",
				type: editMode == true ? "edit" : "create",
				userId: userId,
				organizationId: orgId,
				envID: budgetFormData.environment ? budgetFormData.environment : envId,
				workLoadId: budgetFormData.workloadName,
				cloudProvider: budgetFormData.cloudProvider,
				budget: budgetFormData.budget,
				UniqueTag: budgetFormData.uniqueTagIdentifier,
				period: budgetFormData.resetPeriod,
				fromDate: budgetFormData.fromDate2,
				toDate: budgetFormData.toDate2,
				DatabricksConfiguration: {
					tenantId: tenantId,
					subcriptionId: subcriptionId,
					secretId: secretId,
					clientId: clientId
				},
				isComplete: "true",
				Conditions: {
					InitBudget: budgetFormData.initBudget,
					fromDate: budgetFormData.fromDate,
					toDate: budgetFormData.toDate,
					AlertEmailID: budgetFormData.alertEmailId,
					TeamsIntegration: budgetFormData.teamsIntegrationUrl,
					Threshold: budgetFormData.thresholds
				},
				Workspaces: resourceGroupsData.map(group => ({
					ResourceGroup: group.ResourceGroup,
					WorkspaceName: group.WorkspaceName, // Include Workspace Name at this level
					Workspaces: group.Workspaces.map(ws => ({
						WorkspaceName: ws.WorkspaceName,
						ComputeType: ws.ComputeType // Send associated compute types
					}))
				})),

			};
			console.log("dasfs", computeTypesMap);
		}

		try {
			const response = await setThresholdApi(payload); // Call the API function
			if (response.body.success) {
				setToastMsg({ show: true, message: 'Budget set successfully', success: true });

				navigate('/thresholdGridPage')
			} else {
				setToastMsg({ show: true, message: 'An error Occured', success: false });

				console.error('Error setting threshold');
			}
		} catch (error) {
			// setToastMsg({ show: true, message:error.response.data.error, success: false });
			setToastMsg({ show: true, message: "Something went wrong", success: false });

			console.error('An error occurred while setting threshold:', error);
		}
	};
	//PS_27


	const loadResourceGroups = async (type, credsObj) => {
		let credentials;

		if (type === "edit") {
			credentials = credsObj;
		} else {
			credentials = budgetFormData;
		}

		const { clientId, secretId, tenantId, subcriptionId: subscriptionId } = credentials;

		console.log("subscriptionId", subscriptionId);

		// Check if all required fields are filled
		if (clientId && secretId && tenantId && subscriptionId) {
			const payload = {
				organizationId: orgId,
				userId: credentials.userId,
				tenantId,
				subcriptionId: subscriptionId,
				secretId,
				clientId
			};

			try {
				const response = await getResourceGroupApi(payload); // Call the API function
				if (response.body.success) {
					setResourceGroups(response.body.data);
				} else {
					console.error('Error fetching resource groups:', response.body.message);
				}
			} catch (error) {
				console.error('An error occurred while fetching resource groups:', error);
			}
		} else {
			console.error('Missing required fields for fetching resource groups');
		}
	};

	//PS_28
	const loadWorkspaceDetails = async (resourceGroupName) => {
		const { clientId, secretId, tenantId, subcriptionId } = budgetFormData;
		console.log("subcriptionid", subcriptionId);
		const payload = {
			organizationId: orgId,
			userId: userId,
			tenantId: tenantId,
			subcriptionId: subcriptionId,
			secretId: secretId,
			clientId: clientId,
			resourceGroupName,
		};
		console.log("workspaceeee", payload);

		try {
			const response = await getWorkspaceAzureApi(payload); // Call the API function
			if (response.body.success) {
				setWorkspaces2(response.body.data);
			} else {
				console.error('Error fetching workspace details');
			}
		} catch (error) {
			console.error('An error occurred while fetching workspace details:', error);
		}
	};
	//PS_29
	const loadWorkspaceDetails2 = async () => {
		const payload = {
			userId: userId,
			organizationId: orgId,
			envId: envId,
			mailId: clientId,
		};

		try {
			const response = await getWorkspaceAwsApi(payload); // Call the API function
			if (response.body.success) {
				setAwsWorkspaces(response.body.data)
			} else {
				console.error('Error fetching AWS workspace details');
			}
		} catch (error) {
			console.error('An error occurred while fetching AWS workspace details:', error);
		}
	};
	//PS_30
	const handleAddWorkspace = async () => {
		const payload = {
			organizationId: orgId,
			userId: userId,
			envId: envId,
			mailId: clientId,
			workspaceId: workspaceId,
		};

		try {
			const response = await getComputeAwsApi(payload); // Call the API function
			if (response.body.success) {
				const newComputeTypes = response.body.data; // Extracting compute types from response

				// Check if the workspace already exists
				setWorkspaces(prevWorkspaces => {
					const existingWorkspaceIndex = prevWorkspaces.findIndex(workspace => workspace.workspaceId === workspaceId);

					if (existingWorkspaceIndex !== -1) {
						// If it exists, update its compute types
						const updatedWorkspaces = [...prevWorkspaces];
						updatedWorkspaces[existingWorkspaceIndex].computeType.push(...newComputeTypes);
						return updatedWorkspaces;
					} else {
						// If it doesn't exist, create a new workspace entry
						return [
							...prevWorkspaces,
							{
								workspaceId: workspaceId,
								workspaceName: `New Workspace ${workspaceName}`, // You can customize this as needed
								computeType: newComputeTypes // Set the new compute types
							}
						];
					}
				});
			} else {
				console.error('Error fetching AWS compute details');
			}
		} catch (error) {
			console.error('An error occurred while fetching AWS compute details:', error);
		}
	};
	//PS_31
	const handleAddWorkspace2 = async () => {
		const { clientId, secretId, tenantId, subcriptionId } = budgetFormData;
		const payload = {
			userId: userId,
			organizationId: orgId,
			tenantId: tenantId,
			subcriptionId: subcriptionId,
			secretId: secretId,
			clientId: clientId,
			resourceGroupName: selectedResourceGroup,
			workspaceName: workspaceId2
		};

		try {
			const response = await getComputeAzureApi(payload); // Call the API function
			if (response.body.success) {
				const newComputeTypes = response.body.data; // Extracting compute types from response

				// Check if the workspace already exists
				setWorkspacesAzure(prevWorkspaces => {
					const existingWorkspaceIndex = prevWorkspaces.findIndex(workspace => workspace.workspaceId === workspaceId2);

					if (existingWorkspaceIndex !== -1) {
						// If it exists, update its compute types
						const updatedWorkspaces = [...prevWorkspaces];
						updatedWorkspaces[existingWorkspaceIndex].computeType.push(...newComputeTypes);
						return updatedWorkspaces;
					} else {
						// If it doesn't exist, create a new workspace entry
						return [
							...prevWorkspaces,
							{
								workspaceId: workspaceId2,
								workspaceName: `New Workspace ${workspaceId2}`, // You can customize this as needed
								computeType: newComputeTypes // Set the new compute types
							}
						];
					}
				});
			} else {
				console.error('Error fetching Azure compute details');
			}
		} catch (error) {
			console.error('An error occurred while fetching Azure compute details:', error);
		}
	};
	const [newThresholds, setNewThresholds] = useState([]);
	const [remainingBudget, setRemainingBudget] = useState(0);
	//PS_14
	const addThresholdRow = () => {
		if (newThresholds.length < 5) {
			const updatedThresholds = [...newThresholds];
			setBudgetFormData((prevData) => ({
				...prevData,
				thresholds: updatedThresholds,
			}));
			const totalAmount = calculateTotalAmount(updatedThresholds);
			setRemainingBudget(budgetFormData.budget - totalAmount);
		} else {
			console.log('Maximum of 5 thresholds allowed.');
		}
	};

	//PS_14 Handle threshold change
	const handleThresholdChange = (index, fieldName, value) => {
		const updatedThresholds = [...newThresholds];

		// Create a new object if it doesn't exist
		if (!updatedThresholds[index]) {
			updatedThresholds[index] = {};
		}

		// Update percentage
		if (fieldName === 'percentage') {
			updatedThresholds[index][fieldName] = value;
			// Calculate amount based on initial budget
			if (budgetFormData.budget !== '') {
				const amount = (parseFloat(value) / 100) * parseFloat(budgetFormData.budget);
				updatedThresholds[index].amount = isNaN(amount) ? '' : amount.toFixed(2); // Format to 2 decimal places
			} else {
				updatedThresholds[index].amount = '';
			}
		} else {
			updatedThresholds[index][fieldName] = value;
		}

		// Remove percentage property when threshold is deleted
		if (value === '') {
			delete updatedThresholds[index].percentage;
			delete updatedThresholds[index].amount;
		}

		setNewThresholds(updatedThresholds);
	};
	//PS_16
	const calculateTotalAmount = (thresholds) => {
		return thresholds.reduce((acc, curr) => acc + parseFloat(curr.amount || 0), 0);
	};
	//PS_17
	const disableAddThreshold = () => {
		return newThresholds.length >= 5 || newThresholds.some((threshold) => !threshold.percentage);
	};
	//PS_18
	const deleteThresholdRow = (index) => {
		// Create a new array without the deleted threshold
		const updatedThresholds = newThresholds.filter((_, i) => i !== index);

		// Update the newThresholds state
		setNewThresholds(updatedThresholds);

		// Update budgetFormData with the new thresholds
		setBudgetFormData((prevData) => ({
			...prevData,
			thresholds: updatedThresholds,
		}));

		// Recalculate remaining budget
		const totalAmount = calculateTotalAmount(updatedThresholds);
		setRemainingBudget(budgetFormData.budget - totalAmount);

		// Reset newThresholds if all thresholds are deleted
		if (updatedThresholds.length === 0) {
			setNewThresholds([]);
		}
	};

	const [isCloudProviderDisabled, setIsCloudProviderDisabled] = useState(false);
	const handleWorkloadChange = (e) => {
		const selectedWorkloadId = e.target.value;
		handleInputChange(e, 'workloadName'); // Update workloadName in budgetFormData
		loadEnvironments(selectedWorkloadId); // Load environments

		// Find the selected workload to get its architecture name and provider ID
		const selectedWorkload = workloads?.workload?.find(workload => workload.workloadId === selectedWorkloadId);

		if (selectedWorkload) {
			setarchitectures(selectedWorkload.architectureName); // Set architecture name
			console.log("Selected Architecture Name:", selectedWorkload.architectureName);

			// Update budgetFormData with the provider ID and disable Cloud Provider dropdown
			setBudgetFormData(prevData => ({
				...prevData,
				cloudProvider: selectedWorkload.providerName // Set cloud provider based on workload
			}));
			setIsCloudProviderDisabled(true); // Disable Cloud Provider dropdown
		} else {
			setarchitectures(''); // Clear architecture name if no workload is found
			setIsCloudProviderDisabled(false); // Enable Cloud Provider dropdown if no workload is selected
		}
	};
	//PS_10
	const loadWorkloads = async () => {
		const payload = {
			userId: userId,
			orgId: orgId,
			orgIdGeneral: "",
			connectorId: "",
			type: "budget",
		};

		try {
			const response = await getAllWorkloadByOrgIdApi(payload); // Call the API function
			console.log(response, "GetAll workloadssby id")
			if (response.success) {
				setWorkloads(response.data); // Update state with fetched workloads
			} else {
				console.error('Error fetching workloads');
			}
		} catch (error) {
			console.error('An error occurred while fetching workloads:', error);
		}
	};
	//PS_11
	const loadEnvironments = async (workloadId) => {
		if (workloadId) {
			const payload = {
				userId: userId,
				orgId: orgId,
				workloadId: workloadId,
				type: "budget",
			};

			try {
				const response = await getAllEnvByWorkloadIdApi(payload); // Call the API function
				if (response.success) {
					console.log('Environments fetched successfully:', response.data);
					setEnvironments(response.data); // Store in setEnvironments2 if event exists

				} else {
					console.error('Error fetching environments');
				}
			} catch (error) {
				console.error('An error occurred while fetching environments:', error);
			}
		}
	};

	const [clientId, setClientId] = useState('');
	const [workspaceId, setWorkspaceId] = useState('');
	const [workspaceId2, setWorkspaceId2] = useState('');
	const [workspaces, setWorkspaces] = useState([]);
	const [workspacesAzure, setWorkspacesAzure] = useState([]);

	const [workspaces2, setWorkspaces2] = useState([]);

	const [workspaceName, setWorkspaceName] = useState('');
	const navigate = useNavigate();

	//PS_12 Handle input change for Workspace ID
	const handleInputChange2 = (setter) => (event) => {
		const selectedId = event.target.value;
		setter(selectedId); // Set the workspace ID
		if (errorMessage) {
			setErrorMessage(''); // Remove validation messages
		}
		// Find the selected workspace name based on the selected ID
		const selectedWorkspace = awsWorkspaces.find(workspace => workspace.workspaceId === selectedId);
		if (selectedWorkspace) {
			setWorkspaceName(selectedWorkspace.workspaceName); // Set the workspace name
		} else {
			setWorkspaceName(''); // Reset if no match found
		}
	};
	const today = new Date();
	const minDate = new Date(today);
	// Uncomment if you want to set a minimum date (e.g., 6 months ago)
	minDate.setMonth(today.getMonth() - 6);
	const maxDate = new Date();
	maxDate.setFullYear(maxDate.getFullYear() + 1); // Set max date to one year from today

	// Format dates to YYYY-MM-DD for input value
	const formatDate = (date) => {
		return date.toISOString().split('T')[0];
	};

	const handleInputChange = (event, fieldName) => {
		console.log("in");
		if (!event.target) return;
		setBudgetFormData((prevData) => ({
			...prevData,
			[fieldName]: event.target.value,
		}));


		if (fieldName === 'toDate') {
			// Check if 'From' date is set
			if (!budgetFormData.fromDate) {
				console.error("Please select a 'From' date before selecting a 'To' date.");
				return; // Prevent updating 'To' date if 'From' is not set
			}

			const toDateValue = new Date(event.target.value);
			const fromDateValue = new Date(budgetFormData.fromDate);

			// Check if the 'To' date is earlier than the 'From' date
			if (toDateValue < fromDateValue) {
				console.error("The 'To' date cannot be earlier than the 'From' date.");
				return; // Prevent updating state if invalid
			}
		}

		// Handle 'toDate2' validation
		if (fieldName === 'toDate2') {
			// Check if 'From' date2 is set
			if (!budgetFormData.fromDate2) {
				console.error("Please select a 'From' date before selecting a 'To' date.");
				return; // Prevent updating 'To' date2 if 'From' is not set
			}

			const toDate2Value = new Date(event.target.value);
			const fromDate2Value = new Date(budgetFormData.fromDate2);

			// Check if the 'To' date2 is earlier than the 'From' date2
			if (toDate2Value < fromDate2Value) {
				console.error("The 'To' date cannot be earlier than the 'From' date.");
				return; // Prevent updating state if invalid
			}
		}

		if (fieldName === 'environment') {
			const selectedEnv = environments.find(env => env.envId === event.target.value);
			if (selectedEnv) {
				setBudgetFormData((prevData) => ({
					...prevData,
					uniqueTagIdentifier: selectedEnv.uniqueId, // Set uniqueId based on selected environment
				}));
			} else {
				setBudgetFormData((prevData) => ({
					...prevData,
					uniqueTagIdentifier: '', // Clear if no environment is selected
				}));
			}
		}
		if (fieldName === "budget") {
			const percentage = (event.target.value / budgetFormData.budget) * 100;
			setNewThresholds([{ percentage: percentage.toFixed(2) }]); // Set newThresholds with the percentage value
		}
		setErrorMessages(prevErrors => ({
			...prevErrors,
			[fieldName]: '' // Clear the error message for this field
		}));

	};
	console.log("bformdata", budgetFormData);
	// Handle Sync button click

	//PS_19
	const handleSync = () => {
		// console.log('Syncing with Client ID:', clientId);

		// loadWorkspaceDetails2()
		handleSetThreshold()
		// Implement your sync logic here
	};
	//PS_20
	const handleSetThreshold = async () => {
		const payload = {
			mode: "manual",
			module: "Databricks",
			type: editMode ? "update" : "create",
			userId: userId,
			organizationId: orgId,
			envID: envId,
			workLoadId: budgetFormData.workloadName,
			cloudProvider: budgetFormData.cloudProvider,
			budget: budgetFormData.budget,
			UniqueTag: budgetFormData.uniqueTagIdentifier,
			period: budgetFormData.resetPeriod,
			fromDate: budgetFormData.fromDate,
			toDate: budgetFormData.toDate,
			email: clientId,
			isComplete: "false", // Convert boolean to string if necessary
		};

		try {
			console.log(payload, "setThresholdApi")
			const response = await setThresholdApi(payload); // Call the API function
			if (response.body.success) {
				await loadWorkspaceDetails2()
				console.log('Threshold set successfully:', response.data);
				console.log('Threshold has been set successfully!');
			} else {
				console.error('Error setting threshold');
				if (response.body.message) { // Check for specific error message
					setErrorMessage(response.body.message); // Set the error message to display
					console.error('Error setting threshold2', response.body.message);

				} else {
					console.log('Error setting threshold: Unknown error');
					setErrorMessage('An unknown error occurred while setting the threshold.');
				}
			}
		} catch (error) {
			setErrorMessage('An error occurred while setting the threshold.');
			console.error('An error occurred while setting threshold:', error);
			console.log('An error occurred while setting threshold');
		}
	};



	const [computeTypesMap, setComputeTypesMap] = useState({}); // Separate state for editable compute types
	const [newComputeData, setNewComputeData] = useState({ computeType: '', dbuCommitment: '' });
	const [editingIndexMap, setEditingIndexMap] = useState({}); // Track editing indices for each workspace

	//PS_21
	const addComputeTypeRow = (workspaceId) => {
		// Initialize the workspace entry if it doesn't exist
		if (!computeTypesMap[workspaceId]) {
			setComputeTypesMap(prev => ({ ...prev, [workspaceId]: [] }));
		}

		// Only add if both 	 are filled
		if (newComputeData.computeType && newComputeData.dbuCommitment) {
			setComputeTypesMap(prev => ({
				...prev,
				[workspaceId]: [...(prev[workspaceId] || []), { ...newComputeData }]
			}));
			setNewComputeData({ computeType: '', dbuCommitment: '' }); // Reset the input fields
		}
	};
	//PS_22
	const handleThresholdChange2 = (workspaceId, index, fieldName, value) => {
		setComputeTypesMap(prev => ({
			...prev,
			[workspaceId]: prev[workspaceId].map((ct, i) =>
				i === index ? { ...ct, [fieldName]: value } : ct
			)
		}));
	};
	//PS_23
	const deleteComputeTypeRow = (workspaceId, index) => {
		setComputeTypesMap(prev => ({
			...prev,
			[workspaceId]: prev[workspaceId].filter((_, i) => i !== index)
		}));
	};

	// Function to get selected compute types for filtering
	const getSelectedComputeTypesForWorkspace = (workspaceId) => {
		return (computeTypesMap[workspaceId] || []).map(ct => ct.computeType);
	};

	const [computeTypesMap2, setComputeTypesMap2] = useState({}); // Separate state for editable compute types
	const [newComputeData2, setNewComputeData2] = useState({ computeType: '', dbuCommitment: '' });
	const [editingIndexMap2, setEditingIndexMap2] = useState({}); // Track editing indices for each workspace

	const getSelectedComputeTypes = () => {
		let selectedTypes = [];

		// Collect selected compute types from Azure workspaces
		workspacesAzure.forEach(ws => {
			const selectedComputeTypes = (computeTypesMap2[ws.workspaceId] || []).map(ct => ct.computeType);
			selectedTypes = [...selectedTypes, ...selectedComputeTypes];
		});

		// Collect selected compute types from resource groups
		resourceGroupsData.forEach(group => {
			if (group.ResourceGroup === selectedResourceGroup) {
				group.Workspaces.forEach(ws => {
					const selectedComputeTypes = (ws.ComputeType || []).map(ct => ct.computeType);
					selectedTypes = [...selectedTypes, ...selectedComputeTypes];
				});
			}
		});

		return selectedTypes;
	};

	const selectedComputeTypes = getSelectedComputeTypes();
	//PS_24
	const addComputeTypeRow2 = (workspaceName) => {
		const newComputeType = { ...newComputeData2 }; // Assuming newComputeData2 holds the new compute type data

		// Update computeTypesMap2 with the new compute type for the specific workspace
		setComputeTypesMap2(prev => ({
			...prev,
			[workspaceName]: [...(prev[workspaceName] || []), newComputeType]
		}));

		// Update resourceGroupsData to add the new compute type
		setResourceGroupsData(prev => {
			return prev.map(group => {
				if (group.ResourceGroup === selectedResourceGroup) {
					return {
						...group,
						Workspaces: group.Workspaces.map(ws => {
							if (ws.WorkspaceName === workspaceName) {
								return {
									...ws,
									ComputeType: [...ws.ComputeType, newComputeType] // Add new compute type to this workspace
								};
							}
							return ws;
						})
					};
				}
				return group;
			});
		});

		// Reset input fields after adding
		setNewComputeData2({ computeType: '', dbuCommitment: '' }); // Reset input fields
	};
	//PS_25
	const handleThresholdChange3 = (resourceGroup, workspaceName, index, fieldName, value) => {
		setResourceGroupsData(prev => {
			return prev.map(group => {
				if (group.ResourceGroup === resourceGroup) {
					return {
						...group,
						Workspaces: group.Workspaces.map(ws => {
							if (ws.WorkspaceName === workspaceName) {
								return {
									...ws,
									ComputeType: ws.ComputeType.map((ct, i) =>
										i === index ? { ...ct, [fieldName]: value } : ct
									)
								};
							}
							return ws;
						})
					};
				}
				return group;
			});
		});
	};
	//PS_26
	const deleteComputeTypeRow2 = (resourceGroup, workspaceName, index) => {
		setResourceGroupsData(prev => {
			return prev.map(group => {
				if (group.ResourceGroup === resourceGroup) {
					return {
						...group,
						Workspaces: group.Workspaces.map(ws => {
							if (ws.WorkspaceName === workspaceName) {
								return {
									...ws,
									ComputeType: ws.ComputeType.filter((_, i) => i !== index) // Remove the compute type at the specified index
								};
							}
							return ws;
						})
					};
				}
				return group;
			});
		});

		// Also remove from workspacesAzure if necessary
		setWorkspacesAzure(prevWorkspaces =>
			prevWorkspaces.map(ws => {
				if (ws.workspaceId === workspaceName) {
					return {
						...ws,
						ComputeType: ws.ComputeType?.filter((_, i) => i !== index) // Ensure the same compute type is removed
					};
				}
				return ws;
			})
		);
	};
	const handleResourceGroupChange = async (e) => {
		const resourceGroupId = e.target.value;
		setSelectedResourceGroup(resourceGroupId);
		await loadWorkspaceDetails(resourceGroupId);
	};


	const isNonEmptyString = (value) => typeof value === 'string' && value.trim() !== '';
	const isNonUndefined = (value) => value !== undefined && value !== null;

	const isSaveEnabled =
		isNonEmptyString(budgetFormData.alertEmailId) &&
		isNonEmptyString(budgetFormData.teamsIntegrationUrl) &&
		isNonEmptyString(budgetFormData.workloadName) &&
		isNonEmptyString(budgetFormData.cloudProvider) &&
		isNonEmptyString(budgetFormData.environment) &&
		isNonUndefined(budgetFormData.budget) &&
		isNonEmptyString(budgetFormData.resetPeriod) &&
		(budgetFormData.resetPeriod !== 'Custom' || (
			isNonEmptyString(budgetFormData.fromDate2) &&
			isNonEmptyString(budgetFormData.toDate2)
		));

	return (
		<>
			{toastMsg.show && (
				<ToastPopup
					toastMsg={toastMsg}
					setToastMsg={setToastMsg}
				/>
			)}
			<div class="container-fluid">
				<div class="">

					<div class="row">
						<SideBar />
						<div className="col-md-9 px-4 pt-4 right-container-scroll">
							<div className="row">
								<div className="col-md-12 mb-4 pb-2">
									<div className="d-flex align-items-center gap-3">
										<button type="button" className="button custom-outline-button-v2 py-2 px-3 font-12 font-medium black-v2" onClick={() => navigate('/thresholdGridPage')} >Back to Budget</button>
										<p className="font-20 font-bold black-v2 mb-0">{editMode == true ? "Edit Budget" : "Add Budget"}</p>
									</div>
								</div>

								<div className="col-md-12 mt-2 mb-3">
									<div className="row add-organization-form">
										<div className="col-md-12 mb-4 px-3">
											<p className="mb-0 font-18 font-bold black-v2">Workload Details</p>
										</div>

										{/* Workload Name Dropdown */}
										<div className="col-md-4 mb-4 pb-3 px-3">
											<label className="form-label font-14 font-medium" htmlFor="WorkloadName">Workload Name<span className="required">*</span></label>
											<select
												id="WorkloadName"
												className="form-select form-control custom-form"
												value={budgetFormData.workloadName}
												onChange={(e) => {
													handleWorkloadChange(e);
													loadEnvironments(e.target.value)
												}}
											>
												<option value="">Select</option>
												{workloads?.workload?.map(workload => (
													<option key={workload.workloadId}
														value={workload.workloadId}>
														{workload.workloadName}
													</option>
												))}
											</select>
										</div>

										{/* Cloud Provider Dropdown */}
										<div className="col-md-4 mb-4 pb-3 px-3">
											<label className="form-label font-14 font-medium" htmlFor="cloudProvider">
												Cloud Provider<span className="required">*</span>
											</label>
											<select
												id="cloudProvider"
												className="form-select form-control custom-form"
												value={budgetFormData.cloudProvider}
												onChange={(e) => handleInputChange(e, 'cloudProvider')} // Pass event first, then field name
												disabled={isCloudProviderDisabled}
											>
												<option value="">Select</option> {/* Make sure to set value to an empty string */}
												{providers.map(provider => (
													<option key={provider.id} value={provider.name}>{provider.name}</option>
												))}
											</select>
										</div>
										{/* Environment Dropdown */}
										<div className="col-md-4 mb-4 pb-3 px-3">
											<label className="form-label font-14 font-medium" htmlFor="Environment">
												Environment<span className="required">*</span>
											</label>
											<select
												id="Environment"
												className="form-select form-control custom-form"
												value={budgetFormData.environment}
												onChange={(e) => { handleInputChange(e, 'environment'); setEnvId(e.target.value) }}
											>
												<option value="">Select</option>
												{environments.map(env => (
													<option key={env.envId} value={env.envId}>{env.envName}</option>
												))}
											</select>
										</div>

										{/* Budget Input */}
										{/* <div className="col-md-4 position-relative mb-4 pb-3 px-3">
											<label className="form-label font-14 font-medium" htmlFor="Budget">
												Budget<span className="required">*</span>
											</label>
											<input
												type="number"
												id="Budget"
												className="form-control custom-form2"
												placeholder="Enter Enter Budget Amount"
												value={budgetFormData.budget}
												onChange={(e) => handleInputChange(e, 'budget')}
												style={{ padding: '3%' }}
											/>

											<img src="images/revenue-icon.svg" alt="Budget-icon" className="position-absolute custom-revenue-icon2 light-theme-img" style={{ marginTop: '-12%', width: '12%' }} />
											<img src="images/revenue-icon-dark.svg" alt="Budget-icon" className="position-absolute custom-revenue-icon2 dark-theme-img" style={{ marginTop: '-12%', width: '12%' }} />
										</div> */}



{/* Budget Input */}
<div className="col-md-4 position-relative mb-4 pb-3 px-3">
											<label className="form-label font-14 font-medium" htmlFor="Budget">
												Budget<span className="required">*</span>
											</label>            <input 
                type="text" 
                id="revenue" 
                name="revenue"
                className={`form-control22 custom-form`} 
                placeholder="Enter Revenue" 
				value={budgetFormData.budget}
				onChange={(e) => handleInputChange(e, 'budget')}
                style={{ paddingLeft: '49px' }} // Adjust padding to make space for the icon
            />
            <img 
                src="images/revenue-icon.svg" 
                alt="Revenue-icon" 
                className="revenue-icon light-theme-img position-absolute" 
                style={{ width: '44px', left: '18px', top: '60%', transform: 'translateY(-50%)' }} 
            />
            <img 
                src="images/revenue-icon-dark.svg" 
                alt="Revenue-icon" 
                className="revenue-icon dark-theme-img position-absolute" 
                style={{ width: '44px', left: '18px', top: '60%', transform: 'translateY(-50%)' }} 
            />
        </div>
										

										{/* Unique Tag Identifier */}
										<div className="col-md-4 mb-4 pb-3 px-3">
											<label className="form-label font-14 font-medium" htmlFor="UniqueTagIdentifier">
												Unique Tag Identifier<span className="required">*</span>
											</label>
											<input
												type="text"
												id="UniqueTagIdentifier"
												className="form-control custom-form"
												placeholder="Unique Tag Identifier"
												value={budgetFormData.uniqueTagIdentifier}
												readOnly
											/>
										</div>

										{/* Reset Period Dropdown */}

										<div className="col-md-4 mb-4 pb-3 px-3">
											<label className="form-label font-14 font-medium" htmlFor="resetPeriod">
												Reset Period<span className="required">*</span>
											</label>
											<select
												id="resetPeriod"
												className="form-select form-control custom-form"
												value={
													budgetFormData.resetPeriod
														? budgetFormData.resetPeriod.toLowerCase() === "custom"
															? "Custom"
															: budgetFormData.resetPeriod.charAt(0).toUpperCase() + budgetFormData.resetPeriod.slice(1).toLowerCase()
														: ""
												}
												onChange={(e) => handleInputChange(e, 'resetPeriod')}
											>
												<option value="">Select</option>
												<option value="Monthly">Monthly</option>
												<option value="Yearly">Yearly</option>
												<option value="Quarterly">Quarterly</option>
												<option value="Custom">Custom</option>
											</select>
										</div>

										{(budgetFormData.resetPeriod === "Custom" || budgetFormData.resetPeriod === "custom") && (
											<>
												<div className="col-md-4 mb-4 pb-3 px-3">
													<label className='form-label font-14 font-medium' htmlFor='FromDate2'>
														From <span className='required'>*</span>
													</label>
													<input
														type='date'
														id='FromDate2'
														className='form-control custom-form'
														value={budgetFormData.fromDate2 ? new Date(budgetFormData.fromDate2).toISOString().split('T')[0] : ''}
														onChange={(e) => handleInputChange(e, 'fromDate2')}
														min={formatDate(minDate)} // Set minimum date
														max={formatDate(maxDate)} // Set maximum date
													/>
												</div>

												{/* To Date Picker 2 */}
												<div className='col-md-4 mb-4 pb-3 px-3'>
													<label className='form-label font-14 font-medium' htmlFor='ToDate2'>
														To <span className='required'>*</span>
													</label>
													<input
														type='date'
														id='ToDate2'
														className='form-control custom-form'
														value={budgetFormData.toDate2 ? new Date(budgetFormData.toDate2).toISOString().split('T')[0] : ''}
														onChange={(e) => handleInputChange(e, 'toDate2')}
														disabled={!budgetFormData.fromDate2} // Disable To input until From 2 is selected
														min={formatDate(minDate)} // Set minimum date
														max={formatDate(maxDate)} // Set maximum date
													/>
												</div>

											</>
										)}

										{/* Databricks Configuration Section */}
										{/* Azure Fields */}

										{((budgetFormData.cloudProvider === "Azure" && architectures === "DataOps") ||
											(budgetFormData.clientId && editMode === true && budgetFormData.cloudProvider === "Azure")) ? (
											<>
												<div className='col-md-12 mb-4 px-3'>
													<p className='mb-0 font-18 font-bold black-v2'>Databricks Configuration - Azure</p>
												</div>

												<div className='col-md-4 mb-4 pb-3 px-3'>
													<label htmlFor='ClientID' className='form-label font-14 font-medium'>Client ID</label>
													<input
														type='text'
														id='ClientID'
														placeholder='Enter Client ID'
														value={budgetFormData.clientId}
														onChange={(e) => handleInputChange(e, 'clientId')}
														onBlur={loadResourceGroups}
														className='form-control custom-form'
													/>
												</div>

												<div className='col-md-4 mb-4 pb-3 px-3'>
													<label htmlFor='SecretID' className='form-label font-14 font-medium'>Secret</label>
													<input
														type='text'
														id='SecretID'
														placeholder='Enter Secret'
														value={budgetFormData.secretId}
														onChange={(e) => handleInputChange(e, 'secretId')}
														onBlur={loadResourceGroups}
														className='form-control custom-form'
													/>
												</div>

												<div className='col-md-4 mb-4 pb-3 px-3'>
													<label htmlFor='TenantID' className='form-label font-14 font-medium'>Tenant ID</label>
													<input
														type='text'
														id='TenantID'
														placeholder='Enter Tenant ID'
														value={budgetFormData.tenantId}
														onChange={(e) => handleInputChange(e, 'tenantId')}
														onBlur={loadResourceGroups}
														className='form-control custom-form'
													/>
												</div>

												<div className='col-md-4 mb-4 pb-3 px-3'>
													<label htmlFor='SubscriptionID' className='form-label font-14 font-medium'>Subscription ID</label>
													<input
														type='text'
														id='SubscriptionID'
														placeholder='Enter Subscription ID'
														value={budgetFormData.subcriptionId}
														onChange={(e) => handleInputChange(e, 'subcriptionId')}
														onBlur={loadResourceGroups}
														className='form-control custom-form'
													/>
												</div>
											</>
										) : ((budgetFormData.cloudProvider === "AWS" && architectures === "DataOps") ||
											(budgetFormData.clientId && editMode === true
												&& budgetFormData.cloudProvider === "AWS")) ? (
											<>
												<div className="col-md-12 mb-4 px-3">
													<p className="mb-0 font-18 font-bold black-v2">Databricks Configuration</p>
												</div>

												<div className="col-md-4 mb-4 pb-3 px-3">
													<label className="form-label font-14 font-medium" htmlFor="Client-ID-new">Email ID</label>
													<input
														type="text"
														id="Client-ID-new"
														className="form-control custom-form"
														placeholder="Enter Email ID"
														value={clientId}
														onChange={(e) => handleInputChange2(setClientId)(e)}
													/>
													{errorMessage && <div className="invalid-feedback d-block">{errorMessage}</div>}
												</div>

												<div className="col-md-4 mb-4 pt-2 px-3 mt-1">
													<button
														className="outline-gradient-btn lh-1 font-14 font-medium primary-color mt-4"
														onClick={handleSync}
													>
														Sync
													</button>
												</div>
											</>
										) : null}


										<div className="col-md-12 mb-4 px-3">
											<p className="mb-0 font-18 font-bold black-v2">Workspace</p>
										</div>
										{((budgetFormData.cloudProvider === "AWS" && architectures === "DataOps") ||
											(budgetFormData.clientId && editMode === true && budgetFormData.cloudProvider === "AWS")) ? (
											<>
												<div className="col-md-4 mb-4 px-3">
													<label className="form-label font-14 font-medium" htmlFor="WorkspaceID">Workspace ID</label>
													<select
														id="WorkspaceID"
														className="form-select custom-form"
														value={workspaceId}
														onChange={handleInputChange2(setWorkspaceId)}
													>
														<option value="">Select Workspace ID</option>
														{awsWorkspaces.map(workspace => (
															<option key={workspace.workspaceId} value={workspace.workspaceId}>
																{workspace.workspaceName}
															</option>
														))}
													</select>
												</div>

												<div className="col-md-4 mb-4 pt-2 px-3 mt-1">
													<button
														className="outline-gradient-btn lh-1 font-14 font-medium primary-color mt-4"
														onClick={handleAddWorkspace}
													>
														Add Workspace
													</button>
												</div>
											</>
										) : ((budgetFormData.cloudProvider === "Azure" && architectures === "DataOps") ||
											(budgetFormData.clientId && editMode === true && budgetFormData.cloudProvider === "Azure")) ? (
											<>
												<div className="col-md-4 mb-4 px-3">
													<label className="form-label font-14 font-medium" htmlFor="WorkspaceID">Resource Group </label>
													<select
														id="ResourceGroupID"
														className="form-select custom-form"
														value={selectedResourceGroup}
														onChange={(e) => {
															handleResourceGroupChange(e)
														}}

													>
														<option value="">Select Resource Group</option>
														{resourceGroups.map((group) => (
															<option key={group.resourceGroupId} value={group.resourceGroupId}>
																{group.resourceGroupName}
															</option>
														))}
													</select>
												</div>
												<div className="col-md-4 mb-4 px-3">
													<label className="form-label font-14 font-medium" htmlFor="WorkspaceID">Workspace Name</label>
													<select
														id="WorkspaceID"
														className="form-select custom-form"
														value={workspaceId2}
														onChange={(e) => {
															const workspaceId = e.target.value;
															setWorkspaceId2(e.target.value)
															const selectedWorkspace = workspaces2.find(ws => ws.workspaceId === workspaceId);

															if (selectedWorkspace) {
																setResourceGroupsData(prev => {
																	const existingGroupIndex = prev.findIndex(group => group.ResourceGroup === selectedResourceGroup);

																	if (existingGroupIndex !== -1) {
																		const existingWorkspaceIndex = prev[existingGroupIndex].Workspaces.findIndex(ws => ws.WorkspaceName === selectedWorkspace.workspaceName);

																		if (existingWorkspaceIndex !== -1) {
																			return prev;
																		} else {
																			const newWorkspace = {
																				WorkspaceName: selectedWorkspace.workspaceName,
																				ComputeType: []
																			};

																			const updatedGroup = {
																				...prev[existingGroupIndex],
																				Workspaces: [
																					...prev[existingGroupIndex].Workspaces,
																					newWorkspace,
																				]
																			};

																			return [
																				...prev.slice(0, existingGroupIndex),
																				updatedGroup,
																				...prev.slice(existingGroupIndex + 1)
																			];
																		}
																	} else {
																		return [
																			...prev,
																			{
																				ResourceGroup: selectedResourceGroup,
																				WorkspaceName: selectedWorkspace.workspaceName,
																				Workspaces: [{
																					WorkspaceName: selectedWorkspace.workspaceName,
																					ComputeType: []
																				}]
																			}
																		];
																	}
																});
															}
														}}
													>
														<option value="">Select Workspace</option>
														{workspaces2.map((workspace) => (
															<option key={workspace.workspaceId} value={workspace.workspaceId}>
																{workspace.workspaceName}
															</option>
														))}
													</select>
												</div>
												{/* Add Workspace Button for Azure */}
												<div className="col-md-4 mb-4 pt-2 px-3 mt-1">
													<button
														className="outline-gradient-btn lh-1 font-14 font-medium primary-color mt-4"
														onClick={handleAddWorkspace2}
													>
														Add Workspace
													</button>
												</div>
											</>
										) : null}

										{/* Display added workspaces */}

									</div>


									<div className="col-md-12 px-3 mb-4 pb-3">
										<div className="accordion" id="accordionExample1">
											{/* Workspace Accordion */}
											{budgetFormData.cloudProvider === "AWS" && workspaces.map((ws, index) => (
												<div className="accordion-item border-0 custom-white" key={ws.workspaceId}>
													<div className="template-container mt-2 py-3 ps-3">
														<div className="accordion-header d-flex align-items-center" id={`sub-heading-${index}`}>
															<button
																className="cust-acc-btn collapsed d-flex align-items-center gap-2 font-14 btn border-0 me-3"
																type="button"
																data-bs-toggle="collapse"
																data-bs-target={`#collapse-notification-${index}`}
																aria-expanded="false"
																aria-controls={`collapse-notification-${index}`}
															>
																<span>
																	<p className="mb-0 font-18 font-bold black-v2">{ws.workspaceName}</p>
																</span>
															</button>
														</div>
														<div id={`collapse-notification-${index}`} className="accordion-collapse collapse" aria-labelledby={`sub-heading-${index}`} data-bs-parent="#accordionExample1">
															<div className="accordion-body pb-2">
																<div className="table-responsive table-border">
																	<table className="table custom-table">
																		<thead>
																			<tr>
																				<th className="text-start border-0">Compute ID</th>
																				<th className="text-start border-0">DBU Commitments</th>
																				<th className="text-center border-0">Action</th>
																			</tr>
																		</thead>
																		<tbody>
																			{/* Row to add a new compute type */}
																			<tr>
																				<td className="text-start align-middle">
																					<select
																						value={newComputeData.computeType}
																						onChange={(e) => setNewComputeData({ ...newComputeData, computeType: e.target.value })}
																						className="form-select form-control custom-form"
																					>
																						<option value="">Select Compute Type</option>
																						{ws.computeType.filter(ct => !getSelectedComputeTypesForWorkspace(ws.workspaceId).includes(ct.computeId)).map(ct => (
																							<option key={ct.computeId} value={ct.computeId}>
																								{ct.computeName}
																							</option>
																						))}
																					</select>
																				</td>
																				<td className="text-start align-middle">
																					<input
																						type="number"
																						value={newComputeData.dbuCommitment}
																						onChange={(e) => setNewComputeData({ ...newComputeData, dbuCommitment: e.target.value })}
																						className="form-control custom-form"
																						placeholder="Enter DBU Commitments"
																					/>
																				</td>
																				<td className="text-center">
																					<div className="d-flex justify-content-center gap-3">
																						<button
																							type="button"
																							onClick={() => addComputeTypeRow(ws.workspaceName)}
																							className={`btn action-btn d-flex align-items-center justify-content-center border-0`}
																						>
																							<img src="images/add-plus-icon.svg" alt="add-action-icon" />
																						</button>
																					</div>
																				</td>
																			</tr>

																			{/* Existing Compute Types */}
																			{(computeTypesMap[ws.workspaceName] || []).map((ct, index) => {
																				const computeTypeName = ws.computeType.find(availableCt => availableCt.computeId === ct.computeType)?.computeName;

																				// Only render the row if computeTypeName exists
																				if (!computeTypeName) return null; // Skip rendering this row if no compute type name is found

																				return (
																					<tr key={index}>
																						<td className="text-start align-middle">
																							{editingIndexMap[ws.workspaceName] === index ? (
																								<select
																									value={ct.computeType}
																									onChange={(e) => handleThresholdChange2(ws.workspaceName, index, 'computeType', e.target.value)}
																									className='form-select form-control custom-form'
																									onBlur={() => setEditingIndexMap(prev => ({ ...prev, [ws.workspaceName]: -1 }))} // Reset editing index on blur
																								>
																									{ws.computeType.filter(availableCt =>
																										!getSelectedComputeTypesForWorkspace(ws.workspaceName).includes(availableCt.computeId) ||
																										availableCt.computeId === ct.computeType // Keep the currently selected type in the dropdown
																									).map(availableCt => (
																										<option key={availableCt.computeId} value={availableCt.computeId}>
																											{availableCt.computeName}
																										</option>
																									))}
																								</select>
																							) : (
																								<span>{computeTypeName}</span> // Display the compute type name
																							)}
																						</td>
																						<td className="text-start align-middle">
																							{editingIndexMap[ws.workspaceName] === index ? (
																								<input
																									type='text'
																									value={ct.dbuCommitment}
																									onChange={(e) => handleThresholdChange2(ws.workspaceName, index, 'dbuCommitment', e.target.value)}
																									className='form-control custom-form'
																									onBlur={() => setEditingIndexMap(prev => ({ ...prev, [ws.workspaceName]: -1 }))} // Reset editing index on blur
																								/>
																							) : (
																								<span>{ct.dbuCommitment}</span> // Display DBU commitments
																							)}
																						</td>
																						<td className="text-center align-middle">
																							{/* Actions */}
																							<div className="d-flex justify-content-center gap-3">
																								{editingIndexMap[ws.workspaceName] === index ? (
																									<>
																										{/* Save Action */}
																										<a href="#" onClick={() => setEditingIndexMap(prev => ({ ...prev, [ws.workspaceName]: -1 }))}>
																											<img src="/images/tick-large-icon.svg" alt="tick-icon" width={16} height={16}></img>
																										</a>
																										<a href="#" onClick={() => deleteComputeTypeRow(ws.workspaceName, index)}>
																											<img src="/images/cross-large-icon.svg" alt="cross-icon" width={10} height={10}></img>
																										</a>
																									</>
																								) : (
																									<>
																										{/* Edit Action */}
																										<a href="#" onClick={() => setEditingIndexMap(prev => ({ ...prev, [ws.workspaceName]: index }))}>
																											<img src="/images/edit-icon.svg" alt="edit-icon" />
																										</a>
																										<a href="#" onClick={() => deleteComputeTypeRow(ws.workspaceName, index)}>
																											<img src="/images/delete-icon.svg" alt="delete-icon" />
																										</a>
																									</>
																								)}
																							</div>
																						</td>
																					</tr>
																				);
																			})}
																		</tbody>
																	</table>

																</div> {/* End of table-responsive */}
															</div> {/* End of accordion body */}
														</div> {/* End of accordion collapse */}
													</div> {/* End of template container */}
												</div>
											))}
											{budgetFormData.cloudProvider === "Azure" && workspacesAzure.map((ws, index) => (
												<div className="accordion-item border-0 custom-white" key={ws.workspaceId}>
													<div className="template-container mt-2 py-3 ps-3">
														<div className="accordion-header d-flex align-items-center" id={`sub-heading-${index}`}>
															<button
																className="cust-acc-btn collapsed d-flex align-items-center gap-2 font-14 btn border-0 me-3"
																type="button"
																data-bs-toggle="collapse"
																data-bs-target={`#collapse-notification-${index}`}
																aria-expanded="false"
																aria-controls={`collapse-notification-${index}`}
															>
																<span>
																	<p className="mb-0 font-18 font-bold black-v2">{ws.workspaceName}</p>
																</span>
															</button>
														</div>
														<div id={`collapse-notification-${index}`} className="accordion-collapse collapse" aria-labelledby={`sub-heading-${index}`} data-bs-parent="#accordionExample1">
															<div className="accordion-body pb-2">
																<div className="table-responsive table-border">
																	<table className="table custom-table">
																		<thead>
																			<tr>
																				<th className="text-start border-0">Compute ID</th>
																				<th className="text-start border-0">DBU Commitments</th>
																				<th className="text-center border-0">Action</th>
																			</tr>
																		</thead>
																		<tbody>
																			{/* Row to add a new compute type */}
																			<tr>
																				<td className="text-start align-middle">
																					<select
																						value={newComputeData2.computeType}
																						onChange={(e) => setNewComputeData2({ ...newComputeData2, computeType: e.target.value })}
																						className="form-select form-control custom-form"
																					>
																						<option value="">Select Compute Type</option>
																						{ws?.computeType?.filter(ct => !selectedComputeTypes.includes(ct.computeId)).map(ct => (
																							<option key={ct.computeId} value={ct.computeId}>
																								{ct.computeName}
																							</option>
																						))}
																					</select>
																				</td>
																				<td className="text-start align-middle">
																					<input
																						type="number"
																						value={newComputeData2.dbuCommitment}
																						onChange={(e) => setNewComputeData2({ ...newComputeData2, dbuCommitment: e.target.value })}
																						className="form-control custom-form"
																						placeholder="Enter DBU Commitments"
																					/>
																				</td>
																				<td className="text-center">
																					<div className="d-flex justify-content-center gap-3">
																						<button
																							type="button"
																							onClick={() => addComputeTypeRow2(ws.workspaceId)}
																							className={`btn action-btn d-flex align-items-center justify-content-center border-0`}
																						>
																							<img src="images/add-plus-icon.svg" alt="add-action-icon" />
																						</button>
																					</div>
																				</td>
																			</tr>

																			{(resourceGroupsData.find(group => group.ResourceGroup === selectedResourceGroup)?.Workspaces || []).map(ws => (
																				(ws.ComputeType || []).map((ct, index) => (
																					<tr key={index}>
																						<td className="text-start align-middle">
																							{editingIndexMap2[ws.WorkspaceName] === index ? (
																								<select
																									value={ct.computeType}
																									onChange={(e) => handleThresholdChange3(selectedResourceGroup, ws.WorkspaceName, index, 'computeType', e.target.value)}
																									className='form-select form-control custom-form'
																									onBlur={() => setEditingIndexMap2(prev => ({ ...prev, [ws.WorkspaceName]: -1 }))} // Reset editing index on blur
																								>
																									{ws.ComputeType.filter(availableCt => !selectedComputeTypes.includes(availableCt.computeId)).map(availableCt => (
																										<option key={availableCt.computeId} value={availableCt.computeId}>
																											{availableCt.computeType}
																										</option>
																									))}
																								</select>
																							) : (
																								<span>{ct.computeType}</span> // Display selected Compute ID
																							)}
																						</td>
																						<td className="text-start align-middle">
																							{editingIndexMap2[ws.WorkspaceName] === index ? (
																								<input
																									type='text'
																									value={ct.dbuCommitment}
																									onChange={(e) => handleThresholdChange3(selectedResourceGroup, ws.WorkspaceName, index, 'dbuCommitment', e.target.value)}
																									className='form-control custom-form'
																									onBlur={() => setEditingIndexMap2(prev => ({ ...prev, [ws.WorkspaceName]: -1 }))} // Reset editing index on blur
																								/>
																							) : (
																								<span>{ct.dbuCommitment}</span> // Display DBU commitments
																							)}
																						</td>
																						<td className="text-center align-middle">
																							{/* Actions */}
																							<div className="d-flex justify-content-center gap-3">
																								{editingIndexMap2[ws.WorkspaceName] === index ? (
																									<>
																										<a href="#"><img src="/images/tick-large-icon.svg" alt="edit-icon" width={16} height={16} onClick={() => setEditingIndexMap2(prev => ({ ...prev, [ws.WorkspaceName]: -1 }))} /></a>
																										<a href="#" onClick={() => deleteComputeTypeRow2(selectedResourceGroup, ws.WorkspaceName, index)}>
																											<img src="/images/cross-large-icon.svg" width={10} height={10} alt="delete-icon" />
																										</a>

																									</>
																								) : (
																									<>
																										<a href="#"><img src="/images/edit-icon.svg" alt="edit-icon" onClick={() => setEditingIndexMap2(prev => ({ ...prev, [ws.WorkspaceName]: index }))} /></a>
																										<a href="#" onClick={() => deleteComputeTypeRow2(selectedResourceGroup, ws.WorkspaceName, index)}>
																											<img src="/images/delete-icon.svg" alt="delete-icon" />
																										</a>
																									</>
																								)}
																							</div>
																						</td>
																					</tr>
																				))
																			))}
																		</tbody>
																	</table>

																</div> {/* End of table-responsive */}
															</div> {/* End of accordion body */}
														</div> {/* End of accordion collapse */}
													</div> {/* End of template container */}
												</div>
											))}
										</div>
										<div className="col-md-12 mb-4 px-3"> </div>
									</div>

									{/* Threshold Management Section */}
									<div className="col-md-12 mb-4 px-3">
										<p className="mb-0 font-18 font-bold black-v2">Conditions</p>
									</div>
									{/* Initial Budget Input */}
									{architectures === "DataOps"
										// || editMode === true && budgetFormData.initBudget 
										? (
											<div class="d-flex">
												<div className="col-md-4 mb-3 px-3">
													<label className="form-label font-14 font-medium black-v2" htmlFor="InitBudget">Init Budget</label>
													<input
														type="number"
														id="InitBudget"
														className="form-control custom-form"
														placeholder="Enter Init Budget"
														value={budgetFormData.initBudget}
														onChange={(e) => handleInputChange(e, 'initBudget')}
													/>
												</div>
												{/* From Date Picker */}
												<div className="col-md-4 mb-4 pb-3 px-3">
													<label className='form-label font-14 font-medium' htmlFor='FromDate'>
														From<span className='required'>*</span>
													</label>
													<input
														type='date'
														id='FromDate'
														className='form-control custom-form'
														value={budgetFormData.fromDate ? new Date(budgetFormData.fromDate).toISOString().split('T')[0] : ''}
														onChange={(e) => handleInputChange(e, 'fromDate')}
														min={formatDate(minDate)} // Set minimum date
														max={formatDate(maxDate)} // Set maximum date

													/>
												</div>

												{/* To Date Picker */}
												<div className='col-md-4 mb-4 pb-3 px-3'>
													<label className='form-label font-14 font-medium' htmlFor='ToDate'>
														To<span className='required'>*</span>
													</label>
													<input
														type='date'
														id='ToDate'
														className='form-control custom-form'
														value={budgetFormData.toDate ? new Date(budgetFormData.toDate).toISOString().split('T')[0] : ''}
														onChange={(e) => handleInputChange(e, 'toDate')}
														disabled={!budgetFormData.fromDate} // Disable To input until From is selected
														min={formatDate(minDate)} // Set minimum date
														max={formatDate(maxDate)} // Set maximum date

													/>
												</div>
											</div>) : null}
									{/* Threshold Management Section */}



									{/* Threshold Management Section */}
									<div className="col-md-12 px-3 mb-4 pb-3">
										<div className="table-responsive table-border">
											<table className="table custom-table">
												<thead>
													<tr>
														<th className="text-start border-0">Threshold %</th>
														<th className="text-end border-0">Amount</th>
														<th className="text-center border-0">Action</th>
													</tr>
												</thead>
												<tbody>

													{/* Row to add a new threshold */}
													<tr>
														<td className="text-start align-middle">
															<input
																type="number"
																value={newThresholds.percentage} // Add this line
																onChange={(e) => handleThresholdChange(budgetFormData.thresholds.length, 'percentage', e.target.value)}
																className="form-control custom-form threshold-width"
																placeholder="Enter %"
															/>
														</td>
														<td className="text-start align-middle"></td>
														<td className="text-center">
															<div className="d-flex justify-content-center gap-3">
																<button
																	type="button"
																	onClick={addThresholdRow}
																	disabled={disableAddThreshold()}
																	className={`btn action-btn d-flex align-items-center justify-content-center border-0`}
																// disabled={!(budgetFormData?.thresholds?.percentage)}
																>
																	<img src="images/add-plus-icon.svg" alt="add-action-icon" />
																</button>
															</div>
														</td>
													</tr>
													{budgetFormData?.thresholds?.map((threshold, index) => (
														<tr key={index}>
															<td className="text-start align-middle">
																{editingIndex === index ? (
																	<input
																		type="text"
																		value={threshold.percentage}
																		onChange={(e) => handleThresholdChange(index, 'percentage', e.target.value)}
																		className="form-control custom-form threshold-width"
																		placeholder="Enter %"
																		onBlur={() => setEditingIndex(-1)}
																	/>
																) : (
																	<span>{threshold.percentage}</span>
																)}
															</td>
															<td className="text-start align-middle">
																{threshold.amount} {/* Amount can be calculated based on percentage */}
															</td>
															<td className="text-center">
																{/* Actions */}
																<div className="d-flex justify-content-center gap-3">
																	{editingIndex === index ? (
																		<>
																			{/* Save Action */}
																			<a href="#" onClick={() => setEditingIndex(-1)}>
																				<img src="/images/tick-large-icon.svg" alt="tick-icon" width={16} height={16}></img>
																			</a>

																			{/* Cancel Action */}
																			<a href="#" onClick={() => deleteThresholdRow(index)}>
																				<img src="/images/cross-large-icon.svg" alt="cross-icon" width={10} height={10}></img>
																			</a>
																		</>
																	) : (
																		<>
																			{/* Edit Action */}
																			<a href="#" onClick={() => setEditingIndex(index)}>
																				<img src="/images/edit-icon.svg" alt="edit-icon" />
																			</a>

																			{/* Delete Action */}
																			<a href="#" onClick={() => deleteThresholdRow(index)}>
																				<img src="/images/delete-icon.svg" alt="delete-icon" />
																			</a>
																		</>
																	)}
																</div>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
									</div>
									<div className="d-flex">
										{/* Alert Email ID */}
										<div className="col-md-4 mb-4 pb-3 px-3 flex-grow-1">
											<label className="form-label font-14 font-medium black-v2" htmlFor="AlertEmailID">Alert Email ID<span className="required">*</span></label>
											<input
												type="text"
												id="AlertEmailID"
												className={`form-control custom-form ${errorMessages.alertEmailId ? 'is-invalid' : ''}`}
												value={budgetFormData.alertEmailId}
												onChange={(e) => handleInputChange(e, 'alertEmailId')}
												placeholder="Enter Email ID"
											/>
											{errorMessages.alertEmailId && <div className="invalid-feedback">{errorMessages.alertEmailId}</div>}
										</div>

										{/* Teams Integration */}
										<div className="col-md-8 mb-4 pb-3 px-3 flex-grow-1">
											<label className="form-label font-14 font-medium black-v2" htmlFor="TeamsIntegration">Teams Integration<span className="required">*</span></label>
											<input
												type="text"
												id="TeamsIntegration"
												className={`form-control custom-form ${errorMessages.teamsIntegrationUrl ? 'is-invalid' : ''}`}
												value={budgetFormData.teamsIntegrationUrl}
												onChange={(e) => handleInputChange(e, 'teamsIntegrationUrl')}
												placeholder="Webhook URL"
											/>
											{errorMessages.teamsIntegrationUrl && <div className="invalid-feedback">{errorMessages.teamsIntegrationUrl}</div>}
										</div>
									</div>

									<div class="col-md-12 px-3 text-end">
										<button type="button"
											class="button cancel-button me-3 px-4 py-3 lh-1" onClick={() => navigate('/thresholdGridPage')}>Cancel</button>
										<button type="button"
											class="button primary-button btn px-32 py-3 lh-1"
											disabled={!isSaveEnabled} // Disable button if conditions are not met

											onClick={handleSave}>Save</button>
									</div>

								</div> {/* End of add organization form */}
							</div> {/* End of col-md12 */}
						</div>
					</div>
				</div></div></>
	);
};

export default CostManagementForm;
