// this is for azure front Door
import { useEffect, useState } from "react";


export default function Azure_Front_Door({ values, onChangeOfConfiguration, isViewer }) {

    const totalZones = [
        { name: 'Asia Pacific (including Japan)', values: 'classiczone2' },
        { name: 'North America, Europe, Middle East and Africa', values: 'classiczone1' },
        { name: 'South America', values: 'classiczone3' },
        { name: 'Australia', values: 'classiczone4' },
        { name: 'India', values: 'classiczone5' },
    ]

    const defaultZones = [
        { name: 'no', values: 'classiczone1' },
    ];

    const addZone = (newZone = { name: '', values: '' }) => {
        setZones([...zones, newZone]);
    };
    const [zones, setZones] = useState(defaultZones);
    const removeZone = (index) => {
        const newZones = zones.filter((_, i) => i !== index);
        setZones(newZones);
    };
    const handleZoneChange = (index, field, value) => {
        const newZones = [...zones];
        newZones[index] = { ...newZones[index], [field]: value };
        setZones(newZones);
    };
    const getMissingValues = (totalZones, selectedValues, index) => {
        // If selectedList is empty or undefined, return all values from totalZones
        return totalZones
            .filter(zone => !selectedValues.includes(zone.values))
            .map(zone => zone.values);
    };
    const binding = (index) => {
        const missingZones = getMissingValues(totalZones, zones, index);
        console.log("missing", missingZones)
        return missingZones.map((data) =>
            <option value={data.values} key={data.values}>{data.name}</option>
        )
    }
    console.log("Zone", zones)
    try {
        console.log("saginDMS---okchangemade", values)
        return (
            <div id={`${values.instanceName.split(" ").join("_")}`} className="accordion-collapse collapse">
                <div className="accordioan-body p-0 ">
                    <table className="table table-borderless cg-grid font-14 font-semibold mb-0">
                        <tbody>
                            <tr className="ms-acc-data-align">
                                <td colSpan={3}>
                                    <div className="row py-4 mx-4 px-5" hidden={(values.isPriceAvailable == "No") ? false : true}>
                                        <div className="col-md-6 px-3"><span className="font-semibold font-14 text-danger">{values.isPriceAvailable == "No" ? "The Price is not available for the region / the configuration that you have sel  ected might be not available in the region" : null}</span></div>
                                    </div>
                                    <div className="row py-4 mx-4 px-5">
                                        <div>
                                            <label htmlFor="tier" className="form-label font-14 font-medium black-v2 mb-3">
                                                Tier
                                            </label>
                                            <select
                                                className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                id="tier"
                                                value={values.tier}
                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                name={"tier"}
                                                onChange={(e) => {
                                                    onChangeOfConfiguration(e, values.instanceName);
                                                }} disabled={isViewer}
                                            >
                                                <option value="frontdoor" class="">Azure Front Door (Classic)</option>
                                                <option value="frontdoorstandard" class="">Azure Front Door Standard</option>
                                                <option value="frontdoorpremium" class="">Azure Front Door Premium</option>
                                            </select>
                                        </div>
                                        {/* {zones.map((zone, index) => (
                                            <div key={index}>
                                                <>
                                                    <div>
                                                        <label htmlFor="instances" className="form-label font-14 font-medium black-v2 mb-3" style={{marginTop :"10px"}}>
                                                            {index + 1}) Zone
                                                        </label>
                                                        <input
                                                            type="text"
                                                            style={{ paddingTop: "10px"}}
                                                            className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                                            id="instances"
                                                            value={values.instances}
                                                            name={"instances"}
                                                            onChange={(e) => handleZoneChange(index, 'name', e.target.value)} disabled={isViewer}
                                                        />
                                                    </div>
                                                    <div>
                                                        <label htmlFor="region" className="form-label font-14 font-medium black-v2 mb-3">
                                                            Outbound Data Transfer
                                                        </label>
                                                        <select
                                                            className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                            id="tier"
                                                            style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                            name={"tier"}
                                                            onChange={(e) => handleZoneChange(index, 'values', e.target.value)}                                                        >
                                                            <option value="classiczone1" class="">North America, Europe, Middle East and Africa</option>
                                                            <option value="classiczone2" class="">Asia Pacific (including Japan)</option>
                                                            <option value="classiczone3" class="">South America</option>
                                                            <option value="classiczone4" class="">Australia</option>
                                                            <option value="classiczone5" class="">India</option>
                                                            {zone && binding(index)}
                                                        </select>
                                                    </div>
                                                    <span onClick={() => addZone()} style={{ marginLeft: '10px', display: "inline", marginTop: "20px"}}><b>ADD ANOTHER ZONE</b></span>
                                                    <span onClick={() => removeZone(index)} style={{ marginLeft: '10px', display: "inline", marginTop: "20px", marginLeft : "65%"}}><b>REMOVE</b></span>
                                                </>
                                            </div>
                                        ))} */}
                                        {values.tier == "frontdoor" && <>
                                            <h6 style={{ textAlign: "center", marginTop: "5%" }}>Currently, Azure Front Door (Classic) is not supported.</h6>
                                        </>}
                                        {values.tier == "frontdoorstandard" && <>
                                            <div>
                                                <label htmlFor="zone" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Zone
                                                </label>
                                                <select
                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                    id="zone"
                                                    value={values.zone}
                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                    name={"zone"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}>

                                                    <option value="zone1" class="">North America</option>
                                                    <option value="zone2" class="">Asia Pacific (including Japan)</option>
                                                    <option value="zone3" class="">South America</option>
                                                    <option value="zone4" class="">Australia</option>
                                                    <option value="zone5" class="">India</option>
                                                    <option value="zone6" class="">Europe</option>
                                                    <option value="zone7" class="">Middle East and Africa</option>
                                                    <option value="zone8" class="">Korea</option>
                                                    <option value="usgov-zone1" class="">US Gov Zone 1</option>
                                                </select>
                                            </div>
                                            <div>
                                                <label htmlFor="baseCost" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Base Cost
                                                </label>
                                                <select
                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                    id="baseCost"
                                                    value={values.baseCost}
                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                    name={"baseCost"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}
                                                >
                                                    <option value="yes" class="">Yes</option>
                                                    <option value="no" class="">No</option>
                                                </select>
                                            </div>
                                            <h6 style={{ marginTop: "15px", marginBottom: "20px" }}>Data Transfer </h6>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="internetEgressCount" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Outbound Data Transfer from Edge to the Client Internet Egress Count
                                                </label>
                                                <input
                                                    type="text"
                                                    style={{ paddingBottom: '9px' }}
                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                    id="internetEgressCount"
                                                    value={values.internetEgressCount}
                                                    name={"internetEgressCount"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}
                                                />
                                            </div>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="internetEgressFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Outbound Data Transfer from Edge to the Client Internet Egress Factor
                                                </label>
                                                <select
                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                    id="internetEgressFactor"
                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                    value={values.internetEgressFactor}
                                                    name={"internetEgressFactor"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}                                                >
                                                    <option value="1" class="">GB</option>
                                                    <option value="1024" class="">TB</option></select>
                                            </div>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="internetIngressCount" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Outbound Data Transfer from Edge to the Origin Internet Ingress Count
                                                </label>
                                                <input
                                                    type="text"
                                                    style={{ paddingBottom: '9px' }}
                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                    id="internetIngressCount"
                                                    value={values.internetIngressCount}
                                                    name={"internetIngressCount"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}
                                                />
                                            </div>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="internetIngressFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Outbound Data Transfer from Edge to the Origin Internet Ingress Factor
                                                </label>
                                                <select
                                                    className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                    id="internetIngressFactor"
                                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                    value={values.internetIngressFactor}
                                                    name={"internetIngressFactor"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}                                                >
                                                    <option value="1" class="">GB</option>
                                                    <option value="1024" class="">TB</option></select>
                                            </div>
                                            <div>
                                                <label htmlFor="standardPremiumRequests" className="form-label font-14 font-medium black-v2 mb-3">
                                                    Request Pricing
                                                </label>
                                                <input
                                                    type="text"
                                                    style={{ paddingBottom: '9px' }}
                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                    id="standardPremiumRequests"
                                                    value={values.standardPremiumRequests}
                                                    name={"standardPremiumRequests"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} disabled={isViewer}
                                                />
                                            </div>
                                        </>}
                                        {values.tier == "frontdoorpremium" &&
                                            <>
                                                <div>
                                                    <label htmlFor="zone" className="form-label font-14 font-medium black-v2 mb-3">
                                                        Zone
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                        id="zone"
                                                        value={values.zone}
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        name={"zone"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}
                                                    >
                                                        <option value="zone1" class="">North America</option>
                                                        <option value="zone2" class="">Asia Pacific (including Japan)</option>
                                                        <option value="zone3" class="">South America</option>
                                                        <option value="zone4" class="">Australia</option>
                                                        <option value="zone5" class="">India</option>
                                                        <option value="zone6" class="">Europe</option>
                                                        <option value="zone7" class="">Middle East and Africa</option>
                                                        <option value="zone8" class="">Korea</option>
                                                        <option value="usgov-zone1" class="">US Gov Zone 1</option>
                                                    </select>
                                                </div>
                                                <div>
                                                    <label htmlFor="baseCost" className="form-label font-14 font-medium black-v2 mb-3">
                                                        Base Cost
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                        id="baseCost"
                                                        value={values.baseCost}
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        name={"baseCost"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}
                                                    >
                                                        <option value="yes" class="">Yes</option>
                                                        <option value="no" class="">No</option>
                                                    </select>
                                                </div>
                                                <h6 style={{ marginTop: "15px", marginBottom: "20px" }}>Data Transfer </h6>
                                                <div className="col-md-6 px-3">
                                                    <label htmlFor="internetEgressCount" className="form-label font-14 font-medium black-v2 mb-3">
                                                        Outbound Data Transfer from Edge to the Client Internet Egress Count
                                                    </label>
                                                    <input
                                                        type="text"
                                                        style={{ paddingBottom: '9px' }}
                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                        id="internetEgressCount"
                                                        value={values.internetEgressCount}
                                                        name={"internetEgressCount"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}
                                                    />
                                                </div>
                                                <div className="col-md-6 px-3">
                                                    <label htmlFor="internetEgressFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                                        Outbound Data Transfer from Edge to the Client Internet Egress Factor
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                        id="internetEgressFactor"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.internetEgressFactor}
                                                        name={"internetEgressFactor"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}                                                >
                                                        <option value="1" class="">GB</option>
                                                        <option value="1024" class="">TB</option></select>
                                                </div>
                                                <div className="col-md-6 px-3">
                                                    <label htmlFor="internetIngressCount" className="form-label font-14 font-medium black-v2 mb-3">
                                                        Outbound Data Transfer from Edge to the Origin Internet Ingress Count
                                                    </label>
                                                    <input
                                                        type="text"
                                                        style={{ paddingBottom: '9px' }}
                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                        id="internetIngressCount"
                                                        value={values.internetIngressCount}
                                                        name={"internetIngressCount"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}
                                                    />
                                                </div>
                                                <div className="col-md-6 px-3">
                                                    <label htmlFor="internetIngressFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                                        Outbound Data Transfer from Edge to the Origin Internet Ingress Factor
                                                    </label>
                                                    <select
                                                        className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                        id="internetIngressFactor"
                                                        style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                        value={values.internetIngressFactor}
                                                        name={"internetIngressFactor"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}                                                >
                                                        <option value="1" class="">GB</option>
                                                        <option value="1024" class="">TB</option></select>
                                                </div>
                                                <div>
                                                    <label htmlFor="standardPremiumRequests" className="form-label font-14 font-medium black-v2 mb-3">
                                                        Request Pricing
                                                    </label>
                                                    <input
                                                        type="text"
                                                        style={{ paddingBottom: '9px' }}
                                                        className=" form-control  custom-form text-field font-14 font-medium"
                                                        id="standardPremiumRequests"
                                                        value={values.standardPremiumRequests}
                                                        name={"standardPremiumRequests"}
                                                        onChange={(e) => {
                                                            onChangeOfConfiguration(e, values.instanceName);
                                                        }} disabled={isViewer}
                                                    />
                                                </div>
                                            </>}
                                    </div>
                                    <div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    } catch (error) {
        console.log(error);
    }
}