
import React, { useState, useRef } from 'react';
import Draggable from "react-draggable";

export function RegenerateButton({regenerationText, setRegenerationText}) {
    const [regenerateText, setRegenerateText] = useState(regenerationText);
    const dropdownRef = useRef(null);
    // Handle of RegereateCahgne Which is basically the Onchange Event 
    const handleRegenerateChange = (e) => {
        setRegenerateText(e.target.value);
    };
    // Handle of Cancel Function 
    const handleCancel = () => {
        setRegenerateText('');
        // Close the dropdown
        if (dropdownRef.current) {
            dropdownRef.current.click();
        }
    };

    const handleRegenerate = () => {
        // Implement regenerate functionality here
        console.log('Regenerating with text:', regenerateText);
        // Close the dropdown after regenerating
        if (dropdownRef.current) {
            dropdownRef.current.click();
        }
        // Which set the Regernerate Comments in the Workbench 
        setRegenerationText(regenerateText)
        

    };

    return (
        <div className="p-4">
            {/* <div className="position-relative"> */}
            <Draggable>
                <div className="dropdown cust-dropdown-pos" style={{
                    position : "absolute",
                    bottom: '10px',
                    right: '10px', 
                    marginRight:"13%",
                    marginBottom:"10%"
                }}>
                    <button
                        type="button"
                        className="btn cust-dropdown d-flex justify-content-between align-items-center gap-3 chatbot-btn shadow-none"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        data-bs-auto-close="outside"
                        ref={dropdownRef}
                        // Changed Styling
                        style={{height:"55px",width:"55px" ,borderRadius:"68%"}}
                    >
                        
                        <img src="images/chatbot-icon.svg" alt="chatbot-icon" />
                        <img
                            src="images/cross-icon-white.svg"
                            alt="cross-icon-white"
                            className="d-none"
                        />
                    </button>
                    <div
                        className="dropdown-menu body-bg chatbot-dropdown p-3 cust-drop-width mb-4"
                        aria-labelledby="dropdownMenuButton1"
                    >
                        <label
                            className="form-label font-12 font-bold color-black"
                            htmlFor="Regenerate"
                        >
                            Regenerate
                        </label>
                        <textarea
                            className="form-control chatbot-textarea font-11 font-medium mb-3"
                            placeholder="Describe what would you like to regenerate"
                            id="Regenerate"
                            value={regenerateText}
                            onChange={handleRegenerateChange}
                        />
                        <div className="d-flex justify-content-end align-items-center gap-3">
                            <button 
                                type="button" 
                                className="button cancel-button lh-1 py-2"
                                onClick={handleCancel}
                            >
                                Cancel
                            </button>
                            <button 
                                className="btn regenerate-btn shadow-none" style={{borderRadius:"70%"}}
                                onClick={handleRegenerate}
                            >
                                <img src="images/send-arrow-icon.svg" alt="send-arrow-icon" />
                            </button>
                        </div>
                        <div className="position-relative">
                            <img
                                src="images/white-polygon-icon.svg"
                                alt="polygon-icon"
                                className="pointer-pos light-theme-img"
                            />
                            <img
                                src="images/polygon-dark.svg"
                                alt="polygon-icon"
                                className="pointer-pos dark-theme-img"
                            />
                        </div>
                    </div>
                </div>
            </Draggable>
            {/* </div> */}
        </div>
    );
}