export default function AwsSDKCognito({ values, onChangeOfConfiguration, isViewer }) {
  return (
    <div
      class="accordion-body p-0 estimation-container">
      <div className="row  py-4 mx-4 px-5">
        <div className="col-md-6 px-3">
          <label htmlFor="region" className="form-label font-14 font-medium black-v2 mb-3">
            Region
          </label>
          <select
            style={{ paddingTop: "10px" }}
            className="form-select custom-form  font-medium font-14 text-field"
            aria-label="Default select example"
            id="region"
            value={values.region}
            name={"region"}
            onChange={(e) => {
              onChangeOfConfiguration(e, values.instanceName);
            }} disabled={isViewer}
          >
            <option value="ap-south-2">ap-south-2</option><option value="ap-southeast-3">ap-southeast-3</option><option value="ap-southeast-1">ap-southeast-1</option><option value="us-gov-east-1">us-gov-east-1</option><option value="eu-west-2">eu-west-2</option><option value="af-south-1">af-south-1</option><option value="ap-northeast-1">ap-northeast-1</option><option value="ap-southeast-2">ap-southeast-2</option><option value="us-east-2">us-east-2</option><option value="eu-central-1">eu-central-1</option><option value="ca-central-1">ca-central-1</option><option value="ap-south-1">ap-south-1</option><option value="ap-northeast-3">ap-northeast-3</option><option value="sa-east-1">sa-east-1</option><option value="ap-east-1">ap-east-1</option><option value="eu-west-1">eu-west-1</option><option value="eu-south-1">eu-south-1</option><option value="ap-southeast-4">ap-southeast-4</option><option value="eu-north-1">eu-north-1</option><option value="eu-south-2">eu-south-2</option><option value="us-west-1">us-west-1</option><option value="us-west-2">us-west-2</option><option value="me-south-1">me-south-1</option><option value="eu-central-2">eu-central-2</option><option value="us-gov-west-1">us-gov-west-1</option><option value="ap-northeast-2">ap-northeast-2</option><option value="me-central-1">me-central-1</option><option value="us-east-1">us-east-1</option><option value="eu-west-3">eu-west-3</option>
          </select>
        </div>
        <div className="col-md-6 px-3">
          <label for="CognitoMAU" className="form-label font-14 font-medium black-v2 mb-3">
            Number of monthly active users (MAU)
          </label>
          <input
            type="number"
            style={{ paddingTop: "10px" }}
            className="form-control custom-form text-field font-14 font-medium mb-3"
            id="CognitoMAU"
            value={values.CognitoMAU}
            name={"CognitoMAU"}
            onChange={(e) => {
              onChangeOfConfiguration(e, values.instanceName);
            }} disabled={isViewer}
          />
        </div>
        <div className="col-md-6 px-3">
          <label           for="CognitoSecurityFeatures" className="form-label font-14 font-medium black-v2 mb-3">
            Advanced security features
          </label>
          <select
          style={{ paddingTop: "10px" , marginTop :"19px"}}
            className="form-select custom-form  font-medium font-14 text-field"
            aria-label="Default select example"
            id="CognitoSecurityFeatures"
            value={values.CognitoSecurityFeatures}
            name={"CognitoSecurityFeatures"}
            onChange={(e) => {
              onChangeOfConfiguration(e, values.instanceName);
            }} disabled={isViewer}
          >
            <option value={"enable"}>Enabled</option>
            <option value={"disable"}>Disabled</option>
          </select>
        </div>
        <div className="col-md-6 px-3">
          <label for="CognitoSAMLFederation" className="form-label font-14 font-medium black-v2 mb-3">
            Percent of monthly users who sign in through SAML or OIDC
            federation
          </label>
          <input
            type="number"
            style={{ paddingTop: "10px" }}
            className="form-control custom-form text-field font-14 font-medium"
            id="CognitoSAMLFederation"
            value={values.CognitoSAMLFederation}
            name={"CognitoSAMLFederation"}
            onChange={(e) => {
              onChangeOfConfiguration(e, values.instanceName);
            }} disabled={isViewer}
          />
        </div>
      </div>
    </div>
  );
}