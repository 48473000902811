
// this HTML is for Azure Container Apps
export default function Azure_Container_Apps({ values, onChangeOfConfiguration, isViewer }) {
    try {
        console.log("sagin aws S3",values,"34535345")
        return (
            <div id={`${values.instanceName.split(" ").join("_")}`} className="accordion-collapse collapse">
                <div className="accordion-body p-0 ">
                    <table className="table table-borderless cg-grid font-14 font-semibold mb-0">
                        <tbody>
                            <tr className="ms-acc-data-align">
                                <td colSpan={3}>
                                    <div className="row py-4 mx-4 px-5" hidden={(values.isPriceAvailable == "No") ? false : true}>
                                        <div className="col-md-6 px-3"><span className="font-semibold font-14 text-danger">{values.isPriceAvailable == "No" ? "The Price is not available for the region / the configuration that you have selected might be not available in the region" : null}</span></div>
                                    </div>
                                    <div className="row py-4 mx-4 px-5">
                                        <div className="col-md-6 px-3">
                                            <label htmlFor="region" className="form-label font-14 font-medium black-v2 mb-3">
                                                Region
                                            </label>
                                            <select
                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                id="region"
                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                value={values.region}
                                                name={"region"}
                                                onChange={(e) => {
                                                    onChangeOfConfiguration(e, values.instanceName);
                                                }} disabled={isViewer}
                                            >
                                            
                                                <option value="us-central">us-central</option>
                                                <option value="us-east">us-east</option>
                                                <option value="us-east-2">us-east-2</option>
                                                <option value="us-north-central">us-north-central</option>
                                                <option value="us-south-central">us-south-central</option>
                                                <option value="us-west-central">us-west-central</option>
                                                <option value="us-west">us-west</option>
                                                <option value="us-west-2">us-west-2</option>
                                                <option value="us-west-3">us-west-3</option>

                                                <option value="united-kingdom-south">united-kingdom-south</option>
                                                <option value="united-kingdom-west">united-kingdom-west</option>

                                                <option value="uae-central">uae-central</option>
                                                <option value="uae-north">uae-north</option>

                                                <option value="switzerland-north">switzerland-north</option>
                                                <option value="switzerland-west">switzerland-west</option>

                                                <option value="sweden-central">sweden-central</option>
                                                <option value="sweden-south">sweden-south</option>

                                                <option value="spain-central">spain-central</option>

                                                <option value="qatar-central">qatar-central</option>

                                                <option value="poland-central">poland-central</option>

                                                <option value="norway-east">norway-east</option>
                                                <option value="norway-west">norway-west</option>

                                                <option value="mexico-central">mexico-central</option>

                                                <option value="korea-central">korea-central</option>
                                                <option value="korea-south">korea-south</option>

                                                <option value="japan-east">japan-east</option>
                                                <option value="japan-west">japan-west</option>

                                                <option value="italy-north">italy-north</option>

                                                <option value="israel-central">israel-central</option>

                                                <option value="central-india">central-india</option>
                                                <option value="south-india">south-india</option>
                                                <option value="west-india">west-india</option>

                                                <option value="germany-north">germany-north</option>
                                                <option value="germany-west-central">germany-west-central</option>
                                                
                                                <option value="france-central">france-central</option>
                                                <option value="france-south">france-south</option>

                                                <option value="europe-north">europe-north</option>
                                                <option value="europe-west">europe-west</option>

                                                <option value="canada-central">canada-central</option>
                                                <option value="canada-east">canada-east</option>

                                                <option value="brazil-south">brazil-south</option>
                                                {/* <option value="brazil-southeast">brazil-southeast</option> */}
                                                {/* <option value="usgov-arizona">usgov-arizona</option>
                                                <option value="usgov-texas">usgov-texas</option>
                                                <option value="usgov-virginia">usgov-virginia</option> */}

                                                <option value="australia-central">australia-central</option>
                                                <option value="australia-central-2">australia-central-2</option>
                                                <option value="australia-east">australia-east</option>
                                                <option value="australia-southeast">australia-southeast</option>

                                                <option value="asia-pacific-east">asia-pacific-east</option>
                                                <option value="asia-pacific-southeast">asia-pacific-southeast</option>

                                                <option value="south-africa-north">south-africa-north</option>
                                                <option value="south-africa-west">south-africa-west</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6 px-3">
                                            <label htmlFor="planType" className="form-label font-14 font-medium black-v2 mb-3">
                                            Plan Type
                                            </label>
                                            <select
                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                id="planType"
                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                value={values.planType}
                                                name={"planType"}
                                                onChange={(e) => {
                                                    onChangeOfConfiguration(e, values.instanceName);
                                                }} disabled={isViewer}
                                            >
                                            
                                            <option value="consumption">consumption</option>
                                            <option value="dedicated">dedicated</option>
                                            <option value="dedicatedgpu">dedicatedgpu</option>
                                            <option value="dynamicsessions">dynamicsessions</option>
                                            </select>
                                        </div>

                                        
                                        {values["planType"] === 'consumption' ? (
                                            <>
                                            <h6 style={{ marginTop: "3%",marginBottom :"2%"}}>Requests</h6>
                                        <div>
                                            <label htmlFor="requestsInMillions" className="form-label font-14 font-medium black-v2 mb-3">
                                            x1 million total requests per month
                                            </label>
                                            <input
                                                type="text"
                                                style={{paddingTop :"10px"}}
                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                id="requestsInMillions"
                                                value={values.requestsInMillions}
                                                name={"requestsInMillions"}
                                                onChange={(e) => {
                                                    onChangeOfConfiguration(e, values.instanceName);
                                                }} disabled={isViewer}

                                            />
                                        </div>
                                        <h6 style={{ marginTop: "3%",marginBottom :"2%"}}>Active usage</h6>
                                        <div className="col-md-6 px-3">
                                            <label htmlFor="activeUsageConcurrentRequests" className="form-label font-14 font-medium black-v2 mb-3">
                                            Concurrent requests per container app
                                            </label>
                                            <input
                                                type="text"
                                                style={{paddingTop :"10px"}}
                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                id="activeUsageConcurrentRequests"
                                                value={values.activeUsageConcurrentRequests}
                                                name={"activeUsageConcurrentRequests"}
                                                onChange={(e) => {
                                                    onChangeOfConfiguration(e, values.instanceName);
                                                }} disabled={isViewer}

                                            />
                                        </div>
                                        <div className="col-md-6 px-3">
                                            <label htmlFor="activeUsageExecutionMsPerRequest" className="form-label font-14 font-medium black-v2 mb-3">
                                            Execution time per request (ms)
                                            </label>
                                            <input
                                                type="text"
                                                style={{paddingTop :"10px"}}
                                                className=" form-control  custom-form text-field font-14 font-medium"
                                                id="activeUsageExecutionMsPerRequest"
                                                value={values.activeUsageExecutionMsPerRequest}
                                                name={"activeUsageExecutionMsPerRequest"}
                                                onChange={(e) => {
                                                    onChangeOfConfiguration(e, values.instanceName);
                                                }} disabled={isViewer}

                                            />
                                        </div>
                                        <h6 style={{ marginTop: "3%",marginBottom :"2%"}}>Resources</h6>
                                        <div className="col-md-6 px-3">
                                            <label htmlFor="vCpuCount" className="form-label font-14 font-medium black-v2 mb-3">
                                            vCPU
                                            </label>
                                            <select
                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                id="vCpuCount"
                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                value={values.vCpuCount}
                                                name={"vCpuCount"}
                                                onChange={(e) => {
                                                    onChangeOfConfiguration(e, values.instanceName);
                                                }} disabled={isViewer}
                                            >
                                            <option value="0.25">0.25</option>
                                            <option value="0.5">0.5</option>
                                            <option value="0.75">0.75</option>
                                            <option value="1">1</option>
                                            <option value="1.25">1.25</option>
                                            <option value="1.5">1.5</option>
                                            <option value="1.75">1.75</option>
                                            <option value="2">2</option>
                                            <option value="2.25">2.25</option>
                                            <option value="2.5">2.5</option>
                                            <option value="2.75">2.75</option>
                                            <option value="3">3</option>
                                            <option value="3.25">3.25</option>
                                            <option value="3.5">3.5</option>
                                            <option value="3.75">3.75</option>
                                            <option value="4">4</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6 px-3">
                                            <label htmlFor="memorySize" className="form-label font-14 font-medium black-v2 mb-3">
                                            Memory
                                            </label>
                                            <select
                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                id="memorySize"
                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                value={values.memorySize}
                                                name={"memorySize"}
                                                onChange={(e) => {
                                                    onChangeOfConfiguration(e, values.instanceName);
                                                }} disabled={isViewer}
                                            >
                                            <option value="1">1 GiB</option>
                                            <option value="2">2 GiB</option>
                                            <option value="3">3 GiB</option>
                                            <option value="4">4 GiB</option>
                                            <option value="5">5 GiB</option>
                                            <option value="6">6 GiB</option>
                                            <option value="7">7 GiB</option>
                                            <option value="8">8 GiB</option>
                                            </select>
                                        </div>
                                        <h6 style={{ marginTop: "3%",marginBottom :"2%"}}>Capacity</h6>
                                        <div>
                                            <label htmlFor="RequiredMinimumReplicas" className="form-label font-14 font-medium black-v2 mb-3">
                                            Required minimum replicas
                                            </label>
                                            <select
                                                className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                id="RequiredMinimumReplicas"
                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                value={values.RequiredMinimumReplicas}
                                                name={"RequiredMinimumReplicas"}
                                                onChange={(e) => {
                                                    onChangeOfConfiguration(e, values.instanceName);
                                                }} disabled={isViewer}
                                            >
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                            
                                            </select>
                                        </div>

                                        
                                        {values['RequiredMinimumReplicas'] === "yes" ? (
                                            <>
                                            <div className="col-md-6 px-3">
                                                <label htmlFor="dedicatedInstanceCount" className="form-label font-14 font-medium black-v2 mb-3">
                                                Minimum number of replicas
                                                </label>
                                                <input
                                                    type="text"
                                                    className=" form-control  custom-form text-field font-14 font-medium"
                                                    id="dedicatedInstanceCount"
                                                    value={values.dedicatedInstanceCount}
                                                    name={"dedicatedInstanceCount"}
                                                    onChange={(e) => {
                                                        onChangeOfConfiguration(e, values.instanceName);
                                                    }} 
                                                    disabled={isViewer}
                                                />
                                            </div>
                                            </>
                                        ) : null}
                                        </>
                                        ) : values["planType"] === 'dedicated' ? (
                                        <>
                                        <h6 style={{ marginTop: "3%",marginBottom :"2%"}}>Workload Profile</h6>
                                        <div className="col-md-6 px-3">
                                            <label htmlFor="workload" className="form-label font-14 font-medium black-v2 mb-3">
                                            Workload
                                            </label>
                                            <select
                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                id="workload"
                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                value={values.workload}
                                                name={"workload"}
                                                onChange={(e) => {
                                                    onChangeOfConfiguration(e, values.instanceName);
                                                }} disabled={isViewer}
                                            >
                                            
                                            <option value="D4">D4, 4 vCPU, 16 GiB RAM</option>
                                            <option value="D8">D8, 8 vCPU, 32 GiB RAM</option>
                                            <option value="D16">D16, 16 vCPU, 64 GiB RAM</option>
                                            <option value="D32">D32, 32 vCPU, 128 GiB RAM</option>
                                            <option value="E4">E4, 4 vCPU, 32 GiB RAM</option>
                                            <option value="E8">E8, 8 vCPU, 64 GiB RAM</option>
                                            <option value="E16">E16, 16 vCPU, 128 GiB RAM</option>
                                            <option value="E32">E32, 32 vCPU, 256 GiB RAM</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6 px-3">
                                        <label htmlFor="dedicatedInstances" className="form-label font-14 font-medium black-v2 mb-3">
                                        Dedicated Instances
                                        </label>
                                        <input
                                            type="text"
                                            style={{paddingTop :"10px"}}
                                            className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                            id="dedicatedInstances"
                                            value={values.dedicatedInstances}
                                            name={"dedicatedInstances"}
                                            onChange={(e) => {
                                                onChangeOfConfiguration(e, values.instanceName);
                                            }} 
                                            disabled={isViewer}
                                        />
                                        </div>

                                        <div className="col-md-6 px-3">
                                        <label htmlFor="dedicatedInstanceHours" className="form-label font-14 font-medium black-v2 mb-3">
                                        Dedicated Instance Hours
                                        </label>
                                        <input
                                            type="text"
                                            style={{paddingTop :"10px"}}
                                            className=" form-control  custom-form text-field font-14 font-medium"
                                            id="dedicatedInstanceHours"
                                            value={values.dedicatedInstanceHours}
                                            name={"dedicatedInstanceHours"}
                                            onChange={(e) => {
                                                onChangeOfConfiguration(e, values.instanceName);
                                            }} 
                                            disabled={isViewer}
                                        />
                                        </div>

                                        <div className="col-md-6 px-3">
                                            <label htmlFor="dedicatedInstanceHoursFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                            Dedicated Instance Hours Factor
                                            </label>
                                            <select
                                                className=" form-select custom-form  font-semibold font-14 form-select-sm mb-3"
                                                id="dedicatedInstanceHoursFactor"
                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                value={values.dedicatedInstanceHoursFactor}
                                                name={"dedicatedInstanceHoursFactor"}
                                                onChange={(e) => {
                                                    onChangeOfConfiguration(e, values.instanceName);
                                                }} disabled={isViewer}
                                            >
                                            
                                            <option value="1">Hours</option>
                                            <option value="24">Days</option>
                                            <option value="730">Month</option>
                                            
                                            </select>
                                        </div>

                                        
                                        </>

                                        ) : values["planType"] === 'dedicatedgpu' ? (
                                        <>
                                        <h6 style={{ marginTop: "3%",marginBottom :"2%"}}>Workload Profile</h6>
                                        <div className="col-md-6 px-3">
                                            <label htmlFor="gpuWorkload" className="form-label font-14 font-medium black-v2 mb-3">
                                            GPU workload
                                            </label>
                                            <select
                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                id="gpuWorkload"
                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                value={values.gpuWorkload}
                                                name={"gpuWorkload"}
                                                onChange={(e) => {
                                                    onChangeOfConfiguration(e, values.instanceName);
                                                }} disabled={isViewer}
                                            >
                                            
                                            <option value="A100">A100 GPU</option>
                                           
                                            </select>
                                        </div>
                                        <div className="col-md-6 px-3">
                                        <label htmlFor="dedicatedInstances" className="form-label font-14 font-medium black-v2 mb-3">
                                        Dedicated Instances
                                        </label>
                                        <input
                                            type="text"
                                            style={{paddingTop:"10px"}}
                                            className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                            id="dedicatedInstances"
                                            value={values.dedicatedInstances}
                                            name={"dedicatedInstances"}
                                            onChange={(e) => {
                                                onChangeOfConfiguration(e, values.instanceName);
                                            }} 
                                            disabled={isViewer}
                                        />
                                        </div>

                                        <div className="col-md-6 px-3">
                                        <label htmlFor="dedicatedInstanceHours" className="form-label font-14 font-medium black-v2 mb-3">
                                        Dedicated Instance Hours
                                        </label>
                                        <input
                                            type="text"
                                            style={{paddingTop:"10px"}}
                                            className=" form-control  custom-form text-field font-14 font-medium"
                                            id="dedicatedInstanceHours"
                                            value={values.dedicatedInstanceHours}
                                            name={"dedicatedInstanceHours"}
                                            onChange={(e) => {
                                                onChangeOfConfiguration(e, values.instanceName);
                                            }} 
                                            disabled={isViewer}
                                        />
                                        </div>

                                        <div className="col-md-6 px-3">
                                            <label htmlFor="dedicatedInstanceHoursFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                            Dedicated Instance Hours Factor
                                            </label>
                                            <select
                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                id="dedicatedInstanceHoursFactor"
                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                value={values.dedicatedInstanceHoursFactor}
                                                name={"dedicatedInstanceHoursFactor"}
                                                onChange={(e) => {
                                                    onChangeOfConfiguration(e, values.instanceName);
                                                }} disabled={isViewer}
                                            >
                                            
                                            <option value="1">Hours</option>
                                            <option value="24">Days</option>
                                            <option value="730">Month</option>
                                            
                                            </select>
                                        </div>
                                        </>
                                        ) : (
                                        <>
                                        <h6 style={{ marginTop: "5%", marginBottom :"2%"}}>Dynamic Sessions</h6>
                                        <div className="col-md-6 px-3">
                                        <label htmlFor="sessions" className="form-label font-14 font-medium black-v2 mb-3">
                                        Sessions
                                        </label>
                                        <input
                                            type="text"
                                            style={{paddingTop:"10px"}}
                                            className=" form-control  custom-form text-field font-14 font-medium"
                                            id="sessions"
                                            value={values.sessions}
                                            name={"sessions"}
                                            onChange={(e) => {
                                                onChangeOfConfiguration(e, values.instanceName);
                                            }} 
                                            disabled={isViewer}
                                        />
                                        </div>

                                        <div className="col-md-6 px-3">
                                        <label htmlFor="sessionHours" className="form-label font-14 font-medium black-v2 mb-3">
                                        Session Hours
                                        </label>
                                        <input
                                            type="text"
                                            style={{paddingTop:"10px"}}
                                            className=" form-control  custom-form text-field font-14 font-medium mb-3"
                                            id="sessionHours"
                                            value={values.sessionHours}
                                            name={"sessionHours"}
                                            onChange={(e) => {
                                                onChangeOfConfiguration(e, values.instanceName);
                                            }} 
                                            disabled={isViewer}
                                        />
                                        </div>

                                        <div className="col-md-6 px-3">
                                            <label htmlFor="sessionHoursFactor" className="form-label font-14 font-medium black-v2 mb-3">
                                            Session Hours Factor
                                            </label>
                                            <select
                                                className=" form-select custom-form  font-semibold font-14 form-select-sm"
                                                id="sessionHoursFactor"
                                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                                value={values.sessionHoursFactor}
                                                name={"sessionHoursFactor"}
                                                onChange={(e) => {
                                                    onChangeOfConfiguration(e, values.instanceName);
                                                }} disabled={isViewer}
                                            >
                                            
                                            <option value="1">Hours</option>
                                            <option value="24">Days</option>
                                            <option value="730">Month</option>
                                            
                                            </select>
                                        </div>
                                        </>
                                        )}
                                        
                                        
                                    </div>
                                    
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    } catch (error) {
        //console.log(error);
    }
}


// values["region"] = 'us-east';
// values["planType"] = 'consumption';
// values["requestsInMillions"] = '10000000000';
// values["activeUsageConcurrentRequests"] = '1000000000000';
// values["activeUsageExecutionMsPerRequest"] = '1000000000000';
// values["dedicatedInstances"] = '1000000000000';
// values["dedicatedInstanceHoursFactor"] = "730";
// values["dedicatedInstanceHours"] = "100000";

// values["dedicatedInstanceCount"] = "1000000000";
// values["workload"] = 'D4';values["tier"] === 'free'
// values["vCpuCount"] = "0.25";
// values["memorySize"] = "2";
// values["gpuWorkload"] = "A100";
// values["sessions"] = "100000000000";
// values["sessionHours"] = "100000000000";
// values["sessionHoursFactor"] = "730";
// values["RequiredMinimumReplicas"] = "no";