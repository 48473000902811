
import React, { useEffect } from 'react';

/**
 * WorkbenchPopup Component
 * 
 * This component displays a popup message that automatically disappears after 3 seconds.
 * It can show both success and error messages with different styling.
 *
 * @param {Object} props
 * @param {Object} props.popupMsg - Object containing popup message details
 * @param {boolean} props.popupMsg.show - Whether to show the popup
 * @param {string} props.popupMsg.Msg - The message to display
 * @param {boolean} props.popupMsg.success - Whether it's a success message (true) or error message (false)
 * @param {Function} props.setPopUpMsg - Function to update the popup message state
 */
export default function WorkbenchPopup({ popupMsg, setPopUpMsg }) {

  useEffect(() => {
    // If popup message is shown, set a timeout to hide it after 3 seconds
    if (popupMsg.show) {
      const timer = setTimeout(() => {
        setPopUpMsg({ ...popupMsg, show: false, Msg: "", success: false });
      }, 3000);

      // Clear timeout if the component is unmounted or if popupMsg changes
      return () => clearTimeout(timer);
    }
  }, [popupMsg, setPopUpMsg]);

  console.log(popupMsg, "popupmsg");

  return (
    /* Add zIndex and other styling to position the popup overlay */
    <div className="container-fluid" style={{ position: 'fixed', top: '10%', left: '50%', transform: 'translateX(-50%)', zIndex: 1000 }}>
    {popupMsg.show && (
      
      <div className={`${popupMsg.success ? 'custom-toast-container' : 'custom-toast-container-1'} d-flex align-items-center justify-content-between`} 
        style={{ backgroundColor: popupMsg.success ? 'green' : 'red', padding: '1rem', borderRadius: '5px',width:'29%',marginLeft:'35%' }}>
        <div className="d-flex align-items-center gap-3 mb-0">
          <img
            src={popupMsg.success ? 'images/tick-green-small.svg' : 'images/error-icon-red.svg'}
            alt="status-icon"
            className="width-25"
          />
          <p className="font-14 font-regular text-white mb-0">
            {popupMsg.Msg}
          </p>
        </div>
        <button 
          type="button" 
          className="btn p-0 shadow-none"
          onClick={() => {
            setPopUpMsg({ ...popupMsg, Msg: '', show: false, success: false });
          }}
        >
          <img src="images/cross-white.svg" alt="cross-icon" className="w-9" />
        </button>
      </div>
    )}
  </div>
  );
}